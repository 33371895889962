<mat-card>
	<div class="header">
		<mat-card-header>
			<mat-card-title class="billing-info">My Billing Information</mat-card-title>
		</mat-card-header>
		<div *ngIf="this.billingInfo?.disabled" class="edit-button">
			<btn07 (onClick)="editEvent()">Edit</btn07>
		</div>
		<div *ngIf="!this.billingInfo?.disabled" class="action-buttons">
			<btn07 (onClick)="cancelEvent()">Cancel</btn07>
			<btn01 (onClick)="saveEvent()">Save</btn01>
		</div>
	</div>
	<hr class="under-line" />
	<mat-card-content>
		<form [formGroup]="billingInfo">
			<div class="container billing-cards">
				<div class="billing-formelements">
					<tb02 [control]="billingInfo.get('Company')" [label]="'Company *'"></tb02>
				</div>
				<div class="billing-formelements">
					<tb02 [control]="billingInfo.get('FullName')" [label]="'Full Name *'"></tb02>
				</div>
				<div class="billing-formelements">
					<tb02 [control]="billingInfo.get('BillingAddress')" [label]="'Billing Address *'"></tb02>
				</div>
			</div>

			<div class="container billing-cards">
				<div class="billing-formelements">
					<tb02 [control]="billingInfo.get('City')" [label]="'City *'"></tb02>
				</div>
				<div class="billing-formelements">
					<tb02 [control]="billingInfo.get('ZipCode')" [label]="'Zipcode *'"></tb02>
				</div>
				<div class="billing-formelements">
					<tb02 *ngIf="isEditable === false" [control]="billingInfo.get('Country')" [label]="'Country *'"></tb02>
					<drp02
						*ngIf="isEditable"
						[control]="billingInfo.get('CountryId')"
						[data]="dropdownCountries"
						[label]="'Country *'"
						[width]="'18.49vw'"
					></drp02>
				</div>
			</div>
		</form>
		<span class="billing-note">
			{{ billingNote }}
		</span>
	</mat-card-content>
</mat-card>
