import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReportComponentService } from '../../../services/chart-services/report-component.service';
import { SingleNumberChartService } from '../../../services/chart-services/single-number.service';
import { ReportModel } from '../../../models/report.model';
import { Helper } from '../../../helper';

import { TableViewColumnTemplateEnum } from '../../../../shared/generic-table2/models/table-structure/table-view-column-template.enum';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-single-number-chart',
	templateUrl: './single-number-chart.component.html',
	styleUrls: ['./single-number-chart.component.scss']
})
export class SingleNumberChartComponent implements OnInit, OnDestroy {
	@Input() widget: ReportModel;
	@Input() index: number;

	public isPositiveValue: boolean;
	public valueToDisplay: number;
	public percentage: number = 0;

	public symbol: TableViewColumnTemplateEnum;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private toastNotificationService: ToastNotificationService,
		private widgetComponentService: ReportComponentService,
		private chartService: SingleNumberChartService
	) {}

	ngOnInit() {
		this.chartService.setChartInstances(this.index, { instance: this, dom: undefined });
		this.setValue();
		this.symbol = this.widget.details.metric[0]?.typeId;
		this.createDataForExcel();
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public clear(): void {}
	public setOption(param: any): void {
		this.valueToDisplay = null;
		this.percentage = null;
		this.setValue();
		this.symbol = this.widget.details.metric[0]?.typeId;
		this.createDataForExcel();
	}

	public dispose(): void {}

	public checkCurrencySymbol(): boolean {
		return this.symbol === TableViewColumnTemplateEnum.Currency;
	}

	public checkPercentageSymbol(): boolean {
		return this.symbol === TableViewColumnTemplateEnum.Percentage;
	}

	public setValue(): void {
		this.percentage = null;
		this.widget.name = this.widget?.name;

		if (this.widget.details.chartData) {
			this.valueToDisplay = this.widget.details.chartData.required_metric ? this.widget.details.chartData.required_metric : 0;

			const percent = Math.round((this.widget.details.chartData.percentage_difference + Number.EPSILON) * 100) / 100;
			this.percentage = percent ? percent : 0;

			this.isPositiveValue = this.widget.details.chartData.trend === 'POSITIVE';
		}
	}

	public getValue(value: number): string {
		if (value > 999) {
			return Helper.convertAxisValues(Helper.round(value));
		} else {
			return !isNaN(value) ? String(+Number(value.toFixed(2))) : null;
		}
	}

	private mapChartData(items: any): any[] {
		return items.map((item: any) => {
			const objectKeys = Object.keys(item);
			let itemName;
			let itemValue;
			const firstKey = item[objectKeys[0]] ? item[objectKeys[0]].toString() : undefined;
			if (firstKey === item[objectKeys[0]]) {
				itemName = item[objectKeys[0]];
				itemValue = item[objectKeys[1]];
			} else {
				itemName = item[objectKeys[1]];
				itemValue = item[objectKeys[0]];
			}
			return {
				name: itemName,
				value: itemValue
			};
		});
	}

	private createDataForExcel(): void {
		const isCurrency = this.checkCurrencySymbol();
		const isPercentage = this.checkPercentageSymbol();
		const value = (isCurrency ? '$ ' : '') + (this.valueToDisplay ? this.getValue(this.valueToDisplay) : 0) + (isPercentage ? ' % ' : '');

		const percentageString = (!this.isPositiveValue && this.percentage ? '-' : '') + (this.percentage ? this.percentage : '0') + '%';
	}

	private getAverageSum(array: any): number {
		let sum = 0;
		array.forEach((item: any) => {
			sum += Number(item.value);
		});
		return sum;
	}
}
