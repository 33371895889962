<div class="oc-container">
	<!-- Objectives-->
	<app-expansion-panel-sc
		(click)="onExpansionClick.emit(campaignPanels.Objectives)"
		[isSelected]="isObjectivesPanelSelected"
		[expansionTitle]="'Campaign Objective'"
		[isExpandable]="false"
	>
		<div expansion-content class="oc-content">
			<div class="oc-image-container oc-margin-bottom">
				<app-card-box
					*ngFor="let objective of objectives"
					class="oc-card-size"
					(click)="onObjectiveClick(objective)"
					[isSelected]="isCardSelected(objective.name, 'objective')"
					[isInvalid]="
						!this.objectivesGroup.get('objective').valid &&
						this.objectivesGroup.touched &&
						objective.imageState !== state.Disabled &&
						objective.imageState !== state.Hover
					"
				>
					<div card-content appHoverSelect [statefulImage]="objective" [statefulImages]="objectives" class="oc-image-card oc-objective-card">
						<img src="/assets/icons/campaign-smart-create/{{ objective.name.toLowerCase() }}-{{ objective.imageState }}.svg" alt="image" />
						<span class="oc-text-container">{{ objective.displayNameAutogen }}</span>
					</div>
				</app-card-box>
			</div>
			<span *ngIf="!objectivesGroup.get('objective').valid && objectivesGroup.touched" class="warning">
				Selecting a Campaign Objective is a mandatory action
			</span>
		</div>
	</app-expansion-panel-sc>

	<!--	 Sub Objectives-->
	<app-expansion-panel-sc
		*ngIf="selectedObjective?.items?.length"
		(click)="onExpansionClick.emit(campaignPanels.SubObjectives)"
		[isSelected]="isSubObjectivesPanelSelected"
		[expansionTitle]="selectedObjective.displayNameAutogen"
		[isExpandable]="false"
	>
		<mat-radio-group expansion-content class="oc-sub-objectives-wrapper" name="Objective">
			<div *ngFor="let subObjective of selectedObjective.items; let last = last" class="oc-radio-btn-container oc-sub-objective-radio-btn">
				<mat-radio-button
					(click)="updateSubObjectiveState(subObjective)"
					[value]="checkSelected(subObjective)"
					[checked]="checkSelected(subObjective) === objectivesGroup.get('subObjective').value"
					name="subObjective"
				>
					<span class="oc-bold-text">{{ subObjective.display_name }}</span>
				</mat-radio-button>
				<span class="oc-radio-btn-description">{{ subObjective.description }}</span>
			</div>
		</mat-radio-group>
	</app-expansion-panel-sc>

	<!--	 Sub Objectives futher check-->
	<app-expansion-panel-sc
		*ngIf="selectedSubObjectiveForCheck?.items?.length"
		(click)="onExpansionClick.emit(campaignPanels.SubObjectives)"
		[isSelected]="isSubObjectivesPanelSelected"
		[expansionTitle]="selectedSubObjectiveForCheck.displayNameAutogen"
		[isExpandable]="false"
	>
		<mat-radio-group expansion-content class="oc-sub-objectives-wrapper" name="Objective">
			<div *ngFor="let subObjective of selectedSubObjectiveForCheck.items" class="oc-radio-btn-container oc-sub-objective-radio-btn">
				<mat-radio-button
					(click)="updateSubObjectiveState(subObjective)"
					[value]="checkSelected(subObjective)"
					[checked]="checkSelected(subObjective) === objectivesGroup.get('subObjective').value"
					name="subObjective"
				>
					<span class="oc-bold-text">{{ subObjective.display_name }}</span>
				</mat-radio-button>
				<span class="oc-radio-btn-description">{{ subObjective.description }}</span>
			</div>
		</mat-radio-group>
	</app-expansion-panel-sc>
</div>
