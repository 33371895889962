import { ChartSeriesModel } from './chart-series.model';

export class ChartLegendData {
	public type: 'plain' | 'scroll' = 'plain';
	public top = '0';
	public left = 'center';
	public orient: 'vertical' | 'horizontal';
	public right: string;
	public bottom: string;
	public data?: string[];
	public padding?: number[];
}
