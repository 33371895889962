import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';

@Component({
	selector: 'app-sad',
	templateUrl: './sad.component.html',
	styleUrls: ['./sad.component.scss']
})
export class SadComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading: boolean = false;
	public myCurrentPlan: any;
	constructor(private _location: Location, private router: Router, private billingService: PaymentServiceApi) {}

	ngOnInit(): void {
		this.loadMyCurrentPlan();
	}

	public loadMyCurrentPlan(): void {
		this.billingService
			.getUserTarrifPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				if (details) {
					this.myCurrentPlan = details;
				}
			});
	}

	get getDate(): string {
		return moment(this.myCurrentPlan?.lastInvoiceDate).format('MMMM Do YYYY').replace('undefined.undefined.', '');
	}
	get getEndDate(): string {
		return moment(this.myCurrentPlan?.lastInvoiceDate).endOf('month').format('MMMM Do YYYY').replace('undefined.undefined.', '');
	}
	public backClicked() {
		this._location.back();
	}

	public onCancel() {
		this.router.navigate(['user-management/security']);
	}

	public onNext() {
		this.router.navigate(['user-management/security/pausing-account']);
	}
}
