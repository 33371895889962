import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-knowledge-base-pop-up',
	templateUrl: './knowledge-base-pop-up.component.html',
	styleUrls: ['./knowledge-base-pop-up.component.scss']
})
export class KnowledgeBasePopUpComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<KnowledgeBasePopUpComponent>) {}

	public ngOnInit(): void {}

	public backButton(): void {
		this.dialogRef.close();
	}
}
