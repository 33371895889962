export enum TimeBreakdownType {
	DateRange = 1,
	Month = 1
}

export enum InsightBreakdownType {
	Time = 'time',
	Delivery = 'delivery',
	Action = 'action',
	Segments = 'segments'
}

export interface InsightBreakdown {
	groupColumn: string;
	filterColumn: string;

	id: number;
	name: string;
	displayName: string;
	columnName?: string;
	value: string;

	isColumn?: boolean;

	breakdownType?: InsightBreakdownType;
	breakdownTempUID?: string;
	propertyName: string;
}

export interface ActionBreakdown extends InsightBreakdown {}

export interface TimeBreakdown extends InsightBreakdown {}

export interface DeliveryBreakdown extends InsightBreakdown {
	values: string[];
	active: boolean;
	column: string;
}

export interface BreakdownCombination {
	deliveryBreakdownId: number;
	deliveryBreakdown: DeliveryBreakdown;
	actionBreakdownId: number;
	actionBreakdown: ActionBreakdown;
	// segments: any[];
	id: number;
}

// Convention: All breakdowns default values are 0
export interface BreakdownWrapper {
	time?: TimeBreakdown[];
	action?: ActionBreakdown[];
	delivery?: DeliveryBreakdown[];
	segments?: InsightBreakdown[];
	combinations?: BreakdownCombination[];
}
