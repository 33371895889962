import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { AdPreviewActions, AdPreviewActionTypes } from './ad-preview.action';

export interface AdPreviewState {
	dropdownPreviewPlacement: DropdownData<CatalogNodeInterface>[];
	iframeSrc: string;
	previewWidth: number;
	previewHeight: number;
	isFetchingIframe: boolean;
}

export const initialAdPreviewState: AdPreviewState = {
	dropdownPreviewPlacement: [],
	iframeSrc: '',
	previewWidth: 100,
	previewHeight: 100,
	isFetchingIframe: false
};

export function adPreviewReducer(state = initialAdPreviewState, action: AdPreviewActions): AdPreviewState {
	switch (action.type) {
		case AdPreviewActionTypes.UpdatePreviewPlacement:
			return {
				...state,
				dropdownPreviewPlacement: action.payload
			};
		case AdPreviewActionTypes.UpdatePreviewIFrameSrc:
			return {
				...state,
				iframeSrc: action.payload
			};
		case AdPreviewActionTypes.UpdatePreviewWidth:
			return {
				...state,
				previewWidth: action.payload
			};
		case AdPreviewActionTypes.UpdatePreviewHeight:
			return {
				...state,
				previewHeight: action.payload
			};
		case AdPreviewActionTypes.SetFetchingIsIframe:
			return {
				...state,
				isFetchingIframe: action.payload
			};
		default:
			return state;
	}
}
