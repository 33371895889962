import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { Observable } from 'rxjs';
import { ShopifyShopDetails } from '../model/shopify-shop-details.interface';
import { map, mergeMap } from 'rxjs/operators';
import { ProductCatalogInterface } from '../model/product-catalog.interface';
import { CatalogShopInterface } from '../model/catalog-shop-interface';
import { CatalogMappingInterface, CsvCatalogMappingInterface, ProductVariantInterface } from '../model/catalog-mapping.interface';
import { VariablesInterface } from 'src/app/export-catalog/model/variables.interface';
import { CatalogDatabase, CatalogAgGrid, CatalogFilterData, CatalogFilter } from '../model/catalog-category-type.enum';
import { Store } from '@ngrx/store';
import { SetsInterface } from '../model/sets.interface';
import { ProductsInterface } from '../model/products.interface';
import { EcomerceSource } from '../model/eccomerce-source.enum';
import moment from 'moment';
import {
	NewAddFieldInterface,
	PreinstallInterface,
	QuickSetPostInterface,
	smartCreatorInterface,
	VariantIssueInterface
} from '../model/catalog-models.interface';
import { SyncSettingPayloadInterface } from '../model/sync-setting-payload.interface';
import { AdditionalDataSourceInterface } from '../model/catalog-data-source-file-option.interface';
import { ImportPreinstallInterface } from '../model/preinstall.interface';
@Injectable()
export class ShopifyCatalog {
	public base_url: string = BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda;
	constructor(private http: HttpClient, public productCatalogStore: Store<any>) {}

	public getShopDetails(store: string): Observable<ShopifyShopDetails> {
		let query = {
			shop_name: store,
			endpoint: 'shop'
		};

		return this.http.post<ShopifyShopDetails>(`http://localhost:5050`, query);
	}
	public getProductCatalog(): Observable<ProductCatalogInterface[]> {
		return this.http.get<ProductCatalogInterface[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/withPlatform`);
	}
	public updateCatalogName(params: any): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs`, params);
	}
	public updateDetectIssues(params: { ids: number[] }): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/detectIssues`, params);
	}
	public toggleTwoWaySync(params: { filedProductCatalogId: number }): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/toggleTwoWaySync`, params);
	}

	public getInstallURL(data: CatalogShopInterface): Observable<PreinstallInterface> {
		return this.http.get<PreinstallInterface>(
			`${this.base_url}oauth/${data.shop}/preinstall?userId=${data.userId}&shop=${data.store}&email=${data.email}&username=${data.username}&password=${data.password}&host_url=${data.store}&redirect_url=${data.redirect_url}`
		);
	}

	public getShopifyInstallURL(shop: string, authKey: string): Observable<PreinstallInterface> {
		const url = `${this.base_url}oauth/shopify/preinstall?shop=${shop}&api_secret_key=${authKey}`;
		return this.http.get<PreinstallInterface>(url);
	}
	public postShopifyInstallURL(payload: string, shop: string): Observable<PreinstallInterface> {
		return this.http.post<PreinstallInterface>(`${this.base_url}oauth/${shop}/preinstall?${payload}`, {});
	}
	public getInstallCSV(data: string): Observable<any> {
		return this.http.get<any>(`${this.base_url}oauth/${data}/preinstall`);
	}
	public postMappingDetails(
		store: string,
		mappings: CatalogMappingInterface,
		fileFormat?: string,
		csvFile?: File,
		link?: string,
		ecommName?: string,
		catalogId: number = 0
	): Observable<any> {
		const sync_preference = {
			days: [moment().format('dddd')],
			hour: moment().hours().toString(),
			minute: moment().minutes().toString(),
			meridiem: moment().format('hh:mm A').split(' ')[1],
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
		};
		let mapping: ProductVariantInterface = mappings.mapping;
		if (EcomerceSource.Fileupload.toString() === store) {
			let formData: FormData = new FormData();
			formData.append('mapping', JSON.stringify({ mapping }));
			formData.append('product_catalog_id', catalogId.toString());
			return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}receiver/${fileFormat}`, {
				mapping: mapping,
				product_catalog_id: catalogId,
				sync_preference
			});
		} else if (store == 'xml') {
			return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}receiver/${store.toLowerCase()}`, {
				mapping: mappings.mapping,
				product_catalog_id: catalogId,
				sync_preference
			});
		} else {
			return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}receiver/${store.toLowerCase()}`, {
				mapping: mapping,
				product_catalog_id: catalogId,
				sync_preference
			});
		}
	}
	public getMappingDetails(data: string, platform: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}${data}/${platform}`, {}).pipe(
			map((v: any) => {
				v.mapping.variant.map(v => {
					v.mappedTo = v.mappedTo[0];
					return v;
				});
				return v;
			})
		);
	}

	public getCsvMapping(formData?: FormData): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}mapping/csv`, formData);
	}
	public getXmlMapping(formData?: FormData): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}mapping/xml`, formData);
	}
	public smartEditor(params: any): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}SmartSets`, params);
	}
	public smartDelete(param: any): Observable<any> {
		let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: param };
		return this.http.delete<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}SmartSets`, options);
	}
	public smartCreator(params: smartCreatorInterface): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}SmartSets`, params);
	}
	public quickSetCreator(para: QuickSetPostInterface): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Sets`, para);
	}
	public getSmartSet(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}SmartSets/1`);
	}
	public getProductData(param: string): Observable<VariablesInterface[]> {
		return this.http.get<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}${param}`);
	}
	public getProductIssue(param: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/issues?id=${param}`);
	}
	public getProductHealth(param: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Products/variants/healthStatus/${param}`);
	}
	public getGridView(param: number): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}AgGrid/view/${param}`);
	}
	public getProductIssueVariants(param: number): Observable<VariantIssueInterface[]> {
		return this.http.get<VariantIssueInterface[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Products/variants/issues/${param}`);
	}
	public variantEditor(variantIssue: FormData): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Products/variants`, variantIssue);
	}
	public productEditor(productData: any): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Products`, productData);
	}
	public getVariantIssue(param: string, catalogId: string): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Products/variants/issues/${param}/${catalogId}`);
	}
	public getAllMetrics(catalogId: number): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/metrics?id=${catalogId}`);
	}
	public getAddFieldPreview(catalogId: number, filter: string): Observable<number> {
		return this.http.get<number>(
			`${BaseApiUrl.ProductCatalog.ProductCatalog}Products/variants/addField/preview?FiledProductCatalogId=${catalogId.toString()}&Filter=${filter}`
		);
	}
	public additionalDataSource(catalogId: string): Observable<AdditionalDataSourceInterface[]> {
		return this.http.get<AdditionalDataSourceInterface[]>(
			`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/additionalDataSource?filedProductCatalogId=${catalogId.toString()}`
		);
	}
	public generatePostModel(database: number, gridtab: number, cColumn: any[], column: any[], res: any): any {
		return {
			databaseTable: database,
			agGridTab: gridtab,
			name: 'Welcome Default View',
			isDefault: true,
			customColumns: cColumn,
			queryModel: {
				startRow: 0,
				rows: 0,
				columns: column,
				filterModel: {
					FiledProductCatalogId: {
						filterType: CatalogFilterData.Number,
						type: CatalogFilter.Equals,
						filter: res.productCatalogId
					}
				}
			}
		};
	}

	public getAllProduct(): Observable<ProductsInterface[]> {
		return this.productCatalogStore.select('ProductCataolog').pipe(
			mergeMap((res: any) => {
				return this.http
					.post<VariablesInterface>(
						`${BaseApiUrl.ProductCatalog.ProductCatalog}AgGrid/fetchData`,
						this.generatePostModel(
							CatalogDatabase.Products,
							CatalogAgGrid.Products,
							res.agGridHeader[CatalogAgGrid.Products - 1].customColumns.map((e: any) => e.columnName),
							res.agGridHeader[CatalogAgGrid.Products - 1].masterColumns.map((e: any) => e.columnName).join(','),
							res
						)
					)
					.pipe(
						map((sets: any) => {
							return sets;
						})
					);
			})
		);
	}
	public getAllVariant(): Observable<VariablesInterface[]> {
		return this.productCatalogStore.select('ProductCataolog').pipe(
			mergeMap((res: any) => {
				return this.http
					.post<VariablesInterface>(
						`${BaseApiUrl.ProductCatalog.ProductCatalog}AgGrid/fetchData`,
						this.generatePostModel(
							CatalogDatabase.Variants,
							CatalogAgGrid.Variants,
							res.agGridHeader[CatalogAgGrid.Variants - 1].customColumns.map((e: any) => e.columnName),
							res.agGridHeader[CatalogAgGrid.Variants - 1].masterColumns.map((e: any) => e.columnName).join(','),
							res
						)
					)
					.pipe(
						map((sets: any) => {
							return sets;
						})
					);
			})
		);
	}
	public getAllSet(): Observable<SetsInterface[]> {
		return this.productCatalogStore.select('ProductCataolog').pipe(
			mergeMap((res: any) => {
				return this.http
					.post<VariablesInterface>(
						`${BaseApiUrl.ProductCatalog.ProductCatalog}AgGrid/fetchData`,
						this.generatePostModel(
							CatalogDatabase.Sets,
							CatalogAgGrid.Sets,
							res.agGridHeader[CatalogAgGrid.Sets - 1].customColumns.map((e: any) => e.columnName),
							res.agGridHeader[CatalogAgGrid.Sets - 1].masterColumns.map((e: any) => e.columnName).join(','),
							res
						)
					)
					.pipe(
						map((sets: any) => {
							return sets;
						})
					);
			})
		);
	}
	public getProductDetails(store: string): Observable<any> {
		let query = {
			shop_name: store,
			endpoint: 'products'
		};
		return this.http.get<any>(`https://filed-ecommerce-lambda-demo.herokuapp.com/get_mapping`);
	}
	public getProductTable(): Observable<any> {
		return this.http.get<any>(`https://filed-ecommerce-lambda-demo.herokuapp.com/get_mapping`);
	}
	public createAddField(data: NewAddFieldInterface): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Products/variants/addfield`, data);
	}
	public syncStatus(para: string): Observable<{ Id: number; SyncStatusId: number }[]> {
		return this.http.get<{ Id: number; SyncStatusId: number }[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/syncStatus?${para}`);
	}
	public catalogSync(payLoadData: SyncSettingPayloadInterface, platform: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}settings/${platform}`, payLoadData);
	}

	public getCsvTemplate(catalogid: string): Observable<HttpResponse<Blob>> {
		/* return this.http.get<Blob>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}csv_template?product_catalog_id=${catalogid}`, {
			observe: 'response',
			responseType: 'blob' as 'json'
		}); */
		return this.http.get<Blob>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}csv_update_template?product_catalog_id=${catalogid}`, {
			observe: 'response',
			responseType: 'blob' as 'json'
		});
	}
	public getDefaultTemplate(): Observable<HttpResponse<Blob>> {
		return this.http.get<Blob>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}csv_add_template`, {
			observe: 'response',
			responseType: 'blob' as 'json'
		});
	}
	public getXmlTemplate(): Observable<HttpResponse<Blob>> {
		return this.http.post<any>(
			`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/mapping/products/download/xml`,
			{},
			{
				observe: 'response',
				responseType: 'blob' as 'json'
			}
		);
	}
	public updateCsvProduct(csvFile: File, catalogid: string): Observable<any> {
		let formData: FormData = new FormData();
		formData.append('file', csvFile, csvFile.name);
		formData.append('product_catalog_id', catalogid);
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}update_products`, formData);
	}
	public addCsvProduct(csvFile: File, catalogid: string): Observable<any> {
		let formData: FormData = new FormData();
		formData.append('file', csvFile, csvFile.name);
		formData.append('product_catalog_id', catalogid);
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}add_products`, formData);
	}
}
