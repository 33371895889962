import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Form, FormControl, Validators } from '@angular/forms';
import { catchError, debounceTime, take, takeUntil } from 'rxjs/operators';
import { EMPTY, of, Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { LogInSuccess, RegisterUserEmail, RegisterUserLastName, RegisterUserName, RegisterUserPassword } from '../../state/authentication.action';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { sleep } from '../utils/signup-utils';
import { GoogleOauthService } from 'src/app/shared/google-oauth/google-oauth.service';
import { UserDetailModel } from '../../_models/login.model';
import { NewSignUpSubscribe } from '../../sign-up/subscribe.interface';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';

@Component({
	selector: 'app-email-register',
	templateUrl: './email-register-new.component.html',
	styleUrls: ['./email-register-new.component.scss']
})
export class EmailRegisterComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public emControl: FormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;

	public firstNameFormControl = new FormControl('', [
		Validators.required,
		Validators.minLength(2),
		Validators.maxLength(50),
		Validators.pattern(/^[A-Za-z]+$/)
	]);
	public lastNameFormControl = new FormControl('', [
		Validators.required,
		Validators.minLength(2),
		Validators.maxLength(50),
		Validators.pattern(/^[A-Za-z]+$/)
	]);

	public passFormControl = new FormControl('', [
		Validators.required,
		Validators.minLength(3),
		Validators.maxLength(50),
		Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
	]);
	public confirmpassFormControl = new FormControl('', [
		Validators.required,
		Validators.minLength(3),
		Validators.maxLength(50),
		Validators.pattern(/^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/)
	]);
	registrationDetais: UserDetailModel;
	planId: number;
	public subscribeData: NewSignUpSubscribe;
	error: boolean;
	emailSent: boolean;
	showConfirmPasswordError = null;
	hidePasswordError = null;

	public firstNameValidationIcon = null;
	public lastNameValidationIcon = null;
	public emailValidationIcon = null;
	public passwordValidationIcon = null;
	public confirmPasswordValidationIcon = null;

	constructor(
		private router: Router,
		private store: Store<AuthenticationState>,
		private user: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		private sharedStore: Store<SharedState>,
		private googleoauthService: GoogleOauthService,
		private activatedRoute: ActivatedRoute,
		private backOfficeService: BackOfficeService,
		private ngZone: NgZone
	) {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			// this.planId = encodeURIComponent(params['token']);
			// this.emailValue = decodeURIComponent(params['email']);
			this.planId = parseInt(params['planId']);
		});
	}

	public ngOnInit(): void {
		const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.emControl = new FormControl('', [Validators.required, Validators.maxLength(70), Validators.pattern(emailValidator)]);
		// this.formChanges();
		this.getRegistrationDetails();
		this.onFormNameChange();
		this.onFormLastNameChange();
		this.onFormEmailChange();
		this.onFormPasswrordChange();
		this.onFormConfirmPasswordChange();
	}

	public ngOnDestroy(): void {}

	public formChanges(): void {
		this.emControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
	}

	public onFormNameChange(): void {
		this.firstNameFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.firstNameFormControl.valid) {
				this.store.dispatch(new RegisterUserName(this.firstNameFormControl.value));
			}
		});
	}

	public onFormLastNameChange(): void {
		this.lastNameFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.lastNameFormControl.valid) {
				this.store.dispatch(new RegisterUserLastName(this.lastNameFormControl.value));
			}
		});
	}

	public onFormEmailChange(): void {
		this.emControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.emControl.valid) {
				this.store.dispatch(new RegisterUserEmail(this.emControl.value));
			}
		});
	}

	public onFormPasswrordChange(): void {
		this.passFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.passFormControl.valid) {
				this.store.dispatch(new RegisterUserPassword(this.passFormControl.value));
			}
		});
	}

	public onFormConfirmPasswordChange(): void {
		this.confirmpassFormControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (!this.confirmpassFormControl.valid || value !== this.passFormControl.value) {
				this.confirmpassFormControl.setErrors({ NoPasswordMatch: true });
			}
		});
	}

	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), take(1)).subscribe(detail => {
			if (detail) {
				console.log(detail);
				this.registrationDetais = detail;
				this.emControl.setValue(detail.email);
				this.firstNameFormControl.setValue(detail.name);
				this.lastNameFormControl.setValue(detail.lastname);
			}
		});
	}

	public checkForInvalid(): void {
		const email = this.emControl.value;
		if (!this.emControl) {
			this.emControl.setErrors({ dialCode: true });
		}
	}

	public continueSignup(): void {
		this.submitting = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.user
			.confirmUserEmail(this.emControl.value)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				status => {
					// if (status) {
					this.store.dispatch(new RegisterUserEmail(this.emControl.value));
					this.submitting = true;
					this.sleep(3000);
					this.router.navigate(['/authentication/personal-detail']);
					// } else {
					// 	this.toastNotificationService.sendErrorToast('This email is already associated with an account.');
					// }
				},
				() => {},
				() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public sleep(ms: number): Promise<any> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	public backToLogin(): void {
		this.router.navigate(['/authentication']);
	}

	public continueSignupTemp(): void {
		this.submitting = true;
		sleep(3000);
		this.router.navigate(['/authentication/verify-email']);
		this.submitting = false;
	}

	signUpWithGoogle(): void {
		this.submitting = true;
		const activePlanId = this.planId ? this.planId : 146;
		this.googleoauthService
			.loginForUser()
			.then(value => {
				this.user
					.googleSignup(activePlanId, value.getAuthResponse().id_token)
					.pipe(
						takeUntil(this.unsubscriber$),
						catchError(error => {
							this.submitting = false;
							this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
							return of(null);
						})
					)
					.subscribe(response => {
						this.submitting = false;
						if (response) {
							this.ngZone.run(() => {
								this.store.dispatch(new LogInSuccess(response));
							});
						}
					});
			})
			.catch(error => {
				this.submitting = false;
				console.log('google auth error ', error);
				this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
			});
	}

	public processRegistrationDetail(): void {
		if (!this.formFieldsValid()) {
			return;
		}
		const activePlanId = this.planId ? this.planId : 146;
		if (!activePlanId) {
			this.toastNotificationService.sendErrorToast('Invalid tarrif plan');
			this.router.navigate(['/authentication']);
		}
		// else if (!this.registrationDetail.phone) {
		// 	this.toastNotificationService.sendErrorToast('User Phone number is invalid');
		// 	this.router.navigate(['/authentication/personal-detail']);
		// }
		else {
			this.submitting = true;
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			this.subscribeData = {
				offerId: activePlanId,
				firstName: this.firstNameFormControl.value,
				lastName: this.lastNameFormControl.value,
				companyName: '',
				phoneNumber: '',
				email: this.emControl.value,
				duration: 'month',
				password: this.passFormControl.value
			};

			this.error = false;

			this.backOfficeService
				.subscribeToOfer(this.subscribeData)
				.toPromise()
				.catch(error => {
					this.error = true;
					if (error && error.error && error.error[0] && error.error[0].description && error.error[0].description.length) {
						this.toastNotificationService.sendErrorToast(error.error[0].description.split(':')[0]);
					} else if (
						(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__Contact__ExistsWithSameCriteria') ||
						(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__Contact_Hubspot__ExistsWithSameCriteria')
					) {
						this.toastNotificationService.sendErrorToast('A user already exists with this email');
					} else {
						this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
					}

					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
					return;
				})
				.then(() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
					if (!this.error) {
						this.emailSent = true;
						this.toastNotificationService.sendSuccessToast('Email was successfully sent.');
						this.router.navigate(['/authentication/verify-email']);
					}
				});
		}
	}

	public formFieldsValid(): boolean {
		let validity = false;
		if (
			this.firstNameFormControl.valid &&
			this.lastNameFormControl.valid &&
			this.emControl.valid &&
			this.passFormControl.valid &&
			this.passFormControl.value === this.confirmpassFormControl.value
		) {
			validity = true;
		}
		return validity;
	}
}
