import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, map, repeatWhen, skipWhile, take, takeUntil, mergeMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { UtilsService } from '../../shared/utils';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { SetCreditCardService } from './set-creditcard.service';
import { Country } from '../../_services/back-office/back-office.models';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { SelectType } from '../../shared/dropdown-search-select/dropdown-type.enum';
import { AuthenticationState, getRedirectUrl, getSetCreditCardConstants } from '../state/authentication.reducer';
import { getFiledId, UserState } from '../../shared/state/user/user.reducer';
import { StripeFormErrorsDTO } from './interfaces/stripe-form-error-dto.interface';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { getGlobalSpinnerStatus, SharedState } from '../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { TrialDialogComponent } from '../trial-dialog/trial-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { Months } from './models/months.model';
import { DropdownInterface } from '../../../app-components/interfaces/dropdown-interface';
import { BillingPlan } from 'src/app/user-management/models/billing-plan.interface';
import { LogIn } from '../state/authentication.action';

@Component({
	selector: 'app-set-credit-card',
	templateUrl: './set-credit-card.component.html',
	styleUrls: ['./set-credit-card.component.scss']
})
export class SetCreditCardComponent implements OnInit, OnDestroy {
	public selectType = SelectType;
	public isFlowCompleted = false;
	public billingActive = false;
	public unknownError: boolean;
	public formStep1IsSubmitted: boolean;
	public formStep2IsSubmitted: boolean;
	public formStep2IsRequested: boolean;
	public dynamicError: string;
	public filterCountrySearch: string;
	public validationMessages: Map<string, ValidatorMessages[]>;
	public formStep1: FormGroup;
	public formStep2: FormGroup;
	public formErrors: StripeFormErrorsDTO;
	public countries: Country[];
	public dropdownCountries: DropdownInterface[];
	public accountState: number;
	public showSpinner: boolean;
	public stripeErrors: boolean;
	public billingPlan: BillingPlan;
	public redirectUrl: string;
	public userState: number;

	private readonly lettersAndSpacePattern: RegExp = /^[a-zA-Z\s]*$/;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private backOfficeService: BackOfficeService,
		private translate: TranslateService,
		private authenticationService: AuthenticationService,
		private setCreditCardService: SetCreditCardService,
		private toastNotificationService: ToastNotificationService,
		private activeRoutes: ActivatedRoute,
		private userServiceApi: UserServiceApi,
		private authStore: Store<AuthenticationState>,
		private userStore: Store<UserState>,
		private sharedStore: Store<SharedState>,
		private dialog: MatDialog
	) {}

	public ngOnInit(): void {
		this.userAccountState();
		this.setDropdownCountries();
		this.setActiveRoutes();
		this.storeCreditcard();
		this.initializeSpinner();
		this.checkIsFreeTrial();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public setActiveRoutes(): void {
		this.activeRoutes.params.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			if (data.pending === 'true') {
				this.isFlowCompleted = true;
				this.pollForValidUser();
			}
		});
	}

	public userAccountState(): void {
		this.userStore.pipe(select(getFiledId), take(1)).subscribe(filedId => {
			this.backOfficeService
				.getUserState(filedId)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(accountState => {
					this.accountState = accountState;
				});
		});
	}

	public next(): void {
		if (!this.billingActive) {
			this.formStep1IsSubmitted = true;
			if (this.formStep1.invalid) {
				UtilsService.validateAllFormFields(this.formStep1);
				return;
			}

			this.billingActive = true;
		} else {
			this.formStep2IsSubmitted = true;
			if (this.formStep2.invalid) {
				UtilsService.validateAllFormFields(this.formStep2);
				return;
			}
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			Observable.fromPromise(this.validateFinalForm())
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {
						this.formStep2IsRequested = false;
						setTimeout(() => {
							if (this.billingPlan.isFreeTrial && !(this.userState === UserStateEnum.Inactive)) {
								this.openFreeTrialDialog();
							} else {
								this.authenticationService.logout({
									queryParams: { [this.redirectUrl]: true }
								});
							}
							this.sharedStore.dispatch(new HideGlobalSpinner());
						}, 20000);
					},
					errorResponse => {
						this.sharedStore.dispatch(new HideGlobalSpinner());
						if (!this.stripeErrors) {
							if (errorResponse && errorResponse.error) {
								const errorShown = errorResponse.error[0].description.split(':');

								this.formErrors = {
									message: errorShown[errorShown.length - 1]
								} as StripeFormErrorsDTO;
							} else {
								this.formErrors = {
									message: 'Error while trying to setup your card, please contact support team'
								} as StripeFormErrorsDTO;

								this.toastNotificationService.sendErrorToast(
									this.translate.instant('Error while trying to setup your card, please contact support team')
								);
							}
						}

						this.unknownError = true;
						this.formStep2IsRequested = false;
						this.formStep1IsSubmitted = false;
						this.formStep2IsSubmitted = false;
					}
				);
		}
	}

	public checkIsFreeTrial(): void {
		const billingPlan = this.backOfficeService.getBillingPlan();
		const redirectUrl = this.authStore.pipe(select(getRedirectUrl), take(1));
		const userState = this.userStore.pipe(
			select(getFiledId),
			take(1),
			mergeMap(filedId => this.backOfficeService.getUserState(filedId))
		);

		forkJoin([billingPlan, redirectUrl, userState])
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(([billingPlan, redirectUrl, userState]) => {
				this.billingPlan = billingPlan;
				this.redirectUrl = redirectUrl;
				this.userState = userState;
			});
	}

	openFreeTrialDialog(): void {
		this.dialog
			.open<TrialDialogComponent>(TrialDialogComponent, {
				width: '40%',
				data: {
					days: this.billingPlan.freeTrialDuration,
					hasPaymentCard: true
				}
			})
			.afterClosed()
			.subscribe(() => {
				this.authenticationService.logout({
					queryParams: { [this.redirectUrl]: true }
				});
			});
	}

	public previous(): void {
		this.billingActive = false;
	}
	public async validateFinalForm() {
		this.formStep2IsRequested = true;
		this.unknownError = false;
		this.dynamicError = null;

		const finalFormControls = this.mergeForms();
		const stripeResponse: { status: number; response: any } = await this.setCreditCardService.validateStripeCard(finalFormControls);
		if (stripeResponse.status !== 200) {
			this.formErrors = stripeResponse.response.error;

			const formControlWithError = this.setCreditCardService.getFormControlWithErrorFromStripe(this.formErrors);

			if (formControlWithError) {
				this.formStep2IsSubmitted = false;
				const formControlWithErrorInFormStep1 = this.formStep1.controls[formControlWithError];
				const formControlWithErrorInFormStep2 = this.formStep2.controls[formControlWithError];

				if (formControlWithErrorInFormStep1) {
					this.billingActive = false;
				} else if (formControlWithErrorInFormStep2) {
					this.billingActive = true;
				}

				this.stripeErrors = true;
			} else {
				this.formErrors = {
					message: 'Error while trying to setup your card, please contact support team'
				} as StripeFormErrorsDTO;

				this.toastNotificationService.sendErrorToast(this.translate.instant('Error while trying to setup your card, please contact support team'));
			}
			this.unknownError = true;
			this.formStep2IsRequested = false;
			return Promise.reject();
		} else if (stripeResponse.status === 200) {
			this.stripeErrors = false;
		}

		const token = stripeResponse.response.id;

		if (this.accountState === UserStateEnum.NoCreditCard) {
			await this.backOfficeService
				.addFirstTimeCard({
					sourceCardToken: token,
					billingAddress: this.formStep2.controls['address1'].value,
					city: this.formStep2.controls['city'].value,
					country: this.formStep2.controls['country'].value.data.value,
					zipCode: this.formStep2.controls['zip'].value
				})
				.toPromise()
				.catch(error => {
					this.toastNotificationService.sendErrorToast(this.translate.instant('Error while trying to setup your card, please contact support team'));
				});
		}
		if (this.accountState === UserStateEnum.FreemiumExpiredNoCreditCard || this.accountState === UserStateEnum.FreeTrialExpiredNoCreditCard) {
			await this.backOfficeService
				.addCardFreeExpired({
					sourceCardToken: token,
					billingAddress: this.formStep2.controls['address1'].value,
					city: this.formStep2.controls['city'].value,
					country: this.formStep2.controls['country'].value.data.value,
					zipCode: this.formStep2.controls['zip'].value
				})
				.toPromise()
				.catch(error => {
					this.toastNotificationService.sendErrorToast(this.translate.instant('Error while trying to setup your card, please contact support team'));
				});
		}
	}

	private storeCreditcard(): void {
		this.authStore
			.pipe(select(getSetCreditCardConstants))
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(constants => {
				this.validationMessages = constants.validationMessages;
				this.formStep1 = this.formBuilder.group({
					cardHolder: new FormControl(null, [
						Validators.required,
						Validators.pattern(this.lettersAndSpacePattern),
						Validators.minLength(5),
						Validators.maxLength(21)
					]),
					number: new FormControl(null, [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(13), Validators.maxLength(19)]),
					expirationDate: new FormControl(null, [Validators.required, this.cardExpiryDateValidator]),
					cvc: new FormControl(null, [Validators.required, Validators.minLength(3)])
				});

				this.formStep2 = this.formBuilder.group({
					company: new FormControl(null, [Validators.minLength(5)]),
					address1: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(150)]),
					address2: new FormControl(null),
					city: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]),
					zip: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
					country: new FormControl(null, [Validators.required])
				});
			});
	}
	private setDropdownCountries(): void {
		this.backOfficeService
			.getAllCountriesList()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.countries = result;
				this.dropdownCountries = this.countries.map((value, index) => {
					return {
						data: value,
						name: value.name,
						id: index
					};
				});
			});
	}
	private mergeForms(): { [key: string]: AbstractControl } {
		return Object.assign({}, this.formStep1.controls, this.formStep2.controls);
	}

	private pollForValidUser(): void {
		this.userStore.pipe(select(getFiledId), take(1)).subscribe(filedId => {
			this.userServiceApi
				.getUserAccountStatus(filedId)
				.pipe(
					take(1),
					repeatWhen(notifier => notifier.pipe(delay(30 * 1000))),
					skipWhile(status => status === UserStateEnum.NoCreditCard),
					takeUntil(this.unsubscriber$)
				)
				.subscribe(() => {
					//** UPDATING TO ADD REFRESH TOKEN FIX  */
					// this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
					// 	this.authenticationService.logout({ queryParams: { [redirectUrl]: true } });
					// });
					this.authenticationService.logout();
					// this.authStore.dispatch(new LogIn());
					this.toastNotificationService.sendSuccessToast(this.translate.instant('Your credit card was added !'));
				});
		});
	}

	private initializeSpinner(): void {
		this.sharedStore
			.select(getGlobalSpinnerStatus)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(status => (this.showSpinner = status));
	}

	private cardExpiryDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
		if (control.value) {
			const expirationDateArray = control.value.split('');
			const monthValue = parseInt(expirationDateArray.slice(0, 2).join(''));
			const yearValue = parseInt(expirationDateArray.slice(2, 4).join(''));

			const datemoment = moment();
			const currentMonth = datemoment.month() + 1;
			const currentYear = parseInt(datemoment.year().toString().slice(2, 4));

			if (monthValue < parseInt(Months.January) || monthValue > parseInt(Months.December)) {
				return { month: true };
			} else if (yearValue < currentYear) {
				return { year: true };
			} else if (monthValue < currentMonth && yearValue <= currentYear) {
				return { expired: true };
			}
			return null;
		}
		return null;
	}

	public logout(): void {
		this.authenticationService.logout();
	}
}
