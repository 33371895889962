import { FacebookInterests } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';
import { BudgetAllocationInterface } from './budget-allocation.interface';

export class StepFourDetails {
	public isSplitByLocation: boolean;
	public isSplitByDevices: boolean;
	public isSplitByGenderSelected: boolean;
	public isSplitAgeRangeSelected: number | boolean;
	public isSplitByInterests: Array<Array<FacebookInterests>> | boolean;
	public isBudgetSplitEvenly: boolean;
	public isSplitByPlacements: boolean;
	public budgetAllocation: BudgetAllocationInterface;
}
