export enum AdSetSettingsPanels {
	AdSetName,
	Pages,
	Placements,
	Targeting,
	Date,
	Budget,
	Optimization,
	Devices,
	MessageDestination
}
