<app-expansion-panel-sc
	(click)="onExpansionClick.emit()"
	[isSelected]="isPanelSelected"
	[expansionTitle]="'Special Ad Category'"
	[isExpandable]="false"
	[infoHoverModel]="specialAdCategoryInfoHover"
>
	<div expansion-content>
		<div class="sac-subtitle">
			<mat-slide-toggle (change)="onSpecialAdCategoryToggle()" [checked]="isSpecialAdCategoryToggled" class="sac-slider"></mat-slide-toggle>
		</div>
		<div class="content">
			<div *ngIf="isSpecialAdCategoryToggled" class="sac-image-container sac-padding-top">
				<app-card-box
					*ngFor="let category of specialAdCategories"
					class="sac-card-size"
					(click)="onSpecialCategoryClick(category)"
					[isSelected]="control.value === category.name"
					[isInvalid]="!control.valid && control.touched && category.imageState !== state.Disabled && category.imageState !== state.Hover"
				>
					<div card-content appHoverSelect [statefulImage]="category" [statefulImages]="specialAdCategories" class="sac-image-card">
						<img
							src="/assets/icons/campaign-smart-create/{{ category.name.toLowerCase() }}-{{ category.imageState }}.svg"
							alt="special ad category"
						/>
						<span class="sac-text-container">{{ category.name[0] + category.name.slice(1).toLowerCase() }}</span>
					</div>
				</app-card-box>
			</div>
			<span *ngIf="!control.valid && control.touched" class="warning">Selecting a Special Ad Category is a mandatory action</span>
		</div>
	</div>
</app-expansion-panel-sc>
