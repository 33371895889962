import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import moment from 'moment';

@Component({
	selector: 'app-user-activity',
	templateUrl: './user-activity.component.html',
	styleUrls: ['./user-activity.component.scss']
})
export class UserActivityComponent implements OnInit {
	public userActivities: any = [];
	public filteredRowData: any = [];
	public columnDef: any = [];
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading: boolean = false;
	public pageSize: number = 10;

	constructor(private userServiceApi: UserServiceApi, private sharedStore: Store<SharedState>) {}

	ngOnInit(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.columnDef = [
			{
				key: 'date',
				label: 'Date'
			},
			{
				key: 'user',
				label: 'User'
			},
			{
				key: 'role',
				label: 'Role'
			},
			{
				key: 'event',
				label: 'Event'
			}
		];
		this.getActivity();
	}

	public getActivity() {
		this.userServiceApi
			.getUserActivity()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				activities => {
					console.log(activities);
					if (activities) {
						this.userActivities = activities;
						this.filteredRowData = this.paginate(this.userActivities, this.pageSize, 1);
					}
				},
				error => {
					console.log(error);
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public onPageChanged({ oldPage, newPage }): void {
		this.filteredRowData = this.paginate(this.userActivities, this.pageSize, newPage);
	}

	public paginate(arrayOfOject: any, pageSize: number, pageNumber: number): any {
		return arrayOfOject.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
	}

	public timeSince(date: string): string {
		return moment(date).fromNow().toString();
	}
}
