import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { skipWhile, take } from 'rxjs/operators';
import { getUserDetails } from '../../shared/state/user/user.reducer';
import { UserDetails } from '../../_models/identity-models/identity-detail';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import moment from 'moment';
import { SharedState } from '../../shared/state/shared.reducer';
import { MapperService } from '../../shared/services/mapper.service';
import { ErrorsLoggingServiceApi } from './errors-logging-api.service';
import { EffectErrorObject, ErrorsEnums, GenericError } from './error-object.model';

@Injectable()
export class ErrorsLoggingService {
	constructor(
		private errorsLoggingServiceApi: ErrorsLoggingServiceApi,
		private http: HttpClient,
		private store: Store<SharedState>,
		private mapperService: MapperService
	) {
		this.fetchStore();
	}
	private error: HttpErrorResponse;
	private effectName: string;
	private userDetails: UserDetails;
	private endpoint: string;
	private responseMessage: string;

	private effectParams: string;
	private storeSliceName: string;
	private statusCode: number;

	public logServerDown(error: HttpErrorResponse): void {
		this.errorsLoggingServiceApi.sendError(ErrorsLoggingService.getGenericErrorQuery(ErrorsEnums.ServerDown, error)).subscribe(() => {});
	}

	public logEffectError(storeSliceName: any, effectName: any, params: any[], error: HttpErrorResponse): void {
		this.storeSliceName = storeSliceName;
		this.endpoint = error.url;
		this.statusCode = error.status;
		this.responseMessage = error.message;
		this.effectName = effectName;
		this.effectParams = JSON.stringify(params);
		this.error = error;
		this.errorsLoggingServiceApi.sendError(ErrorsLoggingService.getGenericErrorQuery(ErrorsEnums.Effect, this.getEffectsQuery())).subscribe(() => {});
	}

	private static getGenericErrorQuery(type: string, error: EffectErrorObject | HttpErrorResponse): GenericError<typeof error> {
		return {
			type: type,
			error: error
		} as GenericError<typeof error>;
	}

	private getEffectsQuery(): EffectErrorObject {
		return {
			userId: this.userDetails?.FiledId,
			date: moment(new Date()).toDate(),
			endpoint: this.endpoint,
			currentUrl: location.href,
			statusCode: this.error.status,
			details: {
				state: {
					storeSliceName: this.storeSliceName,
					userDetails: this.userDetails
				},
				response: {
					headers: this.mapperService.mapToString(this.error.headers),
					error: this.error
				},
				effect: {
					name: this.effectName,
					parameters: this.effectParams
				}
			}
		};
	}

	public getCodeFromHttpError(errorResponse: HttpErrorResponse): string {
		if (errorResponse) {
			const error = errorResponse.error;
			const message = error?.code?.message;

			if (message) {
				return message;
			}
			const firstCode = error?.length ? error[0]?.code : '';

			if (firstCode) {
				if (firstCode?.length) {
					return firstCode;
				}

				if (firstCode?.message) {
					return firstCode?.message;
				}
			}
		}
	}

	private fetchStore(): void {
		this.store
			.pipe(
				select(getUserDetails),
				skipWhile(details => !details),
				take(1)
			)
			.subscribe(userDetails => {
				this.userDetails = userDetails;
			});
	}
}
