import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { AddAdComponent } from './add-ad/add-ad.component';
import { AAARoutingModule } from './AAA-routing.module';
import { AddAdSetComponent } from './add-adset/add-adset.component';
import { CardsModule, TooltipsModule, ButtonsModule, InputsModule, TextBoxesModule, FontsModule, CheckboxesModule, StepperModule } from '@filed-com/filed-lib';
import { AAAEffects } from './state/aaa.effects';
import { EffectsModule } from '@ngrx/effects';
import { CardWithTooltipComponent } from './components/card-with-tooltip/card-with-tooltip.component';
import { CampaignsService } from '../ads-manager/ads-manager-insights/services/campaigns.service';
import { AdSetsService } from '../ads-manager/ads-manager-insights/services/ad-sets.service';
import { AdsService } from '../ads-manager/ads-manager-insights/services/ads.service';
import { ViewsMapperService } from '../ads-manager/ads-manager-insights/services/views-mapper.service';
import { AdsManagerHelperService } from '../ads-manager/ads-manager-insights/services/ads-manager-helper.service';
import { SelectionCountComponent } from './components/selection-count/selection-count.component';
import { AAACreateAdComponent } from './create-ad/create-ad.component';
import { AAANavigationComponentsComponent } from './components/aaanavigation-components/aaanavigation-components.component';
import { QuickNavigationComponent } from './components/quick-navigation/quick-navigation.component';
import { AdNavigationComponent } from './components/ad-navigation/ad-navigation.component';
import { AdPreviewComponent } from './components/ad-preview/ad-preview.component';
import { AdCreationComponent } from './components/ad-creation/ad-creation.component';
import { LayoutComponent } from './layout/layout.component';
import { AAAFooterComponent } from './components/aaafooter/aaafooter.component';
import { reducers } from './state';
import { CreateAdResolver } from './resolvers/create-ad.resolver';
import { AAAAssetsEffects } from './state/aaa-assets/aaa-assets.effects';
import { AAAResolver } from './resolver/aaa.resolver';
import { AdFormatComponent } from './components/ad-format/ad-format.component';
import { PreviewAdComponent } from './preview-ad/preview-ad.component';
import { CollapseCardComponent } from './components/collapse-card/collapse-card.component';
import { AdsEffects } from './state/ad-state/ad-state.effects';
import { AddSelectionComponent } from './components/add-selection/add-selection.component';
import { AdSetResolver } from './resolvers/adset.resolver';
import { ExpansionPanelHelper } from '../shared/expansion-panel/expansion-panel-helper.service';
import { PlacementsComponent } from './components/placements/placements.component';
import { AudienceComponent } from './components/audience/audience.component';
import { Ng5SliderModule } from 'ng5-slider';
import { DevicesComponent } from './components/devices/devices.component';
import { DropdownsModule, TreeViewsModule, PopupsModule } from '@filed-com/filed-lib';
import { OptimistationDeliveryComponent } from './components/optimistation-delivery/optimistation-delivery.component';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { BestPerformingAdsetsComponent } from './components/best-performing-adsets/best-performing-adsets.component';
import { BudgetAndScheduleCardComponent } from './components/budget-and-schedule-card/budget-and-schedule-card.component';
@NgModule({
	declarations: [
		AddAdComponent,
		AddAdSetComponent,
		AdPreviewComponent,
		AdCreationComponent,
		AAACreateAdComponent,
		AdNavigationComponent,
		SelectionCountComponent,
		CardWithTooltipComponent,
		QuickNavigationComponent,
		AAANavigationComponentsComponent,
		OptimistationDeliveryComponent,
		LayoutComponent,
		AAAFooterComponent,
		AdFormatComponent,
		PreviewAdComponent,
		CollapseCardComponent,
		AddSelectionComponent,
		PlacementsComponent,
		AudienceComponent,
		DevicesComponent,
		BestPerformingAdsetsComponent,
		BudgetAndScheduleCardComponent
	],
	imports: [
		CommonModule,
		TooltipsModule,
		ButtonsModule,
		AAARoutingModule,
		CheckboxesModule,
		InputsModule,
		TextBoxesModule,
		TreeViewsModule,
		DropdownsModule,
		SharedModule,
		StoreModule.forFeature('AAA', reducers),
		EffectsModule.forFeature([AAAEffects, AAAAssetsEffects, AdsEffects]),
		FontsModule,
		StepperModule,
		CardsModule,
		PopupsModule,
		Ng5SliderModule
	],
	exports: [
		QuickNavigationComponent,
		AdFormatComponent,
		AdCreationComponent,
		AdPreviewComponent,
		AdNavigationComponent,
		PlacementsComponent,
		AudienceComponent,
		DevicesComponent,
		OptimistationDeliveryComponent,
		BudgetAndScheduleCardComponent
	],
	providers: [
		AAAResolver,
		CreateAdResolver,
		CampaignsService,
		AdSetResolver,
		AdSetsService,
		AdsService,
		ViewsMapperService,
		InterceptorsProvider,
		AdsManagerHelperService,
		ExpansionPanelHelper
	]
})
export class AAAModule {}
