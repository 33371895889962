import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { sleep } from 'src/app/authentication/new-signup/utils/signup-utils';
import { TarrifPlansInterface } from 'src/app/authentication/sign-up/subscribe.interface';
import { RegisterUserDuration, RegisterUserAccountPlan } from 'src/app/authentication/state/authentication.action';
import { BillingAndSubscriptionService } from 'src/app/shared/services/billing-and-subscriptions.service';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { SetUserPlan } from 'src/app/user-management/state/user-management.actions';
import { UserManagementState, userPaymentPlan } from 'src/app/user-management/state/user-management.reducer';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';

@Component({
	selector: 'app-my-plan',
	templateUrl: './my-plan.component.html',
	styleUrls: ['./my-plan.component.scss']
})
export class MyPlanComponent implements OnInit {
	public tarrifPlans: TarrifPlansInterface[];
	public selectedTarrifPlan: any = [];
	public durationControl: FormControl = new FormControl('', [Validators.required]);

	public loading: boolean;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public planDetails: any;

	public duration: string;
	public tarrif: any;
	public paymentCard: any;

	constructor(
		private router: Router,
		private sharedStore: Store<SharedState>,
		private billingService: PaymentServiceApi,
		private store: Store<UserManagementState>,
		private tarrifsService: BillingAndSubscriptionService
	) {}

	ngOnInit(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.loadTarrifPlan();
		this.getAllOffers();
		this.onSelectDuration('month');
		this.getTarrifDetails();
	}
	public loadTarrifPlan(): void {
		this.tarrifPlans = [];
	}

	public getAllOffers(): void {
		this.tarrifsService
			.getBaseOffers()
			.pipe(take(1))
			.subscribe((res: any) => {
				if (res) {
					res.map((tarrif: any, i: number) => {
						this.tarrifPlans.push({
							id: tarrif?.id,
							tag: tarrif?.name,
							price: +tarrif?.fixedPrice,
							amount: tarrif?.fixedPrice,
							tagLine: 'Per month',
							currency: tarrif?.currency,
							header: i == 1 ? 'Recommended' : '',
							features: ['All features', 'Advanced Service', 'Up to 10 million impressions', '150 SignNow contracts'],
							disable: i == 0 ? true : false,
							buttonText: i == 0 ? 'Current Plan' : 'Upgrade Plan'
						});
					});
				}
			});
	}

	public getTarrifDetails(): void {
		this.billingService
			.getUserTarrifPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						details.amount = this.roundFigure(details.amount);
						var dates = details.nextInvoiceDate.split('T')[0].split('-');
						details.nextInvoiceDate = `${dates[2]}.${dates[1]}.${dates[0]}`;
					}
					this.planDetails = details;
				},
				error => {
					console.log(error);
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	private normalizePlane(tarrifId: number): void {
		let index = this.tarrifPlans.findIndex(tarrif => tarrif.id == tarrifId);
		if (index > -1) {
			this.tarrifPlans[index].disable = true;
			this.tarrifPlans[index].buttonText = 'Current Plan';
		}
	}

	public onSelectDuration(duration: string): void {
		for (let ln in this.tarrifPlans) {
			let index = ln;
			if (this.tarrifPlans[ln].price !== 'Free Trial') {
				this.tarrifPlans[ln].amount = duration == 'month' ? this.tarrifPlans[ln].price * 1 : this.tarrifPlans[ln].price * 12;
				this.tarrifPlans[ln].tagLine = duration == 'month' ? 'Per month' : 'Per year';
				this.tarrifPlans[ln].buttonText = +index === 0 && duration == 'month' ? 'Current Plan' : 'Upgrade Plan';
				this.tarrifPlans[ln].disable = +index === 0 && duration == 'month' ? true : false;
			}
		}
		this.durationControl.setValue(duration);
		this.updateStoreData();
	}
	public onSelectTarrifPlan(event: TarrifPlansInterface): void {
		this.selectedTarrifPlan = event;
		this.updateStoreData();
		this.router.navigate(['/user-management/my-plan/payment']);
	}
	public updateStoreData(): void {
		const payload: userPaymentPlan = { duration: this.durationControl.value, tarrif: this.selectedTarrifPlan, paymentCard: [] };
		this.store.dispatch(new SetUserPlan(payload));
	}
}
