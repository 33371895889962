import { PixelStaticStrings } from '../_models/pixel-static-strings';
import { LoadEventsAggregationsFailure, PixelAction, PixelActionsTypes } from './pixel.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EventStaticStrings } from '../_models/event-static-strings';
import { NavLinks } from '../../accounts/models/ad-account.model';
import { PixelsDto } from '../_models/pixels-dto.model';
import { EventDtoInterface } from '../_models/event-dto.interface';
import { PixelEventInsightDto } from '../_models/pixel-event-insight-dto';
import { PixelBreakdown } from '../_models/breakdowns.model';
import { DataInterface, NullDataInterface } from '../../shared/state/state-entities/data-interface.model';
import _ from 'lodash';

export interface PixelState {
	navLinks: NavLinks[];
	pixelMessages: PixelStaticStrings;
	eventMessages: EventStaticStrings;
	pixelsWithTimeRange: DataInterface<PixelsDto[]>;
	pixelAggregations: DataInterface<PixelBreakdown[]>;
	customEventsAggregations: DataInterface<PixelBreakdown[]>;
	pixelEvents: DataInterface<EventDtoInterface[]>;
	pixelEventInsights: DataInterface<PixelEventInsightDto[]>;
	breakdownsLoaded: boolean;
}

export const initialPixelState: PixelState = {
	navLinks: [
		{
			label: 'Pixels',
			link: '/pixel/pixelTab',
			index: 0
		},
		{
			label: 'Events',
			link: '/pixel/eventTab',
			index: 1
		}
	],
	pixelMessages: new PixelStaticStrings(),
	eventMessages: new EventStaticStrings(),
	pixelsWithTimeRange: _.cloneDeep(NullDataInterface),
	pixelAggregations: _.cloneDeep(NullDataInterface),
	customEventsAggregations: _.cloneDeep(NullDataInterface),
	pixelEvents: _.cloneDeep(NullDataInterface),
	pixelEventInsights: _.cloneDeep(NullDataInterface),
	breakdownsLoaded: false
};

const getPixelState = createFeatureSelector<PixelState>('pixel');

export const getNavLinks = createSelector(getPixelState, state => state.navLinks);
export const getPixelMessages = createSelector(getPixelState, state => state.pixelMessages);
export const getEventMessages = createSelector(getPixelState, state => state.eventMessages);
export const getPixels = createSelector(getPixelState, state => state.pixelsWithTimeRange.data);
export const arePixelsLoading = createSelector(getPixelState, state => state.pixelsWithTimeRange.isLoading);
export const getPixelsAggregations = createSelector(getPixelState, state => state.pixelAggregations.data);
export const getCustomEventsAggregations = createSelector(getPixelState, state => state.customEventsAggregations.data);
export const areBreakdownsLoaded = createSelector(getPixelState, state => state.breakdownsLoaded);
export const getEvents = createSelector(getPixelState, state => state.pixelEvents.data);
export const getEventInsights = createSelector(getPixelState, state => state.pixelEventInsights.data);

export function pixelReducer(state: PixelState = initialPixelState, action: PixelAction): PixelState {
	switch (action.type) {
		case PixelActionsTypes.LoadPixelsWIthTimeRange: {
			return {
				...state,
				pixelsWithTimeRange: {
					...state.pixelsWithTimeRange,
					isLoaded: true,
					isLoading: true
				}
			};
		}
		case PixelActionsTypes.LoadPixelsSuccess: {
			return {
				...state,
				pixelsWithTimeRange: {
					...state.pixelsWithTimeRange,
					data: action.payload,
					isLoading: false
				}
			};
		}
		case PixelActionsTypes.LoadPixelsFailure: {
			return {
				...state,
				pixelsWithTimeRange: {
					...state.pixelsWithTimeRange,
					errorCode: action.payload,
					isLoading: false
				}
			};
		}

		case PixelActionsTypes.LoadEventsByAdAccountId:
		case PixelActionsTypes.LoadEventsByPixelId: {
			return {
				...state,
				pixelEvents: {
					...state.pixelEvents,
					isLoaded: true
				}
			};
		}
		case PixelActionsTypes.LoadEventsSuccess: {
			return {
				...state,
				pixelEvents: {
					...state.pixelEvents,
					data: action.payload
				}
			};
		}
		case PixelActionsTypes.LoadEventsFailure: {
			return {
				...state,
				pixelEvents: {
					...state.pixelEvents,
					errorCode: action.payload
				}
			};
		}

		case PixelActionsTypes.LoadPixelAggregations: {
			return {
				...state,
				pixelAggregations: {
					...state.pixelAggregations,
					isLoaded: true
				}
			};
		}
		case PixelActionsTypes.LoadPixelAggregationsSuccess: {
			const otherBreakdownLoaded = !!state.customEventsAggregations;
			return {
				...state,
				pixelAggregations: {
					...state.pixelAggregations,
					data: action.payload
				},
				breakdownsLoaded: otherBreakdownLoaded
			};
		}
		case PixelActionsTypes.LoadPixelAggregationsFailure: {
			return {
				...state,
				pixelAggregations: {
					...state.pixelAggregations,
					errorCode: action.payload
				}
			};
		}

		case PixelActionsTypes.LoadEventsAggregations: {
			return {
				...state,
				customEventsAggregations: {
					...state.customEventsAggregations,
					isLoaded: true
				}
			};
		}
		case PixelActionsTypes.LoadEventsAggregationsSuccess: {
			const otherBreakdownLoaded = !!state.pixelAggregations;
			return {
				...state,
				customEventsAggregations: {
					...state.customEventsAggregations,
					data: action.payload
				},
				breakdownsLoaded: otherBreakdownLoaded
			};
		}
		case PixelActionsTypes.LoadEventsAggregationsFailure: {
			return {
				...state,
				customEventsAggregations: {
					...state.customEventsAggregations,
					errorCode: action.payload
				}
			};
		}

		case PixelActionsTypes.LoadEventInsights: {
			return {
				...state,
				pixelEventInsights: {
					...state.pixelEventInsights,
					isLoaded: true
				}
			};
		}
		case PixelActionsTypes.LoadEventInsightsSuccess: {
			return {
				...state,
				pixelEventInsights: {
					...state.pixelEventInsights,
					data: action.payload
				}
			};
		}
		case PixelActionsTypes.LoadEventInsightsFailure: {
			return {
				...state,
				pixelEventInsights: {
					...state.pixelEventInsights,
					errorCode: action.payload
				}
			};
		}
		default:
			return state;
	}
}
