import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColDef, ColumnApi, GridApi, IServerSideGetRowsParams, PaginationChangedEvent, RowNode } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UpdateAAAAdInterface } from 'src/app/AAA/models/update-aaa-ads.interface';
import { UpdateSelectedAAAAdIds, UpdateSelectedAAAAds } from 'src/app/AAA/state/aaa.actions';
import { AAAState } from 'src/app/AAA/state/aaa.reducer';
import { AdsManagerToggleResponseInterface } from 'src/app/ads-manager/ads-manager-insights/models/ads-manager-toggle-response.interface';
import { SelectedAdAccountModel } from 'src/app/shared/state/state-entities/cached-ad-accounts.model';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { AgGridViewInterface } from '../../../shared/master-table/models/ag-grid-view.interface';
import { MasterTableService } from '../../../shared/master-table/models/master-table.interface';
import { AddColumnsToReqService } from '../../../shared/master-table/services/add-columns-to-req.service';
import { AgGridRequest } from '../../../shared/models/ag-grid-request';
import { SharedState } from '../../../shared/state/shared.reducer';
import { BaseApiUrl } from '../../../_services/base-api-urls';
import { UpdateSelectedAds } from '../../state/ads-manager.actions';
import { AdsManagerAgGridServiceInterface } from '../models/ads-manager-ag-grid-service.interface';
import { AdsManagerStatusEnum } from '../models/ads-manager-status.enum';
import { AdsResponseInterface } from '../models/ads-response.interface';
import { AdsInterface } from '../models/ads.interface';
import { ColumnFilterInterface } from '../models/column-filter.interface';
import { TimeRangeInterface } from '../models/time-range.interface';
import { UpdateAdInterface } from '../models/update-ad.interface';
import { UpdateAdvertisingStructureRequestInterface } from '../models/update-advertising-structure-request.interface';
import { ViewsColumnsEnum } from '../models/views-columns.enum';
import { ViewsConfigurationValuesEnum } from '../models/views-configuration-values.enum';
import { AdsManagerBaseService } from './ads-manager-base.service';

@Injectable()
export class AdsService extends AdsManagerBaseService implements MasterTableService, AdsManagerAgGridServiceInterface {
	public campaignIdName = ViewsColumnsEnum.CampaignId;
	public isSelectionToggledProgramatically = false;
	public isCheckboxDeactivated = true;
	public triggerBundleUpdateSubject = new Subject<void>();
	public resetSubject = new Subject<void>();
	public bundleUpdateArray: Array<UpdateAdInterface> = [];
	public adSetIdName = ViewsColumnsEnum.AdSetId;
	public selectedAdsIds: string[] = [];
	public lastNextPageCursor: string = null;
	public hasDelivery: boolean = false;
	public gridApi: GridApi;
	public columnApi: ColumnApi;
	public selectedCampaignIds: string[];
	public selectedAdSetsIds: string[];
	public timeRange: TimeRangeInterface;
	public selectedAccount: string;
	public selectedAdAccount: SelectedAdAccountModel;
	public totalsRow: Array<Object>;
	public filterName: string = '';
	public pinnedBottomStore: { [key: string]: Array<Object> } = {};
	public innerStoreAction = false;
	public previousChannel: number;

	private triggerCheckCheckboxState = new Subject<void>();
	constructor(http: HttpClient, private store: Store<SharedState>, private addColumnsService: AddColumnsToReqService, private aaaStore: Store<AAAState>) {
		super(http);
	}
	public getRows(request?: AgGridRequest): Observable<AdsInterface[]> {
		return this.getAdsData(request);
	}

	public getAdsData(request: AgGridRequest): Observable<AdsInterface[]> {
		if (request.startRow === 0) {
			this.pinnedBottomStore = {};
		}
		let arrSize: number = Object.keys(this.pinnedBottomStore).length;
		let url: string;
		if (this.selectedAdAccount?.sourceChannel === SourceChannel.Facebook) {
			url = `${BaseApiUrl.FacebookDataPipeline}ag-grid-insights/ad`;
		} else {
			request.googleAccountId = '9959328642';
			request.googleManagerId = '5428845364';
			url = `${BaseApiUrl.GoogleDataPipeline}ag-grid-insights/ad_group_ad`;
		}
		return this.http.post<AdsResponseInterface>(url, request).pipe(
			map((ads: AdsResponseInterface) => {
				this.pinnedBottomStore[arrSize] = ads.summary;
				this.totalsRow = ads.summary;
				this.lastNextPageCursor = ads.nextPageCursor;
				this.isCheckboxDeactivated = false;
				return ads.data;
			})
		);
	}

	public toggleStructureStatus(adId: string, updateStatus: AdsManagerStatusEnum): Observable<AdsManagerToggleResponseInterface> {
		const request: UpdateAdvertisingStructureRequestInterface = {
			details: {
				status: updateStatus
			}
		};
		return this.http.put<AdsManagerToggleResponseInterface>(`${BaseApiUrl.FacebookDataPipeline}ad/${adId}`, request);
	}

	public deleteStructure(adId: string): Observable<void> {
		return this.http.delete<void>(`${BaseApiUrl.FacebookDataPipeline}ad/${adId}`);
	}

	public turnOffHasDelivery(): void {
		this.hasDelivery = false;
	}

	public turnOnHasDelivery(): void {
		this.hasDelivery = true;
	}

	public onPaginationChanged(paginationEvent: PaginationChangedEvent): void {
		this.triggerCheckCheckboxState.next();
	}

	public checkIfNotLastRequest(): boolean {
		return this.lastNextPageCursor !== null;
	}

	public getViews(): Observable<AgGridViewInterface[]> {
		let url: string;
		if (this.selectedAdAccount?.sourceChannel === SourceChannel.Facebook) {
			url = `${BaseApiUrl.FacebookDataPipeline}ag-grid-views/ad`;
		} else {
			url = `${BaseApiUrl.GoogleDataPipeline}ag-grid-views/ad_group_ad`;
		}
		return this.http.get<AgGridViewInterface[]>(`${url}`);
	}

	public updateSelectedRows(toggledRow: UpdateAdInterface | UpdateAAAAdInterface): void {
		this.triggerCheckCheckboxState.next();
		if (!this.isSelectionToggledProgramatically) {
			if (!this.innerStoreAction) {
				this.store.dispatch(new UpdateSelectedAds(toggledRow as UpdateAdInterface));
			} else {
				this.aaaStore.dispatch(new UpdateSelectedAAAAds(toggledRow as UpdateAAAAdInterface));
			}
		} else {
			this.bundleUpdateArray.push(toggledRow as UpdateAdInterface);
		}
	}

	public deselectAll(): void {
		if (this.gridApi) {
			this.isSelectionToggledProgramatically = true;
			this.gridApi.getSelectedNodes().forEach((row: RowNode) => {
				const adRow = row.data as AdsInterface;
				this.bundleUpdateArray.push({
					selectedRow: adRow,
					isChosen: false
				});
				row.setSelected(false);
			});
			setTimeout(() => {
				this.isSelectionToggledProgramatically = false;
				this.bundleUpdateArray = [];
				this.store.dispatch(new UpdateSelectedAds(null));
			});
		}
	}

	public triggerBundleUpdate(): Observable<void> {
		return this.triggerBundleUpdateSubject.asObservable();
	}

	public resetRequest(): void {
		this.lastNextPageCursor = null;
		this.resetSubject.next();
	}

	public listenToRefreshData(): Observable<void> {
		return this.resetSubject.asObservable();
	}

	public checkBoxStateObservable(): Observable<void> {
		return this.triggerCheckCheckboxState.asObservable();
	}

	public mapRequest(params: IServerSideGetRowsParams, pageSize: number): AgGridRequest {
		const request = this.addColumnsService.addColumns(params);
		this.isCheckboxDeactivated = true;
		if (this.selectedCampaignIds.length !== 0) {
			const campaignIdFilter: ColumnFilterInterface = {
				filter: this.selectedCampaignIds,
				filterTo: null,
				filterType: ViewsColumnsEnum.CampaignId,
				type: ViewsConfigurationValuesEnum.InValues
			};
			request.filterModel[ViewsColumnsEnum.CampaignId] = campaignIdFilter;
		}

		if (this.selectedAdSetsIds.length !== 0) {
			const adSetsIdFilter: ColumnFilterInterface = {
				filter: this.selectedAdSetsIds,
				filterTo: null,
				filterType: ViewsColumnsEnum.AdSetId,
				type: ViewsConfigurationValuesEnum.InValues
			};
			request.filterModel[ViewsColumnsEnum.AdSetId] = adSetsIdFilter;
		}
		if (this.filterName.length) {
			request.filterModel['ad_name'] = { filterType: 'text', type: 'contains', filter: this.filterName };
		}
		request.timeRange = this.timeRange;
		request.facebookAccountId = this.selectedAccount;
		request.pageSize = pageSize;
		request.nextPageCursor = this.lastNextPageCursor;
		request.hasDelivery = this.hasDelivery;
		return request;
	}

	public hideColumns(columns: ColDef[], columnAPI: ColumnApi): void {
		for (const column of columns) {
			if (column.suppressColumnsToolPanel) {
				columnAPI.setColumnVisible(column.colId, false);
			}
		}
	}

	public checkSelectedRows(gridApi: GridApi): void {
		gridApi.forEachNode(rowNode => {
			const adData = rowNode.data as AdsInterface;
			if (this.selectedAdsIds?.indexOf(adData?.ad_id) > -1 && !rowNode.isSelected()) {
				rowNode.setSelected(true);
			} else if (this.selectedAdsIds?.indexOf(adData?.ad_id) === -1 && rowNode.isSelected()) {
				rowNode.setSelected(false);
			}
		});
		this.triggerCheckCheckboxState.next();
	}
}
