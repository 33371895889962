<div class="panel">
	<div fxLayout class="panel-header">
		<div fxFlex="70">{{ title }}</div>
		<div fxFlex="30">
			<kendo-icon name="trash" size="medium"></kendo-icon>
			<kendo-icon name="drag-drop" size="medium"></kendo-icon>
			<kendo-icon name="arro-up" size="medium"></kendo-icon>
		</div>
	</div>
	<div fxLayout class="panel-body">
		<div>
			<p>Want to get started?</p>
			<ul>
				<li>Yes</li>
				<li>Not now -> Disqualification</li>
			</ul>
		</div>
		<div>
			<app-input-with-emoji></app-input-with-emoji>
		</div>
	</div>
</div>
