<div class="main-container">
	<div class="search">
		<tb06 [disabled]="true" [control]="searchControl" [icon]="'fa-search'" [placeHolderTextBox]="'Search the Knowledge Base'"></tb06>
	</div>
	<div class="search-option">
		<h6 *ngIf="!kbService.knowledgebaseShowFrame$.value">Results {{ kbArticles?.length }}</h6>
	</div>
	<app-knowledge-search-result *ngIf="!kbService.knowledgebaseShowFrame$.value" [articles]="kbArticles"></app-knowledge-search-result>
	<app-knowledge-frame *ngIf="kbService.knowledgebaseShowFrame$.value"></app-knowledge-frame>
</div>
