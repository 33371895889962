<div class="strength">
	<ul class="strengthBar">
		<li class="point">
			<div class="bar" [style.background-color]="bar0"></div>
			<div class="title">Weak</div>
		</li>
		<li class="point">
			<div class="bar" [style.background-color]="bar2"></div>
			<div class="title">Normal</div>
		</li>
		<li class="point">
			<div class="bar" [style.background-color]="bar3"></div>
			<div class="title">Strong</div>
		</li>
	</ul>
	<br />
</div>
