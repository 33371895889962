<app-expansion-panel-sc (click)="onExpansionClick.emit()" [isSelected]="isPanelSelected" [expansionTitle]="'Targeting'" [isExpandable]="false">
	<div expansion-content class="tc-content">
		<span class="title {{ isAppInstall ? 'd-none' : '' }}">Audience</span>
		<mat-radio-group class="tc-radio-group {{ isAppInstall ? 'd-none' : '' }}" [formControl]="formGroup.get('targetingType')" name="targeting">
			<mat-radio-button
				class="tc-radio-button"
				(click)="onTargetTypeClick(targetingType.NewAudience)"
				[value]="targetingType.NewAudience"
				name="targeting"
			>
				<span>Create new</span>
			</mat-radio-button>
			<mat-radio-button
				*ngIf="!isSpecialAddCategorySelected"
				(click)="onTargetTypeClick(targetingType.SavedAudience)"
				[value]="targetingType.SavedAudience"
				name="targeting"
			>
				<span>Saved audience</span>
			</mat-radio-button>
		</mat-radio-group>
		<div *ngIf="!isAppInstall">
			<!-- Saved Audience -->
			<span class="tc-subtitle-small" *ngIf="formGroup.get('targetingType').value === targetingType.SavedAudience && !isSpecialAddCategorySelected">
				Select a saved audience
			</span>
			<div class="tc-saved-audience" *ngIf="formGroup.get('targetingType').value === targetingType.SavedAudience && !isSpecialAddCategorySelected">
				<app-live-search-dropdown
					[dropdownWidth]="21"
					[inputControl]="formGroup.get('savedAudience')"
					[dropdownData]="savedAudiences"
					[selectedData]="selectedSavedAudiences"
					[columns]="audienceColumns"
					[singleSelection]="true"
					[inputLabel]="'Search for saved audience'"
					[inputPlaceholder]="'My audience'"
					(selectionChange)="onDropdownDataChange($event, 'selectedSavedAudiences')"
				></app-live-search-dropdown>
			</div>

			<!--		New Audience-->
			<span class="tc-subtitle-small">Include custom audience</span>
			<div class="tc-include-audience">
				<app-live-search-dropdown
					[dropdownWidth]="21"
					[dropdownData]="customAudiences"
					[selectedData]="selectedIncludeCustomAudiences"
					[columns]="customAudienceColumns"
					[inputLabel]="'Create Custom audience'"
					[inputPlaceholder]="'My audience'"
					(selectionChange)="onDropdownDataChange($event, 'selectedIncludeCustomAudiences')"
				></app-live-search-dropdown>
			</div>

			<span class="tc-subtitle-small">Exclude custom audience</span>
			<div class="tc-exclude-audience">
				<app-live-search-dropdown
					[dropdownWidth]="21"
					[dropdownData]="customAudiences"
					[selectedData]="selectedExcludeCustomAudiences"
					[columns]="customAudienceColumns"
					[inputLabel]="'Choose Custom audience'"
					[inputPlaceholder]="'My audience'"
					(selectionChange)="onDropdownDataChange($event, 'selectedExcludeCustomAudiences')"
				></app-live-search-dropdown>
			</div>

			<span class="tc-subtitle">Gender</span>
			<div class="tc-gender-group">
				<span
					class="tc-gender"
					(click)="onGenderClick(genders.All)"
					[ngClass]="{ 'tc-gender-selected': gender === genders.All, 'tc-gender-hidden-border': gender <= genders.Women }"
				>
					All
				</span>

				<span
					class="tc-gender"
					(click)="onGenderClick(genders.Women)"
					[ngClass]="{
						'tc-gender-selected': gender === genders.Women,
						'tc-gender-hidden-border': gender >= genders.Women,
						'tc-gender-not-allowed': isSpecialAddCategorySelected
					}"
				>
					Women
				</span>

				<span
					class="tc-gender tc-gender-hidden-border"
					(click)="onGenderClick(genders.Men)"
					[ngClass]="{ 'tc-gender-selected': gender === genders.Men, 'tc-gender-not-allowed': isSpecialAddCategorySelected }"
				>
					Men
				</span>
			</div>

			<span class="tc-age-subtitle">Age</span>
			<div class="tc-age">
				<ng5-slider class="tc-slider" (userChangeEnd)="onAgeChange()" [(highValue)]="maxAge" [(value)]="minAge" [options]="options"></ng5-slider>
			</div>

			<span class="tc-subtitle tc-extra">
				Locations
				<span class="text-danger">*</span>
			</span>
			<div class="tc-locations">
				<app-live-search-dropdown
					(focusout)="validateLocation()"
					[inputControl]="formGroup.get('location')"
					[dropdownWidth]="21"
					[dropdownData]="locations"
					[selectedData]="selectedLocations"
					[selfFilter]="false"
					[columns]="locationColumns"
					[inputLabel]="'Search location'"
					[inputPlaceholder]="'London'"
					(selectionChange)="onDropdownDataChange($event, 'selectedLocations')"
				></app-live-search-dropdown>
			</div>
			<span class="tc-subtitle">Language</span>
			<div class="tc-language">
				<app-live-search-dropdown
					[inputControl]="formGroup.get('languages')"
					[dropdownWidth]="21"
					[dropdownData]="languages"
					[selectedData]="selectedLanguages"
					[columns]="languagesColumns"
					[inputLabel]="'Search languages'"
					[inputPlaceholder]="'English'"
					(selectionChange)="onDropdownDataChange($event, 'selectedLanguages')"
				></app-live-search-dropdown>
			</div>
			<div class="tc-subtitle"><span>Detailed Targeting</span></div>
			<span class="tc-subtitle-small">Include people that match</span>
			<div class="tc-interests">
				<app-live-search-dropdown
					[inputControl]="formGroup.get('includedInterests')"
					[dropdownWidth]="21"
					[dropdownData]="interests"
					[selectedData]="selectedInterests"
					[selfFilter]="false"
					[columns]="interestColumns"
					[inputLabel]="'Add demographics, interests or behaviours'"
					[inputPlaceholder]="'Fishing'"
					(selectionChange)="onDropdownDataChange($event, 'selectedInterests')"
				></app-live-search-dropdown>
			</div>
			<span class="tc-subtitle-small"></span>
			<div class="tc-narrow" *ngIf="!isSpecialAddCategorySelected">
				<div class="tc-narrow-btn-spacing">
					<btn04 *ngIf="!showNarrowInterests" (onClick)="onShowNarrowInterestsClick()">Narrow Audience</btn04>
				</div>

				<app-live-search-dropdown
					*ngIf="showNarrowInterests"
					[inputControl]="formGroup.get('narrowInterests')"
					[dropdownWidth]="21"
					[dropdownData]="narrowInterests"
					[selectedData]="selectedNarrowedInterest"
					[selfFilter]="false"
					[columns]="interestColumns"
					[inputLabel]="'Narrow demographics, interests or behaviours'"
					[inputPlaceholder]="'Fishing'"
					(selectionChange)="onDropdownDataChange($event, 'selectedNarrowedInterest')"
				></app-live-search-dropdown>
			</div>
			<span class="tc-exclude-extra"></span>

			<span class="tc-subtitle-small" *ngIf="showExcludeInterests">Exclude people that match</span>
			<div class="tc=exclude" *ngIf="!isSpecialAddCategorySelected">
				<btn04 *ngIf="!showExcludeInterests" (onClick)="onShowExcludedInterestsClick()">Exclude Audience</btn04>
				<app-live-search-dropdown
					*ngIf="showExcludeInterests"
					[inputControl]="formGroup.get('excludedInterests')"
					[dropdownWidth]="21"
					[dropdownData]="excludedInterests"
					[selectedData]="selectedExcludedInterest"
					[selfFilter]="false"
					[columns]="interestColumns"
					[inputLabel]="'Exclude demographics, interests or behaviours'"
					[inputPlaceholder]="'Fishing'"
					(selectionChange)="onDropdownDataChange($event, 'selectedExcludedInterest')"
				></app-live-search-dropdown>
			</div>

			<hr />

			<div class="tc-button-group">
				<btn01
					class="tc-button"
					*ngIf="formGroup.get('targetingType').value === targetingType.SavedAudience"
					[disabled]="!selectedSavedAudiences.length"
					(onClick)="audienceNamePopUp(buttonNameEnum.Save)"
				>
					{{ buttonNameEnum.Save }}
				</btn01>
				<btn01
					*ngIf="
						formGroup.get('targetingType').value === targetingType.NewAudience ||
						formGroup.get('targetingType').value === targetingType.SavedAudience
					"
					(onClick)="audienceNamePopUp(buttonNameEnum.SaveAsNew)"
				>
					{{ buttonText }}
				</btn01>
			</div>
		</div>
		<ng-container *ngIf="isAppInstall">
			<span class="tc-subtitle">
				Locations
				<span class="text-danger">*</span>
			</span>
			<div class="tc-locations">
				<app-live-search-dropdown
					(focusout)="validateLocation()"
					[inputControl]="formGroup.get('location')"
					[dropdownWidth]="21"
					[dropdownData]="locations"
					[selectedData]="selectedLocations"
					[selfFilter]="false"
					[columns]="locationColumns"
					[inputLabel]="'Search location'"
					[inputPlaceholder]="'London'"
					(selectionChange)="onDropdownDataChange($event, 'selectedLocations')"
				></app-live-search-dropdown>
			</div>
			<span class="tc-subtitle">Language</span>
			<div class="tc-language">
				<app-live-search-dropdown
					[inputControl]="formGroup.get('languages')"
					[dropdownWidth]="21"
					[dropdownData]="languages"
					[selectedData]="selectedLanguages"
					[columns]="languagesColumns"
					[inputLabel]="'Search languages'"
					[inputPlaceholder]="'English'"
					(selectionChange)="onDropdownDataChange($event, 'selectedLanguages')"
				></app-live-search-dropdown>
			</div>
		</ng-container>
	</div>
</app-expansion-panel-sc>
