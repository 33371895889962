import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
	selector: 'app-offer-accepted',
	templateUrl: './offer-accepted.component.html',
	styleUrls: ['./offer-accepted.component.scss']
})
export class OfferAcceptedComponent implements OnInit {
	public loading: boolean = false;
	constructor(private _location: Location, private router: Router) {}

	ngOnInit(): void {}

	public backClicked() {
		this._location.back();
	}

	public onNext() {
		this.router.navigate(['accounts']);
	}
}
