import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[appTruncate]'
})
export class TruncateDirective implements AfterViewInit {
	constructor(public el: ElementRef) {}
	@Input() id = '';

	ngAfterViewInit(): void {
		this.ellipsizeTextBox();
	}

	public ellipsizeTextBox(): void {
		var wordArray = this.el.nativeElement.firstChild.textContent.split(' ');
		let height = this.el.nativeElement.offsetHeight;
		while (this.el.nativeElement.scrollHeight > height) {
			wordArray.pop();
			this.el.nativeElement.innerHTML = wordArray.join(' ') + '...';
		}
	}
}
