import { Injectable } from '@angular/core';
import { MasterTableService } from '../../shared/master-table/models/master-table.interface';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { AgGridColDefInterface } from '../../shared/master-table/models/ag-grid-col-def.interface';
import { AccountsColumnFieldsEnum } from '../enums/accounts-column-fields.enum';

@Injectable()
export class AccountsMasterTableService implements MasterTableService {
	public clickAccountNameSubject = new Subject<string>();
	public clickShowOptimizationsSubject = new Subject<string>();
	public columnApi: ColumnApi;
	public gridApi: GridApi;

	constructor() {}

	public hideColumns(columns: AgGridColDefInterface[], columnAPI: ColumnApi): void {
		columns.forEach(column => {
			if (column.colId === AccountsColumnFieldsEnum.BusinessId || column.colId === AccountsColumnFieldsEnum.AccountId) {
				columnAPI.setColumnVisible(column.colId, false);
			}
		});
	}

	public clickAccountNameObservable(): Observable<string> {
		return this.clickAccountNameSubject.asObservable();
	}

	public clickShowOptimizationsObservable(): Observable<string> {
		return this.clickShowOptimizationsSubject.asObservable();
	}
}
