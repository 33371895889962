import { Component, Injector, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { NewSignupWelcomePopupComponent } from '../new-signup-welcome-popup/new-signup-welcome-popup.component';

@Component({
	selector: 'app-payment-lunch',
	templateUrl: './lunch.component.html',
	styleUrls: ['./lunch.component.scss']
})
export class LunchComponents implements OnInit, OnDestroy {
	public checked: boolean;

	public decodeJwtToken: UserDetailsInterface;

	constructor(private router: Router, private dialog: MatDialog) {}

	ngOnInit(): void {
		this.checked = false;
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
	}
	public ngOnDestroy(): void {}
	public openTop(): void {
		const dialogRef = this.dialog.open(NewSignupWelcomePopupComponent, {
			width: '80%',
			height: '90%',
			data: {}
		});
		dialogRef.afterClosed().subscribe((result: any) => {
			if (result.data === 'dexter') {
				this.router.navigate(['/optimize/dashboard']);
			} else if (result.data === 'marketing') {
				this.router.navigate(['/ads-manager/insights']);
			}
		});
	}
	ngAfterContentInit(): void {
		if (!this.checked) {
			this.checked = false;
			setTimeout(() => {
				this.router.navigate(['/accounts']);
				//this.openTop();
			}, 4000);
		}
	}
}
