import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CampaignSmartCreateState, getCampaign, getStepTwoDetails } from '../state/smart-create.reducer';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { CampaignStepsEnum } from '../models/campaign-steps.enum';
import { AppState } from '../../state/app.state';

@Injectable({
	providedIn: 'root'
})
export class StepThreeGuard implements CanActivate {
	constructor(public store: Store<CampaignSmartCreateState>, public activateRouter: ActivatedRoute, public toasterService: ToastNotificationService) {}
	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (next.queryParams?.lastUserStep > CampaignStepsEnum.Two) {
			return true;
		}
		return this.store.pipe(
			select(getCampaign),
			take(1),
			map(campaign => {
				if (!campaign) {
					return true;
				} else if (
					!campaign.stepTwoDetailsAsJson.adSetName ||
					!campaign.stepTwoDetailsAsJson?.placements.length ||
					!campaign.stepTwoDetailsAsJson.facebookPageId
				) {
					this.toasterService.sendErrorToast('Step two is invalid/incomplete!');
					return false;
				}

				return true;
			})
		);
	}
}
