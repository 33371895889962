import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseOffersModel } from '../../back-office/models/base-offers.model';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { AllCountriesInterface } from '../../back-office/models/all-countries.interface';
import { AllCurrenciesInterface } from '../../back-office/models/all-currencies.interface';
import { OfferInterface } from '../../back-office/models/base-offers.interface';
import { FreemiumOffersInterface } from '../../back-office/models/freemium-offers.interface';
import { ChangeOfferInterface } from '../../back-office/models/change-offer.interface';
import { BillingAddressUpdate } from 'src/app/user-management/shared/billing-and-payment/billing-information-update.models';
import { BillingAddress } from '../../user-management/shared/billing-and-payment/billing-and-payment.models';
import { DiscountDurationStringsObject } from '../../back-office/models/discount-duration-strings.object';
import { map } from 'rxjs/operators/map';
import { IKBSearch, IKBSearchResult, IKBSearchResultData, IKBSubmitTicketResponse, ITicketResponse } from '../models/knowledgebase.interface';

@Injectable()
export class KnowledgebaseService {
	public knowledgebaseHomeVisited$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseHome$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	public knowledgebaseShowView$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseShowSub$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseShowFrame$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public knowledgebaseShowTickets$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public searchValue$: BehaviorSubject<string> = new BehaviorSubject('');
	public searchQuery$: BehaviorSubject<string> = new BehaviorSubject('');
	public articleDataValue$: BehaviorSubject<IKBSearchResultData[]> = new BehaviorSubject([]);
	public searchDataValue$: BehaviorSubject<any[]> = new BehaviorSubject([]);
	public frameIndexValue$: BehaviorSubject<number> = new BehaviorSubject(0);

	constructor(protected http: HttpClient) {}

	public searchKnowledgebase(search: string, type: string): Observable<IKBSearchResult[]> {
		return this.http.get<IKBSearchResult[]>(`${BaseApiUrl.FiledSupport}knowledge-base/${type}/${search}`);
	}

	public submitTicket(data: FormData): Observable<IKBSubmitTicketResponse> {
		return this.http.post<IKBSubmitTicketResponse>(`${BaseApiUrl.FiledSupport}ticket/create`, data);
	}

	public getUserTickets(userEmail: string): Observable<ITicketResponse[]> {
		return this.http.get<ITicketResponse[]>(`${BaseApiUrl.FiledSupport}ticket/search/${userEmail}`);
	}
}
