import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { delay, take, takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { IChannelRequest, IntegrationInterface, IntegrationTypeEnum } from '../../models/integration.models';
import { EcomerceSource } from 'src/app/catalog/model/eccomerce-source.enum';
import { GoogleService } from 'src/app/_services/google/google.service';
import { fromEvent, Observable, Subject } from 'rxjs';
import { IntegrationHelper } from '../../services/integration-helper';
import { StorageKey } from 'src/app/_models/local-storage-key';

@Component({
	selector: 'integration-connect-popup',
	templateUrl: './integration-connect-popup.component.html',
	styleUrls: ['./integration-connect-popup.component.scss']
})
export class IntegrationConnectPopupComponent implements OnInit {
	public loaderStatus = new EventEmitter<boolean>();
	public dialogData: any = {};
	authorizationCode: string;
	oAuthToken: string;
	oAuthVerifier: string;

	private windowHandle: Window;
	private intervalId: any = null;
	private loopCount = 4000;
	private intervalLength = 100;
	public channel: IntegrationInterface;
	public redirectUrl: string;
	private unsubscriber$: Subject<void> = new Subject<void>();
	constructor(
		private router: Router,
		private dialogRef: MatDialogRef<IntegrationConnectPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private productCatalogStore: Store<any>,
		private backofficeService: BackOfficeService,
		private integrationHelper: IntegrationHelper,
		private googleService?: GoogleService
	) {}
	ngOnInit(): void {
		this.channel = this.data.allData;
		this.redirectUrl = this.data.redirectUrl;
	}
	public connectToMarketing(source: string): void {
		if (source === 'google-normal') {
			this.googleService
				.connectToGoogleUserAccount(`${window.location.origin}/#/finish-connection`)
				.pipe(take(1))
				.subscribe(res => {
					this.doAuthorization(res, '', false);
				});
		}
	}
	public closeDialog(status: boolean): void {
		if (status) {
			switch (this.channel?.integrationType) {
				case IntegrationTypeEnum.Marketing:
					this.connectToMarketing(this.channel.source);
					break;
				case IntegrationTypeEnum.FeedManagement:
					let installRequest: IChannelRequest = {
						redirect_url: this.redirectUrl
					};

					this.backofficeService
						.integrationInstall(this.channel.source, installRequest)
						.pipe(take(1))
						.subscribe(
							res => {
								if (res?.status) {
									// this.getMapper();
								} else {
									this.doAuthorization(res.url, '', false);
								}
							},
							error => {}
						);
					break;
				default:
					break;
			}
		}

		this.dialogRef.close(status);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	public doAuthorization(url: string, socialMediaProvider: string, isRegisterAction: boolean): void {
		if (
			this.channel.source.toLowerCase() === EcomerceSource.Shopify.toLowerCase() ||
			this.channel.source.toLowerCase() === EcomerceSource.BigCommerce.toLowerCase()
		) {
			window.open(url, '_blank').focus();
		} else {
			let loopCount = this.loopCount;

			this.windowHandle = this.createOauthWindow(url, 'OAuth login');

			this.intervalId = window.setInterval(() => {
				if (loopCount-- < 0) {
					window.clearInterval(this.intervalId);
					this.closeWindow();
				} else if (this.windowHandle?.location?.href === undefined) {
					window.clearInterval(this.intervalId);
					this.loaderStatus.emit(false);
				} else if (this.windowHandle.location.href.indexOf('#/catalog/saved') > -1) {
					window.clearInterval(this.intervalId);
					this.closeWindow();
				} else if (this.windowHandle.location.href.indexOf('#/finish-connection') > -1) {
					window.clearInterval(this.intervalId);
					this.checkInstalledGoogle();

					this.closeWindow();
				} else {
					let href: string;
					try {
						href = this.windowHandle.location.href;
					} catch (e) {}
					if (href != null) {
						const getQueryString = function (field: any, url: string) {
							const windowLocationUrl = url ? url : href;
							const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
							const string = reg.exec(windowLocationUrl);
							return string ? string[1] : null;
						};

						if (href.match('code')) {
							window.clearInterval(this.intervalId);
							this.authorizationCode = getQueryString('code', href);
							this.closeWindow();
							if (isRegisterAction) {
							} else {
							}
						} else if (href.match('oauth_token')) {
							window.clearInterval(this.intervalId);
							this.oAuthToken = getQueryString('oauth_token', href);
							this.oAuthVerifier = getQueryString('oauth_verifier', href);
							this.closeWindow();
							if (isRegisterAction) {
							} else {
							}
						}
					}
				}
			}, this.intervalLength);
		}
	}
	public closeWindow(): void {
		this.windowHandle.close();
	}
	public checkInstalledGoogle(): void {
		this.googleService
			.checkGoogleStatus()
			.pipe(take(1))
			.subscribe(res => {
				if (res.filed_business_owner_id) {
					if (!!localStorage.getItem(StorageKey.installedPlatorm)) {
						let installedItem: string[] = JSON.parse(localStorage.getItem(StorageKey.installedPlatorm));
						if (!installedItem.includes('Google')) {
							localStorage.setItem(StorageKey.installedPlatorm, JSON.stringify(installedItem.push('Google')));
							this.integrationHelper.connectionStatus({ status: true, name: this.channel.source });
						}
					} else {
						localStorage.setItem(StorageKey.installedPlatorm, JSON.stringify(['Google']));
						this.integrationHelper.connectionStatus({ status: true, name: this.channel.source });
					}
				}
			});
	}
	public createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0): Window {
		if (url == null) {
			return null;
		}
		const options = `width=${width},height=${height},left=${left},top=${top}`;
		return window.open(url, name, options);
	}
}
