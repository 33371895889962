import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ElementsService } from '../../../_services/elements.service';
import { select, Store } from '@ngrx/store';
import { getSelectedAdAccount, getToggleElementsRecommendations, SharedState, getCurrentAdsManagerTab } from '../../state/shared.reducer';
import { Subject } from 'rxjs';
import { RecommendationsListInterface } from './get-recommendations.interface';
import { SetToggleElementsRecommendations, HideGlobalSpinner, ShowGlobalSpinner } from '../../state/shared.actions';
import { OptimizeService } from '../../../optimise/services/optimize.service';
import { ToastNotificationService } from '../../toast-notification/toast-notification.service';
import { UserPreferencesDetails } from '../../../_models/elements-models/user-preferences-details.interface';
import { CampaignInterface } from '../../../ads-manager/ads-manager-insights/models/campaign.interface';
import { AdSetsInterface } from '../../../ads-manager/ads-manager-insights/models/ad-sets.interface';
import { AdsInterface } from '../../../ads-manager/ads-manager-insights/models/ads.interface';
import { InsightsCategoryTypeEnum } from '../../campaign-insights/models/insights-category-type.enum';
import { AdsManagerState, getSelectedCampaigns, getSelectedAdSets, getSelectedAds } from '../../../ads-manager/state/ads-manager.reducer';
import { RecommendationsSettingsInterface } from '../../../_models/elements-models/recommendations-settings.interface';

@Component({
	selector: 'app-elements-recommendations',
	templateUrl: './elements-recommendations.component.html',
	styleUrls: ['./elements-recommendations.component.scss']
})
export class ElementsRecommendationsComponent implements OnInit, OnDestroy {
	@Input() public accounts: boolean;

	public slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: '.recommendations-arrow-left',
		nextArrow: '.recommendations-arrow-right',
		infinite: false,
		adaptiveHeight: true
	};
	public currentSlide = 1;
	public userPreferencesJson: UserPreferencesDetails = {};
	public numberOfSlides: number;
	public selectedAdAccount: string;
	public recommendationsList: RecommendationsListInterface[];
	public showRecommendations: boolean;
	public loading: boolean;
	public selectedCampaigns: string[];
	public selectedAdSets: string[];
	public selectedAds: string[];
	public activeCampaignFilter: boolean;
	public activeAdSetsFilter: boolean;
	public activeAdsFilter: boolean;
	public adsTabChanges: boolean;
	public currentAdsTab: string;

	private unsubscriber$ = new Subject<void>();

	constructor(
		private elementsService: ElementsService,
		private sharedStore: Store<SharedState>,
		private optimizeService: OptimizeService,
		private toastNotificationService: ToastNotificationService,
		private adsManagerStore: Store<AdsManagerState>
	) {}

	public ngOnInit(): void {
		this.getAdAccount();
		this.getRecommendationsToggle();
		this.checkDataSaved();
		this.getSelectedCampaigns();
		this.getCurrentAdsManagerTab();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public checkDataSaved(): void {
		// this.elementsService
		// 	.getUserPreferences()
		// 	.pipe(takeUntil(this.unsubscriber$))
		// 	.subscribe(
		// 		result => {
		// 			this.userPreferencesJson = result.details;
		// 		},
		// 		() => {
		this.userPreferencesJson = {};
		// 	}
		// );
	}

	public updateUserPreferences(data: UserPreferencesDetails): void {
		// this.sharedStore.dispatch(new ShowGlobalSpinner());
		// this.elementsService
		// 	.updateUserPreferences(JSON.stringify(data))
		// 	.pipe(takeUntil(this.unsubscriber$))
		// 	.subscribe(
		// 		() => {
		// 			this.sharedStore.dispatch(new HideGlobalSpinner());
		// 		},
		// 		() => {
		// 			this.sharedStore.dispatch(new HideGlobalSpinner());
		// 			this.toastNotificationService.sendErrorToast('We could not save your preferences, please try again');
		// 		}
		// 	);
	}

	public afterChange(e: any): void {
		if (e.currentSlide) {
			this.currentSlide = e.currentSlide + 1;
		} else {
			this.currentSlide = 1;
		}
	}

	public getAdAccount(): void {
		this.sharedStore.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(data => {
			this.selectedAdAccount = data.adAccount.id;
			this.getRecommendations();
		});
	}

	public getRecommendations(): void {
		this.loading = true;
		const recommendationsSettings: RecommendationsSettingsInterface = {
			adAccountId: this.selectedAdAccount,
			pageSize: 25,
			pageNumber: 1,
			priorities: ['High', 'Low', 'Medium']
		};

		if (this.selectedCampaigns && this.selectedCampaigns.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.Campaign) {
			recommendationsSettings.level = 'campaign';
			recommendationsSettings.structureIds = this.selectedCampaigns;

			this.activeCampaignFilter = true;
		} else if (this.selectedAdSets && this.selectedAdSets.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.AdSet) {
			recommendationsSettings.level = 'adset';
			recommendationsSettings.structureIds = this.selectedAdSets;

			this.activeAdSetsFilter = true;
		} else if (this.selectedAds && this.selectedAds.length > 0 && this.currentAdsTab === InsightsCategoryTypeEnum.Ad) {
			recommendationsSettings.level = 'ad';
			recommendationsSettings.structureIds = this.selectedAds;

			this.activeAdsFilter = true;
		}

		this.elementsService
			.getElementsRecommendations(recommendationsSettings)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				data => {
					if (data) {
						this.recommendationsList = data.recommendations;
					}

					this.loading = false;
				},
				() => {
					this.loading = false;
				}
			);
	}

	public changeRecommendationsToggle(): void {
		this.sharedStore.dispatch(new SetToggleElementsRecommendations(!this.showRecommendations));

		if (this.accounts) {
			this.userPreferencesJson.accountsElements = this.userPreferencesJson.accountsElements || {};
			this.userPreferencesJson.accountsElements.showRecommendations = this.showRecommendations;
		} else {
			this.userPreferencesJson.adsManagerElements = this.userPreferencesJson.adsManagerElements || {};
			this.userPreferencesJson.adsManagerElements.showRecommendations = this.showRecommendations;
		}
		this.updateUserPreferences(this.userPreferencesJson);
	}

	public getRecommendationsToggle(): void {
		this.sharedStore.pipe(select(getToggleElementsRecommendations), takeUntil(this.unsubscriber$)).subscribe(recommendations => {
			this.showRecommendations = recommendations;
		});
	}

	public onApplyClick(recommendationId: string, index: number): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.optimizeService
			.applyRecommendation(recommendationId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					this.recommendationsList.splice(index, 1);
					this.recommendationsList = [...this.recommendationsList];
					this.toastNotificationService.sendSuccessToast(`The recommendation was successfully applied!`);
					this.sharedStore.dispatch(new HideGlobalSpinner());
				},
				() => {
					this.toastNotificationService.sendErrorToast(`The recommendation failed to apply.`);
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public getSelectedCampaigns(): void {
		if (!this.accounts) {
			this.adsManagerStore
				.pipe(select(getSelectedCampaigns), debounceTime(1000), takeUntil(this.unsubscriber$))
				.subscribe((selectedCampaigns: CampaignInterface[]) => {
					this.selectedCampaigns = [];

					if (selectedCampaigns && selectedCampaigns.length > 0) {
						selectedCampaigns?.forEach(item => {
							this.selectedCampaigns.push(item.campaign_id);
						});

						this.getRecommendations();
					}

					if ((this.activeCampaignFilter && !selectedCampaigns) || (this.activeCampaignFilter && selectedCampaigns.length === 0)) {
						this.getRecommendations();
					}
				});
		}
	}

	public getSelectedAdSets(): void {
		if (!this.accounts) {
			this.adsManagerStore
				.pipe(select(getSelectedAdSets), debounceTime(1000), takeUntil(this.unsubscriber$))
				.subscribe((selectedAdSets: AdSetsInterface[]) => {
					this.selectedAdSets = [];

					if (selectedAdSets && selectedAdSets.length > 0) {
						selectedAdSets?.forEach(item => {
							this.selectedAdSets.push(item.adset_id);
						});

						this.getRecommendations();
					}

					if ((this.activeAdSetsFilter && !selectedAdSets) || (this.activeAdSetsFilter && selectedAdSets.length === 0)) {
						this.getRecommendations();
					}
				});
		}
	}

	public getSelectedAds(): void {
		if (!this.accounts) {
			this.adsManagerStore.pipe(select(getSelectedAds), debounceTime(1000), takeUntil(this.unsubscriber$)).subscribe((selectedAds: AdsInterface[]) => {
				this.selectedAds = [];

				if (selectedAds && selectedAds.length > 0) {
					selectedAds?.forEach(item => {
						this.selectedAds.push(item.ad_id);
					});

					this.getRecommendations();
				}

				if ((this.activeAdsFilter && !selectedAds) || (this.activeAdsFilter && selectedAds.length === 0)) {
					this.getRecommendations();
				}
			});
		}
	}

	public getCurrentAdsManagerTab(): void {
		if (!this.accounts) {
			this.sharedStore.pipe(select(getCurrentAdsManagerTab), takeUntil(this.unsubscriber$)).subscribe(currentTab => {
				this.currentAdsTab = currentTab;
				if (this.adsTabChanges && this.currentAdsTab === InsightsCategoryTypeEnum.Campaign) {
					this.getSelectedCampaigns();
				} else if (this.currentAdsTab === InsightsCategoryTypeEnum.AdSet) {
					this.getSelectedAdSets();
				} else if (this.currentAdsTab === InsightsCategoryTypeEnum.Ad) {
					this.getSelectedAds();
				}
				this.adsTabChanges = true;
			});
		}
	}
}
