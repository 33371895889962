<app-expansion-panel-sc
	[expansionTitle]="expansionTitle"
	[isExpandable]="false"
	[isSelected]="isSelected"
	[contentMargin]="showContentMargin"
	[infoHoverModel]="info"
>
	<div expansion-content class="navigation-container">
		<button class="addition__btn fadeIn" (click)="addNewAd()" *ngIf="ads.length < 10"><span>+</span></button>
		<ul class="navigation-list">
			<li class="navigation-list__item" [class.selected]="selectedIndex === i" (click)="selectAd(i)" *ngFor="let ad of ads; let i = index">
				<span *ngIf="!imageExists(ad)">AD {{ i + 1 }}</span>
				<img *ngIf="imageExists(ad)" [src]="getImgSrc(ad)" alt="" class="navigation-list__img" />
				<button *ngIf="ads.length > 1" class="close-btn" (click)="removeAd(i)">
					<img src="assets/icons/aaa/close-icon-sm.svg" alt="close icon" />
				</button>
			</li>
		</ul>
	</div>
</app-expansion-panel-sc>
