import { HttpClient } from '@angular/common/http';
import { InsightsMetadataBaseService } from '../../../services/insights-metadata-base.service';
import { InsightsMetadataFactory } from './insights-metadata-factory.model';
import { InsightsCategoryTypeEnum } from '../../models/insights-category-type.enum';
import { FacebookInsightsMetadataService } from '../../facebook-insights-metadata.service';

export class FacebookInsightsMetadataFactory implements InsightsMetadataFactory {
	constructor(protected http: HttpClient) {}

	public getInstance(categoryType: InsightsCategoryTypeEnum): InsightsMetadataBaseService {
		switch (categoryType) {
			case InsightsCategoryTypeEnum.Campaign:
				return new FacebookInsightsMetadataService(this.http, 'vCampaignInsights');

			case InsightsCategoryTypeEnum.AdSet:
				return new FacebookInsightsMetadataService(this.http, 'vAdSetInsights');

			case InsightsCategoryTypeEnum.Ad:
				return new FacebookInsightsMetadataService(this.http, 'vAdInsights');

			default:
				return null;
		}
	}
}
