export enum AdsManagerFrameworkComponentsEnum {
	CheckboxHeader = 'checkboxHeader',
	ToggleCellRenderer = 'toggleCellRenderer',
	ClickableNameRenderer = 'clickableNameCellRenderer',
	EditableNameEditor = 'nameCellEditor',
	CustomFieldCellRenderer = 'customFieldCellRenderer',
	BudgetCellRenderer = 'budgetCellRenderer',
	TotalsStructureNameCellRenderer = 'totalsStructureNameCellRenderer',
	ImageCellRenderer = 'imageCellRenderer'
}
