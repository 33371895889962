<div class="chart-wrapper" echarts id="table">
	<div>
		<table class="table-chart">
			<thead>
				<tr>
					<th [title]="column" *ngFor="let column of columns">{{ column }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of dataSource">
					<td *ngFor="let column of columns" [title]="row[column]">{{ row[column] }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
