<div *ngIf="dashboard" class="page-container">
	<h1>{{ dashboard.name }}</h1>
	<div class="reports-grid-wrapper">
		<app-dashboard-grid
			[editWindowIsOpen]="openEdit"
			[gridOptions]="options"
			[dashboardInEditMode]="gridsterEnabled"
			[dashboardReports]="dashboard.graphs"
			class="graph"
		></app-dashboard-grid>
	</div>
</div>
