import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ValidatorMessages } from '../validatorMessagesInputs';
import { ErrorMessageService } from '../error-message.service';

@Component({
	selector: 'app-input-text',
	templateUrl: './input-text.component.html',
	styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent {
	@Input() control: FormControl | AbstractControl;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() label: string;
	@Input() placeholder: string;
	@Input() icon: string;
	@Input() readonlyInput: boolean;
	@Input() showSpinner: boolean;

	@Output() inputFocusIn = new EventEmitter();
	@Output() inputFocusOut = new EventEmitter();
	@Output() inputKeyUp = new EventEmitter();
	@Output() inputClick = new EventEmitter<any>();

	public isDestinationFocused: boolean;
	public errorMessage: string;

	constructor(public errorMessageService: ErrorMessageService) {}
	public ngOnInit(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public onFocusIn(): void {
		this.inputFocusIn.emit();
	}

	public onFocusOut(): void {
		this.setErrorMessage();
		this.inputFocusOut.emit();
	}

	public onClick(event: any): void {
		this.inputClick.emit(event);
	}

	public onKeyUp(): void {
		if (typeof this.control.value === 'string') {
			setTimeout(() => {
				this.control.setValue(this.control.value.trim());
			}, 3000);
		}
		this.setErrorMessage();
		this.inputKeyUp.emit();
	}
}
