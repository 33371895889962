import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-placements-split-card',
	templateUrl: './placements-split-card.component.html',
	styleUrls: ['./placements-split-card.component.scss']
})
export class PlacementsSplitCardComponent implements OnInit {
	@Input() public isPlacementsToggle: boolean;
	@Input() public isSelected: boolean;
	@Input() public control: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();

	constructor(public campaignStore: Store) {}

	public ngOnInit(): void {}

	public onPlacementsToggle(): void {
		this.isPlacementsToggle = !this.isPlacementsToggle;
	}
}
