import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SocialUser } from '../_models/social-user';
import { BusinessOwnerService } from '../../_services/facebook-accounts/business-owner.service';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from '../../shared/state/user/user.reducer';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { SharedState } from '../../shared/state/shared.reducer';
import { FacebookResponse } from '../_models/facebook-response.interface';
import { EMPTY, interval, Subject } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';

declare var window: any;
declare var FB: any;

@Component({
	selector: 'app-connect-to-facebook',
	templateUrl: './connect-to-facebook.component.html',
	styleUrls: ['./connect-to-facebook.component.scss']
})
export class ConnectToFacebookComponent implements OnInit {
	@Input() public integrationsFB: boolean;
	@Output() public connectionResponse = new EventEmitter<boolean>();

	private permissions: string[] = [
		'pages_show_list',
		'ads_management',
		'business_management',
		'pages_read_engagement',
		'public_profile',
		'catalog_management',
		'read_insights',
		'instagram_basic',
		'pages_read_user_content'
	];
	private fields = 'name,email,picture,first_name,last_name';
	private readonly defaultUnknownEmail = 'unknownFacebook@email.com';

	private unsubscriber$ = new Subject<void>();
	private filedId: number;
	private errorTriggered: boolean;
	private trigerredError: any;
	private successTriggered: boolean;

	constructor(
		private businessOwnerService: BusinessOwnerService,
		private userStore: Store<UserState>,
		private toastNotification: ToastNotificationService,
		private sharedStore: Store<SharedState>,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	public ngOnInit(): void {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		this.loadFacebook();

		interval(500)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				if (this.errorTriggered) {
					if (this.trigerredError instanceof HttpErrorResponse) {
						if (this.trigerredError.status === 400 && this.trigerredError.error) {
							this.toastNotification.sendErrorToast(this.trigerredError.error.message);
						} else if (this.trigerredError.error.message) {
							this.toastNotification.sendErrorToast(this.trigerredError.error.message);
						} else {
							this.toastNotification.sendErrorToast(
								'Seems like we have had trouble connecting your Facebook account, please try again. If this error persists contact help@filed.com'
							);
						}
					} else if (this.trigerredError.error?.message) {
						this.toastNotification.sendErrorToast(this.trigerredError.error.message);
					} else {
						this.toastNotification.sendErrorToast(
							'Seems like we have had trouble connecting your Facebook account, please try again. If this error persists contact help@filed.com'
						);
					}
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.errorTriggered = false;
				} else if (this.successTriggered) {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.successTriggered = false;
				}
			});
	}

	public loadFacebook(): void {
		window.fbAsyncInit = () => {
			FB.init({
				appId: '174014546372191',
				// appId: isDevMode() ? '1030669141081257' : '174014546372191', //TO BE UPDATED
				autoLogAppEvents: true,
				cookie: true,
				xfbml: true,
				version: 'v10.0'
			});
			FB.AppEvents.logPageView();
		};

		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			(js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	}

	public login(): void {
		FB.login(
			(response: FacebookResponse) => {
				if (response.authResponse) {
					this.sharedStore.dispatch(new ShowGlobalSpinner());
					FB.api(
						'/me',
						{
							fields: this.fields
						},
						(userInfo: any) => {
							const user: SocialUser = new SocialUser();
							user.id = userInfo.id;
							user.name = userInfo.name;
							user.email = userInfo.email || this.defaultUnknownEmail;
							user.photoUrl = 'https://graph.facebook.com/' + userInfo.id + '/picture?type=normal';
							user.firstName = userInfo.first_name;
							user.lastName = userInfo.last_name;
							user.authToken = response.authResponse.accessToken;
							user.facebook = userInfo;

							this.saveFacebookAccount(user);
						}
					);
				} else {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotification.sendErrorToast(
						'Seems like we have had trouble connecting your Facebook account, please try again. If this error persists contact help@filed.com'
					);
				}
			},
			{
				scope:
					'email,pages_show_list,ads_management,business_management,pages_read_engagement,public_profile,catalog_management,read_insights,instagram_basic,pages_read_user_content'
			}
		);
	}

	public saveFacebookAccount(facebookUser: SocialUser): void {
		const account = {
			facebookId: facebookUser.id,
			name: facebookUser.name,
			email: facebookUser.email,
			temporaryToken: facebookUser.authToken,
			requestedPermissions: this.permissions,
			filedUserId: this.filedId
		};

		this.businessOwnerService
			.connectToFacebookAccount(account)
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.errorTriggered = true;
					this.trigerredError = error;
					this.connectionResponse.emit(false);
					FB.logout();
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.successTriggered = true;
				this.connectionResponse.emit(true);
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
