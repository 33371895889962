import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { AaaHeaderFooterStateService } from '../services/aaa-header-footer-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AAAStepNameEnum, stepName } from '../models/AAA-steps.enum';
import { catchError, delay, retryWhen, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AAAModuleState, selectAAAModuleState } from '../state';
import { AdModel } from '../models/ad.model';
import { HideGlobalSpinner, ShowGlobalSpinner, UpdatePublishStatus } from '../../shared/state/shared.actions';
import { getPublishStatus, getSelectedAdAccount, SharedState } from '../../shared/state/shared.reducer';
import { ToastNotificationType } from '../../shared/toast-notification/toast-notification-type.enum';
import { PublishStatusInterface } from '../../shared/state/state-entities/publish/publish-status.interface';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { Ads, PublishAds } from '../models/publish-ads';
import { PublishAddService } from '../services/publish-add.service';
import { CampaignSmartCreateService } from '../../campaign-smart-create/services/campaign-smart-create.service';
import { convertToAdsetModel, setStepBasedOnFlow, IAPIAdsetModel } from '../helpers/helper';
import { CampaignAAAInterface } from '../models/campaign';
import { AdSetsAAAInterface } from '../models/ad-sets-aaa.interface';
import { CampaignSmartCreateState } from '../../campaign-smart-create/state/smart-create.reducer';
import { UserManagementState } from '../../user-management/state/user-management.reducer';
import { getUserDetails } from '../../shared/state/user/user.reducer';
import { DropdownInterface } from '@filed-com/filed-lib';
import { AdsAAAInterface } from '../models/ads-aaa.interface';
import { AdsetState } from '../state/adset-state/adset-state.reducer';
import { Gender } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/gender';
import { BudgetAllocatedEnum } from '../../campaign-smart-create/models/budget-allocated.enum';
import { AdFormatsEnum } from '../../campaign-smart-create/models/ad-formats.enum';
import { CarouselDto, SingleAdvertDto, MultipleSingleAdvertDto } from '../../campaign-builder/models/advert.interface';
import { Adsets, PublishAdset } from '../models/publish-adset';
import { ObjectiveTreeInterface } from '../../campaign-smart-create/models/objective-tree.interface';
import { PublishResponseInterface } from '../../campaign-smart-create/models/publish-response.interface';
import { Publish } from '../../shared/state/state-entities/publish/publish-status.model';
import { map } from 'rxjs/operators/map';
import { UpdateSelectedAAAAdIds } from '../state/aaa.actions';
import { AdSetModel } from '../models/adset.model';
import { AAAAdService } from '../services/aaa-ad.service';

export interface AdsetDetailInterface {
	id: string;
	name: string;
	status: string;
	destinationType: string;
	optimizationGoal: string;
	billingEvent: string;
	publisherPlatforms: string[];
	minAge: number;
	maxAge: number;
	startTime: string;
	bidControl: number;
	pageId: string;
	pixelId: string;
	customEventType: string;
	interests: string[];
	excludedInterests: string[];
	narrowInterests: string[];
	gender: Gender;
	locations: [
		{
			key: string;
			name: string;
			type: string;
			countryCode: string;
			supportsRegion: boolean;
			supportsCity: boolean;
		}
	];
	languages: string[];
	customAudiences: string[];
	excludedCustomAudiences: [];
	facebookPositions: string[];
	instagramPositions: string[];
	audienceNetworkPositions: any;
	devicePlatforms: any;
	mobileOs: string;
	mobileDevices: string[];
	endTime: string;
	adsetBudgetOptimization: {
		amount: number;
		budgetAllocatedTypeId: BudgetAllocatedEnum;
	};
	ads: [
		{
			id: string;
			name: string;
			status: string;
			adFormatType: AdFormatsEnum;
			facebookPageId: string;
			instagramPageId: string;
			advert: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto;
			postId: string;
			pixelId: string;
			pixelAppEventId: string;
		}
	];
}
@Component({
	selector: 'app-preview-ad',
	templateUrl: './preview-ad.component.html',
	styleUrls: ['./preview-ad.component.scss'],
	providers: []
})
export class PreviewAdComponent implements OnInit, OnDestroy {
	public ActiveCampaigns: CampaignAAAInterface[] = [];
	public collapseCard = false;
	public disableEditAdd = false;
	public disableEditCampaign = false;
	public totalAds: number;
	protected unsubscriber$ = new Subject<void>();
	private getState: Observable<any>;
	public currentAds: AdModel[] = [];
	private currentAdsets: AdSetsAAAInterface[] = [];
	public selectedAdsets: AdSetsAAAInterface[] = [];
	public selectedAdsetDetail: AdsetDetailInterface;
	public newAdset: AdsetState;
	public chosenAdset: AdsetState;
	public selectedAds: AdsAAAInterface[] = [];
	public selectedAdsId: string[];
	private currentCampaigns: CampaignAAAInterface[] = [];
	private timer: NodeJS.Timeout;
	public isPublishing: boolean;
	private parentLevel: string;
	private pageIds: DropdownInterface[];
	private currentCampaignIds: string[];
	private seledtedAdsetIds: string[];
	private publishableAds: Ads[];
	private accountId: string;
	public flow: number;
	private userFileId: string | number;
	private business_owner_facebook_id: string;
	public hideAdsetCounter: boolean;
	public loadingSelectedAdset: boolean;
	private publishableAdsets: Adsets[];
	private objectiveTree: ObjectiveTreeInterface;
	exisitingAdImages: string[];
	isfetchingExisitingAds: boolean;
	constructor(
		private aaaStateService: AaaHeaderFooterStateService,
		private aaaHeaderFooterStateService: AaaHeaderFooterStateService,
		private AAAAdService: AAAAdService,
		protected router: Router,
		private activatedRoute: ActivatedRoute,
		protected AAAstore: Store<AAAModuleState>,
		protected sharedStore: Store<SharedState>,
		private userStore: Store<UserManagementState>,
		private toastService: ToastNotificationService,
		private publishAdServices: PublishAddService,
		private smartCreateService: CampaignSmartCreateService,
		private store: Store<CampaignSmartCreateState>
	) {
		this.chosenAdset = null;
		this.totalAds = 0;
		this.getState = this.AAAstore.select(selectAAAModuleState);
		this.publishableAdsets = [];
	}
	public ngOnInit(): void {
		this.listenToRoute();
		this.registeringActions();
		this.checkPublish();
		this.sharedStore.pipe(select(getSelectedAdAccount), take(1)).subscribe(res => {
			this.accountId = res.adAccount.id;
		});
		this.getState.pipe(take(1)).subscribe((state: AAAModuleState) => {
			this.currentAds = state.AdState.ads ?? [];
			this.selectedAds = state.AAAState.selectedAds ?? [];
			this.selectedAdsId = state.AAAState.selectedAdIds ?? [];
			this.selectedAdsets = state.AAAState.selectedAdsets ?? [];
			this.seledtedAdsetIds = state.AAAState.selectedAdSetIds ?? [];
			this.newAdset = state.AdsetState ?? null;
			this.chosenAdset = this.newAdset;
			this.pageIds = state.AdState.pageIds;
			this.currentAdsets = state.AAAState.selectedAdsets;
			this.currentCampaigns = state.AAAState.selectedCampaigns;
			this.currentCampaignIds = state.AAAState.selectedCampaignIds;
			this.ActiveCampaigns = [];
			this.objectiveTree = state.AAAState.objective ?? null;
			if (this.selectedAds.length > 0) {
				this.totalAds = this.selectedAds.length;
				this.disableEditAdd = true;
			}
			if (this.currentAds.length > 0) {
				this.totalAds = this.currentAds.length;
			}
			if (!this.currentCampaigns) {
				return this.editCampaign();
			}
			if (this.flow >= 2 && this.selectedAdsets.length > 0) {
				const __selectedAdset = this.selectedAdsets[0];
				// this.publishAdServices
				// 	.getStructure(this.accountId, 'adset', __selectedAdset.adset_id)
				// 	.pipe(takeUntil(this.unsubscriber$))
				// 	.subscribe(
				// 		selectedAdsetDetail => {
				// 			this.loadingSelectedAdset = false;
				// 			this.selectedAdsetDetail = selectedAdsetDetail[0];
				// 			const adsetDetails = selectedAdsetDetail[0] as IAPIAdsetModel;
				// 			const selectedAdIds = (convertToAdsetModel(adsetDetails) as AdSetModel).ads.map(ad => ad.id);
				// 			let adIdsString = '';
				// 			selectedAdIds.forEach((id, index) => {
				// 				const isLast = index === selectedAdIds.length - 1;
				// 				adIdsString += isLast ? `${id}` : `${id},`;
				// 			});
				// 			if (adIdsString) {
				// 				this.fetchExisitingAdImages(adIdsString);
				// 			}
				// 		},
				// 		error => {
				// 			this.loadingSelectedAdset = false;
				// 		}
				// 	);
			}
			if (this.currentAdsets && this.flow <= 2) {
				this.currentAdsets.forEach(adSet => {
					const campaign = this.currentCampaigns.findIndex(_campaign => _campaign.campaign_id === adSet.campaign_id);
					const _camp = this.currentCampaigns[campaign];
					if (!_camp.adSets) {
						_camp.adSets = [];
					}
					_camp.adSets.push(adSet);
					this.ActiveCampaigns.push(_camp);
				});
			} else {
				this.ActiveCampaigns = this.currentCampaigns;
			}
		});
		this.userStore
			.select(getUserDetails)
			.pipe(take(1))
			.subscribe(userDetails => {
				this.business_owner_facebook_id = userDetails.FacebookBusinessOwnerId;
				this.userFileId = userDetails.FiledId;
			});
	}
	public clickedButton(): boolean {
		return !!this.collapseCard;
	}

	public editCampaign(): void {
		this.router.navigate(['/AAA/add-ad'], { queryParams: { flow: this.flow } });
	}

	public editAdd(): void {
		if (this.selectedAds.length > 0) {
			this.router.navigate(['/AAA/select-ad'], { queryParams: { flow: this.flow } });
			return;
		}
		this.router.navigate(['/AAA/create'], { queryParams: { flow: this.flow } });
		return;
	}

	public editAdset(): void {
		if (this.selectedAdsets.length > 0) {
			this.router.navigate(['/AAA/add-adset'], { queryParams: { flow: this.flow } });
			return;
		}
		this.router.navigate(['/AAA/add-adset'], { queryParams: { flow: this.flow } });
		return;
	}

	// public fetchExisitingAdImages(adIds: string): void {
	// 	this.isfetchingExisitingAds = true;
	// 	this.AAAAdService.getAdImages(adIds)
	// 		.pipe(takeUntil(this.unsubscriber$))
	// 		.subscribe(data => {
	// 			this.isfetchingExisitingAds = false;
	// 			this.exisitingAdImages = [...data];
	// 		});
	// }
	public registeringActions(): void {
		this.aaaStateService
			.onNext()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => this.publishAds());
		this.aaaStateService
			.onBack()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.router.navigate(['/AAA/create']);
			});
	}

	protected publishAds(): void {
		if (this.isPublishing) {
			this.toastService.sendCustomToast('Some campaign is still publishing', ToastNotificationType.Error, 20000, 'Publish was unsuccessful');
		}
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		let child_ids: string[] = [];
		let parent_ids: string[] = [];
		this.publishableAds = [];
		if (!this.currentAdsets || this.currentAdsets.length) {
			this.parentLevel = 'campaign';
			parent_ids = this.currentCampaignIds;
		} else {
			this.parentLevel = 'adset';
			parent_ids = this.seledtedAdsetIds;
		}
		if (this.selectedAdsId && this.selectedAdsId.length > 0 && this.currentAds.length <= 0) {
			child_ids = this.selectedAdsId;
		} else {
			this.currentAds.forEach(ads => {
				this.publishableAds.push({
					adFormatType: ads.adFormatType,
					adName: ads.adName,
					adverts: ads.adverts,
					dynamicAds: ads.dynamicAds,
					pixelAppEventId: ads.pixelAppEventId,
					pixelId: ads.pixelId,
					pixelRule: ads.pixelRule,
					customEventId: ads.conversionId ? ads.conversionId : null,
					customEventType: ads.customEventType ? ads.customEventType : null,
					toggle: false,
					facebookPageId: ads.facebookPageId
				});
			});
		}

		let postData: PublishAds | PublishAdset = null;
		if (this.flow >= 2) {
			this.parentLevel = 'campaign';
			parent_ids = this.currentCampaignIds;
			const adsetName = this.newAdset.adSetName ?? '';
			if (adsetName !== null && adsetName.length > 0) {
				this.publishableAdsets.push({
					ads: this.publishableAds,
					budgetOptimization: this.newAdset.budgetOptimization,
					date: this.newAdset.date,
					dateChoice: this.newAdset.dateChoice,
					devices: {
						devicePlatforms: this.newAdset.device,
						userOs: this.newAdset.deviceOs
					},
					facebookPageId: this.newAdset.facebookPageId,
					instagramPageId: this.newAdset?.instagramPageId,
					optimizationAndDelivery: this.newAdset.optimizationAndDelivery,
					placements: this.newAdset.placements,
					targeting: this.newAdset.targeting,
					adSetName: this.newAdset.adSetName,
					objective: this.objectiveTree.subObjective ?? ''
				});
			} else {
				child_ids = this.seledtedAdsetIds;
			}
			postData = {
				adAccountId: this.accountId,
				parentLevel: this.parentLevel,
				childLevel: 'adset',
				parentIds: parent_ids,
				childIds: child_ids,
				adsets: this.publishableAdsets
			};
		} else {
			postData = {
				ad_account_id: this.accountId,
				parent_level: this.parentLevel,
				child_level: 'ad',
				parent_ids: parent_ids,
				ads: this.publishableAds,
				child_ids: child_ids
			};
		}

		// this.completePublishingAds(postData);
	}

	// private completePublishingAds(postData: PublishAds | PublishAdset): void {
	// 	this.publishAdServices
	// 		.publishAdd(postData)
	// 		.pipe(take(1))
	// 		.subscribe(
	// 			result => {
	// 				this.onPublishConfirmed();
	// 			},
	// 			error => {
	// 				this.toastService.sendCustomToast(
	// 					'An error has occurred while publishing, please try again.',
	// 					ToastNotificationType.Error,
	// 					20000,
	// 					'Publish was unsuccessful'
	// 				);
	// 			}
	// 		);
	// }
	// public onPublishConfirmed(): void {
	// 	this.smartCreateService
	// 		.checkPublishStatus()
	// 		.pipe(
	// 			map(response => {
	// 				if (!response) {
	// 					throw new Error();
	// 				}
	// 				return response;
	// 			}),
	// 			retryWhen(errors => errors.pipe(take(5), delay(1000)))
	// 		)
	// 		.subscribe(response => {
	// 			if (response) {
	// 				setTimeout(() => {
	// 					this.getToastMessage(response);
	// 				}, 6000);
	// 			} else {
	// 				const notActive: PublishStatusInterface = {
	// 					isActive: false,
	// 					totalStructures: null,
	// 					progressStatus: null
	// 				};
	// 				this.sharedStore.dispatch(new UpdatePublishStatus(notActive));
	// 			}
	// 		});
	// }

	public getToastMessage(resp: PublishResponseInterface): void {
		let status: PublishStatusInterface = {
			isActive: true,
			totalStructures: resp.totalStructures,
			progressStatus: resp.publishedStructures
		};
		if (resp.publishStatus === Publish.success) {
			this.toastService.sendCustomToast(
				`${resp.publishedCampaigns + ' Campaigns, ' + resp.publishedAds + ' Ad Sets and ' + resp.publishedAds + ' Ads were published'}`,
				ToastNotificationType.Success,
				20000,
				'Yay! Your Publish is now complete'
			);
		} else if (resp.publishStatus === Publish.failed) {
			this.toastService.sendCustomToast(
				`${resp.publishedCampaigns + ' Campaigns, ' + resp.publishedAds + ' Ad Sets and ' + resp.publishedAds + ' Ads were published'}`,
				ToastNotificationType.Error,
				20000,
				resp.error ?? 'Publish was unsuccessful'
			);
			status = {
				isActive: false,
				totalStructures: null,
				progressStatus: null
			};
		} else if (resp.publishStatus === Publish.inProgress) {
			this.toastService.sendCustomToast(
				'This may take some time. Check the progress above.',
				ToastNotificationType.Info,
				8000,
				'We have started Publishing!'
			);
		}
		this.sharedStore.dispatch(new UpdatePublishStatus(status));
		this.router.navigate([`/ads-manager/insights`]);
		this.sharedStore.dispatch(new HideGlobalSpinner());
	}

	public checkPublish(): void {
		this.sharedStore.pipe(select(getPublishStatus), takeUntil(this.unsubscriber$)).subscribe(status => {
			this.isPublishing = status?.isActive;
		});
	}

	public setCurrentStep(step?: number): void {
		this.aaaHeaderFooterStateService.sendActiveStep(step);
		this.aaaHeaderFooterStateService.onPreviewAd(true);
		this.aaaHeaderFooterStateService.getActiveStep().subscribe(res => {});
		this.aaaStateService.sendActiveStep(step);
		this.aaaStateService.sendCampaignStep(step);
	}

	public setCurrentStepBasedOnFlow(): void {
		setStepBasedOnFlow(this, this.flow, this.setCurrentStep, stepName.ReviewAndPublish);
	}
	public listenToRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.flow = +params['flow'];
			this.hideAdsetCounter = this.flow >= 2;
			this.setCurrentStepBasedOnFlow();
		});
	}

	public onNavClick(step: AAAStepNameEnum): void {
		this.router.navigate([`/AAA/${step}`], {});
	}

	private mockLoader(): void {
		clearTimeout(this.timer);
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.timer = setTimeout(() => {
			this.sharedStore.dispatch(new HideGlobalSpinner());
		}, 200);
	}

	private hideSpinner(): void {
		setTimeout(() => {
			this.sharedStore.dispatch(new HideGlobalSpinner());
		}, 200);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.aaaHeaderFooterStateService.onPreviewAd(true);
	}

	public formatGender(key: Gender | undefined): string {
		const genders = Gender;
		let genderName = '';
		if (key) {
			genderName = genders[key];
		}
		return genderName;
	}
}
