import { Component, Input, OnInit } from '@angular/core';
import { ReportComponentService } from '../../../services/chart-services/report-component.service';
import { PieChartService } from '../../../services/chart-services/pie-chart.service';
import { ReportModel } from '../../../models/report.model';
import { ChartInformation } from '../../../models/chart-information';
import { InitOptionsModel } from '../../../models/init-options.model';
import EChartOption = echarts.EChartOption;
import ECharts = echarts.ECharts;

@Component({
	selector: 'app-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
	@Input() widget: ReportModel;
	@Input() index: number;

	chartOption: EChartOption;
	initOptions: InitOptionsModel;

	constructor(private widgetComponentService: ReportComponentService, private chartService: PieChartService) {
		this.initOptions = {
			renderer: 'canvas'
		};
	}

	ngOnInit() {
		this.chartOption = this.chartService.createChart(this.widget);
	}

	public onChartInit(instance: ECharts): void {
		const chartInformation = new ChartInformation(instance, instance.getDom());
		this.chartService.setChartInstances(this.index, chartInformation);
	}
}
