import { TableMetaColumn } from './table-meta-column.model';
import { TableViewColumnTemplateEnum } from './table-view-column-template.enum';
import { ActionContract } from 'src/app/_models/TableModels/action-contract.model';

export class TableViewColumn2 {
	public id: string;
	public displayName?: string;
	public primaryValue: TableMetaColumn;
	public secondaryValue?: TableMetaColumn;
	public typeId?: TableViewColumnTemplateEnum;
	public actions?: string[];
	public isFixed: boolean;

	public type: {
		templateId: TableViewColumnTemplateEnum;
		actions?: ActionContract[];
	};

	public width: number;
	public left?: number;
	public categoryId: number;

	// The following properties are DEPRECATED - Only used in Table v1
	public name?: string;
	public displayed?: boolean;
	public category: string;
	public sticky?: boolean;
}
