import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { PageService } from '../../_services/facebook-accounts/page.service';
import { select, Store } from '@ngrx/store';
import { getSelectedAdAccount } from '../../shared/state/shared.reducer';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, skipWhile, take } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { UpdateSelectedAAAAdIds } from '../state/aaa.actions';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { AAAAdService } from '../services/aaa-ad.service';
import * as fromAAAModuleState from '../state';
import { AAAFlow } from '../models/AAA-steps.enum';
import { convertToAdsetModel } from '../helpers/helper';
import { AdSetModel } from '../models/adset.model';
import { FiledLibMapperService } from 'src/app/shared/services/filed-lib-Mapper.service';
import { AdsetState } from '../state/adset-state/adset-state.reducer';
import _ from 'lodash';
import { LoadCampaignCatalogs, LoadFacebookPages } from '../../state/campaign-common-state/campaign-common-actions';
import { getFacebookPages } from '../../state/campaign-common-state/campaign-common-reducer';

@Injectable()
export class AdSetResolver implements Resolve<Observable<any>> {
	public isBestPerforming = false;
	constructor(
		private router: Router,
		private pagesService: PageService,
		private mapperService: FiledLibMapperService,
		private store: Store,
		private toastNotificationService: ToastNotificationService,
		private adaaaService: AAAAdService
	) {}

	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		this.store.dispatch(new ShowGlobalSpinner());
		this.store.dispatch(new LoadCampaignCatalogs());
		this.store.dispatch(new LoadFacebookPages());

		const adAccount$ = this.store.pipe(select(getSelectedAdAccount), take(1));

		// const setAdset = (): Observable<AdSetModel | AdsetState> => {
		// 	const isEditSelectedAdset = +route.queryParams['flow'] === AAAFlow.bestPerformingAdset;
		// 	return this.store.pipe(
		// 		mergeMap(adsetState => {
		// 			const storeState = adsetState as any;
		// 			const adset = storeState.AAA.AdsetState;
		// 			const selectedAdset = storeState.AAA.AAAState.selectedAdsets;
		// 			const adAccount = storeState.shared.selectedAdAccount;
		// 			const newAdset: AdSetModel = new AdSetModel();
		// 			const { ads, adSetId, ...newAdsetWithoutOptionals } = newAdset;
		// 			const isAdsetStateEmpty = _.isEqual(adset, newAdsetWithoutOptionals);
		// 			if (isAdsetStateEmpty && isEditSelectedAdset) {
		// 				const adsetId = selectedAdset[0].adset_id;
		// 				const accountId = adAccount.adAccount.id;
		// 				this.isBestPerforming = true;
		// 				return this.adaaaService
		// 					.fetchAdset(adsetId, accountId)
		// 					.pipe(mergeMap(adaaaServiceAdset => of(convertToAdsetModel(adaaaServiceAdset[0]))));
		// 			}
		// 			this.isBestPerforming = false;
		// 			return of(adset);
		// 		}),
		// 		take(1)
		// 	);
		// };

		// const adset$ = setAdset();

		const objectives$ = this.store.pipe(select(fromAAAModuleState.getObjectiveTree), take(1));
		const pages$ = this.store.pipe(
			select(getFacebookPages),
			skipWhile(pages => !pages),
			take(1)
		);
		// return forkJoin([adAccount$, adset$, objectives$, pages$]).pipe(
		return forkJoin([adAccount$, , objectives$, pages$]).pipe(
			take(1),
			map(([adAccount, apiAdset, objectiveTree, mappedPages]) => {
				const adset = apiAdset;
				if (this.isBestPerforming) {
					const selectedAdIds = (adset as AdSetModel).ads.map(ad => ad.id);
					this.store.dispatch(new UpdateSelectedAAAAdIds(selectedAdIds));
				}
				return {
					adAccount,
					adset,
					objectiveTree,
					mappedPages
				};
			}),
			catchError((error: any) => {
				this.toastNotificationService.sendErrorToast('Unable to retrieve facebook pages for the selected Account');
				this.store.dispatch(new HideGlobalSpinner());
				const queryParams = route.queryParams;
				this.router.navigate(['/AAA/add-ad'], { queryParams });
				return EMPTY;
			})
		);
	}
}
