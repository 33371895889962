<div class="chart-wrapper">
	<div
		(chartInit)="onChartInit($event)"
		[autoResize]="true"
		[options]="chartOption"
		[theme]="widget.details.style ? widget.details.style.theme : 'default'"
		class="chart"
		echarts
	></div>
</div>
