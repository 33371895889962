import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Options } from 'ng5-slider';
import { AudienceData } from 'src/app/audience/models/audience-data-table.model';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { KeyName } from 'src/app/_models/savedAudience';
import { DropdownColumnInterface } from '../../../live-search-dropdown/dropdown-columns.interface';
import { FacebookInterestsView } from '../models/facebook-interests-view';
import { FacebookLocationsView } from '../models/facebook-locations-view';
import { Gender } from '../models/gender';

@Component({
	selector: 'app-target-new',
	templateUrl: './target-new.component.html',
	styleUrls: ['./target-new.component.scss']
})
export class TargetNewComponent implements OnInit {
	public genders = Gender;
	@Input() public customAudiences: DropdownData<AudienceData>[];
	@Input() public selectedExcludeCustomAudiences: DropdownData<AudienceData>[];
	@Input() public customAudienceColumns: DropdownData<AudienceData>[];
	@Input() public gender: Gender;
	@Input() public isSpecialAddCategorySelected: boolean;
	@Input() public maxAge: number;
	@Input() public minAge: number;
	@Input() public options: Options;

	@Input() public locationFormControl: FormControl;
	@Input() public locations: DropdownData<FacebookLocationsView>[];
	@Input() public selectedLocations: DropdownData<FacebookLocationsView>[];
	@Input() public locationColumns: DropdownColumnInterface[];

	@Input() public languagesFormControl: FormControl;
	@Input() public languages: DropdownData<KeyName>[];
	@Input() public selectedLanguages: DropdownData<KeyName>[];
	@Input() public languagesColumns: DropdownColumnInterface[];

	@Input() public interestsFormControl: FormControl;
	@Input() public interests: DropdownData<FacebookInterestsView>[];
	@Input() public selectedInterests: DropdownData<FacebookInterestsView>[];
	@Input() public interestColumns: DropdownColumnInterface[];

	@Input() public showExcludeInterests: boolean;

	@Output() public dropdownDataChange = new EventEmitter<any>();
	@Output() public genderClick = new EventEmitter();
	@Output() public ageChange = new EventEmitter();
	@Output() public validateLocation = new EventEmitter();
	constructor() {}

	public ngOnInit(): void {}

	public onDropdownDataChange(data: DropdownData[], type: string): void {
		this.dropdownDataChange.emit({ data, type });
	}

	public onGenderClick(gender: Gender): void {
		this.genderClick.emit(gender);
	}

	public onAgeChange(): void {
		this.ageChange.emit();
	}

	public onValidateLocation(): void {
		this.validateLocation.emit();
	}
}
