import { Bits } from '../../shared/permisions/enums/bits';

export enum PermissionsUserEnum {
	View = 1,
	Edit,
	Full
}

export class userPermission {
	frontEndModule: number;
	accessType: number;
}

export class PermissionAccessValue {
	status: boolean;
	active: boolean;
}
export class NewuserPermission {
	title: string;
	desc: string;
	isEdit: PermissionAccessValue;
	isViewOnly: PermissionAccessValue;
	isAccessBoth: PermissionAccessValue;
	frontEndModule: number;
	accessType: number;
}

export class userPermissionNewUserAdmin {
	frontEndModuleId: string;
	frontEndModuleName: string;
	isEditPermission: boolean;
	isViewOnlyPermission: boolean;
	isBothPermission: boolean;
}
