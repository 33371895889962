import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownOptionInterface } from './dropdown-option.interface';

@Component({
	selector: 'app-generic-dropdown',
	templateUrl: './generic-dropdown.component.html',
	styleUrls: ['./generic-dropdown.component.scss']
})
export class GenericDropdownComponent implements OnInit {
	@Input() public dropdownOptions: Array<DropdownOptionInterface>;
	@Input() public isDropDownVisible: boolean;

	@Output() public clickOption = new EventEmitter<DropdownOptionInterface>();
	constructor() {}

	ngOnInit(): void {}

	public onOptionClick(dropdownOption: DropdownOptionInterface): void {
		if (!dropdownOption.isDisabled) {
			this.clickOption.emit(dropdownOption);
		}
	}
}
