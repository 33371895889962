<div class="symbol-container">
  <button [matMenuTriggerFor]="menu" mat-icon-button>
    <mat-icon class="actionBtn">add</mat-icon>
  </button>
</div>

<mat-menu #menu="matMenu">
  <ng-container>
    <div (click)="executeAction()" class="p-l-10 d-flex action">
      <span class="d-flex d-j-c-left">
        Turn on
      </span>
    </div>
  </ng-container>
  <ng-container>
    <div (click)="executeAction()" class="p-l-10 d-flex action">
      <span class="d-flex d-j-c-left">
        Edit
      </span>
    </div>
  </ng-container>
  <ng-container>
    <div (click)="executeAction()" class="p-l-10 d-flex action">
      <span class="d-flex d-j-c-left">
        Duplicate
      </span>
    </div>
  </ng-container>
  <ng-container>
    <div (click)="executeAction()" class="p-l-10 d-flex action">
      <span class="d-flex d-j-c-left">
        Optimization
      </span>
    </div>
  </ng-container>
  <ng-container>
    <div (click)="executeAction()" class="p-l-10 d-flex action">
      <span class="d-flex d-j-c-left">
        Delete
      </span>
    </div>
  </ng-container>
</mat-menu>
