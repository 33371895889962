<div class="container" [ngClass]="{ sticky: isSticky, nonSticky: !isSticky }">
	<div class="title">
		<h5>Campaign Summary</h5>
	</div>
	<div class="content">
		<span>
			Campaign name:
			<strong>{{ campaignName }}</strong>
		</span>
		<span>
			Campaign Objective:
			<strong>{{ objectiveName }}</strong>
		</span>
		<span>
			No. of Campaigns:
			<strong>{{ numberOfCampaigns }}</strong>
		</span>
		<span *ngIf="!budgetSplits?.campaignsBudget?.length && !budgetSplits?.adSetsBudget?.length">
			Total budget per Campaign:
			<strong>{{ currency + ' ' + (totalBudgetPerCampaign | number: '.2') }}</strong>
		</span>
		<span>
			Total budget for all Campaign:
			<strong *ngIf="!budgetSplits?.adSetsBudget?.length && !budgetSplits?.campaignsBudget?.length">
				{{ currency + ' ' + (campaignBudget | number: '.2') }}
			</strong>
			<strong *ngIf="budgetSplits?.adSetsBudget?.length || budgetSplits?.campaignsBudget?.length">
				{{ currency + ' ' + (campaignBudget | number: '.2') }}
			</strong>
		</span>
	</div>
</div>
