<div class="card-recommendation">
    <div class="recommendation-info-header">
      <div class="left-icon-title">
      <img src="{{imagePath}}" class="icon-image">
      <span class="date-time-label">
        {{ recommendation.createdAt | date: 'EEEE, dd.MM.yyyy HH:mm' }}
      </span>
      </div>
      <span class="optimization-type-label {{getLabelClass(recommendation.recommendationType)}}">
        {{ recommendation.recommendationType }}
      </span>
    </div>
    <div class="recommendation-info-body">
      <div class="actor-info">

        <ng-container *ngIf="recommendation.level === levels.Ad">
        <span class="campaign-info">
          {{recommendation.campaignName}}
        </span>
        <span class="other-info">
        {{ recommendation.parentName }} / {{ recommendation.structureName }}
        </span>
        </ng-container>

        <ng-container *ngIf="recommendation.level === levels.AdSet || recommendation.level === levels.AdGroup">
        <span class="campaign-info">
          {{recommendation.campaignName}}
        </span>
        <span class="other-info">
        {{ recommendation.structureName }}
        </span>

        </ng-container>

        <ng-container *ngIf="recommendation.level === levels.Campaign">
        <span class="campaign-info">
          {{recommendation.structureName}}
        </span>
        <span class="other-info">
        <br>
        </span>
        </ng-container>

      </div>
      <div class="template-label"
      [innerHTML]="recommendation.template.length > 320? recommendation.template.substring(0, 317) + '...' : recommendation.template">
      </div>

    </div>

  <!--btn section -->
    <div class="recommendation-action" *ngIf="showButtons">
      <app-button (buttonClicked)="rejectRecommendation()"
                  [buttonClass]="buttonClassEnum.Secondary"
                  [buttonType]="buttonTypeEnum.Icon"
                  [buttonLabel]=""
                  icon="fa-trash-alt">
      </app-button>

      <div class="right-section-button">
        <app-button (buttonClicked)="viewMoreRecommendation()"
                    [buttonClass]="buttonClassEnum.Tertiary"
                    [buttonType]="buttonTypeEnum.Simple"

                    [buttonLabel]="'VIEW'">
        </app-button>

        <app-button (buttonClicked)="editStructure()"
                    [buttonClass]="buttonClassEnum.Primary"
                    [buttonType]="buttonTypeEnum.Simple"
                    buttonLabel="{{recommendation.applicationDetails? 'APPLY': 'EDIT'}}">
        </app-button>
      </div>
    </div>
  </div>

