import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { QuickNavigationService } from '../../services/quick-navigation.service';

@Component({
	selector: 'app-quick-navigation',
	templateUrl: './quick-navigation.component.html',
	styleUrls: ['./quick-navigation.component.scss']
})
export class QuickNavigationComponent implements OnInit, OnDestroy {
	@Input('navigations') navigations: IQuickNavigation[];
	public selectedNavigation: number;
	protected unsubscriber$ = new Subject<void>();
	constructor(private quickNavigationService: QuickNavigationService) {}

	ngOnInit(): void {
		this.registerSelectedNavigation();
	}

	public setCurrentNavigation(index: number) {
		this.quickNavigationService.setSelectedNavigation(index);
	}

	public registerSelectedNavigation(): void {
		this.quickNavigationService.getSelectedNavigation().subscribe(selectedIndex => {
			if (this.navigations.length > selectedIndex && selectedIndex !== this.selectedNavigation) {
				this.selectedNavigation = selectedIndex;
				const target = document.querySelector(`#${this.navigations[selectedIndex].targetId}`);
				target.scrollIntoView({ behavior: 'smooth', inline: 'center' });
			}
		});

		this.quickNavigationService.getSelectedNavigationViaScroll().subscribe(selectedIndex => {
			if (this.navigations.length > selectedIndex && selectedIndex !== this.selectedNavigation) {
				this.selectedNavigation = selectedIndex;
			}
		});
	}

	public isSelected(index: number): boolean {
		return index === this.selectedNavigation;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}

export interface IQuickNavigation {
	name: string;
	targetId?: string;
}
