import { Recommendation } from './recommendation.model';

export enum RecommendationClickedAction {
	ViewMore = 'ViewMore',
	Reject = 'Reject',
	Apply = 'Apply',
	Edit = 'Edit'
}

export interface RecommendationClickedEvent {
	recommendation: Recommendation;
	action: RecommendationClickedAction;
}
