<lolly-spinning-loader *ngIf="showSpinner" class="loading-indicator"></lolly-spinning-loader>

<div class="grid-section" *ngIf="!isFlowCompleted">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/monthly-ad-spend-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-section-container">
			<div class="grid-gap-left"></div>
			<div class="grid-section-two-inner container">
				<div class="center-column-two">
					<div class="filed-logo">
						<img src="assets/logo/lolly-logo.svg" />
					</div>
					<div class="billing-carousel-wrapper">
						<div class="billing-slide" [class.inactive]="this.billingActive">
							<div class="text-description">
								<h2>Complete your payment details</h2>
								<p class="margin-bottom-spacing">Please enter your payment details to create your Lolly account</p>
							</div>
							<div class="inner-margin">
								<div class="sub-description margin-bottom-spacing">
									<p class="font-medium">Payment Methods</p>
								</div>

								<div class="credit-card-images-wrapper margin-bottom-spacing">
									<img class="credit-card-image" src="assets/icons/card-icons/visa.png" alt="logo" />
									<img class="credit-card-image" src="assets/icons/card-icons/american-express.png" alt="logo" />
									<img class="credit-card-image" src="assets/icons/card-icons/master-card.png" alt="logo" />
									<img class="credit-card-image" src="assets/icons/card-icons/maestro-card.png" alt="logo" />
								</div>
								<form [formGroup]="formStep1">
									<div class="margin-bottom-spacing">
										<tb02
											class="input-box"
											[control]="formStep1.get('cardHolder')"
											[label]="'Name seen on card'"
											[validatorMessages]="validationMessages.get('cardHolder')"
										></tb02>
									</div>
									<div class="margin-bottom-spacing">
										<mtb02
											class="input-box number"
											[control]="formStep1.get('number')"
											[validatorMessages]="validationMessages.get('cardNumber')"
										></mtb02>
									</div>
									<div class="flex-inline col-12 bottom-spacing-lg">
										<div class="flex-left">
											<mtb03 class="input-box date" [control]="formStep1.get('expirationDate')"></mtb03>
										</div>
										<div class="flex-right">
											<mtb04 class="input-box cvc" [control]="formStep1.get('cvc')" [label]="'CVC'"></mtb04>
										</div>
									</div>
								</form>
								<div class="bottom-spacing-lg">
									<btn01 (click)="next()" [disabled]="formStep1.invalid">
										Next
									</btn01>
								</div>
								<p class="logout">
									To change accounts or exit, please
									<span (click)="logout()">logout</span>
								</p>
							</div>
						</div>
						<div class="payment-slide" [class.active]="this.billingActive">
							<div class="font-grey">
								<div class="text-description-2">
									<h2>Complete your billing info</h2>
									<p class="margin-bottom-spacing">Please enter your billing address</p>
								</div>
							</div>
							<div class="inner-margin">
								<form class="margin-top" [formGroup]="formStep2">
									<div class="margin-bottom-spacing">
										<tb01-2
											class="input-box"
											[control]="formStep2.get('address1')"
											[validatorMessages]="validationMessages.get('address1')"
											label="Billing Address*"
										></tb01-2>
									</div>
									<div class="margin-bottom-spacing">
										<tb01-2
											class="input-box"
											[control]="formStep2.get('city')"
											[validatorMessages]="validationMessages.get('city')"
											label="City*"
											placeHolderTextBox="e.g: London"
										></tb01-2>
									</div>

									<div class="margin-bottom-spacing">
										<drp02
											class="input-box"
											[control]="formStep2.get('country')"
											[data]="dropdownCountries"
											[label]="'Country*'"
											[placeholder]="'Select Country'"
											[width]="'100%'"
										></drp02>
									</div>

									<div class="bottom-spacing-lg">
										<tb01-2
											class="input-box"
											[control]="formStep2.get('zip')"
											[validatorMessages]="validationMessages.get('zip')"
											label="Zipcode/ Postcode*"
											placeHolderTextBox="e.g: ER5 6GL"
										></tb01-2>
									</div>
								</form>
								<div class="margin-bottom-spacing top-space">
									<btn01 (click)="next()" [disabled]="formStep2IsSubmitted || formStep2.invalid || formStep2IsRequested">
										Save
									</btn01>
								</div>
								<div (click)="previous()" class="previous-step">
									<img src="assets/icons/blue-chevron-left.svg" />
									<p>Back</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid-gap-right"></div>
		</div>
	</div>
</div>
<img *ngIf="isFlowCompleted" class="pending-image" src="assets/images/success-background.svg" alt="pending-credit-card" />
<app-toast-notification></app-toast-notification>
