<app-user-header></app-user-header>

<div class="center-wrapper">
	<!--Header employees -->
	<div class="header-wrapper">
		<div class="employees-title">
			<h4>My Users</h4>
			<p>{{ rowDataLength }} employees</p>
		</div>

		<div class="right-options">
			<app-input-text [control]="searchFormControl.get('searchForm')" [icon]="'fa-search'" class="search-input" [label]="'Search'"></app-input-text>
			<div *appCheckPermissions="permissionModelUsers">
				<btn03 (onClick)="openPopUp()">+ Create New</btn03>
			</div>
		</div>
	</div>
	<div class="master-table-container">
		<app-master-table
			(gridReady)="onGridReady($event)"
			[buttonsConfiguration]="iconsConfiguration"
			[canPageSizeBeChanged]="true"
			[columnDefs]="columnDefs"
			[defaultColDef]="defaultColDef"
			[frameworkComponents]="frameworkComponents"
			[isEditClickSuppressed]="true"
			[isEditingStoppedWhenGridLosesFocus]="true"
			[isIconsBarVisible]="true"
			[paginationOptions]="paginationOptions"
			[paginationPageSize]="defaultPageSize"
			[rowBuffer]="200"
			[rowData]="filteredRowData"
		></app-master-table>
	</div>
</div>
