import { AfterViewInit, Component, Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { catchError, take } from 'rxjs/operators';
import { StepThreeDetails } from '../../models/step-three-details';
import { EMPTY, Subject } from 'rxjs';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';

import { HierarchyDatum } from './tree-ui.interface';

import { ToastNotificationType } from '../../../shared/toast-notification/toast-notification-type.enum';
import * as d3 from 'd3';
import { CampaignTemplate } from '../../models/campaign-template';
import { StepFiveDetails } from '../../models/step-five-details';
import { SplitHistoryInterface } from '../../models/ab-test-interface';
@Component({
	selector: 'app-publishing-tree-structure',
	templateUrl: './publishing-tree-structure.component.html',
	styleUrls: ['./publishing-tree-structure.component.scss']
})
export class PublishingTreeStructureComponent implements OnInit, OnDestroy, AfterViewInit {
	protected currentStep: StepThreeDetails[] = [];
	protected unsubscriber$ = new Subject<void>();
	protected toastService: ToastNotificationService;
	protected router: Router;
	protected activatedRoute: ActivatedRoute;
	public treeData: HierarchyDatum;
	public readFile: string;
	public campaignDetails: CampaignTemplate;
	public step5Details: StepFiveDetails;

	constructor(protected injector: Injector, private zone: NgZone, private smartCreateService: CampaignSmartCreateService) {
		this.router = this.injector.get<Router>(Router);
		this.activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
		this.toastService = this.injector.get<ToastNotificationService>(ToastNotificationService);
	}
	public ngOnInit(): void {
		this.getActivatedRoute();
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	public ngAfterViewInit(): void {}
	public onResize(): void {}
	public getActivatedRoute(): void {
		this.activatedRoute.data.subscribe((data: Data) => {
			this.campaignDetails = data.store.campaign;
			this.step5Details = this.campaignDetails.stepFiveDetailsAsJson;
		});
		this.getData();
		this.initializerTree();
	}
	public readDataFromFile(): void {}
	public initializerTree(): void {
		this.zone.runOutsideAngular(() => {
			const width = 1200;
			const height = 850;
			const svg = d3
				.select('#treeContainer')
				.append('svg')
				.attr('width', width)
				.attr('height', height)
				.append('g')
				.attr('transform', 'translate(40,0)')
				.attr('class', 'node fadeIn');

			const cluster = d3.cluster().size([height, width - 160]);

			const root = d3.hierarchy(this.treeData, function (d: any) {
				return d.children;
			});

			cluster(root);

			svg.selectAll('path')
				.data(root.descendants().slice(1))
				.enter()
				.append('path')
				.attr('d', function (d: any) {
					return (
						'M' +
						d.y +
						',' +
						d.x +
						'C' +
						(d.parent.y + 190) +
						',' +
						d.x +
						' ' +
						(d.parent.y + 200) +
						',' +
						d.parent.x +
						' ' +
						(d.parent.y + d.data.name.length + 50) +
						',' +
						d.parent.x
					);
				})
				.style('fill', 'none')
				.attr('stroke', '#a9a9a9')
				.style('stroke-width', '1.5px')
				.attr('class', 'path');

			// Add a text
			svg.selectAll('g')
				.data(root.descendants())
				.enter()
				.append('foreignObject')
				.text(function (d: any) {
					if (d) {
						return d.data.name;
					} else {
						return '';
					}
				})
				.attr('transform', function (d: any) {
					return 'translate(' + (d.y + 20) + ',' + (d.x - 11) + ')';
				})
				.attr('width', function (d) {
					return '100%';
				})
				.attr('height', function (d) {
					return '100%';
				})
				.style('color', 'steelblue')
				.style('font-size', '12px')
				.style('font-weight', 'bold')
				.style('font-style', 'sans-serif')
				.style('fill', '#ffffff')
				.style('background-color', '#ffffff')
				.style('display', 'inline-block');

			// Add a circle for each node.
			svg.selectAll('g')
				.data(root.descendants())
				.enter()
				.append('circle')
				.attr('transform', function (d: any) {
					return 'translate(' + d.y + ',' + d.x + ')';
				})
				.attr('r', 15)
				.style('opacity', function (d, i) {
					return !d.depth ? 0 : 1;
				})
				.style('fill', '#E7EFFA')
				.attr('stroke', '#f2f2f2')
				.style('stroke-width', '1px');

			svg.selectAll('g')
				.data(root.descendants())
				.enter()
				.append('image')
				.attr('xlink:href', function (d) {
					return d.data.icon;
				})
				.attr('transform', function (d: any) {
					return 'translate(' + (d.y - 13) + ',' + (d.x - 12) + ')';
				})
				.attr('width', '26px')
				.attr('height', '26px')
				.attr('r', 15)
				.style('fill', '#69b3a2')
				.attr('stroke', 'black')
				.style('stroke-width', 2);
		});
		/*		setTimeout(() => {
			this.toastService.sendCustomToast(
				'This may take some time. Check the progress above.',
				ToastNotificationType.Info,
				3000,
				'We have started Publishing!'
			);
		}, 3000);*/
	}

	// private getTreeData(): void {
	// 	this.smartCreateService
	// 		.getPushingAdsetData()
	// 		.pipe(
	// 			take(3),
	// 			catchError(() => {
	// 				this.toastService.sendCustomToast(
	// 					'Something went wrong, please try again later!',
	// 					ToastNotificationType.Warning,
	// 					4000,
	// 					'Error ! Could not Publish!'
	// 				);
	// 				return EMPTY;
	// 			})
	// 		)
	// 		.subscribe(response => {
	// 			if (!response) {
	// 				this.treeData = response;
	// 			}
	// 		});
	// }
	private getData(): void {
		this.treeData = {
			name: this.campaignDetails.stepTwoDetailsAsJson.name,
			icon: '',
			children: []
		};
		if (this.step5Details && this.step5Details?.splitHistory?.length > 0) {
			const splitHistories = this.step5Details.splitHistory;
			const totalSplitLength = splitHistories.length;
			let splitHistoryTreeData: HierarchyDatum[] = [];
			splitHistoryTreeData = this.formatSplit(splitHistories[0], []);
			if (splitHistories.length > 1) {
				splitHistoryTreeData.map(splitHistory => {
					let oldData = [];
					for (let splits = totalSplitLength - 1; splits > 0; splits--) {
						const splitData = splitHistories[splits];
						oldData = this.formatSplit(splitData, oldData);
					}
					return (splitHistory.children = oldData);
				});
			}
			this.treeData.children = splitHistoryTreeData;
		}
	}
	private formatSplit(data: SplitHistoryInterface, oldData = []): HierarchyDatum[] {
		const formatData: HierarchyDatum[] = [];
		if (data && data.groups.length > 0) {
			data?.groups?.forEach((group, index) => {
				formatData.push({
					name: this.generateName(group, data.field),
					icon: `assets/icons/campaign-smart-create/optimization/${data.field}.svg`,
					children: oldData
				});
			});
		}
		return formatData;
	}
	private generateName(name: string, field: string): string {
		if (field === 'interest') {
			return `interest`;
		}
		if (field === 'age') {
			return `${name} year olds`;
		}
		return name;
	}
	public runBackground(): void {
		this.router.navigate(['/ads-manager/insights']);
	}
}
