import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DropdownInterface } from '@filed-com/filed-lib/lib/dropdowns/models/dropdown-interface';
//import { DrawerSmartEditInterface } from '@filed-com/filed-lib/lib/tree-view/models/drawer-smart-edit-interface';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { EMPTY, Subject } from 'rxjs';
import { catchError, skipWhile, take, takeUntil } from 'rxjs/operators';
import { convertToAdModel, convertToAdsetModel } from 'src/app/AAA/helpers/helper';
import { AAAAdService } from 'src/app/AAA/services/aaa-ad.service';
import { UpdateAdsetState } from 'src/app/AAA/state/adset-state/adset-state.action';
import { AdsManagerStatusEnum } from 'src/app/ads-manager/ads-manager-insights/models/ads-manager-status.enum';
import { UpdateAdInterface } from 'src/app/ads-manager/ads-manager-insights/models/update-ad.interface';
import { UpdateAdsetInterface } from 'src/app/ads-manager/ads-manager-insights/models/update-adset.interface';
import { UpdateCampaignInterface } from 'src/app/ads-manager/ads-manager-insights/models/update-campaign.interface';
import { AdSetsService } from 'src/app/ads-manager/ads-manager-insights/services/ad-sets.service';
import { AdsService } from 'src/app/ads-manager/ads-manager-insights/services/ads.service';
import { CampaignsService } from 'src/app/ads-manager/ads-manager-insights/services/campaigns.service';
import { UpdateSelectedAds, UpdateSelectedAdSets, UpdateSelectedCampaigns } from 'src/app/ads-manager/state/ads-manager.actions';
import { AdsManagerState } from 'src/app/ads-manager/state/ads-manager.reducer';
import { IDexterHealthRequest, IDexterHealthScoreResponse } from 'src/app/optimise/models/dexter-health.interface';
import { OptimizeService } from 'src/app/optimise/services/optimize.service';
import { SmartEditService } from 'src/app/smart-edit/services/smart-edit.service';
import { UpdateState } from 'src/app/smart-edit/state/ad/ad.action';
import { LoadSelectedCampaign } from 'src/app/smart-edit/state/campaign/campaign.action';
import { CampaignSmartEditState } from 'src/app/smart-edit/state/campaign/campaign.reducer';
import { UpdateSelectedIds } from 'src/app/smart-edit/state/smart-edit.actions';
import { PrioritiesEnum } from '../../optimise/enums/priorities.enum';
import { OptimiseChartsHelperService } from '../../optimise/services/optimise-charts-helper.service';
import { ChartTypeEnum } from '../../reports/shared/charts/chart-type.enum';
import { DateRangeX } from '../calendar/calendar.models';
import { ChartDataBuilder } from '../charts2/chart-models/chart-data-builder';
import { ChartDataModel } from '../charts2/chart-models/chart-data.model';
import { ChartSeriesModel } from '../charts2/chart-models/chart-series.model';
import { MarkLine } from '../charts2/chart-models/mark-line.model';
import { DateHelper } from '../charts2/helper/date-helper';
import { PopUpInterface } from '../models/generic-popup-interface';
import { GenericPopupComponent } from '../pop-ups/generic-popup/generic-popup.component';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../state/shared.actions';
import { getSelectedAdAccount, SharedState } from '../state/shared.reducer';
import { UserState } from '../state/user/user.reducer';
import { ApplyDefinitions } from './models/apply-tooltips';
import { ChartDataRange } from './models/chart-data-range';
import { DaysToSlice } from './models/days-to-slice';
import { NewRecommendationInterface } from './models/new-recommendation.interface';

@Component({
	selector: 'app-recommendations-new-template',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendations-new-template.component.html',
	styleUrls: ['./recommendations-new-template.component.scss']
})
export class RecommendationsNewTemplateComponent implements OnInit, OnDestroy {
	@Input() public newRecommendation: NewRecommendationInterface;
	@Input() public isOnSmartEdit: boolean = false;

	@Output() public onDismissClick = new EventEmitter<string>();
	@Output() public onApplyClick = new EventEmitter<string>();

	public isExpanded = false;
	public isDismissVisible = false;
	public isDismissed = false;
	public isLoading = false;
	public isLoaded = false;
	public threeDotsImageSource = 'assets/icons/optimize/three-dots-option.svg';
	public iconUrl: string;
	public chartDataModel: ChartDataModel;
	public borderColor: string;
	public prioritySrc: string;
	public applyTooltip: string;
	public chartColorArray: string[] = [
		'#5f8ad0',
		'#23c103',
		'#eb6348',
		'#fcc823',
		'#fd2700',
		'#f38cc1',
		'#9f0f2a',
		'#fd00ba',
		'#0ceeeb',
		'#eff386',
		'#95f6a2'
	];
	public chartTypes: DropdownInterface[] = [
		{
			name: 'Line Chart',
			id: 1,
			data: ChartTypeEnum.Line
		},
		{
			name: 'Bar Chart',
			id: 2,
			data: ChartTypeEnum.Bar
		}
	];
	public activeChartTypeControl: FormControl;
	public activeChartType: ChartTypeEnum;
	public dates: string[];
	public newChartDataLines: ChartSeriesModel[];

	//public drawerTreeData: DrawerSmartEditInterface[] = null;
	public dexterHealthScore: IDexterHealthScoreResponse = null;

	public dataRange: string;

	private unsubscriber$ = new Subject<void>();
	public showAll = true;
	public aboveOneDay = true;
	public aboveThreeDays = true;
	public aboveOneWeek = true;
	public aboveTwoWeeks = true;
	public aboveOneMonth = true;
	public aboveTwoMonths = true;
	public isCyb: boolean;

	constructor(
		private matDialog: MatDialog,
		private optimiseChartsHelperService: OptimiseChartsHelperService,
		private changeDetectorRef: ChangeDetectorRef,
		private el: ElementRef,
		private router: Router,
		public smartEditService: SmartEditService,
		private smartEditCampaignStore: Store<CampaignSmartEditState>,
		public sharedStore: Store<SharedState>,
		public store: Store<AdsManagerState>,
		private aaaAdservice: AAAAdService,
		private campaignService: CampaignsService,
		private adsService: AdsService,
		private adsetService: AdSetsService,
		public optimizeService: OptimizeService,
		private userStore: Store<UserState>
	) {}

	public ngOnInit(): void {
		this.isCyb = this.newRecommendation?.algorithmType === 'breakdown_strategy' ? true : false;
		this.activeChartTypeControl = new FormControl(this.chartTypes[0]);
		this.activeChartType = this.chartTypes[0].data;
		this.showAll = true;
		this.checkPriority();
		this.getApplyToolTip();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onToggleSeeMore(): void {
		if (!this.isExpanded && !(this.isLoaded && this.isLoading)) {
			if (!this.isLoaded) {
				this.isLoading = true;
				this.getDataForChart();
			}
		}
		this.isExpanded = !this.isExpanded;
	}

	public setBorder(): Partial<CSSStyleDeclaration> {
		return {
			borderLeft: `5px solid ${this.borderColor}`
		};
	}

	public onMouseEnterOptionsContainer(): void {
		this.threeDotsImageSource = 'assets/icons/optimize/three-dots-option-active.svg';
	}

	public onMouseLeaveOptionsContainer(): void {
		this.threeDotsImageSource = 'assets/icons/optimize/three-dots-option.svg';
	}

	public onOptionsButtonClick(): void {
		this.isDismissVisible = true;
	}

	public onClickOutsideOption(): void {
		if (this.isDismissVisible) {
			this.isDismissVisible = false;
			this.threeDotsImageSource = 'assets/icons/optimise/three-dots-option.svg';
		}
	}

	public onDismissButtonClick(): void {
		this.matDialog
			.open<GenericPopupComponent, PopUpInterface>(GenericPopupComponent, {
				data: {
					message: `If you dismiss this recommendation, it will not be displayed anymore for today.`,
					actionAnswer: 'Dismiss',
					noActionAnswer: 'Cancel',
					header: 'Are you sure?',
					image: '/assets/icons/warning-exclamation-triangle-icon.svg'
				},
				panelClass: ['generic-popup']
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((confirmed: boolean) => {
				this.isDismissVisible = false;
				this.threeDotsImageSource = 'assets/icons/optimise/three-dots-option.svg';
				this.changeDetectorRef.detectChanges();
				if (confirmed) {
					this.onDismissClick.emit(this.newRecommendation.recommendationId);
				}
			});
	}

	public onApplyButtonClick(): void {
		this.onApplyClick.emit(this.newRecommendation.recommendationId);
	}

	public getDataForChart(): void {
		const medianMarkLine: MarkLine = {
			data: [
				{
					name: `Bucket - ${this.newRecommendation.metrics[0]?.trendAverage.bucket}`,
					yAxis: this.newRecommendation.metrics[0]?.trendAverage.value,
					label: {
						show: true,
						position: 'middle',
						color: 'black',
						fontSize: 14,
						formatter: `Bucket - ${this.newRecommendation.metrics[0]?.trendAverage.bucket}`
					},
					lineStyle: {
						color: 'orange'
					}
				}
			]
		};
		if (this.isCyb) {
			const timeRange: DateRangeX<moment.Moment> = {
				endDate: moment(),
				startDate: moment().subtract(90, 'days')
			};
			this.optimiseChartsHelperService
				.getDataForChartWithQueryBuilder(this.newRecommendation, timeRange, null, null, this.isCyb)
				.pipe(take(1))
				.subscribe(newChartDataLines => {
					newChartDataLines.forEach((item, index) => {
						newChartDataLines[index].smooth = true;
					});
					const dates = DateHelper.getDaysInDateRange(timeRange);
					this.dates = newChartDataLines[0].labels;
					this.newChartDataLines = newChartDataLines;

					// extract required data for cyb
					const trimmedLabels = [];
					const trimmedData = [];
					const colorCode = [];
					for (let i = 0; i < this.newRecommendation.performingBreakdowns.length; i++) {
						const foundDataIndex = this.newChartDataLines[0].labels.findIndex(value => {
							return value === this.newRecommendation.performingBreakdowns[i];
						});
						trimmedData.push(this.newChartDataLines[0].data[foundDataIndex]);
						const foundColorMatch = this.newRecommendation.underperformingBreakdowns.findIndex(value => {
							return value === this.newRecommendation.performingBreakdowns[i];
						});
						colorCode.push(foundColorMatch ? 1 : 0);
					}
					newChartDataLines[0].labels = this.newRecommendation.performingBreakdowns;
					newChartDataLines[0].data = trimmedData;
					newChartDataLines[0].colorCode = colorCode;
					this.updateChart(newChartDataLines[0].labels, newChartDataLines, this.isCyb);
					this.isLoaded = true;
					this.isLoading = false;
					this.changeDetectorRef.detectChanges();
				});
			return;
		}
		const timeRange: DateRangeX<moment.Moment> = {
			endDate: moment(),
			startDate: moment().subtract(90, 'days')
		};
		this.optimiseChartsHelperService
			.getDataForChartWithQueryBuilder(this.newRecommendation, timeRange)
			.pipe(take(1))
			.subscribe(newChartDataLines => {
				newChartDataLines.forEach((item, index) => {
					newChartDataLines[index].smooth = true;
				});

				newChartDataLines[0].markLine = medianMarkLine;
				const dates: string[] = [];
				newChartDataLines[0].chartData.forEach(value => {
					if (value.date_start) {
						dates.push(value.date_start);
					} else {
						dates.push(value.date);
					}
				});
				this.dates = dates;
				this.newChartDataLines = newChartDataLines;
				this.updateChart(dates, newChartDataLines);
				this.defaultDateRange();
				this.isLoaded = true;
				this.isLoading = false;
				this.changeDetectorRef.detectChanges();
			});
	}

	private updateChart(dates: string[], newChartDataLines: ChartSeriesModel[], isCyb: boolean = false): void {
		const gridData = this.optimiseChartsHelperService.createGridData();
		const legendData = this.optimiseChartsHelperService.createLegendData();
		const chartBuilder = new ChartDataBuilder(this.chartDataModel);
		this.chartDataModel = chartBuilder
			.categoricalData(dates)
			.continuousData(newChartDataLines)
			.chartType(isCyb ? (this.activeChartType = ChartTypeEnum.Bar) : this.activeChartType)
			.gridData(gridData)
			.legendData(legendData)
			.toolboxData({
				show: false,
				feature: {}
			})
			.build();
	}

	private checkPriority(): void {
		switch (this.newRecommendation.priority) {
			case PrioritiesEnum.High:
				this.iconUrl = 'assets/icons/optimize/red-circle-no-border-icon.svg';
				this.borderColor = '#DC3445';
				break;
			case PrioritiesEnum.Medium:
				this.iconUrl = 'assets/icons/optimize/yellow-circle-no-border-icon.svg';
				this.borderColor = '#FFC12F';
				break;
			case PrioritiesEnum.Low:
				this.iconUrl = 'assets/icons/optimize/green-circle-with-border-icon.svg';
				this.borderColor = '#09D8DE';
				break;
		}
	}

	private getApplyToolTip(): void {
		if (!this.newRecommendation.isApplicable) {
			this.applyTooltip = ApplyDefinitions.noApply;
		} else {
			this.applyTooltip = this.newRecommendation.applyTooltip;
		}
	}

	public changeChartType($event: any): void {
		this.activeChartType = $event.data;
		this.changeDataRange(this.dataRange);
	}

	public changeDataRange(range: string): void {
		this.dataRange = range;
		let filteredDates = [];
		let filteredNewChartDataLines: ChartSeriesModel[] = [];
		filteredDates = [...this.dates];
		filteredNewChartDataLines = JSON.parse(JSON.stringify(this.newChartDataLines));
		const dataNoNull: any = [];
		filteredNewChartDataLines[0].data.forEach(value => {
			value ? dataNoNull.push(value) : null;
		});
		filteredNewChartDataLines[0].data = dataNoNull;

		if (range !== ChartDataRange.Max) {
			this.showAll = false;
			switch (range) {
				case ChartDataRange.OneDay:
					filteredDates = filteredDates.slice(DaysToSlice.OneDay);
					filteredNewChartDataLines.forEach(dataLine => {
						dataLine.data = dataLine.data.slice(DaysToSlice.OneDay);
					});
					break;
				case ChartDataRange.ThreeDays:
					filteredDates = filteredDates.slice(DaysToSlice.ThreeDays);
					filteredNewChartDataLines.forEach(dataLine => {
						dataLine.data = dataLine.data.slice(DaysToSlice.ThreeDays);
					});
					break;
				case ChartDataRange.OneWeek:
					filteredDates = filteredDates.slice(DaysToSlice.OneWeek);
					filteredNewChartDataLines.forEach(dataLine => {
						dataLine.data = dataLine.data.slice(DaysToSlice.OneWeek);
					});
					break;
				case ChartDataRange.TwoWeeks:
					filteredDates = filteredDates.slice(DaysToSlice.TwoWeeks);
					filteredNewChartDataLines.forEach(dataLine => {
						dataLine.data = dataLine.data.slice(DaysToSlice.TwoWeeks);
					});
					break;
				case ChartDataRange.OneMonth:
					filteredDates = filteredDates.slice(DaysToSlice.OneMonth);
					filteredNewChartDataLines.forEach(dataLine => {
						dataLine.data = dataLine.data.slice(DaysToSlice.OneMonth);
					});
					break;
				case ChartDataRange.TwoMonths:
					filteredDates = filteredDates.slice(DaysToSlice.TwoMonths);
					filteredNewChartDataLines.forEach(dataLine => {
						dataLine.data = dataLine.data.slice(DaysToSlice.TwoMonths);
					});
					break;

				default:
					break;
			}
		} else {
			this.showAll = true;
		}

		this.updateChart(filteredDates, filteredNewChartDataLines);
		this.isLoaded = true;
		this.isLoading = false;
		this.changeDetectorRef.detectChanges();
	}

	private defaultDateRange(): void {
		if (this.newRecommendation.timeInterval === 3) {
			this.dataRange = ChartDataRange.ThreeDays;
		} else if (this.newRecommendation.timeInterval === 1) {
			this.dataRange = ChartDataRange.OneDay;
		} else if (this.newRecommendation.timeInterval === 7) {
			this.dataRange = ChartDataRange.OneWeek;
		} else if (this.newRecommendation.timeInterval === 14) {
			this.dataRange = ChartDataRange.TwoWeeks;
		} else if (this.newRecommendation.timeInterval === 30) {
			this.dataRange = ChartDataRange.OneMonth;
		} else if (this.newRecommendation.timeInterval === 60) {
			this.dataRange = ChartDataRange.TwoMonths;
		} else if (this.newRecommendation.timeInterval === 90) {
			this.dataRange = ChartDataRange.Max;
		}
		this.changeDataRange(this.dataRange);
	}

	private enableButtonRange(): void {
		if (this.dates.length > 0 && this.dates.length < 3) {
			this.aboveOneDay = true;
		} else if (this.dates.length >= 3 && this.dates.length < 7) {
			this.aboveOneDay = true;
			this.aboveThreeDays = true;
		} else if (this.dates.length >= 7 && this.dates.length < 14) {
			this.aboveOneDay = true;
			this.aboveThreeDays = true;
			this.aboveOneWeek = true;
		} else if (this.dates.length >= 14 && this.dates.length < 30) {
			this.aboveOneDay = true;
			this.aboveThreeDays = true;
			this.aboveOneWeek = true;
			this.aboveTwoWeeks = true;
		} else if (this.dates.length >= 30 && this.dates.length < 60) {
			this.aboveOneDay = true;
			this.aboveThreeDays = true;
			this.aboveOneWeek = true;
			this.aboveTwoWeeks = true;
			this.aboveOneMonth = true;
		} else if (this.dates.length >= 60) {
			this.aboveOneDay = true;
			this.aboveThreeDays = true;
			this.aboveOneWeek = true;
			this.aboveTwoWeeks = true;
			this.aboveOneMonth = true;
			this.aboveTwoMonths = true;
		}
	}

	public onEditButtonClick(): void {
		const structureId = this.newRecommendation.structureId;
		const structureName = this.newRecommendation.structureName;
		const level = this.newRecommendation.level;
		switch (level) {
			case 'campaign':
				this.navigateCampaign(structureId, structureName);
				break;
			case 'ad':
				this.navigateAds(structureId, structureName);
				break;
			case 'adset':
				this.navigateAdSet(structureId, structureName);
				break;
			default:
				break;
		}
		this.smartEditService.dexterRecommendation$.next(this.newRecommendation);
	}

	private navigateCampaign(structureId: string, structureName: string): void {
		const adData: UpdateCampaignInterface = {
			isChosen: true,
			selectedRow: {
				name: structureName,
				campaign_id: structureId
			}
		};
		this.store.dispatch(new UpdateSelectedCampaigns(adData));
		this.router.navigate(['/smart-edit/default/', { level: 'campaign' }]);
	}
	private navigateAdSet(structureId: string, structureName: string): void {
		const adData: UpdateAdsetInterface = {
			isChosen: true,
			selectedRow: {
				adset_name: structureName,
				adset_id: structureId
			}
		};
		this.store.dispatch(new UpdateSelectedAdSets(adData));
		this.router.navigate(['/smart-edit/default/', { level: 'adset' }]);
	}
	private navigateAds(structureId: string, structureName: string): void {
		const adData: UpdateAdInterface = {
			isChosen: true,
			selectedRow: {
				ad_name: structureName,
				ad_id: structureId
			}
		};
		this.store.dispatch(new UpdateSelectedAds(adData));
		this.router.navigate(['/smart-edit/default/', { level: 'ad' }]);
	}
}
