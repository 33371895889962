import { InjectionToken } from '@angular/core';

export const OtpComponentConfigToken = new InjectionToken<OtpComponentConfig>('OtpComponentConfig');

export interface OtpComponentConfig {
	codeLength?: number;
	inputType?: string;
	inputMode?: string;
	initialFocusField?: number;
	isCharsCode?: boolean;
	isCodeHidden?: boolean;
	isPrevFocusableAfterClearing?: boolean;
	isFocusingOnLastByClickIfFilled?: boolean;
	code?: string | number;
	disabled?: boolean;
	autocapitalize?: string;
}

export const defaultComponentConfig: OtpComponentConfig = {
	codeLength: 4,
	inputType: 'tel',
	inputMode: 'numeric',
	initialFocusField: undefined,
	isCharsCode: false,
	isCodeHidden: false,
	isPrevFocusableAfterClearing: true,
	isFocusingOnLastByClickIfFilled: false,
	code: undefined,
	disabled: false,
	autocapitalize: undefined
};
