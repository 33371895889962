import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AdModel } from '../../models/ad.model';
import { AddAd, RemoveAd, UpdateCurrentAdId, UpdatePixelToggle } from '../../state/ad-state/ad-state.actions';
import { AdState } from '../../state/ad-state/ad-state.reducer';
import * as fromAdState from '../../state/ad-state';
import { AAAModuleState } from '../../state';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { SingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-ad-navigation',
	templateUrl: './ad-navigation.component.html',
	styleUrls: ['./ad-navigation.component.scss']
})
export class AdNavigationComponent implements OnInit {
	@Input() public isSelected: boolean;
	@Input() public expansionTitle: string;
	@Input() public label: string;
	@Input() public placeholder: string;
	@Input() public showContentMargin = true;
	protected unsubscriber$ = new Subject<void>();
	public info: InfoHoverDataModel = { message: `The Ad Navigation allows you to navigate between your ads and edit them in a bulk.` };

	public ads: AdModel[] = [];
	public selectedAdIndex: number;

	constructor(private store: Store<AAAModuleState>) {}

	ngOnInit(): void {
		this.registerGetAds();
		this.registerGetSelectedAdIndex();
	}

	public addNewAd(): void {
		const ad = new AdModel();
		this.store.dispatch(new AddAd(ad));
		this.store.dispatch(new UpdatePixelToggle(false));
	}

	public registerGetAds(): void {
		this.store.pipe(select(fromAdState.getAds), takeUntil(this.unsubscriber$)).subscribe(ads => {
			this.ads = ads;
		});
	}

	public registerGetSelectedAdIndex(): void {
		this.store.pipe(select(fromAdState.getCurrentAdIndex), takeUntil(this.unsubscriber$)).subscribe(index => {
			this.selectedAdIndex = index;
		});
	}

	public removeAd(index: number) {
		this.store.dispatch(new RemoveAd(index));
	}

	public selectAd(index: number): void {
		this.store.dispatch(new UpdateCurrentAdId(index));
	}

	public imageExists(ad: AdModel): boolean {
		return !!(ad?.adverts as SingleAdvertDto)?.media_url;
	}

	public getImgSrc(ad: AdModel): string {
		return (ad.adverts as SingleAdvertDto).media_url || ad.adverts.picture;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
