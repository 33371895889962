import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Ng5SliderModule } from 'ng5-slider';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AuthenticationGuard } from './shared/guards/authentication.guard';
import { AudienceService } from './_services/audience/audience.service';
import { AuthenticationService } from './_services/authentication.service';
import { InterceptorsProvider } from './_services/interceptors/intercepors.provider';
import { ExcelService } from './_services/excel.service';
import { AdAccountApiService } from './_services/facebook-accounts/ad-account-api.service';
import { BusinessOwnerService } from './_services/facebook-accounts/business-owner.service';
import { PageService } from './_services/facebook-accounts/page.service';
import { AdvertService } from './_services/facebook-marketing/advert.service';
import { OptimizationService } from './_services/optimization/optimization.service';
import { PixelService } from './_services/pixel/pixel.service';
import { PixelServiceApi } from './_services/pixel/pixel.api.service';
import { PopupDialogService } from './_services/popup-dialog.service';
import { SchedulesService } from './reports/services/api-services/schedules.service';
import { SidenavService } from './_services/side-nav.service';
import { TableService } from './_services/table.service';
import { UserServiceSubscription } from './_services/user/user-subscription.service';
import { UserServiceApi } from './_services/user/user.api.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { SidenavModule } from './sidenav/sidenav.module';
import { UserRoleAdminGuard } from './shared/guards/user-role-admin-guard.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { from, Observable } from 'rxjs';
import { DashboardService } from './_services/dashboard/dashboard-service';
import { AuthenticationRedirectGuard } from './shared/guards/authentication-redirect.guard';
import { GoogleService } from './_services/google/google.service';
import { AccountTypeService } from './shared/account-type.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UrlSerializer } from '@angular/router';

import { CaseInsensitiveUrlSerializer } from './shared/case-insensitive-url-serializer.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { ComponentRegistryService } from './shared/component-registry.service';
import { ChartTypeEnum } from './reports/shared/charts/chart-type.enum';
import { LineChartComponent } from './reports/shared/charts/line-chart/line-chart.component';
import { BarChartComponent } from './reports/shared/charts/bar-chart/bar-chart.component';
import { HorizontalBarChartComponent } from './reports/shared/charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { GroupedHorizontalBarChartComponent } from './reports/shared/charts/grouped-horizontal-bar-chart/grouped-horizontal-bar-chart.component';
import { GroupedVerticalBarChartComponent } from './reports/shared/charts/grouped-vertical-bar-chart/grouped-vertical-bar-chart.component';
import { HorizontalStackedBarChartComponent } from './reports/shared/charts/horizontal-stacked-bar-chart/horizontal-stacked-bar-chart.component';
import { VerticalStackedBarChartComponent } from './reports/shared/charts/vertical-stacked-bar-chart/vertical-stacked-bar-chart.component';
import { PieChartComponent } from './reports/shared/charts/pie-chart/pie-chart.component';
import { DonutChartComponent } from './reports/shared/charts/donut-chart/donut-chart.component';
import { AreaChartComponent } from './reports/shared/charts/area-chart/area-chart.component';
import { HeatMapChartComponent } from './reports/shared/charts/heat-map-chart/heat-map-chart.component';
import { TableChartComponent } from './reports/shared/charts/table-chart/table-chart.component';
import { SingleNumberChartComponent } from './reports/shared/charts/single-number-chart/single-number-chart.component';
import { clearStateMetaReducer, initialAppState, REDUCER_TOKEN, reducers } from './state/app.state';
import { PixelResolver } from './pixel/resolver/pixel.resolver';
import { CampaignSmartCreateModule } from './campaign-smart-create/campaign-smart-create.module';
import { MapperService } from './shared/services/mapper.service';
import { ErrorsLoggingService } from './_services/errors-logging/errors-logging.service';
// import { GoogleAdAccountService } from './_services/google-accounts/ad-account.service';
import { ErrorsLoggingServiceApi } from './_services/errors-logging/errors-logging-api.service';
import { InstagramService } from './_services/facebook-accounts/instagram.service';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NgxEchartsModule } from 'ngx-echarts';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FiledLibMapperService } from './shared/services/filed-lib-Mapper.service';
import { CampaignCommonEffect } from './state/campaign-common-state/campaign-common-effect';
import { EditorModule } from '@progress/kendo-angular-editor';
import { GridModule } from '@progress/kendo-angular-grid';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ExportCatalogRedirectResolver } from './export-catalog/resolver/export-catalog-redirect.resolver';
import { ExportCatalog } from './export-catalog/service/export-catalog.service';
import { SharedService } from './export-catalog/service/shared.service';
import { UserBusinessOwnerGuard } from './shared/guards/user-business-owner.guard';
import { UserAdminComponent } from './user-management/new-sprint/components/user-admin/user-admin.component';
import { AuthenticationRouteGuard } from './shared/guards/authentication-route.guard';
import { UserBlockGuard } from './shared/guards/user-block.guard';

export class WebpackTranslateLoader implements TranslateLoader {
	getTranslation(lang: string): Observable<any> {
		return from(import(`../assets/i18n/${lang}.json`));
	}
}

@NgModule({
	declarations: [AppComponent, UserAdminComponent],
	exports: [],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: WebpackTranslateLoader
			}
		}),
		StoreModule.forRoot(REDUCER_TOKEN, {
			metaReducers: [clearStateMetaReducer],
			initialState: initialAppState,
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false
			}
		}),
		EffectsModule.forRoot([CampaignCommonEffect]),
		StoreDevtoolsModule.instrument({
			name: 'Filed',
			maxAge: 50
		}),
		AgGridModule.withComponents([]),
		BrowserModule,
		Ng5SliderModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		SharedModule,
		SidenavModule,
		SlickCarouselModule,
		AuthenticationModule,
		CampaignSmartCreateModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts')
		}),
		InputsModule,
		LabelModule,
		DropDownsModule,
		NotificationModule,
		LayoutModule,
		TooltipModule,
		DialogsModule,
		DateInputsModule,
		EditorModule,
		GridModule
	],
	providers: [
		{
			provide: REDUCER_TOKEN,
			useValue: reducers
		},
		SharedService,
		ExportCatalogRedirectResolver,
		ErrorsLoggingService,
		ErrorsLoggingServiceApi,
		MapperService,
		FiledLibMapperService,
		PixelResolver,
		ExportCatalog,
		AuthenticationService,
		UserServiceApi,
		UserServiceSubscription,
		SidenavService,
		TableService,
		OptimizationService,
		AdvertService,
		PageService,
		InstagramService,
		PixelService,
		PixelServiceApi,
		BusinessOwnerService,
		AdAccountApiService,
		AudienceService,
		ExcelService,
		AuthenticationGuard,
		UserRoleAdminGuard,
		InterceptorsProvider,
		SchedulesService,
		PopupDialogService,
		DashboardService,
		AuthenticationRedirectGuard,
		GoogleService,
		AccountTypeService,
		UserBusinessOwnerGuard,
		AuthenticationRouteGuard,
		UserBlockGuard,

		ComponentRegistryService,
		{
			provide: UrlSerializer,
			useClass: CaseInsensitiveUrlSerializer
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(componentRegistryService: ComponentRegistryService) {
		componentRegistryService.register(ChartTypeEnum.Line, LineChartComponent);
		componentRegistryService.register(ChartTypeEnum.Bar, BarChartComponent);
		componentRegistryService.register(ChartTypeEnum.HorizontalBar, HorizontalBarChartComponent);
		componentRegistryService.register(ChartTypeEnum.GroupedHorizontalBar, GroupedHorizontalBarChartComponent);
		componentRegistryService.register(ChartTypeEnum.GroupedVerticalBar, GroupedVerticalBarChartComponent);
		componentRegistryService.register(ChartTypeEnum.HorizontalStackedBar, HorizontalStackedBarChartComponent);
		componentRegistryService.register(ChartTypeEnum.VerticalStackedBar, VerticalStackedBarChartComponent);
		componentRegistryService.register(ChartTypeEnum.Pie, PieChartComponent);
		componentRegistryService.register(ChartTypeEnum.Donut, DonutChartComponent);
		componentRegistryService.register(ChartTypeEnum.Area, AreaChartComponent);
		componentRegistryService.register(ChartTypeEnum.Heat, HeatMapChartComponent);
		componentRegistryService.register(ChartTypeEnum.Table, TableChartComponent);
		componentRegistryService.register(ChartTypeEnum.SingleNumber, SingleNumberChartComponent);
	}
}
