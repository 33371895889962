import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
	selector: '[appInputNumberValue]'
})
export class InputNumberValueDirective implements OnInit, OnDestroy {
	@Input('decimals') decimals = 0;
	@Input('formControl') formControl: FormControl;

	private currentValue: number = null;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor() {}

	ngOnInit() {
		this.currentValue = this.formControl.value;

		this.formControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (!value || (value && this.check(value, this.decimals))) {
				this.currentValue = value;
			} else {
				this.formControl.setValue(this.currentValue);
			}
		});
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private check(value: string, decimals: number) {
		if (decimals <= 0) {
			return String(value).match(new RegExp(/^\d+$/));
		} else {
			const regExpString = '^\\s*((\\d+(\\.\\d{0,' + decimals + '})?)|((\\d*(\\.\\d{1,' + decimals + '}))))\\s*$';
			return String(value).match(new RegExp(regExpString));
		}
	}
}
