import { Component, Injector, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioButton } from '@angular/material/radio';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY, of, Subject } from 'rxjs';
import { catchError, debounceTime, takeUntil, take } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { CardDetailsInterface, TarrifPlansInterface } from '../../sign-up/subscribe.interface';
import { LogIn, LogInSuccess } from '../../state/authentication.action';
import { getUserRegistrationDetail } from '../../state/authentication.reducer';
import { PaymentConfirmPopupComponent } from '../payment-confirm-popup/payment-confirm-popup.component';
declare var bluesnap: any;

@Component({
	selector: 'app-payment-page',
	templateUrl: './payment-new-page.component.html',
	styleUrls: ['./payment-new-page.component.scss']
})
export class PaymentPageComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public planDetails: any;
	public cardDetails: FormGroup;
	public expirationDate: FormControl;
	public cardNumber: FormControl;

	public submitting: boolean;

	public currentRoute: string;

	public countries: Country[];
	public countryData: any[] = [];
	public formControlsRequests: any = {};
	public isNewCard: boolean = true;
	public hasUserAgreed: boolean = false;
	public paymentModeSelected: boolean = false;
	public paymentWallType: string;
	public PfToken: string;

	bnccNoFormControl = new FormControl('', [Validators.required]);
	expiryDateFormControl = new FormControl('', [Validators.required]);
	cvcFormControl = new FormControl('', [Validators.required]);
	nameFormControl = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern(/^[a-zA-Z ]*$/)]);
	billingFormControl = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]);
	zipFormControl = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(7), Validators.pattern(/^[0-9]+$/)]);
	cityFormControl = new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern(/^[A-Za-z ]+$/)]);
	emailFormControl = new FormControl('', [
		Validators.required,
		Validators.minLength(3),
		Validators.maxLength(70),
		Validators.pattern(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
	]);

	public countryControlField: FormControl = new FormControl(null, [Validators.required]);

	@ViewChild('ccNumber') ccNumberField!: ElementRef;
	@ViewChild('bluesnapToggle') bluesnapToggle: MatRadioButton;

	protected sharedStore: Store<SharedState>;
	emailValue: string;
	constructor(
		private injector: Injector,
		private formBuilder: FormBuilder,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private router: Router,
		public backOfficeService: BackOfficeService,
		public activatedRoute: ActivatedRoute,
		private userService: UserServiceApi,
		public matDialog: MatDialog,
		private authService: AuthenticationService
	) {
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
		this.submitting = true;

		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			this.token = decodeURIComponent(params['token']);
			this.emailValue = decodeURIComponent(params['email']);

			if (this.token && this.token !== 'undefined') {
				this.userService
					.verifyLollyEmail(this.emailValue, this.token)
					.pipe(
						take(1)
						// catchError(error => {
						// 	this.sharedStore.dispatch(new HideGlobalSpinner());
						// 	this.submitting = false;
						// 	this.toastService.sendErrorToast('Invalid ', error);
						// 	this.router.navigate(['authentication']);
						// 	return error;
						// })
					)
					.subscribe(response => {
						if (response?.length > 0) {
							this.sharedStore.dispatch(new LogInSuccess(response));
						} else {
							this.toastService.sendErrorToast('Invalid response');
							this.router.navigate(['authentication']);
						}
						this.sharedStore.dispatch(new HideGlobalSpinner());
						// this.submitting = false;
					});
			} else if (!this.authService.isLoggedIn()) {
				this.router.navigate(['authentication']);
				this.sharedStore.dispatch(new HideGlobalSpinner());
			}
		});
	}

	ngOnInit(): void {
		const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		this.emailFormControl = new FormControl('', [Validators.required, Validators.pattern(emailValidator), Validators.maxLength(70)]);

		setTimeout(() => {
			this.setDropdownCountries();
			this.getTarrifDetails();
		}, 5000);
	}

	ngAfterViewInit(): void {
		this.loadScript();
	}

	private loadScript(cardChange = true) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = BaseApiUrl.BlueSnap;
		const head = document.getElementsByTagName('head')[0];
		if (head !== null && head !== undefined) {
			document.head.appendChild(script);
			// cardChange ? this.cardTypeChanged({ value: '1' }) : null;
		}
	}

	public getPfToken(): void {
		this.submitting = true;

		if (this.paymentWallType == 'BlueSnap') {
			this.billingService
				.getPfToken()
				.pipe(
					takeUntil(this.unsubscriber$),
					catchError(error => {
						// this.submitting = false;
						return of(null);
					})
				)
				.subscribe(res => {
					this.PfToken = res;

					setTimeout(() => {
						this.onReady();
					}, 200);
					this.submitting = false;
				});
		} else if (this.paymentWallType == 'PayPal') {
			this.submitting = false;
		}
	}

	public onReady(): void {
		var cardUrl = {
			AMEX: 'https://files.readme.io/97e7acc-Amex.png',
			DINERS: 'https://files.readme.io/8c73810-Diners_Club.png',
			DISCOVER: 'https://files.readme.io/caea86d-Discover.png',
			JCB: 'https://files.readme.io/e076aed-JCB.png',
			MASTERCARD: 'https://files.readme.io/5b7b3de-Mastercard.png',
			VISA: 'https://files.readme.io/9018c4f-Visa.png'
		};
		var bsObj = {
			//insert your Hosted Payment Fields token
			token: this.PfToken,
			onFieldEventHandler: {
				onFocus: function (tagId) {
					// Handle focus
					changeImpactedElement(tagId, 'hosted-field-valid hosted-field-invalid', 'hosted-field-focus');
				},
				onBlur: function (tagId) {
					// Handle blur
					changeImpactedElement(tagId, 'hosted-field-focus', '');
				},
				onError: function (tagId, errorCode, errorDescription) {
					// Handle a change in validation
					changeImpactedElement(tagId, 'hosted-field-valid hosted-field-focus', 'hosted-field-invalid');
					var error: string;
					switch (tagId) {
						case 'ccn':
							error = 'Card Invalid';
							onFormError(tagId);
							break;
						case 'exp':
							error = 'Expiry Date Invalid';
							onFormError(tagId);
							break;
						case 'cvv':
							error = 'CVV Invalid';
							onFormError(tagId);
							break;
					}
					$('#' + tagId + '-help')
						.removeClass('helper-text-green')
						.text(error);
				},
				onType: function (tagId, cardType, cardData) {
					// get card type from cardType and display card image
					$('#card-logo > img').attr('src', cardUrl[cardType]);
				},
				onValid: function (tagId) {
					// Handle a change in validation
					changeImpactedElement(tagId, 'hosted-field-focus hosted-field-invalid', 'hosted-field-valid');
					$('#' + tagId + '-help').text('');
					onFormValid(tagId);
				}
			},
			//styling is optional
			style: {
				// Styling all inputs
				input: {
					'font-size': '13px',
					'font-family': 'Helvetica Neue,Helvetica,Arial,sans-serif',
					'line-height': '1.42857143',
					color: '#4d4d4d'
				},

				// Styling a specific field
				/*"#ccn": {
								    
								},*/

				// Styling Hosted Payment Field input state
				':focus': {
					color: '#4d4d4d'
				}
			},
			ccnPlaceHolder: '1234 1234 1234 1234',
			cvvPlaceHolder: 'CVC',
			expPlaceHolder: 'MM/YY'
		};
		function changeImpactedElement(tagId, removeClass, addClass) {
			removeClass = removeClass || '';
			addClass = addClass || '';
			$('[data-bluesnap=' + tagId + ']')
				.removeClass(removeClass)
				.addClass(addClass);
		}
		const onFormValid = (tagId): void => {
			switch (tagId) {
				case 'ccn':
					this.bnccNoFormControl.patchValue(tagId);
					break;
				case 'exp':
					this.expiryDateFormControl.patchValue(tagId);
					break;
				case 'cvv':
					this.cvcFormControl.patchValue(tagId);
					break;
			}
		};
		const onFormError = (tagId): void => {
			switch (tagId) {
				case 'ccn':
					this.bnccNoFormControl.patchValue(null);
					break;
				case 'exp':
					this.expiryDateFormControl.patchValue(null);
					break;
				case 'cvv':
					this.cvcFormControl.patchValue(null);
					break;
			}
		};
		bluesnap?.hostedPaymentFieldsCreate(bsObj);
	}

	public paywithPftoken(): void {
		bluesnap?.hostedPaymentFieldsSubmitData(callback => {
			if (null != callback.error) {
				this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
			} else {
				this.finalSubmit();
			}
		});
	}

	private setDropdownCountries(): void {
		this.backOfficeService
			.getAllCountriesList()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.countryData = [
						{ value: { name: 'United Kingdom' }, name: 'United Kingdom', id: 1 },
						{ value: { name: 'United States of America' }, name: 'United States of America', id: 2 },
						{ value: { name: 'United Arab Emirates' }, name: 'United Arab Emirates', id: 3 }
					];

					return of(null);
				})
			)
			.subscribe(result => {
				this.countries = result;
				this.countryData = this.countries?.map((value, index) => {
					return {
						value: value,
						label: value.name,
						id: index,
						name: value.name
					};
				});
			});
	}

	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.cardDetails.controls['country'].setValue(selected);
		}
	}

	public onFormNameChange(): void {
		this.cardDetails.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {});
	}

	public getTarrifDetails(): void {
		this.submitting = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getUserTarrifPlan()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					return of(null);
				})
			)
			.subscribe((details: any) => {
				if (details) {
					details.amount = this.roundFigure(details.amount);
					var dates = details.nextInvoiceDate.split('T')[0].split('-');
					details.nextInvoiceDate = `${dates[2]}.${dates[1]}.${dates[0]}`;
				}
				this.planDetails = details;
				// this.loadScript();
				this.cardTypeChanged({ value: '1' });
			});
		this.sharedStore.dispatch(new HideGlobalSpinner());
	}

	get getPlanAmount(): string {
		let cur = this.planDetails?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.planDetails?.currency || 'USD'
		}).format(this.planDetails?.amount || 0);
		return cur + ' ' + amount;
	}

	get getCurrentlyChargingAmount(): string {
		let cur = this.planDetails?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.planDetails?.currency || 'USD'
		}).format(this.planDetails?.currentlyCharging && !this.planDetails?.isFreeTrial ? this.planDetails?.currentlyCharging : 0 || 0);
		return cur + ' ' + amount;
	}

	get getFreeTrialCurrentlyChargingAmount(): string {
		let cur = this.planDetails?.currency || '';
		let amount = new Intl.NumberFormat('ja-JP', {
			style: 'currency',
			currency: this.planDetails?.currency || 'USD'
		}).format(this.planDetails?.currentlyCharging && this.planDetails?.isFreeTrial ? this.planDetails?.currentlyCharging : 0 || 0);
		return cur + ' ' + amount;
	}

	public roundFigure(num: number): number {
		const factor = 10 ** 2;
		return Math.round(num * factor) / factor;
	}

	public continueSignup(): void {
		if (!this.formFieldsValid()) {
			return;
		}
		if (this.paymentWallType == 'BlueSnap') {
			this.paywithPftoken();
		} else if (this.paymentWallType == 'PayPal') {
			this.finalSubmit();
		}
	}

	public finalSubmit(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.submitting = true;
		const card_detail: CardDetailsInterface = {
			address1: this.billingFormControl.value,
			address2: '',
			city: this.cityFormControl.value,
			zipCode: this.zipFormControl.value,
			country: this.countryControlField.value.value.value,
			paymentWallType: this.paymentWallType,
			pfToken: this.PfToken
		};
		this.billingService
			.postCardDetails(card_detail)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detailResponse => {
					if (detailResponse.isSuccess == true) {
						switch (this.paymentWallType) {
							case 'BlueSnap':
								// this.router.navigate(['/authentication/payment-confirmation']);
								if (!this.planDetails.isFreeTrial) {
									this.matDialog
										.open(PaymentConfirmPopupComponent, {
											height: '444px',
											width: '762px',
											data: {
												price: this.getCurrentlyChargingAmount,
												reference: detailResponse.transactionId
											},
											disableClose: true
										})
										.afterClosed();
								} else {
									this.sharedStore.dispatch(new LogIn());
								}
								break;
							case 'PayPal':
								window.location.href = detailResponse.paypalTransaction.paypalUrl;
								break;
						}
					} else {
						this.getPfToken();
						this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
						this.sharedStore.dispatch(new HideGlobalSpinner());
						this.submitting = false;
					}
				},
				({ error }) => {
					this.getPfToken();
					this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.submitting = false;
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onMarkDefault(isChecked: boolean): void {
		this.hasUserAgreed = isChecked;
		this.cardDetails.controls['agreedToPrivacy'].setValue(isChecked);
	}
	public openPrivacyPolicy(): void {
		window.location.href = 'https://www.filed.com/privacy-policy-of-filed/';
	}

	cardTypeChanged(event): void {
		// this.submitting = true;
		this.paymentModeSelected = true;
		this.paymentWallType = event.value === '1' ? 'BlueSnap' : 'PayPal';

		setTimeout(() => {
			this.getPfToken();
		}, 500);
	}

	formFieldsValid(): boolean {
		let validity = false;
		if (
			this.paymentWallType === 'BlueSnap' &&
			this.bnccNoFormControl.valid &&
			this.expiryDateFormControl.valid &&
			this.nameFormControl.valid &&
			this.countryControlField.valid &&
			this.cityFormControl.valid &&
			this.zipFormControl.valid
		) {
			validity = true;
		} else if (
			this.paymentWallType === 'PayPal' &&
			this.emailFormControl.valid &&
			this.countryControlField.valid &&
			this.cityFormControl.valid &&
			this.zipFormControl.valid
		) {
			validity = true;
		}
		return validity;
	}
}
