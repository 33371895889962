<div class="container">
	<div class="wrapper">
		<div class="vertical-icon" [style.margin-bottom]="expanded ? 'blue' : '0px'">
			<span class="k-icon k-i-select-all"></span>
		</div>
		<div class="panel">
			<div class="panel">
				<div class="sub-sub-panel first" (click)="toggleExpansion()">
					<div>Add a Conditional Answer Set</div>
					<div>
						<button class="give-border">
							<i class="k-icon k-i-connector"></i>
							{{ placeholderText }}
						</button>
					</div>
				</div>
				<div *ngIf="expanded">
					<hr />
				</div>
			</div>

			<div [formGroup]="formControl" *ngIf="expanded">
				<div class="subpanel">
					<div>
						<label class="form-label">STEP 1</label>
					</div>
					<div>
						<font10>
							Arrange your conditional answers in a CSV file. Download a
							<a
								href="https://l.facebook.com/l.php?u=https%3A%2F%2Fstatic.xx.fbcdn.net%2Frsrc.php%2Fyo%2Fr%2F30PIN3ywZ7f.csv%3F_nc_eui2%3DAeFNsR0iQHrg1w_KEujMh2KFm5VDOSES_TWblUM5IRL9NQvq3f87EVOU8zIcfg4ym6Q&h=AT0QYWt-Q_XTOlyLmtopJJJZSRdKaoOfyZf7T-kGZWdTGsH36DaBzaqYvjORWeibzvTJXGh4TTzscwkKt-cggu848EmCV161qTFhUUGf2EfxjeOflX4DjmmrIEcum0MbdJXbMU23"
							>
								sample file
							</a>
							to use as a template or learn more about how to format your CSV.
						</font10>
					</div>
				</div>
				<div class="subpanel">
					<div>
						<label class="form-label">STEP 2</label>
					</div>
					<div>
						<btn03 [icon]="'attachment'">Upload CSV File</btn03>
					</div>
				</div>
			</div>
			<div class="sub-sub-panel first" *ngIf="expanded">
				<div>&nbsp;</div>
				<btn03 [icon]="'trash'" (onClick)="removeItem()">Remove</btn03>
			</div>
		</div>
	</div>
	<div class="vertical-icon" *ngIf="!expanded">
		<btn08 [icon]="'trash'" (onClick)="removeItem()"></btn08>
	</div>
</div>
