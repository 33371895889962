<div class="container">
	<div class="header">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div>
			<h4 class="title">{{ isEdit ? 'Edit User' : 'Add User' }}</h4>
			<font04 class="subtitle">Changes to account setting will apply to all other signed in devices.</font04>
		</div>
	</div>
	<div class="body">
		<div class="back-btn">
			<p (click)="goBacks()">
				<i class="k-icon k-i-arrow-chevron-left"></i>
				Back
			</p>
		</div>
		<div class="body-form">
			<div class="forms">
				<div>
					<label class="label">
						First Name
						<span class="required">*</span>
					</label>
					<user-tb-2
						[control]="userForm.controls['firstname']"
						placeHolderTextBox="Enter first name"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>
				<div>
					<label class="label">
						Last Name
						<span class="required">*</span>
					</label>
					<user-tb-2
						[control]="userForm.controls['lastname']"
						placeHolderTextBox="Enter last name"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>
			</div>
			<div class="forms">
				<div>
					<label class="label">
						Email
						<span class="required">*</span>
					</label>
					<user-tb-2
						[control]="userForm.controls['email']"
						placeHolderTextBox="Enter email address"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>

				<div>
					<label class="label">
						Choose and Access Level
						<span class="required">*</span>
					</label>
					<app-select-item
						[dropDownControl]="userForm.controls['accessLevel']"
						[placeholderText]="'Select an access Level'"
						[dropDownData]="dropDownListItems"
						[showCaretIcon]="true"
						[allowFilter]="false"
						(onItemSelected)="dropDownValueChanges($event)"
					></app-select-item>
				</div>
			</div>
			<div class="buttons">
				<button class="btn-gcb-01" (click)="onContinueEdit()">CONTINUE</button>
			</div>
		</div>
	</div>
</div>
