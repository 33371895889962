import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { TemplateModel } from './models/template.model';
import { FacebookAsset } from './models/facebook-asset.model';
import { AssetType } from './models/asset-type.enum';
import { Asset as FiledAsset, Asset } from './models/asset.model';
import { map, take, concatMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getFacebookBusinessOwnerId, UserState } from '../../state/user/user.reducer';

export interface AssetCollection {
	assets: Asset[];
	totalRows: number;
	totalPages: number;
}

@Injectable({
	providedIn: 'root'
})
export class AssetService {
	public templates: BehaviorSubject<TemplateModel[]> = new BehaviorSubject([]);
	public videos: BehaviorSubject<Asset[]> = new BehaviorSubject([]);
	public images: BehaviorSubject<Asset[]> = new BehaviorSubject([]);
	public existingAdsVideos: BehaviorSubject<FacebookAsset[]> = new BehaviorSubject([]);
	public existingAdsImages: BehaviorSubject<FacebookAsset[]> = new BehaviorSubject([]);

	// TODO check type (and change if necessary) when request works
	public pagePosts: BehaviorSubject<FacebookAsset[]> = new BehaviorSubject([]);

	constructor(protected http: HttpClient, private userStore: Store<UserState>) {}

	public getAssets(type?: AssetType, name?: string, pagination?: { page: number; rows: number }): Observable<AssetCollection> {
		return this.http.get<AssetCollection>(this.buildUrlGetAssets(type, name, pagination));
	}

	public getTemplates(): void {
		this.getAssets(AssetType.Template).subscribe((dto: AssetCollection) => this.templates.next((dto.assets as any) as TemplateModel[]));
	}

	public getVideos(): void {
		this.getAssets(AssetType.Video).subscribe((assetsDto: AssetCollection) => {
			assetsDto.assets.forEach((asset: Asset) => delete asset.id);
			this.videos.next(assetsDto.assets);
		});
	}

	public getImages(): void {
		this.getAssets(AssetType.Image).subscribe((assetsDto: AssetCollection) => {
			assetsDto.assets.forEach((asset: Asset) => delete asset.id);
			this.images.next(assetsDto.assets);
		});
	}

	public getAssetById(id: number): Observable<Asset> {
		return this.http.get<Asset>(`${BaseApiUrl.Assets}assets/${id}`);
	}

	// public getAdImages(adAccountFacebookId: string, businessOwnerId: string): void {
	// 	this.http
	// 		.get<FacebookAsset[]>(`${BaseApiUrl.FacebookPythonCampaignBuilder}assets/${businessOwnerId}/ad-images/${adAccountFacebookId}`)
	// 		.subscribe((assets: FacebookAsset[]) => {
	// 			assets.forEach((asset: FacebookAsset) => {
	// 				asset.type = AssetType.Image;
	// 				asset.url = asset.permalinkUrl;
	// 				asset.title = asset.name;
	// 			});
	// 			this.existingAdsImages.next(assets);
	// 		});
	// }

	// public getAdImagesList(adAccountFacebookId: string): Observable<FacebookAsset[]> {
	// 	return this.userStore.pipe(
	// 		select(getFacebookBusinessOwnerId),
	// 		take(1),
	// 		concatMap(businessOwnerId =>
	// 			this.http.get<FacebookAsset[]>(`${BaseApiUrl.FacebookPythonCampaignBuilder}assets/${businessOwnerId}/ad-images/${adAccountFacebookId}`).pipe(
	// 				map((result: FacebookAsset[]) => {
	// 					return result;
	// 				})
	// 			)
	// 		)
	// 	);
	// }

	// public getAdVideos(adAccountFacebookId: string, businessOwnerId: string): void {
	// 	this.http
	// 		.get<FacebookAsset[]>(`${BaseApiUrl.FacebookPythonCampaignBuilder}assets/${businessOwnerId}/ad-videos/${adAccountFacebookId}`)
	// 		.subscribe((assets: FacebookAsset[]) => {
	// 			assets.forEach((asset: FacebookAsset) => {
	// 				asset.type = AssetType.Video;
	// 				asset.url = asset.permalinkUrl;
	// 			});
	// 			this.existingAdsVideos.next(assets);
	// 		});
	// }

	// public getPagePosts(pageFacebookId: string, businessOwnerId: string): void {
	// 	this.http
	// 		.get<FacebookAsset[]>(`${BaseApiUrl.FacebookPythonCampaignBuilder}assets/page-posts?page_facebook_id=${pageFacebookId}`)
	// 		.subscribe((assets: FacebookAsset[]) => this.pagePosts.next(assets));
	// }

	public updateTitle(id: number, title: string): Observable<Asset> {
		return this.http.put<Asset>(`${BaseApiUrl.Assets}assets/${id}`, title);
	}

	// public uploadFile(file: File, businessOwnerId: string): Observable<number> {
	// 	const formData = this.getNewUploadPayload(file, businessOwnerId);
	// 	const headers = this.getUploadHeaders();

	// 	return this.http.post<number>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/images?ad_account_id=${businessOwnerId}`, formData, {
	// 		headers: headers
	// 	});
	// }

	// non retarded get methods

	public getUserAssets(type: AssetType): Observable<AssetCollection> {
		return this.http.get<AssetCollection>(`${BaseApiUrl.Assets}assets?Type=${type}`);
	}

	// public getAssetExistingImages(adAccountFacebookId: string, businessOwnerId: string): Observable<FacebookAsset[]> {
	// 	return this.http.get<FacebookAsset[]>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/images?ad_account_id=${adAccountFacebookId}`);
	// }

	// public getAssetExistingVideos(adAccountFacebookId: string, businessOwnerId: string, filter: boolean): Observable<FacebookAsset[]> {
	// 	if (filter) {
	// 		return this.http.get<FacebookAsset[]>(
	// 			`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/videos?ad_account_id=${adAccountFacebookId}&instagram_eligible=true`
	// 		);
	// 	}
	// 	return this.http.get<FacebookAsset[]>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/videos?ad_account_id=${adAccountFacebookId}`);
	// }

	// public getAssetPagePosts(pageFacebookId: string, businessOwnerId?: string): Observable<FacebookAsset[]> {
	// 	return this.http.get<FacebookAsset[]>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/page-posts?facebook_page_id=${pageFacebookId}`);
	// }

	public getAssetImageById(id: number): Observable<Asset> {
		return this.http.get<Asset>(`${BaseApiUrl.Assets}Assets/${id}`);
	}

	private buildUrlGetAssets(type?: AssetType, name?: string, pagination?: { page: number; rows: number }): string {
		let url = `${BaseApiUrl.Assets}assets`;

		url += type || name || pagination ? '?' : '';
		url += type ? `Type=${type}&` : '';
		url += name ? `Name=${name}&` : '';
		url += pagination ? `Pagination.Page=${pagination.page}&Pagination.Rows=${pagination.rows}` : '';
		return url;
	}

	private getUploadPayload(file: File, businessOwnerId: string): FormData {
		const asset = new FiledAsset(+businessOwnerId, file);
		const formData = new FormData();

		formData.append('file', asset.file);
		formData.append('businessOwnerFacebookId', asset.businessOwnerId.toString());
		return formData;
	}

	private getNewUploadPayload(file: File, accountId: string): FormData {
		const asset = new FiledAsset(+accountId, file);
		const formData = new FormData();

		formData.append('uploaded_image', asset.file);
		return formData;
	}

	private getUploadHeaders(): HttpHeaders {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');

		return headers;
	}
}
