import { Injectable } from '@angular/core';
import { CampaignLevelSplitsInterface } from '../components-pieces/optimization-budget-card/campaign-level-splits.interface';
import { BudgetAllocationInterface } from '../models/budget-allocation.interface';
import { FacebookLocations } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations';
import { AdSetLevelSplitsInterface } from '../components-pieces/optimization-budget-card/ad-set-level-splits.interface';
import { AdSetsSplitsInterface } from '../components-pieces/optimization-budget-card/ad-sets-splits-interface';
import { AgeSplitInterface } from '../models/age-split.interface';
import { Gender } from '../models/gender-model';

@Injectable()
export class CampaignSmartCreateBudgetSplitsService {
	constructor() {}

	public campaignSplits(campaignName: string, budget: number, locations?: FacebookLocations[], devices?: string[]): BudgetAllocationInterface {
		const campaignsBudgeAllocation: BudgetAllocationInterface = { campaignsBudget: [], adSetsBudget: [] };
		if (locations && devices) {
			locations.forEach(location => {
				devices.forEach(device => {
					const campaign: CampaignLevelSplitsInterface = {
						campaignName: `${campaignName + ' - ' + location.name + ' - ' + device}`,
						location: location.key,
						device: device,
						budget: budget
					};
					campaignsBudgeAllocation.campaignsBudget.push(campaign);
				});
			});
		} else if (locations) {
			locations.forEach(location => {
				const campaign: CampaignLevelSplitsInterface = {
					campaignName: `${campaignName + ' - ' + location.name}`,
					location: location.key,
					budget: budget
				};
				campaignsBudgeAllocation.campaignsBudget.push(campaign);
			});
		} else if (devices) {
			devices.forEach(device => {
				const campaign: CampaignLevelSplitsInterface = {
					campaignName: `${campaignName + ' - ' + device}`,
					device: device,
					budget: budget
				};
				campaignsBudgeAllocation.campaignsBudget.push(campaign);
			});
		}

		const budgetSplit = (budget / campaignsBudgeAllocation.campaignsBudget.length).toFixed(2);
		const budgetPerCampaign = parseFloat(budgetSplit);
		campaignsBudgeAllocation.campaignsBudget.forEach(campaign => {
			campaign.budget = budgetPerCampaign;
		});

		return campaignsBudgeAllocation;
	}
}
