import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
	selector: 'app-edit-setup',
	templateUrl: './edit-setup.component.html',
	styleUrls: ['./edit-setup.component.scss']
})
export class EditSetupComponent implements OnInit {
	public loading: boolean = false;
	constructor(private _location: Location, private router: Router) {}

	ngOnInit(): void {}

	public backClicked() {
		this._location.back();
	}

	public onCancel() {
		this.router.navigate(['user-management/security']);
	}

	public onNext() {
		this.router.navigate(['user-management/security/pausing-account']);
	}
}
