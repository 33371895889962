<app-user-management-header></app-user-management-header>

<div class="page-container">
	<app-user-sub-header
		(cancelledSubscriptionAction)="getBillingPlan()"
		[displayMenu]="!billingPlan?.freeTrialEndDate || !billingPlan.contactRemovalDate"
		[pageTitle]="SettingsPageLabelsEnum.MySubscription"
	></app-user-sub-header>

	<form [formGroup]="subscriptionGroup" class="subscription-container">
		<div>
			<div class="filed-input-field">
				<tb02 [control]="subscriptionGroup.get('tier')" [label]="'Subscription'"></tb02>
			</div>
			<div class="filed-input-field">
				<tb02 [control]="subscriptionGroup.get('startDate')" [label]="'Start Date'"></tb02>
			</div>
			<div class="filed-input-field">
				<tb02 [control]="subscriptionGroup.get('lastInvoiceDate')" [label]="'Last Invoice date'"></tb02>
			</div>
		</div>
		<div>
			<div class="filed-input-field">
				<tb02 [control]="subscriptionGroup.get('amount')" [label]="'Amount'"></tb02>
			</div>
			<div class="filed-input-field">
				<tb02 [control]="subscriptionGroup.get('endDate')" [label]="'End Date'"></tb02>
			</div>
			<div class="filed-input-field">
				<tb02 [control]="subscriptionGroup.get('nextInvoiceDate')" [label]="'Next invoice date'"></tb02>
			</div>
		</div>
	</form>
</div>

<div class="button-wrapper">
	<app-button
		(buttonClicked)="goToContactUs()"
		[buttonClass]="buttonClassEnum.Primary"
		[buttonType]="buttonTypeEnum.Simple"
		buttonLabel="CONTACT US"
	></app-button>
</div>
