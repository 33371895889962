import { GridsterConfigurator } from './gridster.config';
import { CompactType, DisplayGrid, GridsterConfig } from 'angular-gridster2';

export class GridsterCreateEditReportConfigurator extends GridsterConfigurator {
	public getConfiguration(): GridsterConfig {
		return {
			displayGrid: DisplayGrid.OnDragAndResize,
			gridType: 'scrollVertical',
			compactType: CompactType.None,
			pushItems: true,
			pushResizeItems: false,
			pushDirections: {
				north: false,
				south: false,
				east: true,
				west: false
			},
			swap: true,
			minCols: 12,
			maxCols: 12,
			margin: 15,
			draggable: {
				enabled: true,
				ignoreContentClass: 'gridster-item-content',
				ignoreContent: false,
				dragHandleClass: 'drag-handler',
				dropOverItems: false,
				delayStart: 50
			},
			resizable: {
				enabled: true
			},
			colWidth: 'auto',
			fixedRowHeight: 140
		};
	}
}
