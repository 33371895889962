import { Component, OnInit } from '@angular/core';
import { IToolPanel, IToolPanelParams, RowNode } from 'ag-grid-community';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ShopifyCatalog } from '../../../service/shopify-catalog.service';
import { take, takeUntil, filter, pairwise } from 'rxjs/operators';
import { resolve } from 'path';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { CatalogDashboardTabsContextInterface } from '../../catalog-dashboard-tab/models/catalog-dashboard-tabs-context.interface';

@Component({
	selector: 'app-agrid-custom-filter',
	templateUrl: './agrid-custom-filter.component.html',
	styleUrls: ['./agrid-custom-filter.component.scss']
})
export class AgridCustomFilterComponent implements IToolPanel, OnInit {
	private params: IToolPanelParams;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public dropDownControl: FormControl = new FormControl(null, [Validators.required]);
	private variantsReload = true;
	public tabsContext: CatalogDashboardTabsContextInterface[];

	agInit(params: IToolPanelParams): void {}
	refresh(): void {}
	constructor(private ShopifyCatalogService: ShopifyCatalog, private formBuilder: FormBuilder, private productCatalogStore: Store<any>) {}

	public ngOnInit(): void {
		this.initializeSelectProduct();
	}
	public dropDownValueChanges($event: any): void {}
	public dropDownListItems = [
		{ name: 'Baseball', id: 1 },
		{ name: 'Cricket', id: 2 },
		{ name: 'Field Hockey', id: 3 },
		{ name: 'Football', id: 4 }
	];
	public filterData: any[] = [];
	public initializeSelectProduct(): void {
		this.productCatalogStore
			.select('ProductCataolog')
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.filterData = res.agGridHeader[0].masterColumns;
					if (res.selectedProduct != undefined) {
						this.variantsReload = true;
						this.tabsContext[1].numberOfSelections = res.selectedProduct.length;
					}
				},
				error => {}
			);
	}
}
