import { Component, OnDestroy } from '@angular/core';
import { NoResourceService } from './no-resource.service';
import { NoResourceState } from './no-resource.models';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ButtonClassEnum } from '../button-component/button-class.enum';
import { ButtonTypeEnum } from '../button-component/button-type.enum';

@Component({
	selector: 'app-no-resource',
	templateUrl: './no-resource.component.html',
	styleUrls: ['./no-resource.component.scss']
})
export class NoResourceComponent implements OnDestroy {
	public state: NoResourceState;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor(public noResourceService: NoResourceService, private location: Location) {
		const defaultState: NoResourceState = {
			title: "It looks like this page doesn't exist...",
			button: {
				label: 'Go back',
				callback: () => this.callback()
			}
		};

		this.state = this.noResourceService.getState() || defaultState;
	}

	ngOnDestroy() {
		this.noResourceService.setState(null);
	}

	private callback(): void {
		if (this.noResourceService?.getState()?.button?.callback()) {
			return this.noResourceService.getState().button.callback();
		} else {
			return this.location.back();
		}
	}
}
