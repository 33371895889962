import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { WidgetPropertyModel } from '../../models/widget-property.model';
import { ReportModel } from '../../models/report.model';
import { StyleModel } from '../../models/style.model';
import { WidgetAdditionBasicModel } from '../../models/widget-addition-basic';
import { GoalModel } from '../../models/goal.model';
import { Helper } from '../../helper';
import { TableViewColumn2 } from 'src/app/shared/generic-table2/models/table-structure/table-view-column.model';
import { ChartTypeEnum } from '../../shared/charts/chart-type.enum';

@Injectable({
	providedIn: 'root'
})
export class ReportComponentService {
	public removeWidgetSubject: Subject<number> = new Subject<number>();
	public metricsSubject: BehaviorSubject<TableViewColumn2[]> = new BehaviorSubject(new Array<TableViewColumn2>());
	public reportLevelSubject: BehaviorSubject<string> = new BehaviorSubject(null);
	public imageWidgetChangedSubject: Subject<any> = new Subject();
	public widgetSelectionSubject = new BehaviorSubject(null);
	public widgetSettings: any = {
		breakdowns: '',
		begin: '',
		end: '',
		metrics: '',
		dimensions: '',
		simpleDataSource: false,
		operation: '',
		compare: false
	};
	public dashboardId$: BehaviorSubject<string> = new BehaviorSubject(null);
	public dashboardData$: BehaviorSubject<any[]> = new BehaviorSubject(null);

	constructor() {}

	public createWidgetModel(widgetType: string, chartType: { ChartTypeEnum: string; id: number }, simpleDataSource: boolean) {
		const style: StyleModel = new StyleModel();

		const widgetProperty: WidgetPropertyModel = new WidgetPropertyModel();

		if (chartType?.ChartTypeEnum === ChartTypeEnum.SingleNumber) {
			widgetProperty.minItemCol = 2;
			widgetProperty.minItemRows = 1;
			widgetProperty.cols = 2;
			widgetProperty.rows = 1;
		}

		const widget = new ReportModel();
		Object.assign(widget, {
			details: {
				reportLevel: widget.details.reportLevel,
				insights: widget.details.insights,
				metric: widget.details.metric,
				goals: widget.details.goals,
				adAccount: widget.details.adAccount,
				chartType: chartType,
				style: style,
				reportProperty: widgetProperty,
				simpleDataSource: simpleDataSource
			},
			type: widgetType
		} as ReportModel);
		return widget;
	}

	public addChart(event: WidgetAdditionBasicModel): ReportModel {
		const widget = this.createWidgetModel(event.widgetType, event.chartType, event.simpleDataSource);

		if (event.properties) {
			widget.details.reportProperty.x = 0;
			widget.details.reportProperty.y = 0;
			widget.details.reportProperty.cols = event.properties.cols;
			widget.details.reportProperty.rows = event.properties.rows;
		}

		return widget;
	}

	public addWidget(widget: ReportModel): ReportModel {
		return widget;
	}

	public checkGoal(goal: GoalModel): boolean {
		return goal && goal.name !== '' && goal.operator !== '' && goal.metric !== '';
	}

	public removeWidget(index: number) {
		this.removeWidgetSubject.next(index);
	}

	public checkReportWidgetsForSave(reportWidgets: ReportModel[]): boolean {
		let result = true;
		for (const reportWidget of reportWidgets) {
			if (!Helper.checkReport(reportWidget)) {
				result = false;
				break;
			}
		}
		return result;
	}

	public createXAxisForComparison(widget: ReportModel, firstDimensions: any[], secondDimensions: any[]) {
		const firstRangeName = widget.details.metric ? widget.details.metric[0].displayName : 'Name';

		return [
			{
				type: 'category',
				name: firstRangeName,
				nameLocation: 'middle',
				nameGap: 30,
				axisTick: {
					show: true,
					alignWithLabel: true
				},
				axisPointer: {
					label: {
						formatter: (params: any) => {
							return params.value;
						}
					}
				},
				axisLine: {
					show: true,
					onZero: false
				},
				data: firstDimensions
			},
			{
				type: 'category',
				axisPointer: {
					label: {
						formatter: (params: any) => {
							return params.value;
						}
					}
				},
				axisTick: {
					show: true,
					alignWithLabel: true
				},
				axisLine: {
					show: true,
					onZero: false
				},
				data: secondDimensions
			}
		];
	}
}
