export enum AdAccountEffectsEnum {
	LoadFacebookCachedAdAccounts = 'loadFacebookAdAccounts$',
	LoadGoogleAdAccounts = 'loadGoogleAdAccounts$',
	LoadCampaigns = 'loadCampaigns$',
	LoadAdSets = 'loadAdSets$',
	LoadAds = 'loadAd$'
}

export enum GeneralSettingsEffectsEnum {
	LoadSharedSettings = 'loadSharedSettings',
	LoadAdsManagerSettings = 'loadAdsManagerSettings',
	LoadCampaignBuilderSettings = 'loadCampaignBuilderSettings',
	UpdateSharedSettings = 'updateSharedSettings$',
	UpdateAdsManagerSettings = 'updateAdsManagerSettings',
	UpdateCampaignBuilderSettings = 'updateCampaignBuilderSettings'
}
