import { Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationExtras, NavigationStart, Router, RouterEvent } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EMPTY, of, Subject } from 'rxjs';
import { catchError, skipWhile, take, takeUntil } from 'rxjs/operators';
import { AccountsService } from 'src/app/accounts/services/accounts.service';
import { TreeSelection } from 'src/app/shared/models/tree-selection.const';
import { UserSelection } from 'src/app/shared/models/user-selection.const';
import { GenericPopupComponent } from 'src/app/shared/pop-ups/generic-popup/generic-popup.component';
import { AdAccount } from '../../accounts/models/ad-account.model';
import { CampaignSmartCreateService } from '../../campaign-smart-create/services/campaign-smart-create.service';
import { LoadCampaignList, SaveCampaignTemplates } from '../../campaign-smart-create/state/smart-create.actions';
import { CampaignSmartCreateState } from '../../campaign-smart-create/state/smart-create.reducer';
import { LinkAccountDialogComponent } from '../../sidenav/link-account-dialog/link-account-dialog.component';
import { ChannelButton } from '../../sidenav/sidenav/sidenav-button-channel.model';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AuthenticationService } from '../../_services/authentication.service';
import { AdAccountApiService } from '../../_services/facebook-accounts/ad-account-api.service';
import { AccountTypeService } from '../account-type.service';
import { MapperService } from '../services/mapper.service';
import { LoadFacebookAdAccounts, LoadGoogleAdAccounts, SetAdAccountSelection, ShowGlobalSpinner } from '../state/shared.actions';
import { getSelectedAdAccount, SharedState } from '../state/shared.reducer';
import { SelectedAdAccountModel } from '../state/state-entities/cached-ad-accounts.model';
import { getUserDetails, isUserFacebookOwner, isUserGoogleOwner, UserState } from '../state/user/user.reducer';
import { ToastNotificationService } from '../toast-notification/toast-notification.service';
import { TreeChecklistComponent } from '../tree-checklist/tree-checklist.component';
import { TreeChecklistService } from '../tree-checklist/tree-checklist.service';
import { GoogleService } from 'src/app/_services/google/google.service';
import { NewSignupWelcomePopupComponent } from 'src/app/authentication/new-signup/new-signup-welcome-popup/new-signup-welcome-popup.component';
import { TrialDialogComponent } from 'src/app/authentication/trial-dialog/trial-dialog.component';
import { Location } from '@angular/common';

@Component({
	selector: 'app-multi-channel-account-selection',
	templateUrl: './multi-channel-account-selection.component.html',
	styleUrls: ['./multi-channel-account-selection.component.scss']
})
export class MultiChannelAccountSelectionComponent implements OnInit, OnDestroy {
	childSelectionStatus: boolean = false;
	@ViewChild('menuDD1') menuDD1: ElementRef;
	closedDialog: any;
	@Input() public fromGoogleCB: boolean;

	@HostListener('document: click', ['$event'])
	public clickOutsideElement(event: Event): void {
		const target = <HTMLElement>event.target;
		const isOutsideClick = !this.eRef.nativeElement.contains(target);
		if (isOutsideClick && target.offsetParent !== null) {
			this.childSelectionStatus = true;
			this.openDD = false;
		}
	}
	@ViewChild('searchInputRef') private _searchInputRef: ElementRef;

	public adAccountSearchFormControl = new FormControl();
	public userSelection = UserSelection.selectAccounts;
	public hideTab = true;
	public dataForTree: any[];
	public selectedDataFroTree: any[];
	public adAccountsGroup: any[] = [];
	public channelButtons: ChannelButton[] = [];
	public selectedButton: ChannelButton;
	public filterSearch: string;
	public allAdAccounts: AdAccount[];
	public hideChannel: boolean;
	public googleDataAds: any;
	public selectedGoogleAccountName: string = 'Connect to Google';

	private readonly debounceDelay: number = 250;
	private unsubscriber$ = new Subject<void>();
	private routesToHide = ['/audience', '/pixel', '/ads-manager', '/campaign', '/campaign-builder'];
	private timeout: any;
	private treeChkListService: TreeChecklistService;
	private selectedAdAccount: AdAccount;
	private facebookBusinessOwnerId: string;
	private googleBusinessOwnerId: string;
	public selectedAdToDisplay: string;
	public googleAvailability: boolean = false;
	openDD: boolean;

	public channelGroups: { channelName: string; channelIcon: string; data: any }[] = [
		{ channelName: 'facebook', channelIcon: 'assets/icons/Icon-facebook.svg', data: null },
		{ channelName: 'google', channelIcon: 'assets/images/export-catalog/google-logo.png', data: null }
	];

	constructor(
		private eRef: ElementRef,
		private router: Router,
		private adAccountService: AdAccountApiService,
		private authenticationService: AuthenticationService,
		private smartCreateService: CampaignSmartCreateService,
		private mapperService: MapperService,
		private matDialog: MatDialog,
		private accountTypeService: AccountTypeService,
		private store: Store<SharedState>,
		private userStore: Store<UserState>,
		private smartCreateStore: Store<CampaignSmartCreateState>,
		private accountsService: AccountsService,
		public toastNotificationService: ToastNotificationService,
		private googleService: GoogleService,
		private dialog: MatDialog,
		private dialogNew: MatDialog,
		private _location: Location,
		public ngZone: NgZone
	) {}

	public ngOnInit(): void {
		this.childSelectionStatus = true;
		this.userSelection = UserSelection.selectAccounts;
		if (this.fromGoogleCB) {
			this.channelGroups = [{ channelName: 'google', channelIcon: 'assets/images/export-catalog/google-logo.png', data: null }];
			this.googleService.selectedGoogleAccount.pipe(takeUntil(this.unsubscriber$)).subscribe(selectedAcc => {
				if (selectedAcc) {
					this.selectedGoogleAccountName = selectedAcc.name;
				} else {
					this.selectedGoogleAccountName = '-----';
				}
			});
		} else {
			this.googleService.selectedGoogleAccount.pipe(takeUntil(this.unsubscriber$)).subscribe(selectedAcc => {
				this.selectedGoogleAccountName = selectedAcc?.name ? selectedAcc?.name : 'Connect to google';
			});
		}

		this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe((event: RouterEvent) => {
			if ((event instanceof NavigationStart || (event instanceof NavigationEnd && this.channelButtons.length)) && !this.fromGoogleCB) {
				this.checkButtons(event.url);
			}
			if (event.url === '/optimize/dashboard' || event.url === '/optimize/recommendations' || event.url === '/optimize/labs') {
				// setTimeout(() => {
				// 	this.openDD = true;
				// 	this.childSelectionStatus = true;
				// }, 5000);
			}
		});
		this.userStore
			.pipe(
				select(getUserDetails),
				// skipWhile(details => !details),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(details => {
				this.facebookBusinessOwnerId = details?.FacebookBusinessOwnerId;
				this.googleBusinessOwnerId = details?.GoogleBusinessOwnerId;

				this.googleDataAds = details?.GoogleAccountsData;

				this.initChannelButtons();
				this.initializeAdAccounts();
				this.fetchStore();

				this.adAccountService.selectFirstAdAccountSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
					this.treeChkListService.deselectAll();
					this.treeChkListService.selectNodes([this.allAdAccounts[0]]);
					this.adAccountService.setSelectedAdAccounts(this.allAdAccounts[0]);
					this.updateCurrentSelectionLabel();
				});
			});
	}
	public getGoogleAdsData(): void {
		if (this.googleBusinessOwnerId && !this.fromGoogleCB) {
			this.channelGroups = [];
			this.channelGroups = [
				{ channelName: 'facebook', channelIcon: 'assets/icons/Icon-facebook.svg', data: null },
				{ channelName: 'google', channelIcon: 'assets/images/export-catalog/google-logo.png', data: null }
			];
			this.googleAvailability = true;
			this.createDataForTree();
		} else if (this.googleBusinessOwnerId && this.fromGoogleCB) {
			this.channelGroups = [];
			this.channelGroups = [{ channelName: 'google', channelIcon: 'assets/images/export-catalog/google-logo.png', data: null }];
			this.googleAvailability = true;
			this.createDataForTree();
		} else if (!this.googleBusinessOwnerId && !this.fromGoogleCB) {
			this.googleAvailability = false;
			this.channelGroups = [];
			this.channelGroups = [
				{ channelName: 'facebook', channelIcon: 'assets/icons/Icon-facebook.svg', data: null },
				{ channelName: 'google', channelIcon: 'assets/images/export-catalog/google-logo.png', data: null }
			];
			this.createDataForTree();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}

	public toggleDropdown(): void {
		this.hideTab = !this.hideTab;
		setTimeout(() => {
			this._searchInputRef.nativeElement.focus();
		}, 0);
	}

	public treeSelectionCallback(selection: AdAccount[]): void {
		const adAccounts = this.adAccountService.allAdAccounts.get(this.selectedButton.sourceChannel);
		const selectedAdAccount = adAccounts.find(adAccount => adAccount.name === selection[0].name);
		selection[0].id = selectedAdAccount.id;
		this.matDialog
			.open(GenericPopupComponent, {
				data: {
					image: TreeSelection.image,
					actionAnswer: TreeSelection.actionAnswer,
					noActionAnswer: TreeSelection.noActionAnswer,
					message: TreeSelection.message,
					header: TreeSelection.header
				},
				panelClass: ['generic-popup']
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.changeActiveAdAccount(selection);

					if (!this.fromGoogleCB) {
						this.accountTypeService.selectedLabsCampaign$.next('Select adset or campaign');
						this.accountTypeService.reloadDexterHealth$.next(true);
						this.accountTypeService.optimizationScore$.next(true);
						this.accountsService
							.runDexterSyncStart()
							.pipe(
								take(1),
								catchError(error => {
									return EMPTY;
								})
							)
							.subscribe((msg: any) => {
								this.accountTypeService.reloadSync$.next(true);
								if (msg.data && typeof msg.data?.progress === 'number') {
								}
							});
					}
				}
			});
	}

	public googleTreeSelectionCallback(selection: AdAccount[]): void {
		const adAccounts = this.googleService.allGoogleAdAccounts.get(1);
		const selectedAdAccount: AdAccount = adAccounts.find(adAccount => adAccount.name === selection[0].name);
		selection[0].id = selectedAdAccount.id;
		this.matDialog
			.open(GenericPopupComponent, {
				data: {
					image: TreeSelection.image,
					actionAnswer: TreeSelection.actionAnswer,
					noActionAnswer: TreeSelection.noActionAnswer,
					message: TreeSelection.message,
					header: TreeSelection.header
				},
				panelClass: ['generic-popup']
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.googleService.selectedGoogleAccount.next(selectedAdAccount);
					this.googleService.selectedGoogleAccount.pipe(takeUntil(this.unsubscriber$)).subscribe(selectedAcc => {
						this.selectedGoogleAccountName = selectedAcc.name;
					});
					if (!this.fromGoogleCB) {
						this.accountTypeService.reloadDexterHealth$.next(true);
						this.accountsService
							.runDexterSyncStart()
							.pipe(
								take(1),
								catchError(error => {
									return EMPTY;
								})
							)
							.subscribe((msg: any) => {
								this.accountTypeService.reloadSync$.next(true);
								if (msg.data && typeof msg.data?.progress === 'number') {
								}
							});
					}
				}
			});
	}

	public updateSmartCreateTemplates(): void {
		this.store
			.pipe(
				select(getSelectedAdAccount),
				skipWhile(account => !account),
				take(1)
			)
			.subscribe(account => {
				this.smartCreateStore.dispatch(new LoadCampaignList(account.adAccount.id));
				// this.smartCreateService.getCampaignTemplatesList(account.adAccount.id).subscribe(templates => {
				// 	const mappedTemplates = this.mapperService.convertToDropdownData(templates.reverse(), 'id', 'name').slice(0, 20);
				// 	this.smartCreateStore.dispatch(new SaveCampaignTemplates(mappedTemplates));
				// 	if (!mappedTemplates.length) {
				// 		this.router.navigate(['campaign/welcome']);
				// 	}
				// });
			});
	}

	public getTreeModel(model: TreeChecklistComponent): void {
		this.treeChkListService = new TreeChecklistService(model);
	}

	public onSelectedChannelChange(channelButton: ChannelButton): void {
		const channel = this.getNewChannel(channelButton.sourceChannel, this.selectedButton.sourceChannel);
		if (!(channel === this.selectedButton.sourceChannel)) {
			this.accountTypeService.setAccountType(channel);
			this.selectedButton = channelButton;
			this.selectedAdAccount = this.adAccountService.getSelectedAdAccount();
			this.setAdAccountChannelSelection();
			this.updateData();
		}
	}

	public searchForAccount(event: KeyboardEvent): void {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.filterSearch = (event.target as HTMLTextAreaElement).value;
			if (this.filterSearch?.length > 0) {
				this.expandAllGroups();
			}
			this.createDataForTree(this.filterSearch);
		}, this.debounceDelay);
	}

	private initializeAdAccounts(): void {
		if (this.googleBusinessOwnerId) {
			// this.store.dispatch(new LoadGoogleAdAccounts());

			const adAccounts: AdAccount[] = [];

			this.googleDataAds.forEach(account => {
				const businessOwnerId = account.managerId;
				const businessOwnerName = account.managerName;
				account.adaccounts.forEach(adAcc => {
					const adAccount: AdAccount = {
						id: adAcc.accountId,
						name: adAcc.accountName,
						businessId: parseInt(businessOwnerId),
						businessName: businessOwnerName,
						businessIdAsNumber: parseInt(businessOwnerId)
					};
					adAccounts.push(adAccount);
				});
			});
			this.adAccountService.setAllCachedAdAccounts(SourceChannel.Google, adAccounts);
			this.initializeSourceChannel();
			this.updateData();

			// this.adAccountService
			// 	.getAllGoogleAccounts()
			// 	.pipe(takeUntil(this.unsubscriber$))
			// 	.subscribe(res => {sdsds
			// 		this.adAccountService.setAllCachedAdAccounts(SourceChannel.Google, res);
			// 		this.initializeSourceChannel();
			// 		this.updateData();
			// 	});
		}

		if (this.facebookBusinessOwnerId) {
			this.store.dispatch(new LoadFacebookAdAccounts());
			this.adAccountService
				.getAllAdAccounts()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					this.adAccountService.setAllCachedAdAccounts(SourceChannel.Facebook, res);
					this.initializeSourceChannel();
					this.updateData();
				});
		}
	}

	private reloadCurrentRoute(): void {
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};

		this.router.events.subscribe(evt => {
			if (evt instanceof NavigationEnd) {
				this.router.navigated = false;
				window.scrollTo(0, 0);
			}
		});
	}
	private changeActiveAdAccount(selection: AdAccount[]): void {
		this.selectedAdAccount = selection[0];
		this.adAccountService.setSelectedAdAccounts(selection[0]);
		this.updateCurrentSelectionLabel();
		this.hideTab = true;

		if (this.router.url.includes('/campaign/build-campaign/')) {
			this.router.navigate(['/campaign/welcome']);
		} else if (this.router.url.includes('/campaign/select-campaign')) {
			this.updateSmartCreateTemplates();
		} else {
		}
		// if (this.router.url.includes('/optimize/dashboard')) {
		// 	this.store.dispatch(new ShowGlobalSpinner());
		// 	// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		// 	// this.router.onSameUrlNavigation = 'reload';
		// 	this.authenticationService.setExternalRoute$.next({ route: '/optimize/dashboard', type: 'parent' });
		// 	this.router.navigate(['/accounts']);
		// 	// this.authenticationService.setRoute$.next('optimize');
		// }
	}

	private createSelectionObject(): SelectedAdAccountModel {
		const selectedAdAccountModel = {
			adAccount: this.selectedAdAccount,
			sourceChannel: this.selectedButton.sourceChannel
		};

		return selectedAdAccountModel;
	}

	private initChannelButtons(): void {
		this.channelButtons = [];
		this.userStore.pipe(select(isUserFacebookOwner), take(1)).subscribe(isFacebookBusinessOwner => {
			if (isFacebookBusinessOwner) {
				this.channelButtons.push(
					new ChannelButton(SourceChannel.Facebook, 'assets/icons/Icon-facebook.svg', 'assets/icons/Icon-facebook-disabled.svg')
				);
			}
		});
		this.userStore.pipe(select(isUserGoogleOwner), take(1)).subscribe(isGoogleBusinessOwner => {
			if (isGoogleBusinessOwner) {
				this.channelButtons.push(
					new ChannelButton(SourceChannel.Google, 'assets/icons/google-icon-channel.svg', 'assets/icons/google-disabled-icon.svg')
				);
				this.checkButtons(this.router.url);
			}
		});
	}

	private getNewChannel(newChannel: SourceChannel, oldChannel: SourceChannel): SourceChannel {
		switch (newChannel) {
			case SourceChannel.Facebook:
				return this.tryConnectAccount(this.facebookBusinessOwnerId, newChannel, oldChannel);
			case SourceChannel.Google:
				return this.tryConnectAccount(this.googleBusinessOwnerId, newChannel, oldChannel);
		}
	}

	private tryConnectAccount(adAccountId: string, newChannel: SourceChannel, oldChannel: SourceChannel): SourceChannel {
		if (adAccountId) {
			return newChannel;
		}
		this.openLinkAccountDialog(newChannel);
		return oldChannel;
	}

	private openLinkAccountDialog(channel: SourceChannel): void {
		this.matDialog
			.open(LinkAccountDialogComponent, {
				data: channel,
				panelClass: 'link-account-section-popup'
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((hasLinkedAnAccount: boolean) => {
				if (hasLinkedAnAccount) {
					this.authenticationService.logout();
				}
			});
	}

	private initializeSourceChannel(): void {
		const sourceChannel = parseInt(localStorage.getItem(this.accountTypeService.localStorageChannelKey));

		if (!isNaN(sourceChannel) && sourceChannel >= 0 && sourceChannel < this.channelButtons.length) {
			this.selectedButton = this.channelButtons.find(btn => btn.sourceChannel === sourceChannel);
		} else {
			if (this.facebookBusinessOwnerId) {
				this.selectedButton = this.channelButtons.find(btn => btn.sourceChannel === SourceChannel.Facebook);
			} else if (!this.selectedButton && this.googleBusinessOwnerId) {
				this.selectedButton = this.channelButtons.find(btn => btn.sourceChannel === SourceChannel.Google);
			} else {
				this.authenticationService.logout();
				return;
			}
		}

		this.setAdAccountChannelSelection();
		this.accountTypeService.setAccountType(this.selectedButton.sourceChannel);
	}

	private setAdAccountChannelSelection(): void {
		if (this.selectedAdAccount && this.selectedButton) {
			this.store.dispatch(new SetAdAccountSelection(this.createSelectionObject()));
		}
	}

	private updateData(): void {
		const data = this.adAccountService.getAllCachedAdAccounts(this.selectedButton.sourceChannel);

		if (data && data.length > 0) {
			if (!this.adAccountService.getSelectedAdAccount()) {
				this.adAccountService.setSelectedAdAccounts(data[0]);
			}

			this.adAccountsGroup = this.groupBy(this.adAccountService.getAllCachedAdAccounts(this.selectedButton.sourceChannel));
			this.getGoogleAdsData();
		} else {
			this.treeChkListService?.deselectAll();
			this.treeChkListService?.updateDataSource([]);
		}

		this.updateCurrentSelectionLabel();
	}

	private groupBy(list: AdAccount[]): AdAccount[] {
		return list.reduce(function (total, adAccount) {
			if (!total.length) {
				total.push({
					accounts: [adAccount],
					businessName: adAccount.businessName,
					checked: false
				});
			} else {
				const occ = total.find(i => i.businessName === adAccount.businessName);
				occ
					? occ.accounts.push(adAccount)
					: total.push({
							accounts: [adAccount],
							businessName: adAccount.businessName,
							checked: false
					  });
			}
			return total;
		}, []);
	}

	private createDataForTree(filter: string = ''): void | AdAccount {
		const regFilter = new RegExp(filter, 'i');
		const filterFunction = (e: AdAccount) => {
			if (regFilter.test(e.name)) {
				return e;
			}
		};

		this.dataForTree = [];
		this.adAccountsGroup.forEach(adAccountsGroup => {
			let adAccountChildren = adAccountsGroup.accounts.filter(filterFunction);
			if (adAccountChildren.length > 0) {
				this.dataForTree.push({
					children: adAccountChildren,
					name: adAccountsGroup.businessName,
					isOpen: true,
					id: adAccountsGroup.id,
					channel: 'facebook'
				});
			}
		});
		if (this.googleAvailability) {
			this.googleDataAds.forEach(adAccountsGroup => {
				let adAccountChildren = adAccountsGroup.adaccounts;
				let adAccountChildrenGoogle: any[] = [];
				if (adAccountChildren?.length > 0) {
					adAccountChildren.forEach(child => {
						adAccountChildrenGoogle.push({
							businessId: child.accountId,
							businessIdAsNumber: child.accountId,
							businessName: adAccountsGroup.managerName,
							name: child.accountName,
							currency: 'USD',
							id: Math.random().toString(),
							status: 'ACTIVE'
						});
					});
					this.dataForTree.push({
						children: adAccountChildrenGoogle,
						name: adAccountsGroup.managerName,
						isOpen: true,
						id: adAccountsGroup.managerId,
						channel: 'google'
					});
				}
			});
		}

		let googleChildrenData: any[] = [];
		let facebookChildrenData: any[] = [];
		let googleSetSaveData: any[] = [];
		this.dataForTree.forEach(data => {
			if (data.channel === 'facebook') {
				facebookChildrenData.push(data);
			} else {
				googleChildrenData.push(data);
				data.children.forEach(item => {
					googleSetSaveData.push(item);
				});
			}
		});
		if (this.googleAvailability) {
			this.googleService.allGoogleAdAccounts.set(SourceChannel.Google, googleSetSaveData);
			const findIndex = this.channelGroups.findIndex(item => {
				return item.channelName === 'google';
			});
			this.channelGroups[findIndex].data = googleChildrenData;
			this.selectedGoogleAccountName = googleChildrenData[0].name;
		}
		if (!this.fromGoogleCB) {
			const findIndex = this.channelGroups.findIndex(item => {
				return item.channelName === 'facebook';
			});
			this.channelGroups[findIndex].data = facebookChildrenData;
		}
		if (this.treeChkListService) {
			this.treeChkListService.deselectAll();
			this.treeChkListService.updateDataSource(this.dataForTree);
			this.treeChkListService.selectNodes([this.adAccountService.getSelectedAdAccount()].filter(filterFunction));
			this.treeChkListService.expandAll();
		}
	}

	private updateCurrentSelectionLabel(adAccount: AdAccount = null): void {
		let currentSelection: AdAccount;

		if (adAccount && adAccount.name) {
			currentSelection = adAccount;
			this.selectedAdAccount = adAccount;
		} else {
			currentSelection = this.adAccountService.getSelectedAdAccount();
		}

		if (currentSelection) {
			this.userSelection = currentSelection.name;
			this.childSelectionStatus = true;
		} else {
			this.userSelection = UserSelection.selectAccounts;
		}
	}

	private fetchStore(): void {
		this.store.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(selectedAdAccount => {
			if (selectedAdAccount) {
				this.updateCurrentSelectionLabel(selectedAdAccount.adAccount);
			}
		});
	}

	private checkButtons(url: string): void {
		const btn = this.channelButtons.find(button => button.sourceChannel === SourceChannel.Google);
		if (btn) {
			for (const route of this.routesToHide) {
				btn.hideChannel = url.startsWith(route);
				if (btn.hideChannel) {
					this.onSelectedChannelChange(this.channelButtons.find(button => button.sourceChannel === SourceChannel.Facebook));
					break;
				}
			}
		}
	}

	public openDDSelection(): void {
		this.openDD ? (this.openDD = false) : (this.openDD = true);
	}

	public parentAccountAction(account): void {
		const findIndex = this.dataForTree.findIndex(item => {
			return item.name === account.name;
		});

		this.dataForTree[findIndex].isOpen === true ? (this.dataForTree[findIndex].isOpen = false) : (this.dataForTree[findIndex].isOpen = true);
	}

	public childAccountAction(account, channelSelected): void {
		const accountArr = [account];

		if (channelSelected === 'facebook') {
			this.treeSelectionCallback(accountArr);
		} else {
			this.googleTreeSelectionCallback(accountArr);
		}
		this.openDD = true;
	}

	public expandAllGroups(expandedAds: string = 'allAccounts'): void {
		this.selectedAdToDisplay = expandedAds;
		this.childSelectionStatus = false;
		const findGoogle = this.dataForTree.find(item => {
			return item.channel === 'google';
		});
		if (!this.dataForTree) {
			this.openTop();
			return;
		}
		if (expandedAds === 'facebook' && !this.dataForTree[0]) {
			this.openTop();
		}
		if (expandedAds === 'google' && !findGoogle) {
			this.openTop();
		}
	}
	public backTodropDown(): void {
		this.childSelectionStatus = true;
	}

	public openTop(): void {
		// const dialogRef = this.dialog.open(NewSignupWelcomePopupComponent, {
		// 	width: '100%',
		// 	height: '100%',
		// 	maxWidth: '100vw',
		// 	disableClose: false
		// });
		// dialogRef.afterClosed().subscribe((result: any) => {
		// 	if (result && result?.data) {
		// 		if (result.data?.type && result.data?.type === 'trial') {
		// 			this.trialModal(result.data);
		// 		} else {
		// 			const url = result.data?.url ? result.data?.url : result.data;
		// 			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		// 			this.router.onSameUrlNavigation = 'reload';
		// 			this.router.navigate([url]);
		// 			// this.closedDialog = of(true);
		// 			// this.ngZone.run(() => this.router.navigate([url]));
		// 			// this.router.navigateByUrl(url);
		// 			// this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
		// 			// 	this.router.navigate([url]);
		// 			// });
		// 		}
		// 	}
		// });
		this.router.navigate(['authentication/connect-channel']);
	}

	public trialModal(data: any): void {
		const dialogRef = this.dialogNew.open(TrialDialogComponent, {
			width: '40%',
			data: {
				days: data.billingPlan.freeTrialDuration,
				hasPaymentCard: data.hasPaymentCard
			}
		});
		dialogRef.afterClosed().subscribe(() => {
			let url = window.location.toString().replace('accounts', data.url);
			window.location.href = url;
		});
	}
}
