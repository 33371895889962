<div class="container">
	<div class="left-dock">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="right-dock">
		<div class="wrapper">
			<div class="logo">
				<img src="assets/logo/lolly-logo.svg" />
			</div>
			<div class="welcome-text">{{ 'Welcome Back' }}</div>
			<div (click)="signinWithGoogle()" class="continue-btn-wrapper">
				<div class="btn">
					<div class="items">
						<img src="assets/images/google-icon.svg" />
						<div class="text">{{ 'Continue with Google' }}</div>
					</div>
				</div>
			</div>
			<div class="rule-a-text">
				<hr />
				<div class="text">{{ 'Or Login with your email' }}</div>
			</div>
			<div class="full-text-input">
				<div class="label">{{ 'Email Address' }}</div>
				<app-input-field
					[showValidIcon]="true"
					[hideError]="true"
					class="title-input"
					[placeHolderTextBox]="'Enter your email'"
					[inputControl]="emFormControl"
				></app-input-field>
			</div>
			<div class="full-text-input l-margin">
				<div class="label">{{ 'Password' }}</div>
				<app-input-field-password
					[showValidIcon]="true"
					[hideError]="true"
					class="title-input"
					[placeHolderTextBox]="'Enter your password'"
					[inputControl]="passFormControl"
				></app-input-field-password>
			</div>
			<div class="margin-bottom-spacing-ps l-margin">
				<a (click)="onForgotPasswordClick()" class="link password_forgot">Forgot password?</a>
			</div>
			<div (click)="onSubmit()" class="signin-btn-wrapper">
				<div class="btn">
					<div class="items">
						<div class="text">{{ 'Login' }}</div>
					</div>
				</div>
			</div>

			<!-- <div class="bottom-text-action">
				<div class="dark">{{ "Don't have an account?" }}</div>
				<div (click)="onSubscribeToAnOffer()" class="theme">{{ 'Signup' }}</div>
			</div> -->
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
