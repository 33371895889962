import { Component, Input, OnInit } from '@angular/core';
import { ReportModel } from '../../../models/report.model';
import { ReportComponentService } from '../../../services/chart-services/report-component.service';
import { TableChartService } from '../../../services/chart-services/table-chart.service';
import html2canvas from 'html2canvas';
import { TableViewColumnTemplateEnum } from '../../../../shared/generic-table2/models/table-structure/table-view-column-template.enum';
import EChartOption = echarts.EChartOption;
import { getCurrencySymbol } from '@angular/common';
import { DefaultChartValues } from 'src/app/reports/models/default-chart-values';

@Component({
	selector: 'app-table-chart',
	templateUrl: './table-chart.component.html',
	styleUrls: ['./table-chart.component.scss']
})
export class TableChartComponent implements OnInit {
	@Input() widget: ReportModel;
	@Input() index: number;

	public dataSource: any = [];
	public chartOption: EChartOption;
	public columns: string[] = [];

	constructor(private widgetComponentService: ReportComponentService, private chartService: TableChartService) {}

	ngOnInit() {
		this.onChartInit();
		if (this.widget.details.chartData && this.widget.details.chartData.length) {
			this.dataSource = this.widget.details.chartData;
			const dataSource = this.dataSource[0];
			this.columns = Object.keys(dataSource).reverse();
		} else {
			this.dataSource = [...DefaultChartValues.Table];
			this.columns = Object.keys(this.dataSource[0]);
		}
		this.formatWidget();
	}

	public onChartInit(): void {
		this.chartService.setChartInstances(this.index, { instance: this, dom: null });
	}

	public dispose(): void {}

	public clear(): void {
		this.dataSource = [];
	}

	public setOption(): void {
		this.dataSource = [];
		this.columns = [];

		if (this.widget.details.chartData?.length > 0) {
			this.dataSource = this.chartService.createChart(this.widget) as any[];

			this.formatWidget();
		}
		this.columns = [];
		if (this.dataSource[0]) {
			this.columns = Object.keys(this.dataSource[0]);
		}
	}

	private formatWidget(): void {
		const currency = this.widget.details.adAccount.currency;
		const currencyString = getCurrencySymbol(currency, 'narrow');
		this.widget.details.metric.forEach(metric => {
			this.dataSource.forEach((dataS: any) => {
				dataS[metric.primaryValue.name] = +Number(String(dataS[metric.primaryValue.name]).replace(currencyString, '')).toFixed(2);
				if (!dataS[metric.primaryValue.name] || dataS[metric.primaryValue.name] === 'null' || dataS[metric.primaryValue.name] === 'undefined') {
					dataS[metric.primaryValue.name] = 0;
				}
				if (Number(dataS[metric.primaryValue.name])) {
					dataS[metric.primaryValue.name] = +Number(dataS[metric.primaryValue.name]).toFixed(2);
				}
				if (metric.typeId === TableViewColumnTemplateEnum.Currency) {
					if (!String(dataS[metric.primaryValue.name]).includes(currencyString)) {
						dataS[metric.primaryValue.name] = currencyString + ' ' + dataS[metric.primaryValue.name];
					}
				}
				if (metric.typeId === TableViewColumnTemplateEnum.Percentage) {
					if (!String(dataS[metric.primaryValue.name]).includes('%')) {
						dataS[metric.primaryValue.name] = dataS[metric.primaryValue.name] + ' %';
					}
				}
				dataS[metric.primaryValue.name] = String(dataS[metric.primaryValue.name]).replace('null', '0');
				dataS[metric.primaryValue.name] = String(dataS[metric.primaryValue.name]).replace('undefined', '0');
			});
		});
	}
}
