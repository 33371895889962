<app-user-header></app-user-header>

<div class="center-wrapper">
	<!--Header employees -->
	<div class="header-wrapper">
		<div class="employees-title">
			<img alt="" class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
			<div>
				<h4>Users Admin</h4>
				<p>Manage and authenticate your users.</p>
			</div>
		</div>

		<div class="right-options">
			<div class="input-group">
				<input type="text" class="form-control" placeholder="Search" [formControl]="searchFormControl.get('searchForm')" />
				<div class="icon">
					<span class="k-icon k-i-search"></span>
				</div>
			</div>
			<div *appCheckPermissions="permissionModelUsers">
				<btn04 (onClick)="addNewUser()">
					<span>+</span>
					ADD USER
				</btn04>
			</div>
		</div>
	</div>
	<div class="master-table-container">
		<div class="datatable">
			<table>
				<thead>
					<tr>
						<th *ngFor="let col of columnDef">{{ col.label }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of filteredRowData">
						<td>
							<div class="name">{{ row.firstName }} {{ row.lastName }}</div>
						</td>
						<td>
							<select class="select" (change)="onSelectItem($event, row)" [disabled]="isAccountOwner(row)">
								<option value="" disabled [selected]="row.access === 'Standard' && row.access === 'View only'">Roles</option>
								<option value="Standard" [selected]="row.access === 'Standard'">Standard</option>
								<option value="View only" [selected]="row.access === 'View only'">View only</option>
							</select>
						</td>
						<td>
							<div class="date">{{ fixDate(row.lastLogin) }}</div>
						</td>
						<td>
							<div class="alert">
								<span *ngIf="row.accountState == 1" class="success">CONFIRMED</span>
								<span *ngIf="row.accountState == 0" class="pending">PENDING</span>
							</div>
						</td>
						<td>
							<div class="email">{{ row.email }}</div>
						</td>
						<td>
							<div class="button">
								<button class="edit" (click)="editUser(row)">EDIT</button>
								<button class="button remove" (click)="onRemoveClick(row)">REMOVE ACCESS</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="paginate">
			<app-datatable-pagination [totalPage]="rowDataLength" (eventClick)="onPageChanged($event)"></app-datatable-pagination>
		</div>
	</div>
</div>
