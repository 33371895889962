<div class="modalContainer">
	<div class="header">
		<i class="k-icon k-i-close close" (click)="closeDialog()"></i>
	</div>
	<div class="body">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div class="title">{{ modelDetail.title }}</div>
		<div class="text">
			{{ modelDetail.text }}
		</div>
	</div>
</div>
