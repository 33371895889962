import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, RouterEvent, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { AAAState } from '../state/aaa.reducer';
import { selectAAAModuleState } from '../state';

@Injectable({
	providedIn: 'root'
})
export class CampaignGuard implements CanActivate {
	constructor(public store: Store<AAAState>, public activateRouter: ActivatedRoute, public toasterService: ToastNotificationService, public router: Router) {}
	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.store.pipe(
			select(selectAAAModuleState),
			take(1),
			map(state => {
				if (!state.AAAState) {
					return true;
				} else if (!state.AAAState.selectedCampaigns) {
					this.toasterService.sendErrorToast('You need to select campaigns');
					const queryParams = next.queryParams;
					this.router.navigate(['/AAA/add-ad'], { queryParams });
					return false;
				}
				return true;
			})
		);
	}
}
