import { Moment } from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class DateRangeX<T> {
	startDate: T;
	endDate: T;
}

export class DateRange {
	startDate: Moment;
	endDate: Moment;
}

export class DateModel<T> {
	date: DateRangeX<T>;
	compareDate?: DateRangeX<T>;

	constructor() {
		this.date = new DateRangeX<T>();
		this.compareDate = new DateRangeX<T>();
	}
}

export class PublicDateModel extends DateModel<Moment> {
	constructor() {
		super();
	}
}

export class InternalDateModel extends DateModel<NgbDate> {
	constructor() {
		super();
	}
}

export class DateSelection {
	label?: string;
	dateRangeToBeSelected?: Moment[];
	dateRangeFn?: () => Moment[];
	inputValidations?: InputValidations;
	hasParameter?: any;
	hasParameterValue?: any;
	type?: 'input' | 'dropdown';
	selectOptions?: SelectOptions[];
	selected?: SelectOptions;
}

export class InputValidations {
	minValue?: number;
}

export class SelectOptions {
	label: string;
	value: Moment[];
}

export class MaxDateTimeLimit {
	maxLimit: string;
	minDate: string;
	maxDate: string;
}
