import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './http-config.interceptor';
import { ErrorInterceptor } from './error.interceptor';

export const InterceptorsProvider = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ErrorInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpConfigInterceptor,
		multi: true
	}
];
