export enum ServiceNamesEnum {
	Accounts = 'Accounts',
	AdsManager = 'AdsManager',
	Audience = 'Audience',
	CampaignBuilder = 'CampaignBuilder',
	CampaignManager = 'CampaignManager',
	Optimise = 'Optimise',
	Pixels = 'Pixels',
	Reports = 'Reports',
	UserManagement = 'UserManagement'
}
