import { BreakdownWrapper } from 'src/app/ads-manager/models/delivery-breakdown';
import { TableViewColumn2 } from '../../generic-table2/models/table-structure/table-view-column.model';

export class InsightsReportModel {
	name: string;
	tableName: string;
	breakdowns: BreakdownWrapper;
	reportBreakdowns?: string[];
	columns: TableViewColumn2[];

	constructor() {}
}
