import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { BusinessOwnerService } from '../../../_services/facebook-accounts/business-owner.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadSelectedKeysPermissions, TogglePermissionCheckByTitle } from '../../state/user-management.actions';
import { UserManagementState } from '../../state/user-management.reducer';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-confirm-dialog-permissions',
	templateUrl: './confirm-dialog-permissions.component.html',
	styleUrls: ['./confirm-dialog-permissions.component.scss']
})
export class ConfirmDialogPermissionsComponent implements OnInit, OnDestroy {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	private deletedPermissions: string;

	constructor(
		private router: Router,
		private dialogRef: MatDialogRef<ConfirmDialogPermissionsComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private businessOwnerService: BusinessOwnerService,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		if (this.data && this.data.deletedPermissions) {
			this.deletedPermissions = this.data.deletedPermissions;
		} else {
			this.close();
		}
	}

	ngOnDestroy() {}

	public close(send = false): void {
		if (send) {
			this.deletePermissions();
		} else {
			this.dialogRef.close();
		}
	}

	private deletePermissions(): void {
		this.businessOwnerService
			.deleteBusinessOwnerPermissions(this.deletedPermissions)
			.pipe(take(1))
			.subscribe(
				resp => {
					if (resp.failed && resp.failed[0]) {
						this.toastNotificationService.sendErrorToast('Failed for: ' + resp.failed.join(', '));
					} else {
						this.toastNotificationService.sendSuccessToast(this.translate.instant('PERMISSIONS_CHANGED_SUCCESS'));
					}
					this.close();
				},
				() => {
					this.toastNotificationService.sendErrorToast('PERMISSIONS_CHANGED_ERROR');
					this.close();
				}
			);
	}
}
