<div class="main-container">
	<div class="left-section">
		<div class="top-head">
			<h5>Support Inbox</h5>
		</div>
		<mat-divider></mat-divider>
		<div class="top-select">
			<mat-form-field appearance="fill">
				<mat-select value="all">
					<mat-option value="all">All</mat-option>
					<mat-option value="open">Open Tickets</mat-option>
					<mat-option value="closed">Closed Tickets</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="ticket-cards">
			<div class="avatar">
				<img src="../../../assets/icons/account-icon.svg" />
			</div>
			<div class="details">
				<div class="top">
					<mat-chip>
						Open
					</mat-chip>
					<h6>#1923874</h6>
					<h6>{{ todayDate | date }}</h6>
				</div>
				<div class="bottom">
					I cannot see on contact added initially so let's see...
				</div>
			</div>
		</div>
	</div>
	<div class="right-section">
		<div class="top-bar">
			<div class="left">
				<img src="../../../assets/icons/Group 14152.svg" />
				<h5>Some support topic</h5>
				<h5>#1923874</h5>
			</div>
			<div class="right">
				<mat-chip>
					Open
				</mat-chip>
				<div>
					<h6>Ticket Created: {{ todayDate | date: 'long' }}</h6>
				</div>
			</div>
		</div>
	</div>
</div>
