<section class="pr-container">
	<div class="content-wrapper">
		<div class="spacer"></div>
		<font03>Review and Publish</font03>
		<div class="spacer"></div>
		<card-collapsible
			imgLink="assets/icons/ads-manager/aaa/select-grid.svg"
			title="Selected Campaigns"
			subtitle="Apply the ads to the following campaigns and ad sets"
			buttonTitle="edit"
			(onClick)="editCampaign()"
			[disabled]="disableEditCampaign"
			width="51.875rem"
		>
			<ng-container>
				<div class="inner-collapse">
					<div *ngFor="let campaign of ActiveCampaigns">
						<app-collapse-card
							*ngIf="!campaign.adSets || flow >= 2"
							[title]="campaign.campaign_name"
							[hideAdsetCount]="hideAdsetCounter"
						></app-collapse-card>
						<app-collapse-card
							*ngIf="campaign.adSets && flow < 2"
							title="{{ campaign.campaign_name }}"
							[adSetCount]="campaign.adSets.length"
							isCollapsed="false"
						>
							<div class="adset-list">
								<div style="margin-bottom: 12px;" *ngFor="let adSet of campaign.adSets">
									<font08-4>{{ adSet.adset_name }}</font08-4>
								</div>
							</div>
						</app-collapse-card>
					</div>
				</div>
			</ng-container>
		</card-collapsible>
		<div *ngIf="flow >= 2">
			<div class="spacer"></div>
			<card-collapsible
				imgLink="assets/icons/ads-manager/aaa/adset-card.svg"
				title="Ad Set Details"
				subtitle="An Ad Set with following details will be created"
				buttonTitle="edit"
				(onClick)="editAdset()"
				[iscollapsed]="true"
			>
				<ng-container>
					<div *ngIf="chosenAdset.adSetName">
						<div class="adset-items">
							<font07>Budget</font07>
							<font08>{{ chosenAdset?.budgetOptimization.amount | currency: 'USD' }}</font08>
						</div>
						<div class="adset-items">
							<font07>Start Date</font07>
							<font08>{{ chosenAdset?.date?.startDate | date: 'fullDate' }}</font08>
						</div>
						<div class="adset-items">
							<font07>End Date</font07>
							<font08>{{ chosenAdset.date.endDate ? (chosenAdset.date.endDate | date: 'fullDate') : 'No End Date' }}</font08>
						</div>
						<div class="adset-items">
							<font07>Gender</font07>
							<font08>{{ formatGender(chosenAdset?.targeting.gender) }}</font08>
						</div>
						<div class="adset-items">
							<font07>Age</font07>
							<font08>{{ chosenAdset?.targeting.ageRange.minAge }} - {{ chosenAdset?.targeting.ageRange.maxAge }}</font08>
						</div>
						<div class="adset-items">
							<font07>Location</font07>
							<font08>
								<ng-template ngFor let-locations [ngForOf]="chosenAdset?.targeting?.locations" let-i="index">
									<span *ngIf="i > 0">,</span>
									<span>{{ locations.name | titlecase }}</span>
								</ng-template>
							</font08>
						</div>
						<div class="adset-items">
							<font07>Devices</font07>
							<font08>{{ chosenAdset?.device | titlecase }}</font08>
						</div>
						<div class="adset-items">
							<font07>Placements</font07>
							<font08>
								<ng-template ngFor let-placements [ngForOf]="chosenAdset.placements" let-i="index">
									<span *ngIf="i > 0">,</span>
									<span>{{ placements.name | titlecase }}</span>
								</ng-template>
							</font08>
						</div>
						<div class="adset-items">
							<font07>Optimization Goal</font07>
							<font08>{{ chosenAdset?.optimizationAndDelivery?.optimizationGoal | snakeCaseToHuman }}</font08>
						</div>
						<div class="adset-items">
							<font07>Bid Strategy</font07>
							<font08>{{ chosenAdset?.budgetOptimization?.bidStrategy | snakeCaseToHuman }}</font08>
						</div>
						<div class="adset-items">
							<font07>Bid Control</font07>
							<font08>{{ chosenAdset?.optimizationAndDelivery?.bidControl | currency: 'USD' }}</font08>
						</div>
						<div class="adset-items">
							<font07>When you get charge</font07>
							<font08>{{ chosenAdset?.optimizationAndDelivery?.billingEvent | titlecase }}</font08>
						</div>
					</div>
					<div *ngIf="!chosenAdset.adSetName && selectedAdsets">
						<div *ngIf="loadingSelectedAdset; else loadedEnd">
							<i class="fa fa-spin fa-spinner"></i>
						</div>
						<ng-template #loadedEnd>
							<div class="adset-items">
								<font07>Budget</font07>
								<font08>{{ selectedAdsetDetail?.adsetBudgetOptimization?.amount | currency: 'USD' }}</font08>
							</div>
							<div class="adset-items">
								<font07>Start Date</font07>
								<font08>{{ selectedAdsetDetail?.startTime | date: 'fullDate' }}</font08>
							</div>
							<div class="adset-items">
								<font07>End Date</font07>
								<font08>{{ selectedAdsetDetail?.endTime ? (selectedAdsetDetail?.endTime | date: 'fullDate') : 'No End Date' }}</font08>
							</div>
							<div class="adset-items">
								<font07>Gender</font07>
								<font08>{{ formatGender(selectedAdsetDetail?.gender) }}</font08>
							</div>
							<div class="adset-items">
								<font07>Age</font07>
								<font08>{{ selectedAdsetDetail?.minAge }} - {{ selectedAdsetDetail?.maxAge }}</font08>
							</div>
							<div class="adset-items">
								<font07>Location</font07>
								<font08>
									<ng-template ngFor let-locations [ngForOf]="selectedAdsetDetail?.locations" let-i="index">
										<span *ngIf="i > 0">,</span>
										<span>{{ locations.name | titlecase }}</span>
									</ng-template>
								</font08>
							</div>
							<div class="adset-items">
								<font07>Devices</font07>
								<font08>{{ selectedAdsetDetail?.devicePlatforms | titlecase }}</font08>
							</div>
							<div class="adset-items">
								<font07>Optimization Goal</font07>
								<font08>{{ selectedAdsetDetail?.optimizationGoal | snakeCaseToHuman }}</font08>
							</div>
							<div class="adset-items">
								<font07>Bid Control</font07>
								<font08>{{ selectedAdsetDetail?.bidControl ? (selectedAdsetDetail?.bidControl | currency: 'USD') : 'None' }}</font08>
							</div>
							<div class="adset-items">
								<font07>When you get charge</font07>
								<font08>{{ selectedAdsetDetail?.billingEvent | titlecase }}</font08>
							</div>
						</ng-template>
					</div>
				</ng-container>
			</card-collapsible>
		</div>

		<div class="spacer"></div>
		<card-collapsible
			imgLink="assets/icons/ads-manager/aaa/ad-card-icon.svg"
			title="Create New Ads"
			subtitle="The Following Ads will be published"
			[miniDescription]="'( ' + totalAds + ' Ads)'"
			buttonTitle="edit"
			(onClick)="editAdd()"
			[disabled]="disableEditAdd"
			width="51.875rem"
		>
			<ng-container>
				<div class="group" *ngIf="isfetchingExisitingAds; else ads">
					<lolly-spinning-loader class="spinner"></lolly-spinning-loader>
				</div>
				<ng-template #ads>
					<div class="ad-items-container" *ngIf="selectedAdsetDetail?.ads?.length > 0">
						<p class="text-header">Exisiting Ads in Adset</p>
						<div class="ad-item" *ngFor="let Ads of selectedAdsetDetail?.ads; let i = index">
							<img src="{{ exisitingAdImages[i] }}" />
							<font07-4>{{ Ads.name | limitLength: 17:'...' }}</font07-4>
						</div>
					</div>
					<div class="ad-items-container" *ngIf="currentAds.length > 0">
						<div class="ad-item" *ngFor="let Ads of currentAds">
							<img src="{{ Ads.adverts.media_url }}" />
							<font07-4>{{ Ads.adName | limitLength: 17:'...' }}</font07-4>
						</div>
					</div>
					<div class="ad-items-container" *ngIf="selectedAds.length > 0 && currentAds.length <= 0">
						<div class="ad-item" *ngFor="let Ads of selectedAds">
							<img src="{{ Ads.ad_image }}" alt="adImage" />
							<font07-4>{{ Ads.ad_name | limitLength: 17:'...' }}</font07-4>
						</div>
					</div>
				</ng-template>
			</ng-container>
		</card-collapsible>
	</div>
</section>
<div class="bottomSpacing"></div>
