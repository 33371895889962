import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-filed-logo',
	templateUrl: './filed-logo.component.html',
	styleUrls: ['./filed-logo.component.scss']
})
export class FiledLogoComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
