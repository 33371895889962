import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DropdownData } from '@filed-com/filed-lib';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CardDefaultInterface, EditCardInterface } from 'src/app/authentication/sign-up/subscribe.interface';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { CompleteModalComponent } from '../../../modals/complete-modal/complete-modal.component';

@Component({
	selector: 'app-billing-details',
	templateUrl: './billing-details.component.html',
	styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {
	public userForm: FormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public userId: string;
	public decodeJwtToken: UserDetailsInterface;
	public countries: Country[];
	public dropdownCountries: any[];
	public loading: boolean;
	public isEditMode: boolean = false;
	public defaultCard: any;
	public noCards: boolean;

	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private formBuilder: FormBuilder,
		private billingService: PaymentServiceApi,
		private sharedStore: Store<SharedState>,
		private toastService: ToastNotificationService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
		this.setDropdownCountries();
		this.createForm();
		this.loadUserCreditCards();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private createForm(): void {
		this.userForm = this.formBuilder.group({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			firstAddress: new FormControl('', [Validators.required, Validators.maxLength(70)]),
			secondAddress: new FormControl('', [Validators.required, Validators.maxLength(70)]),
			city: new FormControl('', Validators.required),
			zipcode: new FormControl('', Validators.required),
			country: new FormControl('', Validators.required),
			getCardFailsText: new FormControl(false)
		});
		this.userForm.disable();
	}

	public editForm() {
		if (this.noCards) {
			this.toastService.sendWarningToast('Please add a card');
			return;
		}
		this.router.navigateByUrl(
			`/user-management/payment-and-billing/edit-card?lastdigit=${this.defaultCard.cardLastFourDigits}&type=${this.defaultCard.cardType}`
		);
		//this.userForm.enable();
		//this.isEditMode = true;
	}
	public loadUserCreditCards(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getPaymentCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						const cards = details.filter(card => card.isDefault);
						if (cards.length > 0) {
							this.noCards = false;
							this.defaultCard = cards[0];
							const billingInfo = this.defaultCard.billingInfo;
							this.userForm.controls['firstName'].setValue(`${billingInfo?.holderName.split(' ')[0] || ''}`);
							this.userForm.controls['lastName'].setValue(`${billingInfo?.holderName.split(' ')[1] || ''}`);
							this.userForm.controls['firstAddress'].setValue(billingInfo?.address1 || '');
							this.userForm.controls['secondAddress'].setValue(billingInfo?.address2 || '');
							this.userForm.controls['city'].setValue(billingInfo?.city || '');
							this.userForm.controls['zipcode'].setValue(billingInfo?.zip || '');
							this.fixCountry(billingInfo?.country);
						} else {
							this.noCards = true;
						}
					}
				},
				error => {},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public fixCountry(countryCode: string): void {
		if (countryCode) {
			let currentCountry = this.dropdownCountries.filter(country => country.value.value == countryCode.toUpperCase());
			this.userForm.controls['country'].setValue(currentCountry[0].label);
		}
	}
	public getMySelectedCard(payload: CardDefaultInterface): void {
		this.billingService
			.getMyCardDetails(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					const billingInfo = details?.billingInfo;
					const creditCard = details?.creditCard;
					let firstname = billingInfo?.firstName || '';
					let lastname = billingInfo?.lastName ? ' ' + billingInfo?.lastName : '';
					this.userForm.controls['firstNme'].setValue(firstname);
					this.userForm.controls['lasttNme'].setValue(lastname);
					this.userForm.controls['firstAddress'].setValue(billingInfo?.address1 || '');
					this.userForm.controls['secondAddress'].setValue(billingInfo?.address2 || '');
					this.userForm.controls['city'].setValue(billingInfo?.city || '');
					this.userForm.controls['zipcode'].setValue(billingInfo?.zip || '');
				},
				error => {
					console.log(error);
				}
			);
	}

	private setDropdownCountries(): void {
		this.backOfficeService
			.getAllCountriesList()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.countries = result;
				this.dropdownCountries = this.countries.map((value, index) => {
					return {
						value: value,
						label: value.name,
						id: index
					};
				});
			});
	}

	public onFormChange(value: string): void {}
	public onSaveBillings(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		let firstname = this.userForm.get('firstName').value;
		let lastname = this.userForm.get('lastName').value;
		const country = this.userForm.get('country').value;
		const card_detail: EditCardInterface = {
			cardLastFourDigit: this.defaultCard.cardLastFourDigits,
			currentCardType: this.defaultCard.cardType,
			expirationMonth: this.defaultCard.expirationMonth,
			expirationYear: this.defaultCard.expirationYear,
			securityCode: this.defaultCard.securityCode,
			firstName: firstname,
			lastName: firstname.length > 1 ? lastname : firstname,
			address1: this.userForm.get('firstAddress').value,
			address2: this.userForm.get('secondAddress').value || '',
			city: this.userForm.get('city').value,
			country: country.value,
			zip: this.userForm.controls['zipcode'].value,
			isDefault: this.defaultCard.isDefault,
			personalIdentificationNumber: ''
		};
		this.billingService
			.updatePaymentCards(card_detail)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detailResponse => {
					this.openConfirmModal();
				},
				({ error }) => {
					this.toastService.sendErrorToast('Error occured while processing your card information.', 8000);
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
		this.openConfirmModal();
	}
	public openConfirmModal(): void {
		const dialogRef = this.dialog.open(CompleteModalComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Billing Information',
			text: 'You have successfully changed your billing information.',
			closeBtn: false,
			actionBtn: false
		};
		dialogRef.afterClosed().subscribe(() => {
			this.isEditMode = false;
		});
	}

	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.userForm.get('country').setValue(selected);
		}
	}
}
