import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { InsightsCategoryTypeEnum } from 'src/app/shared/campaign-insights/models/insights-category-type.enum';

export const getViewCategoryKey = (channel: SourceChannel, categoryType: InsightsCategoryTypeEnum) => {
	switch (channel) {
		case SourceChannel.Facebook:
			switch (categoryType) {
				case InsightsCategoryTypeEnum.Campaign:
					return 'facebookCampaignsData';
				case InsightsCategoryTypeEnum.AdSet:
					return 'facebookAdsetsData';
				case InsightsCategoryTypeEnum.Ad:
					return 'facebookAdsData';
			}
			break;

		case SourceChannel.Google:
			switch (categoryType) {
				case InsightsCategoryTypeEnum.Campaign:
					return 'googleCampaignsData';
				case InsightsCategoryTypeEnum.AdGroup:
					return 'googleAdgroupsData';
				case InsightsCategoryTypeEnum.Ad:
					return 'googleAdsData';
				case InsightsCategoryTypeEnum.Keyword:
					return 'googleKeywordsData';
			}
			break;

		default:
			return null;
	}
};
