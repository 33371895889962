import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';

@Component({
	selector: 'app-first-dialog-component',
	templateUrl: './first-dialog.component.html',
	styleUrls: ['./first-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FirstLoginDialogComponent implements OnInit, OnDestroy {
	public userName: string;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor(private dialogRef: MatDialogRef<FirstLoginDialogComponent>, @Inject(MAT_DIALOG_DATA) public userNameData: any, private _router: Router) {
		this.userName = userNameData;
	}

	ngOnDestroy() {}

	ngOnInit() {}

	public createNewCampaign() {
		this._router.navigate(['/campaign-builder'], { queryParams: { isNew: true, forceSingle: true } });
		this.dialogRef.close();
	}

	public viewAnExistingCampaign() {
		this._router.navigate(['/campaign-builder']);
		this.dialogRef.close();
	}
}
