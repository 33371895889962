import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { RegisterUserEmail, RegisterUserToken, RegistrationDetail } from '../../state/authentication.action';

@Component({
	selector: 'app-dexter-welcome',
	templateUrl: './dexter-welcome.component.html',
	styleUrls: ['./dexter-welcome.component.scss']
})
export class DexterWelcomeComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public token: string;
	public emailValue: string;

	constructor(private router: Router, private activatedRoute: ActivatedRoute, private store: Store<UserManagementState>) {}

	ngOnInit(): void {
		this.store.dispatch(
			new RegistrationDetail({
				name: '',
				lastname: '',
				phone: 0,
				dialCode: '',
				email: '',
				password: '',
				plan: 0,
				duration: '',
				token: '',
				companyName: ''
			})
		);
		this.getActivatedRoute();
	}

	public continueSignup(): void {
		this.router.navigate(['/authentication/register-password']);
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.token = encodeURIComponent(params['token']);
			this.emailValue = decodeURIComponent(params['email']);
			this.store.dispatch(new RegisterUserEmail(this.emailValue));
			this.store.dispatch(new RegisterUserToken(this.token));
		});
	}
}
