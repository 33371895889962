import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ChargeBeeFormErrorsDTO } from './interfaces/charge-form-errors-dto.interface';
import { StripeFormErrorsDTO } from './interfaces/stripe-form-error-dto.interface';
import { StripeCreditCard } from './models/stripe-creditcard.class';
import { FromControlErrorEnum } from './enums/from-control-error.enum';
import { StripeFormTypeEnum } from './enums/stripe-form-type.enum';
import { StripeKeyEnum } from './enums/stripe-key.enum';

@Injectable({
	providedIn: 'root'
})
export class SetCreditCardService {
	public async validateStripeCard(finalFormControls: { [key: string]: AbstractControl }): Promise<{ status: number; response: any }> {
		await this.loadStripe();
		return new Promise(resolve => {
			const creditCard = new StripeCreditCard();
			finalFormControls.cardHolder && finalFormControls.cardHolder.value && (creditCard.name = finalFormControls.cardHolder.value.toString());
			finalFormControls.number && finalFormControls.number.value && (creditCard.number = finalFormControls.number.value.toString().replace(/\s/g, ''));
			if (finalFormControls.expirationDate && finalFormControls.expirationDate.value) {
				const dates = finalFormControls.expirationDate.value.split('');
				creditCard.exp_month = dates.slice(0, 2).join('');
				creditCard.exp_year = dates.slice(2, 4).join('');
			}

			finalFormControls.cvc && finalFormControls.cvc.value && (creditCard.cvc = finalFormControls.cvc.value.toString());
			finalFormControls.address1 &&
				finalFormControls.address1.value &&
				(creditCard.address_line1 =
					finalFormControls.company && finalFormControls.company.value
						? `${finalFormControls.address1.value} / ${finalFormControls.company.value}`
						: `${finalFormControls.address1.value}`);

			finalFormControls.address2 &&
				finalFormControls.address2 &&
				(creditCard.address_line2 = (finalFormControls.address2.value && finalFormControls.address2.value.toString()) || '');
			finalFormControls.city && finalFormControls.city.value && (creditCard.address_city = finalFormControls.city.value.toString());
			finalFormControls.zip && finalFormControls.zip.value && (creditCard.address_zip = finalFormControls.zip.value.toString());
			finalFormControls.county && finalFormControls.county.value && (creditCard.address_state = finalFormControls.county.value.toString());
			finalFormControls.country &&
				finalFormControls.country.value.data.value &&
				(creditCard.address_country = finalFormControls.country.value.data.value.toString());

			return (window as any).Stripe.card.createToken(creditCard, (status: number, response: any) => {
				return resolve({
					status,
					response
				});
			});
		});
	}

	public getFormControlWithErrorFromStripe(stripeFormErrorsDTO: StripeFormErrorsDTO): string {
		// todo: those should be presented somewhere...in their API and switch to dynamic mapping
		let formControlWithError: string;

		switch (stripeFormErrorsDTO.code) {
			case StripeFormTypeEnum.incorrectAddress:
				formControlWithError = FromControlErrorEnum.address;
				break;
			case StripeFormTypeEnum.incorrectCvc:
				formControlWithError = FromControlErrorEnum.cvc;
				break;
			case StripeFormTypeEnum.incorrectNumber:
				formControlWithError = FromControlErrorEnum.number;
				break;
			case StripeFormTypeEnum.invalidNumber:
				formControlWithError = FromControlErrorEnum.number;
				break;
			case StripeFormTypeEnum.accountNumberInvalid:
				formControlWithError = FromControlErrorEnum.number;
				break;
			case StripeFormTypeEnum.invalidCardType:
				formControlWithError = FromControlErrorEnum.number;
				break;
			case StripeFormTypeEnum.invalidCvc:
				formControlWithError = FromControlErrorEnum.cvc;
				break;
			case StripeFormTypeEnum.invalidExpiryMonth:
				formControlWithError = FromControlErrorEnum.expirationDate;
				break;
			case StripeFormTypeEnum.invalidExpiryYear:
				formControlWithError = FromControlErrorEnum.expirationDate;
				break;
			case StripeFormTypeEnum.cardDeclined:
				formControlWithError = FromControlErrorEnum.number;
				break;
		}

		return formControlWithError;
	}

	public getFormControlWithErrorFromChargebeeAddNewCard(chargeBeeFormErrorsDTO: ChargeBeeFormErrorsDTO): string {
		let formControlWithError: string;
		switch (chargeBeeFormErrorsDTO.code) {
			case StripeFormTypeEnum.billingZip:
				formControlWithError = FromControlErrorEnum.zip;
		}

		return formControlWithError;
	}

	public async loadStripe() {
		return new Promise(resolve => {
			if (!window.document.getElementById('stripe-custom-form-script')) {
				const s = window.document.createElement('script');
				s.id = 'stripe-custom-form-script';
				s.type = 'text/javascript';
				s.src = 'https://js.stripe.com/v2/';
				s.onload = () => {
					(window as any).Stripe.setPublishableKey(StripeKeyEnum.stripePublishKey);
					resolve();
				};

				window.document.body.appendChild(s);
			} else {
				return resolve();
			}
		});
	}
}
