import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseOffersModel } from '../../back-office/models/base-offers.model';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { AllCountriesInterface } from '../../back-office/models/all-countries.interface';
import { AllCurrenciesInterface } from '../../back-office/models/all-currencies.interface';
import { OfferInterface } from '../../back-office/models/base-offers.interface';
import { FreemiumOffersInterface } from '../../back-office/models/freemium-offers.interface';
import { ChangeOfferInterface } from '../../back-office/models/change-offer.interface';
import { BillingAddressUpdate } from 'src/app/user-management/shared/billing-and-payment/billing-information-update.models';
import { BillingAddress } from '../../user-management/shared/billing-and-payment/billing-and-payment.models';
import { DiscountDurationStringsObject } from '../../back-office/models/discount-duration-strings.object';
import { map } from 'rxjs/operators/map';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class BillingAndSubscriptionService {
	private readonly baseOffersURL = `${BaseApiUrl.Subscription}baseOffers/mainOffer`;

	public toggleEventAction$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	constructor(protected http: HttpClient) {}

	public getBasePublicOffers(): Observable<BaseOffersModel[]> {
		return this.http.get<BaseOffersModel[]>(`${BaseApiUrl.Subscription}baseOffers/public`).pipe(
			map(res => {
				res.map(element => {
					element['offerUrl'] = `${location.origin}/authentication/sign-up?id=${element.id}`;
					element.users = element.users ? element.users : null;
					element.hasPaymentWall = element.hasPaymentWall ? 'Yes' : 'No';
					switch (element?.discount?.duration) {
						case 1:
							element.discount.duration = DiscountDurationStringsObject.oneMonth;
							break;
						case 3:
							element.discount.duration = DiscountDurationStringsObject.threeMonths;
							break;
						case 6:
							element.discount.duration = DiscountDurationStringsObject.sixMonths;
							break;
						case 12:
							element.discount.duration = DiscountDurationStringsObject.oneYear;
							break;
					}
				});
				return res;
			})
		);
	}

	public getBaseOffers(): Observable<BaseOffersModel[]> {
		return this.http.get<BaseOffersModel[]>(`${BaseApiUrl.Subscription}BaseOffers/my-plans`).pipe(
			map(res => {
				res.map(element => {
					element['offerUrl'] = `${location.origin}/authentication/sign-up?id=${element.id}`;
					element.users = element.users ? element.users : null;
					element.hasPaymentWall = element.hasPaymentWall ? 'Yes' : 'No';
					switch (element?.discount?.duration) {
						case 1:
							element.discount.duration = DiscountDurationStringsObject.oneMonth;
							break;
						case 3:
							element.discount.duration = DiscountDurationStringsObject.threeMonths;
							break;
						case 6:
							element.discount.duration = DiscountDurationStringsObject.sixMonths;
							break;
						case 12:
							element.discount.duration = DiscountDurationStringsObject.oneYear;
							break;
					}
				});
				return res;
			})
		);
	}

	public getBackBaseOffers(): Observable<BaseOffersModel[]> {
		return this.http.get<BaseOffersModel[]>(`${BaseApiUrl.Subscription}BaseOffers`).pipe(
			map(res => {
				res.map(element => {
					element['offerUrl'] = `${location.origin}/authentication/sign-up?id=${element.id}`;
					element.users = element.users ? element.users : null;
					element.hasPaymentWall = element.hasPaymentWall ? 'Yes' : 'No';
					switch (element?.discount?.duration) {
						case 1:
							element.discount.duration = DiscountDurationStringsObject.oneMonth;
							break;
						case 3:
							element.discount.duration = DiscountDurationStringsObject.threeMonths;
							break;
						case 6:
							element.discount.duration = DiscountDurationStringsObject.sixMonths;
							break;
						case 12:
							element.discount.duration = DiscountDurationStringsObject.oneYear;
							break;
					}
				});
				return res;
			})
		);
	}

	public getFreemiumOffers(): Observable<FreemiumOffersInterface[]> {
		return this.http.get<FreemiumOffersInterface[]>(`${BaseApiUrl.Subscription}freemiumOffers`).pipe(
			map(res => {
				res.map(element => {
					element['offerUrl'] = `${location.origin}/authentication/sign-up?id=${element.id}`;
					element.users = element.users ? element.users : null;
					element.hasPaymentWall = element.hasPaymentWall ? 'Yes' : 'No';
					switch (element?.discount?.duration) {
						case 1:
							element.discount.duration = DiscountDurationStringsObject.oneMonth;
							break;
						case 3:
							element.discount.duration = DiscountDurationStringsObject.threeMonths;
							break;
						case 6:
							element.discount.duration = DiscountDurationStringsObject.sixMonths;
							break;
						case 12:
							element.discount.duration = DiscountDurationStringsObject.oneYear;
							break;
						case 9999:
							element.discount.duration = DiscountDurationStringsObject.lifetime;
							break;
					}
					if (element?.thresholds.length > 0) {
						element.threshold = element?.thresholds[0];
					}
				});
				return res;
			})
		);
	}

	public deleteBaseOffer(id: number): Observable<void> {
		return this.http.delete<void>(`${BaseApiUrl.Subscription}baseOffers/${id}`);
	}

	public deleteFreemiumOffer(id: number): Observable<void> {
		return this.http.delete<void>(`${BaseApiUrl.Subscription}freemiumOffers/${id}`);
	}

	public createBaseOffer(offer: OfferInterface): Observable<number> {
		return this.http.post<number>(`${BaseApiUrl.Subscription}baseOffers`, offer);
	}

	public updateBaseOffer(id: number, offer: OfferInterface): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}baseOffers/${id}`, offer);
	}

	public updateBaseOfferURL(id: number, url: string): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}baseOffers/${id}/landingPageUrl`, { LandingPageUrl: url });
	}

	public changeOffer(data: ChangeOfferInterface): Observable<ChangeOfferInterface> {
		return this.http.post<ChangeOfferInterface>(`${BaseApiUrl.Subscription}Subscriptions/changeOffer`, data);
	}

	public activateOffer(offerId: number, contactsIds: number[]): Observable<ChangeOfferInterface> {
		return this.http.post<ChangeOfferInterface>(`${BaseApiUrl.Subscription}subscriptions/activateOffer/${offerId}`, { ContactIds: contactsIds });
	}

	public createFreemiumOffer(offer: FreemiumOffersInterface): Observable<number> {
		return this.http.post<number>(`${BaseApiUrl.Subscription}freemiumOffers`, offer);
	}

	public pauseSubscription(contactId: number, amountOfMonths: number): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}subscriptions/contact/${contactId}/pause-subscription`, { Months: amountOfMonths });
	}

	public resumeSubscription(contactId: number): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}subscriptions/reactivate-subscription?contactId=${contactId}`, {});
	}

	public updateFreemiumOffer(id: number, offer: FreemiumOffersInterface): Observable<number> {
		return this.http.put<number>(`${BaseApiUrl.Subscription}freemiumOffers/${id}`, offer);
	}

	public updateFreemiumOfferURL(id: number, url: string): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}freemiumOffers/${id}/landingPageUrl`, { LandingPageUrl: url });
	}

	public getCountries(): Observable<AllCountriesInterface> {
		return this.http.get<AllCountriesInterface>(`${BaseApiUrl.Subscription}countries`);
	}

	public getCurrencies(): Observable<AllCurrenciesInterface[]> {
		return this.http.get<AllCurrenciesInterface[]>(`${BaseApiUrl.Subscription}currencies`);
	}

	public updateBillingDetails(billingData: BillingAddressUpdate): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.Subscription}accounts`, billingData);
	}

	public userFullDelete(userEmail: string): Observable<HttpResponse<void>> {
		return this.http.delete<void>(`${BaseApiUrl.Subscription}IntegrationTests?email=${userEmail}`, { observe: 'response' });
	}

	public updateSubcribeToId(id: number): Observable<void> {
		return this.http.put<void>(`${this.baseOffersURL}/${id}`, null);
	}

	public getSubcribeToId(): Observable<any> {
		return this.http.get<any>(`${this.baseOffersURL}`);
	}
}
