import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { Router } from '@angular/router';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { RegisterUserCompanyName, RegisterUserDialCode, RegisterUserLastName, RegisterUserName, RegisterUserPhone } from '../../state/authentication.action';
import { CountryDropdownValueInterface } from 'src/app/shared/models/country-dropdown-value.interface';
import { FieldValidators } from 'src/app/user-management/models/validators';
import { UserDetailModel } from '../../_models/login.model';
import { ErrorMessageService } from 'src/app/shared/form-input/error-message.service';
import { sleep } from '../utils/signup-utils';
import { Ntf01Service } from '@filed-com/filed-lib';
import { I } from '@angular/cdk/keycodes';
import { TimeZoneService } from 'src/app/shared/services/time-zone.service';

@Component({
	selector: 'app-personal-detail',
	templateUrl: './personal-detail.component.html',
	styleUrls: ['./personal-detail.component.scss']
})
export class PersonalDetailComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public accountForm: FormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;

	public defaultCountryCode = 'GB';
	public selectedCountry: CountryDropdownValueInterface;
	public timezone: string;

	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private store: Store<AuthenticationState>,
		private errorMessageService: ErrorMessageService,
		private toastNotificationService: Ntf01Service,
		private timeZoneService: TimeZoneService
	) {
		this.selectedCountry = {
			code: 'GB',
			dialcode: '+44'
		};

		const code = this.timeZoneService.getCountryCode();
		const dialCode = this.timeZoneService.getCountryDialCode();
		this.selectedCountry.code = code;
		this.defaultCountryCode = code;
		this.selectedCountry.dialcode = dialCode;
	}

	public ngOnInit(): void {
		this.createForm();
		this.formChanges();
	}

	public ngOnDestroy(): void {}

	public onFormNameChange(value: string): void {
		this.store.dispatch(new RegisterUserName(this.accountForm.controls['name'].value));
	}

	public onFormLastNameChange(value: string): void {
		this.store.dispatch(new RegisterUserLastName(this.accountForm.controls['lastname'].value));
	}

	public onFormCompanyNameChange(value: string): void {
		this.store.dispatch(new RegisterUserCompanyName(this.accountForm.controls['companyName'].value));
	}

	public onFormNumberChange(value: string): void {
		this.store.dispatch(new RegisterUserPhone(this.accountForm.controls['phone'].value));
	}

	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.accountForm.controls['name'].setValue(detail.name);
				this.accountForm.controls['lastname'].setValue(detail.lastname);
				this.accountForm.controls['phone'].setValue(detail.phone);
				let decodedDialcode = JSON.parse(detail.dialCode) || { code: this.defaultCountryCode, dialcode: '' };
				this.accountForm.controls['dialcode'].setValue(decodedDialcode.dialCode);
				this.defaultCountryCode = decodedDialcode.code;
				this.accountForm.controls['companyName'].setValue(detail.companyName);
			}
		});
	}

	public formChanges(): void {
		this.accountForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
		this.accountForm.controls['phone'].updateValueAndValidity();
		this.accountForm.controls['dialcode'].updateValueAndValidity();
	}

	private createForm(): void {
		this.accountForm = this.formBuilder.group({
			name: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
			lastname: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
			companyName: new FormControl(null),
			dialcode: new FormControl(''),
			phone: new FormControl(null, [Validators.minLength(7), Validators.required, Validators.maxLength(11), Validators.pattern(FieldValidators.Phone)])
		});
	}

	public errorMessages(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validationMessages, this.accountForm.controls['name']);
	}

	public onSelectCountry(event: CountryDropdownValueInterface): void {
		this.selectedCountry = event;
		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');

		this.accountForm.controls['phone'].setValidators(Validators.maxLength(15 - dialCodeNumber.length));

		const dropdownCountryCode = this.accountForm.get('dialcode');
		if (this.selectedCountry?.dialcode !== undefined) {
			dropdownCountryCode.setValidators(null);
		}

		if (this.selectedCountry?.dialcode === undefined) {
			dropdownCountryCode.setValidators([Validators.required]);
		}

		this.accountForm.controls['dialcode'].setValue(this.selectedCountry.dialcode);
		dropdownCountryCode.updateValueAndValidity();
		if (this.accountForm.get('dialcode').valid) {
			this.store.dispatch(new RegisterUserDialCode(JSON.stringify(this.selectedCountry)));
		}
	}

	public getValidationMessage(): ValidatorMessages[] {
		return [
			{
				validationType: 'dialCode',
				validationMessage: `Please select a country and type in your phone number `
			},
			{
				validationType: 'phoneMaxLength',
				validationMessage: `Please select the right country or correct the phone number`
			},
			{
				validationType: 'phoneMinLength',
				validationMessage: `Phone number cannot be less the 7 digits. 
									Please select the correct country or type in the correct phone number `
			},
			{
				validationType: 'numbersOnly',
				validationMessage: `Please alphanumeric values not allowed `
			}
		];
	}

	public checkForInvalid(): void {
		const NDCandSN = this.accountForm.get('phone').value;
		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');
		if (dialCodeNumber === undefined) {
			this.accountForm.controls['phone'].setErrors({ dialCode: true });
		}

		if (NDCandSN !== null) {
			if (dialCodeNumber === undefined) {
				this.accountForm.controls['phone'].setErrors({ dialCode: true });
			}

			if (isNaN(Number(NDCandSN))) {
				this.accountForm.controls['phone'].setErrors({ numbersOnly: true });
			}

			if ((NDCandSN.toString() === '' || NDCandSN.toString().length < 7) && dialCodeNumber !== undefined) {
				this.accountForm.controls['phone'].setErrors({ phoneMinLength: true });
			}

			if (dialCodeNumber !== undefined && NDCandSN.toString().length > 7) {
				if (NDCandSN.toString().length + dialCodeNumber.length > 15) {
					this.accountForm.controls['phone'].setErrors({ phoneMaxLength: true });
				}
			}
		}
	}

	public continueSignup(): void {
		this.submitting = true;
		sleep(3000);
		this.router.navigate(['/authentication/verify-email']);
	}
}
