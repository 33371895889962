import { Action } from '@ngrx/store';
import { PixelsDto } from '../_models/pixels-dto.model';
import { PixelsRequest } from '../_models/pixels-request.model';
import { EventDtoInterface } from '../_models/event-dto.interface';
import { PixelInsightsCommand } from '../_models/pixel-query.model';
import { PixelEventInsightDto } from '../_models/pixel-event-insight-dto';
import { PixelBreakdown } from '../_models/breakdowns.model';

export enum PixelActionsTypes {
	PixelNavLinks = '[Pixel] Nav Links',
	PixelMessages = '[Pixel] Static Messages',
	LoadPixelsWIthTimeRange = '[Pixel] Pixels With Time Range',
	LoadPixelsSuccess = '[Pixel] Pixel Load Success',
	LoadPixelsFailure = '[Pixel] Pixel Load Failure',
	LoadPixelAggregations = '[Pixel] Aggregations',
	LoadPixelAggregationsSuccess = '[Pixel] Aggregations Load Success',
	LoadPixelAggregationsFailure = '[Pixel] Aggregations Load Failure',

	EventMessages = '[PixelEvent] Static Messages',
	LoadEventsByAdAccountId = '[PixelEvent] Load Events By Ad Account Id',
	LoadEventsByPixelId = '[PixelEvent] Load Events By Pixel Id',
	LoadEventsSuccess = '[PixelEvent] Load Events Success',
	LoadEventsFailure = '[PixelEvent] Load Events Failure',
	LoadEventsAggregations = '[PixelEvent] Aggregations',
	LoadEventsAggregationsSuccess = '[PixelEvent] Aggregations Load Success',
	LoadEventsAggregationsFailure = '[PixelEvent] Aggregations Load Failure',
	LoadEventInsights = '[PixelEvent] Load Event Insights',
	LoadEventInsightsSuccess = '[PixelEvent] Load Event Insights Success',
	LoadEventInsightsFailure = '[PixelEvent] Load Event Insights Failure'
}

export class LoadNavLinks implements Action {
	readonly type = PixelActionsTypes.PixelNavLinks;
	constructor() {}
}
export class LoadPixelMessages implements Action {
	readonly type = PixelActionsTypes.PixelMessages;
	constructor() {}
}

export class LoadEventMessages implements Action {
	readonly type = PixelActionsTypes.EventMessages;
	constructor() {}
}

export class LoadPixelsWithTimeRange implements Action {
	readonly type = PixelActionsTypes.LoadPixelsWIthTimeRange;
	constructor(public payload: PixelsRequest) {}
}

export class LoadPixelsSuccess implements Action {
	readonly type = PixelActionsTypes.LoadPixelsSuccess;
	constructor(public payload: PixelsDto[]) {}
}

export class LoadPixelsFailure implements Action {
	readonly type = PixelActionsTypes.LoadPixelsFailure;
	constructor(public payload: string) {}
}

export class LoadPixelAggregations implements Action {
	readonly type = PixelActionsTypes.LoadPixelAggregations;
	constructor() {}
}

export class LoadPixelAggregationsSuccess implements Action {
	readonly type = PixelActionsTypes.LoadPixelAggregationsSuccess;
	constructor(public payload: PixelBreakdown[]) {}
}

export class LoadPixelAggregationsFailure implements Action {
	readonly type = PixelActionsTypes.LoadPixelAggregationsFailure;
	constructor(public payload: string) {}
}

export class LoadEventsAggregations implements Action {
	readonly type = PixelActionsTypes.LoadEventsAggregations;
	constructor() {}
}

export class LoadEventsAggregationsSuccess implements Action {
	readonly type = PixelActionsTypes.LoadEventsAggregationsSuccess;
	constructor(public payload: PixelBreakdown[]) {}
}

export class LoadEventsAggregationsFailure implements Action {
	readonly type = PixelActionsTypes.LoadEventsAggregationsFailure;
	constructor(public payload: string) {}
}

export class LoadEventsByAdAccountId implements Action {
	readonly type = PixelActionsTypes.LoadEventsByAdAccountId;
	constructor(public payload: string) {}
}

export class LoadEventsByPixelId implements Action {
	readonly type = PixelActionsTypes.LoadEventsByPixelId;
	constructor(public payload: string) {}
}

export class LoadEventsSuccess implements Action {
	readonly type = PixelActionsTypes.LoadEventsSuccess;
	constructor(public payload: EventDtoInterface[]) {}
}

export class LoadEventsFailure implements Action {
	readonly type = PixelActionsTypes.LoadEventsFailure;
	constructor(public payload: string) {}
}

export class LoadEventInsights implements Action {
	readonly type = PixelActionsTypes.LoadEventInsights;
	constructor(public payload: PixelInsightsCommand) {}
}

export class LoadEventInsightsSuccess implements Action {
	readonly type = PixelActionsTypes.LoadEventInsightsSuccess;
	constructor(public payload: PixelEventInsightDto[]) {}
}

export class LoadEventInsightsFailure implements Action {
	readonly type = PixelActionsTypes.LoadEventInsightsFailure;
	constructor(public payload: string) {}
}

export type PixelAction =
	| LoadEventsAggregationsFailure
	| LoadPixelAggregationsFailure
	| LoadEventsFailure
	| LoadPixelsFailure
	| LoadNavLinks
	| LoadPixelMessages
	| LoadEventMessages
	| LoadPixelsWithTimeRange
	| LoadPixelsSuccess
	| LoadPixelAggregations
	| LoadPixelAggregationsSuccess
	| LoadEventsAggregations
	| LoadEventsAggregationsSuccess
	| LoadEventsByAdAccountId
	| LoadEventsSuccess
	| LoadEventsByPixelId
	| LoadEventInsights
	| LoadEventInsightsSuccess
	| LoadEventInsightsFailure;
