<app-campaign-smart-create-navigation
	#top
	[activeStep]="step"
	[campaignStep]="campaignLastStepId"
	(navigationClick)="onNavClick($event)"
></app-campaign-smart-create-navigation>

<div class="container">
	<div class="content-wrapper">
		<div class="header-control-wrapper">
			<div class="header-control-title">
				<span>A/B Testing Settings</span>
				<btn04>Campaign Summary</btn04>
			</div>
			<div class="toggle-summary-wrapper">
				<div class="header-control row">
					<div class="col">
						<div>
							<img src="assets/icons/campaign-smart-create/optimization/interest.svg" />
							<span>Interest</span>
							<mat-slide-toggle [disabled]="!canSplitInterests" [formControl]="toggleFormGroup.get('interest')"></mat-slide-toggle>
						</div>
						<div>
							<img src="assets/icons/campaign-smart-create/optimization/gender.svg" />
							<span>gender</span>
							<mat-slide-toggle [disabled]="!isSplitByGender" [formControl]="toggleFormGroup.get('gender')"></mat-slide-toggle>
						</div>
						<div>
							<img src="assets/icons/campaign-smart-create/optimization/age.svg" />
							<span>age range</span>
							<mat-slide-toggle [disabled]="!isSplitByAgeRange" [formControl]="toggleFormGroup.get('ageRange')"></mat-slide-toggle>
						</div>
						<div>
							<img src="assets/icons/campaign-smart-create/optimization/placement.svg" />
							<span>placements</span>
							<mat-slide-toggle [disabled]="!isSplitByPlacements" [formControl]="toggleFormGroup.get('placement')"></mat-slide-toggle>
						</div>
					</div>
					<div class="summary-wrapper col-md-3">
						<div class="summary">
							<h1>{{ optimizationDrafts?.liveAdsetCount | number }}</h1>
							<span>Total Live Ad Sets</span>
						</div>
						<div class="summary">
							<h1>
								{{
									!optimizationDrafts?.overallBudget
										? (optimizationDrafts?.proposedOverallBudget | currency: currency + ' ')
										: (optimizationDrafts?.overallBudget | currency: currency + ' ')
								}}
							</h1>
							<span>Total Budget</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white split-item-wrapper">
			<div class="table-responsive">
				<table class="table table-striped">
					<thead>
						<tr>
							<th>Ad Set Name</th>
							<th style="width: 190px;">Interest</th>
							<th style="width: 91px;">Gender</th>
							<th style="width: 75px;">Age Range</th>
							<th style="width: 119px;">Placements</th>
							<th style="width: 121px;">Budget</th>
							<th style="width: 53px;"></th>
							<th style="width: 57px;" class="text-center">
								<span>All</span>
								<mat-slide-toggle [checked]="true"></mat-slide-toggle>
							</th>
						</tr>
					</thead>
					<tbody class="split-item-wrapper">
						<tr *ngFor="let optimization of optimizationDrafts?.optimizationSettings; let index = index">
							<td>
								{{ optimization?.name }}
								<button class="edit-button btn-sm d-none" type="button">
									<i class="k-icon k-i-edit"></i>
								</button>
							</td>
							<td>
								<div *ngIf="optimization?.targeting?.interests">
									<div class="" *ngFor="let interest of optimization?.targeting?.interests">
										<span>{{ interest.name }}</span>
									</div>
								</div>
								<span *ngIf="!optimization?.targeting?.interests?.length">-</span>
							</td>
							<td>
								<div *ngIf="optimization?.targeting?.gender">
									<span *ngIf="optimization?.targeting?.gender === genderEnum.Women">Women</span>
									<span *ngIf="optimization?.targeting?.gender === genderEnum.Men">Men</span>
								</div>
								<span *ngIf="!optimization?.targeting?.gender">-</span>
							</td>
							<td>
								<span *ngIf="optimization?.targeting?.ageRange">
									{{ optimization?.targeting?.ageRange.minAge + ' - ' + optimization?.targeting?.ageRange?.maxAge }}
								</span>
								<span *ngIf="!optimization?.targeting?.ageRange">-</span>
							</td>
							<td>
								<span *ngIf="optimization?.placements?.length">{{ optimization.placements[0]?.displayNameAutogen }}</span>
								<span *ngIf="!optimization?.placements?.length">-</span>
							</td>
							<td>
								<span>
									{{
										(!optimization?.customBudgetAmount ? optimization?.defaultBudgetAmount : optimization?.customBudgetAmount)
											| currency: this.currency + ' '
									}}
								</span>
								<button class="edit-button btn-sm d-none" type="button"><i class="k-icon k-i-edit"></i></button>
							</td>
							<td><button class="btn-sm action-button" type="button" (click)="edit(optimization)">Edit</button></td>
							<td class="text-center">
								<mat-slide-toggle [checked]="optimization?.isLive" (toggleChange)="toggleLiveState(optimization, index)"></mat-slide-toggle>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<!-- <app-campaign-smart-create-footer
	[saveButtonText]="'Back'"
	[continueButtonText]="'Publish'"
	[isPublishing]="isPublishing"
	(next)="onPublish()"
	(cancel)="onCancelClick()"
	(save)="onBackClick()"
></app-campaign-smart-create-footer> -->
<app-campaign-smart-create-footer
	[saveButtonText]="'Back'"
	[continueButtonText]="'Publish'"
	[isPublishing]="isPublishing"
	(cancel)="onCancelClick()"
	(save)="onBackClick()"
></app-campaign-smart-create-footer>
