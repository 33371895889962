import { Bits } from './bits';

export enum ProductCatalogPermissions {
	CanAccessProductCatalog = Bits.Bit1,
	PCCatalogsProductSetsProductsVariantsView = Bits.Bit2,
	PCCatalogsProductSetsProductsVariantsEdit = Bits.Bit3,
	PCCatalogsProductSetsProductsVariantsDelete = Bits.Bit4,
	PCCatalogsProductSetsProductsVariantsCreate = Bits.Bit5,
	PCDiagnosticsView = Bits.Bit6,
	PCDiagnosticsEdit = Bits.Bit7,
	PCFacebookAndShopifyImport = Bits.Bit8,
	PCFacebookAndShopifyExport = Bits.Bit9
}
