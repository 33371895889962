import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BackOfficeService } from '../../../_services/back-office/back-office.service';

import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { TranslateService } from '@ngx-translate/core';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { Store } from '@ngrx/store';
import { UserManagementState } from '../../state/user-management.reducer';
import { SetActiveBillingPageUserManagement, SetInvoiceHistory } from '../../state/user-management.actions';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { BillingImagesEnum } from '../../shared/billing-and-payment/billing-tab.enum';
import { SettingsPageLabelsEnum } from '../../shared/enums/navigation.enum';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { UserServiceSubscription } from 'src/app/_services/user/user-subscription.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { getCurrencySymbol } from '@angular/common';
import { InvoiceViewAllPopupComponent } from '../../../back-office/invoice/invoice-view-all-popup/invoice-view-all-popup.component';
import { PopupDialogService } from '../../../_services/popup-dialog.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';

@Component({
	selector: 'app-billing-invoices',
	templateUrl: './invoices.component.html',
	styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, OnDestroy {
	@Input() public invoiceHistoryDetails: InvoiceHistory;
	public displayedColumns: string[] = ['id', 'date', 'total', 'status', 'actions'];
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public imagesPathEnum = BillingImagesEnum;
	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;
	public isLoaded = false;
	public dataSource: InvoiceHistory[] = [];

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private translate: TranslateService,
		private backOfficeService: BackOfficeService,
		private store: Store<UserManagementState>,
		private dialog: MatDialog,
		private popupDialog: PopupDialogService,
		private sharedStore: Store<SharedState>,
		private userServiceSubscription: UserServiceSubscription,
		private toastNotificationService: ToastNotificationService
	) {}

	public ngOnInit(): void {
		this.fetchStore();
	}

	public ngOnDestroy(): void {
		this.store.dispatch(new SetActiveBillingPageUserManagement(null));

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public convertCurrency(currency: string): string {
		return getCurrencySymbol(currency, 'wide');
	}

	public shareInvoice(bill: InvoiceHistory): void {
		this.popupDialog.shareInvoice(bill);
	}

	public payNow(currentInvoice: InvoiceHistory): void {
		this.popupDialog.payNow(currentInvoice);
	}
	public download(id: string): void {
		this.popupDialog.download(id);
	}

	public viewInvoiceDetails(id: string): void {
		this.popupDialog.showInvoice(id);
	}

	public viewAllInvoice = () => {
		this.dialog
			.open<InvoiceViewAllPopupComponent>(InvoiceViewAllPopupComponent, {
				data: {
					title: 'Invoice History'
				},
				width: '50%'
			})
			.afterClosed()
			.pipe();
	};

	private fetchStore(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.getInvoiceHistory()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					if (response) {
						this.isLoaded = true;
						this.dataSource = response.slice(0, 5).map((item, i) => {
							return item;
						});
						this.store.dispatch(new SetInvoiceHistory(response));
					}
					this.sharedStore.dispatch(new HideGlobalSpinner());
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}
}
