<div [ngClass]="{ 'show-spinner': localFlag, 'hide-spinner': !localFlag, 'delayed-hide': hide }" class="spinner-wrapper">
	<div class="spinner-inner">
		<div class="filed_logo">
			<img alt="Filed" src="/assets/logo/lolly-logo.svg" />
		</div>
		<div class="spinner">
			<div class="double-bounce1"></div>
			<div class="double-bounce2"></div>
		</div>
	</div>
</div>
