import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryBuilderAggregatorEnum } from '../../../shared/query-builder/models/query-builder.aggregator.enum';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { QueryModel, ReportsQueryModel, ReportsQueryTimeRangeModel } from '../../../shared/query-builder/models/query.model';
import { QueryBuilderConditionBlock } from '../../../shared/query-builder/models/query-builder-condition-block.model';
import { QueryBuilderLogicalOperator } from '../../../shared/query-builder/models/query-builder-logical-operator.enum';
import { QueryBuilderWhereOperator } from '../../../shared/query-builder/models/query-builder-where-operator.enum';
import { QueryBuilderService } from '../../../shared/query-builder/query-builder.service';
import { BaseApiUrl } from '../../../_services/base-api-urls';
import { InsightsCategoryTypeEnum } from '../../../shared/campaign-insights/models/insights-category-type.enum';
import { QueryBuilderCondition } from '../../../shared/query-builder/models/query-builder-condition.model';
import { take } from 'rxjs/operators';
import { SourceChannel } from '../../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { ReportModel } from '../../models/report.model';
import { TableViewColumnTemplateEnum } from 'src/app/shared/generic-table2/models/table-structure/table-view-column-template.enum';
import { TableViewColumn2 } from 'src/app/shared/generic-table2/models/table-structure/table-view-column.model';
import { TableMetaColumn } from 'src/app/shared/generic-table2/models/table-structure/table-meta-column.model';
import { ChartTypeEnum } from '../../shared/charts/chart-type.enum';
import { Helper } from '../../helper';
import { ToastNotificationType } from '../../../shared/toast-notification/toast-notification-type.enum';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { ChartInformation } from '../../models/chart-information';
import echarts from 'echarts';
import { BasicStructureModel } from '../../models/basic-structure.model';
import { GoogleInsightsMetadataService } from '../../../shared/services/google-insights-metadata.service';
import { BreakdownModel } from '../../../shared/models/breakdown.model';
import { select, Store } from '@ngrx/store';
import { LoadCachedAds, LoadCachedAdSets, LoadCachedCampaigns } from '../../../shared/state/shared.actions';
import { getGlobalDate, getInsightsFromAdAccount, SharedState } from '../../../shared/state/shared.reducer';
import { InsightsLevelEnum } from '../../../shared/services/general-settings/models/insights-level.enum';
import { FilterKeysEnum } from '../../shared/models/filter-keys.enum';

@Injectable({ providedIn: 'root' })
export class ReportingMetaInsightsService {
	constructor(
		private http: HttpClient,
		private toastNotificationService: ToastNotificationService,
		private googleInsightsService: GoogleInsightsMetadataService,
		private sharedStore: Store<SharedState>
	) {}

	public getInsights(
		adAccountId: string,
		sourceChannel: SourceChannel,
		reportLevel: InsightsCategoryTypeEnum,
		filterKeys: FilterKeysEnum[]
	): Observable<BasicStructureModel[]> {
		this.sharedStore.dispatch(
			new LoadCachedCampaigns({
				adAccountId: adAccountId,
				filterKeys: filterKeys,
				sourceChannel: sourceChannel,
				reportLevel: reportLevel
			})
		);
		return this.sharedStore.pipe(select(getInsightsFromAdAccount(sourceChannel, filterKeys, adAccountId, InsightsLevelEnum.Campaigns)));
	}

	public addCompletedStatus(filtersKeys: number[]): number[] {
		if (!filtersKeys) {
			return;
		}
		filtersKeys = filtersKeys.filter(filterKey => {
			return filterKey !== FilterKeysEnum.Completed;
		});
		if (filtersKeys.includes(FilterKeysEnum.Paused)) {
			filtersKeys.push(FilterKeysEnum.Completed);
		}

		return filtersKeys;
	}

	public getAggregator(operation: string | QueryBuilderAggregatorEnum): number {
		let result;
		if (operation === 'Average') {
			result = QueryBuilderAggregatorEnum.Avg;
		} else {
			result = QueryBuilderAggregatorEnum.Sum;
		}
		return result;
	}

	public populateChart(report: ReportModel, chartInstanceService: any, reportIndex: number): void {
		if (Helper.checkReport(report) && chartInstanceService) {
			if (report.type !== ChartTypeEnum.Table && report.type !== ChartTypeEnum.SingleNumber) {
				if (chartInstanceService.charts.get(reportIndex)?.instance) {
					chartInstanceService.charts.get(reportIndex).instance.showLoading('default', {
						text: 'Fetching data...',
						color: '#2585FE',
						textColor: '#282828',
						maskColor: 'rgba(255, 255, 255, 0.6)',
						zlevel: 0
					});
				}
			}
			// timeout is required since chart can be created before request finish
			setTimeout(() => {
				this.getChartData(report).subscribe((resp: any) => {
					if (resp.length > 1) {
						if (resp[1].length) {
							report.details.compareData = resp[1];
						} else {
							this.toastNotificationService.sendToast('There is no data in the second date interval', ToastNotificationType.Warning);
						}
					}
					this.nameReport(report);
					this.assignChartData(resp[0], report, chartInstanceService, reportIndex);
					this.updateChartInstance(reportIndex, report, chartInstanceService);
				});
			}, 1000);
		}
	}

	public nameReport(report: ReportModel): void {
		let reportName = '';
		report.details.metric.forEach(metric => {
			if (reportName.length) {
				reportName += `, ${metric.displayName}`;
			} else {
				reportName += metric.displayName;
			}
		});
		if (report.details.breakdown) {
			reportName += ` - ${report.details.breakdown.displayName}`;
		}

		report.name = report.name || reportName;
	}

	public assignChartData(chartData: any[], report: ReportModel, chartInstanceService: any, index: number): void {
		if (this.checkWidgetData(chartData, report)) {
			report.details.rawData = Object.assign([], chartData);
			if (report.details.breakdown) {
				chartData = this.convertChartDataWithBreakdowns(
					chartData,
					report.details.breakdown.columnName,
					report.details.dimension.primaryValue.name,
					report.details.metric[0]
				);
			}
			report.details.chartData = chartData;
		} else {
			report.details.chartData = [];
			this.toastNotificationService.sendToast('No data available', ToastNotificationType.Warning);
		}
		if (report.type !== ChartTypeEnum.Table && report.type !== ChartTypeEnum.SingleNumber) {
			const chartInstance = chartInstanceService.charts.get(index);

			if (chartInstance) {
				chartInstanceService.charts.get(index).instance.hideLoading();
			}
		}
	}

	public updateChartInstance(index: number, report: ReportModel, chartInstanceService: any): void {
		if (report.type === ChartTypeEnum.SingleNumber) {
			const updateOptions = chartInstanceService.createChart(report);
			chartInstanceService.charts.get(index).instance.setOption(updateOptions, true);
		}
	}

	private checkWidgetData(data: any, report: ReportModel) {
		let isValid;
		if (report.details.breakdown) {
			isValid =
				data &&
				data.length &&
				data[0].hasOwnProperty(report.details.breakdown.columnName) &&
				data[0].hasOwnProperty(report.details.metric[0].primaryValue.name) &&
				data[0].hasOwnProperty(report.details.dimension.primaryValue.name);
		} else {
			const metricName =
				data &&
				data[0] &&
				report &&
				report.details &&
				report.details.metric[0] &&
				report.details.metric[0].primaryValue &&
				report.details.metric[0].primaryValue.name
					? data[0].hasOwnProperty(report.details.metric[0].primaryValue.name)
					: null;
			const dimensionName =
				data &&
				data[0] &&
				report &&
				report.details &&
				report.details.dimension &&
				report.details.metric[0].primaryValue &&
				report.details.metric[0].primaryValue.name
					? data[0].hasOwnProperty(report.details.dimension.primaryValue.name)
					: null;

			isValid = data && data.length && metricName && dimensionName;
		}
		return isValid;
	}

	public getFacebookChartDataWithQueryBuilder(
		tableName: string,
		columns: TableViewColumn2[],
		dimensionName: string,
		dateStart: moment.Moment,
		dateEnd: moment.Moment,
		structureIds: string[] | number[],
		reportLevel: string,
		selectedAdAccount?: string,
		breakdown?: BreakdownModel,
		dimensionType?: TableViewColumnTemplateEnum
	): Observable<any> {
		const query: QueryModel = {
			TableName: tableName,
			Columns: [],
			Dimensions: []
		};

		// Add columns to query model
		query.Columns = [];
		for (const column of columns) {
			query.Columns.push({
				Name: column.primaryValue && column.primaryValue.name ? column.primaryValue.name : null,
				Aggregator: this.getAggregator(column.primaryValue && column.primaryValue.aggregationId ? column.primaryValue.aggregationId : null)
			});
		}

		// Add dimensions to query model
		query.Dimensions = [{ GroupColumnName: dimensionName }];

		// Create conditions to add to query model
		const conditionBlock: QueryBuilderConditionBlock = new QueryBuilderConditionBlock(QueryBuilderLogicalOperator.And, [
			{
				ColumnName: 'date_start',
				Operator: QueryBuilderWhereOperator.GreaterThanOrEqualWith,
				Value: dateStart.format('YYYY-MM-DD')
			},
			{
				ColumnName: 'date_stop',
				Operator: QueryBuilderWhereOperator.LessThanOrEqualWith,
				Value: dateEnd.format('YYYY-MM-DD')
			},
			{
				ColumnName: 'account_id',
				Operator: QueryBuilderWhereOperator.Equals,
				Value: selectedAdAccount
			},
			{
				ColumnName: this.getParameterFromWidgetLevel(reportLevel).slice(0, -1) + '.id',
				Operator: QueryBuilderWhereOperator.In,
				Value: structureIds
			}
		]);

		if (dimensionType === TableViewColumnTemplateEnum.Date) {
			conditionBlock.Conditions.push({
				ColumnName: 'time_increment',
				Operator: QueryBuilderWhereOperator.In,
				Value: 1
			});
		}

		if (breakdown) {
			query.Dimensions.push({ GroupColumnName: breakdown.columnName });
		}

		// Add where block to query model
		query.Where = new QueryBuilderConditionBlock(QueryBuilderLogicalOperator.Or, null, [conditionBlock]);
		// TODO: CHANGE ONCE QUERY BUILDER IS UPDATED TO SUPPORT AS FOR BOTH COLS AND DIMENSIONS

		const newQuery: ReportsQueryModel = {
			level: reportLevel.toLowerCase(),
			columns: [],
			breakdowns: [],
			conditions: [],
			timeRange: <ReportsQueryTimeRangeModel>{}
		};
		for (const column of columns) {
			newQuery.columns.push(column.primaryValue && column.primaryValue.name ? column.primaryValue.name : null);
		}
		newQuery.breakdowns = [dimensionName];
		if (breakdown) {
			newQuery.breakdowns.push(breakdown.columnName);
		}
		newQuery.timeRange.since = dateStart.format('YYYY-MM-DD');
		newQuery.timeRange.until = dateEnd.format('YYYY-MM-DD');
		newQuery.conditions = [
			{
				ColumnName: 'account_id',
				Operator: QueryBuilderWhereOperator.Equals,
				Value: selectedAdAccount
			},
			{
				ColumnName: this.getParameterFromWidgetLevel(reportLevel).slice(0, -1) + '.id',
				Operator: QueryBuilderWhereOperator.In,
				Value: structureIds
			}
		];
		return new QueryBuilderService(this.http, BaseApiUrl.FacebookDataPipeline + 'reports/reports', SourceChannel.Facebook).postQuery(newQuery);
	}

	public getGoogleChartDataWithQueryBuilder(
		reportType: string,
		reportLevel: InsightsCategoryTypeEnum,
		columns: TableViewColumn2[],
		dimensionName: string,
		dateStart: moment.Moment,
		dateEnd: moment.Moment,
		structureIds: number[] | string[],
		breakdown?: BreakdownModel
	): Observable<any> {
		const metaColumnDictionary = new Map<string, TableMetaColumn>();
		for (const column of columns) {
			metaColumnDictionary.set(column.primaryValue.name, column.primaryValue);
		}
		const metaColumns = Array.from(metaColumnDictionary.values());

		let idColumnName = '';
		let tableName = '';
		switch (reportLevel) {
			case InsightsCategoryTypeEnum.Campaign: {
				idColumnName = 'CampaignGoogleId';
				tableName = 'CampaignPerformances';
				break;
			}
			case InsightsCategoryTypeEnum.AdSet: {
				idColumnName = 'AdGroupGoogleId';
				tableName = 'AdGroupPerformances';
				break;
			}
			case InsightsCategoryTypeEnum.Ad: {
				idColumnName = 'AdGoogleId';
				tableName = 'AdPerformances';
				break;
			}
			default: {
				break;
			}
		}

		if (reportType === 'GenderPerformances') {
			tableName = 'GenderPerformances';
		} else if (reportType === 'GeoPerformances') {
			tableName = 'GeoPerformances';
		}

		const query = new QueryBuilderService(this.http, BaseApiUrl.GoogleDataPipeline + 'reports', SourceChannel.Google)
			.setTableName(tableName)
			.addDimensions({ GroupColumnName: dimensionName })
			.addColumnsList(metaColumns)
			.addConditionBlock(
				new QueryBuilderConditionBlock(QueryBuilderLogicalOperator.Or).addConditions(
					new QueryBuilderCondition('Date', QueryBuilderWhereOperator.GreaterThanOrEqualWith, dateStart.format('YYYY-MM-DD')),
					new QueryBuilderCondition('Date', QueryBuilderWhereOperator.LessThanOrEqualWith, dateEnd.format('YYYY-MM-DD')),
					new QueryBuilderCondition(idColumnName, QueryBuilderWhereOperator.In, structureIds)
				)
			);

		if (breakdown) {
			query.addDimensions({ GroupColumnName: breakdown.columnName });
		}

		return query.post().result;
	}

	public loadAllStructures(payload: any, campaignIds: string[] = [], adSetIds: string[] = [], adIds: string[] = []): void {
		this.sharedStore.dispatch(
			new LoadCachedAdSets({
				...payload,
				reportLevel: InsightsCategoryTypeEnum.AdSet,
				campaignIds: campaignIds,
				adSetIds: adSetIds
			})
		);
		this.sharedStore.dispatch(
			new LoadCachedAds({
				...payload,
				reportLevel: InsightsCategoryTypeEnum.Ad,
				adSetIds: adSetIds,
				adIds: adIds
			})
		);

		this.sharedStore.dispatch(new LoadCachedCampaigns(payload));
	}

	public getParameterFromWidgetLevel(reportLevel: string): string {
		let level;
		switch (reportLevel) {
			case InsightsCategoryTypeEnum.Campaign: {
				level = 'campaigns';
				break;
			}
			case InsightsCategoryTypeEnum.AdSet: {
				level = 'adsets';
				break;
			}
			case InsightsCategoryTypeEnum.Ad: {
				level = 'ads';
				break;
			}
			default: {
				break;
			}
		}
		return level;
	}

	public mapInsights(resp: BasicStructureModel[]): BasicStructureModel[] {
		let insights: BasicStructureModel[];

		if (!resp?.length) {
			return;
		}

		insights = resp.map((item: any) => ({
			id: item.currentKey,
			displayName: item.displayName + ' - ' + item.currentKey,
			currentKey: item.currentKey
		}));

		return insights;
	}

	public convertChartDataWithBreakdowns(chartData: any[], breakdownColumn: string, dimension: string, metric: TableViewColumn2): any[] {
		return chartData.reduce((accumulator, item) => {
			let processedItem = accumulator.find((x: any) => x[dimension] === item[dimension]);
			const objectExists = !!processedItem;
			if (!objectExists) {
				processedItem = {} as any;
				processedItem[dimension] = item[dimension];
			}
			processedItem[item[breakdownColumn]] = item[metric.primaryValue.name];
			if (!objectExists) {
				accumulator.push(processedItem);
			}
			return accumulator;
		}, []);
	}

	public getChartData(report: ReportModel): Observable<any> {
		const calls = [];
		let structureIds;
		structureIds = report.details.insights.map(insight => {
			return insight.currentKey;
		});
		if (report.details.dataSource === SourceChannel.Facebook) {
			calls.push(
				this.getFacebookChartDataWithQueryBuilder(
					`v${report.details.reportLevel}Insights`,
					report.details.metric,
					report.details.dimension.primaryValue.name,
					moment(report.details.dateFrom),
					moment(report.details.dateTo),
					structureIds,
					report.details.reportLevel,
					report.details.adAccount.id,
					report.details.breakdown,
					report.details.dimension.typeId
				)
			);

			if (
				(report.type === ChartTypeEnum.Line || report.type === ChartTypeEnum.Area) &&
				report.details.compareDate &&
				report.details.compareDate.from &&
				report.details.compareDate.to
			) {
				calls.push(
					this.getFacebookChartDataWithQueryBuilder(
						`v${report.details.reportLevel}Insights`,
						report.details.metric,
						report.details.dimension.primaryValue.name,
						moment(report.details.compareDate.from),
						moment(report.details.compareDate.to),
						structureIds,
						report.details.reportLevel,
						report.details.adAccount.id,
						report.details.breakdown,
						report.details.dimension.typeId
					)
				);
			}
		} else {
			const dimensions = [report.details.dimension.primaryValue.name];
			if (report.details.breakdown) {
				dimensions.push(report.details.breakdown.columnName);
			}
			calls.push(
				this.googleInsightsService.postGoogleInsightsQuery(
					report.details.reportType,
					moment(report.details.dateFrom),
					moment(report.details.dateTo),
					report.details.metric.map(item => item.primaryValue.name),
					dimensions,
					report.details.reportLevel as any,
					report.details.adAccount.id,
					report.details.insights.map(item => item.id.toString())
				)
			);
			if (
				(report.type === ChartTypeEnum.Line || report.type === ChartTypeEnum.Area) &&
				report.details.compareDate &&
				report.details.compareDate.from &&
				report.details.compareDate.to
			) {
				calls.push(
					this.getGoogleChartDataWithQueryBuilder(
						report.details.reportType,
						report.details.reportLevel,
						report.details.metric,
						report.details.dimension.primaryValue.name,
						moment(report.details.compareDate.from),
						moment(report.details.compareDate.to),
						structureIds,
						report.details.breakdown
					)
				);
			}
		}

		return forkJoin(calls);
	}

	public populateSingleNumberChart(report: ReportModel, chartInstanceService: any, ownWidgetIndex: number): void {
		if (Helper.checkReport(report) && chartInstanceService) {
			this.sharedStore.pipe(select(getGlobalDate), take(1)).subscribe(resp => {
				report.details.dateFrom = new Date(resp.date.startDate.format());
				report.details.dateTo = new Date(resp.date.endDate.format());
				this.getSingleNumberChartData(report)
					.pipe(take(1))
					.subscribe(([resp1, resp2]) => {
						if (resp1 && resp1[0] && resp2 && resp2[0]) {
							report.details.chartData = {
								firstData: resp1,
								secondData: resp2
							};
						} else {
							report.details.chartData = null;
							this.toastNotificationService.sendToast('There is no data in the second date interval', ToastNotificationType.Warning);
						}
						this.nameReport(report);
						this.updateChartInstance(ownWidgetIndex, report, chartInstanceService);
					});
			});
		}
	}

	private getSingleNumberChartData(report: ReportModel): Observable<any> {
		const calls = [];
		const structureIds = report.details.insights.map(insight => {
			return insight.currentKey;
		});

		const dateTo = moment(new Date(report.details.dateTo));
		const dateFrom = moment(new Date(report.details.dateFrom));

		const days = moment.duration(dateTo.diff(dateFrom)).asDays();

		const dateFromPast = moment(new Date(report.details.dateFrom)).subtract(days, 'days');
		const dateToPast = moment(new Date(report.details.dateTo)).subtract(days, 'days');

		calls.push(this.singleNumberChartRequest(report, dateTo, dateFrom, structureIds));
		calls.push(this.singleNumberChartRequest(report, dateToPast, dateFromPast, structureIds));

		return forkJoin(calls);
	}

	private singleNumberChartRequest(report: ReportModel, dateTo: moment.Moment, dateFrom: moment.Moment, structureIds: string[] | number[]): Observable<any> {
		if (report.details.dataSource === SourceChannel.Google) {
			return this.googleInsightsService.postGoogleInsightsQuery(
				report.details.reportType,
				dateFrom,
				dateTo,
				report.details.metric.map(item => item.primaryValue.name),
				[`${this.getParameterFromWidgetLevel(report.details.reportLevel).slice(0, -1)}_id`],
				report.details.reportLevel as any,
				report.details.adAccount.id,
				report.details.insights.map(item => item.id.toString())
			);
		} else if (report.details.dataSource === SourceChannel.Facebook) {
			return this.getFacebookChartDataWithQueryBuilder(
				`v${report.details.reportLevel}Insights`,
				report.details.metric,
				`${this.getParameterFromWidgetLevel(report.details.reportLevel).slice(0, -1)}_id`,
				dateFrom,
				dateTo,
				structureIds,
				report.details.reportLevel,
				report.details.adAccount.id,
				report.details.breakdown,
				null
			);
		}
	}
}
