import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { PageService } from '../../_services/facebook-accounts/page.service';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getCampaign, isCampaignUpdating } from '../state/smart-create.reducer';
import { SharedState } from '../../shared/state/shared.reducer';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, skipWhile, take, tap, withLatestFrom } from 'rxjs/operators';
import { AdAccountResolver } from '../../shared/resolvers/ad-account.resolver';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { LoadCampaignTemplate } from '../state/smart-create.actions';
import { FiledLibMapperService } from 'src/app/shared/services/filed-lib-Mapper.service';
import { LoadCampaignCatalogs, LoadFacebookPages } from '../../state/campaign-common-state/campaign-common-actions';
import { getFacebookPages } from '../../state/campaign-common-state/campaign-common-reducer';

@Injectable()
export class AdSetSettingsResolver implements Resolve<Observable<any>> {
	constructor(
		private router: Router,
		private pagesService: PageService,
		private mapperService: FiledLibMapperService,
		private campaignStore: Store<CampaignSmartCreateState>,
		private sharedStore: Store<SharedState>,
		private accountResolver: AdAccountResolver
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.campaignStore.dispatch(new LoadCampaignCatalogs());
		const adAccount$ = this.accountResolver.resolve(route, state);
		this.campaignStore.dispatch(new LoadFacebookPages());
		const campaignId = Number(route.params.campaignId);
		this.campaignStore.dispatch(new LoadCampaignTemplate(campaignId));

		const campaign$ = this.campaignStore.pipe(
			withLatestFrom(this.campaignStore.pipe(select(isCampaignUpdating))),
			skipWhile(([action, isUpdating]) => isUpdating),
			map(([action, isUpdating]) => action),
			select(getCampaign),
			skipWhile(campaign => !campaign || campaign.id !== campaignId),
			take(1)
		);

		const pages$ = this.campaignStore.pipe(
			select(getFacebookPages),
			skipWhile(pages => !pages),
			take(1)
		);

		return forkJoin([adAccount$, campaign$, pages$]).pipe(
			take(1),
			tap(([adAccount, campaign]) => {
				if (!campaign || !campaign.stepOneDetailsAsJson) {
					this.router.navigate(['/campaign/welcome']);
					return EMPTY;
				}
				this.sharedStore.dispatch(new HideGlobalSpinner());
			}),
			map(([adAccount, campaign, facebookPages]) => {
				return { adAccount, campaign, facebookPages };
			})
		);
	}
}
