import { themesModel } from './themes.model';

export class StyleModel {
	constructor(
		public title: string = '',
		public subtitle: string = '',
		public isBrandGuardian: boolean = false,
		public theme: string = 'default',
		public color: string = '',
		public hasGradient: boolean = false
	) {
		this.color = themesModel.default.color[Math.floor(Math.random() * themesModel.default.color.length)];
	}
}
