import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { IQuickNavigation } from '@filed-com/filed-lib/lib/cards/models/quick-navigation.model';
import { CampaignStepsEnum } from '../../models/campaign-steps.enum';
import { CatalogNodeInterface } from '../../catalogs/catalog-node.interface';
import { OptimizationPanelsEnum } from '../optimization/optimization-panels.enum';
import {
	ClearCurrentStep,
	CreateOptimizationDraftSuccess,
	PublishCampaign,
	UpdateBudgetAllocation,
	UpdateCampaignCurrentStep,
	UpdateCampaignTemplate,
	UpdateCurrency,
	UpdateSplitByAgeRange,
	UpdateSplitByGender,
	UpdateSplitByInterests,
	UpdateSplitByLocation,
	UpdateSplitByPlacements
} from '../../state/smart-create.actions';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { SubObjectives } from '../../models/sub-objectives';
import { BudgetLevel } from '../../components-pieces/optimization-budget-card/budget-level.model';
import { ParentTargetingTypeEnum } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/targeting-type.enum';
import { Gender } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/gender';
import { ExpansionPanelHelper } from '../../../shared/expansion-panel/expansion-panel-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { getCurrency, getGenders, getStepFiveDetails } from '../../state/smart-create.reducer';
import { SharedState } from '../../../shared/state/shared.reducer';
import { CampaignSmartCreateBudgetSplitsService } from '../../services/campaign-smart-create-budget-splits.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { AdAccountApiService } from '../../../_services/facebook-accounts/ad-account-api.service';
import { QuickNavigationService } from '@filed-com/filed-lib';
import { OptimizeService } from '../../../optimise/services/optimize.service';
import { AccountTypeService } from '../../../shared/account-type.service';
import { FacebookInterestsView } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests-view';
import { FacebookLocations } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations';
import { BudgetAllocationInterface } from '../../models/budget-allocation.interface';
import { AgeRange } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/age-range';
import { AgeSplitInterface } from '../../models/age-split.interface';
import { AllocationPopUpBudgetInterface } from '../../components-pieces/budget-allocation-popup/allocation-pop-up-budget.interface';
import { HierarchyDatum } from '../optimization/tree-ui/tree-ui.interface';
import { CampaignTemplate } from '../../models/campaign-template';
import { StepFiveDetails } from '../../models/step-five-details';
import { getCurrencySymbol } from '@angular/common';
import { OptimizationSettingsInterface } from '../../models/ab-test-interface';
import { HideGlobalSpinner, ShowGlobalSpinner, UpdatePublishStatus } from '../../../shared/state/shared.actions';
import { FacebookInterests } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';
import { ToastNotificationType } from '../../../shared/toast-notification/toast-notification-type.enum';
import { ABTestingEditPopup } from '../../components-pieces/ab-testing-edit-popup/ab-testing-edit-popup.component';
import { ColDef } from 'ag-grid-community';
import { PublishStatusInterface } from '../../../shared/state/state-entities/publish/publish-status.interface';

@Component({
	selector: 'app-ab-testing',
	templateUrl: './ab-testing.component.html',
	styleUrls: ['./ab-testing.component.scss']
})
export class AbTestingComponent implements OnInit, OnDestroy {
	public navigations: IQuickNavigation[] = [];
	public readonly step = CampaignStepsEnum.Optimize;
	public objectives: CatalogNodeInterface[];
	public optimizationPanelsEnum = OptimizationPanelsEnum;
	public objectiveName: string;
	public campaignLastStepId: CampaignStepsEnum;
	public toggleFormGroup: FormGroup;

	public isSplitByLocation = false;
	public isSplitByDevices = false;
	public isSplitByPlacements = false;
	public isSplitByInterests: Array<Array<FacebookInterests>> | boolean = false;
	public isSplitByGender = false;
	public isSplitByAgeRange: number | boolean;
	public isBudgetSplitEvenly: boolean;
	public isPublishing: boolean;

	public currency = '$';
	private campaignId: number;
	private unsubscriber$ = new Subject<void>();
	public selectedIndex: number;
	public campaignName: string;
	public adSetName: string;
	public label: string;
	public selectedInterests: FacebookInterestsView[] = [];
	public selectedLocations: FacebookLocations[] = [];
	public maximumAgeRange: number;
	public budgeAllocationSplits: BudgetAllocationInterface;
	public campaignControl: FormArray;
	public adSetsControl: FormArray;
	public ageRange: AgeRange;
	public genders: number[];
	public splitsByAge: AgeSplitInterface[];
	public numberOfSplitsByAge: number;
	public popUpData: AllocationPopUpBudgetInterface;
	public minBudget: number;

	public specialAdCategorySelected: boolean;
	public selectedMultipleGenders: boolean;
	public selectedMultipleLocations: boolean;
	public isOnCampaignLevelBudget: number | boolean;
	public isOnAdSetLevelBudget: number | boolean;
	public isProductCatalogue: boolean;
	public isRetargetingType: boolean;
	public isAppInstall: boolean;
	public treeData: HierarchyDatum;
	public campaignDetails: CampaignTemplate;
	public draftStructureId: string;
	public optimizationDrafts: StepFiveDetails;
	public genderEnum = Gender;
	public canSplitInterests = false;
	public canSplitGenders = false;
	public canSplitAgeRange = false;
	public canSplitPlacements = false;
	public columnDefs: ColDef[] = [
		{ field: 'name', editable: true },
		{ field: 'interest' },
		{ field: 'gender' },
		{ field: 'ageRange' },
		{ field: 'placement' },
		{ field: 'budget', editable: true },
		{ field: 'isLive', filter: 'agNumberColumnFilter' }
	];
	/*public columnDefs: ColDef[] = [
		{ headerName: 'Ad Set Name', field: 'name' },
		{ headerName: 'Interests', field: 'interest' },
		{ headerName: 'Genders', field: 'gender' },
		{ headerName: 'Age Range', field: 'ageRange' },
		{ headerName: 'Placements', field: 'placement' },
		{ headerName: 'Budget', field: 'budget' },
		{ headerName: 'isLive', field: 'isLive' }
	];*/
	public optimizationSettingsTableData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
	public optimizationSettingsTableData$ = this.optimizationSettingsTableData.asObservable();
	public rowData = [
		[
			{
				gender: 'Women',
				ageRange: '18 - 34',
				interest: '-',
				name: 'sdds - Men - 18 - 34',
				placement: '-',
				budget: 333.33,
				isLive: true
			},
			{
				gender: 'Women',
				ageRange: '34 - 50',
				interest: '-',
				name: 'sdds - Men - 34 - 50',
				placement: '-',
				budget: 333.33,
				isLive: true
			},
			{
				gender: 'Women',
				ageRange: '50 - 55',
				interest: '-',
				name: 'sdds - Men - 50 - 55',
				placement: '-',
				budget: 333.33,
				isLive: true
			},
			{
				gender: 'Men',
				ageRange: '18 - 34',
				interest: '-',
				name: 'sdds - Women - 18 - 34',
				placement: '-',
				budget: 333.33,
				isLive: true
			},
			{
				gender: 'Men',
				ageRange: '34 - 50',
				interest: '-',
				name: 'sdds - Women - 34 - 50',
				placement: '-',
				budget: 333.33,
				isLive: true
			},
			{
				gender: 'Men',
				ageRange: '50 - 55',
				interest: '-',
				name: 'sdds - Women - 50 - 55',
				placement: '-',
				budget: 333.33,
				isLive: true
			}
		]
	];
	public defaultColDef = {
		// set every column width
		width: 100,
		// make every column editable
		editable: true,
		// make every column use 'text' filter by default
		filter: 'agTextColumnFilter'
	};

	constructor(
		private store: Store,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private campaignSmartCreateService: CampaignSmartCreateService,
		private injector: Injector,
		public expansionPanelHelper: ExpansionPanelHelper<OptimizationPanelsEnum>,
		public dialog: MatDialog,
		private sharedStore: Store<SharedState>,
		private splitsService: CampaignSmartCreateBudgetSplitsService,
		private toastService: ToastNotificationService,
		private accountService: AdAccountApiService,
		private smartCreateService: CampaignSmartCreateService,
		private quickNavigationService: QuickNavigationService,
		private optimizeService: OptimizeService,
		private accountTypeService: AccountTypeService
	) {}

	public ngOnInit(): void {
		this.initFormGroup();
		this.initiateAgGridSettings();
		this.routerData();
		this.getCurrency();
		this.optimizationDraftListener();
	}
	private initiateAgGridSettings(): void {}

	public routerData(): void {
		this.activeRoute.data.subscribe((data: Data) => {
			this.campaignDetails = data.store.campaign;
			this.campaignControl = new FormArray([]);
			this.adSetsControl = new FormArray([]);
			this.store.dispatch(new UpdateCampaignCurrentStep(this.step));
			this.objectives = data.store.catalogs.Objective;
			const selectedObjective = data?.store?.campaign?.stepOneDetailsAsJson?.objective;
			this.isProductCatalogue = selectedObjective === SubObjectives.ProductCatalogSales;
			if (data.store.campaign.stepOneDetailsAsJson.campaignBudgetOptimization?.amount > 0) {
				this.isOnCampaignLevelBudget = data.store.campaign.stepOneDetailsAsJson.campaignBudgetOptimization.amount;
				this.minBudget = data.store.campaign.stepOneDetailsAsJson.campaignBudgetOptimization.minimumBudget;
				this.label = BudgetLevel.Campaigns;
				this.popUpData = {
					initial: this.isOnCampaignLevelBudget as number,
					current: this.isOnCampaignLevelBudget as number,
					currency: this.currency,
					level: 'Campaign',
					step: 'Step 1'
				};
			} else {
				this.isOnCampaignLevelBudget = false;
			}
			const targeting = data.store.campaign?.stepTwoDetailsAsJson?.targeting;
			if (targeting?.targeting_type === ParentTargetingTypeEnum.ReTargeting) {
				this.isRetargetingType = true;
			}
			if (targeting?.targeting_type === ParentTargetingTypeEnum.AutomatedTargeting) {
				this.isAppInstall = true;
			}
			if (data.store.campaign.stepTwoDetailsAsJson?.budgetOptimization?.amount > 0) {
				this.isOnAdSetLevelBudget = data.store.campaign.stepTwoDetailsAsJson.budgetOptimization.amount;
				this.minBudget = data.store.campaign.stepTwoDetailsAsJson.budgetOptimization.minimumBudget;
				this.popUpData = {
					initial: this.isOnAdSetLevelBudget as number,
					current: this.isOnAdSetLevelBudget as number,
					currency: this.currency,
					level: 'Ad Set',
					step: 'Step 2'
				};
				this.label = BudgetLevel.AdSets;
			} else {
				this.isOnAdSetLevelBudget = false;
			}
			if (data.store.campaign.stepFourDetailsAsJson?.isBudgetSplitEvenly) {
				this.isBudgetSplitEvenly = data.store?.campaign?.stepFourDetailsAsJson?.isBudgetSplitEvenly;
			}
			this.currency = data.store?.campaign?.currency;
			this.ageRange = data.store.campaign?.stepTwoDetailsAsJson?.targeting?.ageRange;
			this.adSetName = data.store.campaign?.stepTwoDetailsAsJson?.adSetName;
			this.objectiveName = data.store.campaign.stepOneDetailsAsJson.objectiveGroupTree?.subObjectiveDisplayName;
			this.specialAdCategorySelected = !!data.store.campaign?.stepOneDetailsAsJson?.specialAdCategory;
			this.campaignName = data.store.campaign?.name;
			this.campaignId = data.store.campaign?.id;
			this.draftStructureId = data.store.campaign?.stepFiveDetailsAsJson?.draftStructureId;
			this.campaignLastStepId = data.store.campaign?.lastStepId;
			this.checkDynamicComponents(data.store.campaign);
			this.checkFields(data.store.campaign);
			this.splitRequest();
		});
	}

	public initAdSetListeners(): void {
		this.adSetsControl.controls.forEach(control => {
			control.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(amount => {
				const controlIndex = this.adSetsControl.controls.indexOf(control);
				let index = -1;
				this.budgeAllocationSplits.adSetsBudget.forEach(campaign => {
					campaign.adSets.forEach(adSet => {
						index++;
						if (index === controlIndex) {
							adSet.budget = parseFloat(amount);
						}
					});
				});
				this.popUpData.current = 0;
				this.budgeAllocationSplits.adSetsBudget.forEach(campaign => {
					campaign.adSets.forEach(adSet => {
						this.popUpData.current += adSet.budget;
					});
				});
				this.store.dispatch(new UpdateBudgetAllocation(this.budgeAllocationSplits));
			});
		});
	}
	public initFormGroup(): void {
		this.toggleFormGroup = this.formBuilder.group({
			location: new FormControl(this.isSplitByLocation),
			placement: new FormControl(this.isSplitByDevices),
			gender: new FormControl(this.isSplitByGender),
			ageRange: new FormControl(this.isSplitByAgeRange),
			interest: new FormControl(false)
		});
		this.toggleFormGroup.get('interest').valueChanges.subscribe(res => {
			if (res && typeof this.isSplitByInterests !== 'boolean') {
				this.makeSplits({ interest: this.selectedInterests?.length / this.isSplitByInterests?.length });
			} else if (!res) {
				// this.mergeSplits('interest');
			}
		});
		this.toggleFormGroup.get('gender').valueChanges.subscribe(res => {
			if (res) {
				this.makeSplits({ gender: true });
			} else {
				// this.mergeSplits('gender');
			}
		});
		this.toggleFormGroup.get('ageRange').valueChanges.subscribe(res => {
			if (res && typeof this.isSplitByAgeRange !== 'boolean') {
				this.makeSplits({ age: this.isSplitByAgeRange });
			} else if (!res) {
				// this.mergeSplits('age');
			}
		});
		this.toggleFormGroup.get('placement').valueChanges.subscribe(res => {
			if (res) {
				this.makeSplits({ placement: true });
			} else {
				// this.mergeSplits('placement');
			}
		});
	}
	private updateFormGroup(): void {
		this.toggleFormGroup.patchValue(
			{
				location: this.isSplitHistoryContain('location'),
				placement: this.isSplitHistoryContain('placement'),
				gender: this.isSplitHistoryContain('gender'),
				ageRange: this.isSplitHistoryContain('age'),
				interest: this.isSplitHistoryContain('interest')
			},
			{ onlySelf: true, emitEvent: false }
		);
	}
	public onNavClick(step: CampaignStepsEnum): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate([`campaign/build-campaign/${step}/${this.campaignId}`], {
			queryParams: {
				lastUserStep: this.step
			}
		});
	}

	// public onPublish(): void {
	// 	this.smartCreateService
	// 		.checkPublishStatus()
	// 		.pipe(
	// 			take(3),
	// 			catchError(() => {
	// 				this.sharedStore.dispatch(new HideGlobalSpinner());
	// 				this.toastService.sendCustomToast(
	// 					'Something went wrong, please try again later!',
	// 					ToastNotificationType.Warning,
	// 					4000,
	// 					'Error ! Could not Publish!'
	// 				);
	// 				return EMPTY;
	// 			})
	// 		)
	// 		.subscribe(response => {
	// 			if (!response) {
	// 				this.onPublishConfirmed();
	// 			} else {
	// 				this.router.navigate([`/campaign/welcome`]);
	// 				this.toastService.sendCustomToast(
	// 					'Publishing for this campaign has already started and is still in progress.',
	// 					ToastNotificationType.Warning,
	// 					5000
	// 				);
	// 			}
	// 		});
	// }
	public onPublishConfirmed(): void {
		this.store.dispatch(new PublishCampaign());
		const status: PublishStatusInterface = { isActive: true };
		this.sharedStore.dispatch(new UpdatePublishStatus(status));

		// APPLY DEXTER RECOMMENDATION IF CASE
		if (this.accountTypeService.dexterAction$.value) {
			this.optimizeService.applyRecommendationAction(this.accountTypeService.dexterAction$?.value?.recommendationId);
		}

		setTimeout(() => {
			this.toastService.sendCustomToast(
				'This may take some time. Check the progress above.',
				ToastNotificationType.Info,
				300,
				'We have started Publishing!'
			);
			this.router.navigate([`/campaign/build-campaign/6/${this.campaignId}`]);
		}, 3000);
	}
	public onCancelClick(): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate(['/campaign/select-campaign']);
	}

	public edit(optimization: OptimizationSettingsInterface): void {
		this.dialog
			.open<ABTestingEditPopup>(ABTestingEditPopup, {
				data: { optimization, currency: this.currency },
				width: '850px'
			})
			.afterClosed()
			.subscribe(response => {
				if (response) {
					this.updateOptimizationSetting(response);
				}
			});
	}
	public updateOptimizationSetting(optimization: OptimizationSettingsInterface): void {
		if (!optimization) {
			return;
		}
		this.store.dispatch(new ShowGlobalSpinner());
		const optimizationSetting: { filedAdsetId: string; fields: any } = { filedAdsetId: '', fields: '' };
		const publishData = [];
		if (optimization.hasOwnProperty('filedAdsetId')) {
			optimizationSetting.filedAdsetId = optimization?.filedAdsetId;
			optimizationSetting.fields = {
				name: optimization?.name,
				isLive: optimization?.isLive,
				budgetAmount: optimization?.customBudgetAmount ?? optimization?.defaultBudgetAmount,
				gender: optimization?.targeting?.gender,
				placements: optimization?.placements,
				interests: optimization?.targeting?.interests,
				ageRange: optimization?.targeting?.ageRange
			};
		}
		publishData.push(optimizationSetting);
		// this.smartCreateService.updateOptimizationSetting({ optimizationSettings: publishData }, this.optimizationDrafts.draftStructureId).subscribe(
		// 	res => {
		// 		if (res) {
		// 			this.store.dispatch(new CreateOptimizationDraftSuccess(res));
		// 			this.store.dispatch(new HideGlobalSpinner());
		// 		}
		// 	},
		// 	error => {
		// 		this.store.dispatch(new HideGlobalSpinner());
		// 		this.toastService.sendCustomToast(error.error.message, ToastNotificationType.Warning, 8000);
		// 	}
		// );
	}
	public onBackClick(): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate([`campaign/build-campaign/4/${this.campaignId}`], {
			queryParams: {
				lastUserStep: this.step
			}
		});
	}
	public getCurrency(): void {
		this.store.pipe(select(getCurrency), take(2)).subscribe(AccCurrency => {
			if (!AccCurrency) {
				this.accountService.adAccountChanged$.pipe(takeUntil(this.unsubscriber$)).subscribe(resp => {
					this.currency = getCurrencySymbol(resp.currency, 'narrow');
					this.store.dispatch(new UpdateCurrency(this.currency));
				});
			} else {
				this.currency = AccCurrency;
			}
		});
	}
	// private loadStepDetails(): void {
	// 	this.campaignSmartCreateService.getOptimizationDraftById(this.draftStructureId).subscribe(
	// 		res => {
	// 			this.store.dispatch(new CreateOptimizationDraftSuccess(res));
	// 			this.splitRequest();
	// 		},
	// 		error => {
	// 			this.toastService.sendErrorToast(error.error.message);
	// 			this.store.dispatch(new HideGlobalSpinner());
	// 		}
	// 	);
	// }
	public optimizationDraftListener(): void {
		this.store.select(getStepFiveDetails).subscribe(res => {
			this.optimizationDrafts = res;
			this.updateFormGroup();
			this.createTableData();
		});
	}

	public createTableData(): void {
		const optimizationDrafts: OptimizationSettingsInterface[] = this.optimizationDrafts?.optimizationSettings;
		const optimizationSettings = [];
		if (optimizationDrafts && optimizationDrafts.length > 0) {
			optimizationDrafts.forEach(optimizationDraft => {
				optimizationSettings.push({
					gender: optimizationDraft.targeting?.gender ? this.genderEnum[optimizationDraft?.targeting?.gender] : '-',
					ageRange: optimizationDraft.targeting?.ageRange
						? optimizationDraft?.targeting?.ageRange.minAge + ' - ' + optimizationDraft?.targeting?.ageRange?.maxAge
						: '-',
					interest: '-',
					name: optimizationDraft.name,
					placement: optimizationDraft.placements[0]?.displayNameAutogen ?? '-',
					budget: optimizationDraft?.customBudgetAmount ? optimizationDraft?.customBudgetAmount : optimizationDraft?.defaultBudgetAmount,
					isLive: optimizationDraft.isLive
				});
			});
		}
		this.optimizationSettingsTableData.next(optimizationSettings);
	}
	public onGridReady(event: any): void {
		event.api.setColumnDefs(this.columnDefs);
		event.api.setRowData(this.rowData);
	}
	public initCampaignListeners(): void {
		this.campaignControl.controls.forEach(control => {
			control.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(amount => {
				if (amount) {
					const index = this.campaignControl.controls.indexOf(control);
					this.budgeAllocationSplits.campaignsBudget[index].budget = parseFloat(amount);
					this.store.dispatch(new UpdateBudgetAllocation(this.budgeAllocationSplits));
					this.popUpData.current = 0;
					this.budgeAllocationSplits.campaignsBudget.forEach(campaign => {
						this.popUpData.current += campaign.budget;
					});
				}
			});
		});
	}

	public checkDynamicComponents(campaign: CampaignTemplate): void {
		if (campaign.stepTwoDetailsAsJson?.targeting?.locations?.length > 1) {
			this.selectedLocations = campaign.stepTwoDetailsAsJson?.targeting?.locations;
			this.selectedMultipleLocations = true;
		} else {
			this.selectedMultipleLocations = false;
			this.store.dispatch(new UpdateSplitByLocation(false));
		}
		this.selectedInterests = campaign.stepTwoDetailsAsJson?.targeting?.interests;
		if (campaign.stepTwoDetailsAsJson?.targeting?.interests?.length > 1 && Array.isArray(this.isSplitByInterests) && this.isSplitByInterests?.length > 1) {
			this.canSplitInterests = true;
		} else {
			this.canSplitInterests = false;
			this.store.dispatch(new UpdateSplitByInterests(false));
		}

		if (campaign.stepFourDetailsAsJson?.isSplitByDevices) {
			this.isSplitByDevices = campaign.stepFourDetailsAsJson?.isSplitByDevices;
		} else {
			this.isSplitByDevices = false;
		}
	}

	public checkGenders(): void {
		this.store.pipe(select(getGenders), take(1)).subscribe(genders => {
			this.selectedMultipleGenders = genders === Gender.All;
		});
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	public checkFields(data: CampaignTemplate): void {
		if (data.stepFourDetailsAsJson?.isSplitByPlacements) {
			this.isSplitByPlacements = data.stepFourDetailsAsJson?.isSplitByPlacements;
		}
		if (!this.isSplitByPlacements) {
			this.store.dispatch(new UpdateSplitByPlacements(false));
		} else {
			this.store.dispatch(new UpdateSplitByPlacements(true));
		}
		this.isSplitByGender = !!data.stepFourDetailsAsJson?.isSplitByGenderSelected;
		if (!this.isSplitByGender || this.specialAdCategorySelected) {
			this.store.dispatch(new UpdateSplitByGender(false));
		} else {
			this.store.dispatch(new UpdateSplitByGender(true));
		}
		this.isSplitByInterests = data.stepFourDetailsAsJson?.isSplitByInterests;
		this.selectedInterests = data.stepTwoDetailsAsJson.targeting?.interests;
		this.canSplitInterests = this.isSplitByInterests && typeof this.isSplitByInterests !== 'boolean';
		this.isSplitByAgeRange = data.stepFourDetailsAsJson?.isSplitAgeRangeSelected !== undefined ? data.stepFourDetailsAsJson.isSplitAgeRangeSelected : false;
		this.maximumAgeRange = data.stepTwoDetailsAsJson.targeting?.ageRange?.maxAge - data.stepTwoDetailsAsJson.targeting?.ageRange?.minAge;
		if (!this.isSplitByAgeRange || this.specialAdCategorySelected) {
			this.store.dispatch(new UpdateSplitByAgeRange(false));
		} else {
			this.store.dispatch(new UpdateSplitByAgeRange(this.isSplitByAgeRange));
		}
		this.updateFormGroup();
	}

	private isSplitHistoryContain(field: string): boolean {
		if (!this.optimizationDrafts?.splitHistory) {
			return false;
		}
		const splitHistory = this.optimizationDrafts.splitHistory.find(v => v.field === field);
		return !!splitHistory;
	}

	public splitRequest(): void {
		let splitOptions = {};
		splitOptions = { ...splitOptions, gender: this.isSplitByGender };
		if (this.isSplitByAgeRange) {
			splitOptions = { ...splitOptions, age: this.isSplitByAgeRange };
		}
		if (this.isSplitByPlacements) {
			splitOptions = { ...splitOptions, placement: true };
		}
		if (this.isSplitByInterests && Array.isArray(this.isSplitByInterests) && this.isSplitByInterests.length > 0 && this.canSplitInterests) {
			splitOptions = { ...splitOptions, interest: this.selectedInterests?.length / this.isSplitByInterests?.length ?? 0 };
		}
		this.makeSplits(splitOptions);
	}

	// public mergeSplits(field: string): void {
	// 	this.store.dispatch(new ShowGlobalSpinner());
	// 	this.campaignSmartCreateService.mergeSplits(this.draftStructureId, field).subscribe(
	// 		res => {
	// 			this.store.dispatch(new CreateOptimizationDraftSuccess(res));
	// 			this.store.dispatch(new HideGlobalSpinner());
	// 		},
	// 		error => {
	// 			this.toastService.sendErrorToast(error.error.message);
	// 			this.store.dispatch(new HideGlobalSpinner());
	// 			this.updateFormGroup();
	// 		}
	// 	);
	// }
	public makeSplits(data: any): void {
		if (data) {
			data = { ...data, draft_structure_id: this.draftStructureId };
		}
		this.store.dispatch(new ShowGlobalSpinner());
		// this.campaignSmartCreateService.updateOptimizationSplit(data).subscribe(
		// 	res => {
		// 		this.store.dispatch(new CreateOptimizationDraftSuccess(res));
		// 		this.store.dispatch(new HideGlobalSpinner());
		// 	},
		// 	error => {
		// 		this.toastService.sendErrorToast(error.error.message ?? error.error);
		// 		this.store.dispatch(new HideGlobalSpinner());
		// 		this.updateFormGroup();
		// 	}
		// );
	}

	public toggleLiveState(optimization: OptimizationSettingsInterface, index: number): void {
		optimization.isLive = !optimization.isLive;
		this.updateOptimizationSetting(optimization);
	}
}
