import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CustomFieldCellRendererParamsInterface } from '../../../shared/ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer-params.interface';
import { InsightsCategoryTypeEnum } from '../../../shared/campaign-insights/models/insights-category-type.enum';
import { AgGridColDefInterface } from '../../../shared/master-table/models/ag-grid-col-def.interface';
import { AgGridColumnConfigurationEnum } from '../../../shared/master-table/models/ag-grid-column-configuration.enum';
import { AgGridViewInterface } from '../../../shared/master-table/models/ag-grid-view.interface';
import { ColumnTypesEnum } from '../../../shared/master-table/models/column-types.enum';
import { AdsManagerAgGridServiceInterface } from '../models/ads-manager-ag-grid-service.interface';
import { AdsManagerCellParamsInterface } from '../models/ads-manager-cell-params.interface';
import { AdsManagerFrameworkComponentsEnum } from '../models/ads-manager-framework-components.enum';

@Injectable()
export class ViewsMapperService {
	public currency: string;
	constructor() {}

	public showFilterButtons(column: AgGridColDefInterface): void {
		if (column.filter) {
			column.menuTabs = [AgGridColumnConfigurationEnum.FilterMenuTab];
		}
	}

	public addToggleSlider(column: AgGridColDefInterface, service: AdsManagerAgGridServiceInterface): void {
		if (column.isToggle) {
			column.cellRenderer = AdsManagerFrameworkComponentsEnum.ToggleCellRenderer;
			column.minWidth = 80;
			column.maxWidth = 80;
			column.resizable = false;
			(column.cellRendererParams as AdsManagerCellParamsInterface) = {
				parentService: service
			};
			delete column.isToggle;
		}
	}

	public addClickableName(column: AgGridColDefInterface, service: AdsManagerAgGridServiceInterface): void {
		if (column.editable) {
			column.minWidth = 180;
			column.pinnedRowCellRenderer = AdsManagerFrameworkComponentsEnum.TotalsStructureNameCellRenderer;
			column.cellRenderer = AdsManagerFrameworkComponentsEnum.ClickableNameRenderer;
			(column.cellRendererParams as AdsManagerCellParamsInterface) = {
				parentService: service,
				isClickable: !!column.isNameClickable
			};
		}
		delete column.isNameClickable;
	}

	public addEditableName(column: AgGridColDefInterface, service: AdsManagerAgGridServiceInterface, level: InsightsCategoryTypeEnum): void {
		if (column.editable) {
			column.cellEditor = AdsManagerFrameworkComponentsEnum.EditableNameEditor;
			(column.cellEditorParams as AdsManagerCellParamsInterface) = {
				parentService: service,
				level: level
			};
		}
	}

	public addCustomFieldCellRenderer(column: AgGridColDefInterface): void {
		if (
			column.columnType === ColumnTypesEnum.Currency ||
			column.columnType === ColumnTypesEnum.Number ||
			column.columnType === ColumnTypesEnum.Percentage ||
			column.columnType === ColumnTypesEnum.Budget ||
			column.columnType === ColumnTypesEnum.Image
		) {
			if (column.columnType === ColumnTypesEnum.Budget) {
				column.cellRenderer = AdsManagerFrameworkComponentsEnum.BudgetCellRenderer;
			} else if (column.columnType === ColumnTypesEnum.Image) {
				column.cellRenderer = AdsManagerFrameworkComponentsEnum.ImageCellRenderer;
			} else {
				column.cellRenderer = AdsManagerFrameworkComponentsEnum.CustomFieldCellRenderer;
			}
			(column.cellRendererParams as CustomFieldCellRendererParamsInterface) = {
				numberOfDecimals: column.numberOfDecimals,
				isPercentage: column.columnType === ColumnTypesEnum.Percentage,
				currency: column.columnType === ColumnTypesEnum.Currency || column.columnType === ColumnTypesEnum.Budget ? this.currency : null
			};
			delete column.numberOfDecimals;
		}
		if (column.columnType) {
			delete column.columnType;
		}
	}

	public onChangeAdAccount(views: AgGridViewInterface[]): void {
		views.forEach(view => {
			view.columns = view.columns.map(column => {
				this.changeCurrencyOnColumn(column);
				return column;
			});
		});
	}

	public changeCurrencyOnColumn(column: AgGridColDefInterface): void {
		if (column.cellRendererParams?.currency) {
			(column.cellRendererParams as CustomFieldCellRendererParamsInterface).currency = this.currency;
		}
	}

	public mapViews(views: AgGridViewInterface[], service: AdsManagerAgGridServiceInterface, level: InsightsCategoryTypeEnum): void {
		views.forEach(view => {
			view.columns = view.columns.map(column => {
				this.addToggleSlider(column, service);
				this.showFilterButtons(column);
				this.addClickableName(column, service);
				this.addEditableName(column, service, level);
				this.addCustomFieldCellRenderer(column);
				return column;
			});
		});
		this.addCheckboxColumn(views, service);
	}

	public addCheckboxColumn(views: AgGridViewInterface[], service: AdsManagerAgGridServiceInterface): void {
		const headerComponentParams: AdsManagerCellParamsInterface = {
			parentService: service
		};
		const checkboxColumn: ColDef = {
			headerName: '',
			colId: 'selected',
			field: 'value',
			maxWidth: 50,
			minWidth: 50,
			headerComponent: AdsManagerFrameworkComponentsEnum.CheckboxHeader,
			pinned: 'left',
			resizable: false,
			lockPinned: true,
			lockPosition: true,
			cellRendererParams: {
				checkbox: true
			},
			checkboxSelection: true,
			suppressMenu: true,
			headerComponentParams: headerComponentParams
		};
		views.forEach(view => {
			view.columns.unshift(checkboxColumn);
		});
	}
}
