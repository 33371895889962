import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appInputNumberCard]'
})
export class InputNumberCardDirective {
	constructor(private el: ElementRef) {}

	@HostListener('input', ['$event'])
	onKeyUp(event: KeyboardEvent) {
		const input = this.el.nativeElement;
		const { selectionStart } = input;
		const cardNumber = event.target as HTMLInputElement;

		let trimmedCardNum = cardNumber.value.replace(/\s+/g, '');

		if (trimmedCardNum.length > 16) {
			trimmedCardNum = trimmedCardNum.substr(0, 16);
		}

		/* Handle American Express 4-6-5 spacing */
		const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37') ? [4, 6, 5] : [4, 4, 4, 4];

		const numbers: Array<string> = [];
		let position = 0;
		partitions.forEach(partition => {
			const part = trimmedCardNum.substr(position, partition);
			if (part) {
				numbers.push(part);
			}
			position += partition;
		});

		cardNumber.value = numbers.join(' ');

		/* Handle caret position if user edits the number later */
		if (selectionStart < cardNumber.value.length - 1) {
			input.setSelectionRange(selectionStart, selectionStart, 'none');
		}
	}
}
