<div mat-dialog-title class="dialog-header">
	<h6>
		Create Form
		<span class="dialog-close" (click)="close()"><i class="fa fa-times"></i></span>
	</h6>
</div>

<div class="dialog-container" [formGroup]="dialogGroup">
	<div class="modal-box-content-tabs">
		<btn15 (onClick)="toggleShowGeneralContentTab(false)">Contents</btn15>
		<btn15 (onClick)="toggleShowGeneralContentTab(true)">Advanced</btn15>
	</div>
	<div class="modal-box-content">
		<div fxLayout>
			<div fxFlex="67">
				<h5>Intro</h5>
				<div><tb06 [label]=""></tb06></div>
				<h5>Questions</h5>
				<app-custom-question [title]="'Custome Question'"></app-custom-question>

				<btn16-02 [data]="dropdwonWithIcon" [icon]="'plus'">
					Add Question
				</btn16-02>
			</div>
			<div fxFlex="5"></div>
			<div fxFlex="28">
				<img alt="cameraOff" src="/assets/icons/cameraOff.svg" />
			</div>
		</div>
	</div>
</div>

<mat-dialog-actions fxLayout class="dialog-footer">
	<div fxFlex="70"></div>
	<div fxFlex="30">
		<btn15 (onClick)="close()" [icon]="'fa fa-file'">Save Draft</btn15>
		<btn06 (onClick)="onSubmit()" [icon]="'fa fa-paper-plane'">Publish</btn06>
	</div>
</mat-dialog-actions>
