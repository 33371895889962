import { AaaActionsTypes, AaaActions } from './aaa.actions';
import { CatalogInterface } from 'src/app/campaign-smart-create/catalogs/catalog.interface';
import _ from 'lodash';
import { ObjectiveTreeInterface } from 'src/app/campaign-smart-create/models/objective-tree.interface';
import { CampaignAAAInterface } from '../models/campaign';
import { AdSetsAAAInterface } from '../models/ad-sets-aaa.interface';
import { AdsAAAInterface } from '../models/ads-aaa.interface';

export interface AAAState {
	objective: ObjectiveTreeInterface;
	selectedCampaignIds: string[];
	selectedAdSetIds: string[];
	selectedCampaigns: CampaignAAAInterface[];
	selectedAdsets: AdSetsAAAInterface[];
	selectedAdIds: string[];
	selectedAds: AdsAAAInterface[];
}

export const initialAAAState: AAAState = {
	objective: null,
	selectedCampaignIds: null,
	selectedAdSetIds: null,
	selectedAdIds: null,
	selectedCampaigns: null,
	selectedAdsets: null,
	selectedAds: null
};

export function aaaReducer(state: AAAState = initialAAAState, action: AaaActions): AAAState {
	switch (action.type) {
		case AaaActionsTypes.UpdateObjective: {
			return {
				...state,
				objective: action.payload
			};
		}
		case AaaActionsTypes.UpdateSelectedAAACamapignIds: {
			return {
				...state,
				selectedCampaignIds: action.payload
			};
		}
		case AaaActionsTypes.UpdateSelectedAAAAdSetIds:
			return {
				...state,
				selectedAdSetIds: action.payload
			};
		case AaaActionsTypes.UpdateSelectedAAAAdIds:
			return {
				...state,
				selectedAdIds: action.payload
			};
		case AaaActionsTypes.UpdateSelectedAAACampaigns: {
			let selectedCampaigns = null;
			if (action.payload !== null) {
				selectedCampaigns = _.cloneDeep(state.selectedCampaigns) ?? ([] as CampaignAAAInterface[]);
				const selectedCampaignsId = selectedCampaigns.map((selectedCampaign: CampaignAAAInterface) => selectedCampaign.campaign_id);
				if (action.payload.isChosen && selectedCampaignsId.indexOf(action.payload.selectedRow.campaign_id) === -1) {
					selectedCampaigns.push(action.payload.selectedRow);
				} else if (!action.payload.isChosen && selectedCampaignsId.indexOf(action.payload.selectedRow.campaign_id) > -1) {
					const selectedIndex = selectedCampaignsId.indexOf(action.payload.selectedRow.campaign_id);
					selectedCampaigns.splice(selectedIndex, 1);
				}
				selectedCampaigns = selectedCampaigns.length !== 0 ? selectedCampaigns : null;
			}
			return {
				...state,
				selectedCampaigns: selectedCampaigns
			};
		}
		case AaaActionsTypes.UpdateSelectedAAAAdSets: {
			let selectedAdSets = null;
			if (action.payload !== null) {
				selectedAdSets = _.cloneDeep(state.selectedAdsets) ?? ([] as AdSetsAAAInterface[]);
				const selectedAdSetsIds = selectedAdSets.map((selectedAdSet: AdSetsAAAInterface) => selectedAdSet.adset_id);
				if (action.payload.isChosen && selectedAdSetsIds.indexOf(action.payload.selectedRow.adset_id) === -1) {
					selectedAdSets.push(action.payload.selectedRow);
				} else if (!action.payload.isChosen && selectedAdSetsIds.indexOf(action.payload.selectedRow.adset_id) > -1) {
					const selectedIndex = selectedAdSetsIds.indexOf(action.payload.selectedRow.adset_id);
					selectedAdSets.splice(selectedIndex, 1);
				}
			}
			return {
				...state,
				selectedAdsets: selectedAdSets
			};
		}
		case AaaActionsTypes.UpdateSelectedAAAAds: {
			let selectedAds = null;
			if (action.payload !== null) {
				selectedAds = _.cloneDeep(state.selectedAds) ?? ([] as AdsAAAInterface[]);
				const selectedAdIds = selectedAds.map((_selectedAds: AdsAAAInterface) => _selectedAds.ad_id);
				if (action.payload.isChosen && selectedAdIds.indexOf(action.payload.selectedRow.ad_id) === -1) {
					selectedAds.push(action.payload.selectedRow);
				} else if (!action.payload.isChosen && selectedAdIds.indexOf(action.payload.selectedRow.ad_id) > -1) {
					const selectedIndex = selectedAdIds.indexOf(action.payload.selectedRow.ad_id);
					selectedAds.splice(selectedIndex, 1);
				}
			}
			return {
				...state,
				selectedAds: selectedAds
			};
		}
		case AaaActionsTypes.ResetState:
			return Object.assign({}, initialAAAState);
		default:
			return state;
	}
}
