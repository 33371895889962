import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '../../dropdown-search-select/dropdown-type.enum';

import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownColumnInterface } from './dropdown-columns.interface';
import { DropdownData } from '../../dropdown-search-select/dropdown-data.interface';
import { MapperService } from '../../services/mapper.service';

@Component({
	selector: 'app-live-search-dropdown',
	templateUrl: './live-search-dropdown.component.html',
	styleUrls: ['./live-search-dropdown.component.scss']
})
export class LiveSearchDropdownComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public inputControl: FormControl | AbstractControl = new FormControl();
	@Input() public dropdownData: DropdownData[] = [];
	@Input() public selectedData: DropdownData[] = [];
	@Input() public columns: DropdownColumnInterface[];
	@Input() public dropdownWidth = 30;
	@Input() public inputLabel: string;
	@Input() public inputPlaceholder: string;
	@Input() public selfFilter = true;
	@Input() public showSelected = true;
	@Input() public singleSelection = false;
	@Input() public showSpinner = false;
	@Input() public contextUpdateData: DropdownData;

	@Output() public selectionChange = new EventEmitter<DropdownData[]>();
	@ViewChild('dropdownElement', { static: true }) public dropdownElement: ElementRef;
	public showDropdown: boolean;
	public searchDataView: DropdownData[];
	public selectType = SelectType;

	private searchData: DropdownData[];
	private unsubscriber$ = new Subject();

	constructor() {}

	public ngOnInit(): void {
		this.inputControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(text => {
			if (this.selfFilter) {
				this.filterData(text);
			} else {
				this.showSpinner = !!text;
			}
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.dropdownData = this.dropdownData ?? [];
		this.selectedData = this.selectedData ?? [];
		this.searchData = this.dropdownData;
		this.searchDataView = this.searchData;
		this.showSpinner = false;
		this.setVisibleColumns();
		if (changes.contextUpdateData?.currentValue && changes.contextUpdateData?.currentValue !== changes.contextUpdateData?.previousValue) {
			this.onSelectionClick(changes.contextUpdateData?.currentValue);
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	public onOutsideClick(): void {
		this.showDropdown = false;
		this.searchData = this.dropdownData;
		this.searchDataView = this.searchData;

		if (this.selfFilter) {
			this.inputControl.setValue('');
		}
	}

	public onInputFocusIn(): void {
		this.showDropdown = true;
		this.dropdownElement?.nativeElement.scrollIntoView({ block: 'center' });
	}

	public onSelectionClick(selection: DropdownData): void {
		const entry = this.selectedData.find(data => data.value === selection.value);
		const selectedDataLength = this.selectedData.length;

		if ((selectedDataLength < 1 || !this.singleSelection) && !entry) {
			this.selectedData.push(selection);
		} else if (!entry && this.singleSelection) {
			this.selectedData[0] = selection;
		} else {
			this.selectedData = this.selectedData.filter(data => data.value !== selection.value);
		}
		this.selectionChange.emit(this.selectedData);
	}

	public isValueSelected(data: DropdownData): boolean {
		return !!this.selectedData.find(item => item.value === data.value);
	}
	public isSelectedDataColumn(column: DropdownColumnInterface): boolean {
		return column.showInSelectContainer;
	}

	public isDropdownColumn(column: DropdownColumnInterface): boolean {
		return column.showColumnInDropdown;
	}

	public isLastColumnInDropdown(index: number): boolean {
		return index === this.columns.filter(col => col.showColumnInDropdown).length - 1;
	}

	private filterData(value: string): void {
		this.searchData = this.dropdownData.filter(data => data.displayName?.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1);
		this.searchDataView = this.searchData;
	}

	private setVisibleColumns(): void {
		this.columns?.forEach(column => {
			column.showColumnInDropdown = !column.excludeColumnFromDropdown;
		});
	}
}
