<app-expansion-panel-sc
	class="scs-expansion-panel"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Choose Catalog Set'"
	[isExpandable]="false"
>
	<div expansion-content>
		<div>
			<div class="pc-dropdown-wrapper pc-margin-bottom">
				<span class="k-mb-2">Choose the Catalogue Set</span>
				<drp01 [data]="productCatalogSets" [valuePrimitive]="true" placeholder="Choose Catalog Set" [control]="productCatalogSetFormControl"></drp01>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
