import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { SelectType } from '../../../dropdown-search-select/dropdown-type.enum';
import { DropdownData } from '../../../dropdown-search-select/dropdown-data.interface';
import { CatalogNodeInterface } from '../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { AbstractControl, FormControl } from '@angular/forms';
import { ValidationNodeInterface } from '../../../../campaign-smart-create/catalogs/validation-node.interface';
import { CampaignSmartCreateValidationService } from '../../../../campaign-smart-create/services/campaign-smart-create-validation.service';
import { ValidationFilterByEnum } from '../../../../campaign-smart-create/models/validation-filter-by.enum';
import { ValidationFilterSelectEnum } from '../../../../campaign-smart-create/models/validation-filter-select.enum';
import { FiledLibMapperService } from 'src/app/shared/services/filed-lib-Mapper.service';
import { DropdownInterface } from '@filed-com/filed-lib';

@Component({
	selector: 'app-optimization-and-delivery-card',
	templateUrl: './optimization-and-delivery-card.component.html',
	styleUrls: ['./optimization-and-delivery-card.component.scss']
})
export class OptimizationAndDeliveryCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public optimizationDelivery: AbstractControl | FormControl;
	@Input() public optimizationGoalList: DropdownData<CatalogNodeInterface>[];
	@Input() public billingEvents: DropdownData<CatalogNodeInterface>[];
	@Input() public clicks: DropdownData<CatalogNodeInterface>[];
	@Input() public views: DropdownData<CatalogNodeInterface>[];
	@Input() public validations: ValidationNodeInterface[];
	@Input() public objectiveWithDestination: string;
	@Input() public subObjective: string;

	@Output() public onExpansionClick = new EventEmitter();

	public clicksAndView: DropdownData<CatalogNodeInterface>[];
	public validGoals: DropdownInterface[];
	public validClicksAndViews: DropdownInterface[];
	public validBillingEvents: DropdownInterface[];
	public selectType = SelectType;
	public validService: CampaignSmartCreateValidationService;
	public mapperService: FiledLibMapperService;

	constructor(private injector: Injector) {
		this.validService = this.injector.get<CampaignSmartCreateValidationService>(CampaignSmartCreateValidationService);
		this.mapperService = this.injector.get<FiledLibMapperService>(FiledLibMapperService);
	}

	public ngOnInit(): void {
		this.makeCartesianProduct();
		this.populateDropdowns();
		this.optimizationDelivery.disable();
	}

	public makeCartesianProduct(): void {
		const temp: DropdownData<CatalogNodeInterface>[] = [];
		this.views?.forEach(item => {
			this.clicks?.forEach(x => {
				const create: DropdownData<CatalogNodeInterface> = {
					displayName: x.displayName + ', ' + item.displayName,
					value: x.value + '/' + item.value
				};
				temp.push(create);
			});
		});
		this.clicksAndView = temp;
	}

	public populateDropdowns(): void {
		this.getOptimizationGoals();
		this.getClicksViewsAndBillingEvents();
	}

	public getOptimizationGoals(): void {
		const validGoals = this.validService.getValidFields(
			this.validations,
			'ObjectiveWithDestination',
			'OptimizationGoal',
			this.optimizationGoalList,
			this.objectiveWithDestination
		);
		if (validGoals.length) {
			this.validGoals = this.mapperService.convertToDropdownData(validGoals, 'value', 'displayName');
			this.optimizationDelivery.get('optimizationGoal').patchValue(this.validGoals[0]?.id);
		}
	}

	public getClicksViewsAndBillingEvents(): void {
		const optimizationGoal = this.optimizationDelivery.get('optimizationGoal').value;
		const validBillingEvents = this.validService.getValidFields(
			this.validations,
			ValidationFilterByEnum.OptimizationGoal,
			ValidationFilterSelectEnum.BillingEvent,
			this.billingEvents,
			optimizationGoal
		);
		if (validBillingEvents.length) {
			this.validBillingEvents = this.mapperService.convertToDropdownData(validBillingEvents, 'value', 'displayName');
			this.optimizationDelivery.get('billingEvents').patchValue(this.validBillingEvents[0]?.id);
		}
		const validClicksAndViews = this.validService.getValidFourLayer(
			this.validations,
			ValidationFilterByEnum.Objective,
			ValidationFilterSelectEnum.OptimizationGoal,
			this.clicksAndView,
			this.subObjective,
			optimizationGoal
		);
		if (validClicksAndViews.length) {
			this.validClicksAndViews = this.mapperService.convertToDropdownData(validClicksAndViews, 'value', 'displayName');
			this.optimizationDelivery.get('clicksAndViews').patchValue(this.validClicksAndViews[0]?.id);
		} else {
			this.optimizationDelivery.get('clicksAndViews').patchValue(null);
		}
	}
}
