export class LineChart {
	private areaFlag: boolean;
	private mappingsNumber: number;
	public option: any;

	constructor(chartTitle: string, xAxisBenchmarks: any[], isAreaChart: boolean = false, xAxisTitle: string = ' ', yAxisTitle: string = ' ') {
		this.areaFlag = isAreaChart;
		this.mappingsNumber = xAxisBenchmarks.length;
		this.option = {
			title: {
				text: chartTitle
			},
			tooltip: {
				trigger: 'axis'
			},
			legend: {
				data: []
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			},
			toolbox: {
				feature: {
					saveAsImage: {
						name: chartTitle,
						title: ' '
					}
				}
			},
			xAxis: {
				axisLabel: {
					formatter: (value: any) => {
						return value.length > 15 ? value.substr(0, 14) + '...' : value;
					}
				},
				type: 'category',
				boundaryGap: false,
				/////// TODO: Refactor
				// // Temporary fix as left and right side bar chart columns were going over the chart edge (axis lines)
				data: ['', ...xAxisBenchmarks, '']
			},
			yAxis: {
				type: 'value'
			},
			series: []
		};

		if (xAxisTitle !== ' ') {
			this.option.xAxis.name = xAxisTitle;
		}
		if (yAxisTitle !== ' ') {
			this.option.xAxis.name = yAxisTitle;
		}
	}

	public addLine(lineName: string, linePoints: number[]): void {
		this.option.legend.data.push(lineName);
		const optionBuffer: { [k: string]: any } = {
			name: lineName,
			type: 'line',
			data: linePoints
		};

		if (this.areaFlag) {
			optionBuffer.areaStyle = {};
		}

		this.option.series.push(optionBuffer);
	}
}
