<div class="container">
	<div class="left-dock">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="right-dock">
		<div *ngIf="!submitting" class="wrapper">
			<div class="logo">
				<img src="assets/logo/lolly-logo.svg" />
			</div>
			<div class="started-text">{{ "Let's Get Started" }}</div>
			<div class="cta-text">{{ "You'll be up & running in 2 minutes" }}</div>
			<div (click)="signUpWithGoogle()" class="continue-btn-wrapper">
				<div class="btn">
					<div class="items">
						<img src="assets/images/google-icon.svg" />
						<div class="text">{{ 'Continue with Google' }}</div>
					</div>
				</div>
			</div>
			<div class="rule-a-text">
				<hr />
				<div class="text">{{ 'Or register with your email' }}</div>
			</div>

			<div class="text-input-split">
				<div class="left">
					<div class="label">{{ 'First Name' }}</div>
					<app-input-field
						[showValidIcon]="true"
						[hideError]="false"
						[placeHolderTextBox]="''"
						[inputControl]="firstNameFormControl"
						[showCustomIcon]="firstNameValidationIcon"
						[errorMessage]="'Enter a valid first name'"
						[iconWidth]="'14px'"
						[marginRight]="'8px'"
					></app-input-field>
				</div>
				<div class="right">
					<div class="label">{{ 'Last Name' }}</div>
					<app-input-field
						[showValidIcon]="true"
						[hideError]="false"
						class="title-input"
						[placeHolderTextBox]="''"
						[inputControl]="lastNameFormControl"
						[showCustomIcon]="lastNameValidationIcon"
						[errorMessage]="'Enter a valid last name'"
						[iconWidth]="'14px'"
						[marginRight]="'8px'"
					></app-input-field>
				</div>
			</div>

			<div class="full-text-input l-margin">
				<div class="label">{{ 'Email Address' }}</div>
				<app-input-field
					[showValidIcon]="true"
					[hideError]="false"
					class="title-input"
					[placeHolderTextBox]="''"
					[inputControl]="emControl"
					[showCustomIcon]="emailValidationIcon"
					[errorMessage]="'Enter a valid email'"
					[iconWidth]="'14px'"
					[marginRight]="'8px'"
				></app-input-field>
			</div>
			<!-- <div class="full-text-input l-margin">
				<div class="label">{{ 'Password' }}</div>
				<app-input-field class="title-input" [placeHolderTextBox]="''" [inputControl]="passFormControl"></app-input-field>
			</div> -->

			<div class="text-input-split l-margin">
				<div class="left">
					<div class="label">{{ 'Password' }}</div>
					<app-input-field-password
						[showValidIcon]="true"
						[hideError]="false"
						class="title-input"
						[placeHolderTextBox]="''"
						[inputControl]="passFormControl"
						[showCustomIcon]="passwordValidationIcon"
						[errorMessage]="'Strong password required with 1 upper, 1 lowercase, 1 special character and 1 number'"
						[iconWidth]="'14px'"
						[marginRight]="'8px'"
					></app-input-field-password>
				</div>
				<div class="right">
					<div class="label">{{ 'Confirm Passowrd' }}</div>
					<app-input-field-password
						[showValidIcon]="true"
						[hideError]="false"
						class="title-input"
						[placeHolderTextBox]="''"
						[inputControl]="confirmpassFormControl"
						[showCustomIcon]="showConfirmPasswordError"
						[errorMessage]="'Confirm password is not same as password'"
						[iconWidth]="'12px'"
						[marginRight]="'8px'"
					></app-input-field-password>
				</div>
			</div>

			<div (click)="processRegistrationDetail()" [ngClass]="{ 'invalid-register': !formFieldsValid() }" class="signin-btn-wrapper">
				<div class="btn">
					<div class="items">
						<div class="text">{{ 'Continue' }}</div>
					</div>
				</div>
			</div>

			<div class="bottom-text-action">
				<div class="dark">{{ 'Already have an account?' }}</div>
				<div (click)="backToLogin()" class="theme">{{ 'Login' }}</div>
			</div>
		</div>
		<div class="wrapper" *ngIf="submitting">
			<lolly-spinning-loader></lolly-spinning-loader>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
