import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { Observable } from 'rxjs';
import { PublishResponseInterface } from '../../campaign-smart-create/models/publish-response.interface';
import { PublishAds } from '../models/publish-ads';
import { Store } from '@ngrx/store';
import { SharedState } from '../../shared/state/shared.reducer';

@Injectable({
	providedIn: 'root'
})
export class PublishAddService {
	constructor(private http: HttpClient) {}

	// public publishAdd(body: PublishAds): Observable<PublishAds> {
	// 	return this.http.post<PublishAds>(`${BaseApiUrl.FacebookPythonCampaignBuilder}add-structures-to-parent`, body);
	// }

	// public checkPublishStatus(): Observable<PublishResponseInterface> {
	// 	return this.http.get<PublishResponseInterface>(`${BaseApiUrl.FacebookPythonCampaignBuilder}smart-create/publish-progress/`);
	// }

	// public getStructure(accountId: string, level: string, itemId: string): Observable<any> {
	// 	return this.http.get(`${BaseApiUrl.FacebookPythonCampaignBuilder}get-structure/${accountId}/${level}/${itemId}`);
	// }
}
