import { Component, EventEmitter, Injector, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TreeViewInterface } from '@filed-com/filed-lib/lib/tree-view/models/tw-interface';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { PlacementsCardComponent } from 'src/app/shared/smart-create-edit-components/step-two-components/placements-card/placements-card.component';
import _ from 'lodash';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
@Component({
	selector: 'app-placements',
	templateUrl: './placements.component.html',
	styleUrls: ['./placements.component.scss']
})
export class PlacementsComponent extends PlacementsCardComponent implements OnInit, OnChanges {
	public info: InfoHoverDataModel = { message: 'Explanation text goes here' };
	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (this.selectedPlacements.length > 0) {
			this.filterPlacementPositions();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		super.ngOnChanges(changes);
	}
}

export interface IPlacementItem {
	text: string;
	name: string;
}

export interface IPlacementPerPlatform extends TreeViewInterface {
	items: IPlacementItem[];
}
