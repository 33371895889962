import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SetPassword } from '../../_models/identity-models/set-password';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';
import { Store } from '@ngrx/store';
import { AuthenticationState } from '../state/authentication.reducer';
import { catchError, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { CustomValidator } from 'src/app/shared/form-input/input-password/custom-validator';
import { LogIn } from '../state/authentication.action';
import { LogInModel } from '../_models/login.model';
import { getUserDetails } from 'src/app/shared/state/user/user.reducer';

@Component({
	selector: 'app-set-first-password',
	templateUrl: './set-first-password.component.html',
	styleUrls: ['./set-first-password.component.scss']
})
export class SetFirstPasswordComponent implements OnInit {
	public mainBtn = ButtonClassEnum;
	public typeBtn = ButtonTypeEnum;
	public pageType = 'Create Password';
	public form: FormGroup;
	public emailFormControl: FormControl;
	public passwordFormControl: FormControl;
	public confirmPasswordFormControl: FormControl;

	private unsubscriber$ = new Subject<void>();
	private token: string;
	private emailValue: string;
	public passwordStrength: string;
	public loading = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private userServiceApi: UserServiceApi,
		private formBuilder: FormBuilder,
		private toastNotificationService: ToastNotificationService,
		private authStore: Store<AuthenticationState>
	) {
		this.checkToRedirect();
	}

	public ngOnInit(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.token = encodeURIComponent(params['token']);
			this.emailValue = decodeURIComponent(params['email']);
		});

		this.createForm();
		this.resetTokens();
		this.formChange();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public checkToRedirect(): void {
		let currentRoute = window.location.href;
		var subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
		// should be direct password page
		// if (subdomain == 'dev3') {
		// 	window.location.href = currentRoute.replace('set-first-password', 'new-signup/dexter-welcome');
		// }
	}
	public createForm(): void {
		this.form = this.formBuilder.group(
			{
				EmailAddress: new FormControl(this.emailValue, Validators.required),
				password: new FormControl('', [
					Validators.required,
					CustomValidator.patternValidator(/\d/, {
						hasNumber: true
					}),
					CustomValidator.patternValidator(/[A-Z]/, {
						hasCapitalCase: true
					}),
					CustomValidator.patternValidator(/[a-z]/, {
						hasSmallCase: true
					}),
					CustomValidator.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
						hasSpecialCharacters: true
					}),
					Validators.minLength(8)
				]),
				confirmPassword: new FormControl(null, Validators.compose([Validators.required])),
				TwoFactorToken: new FormControl(this.token, [Validators.required])
			},
			{ validator: CustomValidator.passwordMatchValidator }
		);
	}

	public resetTokens(): void {
		localStorage.clear();
	}

	public onSubmit(): void {
		this.loading = true;
		if (this.form.invalid) {
			return;
		}

		const newPassword: SetPassword = this.form.value as SetPassword;

		this.userServiceApi
			.setPassword(newPassword)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					const loginPayload: LogInModel = {
						email: this.form.get('EmailAddress').value,
						password: this.form.get('password').value
					};
					this.authStore.dispatch(new LogIn(loginPayload));
					this.authStore
						.select(getUserDetails)
						.pipe(
							takeUntil(this.unsubscriber$),
							catchError(error => {
								this.toastNotificationService.sendErrorToast('There was an error verifying your user credentials. Please contact support.');
								this.loading = false;
								return EMPTY;
							})
						)
						.subscribe(user => {
							if (user) {
								this.loading = false;
							}
						});
				},
				error => {
					if (error.error[0].code === 'Filed__Domain__NA__FirstPassword__Exists') {
						this.toastNotificationService.sendErrorToast('This account has already been registered. Please log in.');
					} else {
						this.toastNotificationService.sendErrorToast('There has been a problem with the server. Please contact support.');
					}
					this.loading = false;
				}
			);
	}

	private formChange(): void {
		this.form.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			this.passwordStrength = 'default';
			const passwordControl = this.form.controls['password'];
			const chunkStrength = [];
			if (passwordControl.value.trim() == '') {
				this.passwordStrength = 'default';
			} else if (passwordControl.invalid) {
				if (passwordControl.hasError('hasSmallCase') && !passwordControl.hasError('required')) {
					chunkStrength.push(passwordControl.hasError('hasSmallCase'));
				}
				if (passwordControl.hasError('hasNumber') && !passwordControl.hasError('required')) {
					chunkStrength.push(passwordControl.hasError('hasNumber'));
				}
				if (passwordControl.hasError('hasCapitalCase') && !passwordControl.hasError('required')) {
					chunkStrength.push(passwordControl.hasError('hasCapitalCase'));
				}
				if (passwordControl.hasError('hasSpecialCharacters') && !passwordControl.hasError('required')) {
					chunkStrength.push(passwordControl.hasError('hasSpecialCharacters'));
				}
				if (passwordControl.hasError('minlength') && !passwordControl.hasError('required')) {
					chunkStrength.push(passwordControl.hasError('minlength'));
				}

				if (chunkStrength.length >= 3) {
					this.passwordStrength = 'weak';
				}
				if (chunkStrength.length < 3) {
					this.passwordStrength = 'medium';
				}
			} else {
				this.passwordStrength = 'strong';
			}
		});
	}
}
