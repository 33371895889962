import { Component, Input, Output, OnInit, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
	selector: 'app-custome-disclaimer',
	templateUrl: './custome-disclaimer.component.html',
	styleUrls: ['./custome-disclaimer.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CustomeDisclaimerComponent),
			multi: true
		}
	]
})
export class CustomeDisclaimerComponent implements OnInit, ControlValueAccessor {
	@Input() placeholderText: string;
	@Input() name: string;

	@Output() consentChanged: EventEmitter<FormControl> = new EventEmitter();
	@Output() removeConsent: EventEmitter<string> = new EventEmitter();

	public consent = new FormControl();
	disabled = false;

	value = '';
	checked: false;

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {}

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	removeItem(): void {
		this.removeConsent.emit(this.name);
	}

	onTypeChange(): void {
		this.consentChanged.emit(this.consent);
	}
}
