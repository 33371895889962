import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { BudgetAllocatedEnum } from 'src/app/campaign-smart-create/models/budget-allocated.enum';
import { CampaignBudgetOptimizationInterface } from 'src/app/campaign-smart-create/models/campaign-budget-optimization.interface';
import { TargetingAudience } from 'src/app/campaign-smart-create/models/targeting-audience';
import { DateRangeX } from 'src/app/shared/calendar/calendar.models';
import { CampaignDateChoices } from 'src/app/shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { Devices, DeviceOS } from '../components/devices/devices.component';
import { IAPIAdModel } from '../helpers/helper';
import { createSelector } from '@ngrx/store';
import { getStepTwoDetails } from '../../campaign-smart-create/state/smart-create.reducer';

export class AdSetModel {
	public adSetId?: string;
	public ads?: IAPIAdModel[] = [];
	public adSetName = '';
	public name?: string = '';
	public facebookPageId = '';
	public instagramPageId = '';
	public placements: CatalogNodeInterface[] = [];
	public targeting = new TargetingAudience();
	public date: DateRangeX<string> = {
		startDate: '',
		endDate: ''
	};
	public dateChoice = CampaignDateChoices.Default;
	public budgetOptimization: CampaignBudgetOptimizationInterface = {
		budgetAllocatedTypeId: BudgetAllocatedEnum.LifeTime,
		amount: 0,
		minimumBudget: 0,
		bidStrategy: '',
		deliveryType: ''
	};
	public optimizationAndDelivery = {
		optimizationGoal: '',
		click: '',
		view: '',
		billingEvent: '',
		bidControl: ''
	};
	public device = Devices.all;
	public deviceOs = DeviceOS.all;
	public placementPerPlatform: any[] = [];
	public productSetId: string;
	public dexterAdsetBudgetLevel?: string;
}
