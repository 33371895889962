import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { AdFormatsEnum } from '../../../../../campaign-smart-create/models/ad-formats.enum';
import { Asset } from '../asset-picker-dialog/asset-model';
import { CatalogNodeInterface } from '../../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { DropdownData } from '../../../../dropdown-search-select/dropdown-data.interface';
import { Placements } from '../ad-creation-card/ad-creation.placement.model';

@Component({
	selector: 'app-preview-card',
	templateUrl: './preview-card.component.html',
	styleUrls: ['./preview-card.component.scss']
})
export class PreviewCardComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public iframeSrc: string;
	@Input() public dropdownPreviewPlacement: DropdownData<CatalogNodeInterface>[] = [];
	@Input() public adFormat: AdFormatsEnum;
	@Input() public isSticky: boolean;
	@Input() public adCreateFormGroup: FormGroup;
	@Input() public selectedAssets: Asset[];
	@Input() public devicePlacement: CatalogNodeInterface[];

	public adFormatEnum = AdFormatsEnum;
	public previewHeight: number = 540;
	public previewWidth: number = 460;

	constructor() {}

	public ngOnInit(): void {
		if (this.dropdownPreviewPlacement && this.dropdownPreviewPlacement.length > 0 && !this.adCreateFormGroup.get('preview')) {
			if (this.dropdownPreviewPlacement.find(placement => placement.value === Placements.FacebookMobileFeed)) {
				this.adCreateFormGroup.get('preview').patchValue(Placements.FacebookMobileFeed);
			}
		}
		this.setPreviewWidthAndHeight();
	}

	public ngOnDestroy(): void {}

	public ngOnChanges(): void {}
	public getCorrectHeight(): number {
		const oneRow = 58;
		let height = 690;
		const numberOfLetters = this.getCarouselCards(0).get('primary_text').value.length;
		let numberOfRows = Math.ceil(numberOfLetters / oneRow);
		numberOfRows = numberOfRows < 9 ? numberOfRows : 9;
		if (
			this.selectedAssets[0]?.width > this.selectedAssets[0]?.height ||
			this.adFormat === AdFormatsEnum.Carousel ||
			(this.adFormat === AdFormatsEnum.Video && this.selectedAssets[0]?.videoSize?.width > this.selectedAssets[0]?.videoSize?.height)
		) {
			height = 530;
		} else if (!this.selectedAssets[0]?.width && this.adFormat === AdFormatsEnum.SingleImage) {
			const image = new Image();
			image.src = this.selectedAssets[0]?.url;

			if (image.width > image.height) {
				height = 530;
			}
		}
		height += numberOfRows * 20;
		return height;
	}
	public returnWidth(): string {
		return this.previewWidth + 'px';
	}
	public returnHeight(): string {
		return this.previewHeight + 'px';
	}
	public LoadEvent(): void {}
	public setPreviewWidthAndHeight(): void {
		this.iframeSrc = '';
		const selected = this.adCreateFormGroup.get('preview').value;
		switch (selected) {
			case Placements.FacebookDesktopFeed: {
				this.previewHeight = this.getCorrectHeight();
				this.previewWidth = 460;
				break;
			}
			case Placements.FacebookMobileFeed: {
				this.previewHeight = 540;
				this.previewWidth = 320;
				break;
			}
			case Placements.InstagramStory:
			case Placements.FacebookStory: {
				this.previewHeight = 560;
				this.previewWidth = 320;
				break;
			}
			case Placements.InstagramStandard: {
				this.previewHeight = 557;
				this.previewWidth = 320;
				break;
			}
			case Placements.MobileNative: {
				this.previewHeight = 400;
				this.previewWidth = 325;
				break;
			}
			case Placements.MobileBanner: {
				this.previewHeight = 355;
				this.previewWidth = 325;
				break;
			}
			case Placements.MobileInterstitial: {
				this.previewHeight = 570;
				this.previewWidth = 325;
				break;
			}
			case Placements.MobileRectangle: {
				this.previewHeight = 400;
				this.previewWidth = 350;
				break;
			}
		}
	}

	public getCarouselCards(index: number = 0): AbstractControl {
		const advertItemsForm = this.adCreateFormGroup.get('adCardsItemData') as FormArray;
		return advertItemsForm.controls[index];
	}
}
