export enum ActionTypeEnum {
	Delete = 'Delete',
	Reset = 'Reset',
	Cancel = 'Cancel',
	Edit = 'Edit',
	DoNotSave = 'Do Not Save',
	ContinueEditing = 'Continue Editing',
	View = 'View',
	Update = 'Update',
	Create = 'Create',
	Continue = 'Continue'
}
