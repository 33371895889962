<div *ngIf="isToastVisible" class="toast-wrapper">
	<div class="toast-container">
		<div
			class="notification-content"
			[ngClass]="{
				'toast-info': toastModel.type === toastTypes.Info,
				'toast-error': toastModel.type === toastTypes.Error,
				'toast-warning': toastModel.type === toastTypes.Warning,
				'toast-success': toastModel.type === toastTypes.Success
			}"
		>
			<!-- info-->
			<div class="information-wrapper">
				<div [ngSwitch]="toastModel.type" class="toast-icon">
					<i *ngSwitchCase="toastTypes.Info" class="fas fa-info-circle info"></i>
					<i *ngSwitchCase="toastTypes.Success" class="fas fa-check-circle success"></i>
					<i
						*ngSwitchDefault
						[ngClass]="{ error: toastModel.type === toastTypes.Error, warning: toastModel.type === toastTypes.Warning }"
						class="fas fa-exclamation-triangle"
					></i>
				</div>
				<div class="content-toast">
					<h6
						[ngClass]="{
							info: toastModel.type === toastTypes.Info,
							error: toastModel.type === toastTypes.Error,
							warning: toastModel.type === toastTypes.Warning,
							success: toastModel.type === toastTypes.Success
						}"
					>
						{{ toastModel.customTitle }}
					</h6>
					<span>{{ toastModel.message }}</span>
				</div>
			</div>
			<!--close button-->
			<button (click)="onClick()" class="remove-button">
				<i class="fas fa-times"></i>
			</button>
		</div>
	</div>
</div>
