import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';

import { Toggable } from './toggle-cell-contract';
import { CatalogData } from 'src/app/product-catalogue/models/catalog-data-table.model.';

@Component({
	selector: 'app-ads-manager-toggle-cell',
	templateUrl: './ads-manager-toggle-cell.component.html',
	styleUrls: ['./ads-manager-toggle-cell.component.scss']
})
export class AdsManagerToggleCellComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;
	public isToggled: boolean;

	public agInit(params: ICellRendererParams): void {
		this.params = params;
		this.isToggled = (this.params.data as Toggable)?.getToggleState();
	}

	public refresh(): boolean {
		return false;
	}

	public executeAction(): void {
		console.log('toggle action here');
	}
}
