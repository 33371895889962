import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-trial-dialog',
	templateUrl: './trial-dialog.component.html',
	styleUrls: ['./trial-dialog.component.scss']
})
export class TrialDialogComponent implements OnInit {
	public days: string;
	public hasPaymentCard: boolean;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<TrialDialogComponent>) {}

	public ngOnInit(): void {
		this.days = this.data.days;
		this.hasPaymentCard = this.data.hasPaymentCard;
	}

	public close(): void {
		this.dialogRef.close();
	}
}
