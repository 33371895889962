import { RouterModule, Routes } from '@angular/router';
import { SmartCreateCampaignSelect } from './components/smart-create-campaign-select/smart-create-campaign-select.component';
import { NgModule } from '@angular/core';
import { SelectCampaignResolver } from './resolver/select-campaign.resolver';
import { CampaignWelcomePageComponent } from './components/campaign-welcome-page/campaign-welcome-page.component';
import { BuildCampaignTemplateComponent } from './components/build-campaign-template/build-campaign-template.component';
import { CreateCampaignResolver } from './resolver/create-campaign.resolver';
import { AdSetSettingsComponent } from './components/ad-set-settings/ad-set-settings.component';
import { AdSetSettingsResolver } from './resolver/ad-set-settings.resolver';
import { CreateAdComponent } from './components/create-ad/create-ad.component';
import { CreateAdResolver } from './resolver/create-ad.resolver';
import { OptimizationResolver } from './resolver/optimization.resolver';
import { OptimizationComponent } from './components/optimization/optimization.component';
import { StepThreeGuard } from './guards/step-three.guard';
import { StepFourGuard } from './guards/step-four.guard';
import { PublishingTreeStructureComponent } from './components/publishing-trees-tructure/publishing-tree-structure.component';
import { AbTestingComponent } from './components/ab-testing/ab-testing.component';
import { CampaignBuilderIntroComponent } from './components/campaign-builder-intro/campaign-builder-intro.component';

const routes: Routes = [
	{ path: '', component: CampaignBuilderIntroComponent },
	{ path: 'intro', component: CampaignBuilderIntroComponent },
	{ path: 'welcome', component: CampaignWelcomePageComponent },
	{ path: 'select-campaign', component: SmartCreateCampaignSelect, resolve: { store: SelectCampaignResolver } },
	{
		path: 'build-campaign/1/:campaignId',
		component: BuildCampaignTemplateComponent,
		resolve: { store: CreateCampaignResolver }
	},
	{ path: 'build-campaign/1', component: BuildCampaignTemplateComponent, resolve: { store: CreateCampaignResolver } },
	{ path: 'build-campaign/2/:campaignId', component: AdSetSettingsComponent, resolve: { store: AdSetSettingsResolver } },
	{ path: 'build-campaign/3/:campaignId', component: CreateAdComponent, resolve: { store: CreateAdResolver }, canActivate: [StepThreeGuard] },
	{ path: 'build-campaign/4/:campaignId', component: OptimizationComponent, resolve: { store: OptimizationResolver }, canActivate: [StepFourGuard] },
	{ path: 'build-campaign/5/:campaignId', component: AbTestingComponent, resolve: { store: OptimizationResolver }, canActivate: [StepFourGuard] },
	{
		path: 'build-campaign/6/:campaignId',
		component: PublishingTreeStructureComponent,
		resolve: { store: OptimizationResolver },
		canActivate: [StepFourGuard]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CampaignSmartCreateRoutingModule {}
