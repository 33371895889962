<div class="modalContainer">
	<div class="header">
		<i class="k-icon k-i-close close" (click)="closeDialog()"></i>
	</div>
	<div class="body">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div class="title">{{ modelDetail.title }}</div>
		<div class="text">
			{{ modelDetail.text }}
		</div>
		<div *ngIf="modelDetail.useOtp" class="otp" [class.for4Chars]="modelDetail.otp.count === 6">
			<app-opt
				[isCodeHidden]="modelDetail.otp.secret ? true : false"
				[codeLength]="modelDetail.otp.count"
				(codeChanged)="onCodeChanged($event)"
				(codeCompleted)="onCodeCompleted($event)"
			></app-opt>
		</div>
	</div>
	<div class="footer">
		<btn04 *ngIf="modelDetail.closeBtn && !modelDetail.useOtp" (onClick)="resendOTP()">CANCEL</btn04>
		<btn04 (onClick)="resendOTP()" *ngIf="modelDetail.useOtp" [disabled]="showResend">
			RESEND
			<span *ngIf="showResend">({{ leadingZero(seconds) }})</span>
		</btn04>
		<btn01 class="btn" (onClick)="actionButtonClick()" *ngIf="modelDetail.actionBtn">{{ modelDetail.actionBtnText }}</btn01>
	</div>
</div>
