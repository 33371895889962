import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	CampaignCommonActionsType,
	LoadAdvertisableApp,
	LoadAdvertisableAppSuccess,
	LoadCampaignCatalogs,
	LoadCampaignCatalogsFailure,
	LoadCampaignCatalogsSuccess,
	LoadCreativeBuilderSingleImage,
	LoadCreativeBuilderSingleImageSuccess,
	LoadExistingImages,
	LoadExistingImagesFailure,
	LoadExistingImagesSuccess,
	LoadExistingVideos,
	LoadExistingVideosFailure,
	LoadExistingVideosSuccess,
	LoadFacebookPages,
	LoadFacebookPageSuccess,
	LoadUserImages,
	LoadUserImagesFailure,
	LoadUserImagesSuccess,
	LoadUserVideos,
	LoadUserVideosFailure,
	LoadUserVideosSuccess,
	LoadFacebookPixels,
	LoadFacebookPixelsSuccess,
	LoadProductCatalog,
	LoadProductCatalogSuccess
} from './campaign-common-actions';
import { catchError, exhaustMap, filter, map, mergeMap, skipWhile, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AssetService } from '../../shared/services/assets/asset.service';
import { CampaignSmartCreateService } from '../../campaign-smart-create/services/campaign-smart-create.service';
import { CampaignSmartCreatePythonService } from '../../campaign-smart-create/services/campaign-smart-create-python.service';
import { CampaignSmartCreateMapperService } from '../../campaign-smart-create/services/campaign-smart-create-mapper.service';
import {
	CampaignCommonState,
	getAdvertisableApps,
	getCatalogs,
	getCreativeSingleImages,
	getExistingImages,
	getExistingVideos,
	getFacebookPages,
	getUserImages,
	getUserVideos,
	getFacebookPixels,
	selectProductCatalog
} from './campaign-common-reducer';
import { AssetType } from '../../shared/services/assets/models/asset-type.enum';
import { PageService } from '../../_services/facebook-accounts/page.service';
import { FiledLibMapperService } from '../../shared/services/filed-lib-Mapper.service';
import { getSelectedAdAccount, SharedState } from '../../shared/state/shared.reducer';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { PixelServiceApi } from 'src/app/_services/pixel/pixel.api.service';
import { MapperService } from 'src/app/shared/services/mapper.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { getFacebookBusinessOwnerId, getUserDetails } from '../../shared/state/user/user.reducer';

@Injectable()
export class CampaignCommonEffect {
	constructor(
		private actions: Actions,
		private assetService: AssetService,
		private pagesService: PageService,
		private pixelServiceApi: PixelServiceApi,
		private mapperService: FiledLibMapperService,
		private dataMapperService: MapperService,
		private smartCreateService: CampaignSmartCreateService,
		private smartCreatePythonService: CampaignSmartCreatePythonService,
		private campaignMappingService: CampaignSmartCreateMapperService,
		private store: Store<CampaignCommonState>,
		private sharedStore: Store<SharedState>,
		private toastNotificationService: ToastNotificationService
	) {}

	// public loadCatalogs$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadCampaignCatalogs>(CampaignCommonActionsType.LoadCatalogs),
	// 		withLatestFrom(this.store.pipe(select(getCatalogs))),
	// 		exhaustMap(([action, latest]) => {
	// 			if (!latest) {
	// 				return this.smartCreatePythonService.getCatalogs().pipe(
	// 					map(catalogs => {
	// 						return new LoadCampaignCatalogsSuccess(catalogs);
	// 					}),
	// 					catchError(err => {
	// 						return of(new LoadCampaignCatalogsFailure());
	// 					})
	// 				);
	// 			} else {
	// 				return of(new LoadCampaignCatalogsSuccess(latest));
	// 			}
	// 		})
	// 	)
	// );
	public loadUserImages$ = createEffect(() =>
		this.actions.pipe(
			ofType<LoadUserImages>(CampaignCommonActionsType.LoadUserImages),
			withLatestFrom(this.store.pipe(select(getUserImages))),
			exhaustMap(([action, images]) => {
				if (action.payload || !images) {
					return this.assetService.getUserAssets(AssetType.Image).pipe(
						map(assets => {
							const mappedAssets = this.campaignMappingService.mapAssetCollectionToAssetPickerModel(assets);
							return new LoadUserImagesSuccess(mappedAssets);
						}),
						catchError(() => of(new LoadUserImagesFailure()))
					);
				} else {
					return of(new LoadUserImagesSuccess(images));
				}
			})
		)
	);

	public loadUserVideos$ = createEffect(() =>
		this.actions.pipe(
			ofType<LoadUserVideos>(CampaignCommonActionsType.LoadUserVideos),
			withLatestFrom(this.store.pipe(select(getUserVideos))),
			exhaustMap(([action, videos]) => {
				if (action.payload || !videos) {
					return this.assetService.getUserAssets(AssetType.Video).pipe(
						map(assets => {
							const mappedAssets = this.campaignMappingService.mapAssetCollectionToAssetPickerModel(assets);
							return new LoadUserVideosSuccess(mappedAssets);
						}),
						catchError(() => of(new LoadUserVideosFailure()))
					);
				} else {
					return of(new LoadUserVideosSuccess(videos));
				}
			})
		)
	);

	// public loadExistingImages$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadExistingImages>(CampaignCommonActionsType.LoadExistingImages),
	// 		withLatestFrom(this.store.pipe(select(getExistingImages))),
	// 		filter(([action, images]) => action && !images),
	// 		map(([action, images]) => action.payload),
	// 		exhaustMap(payload => {
	// 			return this.assetService.getAssetExistingImages(payload.adAccountId, payload.businessOwnerId).pipe(
	// 				map(assets => {
	// 					const mappedAssets = this.campaignMappingService.mapFacebookAssetToAssetPickerModel(assets);
	// 					return new LoadExistingImagesSuccess(mappedAssets);
	// 				}),
	// 				catchError(() => of(new LoadExistingImagesFailure()))
	// 			);
	// 		})
	// 	)
	// );

	// public loadExistingVideos$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadExistingVideos>(CampaignCommonActionsType.LoadExistingVideos),
	// 		withLatestFrom(this.store.pipe(select(getExistingVideos))),
	// 		filter(([action, images]) => !images),
	// 		map(([action, images]) => action.payload),
	// 		exhaustMap(payload => {
	// 			return this.assetService.getAssetExistingVideos(payload.adAccountId, payload.businessOwnerId, payload.filterInstagram ?? false).pipe(
	// 				map(assets => {
	// 					const mappedAssets = this.campaignMappingService.mapFacebookAssetToAssetPickerModel(assets);
	// 					return new LoadExistingVideosSuccess(mappedAssets);
	// 				}),
	// 				catchError(() => of(new LoadExistingVideosFailure()))
	// 			);
	// 		})
	// 	)
	// );

	public loadCreativeSingleImage = createEffect(() =>
		this.actions.pipe(
			ofType<LoadCreativeBuilderSingleImage>(CampaignCommonActionsType.LoadCreativeBuilderSingleImage),
			withLatestFrom(this.store.pipe(select(getCreativeSingleImages))),
			exhaustMap(([action, latest]) => {
				return this.smartCreateService.getCreativeSingleImage().pipe(
					map(res => {
						return new LoadCreativeBuilderSingleImageSuccess(res);
					}),
					catchError(err => {
						return EMPTY;
					})
				);
			})
		)
	);
	// public getActiveFacePages = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadFacebookPages>(CampaignCommonActionsType.LoadFacebookPages),
	// 		withLatestFrom(this.store.pipe(select(getFacebookPages))),
	// 		exhaustMap(([action, facebookPages]) => {
	// 			if (!facebookPages) {
	// 				return this.store.pipe(
	// 					select(getUserDetails),
	// 					skipWhile(account => !account),
	// 					take(1),
	// 					exhaustMap(facebookBusinessId => {
	// 						if (facebookBusinessId) {
	// 							return this.pagesService.getPages(facebookBusinessId.FacebookBusinessOwnerId).pipe(
	// 								map(pages => {
	// 									const mappedPages = this.mapperService.convertToDropdownData(pages, 'id', 'name');
	// 									return new LoadFacebookPageSuccess(mappedPages);
	// 								}),
	// 								catchError(() => {
	// 									return EMPTY;
	// 								})
	// 							);
	// 						}
	// 						return EMPTY;
	// 					})
	// 				);
	// 			} else {
	// 				return of(new LoadFacebookPageSuccess(facebookPages));
	// 			}
	// 		})
	// 	)
	// );

	// public getAdvertisableApps = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadAdvertisableApp>(CampaignCommonActionsType.LoadAdvertisableApp),
	// 		withLatestFrom(this.store.pipe(select(getAdvertisableApps))),
	// 		exhaustMap(([action, advertisableApps]) => {
	// 			if (!advertisableApps) {
	// 				return this.store.pipe(
	// 					select(getSelectedAdAccount),
	// 					take(1),
	// 					exhaustMap(selectedAccount => {
	// 						return this.smartCreateService.getAdvertisableApps(selectedAccount.adAccount).pipe(
	// 							map(advertisableApp => {
	// 								const mappedPages = this.mapperService.convertToDropdownData(advertisableApp, 'appId', 'name');
	// 								return new LoadAdvertisableAppSuccess(mappedPages);
	// 							}),
	// 							catchError(() => {
	// 								return EMPTY;
	// 							})
	// 						);
	// 					})
	// 				);
	// 			} else {
	// 				return of(new LoadAdvertisableAppSuccess(advertisableApps));
	// 			}
	// 		})
	// 	)
	// );

	// public getAccountPixels$ = createEffect(() => {
	// 	return this.actions.pipe(
	// 		ofType<LoadFacebookPixels>(CampaignCommonActionsType.LoadFacebookPixels),
	// 		withLatestFrom(this.store.pipe(select(getFacebookPixels))),
	// 		exhaustMap(([action, facebookPixels]) => {
	// 			if (facebookPixels && facebookPixels?.length > 0) {
	// 				return of(new LoadFacebookPixelsSuccess(facebookPixels));
	// 			}
	// 			return this.store.pipe(
	// 				select(getSelectedAdAccount),
	// 				take(1),
	// 				exhaustMap(selectedAccount => {
	// 					return this.pixelServiceApi.getPixelEventsForAdAccount(parseInt(selectedAccount.adAccount.id.split('_')[1])).pipe(
	// 						map(pixels => {
	// 							const mappedPages = this.dataMapperService.convertToDropdownData(pixels, 'id', 'name');
	// 							return new LoadFacebookPixelsSuccess(mappedPages);
	// 						}),
	// 						catchError(() => {
	// 							return EMPTY;
	// 						})
	// 					);
	// 				})
	// 			);
	// 		})
	// 	);
	// });

	// public LoadProductCatalog$ = createEffect(() => {
	// 	return this.actions.pipe(
	// 		ofType<LoadProductCatalog>(CampaignCommonActionsType.LoadProductCatalog),
	// 		withLatestFrom(this.store.pipe(select(selectProductCatalog))),
	// 		switchMap(([action, productCatalogs]) => {
	// 			if (!productCatalogs) {
	// 				return this.store.pipe(
	// 					select(getSelectedAdAccount),
	// 					take(3),
	// 					exhaustMap(selectedAccount => {
	// 						return this.smartCreateService.getCatalogs(selectedAccount?.adAccount?.id).pipe(
	// 							map(res => {
	// 								return new LoadProductCatalogSuccess(res);
	// 							}),
	// 							catchError(() => {
	// 								return EMPTY;
	// 							})
	// 						);
	// 					})
	// 				);
	// 			} else {
	// 				return of(new LoadProductCatalogSuccess(productCatalogs));
	// 			}
	// 		})
	// 	);
	// });
}
