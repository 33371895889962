import { Component } from '@angular/core';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-primary-method',
	templateUrl: './confirm-primary-method.component.html',
	styleUrls: ['./confirm-primary-method.component.scss']
})
export class ConfirmPrimaryMethodComponent {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor(private dialogRef: MatDialogRef<ConfirmPrimaryMethodComponent>) {}

	public close(): void {
		this.dialogRef.close({ data: false });
	}

	public send(): void {
		this.dialogRef.close({ data: true });
	}
}
