export enum FacebookActionIdentifier {
	InlineEditCampaign = 'inlineEditCampaign',
	InlineEditAdset = 'inlineEditAdset',
	InlineEditAd = 'inlineEditAd',
	InlineEditBudget = 'inlineEditBudget',
	InlineEditSchedule = 'inlineEditSchedule',

	InlineStateToggle = 'inlineStateToggle',

	RedirectAdsManagerParent = 'redirectAdsManagerParent',

	SingleStructureActionDuplicate = 'singleStructureActionDuplicate',
	SingleStructureActionStateToggle = 'singleStructureActionStateToggle',
	SingleStructureActionRedirectOptimization = 'singleStructureActionRedirectOptimization',
	SingleStructureActionEdit = 'singleStructureActionEdit',
	SingleStructureActionDelete = 'singleStructureActionDelete',

	MultipleStructureActionDelete = 'multipleStructureActionDelete',
	MultipleStructureActionToggleOff = 'multipleStructureActionToggleOff',
	MultipleStructureActionToggleOn = 'multipleStructureActionToggleOn'
}
