<app-expansion-panel-sc
	*ngIf="adFormat"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[isExpandable]="false"
	[isExpanded]="true"
	[expansionTitle]="'Ad Creation'"
	[contentMargin]="false"
	[infoHoverModel]="info"
>
	<div expansion-content class="ac-container">
		<div
			class="ac-button"
			*ngIf="
				adFormat === adFormatEnum.SingleImage ||
				adFormat === adFormatEnum.Video ||
				adFormat === adFormatEnum.PagePosts ||
				adFormat === adFormatEnum.multipleSingleImage ||
				subObjective === subObjectives.CatalogSalesText ||
				subObjective === subObjectives.WebsiteConversion
			"
		>
			<div *ngIf="subObjective !== subObjectives.AppInstallText && subObjective !== subObjectives.CatalogSalesText">
				<btn04 [disabled]="!dialogContracts?.get(adFormat)" (onClick)="openDialog()">
					{{ buttonLabel }}
				</btn04>
			</div>

			<div *ngIf="subObjective === subObjectives.AppInstallText">
				<div class="horizontal-image-asset">
					<button class="addition__btn fadeIn" (click)="openDialog()" *ngIf="assetUrls.length < 5"><span>+</span></button>

					<ul class="navigation-list">
						<li class="navigation-list__no-assets" *ngIf="!assetUrls.length">
							<span>Add Image</span>
						</li>

						<li class="navigation-list__item" [hidden]="!assetUrls.length" *ngFor="let ad of assetUrls; let i = index">
							<img [src]="ad" alt="selected-image" class="navigation-list__img" />
							<button class="close-btns" (click)="removeMediaUrl(i)">
								<img src="assets/icons/aaa/close-icon-sm.svg" alt="close icon" />
							</button>
						</li>
					</ul>
				</div>
			</div>
			<div
				class="ac-header"
				*ngIf="selectedAssets.length > 0 && subObjective !== subObjectives.CatalogSalesText && subObjective !== subObjectives.AppInstallText"
			>
				<div class="ac-image-container" [hidden]="!selectedAssets.length || !selectedAssets[0].url">
					<a><i class="fas fa-times close" (click)="removeAsset(selectedAssets[0])"></i></a>
					<img
						*ngIf="adFormat === adFormatEnum.SingleImage || adFormat === adFormatEnum.PagePosts"
						class="ac-uploaded-image"
						[src]="selectedAssets[0]?.url"
						alt="upload-image"
					/>
					<img *ngIf="adFormat === adFormatEnum.Video" class="ac-uploaded-image" [src]="selectedAssets[0]?.url" alt="upload-image" />
				</div>
			</div>

			<div
				class="ac-preview-inputs-container"
				*ngIf="(selectedAssets.length && selectedAssets[0].url) || subObjective === subObjectives.CatalogSalesText"
			>
				<app-ad-creation-input-form
					[isPageLikesSelected]="isPageLikesSelected"
					[isPostLikesSelected]="isPostLikesSelected"
					[isWebsiteUrl]="isWebsiteUrl"
					[adFormat]="adFormat"
					[adCreateFormGroup]="adCreateFormGroup"
					[formGroup]="adCreateFormGroup"
					[callToActions]="callToActions"
					[cardIndex]="selectedCardIndex"
					[subObjective]="subObjective"
					[itemCount]="assetUrls.length"
				></app-ad-creation-input-form>
			</div>
		</div>

		<div *ngIf="adFormat === adFormatEnum.Carousel">
			<div class="ac-carousel-input-header">
				<h6 class="ac-bold">Ad Navigation</h6>
				<div class="ac-buttons-and-asset">
					<div class="ac-carousel-cards-slider">
						<h6
							class="ac-button"
							[ngClass]="{ 'ac-selected-carousel-card': selectedCardIndex === i }"
							*ngFor="let carousel of getCarouselCards().controls; let i = index"
							(click)="carouselCardChange(i)"
						>
							{{ i + 1 }}
							<a class="ac-x-remove" *ngIf="getCarouselCards().controls.length > 3" (click)="removeCard(i)">x</a>
						</h6>
						<h6 class="ac-plus" *ngIf="getCarouselCards().controls.length <= 9" (click)="onAddCarouselCard()">+</h6>
					</div>
					<h6 class="ac-bold">Ad Media & Info</h6>
					<div class="ac-carousel-buttons">
						<app-button
							[disabled]="!dialogContracts?.get(adFormatEnum.SingleImage)"
							[waiting]="!dialogContracts?.get(adFormatEnum.SingleImage)"
							(buttonClicked)="openDialog(adFormatEnum.SingleImage)"
							[buttonLabel]="buttonStatusName + ' ' + 'Image'"
							[buttonClass]="buttonClass.Tertiary"
							[buttonType]="buttonType.Simple"
						></app-button>
						<app-button
							[disabled]="!dialogContracts?.get(adFormatEnum.Video)"
							[waiting]="!dialogContracts?.get(adFormatEnum.Video)"
							(buttonClicked)="openDialog(adFormatEnum.Video)"
							[buttonLabel]="buttonStatusName + ' ' + 'Video'"
							[buttonClass]="buttonClass.Tertiary"
							[buttonType]="buttonType.Simple"
						></app-button>
					</div>

					<div class="ac-header" *ngIf="selectedAssets.length">
						<div class="ac-image-container" *ngIf="selectedAssets[selectedCardIndex]">
							<a><i class="fas fa-times close" (click)="removeAsset(selectedAssets[selectedCardIndex])"></i></a>
							<img
								*ngIf="!selectedAssets[selectedCardIndex]?.picture"
								class="ac-uploaded-image"
								[src]="selectedAssets[selectedCardIndex]?.url"
								alt="upload-image"
							/>
							<img
								*ngIf="selectedAssets[selectedCardIndex]?.picture"
								class="ac-uploaded-image"
								[src]="selectedAssets[selectedCardIndex]?.picture"
								alt="upload-image"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="ac-preview-inputs-container" *ngIf="selectedAssets.length">
				<app-ad-creation-input-form
					[formGroup]="adCreateFormGroup"
					[callToActions]="callToActions"
					[cardIndex]="selectedCardIndex"
					[subObjective]="subObjective"
				></app-ad-creation-input-form>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
