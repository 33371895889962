import { CatalogInterface } from '../../campaign-smart-create/catalogs/catalog.interface';
import { Asset } from '../../shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';
import { CampaignCommonAction, CampaignCommonActionsType, LoadAdvertisableApp } from './campaign-common-actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import { DropdownInterface } from '@filed-com/filed-lib';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';

export interface CampaignCommonState {
	catalogs: CatalogInterface;
	userImages: Asset[];
	userVideos: Asset[];
	existingImages: Asset[];
	existingVideos: Asset[];
	creativeSingleImage: any;
	accountFacebookPages: DropdownInterface[];
	accountAdvertiseApp: DropdownInterface[];
	accountPixels: DropdownData[];
	productCatalogs: unknown;
}
export const initialCampaignCommonState: CampaignCommonState = {
	catalogs: null,
	userImages: null,
	userVideos: null,
	existingImages: null,
	existingVideos: null,
	creativeSingleImage: null,
	accountFacebookPages: null,
	accountAdvertiseApp: null,
	accountPixels: null,
	productCatalogs: null
};
export function CampaignCommonReducer(state: CampaignCommonState = initialCampaignCommonState, action: CampaignCommonAction): CampaignCommonState {
	switch (action.type) {
		case CampaignCommonActionsType.LoadCatalogsSuccess: {
			return {
				...state,
				catalogs: action.payload
			};
		}
		case CampaignCommonActionsType.LoadUserImagesSuccess: {
			return {
				...state,
				userImages: action.payload
			};
		}
		case CampaignCommonActionsType.LoadUserVideosSuccess: {
			return {
				...state,
				userVideos: action.payload
			};
		}
		case CampaignCommonActionsType.LoadExistingImagesSuccess: {
			return {
				...state,
				existingImages: action.payload
			};
		}
		case CampaignCommonActionsType.LoadExistingVideosSuccess: {
			return {
				...state,
				existingVideos: action.payload
			};
		}
		case CampaignCommonActionsType.LoadCreativeBuilderSingleImageSuccess: {
			return {
				...state,
				creativeSingleImage: action.payload
			};
		}
		case CampaignCommonActionsType.LoadFacebookPageSuccess: {
			return {
				...state,
				accountFacebookPages: action.payload
			};
		}
		case CampaignCommonActionsType.ClearFacebookPage: {
			return {
				...state,
				accountFacebookPages: null
			};
		}
		case CampaignCommonActionsType.LoadFacebookPixelsSuccess: {
			return {
				...state,
				accountPixels: action.payload
			};
		}
		case CampaignCommonActionsType.ClearFacebookPixels: {
			return {
				...state,
				accountPixels: null
			};
		}
		case CampaignCommonActionsType.LoadAdvertisableAppSuccess: {
			return {
				...state,
				accountAdvertiseApp: action.payload
			};
		}
		case CampaignCommonActionsType.LoadProductCatalogSuccess: {
			return {
				...state,
				productCatalogs: action.payload
			};
		}
		default: {
			return state;
		}
	}
}
const getCampaignCommonSelector = createFeatureSelector<CampaignCommonState>('campaignShared');

// Catalogs
export const getCatalogs = createSelector(getCampaignCommonSelector, state => _.cloneDeep(state.catalogs));
export const getObjectivesWithDestinationFromCatalog = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.ObjectiveWithDestinationGroup));
export const getBidStrategiesFromCatalog = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.BidStrategy));
export const getSpecialAdCategoriesFromCatalog = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.SpecialAdCategories));
export const getOptimizationGoal = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.OptimizationGoal));
export const getBillingEvents = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.BillingEvent));
export const getClicks = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.ActionAttributionWindowClick));
export const getViews = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.ActionAttributionWindowView));
export const getPlacementsFromCatalog = createSelector(getCatalogs, catalogs => {
	return catalogs?.Placement;
});
export const getTemplateFieldsFromCatalog = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.TemplateFields));
export const getRetargetingOptionsFromCatalog = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.RetargetingOptions));
export const getAdFormatFromCatalog = createSelector(getCatalogs, catalogs => {
	return catalogs.AdFormat;
});

// Validations

export const getValidations = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.validations));

export const getPlatforms = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.Platform));

export const getAdPreviewFormat = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.AdFormat));

export const getCallToAction = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.CallToActionType));

// assets
export const getUserImages = createSelector(getCampaignCommonSelector, state => _.cloneDeep(state.userImages));
export const getUserVideos = createSelector(getCampaignCommonSelector, state => _.cloneDeep(state.userVideos));
export const getExistingImages = createSelector(getCampaignCommonSelector, state => state.existingImages);
export const getExistingVideos = createSelector(getCampaignCommonSelector, state => state.existingVideos);
export const getImageAssets = createSelector(getUserImages, getExistingImages, (userImages, existingImages) => {
	if (!userImages && !existingImages) {
		return null;
	}
	return { userImages: userImages, existingImages: existingImages };
});
export const getVideoAssets = createSelector(getUserVideos, getExistingVideos, (userVideos, existingVideos) => {
	if (!userVideos && !existingVideos) {
		return null;
	}
	return { userVideos: userVideos, existingVideos: existingVideos };
});
export const getCreativeSingleImages = createSelector(getCampaignCommonSelector, state => state.creativeSingleImage);
export const getFacebookPages = createSelector(getCampaignCommonSelector, state => state.accountFacebookPages);
export const getAdvertisableApps = createSelector(getCampaignCommonSelector, state => state.accountAdvertiseApp);
export const getFacebookPixels = createSelector(getCampaignCommonSelector, state => state.accountPixels);
export const selectProductCatalog = createSelector(getCampaignCommonSelector, state => state.productCatalogs);
