import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { IKBSearchResultData } from 'src/app/shared/models/knowledgebase.interface';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';

@Component({
	selector: 'app-knowledge-sub',
	templateUrl: './knowledge-sub.component.html',
	styleUrls: ['./knowledge-sub.component.scss']
})
export class KnowledgeSubComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

	public subArticlesData: IKBSearchResultData[];

	constructor(private matDialog: MatDialog, public router: Router, public kbService: KnowledgebaseService) {}

	ngOnInit(): void {
		this.subArticlesData = this.kbService.articleDataValue$.value.sort((a, b) => {
			return a.id - b.id;
		});
	}

	public showFrame(index: number): void {
		this.kbService.knowledgebaseShowView$.next(false);
		this.kbService.knowledgebaseShowSub$.next(false);
		this.kbService.knowledgebaseShowFrame$.next(true);
		this.kbService.frameIndexValue$.next(index);
	}

	public shortenDescription(description: string): string {
		const strTake = description.length <= 80 ? description : `${description.substring(0, 80)}...`;
		return strTake;
	}
}
