import { CarouselDto, SingleAdvertDto, MultipleSingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { DynamicAds } from 'src/app/campaign-smart-create/models/dynamic-ads.enum';

export class AdModel {
	public adId?: string = null;
	public adName: string = 'File ads 1';
	public dynamicAds: DynamicAds = DynamicAds.Off;
	public adFormatType: AdFormatsEnum = AdFormatsEnum.SingleImage;
	public adverts: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto = new SingleAdvertDto();
	public toggle: boolean = false;
	public pixelId: string = '';
	public eventRule: Object;
	public pixelAppEventId: string = '';
	public conversionId: string = '';
	public customEventType?: string = '';
	public pixelRule?: Object;
	public facebookPageId?: string = '';
	public instagramPageId?: string = '';
}
