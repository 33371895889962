import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalInterface } from 'src/app/user-management/models/DatatableEnum';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-complete-modal',
	templateUrl: './complete-modal.component.html',
	styleUrls: ['./complete-modal.component.scss']
})
export class CompleteModalComponent implements OnInit, OnChanges {
	@Input() public modelDetail: ModalInterface;
	constructor(private dialogRef: MatDialogRef<CompleteModalComponent>) {}

	ngOnInit(): void {}

	ngOnChanges(): void {}

	public closeDialog(): void {
		this.dialogRef.close(false);
	}

	public actionButtonClick(): void {
		this.dialogRef.close();
	}
}
