import { AfterViewInit, Component, OnInit, OnChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { GoogleService } from 'src/app/_services/google/google.service';
import { IntegrationHelper } from '../../services/integration-helper';

@Component({
	selector: 'app-close-popup',
	templateUrl: './close-popup.component.html',
	styleUrls: ['./close-popup.component.scss']
})
export class ClosePopupComponent implements OnInit {
	constructor(private integrationHelper: IntegrationHelper, private googleService?: GoogleService) {}

	public ngOnInit(): void {
		this.googleService
			.checkGoogleStatus()
			.pipe(take(1))
			.subscribe(res => {
				if (res.filed_business_owner_id) {
					if (!!localStorage.getItem(StorageKey.installedPlatorm)) {
						let installedItem: string[] = JSON.parse(localStorage.getItem(StorageKey.installedPlatorm));
						if (!installedItem.includes('google')) {
							localStorage.setItem(StorageKey.installedPlatorm, JSON.stringify(installedItem.push('Google')));
						}
					} else {
						localStorage.setItem(StorageKey.installedPlatorm, JSON.stringify(['Google']));
					}
				}
			});
	}

	public nextcontent(): void {
		window.close();
	}
}
