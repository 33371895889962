import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { CardBluesnapInterface, CardDetailsInterface } from '../../sign-up/subscribe.interface';
declare var bluesnap: any;

@Component({
	selector: 'app-retry-payment',
	templateUrl: './retry-payment.component.html',
	styleUrls: ['./retry-payment.component.scss']
})
export class RetryPaymentComponent implements OnInit {
	public submitting: boolean;
	public planDetails: any;
	public cardLists: CardBluesnapInterface[] = [];
	public defaultCard: CardBluesnapInterface;
	public paymentModeSelected: boolean = false;
	public paymentWallType: string;
	public PfToken: string;
	public biliingId: string;
	public contactId: string;
	public threeDSecureReferenceId: string;
	public hasUserAgreed: boolean = false;
	public payNewCard: boolean = false;
	public paid: boolean = false;
	public cardDetails: FormGroup;
	public title: string = 'The transaction has been completed!';
	public subtitle: string = 'You have been sent an email confirmation for the payment.';
	public countries: Country[];
	public countryData: any[];

	private unsubscriber$: Subject<void> = new Subject<void>();
	protected sharedStore: Store<SharedState>;
	constructor(
		private injector: Injector,
		private billingService: PaymentServiceApi,
		public activeRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		public backOfficeService: BackOfficeService,
		private toastService: ToastNotificationService,
		private router: Router
	) {
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
	}

	ngOnInit(): void {
		this.getRouteParam();
		this.loadScript();
		this.createForm();
	}

	private loadScript() {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = BaseApiUrl.BlueSnap;
		const head = document.getElementsByTagName('head')[0];
		if (head !== null && head !== undefined) {
			document.head.appendChild(script);
		}
	}

	private setDropdownCountries(): void {
		this.backOfficeService
			.getAllCountriesList()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(result => {
				this.countries = result;
				this.countryData = this.countries.map((value, index) => {
					return {
						value: value,
						label: value.name,
						id: index
					};
				});
			});
	}

	public createForm(): void {
		this.cardDetails = this.formBuilder.group({
			cardName: new FormControl(''),
			cardNumber: new FormControl('', Validators.required),
			securityCode: new FormControl('', Validators.required),
			expirationDate: new FormControl('', Validators.required),
			firstAddress: new FormControl(''),
			secondAddress: new FormControl(''),
			city: new FormControl(''),
			zipCode: new FormControl(''),
			country: new FormControl(''),
			agreedToPrivacy: new FormControl(false, Validators.required)
		});
	}

	public getRouteParam(): void {
		this.activeRoute.queryParams.pipe(take(1)).subscribe(params => {
			if (params['b'] != undefined) {
				this.biliingId = params['b'];
				this.contactId = params['c'];
				this.getBillingDetails();
			}
		});
	}

	public getBillingDetails(): void {
		this.billingService.getRetryBillingDetails(this.biliingId, this.contactId).subscribe(res => {
			this.planDetails = res;
			if (this.planDetails.externalTransactionId) {
				this.paid = true;
			}
		});
	}

	public selectMode(mode: string): void {
		this.getPfToken();
		this.paymentWallType = mode;
	}

	public deselectMode(): void {
		this.paymentModeSelected = false;
		this.paymentWallType = null;
		this.onMarkDefault(false);
		this.cardLists = [];
		this.cardDetails.reset();
	}

	public changeDefaultPaymentCard(card: CardBluesnapInterface): void {
		this.defaultCard = card;
	}

	public addnewCard(): void {
		this.payNewCard = true;
		setTimeout(() => {
			this.onReady();
		}, 1000);
	}

	public getPfToken(): void {
		this.billingService.getRetryPfToken(this.biliingId, this.contactId).subscribe(res => {
			this.PfToken = res;
			if (this.paymentWallType == 'BlueSnap') {
				this.getCardList();
			} else {
				this.payNewCard = true;
				this.finalSubmit();
			}
		});
	}

	public getCardList(): void {
		this.billingService.getRetryCardLists(this.biliingId, this.contactId).subscribe(res => {
			this.cardLists = res;
			this.paymentModeSelected = true;
			if (this.cardLists.length == 0) {
				this.payNewCard = true;
				this.addnewCard();
			} else {
				this.payNewCard = false;
				this.changeDefaultPaymentCard(this.cardLists[0]);
				this.threeDSSetup();
			}
		});
	}

	// with new card
	public onReady(): void {
		var cardUrl = {
			AMEX: 'https://files.readme.io/97e7acc-Amex.png',
			DINERS: 'https://files.readme.io/8c73810-Diners_Club.png',
			DISCOVER: 'https://files.readme.io/caea86d-Discover.png',
			JCB: 'https://files.readme.io/e076aed-JCB.png',
			MASTERCARD: 'https://files.readme.io/5b7b3de-Mastercard.png',
			VISA: 'https://files.readme.io/9018c4f-Visa.png'
		};
		var bsObj = {
			//insert your Hosted Payment Fields token
			token: this.PfToken,
			onFieldEventHandler: {
				onFocus: function (tagId) {
					// Handle focus
					changeImpactedElement(tagId, 'hosted-field-valid hosted-field-invalid', 'hosted-field-focus');
				},
				onBlur: function (tagId) {
					// Handle blur
					changeImpactedElement(tagId, 'hosted-field-focus', '');
				},
				onError: function (tagId, errorCode, errorDescription) {
					// Handle a change in validation
					changeImpactedElement(tagId, 'hosted-field-valid hosted-field-focus', 'hosted-field-invalid');
					var error: string;
					switch (tagId) {
						case 'ccn':
							error = 'Card Invalid';
							onFormError(tagId);
							break;
						case 'exp':
							error = 'Expiry Date Invalid';
							onFormError(tagId);
							break;
						case 'cvv':
							error = 'CVV Invalid';
							onFormError(tagId);
							break;
					}
					$('#' + tagId + '-help')
						.removeClass('helper-text-green')
						.text(error);
				},
				onType: function (tagId, cardType, cardData) {
					// get card type from cardType and display card image
					$('#card-logo > img').attr('src', cardUrl[cardType]);
				},
				onValid: function (tagId) {
					// Handle a change in validation
					changeImpactedElement(tagId, 'hosted-field-focus hosted-field-invalid', 'hosted-field-valid');
					$('#' + tagId + '-help').text('');
					onFormValid(tagId);
				}
			},
			//styling is optional
			style: {
				// Styling all inputs
				input: {
					'font-size': '14px',
					'font-family': 'Helvetica Neue,Helvetica,Arial,sans-serif',
					'line-height': '1.42857143',
					color: '#555'
				},

				// Styling a specific field
				/*"#ccn": {
								    
								},*/

				// Styling Hosted Payment Field input state
				':focus': {
					color: '#555'
				}
			},
			ccnPlaceHolder: 'Card Number',
			cvvPlaceHolder: 'Security Code (CVV)',
			expPlaceHolder: 'Expiry Date (MM / YY)'
		};
		function changeImpactedElement(tagId, removeClass, addClass) {
			removeClass = removeClass || '';
			addClass = addClass || '';
			$('[data-bluesnap=' + tagId + ']')
				.removeClass(removeClass)
				.addClass(addClass);
		}
		const onFormValid = (tagId): void => {
			switch (tagId) {
				case 'ccn':
					this.cardDetails.get('cardNumber').patchValue(tagId);
					break;
				case 'exp':
					this.cardDetails.get('expirationDate').patchValue(tagId);
					break;
				case 'cvv':
					this.cardDetails.get('securityCode').patchValue(tagId);
					break;
			}
		};
		const onFormError = (tagId): void => {
			switch (tagId) {
				case 'ccn':
					this.cardDetails.get('cardNumber').patchValue(null);
					break;
				case 'exp':
					this.cardDetails.get('expirationDate').patchValue(null);
					break;
				case 'cvv':
					this.cardDetails.get('securityCode').patchValue(null);
					break;
			}
		};
		bluesnap.hostedPaymentFieldsCreate(bsObj);
	}

	// with old card
	public threeDSSetup(): void {
		bluesnap.threeDsPaymentsSetup(this.PfToken, function (sdkResponse) {
			var code = sdkResponse.code;
			if (code == 1) {
				// on Success
				var cardData = sdkResponse.cardData;
				var threeDSecure = sdkResponse.threeDSecure;
				onFormValid(threeDSecure.threeDSecureReferenceId);
				// SUBMIT THE FORM HERE!
			} else {
				onFormError();

				// in case some errors occurred
				var errorsArray = sdkResponse.info.errors;
				// in case some warnings occurred
				var warningsArray = sdkResponse.info.warnings;
			}
		});
		const onFormValid = (refereenceID): void => {
			this.threeDSecureReferenceId = refereenceID;
			this.finalSubmit();
		};
		const onFormError = (): void => {
			this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
		};
	}

	public continueSignup(): void {
		if (this.cardDetails.invalid) {
			if (this.payNewCard) {
				this.toastService.sendErrorToast('Please fill the required fields.');
			} else {
				this.threeDsSubmitData();
			}
		} else {
			if (this.paymentWallType == 'BlueSnap') {
				if (this.payNewCard) {
					this.paywithPftoken();
				} else {
					this.threeDsSubmitData();
				}
			} else if (this.paymentWallType == 'PayPal') {
				this.finalSubmit();
			}
		}
	}

	public paywithPftoken(): void {
		bluesnap.hostedPaymentFieldsSubmitData(callback => {
			if (null != callback.error) {
				this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
			} else {
				this.finalSubmit();
			}
		});
	}

	public threeDsSubmitData(): void {
		var previouslyUsedCard = {
			last4Digits: this.defaultCard.cardLastFourDigits,
			ccType: this.defaultCard.cardType,
			amount: 0.1,
			currency: 'USD'
		};
		bluesnap.threeDsPaymentsSubmitData(previouslyUsedCard);
	}

	public onMarkDefault(isChecked: boolean): void {
		this.hasUserAgreed = isChecked;
		this.cardDetails.controls['agreedToPrivacy'].setValue(isChecked);
	}

	public finalSubmit(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.submitting = true;
		var country = this.cardDetails.get('country').value;
		const card_detail: CardDetailsInterface = {
			invoiceId: atob(this.biliingId),
			ContactId: atob(this.contactId),
			paymentWallType: this.paymentWallType,
			pfToken: this.payNewCard ? this.PfToken : null,
			cardLastFourDigits: this.payNewCard ? null : this.defaultCard.cardLastFourDigits,
			cardType: this.payNewCard ? null : this.defaultCard.cardType,
			threeDSecureReferenceId: this.payNewCard ? null : this.threeDSecureReferenceId
		};
		this.billingService
			.postRetryPayment(card_detail)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				detailResponse => {
					switch (this.paymentWallType) {
						case 'BlueSnap':
							this.router.navigate(['/authentication/retry-payment-confirmation'], { queryParams: { b: this.biliingId, c: this.contactId } });
							break;
						case 'PayPal':
							window.location.href = detailResponse.paypalTransaction.paypalUrl;
							break;
					}
				},
				({ error }) => {
					this.getPfToken();
					this.toastService.sendErrorToast('There appears to be a problem with the payment method you are trying to use.');
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.submitting = false;
				},
				() => {
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public openPrivacyPolicy(): void {
		window.location.href = 'https://www.filed.com/privacy-policy-of-filed/';
	}

	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.cardDetails.controls['country'].setValue(selected);
		}
	}

	public navigateToLunch(): void {
		this.router.navigate(['/authentication/launch']);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
