import { Component, Injector, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { AdFormatSelectionCardComponent } from 'src/app/shared/smart-create-edit-components/step-three-components/ad-format/ad-format-selection-card.component';
import { AAAModuleState } from '../../state';
import { UpdateAdverts } from '../../state/ad-state/ad-state.actions';

@Component({
	selector: 'app-ad-format',
	templateUrl: './ad-format.component.html',
	styleUrls: ['./ad-format.component.scss']
})
export class AdFormatComponent extends AdFormatSelectionCardComponent implements OnInit {
	constructor(injector: Injector, private AAAstore: Store<AAAModuleState>) {
		super(injector);
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	public onAdFormatClick(selection: CatalogNodeInterface): void {
		const key = parseInt(selection.key);
		if (key !== this.selectedFormat) {
			this.AAAstore.dispatch(new UpdateAdverts(null));
		}
		this.selectedFormat = key;
		this.onUpdateFormatType.emit(this.selectedFormat as AdFormatsEnum);
	}
}
