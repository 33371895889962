import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
import { BudgetCardComponent } from 'src/app/shared/smart-create-edit-components/step-two-components/budget-card/budget-card.component';
import { AudienceService } from 'src/app/_services/audience/audience.service';

@Component({
	selector: 'app-budget-and-schedule-card',
	templateUrl: './budget-and-schedule-card.component.html',
	styleUrls: ['./budget-and-schedule-card.component.scss']
})
export class BudgetAndScheduleCardComponent extends BudgetCardComponent implements OnInit {
	@Input() public isCampaignBudgetOptimized = false;
	public info: InfoHoverDataModel = {
		message: `Your ad set budget is the daily or lifetime amount that you want to spend on this ad set.
  A daily budget is the average that you'll spend every day. A lifetime budget is the maximum that you'll spend during the lifetime of your ad set.`
	};
	constructor(private audienceService: AudienceService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
