export enum AAAStepsEnum {
	One,
	Two,
	Three
}

export enum AAAStepNameEnum {
	One = 'add-ad',
	Two = 'create',
	Three = 'ad-preview'
}

export enum FlowOneSteps {
	SelectCampaigns,
	CreateAd,
	ReviewAndPublish
}

export enum FlowTwoSteps {
	SelectCampaigns,
	SelectAd,
	CreateAd,
	ReviewAndPublish
}

export enum FlowThreeSteps {
	SelectCampaigns,
	CreateAdset,
	SelectAd,
	CreateAd,
	ReviewAndPublish
}

export enum FlowFourSteps {
	SelectCampaigns,
	SelectAdset,
	CreateAdset,
	CreateAd,
	ReviewAndPublish
}

export enum AAAFlow {
	createNewAd,
	bestPerformingAd,
	createNewAdset,
	bestPerformingAdset
}

export enum stepName {
	SelectCampaigns = 'SelectCampaigns',
	SelectAdset = 'SelectAdset',
	CreateAdset = 'CreateAdset',
	SelectAd = 'SelectAd',
	CreateAd = 'CreateAd',
	ReviewAndPublish = 'ReviewAndPublish'
}
