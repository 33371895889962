import { WidgetCategoryEnum } from './widget-category-enum';
import { ChartTypeEnum } from '../shared/charts/chart-type.enum';

export class WidgetSelect {
	constructor(
		public displayName: string,
		public widgetType: ChartTypeEnum,
		public chartType: any,
		public category: WidgetCategoryEnum,
		public properties: any,
		public simpleDataSource: boolean,
		public hasDataLabels: boolean,
		public image: string,
		public isSelected: boolean,
		public group: string,
		public customClass: string = '',
		public isWidget: boolean = true
	) {}
}
