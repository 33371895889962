import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InstagramAccount } from '../../campaign-builder/models/instagram-account.model';
import { BaseApiUrl } from '../base-api-urls';
import { InstagramPagesInterface } from '../../shared/smart-create-edit-components/step-two-components/pages-card/instagram-pages.interface';

@Injectable()
export class InstagramService {
	constructor(private http: HttpClient) {}

	public getInstagramAccounts(adAccountId: string): Observable<InstagramAccount[]> {
		return this.http.get<InstagramAccount[]>(`${BaseApiUrl.GoogleTuring}instagram-accounts/${adAccountId}`);
	}

	public getInstagramPages(facebookId: string): Observable<InstagramPagesInterface[]> {
		return this.http.get<InstagramPagesInterface[]>(`${BaseApiUrl.GoogleTuring}page-instagram-account/${facebookId}`);
	}
}
