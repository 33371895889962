import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ColDef, ColumnApi, GridApi, IServerSideGetRowsParams, PaginationChangedEvent, RowNode } from 'ag-grid-community';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AAAModuleState } from 'src/app/AAA/state';
import { AdsManagerToggleResponseInterface } from 'src/app/ads-manager/ads-manager-insights/models/ads-manager-toggle-response.interface';
import { AgGridViewInterface } from '../../../shared/master-table/models/ag-grid-view.interface';
import { MasterTableService } from '../../../shared/master-table/models/master-table.interface';
import { AddColumnsToReqService } from '../../../shared/master-table/services/add-columns-to-req.service';
import { AgGridRequest } from '../../../shared/models/ag-grid-request';
import { BaseApiUrl } from '../../../_services/base-api-urls';
import { UpdateSelectedCampaigns } from '../../state/ads-manager.actions';
import { UpdateSelectedAAACampaigns } from '../../../AAA/state/aaa.actions';
import { AdsManagerState } from '../../state/ads-manager.reducer';
import { AdSetsInterface } from '../models/ad-sets.interface';
import { AdsManagerAgGridServiceInterface } from '../models/ads-manager-ag-grid-service.interface';
import { AdsManagerStatusEnum } from '../models/ads-manager-status.enum';
import { CampaignResponseInterface } from '../models/campaign-response.interface';
import { CampaignInterface } from '../models/campaign.interface';
import { CampaignAAAInterface } from '../../../AAA/models/campaign';
import { TimeRangeInterface } from '../models/time-range.interface';
import { UpdateAdvertisingStructureRequestInterface } from '../models/update-advertising-structure-request.interface';
import { UpdateCampaignInterface } from '../models/update-campaign.interface';
import { UpdateAAACampaignInterface } from '../../../AAA/models/update-aaa-campaign.interface';
import { AdsManagerBaseService } from './ads-manager-base.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedAdAccountModel } from 'src/app/shared/state/state-entities/cached-ad-accounts.model';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';

@Injectable()
export class CampaignsService extends AdsManagerBaseService implements MasterTableService, AdsManagerAgGridServiceInterface {
	public bundleUpdateArray: Array<UpdateCampaignInterface> = [];
	public resetSubject = new Subject<void>();
	public changeTabSubject = new Subject<void>();
	public triggerBundleUpdateSubject = new Subject<void>();
	public isCheckboxDeactivated = true;
	public isSelectionToggledProgramatically = false;
	public lastNextPageCursor: string = null;
	public hasDelivery: boolean = false;
	public selectedCampaignsIds: string[] = [];
	public gridApi: GridApi;
	public columnApi: ColumnApi;
	public timeRange: TimeRangeInterface;
	public selectedAccount: string;
	public selectedAdAccount: SelectedAdAccountModel;
	public totalsRow: Array<Object>;
	public innerStoreAction: boolean = false;
	public pinnedBottomStore: { [key: string]: Array<Object> } = {};
	public filterObjective: string[] = [];
	public filterModel: any = {};

	private triggerCheckCheckboxState = new Subject<void>();
	constructor(
		http: HttpClient,
		public store: Store<AdsManagerState>,
		private addColumnsService: AddColumnsToReqService,
		private aaaStore: Store<AAAModuleState>,
		private router: Router
	) {
		super(http);
	}

	public getViews(): Observable<AgGridViewInterface[]> {
		let url: string;
		if (this.selectedAdAccount?.sourceChannel === SourceChannel.Facebook) {
			url = `${BaseApiUrl.FacebookDataPipeline}`;
		} else {
			url = `${BaseApiUrl.GoogleDataPipeline}`;
		}
		return this.http.get<AgGridViewInterface[]>(`${url}ag-grid-views/campaign`).pipe(
			catchError(err => {
				if (err.status === 400) {
					this.router.routeReuseStrategy.shouldReuseRoute = () => {
						return false;
					};
				}
				return EMPTY;
			})
		);
	}

	public resetRequest(): void {
		this.lastNextPageCursor = null;
		this.resetSubject.next();
	}

	public checkIfNotLastRequest(): boolean {
		return this.lastNextPageCursor !== null;
	}

	public checkBoxStateObservable(): Observable<void> {
		return this.triggerCheckCheckboxState.asObservable();
	}

	public mapRequest(params: IServerSideGetRowsParams, pageSize: number): AgGridRequest {
		const request = this.addColumnsService.addColumns(params);
		this.isCheckboxDeactivated = true;
		request.timeRange = this.timeRange;
		request.facebookAccountId = this.selectedAccount;
		request.pageSize = pageSize;
		request.nextPageCursor = this.lastNextPageCursor;
		request.hasDelivery = this.hasDelivery;
		request.filterObjective = this.filterObjective;
		if (Object.keys(this.filterModel).length) {
			request.filterModel = this.filterModel;
		}
		return request;
	}

	public listenToRefreshData(): Observable<void> {
		return this.resetSubject.asObservable();
	}

	// used for full deselection
	public deselectAll(): void {
		if (this.gridApi) {
			this.isSelectionToggledProgramatically = true;
			this.gridApi.getSelectedNodes().forEach((row: RowNode) => {
				const campaignRow = row.data as CampaignInterface;
				this.bundleUpdateArray.push({
					selectedRow: campaignRow,
					isChosen: false
				});
				row.setSelected(false);
			});
			setTimeout(() => {
				this.isSelectionToggledProgramatically = false;
				this.bundleUpdateArray = [];
				this.store.dispatch(new UpdateSelectedCampaigns(null));
				this.aaaStore.dispatch(new UpdateSelectedAAACampaigns(null));
			});
		}
	}

	public getRows(request: AgGridRequest): Observable<CampaignInterface[]> {
		return this.getCampaignsData(request);
	}

	public getCampaignsData(request: AgGridRequest): Observable<CampaignInterface[]> {
		if (request.startRow === 0) {
			this.pinnedBottomStore = {};
		}
		let url: string;
		if (this.selectedAdAccount?.sourceChannel === SourceChannel.Facebook) {
			url = `${BaseApiUrl.FacebookDataPipeline}ag-grid-insights/campaign`;
		} else {
			request.googleAccountId = '9959328642';
			request.googleManagerId = '5428845364';
			url = `${BaseApiUrl.GoogleDataPipeline}ag-grid-insights/campaign`;
		}
		let arrSize: number = Object.keys(this.pinnedBottomStore).length;
		return this.http.post<CampaignResponseInterface>(url, request).pipe(
			retry(2),
			map((campaigns: CampaignResponseInterface) => {
				this.pinnedBottomStore[arrSize] = campaigns.summary;
				this.totalsRow = campaigns.summary;
				this.lastNextPageCursor = campaigns.nextPageCursor;
				this.isCheckboxDeactivated = false;
				return campaigns.data;
			})
		);
	}

	public updateSelectedRows(toggledRow: UpdateCampaignInterface | UpdateAAACampaignInterface): void {
		this.triggerCheckCheckboxState.next();
		if (!this.isSelectionToggledProgramatically) {
			if (!this.innerStoreAction) {
				this.store.dispatch(new UpdateSelectedCampaigns(toggledRow as UpdateCampaignInterface));
			} else {
				this.aaaStore.dispatch(new UpdateSelectedAAACampaigns(toggledRow as UpdateAAACampaignInterface));
			}
		} else {
			if (!this.innerStoreAction) {
				this.bundleUpdateArray.push(toggledRow as UpdateCampaignInterface);
			} else {
				this.aaaStore.dispatch(new UpdateSelectedAAACampaigns(toggledRow as UpdateAAACampaignInterface));
			}
		}
	}

	public hideColumns(columns: ColDef[], columnAPI: ColumnApi): void {
		for (const column of columns) {
			if (column.suppressColumnsToolPanel) {
				columnAPI.setColumnVisible(column.colId, false);
			}
		}
	}

	public toggleStructureStatus(campaignId: string, updateStatus: AdsManagerStatusEnum): Observable<AdsManagerToggleResponseInterface> {
		const request: UpdateAdvertisingStructureRequestInterface = {
			details: {
				status: updateStatus
			}
		};
		return this.http.put<AdsManagerToggleResponseInterface>(`${BaseApiUrl.FacebookDataPipeline}campaign/${campaignId}`, request);
	}

	public deleteStructure(campaignId: string): Observable<void> {
		return this.http.delete<void>(`${BaseApiUrl.FacebookDataPipeline}campaign/${campaignId}`);
	}

	public turnOffHasDelivery(): void {
		this.hasDelivery = false;
	}

	public turnOnHasDelivery(): void {
		this.hasDelivery = true;
	}

	public onPaginationChanged(paginationEvent: PaginationChangedEvent): void {
		this.triggerCheckCheckboxState.next();
	}

	public checkSelectedRows(gridApi: GridApi): void {
		gridApi.forEachNode(rowNode => {
			const adSetData = rowNode.data as AdSetsInterface;
			if (this.selectedCampaignsIds?.indexOf(adSetData?.campaign_id) > -1 && !rowNode.isSelected()) {
				rowNode.setSelected(true);
			} else if (this.selectedCampaignsIds?.indexOf(adSetData?.campaign_id) === -1 && rowNode.isSelected()) {
				rowNode.setSelected(false);
			}
			this.triggerCheckCheckboxState.next();
		});
	}

	public triggerBundleUpdate(): Observable<void> {
		return this.triggerBundleUpdateSubject.asObservable();
	}

	public onClickName(campaignId: string): void {
		this.deselectAll();
		setTimeout(() => {
			this.gridApi
				.getRenderedNodes()
				.find(rowNode => (rowNode.data as CampaignInterface).campaign_id === campaignId)
				.setSelected(true);
			this.changeTabSubject.next();
		}, 0);
	}

	public listenToTabChange(): Observable<void> {
		return this.changeTabSubject.asObservable();
	}
}
