export interface PermissionModel {
	id: number;
	title: string;
	description: string;
	faceBookCategoryName: string;
	filedPermisionCategoryId: FiledPermissionCategory;
	isChecked: boolean;
}

export interface GetPermissionsResponse {
	keysPermissions: string;
}

export enum FiledPermissionCategory {
	General,
	AdsAndPages,
	Instagram
}
