<div class="container">
	<div class="wrapper">
		<div class="vertical-icon" [style.margin-bottom]="expanded ? 'blue' : '0px'">
			<span class="k-icon k-i-select-all"></span>
		</div>
		<div class="panel">
			<div class="panel">
				<div class="sub-sub-panel first" (click)="toggleExpansion()">
					<div>
						<font07>{{ shortAnsFormGroup.value.question }}</font07>
					</div>
					<div>
						<button class="give-border">
							<i class="k-icon k-i-convert-uppercase"></i>
							{{ placeholderText }}
						</button>
					</div>
				</div>
				<div *ngIf="expanded">
					<hr />
				</div>
			</div>

			<div class="panel" [formGroup]="formControl" *ngIf="expanded">
				<div class="form-container">
					<label class="form-label">Question</label>
					<tb06 [control]="shortAnsFormGroup.get('question')" [placeHolderTextBox]="'Enter a short answer question'"></tb06>
				</div>
			</div>
			<div class="sub-sub-panel first" *ngIf="expanded">
				<div>&nbsp;</div>
				<btn03 [icon]="'trash'" (onClick)="removeItem()">Remove</btn03>
			</div>
		</div>
	</div>
	<div class="vertical-icon" *ngIf="!expanded">
		<btn08 [icon]="'trash'" (onClick)="removeItem()"></btn08>
		<div class="panel" [formGroup]="formControl">
			<div>
				<div fxLayout *ngIf="expanded">
					<div fxFlex="5" class="icon-area">
						<span class="k-icon k-i-full-screen"></span>
					</div>
					<div fxFlex="95">
						<div class="form-container">
							<label class="form-label">Question</label>
							<tb06 [control]="formControl.get('question')" [placeHolderTextBox]="'Enter a short answer question'"></tb06>
						</div>
					</div>
				</div>
				<div fxLayout *ngIf="!expanded" class="collapsed-panel" (click)="expanded = true">
					<div fxFlex="5" [style.margin-top]="'5px'">
						<span class="k-icon k-i-full-screen"></span>
					</div>
					<div>
						<font07>{{ formControl.value.question }}</font07>
					</div>
					<div fxFlex="30" class="push-right">
						<button class="give-border">
							<i class="k-icon k-i-convert-uppercase"></i>
							{{ placeholderText }}
						</button>
					</div>
				</div>
			</div>
			<div class="bct-buttons-wrapper" *ngIf="expanded">
				<div>
					<btn03 [icon]="'trash'" (onClick)="removeItem()">Remove</btn03>
				</div>
			</div>
		</div>
	</div>
</div>
