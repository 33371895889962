<div class="dropdown-container">
	<!--Channel buttons-->
	<div style="display: none;" class="buttons-wrapper" *ngIf="channelButtons.length">
		<div *ngFor="let button of channelButtons; last as isLast; index as i" class="buttons-line">
			<button (click)="onSelectedChannelChange(button)" mat-button>
				<img [src]="selectedButton?.sourceChannel === button.sourceChannel ? button.fileIcon : button.fileIconDisabled" alt="filed-image" />
			</button>
			<hr *ngIf="!isLast && !channelButtons[i + 1].hideChannel" />
		</div>
	</div>

	<!--Dropdown menu-->
	<section class="account_dropdown no-select" fxHide.xs="false" fxShow tabindex="-1">
		<div (click)="toggleDropdown()" class="dropdown-head">
			<div class="dropdown-head__label">{{ userSelection }}</div>
			<span [ngClass]="{ opened: hideTab }" class="mat-expansion-indicator">
				<i class="fa fa-chevron-down" aria-hidden="true"></i>
			</span>
		</div>
		<div [hidden]="hideTab" class="tab-content">
			<div class="panels panel-search">
				<mat-form-field floatLabel="never">
					<span matPrefix>
						<mat-icon>search</mat-icon>
						&nbsp;
					</span>
					<mat-label *ngIf="!adAccountSearchFormControl.value">{{ 'SEARCH_FOR_ACCOUNT_NAME' | translate }}</mat-label>
					<input
						#searchInputRef
						(keydown)="searchForAccount($event)"
						[formControl]="adAccountSearchFormControl"
						class="placeholder-wrapper"
						matInput
						value=""
					/>
				</mat-form-field>
			</div>
			<div class="panels">
				<app-tree-checklist
					(model)="getTreeModel($event)"
					(treeSelection)="treeSelectionCallback($event)"
					[data]="dataForTree"
					[expanded]="true"
					[initialSelectionIds]="selectedDataFroTree"
					[onlySelectLeaves]="true"
					[singleSelection]="true"
					class="dropdown_filters"
					primaryKeyName="id"
				></app-tree-checklist>
			</div>
		</div>
	</section>
</div>
