import { Component, Input, Output, OnInit, forwardRef, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

@Component({
	selector: 'app-tracking-parameter',
	templateUrl: './tracking-parameter.component.html',
	styleUrls: ['./tracking-parameter.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TrackingParameterComponent),
			multi: true
		}
	]
})
export class TrackingParameterComponent implements OnInit, ControlValueAccessor {
	@Input() showLabel: boolean;
	@Input() itemIndex: number;

	@Output() consentChanged: EventEmitter<FormControl> = new EventEmitter();
	@Output() removeParameter: EventEmitter<number> = new EventEmitter();

	public parameterName = new FormControl();
	public parametervalue = new FormControl();
	public showTrashButton: boolean = false;
	disabled = false;

	value = '';
	checked: false;

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {}

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	removeItem(): void {
		this.removeParameter.emit(this.itemIndex);
	}
}
