import { DropdownColumnInterface } from '../../live-search-dropdown/dropdown-columns.interface';

export class ConversionDropdownConfig {
	public static conversionColumns: DropdownColumnInterface[] = [
		{
			property: 'statusDescription',
			formatText: true
		},
		{
			property: 'name',
			formatText: true
		},
		{
			property: 'eventCount',
			formatText: true
		}
	];
}
