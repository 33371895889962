import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CampaignTemplateListItem } from '../models/campaign-template-list-item';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { Observable } from 'rxjs';
import { CampaignTemplate } from '../models/campaign-template';
import { CreateCampaignRequest } from '../models/create-campaign-request';
import { UpdateCampaignRequest } from '../models/update-campaign-request';
import { tap } from 'rxjs/operators';
import { MinimumBudgetResponseInterface } from '../models/minimum-budget-response.interface';
import { PublishResponseInterface } from '../models/publish-response.interface';
import { AdvertiseAppInterface } from '../models/advertise-app.interface';
import { AdAccount } from '../../accounts/models/ad-account.model';
import { HierarchyDatum } from '../components/publishing-trees-tructure/tree-ui.interface';

@Injectable()
export class CampaignSmartCreateService {
	constructor(private http: HttpClient) {}

	// public getCampaignTemplatesList(accountId: string): Observable<CampaignTemplateListItem[]> {
	// 	return this.http
	// 		.get<CampaignTemplateListItem[]>(`${BaseApiUrl.CampaignBuilderSmartCreate}smartCreate?adAccountId=${accountId}`)
	// 		.pipe(tap(items => items.forEach(item => item.name)));
	// }

	// public getCampaignTemplate(id: number): Observable<CampaignTemplate> {
	// 	return this.http.get<CampaignTemplate>(`${BaseApiUrl.CampaignBuilderSmartCreate}smartCreate/${id}`);
	// }

	// public createCampaign(body: CreateCampaignRequest): Observable<number> {
	// 	return this.http.post<number>(`${BaseApiUrl.CampaignBuilderSmartCreate}smartCreate`, body);
	// }

	// public updateCampaign(id: number, body: UpdateCampaignRequest): Observable<void> {
	// 	return this.http.put<void>(`${BaseApiUrl.CampaignBuilderSmartCreate}smartCreate/${id}`, body);
	// }

	// public deleteCampaign(id: number): Observable<void> {
	// 	return this.http.delete<void>(`${BaseApiUrl.CampaignBuilderSmartCreate}smartCreate/${id}`);
	// }

	// public publishCampaign(id: number, body: UpdateCampaignRequest): Observable<void> {
	// 	const lastStep = { StepFourDetails: body.stepFourDetails };
	// 	return this.http.put<void>(`${BaseApiUrl.CampaignBuilderSmartCreate}smartCreate/${id}/publish`, lastStep);
	// }

	// public getBudgetValidation(id: string): Observable<MinimumBudgetResponseInterface> {
	// 	return this.http.get<MinimumBudgetResponseInterface>(`${BaseApiUrl.FacebookPythonCampaignBuilder}budget-validation/${id}`);
	// }
	// public checkPublishStatus(): Observable<PublishResponseInterface> {
	// 	return this.http.get<PublishResponseInterface>(`${BaseApiUrl.FacebookPythonCampaignBuilder}smart-create/publish-progress/`);
	// }

	// public getAdvertisableApps(adAccount: AdAccount): Observable<AdvertiseAppInterface[]> {
	// 	return this.http.get<AdvertiseAppInterface[]>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/advertisable-apps?ad_account_id=${adAccount.id}`);
	// }
	// public getCatalogs(id: string): Observable<any> {
	// 	return this.http.get<any>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/catalogs?ad_account_id=${id}`);
	// }

	// public getCatalogSets(catalogId: string): Observable<any> {
	// 	return this.http.get<any>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/catalog-sets?product_catalog_id=${catalogId}`);
	// }
	public getCreativeSingleImage(): Observable<any> {
		return this.http.get<any>(`${BaseApiUrl.CreativeBuilder}SingleAds`);
	}
	public uploadSingleImage(data: any): Observable<any> {
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'multipart/form-data');
		const formData: FormData = new FormData();
		formData.append('SingleAdImage', data.SingleAdImage);
		formData.append('SingleAdName', data.SingleAdName);
		return this.http.post<any>(`${BaseApiUrl.CreativeBuilder}SingleAds/PostSingleAdFromSmartCreate`, formData, { headers: headers });
	}

	// public getPushingAdsetData(): Observable<HierarchyDatum> {
	// 	return this.http.get<HierarchyDatum>(`${BaseApiUrl.FacebookPythonCampaignBuilder}smart-create/publish-progress-data/`);
	// }
	// public createOptimizationDraft(id: number, body: UpdateCampaignRequest): Observable<any> {
	// 	if (body && body.stepTwoDetails) {
	// 		body.stepTwoDetails.name = body.stepTwoDetails.adSetName;
	// 		delete body.stepTwoDetails.adSetName;
	// 	}
	// 	return this.http.post<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}optimization-draft`, body);
	// }
	// public updateOptimizationDraft(id: string, body: UpdateCampaignRequest): Observable<any> {
	// 	if (body && body.stepTwoDetails) {
	// 		body.stepTwoDetails.name = body.stepTwoDetails.adSetName;
	// 		delete body.stepTwoDetails.adSetName;
	// 	}
	// 	return this.http.put<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}optimization-draft?draft_structure_id=${id}`, body);
	// }
	// public getOptimizationDraftById(id: string): Observable<any> {
	// 	return this.http.get<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}optimization-draft`, { params: { draft_structure_id: id } });
	// }
	// public updateOptimizationSplit(data: any): Observable<any> {
	// 	if (data) {
	// 	}
	// 	return this.http.put<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}optimization-draft/split`, {}, { params: data });
	// }
	// public updateOptimizationSetting(data: any, id: string): Observable<any> {
	// 	return this.http.put<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}optimization-draft/adset`, data, { params: { draft_structure_id: id } });
	// }

	// public mergeSplits(draftId, field: any): Observable<any> {
	// 	return this.http.put<any>(
	// 		`${BaseApiUrl.FacebookPythonCampaignBuilder}optimization-draft/merge`,
	// 		{},
	// 		{ params: { draft_structure_id: draftId, field: field } }
	// 	);
	// }
}
