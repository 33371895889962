<ng-container *ngIf="!integrationsFB">
	<div class="flex-center">
		<btn21 (click)="login()">
			<span>
				<img src="assets/icons/facebook.svg" />
				Connect With Facebook
			</span>
		</btn21>
	</div>
</ng-container>

<ng-container *ngIf="integrationsFB">
	<div (click)="login()" class="status-text-action">
		{{ 'Connect' }}
	</div>
</ng-container>
