<section [ngClass]="{ grid: assets.length > 0 }" appDragAndDrop (onFileDropped)="onFileSelected($event)">
  <div *ngFor="let asset of assets" class="grid_item">

    <div *ngIf="asset.type==0">
      <img
          [alt]="asset.title"
          [src]="asset.url"/>
    </div>
    <div *ngIf="asset.type==1">
      <video controls preload="metadata">
        <source [src]="asset.url">
      </video>
    </div>

    <!--    <mat-form-field>-->
    <!--      <input [(ngModel)]="asset.title" matInput placeholder="{{'NAME'| translate}}"/>-->
    <!--    </mat-form-field>-->
  </div>
  <div [ngClass]="{ exist_assets: assets.length > 0}" class="container_upload">
    <div [ngClass]="{ exist_assets: assets.length > 0 }" class="container_drag_image text-center">
      <label for="file">
        <div class="drag_image">
          <p>{{ "DROP_IMAGE" | translate }}</p>
          <p>{{ "OR" | translate }}</p>
          <div class="select_file_button">
            <app-button (buttonClicked)="openFileSelection()"
                        [buttonClass]="buttonClassEnum.Primary"
                        [buttonType]="buttonTypeEnum.Simple"
                        buttonLabel="UPLOAD">
            </app-button>
          </div>
        </div>
        <input #input (change)="onFileSelected(input.files)" hidden id="file" type="file" multiple/>
      </label>
    </div>
  </div>
</section>
<section *ngIf="assets.length > 0" fxLayout="row" fxLayoutAlign="end start">
  <button (click)="updateAssets()" mat-raised-button>
    {{ "SAVE" | translate }}
  </button>
</section>
