import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-information-dialog',
	templateUrl: './information-dialog.component.html',
	styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit {
	@Input() public message: string;
	@Input() public title: string;

	constructor(public dialogRef: MatDialogRef<InformationDialogComponent>) {}

	ngOnInit() {}
}
