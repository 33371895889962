import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { CatalogImages } from '../../model/catalog-images.enum';
import { EcomerceSource } from '../../model/eccomerce-source.enum';
import { SelectedProductInterface } from '../../model/selected-product.interface';
import { ShopifyCatalog } from '../../service/shopify-catalog.service';
import { ActionTypes } from '../../state/product-catalog.action';
@Component({
	selector: 'app-connection-page',
	templateUrl: './connection-page.component.html',
	styleUrls: ['./connection-page.component.scss']
})
export class ConnectionPageComponent implements OnInit {
	public eccomerce: string = '';
	public selectedProductInterface: SelectedProductInterface;
	constructor(
		private activatedRoute: ActivatedRoute,
		private ShopifyCatalogService: ShopifyCatalog,
		private productCatalogStore: Store<any>,
		private router: Router
	) {}

	ngOnInit() {
		this.eccomerce = this.activatedRoute.snapshot.paramMap.get('id');
		switch (this.eccomerce.toLowerCase()) {
			case EcomerceSource.Shopify.toLowerCase():
				this.selectedProductInterface = {
					selected: true,
					ecomerceSource: EcomerceSource.Shopify,
					image: CatalogImages.Shopify
				};
				break;
			case EcomerceSource.BigCommerce.toLowerCase():
				this.selectedProductInterface = {
					selected: true,
					ecomerceSource: EcomerceSource.BigCommerce,
					image: CatalogImages.BigCommerce
				};
				break;
			default:
				this.selectedProductInterface = {
					selected: true,
					ecomerceSource: EcomerceSource.BigCommerce,
					image: CatalogImages.BigCommerce
				};
		}
	}
	public nextcontent(): void {
		if (this.eccomerce.toLowerCase() === EcomerceSource.Shopify.toLowerCase()) {
			this.getMapper();
		} else {
			if (window.location.href.indexOf('catalog/saved') > -1) {
				this.getMapper();
			} else {
				window.top.location.href = `${window.location.origin}/catalog/saved/${this.eccomerce.toLowerCase()}`;
			}
		}
	}
	public getMapper(): void {
		this.ShopifyCatalogService.getMappingDetails('mapping', this.eccomerce)
			.pipe(take(1))
			.subscribe(
				res => {
					this.productCatalogStore.dispatch({
						type: ActionTypes.FILE_UPLOAD,
						payload: [{ Method: 'eccomerce', FileType: this.selectedProductInterface, header: res }]
					});
					this.router.navigate(['/catalog/catalog-mapping']);
				},
				error => {}
			);
	}
}
