import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CampaignBudgetChoices } from './campaign-budget-choices.enum';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BudgetAllocatedEnum } from '../../../../campaign-smart-create/models/budget-allocated.enum';
import { ValidatorMessages } from '../../../form-input/validatorMessagesInputs';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AudienceService } from 'src/app/_services/audience/audience.service';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-budget-card',
	templateUrl: './budget-card.component.html',
	styleUrls: ['./budget-card.component.scss']
})
export class BudgetCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public campaignBudgetOptimization: boolean;
	@Input() public currency: string;
	@Input() public validBudget: number;
	@Input() public adSetBudget: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();

	public budgeChoiceEnum = CampaignBudgetChoices;
	public budgetType = BudgetAllocatedEnum;
	public validationMessage: ValidatorMessages[];
	protected unsubscriber$ = new Subject<void>();

	public budgetInfoModel: InfoHoverDataModel;

	constructor() {}

	public ngOnInit(): void {
		this.initComponentListener();
		this.budgetInfoModel = {
			message:
				'Ad set budget optimization automatically manages your budget across your ad sets to get you the overall best results. You set one central campaign budget and this budget continuously distributes in real-time to the ad sets with the highest success rate, for the lowest cost per result.'
		};
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.validationMessage = [
			{
				validationType: 'required',
				validationMessage: 'This field is mandatory'
			},
			{
				validationType: 'min',
				validationMessage: `${'Minimum budget is ' + this.validBudget + ' ' + this.currency}`
			}
		];
	}

	public onBudgetTypeClick(type: BudgetAllocatedEnum): void {
		this.adSetBudget.get('budgetType').patchValue(type);
	}

	public initComponentListener(): void {
		if (!this.adSetBudget.get('budgetType').value) {
			this.adSetBudget.get('budgetType').patchValue(this.budgetType.LifeTime);
		}
		this.adSetBudget.get('amount').setValidators([Validators.required, Validators.max(1000000000), Validators.pattern('^[0-9]+$')]);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
