<div>
	<div class="verticalSpacing"></div>
	<div>
		<font03>Choose Ad</font03>
	</div>
	<div class="verticalSpacing"></div>
	<div>
		<card-collapsible
			[title]="flow === 2 ? 'Select Ads' : 'Use Best Performing Ads'"
			[subtitle]="'Choose the ads you would like to use. Edit these, or continue using them as they are.'"
			[width]="'51.875rem'"
			[imgLink]="'../../../assets/icons/Group 16724.svg'"
			[iscollapsed]="false"
			[disabled]="false"
			[description]="'Only ads that are compatible with the selected campaigns will appear in the list.'"
		>
			<div class="row">
				<div class="searchBox">
					<tb06 [icon]="'fa-search'" class="search-input" [placeHolderTextBox]="'Search'" [control]="searchFormControl.get('searchAdForm')"></tb06>
				</div>
				<div class="selectionCard">
					<app-selection-count>
						<font07-4>Selected: {{ selectedAdIds?.length }}</font07-4>
					</app-selection-count>
				</div>
			</div>
			<div class="verticalSpacing"></div>
			<div>
				<app-master-table
					[areTabsAttached]="true"
					[masterService]="adsService"
					[masterTableImplementation]="adsManagerMasterTableImplementations.AdsManagerCampaignTable"
					[columnDefs]="adView"
					[frameworkComponents]="frameworkComponents"
					[rowBuffer]="200"
					[rowSelection]="rowSelection.Multiple"
					[rowModelType]="rowModelType.ServerSide"
					[defaultColDef]="defaultColDef"
					[paginationPageSize]="defaultPageSize"
					[numberOfRowsReturnedByServer]="defaultPageSize"
					[paginationOptions]="paginationOptions"
					[canPageSizeBeChanged]="true"
					[isRowSelectable]="adsManagerHelper.isRowSelectable"
					[getRowStyle]="adsManagerHelper.getRowStyle"
					[getRowClass]="adsManagerHelper.getRowClass"
					(gridReady)="onGridReady($event)"
				></app-master-table>
			</div>
		</card-collapsible>
	</div>
	<div class="bottomSpacing"></div>
</div>
