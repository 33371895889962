export enum UserRejectedReason {
	None = -1,
	UserIsInactive = 0,
	UserWithoutProperFiledDetails = 1,
	UserIsRemoved = 2,
	UserAccountInTransitoryState = 3,
	FrontOfficeUserWithNoBusinessOwner = 4,
	FrontOfficeUserWithNoConfirmedCreditCard = 5,
	FrontOfficeUserWithZeroAdAccounts = 7,
	FrontOfficeUserWithUnpaidBills = 8,
	FreemiumExpiredNoCreditCard = 9,
	FreeTrialExpiredNoCreditCard = 10
}
