import { Injectable } from '@angular/core';
import { NoResourceState } from './no-resource.models';

@Injectable({
	providedIn: 'root'
})
export class NoResourceService {
	private state: NoResourceState;

	public setState(state: NoResourceState): void {
		this.state = state;
	}

	public getState(): NoResourceState {
		return this.state;
	}
}
