<div class="main">
	<div class="filter-form-container">
		<div class="filter-row" *ngFor="let filter of filterData">
			<div class="filter-name">
				<font08>{{ filter.displayName }}</font08>
			</div>
			<div class="drop-down">
				<drp01 (valueChanges)="dropDownValueChanges($event)" [data]="dropDownListItems" [control]="dropDownControl"></drp01>
			</div>
		</div>
	</div>
	<div class="apply-button">
		<btn01>Apply</btn01>
	</div>
</div>
