import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getCampaign, isCampaignUpdating } from '../state/smart-create.reducer';
import { SharedState } from '../../shared/state/shared.reducer';
import { map, skipWhile, take, tap, withLatestFrom } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { ClearAds, ClearCurrentCampaign, LoadCampaignTemplate } from '../state/smart-create.actions';
import { Injectable } from '@angular/core';
import { AdAccountResolver } from '../../shared/resolvers/ad-account.resolver';
import { LoadCampaignCatalogs, LoadFacebookPixels, LoadProductCatalog } from '../../state/campaign-common-state/campaign-common-actions';
import {
	getBidStrategiesFromCatalog,
	getObjectivesWithDestinationFromCatalog,
	getSpecialAdCategoriesFromCatalog,
	getFacebookPixels
} from '../../state/campaign-common-state/campaign-common-reducer';

@Injectable()
export class CreateCampaignResolver implements Resolve<Observable<any>> {
	constructor(private adAccountResolver: AdAccountResolver, private store: Store<CampaignSmartCreateState>, private sharedStore: Store<SharedState>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.store.dispatch(new LoadCampaignCatalogs());
		this.store.dispatch(new LoadProductCatalog());
		this.store.dispatch(new LoadFacebookPixels());

		const campaignId = Number(route.params.campaignId);
		const isNewCampaign = isNaN(campaignId);
		if (!isNewCampaign) {
			if (campaignId !== this.getCurrentCampaign()) {
				this.store.dispatch(new ClearAds());
			}
			this.store.dispatch(new LoadCampaignTemplate(campaignId));
		} else {
			this.store.dispatch(new ClearCurrentCampaign());
		}

		const campaign$ = isNewCampaign
			? of(null)
			: this.store.pipe(
					withLatestFrom(this.store.select(isCampaignUpdating)),
					skipWhile(([action, isUpdating]) => isUpdating),
					map(([action, updating]) => action),
					select(getCampaign),
					skipWhile(campaign => !campaign || campaign.id !== campaignId),
					take(1)
			  );

		const adAccount$ = this.adAccountResolver.resolve(route, state);
		const specialAdCategories$ = this.store.pipe(
			select(getSpecialAdCategoriesFromCatalog),
			skipWhile(categories => !categories),
			take(1)
		);
		const objectives$ = this.store.pipe(
			select(getObjectivesWithDestinationFromCatalog),
			skipWhile(objectives => !objectives),
			take(1)
		);
		const bidStrategy$ = this.store.pipe(
			select(getBidStrategiesFromCatalog),
			skipWhile(strategies => !strategies),
			take(1)
		);

		const accountPixels$ = this.store.pipe(select(getFacebookPixels), take(1));

		return forkJoin([adAccount$, campaign$, specialAdCategories$, objectives$, bidStrategy$, accountPixels$]).pipe(
			take(1),
			map(([adAccount, campaign, categories, objectives, bidStrategy, accountPixels]) => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				return {
					account: adAccount,
					campaign: campaign,
					specialAdCategory: categories,
					bidStrategy: bidStrategy,
					objectives: objectives,
					accountPixels: accountPixels,
					isNewCampaign: isNewCampaign
				};
			})
		);
	}

	protected getCurrentCampaign(): number {
		let campaignId = 0;
		this.store.pipe(select(getCampaign), take(1)).subscribe(res => {
			campaignId = res?.id;
		});

		return campaignId;
	}
}
