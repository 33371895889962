import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AaaHeaderFooterStateService } from '../../services/aaa-header-footer-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AAAFooterButtonInterface } from '../../models/aaafooter-button.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SmartEditService } from 'src/app/smart-edit/services/smart-edit.service';
import { OptimizeService } from 'src/app/optimise/services/optimize.service';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { OptimizationTypeEnum } from 'src/app/optimise/models/optimization-level.enum';
import { NavigationService } from '../../services/NavigationService';

@Component({
	selector: 'app-aaafooter',
	templateUrl: './aaafooter.component.html',
	styleUrls: ['./aaafooter.component.scss']
})
export class AAAFooterComponent implements OnInit, OnChanges, OnDestroy {
	public disableEdit: boolean;
	public continueButtonText: 'Continue' | 'Publish' = 'Continue';
	public saveButtonText: 'Back' | 'Save as Draft' = 'Save as Draft';
	public isPublishing: boolean;
	public hasEditButton: boolean;
	protected unsubscriber$ = new Subject<void>();
	private history: string[] = [];
	constructor(
		private aaaStateService: AaaHeaderFooterStateService,
		private router: Router,
		private location: Location,
		private smartEditService: SmartEditService,
		private accountService: AccountTypeService,
		private navigation: NavigationService
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.history.push(event.urlAfterRedirects);
			}
		});
	}

	public ngOnInit(): void {
		this.setListeners();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.aaaStateService
			.getActiveStep()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				if (res && res > 0) {
					this.aaaStateService.onPreviewAd(false);
					this.setDefaultButtonText();
				}
			});
	}

	private setEditButtonState(value: AAAFooterButtonInterface): void {
		this.disableEdit = value.disabled;
		this.hasEditButton = value.show;
	}

	private editButtonStatus(): void {
		this.hasEditButton = false;
	}

	public onCancelClick(): void {
		this.aaaStateService.sendOnCancel();
		if (this.accountService.dexterAction$?.value) {
			this.router.navigate(['/optimize/labs']);
		} else if (this.smartEditService.dexterRecommendation$?.value) {
			this.router.navigate(['/optimize/recommendations']);
		} else {
			this.router.navigate(['/campaign/select-campaign']);
		}
	}
	private setDefaultButtonText(): void {
		this.continueButtonText = 'Continue';
		this.saveButtonText = 'Back';
	}
	public previewStageConfig(): void {
		this.continueButtonText = 'Publish';
		this.saveButtonText = 'Back';
	}

	public onSaveAsDraftClick(): void {
		this.aaaStateService.sendOnSave();
	}

	public onNextClick(): void {
		this.aaaStateService.sendOnNext();
	}

	public onBackClick(): void {
		this.aaaStateService.goBack();
		this.navigation.back();
	}

	public onEditClick(): void {
		this.aaaStateService.sendOnEdit();
	}

	public setListeners(): void {
		this.aaaStateService
			.getEditButtonState()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(state => this.setEditButtonState(state));

		this.aaaStateService
			.getPreviewState()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => {
				if (res) {
					this.previewStageConfig();
				} else {
					this.setDefaultButtonText();
				}
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
