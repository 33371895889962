<lolly-spinning-loader *ngIf="showSpinner" class="loading-indicator"></lolly-spinning-loader>
<div class="grid-section" *ngIf="!isFlowCompleted">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/monthly-ad-spend-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-section-container">
			<div class="grid-gap-left"></div>
			<div class="grid-section-two-inner container">
				<div class="center-column-two">
					<div class="filed-logo">
						<img src="assets/logo/lolly-logo.svg" />
					</div>
					<div class="billing-carousel-wrapper">
						<div class="payment-slide" [class.active]="!this.billingActive">
							<div class="font-grey">
								<div class="text-description-2">
									<h2>Complete your billing info</h2>
									<p class="margin-bottom-spacing">Please enter your billing address</p>
								</div>
							</div>
							<div class="inner-margin">
								<form class="margin-top" [formGroup]="formStep2">
									<div class="margin-bottom-spacing">
										<tb01-2
											class="input-box"
											[control]="formStep2.get('address1')"
											[validatorMessages]="validationMessages.get('address1')"
											label="Billing Address*"
										></tb01-2>
									</div>
									<div class="margin-bottom-spacing">
										<tb01-2
											class="input-box"
											[control]="formStep2.get('city')"
											[validatorMessages]="validationMessages.get('city')"
											label="City*"
											placeHolderTextBox="e.g: London"
										></tb01-2>
									</div>

									<div class="margin-bottom-spacing">
										<drp02
											class="input-box"
											[control]="formStep2.get('country')"
											[data]="dropdownCountries"
											[label]="'Country*'"
											[placeholder]="'Select Country'"
											[width]="'100%'"
										></drp02>
									</div>

									<div class="bottom-spacing-lg">
										<tb01-2
											class="input-box"
											[control]="formStep2.get('zip')"
											[validatorMessages]="validationMessages.get('zip')"
											label="Zipcode/ Postcode*"
											placeHolderTextBox="e.g: ER5 6GL"
										></tb01-2>
									</div>
								</form>
								<div class="margin-bottom-spacing top-space">
									<btn01 (click)="next()" [disabled]="formStep2IsSubmitted || formStep2.invalid || formStep2IsRequested">
										Save
									</btn01>
								</div>
								<p class="logout">
									To change accounts or exit, please
									<span (click)="logout()">logout</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid-gap-right"></div>
		</div>
	</div>
</div>
<img *ngIf="isFlowCompleted" class="pending-image" src="assets/images/success-background.svg" alt="pending-credit-card" />
<app-toast-notification></app-toast-notification>
