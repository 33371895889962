import { Injectable } from '@angular/core';

import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartLabel } from '../../models/chart-label';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { GridLayout } from '../../models/grid-layout';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { chartTooltipInterface } from '../../shared/models/chart-tooltip.interface';
import _ from 'lodash';
import { chartTooltipModel } from '../../shared/models/chart-tooltip.model';

@Injectable({
	providedIn: 'root'
})
export class StackedHorizontalChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel) {
		const label = new ChartLabel('center', 'middle', 'inside', 0, 15, 12, widget.details.reportProperty.showDataLabels);
		const chartOptions = this.initChartOptions(widget);
		chartOptions.grid = new GridLayout('110', '20', '20', '115', false);

		const chartData = this.createChartSourceForComplexData(widget, true, false, false, label);
		chartOptions.xAxis = this.createSecondaryAxis(chartData, widget, 'xAxisIndex', widget.details.reportProperty.horizontalAxisTitle, 35, 0);
		chartOptions.yAxis = this.createPrimaryAxis(chartData, widget, 90, widget.details.reportProperty.verticalAxisTitle);
		(chartOptions.yAxis.axisLabel = {
			margin: 70,
			align: 'left',
			formatter: (value: any) => {
				return value.length > 10 ? value.substr(0, 10) + '...' : value;
			}
		}),
			(chartOptions.nameTextStyle = {
				color: '#424242'
			});
		chartOptions.series = chartData.data;
		chartOptions.tooltip = chartTooltipModel;
		chartOptions.legend.data = [];
		chartOptions.legend.data = chartData.data.map(item => item.name);
		return chartOptions;
	}
}
