import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertTitleEnum, DistributionComparisons, IDexterAd, IDexterAdAlert, IHealthColorStatus } from 'src/app/optimise/models/dexter-health.interface';
import { OptimizeHelper } from 'src/app/optimise/services/optimize-helper';
import { OptimizeService } from 'src/app/optimise/services/optimize.service';
import { WelcomePageModel } from 'src/app/shared/welcome-page/welcome-page.model';

@Component({
	selector: 'app-dexter-health-overview',
	templateUrl: './dexter-health-overview.component.html',
	styleUrls: ['./dexter-health-overview.component.scss']
})
export class DexterHealthOverviewComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();

	public labsHomepageModel: WelcomePageModel;

	public notFirstTimeUser: string = null;
	public comparisonText: any;
	public pageAlertText: any;
	public sentimentText: any;
	public engagementRate: any;
	public conversionRate: any;
	public overallScoreValue: number = 0;

	public showWarning = true;
	public qualityRanking: any;

	public dexterHealthValues: IDexterAd;
	public landingPageAlert: IDexterAdAlert;
	public landingPageComparison: IDexterAdAlert;
	public sentimentAnalysis: IDexterAdAlert;
	public distributionComparison: IDexterAdAlert;
	public adsList: IDexterAd[] = [];
	public selectedCampaigns: any[] = [];
	public indexNumber: number = 0;

	constructor(private dialog: MatDialog, public optimizeService: OptimizeService, public cdRef: ChangeDetectorRef) {
		this.notFirstTimeUser = localStorage.getItem('firstTimeDexterUser');
		this.labsHomepageModel = this.notFirstTimeUser ? OptimizeHelper.getLabsHomePageModel(false) : OptimizeHelper.getLabsHomePageModel(true);
	}

	ngOnInit(): void {
		this.optimizeService.setSelectedDexterHealthCanpaign.pipe(takeUntil(this.unsubscriber$)).subscribe(campaignName => {
			this.optimizeService.allDexterHealthAds.pipe(takeUntil(this.unsubscriber$)).subscribe(healthData => {
				this.adsList = healthData;
				this.selectedCampaigns = [];
				if (campaignName === 'Campaigns Overview') {
					this.selectedCampaigns = this.adsList;
				} else {
					this.adsList.forEach(item => {
						if (item.topLevelData.campaignName === campaignName) {
							this.selectedCampaigns.push(item);
						}
					});
				}
				let adHealthValue = this.selectedCampaigns[this.indexNumber];
				this.optimizeService.setAdsNumber.next(this.selectedCampaigns.length);
				this.optimizeService.setAdIndex.next(0);
				this.optimizeService.setAdIndex.pipe(takeUntil(this.unsubscriber$)).subscribe(indexNumber => {
					this.indexNumber = indexNumber;

					this.dexterHealthValues = this.selectedCampaigns[this.indexNumber];
					this.optimizeService.setSelectedDexterHealthAd.next(this.dexterHealthValues);
					this.optimizeService.setAdsetSelectedName.next(this.dexterHealthValues.topLevelData.adsets[0].adsetName);
					this.optimizeService.setAdSelectedName.next(this.dexterHealthValues.adName);
					this.landingPageAlert = this.dexterHealthValues?.alerts.find(value => {
						return value.alertTitle === AlertTitleEnum.PageAlerts;
					});
					this.landingPageComparison = this.dexterHealthValues?.alerts.find(value => {
						return value.alertTitle === AlertTitleEnum.PageComparisons;
					});
					for (let values of this.dexterHealthValues?.alerts) {
						if (values.overallHealthScore) {
							this.overallScoreValue = values.overallHealthScore;
							break;
						}
					}
					this.sentimentAnalysis = this.dexterHealthValues?.alerts.find(value => {
						return value.alertTitle === AlertTitleEnum.SentimentAnalysis;
					});
					this.distributionComparison = this.dexterHealthValues?.alerts.find(value => {
						return value.alertTitle === 'Distribution Comparisons';
					});
					const comparisonAlertSummary = this.landingPageComparison?.alertSummary ? this.landingPageComparison?.alertSummary : 0;
					const pageAlertSummary = this.landingPageAlert?.alertSummary ? this.landingPageAlert?.alertSummary : '';
					const sentimentSummary = this.sentimentAnalysis?.alertSummary ? this.sentimentAnalysis?.alertSummary : '';
					const distributionComparisonSummary = this.distributionComparison?.alertSummary ? this.distributionComparison?.alertSummary : '';
					this.comparisonText = comparisonAlertSummary;
					this.pageAlertText = this.optimizeService.shortenSomeText(pageAlertSummary.toString(), 23);
					this.sentimentText = sentimentSummary;
					this.qualityRanking = this.dComparisonText(this.distributionComparison?.ranking.qualityRanking);
					this.conversionRate = this.dComparisonText(this.distributionComparison?.ranking.conversionRateRanking);
					this.engagementRate = this.dComparisonText(this.distributionComparison?.ranking.engagementRateRanking);
				});
			});
		});
	}

	public ngAfterViewChecked(): void {
		this.cdRef.detectChanges();
	}

	private dComparisonText(ranking: string): string | any {
		let summaryText = '';
		let summary = {
			text: ''
		};
		if (ranking === DistributionComparisons.ABOVE_AVERAGE) {
			summaryText = 'Strong';
			summary = {
				text: ''
			};
		} else if (ranking === DistributionComparisons.AVERAGE) {
			summaryText = 'Average';
			summary = {
				text: 'Average'
			};
		} else if (
			ranking === DistributionComparisons.BELOW_AVERAGE_10 ||
			ranking === DistributionComparisons.BELOW_AVERAGE_20 ||
			ranking === DistributionComparisons.BELOW_AVERAGE_35
		) {
			summaryText = 'Weak';
			summary = {
				text: 'Weak'
			};
		}
		return summaryText;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
