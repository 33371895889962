export const Placements = {
	FacebookDesktopFeed: 'DESKTOP_FEED_STANDARD',
	FacebookMobileFeed: 'MOBILE_FEED_STANDARD',
	FacebookStory: 'FACEBOOK_STORY_MOBILE',
	InstagramStory: 'INSTAGRAM_STORY',
	InstagramStandard: 'INSTAGRAM_STANDARD',
	MobileNative: 'MOBILE_NATIVE',
	MobileBanner: 'MOBILE_BANNER',
	MobileInterstitial: 'MOBILE_INTERSTITIAL',
	MobileRectangle: 'MOBILE_MEDIUM_RECTANGLE'
};
