import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-custom-question',
	templateUrl: './custom-question.component.html',
	styleUrls: ['./custom-question.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CustomQuestionComponent),
			multi: true
		}
	]
})
export class CustomQuestionComponent implements OnInit, ControlValueAccessor {
	@Input() title: string;

	disabled = false;

	value = '';

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {}

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
