import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
	ColDef,
	ColumnApi,
	FilterChangedEvent,
	GridApi,
	GridReadyEvent,
	IServerSideGetRowsParams,
	PaginationChangedEvent,
	RowSelectedEvent,
	SortChangedEvent,
	IToolPanel,
	IToolPanelParams,
	RowNode
} from 'ag-grid-community';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MasterTableRowSelectionEnum } from './master-table-row-selection.enum';
import { MasterTableGridReadyInterface } from './models/master-table-grid-ready.interface';
import { MasterTableImplementationsEnum } from './models/master-table-implementations.enum';
import { MasterTableService } from './models/master-table.interface';
import { RowModelTypeEnum } from './models/row-model-type.enum';
import { ToggleIconsInterface } from './models/toggle-icons.interface';
import { ActionParamsInterface } from '../../back-office/models/action-params.interface';
import { TitleUsersEnum } from '../../back-office/users/title-users.enum';
import { OfferTableFieldEnum } from '../../back-office/offers-table/offer-table-field.enum';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { ActivatedRoute } from '@angular/router';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { AgridViewComponent } from 'src/app/catalog/component-pieces/catalog-ag-grid-components/agrid-view/agrid-view-component';
import { AgridCustomFilterComponent } from 'src/app/catalog/component-pieces/catalog-ag-grid-components/agrid-custom-filter/agrid-custom-filter.component';
import { AddQuickSetOverlayComponent } from 'src/app/catalog/component-pieces/catalog-ag-grid-components/add-quick-set-overlay/add-quick-set-overlay.component';
import { AgGridNoRowOverlayEnum } from './models/ag-grid-no-row-overlay.enum';
import { DefaultNoRowOverlayComponent } from './template/default-no-row-overlay/default-no-row-overlay.component';

@Component({
	selector: 'app-master-table',
	templateUrl: './master-table.component.html',
	styleUrls: ['./master-table.component.scss']
})
export class MasterTableComponent implements OnInit, OnDestroy {
	@Input() public buttonsConfiguration: ToggleIconsInterface = {
		isColumnButtonShown: false,
		isExpandButtonShown: false,
		isExportButtonShown: false,
		isFilterButtonShown: false
	};
	@Input() public overlayNoRowsTemplate = 'defaultNoRowOverlayComponent';
	@Input() public overlayNoRowsParams: boolean = false;
	@Input() public overlayNoRowsId: AgGridNoRowOverlayEnum = AgGridNoRowOverlayEnum.QuickSet;
	@Input() public isCellSelectionSuppressed = true;
	@Input() public isEditingStoppedWhenGridLosesFocus = false;
	@Input() public isSideBarShown = true;
	@Input() public isEditClickSuppressed = false;
	@Input() public isActionsBarVisible = false;
	@Input() public canPageSizeBeChanged = false;
	@Input() public isIconsBarVisible = false;
	@Input() public isTreeDataUsed = false;
	@Input() public areChartsEnabled = false;
	@Input() public areTabsAttached = false;
	@Input() public masterService: MasterTableService;
	@Input() public columnDefs: ColDef[];
	@Input() public masterDetail: boolean;
	@Input() public detailCellRendererParams: any;
	@Input() public rowModelType: RowModelTypeEnum;
	@Input() public masterTableImplementation: MasterTableImplementationsEnum;
	@Input() public defaultColDef: ColDef;
	@Input() public paginationPageSize: number;
	@Input() public numberOfRowsReturnedByServer: number;
	@Input() public columnTypes: Record<string, ColDef>;
	@Input() public rowSelection: MasterTableRowSelectionEnum;
	@Input() public topPinnedRowData: Array<Object>;
	@Input() public bottomPinnedRowData: { [key: string]: Array<Object> };
	@Input() public paginationOptions: number[];
	@Input() public context: Object;
	@Input() public rowData: any;
	@Input() public frameworkComponents: any;
	@Input() public getRowStyle: Function;
	@Input() public getRowClass: Function;
	@Input() public rowBuffer: number;
	@Input() public detailRowHeight: number = 150;
	@Input() public isRowSelectable: Function;
	@Input() public domLayout: string = null;

	@Output() public gridReady = new EventEmitter<MasterTableGridReadyInterface>();
	@Output() public quicSetEvent = new EventEmitter();
	@Output() public saveGridView = new EventEmitter();

	@ViewChild('wrapper') public wrapper: ElementRef;

	public request: Object = null;
	public isFullScreenToggled = false;
	public heightNotOccupiedByAgGrid = 0;
	public lastNextPageCursor: string;
	public gridApi: GridApi;
	public gridColumnApi: ColumnApi;
	public gridOpyion = {
		components: {
			agridViewComponent: AgridViewComponent,
			addQuickSetOverlayComponent: AddQuickSetOverlayComponent,
			defaultNoRowOverlayComponent: DefaultNoRowOverlayComponent,
			agridCustomFilterComponent: AgridCustomFilterComponent
		}
	};
	private isColumnSideBarOpened = false;
	private isFilterSideBarOpened = false;
	private isGridViewSideBarOpened = false;
	private isCustomFilterSideBarOpened = false;
	private unsubscriber$ = new Subject<void>();
	public noRowsOverlayComponentParams;
	public sideBar = {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel'
			},
			{
				id: 'filters',
				labelDefault: 'Filters',
				labelKey: 'filters',
				iconKey: 'filter',
				toolPanel: 'agFiltersToolPanel'
			}
		],
		defaultToolPanel: ''
	};

	constructor(private store: Store<AppState>, private router: ActivatedRoute) {}

	public ngOnInit(): void {
		if (this.overlayNoRowsParams && this.overlayNoRowsId === AgGridNoRowOverlayEnum.QuickSet) {
			this.noRowsOverlayComponentParams = {
				noRowsActionFunc: () => this.quicSetEvent.emit()
			};
		}

		if (this.buttonsConfiguration.isViewsButtonShown) {
			this.sideBar.toolPanels.push({
				id: 'agridViewComponent',
				labelDefault: 'AGrid ViewComponent',
				labelKey: 'agridViewComponent',
				iconKey: 'agridViewComponent',
				toolPanel: 'agridViewComponent'
			});
			this.sideBar.defaultToolPanel = 'agridViewComponent';
		}
		if (this.buttonsConfiguration.isViewsButtonShown) {
			this.sideBar.toolPanels.push({
				id: 'agridCustomFilterComponent',
				labelDefault: 'AGrid FilterComponent',
				labelKey: 'agridCustomFilterComponent',
				iconKey: 'agridCustomFilterComponent',
				toolPanel: 'agridCustomFilterComponent'
			});
			this.sideBar.defaultToolPanel = 'agridCustomFilterComponent';
		}
		this.heightNotOccupiedByAgGrid = (this.isIconsBarVisible || this.isActionsBarVisible ? 59 : 0) + (this.canPageSizeBeChanged ? 42 : 0);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	@HostListener('fullscreenchange')
	public onFullScreenChange(): void {
		if (this.isFullScreenToggled) {
			this.buttonsConfiguration.isExpandButtonShown = true;
		}
		this.isFullScreenToggled = !this.isFullScreenToggled;
	}

	public onPaginationChanged(paginationParameters: PaginationChangedEvent): void {
		if (this.masterService?.onPaginationChanged) {
			this.masterService.onPaginationChanged(paginationParameters);
		}
	}
	public getbottomPinnedRowData(): Array<Object> {
		if (this.bottomPinnedRowData === undefined) {
			return null;
		}
		if (this.bottomPinnedRowData.hasOwnProperty(this.gridApi?.paginationGetCurrentPage()) === true) {
			return this.bottomPinnedRowData[this.gridApi.paginationGetCurrentPage()];
		} else {
			return null;
		}
	}
	public onQuickSetButton(): void {
		this.quicSetEvent.emit();
	}
	public onViewButton(): void {
		if (!this.isGridViewSideBarOpened) {
			this.gridApi.openToolPanel('agridViewComponent');
		} else {
			this.gridApi.closeToolPanel();
		}
		this.isGridViewSideBarOpened = !this.isGridViewSideBarOpened;
	}
	public onCustomFilterButton(): void {
		if (!this.isGridViewSideBarOpened) {
			this.gridApi.openToolPanel('agridCustomFilterComponent');
		} else {
			this.gridApi.closeToolPanel();
		}
		this.isGridViewSideBarOpened = !this.isGridViewSideBarOpened;
	}
	public onGridReady(params: GridReadyEvent): void {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		if (this.masterService?.hideColumns) {
			this.masterService.hideColumns(this.columnDefs, this.gridColumnApi);
		}
		if (this.rowModelType === RowModelTypeEnum.ServerSide) {
			this.masterService
				.listenToRefreshData()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(data => {
					this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
				});
		}
		this.gridReady.emit({ masterTableImplementation: this.masterTableImplementation, gridApi: this.gridApi, columnApi: this.gridColumnApi });
		this.gridApi.closeToolPanel();
	}

	public onSelectionChanged(selectedRowEvent: RowSelectedEvent): void {
		const selectedRow = selectedRowEvent.data;
		const isSelected = selectedRowEvent.node.isSelected();
		const toggledRow = {
			selectedRow: selectedRow,
			isChosen: isSelected
		};
		this.masterService.updateSelectedRows(toggledRow);
	}

	public onFirstDataRendered(params: ActionParamsInterface): void {
		this.router.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(routeParams => {
			if (routeParams['selectedTab'] === TitleUsersEnum.Clients) {
				this.masterService.filterAndSelectRows(params.api, params.columnApi, routeParams['offerId'], OfferTableFieldEnum.OfferId);
			}
		});
	}

	public onExpandButton(): void {
		this.buttonsConfiguration.isExpandButtonShown = false;
		this.wrapper.nativeElement.requestFullscreen();
	}

	public onCancelExpandButton(): void {
		this.buttonsConfiguration.isExpandButtonShown = true;
		document.exitFullscreen();
	}

	public onColumnButton(): void {
		if (!this.isColumnSideBarOpened) {
			this.gridApi.openToolPanel('columns');
		} else {
			this.gridApi.closeToolPanel();
		}
		this.isColumnSideBarOpened = !this.isColumnSideBarOpened;
	}

	public onFilterButton(): void {
		if (!this.isFilterSideBarOpened) {
			this.gridApi.openToolPanel('filters');
		} else {
			this.gridApi.closeToolPanel();
		}
		this.isFilterSideBarOpened = !this.isFilterSideBarOpened;
	}

	public onExportButton(): void {
		this.gridApi.exportDataAsExcel();
	}

	public onPageSizeChanged(target: EventTarget): void {
		const numberOfRowsPerPage = target as HTMLSelectElement;
		this.paginationPageSize = parseInt(numberOfRowsPerPage.value);
		if (this.rowModelType === RowModelTypeEnum.ServerSide) {
			this.numberOfRowsReturnedByServer = parseInt(numberOfRowsPerPage.value);
			setTimeout(() => {
				this.masterService.resetRequest();
			}, 500);
		} else {
			this.gridApi.paginationSetPageSize(this.paginationPageSize);
		}
	}

	public onFilterChanged(event: FilterChangedEvent): void {
		if (this.masterService?.onFilterChanged) {
			this.masterService.onFilterChanged(this.gridApi, this.gridColumnApi, event);
		}
		if (this.rowModelType === RowModelTypeEnum.ServerSide) {
			this.masterService.resetRequest();
		}
	}

	public onSortChanged(event: SortChangedEvent): void {
		if (this.masterService?.onSortChanged) {
			this.masterService.onSortChanged(this.gridApi, this.gridColumnApi, event);
		}
		if (this.rowModelType === RowModelTypeEnum.ServerSide) {
			this.masterService.resetRequest();
		}
	}

	public onToggleColumn(): void {
		if (this.masterService?.hideColumns) {
			this.masterService.hideColumns(this.columnDefs, this.gridColumnApi);
		}
	}

	private createServerSideDatasource() {
		return {
			getRows: (params: IServerSideGetRowsParams) => {
				if (this.masterService.mapRequest) {
					this.request = this.masterService.mapRequest(params, this.paginationPageSize);
				}
				this.masterService
					.getRows(this.request)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(response => {
						if (this.masterService.checkIfNotLastRequest()) {
							params.successCallback(response, -1);
						} else {
							const currentNumberOfRows = this.gridApi.getDisplayedRowCount() - 1;
							params.successCallback(response, response.length);
						}
						if (this.masterService.checkSelectedRows) {
							this.masterService.checkSelectedRows(this.gridApi);
						}
						if (!response.length) {
							this.gridApi.showNoRowsOverlay();
						}
					});
			}
		};
	}
}
