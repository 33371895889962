import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseApiUrl } from './base-api-urls';
import { InsightsQueryParams } from '../shared/charts/charts.component';
import { QueryBuilderColumn } from '../shared/query-builder/models/query-builder-column.model';
import { map, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getFacebookBusinessOwnerId, UserState } from '../shared/state/user/user.reducer';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class EchartsService {
	constructor(protected http: HttpClient, private userStore: Store<UserState>) {}

	public getChartData(metrics: QueryBuilderColumn[], queryParams: InsightsQueryParams, endpoint: string): Observable<any[]> {
		const accessMappedMetrics = metrics.map(metric => {
			if (metric.accessName) {
				return {
					Name: metric.accessName,
					Aggregator: metric.Aggregator
				};
			} else {
				return metric;
			}
		});

		const query = {
			TableName: queryParams.tableName,
			Dimensions: queryParams.dimensions,
			Columns: accessMappedMetrics,
			Where: queryParams.whereConditionBlock
		};

		const displayNameMapping = metrics.filter(metric => metric.accessName);
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

		return this.userStore.pipe(
			select(getFacebookBusinessOwnerId),
			take(1),
			switchMap(facebookId => {
				return this.http.post(endpoint, query, { headers: headers }).pipe(
					map((response: any[]) => {
						response.forEach(row => {
							displayNameMapping.forEach(metric => {
								if (row[metric.accessName]) {
									row[metric.Name] = row[metric.accessName];
									delete row[metric.accessName];
								}
							});
						});
						return response;
					})
				);
			})
		);
	}
}
