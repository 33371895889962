<div class="reset-password-wrapper">
	<button (click)="close()" class="rp-close-button">
		<mat-icon>clear</mat-icon>
	</button>
	<img src="assets/icons/locked-image.svg" alt="reset password" />

	<section class="rp-text-wrapper">
		<h4>Forgot your password?</h4>
		<p>Please enter your Lolly account email address to reset your password.</p>
		<form>
			<app-input-text [control]="emailFormGroup.get('emailFormControl')" label="Email" placeholder="Enter email address" #emailInput></app-input-text>
			<app-input-text
				[control]="emailFormGroup.get('confirmFormControl')"
				[validatorMessages]="validationMessages"
				label="Confirm Email"
				placeholder="Confirm email address"
				#confirmEmailInput
			></app-input-text>
			<app-button
				class="rp-submit-btn"
				(buttonClicked)="onSubmit()"
				[buttonClass]="mainBtn.Primary"
				[buttonType]="typeBtn.Simple"
				[buttonLabel]="'Submit'"
			></app-button>
		</form>
	</section>
</div>
