import { Component, OnInit } from '@angular/core';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationState } from '../../state/authentication.reducer';
import { DisableFirstTimeCustomer } from '../../state/authentication.action';

@Component({
	selector: 'app-google-landing-page',
	templateUrl: './google-landing-page.component.html',
	styleUrls: ['./google-landing-page.component.scss']
})
export class GoogleLandingPageComponent implements OnInit {
	public mainBtn = ButtonClassEnum;
	public typeBtn = ButtonTypeEnum;

	constructor(private router: Router, private authStore: Store<AuthenticationState>) {}

	ngOnInit() {
		this.authStore.dispatch(new DisableFirstTimeCustomer());
	}

	public onAdsManagerClick(): void {
		this.router.navigate(['ads-manager/campaign']);
	}

	public onReportingClick(): void {
		this.router.navigate(['reports/smart-create']);
	}
}
