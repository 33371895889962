import { Gender } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/gender';
import { AgeRange } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/age-range';
import { FacebookLocationsView } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations-view';
import { KeyName } from '../../_models/savedAudience';
import { FacebookInterestsView } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests-view';
import {
	ParentTargetingTypeEnum,
	TargetingTypeEnum
} from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/targeting-type.enum';
import { AudienceData } from 'src/app/audience/models/audience-data-table.model';
import { RetargetingInterface } from './retargeting.interface';

export class TargetingAudience {
	public type?: TargetingTypeEnum;
	public targeting_type?: ParentTargetingTypeEnum;
	public gender?: Gender;
	public ageRange?: AgeRange = {
		minAge: 18,
		maxAge: 55
	};
	public locations?: FacebookLocationsView[] = [];
	public languages?: KeyName[] = [];
	public interests?: FacebookInterestsView[] = [];
	public excludedInterests?: FacebookInterestsView[] = [];
	public narrowInterests?: FacebookInterestsView[] = [];
	public customAudiences?: string[] = [];
	public includedCustomAudiences?: string[] = [];
	public excludeCustomAudiences?: string[] = [];
	public savedAudienceId?: string;
	public name?: string;
	public size?: number;
	public businessId?: number;
	public retargeting?: RetargetingInterface = null;
}
