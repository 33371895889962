<div class="bct-buttons-wrapper">
	<btn07 (onClick)="onCancelClick()">Cancel</btn07>
	<div class="bc-navigation">
		<btn01 *ngIf="saveButtonText !== 'Save as Draft'" (onClick)="onSaveAsDraftClick()">{{ saveButtonText }}</btn01>
		<div *ngIf="isPublishing && continueButtonText === 'Publish'" class="publish-hover">
			<span>Please wait until the current publishing action has finished.</span>
		</div>
		<btn01 class="test" (onClick)="onNextClick()" [disabled]="(isPublishing && continueButtonText === 'Publish') || disableNext">
			{{ continueButtonText }}
		</btn01>
	</div>
</div>
