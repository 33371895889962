<!-- notification input type text inline-->
<div class="line-wrapper">
	<div class="line-input-wrapper">
		<mat-form-field [ngClass]="{'line-error-message': control.invalid && control.touched,
                                'line-success-message': control.valid && control.touched,
                                'line-disabled-message': control.disabled}"
						appearance="standard">

			<mat-label>{{label}}</mat-label>

			<input *ngIf="isInputTypeText"
				   (focus)="autocompleteFocus()"
				   (focusout)="onFocusOut()"
				   [formControl]="control"
				   [disabled]="disabledInput"
				   matInput
				   disabled
				   placeholder="{{placeholder}}"
				   type="text">

			<div *ngIf="!isInputTypeText" class="currency-input-wrapper">
				<label *ngIf="isInputTypeCurrency">{{currencyStr}}&nbsp;</label>
				<input (focus)="autocompleteFocus()"
					   (focusout)="onFocusOut()"
					   [formControl]="control"
					   [disabled]="disabledInput"
					   [decimals]="decimals"
					   autocomplete="off"
					   matInput
					   appInputNumberValue
					   placeholder="{{placeholder}}"
					   type="text" />
			</div>


			<!--Error message -->
			<mat-error *ngIf="control.invalid">{{errorMessage}}</mat-error>
		</mat-form-field>
	</div>
</div>
