import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { areBreakdownsLoaded, PixelState } from '../state/pixel.reducer';
import * as pixelActions from '../state/pixel.actions';
import { skipWhile, switchMap, take } from 'rxjs/operators';
import { SharedState } from '../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';

@Injectable()
export class PixelResolver implements Resolve<Observable<void>> {
	constructor(private store: Store<PixelState>, private sharedStore: Store<SharedState>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Observable<void>> | Promise<Observable<void>> | Observable<void> {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.store.dispatch(new pixelActions.LoadEventsAggregations());
		this.store.dispatch(new pixelActions.LoadPixelAggregations());
		return this.store.pipe(
			select(areBreakdownsLoaded),
			skipWhile(loaded => !loaded),
			take(1),
			switchMap(loaded => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				return EMPTY;
			})
		);
	}
}
