export enum CatalogCategory {
	Sets = 'Sets',
	Products = 'Products',
	Variables = 'Variables'
}
export enum CatalogAgGrid {
	Products = 1,
	Sets = 2,
	Variants = 3,
	SmartSet = 4
}
export enum CatalogDatabase {
	Products = 2,
	Sets = 3,
	Variants = 4,
	SmartSet = 5
}
export enum CatalogFilter {
	Contains = 1,
	NotContains = 2,
	Equals = 3,
	NotEqual = 4,
	StartsWith = 5,
	EndsWith = 6,
	LessThan = 7,
	LessThanOrEquals = 8,
	GreaterThan = 9,
	GreaterThanOrEquals = 10,
	InRange = 11
}
export enum CatalogFilterData {
	Text = 1,
	Number = 2,
	DateTime = 3,
	Image = 4
}
