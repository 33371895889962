export enum AAACreateAdPanels {
	AdName,
	DynamicAds,
	AdFormat,
	AdCreate,
	Pixel,
	AppEvents,
	Pages,
	AdNavigation
}
