import { Injectable } from '@angular/core';
import { IServerSideGetRowsParams, Column } from 'ag-grid-community';
import { AgGridRequest } from '../../models/ag-grid-request';
import { of, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AddColumnsToReqService {
	constructor() {}

	addColumns(agGridParams: IServerSideGetRowsParams): AgGridRequest {
		const allColumns: Column[] = agGridParams.columnApi.getAllColumns();
		const requestedColumnsArray = [];
		let requestedColumnsString: string;
		const request: AgGridRequest = Object.assign(agGridParams.request);
		for (const column of allColumns) {
			requestedColumnsArray.push(column.getColId());
		}

		requestedColumnsString = requestedColumnsArray.toString();
		request['agColumns'] = requestedColumnsString;
		return request;
	}
}
