<div class="grid-section">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-gap-left"></div>
		<div class="grid-section-two-inner container">
			<div class="outside-form">
				<div class="filed-logo">
					<img src="assets/logo/lolly-logo.svg" />
				</div>
				<div class="text-description">
					<h2>{{ pageType }}</h2>
					<p>
						Please enter a new password for your Lolly account
					</p>
				</div>

				<form>
					<section class="margin-bottom-spacing">
						<app-input-password (enterPress)="onSubmit()" [control]="passwordFormControl" [isLogin]="false"></app-input-password>
					</section>

					<btn01 (onClick)="onSubmit()">Reset Password</btn01>
				</form>
			</div>
		</div>
		<div class="grid-gap-right"></div>
	</div>
</div>
