import { ValidatorMessages } from '../../../shared/form-input/validatorMessagesInputs';

export let validationNameMessages: ValidatorMessages[];
export let validationSurnameMessages: ValidatorMessages[];
export let validationFirstnameMessage: ValidatorMessages[];
export let validationEmailMessages: ValidatorMessages[];
export let validationRoleMessages: ValidatorMessages[];
export let validationPhoneMessages: ValidatorMessages[];

validationNameMessages = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory, please fill it in'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Name must not exceed 50 characters'
	}
];

validationFirstnameMessage = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory, please fill it in'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'First Name must not exceed 50 characters'
	}
];

validationSurnameMessages = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory, please fill it in'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Surname must not exceed 50 characters'
	}
];

validationEmailMessages = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory, please fill it in'
	},
	{
		validationType: 'pattern',
		validationMessage: 'Invalid email'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Email must not exceed 70 characters'
	}
];

validationRoleMessages = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory, please fill it in'
	}
];

validationPhoneMessages = [
	{
		validationType: 'pattern',
		validationMessage: 'Please enter a valid phone number'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Phone number must not exceed 15 characters'
	}
];
