import { Injectable } from '@angular/core';

import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartLabel } from '../../models/chart-label';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { ReportComponentService } from './report-component.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import EChartOption = echarts.EChartOption;
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { GridLayout } from '../../models/grid-layout';

@Injectable({
	providedIn: 'root'
})
export class AreaChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		private widgetComponentService: ReportComponentService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel): EChartOption {
		const label = new ChartLabel('left', 'middle', 'insideBottom', 0, 20, 12, widget.details.reportProperty.showDataLabels);
		const chartOptions = this.initChartOptions(widget);
		chartOptions.tooltip.axisPointer = {
			type: 'cross'
		};

		if (widget.details.compareData) {
			const firstData = this.createChartSourceForComplexData(widget, false, false, false, label);
			const secondData = this.createChartSourceForComplexData(widget, false, false, false, label, true);

			firstData.data.areaStyle = {};
			secondData.data.areaStyle = {};

			chartOptions.xAxis = this.widgetComponentService.createXAxisForComparison(widget, firstData.dimensions, secondData.dimensions);
			chartOptions.yAxis = {
				type: 'value'
			};
			chartOptions.series = [];
			chartOptions.series = [firstData.data[0], secondData.data[0]];
		} else {
			const chartData = this.createChartSourceForComplexData(widget, false, false, true, label);
			chartOptions.xAxis = this.createPrimaryAxis(chartData, widget, 35, widget.details.reportProperty.horizontalAxisTitle);
			chartOptions.yAxis = this.createSecondaryAxis(chartData, widget, 'yAxisIndex', widget.details.reportProperty.verticalAxisTitle, 50, 60);
			chartOptions.series = chartData.data;
			chartOptions.dataZoom = [
				{
					show: true,
					type: 'inside',
					filterMode: 'none',
					xAxisIndex: [0]
				},
				{
					show: true,
					type: 'inside',
					filterMode: 'none',
					yAxisIndex: [0]
				}
			];
			chartOptions.nameTextStyle = {
				color: '#424242'
			};
			chartOptions.grid = new GridLayout('120', '20', '20', '115', false);
			chartOptions.legend.data = [];
			chartOptions.legend.data = chartData.data.map(item => item.name);
		}

		return chartOptions;
	}
}
