<div class="pricing">
	<div class="tarrif-plan" *ngFor="let tarrif of tarrifPlans">
		<ngTemplate [class.disable]="tarrif.disable">
			<div class="tarrif-plan-header" [ngClass]="{ 'display-none': !tarrif.header }">{{ tarrif.header }}</div>
			<div class="tarrif-plan-content">
				<div class="title">{{ tarrif.tag }}</div>
				<div class="icons"><img src="assets/images/signup/tarrif-icon.svg" /></div>
				<div class="price">
					<span class="amount">{{ getPlanAmount(tarrif) }}</span>
					<span class="tag">{{ tarrif.tagLine }}</span>
				</div>
				<div class="features">
					<div class="list" *ngFor="let feature of tarrif.features">
						<div class="list-item-description">
							{{ feature }}
						</div>
						<div class="list-item-check">L</div>
					</div>
				</div>
			</div>
			<div class="tarrif-plan-footer" [class.topMargin]="tarrif.header">
				<button (click)="onSelectTarrif(tarrif)" [disabled]="tarrif.disable" class="btn-gcb-01 action-button">{{ tarrif.buttonText }}</button>
			</div>
		</ngTemplate>
	</div>
</div>
