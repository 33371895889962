<app-expansion-panel-sc
	(click)="onExpansionClick.emit()"
	[isSelected]="isPanelSelected"
	[expansionTitle]="'Targeting'"
	[isExpandable]="false"
	[infoHoverModel]="targetInfoHover"
>
	<div expansion-content class="tc-content">
		<div class="catalog-targeting">
			<div class="header" *ngIf="isCatalogTargeting">
				<a
					class="toggle"
					[class.active]="catalogTargetingType === targetingMode.ReTargeting"
					(click)="toggleCatalogTargeting(targetingMode.ReTargeting)"
				>
					Retargeting
				</a>
				<a class="toggle" [class.active]="catalogTargetingType === targetingMode.NewTarget" (click)="toggleCatalogTargeting(targetingMode.NewTarget)">
					Target new audience
				</a>
			</div>
			<ng-container *ngIf="isCatalogTargetingType(targetingMode.ReTargeting)">
				<app-re-targeting
					[productSetId]="productSetId"
					[productCatalogSets]="productCatalogSets"
					[retargetingControl]="reTargetingControl"
					(productAudienceSpec)="getSelectedRetargetingData($event)"
				></app-re-targeting>
			</ng-container>
			<ng-container *ngIf="!isCatalogTargetingType(targetingMode.ReTargeting) && !isAppInstall">
				<span class="title">Audience</span>
				<mat-radio-group class="tc-radio-group" [formControl]="formGroup.get('targetingType')" name="targeting">
					<mat-radio-button
						class="tc-radio-button"
						(click)="onTargetTypeClick(targetingType.NewAudience)"
						[value]="targetingType.NewAudience"
						name="targeting"
					>
						<span>Create new</span>
					</mat-radio-button>
					<mat-radio-button
						*ngIf="!isSpecialAddCategorySelected"
						(click)="onTargetTypeClick(targetingType.SavedAudience)"
						[value]="targetingType.SavedAudience"
						name="targeting"
					>
						<span>Saved audience</span>
					</mat-radio-button>
				</mat-radio-group>

				<!-- Saved Audience -->
				<span class="tc-subtitle-small" *ngIf="formGroup.get('targetingType').value === targetingType.SavedAudience && !isSpecialAddCategorySelected">
					Select a saved audience
				</span>
				<div class="tc-saved-audience" *ngIf="formGroup.get('targetingType').value === targetingType.SavedAudience && !isSpecialAddCategorySelected">
					<app-live-search-dropdown
						[dropdownWidth]="21"
						[inputControl]="formGroup.get('savedAudience')"
						[dropdownData]="savedAudiences"
						[selectedData]="selectedSavedAudiences"
						[columns]="audienceColumns"
						[singleSelection]="true"
						[inputLabel]="'Search for saved audience'"
						[inputPlaceholder]="'My audience'"
						(selectionChange)="onDropdownDataChange($event, 'selectedSavedAudiences')"
					></app-live-search-dropdown>
				</div>

				<!--		New Audience-->
				<div class="section-text d-flex">
					<div class="tc-subtitle-small mr-5px">Include custom audience</div>
					<div>
						<info-tooltip
							[position]="'top'"
							title="Use Custom Audiences to reach people who've already interacted with your business or Lookalike Audiences to reach new people who are similar to your existing customers."
							[width]="457"
						></info-tooltip>
					</div>
				</div>
				<div class="tc-include-audience">
					<app-live-search-dropdown
						[dropdownWidth]="21"
						[dropdownData]="customAudiences"
						[selectedData]="selectedIncludeCustomAudiences"
						[columns]="customAudienceColumns"
						[inputLabel]="'Create Custom audience'"
						[inputPlaceholder]="'My audience'"
						(selectionChange)="onDropdownDataChange($event, 'selectedIncludeCustomAudiences')"
					></app-live-search-dropdown>
				</div>
				<div class="section-text d-flex">
					<div class="tc-subtitle mr-5px">Gender</div>
					<div>
						<info-tooltip [position]="'top'" title='Show your ads to either men or women, or select "All" for both.' [width]="457"></info-tooltip>
					</div>
				</div>

				<div class="tc-gender-group">
					<span
						class="tc-gender"
						(click)="onGenderClick(genders.All)"
						[ngClass]="{ 'tc-gender-selected': gender === genders.All, 'tc-gender-hidden-border': gender <= genders.Women }"
					>
						All
					</span>

					<span
						class="tc-gender"
						(click)="onGenderClick(genders.Women)"
						[ngClass]="{
							'tc-gender-selected': gender === genders.Women,
							'tc-gender-hidden-border': gender >= genders.Women,
							'tc-gender-not-allowed': isSpecialAddCategorySelected
						}"
					>
						Women
					</span>

					<span
						class="tc-gender tc-gender-hidden-border"
						(click)="onGenderClick(genders.Men)"
						[ngClass]="{ 'tc-gender-selected': gender === genders.Men, 'tc-gender-not-allowed': isSpecialAddCategorySelected }"
					>
						Men
					</span>
				</div>
				<div class="section-text d-flex">
					<div class="tc-subtitle mr-5px">Age</div>
					<div>
						<info-tooltip
							[position]="'top'"
							title="Select the minimum and maximum age of the people who will find your ad relevant."
							[width]="457"
						></info-tooltip>
					</div>
				</div>
				<div class="tc-age">
					<ng5-slider class="tc-slider" (userChangeEnd)="onAgeChange()" [(highValue)]="maxAge" [(value)]="minAge" [options]="options"></ng5-slider>
				</div>
				<br />
				<div class="section-title">
					<font08>Location & Languages</font08>
				</div>
				<div class="section-text d-flex">
					<div class="tc-subtitle mr-5px">Locations</div>
					<div>
						<info-tooltip
							[position]="'top'"
							title="Enter one or more global regions, countries, counties/regions/states, cities, postal codes, addresses, or
									Designated Market Areas® to show or exclude your ad to people in those locations. Location targeting is not
									available in all countries."
							[width]="457"
						></info-tooltip>
					</div>
				</div>
				<div class="tc-locations">
					<app-live-search-dropdown
						(focusout)="validateLocation()"
						[inputControl]="formGroup.get('location')"
						[dropdownWidth]="21"
						[dropdownData]="locations"
						[selectedData]="selectedLocations"
						[selfFilter]="false"
						[columns]="locationColumns"
						[inputLabel]="'Search location'"
						[inputPlaceholder]="'London'"
						(selectionChange)="onDropdownDataChange($event, 'selectedLocations')"
					></app-live-search-dropdown>
				</div>
				<div class="section-text d-flex">
					<div class="tc-subtitle mr-5px">Language</div>
					<div>
						<info-tooltip
							[position]="'top'"
							title="Enter a language to show your ads to people who use a language that isn't common to your location. Otherwise, leave this blank."
							[width]="457"
						></info-tooltip>
					</div>
				</div>
				<div class="tc-language">
					<app-live-search-dropdown
						[inputControl]="formGroup.get('languages')"
						[dropdownWidth]="21"
						[dropdownData]="languages"
						[selectedData]="selectedLanguages"
						[columns]="languagesColumns"
						[inputLabel]="'Search languages'"
						[inputPlaceholder]="'English'"
						(selectionChange)="onDropdownDataChange($event, 'selectedLanguages')"
					></app-live-search-dropdown>
				</div>

				<div class="section-text d-flex">
					<div class="section-title mr-5px">
						<font08>Detailed Targeting</font08>
					</div>
					<div>
						<info-tooltip
							[position]="'top'"
							title="Define your audience by including or excluding demographics, interests and behaviors. Adding more targeting details here will expand your audience to include more people."
							[width]="457"
						></info-tooltip>
					</div>
				</div>
				<span class="tc-subtitle-small">Include people that match</span>
				<div class="tc-interests">
					<app-live-search-dropdown
						[inputControl]="formGroup.get('includedInterests')"
						[dropdownWidth]="21"
						[dropdownData]="interests"
						[selectedData]="selectedInterests"
						[selfFilter]="false"
						[columns]="interestColumns"
						[inputLabel]="'Add demographics, interests or behaviours'"
						[inputPlaceholder]="'Fishing'"
						(selectionChange)="onDropdownDataChange($event, 'selectedInterests')"
					></app-live-search-dropdown>
				</div>
				<div class="exclusion-buttons">
					<div class="tc-narrow-btn-spacing">
						<btn04 *ngIf="!showNarrowInterests" (onClick)="onShowNarrowInterestsClick()">Narrow Audience</btn04>
						<btn04 *ngIf="!showExcludeInterests" (onClick)="onShowExcludedInterestsClick()">Exclude Audience</btn04>
					</div>
				</div>
				<span class="tc-subtitle-small" *ngIf="showNarrowInterests">Narrow audience</span>
				<div class="tc-exclude-extral" *ngIf="!isSpecialAddCategorySelected">
					<app-live-search-dropdown
						*ngIf="showNarrowInterests"
						[inputControl]="formGroup.get('narrowInterests')"
						[dropdownWidth]="21"
						[dropdownData]="narrowInterests"
						[selectedData]="selectedNarrowedInterest"
						[selfFilter]="false"
						[columns]="interestColumns"
						[inputLabel]="'Narrow demographics, interests or behaviours'"
						[inputPlaceholder]="'Fishing'"
						(selectionChange)="onDropdownDataChange($event, 'selectedNarrowedInterest')"
					></app-live-search-dropdown>
				</div>
				<span class="tc-exclude-extra"></span>
				<span class="tc-subtitle-small" *ngIf="showExcludeInterests">Exclude people that match</span>
				<div class="tc-exclude-extral" *ngIf="!isSpecialAddCategorySelected">
					<app-live-search-dropdown
						*ngIf="showExcludeInterests"
						[inputControl]="formGroup.get('excludedInterests')"
						[dropdownWidth]="21"
						[dropdownData]="excludedInterests"
						[selectedData]="selectedExcludedInterest"
						[selfFilter]="false"
						[columns]="interestColumns"
						[inputLabel]="'Exclude demographics, interests or behaviours'"
						[inputPlaceholder]="'Fishing'"
						(selectionChange)="onDropdownDataChange($event, 'selectedExcludedInterest')"
					></app-live-search-dropdown>
				</div>
				<hr />
			</ng-container>
			<ng-container *ngIf="isAppInstall">
				<span class="tc-subtitle tc-extra">
					Locations
					<span class="text-danger">*</span>
				</span>
				<div class="tc-locations">
					<app-live-search-dropdown
						(focusout)="validateLocation()"
						[inputControl]="formGroup.get('location')"
						[dropdownWidth]="21"
						[dropdownData]="locations"
						[selectedData]="selectedLocations"
						[selfFilter]="false"
						[columns]="locationColumns"
						[inputLabel]="'Search location'"
						[inputPlaceholder]="'London'"
						(selectionChange)="onDropdownDataChange($event, 'selectedLocations')"
					></app-live-search-dropdown>
				</div>
				<span class="tc-subtitle">Language</span>
				<div class="tc-language">
					<app-live-search-dropdown
						[inputControl]="formGroup.get('languages')"
						[dropdownWidth]="21"
						[dropdownData]="languages"
						[selectedData]="selectedLanguages"
						[columns]="languagesColumns"
						[inputLabel]="'Search languages'"
						[inputPlaceholder]="'English'"
						(selectionChange)="onDropdownDataChange($event, 'selectedLanguages')"
					></app-live-search-dropdown>
				</div>
			</ng-container>
		</div>
		<div class="tc-button-group" *ngIf="!isCatalogTargetingType(targetingMode.ReTargeting) && !isAppInstall">
			<btn01
				class="tc-button"
				*ngIf="formGroup.get('targetingType').value === targetingType.SavedAudience"
				[disabled]="!selectedSavedAudiences.length"
				(onClick)="audienceNamePopUp(buttonNameEnum.Save)"
			>
				{{ buttonNameEnum.Save }}
			</btn01>
			<btn01
				*ngIf="
					formGroup.get('targetingType').value === targetingType.NewAudience || formGroup.get('targetingType').value === targetingType.SavedAudience
				"
				[disabled]="selectedLocations?.length < 1"
				(onClick)="audienceNamePopUp(buttonNameEnum.SaveAsNew)"
			>
				{{ buttonText }}
			</btn01>
		</div>
	</div>
</app-expansion-panel-sc>
