import { TodoItemFlatNode, TodoItemNode, TreeChecklistComponent } from './tree-checklist.component';

export class TreeChecklistService {
	constructor(private componentInstance: TreeChecklistComponent) {}

	public updateDataSource(dataSource: TodoItemNode[]): void {
		this.componentInstance.dataChange.next(dataSource);
	}

	public updateSelectionForDisabled(): void {
		this.componentInstance.updateSelectionForDisabledSubject.next();
	}

	public selectAll(): void {
		this.componentInstance.selectAllSubject.next();
	}

	public selectNodes(nodeList: any): void {
		this.componentInstance.selectNodesSubject.next(nodeList);
	}

	public deselectAll(): void {
		this.componentInstance.deselectNodesSubject.next();
	}

	public disableNodes(nodeList: any[]): void {
		this.componentInstance.disableNodesSubject.next(nodeList);
	}

	public enableNodes(nodeList: any[]): void {
		this.componentInstance.enableNodesSubject.next(nodeList);
	}

	public disableAll(): void {
		this.componentInstance.disableAllSubject.next();
	}

	public enableAll(): void {
		this.componentInstance.enableAllSubject.next();
	}

	public getAllFlattenNodes(): TodoItemFlatNode[] {
		return this.componentInstance.getAllFlattenNodes();
	}

	public expandAll(): void {
		this.componentInstance.expandAllSubject.next();
	}

	public collapseAll(): void {
		this.componentInstance.collapseAllSubject.next();
	}
}
