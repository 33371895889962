import { OptimizationLevelsEnum } from './optimization-level.enum';
import { OptimizationType } from './optimization-type.enum';
import { ConfidenceValue } from './confidence-value';
import { RecommendationType } from './recomendation-type.enum';
import { RecommendationBreakdown } from './recommendation-breakdown.model';
import { RecommendationCategory } from './recommendation-category.enum';
import { RecommendationMetric } from './recommendation-metric.model';
import { RecommendationPlatform } from './recommendation-platform.enum';
import { RecommendationEditDestination } from './recommendation-redirect.enum';

export class Recommendation {
	public id: string;
	public optimizationType: OptimizationType;
	public template: string;
	public level: OptimizationLevelsEnum;
	public recommendationType: RecommendationType;
	public source: RecommendationPlatform;
	public structureId: string;
	public createdAt: string;
	public structureName: string;
	public campaignId: string;
	public parentId: string;
	public confidence: number;
	public importance: ConfidenceValue;
	public category: RecommendationCategory;
	public title: string;
	public parentName: string;
	public campaignName: string;
	public adAccountId: string;
	public channel: string; // Not Source channel because it's string not number in the BE
	public metrics: RecommendationMetric[];
	public actionBreakdown: RecommendationBreakdown;
	public breakdown: RecommendationBreakdown;
	public redirectForEdit: RecommendationEditDestination;
	public applicationDetails: string;

	constructor(
		id: string,
		optimizationType: OptimizationType,
		template: string,
		level: OptimizationLevelsEnum,
		recommendationType: RecommendationType,
		recommendationPlatform: RecommendationPlatform,
		structureId: string,
		campaignId: string,
		parentId: string,
		createdAt: string,
		confidence: number,
		importance: ConfidenceValue,
		category: RecommendationCategory,
		channel: string
	) {
		this.id = id;
		this.optimizationType = optimizationType;
		this.template = template;
		this.level = level;
		this.recommendationType = recommendationType;
		this.source = recommendationPlatform;
		this.structureId = structureId;
		this.createdAt = createdAt;
		this.confidence = confidence;
		this.importance = importance;
		this.category = category;
		this.channel = channel;
		this.title = recommendationType + ' ' + (this.category ? this.category : '');
	}
}
