<app-expansion-panel-sc
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[isExpandable]="false"
	[expansionTitle]="'Optimization & Delivery'"
	[infoHoverModel]="{ message: 'Explanation text goes here' }"
>
	<div expansion-content>
		<div class="oad-single-dropdown">
			<span>Optimization Goal</span>
			<div class="oad-dropdown-wrapper">
				<drp01
					(valueChanges)="getClicksViewsAndBillingEvents()"
					[data]="validGoals"
					[control]="optimizationDelivery.get('optimizationGoal')"
					[valuePrimitive]="true"
				></drp01>
			</div>
		</div>
		<div class="form__group">
			<p class="form__label">Bid Control</p>
			<tb04 [control]="optimizationDelivery.get('bidControl')" [currency]="'USD'" [placeHolderTextBox]="'This is the placeholder'"></tb04>
		</div>
		<div class="oad-single-dropdown" *ngIf="validClicksAndViews?.length">
			<span>Conversions window</span>
			<div class="oad-dropdown-wrapper">
				<drp01 [data]="validClicksAndViews" [control]="optimizationDelivery.get('clicksAndViews')" [valuePrimitive]="true"></drp01>
			</div>
		</div>
		<div class="oad-single-dropdown" *ngIf="validBillingEvents?.length">
			<span>When you get charged</span>
			<div class="oad-dropdown-wrapper">
				<drp01 [data]="validBillingEvents" [control]="optimizationDelivery.get('billingEvents')" [valuePrimitive]="true"></drp01>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
