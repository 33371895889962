import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from '../base-api-urls';
import { Observable } from 'rxjs';
import { KeyName } from '../../_models/savedAudience';
import { FacebookLocations } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations';
import { FacebookInterests } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';

@Injectable({
	providedIn: 'root'
})
export class AudienceTargetingService {
	// private baseURL = `${BaseApiUrl.FacebookAquaPythonCampaignBuilder}targeting-search/`;

	constructor(private http: HttpClient) {}

	// public getInterests(value: string, adAccountId?: string): Observable<FacebookInterests[]> {
	// 	return this.http.get<FacebookInterests[]>(`${this.baseURL}interests?ad_account_id=${adAccountId}&query=${value}`);
	// }

	// public getRegulatedInterests(adCategory: string, adAccountId?: string): Observable<FacebookInterests[]> {
	// 	return this.http.get<FacebookInterests[]>(`${this.baseURL}regulated-interests?ad_account_id=${adAccountId}&categories=${adCategory}`);
	// }

	// public getLocations(value: string): Observable<FacebookLocations[]> {
	// 	return this.http.get<FacebookLocations[]>(`${this.baseURL}locations?query=${value}`);
	// }

	// public getLanguages(): Observable<KeyName[]> {
	// 	return this.http.get<KeyName[]>(`${this.baseURL}languages`);
	// }
}
