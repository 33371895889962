<div class="main">
	<div class="header">
		<div class="image">
			<img class="icon" src="assets/icons/facebook2.svg" />
		</div>
		<div class="header-text">
			<div class="top-text">
				Connect your Facebook Channel
				<i-tooltip [title]="'Loding...'">
					<div class="q-mark">
						?
					</div>
				</i-tooltip>
			</div>
			<div class="tagline-text">
				Select one or multiple account to connect with Lolly.
				<span class="learn-more">Learn More</span>
			</div>
		</div>
	</div>
	<div class="multi-select-div">
		<p class="select-text">
			Select accounts
		</p>
		<div class="selected-div">
			<mat-accordion class="mat-headers-align">
				<mat-expansion-panel hideToggle *ngFor="let adsAccount of adsAccountFacebook; let i = index">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<span class="material-icons">
								arrow_drop_down
							</span>
							<span (click)="$event.stopPropagation()">
								<input
									type="checkbox"
									id="{{ adsAccount.businessOwnerId }}"
									(click)="selectAndDEselectAllGroups(adsAccount)"
									class="checkbox-select"
								/>
							</span>
							{{ adsAccount.businessOwnerName }}
						</mat-panel-title>
						<mat-panel-description></mat-panel-description>
					</mat-expansion-panel-header>
					<div class="multi-select">
						<div class="single-select" *ngFor="let ads of adsAccount.adaccounts; let i = index">
							<div class="select-part">
								<input type="checkbox" id="{{ ads.accountId }}" (click)="selectFacebookAdsQue(ads)" class="checkbox-select" />
								{{ ads.accountName }}
							</div>
						</div>
					</div>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
		<p class="select-text">
			Selected Account
		</p>
		<div class="selected-ads">
			<div class="single-selected-button" *ngFor="let selectedAd of selectedFacebookAds">
				{{ selectedAd.accountName }}
				<img src="assets/icons/removeicon.svg" (click)="removeSelectedFacebookAdsQue(selectedAd.accountId)" alt="" />
			</div>
		</div>
		<div class="connect-button">
			<btn01 (click)="connectFacebookAccount()">CONNECT</btn01>
		</div>
	</div>
</div>
