import { Injectable, NgZone } from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class GoogleOauthService {
	private auth: gapi.auth2.GoogleAuth;
	private user: gapi.auth2.GoogleUser;
	constructor(private zone: NgZone) {
		this.load()
			.then(() => this.init())
			.then(auth => {
				this.auth = auth;
			});
	}

	private load(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.zone.run(() => {
				gapi.load('auth2', {
					callback: resolve,
					onerror: reject
				});
			});
		});
	}

	private init(): Promise<gapi.auth2.GoogleAuth> {
		return new Promise((resolve, reject) => {
			this.zone.run(() => {
				const auth = gapi.auth2.init({
					client_id: '17118844476-fq28ato7jbgv4095u7cd6crlp9m4as55.apps.googleusercontent.com',
					scope: 'profile email'
				});
				resolve(auth);
			});
		});
	}

	loginForUser(): Promise<gapi.auth2.GoogleUser> {
		return this.zone.run(() => {
			return this.auth.signIn().then(user => {
				this.user = user;
				return user;
			});
		});
	}

	loginForCode(): Promise<{ code: string }> {
		return this.zone.run(() => {
			return this.auth.grantOfflineAccess().then(response => {
				return response;
			});
		});
	}
}
