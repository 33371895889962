import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';

@Component({
	selector: 'app-knowledge-list',
	templateUrl: './knowledge-list.component.html',
	styleUrls: ['./knowledge-list.component.scss']
})
export class KnowledgeListComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

	constructor(private matDialog: MatDialog, public router: Router, public kbService: KnowledgebaseService) {}

	ngOnInit(): void {}

	public showKnowledgeView(search?: string): void {
		this.kbService.knowledgebaseHome$.next(false);
		this.kbService.knowledgebaseShowView$.next(true);
		this.kbService.searchValue$.next(search);
	}
}
