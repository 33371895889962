import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BuildCampaignPanelsEnum } from '../../../../campaign-smart-create/components/build-campaign-template/build-campaign-panels.enum';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ImageStateEnum } from '../../../hover-select-directive/image-state.enum';
import { CatalogNodeInterface } from '../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { CatalogItemsInterface } from '../../../../campaign-smart-create/catalogs/catalog.items.interface';
import { ObjectivesEnum } from '../../../../campaign-smart-create/models/objectives.enum';
import { SubObjectives } from '../../../../campaign-smart-create/models/sub-objectives';
import { LeadGenerationEnum } from './models/lead-generation.enum';

export interface DataInterface {
	name: string;
	description: string;
	value: LeadGenerationEnum;
}

@Component({
	selector: 'app-lead-generation',
	templateUrl: './lead-generation.component.html',
	styleUrls: ['./lead-generation.component.scss']
})
export class LeadGenerationComponent implements OnInit, OnDestroy {
	public datas: DataInterface[];
	public selectedLeadMethod: LeadGenerationEnum | LeadGenerationEnum[];
	private unsubscriber$ = new Subject();
	constructor() {}

	public ngOnInit(): void {
		this.setDatas();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private setDatas(): void {
		this.datas = [
			{ name: 'Instant Forms', description: 'Let people submit a form to become leads.', value: LeadGenerationEnum.InstantForms },
			{ name: 'Calls', description: 'Let people call your business to become leads.', value: LeadGenerationEnum.Calls },
			{ name: 'Automated chat', description: 'Let people message your business to become leads..', value: LeadGenerationEnum.AutomatedChat }
		];
	}

	public updateLeadMethodState(method: DataInterface): void {
		this.selectedLeadMethod = Object.values(method.value);
		localStorage.setItem('method', LeadGenerationEnum[method.value]);
	}
}
