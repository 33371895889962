import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ColumnApi, DetailGridInfo, GridApi } from 'ag-grid-community';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionTypeEnum } from '../../../../back-office/users/action-type.enum';
import { AgGridColumnDefEnum } from '../../../../back-office/users/ag-grid-column-def.enum';
import { DeleteActionEnum } from '../../../../back-office/users/delete-action.enum';
import { ResetActionEnum } from '../../../../back-office/users/reset-action.enum';

import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { MiscellaneousPermissions } from '../../../../shared/permisions/enums/miscellaneous-permissions';
import { Modules } from '../../../../shared/permisions/enums/modules';
import { PermissionsDirectiveInterface } from '../../../../shared/permisions/models/permissions-directive.interface';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../../shared/state/shared.actions';
import { getFacebookBusinessOwnerId } from '../../../../shared/state/user/user.reducer';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { User } from '../../../../_models/user-models/user';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { UserServiceApi } from '../../../../_services/user/user.api.service';
import { FrameworkComponentsUserManagementInterface } from '../../../models/framework-components-user-management-interface';
import { NewUserModel } from '../../../models/new-user-model';
import { UserActionButtonReturnInterface } from '../../../models/user-action-button-return-interface';
import { UserManagementState } from '../../../state/user-management.reducer';
import { ToggleIconsInterface } from '../../../../shared/master-table/models/toggle-icons.interface';
import { MasterTableGridReadyInterface } from '../../../../shared/master-table/models/master-table-grid-ready.interface';
import { Router } from '@angular/router';
import { DatatableEnum } from 'src/app/user-management/models/DatatableEnum';
import { PermissionsApiOptionsService } from 'src/app/shared/permisions/permissions-api-options.service';
import { PermissionsRoleInterface } from 'src/app/shared/permisions/table-permissions/permissions-role.interface';
import { VerifyAddUserComponent } from '../modals/verify-add-user/verify-add-user.component';
import { resolve } from 'path';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { CompleteModalComponent } from '../modals/complete-modal/complete-modal.component';
import { SetAdminManagement } from 'src/app/user-management/state/user-management.actions';

@Component({
	selector: 'app-filed-users-new',
	templateUrl: './filed-users-new.component.html',
	styleUrls: ['./filed-users-new.component.scss']
})
export class FiledUsersNewComponent implements OnInit, OnDestroy {
	public rowDataLength = 0;
	public accessTypes = ['Standard', 'View only'];

	public paginationOptions = [25, 50, 100, 200];
	public defaultPageSize = 25;
	public permissionModelUsers: PermissionsDirectiveInterface = {
		moduleName: Modules.Miscellaneous,
		permissions: [MiscellaneousPermissions.IsBusinessOwner, MiscellaneousPermissions.IsClientEmployee]
	};
	public frameworkComponents: FrameworkComponentsUserManagementInterface;
	public rowData: any[];
	public filteredRowData: User[];
	public businessOwnerFacebookId: string;
	public formIsValid: boolean;
	public searchFormControl: FormGroup;
	public employeeFromControl: FormGroup;
	public subscription: Subscription;
	public addingUser: User;
	public userId: number;

	private unsubscriber$ = new Subject<void>();
	public columnDef: any = [
		{
			key: 'name',
			label: 'User',
			type: DatatableEnum.none
		},
		{
			key: 'roles',
			label: 'Access Level',
			type: DatatableEnum.select,
			options: []
		},
		{
			key: 'date',
			label: 'Added At',
			type: DatatableEnum.none
		},
		{
			key: 'status',
			label: 'Status',
			type: DatatableEnum.alert
		},
		{
			key: 'email',
			label: 'Email',
			type: DatatableEnum.none
		},
		{
			key: 'action',
			label: 'Actions',
			type: DatatableEnum.button,
			options: []
		}
	];

	public pageSize: number = 25;

	public userRoles: PermissionsRoleInterface[];
	public currentUser: any;

	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private formBuilder: FormBuilder,
		private matDialog: MatDialog,
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService,
		private store: Store<UserManagementState>,
		private toaster: ToastNotificationService,
		private router: Router,
		private roleServices: PermissionsApiOptionsService,
		private sharedStore: Store<SharedState>
	) {}

	public ngOnInit(): void {
		this.fetchStore();
		this.getTableData();
		this.createForm();
		this.filteredData();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private fetchStore(): void {
		this.store.pipe(select(getFacebookBusinessOwnerId)).subscribe(businessOwnerFacebookId => {
			this.businessOwnerFacebookId = businessOwnerFacebookId;
		});
	}

	private createForm(): void {
		this.searchFormControl = this.formBuilder.group({
			searchForm: new FormControl(null)
		});
	}

	public addNewUser(): void {
		this.router.navigate(['/user-management/user-admin/add']);
	}

	public getRoles(): void {
		this.roleServices
			.getAllRoles()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(roles => {
				if (roles) {
					this.userRoles = roles;
				}
			});
	}

	public getTableData(): void {
		this.userServiceApi
			.getAllUsersByOwnerFacebookId(this.businessOwnerFacebookId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.rowData = res;
					this.rowDataLength = this.rowData.length;
					this.filteredRowData = this.paginate(this.rowData, this.pageSize, 1);
				},
				error => console.log(error)
			);
	}

	public filteredData(): void {
		this.searchFormControl
			.get('searchForm')
			.valueChanges.pipe()
			.subscribe(value => {
				value = value.toLowerCase();
				this.filteredRowData = this.rowData.filter(data => {
					return (
						data.email.toLowerCase().includes(value) ||
						data.firstName.toLowerCase().includes(value) ||
						data.lastName.toLowerCase().includes(value) ||
						data.phoneNumber.includes(value) ||
						data.id === +value
					);
				});
				this.filteredRowData = [...this.filteredRowData];
			});
	}

	public onPageChanged({ oldPage, newPage }): void {
		this.filteredRowData = this.paginate(this.rowData, this.pageSize, newPage);
	}

	public onSelectItem(value: any, items: any): void {
		items.access = value;
		this.processUser(items);
	}
	public paginate(arrayOfOject: any, pageSize: number, pageNumber: number): any {
		return arrayOfOject.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
	}

	public isAccountOwner(row: any): boolean {
		return false;
	}
	public editUser(item: any): void {
		const payload: any = {
			user: {
				name: item.firstName + ' ' + item.lastName,
				email: item.email,
				roles: ''
			},
			acccounts: [],
			permission: []
		};
		this.store.dispatch(new SetAdminManagement(payload));
		this.router.navigateByUrl(`user-management/user-admin/edit?userId=${item.id}`);
	}
	public fixDate(date: string): string {
		if (!date || date == '') return date;
		let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nor', 'Dec'];
		let dateStr = date.split(' ');
		let today = new Date(dateStr[0]);
		const yyyy = today.getFullYear();
		let mm = months[today.getMonth()];
		let dd = today.getDate();
		return `${dd}-${mm}-${yyyy}`;
	}
	public onRemoveClick(item: any): void {
		this.currentUser = item;
		const dialogRef = this.dialog.open(VerifyAddUserComponent, {
			width: '480px',
			height: '367px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'User Removal',
			text: `Are you sure you want to continue to remove ”${item.firstName} ${item.lastName}” from user admin?`,
			closeBtn: true,
			actionBtn: true,
			actionBtnText: 'CONFIRM'
		};
		dialogRef.afterClosed().subscribe((res: any) => {
			if (res == true) {
				this.removeUserAccount();
			}
		});
	}

	public removeUserAccount() {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.userServiceApi
			.removeUser(this.currentUser.id)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					let index = this.rowData.findIndex(user => user.id == this.currentUser.id);
					if (index > -1) {
						this.rowData.splice(index, 1);
						this.paginate(this.rowData, this.pageSize, 1);
					}
					const dialogRef = this.dialog.open(CompleteModalComponent, {
						width: '480px',
						height: '360px',
						disableClose: true
					});
					dialogRef.componentInstance.modelDetail = {
						title: 'User Removal',
						text: `“${this.currentUser.firstName} ${this.currentUser.lastName}” has now removed from your user admin account.`,
						closeBtn: false,
						actionBtn: false
					};
					dialogRef.afterClosed().subscribe(() => {
						this.router.navigate(['/user-management/user-admin']);
					});
				},
				() => this.toaster.sendErrorToast('Cannot remove user. Please try again later!'),
				() => {
					this.getTableData();
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public processUser(user: any): void {
		this.fetchStore();
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		let users: NewUserModel = {
			id: +user.id,
			firstName: user.firstName,
			lastName: user.lastName,
			email: user.email,
			phoneNumber: user.phoneNumber,
			position: user.position,
			accountState: user.accountState,
			FiledPermissionsIds: user.facebookBusinessOwnerId,
			access: user.access
		};
		this.userServiceApi
			.updateClientEmployee(users, +user.id)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					const dialogRef = this.dialog.open(CompleteModalComponent, {
						width: '480px',
						height: '360px',
						disableClose: true
					});
					dialogRef.componentInstance.modelDetail = {
						title: 'User Updated',
						text: `User record of ${user.firstName} ${user.lastName} has been updated!`,
						closeBtn: false,
						actionBtn: false
					};
					dialogRef.afterClosed().subscribe(() => {});
				},
				err => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					if (err.error[0].code === 'Filed__Domain__NA__User__ExistsWithSameCriteria') {
						this.toastNotification.sendErrorToast('A user with this email already exists!');
					} else {
						this.toastNotification.sendErrorToast('Cannot create user. Please try again later!');
					}
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
