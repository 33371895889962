import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportingAction, ReportingActionTypes } from './reporting.action';
import { DashboardStateItem, ReportStateItem } from './reporting-state-data.model';
import _ from 'lodash';
import { WidgetSelect } from '../models/widget-select';
import { WidgetCategoryEnum } from '../models/widget-category-enum';
import { ChartImagePath } from '../models/chart-image-path';
import { InsightsCategoryTypeEnum } from '../../shared/campaign-insights/models/insights-category-type.enum';
import { InlineSelection } from '../../shared/inline-selector/inline-selection.model';
import { DashboardSmartCreateModel } from '../components/smart-create/dashboard-smart-create.model';
import { ChartTypeEnum } from '../shared/charts/chart-type.enum';
import { ReportBasicDetailsValidationMessages } from '../models/report-basic-details-validation-messages';
import { DataInterface, NullDataInterface } from '../../shared/state/state-entities/data-interface.model';
import { ScheduleDataModel } from '../models/schedule-data.model';
import { DashboardModel } from '../models/dashboard.model';
import { ReportingNavigationLabelEnum } from '../models/navigation/reporting-navigation-label.enum';
import { ReportingNavigationLinkEnum } from '../models/navigation/reporting-navigation-link.enum';
import { ReportingNavigationIndexEnum } from '../models/navigation/reporting-navigation-index.enum';
import { NavLinks } from '../../accounts/models/ad-account.model';
import { Helper } from '../helper';

class MiscellaneousReportingState {
	defaultReports: WidgetSelect[];
	reportBasicDetailsSelectorItems: InlineSelection[];
	navigationLinks: NavLinks[];
}

export interface ReportingState {
	miscellaneous: MiscellaneousReportingState;
	reports: ReportStateItem;
	reportTemplates: ReportStateItem;
	defaultDashboard: DataInterface<DashboardModel>;
	dashboards: DashboardStateItem;
	dashboardTemplates: DashboardStateItem;
	smartCreateSelection: DashboardSmartCreateModel;
	savedReportTemplateId: number;
	savedReportId: number;
	savedDashboardId: number | string;
	savedDashboardTemplateId: number | string;
	reportBasicDetailsValidationMessages: ReportBasicDetailsValidationMessages;
	reportsSchedules: DataInterface<ScheduleDataModel[]>;
}

export const initialReportingState: ReportingState = {
	miscellaneous: {
		defaultReports: [
			new WidgetSelect(
				'Vertical bar chart',
				ChartTypeEnum.Bar,
				{
					id: 3,
					chartType: ChartTypeEnum.Bar
				},
				WidgetCategoryEnum.Basic,
				undefined,
				true,
				true,
				ChartImagePath.Bar,
				false,
				'Bar',
				''
			),
			new WidgetSelect(
				'Horizontal bar chart',
				ChartTypeEnum.HorizontalBar,
				{
					id: 3,
					chartType: ChartTypeEnum.Bar
				},
				WidgetCategoryEnum.Basic,
				undefined,
				true,
				true,
				ChartImagePath.HorizontalBar,
				false,
				'Bar',
				''
			),

			new WidgetSelect(
				'Grouped vertical bar chart',
				ChartTypeEnum.GroupedVerticalBar,
				{
					id: 3,
					chartType: ChartTypeEnum.Bar
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				true,
				ChartImagePath.GroupedVerticalBar,
				false,
				'Bar',
				''
			),

			new WidgetSelect(
				'Grouped horizontal bar chart',
				ChartTypeEnum.GroupedHorizontalBar,
				{
					id: 3,
					chartType: ChartTypeEnum.Bar
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				true,
				ChartImagePath.GroupedHorizontalBar,
				false,
				'Bar',
				''
			),

			new WidgetSelect(
				'Vertical stacked bar chart',
				ChartTypeEnum.VerticalStackedBar,
				{
					id: 3,
					chartType: ChartTypeEnum.Bar
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				true,
				ChartImagePath.VerticalStackedBar,
				false,
				'Bar',
				''
			),

			new WidgetSelect(
				'Horizontal stacked bar chart',
				ChartTypeEnum.HorizontalStackedBar,
				{
					id: 3,
					chartType: ChartTypeEnum.Bar
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				true,
				ChartImagePath.HorizontalStackedBar,
				false,
				'Bar',
				''
			),

			new WidgetSelect(
				'Line chart',
				ChartTypeEnum.Line,
				{
					id: 1,
					chartType: ChartTypeEnum.Line
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				true,
				ChartImagePath.Line,
				false,
				'Line',
				''
			),

			new WidgetSelect(
				'Area chart',
				ChartTypeEnum.Area,
				{
					id: 1,
					chartType: ChartTypeEnum.Area
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				true,
				ChartImagePath.Area,
				false,
				'Line',
				''
			),

			new WidgetSelect(
				'Pie chart',
				ChartTypeEnum.Pie,
				{
					id: 5,
					chartType: ChartTypeEnum.Pie
				},
				WidgetCategoryEnum.Basic,
				undefined,
				true,
				false,
				ChartImagePath.Pie,
				false,
				'Other',
				''
			),

			new WidgetSelect(
				'Donut chart',
				ChartTypeEnum.Donut,
				{
					id: 8,
					chartType: ChartTypeEnum.Donut
				},
				WidgetCategoryEnum.Basic,
				undefined,
				true,
				false,
				ChartImagePath.Donut,
				false,
				'Other',
				''
			),

			new WidgetSelect(
				'Heat chart',
				ChartTypeEnum.Heat,
				{
					id: 8,
					chartType: ChartTypeEnum.Heat
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				false,
				ChartImagePath.Heat,
				false,
				'Other',
				''
			),

			new WidgetSelect(
				'Single number chart',
				ChartTypeEnum.SingleNumber,
				{
					id: 8,
					chartType: ChartTypeEnum.SingleNumber
				},
				WidgetCategoryEnum.Basic,
				undefined,
				true,
				false,
				ChartImagePath.SingleNumber,
				false,
				'Other',
				'no-img-stretch'
			),

			new WidgetSelect(
				'Table chart',
				ChartTypeEnum.Table,
				{
					id: 8,
					chartType: ChartTypeEnum.Table
				},
				WidgetCategoryEnum.Basic,
				undefined,
				false,
				false,
				ChartImagePath.Table,
				false,
				'Other',
				''
			)
		],
		reportBasicDetailsSelectorItems: [
			{
				displayName: 'Campaign',
				value: InsightsCategoryTypeEnum.Campaign,
				selected: false
			},
			{
				displayName: 'Ad set',
				value: InsightsCategoryTypeEnum.AdSet,
				selected: false
			},
			{
				displayName: 'Ad',
				value: InsightsCategoryTypeEnum.Ad,
				selected: false
			}
		],
		navigationLinks: [
			{
				label: ReportingNavigationLabelEnum.Overview,
				link: ReportingNavigationLinkEnum.Overview,
				index: ReportingNavigationIndexEnum.Overview
			},
			{
				label: ReportingNavigationLabelEnum.Dashboards,
				link: ReportingNavigationLinkEnum.Dashboards,
				index: ReportingNavigationIndexEnum.Dashboards
			},
			{
				label: ReportingNavigationLabelEnum.Templates,
				link: ReportingNavigationLinkEnum.Templates,
				index: ReportingNavigationIndexEnum.Templates
			},
			{
				label: ReportingNavigationLabelEnum.Schedules,
				link: ReportingNavigationLinkEnum.Schedules,
				index: ReportingNavigationIndexEnum.Schedules
			}
		]
	},
	savedReportId: null,
	smartCreateSelection: null,
	savedReportTemplateId: null,
	savedDashboardId: null,
	savedDashboardTemplateId: null,
	reportsSchedules: _.cloneDeep(NullDataInterface),
	reports: {
		data: null,
		loaded: false,
		errorMessage: null
	},
	reportTemplates: {
		data: null,
		loaded: false,
		errorMessage: null
	},
	defaultDashboard: _.cloneDeep(NullDataInterface),
	dashboards: {
		data: null,
		loaded: false,
		errorMessage: null
	},
	dashboardTemplates: {
		data: null,
		loaded: false,
		errorMessage: null
	},
	reportBasicDetailsValidationMessages: new ReportBasicDetailsValidationMessages()
};

const getReportingState = createFeatureSelector<ReportingState>('reporting');

export const getBasicDetailsSelectListData = createSelector(getReportingState, state => _.cloneDeep(state.miscellaneous.reportBasicDetailsSelectorItems));

export const getDefaultReports = createSelector(getReportingState, state => state.miscellaneous.defaultReports);
export const getSavedReportId = createSelector(getReportingState, state => state.savedReportId);
export const getReports = createSelector(getReportingState, state => _.cloneDeep(state.reports.data));
export const getReportById = (id: number) => createSelector(getReportingState, state => _.cloneDeep(state.reports.data.find(report => report.id === id)));
export const getReportsLoaded = createSelector(getReportingState, state => state.reports.loaded);
export const getReportsErrorMessage = createSelector(getReportingState, state => state.reports.errorMessage);
export const getReportTemplates = createSelector(getReportingState, state => _.cloneDeep(state.reportTemplates.data));
export const getReportTemplateById = (id: number) =>
	createSelector(getReportingState, state => _.cloneDeep(state.reportTemplates.data.find(reportTemplate => reportTemplate.id === id)));
export const getSavedReportTemplateId = createSelector(getReportingState, state => state.savedReportTemplateId);
export const getReportTemplatesLoaded = createSelector(getReportingState, state => state.reportTemplates.loaded);
export const getReportTemplatesErrorMessage = createSelector(getReportingState, state => state.reportTemplates.errorMessage);
export const getSavedDashboardId = createSelector(getReportingState, state => state.savedDashboardId);
export const getDashboards = createSelector(getReportingState, state => _.cloneDeep(state.dashboards.data));
export const getSmartCreateSelection = createSelector(getReportingState, state => _.cloneDeep(state.smartCreateSelection));
export const getDefaultDashboard = createSelector(getReportingState, state => state.defaultDashboard);
export const getReportsSchedules = createSelector(getReportingState, state => _.cloneDeep(state.reportsSchedules.data));
export const getDashboardById = (id: number | string) =>
	createSelector(getReportingState, state => {
		if (state.dashboards.data) {
			return _.cloneDeep(state.dashboards.data.find(dashboard => dashboard.id === id));
		} else {
			return null;
		}
	});

export const getDashboardsLoaded = createSelector(getReportingState, state => state.dashboards.loaded);
export const getDashboardErrorMessage = createSelector(getReportingState, state => state.dashboards.errorMessage);
export const getSavedDashboardTemplateId = createSelector(getReportingState, state => state.savedDashboardTemplateId);
export const getDashboardTemplates = createSelector(getReportingState, state => _.cloneDeep(state.dashboardTemplates.data));
export const getDashboardTemplateById = (id: number) =>
	createSelector(getReportingState, state => _.cloneDeep(state.dashboardTemplates.data.find(dashboardTemplate => dashboardTemplate.id === id)));
export const getDashboardTemplatesLoaded = createSelector(getReportingState, state => state.dashboardTemplates.loaded);
createSelector(getReportingState, state => state.dashboardTemplates.errorMessage);
export const getReportBasicDetailsValidationMessages = createSelector(getReportingState, state => state.reportBasicDetailsValidationMessages);
export const getNavigationLinks = createSelector(getReportingState, state => state.miscellaneous.navigationLinks);

export function reportingReducer(state = initialReportingState, action: ReportingAction): ReportingState {
	switch (action.type) {
		case ReportingActionTypes.ReportSaved: {
			return {
				...state,
				savedReportId: action.payload
			};
		}
		case ReportingActionTypes.LoadSuccessReports: {
			return {
				...state,
				reports: {
					data: action.payload,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.GetReportsByIdSuccess: {
			const index = state.reports.data.findIndex(report => report.id === action.payload.id);
			const newReportData = _.cloneDeep(state.reports.data);
			if (index !== -1) {
				newReportData[index] = action.payload;
			} else {
				newReportData.push(action.payload);
			}
			return {
				...state,
				reports: {
					data: newReportData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.UpdateReportSuccess: {
			const updatedReports = state.reports.data.map(report => (action.payload.id === report.id ? _.cloneDeep(action.payload) : report));
			return {
				...state,
				reports: {
					data: updatedReports,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.UpdateReportFail: {
			return {
				...state,
				reports: {
					data: state.reports.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.CreateReportSuccess: {
			const updatedData = _.cloneDeep(state.reports.data) || [];
			updatedData.push(action.payload);
			return {
				...state,
				savedReportId: null,
				reports: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.CreateReportFail: {
			return {
				...state,
				reports: {
					data: state.reports.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.DeleteReportSuccess: {
			const updatedData = state.reports.data.filter(report => report.id !== action.payload);
			return {
				...state,
				reports: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.DeleteReportFail: {
			return {
				...state,
				reports: {
					data: state.reports.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.LoadSuccessReportTemplates: {
			return {
				...state,
				reportTemplates: {
					data: action.payload,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.GetReportTemplateByIdSuccess: {
			const index = state.reportTemplates.data.findIndex(reportTemplate => reportTemplate.id === action.payload.id);
			const newReportData = _.cloneDeep(state.reportTemplates.data);
			newReportData[index] = action.payload;
			return {
				...state,
				reportTemplates: {
					data: newReportData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.GetReportTemplateByIdFailure: {
			return {
				...state,
				reportTemplates: {
					...state.reportTemplates,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.ReportTemplateSaved: {
			return {
				...state,
				savedReportTemplateId: action.payload
			};
		}
		case ReportingActionTypes.UpdateReportTemplateSuccess: {
			const updatedReportTemplates = state.reportTemplates.data.map(report => (action.payload.id === report.id ? _.cloneDeep(action.payload) : report));
			return {
				...state,
				reportTemplates: {
					data: updatedReportTemplates,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.UpdateReportTemplateFail: {
			return {
				...state,
				reportTemplates: {
					data: state.reportTemplates.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.CreateReportTemplateSuccess: {
			const updatedData = _.cloneDeep(state.reportTemplates.data) || [];
			updatedData.push(action.payload);
			return {
				...state,
				reportTemplates: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.CreateReportTemplateFail: {
			return {
				...state,
				savedReportTemplateId: null,
				reportTemplates: {
					data: state.reportTemplates.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.DeleteReportTemplateSuccess: {
			const updatedData = state.reportTemplates.data.filter(report => report.id !== action.payload);
			return {
				...state,
				reportTemplates: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.DeleteReportTemplateFail: {
			return {
				...state,
				reportTemplates: {
					data: state.reportTemplates.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.LoadSuccessDashboards: {
			return {
				...state,
				dashboards: {
					data: action.payload,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.LoadFailureDashboards: {
			return {
				...state,
				dashboards: {
					...state.dashboards,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.GetDefaultDashboardByIdSuccess: {
			const index = state.dashboards.data.findIndex(dashboard => dashboard.id === action.payload.id);
			const newReportData = _.cloneDeep(state.dashboards.data);
			newReportData[index] = action.payload;

			return {
				...state,
				defaultDashboard: {
					errorCode: null,
					isLoaded: true,
					data: action.payload
				},
				dashboards: {
					data: newReportData,
					loaded: true,
					errorMessage: null
				}
			};
		}

		case ReportingActionTypes.GetDefaultDashboardByIdFailed: {
			return {
				...state,
				defaultDashboard: {
					...state.defaultDashboard,
					isLoaded: true,
					errorCode: action.payload
				}
			};
		}

		case ReportingActionTypes.GetDashboardByIdSuccess: {
			const index = state.dashboards.data.findIndex(dashboard => dashboard.id === action.payload.id);
			const newReportData = _.cloneDeep(state.dashboards.data);
			newReportData[index] = action.payload;
			return {
				...state,
				dashboards: {
					data: newReportData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.GetDashboardByIdFailed: {
			return {
				...state,
				dashboards: {
					...state.dashboards,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.DashboardSaved: {
			return {
				...state,
				savedDashboardId: action.payload
			};
		}
		case ReportingActionTypes.UpdateDashboardSuccess: {
			const updatedDashboard = action.payload;
			const updatedDashboards = state.dashboards.data.map(dashboard => {
				const result = _.cloneDeep(dashboard);
				if (updatedDashboard.isDefault && dashboard.id !== updatedDashboard.id) {
					dashboard.isDefault = false;
				}
				return action.payload.id === result.id ? _.cloneDeep(updatedDashboard) : result;
			});
			return {
				...state,
				dashboards: {
					data: updatedDashboards,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.UpdateDashboardFail: {
			return {
				...state,
				dashboards: {
					data: state.dashboards.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.CreateDashboardSuccess: {
			const updatedData = _.cloneDeep(state.dashboards.data);
			updatedData.push(action.payload);
			return {
				...state,
				savedDashboardId: null,
				dashboards: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.CreateDashboardFail: {
			return {
				...state,
				dashboards: {
					data: state.dashboards.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.DeleteDashboardSuccess: {
			const updatedData = state.dashboards.data.filter(dashboard => dashboard.id !== action.payload);
			return {
				...state,
				dashboards: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.DeleteDashboardFail: {
			return {
				...state,
				dashboards: {
					data: state.dashboards.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.LoadSuccessDashboardTemplates: {
			return {
				...state,
				dashboardTemplates: {
					data: action.payload,
					loaded: true,
					errorMessage: null
				}
			};
		}

		case ReportingActionTypes.LoadSchedulesSuccess: {
			return {
				...state,
				reportsSchedules: {
					...state.reportsSchedules,
					data: action.payload,
					isLoaded: true,
					isLoading: false
				}
			};
		}
		case ReportingActionTypes.LoadSchedulesFailed: {
			return {
				...state,
				reportsSchedules: {
					...state.reportsSchedules,
					isLoaded: true,
					isLoading: false
				}
			};
		}
		case ReportingActionTypes.LoadSchedules: {
			return {
				...state,
				reportsSchedules: {
					...state.reportsSchedules,
					isLoaded: false,
					isLoading: true
				}
			};
		}

		case ReportingActionTypes.GetDashboardTemplateByIdSuccess: {
			const index = state.dashboardTemplates.data.findIndex(dashboardTemplate => dashboardTemplate.id === action.payload.id);
			const newReportData = _.cloneDeep(state.dashboardTemplates.data);
			newReportData[index] = action.payload;
			return {
				...state,
				dashboardTemplates: {
					data: newReportData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.GetDashboardTemplateByIdFailure: {
			return {
				...state,
				dashboardTemplates: {
					...state.dashboardTemplates,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}

		case ReportingActionTypes.DashboardTemplateSaved: {
			return {
				...state,
				savedDashboardTemplateId: action.payload
			};
		}
		case ReportingActionTypes.UpdateDashboardTemplateSuccess: {
			const updatedDashboardTemplates = state.dashboardTemplates.data.map(dashboardTemplate =>
				action.payload.id === dashboardTemplate.id ? _.cloneDeep(action.payload) : dashboardTemplate
			);
			return {
				...state,
				dashboardTemplates: {
					data: updatedDashboardTemplates,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.UpdateDashboardTemplateFail: {
			return {
				...state,
				dashboardTemplates: {
					data: state.dashboardTemplates.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.CreateDashboardTemplateSuccess: {
			const updatedData = _.cloneDeep(state.dashboardTemplates.data);
			updatedData.push(action.payload);
			return {
				...state,
				savedDashboardTemplateId: null,
				dashboardTemplates: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.CreateDashboardTemplateFail: {
			return {
				...state,
				dashboardTemplates: {
					data: state.dashboardTemplates.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.DeleteDashboardTemplateSuccess: {
			const updatedData = state.dashboardTemplates.data.filter(dashboardTemplate => dashboardTemplate.id !== action.payload);
			return {
				...state,
				dashboardTemplates: {
					data: updatedData,
					loaded: true,
					errorMessage: null
				}
			};
		}
		case ReportingActionTypes.DeleteDashboardTemplateFail: {
			return {
				...state,
				dashboardTemplates: {
					data: state.dashboardTemplates.data,
					loaded: true,
					errorMessage: action.payload
				}
			};
		}
		case ReportingActionTypes.SetSmartCreateSelection: {
			return {
				...state,
				smartCreateSelection: action.payload
			};
		}
		case ReportingActionTypes.ClearSmartCreateSelection: {
			return {
				...state,
				smartCreateSelection: null
			};
		}

		default:
			return state;
	}
}
