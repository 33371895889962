<div class="fxLayout">
	<div fxFlex="60" class="form-container">
		<label class="form-label">{{ name.replace('_', ' ') }}</label>
		<tb06 [control]="consent" [placeHolderTextBox]="placeholderText" onblur="onTypeChange()"></tb06>
	</div>
	<div fxFlex="30" class="checkbox-margin">
		<mat-checkbox class="">Optional</mat-checkbox>
	</div>
	<div fxFlex="10" class="form-button">
		<btn02 (onClick)="removeItem()" [icon]="'trash'"></btn02>
	</div>
</div>
