import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseApiUrl } from '../../../_services/base-api-urls';
import { BasicStructureModel } from '../../models/basic-structure.model';
import { DashboardModel } from '../../models/dashboard.model';
import { GetDashboardModel } from '../../models/get-dashboard.model';
import { GetReportModel } from '../../models/get-report.model';
import { IChartPositionProperty, IPositioningResponse, ReportModel } from '../../models/report.model';
import { SendEmailModel } from '../../models/send-email.model';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {
	private readonly baseURL = BaseApiUrl.Reporting + 'report/';
	private readonly newbaseURL = BaseApiUrl.PyReportingfb;
	private readonly PYbaseURL = BaseApiUrl.PyReporting;
	private readonly fbbaseURL = BaseApiUrl.FacebookDataPipeline;
	private readonly baseUrlSchedule = BaseApiUrl.Reporting + 'report-schedule/';

	public deleteId$: BehaviorSubject<string> = new BehaviorSubject('');

	public dashboardEditChange$: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(private http: HttpClient) {}

	public createDashboard(report: DashboardModel): Observable<number> {
		return this.http.post<number>(`${this.baseURL}`, report);
	}
	public deleteDashboard(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}${id}`);
	}
	public duplicateDashboard(id: string, payload: any): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}dashboard/duplicate/` + id, payload);
	}
	public newDeleteDashboard(id: string): Observable<void> {
		return this.http.delete<void>(`${this.PYbaseURL}delete-dashboard/` + id);
	}
	public newDeleteDashboardchart(Did: string, Gid: string): Observable<void> {
		return this.http.delete<void>(`${this.PYbaseURL}delete-graph/` + Did + '/' + Gid);
	}
	public newEditDashboardchart(Did: string, Gid: string, payload: any): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}edit-graph/` + Did + '/' + Gid, payload);
	}
	public updateDashboard(dashboard: DashboardModel): Observable<number> {
		return this.http.put<number>(`${this.baseURL}${dashboard.id}`, dashboard);
	}

	public getDashboards(): Observable<DashboardModel[]> {
		return this.http.get<DashboardModel[]>(`${this.baseURL}`);
	}
	public getNewDashboards(): Observable<GetDashboardModel[]> {
		return this.http.get<GetDashboardModel[]>(`${this.PYbaseURL}get-dashboards`);
	}
	public createnewDashboardname(name: string): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}create-dashboard/` + name, '');
	}
	public updatenewDashboardname(id: string, name: string): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}edit-dashboard-name/` + id, { name: name });
	}
	public getDashboardsById(id: number | string): Observable<DashboardModel> {
		return this.http.get<DashboardModel>(`${this.baseURL}${id}`);
	}

	public getDefaultDashboard(): Observable<DashboardModel> {
		return this.http.get<DashboardModel>(`${this.baseURL}default`);
	}

	public getDashboardShareHash(id: number | string): Observable<string> {
		return this.http.get<string>(`${this.PYbaseURL}share-token/generate/${id}`);
	}
	public shareDashboardbyemail(id: string, payload: any): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}share/email/${id}`, payload);
	}
	public getReportByHashForSharing(hash: string): Observable<GetReportModel> {
		return this.http.post<GetReportModel>(`${this.PYbaseURL}dashboard-reports/get/share-token`, { token: hash });
	}

	public sendEmail(sendEmail: SendEmailModel): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}send-email`, sendEmail);
	}
	public getNewDashboardcharts(report: any): Observable<any> {
		return this.http.post<any>(`${this.newbaseURL}reports/reports`, report);
	}
	public getNewDashboardData(id: any): Observable<GetReportModel> {
		return this.http.get<GetReportModel>(`${this.PYbaseURL}get-dashboard-reports/` + id);
	}
	public addDashboardcharts(report: any, id: string): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}add-graph/` + id, report);
	}
	public getInsightsData(payload: any, level: string): Observable<any> {
		return this.http.post<any>(`${this.fbbaseURL}filtered-structures/` + level, payload);
	}

	public editReportPositioning(reportPositioning: IChartPositionProperty[], id: string): Observable<HttpResponse<IPositioningResponse>> {
		return this.http.put<IPositioningResponse>(`${this.PYbaseURL}edit-graph-positions/${id}`, reportPositioning, { observe: 'response' });
	}
}
