<app-expansion-panel-sc
	class="scs-expansion-panel"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Choose Catalog'"
	[isExpandable]="false"
>
	<div expansion-content>
		<div class="pc-dropdown-wrapper pc-margin-bottom">
			<drp01 [data]="catalogList" [valuePrimitive]="true" placeholder="Choose Catalog" [control]="catalogFormControl"></drp01>
		</div>
	</div>
</app-expansion-panel-sc>
