export enum AdFormatsEnum {
	SingleImage = 1,
	Carousel = 2,
	Collection = 3,
	Slideshow = 4,
	Video = 5,
	PagePosts = 6,
	catalogueSale = 7,
	multipleSingleImage = 10
}
