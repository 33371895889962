<!--dropdown select -->
<ng-select
	#ngSelect
	(window:resize)="onResize($event, ngSelect)"
	[formControl]="dropdownFormControl"
	[readonly]="readOnly"
	[items]="data"
	(scroll)="onScroll($event)"
	(scrollToEnd)="onScrollToEnd()"
	[searchable]="searchEnable"
	[bindLabel]="labelProperty"
	[clearSearchOnAdd]="true"
	[closeOnSelect]="true"
	[clearable]="!hideClearButton"
	(focus)="autocompleteFocus()"
	(focusout)="setErrorMessage()"
	(change)="onChange($event)"
	[multiple]="false"
	(close)="onDropdownClose()"
	addTagText="Use this"
	[addTag]="createNew"
	[compareWith]="compare"
	[ngClass]="{
		'input-error-message': dropdownFormControl.invalid && dropdownFormControl.touched,
		'input-success-message': dropdownFormControl.valid && dropdownFormControl.touched
	}"
	[ngClass]="{ fromOptimise: fromOptimise, custom: !fromOptimise, 'multiple-input-custom': true }"
	notFoundText="{{ noDataFoundLabel }}"
	placeholder="{{ placeHolder }}"
>
	<div>
		<ng-template (mouseleave)="onResize($event, ngSelect)" let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
			<span class="right-check">
				<span>{{ item.name }}</span>
			</span>
		</ng-template>
	</div>
</ng-select>
<!--Error message -->
<span class="error-message" *ngIf="dropdownFormControl.invalid && dropdownFormControl.touched && !readOnly">{{ errorMessage }}</span>
