<div class="quick-nav">
	<h5 class="title">Quick Navigation</h5>
	<ul class="list">
		<ngTemplate *ngFor="let nav of navigations; let i = index">
			<li class="list__item" [class.active]="isSelected(i)" (click)="setCurrentNavigation(i)" *ngIf="!nav?.hide">
				<a class="list__link">{{ nav?.name }}</a>
			</li>
		</ngTemplate>
	</ul>
</div>
