<div class="popup-section">
	<!--header-->
	<!--delete btn-->
	<div class="clear-btn">
		<mat-icon (click)="onNoClick()">clear</mat-icon>
		<div></div>
	</div>

	<!--main section-->
	<div class="main-section">
		<img src="{{ image }}" />
		<p class="title">{{ header }}</p>
		<p>{{ message }}</p>
	</div>

	<!--footer-->
	<div class="bottom-buttons">
		<app-button
			(buttonClicked)="onNoClick()"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="{{ noActionAnswer }}"
		></app-button>

		<app-button
			(buttonClicked)="actionEvent()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="{{ actionAnswer }}"
		></app-button>
	</div>
</div>
