<app-expansion-panel-sc
	class="scs-expansion-panel"
	[isSelected]="isPanelSelected"
	(click)="onPanelClick.emit('platform-selection')"
	[expansionTitle]="'What is your app?'"
	[isExpandable]="false"
	id="platform-selection"
	style="margin-bottom: 1rem; display: block;"
>
	<div expansion-content>
		<div class="">
			<mat-radio-group class="tc-radio-group" (change)="setControlValue($event)" [formControl]="control">
				<mat-radio-button class="tc-radio-button k-align-items-start" [value]="appStoreTypeEnum.AppleAppStore" name="applicationType" [disabled]="true">
					<div>
						<span>Apple App Store</span>
						<font08>
							iOS 14 and later.
						</font08>
					</div>
				</mat-radio-button>
				<mat-radio-button
					class="tc-radio-button k-align-items-start"
					[value]="appStoreTypeEnum.GooglePlayStore"
					name="applicationType"
					[checked]="true"
				>
					<div>
						<span>Google Play Store</span>
						<font08>
							Android based applications.
						</font08>
					</div>
				</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="pc-dropdown-wrapper pc-margin-bottom">
			<font08 class="bold-text">App</font08>
			<drp12 [data]="advertisableApps" placeholder="Select the App to advertise" [control]="appControl" (valueChanges)="selectedApp($event)"></drp12>
		</div>
	</div>
	<div class="pc-dropdown-wrapper pc-margin-bottom"></div>
</app-expansion-panel-sc>
