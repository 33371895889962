import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';

@Component({
	selector: 'app-catalog-selector',
	templateUrl: './catalog-selector.component.html',
	styleUrls: ['./catalog-selector.component.scss']
})
export class CatalogSelectorComponent implements OnInit, OnDestroy {
	@Input() public accountId: string;
	@Input() public isPanelSelected: boolean;
	@Input() public catalogFormControl: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();
	@Input() public catalogList!: any;
	public unsubscriber$: Subject<void> = new Subject<void>();
	constructor() {}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
