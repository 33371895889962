import { Injectable } from '@angular/core';

import { DefaultChartValues } from '../../models/default-chart-values';
import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { DonutChartComponent } from '../../shared/charts/donut-chart/donut-chart.component';
import { Legend } from '../../models/legend';
import { Tooltip } from '../../models/tooltip';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { ChartLabel } from '../../models/chart-label';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';

@Injectable({
	providedIn: 'root'
})
export class DoughnutChartService extends ChartService {
	public component: DonutChartComponent;

	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel) {
		const initial = this.initializeChart(widget);
		const chartOptions = initial.options;
		chartOptions.tooltip = new Tooltip('item', null);
		const label = new ChartLabel('left', 'middle', 'insideBottom', 0, 10, 12, widget.details.reportProperty.showDataLabels);
		chartOptions.series = this.createSeriesForDoughnutChart(widget, initial.data, ['45%', '50%'], ['50%', '35%'], label);

		if (initial.data.length) {
			chartOptions.legend = new Legend('horizontal', '0', '80%', 'circle');
			// chartOptions.legend.type = 'scroll';
			chartOptions.legend.data = initial.data.map((item: { name: string }) => item.name);
		} else {
			chartOptions.legend.data = ['First', 'Second', 'Third', 'Forth', 'Fifth'];
		}

		return chartOptions;
	}

	private createSeriesForDoughnutChart(widget: ReportModel, chartData: any, radius: any, center: any, labelAlignment: any): { [key: string]: any }[] {
		return [
			{
				label: {
					position: 'outer',
					alignTo: 'edge'
				},
				type: 'pie',
				name: 'Pie',
				selectedMode: 'multiple',
				radius: radius,
				center: center,
				data: chartData.length ? chartData : (DefaultChartValues as any)['Pie'],
				itemStyle: {
					normal: {
						shadowBlur: widget.details.style.hasGradient ? 5 : 0,
						shadowColor: widget.details.style.hasGradient ? 'rgba(0, 0, 0, 0.9)' : null
					}
				},
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			}
		];
	}
}
