import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { map, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CreateAdComponent } from 'src/app/campaign-smart-create/components/create-ad/create-ad.component';
import { ExpansionPanelHelper } from 'src/app/shared/expansion-panel/expansion-panel-helper.service';
import { IQuickNavigation } from '../components/quick-navigation/quick-navigation.component';
import { AAACreateAdPanels } from '../models/create-ad.panels';
import * as fromAAAState from '../state';
import * as fromAdStore from '../state/ad-state';
import * as fromAdActions from '../state/ad-state/ad-state.actions';
import * as fromAssetsState from '../state/aaa-assets';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { AdModel } from '../models/ad.model';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { EventDtoInterface } from 'src/app/pixel/_models/event-dto.interface';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { CarouselDto, SingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';
import { AaaHeaderFooterStateService } from '../services/aaa-header-footer-state.service';
import { AAAFlow, stepName } from '../models/AAA-steps.enum';
import { Observable } from 'rxjs';
import { setStepBasedOnFlow } from '../helpers/helper';
import { DropdownInterface } from '@filed-com/filed-lib';
import { LoadPagePosts } from '../state/aaa-assets/aaa-assets.actions';
import _ from 'lodash';
import { SubObjectives } from 'src/app/campaign-smart-create/models/sub-objectives';
import { ObjectivesEnum } from '../../campaign-smart-create/models/objectives.enum';
import { getCurrentAd, getPixelAppEventId } from '../../campaign-smart-create/state/smart-create.reducer';

@Component({
	selector: 'app-create-ad',
	templateUrl: './create-ad.component.html',
	styleUrls: ['./create-ad.component.scss'],
	providers: [ExpansionPanelHelper]
})
export class AAACreateAdComponent extends CreateAdComponent implements OnInit, AfterViewInit {
	public navigations: IQuickNavigation[] = [
		{
			name: 'Ad Navigation',
			targetId: 'adNavigation'
		},
		{
			name: 'Ad Name',
			targetId: 'adName'
		},
		{
			name: 'Select Page',
			targetId: 'selectPage'
		},
		{
			name: 'Ad Format',
			targetId: 'adFormat'
		},
		{
			name: 'Ad Creation',
			targetId: 'adCreate'
		},
		{
			name: 'Tracking',
			targetId: 'adTracking'
		}
	];
	public aaaPanels = AAACreateAdPanels;
	public curStep: AdModel = new AdModel();
	public showPageComponent = true;
	public aaaExpansionPanelHelper: ExpansionPanelHelper<AAACreateAdPanels>;
	public adCreationFormGroup: FormGroup;
	protected AAAstore: Store<fromAAAState.AAAModuleState>;
	public timer: NodeJS.Timeout;
	private aaaStateService: AaaHeaderFooterStateService;
	public selectedIndex: number;
	public flow: AAAFlow;
	public facebookPages: DropdownInterface[];
	public pixelMandatory: boolean;
	public pixelId: string;
	public conversionEventId: string;
	constructor(injector: Injector) {
		super(injector);
		this.aaaExpansionPanelHelper = this.injector.get<ExpansionPanelHelper<AAACreateAdPanels>>(ExpansionPanelHelper);
		this.AAAstore = this.injector.get<Store<fromAAAState.AAAModuleState>>(Store);
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
		this.aaaStateService = this.injector.get<AaaHeaderFooterStateService>(AaaHeaderFooterStateService);
		this.listenToRoute();
	}

	public ngOnInit(): void {
		this.initFormGroup();
		this.adCreationFormGroup = this.initAdCreationFormGroup();
		this.initFormGroupListeners();
		this.getActivatedRoute();
		this.getAdFormats();
		this.getAdFormat();
		this.checkSelectedPixelId();
		this.checkSelectedEventId();
		this.checkIfPixelIsMandatory();
		this.getConversion();
		this.storeListeners();
		this.registeringActions();
		this.registerQuickNavigationSelection();
		this.firstTime = true;
	}

	public getActivatedRoute(): void {
		this.activatedRoute.data.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			this.objectiveTree = data.store.objectiveTree;
			this.validations = data.store.validations;
			this.placements = data.store.placements;
			this.setPlacements();
			this.posts = data?.store?.posts;
			this.images = data?.store?.images;
			this.videos = data?.store?.videos;
			this.selectedAdAccount = data?.store?.account;
			this.previews = data?.store?.platforms;
			this.callToActions = data?.store?.callToActions;
			this.curStep = data?.store?.ads[data?.store?.currentAdIndex];

			this.facebookPages = data?.store?.pages;
			this.facebookPageId = data?.store?.pages?.[0]?.data?.id || '';
			if (this.curStep?.adName) {
				this.adNameControl.setValue(this.curStep?.adName);
				this.label = '';
			}

			this.pixelFormGroup?.get('pixel').patchValue(this.curStep?.pixelId);
			this.pixelMandatory = this.objectiveTree.subObjective === ObjectivesEnum.Conversions;
		});
	}

	public hidePageComponent(): void {
		this.showPageComponent = false;
		this.navigations = this.navigations.filter(nav => nav.targetId !== 'selectPage');
	}

	private initAdCreationFormGroup(): FormGroup {
		const formGroup = this.formBuilder.group({
			adCardsItemData: this.formBuilder.array([]),
			preview: ['', Validators.required],
			pages: this.formBuilder.group({
				facebookId: [null, Validators.required],
				instagramId: [null]
			})
		});
		const advertItemsForm = formGroup.get('adCardsItemData') as FormArray;
		advertItemsForm.push(this.cardAdItem());
		return formGroup;
	}

	private cardAdItem(): FormGroup {
		const urlValidator = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
		return this.formBuilder.group({
			primary_text: ['', Validators.required],
			headline: ['', Validators.required],
			website_url: new FormControl('', { validators: [Validators.required, Validators.pattern(urlValidator)], updateOn: 'blur' }),
			description: ['', Validators.required],
			displayLink: [''],
			call_to_action: ['', Validators.required],
			media_url: [''],
			picture: [''],
			video_id: [0],
			mediaType: ['']
		});
	}

	public checkSelectedPixelId(): void {
		this.AAAstore.pipe(select(fromAdStore.getPixelId), take(1)).subscribe(data => {
			if (data) {
				this.pixelId = data;
			}
		});
		this.getToggleStatus();
	}

	public getToggleStatus(): void {
		this.AAAstore.pipe(select(fromAdStore.getPixelToggle), take(1)).subscribe(resp => {
			this.firstTime = !resp;
		});
		this.AAAstore.dispatch(new fromAdActions.UpdatePixelToggle(true));
	}

	public checkSelectedEventId(): void {
		this.AAAstore.pipe(select(fromAdStore.getPixelAppEventId), take(1)).subscribe(data => {
			if (data) {
				this.pixelEventId = data;
			}
		});
	}

	public getConversion(): void {
		this.AAAstore.pipe(select(fromAdStore.getCurrentAdDetails), take(1)).subscribe(data => {
			if (data?.customEventType) {
				this.conversion = { customEventType: data.customEventType, pixelRule: data.pixelRule, id: data.conversionId };
			}
		});
	}

	protected initFormGroup(): void {
		this.adCreateFormGroup = this.formBuilder.group({
			name: new FormControl('', Validators.required)
		});
		this.pixelFormGroup = this.formBuilder.group({
			pixel: new FormControl(''),
			pixelEvent: new FormControl(''),
			conversion: new FormControl('')
		});
	}

	public getAdFormat(): void {
		this.adFormatListener$ = this.AAAstore.pipe(select(fromAdStore.getAdFormat), takeUntil(this.unsubscriber$));
	}

	public getAdFormats(): void {
		this.AAAstore.pipe(select(fromAAAState.getAdFormatFromCatalog), take(1)).subscribe(adFormats => {
			this.adFormats = adFormats;
		});
	}

	protected initFormGroupListeners(): void {
		this.adCreateFormGroup
			.get('name')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(adName => {
				this.label = '';
				if (this.adCreateFormGroup.get('name').valid) {
					this.curStep['adName'] = adName;
					this.AAAstore.dispatch(new fromAdActions.UpdateAdName(adName));
				}
			});

		this.pixelFormGroup
			.get('pixel')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pixelId => {
				this.curStep['pixelId'] = pixelId;
				this.pixelId = pixelId;
				this.AAAstore.dispatch(new fromAdActions.UpdatePixelId(pixelId));
			});

		this.pixelFormGroup
			.get('pixelEvent')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pixelEventId => {
				this.AAAstore.dispatch(new fromAdActions.UpdatePixelAppEventId(pixelEventId));
			});

		this.adCreateFormGroup.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			setTimeout(() => {
				this.isThisStepValid = this.adCreateFormGroup.valid && this.isAdCreationFormValid && this.pixelFormGroup.valid;
			});
		});

		this.adCreationFormGroup
			.get('pages')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pages => {
				this.facebookPageId = pages.facebookId;
				if (pages.facebookId || pages.instagramId) {
					this.store.dispatch(new fromAdActions.UpdatePages({ facebookPageId: pages.facebookId, instagramPageId: pages.instagramId }));
				}
			});

		this.adCreationFormGroup
			.get('preview')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pages => {
				if (pages.facebookId || pages.instagramId) {
					this.store.dispatch(new fromAdActions.UpdatePages({ facebookPageId: pages.facebookId, instagramPageId: pages.instagramId }));
				}
			});
	}

	public updateFormatType(format: AdFormatsEnum): void {
		this.AAAstore.dispatch(new fromAdActions.UpdateAdFormatType(format));
	}

	public updateConversion(conversion: DropdownData<EventDtoInterface>): void {
		this.AAAstore.dispatch(new fromAdActions.UpdateConversion(conversion));
	}

	private mockLoader(): void {
		clearTimeout(this.timer);
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.timer = setTimeout(() => {
			this.sharedStore.dispatch(new HideGlobalSpinner());
		}, 200);
	}

	public storeListeners(): void {
		this.AAAstore.pipe(select(fromAdStore.getCurrentAdIndex), takeUntil(this.unsubscriber$))
			.pipe(
				tap(_ => this.mockLoader()),
				switchMap(() => this.AAAstore.pipe(select(fromAdStore.getCurrentAdDetails), take(1)))
			)
			.subscribe((data: AdModel) => this.updateFormValues(data));

		this.AAAstore.pipe(select(fromAssetsState.getPagePosts), takeUntil(this.unsubscriber$)).subscribe(data => {
			this.posts = data;
		});

		this.AAAstore.pipe(select(fromAdStore.getFacebookId), takeUntil(this.unsubscriber$)).subscribe(facebookId => this.fetchPosts(facebookId));
		this.AAAstore.pipe(select(fromAAAState.getObjectiveTree), takeUntil(this.unsubscriber$)).subscribe(objectiveTree => {
			if (objectiveTree.subObjective === 'REACH') {
				this.hidePageComponent();
			}
		});
	}

	public updateFormValues(data: AdModel): void {
		const { adName, pixelId } = data;
		if (adName) {
			this.adCreateFormGroup.get('name').patchValue(adName);
		} else {
			this.adCreateFormGroup.reset();
		}
		if (adName) {
			this.label = '';
		}
		this.selectedFormat = data.adFormatType;
		this.getToggleStatus();
		this.pixelFormGroup.get('pixel').patchValue(pixelId);
		this.pixelFormGroup.clearValidators();
		this.pixelFormGroup.updateValueAndValidity();
		this.checkSelectedPixelId();
		this.checkSelectedEventId();
		this.checkIfPixelIsMandatory();
	}

	public updateAdverts(advert: SingleAdvertDto | CarouselDto): void {
		this.AAAstore.dispatch(new fromAdActions.UpdateAdverts(advert));
	}

	public listenToRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.flow = +params['flow'];
			this.setCurStepBasedOnFlow();
		});
	}

	public setCurStepBasedOnFlow(): void {
		setStepBasedOnFlow(this, this.flow, this.setCurStep, stepName.CreateAd);
	}

	public setCurStep(step: number): void {
		this.aaaStateService.sendActiveStep(step);
		this.aaaStateService.sendCampaignStep(step);
		this.aaaStateService.onPreviewAd(false);
	}

	public registeringActions(): void {
		this.aaaStateService
			.onNext()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.checkValidity().subscribe(isValidStep => {
					if (isValidStep) {
						this.aaaStateService.sendNavigateForward();
					}
				});
			});
	}

	public setPlacements(): void {
		this.placements = [
			{ name: 'FACEBOOK', displayNameAutogen: 'Facebook' },
			{ name: 'AUDIENCE_NETWORK', displayNameAutogen: 'Audience Network' },
			{ name: 'INSTAGRAM', displayNameAutogen: 'Instagram' }
		];
	}

	public registerQuickNavigationSelection(): void {
		this.quickNavigationService.getSelectedNavigation().subscribe(selectedIndex => (this.selectedIndex = selectedIndex));
	}
	public handlePanelClick(panelType: AAACreateAdPanels, index: number): void {
		this.aaaExpansionPanelHelper.onPanelClick(panelType);
		this.quickNavigationService.setSelectedNavigation(index);
	}

	public fetchPosts(facebookPageId: string): void {
		if (facebookPageId) {
			this.AAAstore.pipe(select(fromAAAState.getCredentialsForAssets), take(1)).subscribe(data => {
				this.AAAstore.dispatch(new LoadPagePosts({ adAccountId: facebookPageId, businessOwnerId: data.businessOwnerId }));
			});
		}
	}

	public checkValidity(): Observable<boolean> {
		return this.AAAstore.pipe(
			select(fromAdStore.getAds),
			take(1),
			map(data => {
				const ads: AdModel[] = _.cloneDeep(data);
				for (let i = 0; i < ads.length; i++) {
					this.runSingleAdValidation(ads[i], i);
					if (!this.isThisStepValid) {
						break;
					}
				}
				return this.isThisStepValid;
			})
		);
	}

	public runSingleAdValidation(ad: AdModel, index: number): void {
		this.validatorTrigger = false;
		this.isThisStepValid = true;
		const isCarousel = (ad.adverts as CarouselDto)?.cards;
		const isPagePost = ad.adverts?.post_id;
		if (isPagePost) return;
		const adCardsItemCount = isCarousel ? isCarousel.length : 1;
		const type = adCardsItemCount === 1 ? AdFormatsEnum.SingleImage : AdFormatsEnum.Carousel;
		const adCreationForm = this.initAdCreationFormGroup();
		const formCardItems = adCreationForm.get('adCardsItemData') as FormArray;
		for (let i = formCardItems.length; i < adCardsItemCount; i++) {
			formCardItems.push(this.cardAdItem());
		}
		adCreationForm.get('pages').patchValue({
			facebookId: ad?.facebookPageId || '',
			instagramId: ad?.instagramPageId || ''
		});
		adCreationForm.get('preview').patchValue(`${ad?.adverts?.device_placement_position}`);
		if (this.objectiveTree.subObjective === 'REACH') {
			adCreationForm.get('pages').get('facebookId').clearValidators();
			adCreationForm.get('pages').get('facebookId').setErrors(null);
			adCreationForm.get('pages').get('facebookId').updateValueAndValidity();
		}
		for (let i = 0; i < adCardsItemCount; i++) {
			const formBuildControl = formCardItems.controls[i];
			this.patchAdvertValue(type, formBuildControl, ad, i);
		}
		this.setValidationBasedOnObjective(formCardItems.controls[0]);
		const isConversionObjective = this.objective === this.objectiveType.Conversions;
		if (!ad.adName) {
			this.toastService.sendErrorToast('You must select a name for your ad.');
			this.adCreateFormGroup.markAllAsTouched();
			this.quickNavigationService.setSelectedNavigation(1);
			this.isThisStepValid = false;
			return;
		} else if (!ad.facebookPageId && this.objectiveTree.subObjective !== 'REACH') {
			this.toastService.sendErrorToast('Please select a Facebook Page');
			this.quickNavigationService.setSelectedNavigation(2);
			this.isThisStepValid = false;
			return;
		} else if (adCreationForm.invalid || !ad.adverts) {
			if (!ad.adFormatType) {
				this.toastService.sendErrorToast('You must select an Ad Format.');
				this.isThisStepValid = false;
				return;
			} else {
				this.quickNavigationService.setSelectedNavigation(4);
				this.toastService.sendErrorToast('You must complete Ad Creation Form.');
				this.validatorTrigger = true;
				this.isThisStepValid = false;
				return;
			}
		} else if (isConversionObjective && !ad.pixelId) {
			this.toastService.sendErrorToast('You must select an valid Pixel');
			this.quickNavigationService.setSelectedNavigation(5);
			this.isThisStepValid = false;
			return;
		} else {
		}
	}

	private setValidationBasedOnObjective(formBuildControl: AbstractControl): void {
		if (this.subObjective === SubObjectives.PageLikes) {
			this.clearFormValidators(formBuildControl);
			formBuildControl.get('call_to_action').clearValidators();
			formBuildControl.get('call_to_action').updateValueAndValidity();
		} else if (this.subObjective === SubObjectives.PostLikes) {
			this.clearFormValidators(formBuildControl);
		}
	}
	public clearFormValidators(formBuildControl: AbstractControl): void {
		formBuildControl.get('headline').clearValidators();
		formBuildControl.get('headline').updateValueAndValidity();
		formBuildControl.get('description').clearValidators();
		formBuildControl.get('description').updateValueAndValidity();
		formBuildControl.get('website_url').clearValidators();
		formBuildControl.get('website_url').updateValueAndValidity();
	}

	public patchAdvertValue(type: AdFormatsEnum, formGroup: AbstractControl, ad: AdModel, index: number = null): void {
		if (type === AdFormatsEnum.SingleImage) {
			formGroup.patchValue({
				primary_text: {
					primary_text: `${ad?.adverts?.primary_text}`
				},
				headline: {
					headline: `${ad?.adverts?.headline}`
				},
				website_url: `${ad?.adverts?.website_url}`,
				description: {
					description: `${ad?.adverts?.description}`
				},
				call_to_action: `${ad?.adverts?.call_to_action}`
			});
		}
		if (type === AdFormatsEnum.Carousel) {
			const adverts = ad?.adverts as CarouselDto;
			formGroup.patchValue({
				primary_text: {
					primary_text: `${adverts?.cards[index].primary_text}`
				},
				headline: {
					headline: `${adverts?.cards[index].headline}`
				},
				website_url: `${adverts?.cards[index].website_url}`,
				description: {
					description: `${adverts?.cards[index].description}`
				},
				call_to_action: `${adverts?.cards[index].call_to_action}`
			});
		}
	}
}
