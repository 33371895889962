import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorMessages } from '../validatorMessagesInputs';
import { CustomValidator } from './custom-validator';
import { ErrorMessageService } from '../error-message.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-input-password',
	templateUrl: './input-password.component.html',
	styleUrls: ['./input-password.component.scss', '../shared.scss']
})
export class InputPasswordComponent implements OnInit, OnDestroy {
	@Input() control: FormControl | AbstractControl;
	@Input() validatorMessages: ValidatorMessages[];
	@Input() label = 'Enter Password';
	@Input() placeholder: string;
	@Input() isLogin: boolean;

	@Output() enterPress = new EventEmitter<void>();

	public isDestinationFocused: boolean;
	public errorMessage: string;
	public hidePassword = true;
	public myForm: FormGroup;
	public isConfirmHidden = true;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private fb: FormBuilder, public errorMessageService: ErrorMessageService) {}

	ngOnInit() {
		if (!this.isLogin) {
			this.myForm = this.createMyForm();
			this.control.setErrors({ required: true });
			this.myForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
				this.control.setErrors(this.myForm.get('password').errors);
				if (this.control.valid) {
					this.control.setErrors(this.myForm.get('confirmPassword').errors);
				}

				if (this.control.valid) {
					this.control.setValue(this.myForm.get('password').value);
				}
			});
		} else {
			this.control.setValidators(Validators.required);
		}

		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public createMyForm(): FormGroup {
		const control = new FormControl('', [
			Validators.required,
			CustomValidator.patternValidator(/\d/, {
				hasNumber: true
			}),
			// check whether the entered password has upper case letter
			CustomValidator.patternValidator(/[A-Z]/, {
				hasCapitalCase: true
			}),
			// check whether the entered password has a lower case letter
			CustomValidator.patternValidator(/[a-z]/, {
				hasSmallCase: true
			}),
			// check whether the entered password has a special character
			CustomValidator.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
				hasSpecialCharacters: true
			}),
			Validators.minLength(8)
		]);

		return this.fb.group(
			{
				password: control,
				confirmPassword: [null, Validators.compose([Validators.required])]
			},
			{
				// check whether our password and confirm password match
				validator: CustomValidator.passwordMatchValidator
			}
		);
	}

	public autocompleteFocus(): void {
		this.isDestinationFocused = true;
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validatorMessages, this.control);
	}

	public toggleEvent(isConfirm: boolean = false): void {
		if (isConfirm) {
			this.isConfirmHidden = !this.isConfirmHidden;
		} else {
			this.hidePassword = !this.hidePassword;
		}
	}

	public onEnterKeyUp(): void {
		this.enterPress.emit();
	}
}
