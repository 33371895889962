<div class="center-wrapper">
	<div class="campaign-selector-wrapper">
		<button class="close-button k-button k-button-primary k-button-clear" routerLink="/campaign/welcome">
			<img src="assets/icons/campaign-smart-create/welcome/cross-dismiss-icon.svg" />
		</button>
		<div class="cs-dropdown-wrapper">
			<h5>You have already started a campaign, do you want to continue with it?</h5>
			<div class="cs-dropdown">
				<app-dropdown-search-select
					[hideClearButton]="true"
					[dropdownFormControl]="dropdownControl"
					[dropdownPlaceholder]="'Select a campaign'"
					[dropdownData]="dropdownData"
				></app-dropdown-search-select>
			</div>
		</div>
		<div class="cs-buttons-wrapper">
			<btn04 (onClick)="onCreateNewClick()">CREATE NEW</btn04>
			<btn01 (onClick)="onContinueClick()" [disabled]="dropdownControl.value === null" class="btn-continue">CONTINUE</btn01>
		</div>
	</div>
</div>
