import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

@Component({
	selector: 'app-default-no-row-overlay',
	templateUrl: './default-no-row-overlay.component.html',
	styleUrls: ['./default-no-row-overlay.component.scss']
})
export class DefaultNoRowOverlayComponent implements INoRowsOverlayAngularComp {
	public selectedValue: string;
	private params: INoRowsOverlayParams;

	constructor() {}

	public agInit(params: INoRowsOverlayParams): void {
		this.params = params;
	}
}
