<div *ngIf="dexterHealthValues" class="container">
	<mat-card>
		<div class="body-section">
			<div class="left-chart">
				<graph09
					[startAngle]="90"
					[valueType]="'%'"
					[height]="'150px'"
					[width]="'150px'"
					[color]="optimizeService.getColorAndStatus(overallScoreValue).colorCode"
					[value]="overallScoreValue ? overallScoreValue : 0"
				></graph09>
			</div>
			<div class="right-text">
				<div class="right-header">
					<font05>
						<span class="health-overall">
							Dexter has found your campaign's health overall to be
							<span>{{ optimizeService.getColorAndStatus(overallScoreValue).overallStatus }}</span>
							.
						</span>
					</font05>
				</div>
				<div class="content-right">
					<div class="right-details">
						<div class="details-img">
							<img src="assets/icons/optimize/Positive-Icon.svg" />
							<font06><span class="text-detail">Landing Page Comparisons:</span></font06>
						</div>
						<div class="details-img">
							<img src="assets/icons/optimize/Positive-Icon.svg" />
							<font06><span class="text-detail">Quality:</span></font06>
						</div>
					</div>
					<div class="right-details">
						<div class="details-img">
							<font06>
								<span class="text-ans">{{ comparisonText }}</span>
							</font06>
						</div>
						<div class="details-img">
							<font06>
								<span class="text-ans">{{ qualityRanking }}</span>
							</font06>
						</div>
					</div>
					<div class="right-details">
						<div class="details-img">
							<img src="assets/icons/optimize/negate-icon.svg" />
							<font06><span class="text-detail">Sentiment Analysis:</span></font06>
						</div>
						<div class="details-img">
							<img src="assets/icons/optimize/negate-icon.svg" />
							<font06><span class="text-detail">Engagement Rate:</span></font06>
						</div>
					</div>
					<div class="right-details">
						<div class="details-img">
							<font06>
								<span class="text-ans">{{ sentimentText }}</span>
							</font06>
						</div>
						<div class="details-img">
							<font06>
								<span class="text-ans">{{ engagementRate }}</span>
							</font06>
						</div>
					</div>
					<div class="right-details">
						<div class="details-img">
							<img src="assets/icons/optimize/negate-icon.svg" />
							<font06><span class="text-detail">Landing Page Alerts:</span></font06>
						</div>
						<div class="details-img">
							<img src="assets/icons/optimize/negate-icon.svg" />
							<font06><span class="text-detail">Conversion Rate:</span></font06>
						</div>
					</div>
					<div class="right-details">
						<div class="details-img">
							<font06>
								<span class="text-ans">{{ pageAlertText }}</span>
							</font06>
						</div>
						<div class="details-img">
							<font06>
								<span class="text-ans">{{ conversionRate }}</span>
							</font06>
						</div>
					</div>
				</div>
				<div class="common">
					<div
						[ngClass]="{
							negative: landingPageComparison?.alertSummary < 60,
							neutral: landingPageComparison?.alertSummary === 60,
							positive: landingPageComparison?.alertSummary >= 60
						}"
						class="list"
					>
						<div class="circle"></div>
						<p [innerHTML]="comparisonText"></p>
					</div>
				</div>

				<div class="common">
					<i-tooltip [title]="landingPageAlert?.alertSummary">
						<div
							[ngClass]="{
								negative: landingPageAlert?.alertSummary !== 'Your link works!',
								positive: landingPageAlert?.alertSummary === 'Your link works!'
							}"
							class="list"
						>
							<div class="circle"></div>
							<p [innerHTML]="pageAlertText"></p>
						</div>
					</i-tooltip>
				</div>

				<div class="common">
					<div
						[ngClass]="{
							negative: sentimentAnalysis?.alertSummary === 'Negative',
							positive: sentimentAnalysis?.alertSummary === 'Positive',
							neutral: sentimentAnalysis?.alertSummary === 'Neutral'
						}"
						class="list"
					>
						<div class="circle"></div>
						<p [innerHTML]="sentimentText"></p>
					</div>
				</div>

				<div class="common">
					<i-tooltip [title]="distributionComparison?.alertSummary">
						<div class="list default">
							<div class="circle"></div>
							<p [innerHTML]="distributionText"></p>
						</div>
					</i-tooltip>
				</div>
			</div>
		</div>
	</mat-card>
</div>
