import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { KnowledgebaseComponent } from 'src/app/sidenav/component-pieces/knowledgebase/knowledgebase.component';

@Component({
	selector: 'app-knowledge-home',
	templateUrl: './knowledge-home.component.html',
	styleUrls: ['./knowledge-home.component.scss']
})
export class KnowledgeHomeComponent implements OnInit {
	@Input() public newToHome = false;
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

	public searchControl: FormControl = new FormControl('');
	private unsubscriber$ = new Subject<void>();

	public showView = false;

	constructor(public router: Router, public kbService: KnowledgebaseService, public dialogRef: MatDialogRef<KnowledgebaseComponent>) {}

	ngOnInit(): void {
		this.searchControl.valueChanges.pipe(takeUntil(this.unsubscriber$), debounceTime(2000)).subscribe(search => {
			if (search.length > 0) {
				this.kbService.searchQuery$.next(search);
				this.searchKnowledgeBase();
			}
		});
	}

	public searchKnowledgeBase(): void {
		this.router.navigate([{ outlets: { 'knowledge-base-modal': 'search' } }]);
		this.kbService.knowledgebaseHome$.next(false);
	}

	public moveToModalRoute(route: string): void {
		if (route === 'submitTicket') {
			this.router.navigate([{ outlets: { 'knowledge-base-modal': 'submit-ticket' } }]);
			this.kbService.knowledgebaseHome$.next(false);
		} else if (route === 'viewTickets') {
			this.kbService.knowledgebaseShowView$.next(false);
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseHomeVisited$.next(true);
			this.kbService.articleDataValue$.next([]);
			this.kbService.searchDataValue$.next([]);
			this.kbService.searchValue$.next('');
			this.kbService.frameIndexValue$.next(0);
			this.kbService.knowledgebaseHome$.next(false);
			this.kbService.knowledgebaseShowTickets$.next(true);
		}
	}

	public closeModal(): void {
		this.dialogRef.close();
	}
}
