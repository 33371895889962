export enum SettingsPageIndexEnum {
	MyAccount,
	UserManagement,
	MySubscription,
	ManagePermissions,
	BillingAndPayment,
	ChangePassword
}

export enum SettingsPageLabelsEnum {
	MyAccount = 'My Account',
	UserManagement = 'User Management',
	Integrations = 'Integrations',
	MySubscription = 'My Subscription',
	ManagePermissions = 'Manage Permissions',
	BillingAndPayment = 'Billing and Payment',
	ChangePassword = 'Change Password'
}

export enum NewSettingsPageLabelsEnum {
	MyAccount = 'MY PROFILE',
	MySubscription = 'MY PLAN',
	BillingAndPayment = 'PAYMENT & BILLING',
	//ManagePermissions = 'CHANNEL MANAGEMENT',
	UserManagement = 'USER ADMIN',
	ChangePassword = 'SECURITY'
}

export enum SettingsPageUrlsEnum {
	MyAccount = '/user-management/my-account',
	UserManagement = '/user-management/users',
	Integrations = '/user-management/integrations',
	MySubscription = '/user-management/my-subscription',
	ManagePermissions = '/user-management/manage-permissions',
	BillingAndPayment = '/user-management/billing-and-payment',
	ChangePassword = '/user-management/change-password'
}

export enum NewSettingsPageUrlsEnum {
	MyAccount = '/user-management/my-profile',
	UserManagement = '/user-management/user-admin',
	MySubscription = '/user-management/my-plan',
	//ManagePermissions = '/user-management/channel-management',
	BillingAndPayment = '/user-management/payment-and-billing',
	ChangePassword = '/user-management/security'
}
