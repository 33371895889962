<app-expansion-panel-sc
	class="scs-expansion-panel"
	[isSelected]="isPanelSelected"
	(click)="onPanelClick.emit('facebook-registration')"
	[expansionTitle]="'Facebook Registration'"
	[isExpandable]="false"
	id="facebook-registration"
	style="margin-bottom: 1rem; display: block;"
>
	<div expansion-content>
		<div class="sac-subtitle">
			<mat-slide-toggle
				[formControl]="appRegisterControl"
				(change)="checkAppRegistered()"
				[checked]="isFacebookAppRegistered"
				class="sac-slider"
			></mat-slide-toggle>
			<span>Is your App registered with Facebook?</span>
		</div>
		<div *ngIf="!isFacebookAppRegistered">
			<div>
				<span class="warning">
					<img src="assets/icons/campaign-smart-create/new/awesome-exclamation-triangle.svg" width="17" />
					Warning
				</span>
			</div>
			<font08>
				Please check with Facebook if your app is SDK registered otherwise it will not appear in the below search bar. If it is not SDK registered, your
				ad will not be able to track app installs.
			</font08>
			<font08>
				If your app is registered with Facebook, then your campaign can be optimised fully and track via app installs.
			</font08>
		</div>
		<div *ngIf="isFacebookAppRegistered">
			<div>
				<span class="warning info">
					<i class="k-icon k-i-exclamation-circle"></i>
					Success
				</span>
			</div>
			<font08>
				If your app is registered with Facebook, then your campaign can be optimised fully and track via app installs.
			</font08>
		</div>
	</div>
</app-expansion-panel-sc>
