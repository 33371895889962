<div class="grid-section">
	<div class="spinner" *ngIf="submitting">
		<lolly-spinning-loader></lolly-spinning-loader>
	</div>
	<div class="form-area" *ngIf="!submitting">
		<div class="container">
			<button mat-button class="ml-n20" *ngIf="paymentModeSelected" (click)="deselectMode()">
				<mat-icon>chevron_left</mat-icon>
				Back
			</button>
			<div class="title">Order Summary</div>
			<div class="logo"><img src="assets/images/signup/flash-icon.svg" /></div>

			<div class="price">
				<div class="title">Total Amount</div>
				<div class="amount">$ {{ planDetails?.totalAmountExVat }}</div>
			</div>
			<div class="duration">
				<!-- <div class="remark">Billed {{ planDetails?.billingInterval }}ly.</div>
                <div class="sub-title">We will bill you every {{ planDetails?.billingInterval }} on the 1st, unless you
                    cancel.</div> -->
				<div class="sub-title2">Your next bill will be on the {{ planDetails?.billingPeriodTo | date: 'dd.MM.yy' }}</div>
			</div>

			<div class="footer-link">
				Got a question? &nbsp;
				<a href="https://www.filed.com/contact-us-filed/">Don't worry. We here to help you</a>
			</div>
		</div>
		<ng-container *ngIf="!paid">
			<div class="right-pane w-800px d-flex d-align-items-c justify-content-center" *ngIf="!paymentModeSelected">
				<div class="d-flex d-j-c-flex-column d-align-items-c justify-content-center mt-n50px">
					<font04>Select a payment method</font04>
					<div class="payment-mode wallactvie d-flex d-align-items-c justify-content-between mt-24px" (click)="selectMode('BlueSnap')">
						<font06>Debit/Credit Card</font06>
						<div class="d-flex d-align-items-c">
							<img class="visa" src="assets/icons/Visa.svg" />
							<img class="mastercard ml-10px" src="assets/icons/Master.svg" />
						</div>
					</div>
					<div class="payment-mode wallInactvie d-flex d-align-items-c justify-content-center mt-16px">
						<img class="visa" src="assets/icons/paypal-logo-black-and-white.png" height="25px" />
					</div>
				</div>
			</div>
			<div class="right-pane" *ngIf="paymentModeSelected">
				<div class="inner-div">
					<div class="payment-link">
						Enter payment below, and we good to go!
					</div>
					<div class="mobile-ruler">
						<div class="ruler"></div>
						<div class="content">Enter Payment Details</div>
						<div class="ruler"></div>
					</div>
					<div class="title">Enter Payment Detail</div>

					<div class="card-image" *ngIf="paymentWallType == 'BlueSnap'">
						<img class="visa" src="assets/icons/Visa.svg" />
						<img class="mastercard" src="assets/icons/Master.svg" />
					</div>
					<div class="card-image" *ngIf="paymentWallType == 'PayPal'">
						<img class="visa" src="assets/icons/pp-logo.png" height="25px" />
					</div>
					<div class="form">
						<div *ngIf="!payNewCard">
							<div class="table-header">
								<div class="cards">
									<font07-6>
										Your Credit Cards
									</font07-6>
								</div>
								<div class="d-flex">
									<div class="name">
										<font07>Name on card</font07>
									</div>
									<div class="expiry">
										<font07>Expiry</font07>
									</div>
								</div>
							</div>
							<ng-container>
								<div *ngFor="let card of cardLists">
									<div
										class="table-data cursor-pointer"
										[class.active-class]="
											defaultCard?.cardLastFourDigits == card.cardLastFourDigits && defaultCard?.cardType == card.cardType
										"
										[class.processing]="isprocessing"
										(click)="changeDefaultPaymentCard(card)"
									>
										<div class="left-card">
											<span class="check">
												<img
													src="assets/icons/Listing-Icon.svg"
													*ngIf="
														defaultCard?.cardLastFourDigits !== card.cardLastFourDigits || defaultCard?.cardType !== card.cardType
													"
												/>
												<img
													src="assets/icons/fullfilled.png"
													*ngIf="defaultCard?.cardLastFourDigits == card.cardLastFourDigits && defaultCard?.cardType == card.cardType"
												/>
											</span>
											<span class="card ml-10px">
												<img class="visa" src="assets/icons/Visa.svg" *ngIf="card.cardType.toLowerCase() == 'visa'" />
												<img class="mastercard" src="assets/icons/Master.svg" *ngIf="card.cardType.toLowerCase() == 'mastercard'" />
											</span>
											<span class="number ml-10px">
												<strong>{{ card.cardType }} {{ card.funding }} Card</strong>
												/ {{ card.cardLastFourDigits }}
											</span>
										</div>

										<div class="right-card">
											<span class="name">{{ card.billingInfo.holderName }}</span>
											<span class="expiry">{{ card.expirationMonth }}/{{ card.expirationYear }}</span>
										</div>
									</div>
								</div>
							</ng-container>
							<div>
								<btn01 (onClick)="addnewCard()">Add New Card</btn01>
							</div>
						</div>
						<div *ngIf="payNewCard">
							<div class="perrows" *ngIf="paymentWallType == 'BlueSnap'">
								<div class="column">
									<label>
										Name as per the card
										<span>*</span>
									</label>
									<user-tb-2
										[control]="cardDetails.controls['cardName']"
										placeHolderTextBox="Enter name as seen on card"
										class="input-box"
									></user-tb-2>
								</div>
								<div class="column">
									<div>
										<label>
											Card Number
											<span>*</span>
										</label>
										<!-- <card-input
                                            class="input-box"
                                            [control]="cardDetails.controls['cardNumber']"
                                            [placeHolderTextBox]="'Enter 16 digit number'"
                                        ></card-input> -->
										<div class="ccn">
											<div class="form-control" id="card-number" data-bluesnap="ccn"></div>
											<div id="card-logo" class="input-group-addon">
												<img src="https://files.readme.io/d1a25b4-generic-card.png" height="20px" />
											</div>
										</div>
										<span class="helper-text" id="ccn-help"></span>
									</div>
									<div class="perrows mt-10px">
										<div class="columnp expiry">
											<label>
												Expiry Date
												<span>*</span>
											</label>
											<!-- <user-tb-2 [control]="expirationDate" placeHolderTextBox="MM/YYYY" class="input-box"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    (keyup)="formatExpiryDate($event)"></user-tb-2> -->
											<div class="exp" id="exp-date" data-bluesnap="exp"></div>
											<span class="helper-text" id="exp-help"></span>
										</div>
										<div class="columnp cvv">
											<label>
												CVV
												<span>*</span>
											</label>
											<!-- <user-tb-2 [control]="cardDetails.controls['securityCode']"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                placeHolderTextBox="3 digit" class="input-box"></user-tb-2> -->
											<div class="CVV" data-bluesnap="cvv"></div>
											<span class="helper-text" id="cvv-help"></span>
										</div>
									</div>
								</div>
							</div>

							<!-- <div>
                                <div class="sub-title billing-title">Billing details & address</div>
                                <div class="perrows">
                                    <div class="column">
                                        <label>
                                            First line of address
                                            <span>*</span>
                                        </label>
                                        <user-tb-2 [control]="cardDetails.controls['firstAddress']"
                                            placeHolderTextBox="Enter first line of your address" class="input-box">
                                        </user-tb-2>
                                    </div>
                                    <div class="column">
                                        <label>
                                            Second line of address
                                        </label>
                                        <user-tb-2 [control]="cardDetails.controls['secondAddress']"
                                            placeHolderTextBox="Enter your address" class="input-box"></user-tb-2>
                                    </div>
                                </div>
                                <div class="perrows">
                                    <div class="column">
                                        <label>
                                            City
                                            <span>*</span>
                                        </label>
                                        <user-tb-2 [control]="cardDetails.controls['city']"
                                            placeHolderTextBox="Enter in your City" class="input-box"></user-tb-2>
                                    </div>
                                    <div class="column">
                                        <label>
                                            Zipcode/ Postcode
                                            <span>*</span>
                                        </label>
                                        <user-tb-2 [control]="cardDetails.controls['zipCode']"
                                            placeHolderTextBox="Enter your zipcode/postcode" class="input-box"></user-tb-2>
                                    </div>
                                </div>
                                <div class="perrows">
                                    <div class="column">
                                        <label>
                                            Country
                                            <span>*</span>
                                        </label>
                                     <app-country [dropDownControl]="cardDetails.controls['country']"
                                            [placeholderText]="'Select country'" [showCaretIcon]="true"
                                            [dropDownData]="countryData" [allowFilter]="true"
                                            (onItemSelected)="dropDownValueChanges($event)"></app-country>
                                    </div>
                                    <div class="column"></div>
                                </div>
                            </div> -->
						</div>
					</div>
				</div>
				<div class="form-btn">
					<div>
						<label class="d-flex d-align-items-c justify-content-end">
							<input type="checkbox" [checked]="hasUserAgreed ? 'checked' : ''" (change)="onMarkDefault($event.target.checked)" />
							<span>
								Agree to
								<a (click)="openPrivacyPolicy()">terms of services</a>
								and
								<a (click)="openPrivacyPolicy()">privacy policy</a>
								.
							</span>
						</label>
					</div>
					<div>
						<button
							class="btn-signup"
							(click)="continueSignup()"
							[disabled]="!hasUserAgreed"
							[class.content-valid]="hasUserAgreed"
							data-bluesnap="subitButton"
						>
							Pay
						</button>
					</div>
				</div>
				<div class="footer-link">
					Got a question?
					<a href="https://www.filed.com/contact-us-filed/">Don't worry. We here to help you</a>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="paid">
			<div class="right-pane d-flex d-j-c-flex-column">
				<div class="innr-div d-flex d-j-c-flex-column d-j-c-center">
					<img src="assets/icons/dexter-icons/dexter-action-icon.svg" />
					<div class="font2">{{ title }}</div>
					<div class="font1">{{ subtitle }}</div>
					<div class="btn">
						<btn01 (onClick)="navigateToLunch()">
							Go to Lolly!
						</btn01>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
<app-toast-notification></app-toast-notification>
