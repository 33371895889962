import { Component, Injector, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CardDefaultInterface } from 'src/app/authentication/sign-up/subscribe.interface';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { VerifyAddUserComponent } from '../../../modals/verify-add-user/verify-add-user.component';

@Component({
	selector: 'app-payment-cards',
	templateUrl: './payment-cards.component.html',
	styleUrls: ['./payment-cards.component.scss']
})
export class PaymentCardsComponent implements OnInit {
	@Input() source?: string = 'payment';
	@Input() userDetail: any;
	@Input() hideRemove: boolean;
	@Output() updateCurrentDefaultCard: EventEmitter<any> = new EventEmitter();

	private unsubscriber$: Subject<void> = new Subject<void>();
	public cardDetails: any[];

	public loading: boolean;
	public paymentCard: any;
	public currentCard: any;
	public isprocessing: boolean;

	constructor(
		private injector: Injector,
		private toastService: ToastNotificationService,
		private billingService: PaymentServiceApi,
		private sharedStore: Store<SharedState>,
		public dialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.loadUserCreditCards();
	}

	public loadUserCreditCards(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.billingService
			.getPaymentCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						if (details.length === 1) {
							this.hideRemove = true;
						}
						this.cardDetails = details;
						const cards = details.filter(card => card.isDefault);
						this.paymentCard = cards[0];
						this.updateCurrentDefaultCard.emit(cards[0]);
					}
				},
				error => {},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public changeDefaultPaymentCard(card: any): void {
		this.isprocessing = true;
		const payload: CardDefaultInterface = {
			cardLastFourDigits: card.cardLastFourDigits,
			cardType: card.cardType
		};
		this.billingService
			.setDefaultPaymentCards(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				(details: any) => {
					if (details) {
						this.loadUserCreditCards();
					}
					this.isprocessing = false;
				},
				error => {
					this.isprocessing = false;
					this.loadUserCreditCards();
				}
			);
	}

	public removeCard(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());

		const payload: CardDefaultInterface = {
			cardLastFourDigits: this.currentCard.cardLastFourDigits,
			cardType: this.currentCard.cardType
		};
		this.billingService
			.deletePaymentCard(payload)

			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					let index = this.cardDetails.findIndex(
						card => card.cardLastFourDigits == this.currentCard.cardLastFourDigits && card?.cardType == this.currentCard.cardType
					);
					this.cardDetails.splice(index, 1);
				},
				error => {
					console.log(error);
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
	public editCard(card: any): void {
		this.router.navigateByUrl(`/user-management/payment-and-billing/edit-card?lastdigit=${card.cardLastFourDigits}&type=${card.cardType}`);
	}
	public addCard(): void {
		this.router.navigate(['/user-management/payment-and-billing/add-card']);
	}
	public openConfirmModal(card: any): void {
		this.currentCard = card;
		const dialogRef = this.dialog.open(VerifyAddUserComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Delete card',
			text: 'Are you sure you want to delete this payment card?',
			closeBtn: true,
			actionBtn: true,
			actionBtnText: 'Continue'
		};
		dialogRef.afterClosed().subscribe((confirm: boolean) => {
			if (confirm) {
				this.removeCard();
			}
		});
	}
}
