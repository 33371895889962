<div class="container">
	<div class="left-dock">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="right-dock">
		<div class="wrapper">
			<div class="logo">
				<img src="assets/logo/lolly-logo.svg" />
			</div>
			<div class="started-text">{{ 'Verify your email' }}</div>
			<div class="cta-text">{{ 'Please check your inbox for a verification link to confirm your identity.' }}</div>
			<div class="cta-email">
				<div class="cta-text">{{ 'Link sent to' }}</div>
				&nbsp;
				<div class="cta-theme">{{ registrationDetail?.email ? registrationDetail.email : 'user@lolly.com' }}</div>
			</div>

			<div class="email-btns">
				<div class="gmail-btn-wrapper">
					<a class="btn" href="https://mail.google.com/mail/" target="_blank">
						<div class="items">
							<img src="assets/images/gmail2.svg" />
							<div class="text">{{ 'Open Gmail' }}</div>
						</div>
					</a>
				</div>
				<div class="outlook-btn-wrapper">
					<a class="btn" href="https://outlook.office.com/mail/" target="_blank">
						<div class="items">
							<img src="assets/images/outlook.svg" />
							<div class="text">{{ 'Open Outlook' }}</div>
						</div>
					</a>
				</div>
			</div>

			<div (click)="resendVerificationEmail()" class="bottom-text-action">
				{{ 'Resend' }}
			</div>

			<div (click)="tryDifferentEmail()" class="bottom-text-action less-margin">
				{{ 'Try different email' }}
			</div>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
