import { SharedPreferences } from 'src/app/shared/services/general-settings/models/shared-preferences/shared-preferences.model';
import { DataInterface } from '../data-interface.model';

export const initialState = {
	isLoaded: false,
	errorCode: null,
	data: {
		adsManagerViews: {
			facebookCampaigns: null,
			facebookAdsets: null,
			facebookAds: null,
			googleCampaigns: null,
			googleAdgroups: null,
			googleAds: null,
			googleKeywords: null
		},
		userDashboards: null
	}
} as DataInterface<SharedPreferences>;
