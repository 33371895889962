import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ColumnApi, DetailGridInfo, GridApi } from 'ag-grid-community';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionParamsInterface } from '../../../../back-office/models/action-params.interface';
import { GenericPopUpComponent } from '../../../../back-office/popUps/generic-pop-up/generic-pop-up.component';
import { ActionTypeEnum } from '../../../../back-office/users/action-type.enum';
import { AgGridColumnDefEnum } from '../../../../back-office/users/ag-grid-column-def.enum';
import { DeleteActionEnum } from '../../../../back-office/users/delete-action.enum';
import { ResetActionEnum } from '../../../../back-office/users/reset-action.enum';
import {
	validationEmailMessages,
	validationNameMessages,
	validationPhoneMessages,
	validationRoleMessages,
	validationSurnameMessages
} from '../../../../back-office/users/user-generic/user-validation-messages';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { MiscellaneousPermissions } from '../../../../shared/permisions/enums/miscellaneous-permissions';
import { Modules } from '../../../../shared/permisions/enums/modules';
import { PermissionsDirectiveInterface } from '../../../../shared/permisions/models/permissions-directive.interface';
import { HideGlobalSpinner } from '../../../../shared/state/shared.actions';
import { getFacebookBusinessOwnerId } from '../../../../shared/state/user/user.reducer';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { User } from '../../../../_models/user-models/user';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { UserServiceApi } from '../../../../_services/user/user.api.service';
import { FrameworkComponentsUserManagementInterface } from '../../../models/framework-components-user-management-interface';
import { NewUserModel } from '../../../models/new-user-model';
import { UserActionButtonReturnInterface } from '../../../models/user-action-button-return-interface';
import { UserManagementPopupEditInterface } from '../../../models/user-management-popup-edit.interface';
import { UserManagementPopupInterface } from '../../../models/user-management-popup.interface';
import { UserManagementUserPopupInterface } from '../../../models/user-management-user-popup.interface';
import { UserManagementState } from '../../../state/user-management.reducer';
import { CreateNewUserComponent } from '../../create-and-edit-user/create-new-user.component';
import { ActionsButtonUserManagementComponent } from '../actions-button-user-management/actions-button-user-management.component';
import { ToggleStateComponent } from '../toggle-state-component/toggle-state.component';
import { AgGridFirstColumnInterface } from '../../../../reports/models/ag-grid-first-column.interface';
import { ToggleIconsInterface } from '../../../../shared/master-table/models/toggle-icons.interface';
import { MasterTableGridReadyInterface } from '../../../../shared/master-table/models/master-table-grid-ready.interface';

@Component({
	selector: 'app-filed-users',
	templateUrl: './filed-users.component.html',
	styleUrls: ['./filed-users.component.scss']
})
export class FiledUsersComponent implements OnInit, OnDestroy {
	public rowDataLength = 0;
	public currentStep: number = 0;
	public firstStep: boolean = false;
	public secondStep: boolean = true;
	public thirdStep: boolean = true;
	public actionTypeButtons = ActionTypeEnum;
	public deleteAction = DeleteActionEnum;
	public resetAction = ResetActionEnum;
	public agGridColumnDef = AgGridColumnDefEnum;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public validationNameMessages = validationNameMessages;
	public validationSurnameMessages = validationSurnameMessages;
	public validationEmailMessages = validationEmailMessages;
	public validationPhoneMessages = validationPhoneMessages;
	public validationRoleMessages = validationRoleMessages;
	public columnDefs = [
		{
			headerName: this.agGridColumnDef.HeaderName,
			cellRenderer: this.agGridColumnDef.CellRenderer,
			cellRendererParams: {
				onClick: this.actionButton.bind(this)
			},
			width: 87,
			maxWidth: 87,
			suppressSorting: true,
			suppressMenu: true,
			sortable: false
		},
		{
			headerName: this.agGridColumnDef.UserHeaderName,
			cellRenderer: this.agGridColumnDef.CellRenderer2,
			cellRendererParams: {
				onClick: this.stateButton.bind(this)
			},
			width: 100,
			maxWidth: 100
		},
		{ field: 'fullName', filter: true, sortable: true, resizable: true, maxWidth: 350 },
		{ field: 'email', filter: true, sortable: true, resizable: true, maxWidth: 350 },
		{ field: 'position', filter: true, sortable: true, resizable: true, maxWidth: 350 },
		{ field: 'phoneNumber', filter: true, sortable: true, resizable: true, maxWidth: 350 },
		{ field: 'lastLogin', filter: true, sortable: true, resizable: true, maxWidth: 350 }
	];
	public defaultColDef = {
		flex: 1
	};
	public iconsConfiguration: ToggleIconsInterface = {
		isExpandButtonShown: true,
		isFilterButtonShown: false,
		isColumnButtonShown: false,
		isExportButtonShown: true
	};
	public paginationOptions = [25, 50, 100, 200];
	public defaultPageSize = 25;
	public permissionModelUsers: PermissionsDirectiveInterface = {
		moduleName: Modules.Miscellaneous,
		permissions: [MiscellaneousPermissions.IsBusinessOwner, MiscellaneousPermissions.IsClientEmployee]
	};
	public frameworkComponents: FrameworkComponentsUserManagementInterface;
	public rowData: User[];
	public filteredRowData: User[];
	public businessOwnerFacebookId: string;
	public formIsValid: boolean;
	public searchFormControl: FormGroup;
	public employeeFromControl: FormGroup;
	public subscription: Subscription;
	public addingUser: User;
	public userId: number;

	private unsubscriber$ = new Subject<void>();
	private gridApi: GridApi;
	private gridColumnApi: ColumnApi;

	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private formBuilder: FormBuilder,
		private matDialog: MatDialog,
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService,
		private store: Store<UserManagementState>,
		private toaster: ToastNotificationService
	) {
		this.frameworkComponents = {
			buttonRenderer: ActionsButtonUserManagementComponent,
			buttonRenderer2: ToggleStateComponent
		};
	}

	public ngOnInit(): void {
		this.fetchStore();
		this.getTableData();
		this.createForm();
		this.filteredData();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onFirstDataRendered(params: ActionParamsInterface): void {
		params.api.sizeColumnsToFit();
	}

	public stateButton(event: UserActionButtonReturnInterface): void {}

	public openPopUp(): void {
		this.matDialog
			.open<CreateNewUserComponent, UserManagementUserPopupInterface>(CreateNewUserComponent, {
				data: {
					titlePopup: 'Create New User',
					labelButtonLeftSide: 'Back',
					labelButtonRightSide: 'NEXT'
				},
				width: '50%',
				height: '100%',
				panelClass: ['matdialog-no-padding', 'slide', 'slideInRight'],
				position: {
					top: '0',
					right: '0'
				}
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(user => {
				if (user) {
					this.createUser(user);
				}
			});
	}

	public createUser(user: NewUserModel): void {
		this.userServiceApi
			.createClientEmployee(user)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					user.id = res;
					user.fullName = user.firstName + ' ' + user.lastName;
					this.gridApi.applyTransaction({ add: [user] });
					this.store.dispatch(new HideGlobalSpinner());
					this.toastNotification.sendSuccessToast('The user has been successfully created.');
				},
				err => {
					if (err.error[0].code === 'Filed__Domain__NA__User__ExistsWithSameCriteria') {
						this.toastNotification.sendErrorToast('A user with this email already exists!');
					} else {
						this.toastNotification.sendErrorToast('Cannot create user. Please try again later!');
					}
				}
			);
	}

	public editUser(user: NewUserModel): void {
		this.userServiceApi
			.updateClientEmployee(user, user.id)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.store.dispatch(new HideGlobalSpinner());
				},
				() => this.toaster.sendErrorToast('Cannot update user. Please try again later!'),
				() => {
					this.filteredRowData = this.filteredRowData.map(user_r => {
						if (user_r.id === user?.id) {
							user_r.firstName = user?.firstName;
							user_r.lastName = user?.lastName;
							user_r.fullName = user?.firstName + ' ' + user?.lastName;
							user_r.phoneNumber = user?.phoneNumber;
							user_r.position = user?.position;
						}
						return user_r;
					});
					this.filteredRowData = [...this.filteredRowData];
					this.store.dispatch(new HideGlobalSpinner());
					this.toastNotification.sendSuccessToast('The user has been successfully updated!');
				}
			);
	}

	public onGridReady(params: MasterTableGridReadyInterface): void {
		this.gridApi = params.gridApi;
		this.gridColumnApi = params.columnApi;

		params.gridApi.setRowData(this.rowData);
		params.gridApi.sizeColumnsToFit();
	}

	public actionButton(event: UserActionButtonReturnInterface): void {
		if (event.actionType === this.actionTypeButtons.Delete) {
			this.userId = event.userId;
			this.matDialog
				.open<GenericPopUpComponent, UserManagementPopupInterface>(GenericPopUpComponent, {
					data: {
						image: this.deleteAction.Image,
						header: this.deleteAction.Header,
						message: this.deleteAction.Message,
						actionAnswer: this.actionTypeButtons.Delete,
						noActionAnswer: this.actionTypeButtons.Cancel
					},
					panelClass: ['generic-popup']
				})
				.afterClosed()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(confirm => {
					if (confirm) {
						this.userServiceApi
							.removeUser(this.userId)
							.pipe(takeUntil(this.unsubscriber$))
							.subscribe(
								() => {},
								() => this.toaster.sendErrorToast('Cannot remove user. Please try again later!'),
								() => {
									this.gridApi.applyTransaction({ remove: [event.rowData] });

									this.toastNotification.sendSuccessToast('The user has been successfully deleted.');
								}
							);
					}
				});
		}
		if (event.actionType === this.actionTypeButtons.Edit) {
			this.matDialog
				.open<CreateNewUserComponent, UserManagementPopupEditInterface>(CreateNewUserComponent, {
					data: {
						titlePopup: 'Edit User',
						labelButtonLeftSide: 'Back',
						labelButtonRightSide: 'NEXT',
						firstName: event.rowData.firstName,
						lastName: event.rowData.lastName,
						email: event.rowData.email,
						id: event.rowData.id,
						phoneNumber: event.rowData.phoneNumber,
						accountState: event.rowData.accountState,
						position: event.rowData.position,
						edit: true
					},
					width: '50%',
					height: '100%',
					panelClass: ['matdialog-no-padding', 'slide', 'slideInRight'],
					position: {
						top: '0',
						right: '0'
					}
				})
				.afterClosed()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					if (res) {
						this.editUser(res);
					}
				});
		}
	}

	public filteredData(): void {
		this.searchFormControl
			.get('searchForm')
			.valueChanges.pipe()
			.subscribe(value => {
				value = value.toLowerCase();
				this.filteredRowData = this.rowData.filter(data => {
					return (
						data.email.toLowerCase().includes(value) ||
						data.firstName.toLowerCase().includes(value) ||
						data.lastName.toLowerCase().includes(value) ||
						data.phoneNumber.includes(value) ||
						data.id === +value
					);
				});
				this.filteredRowData = [...this.filteredRowData];
			});
	}

	public getTableData(): void {
		this.userServiceApi
			.getAllUsersByOwnerFacebookId(this.businessOwnerFacebookId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res => {
					this.rowData = res;
					this.rowData.map(user => {
						user.fullName = user.firstName + ' ' + user.lastName;
						if (user.lastLogin) {
							user.lastLogin = moment(user.lastLogin).format('DD-MMM-YYYY');
						}
					});
					this.rowDataLength = this.rowData.length;
					this.filteredRowData = this.rowData;
				},
				() => null,
				() => {
					this.gridApi?.sizeColumnsToFit();
				}
			);
	}

	private fetchStore(): void {
		this.store.pipe(select(getFacebookBusinessOwnerId)).subscribe(businessOwnerFacebookId => {
			this.businessOwnerFacebookId = businessOwnerFacebookId;
		});
	}

	private createForm(): void {
		this.searchFormControl = this.formBuilder.group({
			searchForm: new FormControl(null)
		});
	}
}
