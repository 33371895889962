import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appDrop]'
})
export class DropDirective<T> {
	@Output() fileChange = new EventEmitter<DragEvent>();

	constructor() {}

	@HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	@HostListener('drop', ['$event'])
	public onDrop(evt: DragEvent) {
		evt.preventDefault();
		evt.stopPropagation();
		this.fileChange.emit(evt);
	}
}
