import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AAAModuleState, selectAAAModuleState } from '..';
import { AdPreviewState } from './ad-preview.reducer';

const getAdPreview = createSelector(selectAAAModuleState, (state: AAAModuleState) => state.adPreview);
export const getAdPreviewPlacements = createSelector(getAdPreview, (state: AdPreviewState) => state.dropdownPreviewPlacement);

export const getAdPreviewIframeSrc = createSelector(getAdPreview, (state: AdPreviewState) => state.iframeSrc);

export const getAdPreviewWidth = createSelector(getAdPreview, (state: AdPreviewState) => state.previewWidth);

export const getAdPreviewHeight = createSelector(getAdPreview, (state: AdPreviewState) => state.previewHeight);
export const getIsFetchingIframe = createSelector(getAdPreview, (state: AdPreviewState) => state.isFetchingIframe);
