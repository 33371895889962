<div>
	<app-expansion-panel-sc [isSelected]="false" [expansionTitle]="'Instant Form'" [isExpandable]="false">
		<div expansion-content>
			<font07-7>
				Make connections with people by letting them send contact information and other details to you through a form.
				<a href="#">Learn More</a>
			</font07-7>
			<p><btn24 (onClick)="openDialog()">Create Form</btn24></p>
			<div class="alert-danger">
				<span class="icon"><i class="fas fa-exclamation-triangle"></i></span>
				Choose or create an Instant Form for your lead generation campaign.
			</div>
		</div>
	</app-expansion-panel-sc>
</div>
