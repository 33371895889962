<div class="container">
	<div class="title">Two-Factor Authentication</div>
	<div class="sub-title">
		Enabling this will provide an extra layer of security for your account. When logging in, we will ask for a special authentication code from your device.
	</div>
	<div class="buttons">
		<btn07 class="gray-btn" (onClick)="onEnable2Factor()" *ngIf="!is2FactorEnabled">ENABLE TWO FACTOR AUTHENTICATION</btn07>

		<btn07 class="gray-btn" (onClick)="onEdit2Factor()" *ngIf="is2FactorEnabled">UPDATE TWO FACTOR AUTHENTICATION</btn07>
		<btn07 class="gray-btn danger" (onClick)="onDisable2Factor()" *ngIf="is2FactorEnabled">DISABLE TWO FACTOR AUTHENTICATION</btn07>
	</div>
</div>
