import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TokenService } from 'src/app/_services/token.service';
import { VerifyAddUserComponent } from '../../../modals/verify-add-user/verify-add-user.component';

@Component({
	selector: 'app-two-factor-authentication',
	templateUrl: './two-factor-authentication.component.html',
	styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit {
	public loading: boolean = false;
	public is2FactorEnabled: boolean = false;
	private unsubscriber$: Subject<void> = new Subject<void>();
	constructor(private router: Router, public dialog: MatDialog, private token: TokenService) {}

	ngOnInit(): void {
		this.getTwoFactorStatus();
	}

	public getTwoFactorStatus(): void {
		this.token
			.get2TwoFactorStatus()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(detail => {
				this.is2FactorEnabled = detail;
			});
	}

	public onEnable2Factor() {
		this.router.navigate(['user-management/security/enable-2-factor-authentication/setup']);
	}

	public onEdit2Factor() {
		this.router.navigateByUrl(`user-management/security/enable-2-factor-authentication/setup?edit=yes`);
	}

	public onDisable2Factor() {
		const dialogRef = this.dialog.open(VerifyAddUserComponent, {
			width: '480px',
			height: '370px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Two-Factor Authentication!',
			text: 'Are you sure you want to disable two-factor authentication?',
			closeBtn: true,
			actionBtn: true,
			actionBtnText: 'CONFIRM'
		};
		dialogRef.afterClosed().subscribe(details => {
			if (details) {
				this.router.navigateByUrl(`user-management/security/enable-2-factor-authentication/setup?disable=yes`);
			}
		});
	}
}
