<div class="grid-section">
	<div class="form-area">
		<lolly-spinning-loader class="spinner" *ngIf="submitting"></lolly-spinning-loader>
		<div class="container" *ngIf="!submitting">
			<div class="title">Order Summary</div>
			<div class="plan">LOLLY Plan - {{ planDetails?.subscriptionName }}</div>
			<div class="logo"><img src="assets/images/signup/flash-icon.svg" /></div>

			<div class="price">
				<div class="title">Total due today</div>
				<div class="amount">{{ getCurrentlyChargingAmount }}</div>
			</div>
			<div class="duration">
				<div class="remark">Billed {{ planDetails?.billingInterval }}ly.</div>
				<div class="sub-title">
					We will bill you {{ ' ' + getPlanAmount + ' ' }} every {{ planDetails?.billingInterval }} on the 1st, unless you cancel.
				</div>
				<div class="sub-title2">Your next bill will be on the {{ planDetails?.nextInvoiceDate }}</div>
			</div>
			<div class="footer-link">
				Got a question?
				<a href="https://www.filed.com/contact-us-filed/">Don't worry. We here to help you</a>
			</div>
		</div>
		<div class="right-pane" *ngIf="!submitting">
			<div class="inner-div">
				<img src="assets/icons/dexter-icons/dexter-action-icon.svg" />
				<div class="font2">{{ title }}</div>
				<div class="font1">{{ subtitle }}</div>
				<div class="btn">
					<btn01 *ngIf="buttonStatus" [disabled]="!buttonStatus" (onClick)="navigateToLunch()">
						Launch Now!
					</btn01>
					<btn01 *ngIf="!buttonStatus" (onClick)="logout()">
						Log Out
					</btn01>
				</div>
			</div>
		</div>
	</div>
	<div class="mobile-form-area">
		<div class="logo"><img src="assets/icons/dexter-icons/dexter-action-icon.svg" /></div>

		<div class="title">Thank you for joining us!</div>
		<div class="sub-title">You have been sent an email confirmation for the payment.</div>
		<div class="box">
			<div class="title">Order Summary</div>
			<div class="plan">Filed Plan - {{ planDetails?.subscriptionName }}</div>
			<div class="logo"><img src="assets/images/signup/flash-icon.svg" /></div>

			<div class="price">
				<div class="title">Total due today</div>
				<div class="amount">{{ getPlanAmount }}</div>
			</div>
			<div class="duration">
				<div class="remark">Billed {{ planDetails?.billingInterval }}ly.</div>
				<div class="sub-title">We will bill you every {{ planDetails?.billingInterval }} on the 1st, unless you cancel.</div>
				<div class="sub-title sub-title2">Your next bill will be on the {{ planDetails?.nextInvoiceDate }}</div>
			</div>
		</div>
		<div class="btn">
			<button (click)="continueToAwesome()">Continue</button>
		</div>
		<div class="footer-link">
			Got a question?
			<a href="https://www.filed.com/contact-us-filed/">Don't worry. We here to help you</a>
		</div>
	</div>
</div>
