<div
	(click)="buttonClickedHandler($event)"
	class="wrapper-button"
	[ngClass]="{
		'primary-button': buttonClass === buttonClassEnum.Primary,
		'tertiary-button': buttonClass === buttonClassEnum.Tertiary,
		'secondary-button': buttonClass === buttonClassEnum.Secondary,
		'disabled-button': disabled,
		'waiting-button': waiting,
		selected: selected
	}"
>
	<ng-container *ngIf="buttonLabel && buttonLabel.length > 0">
		<span
			[ngClass]="{ 'text-icon-btn': buttonType === buttonTypeEnum.Icon, 'dropdown-text-btn': buttonType === buttonTypeEnum.Dropdown }"
			class="text-button"
		>
			{{ buttonLabel }}
		</span>
	</ng-container>
	<span *ngIf="buttonType === buttonTypeEnum.Icon"><i class="fas {{ icon }}"></i></span>

	<!--icon-->
	<div class="button-icons" (click)="dropdownIconPressedHandler($event)">
		<i *ngIf="buttonType === buttonTypeEnum.Dropdown" class="fas fa-caret-down"></i>
	</div>
</div>
