import { Component, OnDestroy, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { Router } from '@angular/router';
import { AuthenticationState } from '../../state/authentication.reducer';
import { Store } from '@ngrx/store';
import { RegisterUserAccountPlan, RegisterUserEmail, RegisterUserPassword } from '../../state/authentication.action';
import { TarrifPlansInterface } from '../../sign-up/subscribe.interface';

@Component({
	selector: 'app-tarrif-plan',
	templateUrl: './tarrif-plan.component.html',
	styleUrls: ['./tarrif-plan.component.scss']
})
export class TarrifPlanComponent implements OnInit, OnDestroy {
	@Input() public tarrifPlans: TarrifPlansInterface[];
	@Input() public btnLabel: string;
	@Output() public selectedTarrifPlan = new EventEmitter<TarrifPlansInterface>();

	public errorMessage: string;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];

	constructor(private router: Router, private store: Store<AuthenticationState>) {}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {}

	public onSelectTarrif(tarrif: TarrifPlansInterface): void {
		this.selectedTarrifPlan.emit(tarrif);
	}
	public getPlanAmount(value: any): string {
		if (value.amount == 0) {
			return value.price;
		} else {
			let cur = value.currency || '';
			let amount = new Intl.NumberFormat('ja-JP', {
				style: 'currency',
				currency: value.currency || 'USD'
			}).format(value.amount || 0);
			return amount;
		}
	}
}
