import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { UserDetailModel } from '../../_models/login.model';

@Component({
	selector: 'app-personal-welcome',
	templateUrl: './personal-welcome.component.html',
	styleUrls: ['./personal-welcome.component.scss']
})
export class PersonalWelcomeComponent implements OnInit, OnDestroy {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public registrationDetail: UserDetailModel;
	public decodeJwtToken: UserDetailsInterface;

	constructor(private store: Store<AuthenticationState>, private router: Router) {}

	ngOnInit(): void {
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
		this.continueSignup();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$;
	}

	public continueSignup(): void {
		setTimeout(() => {
			this.router.navigate(['/authentication/payment']);
		}, 4000);
	}
}
