<div
	#menuTrigger="matMenuTrigger"
	(menuOpened)="setDelayedOpenedClass()"
	[matMenuTriggerFor]="datepickerMenu"
	[ngClass]="{ disabled: triggerDisabled }"
	class="trigger-wrapper"
>
	<div
		(mouseleave)="toggleMouseOut()"
		(mouseenter)="toggleMouseOver()"
		[ngClass]="{ 'mousehover-datepicker': mouseOverDate }"
		class="fileddatepicker__menu-trigger"
	>
		<i class="fas fa-calendar-alt"></i>
		<div class="date-info head no-select font-w-medium">
			<div>{{ !dateValueFromDexter ? updatedDateModel?.date.startDate.format(dateFormat) : formatDate(dateValueFromDexter.minDate) }}</div>
			<div class="line"></div>
			<div>{{ !dateValueFromDexter ? updatedDateModel?.date.endDate.format(dateFormat) : formatDate(dateValueFromDexter.maxDate) }}</div>
		</div>
		<mat-icon *ngIf="!updatedDateModel?.compareDate.startDate" aria-hidden="false" aria-label="keyboard_arrow_down icon">keyboard_arrow_down</mat-icon>
		<mat-icon *ngIf="updatedDateModel?.compareDate.startDate" aria-hidden="false" aria-label="sync_alt icon">sync_alt</mat-icon>

		<div *ngIf="updatedDateModel?.compareDate.startDate" class="date-info head no-select has-compare-color">
			<div>{{ updatedDateModel?.compareDate.startDate.format(dateFormat) }}</div>
			<div class="line has-background-color"></div>
			<div>{{ updatedDateModel?.compareDate.endDate.format(dateFormat) }}</div>
		</div>
	</div>
</div>

<mat-menu #datepickerMenu="matMenu" [ngClass]="{ new_backdrop: classClose }" [backdropClass]="backdropClass" [hasBackdrop]="true" class="fileddatepicker">
	<div
		(click)="$event.stopPropagation()"
		[ngClass]="{ 'menu-opened': delayedOpenedClass, 'menu-prepare': menuPrepare, simplifiedInterface: useSimplifiedInterface }"
		class="datepicker-main-wrapper"
	>
		<!--Body -->
		<div class="fileddatepicker__body" [ngClass]="{ classforOptimise: classClose }">
			<!--Left Menu-->
			<div *ngIf="!useSimplifiedInterface" [ngClass]="{ hideSideSelect: dateValueFromDexter }" class="fileddatepicker__left-menu">
				<mat-action-list>
					<button (click)="activeIdx = null" [ngClass]="{ active: activeIdx === null }" mat-list-item>Custom</button>
					<ng-container *ngFor="let option of predefinedDateSelectionList; let idx = index">
						<button (click)="changeDate(option, idx)" [ngClass]="{ active: activeIdx === idx }" mat-list-item>
							<ng-container [ngSwitch]="option.type">
								<ng-container *ngSwitchDefault>
									{{ option.label }}
								</ng-container>

								<ng-container *ngSwitchCase="'input'">
									<div class="inputs">
										<input
											(keyup)="updateCalendar($event?.target.value, option, idx)"
											[value]="option.hasParameter"
											matInput
											type="number"
										/>
										<label>{{ option.label }}</label>
									</div>
								</ng-container>

								<ng-container *ngSwitchCase="'dropdown'">
									<div class="dropdowns">
										<div>{{ option.label }} {{ option.selected.label }}</div>
										<mat-select
											(selectionChange)="dropdownChanged(option, idx)"
											[(value)]="option.selected"
											[disableOptionCentering]="true"
										>
											<mat-option *ngFor="let opt of option.selectOptions" [value]="opt">
												{{ opt.label }}
											</mat-option>
										</mat-select>
									</div>
								</ng-container>
							</ng-container>
						</button>
					</ng-container>
				</mat-action-list>

				<!-- hide this since does not work DEV-865
			  <div [ngClass]="compareFlag ? 'active' : ''" class="toggler">
				<mat-slide-toggle (change)="toggleCompareMode($event)" [checked]="compareFlag">Compare</mat-slide-toggle>
			  </div>
			  -->

				<mat-action-list>
					<button (click)="previousPeriod(0)" [disabled]="!compareFlag" [ngClass]="{ active: activeCompareIdx === 0 }" mat-list-item>
						Previous period
					</button>
					<button
						(click)="changeDate(predefinedLastYear, undefined, true)"
						[disabled]="!compareFlag"
						[ngClass]="{ active: activeCompareIdx === null && compareFlag }"
						mat-list-item
					>
						Last year
					</button>
				</mat-action-list>
			</div>
			<!--Right side-->
			<div class="fileddatepicker__right-menu" [ngClass]="{ 'new_right-menu': classClose }" [class.useSimplifiedInterface]="!useSimplifiedInterface">
				<!--Header calendar-->
				<button (click)="close()" class="remove-button">
					<mat-icon [ngClass]="{ closeButton: classClose }">clear</mat-icon>
				</button>
				<div class="header-calendar-wrapper">
					<h4>Select the date range</h4>
				</div>

				<!-- first calendar: for single selection -->
				<ngb-datepicker
					#defaultCalendar
					(select)="onDateSelection($event)"
					[maxDate]="maxDate"
					[dayTemplate]="defaultTemplate"
					[displayMonths]="defaultCalendarDisplayMonths"
					[minDate]="_minDate"
					class="inline-date-picker default-datepicker"
					outsideDays="hidden"
				></ngb-datepicker>

				<ng-template #defaultTemplate let-today="today" let-date let-focused="focused">
					<div
						(mouseenter)="hoveredDate = date"
						(mouseleave)="hoveredDate = null"
						[class.end-date]="isEndDate(date)"
						[class.faded]="isHovered(date) || isInside(date)"
						[class.first-day-of-week]="isFirstDayOfWeek(date)"
						[class.focused]="focused"
						[class.last-day-of-week]="isLastDayOfWeek(date)"
						[class.last-hovered]="lastHovered(date)"
						[class.no-siblings]="hasNoSiblings(date)"
						[class.range]="isRange(date)"
						[class.start-date]="isStartDate(date)"
						class="custom-day-wrapper"
					>
						<div class="custom-day">
							<span [class.today]="today" class="font-w-medium">
								{{ date.day }}
							</span>
						</div>
					</div>
				</ng-template>

				<!-- second calendar: for compare/multi selection -->
				<ngb-datepicker
					#compareCalendar
					(select)="compareOnDateSelection($event)"
					*ngIf="compareFlag"
					[dayTemplate]="compareTemplate"
					[displayMonths]="1"
					[maxDate]="maxDate"
					[minDate]="_minDate"
					class="inline-date-picker compare-datepicker"
					outsideDays="hidden"
					[markDisabled]="markDisabled"
				></ngb-datepicker>

				<ng-template #compareTemplate let-today="today" let-date let-focusedCompare="focused">
					<div
						(mouseenter)="hoveredCompareDate = date"
						(mouseleave)="hoveredCompareDate = null"
						[class.end-date]="isEndDate(date, true)"
						[class.faded]="isHovered(date, true) || isInside(date, true)"
						[class.first-day-of-week]="isFirstDayOfWeek(date)"
						[class.focused]="focusedCompare"
						[class.last-day-of-week]="isLastDayOfWeek(date)"
						[class.last-hovered]="lastHovered(date, true)"
						[class.no-siblings]="hasNoSiblings(date, true)"
						[class.range]="isRange(date, true)"
						[class.start-date]="isStartDate(date, true)"
						class="custom-day-wrapper"
					>
						<span class="custom-day">
							<span [class.today]="today" class="font-w-medium">{{ date.day }}</span>
						</span>
					</div>
				</ng-template>
				<div class="date-range-section" *ngIf="fromDate && toDate">
					<span>{{ fromDate?.day }}/{{ fromDate?.month }}/{{ fromDate?.year }} - {{ toDate?.day }}/{{ toDate?.month }}/{{ toDate?.year }}</span>
				</div>
				<!--Buttons-->
				<div class="fileddatepicker__buttons">
					<app-button
						*ngIf="!dateValueFromDexter"
						(buttonClicked)="clearAllStates()"
						[buttonClass]="buttonClassEnum.Tertiary"
						[buttonType]="buttonTypeEnum.Simple"
						class="reset-btn"
						buttonLabel="Reset"
					></app-button>

					<app-button
						(buttonClicked)="selectDate()"
						[disabled]="!dateHasChanged"
						[buttonClass]="buttonClassEnum.Primary"
						[buttonType]="buttonTypeEnum.Simple"
						buttonLabel="Apply"
						[ngClass]="{ applyButton: classClose }"
					></app-button>
				</div>
			</div>
		</div>
	</div>
	<div class="catalog-view-calender">
		<div class="date-range-section">
			<span>{{ fromDate?.day }}/{{ fromDate?.month }}/{{ fromDate?.year }} - {{ toDate?.day }}/{{ toDate?.month }}/{{ toDate?.year }}</span>
		</div>
	</div>
</mat-menu>
