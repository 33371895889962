import { Component, Input, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsPermissions } from 'src/app/shared/permisions/enums/settings-permissions';
import { FiledPermissions, ModulesPermissions } from 'src/app/shared/permisions/enums/filed-permissions';
import { getFiledId, getPermissions, UserState } from 'src/app/shared/state/user/user.reducer';
import { GuardNavLinks } from 'src/app/user-management/models/nav-link';
import {
	UserManagementState,
	getNewNavigationUserManagementState,
	getActiveBillingPageUserManagement,
	getActiveUserPage
} from 'src/app/user-management/state/user-management.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { Modules } from 'src/app/shared/permisions/enums/modules';
import { MiscellaneousPermissions } from 'src/app/shared/permisions/enums/miscellaneous-permissions';
import { SettingsPageLabelsEnum } from 'src/app/user-management/shared/enums/navigation.enum';
import { UserStateEnum } from 'src/app/_services/user/user-state.enum';
import * as userManagementActions from 'src/app/user-management/state/user-management.actions';

@Component({
	selector: 'app-datatable-pagination',
	templateUrl: './datatable-pagination.component.html',
	styleUrls: ['./datatable-pagination.component.scss']
})
export class DatatablePaginationComponent {
	@Input() public totalPage: number = 1;
	@Output() public eventClick = new EventEmitter<any>();

	public currentPage: number = 1;
	public isOnFirstPage: boolean;
	public isOnLastPage: boolean;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private store: Store<UserManagementState>, private userStore: Store<UserState>, private backOfficeService: BackOfficeService) {}

	ngOnInit() {}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	get getTotalPage() {
		return Math.ceil(this.totalPage / 10);
	}
	public nextPage(): void {
		if (this.currentPage >= this.getTotalPage) {
			this.currentPage = this.currentPage;
		} else {
			const oldPage = this.currentPage;
			this.currentPage += 1;
			this.eventClick.emit({ oldPage: oldPage, newPage: this.currentPage });
		}
		this.checkFirstAndLastPage();
	}
	public previousPage(): void {
		if (this.currentPage <= 1) {
			this.currentPage = this.currentPage;
		} else {
			const oldPage = this.currentPage;
			this.currentPage -= 1;
			this.eventClick.emit({ oldPage: oldPage, newPage: this.currentPage });
		}
		this.checkFirstAndLastPage();
	}

	public checkFirstAndLastPage(): void {
		this.isOnFirstPage = this.currentPage <= 1 ? true : false;
		this.isOnLastPage = this.currentPage >= this.getTotalPage ? true : false;
	}
}
