import { SidenavComponent } from './sidenav/sidenav.component';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { UserManagementModule } from '../user-management/user-management.module';
import { SharedModule } from '../shared/shared.module';

import { FiledLogoComponent } from '../shared/filed-logo/filed-logo.component';
import { LinkAccountDialogComponent } from './link-account-dialog/link-account-dialog.component';
import { ButtonsModule, FontsModule } from '@filed-com/filed-lib';
import { ProgressBarComponentsModule } from '@filed-com/filed-lib';
import { ToastNotificationModule } from '@filed-com/filed-lib';
import { KnowledgebaseComponent } from './component-pieces/knowledgebase/knowledgebase.component';
import { TicketsViewComponent } from './tickets-view/tickets-view.component';
import { SidenavGroupComponent } from './sidenav/sidenav-group.component';
import { NavFilterPipe } from './sidenav/nav-filter.pipe';

@NgModule({
	declarations: [
		SidenavComponent,
		SidenavGroupComponent,
		FiledLogoComponent,
		LinkAccountDialogComponent,
		KnowledgebaseComponent,
		TicketsViewComponent,
		NavFilterPipe
	],
	imports: [
		SharedModule,
		TranslateModule,
		BrowserModule,
		RouterModule,
		UserManagementModule,
		ButtonsModule,
		ProgressBarComponentsModule,
		ToastNotificationModule,
		FontsModule
	],
	providers: []
})
export class SidenavModule {}
