import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { TreeUiComponent } from '../../components/optimization/tree-ui/tree-ui.component';
import { HierarchyDatum } from '../../components/optimization/tree-ui/tree-ui.interface';

@Component({
	selector: 'app-campaign-smart-create-footer',
	templateUrl: './campaign-smart-create-footer.component.html',
	styleUrls: ['./campaign-smart-create-footer.component.scss']
})
export class CampaignSmartCreateFooterComponent {
	@Input() public continueButtonText: 'Next' | 'Publish' = 'Next';
	@Input() public saveButtonText: 'Back' | 'Save as Draft' = 'Save as Draft';
	@Input() public isPublishing: boolean;
	@Input() public disableNext: boolean;
	@Output() public cancel = new EventEmitter<void>();
	@Output() public save = new EventEmitter<void>();
	@Output() public next = new EventEmitter<void>();
	@Output() public back = new EventEmitter<void>();

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor(private injector: Injector, public dialog: MatDialog) {
		this.dialog = this.injector.get<MatDialog>(MatDialog);
	}

	public onCancelClick(): void {
		this.cancel.emit();
	}

	public onSaveAsDraftClick(): void {
		this.save.emit();
	}

	public onNextClick(): void {
		this.next.emit();
	}
	public onBackClick(): void {
		this.back.emit();
	}
}
