import { Component, LOCALE_ID, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { formatDate } from '@angular/common';

@Component({
	selector: 'app-ads-manager-date-cell',
	templateUrl: './ads-manager-date-cell.component.html',
	styleUrls: ['./ads-manager-date-cell.component.scss']
})
export class AdsManagerDateCellComponent implements ICellRendererAngularComp {
	public params: ICellRendererParams;

	constructor(@Inject(LOCALE_ID) public locale: string) {}

	public agInit(params: ICellRendererParams): void {
		this.params = params;
	}

	public refresh(params: ICellRendererParams): boolean {
		return true;
	}

	public dateFormatter(date: Date) {
		if (date == null) return '';
		//todo: change date formatter to => universal
		return formatDate(date, 'dd-MM-yyyy', this.locale);
	}

	public onChange(event: any) {
		this.params.data[this.params.colDef.field] = event.currentTarget.checked;
	}
}
