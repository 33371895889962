import { Component, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationInterface, IntegrationTypeEnum } from '../../models/integration.models';
import { MatDialog } from '@angular/material/dialog';
import { IntegrationConnectPopupComponent } from '../integration-connect-popup/integration-connect-popup.component';
import { IntegrationHelper } from '../../services/integration-helper';
import { TokenService } from 'src/app/_services/token.service';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { UserRejectedReason } from 'src/app/_services/user/user-rejected-reason.enum';
import { UserDetails } from 'src/app/_models/identity-models/identity-detail';
import { SetUserDetails } from 'src/app/shared/state/user/user.actions';
import { select, Store } from '@ngrx/store';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { AuthenticationState, getRedirectUrl } from 'src/app/authentication/state/authentication.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { AccountsService } from 'src/app/accounts/services/accounts.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { EnableFirstTimeCustomer, LogIn } from 'src/app/authentication/state/authentication.action';
import { take, takeUntil } from 'rxjs/operators';
import { UserStateEnum } from 'src/app/_services/user/user-state.enum';
import { TrialDialogComponent } from 'src/app/authentication/trial-dialog/trial-dialog.component';
import { BillingPlan } from 'src/app/user-management/models/billing-plan.interface';

@Component({
	selector: 'app-integration-list',
	templateUrl: './integration-list.component.html',
	styleUrls: ['./integration-list.component.scss']
})
export class IntegrationListComponent implements OnInit {
	@Input() public integrations: IntegrationInterface[] = [];
	private unsubscriber$ = new Subject<void>();
	public storeEmail: FormControl;
	public hasPaymentCard: boolean;
	public billingPlan: BillingPlan;
	public userState: number;

	constructor(
		private tokenService: TokenService,
		private integrationHelper: IntegrationHelper,
		private router: Router,
		private translate: TranslateService,
		private matDialog: MatDialog,
		private store: Store<SharedState>,
		private toastNotificationService: ToastNotificationService,
		private ngZone: NgZone,
		private authenticationService: AuthenticationService,
		private userStore: Store<UserState>,
		private authStore: Store<AuthenticationState>,
		private dialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private accountsService: AccountsService
	) {}

	public ngOnInit(): void {
		this.initEmitter();
		this.storeEmail = new FormControl(null, Validators.compose([Validators.required, Validators.email]));
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	public initEmitter(): void {
		this.integrationHelper.invokeFirstComponentFunction.pipe(takeUntil(this.unsubscriber$)).subscribe((res: { status: boolean; name: string }) => {
			let integrationRes = this.integrations.filter(r => r.source == res.name);
			if (integrationRes.length > 0) {
				integrationRes[0].status = res.status;
				this.initializeGoogle();
			}
		});
	}
	private googleInit(): void {}
	public connectChannel(channel: IntegrationInterface): void {
		if (channel?.integrationType === IntegrationTypeEnum.FeedManagement) {
			this.openDialog(channel);
		} else if (channel?.integrationType === IntegrationTypeEnum.Marketing && channel.source === 'google-normal') {
			this.openDialog(channel);
		}
	}
	public openDialog(channel: IntegrationInterface): void {
		this.matDialog.open(IntegrationConnectPopupComponent, {
			width: '410px',
			height: '430px',
			panelClass: 'reports-save-wrapper',
			data: {
				allData: channel,
				email: this.storeEmail.value,
				redirectUrl: 'finish-connection'
			}
		});
	}

	public initializeGoogle(): void {
		const decodedToken = this.tokenService.decodeToken(localStorage.getItem(StorageKey.token));
		const userDetails = this.authenticationService.getUserDetailsFromJwt(decodedToken);
		if (userDetails?.RejectedReason === UserRejectedReason.FrontOfficeUserWithZeroAdAccounts) {
			this.authenticationService.logout();
			this.toastNotificationService.sendErrorToast(
				'There was an issue with syncing your data. Please wait a few moments before trying to login again. If this problem persists, contact your account manager'
			);
			return;
		}
		this.userStore.dispatch(new SetUserDetails(userDetails));
	}
	public fbConnectionResponse(response: boolean): void {
		if (response) {
			this.store.dispatch(new ShowGlobalSpinner());
			this.ngZone.run(() => {
				this.authStore.dispatch(new EnableFirstTimeCustomer());

				this.backOfficeService
					.getBillingPlan()
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(
						billingPlan => {
							this.userStore.pipe(select(getFiledId), take(1)).subscribe(filedId => {
								this.billingPlan = billingPlan;
								this.backOfficeService
									.getUserState(filedId)
									.pipe(takeUntil(this.unsubscriber$))
									.subscribe(state => {
										this.userState = state;
										this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
											this.checkPaymentWallAndOpenTrialPopup(redirectUrl);
										});
									});
							});
						},
						() => {
							this.store.dispatch(new HideGlobalSpinner());
							this.toastNotificationService.sendErrorToast(
								this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT')
							);
						}
					);
			});
		}
	}

	public checkPaymentWallAndOpenTrialPopup(redirectUrl: string): void {
		this.backOfficeService
			.getAllCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				cards => {
					this.hasPaymentCard = cards && cards.length > 0 ? true : false;
					if (this.billingPlan.isFreeTrial && this.userState === UserStateEnum.Active) {
						this.dialog
							.open<TrialDialogComponent>(TrialDialogComponent, {
								width: '40%',
								data: {
									days: this.billingPlan.freeTrialDuration,
									hasPaymentCard: this.hasPaymentCard
								}
							})
							.afterClosed()
							.subscribe(() => {
								this.authStore.dispatch(new LogIn());
							});
					} else {
						this.authStore.dispatch(new LogIn());
					}
				},
				() => {
					this.store.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}
}
