<div class="grid-section">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/login-background.png" />
	</div>
	<div class="grid-section-two">
		<div class="grid-section-two-inner container">
			<div *ngIf="!loading">
				<div class="filed-logo">
					<img src="assets/logo/lolly-logo.svg" />
				</div>
				<div class="text-description">
					<h2 class="margin-bottom-spacing">Create your password</h2>
					<p class="margin-bottom-spacing">
						Please enter your password below
					</p>
				</div>
				<div class="form-set-password">
					<form class="pw-change-form">
						<div class="margin-bottom-spacing">
							<tb05 [control]="form.get('password')"></tb05>
							<pgb02 [strength]="passwordStrength" [width]="'22.5vw'"></pgb02>
						</div>
						<p *ngIf="form.controls['password'].invalid; else noError">
							*Your password must have at least
							<span *ngIf="form.controls['password'].hasError('minlength') || form.controls['password'].hasError('required')">8 characters,</span>
							<span *ngIf="form.controls['password'].hasError('hasNumber') || form.controls['password'].hasError('required')">1 number,</span>
							<span *ngIf="form.controls['password'].hasError('hasCapitalCase') || form.controls['password'].hasError('required')">
								1 capital letter,
							</span>
							<span *ngIf="form.controls['password'].hasError('hasSmallCase') || form.controls['password'].hasError('required')">
								1 small letter,
							</span>
							<span *ngIf="form.controls['password'].hasError('hasSpecialCharacters') || form.controls['password'].hasError('required')">
								and 1 special character.
							</span>
						</p>
						<ng-template #noError>
							<p>Your password is strong enough!</p>
						</ng-template>

						<div class="margin-bottom-spacing">
							<tb05 (enterPress)="onSubmit()" [control]="form.get('confirmPassword')"></tb05>
							<p *ngIf="form.controls['confirmPassword'].hasError('required') || form.controls['confirmPassword'].hasError('NoPasswordMatch')">
								Passwords do not match
							</p>
						</div>

						<btn21 class="pw-submit-btn" (onClick)="onSubmit()">{{ pageType }}</btn21>
					</form>
				</div>
			</div>
			<div class="loader" *ngIf="loading">
				<lolly-spinning-loader></lolly-spinning-loader>
			</div>
		</div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
