import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-card-with-tooltip',
	templateUrl: './card-with-tooltip.component.html',
	styleUrls: ['./card-with-tooltip.component.scss']
})
export class CardWithTooltipComponent implements OnInit {
	@Input() public title: string = 'Test';
	@Input() public explanation: string = 'Explanation goes here';
	@Input() public width: string = '100%';
	@Input() public height: string = '2.625rem';
	@Input() public isSelected: boolean = false;

	@Output() public selectedValue = new EventEmitter<string>();

	constructor() {}

	ngOnInit(): void {}

	@HostListener('click') public onClick(): void {
		this.isSelected = !this.isSelected;
		if (this.isSelected) {
			this.selectedValue.emit(this.title);
		}
	}
}
