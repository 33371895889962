import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FiledCreditCard } from '../set-creditcard.models';
import { UserManagementState } from '../../../state/user-management.reducer';
import { Store } from '@ngrx/store';
import { catchError, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../../shared/state/shared.actions';
import { SharedState } from '../../../../shared/state/shared.reducer';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { CardInputComponent } from '../card-input/card-input.component';
import { BillingPlan } from '../../../models/billing-plan.interface';
import { BillingAddress } from 'src/app/user-management/shared/billing-and-payment/billing-and-payment.models';
import { Phone } from 'src/app/user-management/models/phone';
import { ContactService } from 'src/app/_services/contact/contact.service';

@Component({
	selector: 'app-card-table',
	templateUrl: './card-table.component.html',
	styleUrls: ['./card-table.component.scss']
})
export class CardTableComponent implements OnInit, OnDestroy {
	@Input() public billingAddressDetails: BillingAddress;

	public cards: FiledCreditCard[];
	public billingPlan: BillingPlan;

	private unsubscriber$ = new Subject<void>();
	public chargebeeLink: string;

	constructor(
		private translate: TranslateService,
		private dialog: MatDialog,
		private toastNotificationService: ToastNotificationService,
		private store: Store<UserManagementState>,
		private backOfficeService: BackOfficeService,
		private sharedStore: Store<SharedState>,
		private contactService: ContactService
	) {}

	public ngOnInit(): void {
		this.fetchStore();
		this.getBillingPlan();
		this.getChargeBeeLink();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private getChargeBeeLink(): void {
		this.backOfficeService
			.getChargeBeeLink()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
					return EMPTY;
				})
			)
			.subscribe(value => {
				this.chargebeeLink = value;
			});
	}

	private fetchStore(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.getAllCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				cards => {
					if (cards) {
						cards.forEach(function (item: FiledCreditCard, index: number): void {
							if (item.isDefault) {
								cards.splice(index, 1);
								cards.unshift(item);
							}
						});
						this.cards = cards;
					}
					this.sharedStore.dispatch(new HideGlobalSpinner());
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}

	public makePrimaryCard(cardId: string): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.makeCardPrimary(cardId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				response => {
					this.fetchStore();
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendSuccessToast('This card will now be your default payment method');
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}

	public confirmDelete(id: string): void {
		this.dialog
			.open<DeleteConfirmationComponent>(DeleteConfirmationComponent, {
				width: '25%'
			})
			.afterClosed()
			.subscribe(valid => {
				if (valid) {
					this.sharedStore.dispatch(new ShowGlobalSpinner());
					this.backOfficeService
						.deletePaymentCard(id)
						.pipe(takeUntil(this.unsubscriber$))
						.subscribe(
							() => {
								this.toastNotificationService.sendSuccessToast('Your card was successfully deleted.');
							},
							() => {
								this.toastNotificationService.sendErrorToast(
									this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT')
								);
							},
							() => {
								this.fetchStore();
								this.sharedStore.dispatch(new HideGlobalSpinner());
							}
						);
				}
			});
	}

	public addPaymentPopup(): void {
		this.dialog
			.open<CardInputComponent>(CardInputComponent, {
				width: '40%',
				height: '100%',
				position: {
					right: '0'
				},
				panelClass: ['matdialog-no-padding', 'slide', 'slideInRight'],
				data: {
					firstCard: false
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (result) {
					this.sharedStore.dispatch(new ShowGlobalSpinner());
					let err = false;

					this.backOfficeService
						.addNewPaymentCard(result.addedCard.cardId, result.isDefault)
						.toPromise()
						.catch(error => {
							this.store.dispatch(new HideGlobalSpinner());
							err = true;
							if (error && error.error && error.error[0] && error.error[0].description && error.error[0].description.length) {
								this.toastNotificationService.sendErrorToast(error.error[0].description.split(':')[1].split('.')[0]);
							} else {
								this.toastNotificationService.sendErrorToast(
									this.translate.instant('Error while trying to save your card changes, please contact support team')
								);
							}

							return;
						})
						.then(id => {
							this.store.dispatch(new HideGlobalSpinner());

							if (!err && id) {
								this.fetchStore();
								this.toastNotificationService.sendSuccessToast(this.translate.instant('YOUR_CREDIT_CARD_WAS_ADDED'));
							}
						});
				}
			});
	}

	public addPaymentWithChargeBee(): void {
		if (!this.checkBillingAddress()) {
			this.toastNotificationService.sendWarningToast(this.translate.instant('Please complete your billing details to add payment'));
			return;
		}
		window.open(this.chargebeeLink, '_blank');
	}

	public getBillingPlan(): void {
		this.backOfficeService
			.getBillingPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				billingPlan => {
					this.billingPlan = billingPlan;
				},
				() => {
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}

	public checkBillingAddress(): boolean {
		const billingTruthy =
			this.billingAddressDetails.billingAddress &&
			this.billingAddressDetails.billingAddress.length > 0 &&
			this.billingAddressDetails.city &&
			this.billingAddressDetails.city.length > 0 &&
			this.billingAddressDetails.country &&
			this.billingAddressDetails.country.length > 0 &&
			this.billingAddressDetails.zipCode &&
			this.billingAddressDetails.zipCode.length > 0;
		return billingTruthy || this.backOfficeService.billingUpdatedSuccess$.value ? true : false;
	}

	public reactivateSubscription(): void {
		this.store.dispatch(new ShowGlobalSpinner());
		this.contactService
			.uncancelSubscription()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					this.getBillingPlan();
					this.toastNotificationService.sendSuccessToast('Your subscription has been successfully reactivated.');
					this.store.dispatch(new HideGlobalSpinner());
				},
				err => {
					this.toastNotificationService.sendErrorToast('There was an error while performing this action, please try again or contact support.');
					this.store.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
