import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'navfilter',
	pure: false
})
export class NavFilterPipe implements PipeTransform {
	transform(items: IterableIterator<any>, filter: string): any {
		if (!items || !filter) {
			return items;
		}
		let filtered = [];

		for (let item of items) {
			if (item.parentName === filter) {
				filtered.push(item);
			}
		}
		return filtered;
	}
}
