<div class="confirmation-dialog-container">
	<div class="close-box">
		<div>
			<span (click)="decline()" class="remove-button">
				<mat-icon>clear</mat-icon>
			</span>
		</div>
	</div>
	<div class="delete-confirmation-image"><img src="/assets/icons/warning.svg" /></div>
	<h3 mat-dialog-title>Confirm Deletion</h3>
	<mat-dialog-content>
		<div class="popup-text">Are you sure you want to continue deleting this payment method?</div>
	</mat-dialog-content>
	<div class="button-container" mat-dialog-actions>
		<app-button
			(buttonClicked)="decline()"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="Cancel"
		></app-button>

		<app-button
			(buttonClicked)="deleteCard()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			class="button-confirm"
			buttonLabel="Delete"
		></app-button>
	</div>
</div>
