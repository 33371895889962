import { Component, Input, OnInit } from '@angular/core';
import { InfoBoxDataModel } from './info-box-data.model';

@Component({
	selector: 'app-info-box',
	templateUrl: './info-box.component.html',
	styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {
	@Input() infoData: InfoBoxDataModel;

	public showInfo = true;

	constructor() {}

	ngOnInit() {
		this.showInfo = !!this.infoData;
	}

	onNoClick() {
		this.showInfo = false;
	}
}
