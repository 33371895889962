export enum BillingTabEnum {
	PaymentMethod,
	AddNewCard,
	ViewInvoices
}

export enum BillingLabelsEnum {
	PaymentMethod = 'Change payment method',
	AddNewCard = 'Add new card',
	ViewInvoices = 'View invoices',
	BillingNote = '* This is the billing Information connected to your primary payment method'
}

export enum BillingLinksEnum {
	PaymentMethod = '/user-management/billing-and-payment',
	AddNewCard = '/user-management/billing-add-card',
	ViewInvoices = '/user-management/billing-invoice'
}

export enum BillingImagesEnum {
	PaymentMethod = 'assets/icons/user-management/account/payment-icon.png',
	AddNewCard = 'assets/icons/user-management/account/add-icon.png',
	ViewInvoices = 'assets/icons/user-management/account/invoice-icon.png',
	Download = 'assets/icons/user-management/account/download.svg',
	Shared = 'assets/icons/user-management/account/share.svg',
	ActionDots = 'assets/icons/user-management/account/dots2.svg'
}
