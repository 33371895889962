import { Bits } from './bits';

export enum SettingsPermissions {
	SettingsBillingAndPaymentView = Bits.Bit1,
	SettingsChangePasswordView = Bits.Bit2,
	SettingsManagePermissionsView = Bits.Bit3,
	SettingsManagePermissionsEdit = Bits.Bit4,
	SettingsMyAccountView = Bits.Bit5,
	SettingsMyAccountEdit = Bits.Bit6,
	SettingsMySubscriptionView = Bits.Bit7,
	SettingsUserManagementFull = Bits.Bit8,
	SettingsUserManagementView = Bits.Bit9,
	SettingsUserManagementEdit = Bits.Bit10
}
