import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'contains'
})
export class ContainsPipe implements PipeTransform {
	transform(items: any[], filter: string): any {
		if (!items) {
			return [];
		}

		return items.find(t => t.name === filter) ? items.find(t => t.name === filter).show : [];
	}
}
