<input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event)" #fileUpload />

<div class="file-upload" [hidden]="!fileName || fileName == ''">
	<img [src]="base64FileName" class="thumbnail" />
</div>
<btn09 [icon]="'plus'" for="input-file-id" (onClick)="fileUpload.click()">Upload Image</btn09>

<div class="progress">
	<mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress"></mat-progress-bar>

	<mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
</div>
