import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { AppStoreTypeEnum } from '../../models/app-store-type.enum';
import { MatRadioChange } from '@angular/material/radio';
import { DropdownInterface } from '@filed-com/filed-lib';

@Component({
	selector: 'app-campaign-app-selector',
	templateUrl: './campaign-app-selector.component.html',
	styleUrls: ['./campaign-app-selector.component.scss']
})
export class CampaignAppSelectorComponent implements OnInit, OnDestroy {
	@Input() public accountId: string;
	@Input() public isPanelSelected: boolean;
	@Input() public control: FormControl | AbstractControl;
	@Input() public appControl: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();
	@Output() public onPanelClick = new EventEmitter();
	@Output() public selectedAppData = new EventEmitter();
	@Input() public advertisableApps: DropdownInterface[];
	public unsubscriber$: Subject<void> = new Subject<void>();
	public appStoreTypeEnum = AppStoreTypeEnum;
	constructor() {}
	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	public setControlValue($event: MatRadioChange): void {}

	public selectedApp(event: DropdownInterface): void {
		this.selectedAppData.emit(event.data);
	}
}
