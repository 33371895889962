import { AfterViewInit, Component, ElementRef, Injector, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AdSetSettingsComponent } from 'src/app/campaign-smart-create/components/ad-set-settings/ad-set-settings.component';
import { IQuickNavigation } from '../components/quick-navigation/quick-navigation.component';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdSetModel } from '../models/adset.model';
import moment from 'moment';
import * as adsetActions from '../state/adset-state/adset-state.action';
import * as adActions from '../state/ad-state/ad-state.actions';
import * as AAAState from '../state';
import { select, Store } from '@ngrx/store';
import { BudgetAllocatedEnum } from 'src/app/campaign-smart-create/models/budget-allocated.enum';
import { CampaignDateChoices } from 'src/app/shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { CampaignBudgetOptimizationInterface } from 'src/app/campaign-smart-create/models/campaign-budget-optimization.interface';
import { OptimizationAndDeliveryInterface } from 'src/app/campaign-smart-create/models/optimization-and-delivery.interface';
import * as AdsetState from '../state/adset-state';
import { SubObjectives } from 'src/app/campaign-smart-create/models/sub-objectives';
import { TargetingAudience } from 'src/app/campaign-smart-create/models/targeting-audience';
import { DateRangeX } from 'src/app/shared/calendar/calendar.models';
import { SavedAudienceInterface } from 'src/app/campaign-smart-create/models/saved-audience.interface';
import { AudienceTypeEnum } from 'src/app/campaign-smart-create/models/audience-type.enum';
import { AudienceSubtypeEnum } from 'src/app/campaign-smart-create/models/audience-subtype.enum';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { DateCardInterface } from 'src/app/shared/smart-create-edit-components/step-two-components/date-card/campaign-date-card.interface';
import { AaaHeaderFooterStateService } from '../services/aaa-header-footer-state.service';
import { setStepBasedOnFlow } from '../helpers/helper';
import { AAAFlow, stepName } from '../models/AAA-steps.enum';
import { TreeViewInterface } from '@filed-com/filed-lib/lib/tree-view/models/tw-interface';
import { DeviceEnum, DeviceOS, DeviceOSEnum, Devices } from '../components/devices/devices.component';
import { IProgressCards } from '../models/progress-cards.interface';
import { AudienceSizeBarInterface } from 'src/app/shared/models/audience-size-bar.interface';
import { AdSetSettingsPanels } from 'src/app/campaign-smart-create/components/ad-set-settings/ad-set-settings-panels';
import { ObjectiveTreeInterface } from 'src/app/campaign-smart-create/models/objective-tree.interface';
import { UpdateSelectedAAAAdIds } from '../state/aaa.actions';
import { AccountTypeService } from 'src/app/shared/account-type.service';
@Component({
	selector: 'app-add-adset',
	templateUrl: './add-adset.component.html',
	styleUrls: ['./add-adset.component.scss']
})
export class AddAdSetComponent extends AdSetSettingsComponent implements OnInit, AfterViewInit {
	public navigations: IQuickNavigation[] = [
		{
			name: 'Ad Set Name',
			targetId: 'adSetName'
		},
		{ name: 'Select Page', targetId: 'selectPage' },
		{
			name: 'Budget',
			targetId: 'bs'
		},
		{
			name: 'Schedule',
			targetId: 'date'
		},
		{
			name: 'Audience',
			targetId: 'audience'
		},
		{
			name: 'Devices',
			targetId: 'devices'
		},
		{
			name: 'Placements',
			targetId: 'placements'
		},
		{
			name: 'Optimisation & Delivery',
			targetId: 'od'
		}
	];
	public bars: AudienceSizeBarInterface[] = [
		{
			height: '1.4rem',
			isActive: false
		},
		{
			height: '1.69rem',
			isActive: false
		},
		{
			height: '2.188rem',
			isActive: false
		},
		{
			height: '3.375rem',
			isActive: false
		},
		{
			height: '4.375rem',
			isActive: false
		},
		{
			height: '5.375rem',
			isActive: false
		},
		{
			height: '6.688rem',
			isActive: false
		},
		{
			height: '8.313rem',
			isActive: false
		},
		{
			height: '9.75rem',
			isActive: false
		},
		{
			height: '10.938rem',
			isActive: false
		}
	];
	public showPageComponent = true;
	public device = Devices.all;
	public deviceos = DeviceOS.all;
	public adSetSettingsFormGroup: FormGroup;
	public aaaStateService: AaaHeaderFooterStateService;
	private AAAstore: Store<AAAState.AAAModuleState>;
	public flow: number;
	public audienceResult: string;
	public currentStep: AdSetModel;
	public opened: boolean;
	public createPopUpData: { title: string; subTitle: string; type: string };
	public progressCardObject: IProgressCards;
	public objectiveTree: ObjectiveTreeInterface;
	public isDexter = false;
	public accountTypeService: AccountTypeService;

	constructor(injector: Injector) {
		super(injector);
		this.AAAstore = this.injector.get<Store<AAAState.AAAModuleState>>(Store);
		this.aaaStateService = this.injector.get<AaaHeaderFooterStateService>(AaaHeaderFooterStateService);
		this.listenToRoute();
		this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
			this.isDexter = params['isDexter'];
		});
		this.accountTypeService = this.injector.get<AccountTypeService>(AccountTypeService);
		this.registeringActions();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.audienceService.reachEstimate$.pipe(takeUntil(this.unsubscriber$)).subscribe((cardData: IProgressCards) => {
			this.progressCardObject = cardData;
		});
		this.listenForDeviceChange();
		this.getActivatedRouteData();
	}

	public ngAfterViewInit(): void {}
	public getActivatedRouteData(): void {
		this.activatedRoute.data.pipe(take(1)).subscribe(data => {
			this.selectedAdAccount = data.store.adAccount;
			this.adAccountFacebookId = this.selectedAdAccount.adAccount.id;
			this.facebookPages = data.store.mappedPages;
			this.optimizationGoal = 'AWARENESS';
			this.businessId = this.selectedAdAccount.adAccount.businessId;
			this.currentStep = data.store.adset ?? new AdSetModel();
			this.initFormGroup();
			this.AAAstore.dispatch(new adsetActions.UpdateAdsetState(this.currentStep));
			this.initStoreListeners();
			this.dispatchActions();
			this.objectiveTree = data.store.objectiveTree;
			this.isStepValid = this.adSetSettingsFormGroup.valid;
			if (this.objectiveTree.subObjective !== 'REACH') {
				this.hidePageComponent();
			}
			if (this.objectiveTree.subObjective === SubObjectives.ProductCatalogSales) {
				this.isCatalogSales = true;
			}
			if (this.currentStep.targeting.locations) {
				this.adSetSettingsFormGroup.get('locations').patchValue({ ...this.currentStep.targeting.locations });
			}
			if (this.accountTypeService.dexterAction$.value) {
				if (this.accountTypeService.dexterAction$.value.adsetId !== this.currentStep.adSetId) {
					this.accountTypeService.dexterAction$.next(null);
				}
			}
		});
	}

	public handlePanelClick(panelType: AdSetSettingsPanels, index: number): void {
		this.expansionPanelHelper.onPanelClick(panelType);
		this.quickNavigationService.setSelectedNavigation(index);
	}

	public hidePageComponent(): void {
		this.showPageComponent = false;
		this.navigations = this.navigations.filter(nav => nav.targetId !== 'selectPage');
	}

	protected initFormGroup(): void {
		this.adSetSettingsFormGroup = this.formBuilder.group({
			adSetName: new FormControl(this.currentStep?.adSetName, Validators.required),
			pages: this.formBuilder.group({
				facebookId: new FormControl(this.currentStep?.facebookPageId, Validators.required),
				instagramId: new FormControl(this.currentStep?.instagramPageId)
			}),
			placements: new FormControl(this.currentStep?.placements, Validators.required),
			locations: new FormControl('', Validators.required),
			adSetBudget: this.formBuilder.group(
				{
					budgetType: new FormControl(this.currentStep?.budgetOptimization?.budgetAllocatedTypeId),
					amount: new FormControl(this.currentStep?.budgetOptimization?.amount)
				},
				Validators.required
			),
			optimizationDelivery: this.formBuilder.group({
				optimizationGoal: new FormControl({ value: '', disabled: true }),
				clicksAndViews: new FormControl({ value: '', disabled: true }),
				billingEvents: new FormControl({ value: '', disabled: true }),
				bidControl: new FormControl(this.currentStep?.optimizationAndDelivery?.bidControl)
			})
		});

		this.adSetSettingsFormGroup
			.get('adSetName')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(name => {
				if (name.length < 2) {
					this.label = '';
				} else {
					this.label = 'Filed Adset';
				}
				this.AAAstore.dispatch(new adsetActions.UpdateAdSetName(name ?? 'Filed Adset'));
			});

		this.adSetSettingsFormGroup
			.get('pages')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pages => {
				this.facebookPageId = pages.facebookId;
				this.AAAstore.dispatch(new adsetActions.UpdatePages({ facebookPageId: pages.facebookId, instagramPageId: pages.instagramId }));
			});

		this.adSetSettingsFormGroup
			.get('adSetBudget')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(budget => {
				if (
					(budget.budgetType === BudgetAllocatedEnum.Daily || budget.budgetType === BudgetAllocatedEnum.LifeTime) &&
					!this.campaignBudgetOptimization
				) {
					this.isAdSetLifeTimeSelected = budget.budgetType === CampaignDateChoices.Default && !this.campaignBudgetOptimization;
					if (budget.budgetType === CampaignDateChoices.Default && !this.campaignDate.endDate) {
						this.setDefaultDate();
					}

					if (budget.budgetType === BudgetAllocatedEnum.LifeTime) {
						this.validBudget = Math.round(this.dateLength() * this.minBudget);
					} else {
						this.validBudget = this.minBudget;
					}

					this.adSetSettingsFormGroup
						.get('adSetBudget')
						.get('amount')
						.setValidators([Validators.required, Validators.min(this.validBudget)]);
					this.adSetSettingsFormGroup.get('adSetBudget').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });

					const budgetOptimization: CampaignBudgetOptimizationInterface = {
						amount: budget.amount,
						budgetAllocatedTypeId: budget.budgetType,
						minimumBudget: this.validBudget,
						bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
						deliveryType: 'standard'
					};
					this.AAAstore.dispatch(new adsetActions.UpdateAdSetBudgetOptimization(budgetOptimization));
				} else {
					this.AAAstore.dispatch(new adsetActions.UpdateAdSetBudgetOptimization(null));
					this.adSetSettingsFormGroup.get('adSetBudget').get('amount').clearValidators();
					this.adSetSettingsFormGroup.get('adSetBudget').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });
				}
			});

		this.adSetSettingsFormGroup
			.get('optimizationDelivery')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.pipe(debounceTime(500))
			.subscribe(data => {
				const adsetFormRawData = this.adSetSettingsFormGroup.getRawValue()?.optimizationDelivery;
				const index = adsetFormRawData.clicksAndViews ? adsetFormRawData.clicksAndViews.indexOf('/') : null;
				const click = adsetFormRawData.clicksAndViews ? adsetFormRawData.clicksAndViews.slice(0, index) : null;
				const view = adsetFormRawData.clicksAndViews ? adsetFormRawData.clicksAndViews.slice(index + 1, adsetFormRawData.clicksAndViews.length) : null;
				const update: OptimizationAndDeliveryInterface = {
					optimizationGoal: adsetFormRawData.optimizationGoal,
					click: click,
					view: view,
					billingEvent: adsetFormRawData.billingEvents,
					bidControl: adsetFormRawData.bidControl
				};
				this.AAAstore.dispatch(new adsetActions.UpdateOptimizationAndDelivery(update));
			});

		// used for validity check
		this.adSetSettingsFormGroup.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			setTimeout(() => {
				this.isStepValid = this.adSetSettingsFormGroup.valid;
			});
		});
	}

	public getValidations(): void {
		this.AAAstore.pipe(select(AAAState.getValidations), take(1)).subscribe(validation => {
			this.validations = validation;
		});
	}

	public getFacebookId(): void {
		this.AAAstore.pipe(select(AdsetState.getFacebookPageId), takeUntil(this.unsubscriber$)).subscribe(response => {
			if (response) {
				this.facebookPageId = response;
			}
		});
	}

	public getObjectiveTree(): void {
		this.AAAstore.pipe(select(AAAState.getObjectiveTree), take(1)).subscribe(objectiveTree => {
			this.subObjective = objectiveTree.subObjective;
			if (objectiveTree.subObjective !== SubObjectives.LinkCLicks) {
				this.objectiveWithDestination = `${objectiveTree.subObjective + '_X_' + 'UNDEFINED'}`;
			} else {
				this.objectiveWithDestination = `${objectiveTree.subObjective + '_X_' + objectiveTree.destinationType}`;
			}
		});
	}

	public getOptimizationGoals(): void {
		this.AAAstore.pipe(select(AAAState.getOptimizationGoal), take(1)).subscribe(goals => {
			this.optimizationGoalList = this.mapperService.convertToDropdownData(goals, 'name', 'displayNameAutogen');
			this.optimizationGoalList.pop();
		});
	}

	public getClickAndViews(): void {
		this.AAAstore.pipe(select(AAAState.getClicks), take(1)).subscribe(clicksList => {
			this.clicks = this.mapperService.convertToDropdownData(clicksList, 'name', 'displayName');
			this.clicks.pop();
		});

		this.AAAstore.pipe(select(AAAState.getViews), take(1)).subscribe(viewsList => {
			this.views = this.mapperService.convertToDropdownData(viewsList, 'name', 'displayName');
			this.views.pop();
		});
	}

	public getBillingEvents(): void {
		this.AAAstore.pipe(select(AAAState.getBillingEvents), take(1)).subscribe(billingEvents => {
			this.billingEventsList = this.mapperService.convertToDropdownData(billingEvents, 'name', 'displayNameAutogen');
			this.billingEventsList.pop();
		});
	}

	public checkAdSetBudget(): void {
		this.AAAstore.pipe(select(AdsetState.getAdSetOptimization), takeUntil(this.unsubscriber$)).subscribe(isActive => {
			if (isActive && isActive.budgetAllocatedTypeId === 0) {
				this.isAdSetLifeTimeSelected = true;
			}
		});
	}

	public getPlacementsFromCatalog(): void {
		this.AAAstore.pipe(select(AAAState.getPlacements), take(1)).subscribe(placements => {
			this.platformsPerDevice = placements;
		});
	}

	public getPlatforms(): void {
		this.AAAstore.pipe(select(AAAState.getPlatforms), take(1)).subscribe(platforms => {
			this.platforms = platforms;
			this.platforms = this.mapperSmartService.sortContexts(this.platforms);
		});
	}

	public getPlacements(): void {
		this.AAAstore.pipe(select(AdsetState.getAdsetPlacements), take(1)).subscribe(placements => {
			this.placements = placements;
		});
	}

	public getCampaignDateInfo(): void {
		this.AAAstore.pipe(select(AdsetState.getCampaignActiveDate), takeUntil(this.unsubscriber$)).subscribe(date => {
			if ((date && date.dateChoiceType === CampaignDateChoices.Custom) || (date && (!this.isCampaignLifeTimeSelected || !this.isAdSetLifeTimeSelected))) {
				this.campaignDate = date.date;
				this.dateChoiceType = date.dateChoiceType;
				this.preselectedDate.date.startDate = moment(this.campaignDate?.startDate);
				this.preselectedDate.date.endDate = moment(this.campaignDate?.endDate);
			} else {
				this.setDefaultDate();
			}
		});
	}

	public setDefaultDate(): void {
		const campaignDate = this.defaultCampaignDate();
		this.dateChoiceType = CampaignDateChoices.Custom;
		this.campaignDate = campaignDate;
		if (this.campaignBudgetOptimization) {
			this.AAAstore.dispatch(new adsetActions.UpdateCampaignActiveDate({ date: null, dateChoiceType: null }));
		} else {
			this.AAAstore.dispatch(new adsetActions.UpdateCampaignActiveDate({ date: campaignDate, dateChoiceType: this.dateChoiceType }));
		}
	}

	public getTargeting(): void {
		this.AAAstore.pipe(select(AdsetState.getTargeting), takeUntil(this.unsubscriber$)).subscribe(details => {
			this.targeting = details ?? new TargetingAudience();
			this.adSetSettingsFormGroup.get('locations').patchValue({ ...details.locations });
		});
	}

	public defaultCampaignDate(): DateRangeX<string> {
		const result = new DateRangeX<string>();
		result.startDate = moment().toISOString(true);
		result.endDate = moment().add(1, 'month').toISOString(true);
		return result;
	}

	// public saveAudience(audience: TargetingAudience): void {
	// 	audience.businessId = this.businessId;
	// 	const savedAudience: SavedAudienceInterface = {
	// 		adAccountFacebookId: this.adAccountFacebookId,
	// 		details: audience,
	// 		audienceType: AudienceTypeEnum.Saved,
	// 		audienceSubtype: AudienceSubtypeEnum.LOOKALIKE
	// 	};
	// 	// this.audienceService
	// 	// 	.saveAudience(savedAudience)
	// 	// 	.pipe(take(1))
	// 	// 	.subscribe(
	// 	// 		response => {
	// 	// 			this.updateSavedAudiences();
	// 	// 		},
	// 	// 		() => {
	// 	// 			this.toastNotificationService.sendErrorToast('Audience could not be saved due to an internal error. Please try again');
	// 	// 		}
	// 	// 	);
	// }

	public dateLength(): number {
		let numberOfDays = 0;
		this.AAAstore.pipe(select(AdsetState.getCampaignActiveDate), take(1)).subscribe(response => {
			if (response?.date) {
				const startDate = moment(response.date.startDate);
				const endDate = moment(response.date.endDate);
				numberOfDays = Math.round(moment.duration(endDate.diff(startDate)).asDays());
			}
		});
		return numberOfDays;
	}

	public updateAudienceGraph(size: number): void {
		if (size < 100000) {
			this.audienceResult = 'Targeted';
			for (var i = 0; i < 9; i++) {
				if (i < 1) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 1000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 2) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 10000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 3) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 30000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 4) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 40000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 5) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 50000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 6) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 60000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 7) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 70000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 8) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 75000000) {
			this.audienceResult = 'Broad';
			for (var i = 0; i < 9; i++) {
				if (i < 9) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else {
			this.audienceResult = 'Broad';
			for (var i = 0; i < 9; i++) {
				this.bars[i].isActive = true;
			}
		}
	}

	public updateTargeting(target: TargetingAudience): void {
		if (!this.isDexter) {
			this.AAAstore.dispatch(new adsetActions.UpdateTargeting(target));
		}
		const locations = target.locations.length ? [...target.locations] : null;
		this.adSetSettingsFormGroup.get('locations').patchValue({ locations });
	}

	public updatePlacements(data: CatalogNodeInterface[]): void {
		this.AAAstore.dispatch(new adsetActions.UpdatePlacements(data));
	}

	public updateCampaignDateStatus(status: DateCardInterface): void {
		this.AAAstore.dispatch(new adsetActions.UpdateCampaignActiveDate({ date: status.campaignDate, dateChoiceType: status.dateChoiceType }));
		this.AAAstore.pipe(select(AdsetState.getAdSetOptimization), take(1)).subscribe(budget => {
			if (budget) {
				if (budget.budgetAllocatedTypeId === BudgetAllocatedEnum.LifeTime) {
					this.validBudget = Math.round(this.dateLength() * this.minBudget);
				} else {
					this.validBudget = this.minBudget;
				}
				this.adSetSettingsFormGroup
					.get('adSetBudget')
					.get('amount')
					.setValidators([Validators.min(this.validBudget)]);
				this.adSetSettingsFormGroup.get('adSetBudget').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });

				const budgetOptimization: CampaignBudgetOptimizationInterface = {
					amount: budget.amount,
					budgetAllocatedTypeId: budget.budgetAllocatedTypeId,
					minimumBudget: this.validBudget,
					bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
					deliveryType: 'standard'
				};
				this.AAAstore.dispatch(new adsetActions.UpdateAdSetBudgetOptimization(budgetOptimization));
			}
		});
	}

	public listenToRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.flow = +params['flow'];
			this.setCurrentStepBasedOnFlow();
		});
	}

	public setCurrentStepBasedOnFlow(): void {
		setStepBasedOnFlow(this, this.flow, this.setCurrentStep, stepName.CreateAdset);
	}

	public setCurrentStep(step: number): void {
		this.aaaStateService.sendActiveStep(step);
		this.aaaStateService.sendCampaignStep(step);
		this.aaaStateService.onPreviewAd(false);
	}

	public registeringActions(): void {
		this.aaaStateService
			.onNext()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				const isValid = this.runAdsetValidation();
				if (isValid) {
					if (this.flow === AAAFlow.createNewAdset) {
						this.openCreateNewPopUp('Create an Ad', 'How would you like to create your Ad(s) ?', 'ad');
					} else {
						this.aaaStateService.sendNavigateForward();
					}
				}
			});
	}

	public runAdsetValidation(): boolean {
		let isValid = true;
		if (this.adSetSettingsFormGroup.get('adSetName').invalid) {
			this.toastService.sendErrorToast('You must select a name for your ad.');
			this.adSetSettingsFormGroup.get('adSetName').markAllAsTouched();
			this.quickNavigationService.setSelectedNavigation(0);
			isValid = false;
		} else if (!this.facebookPageId && this.objectiveTree.subObjective === 'REACH') {
			this.toastService.sendErrorToast('Please select a Facebook Page');
			this.quickNavigationService.setSelectedNavigation(2);
			isValid = false;
		} else if (this.adSetSettingsFormGroup.get('adSetBudget').invalid) {
			this.adSetSettingsFormGroup.get('adSetBudget').markAllAsTouched();
			this.toastService.sendErrorToast('Please fill budget form');
			this.quickNavigationService.setSelectedNavigation(2);
			isValid = false;
		} else if (this.adSetSettingsFormGroup.get('placements').invalid) {
			this.toastService.sendErrorToast('Please select Placement');
			this.adSetSettingsFormGroup.get('placements').markAllAsTouched();
			this.quickNavigationService.setSelectedNavigation(5);
			isValid = false;
		} else if (this.adSetSettingsFormGroup.get('locations').invalid) {
			this.toastService.sendErrorToast('Please select at least one Location for audience');
			this.adSetSettingsFormGroup.get('locations').markAllAsTouched();
			this.quickNavigationService.setSelectedNavigation(3);
			isValid = false;
		}
		return isValid;
	}

	public openCreateNewPopUp(title: string, subTitle: string, type: string): void {
		this.createPopUpData = {
			title: title,
			subTitle: subTitle,
			type: type
		};
		this.opened = true;
	}

	public closeCreateNewPopUp(): void {
		this.opened = false;
	}

	public createNew(type: string): void {
		this.aaaStateService.editSelectedAds.next(false);
		const skips = this.isDexter ? [0, this.aaaStateService.activeStep.value + 1] : [this.aaaStateService.activeStep.value + 1];
		this.aaaStateService.setSkips(skips);
		this.aaaStateService.sendNavigateForward(this.aaaStateService.activeStep.value + 2);
		this.store.dispatch(new UpdateSelectedAAAAdIds(null));
		this.store.dispatch(new adActions.ClearAds());
		this.closeCreateNewPopUp();
	}

	public createWithBestPerforming(type: string): void {
		const skips = this.isDexter ? [0] : [];
		this.aaaStateService.setSkips(skips);
		this.aaaStateService.sendNavigateForward();
		this.closeCreateNewPopUp();
	}

	public updatePlacementPerPlatform(placementPerPlatform: TreeViewInterface[]) {
		this.AAAstore.dispatch(new adsetActions.UpdatePlacementsPerPlatform(placementPerPlatform));
	}

	public listenForDeviceChange(): void {
		this.AAAstore.pipe(select(AdsetState.getAdSetDevices), take(1)).subscribe(devices => (this.device = devices));
		this.AAAstore.pipe(select(AdsetState.getAdSetDeviceOS), take(1)).subscribe(deviceos => (this.deviceos = deviceos));
	}

	public onDeviceChange(value: DeviceEnum[]): void {
		this.device = value;
		this.AAAstore.dispatch(new adsetActions.UpdateDevice(value));
	}
	public onDeviceOsChange(value: DeviceOSEnum[]): void {
		this.AAAstore.dispatch(new adsetActions.UpdateDeviceOs(value));
	}

	public ngOnDestroy(): void {}
}
