export enum CatalogPlatform {
	Shopify = 'Shopify',
	Magento = 'Magento',
	WooCommerce = 'WooCommerce',
	BigCommerce = 'BigCommerce',
	Csv = 'Csv',
	Xml = 'Xml'
}
export enum CatalogPlatformID {
	Shopify = 2,
	Magento = 5,
	WooCommerce = 6,
	BigCommerce = 4,
	Csv = 7,
	Xml = 20
}
