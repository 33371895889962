import { ChartService } from './chart.service';
import { Injectable } from '@angular/core';
import { ReportModel } from '../../models/report.model';
import { GoalService } from './goal.service';
import { ExcelService } from '../../../_services/excel.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';

@Injectable({
	providedIn: 'root'
})
export class SingleNumberChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected store: Store<ReportingState>,
		protected toastNotificationService: ToastNotificationService
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel): void {
		return this.initChartOptions(widget);
	}
}
