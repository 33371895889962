import { Injectable } from '@angular/core';
import { getSelectedAdAccount, SharedState } from '../state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { FacebookMetadataService } from './facebook-metadata.service';
import { GoogleMetadataService } from './google-metadata.service';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';

@Injectable({
	providedIn: 'root'
})
export class ReportMetadataService {
	public activeService: FacebookMetadataService | GoogleMetadataService;

	constructor(private state: Store<SharedState>, private facebookService: FacebookMetadataService, private googleService: GoogleMetadataService) {
		this.state.pipe(select(getSelectedAdAccount)).subscribe(resp => {
			this.activeService = this.getSelectedMetadataService(resp.sourceChannel);
		});
	}

	public getSelectedMetadataService(sourceChannel: SourceChannel): FacebookMetadataService | GoogleMetadataService {
		let activeService;

		switch (sourceChannel) {
			case SourceChannel.Facebook: {
				activeService = this.facebookService;
				break;
			}
			case SourceChannel.Google: {
				activeService = this.googleService;
				break;
			}
			default: {
				activeService = null;
			}
		}

		return activeService;
	}
}
