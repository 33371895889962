import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdAccount } from '../../accounts/models/ad-account.model';
import { BaseApiUrl } from '../base-api-urls';
import { Page } from '../../campaign-builder/models/page.model';

@Injectable()
export class PageService {
	constructor(protected http: HttpClient) {}

	// public getPages(facebook_user_id: string): Observable<Page[]> {
	// 	return this.http.get<Page[]>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/pages?facebook_user_id=${facebook_user_id}&cached=False`);
	// }

	public getPages(facebook_user_id: string): Observable<Page[]> {
		return this.http.get<Page[]>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}pages/?facebook_user_id=${facebook_user_id}`);
	}
}
