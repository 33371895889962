<div class="container">
	<div class="wrapper">
		<div class="vertical-icon" [style.margin-bottom]="expanded ? 'blue' : '0px'">
			<span class="k-icon k-i-select-all"></span>
		</div>
		<div class="panel">
			<div class="panel" *ngIf="!expanded">
				<div class="sub-sub-panel first" (click)="toggleExpansion()">
					<div>&nbsp;</div>
					<div>
						<button class="give-border">
							<i class="k-icon k-i-radiobutton-checked"></i>
							{{ placeholderText }}
						</button>
					</div>
				</div>
				<div *ngIf="expanded">
					<hr />
				</div>
			</div>

			<div class="panel" [formGroup]="formControl" *ngIf="expanded">
				<div class="form-container">
					<label class="form-label">Question</label>
					<tb07 [control]="formControl.get('question')" [placeHolderTextBox]="'Enter multiple choice question'" [maxLength]="80"></tb07>
				</div>
				<div class="form-container">
					<label class="form-label">
						Confirmation Message
						<mat-slide-toggle [checked]="checked"></mat-slide-toggle>
					</label>
					<tb07
						[control]="formControl.get('confirmationMessage')"
						[placeHolderTextBox]="'Someone will reachout to you to confirm your appointment request'"
						[maxLength]="100"
						[disabled]="!checked"
					></tb07>
				</div>
				<div class="form-container">
					<label class="form-label">Date and Time availability</label>
					<font10>Monday to Sunday - 7am to 10pm</font10>
				</div>
			</div>
			<div class="sub-sub-panel first" *ngIf="expanded">
				<div>&nbsp;</div>
				<btn03 [icon]="'trash'" (onClick)="removeItem()">Remove</btn03>
			</div>
		</div>
	</div>
	<div class="vertical-icon" *ngIf="!expanded">
		<btn08 [icon]="'trash'" (onClick)="removeItem()"></btn08>
	</div>
</div>
