export enum AAACampaignViewsEnum {
	campign_id = 'campaign_id',
	selected = 'selected',
	campaign_name = 'campaign_name',
	impressions = 'impressions',
	results = 'results',
	cost_per_result = 'cost_per_result',
	cost_per_unique_click_all = 'cost_per_unique_click_all',
	unique_ctr_all = 'unique_ctr_all'
}
