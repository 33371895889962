import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
	selector: 'app-ads-manager-link-cell',
	templateUrl: './ads-manager-link-cell.component.html',
	styleUrls: ['./ads-manager-link-cell.component.scss']
})
export class AdsManagerLinkCellComponent implements ICellRendererAngularComp {
	public params: any;

	constructor(private router: Router) {}

	public agInit(params: any): void {
		this.params = params;
	}

	public refresh(): boolean {
		return false;
	}

	public goToLink(): void {}
}
