import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
	transform<T>(items: T[], callback: (item: T) => boolean): T[] {
		if (!items || !callback) {
			return [];
		}
		return items.filter(item => callback(item));
	}
}
