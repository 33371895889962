import { UserDetails } from '../../_models/identity-models/identity-detail';
import { HttpErrorResponse } from '@angular/common/http';

export interface EffectErrorObject {
	userId: number;
	date: Date;
	endpoint: string;
	currentUrl: string;
	statusCode: number;
	details: {
		state: {
			storeSliceName: string;
			userDetails: UserDetails;
		};
		response: {
			headers: string;
			error: HttpErrorResponse;
		};
		effect: {
			name: string;
			parameters: string;
		};
	};
}

export enum ErrorsEnums {
	Effect = 'Effect',
	ServerDown = 'Server down'
}

export interface GenericError<T> {
	type: string;
	error: T;
}
