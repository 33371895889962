import { Action } from '@ngrx/store';
import { AdAccount } from 'src/app/accounts/models/ad-account.model';
import { IMainImage, ISingleAd, ISingleAdData, ISingleAdLineElements } from '../cb-m-single-ad/model/single-ad-model';
import { IFontAsset } from '../cb-m-template/model/font-asset-model';
import { IimageAsset } from '../cb-m-template/model/image-asset-model';
import { IProductCatalog } from '../cb-m-template/model/product-catalog';
import { IProductSet } from '../cb-m-template/model/product-set';
import { ISelectAsset, ITemplate, ITemplateDataAsJson, ITemplateMainImage } from '../cb-m-template/model/template-model';
import { ITextElements, ILineElements, IShapeElements, ISvgElements, IImageElements } from '../model/creative-builder-elements-model';

export enum CreativeBuilderActionType {
	toggleEditorSidebar = '[Creative Builder] Toggle Editor Sidebar',
	deselectAssest = '[Creative Builder] Deselect Assest',
	setActiveTemplateSize = '[Creative Builder] Set Active Template Size',
	setActiveSingleAdPage = '[Creative Builder] Set Active SingleAd Page',
	setLoadImageSpiner = '[Creative Builder] Set Load Image Spiner',
	setIsPredefinedTemplate = '[Creative Builder] Set Is Predefined Template',
	setIsPredefinedSingleAd = '[Creative Builder] Set Is Predefined Single Ad Design',
	setSelectedTextElement = '[Creative Builder] Set Selected Text Element',
	setSelectedAsset = '[Creative Builder] Set Active Asset',
	setActiveToolBar = '[Creative Builder] Set Active ToolBar',
	setActiveSideBar = '[Creative Builder] Set Active SideBar',
	setActiveTextSidebar = '[Creative Builder] Set Active Text SideBar',
	setSelectedShapeColor = '[Creative Builder] Set Selected Shape Color',
	setActiveShapeSidebar = '[Creative Builder] Set Active Shape SideBar',
	setSelectedSet = '[Creative Builder] Set Selected Set',
	setSuggestedText = '[Creative Builder] Set Suggested Text',
	initialiseTemplate = '[Creative Builder] Initialise Template',
	storeDefaultDynamicText = '[Creative Builder] Store Default Dynamic Text',
	storeCustomFont = '[Creative Builder] Store Custom Font',
	storeDefaultFont = '[Creative Builder] Store Default Font',
	storeTemplate = '[Creative Builder] Store Template',
	storeSingleAdDesign = '[Creative Builder] Store Single Ad Design Design',
	storeTemplateData = '[Creative Builder]  Store Template Data',
	storeTextElement = '[Creative Builder] Store Text Element ',
	storeLineElement = '[Creative Builder] Store Line Element ',
	storeSingleAdBackgroundColour = '[Creative Builder] Store Single Ad Design Background Colour ',
	storeTemplateBackgroundColour = '[Creative Builder] Store Template Background Colour ',
	storeSingleAdLineElement = '[Creative Builder] Store Single Ad Design Line Element ',
	storeShapeElement = '[Creative Builder] Store Shape Element ',
	storeTemplateSvgElement = '[Creative Builder] Store Template Svg Element ',
	storeSingleAdSvgElement = '[Creative Builder] Store Single Ad Design Shape Element ',
	storeImageElement = '[Creative Builder] Store Image Element ',
	storeUsedFontElement = '[Creative Builder] Store Used Font Element ',
	storeSingleAdUsedFontElement = '[Creative Builder] Store Single Ad Design Used Font Element ',
	storeUsedImageElement = '[Creative Builder] Store Used Image Element ',
	storeSingleAdUsedImageElement = '[Creative Builder] Store Single Ad Design Used Image Element ',
	storeTemplateDataAsJson = '[Creative Builder] Store Template Data As Json ',
	storeSingleAdData = '[Creative Builder] Store SingleAd Data As Json ',
	storeSingleAdMainImage = '[Creative Builder] Store Single Ad Design Main Image ',
	storeTemplateAdMainImage = '[Creative Builder] Store Template Main Image ',
	storeTemplateName = '[Creative Builder] Store Template Name',
	storeSingleAdName = '[Creative Builder] Store Single Ad Design Name',
	resetTemplateAndSetAndSingleAd = '[Creative Builder] Reset Template And Set And Single Ad Design',
	updateRedoTray = '[Creative Builder] Update Redo Tray',
	updateUndoTray = '[Creative Builder] Update Undo Tray',
	redoAction = '[Creative Builder] Redo Action Template',
	undoAction = '[Creative Builder] Undo Action Template',
	resetRedoTray = '[Creative Builder] Reset Redo Tray',
	resetUndoTray = '[Creative Builder] Reset Undo Tray',
	updateRedoSingleAdTray = '[Creative Builder] Update Redo Single Ad Design Tray',
	updateUndoSingleAdTray = '[Creative Builder] Update Undo Single Ad Design Tray',
	redoSingleAdAction = '[Creative Builder] Redo Action Single Ad Design',
	undoSingleAdAction = '[Creative Builder] Undo Action Single Ad Design',
	resetRedoSingleAdTray = '[Creative Builder] Reset Redo Single Ad Design Tray',
	resetUndoSingleAdTray = '[Creative Builder] Reset Undo Single Ad Design Tray',
	previewTemplateCropImage = '[Creative Builder] Preview Template Crop Image',
	previewSingleAdCropImage = '[Creative Builder] Preview Single Ad Design Crop Image',
	previewBGAdDesignCropImage = '[Creative Builder] Preview Backgorund Single Ad Design Crop Image',
	storeTemplateImageAssets = '[Creative Builder] Store Template Image Assets',
	storeSingleAdImageAssets = '[Creative Builder] Store Single Ad Design Image Assets',
	setTemplateImageAssetSelected = '[Creative Builder] Set Selected Template Image Asset',
	setSingleAdImageAssetSelected = '[Creative Builder] Set Selected Single Ad Design Image Asset',
	setAdDesignBackgroundImageSelected = '[Creative Builder] Set Selected Single Ad Design Background Image',
	setTemplateBackgroundImageSelected = '[Creative Builder] Set Selected Template Background Image',
	setTemplateCroppingInProgress = '[Creative Builder] Set Template Cropping in Progress',
	setSingleAdCroppingInProgress = '[Creative Builder] Set Single Ad Design Cropping in Progress',
	setBGAdDesignCroppingInProgress = '[Creative Builder] Set Background Single Ad Design Cropping in Progress',
	setDefaultTemplateId = '[Creative Builder] Set Default Template ID',
	setDefaultSingleAdId = '[Creative Builder] Set Default Single Ad Design ID',
	setEditorSelectedTemplate = '[Creative Builder] Set Editor Selected Template',
	setEditorSelectedSingleAd = '[Creative Builder] Set Editor Selected Single Ad Design',
	setProductCatalogues = '[Creative Builder] Set ProductCatalogues With Sets',
	setSetList = '[Creative Builder] Set Set List With Unique IDs',
	setIsSingleAd = '[Creative Builder] Set Single Ad Design',
	setIsFreshAccount = '[Creative Builder] Set Fresh Account',
	setAlertAlreadyCalled = '[Creative Builder] Set Alert Already Called',
	storeSingleAd = '[Creative Builder] Store Single Ad Design Design',
	storeSingleAdImageElement = '[Creative Builder] Store Single Ad Design Image Element',
	storeSingleAdTextElement = '[Creative Builder] Store Single Ad Design Text Element',
	setFacebookPageId = '[Creative Builder] Store Facebook Page ID',
	storeAdAccount = '[Creative Builder] Store Ad Account'
}

export class ToggleEditorSidebar implements Action {
	readonly type = CreativeBuilderActionType.toggleEditorSidebar;
	constructor(public payload: boolean) {}
}

export class SetIsSingleAd implements Action {
	readonly type = CreativeBuilderActionType.setIsSingleAd;
	constructor(public payload: boolean) {}
}

export class SetIsFreshAccount implements Action {
	readonly type = CreativeBuilderActionType.setIsFreshAccount;
	constructor(public payload: boolean) {}
}

export class SetFacebookPageId implements Action {
	readonly type = CreativeBuilderActionType.setFacebookPageId;
	constructor(public payload: string) {}
}

export class StoreAdAccount implements Action {
	readonly type = CreativeBuilderActionType.storeAdAccount;
	constructor(public payload: AdAccount) {}
}

export class SetAlertCalled implements Action {
	readonly type = CreativeBuilderActionType.setAlertAlreadyCalled;
	constructor(public payload: boolean) {}
}

export class SetDefaultTemplateId implements Action {
	readonly type = CreativeBuilderActionType.setDefaultTemplateId;
	constructor(public payload: number) {}
}

export class SetDefaultSingleAdId implements Action {
	readonly type = CreativeBuilderActionType.setDefaultSingleAdId;
	constructor(public payload: number) {}
}

export class SetProductCatalogues implements Action {
	readonly type = CreativeBuilderActionType.setProductCatalogues;
	constructor(public payload: IProductCatalog[]) {}
}

export class SetSetList implements Action {
	readonly type = CreativeBuilderActionType.setSetList;
	constructor(public payload: IProductSet[]) {}
}

export class SetTemplateCroppingInProgress implements Action {
	readonly type = CreativeBuilderActionType.setTemplateCroppingInProgress;
	constructor(public payload: boolean) {}
}

export class SetSingleAdCroppingInProgress implements Action {
	readonly type = CreativeBuilderActionType.setSingleAdCroppingInProgress;
	constructor(public payload: boolean) {}
}

export class SetBGAdDesignCroppingInProgress implements Action {
	readonly type = CreativeBuilderActionType.setBGAdDesignCroppingInProgress;
	constructor(public payload: boolean) {}
}

export class SetEditorSelectedDefaultTemplate implements Action {
	readonly type = CreativeBuilderActionType.setEditorSelectedTemplate;
	constructor(public payload: boolean) {}
}

export class SetEditorSelectedDefaultSingleAd implements Action {
	readonly type = CreativeBuilderActionType.setEditorSelectedSingleAd;
	constructor(public payload: boolean) {}
}

export class SetLoadImageSpiner implements Action {
	readonly type = CreativeBuilderActionType.setLoadImageSpiner;
	constructor(public payload: boolean) {}
}
export class SetIsPredefinedTemplate implements Action {
	readonly type = CreativeBuilderActionType.setIsPredefinedTemplate;
	constructor(public payload: boolean) {}
}
export class SetIsPredefinedSingleAd implements Action {
	readonly type = CreativeBuilderActionType.setIsPredefinedSingleAd;
	constructor(public payload: boolean) {}
}
export class SetActiveToolBar implements Action {
	readonly type = CreativeBuilderActionType.setActiveToolBar;
	constructor(public payload: string) {}
}
export class SetActiveSideBar implements Action {
	readonly type = CreativeBuilderActionType.setActiveSideBar;
	constructor(public payload: string) {}
}
export class SetActiveTextSidebar implements Action {
	readonly type = CreativeBuilderActionType.setActiveTextSidebar;
	constructor(public payload: string) {}
}
export class SetActiveShapeSidebar implements Action {
	readonly type = CreativeBuilderActionType.setActiveShapeSidebar;
	constructor(public payload: string) {}
}

export class SetSelectedAsset implements Action {
	readonly type = CreativeBuilderActionType.setSelectedAsset;
	constructor(public payload: ISelectAsset) {}
}
export class SetSelectedTextElement implements Action {
	readonly type = CreativeBuilderActionType.setSelectedTextElement;
	constructor(public payload: ITextElements) {}
}

export class SetSelectedSet implements Action {
	readonly type = CreativeBuilderActionType.setSelectedSet;
	constructor(public payload: IProductSet) {}
}

export class SetSuggestedText implements Action {
	readonly type = CreativeBuilderActionType.setSuggestedText;
	constructor(public payload: ITextElements[]) {}
}

export class InitialiseTemplate implements Action {
	readonly type = CreativeBuilderActionType.initialiseTemplate;
}
export class SetActiveTemplateSize implements Action {
	readonly type = CreativeBuilderActionType.setActiveTemplateSize;
	constructor(public payload: number) {}
}
export class SetActiveSingleAdPage implements Action {
	readonly type = CreativeBuilderActionType.setActiveSingleAdPage;
	constructor(public payload: number) {}
}
export class StoreTemplate implements Action {
	readonly type = CreativeBuilderActionType.storeTemplate;
	constructor(public payload: ITemplate) {}
}
export class StoreTemplateMainImage implements Action {
	readonly type = CreativeBuilderActionType.storeTemplateAdMainImage;
	constructor(public payload: ITemplateMainImage) {}
}
export class StoreSingleAdMainImage implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdMainImage;
	constructor(public payload: IMainImage) {}
}
export class StoreSingleAdDesign implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdDesign;
	constructor(public payload: ISingleAd) {}
}
export class StoreDefaultDynamicText implements Action {
	readonly type = CreativeBuilderActionType.storeDefaultDynamicText;
	constructor(public payload: string[]) {}
}
export class StoreCustomFont implements Action {
	readonly type = CreativeBuilderActionType.storeCustomFont;
	constructor(public payload: IFontAsset[]) {}
}
export class StoreDefaultFont implements Action {
	readonly type = CreativeBuilderActionType.storeDefaultFont;
	constructor(public payload: IFontAsset[]) {}
}

export class StoreSingleAdBackgroundColour implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdBackgroundColour;
	constructor(public payload: number[]) {}
}

export class StoreTemplateBackgroundColour implements Action {
	readonly type = CreativeBuilderActionType.storeTemplateBackgroundColour;
	constructor(public payload: number[]) {}
}

export class StoreSingleAdName implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdName;
	constructor(public payload: string) {}
}
export class StoreTemplateName implements Action {
	readonly type = CreativeBuilderActionType.storeTemplateName;
	constructor(public payload: string) {}
}
export class StoreTemplateData implements Action {
	readonly type = CreativeBuilderActionType.storeTemplateData;
	constructor(public payload: string) {}
}
export class StoreTextElement implements Action {
	readonly type = CreativeBuilderActionType.storeTextElement;
	constructor(public payload: ITextElements[]) {}
}
export class StoreLineElement implements Action {
	readonly type = CreativeBuilderActionType.storeLineElement;
	constructor(public payload: ILineElements[]) {}
}
export class StoreSingleAdLineElement implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdLineElement;
	constructor(public payload: ISingleAdLineElements[]) {}
}
export class SetSelectedShapeColor implements Action {
	readonly type = CreativeBuilderActionType.setSelectedShapeColor;
	constructor(public payload: { name: string; color: string }) {}
}
export class StoreShapeElement implements Action {
	readonly type = CreativeBuilderActionType.storeShapeElement;
	constructor(public payload: IShapeElements[]) {}
}
export class StoreTemplateSvgElement implements Action {
	readonly type = CreativeBuilderActionType.storeTemplateSvgElement;
	constructor(public payload: ISvgElements[]) {}
}

export class StoreSingleAdSvgElement implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdSvgElement;
	constructor(public payload: ISvgElements[]) {}
}
export class StoreSingleAdImageElement implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdImageElement;
	constructor(public payload: IImageElements[]) {}
}
export class StoreSingleAdTextElement implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdTextElement;
	constructor(public payload: ITextElements[]) {}
}
export class StoreImageElement implements Action {
	readonly type = CreativeBuilderActionType.storeImageElement;
	constructor(public payload: IImageElements[]) {}
}
export class StoreUsedFontElement implements Action {
	readonly type = CreativeBuilderActionType.storeUsedFontElement;
	constructor(public payload: number[]) {}
}
export class StoreSingleAdUsedFontElement implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdUsedFontElement;
	constructor(public payload: number[]) {}
}
export class StoreUsedImageElement implements Action {
	readonly type = CreativeBuilderActionType.storeUsedImageElement;
	constructor(public payload: number[]) {}
}
export class StoreSingleUsedImageElement implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdUsedImageElement;
	constructor(public payload: number[]) {}
}
export class StoreTemplateDataAsJson implements Action {
	readonly type = CreativeBuilderActionType.storeTemplateDataAsJson;
	constructor(public payload: ITemplateDataAsJson) {}
}
export class StoreSingleAdData implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdData;
	constructor(public payload: ISingleAdData[]) {}
}

export class ResetTemplateAndSetAndSingleAd implements Action {
	readonly type = CreativeBuilderActionType.resetTemplateAndSetAndSingleAd;
}
export class DeselectAssest implements Action {
	readonly type = CreativeBuilderActionType.deselectAssest;
}

export class UpdateRedoTray implements Action {
	readonly type = CreativeBuilderActionType.updateRedoTray;
	constructor(public payload: ITemplate) {}
}

export class UpdateUndoTray implements Action {
	readonly type = CreativeBuilderActionType.updateUndoTray;
	constructor(public payload: ITemplate) {}
}
export class RedoAction implements Action {
	readonly type = CreativeBuilderActionType.redoAction;
	constructor() {}
}
export class UndoAction implements Action {
	readonly type = CreativeBuilderActionType.undoAction;
	constructor() {}
}
export class ResetRedoTray implements Action {
	readonly type = CreativeBuilderActionType.resetRedoTray;
	constructor() {}
}
export class ResetUndoTray implements Action {
	readonly type = CreativeBuilderActionType.resetUndoTray;
	constructor() {}
}

export class UpdateRedoSingleAdTray implements Action {
	readonly type = CreativeBuilderActionType.updateRedoSingleAdTray;
	constructor(public payload: ISingleAd) {}
}

export class UpdateUndoSingleAdTray implements Action {
	readonly type = CreativeBuilderActionType.updateUndoSingleAdTray;
	constructor(public payload: ISingleAd) {}
}
export class RedoSingleAdAction implements Action {
	readonly type = CreativeBuilderActionType.redoSingleAdAction;
	constructor() {}
}
export class UndoSingleAdAction implements Action {
	readonly type = CreativeBuilderActionType.undoSingleAdAction;
	constructor() {}
}
export class ResetRedoSingleAdTray implements Action {
	readonly type = CreativeBuilderActionType.resetRedoSingleAdTray;
	constructor() {}
}
export class ResetUndoSingleAdTray implements Action {
	readonly type = CreativeBuilderActionType.resetUndoSingleAdTray;
	constructor() {}
}

export class PreviewTemplateCropImage implements Action {
	readonly type = CreativeBuilderActionType.previewTemplateCropImage;
	constructor(public payload: boolean) {}
}

export class PreviewSingleAdCropImage implements Action {
	readonly type = CreativeBuilderActionType.previewSingleAdCropImage;
	constructor(public payload: boolean) {}
}

export class PreviewBGAdDesignCropImage implements Action {
	readonly type = CreativeBuilderActionType.previewBGAdDesignCropImage;
	constructor(public payload: boolean) {}
}

export class StoreTemplateImageAssets implements Action {
	readonly type = CreativeBuilderActionType.storeTemplateImageAssets;
	constructor(public payload: IimageAsset[]) {}
}

export class StoreSingleAdImageAssets implements Action {
	readonly type = CreativeBuilderActionType.storeSingleAdImageAssets;
	constructor(public payload: IimageAsset[]) {}
}

export class SetIsTemplateImageAssetSelected implements Action {
	readonly type = CreativeBuilderActionType.setTemplateImageAssetSelected;
	constructor(public payload: boolean) {}
}

export class SetIsSingleAdImageAssetSelected implements Action {
	readonly type = CreativeBuilderActionType.setSingleAdImageAssetSelected;
	constructor(public payload: boolean) {}
}

export class SetIsAdDesignBackgroundImageSelected implements Action {
	readonly type = CreativeBuilderActionType.setAdDesignBackgroundImageSelected;
	constructor(public payload: boolean) {}
}

export class SetIsTemplateBackgroundImageSelected implements Action {
	readonly type = CreativeBuilderActionType.setTemplateBackgroundImageSelected;
	constructor(public payload: boolean) {}
}

export type creativeBuilderAction =
	| ToggleEditorSidebar
	| SetSelectedAsset
	| SetSelectedTextElement
	| SetSuggestedText
	| SetLoadImageSpiner
	| SetSelectedSet
	| StoreTemplate
	| StoreTemplateName
	| StoreTemplateData
	| SetActiveSideBar
	| ResetTemplateAndSetAndSingleAd
	| StoreTemplateDataAsJson
	| StoreSingleAdData
	| SetActiveTextSidebar
	| InitialiseTemplate
	| StoreTextElement
	| StoreLineElement
	| StoreShapeElement
	| StoreImageElement
	| StoreUsedFontElement
	| StoreSingleAdUsedFontElement
	| StoreUsedImageElement
	| StoreSingleUsedImageElement
	| SetIsPredefinedTemplate
	| SetIsPredefinedSingleAd
	| StoreDefaultDynamicText
	| StoreCustomFont
	| UpdateRedoTray
	| UpdateUndoTray
	| UpdateRedoSingleAdTray
	| UpdateUndoSingleAdTray
	| PreviewTemplateCropImage
	| PreviewSingleAdCropImage
	| PreviewBGAdDesignCropImage
	| StoreTemplateImageAssets
	| StoreSingleAdImageAssets
	| SetIsTemplateImageAssetSelected
	| SetIsSingleAdImageAssetSelected
	| SetIsAdDesignBackgroundImageSelected
	| SetIsTemplateBackgroundImageSelected
	| SetTemplateCroppingInProgress
	| SetSingleAdCroppingInProgress
	| SetBGAdDesignCroppingInProgress
	| SetActiveToolBar
	| SetActiveTemplateSize
	| SetActiveSingleAdPage
	| SetDefaultTemplateId
	| SetDefaultSingleAdId
	| SetEditorSelectedDefaultTemplate
	| SetEditorSelectedDefaultSingleAd
	| RedoAction
	| UndoAction
	| ResetRedoTray
	| ResetUndoTray
	| RedoSingleAdAction
	| UndoSingleAdAction
	| ResetRedoSingleAdTray
	| ResetUndoSingleAdTray
	| SetActiveShapeSidebar
	| SetProductCatalogues
	| SetSetList
	| SetIsSingleAd
	| SetIsFreshAccount
	| SetAlertCalled
	| StoreSingleAdDesign
	| StoreDefaultFont
	| StoreSingleAdImageElement
	| StoreSingleAdTextElement
	| StoreSingleAdLineElement
	| StoreSingleAdSvgElement
	| StoreSingleAdMainImage
	| StoreTemplateMainImage
	| StoreTemplateSvgElement
	| StoreSingleAdName
	| SetSelectedShapeColor
	| DeselectAssest
	| StoreSingleAdBackgroundColour
	| StoreTemplateBackgroundColour
	| SetFacebookPageId
	| StoreAdAccount;
