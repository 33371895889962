<header class="popup-header">
	<font07-6>{{ adData.name }}</font07-6>
	<img (click)="closeForm()" src="assets/icons/campaign-smart-create/welcome/cross-dismiss-icon.svg" alt="" />
</header>

<main class="popup-body">
	<article (click)="toggleAgeRange($event)" class="popup-left">
		<header (click)="toggleAgeRange($event)" class="left-side-header"><font05>Budget</font05></header>
		<section (click)="toggleAgeRange($event)" class="side-body">
			<div class="dropdown">
				<font07>Budget</font07>
				<tb04 [control]="budgetControl" [currency]="currency" [placeHolderTextBox]="'Budget'" [contentEditable]="true"></tb04>
			</div>

			<div class="dropdown">
				<drp02
					[allowClear]="false"
					(valueChanges)="budgetTypeValueChanges($event)"
					[label]="'Frequency'"
					[data]="budgetTypeListItems"
					[control]="budgetTypeControl"
				></drp02>
			</div>
		</section>
	</article>
	<article class="popup-right">
		<header (click)="toggleAgeRange($event)" class="side-header"><font05>Targeting</font05></header>
		<section (click)="toggleAgeRange($event)" class="side-body">
			<div class="dropdown">
				<font07>Age Range</font07>
				<div class="age-range-dropdown" (click)="openSlider()" [style]="!this.ageRange ? 'opacity: 0.5' : ''">
					<div>{{ ageRange?.minAge || 13 }} - {{ ageRange?.maxAge || 65 }}</div>
					<img class="{{ ageRangeEditing ? 'icon-rotate' : '' }}" src="assets/icons/arrow-dropdown.svg" alt="" />
				</div>
				<div [style]="ageRangeEditing ? 'transform: translateY(60px)' : 'transform: translateY(-250px)'" class="slider-wrapper">
					<rs01
						[disabled]="!this.ageRange"
						(valueChanges)="ageRangeSliderEvent($event)"
						[control]="ageRangeControl"
						[largeStep]="ageRange?.maxAge || 15"
						[max]="65"
						[min]="13"
						[smallStep]="ageRange?.minAge || 25"
					></rs01>
				</div>
			</div>
			<div class="dropdown">
				<drp02
					[allowClear]="false"
					[disabled]="!adData.targeting.gender"
					(valueChanges)="genderValueChanges($event)"
					[label]="'Gender'"
					[data]="genderListItems"
					[control]="genderControl"
				></drp02>
			</div>
			<div class="dropdown">
				<drp02
					[allowClear]="false"
					[disabled]="adData.placements.length < 1"
					(valueChanges)="placeValueChanges($event)"
					[label]="'Placement'"
					[data]="placementsListItems"
					[control]="placementsControl"
				></drp02>
			</div>
			<section class="interest-section">
				<header><font07>Interests</font07></header>
				<div class="interest-wrapper">
					<div class="interest" *ngFor="let interest of interests">
						<span>{{ interest }}</span>
					</div>
				</div>
			</section>
		</section>
	</article>
</main>
<btn01 (onClick)="saveForm()">Save</btn01>
