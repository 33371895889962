import { Injectable } from '@angular/core';

import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartLabel } from '../../models/chart-label';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { EChartOption } from 'echarts';
import { ReportComponentService } from './report-component.service';
import { GridLayout } from '../../models/grid-layout';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { chartTooltipModel } from '../../shared/models/chart-tooltip.model';

@Injectable({
	providedIn: 'root'
})
export class LineChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		private widgetComponentService: ReportComponentService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel): EChartOption {
		const label = new ChartLabel('left', 'middle', 'insideBottom', 0, 20, 12, widget.details.reportProperty.showDataLabels);
		const chartOptions = this.initChartOptions(widget);

		if (widget.details.compareData) {
			const firstData = this.createChartSourceForComplexData(widget, false, false, false, label);
			const secondData = this.createChartSourceForComplexData(widget, false, false, false, label, true);
			chartOptions.xAxis = this.widgetComponentService.createXAxisForComparison(widget, firstData.dimensions, secondData.dimensions);
			chartOptions.yAxis = {
				type: 'value'
			};
			chartOptions.series = [];
			chartOptions.series = [firstData.data[0], secondData.data[0]];
		} else {
			const chartData = this.createChartSourceForComplexData(widget, false, false, false, label);
			chartOptions.xAxis = this.createPrimaryAxis(chartData, widget, 35, widget.details.reportProperty.horizontalAxisTitle);
			chartOptions.yAxis = this.createSecondaryAxis(chartData, widget, 'yAxisIndex', widget.details.reportProperty.verticalAxisTitle, 50, 60);
			chartOptions.legend.bottom = 10;
			chartOptions.series = chartData.data;

			(chartOptions.dataZoom = [
				{
					show: true,
					type: 'inside'
				}
			]),
				(chartOptions.nameTextStyle = {
					color: '#424242'
				}),
				(chartOptions.grid = new GridLayout('120', '20', '20', '115', false));
			chartOptions.legend.data = [];
			chartOptions.legend.data = chartData.data.map(item => item.name);
		}

		chartOptions.tooltip = chartTooltipModel;
		return chartOptions;
	}
}
