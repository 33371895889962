<div class="oc-container">
	<!-- Objectives-->
	<app-expansion-panel-sc
		(click)="onExpansionClick.emit(campaignPanels.Objectives)"
		[isSelected]="isObjectivesPanelSelected"
		[expansionTitle]="'Campaign Objective'"
		[isExpandable]="false"
	>
		<div expansion-content class="oc-content">
			<div class="oc-image-container oc-margin-bottom">
				<app-card-box
					*ngFor="let objective of customObjectives"
					class="oc-card-size"
					(click)="onObjectiveClick(objective?.subObjectiveName)"
					[isSelected]="selectedSubObjectiveText === objective.subObjectiveName"
					[hidden]="objective?.disabled"
					[isInvalid]="
						!this.objectivesGroup.get('objective').valid &&
						this.objectivesGroup.touched &&
						!objective?.disabled &&
						objective.imageState !== state.Disabled &&
						objective.imageState !== state.Hover
					"
				>
					<div card-content appHoverSelect [statefulImage]="objective" [statefulImages]="customObjectives" class="oc-image-card oc-objective-card">
						<img
							src="/assets/icons/campaign-smart-create/new/{{ objective.name.toLowerCase() }}-{{
								selectedSubObjectiveText === objective.subObjectiveName ? 'pressed' : objective.imageState
							}}.svg"
							alt="image"
						/>
						<span class="oc-text-container">{{ objective?.name }}</span>
					</div>
				</app-card-box>
			</div>
			<span *ngIf="!objectivesGroup.get('objective').valid && showError" class="warning">
				Selecting a Campaign Objective is a mandatory action
			</span>
			<span *ngIf="pixelError && !showError" class="warning">
				This account doesn't have Pixels. Conversion Objective requires Pixels
			</span>
		</div>
	</app-expansion-panel-sc>
</div>
