import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import * as fromAAA from './aaa.reducer';
import * as fromAds from './ad-state/ad-state.reducer';
import * as fromAdset from './adset-state/adset-state.reducer';
import * as fromAssets from './aaa-assets/aaa-assets.reducer';
import * as fromAdPreview from './ad-preview/ad-preview.reducer';
import * as fromRoot from '../../state/app.state';
import { getFacebookBusinessOwnerId } from 'src/app/shared/state/user/user.reducer';
import { getSelectedAdAccount } from 'src/app/shared/state/shared.reducer';
import { CampaignCommonState } from '../../state/campaign-common-state/campaign-common-reducer';
export interface AAAModuleState extends fromRoot.AppState {
	AAAState: fromAAA.AAAState;
	AdState: fromAds.AdState;
	AdsetState: fromAdset.AdsetState;
	assetsState: fromAssets.AAAAssetsState;
	adPreview: fromAdPreview.AdPreviewState;
}

export const reducers = {
	AAAState: fromAAA.aaaReducer,
	AdState: fromAds.adStateReducer,
	AdsetState: fromAdset.adsetStateReducer,
	assetsState: fromAssets.assetsReducer,
	adPreview: fromAdPreview.adPreviewReducer
};

export const selectAAAModuleState = createFeatureSelector<AAAModuleState>('AAA');
const getAAASelector = createSelector(selectAAAModuleState, (state: AAAModuleState) => state.AAAState);
export const campaignCommonSelector = createFeatureSelector<CampaignCommonState>('campaignShared');

export const getObjectiveTree = createSelector(getAAASelector, details => details?.objective);
export const getSelectedCampaigns = createSelector(getAAASelector, details => details?.selectedCampaigns);
export const getSelectedAdsets = createSelector(getAAASelector, details => details?.selectedAdsets);
export const getSelectedAds = createSelector(getAAASelector, details => details?.selectedAds);
export const getSelectedAdIds = createSelector(getAAASelector, details => details?.selectedAdIds);
export const getCatalogs = createSelector(campaignCommonSelector, state => _.cloneDeep(state?.catalogs));
export const getPlacements = createSelector(getCatalogs, catalogs => catalogs?.Placement);
export const getValidations = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.validations));
export const getAdFormatFromCatalog = createSelector(getCatalogs, catalogs => catalogs?.AdFormat);
export const getAdPreviewFormat = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.AdFormat));
export const getCallToAction = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.CallToActionType));
export const getObjectivesWithDestinationFromCatalog = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.ObjectiveWithDestinationGroup));
export const getCredentialsForAssets = createSelector(getSelectedAdAccount, getFacebookBusinessOwnerId, (adAccount, businessOwnerId) => {
	return { accountId: adAccount?.adAccount?.id, businessOwnerId };
});

export const getOptimizationGoal = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.OptimizationGoal));
export const getClicks = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.ActionAttributionWindowClick));
export const getViews = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.ActionAttributionWindowView));
export const getBillingEvents = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.BillingEvent));
export const getPlatforms = createSelector(getCatalogs, catalogs => _.cloneDeep(catalogs?.Platform));
