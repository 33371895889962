import { Action } from '@ngrx/store';
import { UserDetails } from '../../../_models/identity-models/identity-detail';

export enum UserTypes {
	SetUserDetails = '[User] Set User Details',
	ClearUserDetails = '[User] Clear User Details',
	SetSubUserStatus = '[User] Set Sub user Status'
}

export class SetUserDetails implements Action {
	readonly type = UserTypes.SetUserDetails;
	constructor(public payload: UserDetails) {}
}

export class SetSubUserStatus implements Action {
	readonly type = UserTypes.SetSubUserStatus;
	constructor(public payload: boolean) {}
}

export class ClearUserDetails implements Action {
	readonly type = UserTypes.ClearUserDetails;
}

export type UserActions = SetUserDetails | ClearUserDetails | SetSubUserStatus;
