import { GeneralSettingsResponse } from '../models/api/general-settings-response.model';

export const parseSettings = <T>(payload: GeneralSettingsResponse): T => {
	if (!payload || payload.details == null) {
		return null;
	}

	try {
		return JSON.parse(payload.details) as T;
	} catch (e) {
		return null;
	}
};
