import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { TableViewColumn2 } from '../generic-table2/models/table-structure/table-view-column.model';
import { ReportsMetadataInterfaceService } from './reports-metadata-interface.service';
import { ReportingMetaInsightsService } from '../../reports/services/api-services/reporting-meta-insights.service';
import { BasicStructureModel } from '../../reports/models/basic-structure.model';
import { DimensionsResponseInterface } from '../../reports/models/dimensions-response.interface';
import { ReportTypesResponse } from '../../reports/models/report-types-response.interface';
import { BreakdownsResponseInterface } from '../models/breakdowns-response.interface';
import { FilterKeysEnum } from '../../reports/shared/models/filter-keys.enum';

@Injectable({ providedIn: 'root' })
export class GoogleMetadataService implements ReportsMetadataInterfaceService {
	private baseURL = BaseApiUrl.PythonGoogleAccounts;
	private allFilterKeys = [FilterKeysEnum.Active, FilterKeysEnum.Paused, FilterKeysEnum.Completed];

	constructor(private http: HttpClient, private reportingMetaInsightsService: ReportingMetaInsightsService) {}

	public getReportsByLevel(): Observable<ReportTypesResponse[]> {
		return this.http.get<ReportTypesResponse[]>(`${this.baseURL}get-reports`);
	}

	public getDimensionsByReport(): Observable<DimensionsResponseInterface> {
		return this.http.get<DimensionsResponseInterface>(`${this.baseURL}get-dimensions`);
	}

	public getMetricsByReportAndDimension(): Observable<TableViewColumn2[]> {
		return this.http.get<TableViewColumn2[]>(`${this.baseURL}get-metrics`);
	}

	public getBreakdownsByMetrics(): Observable<BreakdownsResponseInterface> {
		return this.http.get<BreakdownsResponseInterface>(`${this.baseURL}get-breakdowns`);
	}

	public getStructures(reportLevel: string, adAccountsId: string, filtersKeys: FilterKeysEnum[] = this.allFilterKeys): Observable<BasicStructureModel[]> {
		filtersKeys = this.reportingMetaInsightsService.addCompletedStatus(filtersKeys);
		return this.http.post<BasicStructureModel[]>(
			`${this.baseURL}filtered-structures/${this.reportingMetaInsightsService.getParameterFromWidgetLevel(reportLevel).slice(0, -1)}`,
			{
				ad_account_id: adAccountsId.toString(),
				statuses: filtersKeys
			}
		);
	}

	public getFilteredStructuresByCampaignIds(
		reportLevel: string,
		campaignIds: string[],
		adAccountId: string,
		filtersKeys: FilterKeysEnum[] = this.allFilterKeys
	): Observable<BasicStructureModel[]> {
		filtersKeys = this.reportingMetaInsightsService.addCompletedStatus(filtersKeys);
		return this.http.post<BasicStructureModel[]>(
			`${this.baseURL}filtered-structures/${this.reportingMetaInsightsService.getParameterFromWidgetLevel(reportLevel).slice(0, -1)}`,
			{
				ad_account_id: adAccountId.toString(),
				statuses: filtersKeys
			}
		);
	}
}
