import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampaignSmartCreateState } from '../../state/smart-create.reducer';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'app-optimization-location',
	templateUrl: './optimization-location.component.html',
	styleUrls: ['./optimization-location.component.scss']
})
export class OptimizationLocationComponent implements OnInit {
	@Input() public isSelected = false;
	@Input() public isSplitLocationToggled = false;
	@Input() public control: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();

	constructor(public campaignSmartCreateStore: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {}

	public onLocationToggle(): void {
		this.isSplitLocationToggled = !this.isSplitLocationToggled;
	}
}
