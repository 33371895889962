import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {
	constructor() {}

	transform(number: any): string {
		let result = '';

		if (!number) {
			return number;
		} else {
			const value = parseFloat(number);

			if (!isNaN(value)) {
				// numberWithCommas
				const formattedNumber = Math.round(value * 100) / 100;
				return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}
		}

		number.split('_').forEach((element: any) => {
			if (element && element.length) {
				result += element.charAt(0).toUpperCase() + element.slice(1) + ' ';
			}
		});

		return result.trim();
	}
}
