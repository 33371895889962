import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { AccountSelectedGroup } from '../../_models/user-models/account-selected-group';
import { AuthenticationService } from '../../_services/authentication.service';
import { environment } from '../../../environments/environment';
import { buildGeneratedValues } from '../../../../build-generated-values';
import { EMPTY, interval, Subject, Subscription } from 'rxjs';
import { ChannelButton } from './sidenav-button-channel.model';
import { AccountTypeService } from '../../shared/account-type.service';
import { getActiveUserManagementPage, UserManagementState } from '../../user-management/state/user-management.reducer';
import { select, Store } from '@ngrx/store';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { TranslateService } from '@ngx-translate/core';
import { getGlobalSpinnerStatus, getPublishStatus, SharedState } from '../../shared/state/shared.reducer';
import { getAccountState, getFiledId, getUserDetails, hasBackOfficePermission, isUserImpersonated, UserState } from '../../shared/state/user/user.reducer';
import { catchError, debounceTime, delay, retryWhen, skipWhile, take, takeUntil } from 'rxjs/operators';
import { UserDetails } from '../../_models/identity-models/identity-detail';
import { SetUserDetails } from '../../shared/state/user/user.actions';
import { StorageKey } from '../../_models/local-storage-key';
import { AccountsPermissions } from '../../shared/permisions/enums/accounts-permissions';
import { AdsManagerPermissions } from '../../shared/permisions/enums/ads-manager-permissions';
import { Modules } from '../../shared/permisions/enums/modules';
import { PermissionsDirectiveInterface } from '../../shared/permisions/models/permissions-directive.interface';
import { BackofficePermissions } from '../../shared/permisions/enums/backoffice-permissions';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { OptimizePermissions } from '../../shared/permisions/enums/optimize-permissions';
import { PixelPermissions } from '../../shared/permisions/enums/pixel-permissions';
import { ReportsPermissions } from '../../shared/permisions/enums/reports-permissions';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { CampaignSmartCreateService } from '../../campaign-smart-create/services/campaign-smart-create.service';
import { CampaignBuilderPermissions } from '../../shared/permisions/enums/campaign-builder-permissions';
import { HideGlobalSpinner, UpdatePublishStatus } from '../../shared/state/shared.actions';
import { PublishStatusInterface } from '../../shared/state/state-entities/publish/publish-status.interface';
import { Publish } from '../../shared/state/state-entities/publish/publish-status.model';
import { PublishResponseInterface } from '../../campaign-smart-create/models/publish-response.interface';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { ToastNotificationType } from '../../shared/toast-notification/toast-notification-type.enum';
import { ScrollService } from '../../_services/scroll.service';
import { map } from 'rxjs/operators/map';
import { KnowledgebaseComponent } from '../component-pieces/knowledgebase/knowledgebase.component';
import { SourcePlatform } from './sidenav-platform-menu';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserRejectedReason } from 'src/app/_services/user/user-rejected-reason.enum';
import { MatListItem } from '@angular/material/list';
import { InfluencerPermissions } from 'src/app/shared/permisions/enums/influencer-permissions';
import { CreativeBuilderPermissions } from 'src/app/shared/permisions/enums/creative-builder-permissions';
import { AudiencePermissions } from 'src/app/shared/permisions/enums/audience-permissions';
import { ProductCatalogPermissions } from 'src/app/shared/permisions/enums/product-catalog-permissions';
import { IMainMenuGroup, IProductMenuGroup, IProductSubMenuGroup } from '../_models/nav-group.interface';
import { takeWhile } from 'lodash';
import { I } from '@angular/cdk/keycodes';
//import { ShakespeareService } from 'src/app/shakespeare/shakespeare.service';
//import { ReportingCampaignService } from 'src/app/social-media-influencer/modules/reporting/services/reporting-campaign.service';

@Component({
	selector: 'app-sidenav-group',
	templateUrl: './sidenav-group.component.html',
	styleUrls: ['./sidenav-group.component.scss'],
	providers: [ScrollService]
})
export class SidenavGroupComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav') public sidenav: MatSidenav;
	@ViewChild('firstTimeLogin') public firstTimeLogin: TemplateRef<any>;
	@ViewChild('menuTrigger') public trigger: MatMenuTrigger;

	@ViewChild('settingsPopup') settingsPopup: ElementRef;
	@ViewChild('settingsIcon') settingsIcon: ElementRef;
	@ViewChild('mainSection') mainSection: ElementRef;
	@ViewChild('headerLogo') headerLogo: ElementRef;

	public mainMenuGroup = new Map<number, IMainMenuGroup>();

	public productMenuGroup: Map<number, IProductMenuGroup>;

	public productSubMenuGroup = new Map<number, IProductSubMenuGroup>();

	public accountSelectedGroupByDefault: AccountSelectedGroup = AccountSelectedGroup.MyAccount;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public backOfficePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.BackOffice,
		permissions: [BackofficePermissions.CanAccessBackOffice]
	};
	public accountsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Accounts,
		permissions: [AccountsPermissions.CanAccessAccounts]
	};
	public adsManagerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.AdsManager,
		permissions: [AdsManagerPermissions.CanAccessAdsManager]
	};
	public campaignBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CampaignBuilder,
		permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
	};
	public creativeBuilderPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.CreativeBuilder,
		permissions: [CreativeBuilderPermissions.CanAccessCreativeBuilder]
	};
	public optimizePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Optimize,
		permissions: [OptimizePermissions.CanAccessOptimize]
	};
	public pixelPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Pixel,
		permissions: [PixelPermissions.CanAccessPixels]
	};
	public audiencePermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Audience,
		permissions: [AudiencePermissions.CanAccessAudience]
	};
	public reportsPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Reports,
		permissions: [ReportsPermissions.CanAccessReports]
	};
	public influencerPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.Influencer,
		permissions: [InfluencerPermissions.FullAccess]
	};
	public productCatalogPermission: PermissionsDirectiveInterface = {
		moduleName: Modules.ProductCatalog,
		permissions: [ProductCatalogPermissions.CanAccessProductCatalog]
	};
	public showSpinner = false;
	public isAdmin = false;
	public isImpersonated = false;
	public unpaidInvoice = false;
	public showProgressBar = false;
	public invoicesList: InvoiceHistory[];
	public timestamp: string;
	public userDetails: UserDetails;
	public showUserManagement: boolean;
	public dropdownIsVisible: boolean;
	public selectedButton: ChannelButton;
	public accountState: number;
	public currentUrl: string;
	public progressMin = 0;
	public progressMax: number;
	public progressStatus: number;
	public isLoading: boolean;
	public isSocialMediaInfluencer: boolean;
	public isInfluencerPlatform: boolean;
	public progressBarLabel: LabelSettings = {
		position: 'end',
		visible: true,
		format: () => `${this.progressStatus + ' of ' + this.progressMax}`
	};
	public userStateEnum = UserStateEnum;
	public pcHover = false;
	public pcChildHover = false;
	public userStatus: UserRejectedReason;
	public isSelectedNoBO: string;
	public isBO = false;
	productCatalogMenuSelected: boolean;
	selectedRoute: string;
	public newPointPosition: string = '0px';
	expandMenu: boolean;
	mouseInside: boolean;
	filteredMegaMenuList: IMainMenuGroup[] = [];
	filteredMenuList: IProductMenuGroup[] = [];
	filteredSubMenuList: IProductSubMenuGroup[];
	openSubMenu: boolean;
	megaMenuOpen: any;
	megaMenuSelected: string = 'sansa';
	profileOpen: any;
	dexterList: IProductMenuGroup[] = [];
	sansaList: IProductMenuGroup[] = [];
	shakespeareList: IProductMenuGroup[] = [];
	activeSearchMapList: IProductMenuGroup[];
	activeRoute: string;
	userFName: string;
	userLName: string;
	userEmail: string;
	urlOrigin: string;
	activeParentIndex: number;
	allSubMenuItems: IProductSubMenuGroup[] = [];
	pageLoadingRoute: boolean;

	public get navHover(): boolean {
		return this.pcHover || this.pcChildHover;
	}

	public hideSideBar: number = 0;

	private expandAccountMenuSubscription = new Subscription();
	private unsubscriber$ = new Subject<void>();
	private asyncLoadCount: number;
	public hasSMI: boolean;
	public smiHover = false;
	public smiChildHover = false;
	public get smiNavHover(): boolean {
		return this.smiHover || this.smiChildHover;
	}

	@HostListener('document:click', ['$event'])
	clickout(event) {
		if (!this.settingsIcon?.nativeElement.contains(event.target)) {
			this.profileOpen = false;
		}
	}

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private accountTypeService: AccountTypeService,
		private config: NgSelectConfig,
		private store: Store<UserManagementState>,
		private dialog: MatDialog,
		private userServiceApi: UserServiceApi,
		public translate: TranslateService,
		private sharedStore: Store<SharedState>,
		private userStore: Store<UserState>,
		private backOfficeService: BackOfficeService,
		private smartCreateService: CampaignSmartCreateService,
		private toastService: ToastNotificationService,
		private scrollService: ScrollService,
		public cdRef: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute //private shakespeareService: ShakespeareService,
	) //private reportingService: ReportingCampaignService
	{
		this.pageLoadingRoute = true;
		this.mainMenuGroup = new Map();
		const megaMenus = this.authenticationService.allowedMegaRoutes$.value;
		try {
			megaMenus.forEach((item, index) => {
				this.mainMenuGroup.set(index + 1, item);
			});
		} catch (e) {
			this.router.navigate(['accounts']);
		}

		this.productMenuGroup = new Map([
			[
				1,
				{
					parentName: 'sansa',
					name: 'dashboard',
					title: 'Home',
					icon: 'assets/icons/nav-menu/dashboard.svg',
					isSelected: true,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/influencer',
					childIndex: 0
				}
			],
			[
				2,
				{
					parentName: 'sansa',
					name: 'discovery',
					title: 'Discovery',
					icon: 'assets/icons/nav-menu/search.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/influencer/discovery',
					childIndex: 0
				}
			],
			[
				3,
				{
					parentName: 'sansa',
					name: 'outreach',
					title: 'Outreach',
					icon: 'assets/icons/nav-menu/dvr.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/influencer/outreach',
					childIndex: 0
				}
			],
			[
				4,
				{
					parentName: 'sansa',
					name: 'creatives',
					title: 'Creatives',
					icon: 'assets/icons/nav-menu/wallpaper.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/influencer/creatives',
					childIndex: 0
				}
			],
			[
				5,
				{
					parentName: 'sansa',
					name: 'reporting',
					title: 'Reporting',
					icon: 'assets/icons/nav-menu/donut-large.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '/influencer/reporting',
					childIndex: 0
				}
			],
			[
				6,
				{
					parentName: 'sansa',
					name: 'jobs',
					title: 'Jobs',
					icon: 'assets/icons/nav-menu/business-center.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/influencer/jobs',
					childIndex: 0
				}
			],
			[
				7,
				{
					parentName: 'sansa',
					name: 'payments',
					title: 'Payments',
					icon: 'assets/icons/nav-menu/payment.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/influencer/payments',
					childIndex: 0
				}
			],
			[
				8,
				{
					parentName: 'dexter',
					name: 'home',
					title: 'Home',
					icon: 'assets/icons/nav-menu/dashboard.svg',
					isSelected: true,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/accounts',
					childIndex: 0
				}
			],
			[
				9,
				{
					parentName: 'dexter',
					name: 'optimize',
					title: 'Optimize',
					icon: 'assets/icons/nav-menu/dexter-outline.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '/optimize/dashboard',
					childIndex: 0
				}
			],
			[
				10,
				{
					parentName: 'dexter',
					name: 'campaign-builder',
					title: 'Campaign Builder',
					icon: 'assets/icons/nav-menu/campaign.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '/campaign/intro',
					childIndex: 0
				}
			],
			[
				11,
				{
					parentName: 'dexter',
					name: 'audience-creator',
					title: 'Audience Creator',
					icon: 'assets/icons/nav-menu/audience.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '/optimize/dexter-creator',
					childIndex: 0
				}
			],
			[
				12,
				{
					parentName: 'dexter',
					name: 'copywriter',
					title: 'Copywriter',
					icon: 'assets/icons/nav-menu/copywriter.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '/optimize/dexter-creator',
					childIndex: 0
				}
			],
			[
				13,
				{
					parentName: 'dexter',
					name: 'creative-builder',
					title: 'Creative Builder',
					icon: 'assets/icons/nav-menu/wallpaper.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/creative-builder/home',
					childIndex: 0
				}
			],
			[
				14,
				{
					parentName: 'dexter',
					name: 'ecommerce',
					title: 'Ecommerce',
					icon: 'assets/icons/nav-menu/shopping-bag.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '/catalog',
					childIndex: 0
				}
			],
			[
				15,
				{
					parentName: 'shakespeare',
					name: 'home',
					title: 'Home',
					icon: 'assets/icons/nav-menu/dashboard.svg',
					isSelected: true,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/shakespeare',
					childIndex: 0
				}
			],
			[
				16,
				{
					parentName: 'shakespeare',
					name: 'templates',
					title: 'Templates',
					icon: 'assets/icons/nav-menu/templates.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '/shakespeare/templates',
					childIndex: 0
				}
			],
			[
				17,
				{
					parentName: 'shakespeare',
					name: 'projects',
					title: 'Projects',
					icon: 'assets/icons/nav-menu/projects.svg',
					isSelected: false,
					hasChildren: true,
					hoverOver: false,
					childOpen: false,
					route: '',
					childIndex: 0
				}
			],
			[
				18,
				{
					parentName: 'shakespeare',
					name: 'library',
					title: 'Library',
					icon: 'assets/icons/nav-menu/library.svg',
					isSelected: false,
					hasChildren: false,
					hoverOver: false,
					childOpen: false,
					route: '/shakespeare/library',
					childIndex: 0
				}
			]
		]);

		this.productSubMenuGroup = new Map([
			[1, { parentName: 'reporting', name: 'campaign-dashboard', title: 'Campaign Dashboard', icon: '', isSelected: false, hoverOver: false }],
			[2, { parentName: 'reporting', name: 'campaign-analytics', title: 'Campaign Analytics', icon: '', isSelected: false, hoverOver: false }],
			[3, { parentName: 'reporting', name: 'influencer-dashboard', title: 'Influencer dashboard', icon: '', isSelected: false, hoverOver: false }],
			[4, { parentName: 'reporting', name: 'influencer-analytics', title: 'Influencer Analytics', icon: '', isSelected: false, hoverOver: false }],
			[5, { parentName: 'optimize', name: 'dashboard', title: 'Dashboard', icon: '', isSelected: false, hoverOver: false, route: '/optimize/dashboard' }],
			[
				6,
				{
					parentName: 'optimize',
					name: 'recommendationsv2',
					title: 'Recommendations',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/recommendationsv2'
				}
			],
			[7, { parentName: 'optimize', name: 'new-ideas', title: 'New Ideas', icon: '', isSelected: false, hoverOver: false, route: '/optimize/labs' }],
			[
				8,
				{
					parentName: 'optimize',
					name: 'sync',
					title: 'Sync',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/dexter-sync'
				}
			],
			[
				9,
				{
					parentName: 'campaign-builder',
					name: 'google',
					title: 'Google',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/campaign/intro'
				}
			],
			[
				10,
				{
					parentName: 'audience-creator',
					name: 'facebook',
					title: 'Facebook',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/dexter-creator'
				}
			],
			[
				11,
				{
					parentName: 'copywriter',
					name: 'ad-copy',
					title: 'Ad Copy',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/dexter-creator'
				}
			],
			[
				12,
				{
					parentName: 'ecommerce',
					name: 'manage',
					title: 'Manage',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/catalog/catalog-manage'
				}
			],
			[
				13,
				{
					parentName: 'ecommerce',
					name: 'import',
					title: 'Import',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/catalog/catalog-import-option'
				}
			],
			[
				14,
				{
					parentName: 'templates',
					name: 'all-templates',
					title: 'All Templates',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/shakespeare/templates'
				}
			],
			[
				15,
				{ parentName: 'templates', name: 'ad-copy', title: 'Ad Copy', icon: '', isSelected: false, hoverOver: false, route: '/shakespeare/templates' }
			],
			[16, { parentName: 'templates', name: 'email', title: 'Email', icon: '', isSelected: false, hoverOver: false, route: '/shakespeare/templates' }],
			[
				17,
				{
					parentName: 'templates',
					name: 'product-descriptions',
					title: 'Product Descriptions',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/shakespeare/templates'
				}
			],
			[18, { parentName: 'templates', name: 'blogs', title: 'Blogs', icon: '', isSelected: false, hoverOver: false, route: '/shakespeare/templates' }],
			[
				19,
				{
					parentName: 'templates',
					name: 'content-improver',
					title: 'Content Improver',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/shakespeare/templates'
				}
			],
			[
				20,
				{
					parentName: 'optimize',
					name: 'newIdeasV2',
					title: 'New Ideas V2',
					icon: '',
					isSelected: false,
					hoverOver: false,
					route: '/optimize/labsv2'
				}
			]
		]);

		this.asyncLoadCount = 0;
		this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe((event: RouterEvent) => {
			if (event instanceof NavigationEnd) {
				this.urlOrigin = window.location.origin;

				if (event.id === 1) {
					this.isUserAdmin();
				}
				this.userStore.pipe(select(getFiledId), take(1)).subscribe(filedId => {
					this.userStore.pipe(select(hasBackOfficePermission), take(1)).subscribe(isAdmin => {
						this.currentUrl = event.url;
						if (!isAdmin) {
							this.backOfficeService
								.getUserState(filedId)
								.pipe(takeUntil(this.unsubscriber$))
								.subscribe(accountState => {
									this.currentUrl = event.url;

									if (
										this.currentUrl !== '/user-management/billing-and-payment' &&
										accountState === UserStateEnum.BillPendingRestrictedAccess
									) {
										this.router.navigate(['/user-management/billing-and-payment']);
									} else if (
										accountState === UserStateEnum.FreemiumExpiredNoCreditCard ||
										accountState === UserStateEnum.FreeTrialExpiredNoCreditCard
									) {
										this.router.navigate(['/authentication/trial-end']);
									} else if (accountState === UserStateEnum.NoCreditCard) {
										this.router.navigate(['/authentication/add-billing-address']);
									} else if (accountState === UserStateEnum.NoBusinessOwner) {
										this.router.navigate(['/authentication/connect']);
									}
								});
						}
					});
				});
				this.hasSMI = event.url.split('/')[1] == 'influencer';
				this.activeRoute = event.url;

				if (!this.pageLoadingRoute) {
					this.checkRouteMap();
				}

				// if ((this.activeRoute === '/accounts' || this.activeRoute === '/creative-builder' || this.activeRoute === '/catalog') && (this.megaMenuSelected !== 'dexter')) {
				// 	this.megaMenuItemActive(0);
				// } else if (this.activeRoute === '/influencer' && (this.megaMenuSelected !== 'sansa')) {
				// 	this.megaMenuItemActive(1);
				// }
				// if (this.activeRoute === '/accounts' && this.megaMenuSelected === 'shakespeare') {
				// 	this.router.navigate(['/shakespear-home']);
				// }
				// if (this.activeRoute === '/influencer') {
				// 	if (this.filteredMenuList.length < 1) {
				// 		this.filterMegaMenutList();
				// 	}
				// 	// this.megaMenuItemActive(1);
				// }
			}
		});

		if (environment.Flags.hasTimestamp) {
			this.timestamp = buildGeneratedValues.timeStamp;
		}

		this.config.appendTo = 'body';
		// Configs for ng-select
		this.config.bindValue = 'value';
	}

	public ngOnInit(): void {
		this.isUserAdmin();
		// this.initializeSpinner();

		this.hideSideBar = +localStorage.getItem('hideBar') || 0;

		if (this.selectedButton) {
			this.accountTypeService.setAccountType(this.selectedButton.sourceChannel);
		}
		this.impersonationHandle();
		this.checkUserManagementState();
		this.isUnpaidInvoice();

		// this.megaMenuSelected = this.authenticationService.allowedMegaRoutes$.value[0];

		if (!this.isAdmin) {
			setTimeout(() => {
				this.filterMegaMenutList();
			}, 200);
		}

		this.listenForInfluencerAnalytics();

		// this.authenticationService.setMegaRouteIndex$.pipe(
		// 	takeUntil(this.unsubscriber$),
		// 	skipWhile(item => !item)
		// ).subscribe((value) => {
		// 	if (value !== null && value !== undefined && !this.isAdmin) {
		// 		this.filteredMenuList = [];
		// 		this.filterMegaMenutList();
		// 	}
		// })

		// interval(200)
		// 	.pipe(takeUntil(this.unsubscriber$))
		// 	.subscribe(() => {
		// 		// if (this.router.getCurrentNavigation()?.extras?.state?.fromWelcome) {
		// 		// 	this.filterMegaMenutList();
		// 		// }
		// 		const liveRoute = this.authenticationService.setParentRoute$.value;
		// 		if (!this.filteredMenuList[this.activeParentIndex]?.route.includes(liveRoute)) {
		// 			this.filteredMenuList[this.activeParentIndex].isSelected = true;
		// 		}
		// 	});

		// this.authenticationService.setExternalRoute$.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
		// 	if (value) {
		// 		if (value.type === 'parent') {
		// 			const findIndex = this.filteredMenuList.findIndex(item => {
		// 				return item.route === value.route;
		// 			});
		// 			this.menuItemActive(findIndex);
		// 		}
		// 		if (value.type === 'child') {
		// 			const findIndex = this.filteredSubMenuList.findIndex(item => {
		// 				return item.route === value.route;
		// 			});
		// 			this.menuItemSubActive(findIndex);
		// 		}
		// 	}
		// });

		const storageJwt = JSON.parse(localStorage.getItem(StorageKey.decodedJwtIo));
		this.userFName = storageJwt['user_firstname'];
		this.userLName = storageJwt['user_lastname'];
		this.userEmail = storageJwt['sub'];
	}

	public ngAfterViewChecked(): void {
		this.cdRef.detectChanges();
	}

	public listenForInfluencerAnalytics(): void {
		// this.reportingService.selectedCampaign$.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
		// 	if (value) {
		// 		this.menuItemSubActive(1, false);
		// 	}
		// });
	}

	public checkRouteMap(): void {
		if (this.activeRoute.includes('?')) {
			return;
		}
		this.activeSearchMapList = [];
		if (this.megaMenuSelected === 'sansa') {
			this.activeSearchMapList = this.sansaList;
		} else if (this.megaMenuSelected === 'dexter') {
			this.activeSearchMapList = this.sansaList;
		} else if (this.megaMenuSelected === 'shakespeare') {
			this.activeSearchMapList = this.shakespeareList;
		}
		if (this.activeSearchMapList.length > 0) {
			const findRouteIndex = this.activeSearchMapList.findIndex(item => {
				return location.pathname === item.route;
				// return this.activeRoute.includes(item.route);
			});
			if (findRouteIndex !== -1) {
				this.pageLoadingRoute = true;
				this.menuItemActive(findRouteIndex, false);

				if (this.pageLoadingRoute) {
					setTimeout(() => {
						this.pageLoadingRoute = false;
					}, 1000);
				}
			}
		}
		// const findRoute = this.allSubMenuItems.find((item) => {
		// 	return item.route.includes(this.activeRoute);
		// });
		// if (findRoute) {
		// 	this.activeSearchMapList = [];
		// 	if (this.megaMenuSelected === 'sansa') {
		// 		this.activeSearchMapList = this.sansaList;
		// 	} else if (this.megaMenuSelected === 'dexter') {
		// 		this.activeSearchMapList = this.sansaList;
		// 	} else if (this.megaMenuSelected === 'shakespeare') {
		// 		this.activeSearchMapList = this.shakespeareList;
		// 	}
		// 	const findParent = this.activeSearchMapList.find((item) => {
		// 		return item.name === findRoute.parentName
		// 	});
		// 	const filterSubMenuItems = this.allSubMenuItems.filter((item) => {
		// 		return item.parentName === findParent.name
		// 	});
		// 	const findSubMenuIndex = filterSubMenuItems.findIndex(item => item.name === findRoute.name);
		// 	this.menuItemSubActive(findSubMenuIndex, false);
		// }
	}

	toggleProfileAction(): void {
		this.profileOpen ? (this.profileOpen = false) : (this.profileOpen = true);
	}

	public toggleExpandMegaMenu(num?: number): void {
		this.megaMenuOpen ? (this.megaMenuOpen = false) : (this.megaMenuOpen = true);
		this.expandMenu = true;
		this.authenticationService.expandMenu$.next(this.expandMenu);
	}

	public toggleExpandNavMenu(): void {
		if (this.expandMenu) {
			this.expandMenu = false;
			this.authenticationService.expandMenu$.next(this.expandMenu);
			// this.filteredMenuList.forEach(item => {
			// 	item.childOpen = false;
			// });
		} else {
			this.expandMenu = true;
			this.authenticationService.expandMenu$.next(this.expandMenu);
		}
	}

	public filterMegaMenutList(): void {
		let filtered: IMainMenuGroup[] = [];
		const activeMegaRouteIndex = this.authenticationService.setMegaRouteIndex$.value;

		// if (activeMegaRouteIndex === 0) {
		// 	this.megaMenuSelected === 'dexter';
		// } else if (activeMegaRouteIndex === 1) {
		// 	this.megaMenuSelected === 'sansa';
		// } else if (activeMegaRouteIndex === 2) {
		// 	this.megaMenuSelected === 'shakespeare';
		// }

		for (let item of this.mainMenuGroup.values()) {
			filtered.push(item);
			this.filterMenutList(item.name);
		}
		this.filteredMegaMenuList = filtered;

		this.megaMenuItemActive(activeMegaRouteIndex);

		// if (this.activeRoute === '/influencer') {
		// 	this.megaMenuItemActive(1);
		// }
	}

	public filterMenutList(megaMenuName: string): void {
		for (let item of this.productMenuGroup.values()) {
			if (item.parentName === megaMenuName && megaMenuName === 'dexter') {
				this.dexterList.push(item);
			}
			if (item.parentName === megaMenuName && megaMenuName === 'sansa') {
				this.sansaList.push(item);
			}
			if (item.parentName === megaMenuName && megaMenuName === 'shakespeare') {
				this.shakespeareList.push(item);
			}
		}

		if (this.megaMenuSelected === 'dexter') {
			this.filteredMenuList = this.dexterList;
		}
		if (this.megaMenuSelected === 'sansa') {
			this.filteredMenuList = this.sansaList;
			// this.router.navigate(['/influencer']);
		}
		if (this.megaMenuSelected === 'shakespeare') {
			this.filteredMenuList = this.shakespeareList;
		}
		this.openSubMenu = true;
	}

	menuItemActive(index: number, pushRoute = true): void {
		this.filteredMenuList.forEach(item => {
			item.isSelected = false;
		});
		for (let i = 0; i < this.filteredMenuList.length; i++) {
			if (i === index) {
				this.filteredMenuList[i].isSelected = true;
				if (this.filteredMenuList[i].hasChildren) {
					this.expandMenu = true;
					this.authenticationService.expandMenu$.next(this.expandMenu);
				}
				this.menuChildrenList(this.filteredMenuList[i], i, pushRoute);

				break;
			}
		}
		this.activeParentIndex = index;
		if (this.filteredMenuList[index].name === 'audience-creator') {
			this.authenticationService.triggerNavRoute$.next('audience');
		}
		if (this.filteredMenuList[index].name === 'copywriter') {
			this.authenticationService.triggerNavRoute$.next('content');
		}
		if (this.filteredMenuList[index].name === 'campaign-builder') {
			this.authenticationService.triggerNavRoute$.next('google');
		}
		if (!this.filteredMenuList[index].hasChildren) {
			this.router.navigate([this.filteredMenuList[index].route]);
		}
		if (!this.filteredMenuList[index].hasChildren) {
			// this.expandMenu = false;
			this.filteredMenuList.forEach(item => {
				item.childOpen = false;
			});
		} else {
			this.expandMenu = true;
			this.authenticationService.expandMenu$.next(this.expandMenu);
			this.filteredMenuList[index].childOpen = true;
		}
	}

	megaMenuItemActive(index: number, change = false): void {
		this.filteredMegaMenuList.forEach(item => {
			item.isSelected = false;
		});
		for (let i = 0; i < this.filteredMegaMenuList.length; i++) {
			if (i === index) {
				this.filteredMegaMenuList[i].isSelected = true;
				this.megaMenuSelected = this.filteredMegaMenuList[i].name;
				break;
			}
		}

		if (this.megaMenuSelected === 'dexter') {
			this.filteredMenuList = this.dexterList;
			this.router.navigate(['/accounts']);
		}
		if (this.megaMenuSelected === 'sansa') {
			this.filteredMenuList = this.sansaList;
			if (this.activeRoute !== '/influencer/terms-and-conditions') {
				this.router.navigate(['/influencer']);
			}
		}
		if (this.megaMenuSelected === 'shakespeare') {
			this.filteredMenuList = this.shakespeareList;
			this.router.navigate(['/shakespeare']);
		}
		this.megaMenuOpen = false;
		// this.expandMenu = false;
		this.filteredSubMenuList = [];
		this.filteredMenuList.forEach(item => {
			item.isSelected = false;
			item.childOpen = false;
		});
		this.activeParentIndex = 0;
		this.filteredMenuList.length > 0 ? (this.filteredMenuList[0].isSelected = true) : null;

		if (this.pageLoadingRoute) {
			setTimeout(() => {
				this.pageLoadingRoute = false;
			}, 1000);
		}
		// for (let item of this.productSubMenuGroup.values()) {
		// 	this.allSubMenuItems.push(item);
		// }
	}

	menuItemSubActive(index: number, pushRoute = true): void {
		this.filteredSubMenuList.forEach(item => {
			item.isSelected = false;
		});
		for (let i = 0; i < this.filteredSubMenuList.length; i++) {
			if (i === index) {
				this.authenticationService.navMenuRoute$.next(true);
				const findParent = this.filteredMenuList.findIndex(item => {
					return item.name === this.filteredSubMenuList[i].parentName;
				});
				this.activeParentIndex = findParent;
				if (this.filteredSubMenuList[i].parentName === 'reporting') {
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]).then(() => {
							this.authenticationService.setSubNavIndex$.next(i);
						});
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'audience-creator') {
					this.authenticationService.triggerNavRoute$.next('audience');
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]);
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'copywriter') {
					this.authenticationService.triggerNavRoute$.next('content');
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]);
					}
				}
				if (this.filteredSubMenuList[i].parentName === 'templates') {
					//this.shakespeareService.triggerParams$.next(this.filteredSubMenuList[i].name);
					this.router.navigate([this.filteredMenuList[findParent].route]);
				}
				if (this.filteredSubMenuList[i].parentName === 'campaign-builder') {
					this.authenticationService.triggerNavRoute$.next('google');
					if (pushRoute) {
						this.router.navigate([this.filteredMenuList[findParent].route]);
					}
				}
				if (
					this.filteredSubMenuList[i].parentName !== 'reporting' &&
					this.filteredSubMenuList[i].parentName !== 'copywriter' &&
					this.filteredSubMenuList[i].parentName !== 'audience-creator' &&
					this.filteredSubMenuList[i].parentName !== 'campaign-builder'
				) {
					setTimeout(() => {
						if (pushRoute) {
							this.router.navigate([this.filteredSubMenuList[i].route]);
						}
					}, 200);
				}
				this.filteredSubMenuList[i].isSelected = true;
				this.filteredMenuList[findParent].childIndex = i;
				break;
			}
		}
	}

	public menuChildrenList(list: IProductMenuGroup, parentIndex, pushRoute = true): void {
		let filteredSubMenu: IProductSubMenuGroup[] = [];
		for (let item of this.productSubMenuGroup.values()) {
			if (item.parentName === list.name) {
				filteredSubMenu.push(item);
			}
		}
		this.filteredSubMenuList = filteredSubMenu;
		if (this.filteredSubMenuList.length > 0) {
			if (this.filteredMenuList[parentIndex].childIndex === 0) {
				this.filteredSubMenuList[0].isSelected = true;
				this.menuItemSubActive(0, pushRoute);
			} else {
				this.filteredSubMenuList[this.filteredMenuList[parentIndex].childIndex].isSelected = true;
				this.menuItemSubActive(this.filteredMenuList[parentIndex].childIndex, pushRoute);
			}
			this.openSubMenu = true;
		} else {
			this.menuItemSubActive(this.filteredMenuList[parentIndex].childIndex, pushRoute);
		}
		this.store.dispatch(new HideGlobalSpinner());
	}

	public collapseMenu(): void {
		this.openSubMenu ? (this.openSubMenu = false) : (this.openSubMenu = true);
	}

	public mouseEnterMegaMenu(index: number): void {
		this.filteredMegaMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredMegaMenuList.length; i++) {
			if (i === index) {
				this.filteredMegaMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveMegaMenu(index): void {
		this.filteredMegaMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterMenu(index: number): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredMenuList.length; i++) {
			if (i === index) {
				this.filteredMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveMenu(index): void {
		this.filteredMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterSubMenu(index: number): void {
		this.filteredSubMenuList.forEach(item => {
			item.hoverOver = false;
		});
		for (let i = 0; i < this.filteredSubMenuList.length; i++) {
			if (i === index) {
				this.filteredSubMenuList[i].hoverOver = true;
				break;
			}
		}
	}

	public mouseLeaveSubMenu(index): void {
		this.filteredSubMenuList.forEach(item => {
			item.hoverOver = false;
		});
	}

	public mouseEnterMenuEffect(): Object {
		let styles = {
			background: !this.megaMenuOpen ? '#DADCE0' : '#fafafa'
		};
		return styles;
	}

	public menuItemSelectedEffect(): Object {
		let styles = {
			background: '#DADCE0',
			'border-left': !this.megaMenuOpen ? '5px solid #5b5fc7' : '0px'
		};
		return styles;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();

		this.removeSubscriptions();
	}

	public onScroll(event: any): void {
		this.scrollService.changeScrollY(event.target.scrollTop);
	}

	public exitImpersonation(): void {
		const oldToken = localStorage.getItem(StorageKey.oldToken);
		const oldDecodedToken = localStorage.getItem(StorageKey.oldDecodedJwtIo);

		this.userStore.pipe(select(getUserDetails), take(1)).subscribe(userDetails => {
			this.userDetails = userDetails;
		});
		this.router.navigate(['/back-office']);

		localStorage.setItem(StorageKey.token, oldToken);
		localStorage.setItem(StorageKey.decodedJwtIo, JSON.stringify(oldDecodedToken));

		this.userDetails.IsImpersonated = false;
		this.userStore.dispatch(new SetUserDetails(this.userDetails));

		this.resetPermissions();
	}

	public impersonationHandle(): void {
		this.userStore.pipe(select(getUserDetails), takeUntil(this.unsubscriber$)).subscribe(details => {
			const googleBO = JSON.parse(localStorage.getItem('installedPlatorm'))?.find(value => {
				return value === 'Google';
			});
			this.isBO = details?.FacebookBusinessOwnerId !== '' && details?.FacebookBusinessOwnerId ? true : false;
			this.userStatus = details?.RejectedReason;
			this.resetPermissions();
		});
		this.userStore.pipe(select(isUserImpersonated), takeUntil(this.unsubscriber$)).subscribe(isImpersonated => {
			this.isImpersonated = isImpersonated;
			this.dropdownIsVisible = true;
			this.resetPermissions();
		});
		this.userStore.pipe(select(hasBackOfficePermission), take(1)).subscribe(isAdmin => {
			this.isAdmin = isAdmin;
			this.dropdownIsVisible = !isAdmin;
		});
	}

	public checkUserManagementState(): void {
		this.store.pipe(select(getActiveUserManagementPage), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.showUserManagement = resp;
		});
	}

	public openKnowledgeBase(): void {
		this.dialog.open(KnowledgebaseComponent, {
			width: '600px',
			height: '100%',
			position: {
				right: '0'
			},
			panelClass: ['matdialog-no-padding', 'slide', 'slideInRight']
		});
	}

	public goToUserPage(): void {
		this.profileOpen = false;
		this.router.navigate(['/user-management/my-profile']);
	}

	public goToBilling(): void {
		this.profileOpen = false;
		this.router.navigate(['/user-management/payment-and-billing']);
	}

	public logout(): void {
		this.profileOpen = false;
		this.authenticationService.expandMenu$.next(false);
		this.authenticationService.logout();
	}

	public isUserAdmin(): void {
		this.userStore.pipe(select(hasBackOfficePermission), takeUntil(this.unsubscriber$)).subscribe(isAdmin => {
			this.isAdmin = isAdmin;
			// if (!this.isAdmin) {
			// 	this.pollingPublish();
			// }
		});
	}

	public addHoverBar(): void {
		setTimeout(() => {
			this.pcHover = true;
		}, 100);
	}

	public removeHoverBar(): void {
		setTimeout(() => {
			this.pcHover = false;
		}, 100);
	}
	public addSMIHoverBar(): void {
		setTimeout(() => {
			this.smiHover = true;
		}, 100);
	}

	public removeSMIHoverBar(): void {
		setTimeout(() => {
			this.smiHover = false;
		}, 100);
	}

	private removeSubscriptions(): void {
		this.expandAccountMenuSubscription.unsubscribe();
	}

	// private initializeSpinner(): void {
	// 	this.sharedStore
	// 		.select(getGlobalSpinnerStatus)
	// 		.pipe(takeUntil(this.unsubscriber$))
	// 		.subscribe(status => (this.showSpinner = status));
	// }

	private isUnpaidInvoice(): void {
		this.userStore.pipe(select(getAccountState), takeUntil(this.unsubscriber$)).subscribe(accountState => {
			this.accountState = accountState;

			if (this.accountState === 7 && !this.isAdmin) {
				this.unpaidInvoice = true;
			}
		});
	}

	private resetPermissions(): void {
		this.backOfficePermission = {
			moduleName: Modules.BackOffice,
			permissions: [BackofficePermissions.CanAccessBackOffice]
		};
		this.accountsPermission = {
			moduleName: Modules.Accounts,
			permissions: [AccountsPermissions.CanAccessAccounts]
		};
		this.adsManagerPermission = {
			moduleName: Modules.AdsManager,
			permissions: [AdsManagerPermissions.CanAccessAdsManager]
		};
		this.campaignBuilderPermission = {
			moduleName: Modules.CampaignBuilder,
			permissions: [CampaignBuilderPermissions.CanAccessCampaignBuilder]
		};
		this.optimizePermission = {
			moduleName: Modules.Optimize,
			permissions: [OptimizePermissions.CanAccessOptimize]
		};
		this.pixelPermission = {
			moduleName: Modules.Pixel,
			permissions: [PixelPermissions.CanAccessPixels]
		};
		this.audiencePermission = {
			moduleName: Modules.Audience,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.reportsPermission = {
			moduleName: Modules.Reports,
			permissions: [ReportsPermissions.CanAccessReports]
		};
		this.influencerPermission = {
			moduleName: Modules.Influencer,
			permissions: [InfluencerPermissions.FullAccess]
		};
	}

	public accountsClickedNoBO(route: string): void {
		this.selectedRoute = route;
		this.authenticationService.setRoute$.next(route);
		if (route === 'influencer') {
			this.router.navigate(['influencer']);
		}
		return;
	}
}
