<app-expansion-panel
	[expansionTitle]="'Placements'"
	[isExpandable]="false"
	[isSelected]="isSelected"
	[contentMargin]="true"
	[infoHoverModel]="infoHoverModel"
	[subTitle]="'Would you like to split your ad sets by placements?'"
	(click)="onExpansionClick.emit()"
>
	<div expansion-content>
		<mat-slide-toggle (change)="onOptimizationPlacementsToggle()" [checked]="isOptimizationPlacementsToggle"></mat-slide-toggle>
		<div *ngIf="isOptimizationPlacementsToggle">
			<ng5-slider [(value)]="numberOfPlacements" [options]="options"></ng5-slider>
		</div>
	</div>
</app-expansion-panel>
