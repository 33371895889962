import { Component, Inject, Input, OnInit } from '@angular/core';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopUpInterface } from '../../models/pop-up-interface';

@Component({
	selector: 'app-generic-pop-up',
	templateUrl: './generic-pop-up.component.html',
	styleUrls: ['./generic-pop-up.component.scss']
})
export class GenericPopUpComponent implements OnInit {
	@Input() panelClass: string;
	public message: string;
	public actionAnswer: string;
	public noActionAnswer: string;
	public header: string;
	public image: string;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(@Inject(MAT_DIALOG_DATA) public data: PopUpInterface, public dialogRef: MatDialogRef<GenericPopUpComponent>) {
		this.image = data.image;
		this.header = data.header;
		this.message = data.message;
		this.actionAnswer = data.actionAnswer;
		this.noActionAnswer = data.noActionAnswer;
	}

	ngOnInit(): void {}

	public actionEvent(): void {
		this.dialogRef.close(true);
	}

	public onNoClick(): void {
		this.dialogRef.close();
	}
}
