import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionCardInterface } from 'src/app/google-campaign-builder/model/selection-card.interface';

@Component({
	selector: 'app-selection-card',
	templateUrl: './selection-card.component.html',
	styleUrls: ['./selection-card.component.scss']
})
export class SelectionCardComponent implements OnInit {
	@Input() public header: string;
	@Input() public subHeader: string;
	@Input() public headerInfo: string;
	@Input() public readMoreLink: string;
	@Input() public readMoreText = 'Learn More';
	@Input() public selectionCards: SelectionCardInterface[] = [];
	@Output() public onSelect: EventEmitter<{ type: string; data: SelectionCardInterface }> = new EventEmitter();
	@Output() public cardMouseOverEvent: EventEmitter<SelectionCardInterface> = new EventEmitter();

	public selected: SelectionCardInterface;
	constructor() {}

	public ngOnInit(): void {}

	public activateCard(selection: SelectionCardInterface): void {
		if (selection.disabled || selection === this.selected) {
			return;
		}
		this.selected = selection;
		this.onSelect.emit({ type: 'right', data: selection });
	}

	public getIcon(icon: string): string {
		return icon ?? 'assets/google-cb/dexter-icon.png';
	}

	public triggerAssistMouseOver(selection: SelectionCardInterface): void {
		this.cardMouseOverEvent.emit(selection);
	}
}
