import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnChanges {
	public isPanelSelected: boolean = false;
	public isAllDeviceSelected: boolean = true;
	public isMobileSelected: boolean = false;
	public isDesktopSelected: boolean = false;
	public DeviceEnum = DeviceEnum;
	public DeviceOSEnum = DeviceOSEnum;
	public Devices = Devices;
	public DeviceOs = DeviceOS;
	@Input() selectedDevice: DeviceEnum[] = this.Devices.all;
	@Input() selectedDeviceOs: DeviceOSEnum[] = this.DeviceOs.all;
	@Output() onDeviceOs = new EventEmitter<DeviceOSEnum[]>();
	@Output() onDevice = new EventEmitter<string[]>();
	public dropDownListItems = [
		{ name: 'All mobile devices', id: DeviceOSEnum.all },
		{ name: 'Android devices only', id: DeviceOSEnum.android },
		{ name: 'IOS devices only', id: DeviceOSEnum.ios }
	];
	public dropDownControl: FormControl = new FormControl(DeviceOSEnum.all, [Validators.required]);

	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.selectedDeviceOs) {
			this.dropDownControl.patchValue(this.getDropDownValue(this.selectedDeviceOs), { emitEvent: false });
		}
	}

	public onExpansionClick(): void {}

	public dropDownValueChanges(event: DeviceOSEnum): void {
		switch (event) {
			case DeviceOSEnum.all:
				this.selectedDeviceOs = this.DeviceOs.all;
				break;
			case DeviceOSEnum.android:
				this.selectedDeviceOs = this.DeviceOs.android;
				break;
			case DeviceOSEnum.ios:
				this.selectedDeviceOs = this.DeviceOs.ios;
				break;
			default:
				this.selectedDeviceOs = this.DeviceOs.all;
		}
		this.onDeviceOs.emit(this.selectedDeviceOs);
	}

	public isSelected(device: DeviceEnum): boolean {
		return this.selectedDevice.includes(device) && this.selectedDevice.length === 1;
	}

	public selectDevice(device: DeviceEnum): void {
		switch (device) {
			case DeviceEnum.all:
				this.selectedDevice = this.Devices.all;
				break;
			case 'mobile':
				this.selectedDevice = this.Devices.mobile;
				break;
			case 'desktop':
				this.selectedDevice = this.Devices.desktop;
				this.onDeviceOs.emit(this.DeviceOs.all);
				break;
			default:
				this.selectedDevice = this.Devices.all;
		}
		this.onDevice.emit(this.selectedDevice);
	}

	public getDropDownValue(value: DeviceOSEnum[]): DeviceOSEnum {
		if (value.length > 1) {
			return DeviceOSEnum.all;
		} else if (value.includes(DeviceOSEnum.android)) {
			return DeviceOSEnum.android;
		} else if (value.includes(DeviceOSEnum.ios)) {
			return DeviceOSEnum.ios;
		}
	}
}

export interface IDeviceOs {
	name: string;
	id: number;
}
export enum DeviceEnum {
	all = 'all',
	mobile = 'mobile',
	desktop = 'desktop'
}
export enum DeviceOSEnum {
	all = 'all',
	android = 'Android',
	ios = 'iOS'
}
export const DeviceOS: { [key: string]: DeviceOSEnum[] } = {
	all: [DeviceOSEnum.android, DeviceOSEnum.ios],
	android: [DeviceOSEnum.android],
	ios: [DeviceOSEnum.ios]
};
export const Devices: { [key: string]: DeviceEnum[] } = {
	all: [DeviceEnum.mobile, DeviceEnum.desktop],
	mobile: [DeviceEnum.mobile],
	desktop: [DeviceEnum.desktop]
};
