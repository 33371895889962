import { Injectable } from '@angular/core';

import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';

@Injectable({
	providedIn: 'root'
})
export class TableChartService extends ChartService {
	public chartData: any[] = [];

	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(report: ReportModel): any {
		this.chartData = [];

		if (report.details.simpleDataSource) {
			report.details.chartData = this.createSimpleChartExcelData(report);
		} else {
			if (report.details.chartData && report.details.chartData.length > 0) {
				report.details.chartData = this.mapComplexDataForExcel(report);
			}
		}

		return report.details.chartData;
	}
}
