import { Observable } from 'rxjs';
import { PixelDto } from '../../_models/PixelModels/pixel-dto';
import { BaseApiUrl } from '../base-api-urls';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateRange } from '../../shared/calendar/calendar.models';
import { PixelDataTable } from '../../_models/PixelModels/pixel-data-table';
import { PixelDetails, PixelDetailsForAudiences } from '../../_models/PixelModels/pixel-details';
import { PixelInsightsCommand } from '../../pixel/_models/pixel-query.model';
import { PixelEventInsightDto } from '../../pixel/_models/pixel-event-insight-dto';
import { PixelBreakdown } from '../../pixel/_models/breakdowns.model';
import { PixelsDto } from '../../pixel/_models/pixels-dto.model';
import { map } from 'rxjs/operators';
import { PixelService } from './pixel.service';
import { EventDtoInterface } from '../../pixel/_models/event-dto.interface';
import { EventsHelper } from './events-helper';
import { AppEventsInterface } from '../../campaign-smart-create/models/app-events-interface';

@Injectable()
export class PixelServiceApi {
	// private readonly baseURL = `${BaseApiUrl.Pixel}pixels`;
	// private readonly basePythonURL = `${BaseApiUrl.PixelPython}`;

	constructor(protected http: HttpClient) {}

	// public getPixelsByAdAccounts(adAccountId: string): Observable<PixelDto> {
	// 	return this.http.get<PixelDto>(`${this.baseURL}?adAccFbIds=${adAccountId}`);
	// }

	// public getAppEvents(id: string): Observable<AppEventsInterface[]> {
	// 	return this.http.get<AppEventsInterface[]>(`${BaseApiUrl.FacebookPythonCampaignBuilder}smart-create/account-advertisable-apps/${id}`);
	// }

	// public getEventsByAdAccountIds(adAccountId: string): Observable<EventDtoInterface[]> {
	// 	return this.http.get<EventDtoInterface[]>(`${BaseApiUrl.Pixel}events?adAccId=${adAccountId}`).pipe(map(events => EventsHelper.mapEvents(events)));
	// }

	// public getEventsByPixelId(pixelId: string): Observable<EventDtoInterface[]> {
	// 	return this.http.get<EventDtoInterface[]>(`${BaseApiUrl.Pixel}pixels/${pixelId}/events/`).pipe(map(events => EventsHelper.mapEvents(events)));
	// }

	// public getPixelsByAdAccountsWithTimeRange(adAccountId: string, selectedDateRange: DateRange): Observable<PixelsDto[]> {
	// 	return this.http.get<PixelsDto[]>(`${this.baseURL}?adAccId=${adAccountId}`).pipe(map(pixels => PixelService.mapPixels(pixels)));
	// }

	// public getPixelEventsForAdAccount(id: number): Observable<PixelsDto[]> {
	// 	const endpoint = `${BaseApiUrl.FacebookAquaPythonCampaignBuilder}assets/pixels?ad_account_id=act_${id}`;
	// 	return this.http.get<PixelsDto[]>(endpoint);
	// }

	// public getBreakdownsStandardEvents(): Observable<PixelBreakdown[]> {
	// 	return this.http.get<PixelBreakdown[]>(`${this.basePythonURL}pixels/catalogs`);
	// }

	// public getBreakdownsCustomConversions(): Observable<PixelBreakdown[]> {
	// 	return this.http.get<PixelBreakdown[]>(`${this.basePythonURL}customconversions/catalogs`);
	// }

	// public createPixel(pixelDetails: PixelDetails): Observable<PixelDataTable> {
	// 	return this.http.post<PixelDataTable>(this.baseURL, pixelDetails);
	// }

	// public updatePixel(pixelId: string, Attributes: [{ Name: string; Value: any }]): Observable<any> {
	// 	return this.http.put(`${this.baseURL}/${pixelId}`, Attributes);
	// }

	// public getEventsPixelDetails(pixelId: string): Observable<PixelDetailsForAudiences> {
	// 	return this.http.get<PixelDetailsForAudiences>(`${this.baseURL}/${pixelId}/pixel-with-events`);
	// }

	// public getPixelEventInsight(query: PixelInsightsCommand): Observable<PixelEventInsightDto[]> {
	// 	return this.http.post<PixelEventInsightDto[]>(`${this.basePythonURL}${query.level}/insights`, query);
	// }

	// public getConversionEvents(pixelId: string): Observable<EventDtoInterface[]> {
	// 	return this.http.get<EventDtoInterface[]>(`${this.baseURL}/${pixelId}/events`);
	// }
}
