<app-expansion-panel-sc
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Ad Format'"
	[isExpandable]="false"
	[contentMargin]="false"
	[infoHoverModel]="info"
>
	<div expansion-content>
		<div *ngIf="objectiveTree?.subObjective !== subObjectives.CatalogSalesText">
			<mat-radio-group class="afc-radio-group">
				<mat-radio-button
					class="afc-radio-button"
					[value]="AdFormatsEnum.SingleImage"
					[checked]="selectedFormat !== AdFormatsEnum.PagePosts"
					(click)="onRadioButtonClick(AdFormatsEnum.SingleImage)"
				>
					<span>Create Ad</span>
				</mat-radio-button>
				<mat-radio-button
					class="afc-radio-button"
					[value]="AdFormatsEnum.PagePosts"
					[checked]="selectedFormat === AdFormatsEnum.PagePosts"
					[disabled]="objectiveTree?.subObjective === subObjectives.PageLikes"
					(click)="onRadioButtonClick(AdFormatsEnum.PagePosts)"
				>
					<span>Use existing post</span>
				</mat-radio-button>
			</mat-radio-group>
			<h6 *ngIf="!(selectedFormat === AdFormatsEnum.PagePosts || this.objectiveTree?.subObjective === this.subObjectives?.VideoViews)">
				Please choose an ad format
			</h6>
		</div>
		<div class="afc-container" *ngIf="selectedFormat !== AdFormatsEnum.PagePosts && objectiveTree?.subObjective !== subObjectives.CatalogSalesText">
			<app-card-box
				class="afc-images"
				*ngFor="let format of filteredFormats"
				(click)="onAdFormatClick(format)"
				[isSelected]="selectedFormat === format.key"
			>
				<div card-content appHoverSelect class="afc-image-box" [statefulImage]="format" [statefulImages]="filteredFormats">
					<img src="assets/icons/ad-format/{{ format.imageUrl }}-{{ format.imageState }}.svg" alt="{{ format.name }}" />
					<span>{{ format.name }}</span>
				</div>
			</app-card-box>
		</div>
		<div *ngIf="objectiveTree?.subObjective === subObjectives.CatalogSalesText" class="creative-builder-selection">
			<div>
				<font07>Select Creative</font07>
				<drp01
					(valueChanges)="creativeValueChange($event)"
					[data]="creatives"
					[control]="creativeControl"
					[valuePrimitive]="true"
					[placeholder]="'Select creative'"
				></drp01>
			</div>
			<div class="k-mt-1" *ngIf="display">
				<btn04 [disabled]="true">Edit Creatives</btn04>
				<info-tooltip [style.width]="'16px'" [title]="'Edit your media using creative builder'"></info-tooltip>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
