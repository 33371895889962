import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-warning-animation-message',
	templateUrl: './warning-animation-message.component.html',
	styleUrls: ['./warning-animation-message.component.scss']
})
export class WarningAnimationMessageComponent {
	@Input() titleWarningMessage: string;
	@Input() contentWarningMessage: string;

	constructor() {}

	ngOnInit(): void {}
}
