import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CustomFieldCellRendererComponentParamsInterface } from './custom-field-cell-renderer-component-params.interface';

@Component({
	selector: 'app-custom-field-cell-renderer',
	templateUrl: './custom-field-cell-renderer.component.html',
	styleUrls: ['./custom-field-cell-renderer.component.scss']
})
export class CustomFieldCellRendererComponent implements ICellRendererAngularComp {
	public value: string;
	public isRowPinned: boolean;
	public currency: string;
	public isPercentage: boolean;
	private numberOfDecimals: number;
	constructor() {}

	public agInit(params: CustomFieldCellRendererComponentParamsInterface): void {
		this.numberOfDecimals = params.numberOfDecimals;
		this.value = params.value;
		this.isPercentage = params.isPercentage;
		this.currency = params.currency;
		if (params.node.isRowPinned()) {
			this.isRowPinned = true;
		}
		this.onInitialization();
	}

	public refresh(params: CustomFieldCellRendererComponentParamsInterface): boolean {
		return false;
	}

	public onInitialization(): void {
		this.roundValue();
		this.thousandsSeparator();
	}

	protected roundValue(): void {
		if (this.numberOfDecimals && this.value) {
			this.value = parseFloat(this.value).toFixed(this.numberOfDecimals);
		}
	}

	protected thousandsSeparator(): void {
		if (this.value) {
			if (Number.isInteger(parseFloat(this.value))) {
				this.value = parseFloat(this.value).toLocaleString().replace('.', ',');
			} else {
				const [integer, decimals] = this.value.toLocaleString().split('.');
				this.value = `${parseInt(integer).toLocaleString().replace('.', ',')}.${decimals}`;
			}
		}
	}
}
