<!-- notification input type number CVC -->
<div class="input-wrapper">
  <div class="input-cvc-wrapper">
    <mat-form-field [ngClass]="{'error-color-message': control.invalid && control.touched,
                                'success-color-message': control.valid && control.touched,
                                'disabled-color-message': control.disabled}"
                    appearance="outline">

      <mat-label>{{label}}</mat-label>

      <input (focus)="autocompleteFocus()"
             (focusout)="setErrorMessage()"
             [formControl]="control"
             ngDefaultControl
             autocomplete="off"
             matInput
             maxlength="3"
             appInputNumberValue="0"
             placeholder="{{placeholder}}"
             type="text">

      <!--Error message -->
      <mat-error *ngIf="control.invalid">{{errorMessage}}</mat-error>
    </mat-form-field>
  </div>
</div>
