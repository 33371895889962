<div class="input-wrapper">
<div class="emails-section">
  <mat-form-field [ngClass]="{'email-error-message': (control.invalid || notEmail) && control.touched,
                            'email-success-message': control.valid && !notEmail && control.touched,
                            'email-disabled-message': control.disabled}"
                  appearance="outline">
    <div class="scrollable-wrapper">
    <mat-chip-list #chipList>
      <mat-chip
          (removed)="remove(email)"
          *ngFor="let email of emails"
          [removable]="removable"
          [selectable]="selectable">
        {{email.name}}
        <mat-icon *ngIf="removable" matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input (matChipInputTokenEnd)="add($event)"
             [matChipInputAddOnBlur]="addOnBlur"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
             placeholder="{{placeholder}}">

    </mat-chip-list>
    </div>
  </mat-form-field>
  <mat-error class="multiple-error-message" *ngIf="control.invalid && control.touched && control.getError('required')">You need to input at least one email
  </mat-error>
  <mat-error class="multiple-error-message" *ngIf="notEmail">Invalid email format</mat-error>
</div>
</div>
