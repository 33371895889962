import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Input, Output, OnInit, forwardRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FileUploadComponent),
			multi: true
		}
	]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
	@Input() requiredFileType: string;
	public fileName = '';
	public base64FileName = '';
	public uploadProgress: number;
	public uploadSub: Subscription;
	public maxSize: number = 1024;

	@Output() getUploadedFilename: EventEmitter<string> = new EventEmitter();

	public consent = new FormControl();
	disabled = false;

	value = '';
	checked: false;

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor(private http: HttpClient) {}

	ngOnInit(): void {}

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	onFileSelected(event: any): void {
		const file: File = event.target.files[0];
		this.fileName = file.name;
		let errorDialog: boolean = false;
		let errorText: string = '';
		if (file) {
			let size = file.size / this.maxSize / this.maxSize;
			if (!file.type.match('image.*')) {
				// check whether the upload is an image
				errorDialog = true;
				errorText = 'Please choose an image file';
			} else if (size > 1) {
				// check whether the size is greater than the size limit
				errorDialog = true;
				errorText = 'Your file is too big! Please select an image under 1MB';
			} else {
				// Append file into FormData and turn file into image URL
				let imageURL = URL.createObjectURL(file);
				var toBase64 = this.toBase64(file);
				toBase64.then(base64 => {
					this.base64FileName = base64;
				});

				const formData = new FormData();
				formData.append('filename', file);
				formData.append('base64', this.base64FileName);

				const upload$ = this.http
					.post('/api/thumbnail-upload', formData, {
						reportProgress: true,
						observe: 'events'
					})
					.pipe(
						finalize(() => {
							this.reset();
							this.getUploadedFilename.emit(this.fileName);
						})
					);

				this.uploadSub = upload$.subscribe((events: any) => {
					if (events.type == HttpEventType.UploadProgress) {
						this.uploadProgress = Math.round(100 * (events.loaded / events.total));
					}
				});
			}
		}
	}
	public toBase64(file: any): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader: any = new FileReader();
			reader.readAsDataURL(file);
			return (reader.onload = () => resolve(reader.result));
			return (reader.onerror = (error: any) => reject(error));
		});
	}
	cancelUpload() {
		this.uploadSub.unsubscribe();
		this.reset();
	}

	reset() {
		this.uploadProgress = null;
		this.uploadSub = null;
	}
}
