<div class="grid-section">
	<div class="form-area">
		<img src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div class="title">For the best experiences!</div>
		<div class="sub-title">Lolly experiences its optimise best for desktop or tablet. Please login into the app through a desktop Platform.</div>
		<div class="btn">
			<button (click)="navigateToHome()">Back to homepage</button>
		</div>
	</div>
</div>
