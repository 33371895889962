import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SetPassword } from '../../_models/identity-models/set-password';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { select, Store } from '@ngrx/store';
import { AuthenticationState, getRedirectUrl } from '../state/authentication.reducer';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { PasswordComponentEnum } from './set-password.component.enum';
import { LogInModel } from '../_models/login.model';
import { LogIn } from '../state/authentication.action';
import { getUserDetails } from 'src/app/shared/state/user/user.reducer';

@Component({
	selector: 'app-set-password',
	templateUrl: './set-password.component.html',
	styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
	public form: FormGroup;
	public emailFormControl: FormControl;
	public passwordFormControl: FormControl;
	public pageType: PasswordComponentEnum.Password | PasswordComponentEnum.ResetPassword;

	private token: string;
	private emailValue: string;
	private isForgotPasswordUrl: boolean;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading = false;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userServiceApi: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		private authStore: Store<AuthenticationState>
	) {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.token = encodeURIComponent(params['token']);
			this.emailValue = decodeURIComponent(params['email']);
		});
	}

	public ngOnInit(): void {
		this.emailFormControl = new FormControl(this.emailValue);
		this.passwordFormControl = new FormControl();
		this.isForgotPasswordUrl = this.activatedRoute.snapshot.routeConfig.path?.includes('forgot-password');
		this.pageType = this.isForgotPasswordUrl ? PasswordComponentEnum.ResetPassword : PasswordComponentEnum.Password;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onSubmit(): void {
		if (this.passwordFormControl.invalid) {
			return;
		}

		const newPassword: SetPassword = {
			EmailAddress: this.emailFormControl.value.trim(),
			NewPassword: this.passwordFormControl.value.trim(),
			ConfirmPassword: this.passwordFormControl.value.trim(),
			TwoFactorToken: this.token
		};

		const request$ = this.isForgotPasswordUrl ? this.userServiceApi.forgotPassword(newPassword) : this.userServiceApi.setPassword(newPassword);
		request$.pipe(takeUntil(this.unsubscriber$)).subscribe(
			() => {
				if (this.isForgotPasswordUrl) {
					this.router.navigate(['/authentication']);
				} else {
					//** UPDATING TO ADD REFRESH TOKEN FIX  */
					// const temporaryCredentials = {
					// 	emailAddress: this.emailFormControl.value.trim(),
					// 	password: this.passwordFormControl.value.trim()
					// };
					// localStorage.setItem('details', JSON.stringify(temporaryCredentials));
					// this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
					// 	this.router.navigate(['/authentication'], { queryParams: { [redirectUrl]: true } });
					// });
					// this.authStore
					// .select(getUserDetails)
					// .pipe(
					// 	takeUntil(this.unsubscriber$),
					// 	catchError(error => {
					// 		this.toastNotificationService.sendErrorToast('There was an error verifying your user credentials. Please contact support.');
					// 		this.loading = false;
					// 		return EMPTY;
					// 	})
					// )
					// .subscribe(user => {
					// 	if (user) {
					// 		this.loading = false;
					// 	}
					// });
					const loginPayload: LogInModel = {
						email: this.emailFormControl.value.trim(),
						password: this.passwordFormControl.value.trim()
					};
					this.authStore.dispatch(new LogIn(loginPayload));
				}
			},
			error => {
				this.toastNotificationService.sendErrorToast('There was an error while creating your password.');
			}
		);
	}
}
