import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	validationEmailMessages,
	validationNameMessages,
	validationPhoneMessages,
	validationRoleMessages,
	validationSurnameMessages
} from 'src/app/back-office/users/user-generic/user-validation-messages';
import { BusinessModel } from '../../../accounts/models/business.model';
import { ActionTypeEnum } from '../../../back-office/users/action-type.enum';
import { Helper } from '../../../reports/helper';
import { GenericPopupComponent } from '../../../shared/pop-ups/generic-popup/generic-popup.component';
import { ShowGlobalSpinner } from '../../../shared/state/shared.actions';
import { SharedState } from '../../../shared/state/shared.reducer';
import { UserState } from '../../../shared/state/user/user.reducer';
import { UserServiceApi } from '../../../_services/user/user.api.service';
import { changesNotSavedEnum } from '../../models/changes-not-saved.enum';
import { NewUserModel } from '../../models/new-user-model';
import { PermissionsUserEnum, userPermission } from '../../models/permissions-user.enum';
import { UserManagementPopupEditInterface } from '../../models/user-management-popup-edit.interface';
import { UserManagementPopupInterface } from '../../models/user-management-popup.interface';
import { FrontEndModule } from '../../models/user-permissions-table.model';
import { UserManagementActionButtonModel } from '../../models/user-management-action-button.model';
import { StepsEnum } from 'src/app-components/interfaces/stepper.enum';
import { SidePopUpHelper } from 'src/app/shared/side-pop-ups/side-pop-ups.helper';

@Component({
	selector: 'app-create-new-user',
	templateUrl: './create-new-user.component.html',
	styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit {
	public secondStepChanges = new Subject<boolean>();
	public user = new NewUserModel();
	public initialUser = new NewUserModel();
	public defaultPermissions: userPermission[] = [
		{
			frontEndModule: FrontEndModule.Accounts,
			accessType: PermissionsUserEnum.View
		},
		{
			frontEndModule: FrontEndModule.AdsManager,
			accessType: PermissionsUserEnum.Full
		},
		{
			frontEndModule: FrontEndModule.CampaignBuilder,
			accessType: PermissionsUserEnum.Full
		},
		{
			frontEndModule: FrontEndModule.Audience,
			accessType: PermissionsUserEnum.Full
		},
		{
			frontEndModule: FrontEndModule.Reports,
			accessType: PermissionsUserEnum.Full
		},
		{
			frontEndModule: FrontEndModule.Optimize,
			accessType: PermissionsUserEnum.Full
		},
		{
			frontEndModule: FrontEndModule.Pixel,
			accessType: PermissionsUserEnum.View
		}
	];
	public readonly steps = [
		{ label: 'User Details', icon: 'user', disabled: false },
		{ label: 'Account Access', iconClass: 'fas fa-key', disabled: true },
		{ label: 'Permissions', icon: 'lock', disabled: true }
	];
	public current = 0;
	public showPopup = true;
	public nextButtonState = false;
	public firstStep = false;
	public secondStep = true;
	public thirdStep = true;
	public currentStep = 0;
	public backButtonDisable = true;
	public titlePopup: string;
	public userWasChanged: boolean;
	public userPermissions: userPermission[] | userPermission;
	public userFromControl: FormGroup;
	public labelButtonLeftSide: string;
	public labelButtonRightSide: string;
	public edit: boolean;

	public validationNameMessages = validationNameMessages;
	public validationSurnameMessages = validationSurnameMessages;
	public validationEmailMessages = validationEmailMessages;
	public validationPhoneMessages = validationPhoneMessages;
	public validationRoleMessages = validationRoleMessages;

	private unsubscriber$ = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<CreateNewUserComponent>,
		public changesPopUpComponent: MatDialogRef<GenericPopupComponent>,
		public changesPopUp: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: UserManagementPopupEditInterface,
		public userServiceApi: UserServiceApi,
		private userStore: Store<UserState>,
		private formBuilder: FormBuilder,
		private store: Store<SharedState>
	) {
		this.titlePopup = data.titlePopup;
		this.labelButtonLeftSide = data.labelButtonLeftSide;
		this.labelButtonRightSide = data.labelButtonRightSide;
		this.edit = data.edit;
		if (data.edit) {
			(this.user.firstName = data.firstName), (this.user.lastName = data.lastName);
			(this.user.phoneNumber = data.phoneNumber), (this.user.position = data.position), (this.user.email = data.email), (this.user.id = data.id);
		}
	}

	public ngOnInit(): void {
		this.createUserForm();
		this.getBOPermissions();
	}

	public secondStepData(): void {
		this.secondStepChanges.next(true);
	}

	public getBOPermissions(): void {
		if (this.edit) {
			this.userServiceApi
				.getUserById(this.user.id, false)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					this.userPermissions = res.moduleAccessTypes;
				});
		} else {
			this.userPermissions = this.defaultPermissions;
		}
	}

	public changesListener(): void {
		this.getUserFromForm();
		const diff1 = Helper.objectDiffs(this.user, this.initialUser);

		const changes = !!Object.keys(diff1)?.length;

		if (changes) {
			this.userWasChanged = true;
		} else {
			this.userWasChanged = false;
		}
	}

	public onNoClick(): void {
		this.changesListener();
		if (this.userWasChanged) {
			this.changesPopUp
				.open<GenericPopupComponent, UserManagementPopupInterface>(GenericPopupComponent, {
					data: {
						image: changesNotSavedEnum.Image,
						header: changesNotSavedEnum.Header,
						message: changesNotSavedEnum.Message,
						actionAnswer: ActionTypeEnum.ContinueEditing,
						noActionAnswer: ActionTypeEnum.DoNotSave
					},
					disableClose: true,
					width: '485px',
					panelClass: ['generic-popup']
				})
				.afterClosed()
				.subscribe(res => {
					if (!res) {
						SidePopUpHelper.closeSIdePopUpDialog(() => this.changesPopUpComponent.close(false));
					}
				});
		} else {
			SidePopUpHelper.closeSIdePopUpDialog(() => this.changesPopUpComponent.close(false));
		}
	}

	public isDoneLoading(): void {
		this.getUserFromForm();
		this.initialUser = _.cloneDeep(this.user);
	}

	public clickAction(): void {
		this.clickButton(true);
	}

	public backButton(): void {
		this.currentStep--;
		switch (this.currentStep) {
			case StepsEnum.NoBackStep:
				this.onNoClick();
				break;
			case StepsEnum.Step1:
				this.backButtonDisable = true;
				this.firstStep = false;
				this.secondStep = true;
				this.thirdStep = true;
				break;
			case StepsEnum.Step2:
				this.firstStep = true;
				this.secondStep = false;
				this.thirdStep = true;
				this.labelButtonRightSide = 'NEXT';
				break;
		}
	}

	public FormIsValid($event: boolean): void {
		this.nextButtonState = $event;
		if (this.nextButtonState) {
			if (this.currentStep === StepsEnum.Step1) {
				let nextStep = this.steps.find(x => x.label === 'Account Access');
				nextStep.disabled = false;
			}
			if (this.currentStep === StepsEnum.Step2) {
				let nextStep = this.steps.find(x => x.label === 'Permissions');
				nextStep.disabled = false;
			}
		} else {
			if (this.currentStep === StepsEnum.Step2) {
				let nextStep = this.steps.find(x => x.label === 'Permissions');
				nextStep.disabled = true;
			}
		}
	}

	public clickButton($event: boolean): void {
		this.currentStep++;
		if ($event) {
			switch (this.currentStep) {
				case StepsEnum.Step2:
					this.getUserFromForm();
					this.firstStep = true;
					this.secondStep = false;
					this.thirdStep = true;
					this.nextButtonState = this.edit ? true : false;
					this.secondStepData();
					this.backButtonDisable = false;
					break;
				case StepsEnum.Step3:
					this.firstStep = true;
					this.secondStep = true;
					this.thirdStep = false;
					this.labelButtonRightSide = this.edit ? 'UPDATE' : 'CREATE';
					this.nextButtonState = true;
					break;
				case StepsEnum.Step4:
					SidePopUpHelper.closeSIdePopUpDialog(() => this.dialogRef.close(this.user));
					this.store.dispatch(new ShowGlobalSpinner());
					break;
			}
		}
	}

	public getPermissions($event: userPermission): void {
		this.user.ModuleAccessTypes = $event;
	}

	public getAdAccounts($event: BusinessModel[]): void {
		this.user.FacebookBusinessPermissions = $event;
	}

	public stepChange(step: StepsEnum): void {
		this.currentStep = step;
		switch (step) {
			case StepsEnum.Step1:
				this.firstStep = false;
				this.secondStep = true;
				this.thirdStep = true;
				this.labelButtonRightSide = UserManagementActionButtonModel.next;
				this.backButtonDisable = true;
				break;
			case StepsEnum.Step2:
				this.getUserFromForm();
				this.firstStep = true;
				this.secondStep = false;
				this.thirdStep = true;
				this.nextButtonState = this.edit ? true : false;
				this.secondStepData();
				this.labelButtonRightSide = UserManagementActionButtonModel.next;
				this.backButtonDisable = false;
				break;

			case StepsEnum.Step3:
				this.firstStep = true;
				this.secondStep = true;
				this.thirdStep = false;
				this.labelButtonRightSide = this.edit ? UserManagementActionButtonModel.update : UserManagementActionButtonModel.create;
				this.nextButtonState = true;
				break;
		}
	}

	private createUserForm(): void {
		this.userFromControl = this.formBuilder.group({
			nameFormControl: new FormControl(null, [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
			surnameFormControl: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
			emailFormControl: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(70)]),
			phoneFormControl: new FormControl(null, [Validators.pattern('^((?:[0-9]\\-?){6,14}[0-9])|((?:[0-9]\x20?){6,14}[0-9])$'), Validators.maxLength(15)]),
			positionFormControl: new FormControl(null, [Validators.maxLength(50)])
		});
	}

	private getUserFromForm(): void {
		this.user.firstName = this.userFromControl.controls['nameFormControl'].value;
		this.user.lastName = this.userFromControl.controls['surnameFormControl'].value;
		this.user.email = this.userFromControl.controls['emailFormControl'].value;
		if (this.userFromControl.controls['phoneFormControl'].value) {
			this.user.phoneNumber = this.userFromControl.controls['phoneFormControl'].value;
		}
		this.user.position = this.userFromControl.controls['positionFormControl'].value;
	}
}
