import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampaignSmartCreateState } from '../../state/smart-create.reducer';
import { UpdateBudgetAllocation, UpdateSplitByGender } from '../../state/smart-create.actions';
import { AbstractControl, Form, FormControl } from '@angular/forms';

@Component({
	selector: 'app-split-gender-card',
	templateUrl: './split-gender-card.component.html',
	styleUrls: ['./split-gender-card.component.scss']
})
export class SplitGenderCardComponent implements OnInit {
	@Input() public isSelected: boolean;
	@Input() public isSplitGenderToggled: boolean;
	@Input() public control: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();

	constructor(public campaignStore: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {}

	public onSplitGenderToggle(): void {
		this.isSplitGenderToggled = !this.isSplitGenderToggled;
	}
}
