import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from '../../shared/state/user/user.reducer';
import { take, takeUntil } from 'rxjs/operators';
import { EnableFirstTimeCustomer, LogIn } from '../state/authentication.action';
import { AuthenticationState, getRedirectUrl } from '../state/authentication.reducer';
import { TrialDialogComponent } from '../trial-dialog/trial-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BillingPlan } from '../../user-management/models/billing-plan.interface';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { getGlobalSpinnerStatus, SharedState } from '../../shared/state/shared.reducer';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-connect-to-source',
	templateUrl: './connect-to-source.component.html',
	styleUrls: ['./connect-to-source.component.scss']
})
export class ConnectToSourceComponent implements OnInit, OnDestroy {
	public userHasFacebookRights: boolean;
	public userHasGoogleRights: boolean;
	public billingPlan: BillingPlan;
	public userState: number;
	public hasPaymentCard = false;
	public showSpinner: boolean;

	private unsubscriber$ = new Subject<void>();

	public constructor(
		private ngZone: NgZone,
		private authenticationService: AuthenticationService,
		private userStore: Store<UserState>,
		private authStore: Store<AuthenticationState>,
		private dialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private store: Store<SharedState>,
		private translate: TranslateService,
		private toastNotificationService: ToastNotificationService
	) {}

	public ngOnInit(): void {
		this.initializeSpinner();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onConnectionSuccess(response: boolean): void {
		if (response) {
			this.ngZone.run(() => {
				this.authStore.dispatch(new EnableFirstTimeCustomer());

				this.backOfficeService
					.getBillingPlan()
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(billingPlan => {
						this.userStore.pipe(select(getFiledId), take(1)).subscribe(filedId => {
							this.billingPlan = billingPlan;
							this.backOfficeService
								.getUserState(filedId)
								.pipe(takeUntil(this.unsubscriber$))
								.subscribe(state => {
									this.userState = state;
									this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
										this.checkPaymentWallAndOpenTrialPopup(redirectUrl);
									});
								});
						});
					});
			});
		}
	}

	public checkPaymentWallAndOpenTrialPopup(redirectUrl: string): void {
		this.backOfficeService
			.getAllCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				cards => {
					this.hasPaymentCard = cards && cards.length > 0 ? true : false;
					if (this.billingPlan.isFreeTrial && this.userState === UserStateEnum.Active) {
						this.dialog
							.open<TrialDialogComponent>(TrialDialogComponent, {
								width: '40%',
								data: {
									days: this.billingPlan.freeTrialDuration,
									hasPaymentCard: this.hasPaymentCard
								}
							})
							.afterClosed()
							.subscribe(() => {
								//** UPDATING TO ADD REFRESH TOKEN FIX  */
								// this.authenticationService.logout({
								// 	queryParams: { [redirectUrl]: true }
								// });
								this.authenticationService.logout();
								// this.authStore.dispatch(new LogIn());
							});
					} else {
						//** UPDATING TO ADD REFRESH TOKEN FIX  */
						// this.authenticationService.logout({
						// 	queryParams: { [redirectUrl]: true }
						// });
						this.authenticationService.logout();
						// this.authStore.dispatch(new LogIn());
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}

	private initializeSpinner(): void {
		this.store
			.select(getGlobalSpinnerStatus)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(status => (this.showSpinner = status));
	}

	public logout(): void {
		this.authenticationService.logout();
	}
}
