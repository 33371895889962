import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LabelType, Options } from 'ng5-slider';
import { AudienceTargetingService } from '../../../../_services/audience/audience-targeting.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MapperService } from '../../../services/mapper.service';
import { debounceTime, distinctUntilChanged, map, skipWhile, switchMap, takeUntil } from 'rxjs/operators';
import { Gender } from './models/gender';
import { FacebookLocationsView } from './models/facebook-locations-view';
import { EMPTY, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { CampaignSmartCreateMapperService } from '../../../../campaign-smart-create/services/campaign-smart-create-mapper.service';
import { KeyName } from '../../../../_models/savedAudience';
import { FacebookInterestsView } from './models/facebook-interests-view';
import { TargetingAudience } from '../../../../campaign-smart-create/models/targeting-audience';
import { ButtonClassEnum } from '../../../button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../button-component/button-type.enum';
import { ParentTargetingTypeEnum, TargetingTypeEnum } from './models/targeting-type.enum';
import { AudienceService } from '../../../../_services/audience/audience.service';
import { AudienceData } from '../../../../audience/models/audience-data-table.model';
import { SelectedAdAccountModel } from '../../../state/state-entities/cached-ad-accounts.model';
import { Helper } from '../../../../reports/helper';
import { TargetingDropdownConfig } from './models/targeting-dropdown-config';
import { DropdownColumnInterface } from '../../live-search-dropdown/dropdown-columns.interface';
import { AudienceNamePopUpComponent } from './pop-up/audience-name-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { ButtonNamesEnum } from './models/button-names.enum';
import { SavedAudienceSizeReqInterface } from '../../../../campaign-smart-create/models/saved-audience-size-req.interface';
import { SpecsInterface } from './models/specs.interface';
import { GeoLocationEnum } from './models/geo-location.enum';
import { DropdownData } from '../../../dropdown-search-select/dropdown-data.interface';
import { select, Store } from '@ngrx/store';
import { getSelectedAdsets } from 'src/app/AAA/state';
import { AdSetsAAAInterface } from 'src/app/AAA/models/ad-sets-aaa.interface';
import { IReachEstimate, IReachReqEstimate } from 'src/app/campaign-smart-create/models/reach-estimate.interface';
import { IProgressCards } from '@filed-com/filed-lib/lib/cards/progress-card/progress-card.interface';
import { getAdSetOptimization } from 'src/app/AAA/state/adset-state';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { SubObjectives } from 'src/app/campaign-smart-create/models/sub-objectives';
import { getLocations, getLanguages } from 'src/app/campaign-smart-create/state/smart-create.reducer';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
import { ITargetingSpec } from '../../../../campaign-smart-create/models/targeting-spec.interface';

@Component({
	selector: 'app-targeting-card',
	templateUrl: './targeting-card.component.html',
	styleUrls: ['./targeting-card.component.scss']
})
export class TargetingCardComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public isPanelSelected: boolean;
	@Input() public selectedAdAccount: SelectedAdAccountModel;
	@Input() public regulatedInterests: DropdownData<FacebookInterestsView>[];
	@Input() public adCategory: string;
	@Input() public targeting: TargetingAudience;
	@Input() public isSpecialAdCategoryOn: boolean;
	@Input() public interests: DropdownData<FacebookInterestsView>[] = [];
	@Input() public savedAudiences: DropdownData<AudienceData>[] = [];
	@Input() public adAccountFacebookId: string;
	@Input() public optimizationGoal: string;
	@Input() public validationTrigger: boolean;
	@Input() public targetingControl: AbstractControl | FormControl;
	@Input() public reTargetingControl: AbstractControl | FormControl;
	@Input() public productSetId: string;
	@Input() public productCatalogSets: string;
	@Input() public selectedObjective: string;
	@Input() public budgetAmount: number = 0;
	@Input() public productSets: any;
	@Input() public isDexter = false;
	@Output() public onExpansionClick = new EventEmitter();
	@Output() public onUpdateTargeting = new EventEmitter();
	@Output() public audienceSave = new EventEmitter<TargetingAudience>();
	@Output() public audienceSize = new EventEmitter<number>();
	@Output() public modifyAudience = new EventEmitter<TargetingAudience>();
	@Output() public retargetingAudienceSpecs = new EventEmitter<any>();

	public initialized: boolean = false;
	public isSpecialAddCategorySelected = false;
	public catalogTargetingType: ParentTargetingTypeEnum = ParentTargetingTypeEnum.NewTarget;
	public isCatalogTargeting: boolean = true;
	public isAppInstall: boolean;
	public formGroup: FormGroup;
	public audienceControl: FormControl;
	public selectedSavedAudiences: DropdownData<AudienceData>[] = [];
	public customAudiences: DropdownData<AudienceData>[] = [];
	public selectedIncludeCustomAudiences: DropdownData<AudienceData>[] = [];
	public selectedExcludeCustomAudiences: DropdownData<AudienceData>[] = [];
	public selectedCustomAudiences: DropdownData<AudienceData>[] = [];
	public locations: DropdownData<FacebookLocationsView>[] = [];
	public selectedLocations: DropdownData<FacebookLocationsView>[] = [];
	public selectedInterests: DropdownData<FacebookInterestsView>[] = [];
	public excludedInterests: DropdownData<FacebookInterestsView>[] = [];
	public selectedExcludedInterest: DropdownData<FacebookInterestsView>[] = [];
	public narrowInterests: DropdownData<FacebookInterestsView>[] = [];
	public selectedNarrowedInterest: DropdownData<FacebookInterestsView>[] = [];
	public languages: DropdownData<KeyName>[] = [];
	public selectedLanguages: DropdownData<KeyName>[] = [];
	public showNarrowInterests = false;
	public showExcludeInterests = false;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public buttonNameEnum = ButtonNamesEnum;
	public targetingType = TargetingTypeEnum;
	public targetingMode = ParentTargetingTypeEnum;
	public genders = Gender;
	public gender: Gender = Gender.All;
	public maxAge = 65;
	public minAge = 13;
	public buttonText: string;
	public geoCountries: string[];
	public geoCities: SpecsInterface[];
	public geoSubCities: SpecsInterface[];
	public geoRegions: SpecsInterface[];
	public geoNeighborhoods: SpecsInterface[];
	public geoMediumAreas: SpecsInterface[];
	public countryGroups: string[];
	public geoMarkets: SpecsInterface[];
	public zips: SpecsInterface[];
	public electoralDistricts: SpecsInterface[];
	public reqIncludeCustomAudience: SpecsInterface[];
	public reqExcludeCustomAudience: SpecsInterface[];
	public reqExclusionInterests: SpecsInterface[];

	public defaultTargeting: TargetingAudience = {
		targeting_type: 0,
		type: TargetingTypeEnum.SavedAudience,
		gender: Gender.All,
		ageRange: {
			minAge: 18,
			maxAge: 55
		},
		locations: [],
		languages: [],
		interests: [],
		customAudiences: [],
		excludedInterests: [],
		narrowInterests: [],
		retargeting: null
	};

	public options: Options = {
		floor: 13,
		ceil: 65,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
				case LabelType.High:
					if (value === 65) {
						return value + '+';
					} else {
						return `${value}`;
					}
				case LabelType.Ceil:
					return value + '+';
				case LabelType.Low:
					return `${value}`;
				default:
					return `${value}`;
			}
		}
	};
	public locationColumns: DropdownColumnInterface[] = TargetingDropdownConfig.locationColumns;
	public languagesColumns: DropdownColumnInterface[] = TargetingDropdownConfig.languagesColumns;
	public interestColumns: DropdownColumnInterface[] = TargetingDropdownConfig.interestColumns;
	public audienceColumns: DropdownColumnInterface[] = TargetingDropdownConfig.audienceColumns;
	public customAudienceColumns: DropdownColumnInterface[] = TargetingDropdownConfig.customAudienceColumns;
	private unsubscriber$ = new Subject();
	private targetingService: AudienceTargetingService;
	private audienceService: AudienceService;
	private formBuilder: FormBuilder;
	private mapperService: MapperService;
	private smartCreateMapper: CampaignSmartCreateMapperService;
	private accountTypeService: AccountTypeService;
	private matDialog: MatDialog;
	private store: Store;
	public progressCardValues: IProgressCards = null;
	public subObjectives = SubObjectives;

	public targetInfoHover: InfoHoverDataModel;

	constructor(private injector: Injector) {
		this.targetingService = this.injector.get<AudienceTargetingService>(AudienceTargetingService);
		this.audienceService = this.injector.get<AudienceService>(AudienceService);
		this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
		this.mapperService = this.injector.get<MapperService>(MapperService);
		this.smartCreateMapper = this.injector.get<CampaignSmartCreateMapperService>(CampaignSmartCreateMapperService);
		this.accountTypeService = this.injector.get<AccountTypeService>(AccountTypeService);
		this.matDialog = this.injector.get<MatDialog>(MatDialog);
		this.store = this.injector.get<Store>(Store);
	}

	public ngOnInit(): void {
		this.initFormGroup();
		this.onGenderClick(this.genders.All);
		this.initLocationListeners();
		this.initStepDetailsListener();
		this.checkForSpecialAddCategory();
		this.getSelectedLocationsLanguage();
		// this.getAudiences();
		if (this.selectedObjective && this.selectedObjective === this.subObjectives.CatalogSalesText) {
			this.toggleCatalogTargeting(this.targetingMode?.ReTargeting, 'new');
		}
		if (this.selectedObjective && this.isDexter) {
			this.toggleCatalogTargeting(this.targetingMode?.NewTarget, 'new');
		}
		if (this.selectedObjective && this.selectedObjective === this.subObjectives.AppInstallText) {
			this.isAppInstall = true;
		}
		// this.targetingService.getLanguages().subscribe(languages => {
		// 	this.languages = this.mapperService.convertToDropdownData(languages, 'key', 'name');
		// });
		this.checkAudienceSize();
		this.initialized = true;

		this.targetInfoHover = {
			message:
				'When choosing a target audience for your ad set, you can either create a new audience or use a saved one. Facebook automatically tries to optimize ad delivery with any audience you create, but the targeting selections that you select may affect its size.)'
		};
	}

	public ngOnDestroy(): void {
		if (this.accountTypeService.dexterData$.value) {
			this.accountTypeService.dexterData$.next(null);
		}
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.validationTrigger) {
			this.validateLocation();
		}
		if (this.initialized) {
			this.targetingControl.patchValue(this.selectedLocations);
		}
		if (changes.targeting && this.formGroup) {
			this.initStepDetailsListener();
		}
		if (changes.selectedObjective && this.selectedObjective) {
			this.isCatalogTargeting = SubObjectives.CatalogSales.includes(this.selectedObjective);
		}
	}

	public isCatalogTargetingType(type: ParentTargetingTypeEnum): boolean {
		return this.catalogTargetingType === type;
	}

	public onGenderClick(value: Gender): void {
		if (!this.isSpecialAddCategorySelected) {
			this.gender = value;
			this.targeting.gender = this.gender;
			this.updateStepDetails();
		}
	}

	public audienceNamePopUp(action: ButtonNamesEnum): void {
		this.matDialog
			.open(AudienceNamePopUpComponent, { data: this.savedAudiences, autoFocus: false })
			.beforeClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					this.targeting.name = response.audienceName;
					this.checkAudienceSize(action);
				}
			});
	}

	public formatFields(): void {
		this.reqIncludeCustomAudience = this.getSpecsData(this.selectedIncludeCustomAudiences);
		this.reqExcludeCustomAudience = this.getSpecsData(this.selectedExcludeCustomAudiences);
		this.reqExclusionInterests = [];
		this.selectedExcludedInterest.forEach(item => {
			const interest = { id: item.value };
			this.reqExclusionInterests.push(interest);
		});
	}

	public getSpecsData(data: DropdownData<AudienceData>[]): SpecsInterface[] {
		const temp: SpecsInterface[] = [];
		data.forEach(item => {
			const newObject = { id: item.value };
			temp.push(newObject);
		});
		return temp;
	}

	public checkAudienceSize(action?: ButtonNamesEnum): void {
		this.formatFields();
		this.checkAudienceLocationType();
		const requestAudience: SavedAudienceSizeReqInterface = {
			optimizationGoal: 'BRAND_AWARENESS',
			targetingSpec: {
				genders: [this.gender],
				ageMin: this.minAge,
				ageMax: this.maxAge,
				locales: this.selectedLanguages.map(item => item.data.key),
				customAudiences: this.reqIncludeCustomAudience,
				excludedCustomAudiences: this.reqExcludeCustomAudience,
				geoLocations: {
					countries: this.geoCountries,
					regions: this.geoRegions,
					cities: this.geoCities,
					subcities: this.geoSubCities,
					neighborhoods: this.geoNeighborhoods,
					mediumGeoAreas: this.geoMediumAreas,
					countryGroups: this.countryGroups,
					geoMarkets: this.geoMarkets,
					zips: this.zips,
					electoralDistricts: this.electoralDistricts
				},
				flexibleSpec: [
					{
						interests: this.selectedInterests.map(item => ({ id: item.value }))
					},
					{
						interests: this.narrowInterests.map(item => ({ id: item.value }))
					}
				],
				exclusions: { interests: this.reqExclusionInterests }
			}
		};
		const newAdsetObj = {
			ad_account_id: this.selectedAdAccount.adAccount.id,
			optimization_goal: requestAudience.optimizationGoal,
			targeting_spec: requestAudience.targetingSpec
		};

		this.getReachEstimate(newAdsetObj, action);
	}

	public checkAudienceLocationType(): void {
		this.geoCountries = [];
		this.geoRegions = [];
		this.geoCities = [];
		this.geoSubCities = [];
		this.geoMediumAreas = [];
		this.geoNeighborhoods = [];
		this.countryGroups = [];
		this.geoMarkets = [];
		this.zips = [];
		this.electoralDistricts = [];
		for (let i = 0; i < this.targeting?.locations?.length; i++) {
			const targetType = this.targeting?.locations[i].type;
			const location = this.targeting?.locations[i].key;
			const name = this.targeting.locations[i].name;
			switch (targetType) {
				case GeoLocationEnum.Country:
					this.geoCountries.push(location);
					break;
				case GeoLocationEnum.Region:
					const region = { key: location };
					this.geoCities.push(region);
					break;
				case GeoLocationEnum.City:
					const city = { key: location };
					this.geoCities.push(city);
					break;
				case GeoLocationEnum.SubCity:
					const subCity = { key: location };
					this.geoSubCities.push(subCity);
					break;
				case GeoLocationEnum.Neighborhoods:
					const neighborhood = { key: location };
					this.geoNeighborhoods.push(neighborhood);
					break;
				case GeoLocationEnum.MidArea:
					const midArea = { key: location };
					this.geoMediumAreas.push(midArea);
					break;
				case GeoLocationEnum.CountryGroup:
					this.countryGroups.push(location);
					break;
				case GeoLocationEnum.GeoMarket:
					const geoMarket = { key: location, name: name };
					this.geoMarkets.push(geoMarket);
					break;
				case GeoLocationEnum.Zip:
					const zip = { key: location, name: name };
					this.zips.push(zip);
					break;
				case GeoLocationEnum.ElectoralDistrict:
					const district = { key: location };
					this.electoralDistricts.push(district);
					break;
			}
		}
	}

	public checkForSpecialAddCategory(): void {
		if (this.adCategory) {
			this.isSpecialAddCategorySelected = true;
			this.options.disabled = true;
		}
		if (this.regulatedInterests) {
			this.initInterestsListeners();
			this.interests = this.regulatedInterests;
			this.excludedInterests = this.regulatedInterests;
			this.narrowInterests = this.regulatedInterests;
		} else {
			this.initInterestsListeners();
			this.initExcludedInterestsListeners();
			this.initNarrowInterestsListeners();
		}
	}

	public onAgeChange(): void {
		this.targeting.ageRange = {
			minAge: this.minAge,
			maxAge: this.maxAge
		};
		this.updateStepDetails();
	}

	public onTargetTypeClick(type: TargetingTypeEnum): void {
		if (type === TargetingTypeEnum.NewAudience && this.targeting.type === TargetingTypeEnum.SavedAudience) {
			this.onDropdownDataChange([], 'selectedSavedAudiences');
			this.initFormGroup();
			this.formGroup.get('savedAudience').markAsUntouched();
		}
		if (type === TargetingTypeEnum.SavedAudience) {
			this.validateLocation();
		}
		this.targeting.type = type;
		this.buttonText = type === TargetingTypeEnum.NewAudience ? this.buttonNameEnum.Save : this.buttonNameEnum.SaveAsNew;
		this.updateStepDetails();
	}

	public onShowNarrowInterestsClick(): void {
		this.showNarrowInterests = true;
	}
	public onShowExcludedInterestsClick(): void {
		this.showExcludeInterests = true;
	}

	public onDropdownDataChange(newValues: DropdownData[], selectedProperty: string): void {
		(this as any)[selectedProperty] = newValues;
		this.selectedExcludedInterest = this.distinct(this.selectedExcludedInterest, this.selectedInterests);
		this.selectedNarrowedInterest = this.distinct(this.selectedNarrowedInterest, this.selectedInterests);
		this.selectedNarrowedInterest = this.distinct(this.selectedNarrowedInterest, this.selectedExcludedInterest);

		this.excludedInterests = this.distinct(this.excludedInterests, this.selectedInterests);
		this.narrowInterests = this.distinct(this.narrowInterests, this.selectedInterests);
		this.narrowInterests = this.distinct(this.narrowInterests, this.selectedExcludedInterest);

		this.targeting.locations = this.selectedLocations.map(location => location.data);
		this.targeting.languages = this.selectedLanguages.map(language => language.data);
		this.targeting.interests = this.selectedInterests.map(interest => interest.data);
		this.targeting.excludedInterests = this.selectedExcludedInterest.map(interest => interest.data);
		this.targeting.narrowInterests = this.selectedNarrowedInterest.map(interest => interest.data);
		this.targeting.savedAudienceId = this.selectedSavedAudiences[0]?.data.id;
		this.targeting.includedCustomAudiences = this.selectedIncludeCustomAudiences.map(audience => audience.data.id);
		this.targeting.customAudiences = this.selectedCustomAudiences.map(audience => audience.data.id);

		if (selectedProperty === 'selectedSavedAudiences' && newValues.length) {
			const target: TargetingAudience = newValues[0].data.details;

			if (!this.isAppInstall) {
				target.ageRange ? target.ageRange.minAge : 13;
				target.ageRange = {
					minAge: newValues[0].data.details.ageRange.ageMin ? newValues[0].data.details.ageRange.ageMin : newValues[0].data.details.ageRange.minAge,
					maxAge: newValues[0].data.details.ageRange.ageMax ? newValues[0].data.details.ageRange.ageMax : newValues[0].data.details.ageRange.maxAge
				};
			} else {
				target.ageRange = null;
			}
			this.updateTarget(target);
			this.targeting.savedAudienceId = this.selectedSavedAudiences[0].value.toString();
			this.populateAudienceFields(target);
		} else if (selectedProperty === 'selectedSavedAudiences' && !newValues.length) {
			this.populateAudienceFields(this.defaultTargeting);
		} else if (selectedProperty === 'selectedIncludeCustomAudiences') {
			this.selectedExcludeCustomAudiences = this.distinct(this.selectedExcludeCustomAudiences, this.selectedIncludeCustomAudiences);
		} else if (selectedProperty === 'selectedExcludeCustomAudiences') {
			this.selectedIncludeCustomAudiences = this.distinct(this.selectedIncludeCustomAudiences, this.selectedExcludeCustomAudiences);
		}
		this.targeting.includedCustomAudiences = this.selectedIncludeCustomAudiences.map(audience => audience.data.id);
		this.targeting.excludeCustomAudiences = this.selectedExcludeCustomAudiences.map(audience => audience.data.id);
		this.validateLocation();
		this.updateStepDetails();
	}

	public updateTarget(target: TargetingAudience): void {
		this.targeting.retargeting = null;
		this.targeting.targeting_type = this.isAppInstall ? ParentTargetingTypeEnum.AutomatedTargeting : ParentTargetingTypeEnum.NewTarget;
		this.targeting.name = target?.name;
		this.targeting.gender = target?.gender;
		this.targeting.ageRange = target?.ageRange;
		this.targeting.locations = target?.locations;
		this.targeting.languages = target?.languages;
		this.targeting.interests = target?.interests;
		this.targeting.narrowInterests = target?.narrowInterests;
		this.targeting.customAudiences = target?.customAudiences;
		this.targeting.includedCustomAudiences = target?.includedCustomAudiences;
		this.targeting.excludeCustomAudiences = target?.excludeCustomAudiences;
	}

	public populateAudienceFields(target: TargetingAudience): void {
		this.selectedIncludeCustomAudiences = target.includedCustomAudiences?.length
			? this.customAudiences.filter(audience => target.includedCustomAudiences.includes(audience.data.id))
			: [];
		this.selectedExcludeCustomAudiences = target.excludeCustomAudiences?.length
			? this.customAudiences.filter(audience => target.excludeCustomAudiences.includes(audience.data.id))
			: [];
		this.gender = target.gender ? target.gender : Gender.All;
		this.minAge = target.ageRange ? target.ageRange.minAge : 13;
		this.maxAge = target.ageRange ? target.ageRange.maxAge : 65;
		this.selectedLocations = target.locations
			? this.mapperService.convertToDropdownDataOfType(target.locations, 'key', 'name', this.smartCreateMapper.mapLocationsToViewModel)
			: [];

		this.selectedLanguages = target.languages ? (this.selectedLanguages = this.mapperService.convertToDropdownData(target.languages, 'key', 'name')) : [];

		this.selectedInterests = target.interests ? this.smartCreateMapper.mapInterestsToDropdownData(target.interests) : [];

		if (target.excludedInterests) {
			this.selectedExcludedInterest = this.smartCreateMapper.mapInterestsToDropdownData(target.excludedInterests);
			this.showExcludeInterests = true;
		} else {
			this.showExcludeInterests = false;
		}
		if (target.narrowInterests) {
			this.selectedNarrowedInterest = this.smartCreateMapper.mapInterestsToDropdownData(target.narrowInterests);
			this.showNarrowInterests = true;
		} else {
			this.showNarrowInterests = false;
		}
	}

	public validateLocation(): void {
		if (
			this.selectedLocations?.length === 0 &&
			(this.catalogTargetingType === ParentTargetingTypeEnum.NewTarget || this.selectedObjective === SubObjectives.AppInstallText)
		) {
			this.formGroup.get('location').setValidators(this.locationValidator);
			this.formGroup.get('location').updateValueAndValidity();
			this.formGroup.get('location').markAsTouched();
		} else {
			this.formGroup.get('location').clearValidators();
			this.formGroup.get('location').updateValueAndValidity();
			this.formGroup.get('location').markAsUntouched();
		}
	}

	private locationValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (this.selectedLocations.length <= 0) {
				return { locationValidator: true };
			}
			return null;
		};
	}

	private updateStepDetails(): void {
		this.onUpdateTargeting.emit(this.targeting);
		this.checkAudienceSize();
	}

	private initStepDetailsListener(): void {
		if (this.targeting && this.targeting.targeting_type === ParentTargetingTypeEnum.NewTarget) {
			this.gender = this.targeting.gender ?? Gender.All;
			if (this.targeting.gender) {
				this.gender = this.targeting?.gender;
			} else {
				this.targeting.gender = this.gender;
			}
			if (this.isAppInstall) {
				this.targeting.ageRange = null;
				this.targeting.targeting_type = ParentTargetingTypeEnum.AutomatedTargeting;
			} else {
				this.minAge = this.targeting.ageRange.minAge;
				this.maxAge = this.targeting.ageRange.maxAge;
			}
			const targetingType = this.targeting?.type ?? TargetingTypeEnum.NewAudience;
			this.formGroup.get('targetingType').patchValue(targetingType, { emitEvent: false, onlySelf: true });
			this.buttonText = targetingType === TargetingTypeEnum.NewAudience ? this.buttonNameEnum.Save : this.buttonNameEnum.SaveAsNew;
			this.selectedLocations = this.mapperService.convertToDropdownDataOfType(
				this.targeting.locations,
				'key',
				'name',
				this.smartCreateMapper.mapLocationsToViewModel
			);

			this.selectedLanguages = this.mapperService.convertToDropdownData(this.targeting.languages, 'key', 'name');
			this.selectedInterests = this.smartCreateMapper.mapInterestsToDropdownData(this.targeting.interests);
			this.selectedExcludedInterest = this.smartCreateMapper.mapInterestsToDropdownData(this.targeting.excludedInterests);
			this.selectedNarrowedInterest = this.smartCreateMapper.mapInterestsToDropdownData(this.targeting.narrowInterests);
			this.showNarrowInterests = this.selectedNarrowedInterest.length > 0;
			this.showExcludeInterests = this.selectedExcludedInterest.length > 0;
		}
		if (!this.formGroup.get('targetingType').value) {
			this.buttonText = this.buttonNameEnum.Save;
		}
	}

	private initFormGroup(): void {
		this.formGroup = this.formBuilder.group({
			targetingType: new FormControl(this.targetingType.NewAudience),
			location: new FormControl(),
			languages: new FormControl(),
			includedInterests: new FormControl(),
			excludedInterests: new FormControl(),
			narrowInterests: new FormControl(),
			savedAudience: new FormControl(),
			customAudience: new FormControl()
		});
	}

	private initLocationListeners(): void {
		this.formGroup
			.get('location')
			.valueChanges.pipe(
				this.liveSearch(500),
				switchMap(value => {
					if (value === '' || value === null) {
						return EMPTY;
					}
					// return this.targetingService.getLocations(value).pipe(
					// 	map(locations => {
					// 		return this.mapperService.convertToDropdownDataOfType(locations, 'key', 'name', this.smartCreateMapper.mapLocationsToViewModel);
					// 	})
					// );
				})
			)
			.subscribe(locations => {
				if (this.selectedObjective && this.selectedObjective === SubObjectives.AppInstallText) {
					// this.locations = locations.filter(location => location.data?.type === 'country');
				} else {
					this.locations = locations;
				}

				this.validateLocation();
			});
		this.targetingControl.patchValue(this.selectedLocations);
	}

	public getSelectedLocationsLanguage(): void {
		this.store.pipe(select(getLocations), takeUntil(this.unsubscriber$)).subscribe(locations => {
			if (locations && locations.length) {
				this.selectedLocations = this.mapperService.convertToDropdownDataOfType(
					locations,
					'key',
					'name',
					this.smartCreateMapper.mapLocationsToViewModel
				);
			}
		});

		this.store.pipe(select(getLanguages), takeUntil(this.unsubscriber$)).subscribe(languages => {
			if (languages && languages.length) {
				this.selectedLanguages = this.mapperService.convertToDropdownData(languages, 'key', 'name');
			}
		});
	}

	private initInterestsListeners(): void {
		this.getInterests('includedInterests').subscribe(interests => {
			this.interests = interests;
		});
	}

	private initExcludedInterestsListeners(): void {
		this.getInterests('excludedInterests').subscribe(excludedInterests => {
			this.excludedInterests = this.distinct(excludedInterests, this.selectedInterests);
		});
	}

	private initNarrowInterestsListeners(): void {
		this.getInterests('narrowInterests').subscribe(narrowInterests => {
			this.narrowInterests = this.distinct(narrowInterests, this.selectedInterests);
			this.narrowInterests = this.distinct(this.narrowInterests, this.selectedExcludedInterest);
		});
	}

	private getInterests(formControlName: string): Observable<DropdownData<FacebookInterestsView>[]> {
		const updateSearchForm = this.formGroup.get(formControlName).valueChanges.pipe(this.liveSearch(500));
		if (this.regulatedInterests) {
			return updateSearchForm.pipe(
				map(value =>
					this.regulatedInterests.filter(regulatedInterest => regulatedInterest.displayName.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
				)
			);
		}
		// return updateSearchForm.pipe(
		// 	switchMap(value => {
		// 		if (value === '') {
		// 			return EMPTY;
		// 		}
		// 		return this.smartCreateMapper.getAndMapInterests(value, this.adAccountFacebookId);
		// 	})
		// );
	}
	private distinct<T>(source: DropdownData<T>[], other: DropdownData<T>[]): DropdownData<T>[] {
		return source.filter(x => !other.find(y => x.value === y.value));
	}

	private liveSearch(time: number): OperatorFunction<string, string> {
		return input$ =>
			input$.pipe(
				debounceTime(time),
				distinctUntilChanged(),
				skipWhile(value => !value && value === ''),
				takeUntil(this.unsubscriber$)
			);
	}

	// private getAudiences(): void {
	// 	this.audienceService.getAll(this.selectedAdAccount.adAccount).subscribe(audiences => {
	// 		const dexterCustomAudiences = this.accountTypeService.dexterData$.value;
	// 		if (dexterCustomAudiences) {
	// 			audiences = audiences.concat(audiences, dexterCustomAudiences);
	// 		}
	// 		const allAudiences = this.mapperService.convertToDropdownDataOfType(audiences, 'id', 'name', this.smartCreateMapper.formatAudienceSize);
	// 		this.savedAudiences = allAudiences.filter(audience => audience.data.type === 'Saved');
	// 		this.customAudiences = allAudiences.filter(audience => audience.data.type === 'Custom');

	// 		this.customAudiences.forEach(audience => (audience.data.subtype = Helper.snakeToRegular(audience.data.subtype)));
	// 		const saved = this.savedAudiences.find(audience => this.targeting?.savedAudienceId);
	// 		if (saved) {
	// 			this.selectedSavedAudiences = [saved];
	// 		}

	// 		this.selectedCustomAudiences = [
	// 			{
	// 				data: {
	// 					id: '6253981152988',
	// 					name: 'Dexter Audience - Filed_AU_LEAD_AUTISM+LAL_AUTO_CBO - Lookalike 966243616766971',
	// 					adAccountFacebookId: 'act_169352866534012',
	// 					facebookUpdatedAt: new Date(1624443752),
	// 					facebookCreatedAt: new Date(1624443752),
	// 					state: 'ACTIVE',
	// 					source: null,
	// 					type: 'Custom',
	// 					subtype: 'LOOKALIKE',
	// 					size: 720000,
	// 					pixelId: '966243616766971',
	// 					filterType: null,
	// 					displayType: null,
	// 					getToggleState: () => true
	// 				},
	// 				displayName: 'Dexter Audience - Filed_AU_LEAD_AUTISM+LAL_AUTO_CBO - Lookalike 966243616766971',
	// 				value: '6253981152988'
	// 			}
	// 		];
	// 		// this.customAudiences.filter(audience => this.targeting.customAudiences?.includes(audience.data.id));
	// 		this.selectedIncludeCustomAudiences = this.customAudiences.filter(audience => this.targeting.includedCustomAudiences?.includes(audience.data.id));
	// 		this.selectedExcludeCustomAudiences = this.customAudiences.filter(audience => this.targeting.excludeCustomAudiences?.includes(audience.data.id));
	// 	});
	// }

	// private getReachEstimateData(reachEstimateReq: IReachReqEstimate, action?: ButtonNamesEnum): void {
	// 	if (!this.validateLocationData(reachEstimateReq.targeting_spec)) {
	// 		return;
	// 	}
	// this.audienceService
	// 	.getReachEstimate(reachEstimateReq)
	// 	.pipe(takeUntil(this.unsubscriber$))
	// 	.subscribe(response => {
	// 		this.audienceService.reachEstimate$.next(this.progressCardValues);
	// 		const size = Math.round(response.estimate_mau_upper_bound + response.estimate_mau_lower_bound) / 2;
	// 		this.targeting.size = size;
	// 		this.audienceSize.emit(size);
	// 		if (action === ButtonNamesEnum.SaveAsNew) {
	// 			this.audienceSave.emit(this.targeting);
	// 		} else if (action === ButtonNamesEnum.Save) {
	// 			this.targeting.savedAudienceId = this.selectedSavedAudiences[0].value.toString();
	// 			this.modifyAudience.emit(this.targeting);
	// 		}
	// 	});
	// }

	private validateLocationData(targetSpec: ITargetingSpec): boolean {
		const locationData = targetSpec.geoLocations;
		return locationData.cities.length > 0 || locationData.countries.length > 0 || locationData.regions.length > 0;
	}

	private getReachEstimate(adsetObj: IReachReqEstimate, action?: ButtonNamesEnum): void {
		this.store
			.pipe(
				select(getSelectedAdsets),
				debounceTime(50),
				takeUntil(this.unsubscriber$),
				switchMap((selectedAdSets: AdSetsAAAInterface[]) => {
					return of(selectedAdSets);
				})
			)
			.subscribe((selectedAdSets: AdSetsAAAInterface[]) => {
				if (selectedAdSets && selectedAdSets.length !== 0) {
					const selectedAdSetIds = selectedAdSets.map(adSetRow => adSetRow.adset_id);
					const reachEstimateReq = {
						ad_set_id: selectedAdSetIds[0],
						targeting_spec: adsetObj.targeting_spec
					};
					// this.getReachEstimateData(reachEstimateReq, action);
				} else {
					// this.getReachEstimateData(adsetObj, action);
				}
			});
	}

	public toggleCatalogTargeting(catalogTargetingType: ParentTargetingTypeEnum, mode?: string): void {
		this.catalogTargetingType = catalogTargetingType;
		if (catalogTargetingType === this.targetingMode.ReTargeting) {
			this.reTargetingControl = new FormControl('', [Validators.required]);
			if (this.targeting && this.targeting?.retargeting) {
				this.reTargetingControl.patchValue(this.targeting?.retargeting);
			}
		}
		if (catalogTargetingType === this.targetingMode?.NewTarget) {
			this.initFormGroup();
			this.initLocationListeners();
			this.populateAudienceFields(this.defaultTargeting);
			this.updateTarget(this.defaultTargeting);
			this.validateLocation();
		}
	}

	public getSelectedRetargetingData(event: any): void {
		this.retargetingAudienceSpecs.emit(event);
	}
}
