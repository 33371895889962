import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, EMPTY, forkJoin, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromAdState from '../state/ad-state';
import * as fromAAAModuleState from '../state';
import * as fromAssetsState from '../state/aaa-assets';
import { getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { map, mergeMap, skipWhile, take, tap, toArray, withLatestFrom } from 'rxjs/operators';
import { AAAAdService } from '../services/aaa-ad.service';
import { convertToAdModel, IAPIAdModel } from '../helpers/helper';
import { AddAd } from '../state/ad-state/ad-state.actions';
import { AdModel } from '../models/ad.model';
import { AaaHeaderFooterStateService } from '../services/aaa-header-footer-state.service';
import {
	LoadCampaignCatalogs,
	LoadExistingImages,
	LoadUserImages,
	LoadUserVideos,
	LoadExistingVideos,
	LoadFacebookPages
} from '../../state/campaign-common-state/campaign-common-actions';
import { getFacebookPages } from '../../state/campaign-common-state/campaign-common-reducer';

@Injectable()
export class CreateAdResolver implements Resolve<Observable<any>> {
	constructor(
		private router: Router,
		private aaaStateService: AaaHeaderFooterStateService,
		private sharedStore: Store<SharedState>,
		private store: Store<fromAAAModuleState.AAAModuleState>,
		private adaaaService: AAAAdService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.sharedStore.dispatch(new LoadFacebookPages());
		this.store.dispatch(new LoadCampaignCatalogs());
		this.store.dispatch(new LoadUserVideos());

		const setAds = (): void => {
			if (this.aaaStateService.editSelectedAds.value) {
				const getSelectedAdIds$ = this.store.pipe(select(fromAAAModuleState.getSelectedAdIds), take(1));
				const getSelectedAdAccount$ = this.store.pipe(select(getSelectedAdAccount), take(1));
				forkJoin([getSelectedAdIds$, getSelectedAdAccount$]).subscribe(([selectedAdIds, adAccount]) => {
					let adIdsString = '';
					if (selectedAdIds?.length) {
						selectedAdIds.forEach((selectedAdId, index) => {
							const isLast = index === selectedAdIds.length - 1;
							adIdsString += isLast ? `${selectedAdId}` : `${selectedAdId},`;
						});

						// this.adaaaService
						// 	.fetchAds(adIdsString, adAccount.adAccount.id)
						// 	.pipe(
						// 		take(1),
						// 		mergeMap((ads: IAPIAdModel[]) => {
						// 			let postIdsString = '';
						// 			const postIds: { indexInAds: number; postId: string }[] = ads.reduce((acc, ad, index) => {
						// 				if (ad.postId) {
						// 					acc.push({ indexInAds: index, postId: ad.postId });
						// 				}
						// 				return acc;
						// 			}, []);
						// 			postIds.forEach((post, index) => {
						// 				const isLast = index === postIds.length - 1;
						// 				postIdsString += isLast ? `${post.postId}` : `${post.postId},`;
						// 			});
						// 			// if (postIdsString) {
						// 			// 	return this.adaaaService.getPost(postIdsString).pipe(
						// 			// 		mergeMap((postDetails: { callToAction: string; primaryText: string }[]) => {
						// 			// 			postIds.forEach((post, index) => {
						// 			// 				(ads[post.indexInAds].advert as any) = { ...postDetails[index] };
						// 			// 			});
						// 			// 			return ads;
						// 			// 		})
						// 			// 	);
						// 			// }
						// 			return ads;
						// 		}),
						// 		toArray()
						// 	)
						// 	.subscribe(ads => {
						// 		ads.forEach((ad: IAPIAdModel) => {
						// 			this.store.dispatch(new AddAd(convertToAdModel(ad)));
						// 		});
						// 	});
					} else {
						this.store.dispatch(new AddAd(new AdModel()));
					}
				});
			} else {
				this.store.dispatch(new AddAd(new AdModel()));
			}
		};

		const assets$ = this.store.pipe(
			withLatestFrom(this.store.pipe(select(fromAAAModuleState.getCredentialsForAssets))),
			take(1),
			map(([action, credentials]) => {
				this.store.dispatch(new LoadExistingImages({ adAccountId: credentials.accountId, businessOwnerId: credentials.businessOwnerId }));
				this.store.dispatch(new LoadExistingVideos({ adAccountId: credentials.accountId, businessOwnerId: credentials.businessOwnerId }));
			})
		);

		const adAccount$ = this.sharedStore.pipe(select(getSelectedAdAccount), take(1));

		const platforms$ = this.store.pipe(select(fromAAAModuleState.getAdPreviewFormat), take(1));

		const posts$ = this.store.pipe(select(fromAssetsState.getPagePosts), take(1));

		const images$ = this.store.pipe(
			select(fromAssetsState.getImageAssets),
			skipWhile(data => !data),
			take(1)
		);

		const videos$ = this.store.pipe(
			select(fromAssetsState.getVideoAssets),
			skipWhile(data => !data),
			take(1)
		);

		const callToActions$ = this.store.pipe(
			select(fromAAAModuleState.getCallToAction),
			skipWhile(value => !value),
			take(1)
		);

		const objectives$ = this.store.pipe(select(fromAAAModuleState.getObjectiveTree), take(1));

		const validations$ = this.store.pipe(select(fromAAAModuleState.getValidations), take(1));

		const placements$ = this.store.pipe(select(fromAAAModuleState.getPlacements), take(1));
		const pages$ = this.store.pipe(
			select(getFacebookPages),
			skipWhile(pages => !pages),
			take(1)
		);

		const ads$ = this.store.pipe(
			select(fromAdState.getAds),
			tap(ads => {
				if (!ads.length) {
					setAds();
				}
			}),
			skipWhile(ads => !ads.length),
			take(1)
		);

		const currentAdId$ = this.store.pipe(select(fromAdState.getCurrentAdIndex), take(1));

		return forkJoin([
			adAccount$,
			platforms$,
			callToActions$,
			objectives$,
			validations$,
			placements$,
			posts$,
			videos$,
			images$,
			ads$,
			currentAdId$,
			assets$,
			pages$
		]).pipe(
			tap(_ => this.sharedStore.dispatch(new HideGlobalSpinner())),
			map(([account, platforms, callToActions, objectiveTree, validations, placements, posts, videos, images, ads, currentAdIndex, assets, pages]) => {
				if (!objectiveTree || !validations || !placements || !callToActions || !platforms) {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					const queryParams = route.queryParams;
					this.router.navigate(['/AAA/add-ad'], { queryParamsHandling: 'preserve' });
					return EMPTY;
				}

				return {
					account,
					platforms,
					callToActions,
					objectiveTree,
					validations,
					placements,
					posts,
					videos,
					images,
					ads,
					currentAdIndex,
					assets,
					pages
				};
			})
		);
	}
}
