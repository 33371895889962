import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { InsightsCategoryTypeEnum } from '../../campaign-insights/models/insights-category-type.enum';
import {
	LoadFacebookCampaignsMetadata,
	LoadFacebookAdsetsMetadata,
	LoadFacebookAdsMetadata,
	LoadGoogleCampaignsMetadata,
	LoadGoogleAdgroupsMetadata,
	LoadGoogleAdsMetadata,
	LoadGoogleKeywordsMetadata
} from '../shared.actions';

@Injectable({
	providedIn: 'root'
})
export class MetadataLoadActionFactory {
	public getInstance(channel: SourceChannel, category: InsightsCategoryTypeEnum): Action {
		switch (channel) {
			case SourceChannel.Facebook:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return new LoadFacebookCampaignsMetadata();
					case InsightsCategoryTypeEnum.AdSet:
						return new LoadFacebookAdsetsMetadata();
					case InsightsCategoryTypeEnum.Ad:
						return new LoadFacebookAdsMetadata();
				}
				break;

			case SourceChannel.Google:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return new LoadGoogleCampaignsMetadata();
					case InsightsCategoryTypeEnum.AdGroup:
						return new LoadGoogleAdgroupsMetadata();
					case InsightsCategoryTypeEnum.Ad:
						return new LoadGoogleAdsMetadata();
					case InsightsCategoryTypeEnum.Keyword:
						return new LoadGoogleKeywordsMetadata();
				}
				break;

			default:
				return null;
		}
	}
}
