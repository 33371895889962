<app-user-header></app-user-header>

<div class="container">
	<div class="header">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div>
			<h4 class="title">My Profile</h4>
			<div class="subtitle">
				Changes to account setting will apply to all other signed in devices.
			</div>
		</div>
	</div>
	<div class="body">
		<div class="headers">
			<div>
				<h4 class="title">Profile Information</h4>
			</div>
		</div>
		<div class="body-form">
			<div class="forms">
				<div>
					<label class="label">
						First Name
						<span class="required">*</span>
					</label>
					<user-tb-2
						[control]="userForm.controls['firstname']"
						placeHolderTextBox="Enter first name"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>
				<div>
					<label class="label">
						Last Name
						<span class="required">*</span>
					</label>
					<user-tb-2
						[control]="userForm.controls['lastname']"
						placeHolderTextBox="Enter last name"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>
			</div>
			<div class="forms">
				<div>
					<label class="label">
						Company
					</label>
					<user-tb-2
						[control]="userForm.controls['company']"
						placeHolderTextBox="Enter company"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>

				<div>
					<label class="label">
						Role
						<span class="required">*</span>
					</label>
					<app-select-item
						[dropDownControl]="userForm.controls['accessLevel']"
						[placeholderText]="'Enter role'"
						[showCaretIcon]="false"
						[allowFilter]="false"
						[dropDownData]="accessLevel"
						(onItemSelected)="dropDownValueChanges($event)"
					></app-select-item>
				</div>
			</div>
			<div class="buttons">
				<button (click)="editProfile()" class="btn-gcb-04 action-button" *ngIf="!isEdit">Edit</button>
				<button (click)="saveProfile()" class="btn-gcb-01" [disabled]="userForm.invalid" *ngIf="isEdit">SAVE CHANGES</button>
			</div>
		</div>
		<div class="headers">
			<div>
				<h4 class="title">Contact Information</h4>
			</div>
		</div>
		<div class="body-form">
			<div class="forms">
				<div>
					<label class="label">
						Email Address
						<span class="required">*</span>
					</label>
					<user-tb-2
						[control]="contactForm.controls['email']"
						placeHolderTextBox="Enter email address"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>

				<div>
					<label class="label">
						Phone Number
						<span class="required">*</span>
					</label>
					<user-tb-2
						[control]="contactForm.controls['phone']"
						placeHolderTextBox="Enter phone number"
						class="input-box"
						(change)="onFormChange($event)"
					></user-tb-2>
				</div>
			</div>
			<div class="buttons">
				<button (click)="editProfile2()" class="btn-gcb-04 action-button" *ngIf="!isEdit2">Edit</button>
				<button (click)="saveProfile()" class="btn-gcb-01" [disabled]="contactForm.invalid" *ngIf="isEdit2">SAVE CHANGES</button>
			</div>
		</div>
	</div>
</div>
