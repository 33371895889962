import { Component, OnInit } from '@angular/core';
import { IToolPanel, IToolPanelParams, RowNode } from 'ag-grid-community';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ShopifyCatalog } from '../../../service/shopify-catalog.service';
import { take, takeUntil, filter, pairwise } from 'rxjs/operators';
import { resolve } from 'path';

@Component({
	selector: 'custom-stats',
	templateUrl: './agrid-view.html',
	styleUrls: ['./agrid-view.scss']
})
export class AgridViewComponent implements IToolPanel, OnInit {
	private params: IToolPanelParams;
	public agGridFormBuilder: FormGroup;

	private numMedals: number;
	private numGold: number;
	private numSilver: number;
	private numBronze: number;
	public masterColumnViews: any[];
	public customColumnViews: any[];
	public customViews: any[];
	public filterMasterColumnViews: any[];
	public filterCustomColumnViews: any[];
	public filterCustomViews: any[];

	agInit(params: IToolPanelParams): void {
		this.params = params;

		this.numMedals = 0;
		this.numGold = 0;
		this.numSilver = 0;
		this.numBronze = 0;
	}
	refresh(): void {}
	constructor(private ShopifyCatalogService: ShopifyCatalog, private formBuilder: FormBuilder) {}
	public getAgGridView(): any {
		this.ShopifyCatalogService.getGridView(3)
			.pipe(take(1))
			.subscribe(res => {
				this.masterColumnViews = res.masterColumns;
				this.filterMasterColumnViews = this.masterColumnViews;
				this.customColumnViews = res.customColumns;
				this.filterCustomColumnViews = this.customColumnViews;
				this.customViews = res.customViews;
				this.filterCustomViews = this.customColumnViews;
			});
	}

	public ngOnInit(): void {
		this.agGridSearchForm();
		this.filteredRolesData();
		this.getAgGridView();
	}

	public selectAllMaster(clickedId: string, checkBoxClass: string): void {
		let clickedElement = document.getElementById(clickedId) as HTMLInputElement;
		let checkBoxes = document.querySelectorAll(`.${checkBoxClass}`) as any;
		if (clickedElement.checked) {
			clickedElement.checked = false;
			checkBoxes.forEach((ele: any) => {
				ele.checked = false;
			});
		} else {
			checkBoxes.forEach((ele: any) => {
				ele.checked = true;
			});
			clickedElement.checked = true;
		}
	}

	public filteredRolesData(): void {
		this.agGridFormBuilder
			.get('searchControl')
			.valueChanges.pipe()
			.subscribe(value => {
				value = value.toLowerCase();
				this.filterMasterColumnViews = this.masterColumnViews.filter(data => {
					return data.displayName.toLowerCase().includes(value);
				});
				this.filterCustomColumnViews = this.customColumnViews.filter(data => {
					return data.displayName.toLowerCase().includes(value);
				});
				this.filterCustomViews = this.customColumnViews.filter(data => {
					return data.displayName.toLowerCase().includes(value);
				});
				this.filterMasterColumnViews = [...this.filterMasterColumnViews];
				this.filterCustomColumnViews = [...this.filterCustomColumnViews];
				this.filterCustomViews = [...this.filterCustomViews];
			});
	}

	private agGridSearchForm(): void {
		this.agGridFormBuilder = this.formBuilder.group({
			searchControl: new FormControl(null)
		});
	}
}
