import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CatalogNodeInterface } from '../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { Placements } from './placements.model';
import { ToastNotificationService } from '../../../toast-notification/toast-notification.service';
import { ImageStateEnum } from '../../../hover-select-directive/image-state.enum';
import { SubObjectives } from '../../../../campaign-smart-create/models/sub-objectives';
import { AbstractControl } from '@angular/forms';
import { TreeViewInterface } from '@filed-com/filed-lib/lib/tree-view/models/tw-interface';
import _ from 'lodash';
import { InfoHoverDataModel } from '../../../info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-placements-card',
	templateUrl: './placements-card.component.html',
	styleUrls: ['./placements-card.component.scss']
})
export class PlacementsCardComponent implements OnInit, OnChanges {
	@Input() public isPanelSelected: boolean;
	@Input() public platformsPerDevice: CatalogNodeInterface[];
	@Input() public selectedPlacements: CatalogNodeInterface[] = [];
	@Input() public placementsControl: AbstractControl;
	@Input() public platforms: CatalogNodeInterface[];
	@Input() public objective: string;
	@Input() public isInstagramSelected: boolean;

	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public onUpdatePlacements = new EventEmitter<CatalogNodeInterface[]>();
	@Output() public onUpdatePlacementPerPlatform = new EventEmitter<TreeViewInterface[]>();

	public state = ImageStateEnum;
	private toastNotificationService: ToastNotificationService;
	public checkedKeys: string[] = [];
	public platformIndex: { [key: string]: string } = {};
	public placementAdvanceSelectionData: TreeViewInterface[] = [];
	public filteredPlacementAdvanceSelectionData: TreeViewInterface[] = [];
	public placementHoverModel: InfoHoverDataModel;

	constructor(private injector: Injector) {
		this.toastNotificationService = this.injector.get<ToastNotificationService>(ToastNotificationService);
	}

	public ngOnInit(): void {
		this.onComponentInit();
		this.generatePlacementsPerPlatformArray();
		this.placementHoverModel = {
			message:
				"Manually choose the places to show your ad. The more placements you select, the more opportunities you'll have to reach your target audience and achieve your business goals. Note: Depending on your ad type, content, and the design of its creative, every placement may not be available."
		};
	}

	public onComponentInit(): void {
		this.platforms = this.platforms?.filter(platform => platform.name !== Placements.Messenger && platform.name !== Placements.WhatsApp);
		if (!this.selectedPlacements?.length) {
			this.selectedPlacements = this.platforms?.filter(item => item.name !== Placements.AppsSites);
		}
		this.setDisable();
		this.checkInstagramSelected();
		this.setState();
		this.onUpdatePlacements.emit(this.selectedPlacements);
		this.placementsControl.patchValue(this.selectedPlacements);
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.isInstagramSelected) {
			this.checkInstagramSelected();
			this.setState();
			this.onUpdatePlacements.emit(this.selectedPlacements);
			this.placementsControl.patchValue(this.selectedPlacements);
		}
		if (changes.platformsPerDevice) {
			this.placementAdvanceSelectionData = this.generatePlacementsPerPlatformArray();
			this.filterPlacementPositions();
		}
	}

	public generatePlacementsPerPlatform(): Map<string, IPlacementItem[]> {
		const placementsPerPlatform = new Map();
		this.platformsPerDevice?.forEach(placement => {
			if (!placement?.items?.length || placement?.items[0]?.display_name_autogen === 'Messenger') {
				return;
			}
			const platform = placement?.items[0]?.display_name_autogen;
			const placementItem: IPlacementItem = {
				text: placement.displayNameAutogen,
				name: placement.name
			};
			const placements = placementsPerPlatform.get(platform) ?? [];
			placements?.push(placementItem);
			placementsPerPlatform.set(platform, placements);
		});
		return placementsPerPlatform;
	}

	public generatePlacementsPerPlatformArray(): IPlacementPerPlatform[] {
		const result = [];
		const PlacementsPerPlatform = this.generatePlacementsPerPlatform();
		let index = 0;
		for (let [key, value] of PlacementsPerPlatform) {
			const platform = {
				text: key,
				items: value
			};
			this.platformIndex[key] = `${index}`;
			index++;
			result.push(platform);
		}
		return result;
	}

	public runCheckedKeysSelection(): void {
		this.checkedKeys = this.filteredPlacementAdvanceSelectionData.map((val, index) => `${index}`);
		const placementPerPlatform = _.cloneDeep(this.filteredPlacementAdvanceSelectionData);
		placementPerPlatform.forEach((platform, index) => (platform.isChecked = this.checkedKeys.includes(`${index}`)));
		this.updatePlacementPerPlatform(placementPerPlatform);
	}

	public checkInstagramSelected(): void {
		if (this.isInstagramSelected && !this.selectedPlacements?.find(placement => placement?.name === Placements.Instagram)) {
			this.selectedPlacements.push(this.platforms?.filter(platform => platform.name === Placements.Instagram)[0]);
		} else {
			this.selectedPlacements = this.selectedPlacements?.filter(placement => placement?.name !== Placements.Instagram);
		}
	}

	public onPlacementClick(selected: CatalogNodeInterface): void {
		if (!this.selectedPlacements?.find(placement => placement?.name === selected.name)) {
			if (selected.imageState !== ImageStateEnum.Disabled) {
				this.selectedPlacements.push(selected);
			}
		} else {
			if (selected.name === Placements.Facebook) {
				this.selectedPlacements = this.selectedPlacements.filter(placement => placement?.name !== Placements.AppsSites);
			}
			this.selectedPlacements = this.selectedPlacements.filter(placement => placement?.name !== selected.name);
		}
		if (selected.name === Placements.AppsSites) {
			this.autSelectFacebook();
			this.checkInstagramDisabled();
		}
		this.setDisable();
		this.setState(true);
		this.onUpdatePlacements.emit(this.selectedPlacements);
		this.placementsControl.patchValue(this.selectedPlacements);
		this.filterPlacementPositions();
	}

	public checkInstagramDisabled(): void {
		if (!this.selectedPlacements.some(value => value.name === Placements.AppsSites)) {
			this.platforms?.forEach(item => {
				if (item.name === Placements.Instagram) {
					item.imageState = ImageStateEnum.Disabled;
				}
			});
			const index = this.selectedPlacements.findIndex(value => value.name === Placements.Instagram);
			if (index > -1) {
				this.selectedPlacements.splice(index, 1);
			}
		}
	}

	public filterPlacementPositions(): void {
		this.filteredPlacementAdvanceSelectionData = this.placementAdvanceSelectionData.filter(val =>
			this.selectedPlacements?.some(placement => val.text.toLocaleLowerCase() === placement?.displayNameAutogen.toLocaleLowerCase())
		);
		this.runCheckedKeysSelection();
	}

	public setDisable(): void {
		if (this.objective === SubObjectives.PostLikes || this.objective === SubObjectives.PageLikes || this.objective === SubObjectives.BrandAwareness) {
			this.platforms?.forEach(item => {
				if (item.name === Placements.AppsSites) {
					item.imageState = ImageStateEnum.Disabled;
				}
			});
		}
		if (this.objective === SubObjectives.PageLikes) {
			this.platforms?.forEach(item => {
				if (item.name === Placements.Instagram) {
					item.imageState = ImageStateEnum.Disabled;
				}
			});
		}
		this.selectedPlacements = this.selectedPlacements?.filter(item => item?.imageState !== ImageStateEnum.Disabled);
	}

	public autSelectFacebook(): void {
		const isAppsSelected = this.selectedPlacements?.filter(item => item?.name === Placements.AppsSites);
		const isFacebookSelected = this.selectedPlacements?.filter(item => item?.name === Placements.Facebook);
		if (isAppsSelected.length && !isFacebookSelected.length) {
			const facebook = this.platforms?.filter(item => item?.name === Placements.Facebook);
			this.selectedPlacements?.push(facebook[0]);
			this.toastNotificationService.sendInfoToast(
				'Audience Network does not work without Facebook placements. As a result, Facebook was automatically selected as well.',
				8000
			);
		}

		this.runCheckedKeysSelection();
	}

	public checkIfSelected(placement: CatalogNodeInterface, placements: CatalogNodeInterface[]): boolean {
		let found = false;
		placements?.forEach(selectedPlacement => {
			if (selectedPlacement?.name === placement?.name) {
				found = true;
			}
		});
		return found;
	}

	public setState(isClicked?: boolean): void {
		this.platforms?.forEach(item => {
			if (this.selectedPlacements.includes(item)) {
				item.imageState = this.state.Pressed;
			} else if (item.name === Placements.Instagram && !isClicked) {
				item.imageState = this.state.Disabled;
			} else if (item.imageState !== this.state.Disabled && isClicked) {
				item.imageState = this.state.Default;
			}
		});
	}

	public updatePlacementPerPlatform(placementPerPlatform: TreeViewInterface[]): void {
		this.onUpdatePlacementPerPlatform.emit(placementPerPlatform);
	}
}

export interface IPlacementItem {
	text: string;
	name: string;
}

export interface IPlacementPerPlatform extends TreeViewInterface {
	items: IPlacementItem[];
}
