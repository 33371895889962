import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScheduleDataModel } from '../../models/schedule-data.model';
import { ScheduleDetails } from '../../models/schedule.model';
import { BaseApiUrl } from '../../../_services/base-api-urls';
import { SchedulePyDataModel } from '../../models/schedulepy-data.model';
import { ScheduleEditModel } from '../../models/schedule-edit.model';

@Injectable()
export class SchedulesService {
	private readonly baseURL = BaseApiUrl.Reporting + 'report-schedule/';
	private readonly PYbaseURL = BaseApiUrl.PyReporting;
	private readonly SchedulesURL = BaseApiUrl.Schedules;
	constructor(private http: HttpClient) {}

	public createSchedule(schedule: any, Did: number): Observable<any> {
		return this.http.post<any>(`${this.PYbaseURL}schedule/` + Did, schedule);
	}

	public updateSchedule(id: string, schedule: ScheduleEditModel): Observable<void> {
		return this.http.put<void>(`${this.SchedulesURL}notifications/schedule/${id}`, schedule);
	}

	public getnewSchedules(): Observable<SchedulePyDataModel[]> {
		return this.http.get<SchedulePyDataModel[]>(`${this.SchedulesURL}notifications/schedule/list/reports`);
	}
	public getSchedules(): Observable<ScheduleDataModel[]> {
		return this.http.get<ScheduleDataModel[]>(`${this.baseURL}`);
	}
	public getById(id: number): Observable<ScheduleDetails> {
		return this.http.get<ScheduleDetails>(`${this.baseURL}${id}`);
	}

	public delete(id: number | string): Observable<void> {
		return this.http.delete<void>(`${this.SchedulesURL}notifications/schedule/${id}`);
	}
}
