export enum EcommerceSource {
	Facebook = 'facebook-normal',
	FacebookExport = 'facebook',
	Google = 'google-normal',
	GoogleExport = 'google',
	Snapchat = 'snapchat',
	Ebay = 'ebay',
	Amazon = 'amazon',
	Shopify = 'shopify',
	BigCommerce = 'bigcommerce'
}

export interface IntegrationInterface {
	title: string;
	status: boolean;
	icon: string;
	source: string;
	integrationType?: string;
}

export interface IChannelsInstalled {
	installed_channels: Array<{}>;
}
export enum IntegrationTypeEnum {
	Marketing = 'marketing',
	FeedManagement = 'feed-management'
}
export interface IChannelRequest {
	redirect_url: string;
}
