import { Action } from '@ngrx/store';
import { GetPermissionsResponse, PermissionModel } from '../../shared/models/permission';
import { User } from '../../_models/user-models/user';
import { FiledCreditCard } from '../components/filed-cards/set-creditcard.models';
import { DropdownData } from '../../shared/dropdown-search-select/dropdown-data.interface';
import { AdAccountFormData, FirstStepAccountForm } from '../models/account';
import { InvoiceDetails } from '../../_models/invoice-details.interface';

import { Business } from '../../accounts/models/business.model';
import { BillingAddress } from '../shared/billing-and-payment/billing-and-payment.models';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import { userPaymentPlan } from './user-management.reducer';
import { DisableUserModel } from './state.model';

export enum UserManagementTypes {
	// Subscription
	loadInvoices = '[UserManagement -> Subscription] Load invoices',
	loadInvoicesSuccess = '[UserManagement -> Subscription] Load invoices Success',
	loadInvoicesFailure = '[UserManagement -> Subscription] Set invoices Failure',

	// Permissions
	togglePermissionCheckByTitle = '[UserManagement -> Permissions] Toggle permission',
	loadSelectedKeysPermissions = '[UserManagement -> Permissions] Load permission',
	loadSelectedKeysPermissionsSuccess = '[UserManagement -> Permissions] Load permission success',
	loadSelectedKeysPermissionsFailure = '[UserManagement -> Permissions] Load permission failure',
	setGeneralPermissions = '[UserManagement -> Permissions] Set general permissions',
	setAdsAndPagesPermissions = '[UserManagement -> Permissions] Set adsAndPages permissions',
	setInstagramPermissions = '[UserManagement -> Permissions] Set instagram permissions',
	setTouchedPermission = '[UserManagement -> Permissions] Set touched permissions',

	// Miscellaneous
	setBusinesses = '[User Management -> Miscellaneous] Set businesses',

	// Miscellaneous -> Navigation
	activeUserManagementPage = '[UserManagement -> Miscellaneous -> Navigation] Toggle (active/false) page management  page',
	setActiveBillingPageUserManagement = '[UserManagement -> Miscellaneous -> Navigation] Set active index billing page ',
	setActiveUsersPage = '[UserManagement -> Users] Toggle active page',

	// User
	setUserProfileUserManagement = '[UserManagement -> User] Set user',
	setUserProfileUserManagementFailure = '[UserManagement -> User] Set user',

	setUserEditImageUserManagement = '[UserManagement -> User] Set edit image',

	updateUserPicture = '[UserManagement -> Billing] Update User Picture',
	updateUserPictureSuccess = '[UserManagement -> Billing] Update User Picture Success',
	updateUserPictureFailure = '[UserManagement -> Billing] Update User Picture Failure',

	loadCatalogs = '[UserManagement -> User] Load catalogs',

	// Billing
	loadCards = '[UserManagement -> Billing] Load cards',
	loadCardsSuccess = '[UserManagement -> Billing] Load cards success',
	loadCardsFailure = '[UserManagement -> Billing] Set cards failure',
	loadBillingAccountInfo = '[UserManagement -> Billing] Load billing address',
	loadBillingAccountInfoSuccess = '[UserManagement -> Billing] Load billing address success',
	loadBillingAccountInfoFailure = '[UserManagement -> Billing] Load billing address failure',

	addCard = '[UserManagement -> Billing] Add Card',

	makePrimaryCard = 'UserManagement -> Billing] Make primary card',
	makePrimaryCardSuccess = 'UserManagement -> Billing] Make primary  success',
	makePrimaryCardFailure = 'UserManagement -> Billing] Make primary card failure',

	setSelectedCardId = '[UserManagement -> Billing] Set selected card',
	setInvoiceHistory = '[UserManagement -> Invoice] Set Invoice History',

	// Edit card page status
	setValidStatusForEditPage = '[UserManagement -> Billing -> Edit page status] Change valid status for edit page',
	setSubmitStatusForEditPage = '[UserManagement -> Billing -> Edit page status] Change submit status for edit page',
	setErrorsStatusForEditPage = '[UserManagement -> Billing -> Edit page status] Change errors status for edit page',
	setTouchedStatusForEditPage = '[UserManagement -> Billing -> Edit page status] Change touched status for edit page',
	setResetStatusForEditPage = '[UserManagement -> Billing -> Edit page status] Change reset status for edit page',
	setNextStatusForEditPage = '[UserManagement -> Billing -> Edit page status] Change next status for edit page',
	setCheckStatusForEditPage = '[UserManagement -> Billing -> Edit page status] Change check status for edit page',

	// Users
	setRefreshStatusUsers = '[UserManagement -> Users -> Refresh Status] Change refresh status for users',

	setFacebookBusinessRoles = '[UserManagement -> Users] Set Facebook Business Roles',
	setFacebookBusinessRolesFailure = '[UserManagement -> Users] Set Facebook Business Roles Failure',

	setGoogleAdAccountRoles = '[UserManagement -> Users] Set Google AdAccount Roles',
	setGoogleAdAccountRolesFailure = '[UserManagement -> Users] Set Google AdAccount Roles Failure',

	setFacebookAdAccountRoles = '[UserManagement -> Users] Set Facebook AdAccount Roles',
	setFacebookAdAccountRolesFailure = '[UserManagement -> Users] Set Facebook AdAccount Roles Failure',

	LoadUsers = '[UserManagement -> Users] Load Users',
	LoadUsersSuccess = '[UserManagement -> Users] Load Users Success',
	LoadUsersFailure = '[UserManagement -> Users] Load Users Failure',

	LoadFiledUser = '[UserManagement -> Users] Load filed User',
	addFiledUser = '[UserManagement -> Users] Add User',
	deleteUserById = '[UserManagement -> Users] Delete User',

	PauseUserAccount = '[UserManagement ->Users -> PauseUserAccount] Pause User Account',

	//Account
	addAdAccountData = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Add Ad Account',
	addBusiness = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Add Ad Business',
	addSelectedAccesLevelId = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Add selected acces level',
	addRadioButtonValue = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Add radio button value',
	addSelectedBusinessId = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Set selected business',

	setAccountEditState = '[UserManagement -> Account -> My Account -> Status] Set edit status',
	setAccountSubmitState = '[UserManagement -> Account -> My Account -> Status] Set submit status',
	setFirstStepData = '[UserManagement -> Account -> AddEditUser] Set first step data',
	setAddEditEditStatus = '[UserManagement -> Account -> AddEditUser -> Status] Set next status',
	setAddEditSubmitStatus = '[UserManagement -> Account -> AddEditUser -> Status] Set submit status',
	setUpdatedUser = '[UserManagement -> Account -> AddEditUser -> Status] Set selected user to edit',
	setUpdatedUserFailure = '[UserManagement -> Account -> AddEditUser -> Status] Set selected user to edit failure',

	updateAdAccountData = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Update Ad Account',
	updateSelectedBusinessId = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Update selected business',
	updateSelectedAccesLevelId = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Update selected acces level',
	updateRadioButtonValue = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Update radio button value',

	removeSelectedAccesByIndex = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove acess level by index',
	removeRadioValueByIndex = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove radio value by index',
	removeAllSelectedBusinesses = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove all selected businesses',
	removeSelectedAdAccount = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove selected ad account',
	removeSelectedAdAccountByBusiness = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove selected ad account by business id',
	removeAllSelectedAdAccount = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove all selected ad accounts',
	removeAllSelectedAccesLevelId = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove all roles',
	removeAllRadioValues = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove all radio values',
	removeBusinessByIndex = '[UserManagement -> Account -> AddEditUser -> SecondStepForm] Remove business by index',

	setUserPlan = '[UserManagement -> MyPlan] Add Payment Plan To My Account',
	SetAdminManagement = '[UserManagement -> UserAdmin] Add Edit User Management'
}

// Subscription

export class LoadInvoices {
	readonly type = UserManagementTypes.loadInvoices;

	constructor() {}
}

export class LoadInvoicesSuccess {
	readonly type = UserManagementTypes.loadInvoicesSuccess;

	constructor(public readonly payload: InvoiceDetails) {}
}

export class LoadInvoicesFailure {
	readonly type = UserManagementTypes.loadInvoicesFailure;

	constructor(public readonly payload: string) {}
}

//  Billing

export class AddCard {
	readonly type = UserManagementTypes.addCard;

	constructor(public readonly payload: FiledCreditCard) {}
}

export class LoadCards {
	readonly type = UserManagementTypes.loadCards;

	constructor() {}
}

export class LoadCardsSuccess {
	readonly type = UserManagementTypes.loadCardsSuccess;

	constructor(public readonly payload: FiledCreditCard[]) {}
}

export class LoadCardsFailure {
	readonly type = UserManagementTypes.loadCardsFailure;

	constructor(public readonly payload: string) {}
}

export class MakePrimaryCard {
	readonly type = UserManagementTypes.makePrimaryCard;

	constructor(public readonly payload: string) {}
}

export class MakePrimaryCardSuccess {
	readonly type = UserManagementTypes.makePrimaryCardSuccess;

	constructor(public readonly payload: string) {}
}

export class MakePrimaryCardFailure {
	readonly type = UserManagementTypes.makePrimaryCardFailure;

	constructor(public readonly payload: string) {}
}

export class SetNextStatusForEditPage {
	readonly type = UserManagementTypes.setNextStatusForEditPage;

	constructor(public readonly payload: boolean) {}
}

export class SetValidStatusForEditPage {
	readonly type = UserManagementTypes.setValidStatusForEditPage;

	constructor(public readonly payload: boolean) {}
}

export class SetSubmitStatusForEditPage {
	readonly type = UserManagementTypes.setSubmitStatusForEditPage;

	constructor(public readonly payload: boolean) {}
}

export class SetErrorsStatusForEditPage {
	readonly type = UserManagementTypes.setErrorsStatusForEditPage;

	constructor(public readonly payload: boolean) {}
}

export class SetTouchedStatusForEditPage {
	readonly type = UserManagementTypes.setTouchedStatusForEditPage;

	constructor(public readonly payload: boolean) {}
}

export class SetResetStatusForEditPage {
	readonly type = UserManagementTypes.setResetStatusForEditPage;

	constructor(public readonly payload: boolean) {}
}

export class SetCheckStatusForEditPage {
	readonly type = UserManagementTypes.setCheckStatusForEditPage;

	constructor(public readonly payload: boolean) {}
}

export class SetSelectedCardId {
	readonly type = UserManagementTypes.setSelectedCardId;

	constructor(public readonly payload: string) {}
}

// User

export class UpdateUserPicture {
	readonly type = UserManagementTypes.updateUserPicture;

	constructor(public readonly payload: { pictureForm: FormData }) {}
}

export class UpdateUserPictureSuccess {
	readonly type = UserManagementTypes.updateUserPictureSuccess;

	constructor(public readonly payload: User) {}
}

export class UpdateUserPictureFailure {
	readonly type = UserManagementTypes.updateUserPictureFailure;

	constructor(public readonly payload: string) {}
}

export class SetUserProfileUserManagement {
	readonly type = UserManagementTypes.setUserProfileUserManagement;

	constructor(public readonly payload: User) {}
}

export class SetUserProfileUserManagementFailure {
	readonly type = UserManagementTypes.setUserProfileUserManagementFailure;

	constructor(public readonly payload: string) {}
}

export class SetUserEditImageUserManagement {
	readonly type = UserManagementTypes.setUserEditImageUserManagement;

	constructor(public readonly payload: any) {}
}

// Miscellaneous

export class SetBusinesses {
	readonly type = UserManagementTypes.setBusinesses;

	constructor(public readonly payload: Business[]) {}
}

// Miscellaneous -> Navigaton

export class SetActiveBillingPageUserManagement implements Action {
	readonly type = UserManagementTypes.setActiveBillingPageUserManagement;

	constructor(public readonly payload: number) {}
}

export class ToggleActiveUserManagementPage implements Action {
	readonly type = UserManagementTypes.activeUserManagementPage;

	constructor(public readonly payload: boolean) {}
}

// Permissions

export class LoadSelectedKeysPermissions implements Action {
	readonly type = UserManagementTypes.loadSelectedKeysPermissions;

	constructor(public readonly payload: boolean = false) {}
}

export class LoadSelectedKeysPermissionsSuccess implements Action {
	readonly type = UserManagementTypes.loadSelectedKeysPermissionsSuccess;

	constructor(public readonly payload: GetPermissionsResponse) {}
}

export class LoadSelectedKeysPermissionsFailure implements Action {
	readonly type = UserManagementTypes.loadSelectedKeysPermissionsFailure;

	constructor(public readonly payload: string) {}
}

export class SetTouchedPermission implements Action {
	readonly type = UserManagementTypes.setTouchedPermission;

	constructor(public readonly payload: boolean = true) {}
}

export class TogglePermissionCheckByTitle implements Action {
	readonly type = UserManagementTypes.togglePermissionCheckByTitle;

	constructor(public readonly payload: string, public value: boolean) {}
}

export class SetGeneralPermissions implements Action {
	readonly type = UserManagementTypes.setGeneralPermissions;

	constructor(public readonly payload: PermissionModel[]) {}
}

export class SetAdsAndPagesPermissions implements Action {
	readonly type = UserManagementTypes.setAdsAndPagesPermissions;

	constructor(public readonly payload: PermissionModel[]) {}
}

export class SetInstagramPermissions implements Action {
	readonly type = UserManagementTypes.setInstagramPermissions;

	constructor(public readonly payload: PermissionModel[]) {}
}

// Users

export class SetRefreshStatusUsers implements Action {
	readonly type = UserManagementTypes.setRefreshStatusUsers;

	constructor(public readonly payload: boolean) {}
}

export class SetFacebookBusinessRoles implements Action {
	readonly type = UserManagementTypes.setFacebookBusinessRoles;

	constructor(public readonly payload: DropdownData[]) {}
}

export class SetFacebookBusinessRolesFailure implements Action {
	readonly type = UserManagementTypes.setFacebookBusinessRolesFailure;

	constructor(public readonly payload: string) {}
}

export class SetGoogleAdAccountRoles implements Action {
	readonly type = UserManagementTypes.setGoogleAdAccountRoles;

	constructor(public readonly payload: DropdownData[]) {}
}

export class SetGoogleAdAccountRolesFailure implements Action {
	readonly type = UserManagementTypes.setGoogleAdAccountRolesFailure;

	constructor(public readonly payload: string) {}
}

export class LoadFiledUser implements Action {
	readonly type = UserManagementTypes.LoadFiledUser;

	constructor(public readonly payload: { id: number; setSelectedUser: boolean; isBackoffice: boolean }) {}
}

export class LoadUsers implements Action {
	readonly type = UserManagementTypes.LoadUsers;

	constructor(public readonly payload: string) {}
}

export class LoadUsersSuccess implements Action {
	readonly type = UserManagementTypes.LoadUsersSuccess;

	constructor(public readonly payload: User[]) {}
}

export class LoadUsersFailure implements Action {
	readonly type = UserManagementTypes.LoadUsersFailure;

	constructor(public readonly payload: string) {}
}

export class AddFiledUser implements Action {
	readonly type = UserManagementTypes.addFiledUser;

	constructor(public readonly payload: User) {}
}

export class LoadCatalogs implements Action {
	readonly type = UserManagementTypes.loadCatalogs;

	constructor() {}
}

export class SetFacebookAdAccountRoles implements Action {
	readonly type = UserManagementTypes.setFacebookAdAccountRoles;

	constructor(public readonly payload: DropdownData[]) {}
}

export class SetFacebookAdAccountRolesFailure implements Action {
	readonly type = UserManagementTypes.setFacebookAdAccountRolesFailure;

	constructor(public readonly payload: string) {}
}

export class SetActiveUsersPage implements Action {
	readonly type = UserManagementTypes.setActiveUsersPage;

	constructor(public readonly payload: boolean) {}
}

// Account

export class SetAccountEditState implements Action {
	readonly type = UserManagementTypes.setAccountEditState;
	constructor(public readonly payload: boolean = true) {}
}

export class SetAccountSubmitState implements Action {
	readonly type = UserManagementTypes.setAccountSubmitState;
	constructor(public readonly payload: boolean = true) {}
}

export class SetFirstStepData implements Action {
	readonly type = UserManagementTypes.setFirstStepData;
	constructor(public readonly payload: FirstStepAccountForm) {}
}

export class SetAddEditNextStatus implements Action {
	readonly type = UserManagementTypes.setAddEditEditStatus;
	constructor(public readonly payload: boolean = true) {}
}

export class SetAddEditSubmitStatus implements Action {
	readonly type = UserManagementTypes.setAddEditSubmitStatus;
	constructor(public readonly payload: boolean = true) {}
}

export class AddAdAccountData implements Action {
	readonly type = UserManagementTypes.addAdAccountData;
	constructor(public readonly payload: AdAccountFormData) {}
}

export class AddBusiness implements Action {
	readonly type = UserManagementTypes.addBusiness;
	constructor() {}
}

export class RemoveBusinessByIndex implements Action {
	readonly type = UserManagementTypes.removeBusinessByIndex;
	constructor(public readonly payload: number) {}
}

export class UpdateAdAccountData implements Action {
	readonly type = UserManagementTypes.updateAdAccountData;

	constructor(public readonly payload: { formData: AdAccountFormData; oldValue: string }) {}
}

export class AddSelectedBusinessId implements Action {
	readonly type = UserManagementTypes.addSelectedBusinessId;

	constructor(public readonly payload: number = -1) {}
}

export class UpdateSelectedBusinessId implements Action {
	readonly type = UserManagementTypes.updateSelectedBusinessId;

	constructor(public readonly payload: number, public data: number) {}
}

export class SetUpdatedUser implements Action {
	readonly type = UserManagementTypes.setUpdatedUser;

	constructor(public readonly payload: User) {}
}

export class SetUpdatedUserFailure implements Action {
	readonly type = UserManagementTypes.setUpdatedUserFailure;

	constructor(public readonly payload: string) {}
}

export class DeleteUserById implements Action {
	readonly type = UserManagementTypes.deleteUserById;

	constructor(public readonly payload: number) {}
}

export class RemoveSelectedAdAccount implements Action {
	readonly type = UserManagementTypes.removeSelectedAdAccount;

	constructor(public readonly payload: string) {}
}

export class RemoveSelectedAdAccountByBusiness implements Action {
	readonly type = UserManagementTypes.removeSelectedAdAccountByBusiness;

	constructor(public readonly payload: DropdownData[]) {}
}

export class RemoveRadioValueByIndex implements Action {
	readonly type = UserManagementTypes.removeRadioValueByIndex;

	constructor(public readonly payload: number) {}
}

export class RemoveSelectedAccesByIndex implements Action {
	readonly type = UserManagementTypes.removeSelectedAccesByIndex;

	constructor(public readonly payload: number) {}
}

export class RemoveAllSelectedAccesLevelId implements Action {
	readonly type = UserManagementTypes.removeAllSelectedAccesLevelId;

	constructor() {}
}

export class UpdateSelectedAccesLevelId implements Action {
	readonly type = UserManagementTypes.updateSelectedAccesLevelId;

	constructor(public readonly payload: { index: number; value: number }) {}
}

export class AddSelectedAccesLevelId implements Action {
	readonly type = UserManagementTypes.addSelectedAccesLevelId;

	constructor(public readonly payload: number) {}
}

export class AddRadioButtonValue implements Action {
	readonly type = UserManagementTypes.addRadioButtonValue;

	constructor(public readonly payload: number) {}
}

export class UpdateRadioButtonValue implements Action {
	readonly type = UserManagementTypes.updateRadioButtonValue;

	constructor(public readonly payload: { index: number; value: number }) {}
}

export class RemoveAllSelectedBusinesses implements Action {
	readonly type = UserManagementTypes.removeAllSelectedBusinesses;

	constructor() {}
}

export class RemoveAllRadioValues implements Action {
	readonly type = UserManagementTypes.removeAllRadioValues;

	constructor() {}
}

export class RemoveAllSelectedAdAccount implements Action {
	readonly type = UserManagementTypes.removeAllSelectedAdAccount;

	constructor() {}
}

export class LoadBillingAccountInfo {
	readonly type = UserManagementTypes.loadBillingAccountInfo;

	constructor() {}
}

export class LoadBillingAccountInfoSuccess {
	readonly type = UserManagementTypes.loadBillingAccountInfoSuccess;

	constructor(public readonly payload: BillingAddress) {}
}

export class LoadBillingAccountInfoFailure {
	readonly type = UserManagementTypes.loadBillingAccountInfoFailure;

	constructor(public readonly payload: string) {}
}
export class SetInvoiceHistory {
	readonly type = UserManagementTypes.setInvoiceHistory;

	constructor(public readonly payload: InvoiceHistory[]) {}
}

export class SetUserPlan {
	readonly type = UserManagementTypes.setUserPlan;

	constructor(public readonly payload: userPaymentPlan) {}
}

export class PauseUserAccount {
	readonly type = UserManagementTypes.PauseUserAccount;

	constructor(public readonly payload: DisableUserModel) {}
}

export class SetAdminManagement {
	readonly type = UserManagementTypes.SetAdminManagement;

	constructor(public readonly payload: SetAdminManagement) {}
}

export type UserManagementActions =
	| MakePrimaryCardSuccess
	| MakePrimaryCardFailure
	| UpdateUserPictureFailure
	| UpdateUserPictureSuccess
	| UpdateUserPicture
	| LoadCardsFailure
	| LoadSelectedKeysPermissionsFailure
	| LoadInvoicesFailure
	| SetGoogleAdAccountRolesFailure
	| SetFacebookBusinessRolesFailure
	| SetUserProfileUserManagementFailure
	| SetUpdatedUserFailure
	| LoadUsers
	| LoadUsersFailure
	| DeleteUserById
	| MakePrimaryCard
	| AddCard
	| AddFiledUser
	| SetUpdatedUser
	| RemoveSelectedAccesByIndex
	| RemoveRadioValueByIndex
	| RemoveBusinessByIndex
	| RemoveAllRadioValues
	| RemoveAllSelectedAccesLevelId
	| RemoveAllSelectedAdAccount
	| RemoveAllSelectedBusinesses
	| UpdateRadioButtonValue
	| AddSelectedAccesLevelId
	| RemoveSelectedAdAccountByBusiness
	| UpdateSelectedBusinessId
	| AddBusiness
	| LoadFiledUser
	| LoadCatalogs
	| LoadCards
	| LoadCardsSuccess
	| LoadSelectedKeysPermissionsSuccess
	| LoadSelectedKeysPermissions
	| LoadInvoices
	| LoadInvoicesSuccess
	| LoadUsersSuccess
	| AddRadioButtonValue
	| UpdateSelectedAccesLevelId
	| RemoveSelectedAdAccount
	| AddSelectedBusinessId
	| UpdateAdAccountData
	| AddAdAccountData
	| SetAddEditSubmitStatus
	| SetAddEditNextStatus
	| SetFirstStepData
	| SetActiveUsersPage
	| SetFacebookAdAccountRoles
	| SetGoogleAdAccountRoles
	| SetFacebookBusinessRoles
	| TogglePermissionCheckByTitle
	| SetGeneralPermissions
	| SetAdsAndPagesPermissions
	| SetInstagramPermissions
	| SetTouchedPermission
	| ToggleActiveUserManagementPage
	| SetActiveBillingPageUserManagement
	| SetUserProfileUserManagement
	| SetUserEditImageUserManagement
	| SetBusinesses
	| SetSelectedCardId
	| SetValidStatusForEditPage
	| SetSubmitStatusForEditPage
	| SetErrorsStatusForEditPage
	| SetTouchedStatusForEditPage
	| SetNextStatusForEditPage
	| SetResetStatusForEditPage
	| SetCheckStatusForEditPage
	| SetRefreshStatusUsers
	| SetAccountEditState
	| SetAccountSubmitState
	| LoadBillingAccountInfo
	| LoadBillingAccountInfoSuccess
	| LoadBillingAccountInfoFailure
	| SetInvoiceHistory
	| SetUserPlan
	| PauseUserAccount
	| SetAdminManagement;
