<div class="link-account-wrapper">
	<div class="right-button">
		<button (click)="onNoClick()" *ngIf="!hasConnected" class="remove-button">
			<i class="fas fa-times"></i>
		</button>
	</div>
	<div class="message-section">
		<img alt="homepage" src="/assets/icons/google-icon-channel.svg" />
		<div *ngIf="!hasConnected">
			<h3>Oh...There's no {{ missingAccount }} account connected.</h3>
			<p>Would you want to connect your account to Lolly?</p>
		</div>
		<div *ngIf="hasConnected">
			<h4>Your {{ missingAccount }} account is now linked with Lolly.</h4>
			<h4>You need to log in again in order for the changes to take effect.</h4>
		</div>
	</div>

	<!--btn section-->
	<div *ngIf="!hasConnected" class="wrap-section-btn">
		<app-button
			(buttonClicked)="onNoClick()"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="GO BACK"
		></app-button>
		<app-button (buttonClicked)="onClick()" [buttonClass]="buttonClassEnum.Primary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="CONNECT"></app-button>
	</div>

	<div *ngIf="hasConnected" class="signout-btn">
		<app-button
			(buttonClicked)="signOut()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			buttonLabel="Sign out"
		></app-button>
	</div>
</div>
