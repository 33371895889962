<div>
	<app-expansion-panel-sc [isSelected]="false" [expansionTitle]="'Message Template'" [isExpandable]="false">
		<div expansion-content>
			<font07>Set up the automated questions people will answer in Messenger. Then follow up with leads in your Page's Inbox.</font07>
			<mat-radio-group class="oc-sub-objectives-wrapper">
				<div class="oc-radio-btn-container oc-sub-objective-radio-btn">
					<mat-radio-button (click)="updateInstantChat('Create New')" [value]="'Create New'" name="sharing">
						<span class="oc-bold-text">Create New</span>
					</mat-radio-button>
					<p class="oc-radio-btn-description">
						<font10>
							Collect information from people interested in your business and continue the conversation with qualified leads directly in
							Messenger.
							<a>Learn More</a>
						</font10>
					</p>
					<br />
					<btn24 (onClick)="openDialog()">Create Chat</btn24>
					<br />
				</div>
				<div class="oc-radio-btn-container oc-sub-objective-radio-btn">
					<mat-radio-button (click)="updateInstantChat('Use Existing')" [value]="'Use Existing'" name="sharing">
						<span class="oc-bold-text">Use Existing</span>
					</mat-radio-button>
					<p class="oc-radio-btn-description">
						<font10>
							Use, edit or duplicate an existing template you've already created.
							<a>Learn More</a>
						</font10>
					</p>
				</div>
			</mat-radio-group>
		</div>
	</app-expansion-panel-sc>
</div>
