import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../utils';

@Pipe({
	name: 'ngbToMomentToString'
})
export class NgbToMomentToString implements PipeTransform {
	transform(ngbDate: NgbDate, dateFormat: string): string {
		if (!ngbDate) {
			return;
		}

		return UtilsService.ngbDateToMoment(ngbDate).format(dateFormat);
	}
}
