import { CatalogPlatform } from './catalog-platform.enum';

export enum CatalogImages {
	Shopify = 'assets/images/catalog-product/shopifyImage.png',
	WooCommerce = 'assets/images/catalog-product/woocommerce-logo.png',
	BigCommerce = 'assets/images/catalog-product/BigCommerce-logomark-whitebg.png',
	Magento = 'assets/images/catalog-product/magento-logo.png',
	Csv = 'assets/images/catalog-product/download (2).png',
	Xml = 'assets/images/catalog-product/newml.png'
}
export const getCatalogImage = (catalog: string): string => {
	switch (catalog) {
		case CatalogPlatform.Shopify:
			return CatalogImages.Shopify;
		case CatalogPlatform.BigCommerce:
			return CatalogImages.BigCommerce;
		case CatalogPlatform.WooCommerce:
			return CatalogImages.WooCommerce;
		case CatalogPlatform.Magento:
			return CatalogImages.Magento;
		case CatalogPlatform.Csv:
			return CatalogImages.Csv;
		case CatalogPlatform.Xml:
			return CatalogImages.Xml;
		default:
			return 'assets/images/catalog-product/filed-f-icon.svg';
	}
};
