import { Action } from '@ngrx/store';
import { ReportModel } from '../models/report.model';
import { DashboardModel } from '../models/dashboard.model';
import { DashboardSmartCreateModel } from '../components/smart-create/dashboard-smart-create.model';
import { ScheduleDataModel } from '../models/schedule-data.model';

export enum ReportingActionTypes {
	// ======= REPORT =======
	LoadReports = '[Reporting] Load Reports',
	LoadSuccessReports = ' [Reporting] Load Success Reports',
	GetReportsById = '[Reporting] Get Reports By Id',
	GetReportsByIdSuccess = '[Reporting] Get Reports By Id Success',
	ReportSaved = '[Reporting] Report Created/Updated',
	CreateReport = '[Reporting] Create Report',
	CreateReportSuccess = '[Reporting] Report Successfully Created',
	CreateReportFail = '[Reporting] Report Failed To Be Created',
	UpdateReport = '[Reporting] Update Report',
	UpdateReportSuccess = '[Reporting] Report Successfully Updated',
	UpdateReportFail = '[Reporting] Report Failed To Be Updated',
	DeleteReport = '[Reporting] Delete Report',
	DeleteReportSuccess = '[Reporting] Report Successfully Deleted',
	DeleteReportFail = '[Reporting] Report Failed To Be Deleted',
	LoadSchedules = '[Reporting] Schedules has loaded',
	LoadSchedulesSuccess = '[Reporting] Schedules has loaded successfully',
	LoadSchedulesFailed = '[Reporting] Schedules loading failed',

	// ======= REPORT TEMPLATES =======
	ReportTemplateSaved = '[Reporting] Report Template Created/Updated',
	LoadReportTemplates = '[Reporting] Load Report Templates',
	LoadSuccessReportTemplates = '[Reporting] Load Success Report Templates',
	GetReportTemplateById = '[Reporting] Get Report Template By Id',
	GetReportTemplateByIdSuccess = '[Reporting] Get Report Template By Id Success',
	GetReportTemplateByIdFailure = '[Reporting] Get Report Template By Id Failure',
	CreateReportTemplate = '[Reporting] Create Report Template',
	CreateReportTemplateSuccess = '[Reporting] Report Template Successfully Created',
	CreateReportTemplateFail = '[Reporting] Report Template Failed To Be Created',
	UpdateReportTemplate = '[Reporting] Update Report Template',
	UpdateReportTemplateSuccess = '[Reporting] Report Template Successfully Updated',
	UpdateReportTemplateFail = '[Reporting] Report Template Failed To Be Updated',
	DeleteReportTemplate = '[Reporting] Delete Report Template',
	DeleteReportTemplateSuccess = '[Reporting] Report Template Successfully Deleted',
	DeleteReportTemplateFail = '[Reporting] Report Template Failed To Be Deleted',

	// ======= DASHBOARDS =======
	LoadDashboards = '[Reporting] Load Dashboards',
	GetDashboardById = '[Reporting] Get Dashboard By Id',
	GetDefaultDashboard = '[Reporting] Get Default Dashboard',
	GetDashboardByIdSuccess = '[Reporting] Get Dashboard By Id Success',
	GetDefaultDashboardByIdSuccess = '[Reporting] Get Default Dashboard By Id  Success',
	GetDefaultDashboardByIdFailed = '[Reporting] Get Default Dashboard By Id  Failed',
	GetDashboardByIdFailed = '[Reporting] Get Dashboard By Id Failed',
	LoadSuccessDashboards = '[Reporting] Load Success Dashboards',
	LoadFailureDashboards = '[Reporting] Load Failure Dashboards',
	CreateDashboard = '[Reporting] Create Dashboard',
	CreateDashboardSuccess = '[Reporting] Dashboard Successfully Created',
	CreateDashboardFail = '[Reporting] Dashboard Failed To Be Created',
	DashboardSaved = '[Reporting] Dashboard Created/Updated',
	UpdateDashboard = '[Reporting] Update Dashboard',
	UpdateDashboardSuccess = '[Reporting] Dashboard Successfully Updated',
	UpdateDashboardFail = '[Reporting] Dashboard Failed To Be Updated',
	DeleteDashboard = '[Reporting] Delete Dashboard',
	DeleteDashboardSuccess = '[Reporting] Dashboard Successfully Deleted',
	DeleteDashboardFail = '[Reporting] Dashboard Failed To Be Deleted',

	// ======= DASHBOARD TEMPLATES =======
	LoadDashboardTemplates = '[Reporting] Load Dashboard Templates',
	GetDashboardTemplateById = '[Reporting] Get Dashboard Template By Id',
	GetDashboardTemplateByIdSuccess = '[Reporting] Get Dashboard Template By Id Success',
	GetDashboardTemplateByIdFailure = '[Reporting] Get Dashboard Template By Id Failure',
	LoadSuccessDashboardTemplates = '[Reporting] Load Success Dashboard Templates',
	LoadFailedDashboardTemplates = '[Reporting] Load Failed Dashboard Templates',
	CreateDashboardTemplate = '[Reporting] Create Dashboard Template',
	CreateDashboardTemplateSuccess = '[Reporting] Dashboard Template Successfully Created',
	CreateDashboardTemplateFail = '[Reporting] Dashboard Template Failed To Be Created',
	DashboardTemplateSaved = '[Reporting] Dashboard Template Created/Updated',
	UpdateDashboardTemplate = '[Reporting] Update Dashboard Template',
	UpdateDashboardTemplateSuccess = '[Reporting] Dashboard Template Successfully Updated',
	UpdateDashboardTemplateFail = '[Reporting] Dashboard Template Failed To Be Updated',
	DeleteDashboardTemplate = '[Reporting] Delete Dashboard Template',
	DeleteDashboardTemplateSuccess = '[Reporting] Dashboard Template Successfully Deleted',
	DeleteDashboardTemplateFail = '[Reporting] Dashboard Template Failed To Be Deleted',
	SetSmartCreateSelection = '[Reporting] Setting Smart Create Selection',
	ClearSmartCreateSelection = '[Reporting] Clear Smart Create Selection'
}

// ======= ACTIONS =======
// ======= REPORT =======

export class LoadReports implements Action {
	public readonly type = ReportingActionTypes.LoadReports;

	constructor() {}
}

export class GetReportsById implements Action {
	public readonly type = ReportingActionTypes.GetReportsById;

	constructor(public payload: number) {}
}

export class LoadSuccessReports implements Action {
	public readonly type = ReportingActionTypes.LoadSuccessReports;

	constructor(public payload: ReportModel[]) {}
}

export class GetReportsByIdSuccess implements Action {
	public readonly type = ReportingActionTypes.GetReportsByIdSuccess;

	constructor(public payload: ReportModel) {}
}

export class ReportSaved implements Action {
	public readonly type = ReportingActionTypes.ReportSaved;

	constructor(public payload: number) {}
}

export class CreateReport implements Action {
	public readonly type = ReportingActionTypes.CreateReport;

	constructor(public payload: ReportModel) {}
}

export class CreateReportSuccess implements Action {
	public readonly type = ReportingActionTypes.CreateReportSuccess;

	constructor(public payload: ReportModel) {}
}

export class CreateReportFail implements Action {
	public readonly type = ReportingActionTypes.CreateReportFail;

	constructor(public payload: string) {}
}

export class UpdateReport implements Action {
	public readonly type = ReportingActionTypes.UpdateReport;

	constructor(public payload: ReportModel) {}
}

export class UpdateReportSuccess implements Action {
	public readonly type = ReportingActionTypes.UpdateReportSuccess;

	constructor(public payload: ReportModel) {}
}

export class UpdateReportFail implements Action {
	public readonly type = ReportingActionTypes.UpdateReportFail;

	constructor(public payload: string) {}
}

export class DeleteReport implements Action {
	public readonly type = ReportingActionTypes.DeleteReport;

	constructor(public payload: number) {}
}

export class DeleteReportSuccess implements Action {
	public readonly type = ReportingActionTypes.DeleteReportSuccess;

	constructor(public payload: number) {}
}

export class DeleteReportFail implements Action {
	public readonly type = ReportingActionTypes.DeleteReportFail;

	constructor(public payload: string) {}
}

// ======= REPORT TEMPLATES =======

export class LoadReportTemplates implements Action {
	public readonly type = ReportingActionTypes.LoadReportTemplates;

	constructor() {}
}

export class LoadSuccessReportTemplates implements Action {
	public readonly type = ReportingActionTypes.LoadSuccessReportTemplates;

	constructor(public payload: ReportModel[]) {}
}

export class GetReportTemplateById implements Action {
	public readonly type = ReportingActionTypes.GetReportTemplateById;

	constructor(public payload: number) {}
}

export class GetReportTemplateByIdSuccess implements Action {
	public readonly type = ReportingActionTypes.GetReportTemplateByIdSuccess;

	constructor(public payload: ReportModel) {}
}

export class GetReportTemplateByIdFailure implements Action {
	public readonly type = ReportingActionTypes.GetReportTemplateByIdFailure;

	constructor(public payload: string) {}
}

export class CreateReportTemplate implements Action {
	public readonly type = ReportingActionTypes.CreateReportTemplate;

	constructor(public payload: ReportModel) {}
}

export class CreateReportTemplateSuccess implements Action {
	public readonly type = ReportingActionTypes.CreateReportTemplateSuccess;

	constructor(public payload: ReportModel) {}
}

export class CreateReportTemplateFail implements Action {
	public readonly type = ReportingActionTypes.CreateReportTemplateFail;

	constructor(public payload: string) {}
}

export class ReportTemplateSaved implements Action {
	public readonly type = ReportingActionTypes.ReportTemplateSaved;

	constructor(public payload: number) {}
}

export class LoadSchedules implements Action {
	public readonly type = ReportingActionTypes.LoadSchedules;

	constructor(public readonly payload: boolean = false) {}
}

export class LoadSchedulesSuccess implements Action {
	public readonly type = ReportingActionTypes.LoadSchedulesSuccess;

	constructor(public payload: ScheduleDataModel[]) {}
}

export class LoadSchedulesFailed implements Action {
	public readonly type = ReportingActionTypes.LoadSchedulesFailed;
	constructor(public payload: string) {}
}

export class UpdateReportTemplate implements Action {
	public readonly type = ReportingActionTypes.UpdateReportTemplate;

	constructor(public payload: ReportModel) {}
}

export class UpdateReportTemplateSuccess implements Action {
	public readonly type = ReportingActionTypes.UpdateReportTemplateSuccess;

	constructor(public payload: ReportModel) {}
}

export class UpdateReportTemplateFail implements Action {
	public readonly type = ReportingActionTypes.UpdateReportTemplateFail;

	constructor(public payload: string) {}
}

export class DeleteReportTemplate implements Action {
	public readonly type = ReportingActionTypes.DeleteReportTemplate;

	constructor(public payload: number) {}
}

export class DeleteReportTemplateSuccess implements Action {
	public readonly type = ReportingActionTypes.DeleteReportTemplateSuccess;

	constructor(public payload: number) {}
}

export class DeleteReportTemplateFail implements Action {
	public readonly type = ReportingActionTypes.DeleteReportTemplateFail;

	constructor(public payload: string) {}
}

// ======= DASHBOARDS =======

export class LoadDashboards implements Action {
	public readonly type = ReportingActionTypes.LoadDashboards;

	constructor() {}
}

export class LoadSuccessDashboards implements Action {
	public readonly type = ReportingActionTypes.LoadSuccessDashboards;

	constructor(public payload: DashboardModel[]) {}
}

export class LoadFailureDashboards implements Action {
	public readonly type = ReportingActionTypes.LoadFailureDashboards;

	constructor(public payload: string) {}
}

export class GetDashboardById implements Action {
	public readonly type = ReportingActionTypes.GetDashboardById;

	constructor(public payload: number | string) {}
}

export class GetDefaultDashboard implements Action {
	public readonly type = ReportingActionTypes.GetDefaultDashboard;

	constructor() {}
}

export class GetDashboardByIdSuccess implements Action {
	public readonly type = ReportingActionTypes.GetDashboardByIdSuccess;

	constructor(public payload: DashboardModel) {}
}

export class GetDefaultDashboardByIdSuccess implements Action {
	public readonly type = ReportingActionTypes.GetDefaultDashboardByIdSuccess;

	constructor(public payload: DashboardModel) {}
}

export class GetDefaultDashboardByIdFailed implements Action {
	public readonly type = ReportingActionTypes.GetDefaultDashboardByIdFailed;

	constructor(public payload: string) {}
}

export class GetDashboardByIdFailed implements Action {
	public readonly type = ReportingActionTypes.GetDashboardByIdFailed;

	constructor(public payload: string) {}
}

export class DashboardSaved implements Action {
	public readonly type = ReportingActionTypes.DashboardSaved;

	constructor(public payload: number | string) {}
}

export class CreateDashboard implements Action {
	public readonly type = ReportingActionTypes.CreateDashboard;

	constructor(public payload: DashboardModel) {}
}

export class CreateDashboardSuccess implements Action {
	public readonly type = ReportingActionTypes.CreateDashboardSuccess;

	constructor(public payload: DashboardModel) {}
}

export class CreateDashboardFail implements Action {
	public readonly type = ReportingActionTypes.CreateDashboardFail;

	constructor(public payload: string) {}
}

export class UpdateDashboard implements Action {
	public readonly type = ReportingActionTypes.UpdateDashboard;

	constructor(public payload: DashboardModel) {}
}

export class UpdateDashboardSuccess implements Action {
	public readonly type = ReportingActionTypes.UpdateDashboardSuccess;

	constructor(public payload: DashboardModel) {}
}

export class UpdateDashboardFail implements Action {
	public readonly type = ReportingActionTypes.UpdateDashboardFail;

	constructor(public payload: string) {}
}

export class DeleteDashboard implements Action {
	public readonly type = ReportingActionTypes.DeleteDashboard;

	constructor(public payload: number | string) {}
}

export class DeleteDashboardSuccess implements Action {
	public readonly type = ReportingActionTypes.DeleteDashboardSuccess;

	constructor(public payload: number) {}
}

export class DeleteDashboardFail implements Action {
	public readonly type = ReportingActionTypes.DeleteDashboardFail;

	constructor(public payload: string) {}
}

// ======= DASHBOARD TEMPLATES =======

export class LoadDashboardTemplates implements Action {
	public readonly type = ReportingActionTypes.LoadDashboardTemplates;

	constructor() {}
}

export class LoadSuccessDashboardTemplates implements Action {
	public readonly type = ReportingActionTypes.LoadSuccessDashboardTemplates;

	constructor(public payload: DashboardModel[]) {}
}

export class LoadFailedDashboardTemplates implements Action {
	public readonly type = ReportingActionTypes.LoadFailedDashboardTemplates;

	constructor(public payload: string) {}
}

export class GetDashboardTemplateById implements Action {
	public readonly type = ReportingActionTypes.GetDashboardTemplateById;

	constructor(public payload: number) {}
}

export class GetDashboardTemplateByIdSuccess implements Action {
	public readonly type = ReportingActionTypes.GetDashboardTemplateByIdSuccess;

	constructor(public payload: DashboardModel) {}
}

export class GetDashboardTemplateByIdFailure implements Action {
	public readonly type = ReportingActionTypes.GetDashboardTemplateByIdFailure;

	constructor(public payload: string) {}
}

export class CreateDashboardTemplate implements Action {
	public readonly type = ReportingActionTypes.CreateDashboardTemplate;

	constructor(public payload: DashboardModel) {}
}

export class CreateDashboardTemplateSuccess implements Action {
	public readonly type = ReportingActionTypes.CreateDashboardTemplateSuccess;

	constructor(public payload: DashboardModel) {}
}

export class CreateDashboardTemplateFail implements Action {
	public readonly type = ReportingActionTypes.CreateDashboardTemplateFail;

	constructor(public payload: string) {}
}

export class UpdateDashboardTemplate implements Action {
	public readonly type = ReportingActionTypes.UpdateDashboardTemplate;

	constructor(public payload: DashboardModel) {}
}

export class UpdateDashboardTemplateSuccess implements Action {
	public readonly type = ReportingActionTypes.UpdateDashboardTemplateSuccess;

	constructor(public payload: DashboardModel) {}
}

export class UpdateDashboardTemplateFail implements Action {
	public readonly type = ReportingActionTypes.UpdateDashboardTemplateFail;

	constructor(public payload: string) {}
}

export class DashboardTemplateSaved implements Action {
	public readonly type = ReportingActionTypes.DashboardTemplateSaved;

	constructor(public payload: number | string) {}
}

export class DeleteDashboardTemplate implements Action {
	public readonly type = ReportingActionTypes.DeleteDashboardTemplate;

	constructor(public payload: number) {}
}

export class DeleteDashboardTemplateSuccess implements Action {
	public readonly type = ReportingActionTypes.DeleteDashboardTemplateSuccess;

	constructor(public payload: number) {}
}

export class DeleteDashboardTemplateFail implements Action {
	public readonly type = ReportingActionTypes.DeleteDashboardTemplateFail;

	constructor(public payload: string) {}
}

export class SetSmartCreateSelection implements Action {
	public readonly type = ReportingActionTypes.SetSmartCreateSelection;

	constructor(public payload: DashboardSmartCreateModel) {}
}

export class ClearSmartCreateSelection implements Action {
	public readonly type = ReportingActionTypes.ClearSmartCreateSelection;

	constructor() {}
}

export type ReportingAction =
	| GetDefaultDashboardByIdFailed
	| GetDefaultDashboardByIdSuccess
	| GetDashboardTemplateByIdFailure
	| LoadFailureDashboards
	| GetReportTemplateByIdFailure
	| LoadSuccessReports
	| CreateDashboard
	| CreateDashboardTemplate
	| LoadSuccessReportTemplates
	| LoadSuccessDashboards
	| LoadSuccessDashboardTemplates
	| LoadReports
	| LoadReportTemplates
	| LoadDashboards
	| LoadDashboardTemplates
	| CreateReport
	| CreateReportTemplate
	| CreateReportSuccess
	| CreateReportTemplateSuccess
	| CreateDashboardSuccess
	| CreateDashboardTemplateSuccess
	| CreateReportFail
	| CreateReportTemplateFail
	| CreateDashboardFail
	| CreateDashboardTemplateFail
	| UpdateReport
	| UpdateReportTemplate
	| UpdateDashboard
	| UpdateDashboardTemplate
	| UpdateReportSuccess
	| UpdateReportTemplateSuccess
	| UpdateDashboardSuccess
	| UpdateDashboardTemplateSuccess
	| UpdateReportFail
	| UpdateReportTemplateFail
	| UpdateDashboardFail
	| UpdateDashboardTemplateFail
	| ReportSaved
	| ReportTemplateSaved
	| LoadSchedules
	| LoadSchedulesSuccess
	| LoadSchedulesFailed
	| DeleteReport
	| DeleteReportSuccess
	| DeleteReportFail
	| DeleteReportTemplate
	| DeleteReportTemplateSuccess
	| DeleteReportTemplateFail
	| DeleteDashboard
	| DeleteDashboardSuccess
	| DeleteDashboardFail
	| DashboardSaved
	| DashboardTemplateSaved
	| DeleteDashboardTemplate
	| DeleteDashboardTemplateSuccess
	| DeleteDashboardTemplateFail
	| SetSmartCreateSelection
	| ClearSmartCreateSelection
	| GetReportsById
	| GetReportTemplateById
	| GetDashboardById
	| GetDashboardTemplateById
	| GetReportsByIdSuccess
	| GetReportTemplateByIdSuccess
	| GetDashboardByIdSuccess
	| GetDashboardTemplateByIdSuccess
	| GetDefaultDashboard
	| GetDashboardByIdFailed;
