<div class="create-ad-container">
	<h4 class="header">Create Ad Set</h4>
	<div class="layout">
		<div class="quick-navigation">
			<app-quick-navigation [navigations]="navigations"></app-quick-navigation>
		</div>
		<div class="adset-components" QuickNavigationScroll>
			<div [ngClass]="{ 'dexter-adset': isDexter }" class="scs-expansion-panel adSetName" id="adSetName" qn-scroll-item>
				<app-name-expansion-panel
					[control]="adSetSettingsFormGroup.get('adSetName')"
					(click)="handlePanelClick(panels.AdSetName, 0)"
					[isSelected]="expansionPanelHelper.isPanelSelected(panels.AdSetName)"
					[expansionTitle]="'Name your Ad Set'"
					[label]="label"
				></app-name-expansion-panel>
			</div>

			<div class="scs-expansion-panel pages" id="select-page" *ngIf="showPageComponent" qn-scroll-item>
				<app-pages-card
					(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Pages)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Pages)"
					[pagesFormGroup]="adSetSettingsFormGroup.get('pages')"
					[facebookPages]="facebookPages"
					[instagramPages]="instagramPages"
					[selectedObjective]="objectiveTree"
				></app-pages-card>
			</div>

			<div
				*ngIf="
					!isDexter ||
					(isDexter && currentStep.dexterAdsetBudgetLevel !== 'campaign') ||
					(isDexter && currentStep.dexterAdsetBudgetLevel === 'campaign' && currentStep.budgetOptimization.amount === 0)
				"
				class="scs-expansion-panel adSetBudget"
				id="bs"
				qn-scroll-item
			>
				<app-budget-and-schedule-card
					[currency]="currency"
					[validBudget]="validBudget"
					[adSetBudget]="adSetSettingsFormGroup.get('adSetBudget')"
					[campaignBudgetOptimization]="campaignBudgetOptimization"
					(onExpansionClick)="handlePanelClick(panels.Budget, 2)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Budget)"
				></app-budget-and-schedule-card>
			</div>

			<div class="scs-expansion-panel" id="date" qn-scroll-item>
				<app-date-card
					*ngIf="!campaignBudgetOptimization"
					(campaignDateStatus)="updateCampaignDateStatus($event)"
					[preselectedDate]="preselectedDate"
					[isCampaignLifeTimeSelected]="isCampaignLifeTimeSelected"
					[isAdSetLifeTimeSelected]="isAdSetLifeTimeSelected"
					[campaignDate]="campaignDate"
					[dateChoiceType]="dateChoiceType"
					(onExpansionClick)="handlePanelClick(panels.Date, 2)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Date)"
				></app-date-card>
			</div>
			<div (click)="onPanelClick(2)" class="scs-expansion-panel chooseCatalogSet" id="choose-catalog-set" qn-scroll-item *ngIf="isCatalogSales">
				<app-catalog-set-selector
					[productCatalogSets]="productCatalogSets"
					[productCatalogSetFormControl]="this.adSetSettingsFormGroup.get('productCatalogSetFormControl')"
				></app-catalog-set-selector>
			</div>

			<div class="scs-expansion-panel targeting locations" id="audience" qn-scroll-item>
				<!-- <app-targeting-card
									[selectedAdAccount]="selectedAdAccount"
									[adCategory]="adCategory"
									[targeting]="targeting"
									[savedAudiences]="savedAudiences"
									[targetingControl]="adSetSettingsFormGroup.get('locations')"
									[isSpecialAdCategoryOn]="isSpecialAdCategoryOn"
									[adAccountFacebookId]="adAccountFacebookId"
									[optimizationGoal]="optimizationGoal"
									(onUpdateTargeting)="updateTargeting($event)"
									(audienceSave)="saveAudience($event)"
									(audienceSize)="updateAudienceGraph($event)"
									(modifyAudience)="updateAudience($event)"
									(retargetingAudienceSpecs)="getRetargetingAudienceSpec($event)"
									[productSetId]="productSetId"
									[regulatedInterests]="regulatedInterests"
									[interests]="interests"
									[validationTrigger]="validationTrigger"
									(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Targeting)"
									[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Targeting)"
									[budgetAmount]="adSetSettingsFormGroup.get('adSetBudget').value"
									[selectedObjective]="objectiveTree.subObjective"
									[productCatalogSets]="productCatalogSets"
									[isDexter]="isDexter"
								></app-targeting-card> -->
				<app-targeting-card
					[selectedAdAccount]="selectedAdAccount"
					[adCategory]="adCategory"
					[targeting]="targeting"
					[savedAudiences]="savedAudiences"
					[targetingControl]="adSetSettingsFormGroup.get('locations')"
					[isSpecialAdCategoryOn]="isSpecialAdCategoryOn"
					[adAccountFacebookId]="adAccountFacebookId"
					[optimizationGoal]="optimizationGoal"
					(onUpdateTargeting)="updateTargeting($event)"
					(audienceSave)="saveAudience($event)"
					(audienceSize)="updateAudienceGraph($event)"
					(retargetingAudienceSpecs)="getRetargetingAudienceSpec($event)"
					[productSetId]="productSetId"
					[regulatedInterests]="regulatedInterests"
					[interests]="interests"
					[validationTrigger]="validationTrigger"
					(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Targeting)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Targeting)"
					[budgetAmount]="adSetSettingsFormGroup.get('adSetBudget').value"
					[selectedObjective]="objectiveTree.subObjective"
					[productCatalogSets]="productCatalogSets"
					[isDexter]="isDexter"
				></app-targeting-card>
			</div>

			<div class="scs-expansion-panel targeting locations" id="devices" qn-scroll-item>
				<app-devices
					(click)="handlePanelClick(panels.Devices, 4)"
					(onDevice)="onDeviceChange($event)"
					(onDeviceOs)="onDeviceOsChange($event)"
					[selectedDevice]="device"
					[selectedDeviceOs]="deviceos"
				></app-devices>
			</div>

			<div class="scs-expansion-panel placements" id="placements" qn-scroll-item>
				<app-placements
					[selectedPlacements]="placements"
					[platformsPerDevice]="platformsPerDevice"
					[placementsControl]="adSetSettingsFormGroup.get('placements')"
					[objective]="subObjective"
					[platforms]="platforms"
					(onUpdatePlacements)="updatePlacements($event)"
					(onUpdatePlacementPerPlatform)="updatePlacementPerPlatform($event)"
					(onExpansionClick)="handlePanelClick(panels.Placements, 5)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Placements)"
					[isInstagramSelected]="adSetSettingsFormGroup.get('pages').get('instagramId').value"
				></app-placements>
			</div>

			<!-- <app-optimistation-delivery></app-optimistation-delivery> -->
			<div class="scs-expansion-panel optimizationDelivery" id="od" qn-scroll-item>
				<app-optimistation-delivery
					[optimizationGoalList]="optimizationGoalList"
					[clicks]="clicks"
					[views]="views"
					[billingEvents]="billingEventsList"
					[validations]="validations"
					[objectiveWithDestination]="objectiveWithDestination"
					[subObjective]="subObjective"
					[optimizationDelivery]="adSetSettingsFormGroup.get('optimizationDelivery')"
					(onExpansionClick)="handlePanelClick(panels.Optimization, 6)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Optimization)"
				></app-optimistation-delivery>
			</div>
		</div>
		<div class="audience-size">
			<div class="graph-card">
				<font07>Audience Size</font07>
				<font08-4 *ngIf="targeting?.size !== undefined">{{ targeting.size }} people</font08-4>
				<div class="row bar-area">
					<div *ngFor="let bar of bars; let i = index">
						<div
							[style.height]="bar.height"
							[style.opacity]="((i + 1) / 10).toString()"
							class="bar"
							[ngClass]="{
								'bar-active': bar.isActive,
								'bar-non-active': !bar.isActive
							}"
						></div>
					</div>
				</div>
				<div class="bar-area-underline"></div>
				<div class="audience-result">
					<font08-4>{{ audienceResult }}</font08-4>
				</div>
			</div>
			<div class="progress-card">
				<progress-card [sections]="progressCardObject"></progress-card>
			</div>
		</div>
	</div>
</div>

<popup01 [opened]="opened" (close)="closeCreateNewPopUp()" *ngIf="createPopUpData">
	<div class="dialog-content">
		<img class="dialog-image" height="48" alt="Password" src="assets/icons/edit-pencil-blue.svg" />
		<font05>{{ createPopUpData.title }}</font05>
		<font08>{{ createPopUpData.subTitle }}</font08>
	</div>
	<div dialog-actions class="dialog-buttons">
		<btn07 (onClick)="createNew(createPopUpData.type)">Create New</btn07>
		<btn01 (onClick)="createWithBestPerforming(createPopUpData.type)">Select existing ads</btn01>
	</div>
</popup01>
