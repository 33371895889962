<div [ngClass]="{ 'selected-panel': isSelected }" class="expansion-panel-card">
	<div class="expansion-panel-title">
		<div class="title-info-expansion">
			<div class="titles">
				<h5 *ngIf="expansionTitle">{{ expansionTitle }}</h5>
				<h5 *ngIf="expansionSecondLine">{{ expansionSecondLine }}</h5>
			</div>
			<div class="info-hover" *ngIf="infoHoverModel">
				<info-tooltip [title]="infoHoverModel.message" [width]="457"></info-tooltip>
			</div>
		</div>
		<p *ngIf="secondaryTitle">{{ secondaryTitle }}</p>
	</div>
	<div #scrollMe class="expansion-panel-content" [ngClass]="{ 'expansion-panel-content-margin': contentMargin }">
		<h6 *ngIf="subTitle">{{ subTitle }}</h6>
		<ng-content *ngIf="isExpanded" select="[expansion-content]"></ng-content>
	</div>
	<div *ngIf="isExpandable" class="expansion-panel-btn">
		<img (click)="onExpandClick(scrollMe)" alt="expansion-icon" src="{{ imagePath }}" />
	</div>
</div>
