import { Injectable } from '@angular/core';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { InsightsCategoryTypeEnum } from 'src/app/shared/campaign-insights/models/insights-category-type.enum';
import { Action } from '@ngrx/store';
import {
	LoadFacebookCampaignsBusinessViews,
	LoadGoogleCampaignsBusinessViews,
	LoadGoogleAdgroupsBusinessViews,
	LoadGoogleAdsBusinessViews,
	LoadGoogleKeywordsBusinessViews,
	LoadFacebookAdsBusinessViews,
	LoadFacebookAdsetsBusinessViews
} from '../ads-manager.actions';

@Injectable({
	providedIn: 'root'
})
export class AdsManagerLoadBusinessViewsFactory {
	public getInstance(channel: SourceChannel, category: InsightsCategoryTypeEnum): Action {
		switch (channel) {
			case SourceChannel.Facebook:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return new LoadFacebookCampaignsBusinessViews();
					case InsightsCategoryTypeEnum.AdSet:
						return new LoadFacebookAdsetsBusinessViews();
					case InsightsCategoryTypeEnum.Ad:
						return new LoadFacebookAdsBusinessViews();
				}
				break;

			case SourceChannel.Google:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return new LoadGoogleCampaignsBusinessViews();
					case InsightsCategoryTypeEnum.AdGroup:
						return new LoadGoogleAdgroupsBusinessViews();
					case InsightsCategoryTypeEnum.Ad:
						return new LoadGoogleAdsBusinessViews();
					case InsightsCategoryTypeEnum.Keyword:
						return new LoadGoogleKeywordsBusinessViews();
				}
				break;

			default:
				return null;
		}
	}
}
