import { Component, Input, OnInit } from '@angular/core';
import { InfoHoverDataModel } from '../info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-expansion-panel',
	templateUrl: './expansion-panel.component.html',
	styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {
	@Input() public isSelected: boolean;
	@Input() public isExpanded = true;
	@Input() public isExpandable = true;
	@Input() public expansionTitle: string;
	@Input() public subTitle: string;
	@Input() public infoHoverModel: InfoHoverDataModel;
	@Input() public secondaryTitle: string;
	@Input() public contentMargin = true;
	@Input() public hasToggle: boolean;

	public imagePath: string;
	private expandedImagePath = '../../../assets/icons/arrow-up.svg';
	private closedImagePath = '../../../assets/icons/arrow-down.svg';

	constructor() {}

	ngOnInit(): void {
		this.setImagePath();
	}

	public onExpandClick(element: HTMLElement): void {
		this.isExpanded = !this.isExpanded;
		if (this.isExpanded) {
			element.scrollIntoView({ block: 'center' });
		}
		this.setImagePath();
	}

	private setImagePath() {
		this.imagePath = this.isExpanded ? this.expandedImagePath : this.closedImagePath;
	}
}
