import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabList } from '../../back-office/users/tabs-list.interface';
import { IndexTitlesEnum } from '../../back-office/users/index-titles.enum';
import { TitleUsersEnum } from '../../back-office/users/title-users.enum';

@Component({
	selector: 'app-table-tabs-items',
	templateUrl: './table-tabs-items.component.html',
	styleUrls: ['./table-tabs-items.component.scss']
})
export class TableTabsItemsComponent implements OnInit {
	@Input() tabs: TabList[];
	@Output() onClick: EventEmitter<TitleUsersEnum> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}

	public selectTab(selected: TitleUsersEnum): void {
		this.onClick.emit(selected);
	}
}
