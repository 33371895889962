import { AssetType } from './asset-type.enum';
import { AssetReference } from './asset-reference.model';

export class Asset extends AssetReference {
	public businessOwnerId: number;
	public file: File;

	public constructor(businessOwnerFacebookId: number, file: File) {
		super();
		this.file = file;
		this.businessOwnerId = businessOwnerFacebookId;
	}
}
