import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';

export class ResetPasswordDialogConstants {
	public validationMessages: ValidatorMessages[] = [
		{
			validationType: 'email',
			validationMessage: 'Invalid email address.'
		},
		{
			validationType: 'required',
			validationMessage: 'This field is not valid.'
		},
		{
			validationType: 'notSame',
			validationMessage: 'Emails must match.'
		}
	];
}
