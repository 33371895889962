import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FieldValidators } from '../../user-management/models/validators';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { ErrorMessageService } from '../../shared/form-input/error-message.service';
import { Subscribe } from './subscribe.interface';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { UserManagementState } from '../../user-management/state/user-management.reducer';
import { StripeFormTypeEnum } from './form-status.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CountryDropdownValueInterface } from '../../shared/models/country-dropdown-value.interface';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
	public submitted = false;
	public error = false;
	public submitting = false;
	public emailSent = false;
	public accountForm: FormGroup;
	public termsControl: FormControl;
	public validationMessages: ValidatorMessages[];
	public errorMessage: string;
	public offerId: number;
	public subscribeData: Subscribe;
	public errorFormMessage: string;
	private unsubscriber$: Subject<void> = new Subject<void>();

	public defaultCountryCode = 'GB';
	public selectedCountry: CountryDropdownValueInterface;

	constructor(
		private formBuilder: FormBuilder,
		private errorMessageService: ErrorMessageService,
		private backOfficeService: BackOfficeService,
		private store: Store<UserManagementState>,
		private route: ActivatedRoute,
		private toastNotificationService: ToastNotificationService
	) {
		this.selectedCountry = {
			code: 'GB',
			dialcode: '+44'
		};
	}

	public ngOnInit(): void {
		this.errorMessages();
		this.createForm();
		this.formChanges();
		this.getOfferId();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public getValidationMessage(): ValidatorMessages[] {
		return [
			{
				validationType: 'dialCode',
				validationMessage: `Please select a country and type in your phone number `
			},
			{
				validationType: 'phoneMaxLength',
				validationMessage: `Please select the right country or correct the phone number`
			},
			{
				validationType: 'phoneMinLength',
				validationMessage: `Phone number cannot be less the 7 digits.
									Please select the correct country or type in the correct phone number `
			},
			{
				validationType: 'numbersOnly',
				validationMessage: `Please alphanumeric values not allowed `
			}
		];
	}

	public checkForInvalid(): void {
		const NDCandSN = this.accountForm.get('phone').value;
		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');
		if (dialCodeNumber === undefined) {
			this.accountForm.controls['phone'].setErrors({ dialCode: true });
		}

		if (NDCandSN !== null) {
			if (dialCodeNumber === undefined) {
				this.accountForm.controls['phone'].setErrors({ dialCode: true });
			}

			if (isNaN(Number(NDCandSN))) {
				this.accountForm.controls['phone'].setErrors({ numbersOnly: true });
			}

			if ((NDCandSN.toString() === '' || NDCandSN.toString().length < 7) && dialCodeNumber !== undefined) {
				this.accountForm.controls['phone'].setErrors({ phoneMinLength: true });
			}

			if (dialCodeNumber !== undefined && NDCandSN.toString().length > 7) {
				if (NDCandSN.toString().length + dialCodeNumber.length > 15) {
					this.accountForm.controls['phone'].setErrors({ phoneMaxLength: true });
				}
			}
		}
	}

	public formChanges(): void {
		this.accountForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
		this.accountForm.controls['phone'].updateValueAndValidity();
		this.accountForm.controls['dialcode'].updateValueAndValidity();
	}

	private createForm(): void {
		this.accountForm = this.formBuilder.group({
			name: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
			surname: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
			company: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
			dialcode: new FormControl(''),
			phone: new FormControl(null, [Validators.minLength(7), Validators.required, Validators.maxLength(11), Validators.pattern(FieldValidators.Phone)]),
			email: new FormControl(null, [Validators.required, Validators.maxLength(70), Validators.pattern(FieldValidators.Email)])
		});

		this.termsControl = new FormControl(null, [Validators.requiredTrue]);
	}

	public errorMessages(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(this.validationMessages, this.termsControl);
	}

	public getOfferId(): void {
		this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.offerId = params.id;
		});
	}

	public submitForm(): void {
		this.submitting = true;
		this.submitted = true;
		this.accountForm.updateValueAndValidity();
		this.accountForm.markAllAsTouched();
		this.termsControl.updateValueAndValidity();
		this.termsControl.markAsTouched();
		if (this.accountForm.status === StripeFormTypeEnum.valid && this.termsControl.status === StripeFormTypeEnum.valid) {
			this.subscribeData = {
				offerId: this.offerId,
				firstName: this.accountForm.get('name').value,
				lastName: this.accountForm.get('surname').value,
				companyName: this.accountForm.get('company').value,
				phoneNumber: this.selectedCountry.dialcode + this.accountForm.get('phone').value,
				email: this.accountForm.get('email').value
			};

			this.error = false;

			this.backOfficeService
				.subscribeToOfer(this.subscribeData)
				.toPromise()
				.catch(error => {
					this.error = true;
					if (error && error.error && error.error[0] && error.error[0].description && error.error[0].description.length) {
						this.toastNotificationService.sendErrorToast(error.error[0].description.split(':')[0]);
						this.errorFormMessage = error.error[0].description.split(':')[0];
					} else if (
						(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__Contact__ExistsWithSameCriteria') ||
						(error && error.error && error.error[0] && error.error[0].code === 'Filed__Domain__NA__Contact_Hubspot__ExistsWithSameCriteria')
					) {
						this.toastNotificationService.sendErrorToast('A user already exists with this email');
						this.errorFormMessage = 'A user already exists with this email';
					} else {
						this.toastNotificationService.sendErrorToast('Error while trying to create your account, please contact support team');
						this.errorFormMessage = 'Error while trying to create your account, please contact support team';
					}

					this.submitting = false;

					return;
				})
				.then(() => {
					this.submitting = false;

					if (!this.error) {
						this.emailSent = true;
						this.toastNotificationService.sendSuccessToast('Email was successfully sent.');
					}
				});
		} else {
			this.submitting = false;
		}
	}

	public resetEmail(): void {
		this.error = false;
		this.submitting = true;
		this.backOfficeService
			.resendActivationEmail(this.accountForm.get('email').value)
			.toPromise()
			.catch(error => {
				this.error = true;
				if (error && error.error && error.error[0] && error.error[0].description && error.error[0].description.length) {
					this.toastNotificationService.sendErrorToast(error.error[0].description.split(':')[0]);
				} else {
					this.toastNotificationService.sendErrorToast('Error while trying to send the email, please contact support team');
				}

				this.submitting = false;

				return;
			})
			.then(() => {
				this.submitting = false;

				if (!this.error) {
					this.toastNotificationService.sendSuccessToast('Email was successfully sent again.');
				}
			});
	}

	public onSelectCountry(event: CountryDropdownValueInterface): void {
		this.selectedCountry = event;

		const dialCodeNumber = this.selectedCountry?.dialcode.replace(/^\D+/g, '');

		this.accountForm.controls['phone'].setValidators(Validators.maxLength(15 - dialCodeNumber.length));

		const dropdownCountryCode = this.accountForm.get('dialcode');
		if (this.selectedCountry?.dialcode !== undefined) {
			dropdownCountryCode.setValidators(null);
		}

		if (this.selectedCountry?.dialcode === undefined) {
			dropdownCountryCode.setValidators([Validators.required]);
		}

		dropdownCountryCode.updateValueAndValidity();
	}
}
