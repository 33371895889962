<!--Popup content-->
<div class="popup-content" *ngIf="showPopup">
	<div class="popup-modal modal-effect">
		<div class="modal-content">
			<!--header-->
			<header>
				<h4>{{ titlePopup }}</h4>
				<!--X button -->
				<mat-icon (click)="onNoClick()">clear</mat-icon>
			</header>

			<div class="body">
				<div class="navigation">
					<stp01 (stepChange)="stepChange($event)" [current]="currentStep" [stepType]="'full'" [steps]="steps" [width]="'100%'"></stp01>
				</div>

				<!--generic main content -->
				<div class="popup-main-content">
					<app-user-details
						[activeStep]="!firstStep"
						[form]="userFromControl"
						[userData]="user"
						(formIsValid)="FormIsValid($event)"
						[hidden]="firstStep"
					></app-user-details>
					<app-user-account-access
						(isDoneLoading)="isDoneLoading()"
						[changes]="secondStepChanges"
						[activeStep]="!secondStep"
						(userData)="getAdAccounts($event)"
						[isEdit]="edit"
						[userDataId]="user.id"
						(formIsValid)="FormIsValid($event)"
						[hidden]="secondStep"
					></app-user-account-access>
					<app-user-permissions
						[activeStep]="!thirdStep"
						(userData)="getPermissions($event)"
						(formIsValid)="FormIsValid($event)"
						[permission]="userPermissions"
						[hidden]="thirdStep"
					></app-user-permissions>
				</div>
			</div>

			<!-- footer buttons-->
			<footer>
				<btn07 (onClick)="backButton()" [disabled]="backButtonDisable">
					<span class="d-flex">
						<mat-icon>keyboard_arrow_left</mat-icon>
						<span>
							{{ labelButtonLeftSide }}
						</span>
					</span>
				</btn07>
				<btn03 (onClick)="clickAction()" [disabled]="!nextButtonState">{{ labelButtonRightSide }}</btn03>
			</footer>
		</div>
	</div>
	<div class="modal-overlay"></div>
</div>
