<nav class="main-container">
	<div (click)="backToHome()" *ngIf="!kbService.knowledgebaseHome$.value" class="left-button">
		<button mat-button>
			<mat-icon>chevron_left</mat-icon>
			Back
		</button>
	</div>
	<div *ngIf="kbService.knowledgebaseHome$.value" class="avatar">
		<div class="avatar-text">
			<h4>Hi {{ user?.firstName }}</h4>
			<h6>Find your answers here</h6>
		</div>
	</div>
	<div class="right-actions">
		<mat-icon (click)="closeModal()">close</mat-icon>
	</div>
</nav>
