import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { hasBackOfficePermission, isUserImpersonated, UserState } from '../state/user/user.reducer';
import { map, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class UserRoleClientGuard implements CanActivate {
	private isImpersonated: boolean;
	constructor(public authService: AuthenticationService, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.authService.isLoggedIn()) {
			this.userStore.pipe(select(isUserImpersonated), take(1)).subscribe(res => {
				this.isImpersonated = res;
			});
			return this.userStore.pipe(
				select(hasBackOfficePermission),
				take(1),
				map(isAdmin => {
					if (isAdmin && !this.isImpersonated && state.url !== '/user-management/my-account' && state.url !== '/user-management/change-password') {
						this.router.navigate(['/back-office']);
						return false;
					}

					return true;
				})
			);
		}
		return of(false);
	}
}
