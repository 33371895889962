import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-facebook-list',
	templateUrl: './facebook-list.component.html',
	styleUrls: ['./facebook-list.component.scss']
})
export class FacebookListComponent implements OnInit {
	public groupSelectId: any[] = [];
	@Input() facebookAdsData: any;
	@Output() connectAccount = new EventEmitter<any>();
	public adsAccountFacebook: any[] = [];
	public selectedFacebookAds: any[] = [];
	constructor() {}

	ngOnInit(): void {
		this.adsAccountFacebook = this.facebookAdsData.accounts;
	}
	public selectAll(facebookAdsData: any[]): void {
		for (let i: number = 0; i < facebookAdsData.length; i++) {
			let el = document.getElementById(facebookAdsData[i].accountId) as HTMLInputElement;
			if (el.checked === false) {
				el.checked = true;
				this.selectFacebookAdsQue(facebookAdsData[i]);
			}
		}
	}
	public selectFacebookAdsQue(selectedFacebookAdsQue: any): void {
		let checkBox = document.getElementById(selectedFacebookAdsQue.accountId) as HTMLInputElement;
		if (checkBox?.checked === true) {
			selectedFacebookAdsQue.tracked = true;
			this.selectedFacebookAds.push(selectedFacebookAdsQue);
		} else {
			this.removeSelectedFacebookAdsQue(selectedFacebookAdsQue.accountId);
		}
	}
	public selectAndDEselectAllGroups(allDataAds: any): void {
		let checkBox = document.getElementById(allDataAds.businessOwnerId) as HTMLInputElement;
		if (checkBox?.checked === true) {
			this.selectAll(allDataAds.adaccounts);
		} else {
			this.deSelectAll(allDataAds.adaccounts);
		}
	}

	public removeSelectedFacebookAdsQue(id: string): void {
		for (let i: number = 0; i < this.selectedFacebookAds.length; i++) {
			if (this.selectedFacebookAds[i].accountId == id) {
				this.selectedFacebookAds[i].tracked = false;
				let checkBox = document.getElementById(this.selectedFacebookAds[i].accountId) as HTMLInputElement;
				checkBox.checked = false;
				this.selectedFacebookAds.splice(i, 1);
				return;
			}
		}
	}

	public deSelectAll(facebookAdsData: any[]): void {
		for (let i: number = 0; i < facebookAdsData.length; i++) {
			let el = document.getElementById(facebookAdsData[i].accountId) as HTMLInputElement;
			if (el.checked === true) {
				el.checked = false;
				this.removeSelectedFacebookAdsQue(facebookAdsData[i].accountId);
			}
		}
	}

	public connectFacebookAccount(): void {
		let toPostTrackedData: any[] = this.adsAccountFacebook;
		toPostTrackedData.forEach(item => {
			let seletedAdsAcc: any[] = [];
			item.adaccounts.forEach(item2 => {
				if (item2.tracked === true) {
					seletedAdsAcc.push(item2);
				}
			});
			item.adaccounts = [];
			item.adaccounts = seletedAdsAcc;
		});
		this.connectAccount.emit(toPostTrackedData);
	}
}
