<app-expansion-panel-sc
	[expansionTitle]="'Split by Gender'"
	[isExpandable]="false"
	[isSelected]="isSelected"
	[contentMargin]="true"
	(click)="onExpansionClick.emit()"
>
	<div expansion-content class="expansion-input">
		<mat-slide-toggle class="toggle" (change)="onSplitGenderToggle()" [formControl]="control" [checked]="isSplitGenderToggled">
			<span>Do you want to split your Ad Sets by gender?</span>
		</mat-slide-toggle>
	</div>
</app-expansion-panel-sc>
