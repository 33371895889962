import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { CatalogNodeInterface } from '../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { ImageStateEnum } from '../../../hover-select-directive/image-state.enum';
import { CatalogItemsInterface } from '../../../../campaign-smart-create/catalogs/catalog.items.interface';
import { BuildCampaignPanelsEnum } from '../../../../campaign-smart-create/components/build-campaign-template/build-campaign-panels.enum';
import { ObjectivesEnum } from '../../../../campaign-smart-create/models/objectives.enum';
import { Subject } from 'rxjs';
import { SubObjectives } from '../../../../campaign-smart-create/models/sub-objectives';
import { PixelServiceApi } from 'src/app/_services/pixel/pixel.api.service';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { PixelsDto } from 'src/app/pixel/_models/pixels-dto.model';
import { getFacebookPixels } from 'src/app/state/campaign-common-state/campaign-common-reducer';
import { CampaignSmartCreateState } from 'src/app/campaign-smart-create/state/smart-create.reducer';

@Component({
	selector: 'app-new-objective-card',
	templateUrl: './new-objective-card.component.html',
	styleUrls: ['./new-objective-card.component.scss']
})
export class NewObjectiveCardComponent implements OnInit, OnDestroy {
	@Input() public objectivesGroup: AbstractControl;
	@Input() public objectives: CatalogNodeInterface[];
	@Input() public isObjectivesPanelSelected: boolean;
	@Input() public isSubObjectivesPanelSelected: boolean;
	@Input() public isSubObjectivesOptionsPanelSelected: boolean;
	@Input() public selectedSubObjectiveText: string;
	@Input() public showErrorTextMsg?: boolean;
	@Input() public selectedAccount?: any;
	@Output() public onExpansionClick = new EventEmitter();

	public customObjectives: any[];
	public selectedCustomObjectives: any[];
	public state = ImageStateEnum;
	public selectedObjective: CatalogNodeInterface;
	public selectedSubObjective: CatalogItemsInterface | CatalogItemsInterface[];
	public campaignPanels = BuildCampaignPanelsEnum;
	public contexts: string[];
	public objective = ObjectivesEnum;
	private unsubscriber$ = new Subject();
	public selectedSubObjectiveForCheck: CatalogItemsInterface;
	public showError: boolean = false;
	public doesAccountHasPixel: boolean = false;
	public pixelError: boolean = false;
	public pixels: DropdownData<PixelsDto>[] = [];
	constructor(private store: Store<CampaignSmartCreateState>, private pixelServiceApi: PixelServiceApi) {}

	public ngOnInit(): void {
		this.customObjectives = [
			{
				name: 'Awareness',
				objective: this.objectives.find(xd => xd.name === this.objective.Awareness),
				image: '',
				subObjectiveName: SubObjectives.BrandAwarenessName,
				imageState: 'default'
			},
			{
				name: 'Conversions',
				objective: this.objectives.find(xd => xd.name === this.objective.Conversions),
				image: '',
				subObjectiveName: SubObjectives.WebsiteConversionName,
				imageState: 'default'
			},
			{
				name: 'Dynamic Ads',
				objective: this.objectives.find(xd => xd.name === this.objective.Conversions),
				image: '',
				subObjectiveName: SubObjectives.CatalogSalesName,
				disabled: false,
				imageState: 'default'
			},
			{
				name: 'App Installs',
				objective: this.objectives.find(xd => xd.name === this.objective.Consideration),
				image: '',
				subObjectiveName: SubObjectives.AppInstallName,
				disabled: false,
				imageState: 'default'
			}
		];
	}
	public ngOnChanges(): void {
		this.showError = this.showErrorTextMsg;
		this.getPixels();
	}
	public getPixels(): void {
		this.store.pipe(select(getFacebookPixels), takeUntil(this.unsubscriber$)).subscribe(pixels => {
			this.pixels = pixels;
		});
	}
	private checkIfAccountHasPixels(): boolean {
		if (this.selectedSubObjectiveText === SubObjectives.WebsiteConversionName) {
			if (!this.pixels || !this.pixels.length) {
				this.pixelError = true;
				this.showError = false;
				return false;
			} else {
				this.pixelError = false;
				return true;
			}
		} else {
			this.pixelError = false;
			return true;
		}
	}
	public onObjectiveClick(subObjectiveName: string): void {
		this.selectedSubObjectiveText = subObjectiveName;
		const customObjective = this.customObjectives.find(xd => xd.subObjectiveName === subObjectiveName);
		const objective = customObjective.objective;

		this.objectivesGroup.get('objective').setValue(objective.name);
		this.showError = false;
		if (this.checkIfAccountHasPixels()) {
			this.selectedObjective = objective;
			const newSubObjective: CatalogItemsInterface = objective.items ? objective?.items.find(xd => xd.display_name === subObjectiveName) : null;
			this.updateSubObjectiveState(newSubObjective);
		} else {
			this.objectivesGroup.get('subObjectiveDisplayName').setValue('');
		}
	}

	public updateSubObjectiveState(subObjective: CatalogItemsInterface): void {
		this.selectedSubObjective = Object.values(subObjective.items);
		this.objectivesGroup.get('subObjectiveDisplayName').setValue(subObjective.display_name);
		for (let i = 0; i < this.selectedSubObjective.length; ++i) {
			if (this.selectedSubObjective[i].kind === 'Objective') {
				this.objectivesGroup.get('subObjective').setValue(this.selectedSubObjective[i].name);
				this.selectedSubObjectiveForCheck = this.selectedSubObjective[i];
			} else if (this.selectedSubObjective[i].kind === 'DestinationType') {
				this.objectivesGroup.get('destinationType').setValue(this.selectedSubObjective[i].name);
			}
		}
	}
	public isCardSelected(value: string, control: string): boolean {
		return this.objectivesGroup.get(control).value === value;
	}

	public ngOnDestroy(): void {}
}
