import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import moment from 'moment';
import { UserManagementState } from '../../../state/user-management.reducer';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SettingsPageLabelsEnum } from '../../../shared/enums/navigation.enum';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { BillingPlan } from '../../../models/billing-plan.interface';

@Component({
	selector: 'app-my-subscription',
	templateUrl: './my-subscription.component.html',
	styleUrls: ['./my-subscription.component.scss']
})
export class MySubscriptionComponent implements OnInit {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;
	public subscriptionGroup: FormGroup;
	public billingPlan: BillingPlan;

	private unsubscriber$ = new Subject<void>();

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService,
		private store: Store<UserManagementState>,
		private backOfficeService: BackOfficeService
	) {}

	public ngOnInit(): void {
		this.createForm();
		this.getBillingPlan();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public goToContactUs(): void {
		this.router.navigate(['user-management/subscription-question']).then();
	}

	public getBillingPlan(): void {
		this.backOfficeService
			.getBillingPlan()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				billingPlan => {
					this.billingPlan = billingPlan;
					if (this.billingPlan) {
						this.subscriptionGroup.patchValue({
							startDate: this.billingPlan.subscriptionStartDate ? moment(this.billingPlan.subscriptionStartDate).format('YYYY-MM-DD') : '-',
							endDate: this.billingPlan.subscriptionEndDate ? moment(this.billingPlan.subscriptionEndDate).format('YYYY-MM-DD') : null,
							tier: this.billingPlan.subscriptionName ? this.billingPlan.subscriptionName : '-',
							nextInvoiceDate: this.billingPlan.nextInvoiceDate ? moment(this.billingPlan.nextInvoiceDate).format('YYYY-MM-DD') : '-',
							lastInvoiceDate: this.billingPlan.lastInvoiceDate ? moment(this.billingPlan.lastInvoiceDate).format('YYYY-MM-DD') : '-',
							amount: this.billingPlan.amount ? this.billingPlan.amount : '-'
						});
					}
				},
				() => {
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}

	private createForm(): void {
		this.subscriptionGroup = this.formBuilder.group({
			startDate: this.formBuilder.control({ disabled: true, value: '' }, Validators.required),
			endDate: this.formBuilder.control({ disabled: true, value: '' }, Validators.required),
			tier: this.formBuilder.control({ disabled: true, value: '' }, Validators.required),
			lastInvoiceDate: this.formBuilder.control({ disabled: true, value: '' }, Validators.required),
			nextInvoiceDate: this.formBuilder.control({ disabled: true, value: '' }, Validators.required),
			amount: this.formBuilder.control({ disabled: true, value: '' }, Validators.required)
		});
	}
}
