import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { AllocationPopUpBudgetInterface } from './allocation-pop-up-budget.interface';

@Component({
	selector: 'app-budget-allocation-popup.',
	templateUrl: './budget-allocation-popup.component.html',
	styleUrls: ['./budget-allocation-popup.component.scss']
})
export class BudgetAllocationPopupComponent implements OnInit {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public initial: number;
	public current: number;
	public level: string;
	public step: string;
	public currency: string;

	constructor(@Inject(MAT_DIALOG_DATA) public data: AllocationPopUpBudgetInterface, public dialogRef: MatDialogRef<BudgetAllocationPopupComponent>) {
		this.initial = data.initial;
		this.current = data.current;
		this.level = data.level;
		this.step = data.step;
		this.currency = data.currency;
	}

	public ngOnInit(): void {}

	public actionEvent(param: boolean = null): void {
		this.dialogRef.close(param);
	}
}
