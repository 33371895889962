<div class="apd-wrapper" appDrop>
	<section class="apd-menu">
		<div class="apd-menu-item" *ngFor="let contract of contracts" (click)="onMenuItemClick(contract)">
			<h6 [ngClass]="{ 'app-menu-item-selected': isContractSelected(contract) }">{{ contract.contractName }}</h6>
		</div>
	</section>
	<div class="apd-container">
		<section class="apd-container-header">
			<div class="apd-flex-row">
				<h3>{{ selectedContract?.contractName }}</h3>
				<i class="fas fa-times" (click)="onCancel()"></i>
			</div>
		</section>

		<app-input-text class="apd-remove-padding" *ngIf="selectedContract.showSearchInput" [control]="searchControl" [label]="searchLabel"></app-input-text>

		<div *ngIf="!selectedContract.showSearchInput" class="apd-data-update-section" appDrop (fileChange)="onFilesDragged($event)">
			<div class="apd-data-update-section-content">
				<img src="../../../../../../assets/icons/icon-upload-cloud.svg" />
				<span>
					Drag files here
					<br />
					You can upload multiple files.
				</span>
			</div>
			<app-button
				(buttonClicked)="fileInput.click()"
				[buttonLabel]="'Upload'"
				[buttonClass]="buttonClass.Tertiary"
				[buttonType]="buttonType.Simple"
			></app-button>
		</div>

		<div class="apd-autoflow">
			<div class="apd-with-data-container">
				<div
					*ngFor="let asset of assetsToDisplay"
					class="apd-image"
					(click)="onAssetClick(asset)"
					[ngClass]="{ 'apd-selected-asset': isAssetSelected(asset) }"
				>
					<img
						*ngIf="
							selectedContract.contractFor === adFormat.SingleImage ||
							selectedContract.contractFor === adFormat.PagePosts ||
							selectedContract.contractFor === adFormat.multipleSingleImage
						"
						[src]="asset.url"
						alt="Invalid url"
					/>
					<img
						*ngIf="selectedContract.contractFor === adFormat.Video && this.selectedContract.contractName === contractName.ExistingVideos"
						[src]="asset.picture"
						alt="Invalid url"
					/>
					<video
						*ngIf="selectedContract.contractFor === adFormat.Video && this.selectedContract.contractName !== contractName.ExistingVideos"
						src="{{ asset.url }}  "
						controls
					></video>
					<div class="apd-media-text">
						<span>{{ asset.title?.length > 14 ? (asset.title | slice: 0:14) + '...' : asset.title }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="apd-footer">
			<app-button
				(buttonClicked)="onCancel()"
				[buttonLabel]="'Cancel'"
				[buttonClass]="buttonClass.Secondary"
				[buttonType]="buttonType.Simple"
			></app-button>
			<app-button (buttonClicked)="onSubmit()" [buttonLabel]="'Submit'" [buttonClass]="buttonClass.Primary" [buttonType]="buttonType.Simple"></app-button>
		</div>

		<input type="file" hidden (change)="onFileUpload($event)" #fileInput multiple accept="{{ selectedContract.acceptedMime }}" />
	</div>
</div>
