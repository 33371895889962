import { Component, OnInit, Optional, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalInterface } from 'src/app/user-management/models/DatatableEnum';

@Component({
	selector: 'app-verify-add-user',
	templateUrl: './verify-add-user.component.html',
	styleUrls: ['./verify-add-user.component.scss']
})
export class VerifyAddUserComponent implements OnInit {
	@Input() public modelDetail: ModalInterface;
	seconds = 59;
	interval: any;
	showResend = true;
	counter = 3;
	constructor(private dialogRef: MatDialogRef<VerifyAddUserComponent>) {}

	public otpCode: string = '';
	ngOnInit(): void {
		this.startTimer();
	}

	public closeDialog(): void {
		this.dialogRef.close(false);
	}

	public actionButtonClick(): void {
		if (this.modelDetail.useOtp) {
			this.dialogRef.close({ status: true, code: this.otpCode });
		} else {
			this.dialogRef.close(true);
		}
	}

	// Timer function for resend the OTP
	public startTimer(): void {
		this.interval = setInterval(() => {
			if (this.seconds > 1) {
				this.seconds--;
			} else {
				this.showResend = false;
				clearInterval(this.interval);
				if (this.counter === 0) {
					this.counter = -1;
				}
			}
		}, 1000);
	}

	public leadingZero(num: number, size = 2): string {
		let numStr = num.toString();
		while (numStr.length < size) numStr = '0' + numStr;
		return numStr;
	}

	public resendOTP(): void {
		if (this.counter >= 0) {
			this.counter--;
			this.dialogRef.close({ status: 'resend' });
		} else {
			this.dialogRef.close(false);
		}
	}

	public onCodeChanged(code: string) {
		this.otpCode = code;
	}

	public onCodeCompleted(code: string) {
		this.otpCode = code;
	}
}
