import { Injectable } from '@angular/core';
import { InsightsReportModel } from '../../campaign-insights/models/insights-report.model';
import {
	LoadFacebookCampaignsMetadataSuccess,
	SharedActionTypes,
	LoadGoogleKeywordsMetadataSuccess,
	LoadGoogleAdsMetadataSuccess,
	LoadGoogleAdgroupsMetadataSuccess,
	LoadGoogleCampaignsMetadataSuccess,
	LoadFacebookAdsetsMetadataSuccess,
	LoadFacebookAdsMetadataSuccess,
	LoadFacebookCampaignsMetadataFailure,
	LoadFacebookAdsetsMetadataFailure,
	LoadFacebookAdsMetadataFailure,
	LoadGoogleCampaignsMetadataFailure,
	LoadGoogleAdgroupsMetadataFailure,
	LoadGoogleAdsMetadataFailure,
	LoadGoogleKeywordsMetadataFailure
} from '../shared.actions';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { InsightsCategoryTypeEnum } from '../../campaign-insights/models/insights-category-type.enum';
import { Action, select, Store } from '@ngrx/store';
import { createEffect, ofType, Actions, Effect } from '@ngrx/effects';
import { EMPTY, Observable } from 'rxjs';
import { withLatestFrom, filter, mergeMap, map, catchError } from 'rxjs/operators';
import { isMetadataLoaded, SharedState } from '../shared.reducer';
import { InsightsMetadataAbstractFactory } from '../../campaign-insights/services/metadata/insights-metadata-abstract-factory.service';
import { AppStateSlices } from '../../../state/app.state';
import { ErrorsLoggingService } from '../../../_services/errors-logging/errors-logging.service';

@Injectable()
export class InsightsEffects {
	constructor(
		private errorsLoggingService: ErrorsLoggingService,
		private actions$: Actions,
		private abstractFactory: InsightsMetadataAbstractFactory,
		private store: Store<SharedState>
	) {}

	loadFacebookCampaignsMetadata$ = this.getMetadataEffect(
		SharedActionTypes.LoadFacebookCampaignsMetadata,
		(result: InsightsReportModel[]) => new LoadFacebookCampaignsMetadataSuccess(result),
		(error: string) => new LoadFacebookCampaignsMetadataFailure(error),
		SourceChannel.Facebook,
		InsightsCategoryTypeEnum.Campaign
	);

	loadFacebookAdsetsMetadata$ = this.getMetadataEffect(
		SharedActionTypes.LoadFacebookAdsetsMetadata,
		(result: InsightsReportModel[]) => new LoadFacebookAdsetsMetadataSuccess(result),
		(error: string) => new LoadFacebookAdsetsMetadataFailure(error),
		SourceChannel.Facebook,
		InsightsCategoryTypeEnum.AdSet
	);

	loadFacebookAdsMetadata$ = this.getMetadataEffect(
		SharedActionTypes.LoadFacebookAdsMetadata,
		(result: InsightsReportModel[]) => new LoadFacebookAdsMetadataSuccess(result),
		(error: string) => new LoadFacebookAdsMetadataFailure(error),
		SourceChannel.Facebook,
		InsightsCategoryTypeEnum.Ad
	);

	loadGoogleCampaignMetadata$ = this.getMetadataEffect(
		SharedActionTypes.LoadGoogleCampaignsMetadata,
		(result: InsightsReportModel[]) => new LoadGoogleCampaignsMetadataSuccess(result),
		(error: string) => new LoadGoogleCampaignsMetadataFailure(error),
		SourceChannel.Google,
		InsightsCategoryTypeEnum.Campaign
	);

	loadGoogleAdgroupsMetadata$ = this.getMetadataEffect(
		SharedActionTypes.LoadGoogleAdgroupsMetadata,
		(result: InsightsReportModel[]) => new LoadGoogleAdgroupsMetadataSuccess(result),
		(error: string) => new LoadGoogleAdgroupsMetadataFailure(error),
		SourceChannel.Google,
		InsightsCategoryTypeEnum.AdGroup
	);

	loadGoogleAdsMetadata$ = this.getMetadataEffect(
		SharedActionTypes.LoadGoogleAdsMetadata,
		(result: InsightsReportModel[]) => new LoadGoogleAdsMetadataSuccess(result),
		(error: string) => new LoadGoogleAdsMetadataFailure(error),
		SourceChannel.Google,
		InsightsCategoryTypeEnum.Ad
	);

	loadGoogleKeywordsMetadata$ = this.getMetadataEffect(
		SharedActionTypes.LoadGoogleKeywordsMetadata,
		(result: InsightsReportModel[]) => new LoadGoogleKeywordsMetadataSuccess(result),
		(error: string) => new LoadGoogleKeywordsMetadataFailure(error),
		SourceChannel.Google,
		InsightsCategoryTypeEnum.Keyword
	);

	private getMetadataEffect<T extends Action>(
		actionIdentifier: string,
		getSuccessAction: (result: InsightsReportModel[]) => Action,
		getFailureAction: (error: string) => Action,
		channel: SourceChannel,
		category: InsightsCategoryTypeEnum
	): Observable<Action> {
		return createEffect(() =>
			this.actions$.pipe(
				ofType<T>(actionIdentifier),
				withLatestFrom(this.store.pipe(select(isMetadataLoaded, { channel: channel, category: category }))),
				filter(([_, isLoaded]: [T, boolean]) => !isLoaded),
				mergeMap(([action, _]: [T, boolean]) =>
					this.abstractFactory
						.getInstance(channel)
						.getInstance(category)
						.getInsightsMetadataModel(false)
						.pipe(
							catchError(error => {
								getFailureAction(this.errorsLoggingService.getCodeFromHttpError(error));
								this.errorsLoggingService.logEffectError(
									AppStateSlices.Shared,
									actionIdentifier,
									[action, channel, category, _, category],
									error
								);
								return EMPTY;
							}),
							map((result: InsightsReportModel[]) => getSuccessAction(result))
						)
				)
			)
		);
	}
}
