<!-- Navigation -->
<app-campaign-smart-create-navigation
	#top
	[activeStep]="step"
	[campaignStep]="campaignLastStepId"
	(navigationClick)="onNavClick($event)"
></app-campaign-smart-create-navigation>
<div class="o-wrapper">
	<span class="title">Smart Create</span>
	<div class="container">
		<div class="sticky">
			<quick-navigation [navigations]="navigations"></quick-navigation>
		</div>
		<div *ngIf="(isProductCatalogue && isRetargetingType) || isAppInstall; else showOptimization">
			<div class="o-expansion-panel no-optimization">
				<div class="content-wrapper">
					<p>Optimization is not available for retargeting</p>
				</div>
			</div>
		</div>
		<ng-template #showOptimization>
			<div *ngIf="!isRetargetingType">
				<div
					(click)="onPanelClick('split-by-location')"
					class="o-expansion-panel"
					*ngIf="selectedMultipleLocations && !hideDeviceSplit"
					id="split-by-location"
				>
					<app-optimization-location
						(onExpansionClick)="expansionPanelHelper.onPanelClick(optimizationPanelsEnum.Location)"
						[isSelected]="expansionPanelHelper.isPanelSelected(optimizationPanelsEnum.Location)"
						[isSplitLocationToggled]="isSplitByLocation"
						[control]="toggleFormGroup.get('location')"
					></app-optimization-location>
				</div>

				<div (click)="onPanelClick('split-by-device')" class="o-expansion-panel" id="split-by-device" *ngIf="!hideDeviceSplit">
					<app-devices-card
						(onExpansionClick)="expansionPanelHelper.onPanelClick(optimizationPanelsEnum.Devices)"
						[isSelected]="expansionPanelHelper.isPanelSelected(optimizationPanelsEnum.Devices)"
						[isDevicesToggle]="isSplitByDevices"
						[control]="toggleFormGroup.get('devices')"
					></app-devices-card>
				</div>
				<div (click)="onPanelClick('split-by-placements')" class="o-expansion-panel" id="split-by-placements" *ngIf="canSplitByPlacements">
					<app-placements-split-card
						(onExpansionClick)="expansionPanelHelper.onPanelClick(optimizationPanelsEnum.Placements)"
						[isSelected]="expansionPanelHelper.isPanelSelected(optimizationPanelsEnum.Placements)"
						[isPlacementsToggle]="isSplitByPlacements"
						[control]="toggleFormGroup.get('placements')"
					></app-placements-split-card>
				</div>
				<div
					(click)="onPanelClick('split-by-gender')"
					class="o-expansion-panel"
					*ngIf="!specialAdCategorySelected && selectedMultipleGenders"
					id="split-by-gender"
				>
					<app-split-gender-card
						(onExpansionClick)="expansionPanelHelper.onPanelClick(optimizationPanelsEnum.SplitGender)"
						[isSelected]="expansionPanelHelper.isPanelSelected(optimizationPanelsEnum.SplitGender)"
						[isSplitGenderToggled]="isSplitByGender"
						[control]="toggleFormGroup.get('gender')"
					></app-split-gender-card>
				</div>

				<div
					class="o-expansion-panel"
					*ngIf="selectedInterestsAtStepTwo?.length > 1"
					(click)="onPanelClick('split-by-interest')"
					id="split-by-interest"
				>
					<app-interests-card
						(onExpansionClick)="expansionPanelHelper.onPanelClick(optimizationPanelsEnum.Interests)"
						[isSelected]="expansionPanelHelper.isPanelSelected(optimizationPanelsEnum.Interests)"
						[selectedInterestsAtStepTwo]="selectedInterestsAtStepTwo"
						[splitData]="splitByInterests"
						[isInterestsToggled]="splitByInterests !== []"
					></app-interests-card>
				</div>

				<div (click)="onPanelClick('split-by-age')" class="o-expansion-panel" *ngIf="!specialAdCategorySelected" id="split-by-age">
					<app-split-age-range-card
						(onExpansionClick)="expansionPanelHelper.onPanelClick(optimizationPanelsEnum.SplitAgeRange)"
						(onAgeRangeSliderChange)="onAgeRangeSlider($event)"
						[isSelected]="expansionPanelHelper.isPanelSelected(optimizationPanelsEnum.SplitAgeRange)"
						[isSplitAgeRangeToggled]="isSplitByAgeRange"
						[yearsPerAdSet]="isSplitByAgeRange"
						[maximumAgeRange]="maximumAgeRange"
						[control]="toggleFormGroup.get('ageRange')"
						[sliderControl]="toggleFormGroup.get('slider')"
					></app-split-age-range-card>
				</div>
			</div>
		</ng-template>
		<div class="stick-summary">
			<app-campaign-summary-card
				[campaignName]="campaignName"
				[objectiveName]="objectiveName"
				[numberOfLocations]="selectedLocations?.length"
				[budgetSplits]="budgeAllocationSplits"
				[currency]="currency"
				[numberOfSplitsByAge]="numberOfSplitsByAge"
				[campaignBudget]="isOnCampaignLevelBudget"
				[adSetBudget]="isOnAdSetLevelBudget"
			></app-campaign-summary-card>
		</div>
	</div>
	<app-campaign-smart-create-footer
		[saveButtonText]="'Back'"
		[continueButtonText]="'Next'"
		[isPublishing]="isPublishing"
		(next)="onNextClick()"
		(cancel)="onCancelClick()"
		(save)="onBackClick()"
	></app-campaign-smart-create-footer>
</div>
