<div appClickOutside (clickOutside)="onOutsideClick()" class="live-search-dropdown">
	<div *ngIf="showSelected && selectedData.length" class="lsd-selected-values">
		<div *ngFor="let entry of selectedData" class="lsd-selected-row">
			<div *ngFor="let column of columns | filterArray: isSelectedDataColumn; let i = index">
				<span *ngIf="!column.formatText">{{ entry.data[column.property] }}</span>
				<span *ngIf="column.formatText">{{ entry.data[column.property] | formatText }}</span>
			</div>
			<img src="../../../../assets/icons/close-icon.svg" (click)="onSelectionClick(entry)" />
		</div>
	</div>
	<app-input-text
		[label]="inputLabel"
		[placeholder]="inputPlaceholder"
		[control]="inputControl"
		(inputFocusIn)="onInputFocusIn()"
		[icon]="'fa-search'"
		[showSpinner]="showSpinner"
	></app-input-text>

	<div *ngIf="showDropdown && searchDataView.length" class="lsd-dropdown-values" [style.width.rem]="dropdownWidth">
		<div (click)="onSelectionClick(entry)" *ngFor="let entry of searchDataView" class="lsd-dropdown-list-item">
			<i *ngIf="!singleSelection" [ngClass]="isValueSelected(entry) ? 'fa fa-check-square' : 'far fa-square'"></i>
			<div
				*ngFor="let column of columns | filterArray: isDropdownColumn; let i = index"
				[ngClass]="column.primaryColumn ? 'lsd-primary-column ' : 'lsd-column'"
				class="lsd-dropdown-columns"
			>
				<div
					class="lsd-cell"
					[ngClass]="{ 'lsd-no-border': isLastColumnInDropdown(i) }"
					[matTooltip]="column.showTooltip ? entry.data[column.property] : null"
				>
					<span
						[ngClass]="{ 'lsd-no-padding-left': singleSelection && column.primaryColumn, 'lsd-selected-text': isValueSelected(entry) }"
						*ngIf="!column.formatText"
					>
						{{ entry.data[column.property] }}
					</span>
					<span
						[ngClass]="{ 'lsd-no-padding-left': singleSelection && column.primaryColumn, 'lsd-selected-text': isValueSelected(entry) }"
						*ngIf="column.formatText"
					>
						{{ entry.data[column.property] | formatText }}
					</span>
				</div>
			</div>
		</div>
	</div>
</div>
