import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AdFormatsEnum } from '../../../../campaign-smart-create/models/ad-formats.enum';
import { CatalogNodeInterface } from '../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { ObjectiveTreeInterface } from '../../../../campaign-smart-create/models/objective-tree.interface';
import { SubObjectives } from '../../../../campaign-smart-create/models/sub-objectives';
import { AdFormats } from './ad-formats.model';
import { ImageStateEnum } from '../../../../campaign-smart-create/models/image-state.enum';
import { UpdateAdFormatType, UpdateAdverts } from '../../../../campaign-smart-create/state/smart-create.actions';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getObjective, getObjectiveTree } from '../../../../campaign-smart-create/state/smart-create.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DropdownInterface } from '@filed-com/filed-lib';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-ad-format-selection-card',
	templateUrl: './ad-format-selection-card.component.html',
	styleUrls: ['./ad-format-selection-card.component.scss']
})
export class AdFormatSelectionCardComponent implements OnInit, OnDestroy {
	@Input() public isPanelSelected: boolean;
	@Input() public selectedFormat: number;
	@Input() public objectiveTree: ObjectiveTreeInterface;
	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public onUpdateFormatType = new EventEmitter<AdFormatsEnum>();

	public AdFormatsEnum = AdFormatsEnum;
	public subObjectives = SubObjectives;
	public state = ImageStateEnum;
	public formats = AdFormats;
	public filteredFormats: any[] = [];
	private store: Store<CampaignSmartCreateState>;
	private unsubscriber$ = new Subject<void>();
	public selectedObjective: ObjectiveTreeInterface;
	public creatives: any;
	public creativeControl: FormControl = new FormControl({ value: '', disabled: true });
	public display: boolean;
	public info: InfoHoverDataModel = {
		message: `Design your ad using various formats, including imagery, video, or a carousel, to meet your business goals.`
	};

	constructor(private injector: Injector) {
		this.store = this.injector.get<Store<CampaignSmartCreateState>>(Store);
	}

	public ngOnInit(): void {
		this.filterFormats();
		this.checkDefaultSelection();
		this.getStepOneObjective();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public checkDefaultSelection(): void {
		if (!this.selectedFormat) {
			this.selectedFormat = AdFormatsEnum.SingleImage;
			this.formats[0].imageState = this.state.Pressed;
			this.onUpdateFormatType.emit(this.selectedFormat as AdFormatsEnum);
		} else {
			this.formats?.forEach(format => {
				if (format.key === this.selectedFormat) {
					format.imageState = this.state.Pressed;
				}
			});
		}
		if (this.objectiveTree?.subObjective === this.subObjectives.CatalogSalesText) {
			this.selectedFormat = AdFormatsEnum.catalogueSale;
		}
	}

	public filterFormats(): void {
		this.formats.forEach(format => (format.imageState = this.state.Default));
		if (this.objectiveTree.subObjective === SubObjectives.PageLikes || this.objectiveTree.subObjective === SubObjectives.PostLikes) {
			this.formats = this.formats.filter(item => item.key !== AdFormatsEnum.Carousel);
			if (this.selectedFormat === AdFormatsEnum.Carousel) {
				this.selectedFormat = AdFormatsEnum.SingleImage;
			}
		}
		if (this.objectiveTree.subObjective === SubObjectives.VideoViews) {
			this.formats = this.formats.filter(item => item.key === AdFormatsEnum.Video);
			if (this.selectedFormat === AdFormatsEnum.Carousel || this.selectedFormat === AdFormatsEnum.SingleImage) {
				this.selectedFormat = AdFormatsEnum.Video;
			}
		}
		if (this.objectiveTree.subObjective === SubObjectives.PageLikes && this.selectedFormat === AdFormatsEnum.PagePosts) {
			this.selectedFormat = AdFormatsEnum.SingleImage;
		}

		this.validateFormats();
	}

	public onRadioButtonClick(selection: number): void {
		if (this.objectiveTree.subObjective !== SubObjectives.PageLikes) {
			this.selectedFormat = selection;
			this.onUpdateFormatType.emit(this.selectedFormat as AdFormatsEnum);
		}
	}

	public onAdFormatClick(selection: CatalogNodeInterface): void {
		const key = parseInt(selection.key);
		if (key !== this.selectedFormat) {
			this.store.dispatch(new UpdateAdverts(null));
		}
		this.selectedFormat = key;
		this.onUpdateFormatType.emit(this.selectedFormat as AdFormatsEnum);
	}

	private getStepOneObjective(): void {
		this.store.pipe(select(getObjectiveTree), takeUntil(this.unsubscriber$)).subscribe(res => {
			this.selectedObjective = res;
		});
	}

	protected validateFormats(): void {
		this.filteredFormats = [];
		const objective = this.selectedObjective?.subObjective;

		if (objective === SubObjectives.VideoViews) {
			this.filteredFormats = this.formats.filter(fmt => fmt.key === AdFormatsEnum.Video);
			this.selectedFormat = AdFormatsEnum.Video;
		} else if (objective === SubObjectives.PageLikes || objective === SubObjectives.PostLikes) {
			this.filteredFormats = this.formats.filter(fmt => fmt.key !== AdFormatsEnum.Carousel);
			if (this.selectedFormat === AdFormatsEnum.Video) {
				this.selectedFormat = AdFormatsEnum.SingleImage;
			}
		} else {
			this.filteredFormats = [...this.formats];
		}
	}

	public creativeValueChange($event: DropdownInterface): void {}
}
