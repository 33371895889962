<div class="bup-container">
	<section class="bup-header">
		<h4>Build Url Parameter</h4>
		<i class="fas fa-times" (click)="onCancel()"></i>
	</section>

	<div class="bup-website-url">
		<app-input-text [control]="parametersGroup.get('_websiteUrl')" [placeholder]="'Website Url'" [label]="'Website Url'"></app-input-text>
	</div>

	<div class="bup-default-parameters" [formGroup]="parametersGroup">
		<div class="bup-default-parameters-inputs">
			<app-input-text [control]="parametersGroup.get('_medium')" [placeholder]="'Campaign medium'" [label]="'Campaign medium'"></app-input-text>
			<app-input-text [control]="parametersGroup.get('_source')" [placeholder]="'Campaign source'" [label]="'Campaign source'"></app-input-text>
			<app-input-text [control]="parametersGroup.get('_name')" [placeholder]="'Campaign name'" [label]="'Campaign name'"></app-input-text>
			<app-input-text [control]="parametersGroup.get('_content')" [placeholder]="'Campaign content'" [label]="'Campaign content'"></app-input-text>
		</div>
		<div class="bup-url-display">
			<p>{{ urlWithParams }}</p>
		</div>
	</div>
	<div *ngIf="extraParams.length" class="bup-extra-parameters">
		<div class="bup-extra-parameters-row" *ngFor="let param of extraParams; let i = index">
			<app-input-text class="bup-extra-inp-width" [control]="param[0]" [placeholder]="'Parameter name'" [label]="'Parameter name'"></app-input-text>
			<app-input-text [control]="param[1]" [placeholder]="'Value'" [label]="'Value'"></app-input-text>
			<div><i class="fas fa-times" (click)="removeParam(i)"></i></div>
		</div>
	</div>
	<div class="bup-footer">
		<app-button
			[buttonLabel]="'Add Parameter'"
			[buttonType]="buttonType.Simple"
			[buttonClass]="buttonClass.Secondary"
			(buttonClicked)="onAddParameter()"
		></app-button>
		<app-button [buttonLabel]="'Save'" [buttonType]="buttonType.Simple" [buttonClass]="buttonClass.Primary" (buttonClicked)="onSave()"></app-button>
	</div>
</div>
