import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GraphData } from '@filed-com/filed-lib/lib/graphs/models/graph-data.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	AlertRankingText,
	AlertSentimentAnalysis,
	AlertTitleEnum,
	DistributionComparisonValues,
	IDexterAd,
	IDexterAdAlert,
	IDexterAdAlertRanking,
	IDistributionComparisonChartData
} from 'src/app/optimise/models/dexter-health.interface';
import { OptimizeService } from 'src/app/optimise/services/optimize.service';
const jStat = require('jstat');

@Component({
	selector: 'app-health-generic-card',
	templateUrl: './health-generic-card.component.html',
	styleUrls: ['./health-generic-card.component.scss']
})
export class HealthGenericCardComponent implements OnInit {
	@Input('healthType') public healthType: string = '';
	@Input('titleText') public titleText: string = '';
	@Input('infoText') public infoText: string = '';
	@Input('progressGuage') public progressGuage = false;
	public dexterHealthValues: IDexterAd;
	public data: IDexterAdAlert;
	private unsubscriber$: Subject<void> = new Subject<void>();

	public imgSrc: string = '';
	public comparisonText: string = '';
	public chartIsLoading = false;

	public ranking = [
		{
			key: 'qualityRanking',
			value: 'Quality'
		},
		{
			key: 'engagementRateRanking',
			value: 'Engagement Rate'
		},
		{
			key: 'conversionRateRanking',
			value: 'Conversion Rate'
		}
	];

	public chartData: IDistributionComparisonChartData;

	constructor(private dialog: MatDialog, public optimizeService: OptimizeService, public cdRef: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.optimizeService.setSelectedDexterHealthAd.pipe(takeUntil(this.unsubscriber$)).subscribe(adHealthValue => {
			this.dexterHealthValues = adHealthValue;
			if (this.healthType === 'landingPageComparison') {
				this.data = this.dexterHealthValues?.alerts.find(value => {
					return value.alertTitle === AlertTitleEnum.PageComparisons;
				});
			} else if (this.healthType === 'landingPageAlert') {
				this.data = this.dexterHealthValues?.alerts.find(value => {
					return value.alertTitle === AlertTitleEnum.PageAlerts;
				});
				this.imgSrc = this.data.alertSummary === 'Your link works!' ? 'assets/icons/optimize/404-good.png' : 'assets/icons/optimize/404-error-red.svg';
			} else if (this.healthType === 'sentimentAnalysis') {
				this.data = this.dexterHealthValues?.alerts.find(value => {
					return value.alertTitle === AlertTitleEnum.SentimentAnalysis;
				});
				if (this.data.alertSummary === AlertSentimentAnalysis.Positive) {
					this.imgSrc = 'assets/icons/optimize/smiley_good.svg';
				} else if (this.data.alertSummary === AlertSentimentAnalysis.Negative) {
					this.imgSrc = 'assets/icons/optimize/smiley_bad.svg';
				} else if (this.data.alertSummary === AlertSentimentAnalysis.Neutral) {
					this.imgSrc = 'assets/icons/optimize/smiley_neutral.svg';
				}
			} else if (this.healthType === 'distributionComparison') {
				this.data = this.dexterHealthValues?.alerts.find(value => {
					return value.alertTitle === 'Distribution Comparisons';
				});
				if (this.data.links && this.data.alertMessage.includes(this.data.hyperlinkText)) {
					const formHyperlink = `<a href="${this.data.links}" target="_blank" >${this.data.hyperlinkText}</a>`;
					const formComparisonTextWithUrl = this.data.alertMessage.replace(this.data.hyperlinkText, formHyperlink);
					this.comparisonText = formComparisonTextWithUrl;
				} else {
					this.comparisonText = this.data.alertMessage;
				}

				this.chartData = {
					type: 'AreaChart',
					data: this.createArray(0, 100, 0, 100, 50, 18, this.data.ranking),
					chartColumns: [
						{ id: '', label: 'X Value', pattern: '', type: 'number' },
						{ id: '', label: 'Ranking', pattern: '', type: 'number' },
						{ type: 'boolean', role: 'scope', p: { role: 'scope' } },
						{ type: 'string', role: 'style', p: { role: 'style' } },
						{ type: 'string', role: 'annotation', p: { role: 'annotation' } }
					],
					width: 200,
					height: 200,
					options: this.setChartOptions()
				};
			}
		});
	}

	// ANY definition to be updated
	private createArray(xMin: number, xMax: number, xLeft: number, xRight: number, mean: number, stndDev: number, ranking: IDexterAdAlertRanking): any[][] {
		this.chartIsLoading = true;
		let chartData = new Array([]);
		let index = 0;
		for (var i = xMin; i <= xMax; i += 0.1) {
			chartData[index] = new Array(4);
			chartData[index][0] = i;
			chartData[index][1] = jStat.normal.pdf(i, mean, stndDev);

			if (i < xLeft || i > xRight) {
				chartData[index][2] = false;
			}

			if (i >= 0 && i <= 40) {
				chartData[index][3] = 'opacity: ; + color: #111; + stroke-color: #DC3445; ';
			} else if (i > 40 && i <= 60) {
				chartData[index][3] = 'opacity: ; + color: #111; + stroke-color: #FFC12F; ';
			} else if (i > 60) {
				chartData[index][3] = 'opacity: ; + color: #111; + stroke-color: #09D8DE; ';
			}

			chartData[index][4] = null;

			if (i >= 10 && i <= 10.1) {
				for (const [key, value] of Object.entries(this.data.ranking)) {
					const rankValue = this.ranking.find(value => {
						return value.key === key;
					}).value;
					if (value === 'BELOW_AVERAGE_10' && !chartData[index][4]) {
						chartData[index][4] = rankValue;
					} else if (value === 'BELOW_AVERAGE_10' && chartData[index][4] && !chartData[0][4]) {
						chartData[0][4] = rankValue;
					} else if (value === 'BELOW_AVERAGE_10' && chartData[index][4] && chartData[0][4]) {
						chartData[25][4] = rankValue;
					}
				}
			} else if (i >= 20 && i <= 20.1) {
				for (const [key, value] of Object.entries(this.data.ranking)) {
					const rankValue = this.ranking.find(value => {
						return value.key === key;
					}).value;
					if (value === 'BELOW_AVERAGE_20' && !chartData[index][4]) {
						chartData[index][4] = rankValue;
					} else if (value === 'BELOW_AVERAGE_20' && chartData[index][4] && !chartData[index - 40][4]) {
						chartData[index - 40][4] = rankValue;
					} else if (value === 'BELOW_AVERAGE_20' && chartData[index][4] && chartData[index - 40][4]) {
						chartData[index - 70][4] = rankValue;
					}
				}
			} else if (i >= 35 && i <= 35.1) {
				for (const [key, value] of Object.entries(this.data.ranking)) {
					const rankValue = this.ranking.find(value => {
						return value.key === key;
					}).value;
					if (value === 'BELOW_AVERAGE_35' && !chartData[index][4]) {
						chartData[index][4] = rankValue;
					} else if (value === 'BELOW_AVERAGE_35' && chartData[index][4] && !chartData[index - 25][4]) {
						chartData[index - 25][4] = rankValue;
					} else if (value === 'BELOW_AVERAGE_35' && chartData[index][4] && chartData[index - 25][4]) {
						chartData[index - 50][4] = rankValue;
					}
				}
			} else if (i >= 50 && i <= 50.1) {
				for (const [key, value] of Object.entries(this.data.ranking)) {
					const rankValue = this.ranking.find(value => {
						return value.key === key;
					}).value;
					if (value === 'AVERAGE' && !chartData[index][4]) {
						chartData[index][4] = rankValue;
					} else if (value === 'AVERAGE' && chartData[index][4] && !chartData[index - 70][4]) {
						chartData[index - 70][4] = rankValue;
					} else if (value === 'AVERAGE' && chartData[index][4] && chartData[index - 140][4]) {
						chartData[index - 140][4] = rankValue;
					}
				}
			} else if (i >= 75 && i <= 75.1) {
				for (const [key, value] of Object.entries(this.data.ranking)) {
					const rankValue = this.ranking.find(value => {
						return value.key === key;
					}).value;
					if (value === 'ABOVE_AVERAGE' && !chartData[index][4]) {
						chartData[index][4] = rankValue;
					} else if (value === 'ABOVE_AVERAGE' && chartData[index][4] && !chartData[index - 30][4]) {
						chartData[index - 30][4] = rankValue;
					} else if (value === 'ABOVE_AVERAGE' && chartData[index][4] && chartData[index - 60][4]) {
						chartData[index - 60][4] = rankValue;
					}
				}
			} else {
				chartData[index][4] = null;
			}
			index++;
		}
		this.chartIsLoading = false;
		return chartData;
	}

	// ANY return definition to be updated
	public setChartOptions(): any {
		let options: any = { legend: 'none' };
		options.hAxis = {};
		options.hAxis.minorGridlines = {};
		options.hAxis.minorGridlines.count = 5;
		options.vAxis = {
			textPosition: 'none'
		};
		options.tooltip = { trigger: 'none' };

		options.annotations = {
			stemColor: 'blue'
		};
		return options;
	}

	public ngAfterViewChecked(): void {
		this.cdRef.detectChanges();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
