<div class="main-container">
	<div *ngIf="!loading" class="chevron-links">
		<h6>{{ kbService.searchValue$.value.charAt(0).toUpperCase() + kbService.searchValue$.value.slice(1).toLowerCase() }}</h6>
	</div>
	<div *ngIf="!loading" class="main-cards">
		<ng-container *ngFor="let sub of kbArticles; let i = index">
			<mat-card (click)="showSubView(sub.data)">
				<div class="section">
					<div class="section-description">
						<h5>{{ sub.sub_category ? sub.sub_category : sub.data[0].category + ' articles' }}</h5>
					</div>
				</div>
			</mat-card>
		</ng-container>

		<div *ngIf="!kbArticles || kbArticles.length < 1">
			<h6>There are no articles in this category</h6>
		</div>
	</div>
	<div class="loader" *ngIf="loading">
		<lolly-spinning-loader></lolly-spinning-loader>
	</div>
</div>
