import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { Dashboard } from '../../../ads-manager/models/dashboard-widget-data';
import { UserState } from '../user/user.reducer';
import { InsightsCategoryTypeEnum } from '../../campaign-insights/models/insights-category-type.enum';
import { SharedState } from '../shared.reducer';
import { InsightsReportModel } from '../../campaign-insights/models/insights-report.model';
import { UserViewPreferences } from '../../services/general-settings/models/shared-preferences/ads-manager-view-preferences.model';
import { DataInterface } from '../state-entities/data-interface.model';
import { SharedPreferences } from '../../services/general-settings/models/shared-preferences/shared-preferences.model';
import { InsightsLevelEnum } from '../../services/general-settings/models/insights-level.enum';
import { AdAccount, NewAdAccountNulled, StructureModel } from '../../../accounts/models/ad-account.model';
import _ from 'lodash';
import { BasicStructureModel } from '../../../reports/models/basic-structure.model';
import { ReportTypeStoreModel } from '../../../reports/shared/models/report-type-store-model.interface';
import { FilterKeysEnum } from '../../../reports/shared/models/filter-keys.enum';

export class ReducerFunctions {
	public static getBusinessOwnerIdByChannel(state: UserState, { channel }: { channel: SourceChannel }): string {
		switch (channel) {
			case SourceChannel.Facebook:
				return state.userDetails.FacebookBusinessOwnerId;
			case SourceChannel.Google:
				return state.userDetails.GoogleBusinessOwnerId;
			default:
				return null;
		}
	}

	public static getAdsManagerViewDetails(
		slice: DataInterface<SharedPreferences>,
		channel: SourceChannel,
		category: InsightsCategoryTypeEnum
	): UserViewPreferences {
		switch (channel) {
			case SourceChannel.Facebook:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return slice.data?.adsManagerViews.facebookCampaigns;
					case InsightsCategoryTypeEnum.AdSet:
						return slice.data?.adsManagerViews.facebookAdsets;
					case InsightsCategoryTypeEnum.Ad:
						return slice.data?.adsManagerViews.facebookAds;
				}
				break;

			case SourceChannel.Google:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return slice.data.adsManagerViews.googleCampaigns;
					case InsightsCategoryTypeEnum.AdGroup:
						return slice.data.adsManagerViews.googleAdgroups;
					case InsightsCategoryTypeEnum.Ad:
						return slice.data.adsManagerViews.googleAds;
					case InsightsCategoryTypeEnum.Keyword:
						return slice.data.adsManagerViews.googleKeywords;
				}
				break;

			default:
				return null;
		}
	}

	public static getAdsManagerMetadata(slice: SharedState, channel: SourceChannel, category: InsightsCategoryTypeEnum): InsightsReportModel[] {
		switch (channel) {
			case SourceChannel.Facebook:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return slice.insights.facebookCampaignsMetadata.data;
					case InsightsCategoryTypeEnum.AdSet:
						return slice.insights.facebookAdsetsMetadata.data;
					case InsightsCategoryTypeEnum.Ad:
						return slice.insights.facebookAdsMetadata.data;
				}
				break;

			case SourceChannel.Google:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return slice.insights.googleCampaignsMetadata.data;
					case InsightsCategoryTypeEnum.AdGroup:
						return slice.insights.googleAdgroupsMetadata.data;
					case InsightsCategoryTypeEnum.Ad:
						return slice.insights.googleAdsMetadata.data;
					case InsightsCategoryTypeEnum.Keyword:
						return slice.insights.googleKeywordsMetadata.data;
				}
				break;

			default:
				return null;
		}
	}

	public static getAdsManagerSelection(slice: SharedState, channel: SourceChannel, category: InsightsCategoryTypeEnum): string[] {
		switch (channel) {
			case SourceChannel.Facebook:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return slice.generalSettings.adsManager.data.selectedCampaigns;
					case InsightsCategoryTypeEnum.AdSet:
						return slice.generalSettings.adsManager.data.selectedAdsets;
					case InsightsCategoryTypeEnum.Ad:
						return slice.generalSettings.adsManager.data.selectedAds;
				}
				break;

			default:
				return null;
		}
	}

	public static getAdsManagerParentSelection(slice: SharedState, channel: SourceChannel, category: InsightsCategoryTypeEnum): string[] {
		switch (channel) {
			case SourceChannel.Facebook:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return null;
					case InsightsCategoryTypeEnum.AdSet:
						return slice.generalSettings.adsManager.data.selectedCampaigns;
					case InsightsCategoryTypeEnum.Ad:
						return slice.generalSettings.adsManager.data.selectedAdsets;
				}
				break;

			default:
				return null;
		}
	}

	public static getAdsManagerFilteredStructures(slice: SharedState, channel: SourceChannel, category: InsightsCategoryTypeEnum): string[] {
		switch (channel) {
			case SourceChannel.Facebook:
				switch (category) {
					case InsightsCategoryTypeEnum.Campaign:
						return slice.generalSettings.adsManager.data.filteredCampaigns;
					case InsightsCategoryTypeEnum.AdSet:
						return slice.generalSettings.adsManager.data.filteredAdsets;
					case InsightsCategoryTypeEnum.Ad:
						return slice.generalSettings.adsManager.data.filteredAds;
				}
				break;

			default:
				return null;
		}
	}

	public static getAdsManagerUserDashboard(slice: SharedState): Dashboard[] {
		const userDashboards = slice.generalSettings.shared.data?.userDashboards;
		if (userDashboards) {
			return userDashboards;
		}
		return [];
	}

	public static insightCategoryToInsightLevel(reportLevel: InsightsCategoryTypeEnum): InsightsLevelEnum {
		if (reportLevel === InsightsCategoryTypeEnum.Campaign) {
			return InsightsLevelEnum.Campaigns;
		}
		if (reportLevel === InsightsCategoryTypeEnum.AdSet) {
			return InsightsLevelEnum.AdSets;
		}
		if (reportLevel === InsightsCategoryTypeEnum.Ad) {
			return InsightsLevelEnum.Ads;
		}
	}

	public static getAdAccountByIdFromState(state: SharedState, adAccountId: string, sourceChannel: SourceChannel) {
		return state.cachedAdAccounts.data
			.find(adAccountsModel => {
				return adAccountsModel.sourceChannel === sourceChannel;
			})
			?.adAccounts.find(adAccount => {
				return adAccount.id === adAccountId;
			});
	}

	public static checkIfStructureDataIsPopulated(adAccount: AdAccount, level: InsightsLevelEnum): boolean {
		if (adAccount) {
			if (!(level in adAccount)) {
				// @ts-ignore todo: find a better solution without ts-ignore
				Object.defineProperties(adAccount, { [level]: _.cloneDeep(NewAdAccountNulled) });
			}
			const insights = adAccount[level];

			if (insights?.active.data?.length) {
				return true;
			}

			if (insights?.paused.data?.length) {
				return true;
			}
		}
		return false;
	}

	public static getSelectedInsights(insights: StructureModel, statusKeys: FilterKeysEnum[]): BasicStructureModel[] {
		let selectedInsights: BasicStructureModel[] = [];
		if (insights) {
			if (statusKeys?.includes(FilterKeysEnum.Active)) {
				if (insights?.active?.data) {
					selectedInsights = selectedInsights.concat(insights?.active?.data);
				}
			}
			if (statusKeys?.includes(FilterKeysEnum.Paused) || statusKeys?.includes(FilterKeysEnum.Completed)) {
				if (insights?.paused?.data) {
					selectedInsights = selectedInsights.concat(insights?.paused?.data);
				}
			}
		}
		return selectedInsights;
	}

	public static filterAdSetsByCampaignId(basicStructureModels: BasicStructureModel[], campaignsIds: string[]): BasicStructureModel[] {
		return basicStructureModels.filter(structure => {
			return campaignsIds.includes(structure.keys.campaignId);
		});
	}

	public static filterAdsByAdSetId(basicStructureModels: BasicStructureModel[], adSetIds: string[]): BasicStructureModel[] {
		return basicStructureModels.filter(structure => {
			return adSetIds.includes(structure.keys.adsetId);
		});
	}

	public static getCurrentStructureKeyFactory(insight: BasicStructureModel, reportLevel: any) {
		if (reportLevel === InsightsLevelEnum.Campaigns) {
			return insight.keys.campaignId;
		}
		if (reportLevel === InsightsLevelEnum.AdSets) {
			return insight.keys.adsetId;
		}
		if (reportLevel === InsightsLevelEnum.Ads) {
			return insight.keys.adId;
		}
	}

	public static mapReportType(displayName: string, key: string, sourceChannel: SourceChannel, reportLevel: InsightsCategoryTypeEnum): ReportTypeStoreModel {
		return {
			id: key,
			displayName: displayName,
			currentKey: key,
			sourceChannel: sourceChannel,
			reportLevel: reportLevel
		};
	}
}
