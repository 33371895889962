import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastNotificationService } from '../../../../../shared/toast-notification/toast-notification.service';
import { select, Store } from '@ngrx/store';
import {
	getAccount,
	getBusinesses,
	getFacebookPermissions,
	getSelectedBusiness,
	getUsersState,
	UserManagementState
} from '../../../../state/user-management.reducer';
import { DropdownData } from '../../../../../shared/dropdown-search-select/dropdown-data.interface';
import { FacebookBusinessPermissions } from '../../../../../_models/user-models/facebook-business-permissions';
import { AdAccountFormData } from '../../../../models/account';
import {
	AddAdAccountData,
	AddRadioButtonValue,
	AddSelectedAccesLevelId,
	AddSelectedBusinessId,
	RemoveBusinessByIndex,
	RemoveRadioValueByIndex,
	RemoveSelectedAccesByIndex,
	RemoveSelectedAdAccount,
	RemoveSelectedAdAccountByBusiness,
	UpdateRadioButtonValue,
	UpdateSelectedAccesLevelId,
	UpdateSelectedBusinessId
} from '../../../../state/user-management.actions';
import { MatRadioChange } from '@angular/material/radio';
import { AccesLevelValidator, BusinessValidator } from '../../../../models/validators';
import { Business } from '../../../../../accounts/models/business.model';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-add-business',
	templateUrl: './manage-business-permissions.component.html',
	styleUrls: ['./manage-business-permissions.component.scss']
})
export class ManageBusinessPermissionsComponent implements OnInit, OnDestroy {
	@Input() facebookBusinessPermissions: FacebookBusinessPermissions;
	@Input() selectedBusinessId: number;
	@Input() businessesFromJson: Business[];
	@Input() selectedRoleId: number;
	@Input() index: number;

	public facebookAdAccountPermissions: AdAccountFormData[] = [];
	public businesses: DropdownData[];
	public businessesAccessLevels: DropdownData[];
	public adAccountAccessLevels: DropdownData[];
	public fullAdAccounts: DropdownData[];
	public addBusinessManager: FormGroup;
	public adAccounts: DropdownData[];
	public touched = false;
	public optionsForm: FormGroup;
	public radioValue: number;
	public accesLevelValidator = AccesLevelValidator;
	public businessValidator = BusinessValidator;
	public showMenuBO: boolean;

	private fullBusinesses: Business[];
	private selectedAdAccounts: AdAccountFormData[];
	private fullFacebookAdAccountPermissions: AdAccountFormData[];
	private selectedBusinessIds: number[];

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private formBuilder: FormBuilder, private toastNotificationService: ToastNotificationService, private store: Store<UserManagementState>) {}

	ngOnInit() {
		this.showMenuBO = false;
		this.fetchStore();
		this.createForm();
		this.fetchData();
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public selectNextAdAccount(): void {
		let foundAdAccount = false;
		this.adAccounts.forEach(_adAccount => {
			let found = false;
			if (this.selectedAdAccounts && this.selectedAdAccounts.length) {
				this.selectedAdAccounts.forEach(selectedAdAccount => {
					if (_adAccount.value === selectedAdAccount.name) {
						found = true;
					}
				});
			}
			if (!found && !foundAdAccount) {
				foundAdAccount = true;
				const adAccount = {
					name: _adAccount.value,
					level: 2,
					id: this.selectedAdAccounts && this.selectedAdAccounts.length ? this.selectedAdAccounts.length : 0,
					displayName: _adAccount.displayName
				} as AdAccountFormData;
				if (adAccount.name) {
					this.facebookAdAccountPermissions.push(adAccount);
					this.store.dispatch(new AddAdAccountData(adAccount));
				}
				return;
			}
		});
		if (!foundAdAccount) {
			this.toastNotificationService.sendWarningToast('No more ad accounts for this business');
		}
	}

	public checkPlus(bool = false): boolean {
		if (this.radioValue === 1) {
			return true;
		}
		if (!this.selectedBusinessId) {
			return true;
		}
		return bool;
	}

	public radioChange($event: MatRadioChange): void {
		this.radioValue = Number($event.value);
		this.store.dispatch(new UpdateRadioButtonValue({ index: this.index, value: this.radioValue }));

		if (this.radioValue === 1) {
			this.store.dispatch(new RemoveSelectedAdAccountByBusiness(this.fullAdAccounts));
			this.selectNextAdAccount();
		}
		this.checkPlus();
	}

	public removeAdAccount(value: string): void {
		this.store.dispatch(new RemoveSelectedAdAccount(value));
	}

	public toggleMenuBO(): void {
		this.showMenuBO = !this.showMenuBO;
	}

	private fetchStore(): void {
		this.store.dispatch(new AddRadioButtonValue(2));

		this.store.pipe(select(getAccount), takeUntil(this.unsubscriber$)).subscribe(accountState => {
			this.selectedAdAccounts = accountState.addEditUser.secondStepData.adAccountPermissionArray;
		});

		this.store.pipe(select(getSelectedBusiness), takeUntil(this.unsubscriber$)).subscribe(businesses => {
			this.selectedBusinessIds = businesses;

			this.store.pipe(select(getBusinesses), take(1)).subscribe(businesses => {
				this.removeSelectedBusinesses(businesses);
			});
		});

		this.store.pipe(select(getBusinesses), takeUntil(this.unsubscriber$)).subscribe(businesses => {
			this.removeSelectedBusinesses(businesses);
		});
		this.store.pipe(select(getUsersState), takeUntil(this.unsubscriber$)).subscribe(usersState => {
			this.businessesAccessLevels = usersState.facebookBusinessRoles.data;
			this.adAccountAccessLevels = usersState.facebookAdAccountRoles.data;
		});
	}

	private fetchAdAccounts(selectedBusiness: Business): void {
		this.store.pipe(select(getFacebookPermissions), takeUntil(this.unsubscriber$)).subscribe(facebookPermissions => {
			this.facebookAdAccountPermissions = [];
			if (facebookPermissions) {
				this.fullFacebookAdAccountPermissions = [...facebookPermissions];
			}
			let adAccounts;
			if (selectedBusiness) {
				// filter ad Accounts by business
				if (facebookPermissions) {
					facebookPermissions.forEach(adAccount => {
						selectedBusiness.adAccounts.forEach(_businessAdAccount => {
							if (adAccount.name === _businessAdAccount.id) {
								this.facebookAdAccountPermissions.push(adAccount);
							}
						});
					});
				}
				// filter by active ad accounts
				adAccounts = selectedBusiness.adAccounts.filter(adAccount => {
					return adAccount.status === 'ACTIVE';
				});
				// DropdownData format
				this.fullAdAccounts = adAccounts.map(adAccount => {
					return {
						displayName: adAccount.name,
						value: adAccount.id,
						selected: false
					};
				});
			}
			this.adAccounts = this.fullAdAccounts;
		});
	}

	private createForm(): void {
		this.optionsForm = this.formBuilder.group({
			options: new FormControl('2')
		});
		this.addBusinessManager = this.formBuilder.group({
			businessManager: new FormControl({ value: this.selectedBusinessId }, [Validators.required]),
			businessManagerAccessLevel: new FormControl('', [Validators.required])
		});
		this.addBusinessManager.controls['businessManager'].markAsTouched();
		this.addBusinessManager.controls['businessManager'].updateValueAndValidity();
		this.addBusinessManager.controls['businessManagerAccessLevel'].markAsTouched();

		this.listenToChanges();
	}

	private listenToChanges(): void {
		this.addBusinessManager.controls['businessManager'].valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (this.touched) {
				this.store.dispatch(new RemoveSelectedAdAccountByBusiness(this.fullAdAccounts));
			}
			this.touched = true;
			this.store.dispatch(new UpdateSelectedBusinessId(this.selectedBusinessId, value));
		});

		this.addBusinessManager.controls['businessManagerAccessLevel'].valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			this.store.dispatch(new UpdateSelectedAccesLevelId({ index: this.index, value: value }));
		});

		if (this.selectedBusinessId) {
			this.addBusinessManager.get('businessManager').patchValue(this.selectedBusinessId);
			this.addBusinessManager.get('businessManagerAccessLevel').patchValue(this.selectedRoleId);
		}

		this.addBusinessManager
			.get('businessManager')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(value => {
				let selectedBusiness: Business;
				if (this.fullBusinesses) {
					selectedBusiness = this.fullBusinesses.find(business => {
						return business.id === value;
					});
				}
				if (selectedBusiness) {
					this.fullAdAccounts = selectedBusiness.adAccounts
						.filter(adAccount => {
							return adAccount.status === 'ACTIVE' && adAccount.businessId === this.selectedBusinessId;
						})
						.map(adAccount => {
							return {
								displayName: adAccount.name,
								value: adAccount.id,
								selected: false
							};
						});
				}
			});
	}

	public addBusiness(): void {
		const businessId = this.getNextNonSelectedBusiness();
		if (businessId) {
			this.store.dispatch(new AddSelectedAccesLevelId(1));
			this.store.dispatch(new AddSelectedBusinessId(businessId));
		} else {
			this.toastNotificationService.sendWarningToast('No more available businesses');
		}
	}

	private fetchData(): void {
		const selectedBusiness = this.businessesFromJson.find(business => {
			return Number(business.id) === this.selectedBusinessId;
		});
		this.fetchAdAccounts(selectedBusiness);
	}

	public getBusinessNameById(value: number): string {
		const selectedBusiness = this.businesses.find(business => {
			return business.value === value;
		});
		if (selectedBusiness) {
			return selectedBusiness.displayName;
		} else {
			return '';
		}
	}

	private getNextNonSelectedBusiness(): number {
		let foundBusinessId: number = 0;
		this.businesses.forEach(_business => {
			let found = false;
			this.selectedBusinessIds.forEach((selectedBusinessId: number) => {
				if (!found && !foundBusinessId) {
					if (_business.value === selectedBusinessId) {
						found = true;
					}
				}
			});
			if (!found && !foundBusinessId) {
				foundBusinessId = Number(_business.value);
			}
		});
		return foundBusinessId;
	}

	public removeBusiness(): void {
		this.store.dispatch(new RemoveBusinessByIndex(this.index));
		this.store.dispatch(new RemoveSelectedAdAccountByBusiness(this.fullAdAccounts));
		this.store.dispatch(new RemoveRadioValueByIndex(this.index));
		this.store.dispatch(new RemoveSelectedAccesByIndex(this.index));
	}

	public onOutsideClick(): void {
		this.showMenuBO = false;
	}

	private removeSelectedBusinesses(businesses: Business[]): void {
		this.businesses = businesses
			.filter(business => {
				if (Number(business.id) === this.selectedBusinessId) {
					return true;
				}
				return !this.selectedBusinessIds.includes(Number(business.id));
			})
			.map((business: Business) => {
				return {
					displayName: business.name,
					value: Number(business.id),
					selected: false
				};
			});
	}
}
