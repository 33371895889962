import { Action } from '@ngrx/store';

export enum AppTypes {
	ResetAppState = '[ Application ] Clean State'
}

export class ResetAppState implements Action {
	public readonly type = AppTypes.ResetAppState;
	constructor() {}
}

export type AppAction = ResetAppState;
