import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownData, DropdownInterface } from '@filed-com/filed-lib';
import { Gender } from 'src/app/shared/smart-create-edit-components/step-two-components/targeting-card/models/gender';
import { getPlacementsFromCatalog, getPlatforms } from 'src/app/state/campaign-common-state/campaign-common-reducer';
import { CatalogNodeInterface } from '../../catalogs/catalog-node.interface';
import { OptimizationSettingsInterface } from '../../models/ab-test-interface';
import { BudgetAllocatedEnum } from '../../models/budget-allocated.enum';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { FiledLibMapperService } from '../../../shared/services/filed-lib-Mapper.service';
import { CampaignSmartCreateMapperService } from '../../services/campaign-smart-create-mapper.service';

interface IAgeRange {
	minAge: number;
	maxAge: number;
}
interface IPopupData {
	optimization: OptimizationSettingsInterface;
	currency: string;
}

@Component({
	selector: 'ab-testing-edit-popup',
	templateUrl: './ab-testing-edit-popup.component.html',
	styleUrls: ['./ab-testing-edit-popup.component.scss']
})
export class ABTestingEditPopup implements OnInit {
	public adData: OptimizationSettingsInterface;
	public ageRange: IAgeRange;
	public ageRangeEditing = false;
	public interests: string[];
	public gender: string;
	public currency: string = '£';

	public placementsList: CatalogNodeInterface[] = [];
	public budgetTypeControl: FormControl;
	public genderControl: FormControl;
	public placementsControl: FormControl;
	public budgetControl: FormControl;
	public ageRangeControl: FormControl;
	public budgetTypeListItems: DropdownInterface[] = [
		{ name: 'LifeTime', id: BudgetAllocatedEnum.LifeTime },
		{ name: 'Daily', id: BudgetAllocatedEnum.Daily }
	];

	public genderListItems: DropdownInterface[] = [
		{ name: 'All', id: Gender.All },
		{ name: 'Women', id: Gender.Women },
		{ name: 'Men', id: Gender.Men }
	];

	public placementsListItems: DropdownInterface[] = [
		{ name: 'FACEBOOK FEED', id: 1 },
		{ name: 'INSTAGRAM FEED', id: 2 }
	];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: IPopupData,
		public dialogRef: MatDialogRef<ABTestingEditPopup>,
		private store: Store,
		private mapperService: FiledLibMapperService,
		private mapperSmartService: CampaignSmartCreateMapperService
	) {
		this.budgetTypeControl = new FormControl({ value: null, disabled: true }, [Validators.required]);
		this.genderControl = new FormControl(null, [Validators.required]);
		this.placementsControl = new FormControl(null, [Validators.required]);
		this.ageRangeControl = new FormControl(null, [Validators.required]);
		this.budgetControl = new FormControl({ value: 0, disabled: false }, [Validators.required]);
	}
	public ngOnInit(): void {
		this.store.pipe(select(getPlatforms)).subscribe(platforms => {
			if (platforms) {
				this.placementsList = this.mapperSmartService.sortContexts(platforms);
				this.placementsListItems = this.mapperService.convertToDropdownData(this.placementsList, 'name', 'displayNameAutogen');
			}
		});
		this.currency = this.data.currency;
		this.adData = JSON.parse(JSON.stringify(this.data.optimization));
		this.ageRange = this.adData?.targeting?.ageRange;
		this.interests = this.adData?.targeting?.interests ? this.adData?.targeting?.interests?.map(interest => interest.name) : [];
		this.budgetTypeControl.patchValue(this.budgetTypeListItems.find(el => el.id === this.adData?.budgetType));
		this.genderControl.patchValue(this.genderListItems.find(el => el.id === this.adData?.targeting?.gender));
		this.placementsControl.patchValue(this.placementsListItems.find(el => el.name === this.adData?.placements[0].name));
		this.budgetControl.patchValue(this.adData?.customBudgetAmount || this.adData?.defaultBudgetAmount);
	}

	public toggleAgeRange(e: Event): void {
		if (e.currentTarget === e.target) {
			this.ageRangeEditing = false;
		}
	}

	public budgetTypeValueChanges($event): void {
		this.adData.budgetType = $event.id;
	}

	public genderValueChanges($event): void {
		this.adData.targeting.gender = $event.id;
	}

	public placeValueChanges($event): void {
		this.adData.placements = this.placementsList.filter(el => el.name !== $event.name);
	}

	public ageRangeSliderEvent($event): void {
		this.adData.targeting.ageRange = { maxAge: $event.highValue, minAge: $event.value };
	}

	public closeForm(param: boolean = false): void {
		this.dialogRef.close(param);
	}

	public saveForm(): void {
		if (this.budgetControl.valid) {
			this.adData.customBudgetAmount = this.budgetControl.value;
			this.dialogRef.close(this.adData);
		}
	}

	openSlider(): void {
		if (!this.ageRange) {
			return;
		}
		this.ageRangeEditing = !this.ageRangeEditing;
	}
}
