import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CellClassParams, ColDef, IFilterComp, RowNode } from 'ag-grid-community';
import { of, Subject } from 'rxjs';
import moment from 'moment';
import { debounceTime, skip, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { AdsManagerHeaderCheckboxComponent } from 'src/app/ads-manager/ads-manager-insights/components-pieces/ads-manager-ag-grid-components/ads-manager-header-checkbox/ads-manager-header-checkbox.component';
import { TimeRangeInterface } from 'src/app/ads-manager/ads-manager-insights/models/time-range.interface';
import { AdSetsService } from 'src/app/ads-manager/ads-manager-insights/services/ad-sets.service';
import { AdsManagerHelperService } from 'src/app/ads-manager/ads-manager-insights/services/ads-manager-helper.service';
import { CampaignsService } from 'src/app/ads-manager/ads-manager-insights/services/campaigns.service';
import { AdsManagerResolverDataInterface } from 'src/app/ads-manager/models/ads-manager-resolver-data.interface';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { ObjectivesEnum } from 'src/app/campaign-smart-create/models/objectives.enum';
import { SubObjectives } from 'src/app/campaign-smart-create/models/sub-objectives';
import {
	UpdateObjective,
	UpdateSelectedAAAAdSetIds,
	UpdateSelectedAAAAdSets,
	UpdateSelectedAAACamapignIds,
	UpdateSelectedAAACampaigns
} from '../state/aaa.actions';
import { CustomFieldCellRendererComponent } from 'src/app/shared/ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer.component';
import { MasterTableRowSelectionEnum } from 'src/app/shared/master-table/master-table-row-selection.enum';
import { AgGridViewInterface } from 'src/app/shared/master-table/models/ag-grid-view.interface';
import { MasterTableGridReadyInterface } from 'src/app/shared/master-table/models/master-table-grid-ready.interface';
import { MasterTableImplementationsEnum } from 'src/app/shared/master-table/models/master-table-implementations.enum';
import { RowModelTypeEnum } from 'src/app/shared/master-table/models/row-model-type.enum';
import { StandardColorsEnum } from 'src/app/shared/models/standard-colors.enum';
import { getGlobalDate, getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { AAAModuleState, getObjectivesWithDestinationFromCatalog, getObjectiveTree, getSelectedAdsets, getSelectedCampaigns } from '../state';
import { ObjectiveTreeInterface } from 'src/app/campaign-smart-create/models/objective-tree.interface';
import { CatalogItemsInterface } from 'src/app/campaign-smart-create/catalogs/catalog.items.interface';
import { AaaHeaderFooterStateService } from '../services/aaa-header-footer-state.service';
import { AAACampaignViewsEnum } from '../models/AAA-campaign-views.enum';
import { AAAAdsetViewsEnum } from '../models/AAA-adset-views.enum';
import { AAAFlow, stepName } from '../models/AAA-steps.enum';
import { setStepBasedOnFlow } from '../helpers/helper';
import { CampaignAAAInterface } from '../models/campaign';
import { AdSetsAAAInterface } from '../models/ad-sets-aaa.interface';
import { LoadCampaignCatalogs } from '../../state/campaign-common-state/campaign-common-actions';

@Component({
	selector: 'app-add-ad',
	templateUrl: './add-ad.component.html',
	styleUrls: ['./add-ad.component.scss']
})
export class AddAdComponent implements OnInit {
	public objectives: CatalogNodeInterface[];
	public objective = ObjectivesEnum;
	public adsManagerMasterTableImplementations = MasterTableImplementationsEnum;
	public campaignView: ColDef[];
	public adSetView: ColDef[];
	public campaignViews: AgGridViewInterface[];
	public adSetViews: AgGridViewInterface[];
	public frameworkComponents = {
		checkboxHeader: AdsManagerHeaderCheckboxComponent,
		customFieldCellRenderer: CustomFieldCellRendererComponent
	};
	public rowSelection = MasterTableRowSelectionEnum;
	public rowModelType = RowModelTypeEnum;
	public defaultPageSize = 25;
	public stepperData = [
		{ label: 'Select Campaigns', icon: 'user' },
		{ label: 'Create Ad', icon: 'dictionary-add' },
		{ label: 'Review & Publish', icon: 'attachment' }
	];
	public selectedObjective: ObjectiveTreeInterface;
	public defaultFilterParams = {
		suppressAndOrCondition: true,
		buttons: ['reset', 'apply'],
		debounceMs: 200
	};
	public numberFilterOptions = ['lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'];
	public defaultColDef: ColDef = {
		minWidth: 100,
		flex: 1,
		sortable: true,
		resizable: true,
		lockPinned: true,
		menuTabs: [],
		filterParams: { ...this.defaultFilterParams },
		cellStyle: (params: CellClassParams) => {
			if (!params.colDef.pinned || params.colDef.editable) {
				return {
					'border-right': `1px solid ${StandardColorsEnum.FiledLighterGray}`
				};
			}
			return { border: '0' };
		}
	};
	public paginationOptions = [25, 50, 100, 200];
	public selectedObjectiveName: string;
	public selectedSubObjective: CatalogItemsInterface;
	public selectedCampaignIds: string[] = [];
	public selectedAdSetIds: string[] = [];
	public selectedCampaigns: any[];
	public selectedAdsets: any[];
	public searchFormControl: FormGroup;
	public isCampaignCardCollapsed = true;
	public isAdSetCardCollapsed = true;
	public isCampaignCardDisabled = true;
	public isAdSetCardDisabled = true;
	public initialCampaignFilters: (string | true | (new () => IFilterComp))[];
	public initialAdSetFilters: (string | true | (new () => IFilterComp))[];
	private unsubscriber$ = new Subject<void>();
	public flow: AAAFlow;
	public isAllAdsetsSelected: boolean = true;
	public customObjectives: any;

	constructor(
		private store: Store<AAAModuleState>,
		public campaignsService: CampaignsService,
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public adsManagerHelper: AdsManagerHelperService,
		private sharedStore: Store<SharedState>,
		public adSetsService: AdSetsService,
		private formBuilder: FormBuilder,
		private aaaStateService: AaaHeaderFooterStateService
	) {
		this.listenToRoute();
	}

	public ngOnInit(): void {
		this.getObjectives();
		this.initializeSubscriptions();
		this.getViews();
		this.createForm();
		this.filteredData();
		this.registeringActions();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public refactorNumberFilterOptions(column: ColDef): void {
		if (column.filter === 'agNumberColumnFilter') {
			column.filterParams = {
				...this.defaultFilterParams,
				filterOptions: this.numberFilterOptions
			};
		}
	}

	public extractDefaultFilters(arr: ColDef[]): (string | true | (new () => IFilterComp))[] {
		return arr.map(item => item.filter || '');
	}

	public evaluteRowSelection(): MasterTableRowSelectionEnum {
		return this.flow === 0 || this.flow === 1 ? this.rowSelection.Multiple : this.rowSelection.Single;
	}

	public filteredData(): void {
		this.searchFormControl
			.get('searchCampaignForm')
			.valueChanges.pipe(debounceTime(500))
			.subscribe(value => {
				this.campaignsService.filterModel = value.length ? { campaign_name: { filterType: 'text', type: 'contains', filter: value } } : {};
				this.campaignsService.resetRequest();
			});
		this.searchFormControl
			.get('searchAdSetForm')
			.valueChanges.pipe(debounceTime(500))
			.subscribe(value => {
				this.adSetsService.filterName = value;
				this.adSetsService.resetRequest();
			});
	}

	public getObjectives(): void {
		this.store.dispatch(new LoadCampaignCatalogs());
		this.store
			.pipe(
				select(getObjectivesWithDestinationFromCatalog),
				skipWhile(objectives => !objectives),
				take(1)
			)
			.subscribe(objectives => {
				this.objectives = objectives;
				this.hideObjectivesAndSUbObjectives();
			});
	}

	public hideObjectivesAndSUbObjectives(): void {
		this.objectives.pop();
		this.objectives = this.objectives.filter(item => item.name !== this.objective.AppActivity);
		const unwanted = [SubObjectives.LocalAwareness, SubObjectives.LeadGeneration, SubObjectives.Message, SubObjectives.StoreVisits];
		this.objectives.forEach(item => {
			item.items = item.items.filter(child => child.name !== unwanted.filter(x => x === child.name)[0]);
		});
		this.customObjectives = [
			{
				name: 'Awareness',
				objective: this.objectives.find(xd => xd.name === this.objective.Awareness),
				image: '',
				subObjectiveName: SubObjectives.BrandAwarenessName,
				imageState: 'default'
			},
			{
				name: 'Conversions',
				objective: this.objectives.find(xd => xd.name === this.objective.Conversions),
				image: '',
				subObjectiveName: SubObjectives.WebsiteConversionName,
				imageState: 'default'
			},
			{
				name: 'Dynamic Ads',
				objective: this.objectives.find(xd => xd.name === this.objective.Conversions),
				image: '',
				subObjectiveName: SubObjectives.CatalogSalesName,
				disabled: false,
				imageState: 'default'
			},
			{
				name: 'App Installs',
				objective: this.objectives.find(xd => xd.name === this.objective.Consideration),
				image: '',
				subObjectiveName: SubObjectives.AppInstallName,
				disabled: true,
				imageState: 'default'
			}
		];
	}

	public adsetsCheckBox($event: boolean): void {
		this.isAllAdsetsSelected = $event;

		if (!$event) {
			this.isAdSetCardCollapsed = false;
			this.isAdSetCardDisabled = false;
			if (!this.selectedCampaignIds.length) {
				this.selectedCampaignIds = [];
				this.campaignsService.gridApi.forEachNode((row: RowNode) => {
					this.selectedCampaignIds.push(row.data.campaign_id);
				});
			}
			this.adSetsService.selectedCampaignIds = this.selectedCampaignIds;
			this.adSetsService.resetRequest();
		} else {
			this.isAdSetCardCollapsed = true;
			this.isAdSetCardDisabled = true;
		}
	}

	public selectedCard($event: any): void {
		this.selectedObjectiveName = $event;
		this.objectives.forEach(objective => {
			const subObjective = objective.items.find(subobjective => subobjective.display_name === this.selectedObjectiveName);
			if (subObjective) {
				for (let [key, value] of Object.entries(subObjective.items)) {
					if (value.kind === 'Objective') {
						this.selectedSubObjective = value;
						this.selectedObjective = {
							objective: subObjective.name,
							subObjective: value.name,
							destinationType: value.kind,
							subObjectiveDisplayName: subObjective.display_name
						};
					}
					if (value.kind === 'DestinationType') {
						this.selectedObjective.destinationType = value.name;
					}
				}
			}
		});

		this.campaignsService.filterObjective = [this.selectedSubObjective.name];
		this.adSetsService.filterObjective = this.selectedSubObjective.name;
		this.isCampaignCardCollapsed = false;
		this.isCampaignCardDisabled = false;
		this.store.dispatch(new UpdateSelectedAAACampaigns(null));
		this.store.dispatch(new UpdateSelectedAAAAdSets(null));
		this.store.dispatch(new UpdateSelectedAAACamapignIds(null));
		this.store.dispatch(new UpdateSelectedAAAAdSetIds(null));
		this.selectedCampaignIds = [];
		this.selectedCampaigns = [];
		this.selectedAdSetIds = [];
		this.selectedAdSetIds = [];
		this.campaignsService.selectedCampaignsIds = [];
		this.adSetsService.selectedCampaignIds = [];
		this.adSetsService.selectedAdSetsIds = [];
		this.campaignsService.resetRequest();
		this.adSetsService.resetRequest();
		this.store.dispatch(new UpdateObjective(this.selectedObjective));
	}

	private createForm(): void {
		this.searchFormControl = this.formBuilder.group({
			searchCampaignForm: new FormControl(null),
			searchAdSetForm: new FormControl(null)
		});
	}

	public getViews(): void {
		this.activatedRoute.data.pipe(take(1)).subscribe((obj: AdsManagerResolverDataInterface) => {
			this.campaignViews = obj.views.campaignViews;
			const campaignColIds = [
				'campaign_id',
				'selected',
				'campaign_name',
				'impressions',
				'results',
				'cost_per_result',
				'cost_per_unique_click_all',
				'unique_ctr_all'
			];
			this.campaignView = this.campaignViews.find(campaignView => campaignView.isDefault).columns;
			this.campaignView = this.campaignView.filter(campaignView => campaignColIds.includes(campaignView.colId));
			const adsetsColIds = ['selected', 'adset_name', 'impressions', 'results', 'cost_per_result', 'cost_per_unique_click_all', 'unique_ctr_all'];
			this.adSetViews = obj.views.adSetViews;
			this.adSetView = this.adSetViews.find(adSetView => adSetView.isDefault).columns;
			this.adSetView = this.adSetView.filter(adSetView => adsetsColIds.includes(adSetView.colId));
			this.adSetView.splice(
				2,
				0,
				this.campaignView.find(campaignview => campaignview.colId === 'campaign_name')
			);
			this.initialCampaignFilters = this.extractDefaultFilters(this.campaignView);
			this.initialAdSetFilters = this.extractDefaultFilters(this.adSetView);
			this.campaignView.forEach(campaignView => {
				switch (campaignView.colId) {
					case AAACampaignViewsEnum.campaign_name:
						campaignView.pinned = null;
						campaignView.pinnedRowCellRenderer = null;
						campaignView.cellRenderer = null;
						break;
					case AAACampaignViewsEnum.cost_per_result:
						campaignView.headerName = 'CPR';
						break;
					case AAACampaignViewsEnum.cost_per_unique_click_all:
						campaignView.headerName = 'CPC';
						break;
					case AAACampaignViewsEnum.unique_ctr_all:
						campaignView.headerName = 'CTR';
						break;
				}
			});
			this.adSetView.forEach(adSet => {
				switch (adSet.colId) {
					case AAAAdsetViewsEnum.campaign_name:
						adSet.pinned = null;
						adSet.pinnedRowCellRenderer = null;
						adSet.cellRenderer = null;
						break;
					case AAAAdsetViewsEnum.adset_name:
						adSet.pinned = null;
						adSet.pinnedRowCellRenderer = null;
						adSet.cellRenderer = null;
						break;
					case AAAAdsetViewsEnum.cost_per_result:
						adSet.headerName = 'CPR';
						break;
					case AAAAdsetViewsEnum.cost_per_unique_click_all:
						adSet.headerName = 'CPC';
						break;
					case AAAAdsetViewsEnum.unique_ctr_all:
						adSet.headerName = 'CTR';
						break;
				}
			});
			this.campaignView.forEach((campaign, index) => {
				if (campaign.sortable === true) {
					campaign.headerTooltip = '';
					campaign.tooltipField = campaign.field;
				}

				campaign.filter = this.initialCampaignFilters[index] || false;
				this.refactorNumberFilterOptions(campaign);
				campaign.suppressMenu = false;
			});
			this.adSetView.forEach((adSet, index) => {
				if (adSet.sortable === true) {
					adSet.headerTooltip = '';
					adSet.tooltipField = adSet.field;
				}

				adSet.filter = this.initialAdSetFilters[index] || false;
				this.refactorNumberFilterOptions(adSet);
				adSet.suppressMenu = false;
			});
			const toolPanelClassHidden = 'u-hidden';
			this.campaignView[0].toolPanelClass = toolPanelClassHidden;
			this.adSetView[0].toolPanelClass = toolPanelClassHidden;
		});
	}

	public onGridReady(masterTableReady: MasterTableGridReadyInterface): void {
		if (masterTableReady.masterTableImplementation === this.adsManagerMasterTableImplementations.AdsManagerCampaignTable) {
			this.campaignsService.resetRequest();
			this.campaignsService.gridApi = masterTableReady.gridApi;
			this.campaignsService.columnApi = masterTableReady.columnApi;
		} else if (masterTableReady.masterTableImplementation === this.adsManagerMasterTableImplementations.AdsManagerAdSetTable) {
			this.adSetsService.resetRequest();
			this.adSetsService.gridApi = masterTableReady.gridApi;
			this.adSetsService.columnApi = masterTableReady.columnApi;
		}
	}

	private initializeSubscriptions(): void {
		this.sharedStore.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(data => {
			this.campaignsService.selectedAccount = data.adAccount.id;
			this.adSetsService.selectedAccount = data.adAccount.id;
			this.adSetsService.hasDelivery = false;
			this.campaignsService.innerStoreAction = true;
			this.adSetsService.innerStoreAction = true;
			this.isAllAdsetsSelected = true;
			this.selectedCampaignIds = [];
			this.selectedCampaigns = [];
			this.selectedAdSetIds = [];
			this.selectedAdSetIds = [];
			this.campaignsService.selectedCampaignsIds = [];
			this.adSetsService.selectedCampaignIds = [];
			this.adSetsService.selectedAdSetsIds = [];
			this.campaignsService.resetRequest();
			this.adSetsService.resetRequest();
		});
		this.sharedStore.pipe(select(getSelectedAdAccount), skip(1), takeUntil(this.unsubscriber$)).subscribe(data => {
			this.store.dispatch(new UpdateSelectedAAACampaigns(null));
			this.store.dispatch(new UpdateSelectedAAAAdSets(null));
			this.store.dispatch(new UpdateSelectedAAACamapignIds(null));
			this.store.dispatch(new UpdateSelectedAAAAdSetIds(null));
		});
		this.store.pipe(select(getObjectiveTree), takeUntil(this.unsubscriber$)).subscribe(objective => {
			this.selectedObjectiveName = objective?.subObjectiveDisplayName;
			this.objectives?.forEach(objective => {
				const subObjective = objective.items.find(subobjective => subobjective.display_name === this.selectedObjectiveName);
				if (subObjective) {
					for (let [key, value] of Object.entries(subObjective.items)) {
						if (value.kind === 'Objective') {
							this.selectedSubObjective = value;
							this.selectedObjective = {
								objective: subObjective.name,
								subObjective: value.name,
								destinationType: value.kind,
								subObjectiveDisplayName: subObjective.display_name
							};
						}
						if (value.kind === 'DestinationType') {
							this.selectedObjective.destinationType = value.name;
						}
					}
				}
			});
		});

		this.store
			.pipe(
				select(getSelectedCampaigns),
				debounceTime(50),
				switchMap((selectedCampaigns: CampaignAAAInterface[]) => {
					return of(selectedCampaigns);
				}),
				takeUntil(this.unsubscriber$)
			)
			.subscribe((selectedCampaigns: CampaignAAAInterface[]) => {
				this.selectedCampaigns = selectedCampaigns;
				if (selectedCampaigns && selectedCampaigns.length !== 0) {
					this.selectedCampaignIds = selectedCampaigns.map(campaignRow => campaignRow.campaign_id);
					this.store.dispatch(new UpdateSelectedAAACamapignIds(this.selectedCampaignIds));
					this.isCampaignCardCollapsed = false;
					this.campaignsService.selectedCampaignsIds = this.selectedCampaignIds;
					this.adSetsService.selectedCampaignIds = this.selectedCampaignIds;
				} else {
					this.selectedCampaignIds = [];
					this.store.dispatch(new UpdateSelectedAAACamapignIds(null));
					this.campaignsService.deselectAll();
					this.campaignsService.selectedCampaignsIds = [];
					this.adSetsService.selectedCampaignIds = [];
				}
				this.adSetsService.resetRequest();
			});

		this.store
			.pipe(
				select(getSelectedAdsets),
				debounceTime(50),
				takeUntil(this.unsubscriber$),
				switchMap((selectedAdSets: AdSetsAAAInterface[]) => {
					return of(selectedAdSets);
				})
			)
			.subscribe((selectedAdSets: AdSetsAAAInterface[]) => {
				this.selectedAdsets = selectedAdSets;
				if (selectedAdSets && selectedAdSets.length !== 0) {
					this.isAdSetCardCollapsed = false;
					this.selectedAdSetIds = selectedAdSets.map(adSetRow => adSetRow.adset_id);
					this.store.dispatch(new UpdateSelectedAAAAdSetIds(this.selectedAdSetIds));
					this.adSetsService.selectedAdSetsIds = this.selectedAdSetIds;
				} else {
					this.adSetsService.deselectAll();
					this.adSetsService.selectedAdSetsIds = [];
					this.selectedAdSetIds = [];
					this.store.dispatch(new UpdateSelectedAAAAdSetIds(null));
				}
			});

		this.sharedStore.pipe(select(getGlobalDate), takeUntil(this.unsubscriber$)).subscribe(date => {
			const newTimeRange: TimeRangeInterface = {
				since: moment().subtract(6, 'days').format('YYYY-MM-DD'),
				until: moment().format('YYYY-MM-DD')
			};
			this.campaignsService.timeRange = newTimeRange;
			this.adSetsService.timeRange = newTimeRange;
		});
	}

	public registeringActions() {
		this.aaaStateService
			.onNext()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.aaaStateService.sendNavigateForward();
			});
		this.aaaStateService
			.onSave()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {});
		this.aaaStateService
			.onEdit()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {});
	}

	public setCurrentStepBasedOnFlow(): void {
		setStepBasedOnFlow(this, this.flow, this.setCurrentStep, stepName.SelectCampaigns);
	}
	public listenToRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.flow = +params['flow'];
			this.setCurrentStepBasedOnFlow();
		});
	}
	public setCurrentStep(step: number): void {
		this.aaaStateService.sendActiveStep(step);
		this.aaaStateService.sendCampaignStep(step);
		this.aaaStateService.onPreviewAd(false);
		this.aaaStateService.setHasEdit({ disabled: false, show: false });
	}
}
