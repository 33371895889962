import { Helper } from '../helper';

export class ChartLabel {
	formatter: any;

	constructor(
		public align: string,
		public verticalAlign: string,
		public position: string,
		public rotate: number,
		public distance: number,
		public fontSize: number,
		public show: boolean
	) {
		this.attachFormatter();
	}

	attachFormatter() {
		this.formatter = (data: any) => {
			return Helper.convertLabelValues(data.value);
		};
	}
}
