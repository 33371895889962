import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { Ng5SliderModule } from 'ng5-slider';
import { SharedModule } from '../shared/shared.module';
import { AutomatedChatComponent } from '../shared/smart-create-edit-components/step-three-components/lead-generation-methods/automated-chat-component/automated-chat.component';
import { InstantFormComponent } from '../shared/smart-create-edit-components/step-three-components/lead-generation-methods/instant-form-component/instant-form.component';
import { LeadGenerationComponent } from '../shared/smart-create-edit-components/step-two-components/lead-generation/lead-generation.component';
import { CampaignSmartCreateRoutingModule } from './campaign-smart-create-routing.module';
import { CampaignSmartCreateFooterComponent } from './components-pieces/campaign-smart-create-footer/campaign-smart-create-footer.component';
import { CampaignSmartCreateNavigationComponent } from './components-pieces/campaign-smart-create-navigation/campaign-smart-create-navigation.component';
import { CampaignSummaryCardComponent } from './components-pieces/campaign-summary-card/campaign-summary-card.component';
import { DevicesCardComponent } from './components-pieces/devices-card/devices-card.component';
import { InterestsCardComponent } from './components-pieces/interests-card/interests-card.component';
import { OptimizationBudgetCardComponent } from './components-pieces/optimization-budget-card/optimization-budget-card.component';
import { OptimizationLocationComponent } from './components-pieces/optimization-location/optimization-location.component';
import { OptimizationPlacementsCardComponent } from './components-pieces/optimization-placements-card/optimization-placements-card.component';
import { SplitAgeRangeCardComponent } from './components-pieces/split-age-range-card/split-age-range-card.component';
import { SplitGenderCardComponent } from './components-pieces/split-gender-card/split-gender-card.component';
import { AdSetSettingsComponent } from './components/ad-set-settings/ad-set-settings.component';
import { BuildCampaignTemplateComponent } from './components/build-campaign-template/build-campaign-template.component';
import { CampaignWelcomePageComponent } from './components/campaign-welcome-page/campaign-welcome-page.component';
import { CreateAdComponent } from './components/create-ad/create-ad.component';
import { OptimizationComponent } from './components/optimization/optimization.component';
import { CampaignSelectorComponent } from './components/smart-create-campaign-select/campaign-selector/campaign-selector.component';
import { SmartCreateCampaignSelect } from './components/smart-create-campaign-select/smart-create-campaign-select.component';
import { AdSetSettingsResolver } from './resolver/ad-set-settings.resolver';
import { CreateAdResolver } from './resolver/create-ad.resolver';
import { CreateCampaignResolver } from './resolver/create-campaign.resolver';
import { OptimizationResolver } from './resolver/optimization.resolver';
import { SelectCampaignResolver } from './resolver/select-campaign.resolver';
import { CampaignSmartCreateBudgetSplitsService } from './services/campaign-smart-create-budget-splits.service';
import { CampaignSmartCreateMapperService } from './services/campaign-smart-create-mapper.service';
import { CampaignSmartCreatePythonService } from './services/campaign-smart-create-python.service';
import { CampaignSmartCreateValidationService } from './services/campaign-smart-create-validation.service';
import { CampaignSmartCreateService } from './services/campaign-smart-create.service';
import { SmartCreateEffects } from './state/smart-create.effects';
import { campaignSmartCreateReducer } from './state/smart-create.reducer';
import { BudgetAllocationPopupComponent } from './components-pieces/budget-allocation-popup/budget-allocation-popup.component';
import { ButtonsModule, CardsModule, DropdownsModule, FontsModule, TreeViewsModule } from '@filed-com/filed-lib';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { AAAModule } from '../AAA/AAA.module';
import { CreateAdNavComponent } from './components-pieces/create-ad-nav/create-ad-nav.component';
import { MessageDestinationComponent } from './components-pieces/message-destination/message-destination.component';
import { ChooseCatalogComponent } from './components-pieces/choose-catalog/choose-catalog.component';
import { CatalogSelectorComponent } from './components-pieces/catalog-selector/catalog-selector.component';
import { CampaignAppSelectorComponent } from './components-pieces/campaign-app-selector/campaign-app-selector.component';
import { CampaignFacebookRegComponent } from './components-pieces/campaign-facebook-reg/campaign-facebook-reg.component';
import { PublishingTreeStructureComponent } from './components/publishing-trees-tructure/publishing-tree-structure.component';
import { AbTestingComponent } from './components/ab-testing/ab-testing.component';
import { PlacementsSplitCardComponent } from './components-pieces/placements-split-card/placements-split-card.component';
import { ABTestingEditPopup } from './components-pieces/ab-testing-edit-popup/ab-testing-edit-popup.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AgGridModule } from 'ag-grid-angular';
import { CampaignBuilderIntroComponent } from './components/campaign-builder-intro/campaign-builder-intro.component';

@NgModule({
	declarations: [
		SmartCreateCampaignSelect,
		CampaignSelectorComponent,
		CampaignWelcomePageComponent,
		BuildCampaignTemplateComponent,
		CampaignSmartCreateNavigationComponent,
		AdSetSettingsComponent,
		CampaignSmartCreateFooterComponent,
		CreateAdComponent,
		OptimizationComponent,
		DevicesCardComponent,
		OptimizationPlacementsCardComponent,
		SplitGenderCardComponent,
		SplitAgeRangeCardComponent,
		SplitAgeRangeCardComponent,
		CampaignSummaryCardComponent,
		InterestsCardComponent,
		OptimizationBudgetCardComponent,
		OptimizationLocationComponent,
		BudgetAllocationPopupComponent,
		CreateAdNavComponent,
		LeadGenerationComponent,
		InstantFormComponent,
		AutomatedChatComponent,
		AutomatedChatComponent,
		MessageDestinationComponent,
		CreateAdNavComponent,
		ChooseCatalogComponent,
		CatalogSelectorComponent,
		CampaignAppSelectorComponent,
		CampaignFacebookRegComponent,
		PublishingTreeStructureComponent,
		AbTestingComponent,
		PlacementsSplitCardComponent,
		ABTestingEditPopup,
		CampaignBuilderIntroComponent
	],
	imports: [
		CommonModule,
		CampaignSmartCreateRoutingModule,
		StoreModule.forFeature('campaignSmartCreate', campaignSmartCreateReducer),
		EffectsModule.forFeature([SmartCreateEffects]),
		SharedModule,
		Ng5SliderModule,
		DragDropModule,
		ButtonsModule,
		CardsModule,
		AAAModule,
		DropdownsModule,
		FontsModule,
		TreeViewsModule,
		MatDialogModule,
		AgGridModule
	],
	exports: [
		BuildCampaignTemplateComponent,
		MessageDestinationComponent,
		CatalogSelectorComponent,
		CampaignFacebookRegComponent,
		CampaignAppSelectorComponent
	],
	providers: [
		InterceptorsProvider,
		CampaignSmartCreateService,
		CampaignSmartCreatePythonService,
		SelectCampaignResolver,
		CreateCampaignResolver,
		CampaignSmartCreateMapperService,
		AdSetSettingsResolver,
		CreateAdResolver,
		OptimizationResolver,
		CampaignSmartCreateValidationService,
		CampaignSmartCreateBudgetSplitsService,
		InterceptorsProvider,
		{
			provide: MatDialogRef,
			useValue: {}
		}
	]
})
export class CampaignSmartCreateModule {}
