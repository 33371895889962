import { TargetingAudience } from './targeting-audience';
import { DateRangeX } from '../../shared/calendar/calendar.models';
import { CampaignDateChoices } from '../../shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { CampaignBudgetOptimizationInterface } from './campaign-budget-optimization.interface';
import { OptimizationAndDeliveryInterface } from './optimization-and-delivery.interface';
import { CatalogNodeInterface } from '../catalogs/catalog-node.interface';
import { IAPIAdModel } from 'src/app/AAA/helpers/helper';

export class StepTwoDetails {
	public adSetName: string;
	public name?: string;
	public facebookPageId?: string;
	public instagramPageId?: string;
	public placements: CatalogNodeInterface[] = [];
	public targeting: TargetingAudience;
	public date: DateRangeX<string>;
	public dateChoice: CampaignDateChoices;
	public budgetOptimization: CampaignBudgetOptimizationInterface;
	public optimizationAndDelivery: OptimizationAndDeliveryInterface;
	public ads?: IAPIAdModel[];
	public productSetId?: string;
	public appId?: string;
	public appLink?: string;
}
