<div class="container-fluid">
	<div class="row main-row">
		<div class="col-12">
			<div class="row justify-content-center title-container">
				<div class="col-12">
					<div class="f18-black-medium" *ngIf="header">{{ header }}</div>
					<div class="f12-black" *ngIf="subHeader">
						{{ subHeader }}
						<a class="read-more" href="{{ readMoreLink }}" *ngIf="readMoreLink">{{ readMoreText }}</a>
					</div>
				</div>
			</div>
			<div class="row g-0">
				<div
					(mouseover)="triggerAssistMouseOver(card)"
					class="selection-card-wrapper {{ card.disabled ? 'disabled' : '' }}"
					(click)="activateCard(card)"
					*ngFor="let card of selectionCards"
				>
					<div class="selection-card  {{ selected == card ? 'selected' : '' }}">
						<div class="recommendation" *ngIf="card?.recommended">
							<img src="assets/google-cb/recommend-tag.svg" alt="recommendation" />
						</div>
						<div>
							<img [src]="getIcon(card.icon)" />
						</div>
						<div class="title-line">
							<h5>{{ card?.title }}</h5>
							<div *ngIf="card.disabled" class="coming-soon">Coming Soon</div>
						</div>
						<p>{{ card?.description }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
