export enum UserManagementEffectsEnum {
	LoadUsers = 'LoadUsers',
	LoadFiledUser = 'LoadFiledUser',
	LoadCatalogs = 'LoadCatalogs',
	LoadSubscriptions = 'LoadSubscriptions',
	LoadPermissions$ = 'LoadPermissions$',
	LoadCards = 'LoadCards',
	UpdateProfilePicture = 'UpdateProfilePicture',
	MakePrimaryCard = 'MakePrimaryCard',
	LoadBillingAccountInfo = 'LoadBillingAccountInfo',
	LoadInvoiceHistory = 'LoadInvoiceHistory'
}
