import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SubscriptionReason } from '../../../../_models/subscription-reason';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from '../../../../_services/contact/contact.service';
import { UserServiceApi } from '../../../../_services/user/user.api.service';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { getSubscriptionsAnswers, getSubscriptionsReasons, UserManagementState } from '../../../state/user-management.reducer';
import { select, Store } from '@ngrx/store';
import { ValidatorMessages } from '../../../../shared/form-input/validatorMessagesInputs';
import { DropdownData } from '../../../../shared/dropdown-search-select/dropdown-data.interface';
import { CommentValidator } from '../../../models/validators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../../shared/state/shared.actions';
import { SettingsPageLabelsEnum } from '../../../shared/enums/navigation.enum';

@Component({
	selector: 'app-cancel-subscription-question',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
	public reason: FormGroup;
	public reasonSelectedId: number;

	public reasons: DropdownData[];
	public answers: DropdownData[];

	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	public textareaMessages: ValidatorMessages[] = CommentValidator;
	public textareaComment: FormControl;

	private subscriptionReason: SubscriptionReason;
	private reasonOption: FormControl;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService,
		private usersServiceApi: UserServiceApi,
		private contactService: ContactService,
		private location: Location,
		private store: Store<UserManagementState>
	) {}

	ngOnInit() {
		this.fetchStore();
		this.createForm();
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public sendEmail(): void {
		this.subscriptionReason = {
			issueType: this.getReasonNameById(this.reasonOption.value),
			userComment: this.textareaComment.value
		};

		this.store.dispatch(new ShowGlobalSpinner());

		this.contactService
			.sendContactEmail(this.subscriptionReason)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					this.store.dispatch(new HideGlobalSpinner());
					this.toastNotificationService.sendSuccessToast('Email sent');
				},
				() => {
					this.store.dispatch(new HideGlobalSpinner());
					this.showError();
				}
			);
	}

	public goBack(): void {
		this.location.back();
	}

	public formInvalid(): boolean {
		return !!this.reasonSelectedId || this.textareaComment.invalid;
	}

	private getReasonNameById(id: number): string {
		return this.reasons.find(reason => {
			return reason.value === id;
		}).displayName;
	}

	private fetchStore(): void {
		this.store.pipe(select(getSubscriptionsAnswers), takeUntil(this.unsubscriber$)).subscribe(answers => {
			this.answers = answers;
		});
		this.store.pipe(select(getSubscriptionsReasons), takeUntil(this.unsubscriber$)).subscribe(reasons => {
			this.reasons = reasons;
		});
	}

	private showError(): void {
		this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
	}

	private createForm() {
		this.reasonOption = new FormControl('');
		this.textareaComment = new FormControl('', Validators.required);

		this.reason = this.formBuilder.group({
			option: this.reasonOption,
			textarea: this.textareaComment
		});
	}
}
