import { Injectable } from '@angular/core';
import { TableTemplate } from '../_models/TableModels/table-template';
import { tableJson } from '../../table/standard/table';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TableService {
	private availableTableTemplates: { [key: string]: TableTemplate } = {
		tableJson
	};

	private _current: TableTemplate;

	get current(): TableTemplate {
		return this._current;
	}

	constructor(public http: HttpClient) {
		this._current = this.availableTableTemplates.tableJson;
	}

	change(tableName: string): void {
		this._current = this.availableTableTemplates[tableName];
	}
}
