<div class="chart-wrapper" id="singlenumber">
	<div class="chart">
		<div class="inner">
			<div *ngIf="widget.details.metric" class="metric-text">
				<div class="value-text">
					<div class="group-text" *ngIf="valueToDisplay">
						<div *ngIf="checkCurrencySymbol()" class="currency-text">$</div>
						<div class="value">{{ getValue(valueToDisplay) }}</div>
						<div *ngIf="checkPercentageSymbol()" class="percentage-text">%</div>
					</div>
					<div *ngIf="!valueToDisplay">
						<div class="value">0</div>
					</div>
				</div>
				<div class="percentage">
					<div class="value">
						<div *ngIf="isPositiveValue" class="percentage-increase">{{ percentage }}%</div>
						<div *ngIf="!isPositiveValue" class="percentage-decrease">{{ percentage }}%</div>
						<div *ngIf="isPositiveValue" class="metric-icon increase">
							<div class="material-icons">
								arrow_upward
							</div>
						</div>
						<div *ngIf="!isPositiveValue" class="metric-icon decrease">
							<div class="material-icons">
								arrow_downward
							</div>
						</div>
					</div>
					<div class="previous">
						vs. previous period
					</div>
				</div>
				<div class="percentage" *ngIf="!valueToDisplay && !percentage">
					<div class="value">
						<div class="percentage-increase">0%</div>
						<div class="metric-icon increase">
							<div class="material-icons">
								arrow_upward
							</div>
						</div>
					</div>
					<div class="previous">
						vs. previous period
					</div>
				</div>
			</div>
			<div *ngIf="!widget.details.metric" class="metric-text">
				{{ 'SELECT_METRIC_SINGLE_NUMBER_CHART' | translate }}
			</div>
		</div>
	</div>
</div>
