<div class="recommendation-container" [ngStyle]="setBorder()" *ngIf="!isDismissed">
	<div *ngIf="isOnSmartEdit" class="dexter-wrapper">
		<div class="dexter-image-container">
			<img src="assets/icons/icon-dexter-x-dark.svg" />
		</div>
		<label>Dexter recommendations</label>
	</div>
	<div class="info-section">
		<div class="left-side titles">
			<h6>{{ newRecommendation.title }}</h6>
			<i-tooltip [title]="newRecommendation.subText">
				<label *ngIf="!isOnSmartEdit">{{ optimizeService.shortenSomeText(newRecommendation.subText, 60) }}</label>
				<label *ngIf="isOnSmartEdit">{{ optimizeService.shortenSomeText(newRecommendation.subText, 120) }}</label>
			</i-tooltip>
		</div>
		<div *ngIf="!isOnSmartEdit" class="top-info">
			<div class="top-info-text">Is this recommendation useful?</div>
			<div [ngClass]="{ liked: newRecommendation.feedbackValue === 1 }">
				<mat-icon
					(click)="onFeedbackClick(1)"
					[matTooltip]="newRecommendation.feedbackValue !== 1 ? 'Like This' : 'Liked'"
					[matTooltipPosition]="'above'"
				>
					thumb_up
				</mat-icon>
			</div>
			<div [ngClass]="{ disliked: newRecommendation.feedbackValue === -1 }">
				<mat-icon
					(click)="onFeedbackClick(-1)"
					[matTooltip]="newRecommendation.feedbackValue !== -1 ? 'Dislike This' : 'Disliked'"
					[matTooltipPosition]="'above'"
				>
					thumb_down
				</mat-icon>
			</div>
		</div>
	</div>
	<div class="info-section info-bottom">
		<div class="left-side">
			<div class="mid-info">
				<div *ngIf="!isExpanded" (click)="onToggleSeeMore()" class="expand">
					<label class="toggle-recommendation">Data Insights</label>
					<mat-icon>keyboard_arrow_down</mat-icon>
				</div>
				<div class="inside-detail">
					<img *ngIf="newRecommendation.level === 'ad'" src="../../../assets/icons/optimize/tv.svg" />
					<img *ngIf="newRecommendation.level === 'adset'" src="../../../assets/icons/optimize/visibility-adset.svg" />
					<img *ngIf="newRecommendation.level === 'campaign'" src="../../../assets/icons/optimize/megaphone.svg" />
					<label [matTooltip]="newRecommendation.structureName" [matTooltipPosition]="'above'">
						{{ optimizeService.shortenSomeText(newRecommendation.structureName, 15) }}
					</label>
				</div>
				<div class="inside-detail">
					<img src="../../../assets/icons/optimize/metric-icon.svg" />
					<label [matTooltip]="newRecommendation.metrics[0].displayName" [matTooltipPosition]="'above'">
						{{ optimizeService.shortenSomeText(newRecommendation.metrics[0].displayName | titlecase, 15) }}
					</label>
				</div>
			</div>
		</div>
		<div class="right-side">
			<div *ngIf="!isOnSmartEdit" class="button-apply">
				<btn04 [icon]="'plus'" (onClick)="onEditButtonClick()">Edit</btn04>
			</div>
			<div *ngIf="!isOnSmartEdit && newRecommendation.isApplicable" class="button-apply">
				<i-tooltip [title]="applyTooltip">
					<btn03 (onClick)="onApplyButtonClick()">Apply</btn03>
				</i-tooltip>
			</div>
		</div>
	</div>
	<div class="lower-info" [hidden]="!isExpanded" *ngIf="newRecommendation.title !== 'Change Your Breakdown '">
		<label>{{ newRecommendation.analysis }}</label>
		<div *ngIf="newRecommendation.recType !== 'missing_configuration' && newRecommendation.recType !== 'no_results'" class="chart-container">
			<lolly-spinning-loader *ngIf="!isLoaded"></lolly-spinning-loader>
			<div class="chart-option" *ngIf="isLoaded">
				<div class="button-range">
					<btn07 (onClick)="changeDataRange('1d')" [ngClass]="{ active: dataRange === '1d' }" [disabled]="!aboveOneDay">1d</btn07>
					<btn07 (onClick)="changeDataRange('3d')" [ngClass]="{ active: dataRange === '3d' }" [disabled]="!aboveThreeDays">3d</btn07>
					<btn07 (onClick)="changeDataRange('7d')" [ngClass]="{ active: dataRange === '7d' }" [disabled]="!aboveOneWeek">7d</btn07>
					<btn07 (onClick)="changeDataRange('2w')" [ngClass]="{ active: dataRange === '2w' }" [disabled]="!aboveTwoWeeks">2w</btn07>
					<btn07 (onClick)="changeDataRange('1m')" [ngClass]="{ active: dataRange === '1m' }" [disabled]="!aboveOneMonth">1m</btn07>
					<btn07 (onClick)="changeDataRange('2m')" [ngClass]="{ active: dataRange === '2m' }" [disabled]="!aboveTwoMonths">2m</btn07>
					<btn07 (onClick)="changeDataRange('max')" [ngClass]="{ active: showAll }">Max</btn07>
				</div>
				<div class="chart-type">
					<drp07
						[data]="chartTypes"
						[control]="activeChartTypeControl"
						(valueChanges)="changeChartType($event)"
						[placeholder]="'Select chart type'"
					></drp07>
				</div>
			</div>
			<app-generic-charts [chartColor]="chartColorArray" [chartData]="chartDataModel" [zoom]="true"></app-generic-charts>
		</div>
		<div class="no-chart-image" *ngIf="newRecommendation.recType === 'missing_configuration' || newRecommendation.recType === 'no_results'">
			<img src="assets/icons/Group 10866.svg" />
		</div>
		<div class="recommendation-wrapper">
			<div class="image-container">
				<img src="assets/icons/icon-dexter-x-dark.svg" />
			</div>
			<div class="speech-bubble-triangle-space"></div>
			<div class="recommendation-text">
				<label [innerHTML]="recommendationQuote"></label>
			</div>
		</div>
	</div>

	<div class="lower-info" [hidden]="!isExpanded" *ngIf="newRecommendation.title === 'Change Your Breakdown '">
		<label>{{ newRecommendation.analysis }}</label>
		<div class="chart-container">
			<lolly-spinning-loader *ngIf="!isLoaded"></lolly-spinning-loader>
			<app-generic-charts [chartColor]="chartColorArray" [chartData]="chartDataModel" [zoom]="true"></app-generic-charts>
		</div>
		<div class="recommendation-wrapper">
			<div class="image-container">
				<img src="assets/icons/icon-dexter-x-dark.svg" />
			</div>
			<div class="speech-bubble-triangle-space"></div>
			<div class="recommendation-text">
				<label [innerHTML]="recommendationQuote"></label>
			</div>
		</div>
	</div>

	<div *ngIf="isExpanded" (click)="onToggleSeeMore()" class="expand">
		<label class="toggle-recommendation">
			Less details
		</label>
		<mat-icon>keyboard_arrow_up</mat-icon>
	</div>

	<div *ngIf="feedbackApplying" class="feedback-apply-loader">
		<lolly-spinning-loader></lolly-spinning-loader>
	</div>
</div>
