import { Injectable } from '@angular/core';
import { DropdownInterface } from '@filed-com/filed-lib';

@Injectable()
export class FiledLibMapperService {
	constructor() {}

	public convertToDropdownData<T = any>(data: T[], valueKey: string, displayNameKey: string): DropdownInterface[] {
		return data.map(item => {
			return {
				name: (item as any)[displayNameKey],
				id: (item as any)[valueKey],
				data: item
			};
		});
	}
}
