import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PermissionsRoleInterface } from './table-permissions/permissions-role.interface';
import { getAllRoleWithPermissionsInterface } from '../../back-office/roles/models/get-all-role-with-permissions.interface';
import { GetAllRolesInterface } from '../../back-office/roles/models/get-all-roles.interface';
import { CreateNewRoleClass } from '../../back-office/role-managment/create-new-role.class';
import { UpdateNewRolesClass } from '../../back-office/role-managment/update-new-roles.class';
import { BaseApiUrl } from '../../_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class PermissionsApiOptionsService {
	private readonly baseRolesURL = `${BaseApiUrl.Users}roles`;
	private readonly urlGetEmployees = `${BaseApiUrl.Users}backOfficeUsers/backOfficeRoles`;

	constructor(private http: HttpClient) {}

	public getAllRoles(): Observable<PermissionsRoleInterface[]> {
		return this.http.get<PermissionsRoleInterface[]>(`${this.baseRolesURL}`);
	}

	public getAllRolesPermissions(): Observable<GetAllRolesInterface[]> {
		return this.http.get<GetAllRolesInterface[]>(`${this.baseRolesURL}`);
	}

	public getRolesEmployees(): Observable<PermissionsRoleInterface[]> {
		return this.http.get<PermissionsRoleInterface[]>(`${this.urlGetEmployees}`);
	}

	public getRoleById(id: number): Observable<getAllRoleWithPermissionsInterface> {
		return this.http.get<getAllRoleWithPermissionsInterface>(`${this.baseRolesURL}/${id}`);
	}

	public impersonation(id: number): Observable<string> {
		return this.http.get<string>(`${BaseApiUrl.Users}backOfficeUsers/accountManagers/impersonate/${id}`);
	}

	public createRole(role: CreateNewRoleClass): Observable<number> {
		return this.http.post<number>(`${this.baseRolesURL}`, role);
	}

	public updateRole(id: number, role: UpdateNewRolesClass): Observable<void> {
		return this.http.put<void>(`${this.baseRolesURL}/${id}`, role);
	}

	public deleteRole(id: string | number): Observable<void> {
		return this.http.delete<void>(`${this.baseRolesURL}/${id}`);
	}
}
