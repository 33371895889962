import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { DropdownData } from '../../../shared/dropdown-search-select/dropdown-data.interface';
import { CampaignTemplateListItem } from '../../models/campaign-template-list-item';
import { Subject } from 'rxjs';
import { SelectedAdAccountModel } from '../../../shared/state/state-entities/cached-ad-accounts.model';
import { MapperService } from '../../../shared/services/mapper.service';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getTemplateList } from '../../state/smart-create.reducer';
import { ClearCurrentAssets, ClearCurrentCampaign, ClearCurrentStep } from '../../state/smart-create.actions';

@Component({
	selector: 'app-campaign-smart-create-step-zero',
	templateUrl: './smart-create-campaign-select.component.html',
	styleUrls: ['./smart-create-campaign-select.component.scss']
})
export class SmartCreateCampaignSelect implements OnInit, OnDestroy {
	public templates: DropdownData<CampaignTemplateListItem>[];

	private selectedAccount: SelectedAdAccountModel;
	private unsubscriber$ = new Subject<void>();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private mapperService: MapperService,
		private smartCreateService: CampaignSmartCreateService,
		private store: Store<CampaignSmartCreateState>
	) {}

	public ngOnInit(): void {
		this.onActivatedRoute();
		this.getSavedAsDraftCampaign();
		this.onAccountChange();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onCancelClick(): void {
		this.router.navigate(['campaign/welcome']);
	}

	public onContinueClick(template: CampaignTemplateListItem): void {
		this.clearStore();
		this.router.navigate([`campaign/build-campaign/${template.lastStepId}/${template.id}`]);
	}

	public onCreateNewClick(): void {
		this.clearStore();
		this.router.navigate([`campaign/build-campaign/1`]);
	}

	private clearStore(): void {
		this.store.dispatch(new ClearCurrentStep());
		this.store.dispatch(new ClearCurrentCampaign());
	}

	private onActivatedRoute(): void {
		this.activatedRoute.data.pipe(take(1)).subscribe(data => {
			this.selectedAccount = data.store.account;
		});
	}

	private getSavedAsDraftCampaign(): void {
		// this.smartCreateService.getCampaignTemplatesList(this.selectedAccount.adAccount.id).subscribe(templates => {
		// 	this.templates = this.mapperService.convertToDropdownData(templates.reverse(), 'id', 'name').slice(0, 20);
		// });
		this.store.dispatch(new ClearCurrentAssets());
	}

	private onAccountChange(): void {
		this.store.pipe(select(getTemplateList), takeUntil(this.unsubscriber$)).subscribe(templates => {
			this.templates = templates;
		});
	}
}
