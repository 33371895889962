import ECharts = echarts.ECharts;

export class ChartInformation {
	instance: ECharts;

	dom: HTMLElement | HTMLDivElement;

	constructor(instance: ECharts, dom: HTMLElement | HTMLDivElement) {
		this.instance = instance;
		this.dom = dom;
	}
}
