import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { LoadFiledUser } from '../../../user-management/state/user-management.actions';
import { getFiledUser, UserManagementState } from '../../../user-management/state/user-management.reducer';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { SharedState } from '../../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';
import { User } from '../../../_models/user-models/user';
import { getFiledId, hasBackOfficePermission } from '../../../shared/state/user/user.reducer';

@Injectable()
export class AccountResolver implements Resolve<Observable<User>> {
	private filedId: number;
	constructor(
		private router: Router,
		private store: Store<UserManagementState>,
		private sharedStore: Store<SharedState>,
		private toastNotification: ToastNotificationService
	) {
		this.fetchStore();
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
		return this.store.pipe(
			select(getFiledUser),
			filter(user => user != null),
			take(1),
			tap(user => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				if (!user) {
					this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
					this.router.navigate(['/accounts']);
					return EMPTY;
				}
			}),
			catchError(() => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
				this.router.navigate(['/accounts']);
				return EMPTY;
			})
		);
	}

	private fetchStore(): void {
		this.store.pipe(select(getFiledId)).subscribe(id => {
			if (id) {
				this.filedId = id;
				this.sharedStore.dispatch(new ShowGlobalSpinner());
				this.store.pipe(select(hasBackOfficePermission), take(1)).subscribe(isAdmin => {
					this.store.dispatch(new LoadFiledUser({ id: this.filedId, setSelectedUser: false, isBackoffice: isAdmin }));
				});
			}
		});
	}
}
