import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChartSeriesModel } from './chart-models/chart-series.model';
import { ChartDataModel } from './chart-models/chart-data.model';
import { ChartLegendData } from './chart-models/chart-legend-data';
import { ChartGridData } from './chart-models/chart-grid-data';
import { ChartToolboxData } from './chart-models/chart-toolbox-data';
import { ChartTypeEnum } from '../../reports/shared/charts/chart-type.enum';
import EChartOption = echarts.EChartOption;
import ECharts = echarts.ECharts;

@Component({
	selector: 'app-generic-charts',
	templateUrl: './generic-charts.component.html',
	styleUrls: ['./generic-charts.component.scss']
})
export class GenericChartsComponent implements OnInit, OnChanges {
	@Input() public chartData: ChartDataModel;
	@Input() public chartColor: string[] = [];
	@Input() public zoom: boolean;
	@Input() public yData: boolean;

	public eChartOptions: EChartOption = {
		title: {
			text: ''
		},
		color: [],
		tooltip: {
			showContent: true,
			trigger: 'axis',
			axisPointer: {
				type: 'shadow' // on hover behaviour :'line' | 'shadow'
			},
			position: function (point: any, params: any, dom: any, rect: any, size: any) {
				if (!(params instanceof Array)) {
					params = [params];
				}
				const tooltipWidthPosition = point[0] < size.viewSize[0] / 2 ? point[0] : point[0] - size.contentSize[0];
				const nonNullValues = (params as any[]).filter(item => item.data);
				const numberOfElements = nonNullValues.length;
				const tooltipHeight = numberOfElements * 10;
				if (numberOfElements > 5) {
					return [tooltipWidthPosition, `${-tooltipHeight / 2}`];
				}
				return [tooltipWidthPosition, '5%'];
			},
			formatter: (value: any) => {
				const nonNullValues = (value as any[]).filter(item => item.value);
				if (nonNullValues.length !== 0) {
					const valueNames = nonNullValues.map(item => {
						return `<span class="tooltip-item-container">${item.marker} ${item.seriesName} - ${item.value}</span><br>`;
					});
					return '<br>' + valueNames.join('');
				}
				return '';
			}
		},
		xAxis: [
			{
				type: 'category',
				data: [],
				axisTick: {
					alignWithLabel: true
				}
			}
		],
		yAxis: [
			{
				type: 'value'
			}
		],
		dataZoom: [
			{
				type: 'select'
			}
		]
	};
	public _hasData = false;
	private chartInstance: ECharts;

	constructor() {}

	public ngOnInit(): void {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.chartData) {
			this.setData(this.chartData);
		}
	}

	public onChartInit(instance: ECharts): void {
		this.chartInstance = instance;
	}

	private setData(chartData: ChartDataModel): void {
		this.setChartTitle(chartData.title, chartData.subTitle);
		this.setLegendData(chartData.legendData);
		this.setCategoricalData(chartData);
		this.setSeriesData(chartData.continuousData, chartData.chartType);
		this.setGridData(chartData.gridData);
		this.setToolboxData(chartData.toolboxData);
		this.setColorData(this.chartColor);
		this.updateChart();
	}

	private setChartTitle(titleText: string = '', subText: string = ''): void {
		this.eChartOptions.title = {
			text: titleText,
			subtext: subText
		};
	}

	private setLegendData(legendData: ChartLegendData): void {
		this.eChartOptions.legend = legendData;
	}

	private setColorData(chartColor: string[]): void {
		this.eChartOptions.color = chartColor;
	}

	private setGridData(gridData: ChartGridData): void {
		this.eChartOptions.grid = gridData;
	}

	private setCategoricalData(chartData: ChartDataModel): void {
		if (chartData.isHorizontal) {
			this.eChartOptions.xAxis = {
				type: 'category',
				data: chartData.categoricalData,
				boundaryGap: true,
				axisLabel: {
					interval: this.setAxisInterval(chartData.categoricalData.length),
					rotate: this.setAxisInterval(chartData.categoricalData.length) > 1 ? 80 : 0
				}
			};
			if (this.yData) {
				this.eChartOptions.yAxis = {
					type: 'value'
				};
			} else {
				this.eChartOptions.yAxis = {
					type: 'value',
					max: function (value) {
						const aMarkLine = chartData.continuousData[0].markLine;
						return aMarkLine && aMarkLine.data[0].yAxis > value.max ? aMarkLine.data[0].yAxis / 8 + aMarkLine.data[0].yAxis : value.max;
					}
				};
			}
		} else {
			this.eChartOptions.yAxis = {
				data: chartData.categoricalData,
				type: 'category'
			};
			this.eChartOptions.xAxis = { type: 'value' };
		}
	}

	private setAxisInterval(dataLength: number): number {
		if (dataLength >= 25) {
			return Math.round(dataLength / 10);
		} else if (dataLength >= 14 && dataLength < 25) {
			return Math.round(dataLength / 7);
		}
	}

	private setSeriesData(chartSeries: ChartSeriesModel[], chartType: ChartTypeEnum): void {
		this.eChartOptions.series = [];
		let i = 0;
		chartSeries.forEach(x => {
			if (x.data.length !== 0) {
				this.eChartOptions.series.push({
					name: x.name,
					data: this.colorCodeBar(chartSeries, i),
					stack: x.stack,
					connectNulls: x.connectNulls,
					markPoint: x.markPoint,
					smooth: x.smooth,
					markLine: x.markLine
				} as any);
			}
			i++;
		});
		this.setChartType(chartType);
	}

	private setToolboxData(toolboxData: ChartToolboxData): void {
		this.eChartOptions.toolbox = toolboxData;
	}

	private setChartType(chartType: ChartTypeEnum): void {
		this.eChartOptions.series.forEach((x: any) => {
			x.type = chartType === ChartTypeEnum.Area ? ChartTypeEnum.Line : chartType;
			x.areaStyle = chartType === ChartTypeEnum.Area ? {} : null;
		});
	}

	private updateChart(): void {
		if (!this.eChartOptions.series || this.eChartOptions.series.length === 0) {
			this._hasData = false;
			return;
		}

		this._hasData = true;
		if (this.chartInstance) {
			this.chartInstance.clear();
			this.chartInstance.setOption(this.eChartOptions);
			// this.resizeChart();
		}
	}

	private resizeChart(): void {
		this.chartInstance.resize({
			width: 'auto',
			height: 'auto'
		});
	}

	private showZoom(): void {
		this.eChartOptions.dataZoom = [
			{
				type: 'slider',
				show: this.zoom
			}
		];
		this.eChartOptions.grid = {
			bottom: 10
		};
	}

	private colorCodeBar(chartData: ChartSeriesModel[], num: number): number[] | any[] {
		const dataWithColor: any[] = [];
		if (!chartData[num].colorCode || chartData[num].colorCode.length < 0) {
			return chartData[num].data;
		} else {
			for (let i = 0; i < chartData[num].data.length; i++) {
				const value: any = {};
				if (chartData[num].colorCode[i] === 1) {
					value.value = chartData[num].data[i];
					value.itemStyle = { color: '#5f8ad0' };
				} else {
					value.value = chartData[num].data[i];
					value.itemStyle = { color: '#FFC12F' };
				}
				dataWithColor.push(value);
			}
			return dataWithColor;
		}
	}
}
