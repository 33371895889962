import { CatalogInterface } from '../../campaign-smart-create/catalogs/catalog.interface';
import { Asset } from '../../shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';
import { Action } from '@ngrx/store';
import { DropdownInterface } from '@filed-com/filed-lib';
import { AdvertiseAppInterface } from '../../campaign-smart-create/models/advertise-app.interface';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
export enum CampaignCommonActionsType {
	LoadCatalogs = '[Campaign Common State] Load Catalogs',
	LoadCatalogsSuccess = '[Campaign Common State] Load Catalogs Success',
	LoadCatalogsFailure = '[Campaign Common State] Load Catalogs Failure',

	LoadUserImages = '[Campaign Common State] Load User Images',
	LoadUserImagesSuccess = '[Campaign Common State] Load User Images Success',
	LoadUserImagesFailure = '[Campaign Common State] Load User Images Failure',

	LoadUserVideos = '[Campaign Common State] Load User Videos',
	LoadUserVideosSuccess = '[Campaign Common State] Load User Videos Success',
	LoadUserVideosFailure = '[Campaign Common State] Load User Videos Failure',

	LoadExistingImages = '[Campaign Common State] Load Existing Images',
	LoadExistingImagesSuccess = '[Campaign Common State] Load Existing Images Success',
	LoadExistingImagesFailure = '[Campaign Common State] Load Existing Images Failure',

	LoadExistingVideos = '[Campaign Common State] Load Existing Videos',
	LoadExistingVideosSuccess = '[Campaign Common State] Load Existing Videos Success',
	LoadExistingVideosFailure = '[Campaign Common State] Load Existing Videos Failure',

	LoadCreativeBuilderSingleImage = '[Campaign common state] Load Creative Builder Single Image',
	LoadCreativeBuilderSingleImageSuccess = '[Campaign common state] Load Creative Builder Single Image Success',
	LoadCreativeBuilderSingleImageFailure = '[Campaign common state] Load Creative Builder Single Image Failed',

	LoadFacebookPages = '[Campaign common state] Load facebook pages',
	LoadFacebookPageSuccess = '[Campaign common state] Load facebook pages success',
	LoadFacebookPageFailed = '[Campaign common state] Load facebook pages failed',
	ClearFacebookPage = '[Campaign common state] Clear facebook pages',

	LoadFacebookPixels = '[Campaign common state] Load facebook pixels',
	LoadFacebookPixelsSuccess = '[Campaign common state] Load facebook pixels success',
	LoadFacebookPixelsFailed = '[Campaign common state] Load facebook pixels failed',
	ClearFacebookPixels = '[Campaign common state] Clear facebook pixels',

	LoadAdvertisableApp = '[campaign common state] load advertisable app',
	LoadAdvertisableAppSuccess = '[campaign common state] load advertisable app success',

	LoadProductCatalog = '[campaign common state] load product catalog',
	LoadProductCatalogSuccess = '[campaign common state] load product catalog success'
}
export class LoadCampaignCatalogs implements Action {
	public readonly type = CampaignCommonActionsType.LoadCatalogs;
}

export class LoadCampaignCatalogsSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadCatalogsSuccess;

	constructor(public payload: CatalogInterface) {}
}

export class LoadCampaignCatalogsFailure implements Action {
	public readonly type = CampaignCommonActionsType.LoadCatalogsFailure;
}

export class LoadUserImages implements Action {
	public readonly type = CampaignCommonActionsType.LoadUserImages;

	constructor(public payload?: boolean) {}
}

export class LoadUserImagesSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadUserImagesSuccess;

	constructor(public payload: Asset[]) {}
}

export class LoadUserImagesFailure implements Action {
	public readonly type = CampaignCommonActionsType.LoadUserImagesFailure;
}

export class LoadUserVideos implements Action {
	public readonly type = CampaignCommonActionsType.LoadUserVideos;

	constructor(public payload?: boolean) {}
}

export class LoadUserVideosSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadUserVideosSuccess;

	constructor(public payload: Asset[]) {}
}

export class LoadUserVideosFailure implements Action {
	public readonly type = CampaignCommonActionsType.LoadUserVideosFailure;
}

export class LoadExistingImages implements Action {
	public readonly type = CampaignCommonActionsType.LoadExistingImages;

	constructor(public payload: { businessOwnerId: string; adAccountId: string }) {}
}

export class LoadExistingImagesSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadExistingImagesSuccess;

	constructor(public payload: Asset[]) {}
}

export class LoadExistingImagesFailure implements Action {
	public readonly type = CampaignCommonActionsType.LoadExistingImagesFailure;
}

export class LoadExistingVideos implements Action {
	public readonly type = CampaignCommonActionsType.LoadExistingVideos;
	constructor(public payload: { businessOwnerId: string; adAccountId: string; filterInstagram?: boolean }) {}
}

export class LoadExistingVideosSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadExistingVideosSuccess;

	constructor(public payload: Asset[]) {}
}

export class LoadExistingVideosFailure implements Action {
	public readonly type = CampaignCommonActionsType.LoadExistingVideosFailure;
}
export class LoadCreativeBuilderSingleImage implements Action {
	public readonly type = CampaignCommonActionsType.LoadCreativeBuilderSingleImage;
}
export class LoadCreativeBuilderSingleImageSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadCreativeBuilderSingleImageSuccess;
	constructor(public payload: any) {}
}
export class LoadCreativeBuilderSingleImageFailure implements Action {
	public readonly type = CampaignCommonActionsType.LoadCreativeBuilderSingleImageFailure;
}
export class LoadFacebookPages implements Action {
	public readonly type = CampaignCommonActionsType.LoadFacebookPages;
}
export class LoadFacebookPageSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadFacebookPageSuccess;
	constructor(public payload: DropdownInterface[]) {}
}
export class ClearFacebookPage implements Action {
	public readonly type = CampaignCommonActionsType.ClearFacebookPage;
}

export class LoadFacebookPixels implements Action {
	public readonly type = CampaignCommonActionsType.LoadFacebookPixels;
}
export class LoadFacebookPixelsSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadFacebookPixelsSuccess;
	constructor(public payload: DropdownData[]) {}
}
export class ClearFacebookPixels implements Action {
	public readonly type = CampaignCommonActionsType.ClearFacebookPixels;
}

export class LoadAdvertisableApp implements Action {
	public readonly type = CampaignCommonActionsType.LoadAdvertisableApp;
}
export class LoadAdvertisableAppSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadAdvertisableAppSuccess;
	constructor(public payload: DropdownInterface[] | null) {}
}
export class LoadProductCatalog implements Action {
	public readonly type = CampaignCommonActionsType.LoadProductCatalog;
}
export class LoadProductCatalogSuccess implements Action {
	public readonly type = CampaignCommonActionsType.LoadProductCatalogSuccess;
	constructor(public payload: unknown) {}
}
export type CampaignCommonAction =
	| LoadCampaignCatalogs
	| LoadCampaignCatalogsSuccess
	| LoadCampaignCatalogsFailure
	| LoadUserImages
	| LoadUserImagesSuccess
	| LoadUserImagesFailure
	| LoadUserVideos
	| LoadUserVideosSuccess
	| LoadUserVideosFailure
	| LoadExistingImages
	| LoadExistingImagesSuccess
	| LoadExistingImagesFailure
	| LoadExistingVideos
	| LoadExistingVideosSuccess
	| LoadExistingVideosFailure
	| LoadCreativeBuilderSingleImage
	| LoadCreativeBuilderSingleImageSuccess
	| LoadCreativeBuilderSingleImageFailure
	| LoadFacebookPages
	| LoadFacebookPageSuccess
	| ClearFacebookPage
	| LoadFacebookPixels
	| LoadFacebookPixelsSuccess
	| ClearFacebookPixels
	| LoadAdvertisableApp
	| LoadProductCatalog
	| LoadProductCatalogSuccess
	| LoadAdvertisableAppSuccess;
