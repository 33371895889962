import { EventEmitter, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { constants } from 'buffer';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, mergeMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { isUserFacebookOwner, isUserGoogleOwner, UserState } from 'src/app/shared/state/user/user.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { EcommerceSource, IntegrationInterface, IntegrationTypeEnum } from '../models/integration.models';

@Injectable({
	providedIn: 'root'
})
export class IntegrationHelper {
	private unsubscriber$ = new Subject<void>();
	public subsVar: Subscription;
	public invokeFirstComponentFunction = new EventEmitter<{ status: boolean; name: string }>();
	constructor(private backofficeService: BackOfficeService, private userStore: Store<UserState>) {}
	connectionStatus(parameter: { status: boolean; name: string }) {
		this.invokeFirstComponentFunction.emit(parameter);
	}
	public static integrationAssets = [
		{
			source: 'facebook-normal',
			title: 'Facebook',
			icon: 'assets/icons/integrations/icon-facebook.svg',
			integrationType: IntegrationTypeEnum.Marketing
		},
		{
			source: 'facebook',
			title: 'Facebook Export',
			icon: 'assets/icons/integrations/icon-facebook.svg',
			integrationType: IntegrationTypeEnum.FeedManagement
		},
		{
			source: 'google-normal',
			title: 'Google',
			icon: 'assets/icons/integrations/icon-google.svg',
			integrationType: IntegrationTypeEnum.Marketing
		},
		{
			source: 'google',
			title: 'Google Export',
			icon: 'assets/icons/integrations/icon-google.svg',
			integrationType: IntegrationTypeEnum.FeedManagement
		},
		{
			source: 'snapchat',
			title: 'Snapchat',
			icon: 'assets/icons/integrations/icon-snapchat.svg',
			integrationType: IntegrationTypeEnum.FeedManagement
		},
		{
			source: 'amazon',
			title: 'Amazon',
			icon: 'assets/icons/integrations/icon-amazon.svg',
			integrationType: IntegrationTypeEnum.FeedManagement
		},
		{
			source: 'bigcommerce',
			title: 'Big Commerce',
			icon: 'assets/icons/integrations/icon-bigcommerce.svg',
			integrationType: IntegrationTypeEnum.FeedManagement
		},
		{
			source: 'shopify',
			title: 'Shopify',
			icon: 'assets/icons/integrations/icon-shopify.svg',
			integrationType: IntegrationTypeEnum.FeedManagement
		},
		{
			source: 'ebay',
			title: 'Ebay',
			icon: 'assets/icons/integrations/icon-ebay.svg',
			integrationType: IntegrationTypeEnum.FeedManagement
		}
	];

	public static getIntegrationsModels(): IntegrationInterface[] {
		const allIntegrations: IntegrationInterface[] = [];
		for (const key in EcommerceSource) {
			this.integrationAssets.forEach(asset => {
				if (asset.source === EcommerceSource[key]) {
					const obj = {
						title: asset.title,
						status: false,
						icon: asset.icon,
						source: asset.source,
						integrationType: asset?.integrationType
					};
					allIntegrations.push(obj);
				}
			});
		}
		return allIntegrations;
	}

	public getAllIntegrationStatuses(): Observable<IntegrationInterface[]> {
		return this.backofficeService.getAllInstalledPlatforms().pipe(
			takeUntil(this.unsubscriber$),
			mergeMap(installed => {
				const allIntegrations = IntegrationHelper.getIntegrationsModels();
				allIntegrations.forEach(item => {
					const findItem = installed.installed_channels.find(value => {
						const valueObj = Object.keys(value)[0];
						return valueObj === item.source;
					});
					if (findItem) {
						item.status = true;
					}
				});
				return of(allIntegrations);
			}),
			withLatestFrom(this.userStore.select(isUserGoogleOwner)),
			mergeMap(([integrations, isGoogleOwner]) => {
				return this.userStore.pipe(
					select(isUserFacebookOwner),
					take(1),
					mergeMap(isFacebookBusinessOwner => {
						if (isFacebookBusinessOwner) {
							integrations.forEach(value => {
								if (value.source === 'facebook-normal') {
									value.status = true;
								}
							});
						}
						if (isGoogleOwner) {
							integrations.forEach(value => {
								if (value.source === 'google-normal') {
									value.status = true;
								}
							});
						}
						return of(integrations);
					})
				);
			})
		);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
