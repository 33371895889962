import { InsightsReportModel } from '../campaign-insights/models/insights-report.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class InsightsMetadataBaseService {
	protected modelInstances: InsightsReportModel[];

	protected readonly REQUEST_TIMEOUT_MS = 30000;

	constructor(protected http: HttpClient, protected tableName: string) {}

	public abstract getInsightsMetadataModel(useCache: boolean): Observable<InsightsReportModel[]>;
}
