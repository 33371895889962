import { createSelector } from '@ngrx/store';
import { AAAModuleState, selectAAAModuleState } from '..';
import { AdState } from './ad-state.reducer';

const getAdState = createSelector(selectAAAModuleState, (state: AAAModuleState) => state.AdState);
export const getCurrentAdIndex = createSelector(getAdState, (state: AdState) => state.currentSelectedAdId);
export const getCurrentAdDetails = createSelector(getAdState, ({ ads, currentSelectedAdId }: AdState) => ads[currentSelectedAdId]);
export const getAds = createSelector(getAdState, (state: AdState) => state.ads);
export const getAdName = createSelector(getCurrentAdDetails, details => details?.adName);
export const getAdFormat = createSelector(getCurrentAdDetails, details => details?.adFormatType);
export const getAdPreview = createSelector(getCurrentAdDetails, details => details?.adverts);
export const getPixelToggle = createSelector(getCurrentAdDetails, details => details?.toggle);
export const getPixelId = createSelector(getCurrentAdDetails, details => details?.pixelId);
export const getPixelAppEventId = createSelector(getCurrentAdDetails, details => details?.pixelAppEventId);
export const getFacebookId = createSelector(getCurrentAdDetails, details => details?.facebookPageId);
export const getPageIds = createSelector(getAdState, (state: AdState) => state.pageIds);
