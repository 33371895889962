import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EchartsService } from '../../_services/echarts.service';
import { ChartWrapper } from './builders/chart-wrapper';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { Subscription } from 'rx-subject';
import { DateRange } from '../calendar/calendar.models';
import { BarChart } from './builders/bar-chart.component';
import { LineChart } from './builders/line-chart.component';
import { MainDataNameToDisplayNameMapping } from '../../_models/Chart_models/MainNameToDisplayNameMapping';
import { DeepComparer } from '../deep-comparer/deep-comparer';
import { BaseApiUrl } from '../../_services/base-api-urls';
import moment from 'moment';
import { PixelService } from '../../_services/pixel/pixel.service';
import { PixelInsightsCommand } from '../../pixel/_models/pixel-query.model';
import { ToastNotificationService } from '../toast-notification/toast-notification.service';
import { QueryBuilderConditionBlock } from '../query-builder/models/query-builder-condition-block.model';
import { QueryBuilderDimension } from '../query-builder/models/query-builder-dimension.model';
import { QueryBuilderColumn } from '../query-builder/models/query-builder-column.model';
import { QueryBuilderAggregatorEnum } from '../query-builder/models/query-builder.aggregator.enum';
import { ChartTypeEnum } from '../../reports/shared/charts/chart-type.enum';
import { takeUntil } from 'rxjs/operators';

export interface ChartActor {
	name: string;
	id: string;
	breakdown?: BreakdownIdentity;
}

export interface BreakdownIdentity {
	type: number;
	value?: any;
}

export interface InsightsQueryParams {
	dimensions: QueryBuilderDimension[];
	tableName: string;
	whereConditionBlock: QueryBuilderConditionBlock;
}

@Component({
	selector: 'app-charts',
	templateUrl: './charts.component.html',
	styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit, OnDestroy {
	public showSpinner = false;
	private readonly debounceDelay: number = 650;
	private timeout: any;
	private isDataLoaded = false;
	private _selectedActors: ChartActor[] = [];
	private pixelData: Map<string, { Values: any[]; Metrics: QueryBuilderColumn[] }>;

	set selectedActors(value: ChartActor[]) {
		this._selectedActors = value;
		if (!this.pixelFunction) {
			this.multipleMetricsAllowed = this.selectedActors.length === 1;
		}
		if (this.chartName) {
			localStorage.setItem(this.chartName, JSON.stringify(value));
		}
	}

	get selectedActors() {
		return this._selectedActors;
	}

	private _actorSelection: ChartActor[] = [];
	@Input()
	set actorSelection(value: ChartActor[]) {
		// this is a prototypal external update mechanism
		// it's use is tailored for optimize recommendations, where eligible actors are a constant.
		// Using it in more complex scenarios is unlikely to work
		// Using this off-the-bat for default values might also break the chart
		// this also does not work with pixels
		if (!value) {
			return;
		}
		this._actorSelection = value;
		this.allActorsCtrl.setValue(value);
	}

	get actorSelection() {
		return this._actorSelection;
	}

	@Input()
	displayNamesMapping: MainDataNameToDisplayNameMapping[];

	private _actors: ChartActor[];
	get actors() {
		return this._actors;
	}

	@Input()
	set actors(value: ChartActor[]) {
		this._actors = value;
		if (this.isInitialized) {
			const newActors = this.actors;
			const intersectionOfSelectedActorsAndNewActors = this.getIntersection(this.selectedActors, newActors);
			this.filteredActors.next(newActors);
			this.allActorsCtrl.setValue(intersectionOfSelectedActorsAndNewActors);
		}
	}

	// used for actor selection preservation accross screens
	private _chartName: string;
	@Input()
	set chartName(value: string) {
		this._chartName = value;
		if (this.isInitialized) {
			this.setDefaultActors();
		}
	}

	get chartName() {
		return this._chartName;
	}

	@Input()
	default: number[] = [0];

	@Input()
	optimizeLevel = 'Campaign';

	@Input()
	chartWrapper: ChartWrapper;

	@Input()
	metrics: QueryBuilderColumn[] = [
		{
			Name: 'Impressions',
			Aggregator: QueryBuilderAggregatorEnum.Sum
		},
		{
			Name: 'Clicks',
			Aggregator: QueryBuilderAggregatorEnum.Sum
		},
		{
			Name: 'Cpc',
			Aggregator: QueryBuilderAggregatorEnum.Avg
		},
		{
			Name: 'Cpm',
			Aggregator: QueryBuilderAggregatorEnum.Avg
		},
		{
			Name: 'Ctr',
			Aggregator: QueryBuilderAggregatorEnum.Avg
		},
		{
			Name: 'Cpp',
			Aggregator: QueryBuilderAggregatorEnum.Avg
		}
	];

	@Input()
	getQueryParams: (chartWrapper: ChartWrapper, selectedObjects: ChartActor[]) => InsightsQueryParams;

	@Input()
	pixelFunction: (chartWrapper: ChartWrapper, facebookObjects: any[]) => PixelInsightsCommand;

	@Input()
	endpoint: string;

	private _selectedDataRange: DateRange;

	get selectedDateRange() {
		return this._selectedDataRange;
	}

	@Input() set selectedDateRange(value: DateRange) {
		this._selectedDataRange = value;
		this.chartWrapper.selectedDateRange = value;
		if (this.isInitialized) {
			this.fetchData();
		}
	}

	// selectedObjectsData stores the result of the last query so we don't have to make a new query when only the metrics or chart type changes
	private selectedObjectsData: any[] = [];
	private isInitialized = false;

	filteredActors: ReplaySubject<ChartActor[]> = new ReplaySubject<ChartActor[]>();
	allActorsCtrl: FormControl = new FormControl();
	actorsFilterCtrl: FormControl = new FormControl();
	chartTypeCtrl: FormControl = new FormControl();
	chartTypes: string[] = [ChartTypeEnum.Bar, ChartTypeEnum.Line, ChartTypeEnum.Area];

	barChart = 'barchart'; // idk what these are or what they do
	lineChart = 'linechart'; // i assume they can be removed once the chart can store data for both line and bar charts

	selectedObjectsMainDataFieldNames: { [id: string]: string } = {
		// These are the main data field names for drawing the Bar Chart and line Chart respectively
		// They will be updated with each query so that the charts will redraw correctly when the metrics change
		barChart: '',
		lineChart: ''
	};

	multipleMetricsAllowed: boolean;
	private unsubscriber$: Subject<void> = new Subject<void>();

	// TODO consider cleaning up optimization service import. Only used for optimization. Requires charts component further refactoring or query builder upgrade
	constructor(private toastNotificationService: ToastNotificationService, private echartService: EchartsService) {}

	ngOnInit() {
		this.setDefaultMetrics();
		this.setDefaultActors();

		this.chartTypeCtrl.setValue(this.chartWrapper.chartType);
		this.chartTypeCtrl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			this.chartWrapper.chartType = data;
			this.fetchData();
		});

		this.filteredActors.next(this.actors === null ? [] : this.actors.slice());
		this.actorsFilterCtrl.valueChanges
			.pipe()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.filterActors();
			});

		this.allActorsCtrl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			if (this.pixelFunction) {
				data = [data];
			}
			if (data.length === 0) {
				this.chartWrapper.show = false;
			}
			if (
				!(
					this.getSelectedMetrics() != null &&
					!(data.length > 1 && this.selectedActors.length === 1) &&
					(data.length === 1 && this.selectedActors.length > 1) === false
				)
			) {
				if (this.getSelectedMetrics() == null) {
					this.setSelectedMetrics(null);
				} else {
					if (this.getSelectedMetrics().length !== 1) {
						this.setSelectedMetrics(this.getSelectedMetrics());
					}
				}
			}

			this.selectedActors = data;
			this.fetchWrapper();
		});
		this.isInitialized = true;

		this.fetchData();
	}

	ngOnDestroy() {
		if (this.chartName) {
			localStorage.setItem(this.chartName, JSON.stringify(this.selectedActors));
		}
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	setDefaultMetrics() {
		this.chartWrapper.chartMultipleMetricCtrl.setValue([this.metrics[0]]);
		this.chartWrapper.chartSingleMetricCtrl.setValue(this.metrics[0]);
		if (this.pixelFunction) {
			this.multipleMetricsAllowed = false;
		}
	}

	setDefaultActors() {
		if (!this.actors || !this.actors.length) {
			return;
		}
		if (this.chartName) {
			const storedSelection = JSON.parse(localStorage.getItem(this.chartName)) as ChartActor[];
			if (storedSelection && storedSelection.length > 0) {
				const eligibleActorsFromStorage = this.getIntersection(storedSelection, this.actors);
				if (eligibleActorsFromStorage && eligibleActorsFromStorage.length > 0) {
					if (this.pixelFunction) {
						this.allActorsCtrl.setValue(eligibleActorsFromStorage[0]);
						this.selectedActors = [eligibleActorsFromStorage[0]];
						return;
					} else {
						this.allActorsCtrl.setValue(eligibleActorsFromStorage);
						this.selectedActors = eligibleActorsFromStorage;
						return;
					}
				}
			}
		}
		if (this.default) {
			const defaultActors: ChartActor[] = [];
			for (let i = 0; i < this.default.length; i++) {
				defaultActors.push(this.actors[this.default[i]]);
			}
			if (this.pixelFunction) {
				this.allActorsCtrl.setValue(defaultActors[0]);
				this.selectedActors = [defaultActors[0]];
				return;
			} else {
				this.allActorsCtrl.setValue(defaultActors);
				this.selectedActors = defaultActors;
				return;
			}
		}

		if (this.pixelFunction) {
			this.allActorsCtrl = new FormControl(this.actors[0]);
		} else {
			this.allActorsCtrl = new FormControl([this.actors[0]]);
			this.selectedActors = [this.actors[0]];
		}
	}

	setSelectedMetrics(newValue: any) {
		if (this.multipleMetricsAllowed) {
			this.chartWrapper.chartMultipleMetricCtrl.setValue(newValue);
		} else {
			this.chartWrapper.chartSingleMetricCtrl.setValue(newValue);
		}
	}

	getSelectedMetrics() {
		if (this.multipleMetricsAllowed) {
			return this.chartWrapper.chartMultipleMetricCtrl.value;
		} else {
			return this.chartWrapper.chartSingleMetricCtrl.value;
		}
	}

	async fetchWrapper() {
		// potential upgrade: Trigger timeout function anyway after a certain number of selections for a "buffer" data set to increase responsiveness
		// Optional "fake" hook. Spinner is still triggered when the request is actually sent.
		this.showSpinner = true;
		if (this.timeout) {
			clearTimeout(this.timeout);
		}

		this.isDataLoaded = false;
		this.timeout = setTimeout(() => {
			this.fetchData();
			this.showSpinner = false;
		}, this.debounceDelay);
	}

	async fetchData() {
		const metricValues = this.getSelectedMetrics();
		this.syncSingleAndMultipleMetrics();
		if (metricValues == null) {
			this.chartWrapper.show = false;
			return;
		} else {
			if (metricValues.length === 0 || this.selectedActors.length === 0) {
				this.chartWrapper.show = false;
				return;
			}
		}

		try {
			this.showSpinner = true;
			const queryParams = this.getQueryParams(this.chartWrapper, this.selectedActors);
			this.chartWrapper = await this.getInsightsData(this.chartWrapper, queryParams);
			this.drawCharts();
			if (this.chartWrapper.isOptimize) {
				// Technical Debt here
				// this.chartWrapper.chartOption.xAxis.data = await this.getOptimizeLabels(this.chartWrapper, this.selectedActors);
			}
		} catch {
			if (!(this.getSelectedMetrics() == null)) {
				if (!Array.isArray(this.getSelectedMetrics())) {
					if (!(this.getSelectedMetrics().length === 0)) {
						if (!(this.selectedActors.length === 0)) {
							this.snackBarOpen();
						}
					}
				}
			}
		} finally {
			this.showSpinner = false;
		}
	}

	syncSingleAndMultipleMetrics() {
		const multipleValues = this.chartWrapper.chartMultipleMetricCtrl.value;
		const singleValue = this.chartWrapper.chartSingleMetricCtrl.value;
		if (this.multipleMetricsAllowed) {
			const union = this.getMultipleMetricsFromSingle(multipleValues, singleValue);
			this.chartWrapper.chartMultipleMetricCtrl.setValue(union);
		}
	}

	getMultipleMetricsFromSingle(set: any[], element: any) {
		for (let i = 0; i < set.length; ++i) {
			if (DeepComparer.deepEquals(set[i], element)) {
				return set;
			}
		}
		const setCopy = set.slice();
		setCopy.push(element);
		return setCopy;
	}

	getIntersection(oldActors: any[], newActors: any[]) {
		const intersection: any[] = [];
		oldActors.forEach(actor => {
			for (let i = 0; i < newActors.length; ++i) {
				if (DeepComparer.deepEquals(newActors[i], actor, true)) {
					intersection.push(newActors[i]);
				}
			}
		});
		return intersection;
	}

	getSingleMetricFromMultiple(set: any[], element: any) {
		for (let i = 0; i < set.length; ++i) {
			if (DeepComparer.deepEquals(set[i], element)) {
				return element;
			}
		}
		return set[0];
	}

	drawCharts() {
		const metricValue = this.getSelectedMetrics();
		if (metricValue == null) {
			this.chartWrapper.show = false;
			return;
		}

		if (Array.isArray(metricValue)) {
			if (metricValue.length === 0) {
				this.chartWrapper.show = false;
				return;
			}
		}

		if (this.selectedActors.length === 0) {
			this.chartWrapper.show = false;
			return;
		}

		if (
			(!this.selectedObjectsData || this.selectedObjectsData.length === 0) &&
			(!this.pixelData.has(metricValue.accessName) || this.pixelData.get(metricValue.accessName).Values.length === 0)
		) {
			this.snackBarOpen();
			return;
		}

		if (this.chartWrapper.chartType === ChartTypeEnum.Bar) {
			if (this.multipleMetricsAllowed) {
				const axises = ChartWrapper.reformatMoreMetricsForOneFacebookObject(this.selectedObjectsData, metricValue);
				this.chartWrapper.chartOption = new BarChart(' ', axises.xAxisData, axises.yAxisData).option;
				this.chartWrapper.show = true;
				return;
			}

			if (!this.multipleMetricsAllowed) {
				let axises;
				if (this.pixelFunction) {
					const actors = ChartWrapper.reformatMoreMetricsForLineChart(
						this.pixelData.get(metricValue.accessName).Values,
						this.pixelData.get(metricValue.accessName).Metrics,
						this.chartWrapper.selectedDateRange
					);
					const lineChart = new LineChart('', actors.labels, false);
					for (const line of actors.lines) {
						lineChart.addLine(line.name, line.metrics);
					}
					lineChart.option.series.forEach((entry: any) => {
						entry.type = 'bar';
					});
					this.chartWrapper.chartOption = lineChart.option;
					this.chartWrapper.show = true;
					return;
				} else {
					axises = ChartWrapper.reformatMoreFacebookObjectsForBarChart(
						this.selectedObjectsData,
						metricValue,
						this.selectedObjectsMainDataFieldNames[this.barChart],
						this.displayNamesMapping
					);
				}

				this.chartWrapper.chartOption = new BarChart(' ', axises.xAxisData, axises.yAxisData).option;
				this.chartWrapper.show = true;
				return;
			}
		}

		if (this.chartWrapper.chartType === ChartTypeEnum.Line || this.chartWrapper.chartType === ChartTypeEnum.Area) {
			if (!this.multipleMetricsAllowed) {
				let actors;
				if (this.pixelFunction) {
					actors = ChartWrapper.reformatMoreMetricsForLineChart(
						this.pixelData.get(metricValue.accessName).Values,
						this.pixelData.get(metricValue.accessName).Metrics,
						this.chartWrapper.selectedDateRange
					);
				} else {
					actors = ChartWrapper.reformatMoreFacebookObjectsForLineChart(
						this.selectedObjectsData,
						metricValue,
						this.selectedObjectsMainDataFieldNames[this.lineChart],
						this.chartWrapper.selectedDateRange,
						'Parameters_Until',
						this.displayNamesMapping
					);
				}
				ChartWrapper.reformatMoreFacebookObjectsForLineChart(
					this.selectedObjectsData,
					metricValue,
					this.pixelFunction ? 'Name' : this.selectedObjectsMainDataFieldNames[this.lineChart],
					this.chartWrapper.selectedDateRange,
					'Parameters_Until',
					this.displayNamesMapping
				);

				const lineChart = new LineChart(' ', actors.labels, this.chartWrapper.chartType === ChartTypeEnum.Area);
				for (const line of actors.lines) {
					lineChart.addLine(line.name, line.metrics);
				}
				this.chartWrapper.chartOption = lineChart.option;
				this.chartWrapper.show = true;
			} else {
				const actors = ChartWrapper.reformatMoreMetricsForLineChart(this.selectedObjectsData, metricValue, this.chartWrapper.selectedDateRange);
				const lineChart = new LineChart(' ', actors.labels, this.chartWrapper.chartType === ChartTypeEnum.Area);
				for (const line of actors.lines) {
					lineChart.addLine(line.name, line.metrics);
				}
				this.chartWrapper.chartOption = lineChart.option;
				this.chartWrapper.show = true;
			}
		}
	}

	async getInsightsData(chartWrapper: ChartWrapper, queryParams: InsightsQueryParams): Promise<ChartWrapper> {
		return new Promise<ChartWrapper>(resolve => {
			const metricValue = this.getSelectedMetrics();

			if (!metricValue || metricValue.length === 0) {
				chartWrapper.show = false;
				resolve(chartWrapper);
			}

			if (this.chartWrapper.chartType === ChartTypeEnum.Bar) {
				queryParams.dimensions = [queryParams.dimensions[0]];
			}

			this.echartService
				.getChartData(this.metrics, queryParams, this.endpoint)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					(result: any) => {
						// To do : find a nicer way for getting the GroupColumnName field from the mainDataName field of the object
						// (object[mainDataName] is another object and the key that we need is at the GroupColumnName of the inner object

						// We saved the result in selectedObjectsData so that another query is not necessary when only the metrics change
						// (we get all the metrics every time and only display the ones selected)

						if (chartWrapper.chartType === ChartTypeEnum.Bar) {
							this.selectedObjectsMainDataFieldNames[this.barChart] = queryParams.dimensions[0]['GroupColumnName'];
							if (this.selectedActors.length > 1) {
								this.selectedObjectsData = result as any[];
							} else {
								this.selectedObjectsData = result[0] as any[];
							}
						}
						if (chartWrapper.chartType === ChartTypeEnum.Line || chartWrapper.chartType === ChartTypeEnum.Area) {
							this.selectedObjectsMainDataFieldNames[this.lineChart] = queryParams.dimensions[0]['GroupColumnName'];
							this.selectedObjectsData = result as any[];
						}
						resolve(chartWrapper);
					},
					() => {
						this.snackBarOpen();
						resolve(chartWrapper);
					}
				);
		});
	}

	private aggregatePixelData(
		data: { FacebookId: string; Parameters_Until: string; [key: string]: any }[]
	): Map<string, { Values: any[]; Metrics: QueryBuilderColumn[] }> {
		const breakdowns = this.metrics.map(metric => metric.accessName);

		// map insights to days instead of minutes
		data.forEach(insight => {
			insight.Parameters_Until = moment(insight.Parameters_Until, 'YYYY-MM-DD').format('YYYY-MM-DD');
		});

		// squashes insights together into by time grouped objects, and aggregates insights from the same day
		const squashedInsightsByBreakdowns: Map<string, Map<string, any>> = new Map(); // holds  a map of well formatted insights, placed in their corresponding breakdown buckets
		const metricsByBreakdowns: Map<string, string[]> = new Map();
		data.forEach(insight => {
			for (const breakdown in insight) {
				// checks all properties
				if (breakdowns.includes(breakdown)) {
					// but only gets breakdowns
					this.addMetricToMap(insight, breakdown, metricsByBreakdowns);
					let breakdownData: Map<string, any>;
					if (squashedInsightsByBreakdowns.has(breakdown)) {
						breakdownData = squashedInsightsByBreakdowns.get(breakdown);
					} else {
						breakdownData = new Map();
					}
					const insightKey = insight.Parameters_Until;

					const insightObject = breakdownData.has(insightKey)
						? breakdownData.get(insightKey)
						: {
								Parameters_Until: insight.Parameters_Until
						  };
					const addedCount = insightObject[insight.Value] ? insightObject[insight.Value] : 0;
					insightObject[insight.Value] = addedCount + insight[breakdown];
					breakdownData.set(insightKey, insightObject);

					squashedInsightsByBreakdowns.set(breakdown, breakdownData);
				}
			}
		});

		const fullyMappedStuff: Map<string, { Values: any[]; Metrics: QueryBuilderColumn[] }> = new Map();
		breakdowns.forEach(breakdown => {
			const breakdownValues = squashedInsightsByBreakdowns.has(breakdown) ? Array.from(squashedInsightsByBreakdowns.get(breakdown).values()) : [];
			const breakdownMetrics = metricsByBreakdowns.has(breakdown) ? Array.from(metricsByBreakdowns.get(breakdown).values()) : [];
			const mappedObject = {
				Values: breakdownValues,
				Metrics: breakdownMetrics.map(metric => {
					return {
						Name: metric,
						Aggregator: QueryBuilderAggregatorEnum.Sum
					};
				})
			};
			fullyMappedStuff.set(breakdown, mappedObject);
		});
		return fullyMappedStuff;
	}

	private addMetricToMap(insight: any, breakdown: string, metricsByBreakdowns: Map<string, string[]>) {
		const valueArr = metricsByBreakdowns.has(breakdown) ? metricsByBreakdowns.get(breakdown) : [];
		if (!valueArr.includes(insight.Value)) {
			valueArr.push(insight.Value);
		}
		metricsByBreakdowns.set(breakdown, valueArr);
	}

	filterActors() {
		if (!this.actors) {
			return;
		}
		let search = this.actorsFilterCtrl.value;
		if (!search) {
			this.filteredActors.next(this.actors);
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredActors.next(this.actors.filter(actor => actor.name.toLowerCase().indexOf(search) > -1));
	}

	snackBarOpen() {
		this.chartWrapper.show = false;
		this.toastNotificationService.sendErrorToast('We could not fetch data for the selected actors. Please select different actors or contact support.');
	}
}
