export enum MasterTableImplementationsEnum {
	AdsManagerCampaignTable = 'adsManagerCampaignTable',
	AdsManagerAdSetTable = 'adsManagerAdSetTable',
	AdsManagerAdTable = 'adsManagerAdTable',
	InfluencerCampaignTable = 'influencerCampaignTable',
	InfluencerInfluncerTable = 'influencerInfluncerTable',
	OutreachInfluncerTable = 'outreachInfluncerTable',
	CreativesInfluncerTable = 'creativesInfluncerTable',
	JobsInfluncerTable = 'jobsInfluncerTable',
	TasksInfluncerTable = 'tasksInfluncerTable'
}
