import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonTypeEnum } from '../../../../button-component/button-type.enum';
import { ButtonClassEnum } from '../../../../button-component/button-class.enum';

@Component({
	selector: 'app-build-url-parameter-dialog',
	templateUrl: './build-url-parameter-dialog.component.html',
	styleUrls: ['./build-url-parameter-dialog.component.scss']
})
export class BuildUrlParameterDialogComponent implements OnInit, OnDestroy {
	public parametersGroup: FormGroup;
	public urlWithParams: string;
	public buttonType = ButtonTypeEnum;
	public buttonClass = ButtonClassEnum;
	public extraParams: [FormControl, FormControl][] = [];

	private unsubscriber$ = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<BuildUrlParameterDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: string
	) {}

	ngOnInit(): void {
		const urlValidator = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
		this.parametersGroup = this.formBuilder.group({
			_websiteUrl: new FormControl('', [Validators.required, Validators.pattern(urlValidator)]),
			_medium: new FormControl(''),
			_source: new FormControl(''),
			_name: new FormControl(''),
			_content: new FormControl('')
		});
		this.patchForm();
		this.parametersGroup.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			this.urlWithParams = this.buildUrlParam();
		});
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onCancel(): void {
		this.dialogRef.close();
	}

	public onAddParameter(): void {
		this.extraParams.push(this.buildNewExtraParameter('', ''));
	}

	public onSave(): void {
		this.dialogRef.close(this.urlWithParams);
	}

	public removeParam(index: number): void {
		this.extraParams = this.extraParams.filter((value, i) => i !== index);
	}

	private buildNewExtraParameter(name: string, value: string): [FormControl, FormControl] {
		const nameControl = new FormControl(name);
		const valueControl = new FormControl(value);
		valueControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(_ => (this.urlWithParams = this.buildUrlParam()));
		return [nameControl, valueControl];
	}

	private buildUrlParam(): string {
		let result = '?';
		const defaults = this.parametersGroup?.value ?? {};

		if (defaults._medium) {
			result += `utm_medium=${defaults._medium}&`;
		}
		if (defaults._source) {
			result += `utm_source=${defaults._source}&`;
		}
		if (defaults._name) {
			result += `utm_campaign=${defaults._name}&`;
		}
		if (defaults._content) {
			result += `utm_content=${defaults._content}&`;
		}

		this.extraParams.forEach(param => {
			const paramName = param[0].value;
			const paramValue = param[1].value;
			if (paramName && paramValue) {
				result += `${paramName}=${paramValue}&`;
			}
		});

		result = result.slice(0, -1);
		return defaults._websiteUrl + result;
	}

	private extractUrlParams(url: string): [string, string][] {
		const result: [string, string][] = [];
		const baseSplit = url.split('?');
		if (!baseSplit) {
			return null;
		}

		result.push(['base', baseSplit[0]]);

		if (!baseSplit[1]) {
			return;
		}
		const paramsSplit = baseSplit[1].split('&');
		paramsSplit.forEach(param => {
			const nameValuePair = param.split('=');
			result.push([nameValuePair[0], nameValuePair[1]]);
		});

		return result;
	}

	private patchForm(): void {
		const params = this.extractUrlParams(this.data);
		if (!params) {
			this.parametersGroup.get('_websiteUrl').patchValue(this.data);
			this.urlWithParams = this.data;
		} else {
			params.forEach(pair => {
				switch (pair[0]) {
					case 'base':
						this.parametersGroup.get('_websiteUrl').patchValue(pair[1]);
						break;
					case 'utm_medium':
						this.parametersGroup.get('_medium').patchValue(pair[1]);
						break;
					case 'utm_campaign':
						this.parametersGroup.get('_name').patchValue(pair[1]);
						break;
					case 'utm_source':
						this.parametersGroup.get('_source').patchValue(pair[1]);
						break;
					case 'utm_content':
						this.parametersGroup.get('_content').patchValue(pair[1]);
						break;
					default:
						this.extraParams.push(this.buildNewExtraParameter(pair[0], pair[1]));
						break;
				}
			});
			this.urlWithParams = this.buildUrlParam();
		}
	}
}
