import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-mobile-lunch',
	templateUrl: './mobile-lunch.component.html',
	styleUrls: ['./mobile-lunch.component.scss']
})
export class MobileLunchComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {}

	public navigateToHome(): void {
		this.router.navigate(['/authentication']);
	}
}
