import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Subject } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { select, Store } from '@ngrx/store';
import { getFiledId, getUserDetails, UserState } from '../../shared/state/user/user.reducer';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { EnableFirstTimeCustomer, LogIn } from '../state/authentication.action';
import { AuthenticationState, getRedirectUrl } from '../state/authentication.reducer';
import { TrialDialogComponent } from '../trial-dialog/trial-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BillingPlan } from '../../user-management/models/billing-plan.interface';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserStateEnum } from '../../_services/user/user-state.enum';
import { getGlobalSpinnerStatus, SharedState } from '../../shared/state/shared.reducer';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { LogInModel } from '../_models/login.model';
import { AppState } from 'src/app/state/app.state';
import { GenericPopupComponent } from 'src/app/shared/pop-ups/generic-popup/generic-popup.component';
import { PopUpInterface } from 'src/app/shared/models/generic-popup-interface';

@Component({
	selector: 'app-add-payment',
	templateUrl: './add-payment.component.html',
	styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit, OnDestroy {
	public userHasFacebookRights: boolean;
	public userHasGoogleRights: boolean;
	public billingPlan: BillingPlan;
	public userState: number;
	public hasPaymentCard = false;
	public showSpinner: boolean;
	public chargebeeLink: string = null;
	public enableConfirmButton = false;

	private unsubscriber$ = new Subject<void>();
	private accountState: number;
	public userId: string;

	constructor(
		private ngZone: NgZone,
		private authenticationService: AuthenticationService,
		private userStore: Store<UserState>,
		private authStore: Store<AuthenticationState>,
		private sharedStore: Store<SharedState>,
		private matDialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private translate: TranslateService,
		private toastNotificationService: ToastNotificationService,
		private router: Router,
		private store: Store<AppState>
	) {}

	public ngOnInit(): void {
		this.userAccountState();
		this.loadChargebeeLink();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private userAccountState(): void {
		this.showSpinner = true;
		this.userStore.pipe(select(getUserDetails), take(1)).subscribe(user => {
			this.userId = user.FiledId.toString();
			this.backOfficeService
				.getUserState(user.FiledId)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(accountState => {
					this.showSpinner = false;
					this.accountState = accountState;
				});
		});
	}

	public loadChargebeeLink(): void {
		this.showSpinner = true;
		this.backOfficeService
			.getChargeBeeLink()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.showSpinner = false;
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
					return EMPTY;
				})
			)
			.subscribe(value => {
				this.showSpinner = false;
				this.chargebeeLink = value;
			});
	}

	public connectToChargebee(): void {
		window.open(this.chargebeeLink, '_blank');
		this.enableConfirmButton = true;
	}

	public confirmUserPaymentCard(): void {
		this.showSpinner = true;
		if (this.accountState === UserStateEnum.FreeTrialExpiredNoCreditCard || this.accountState === UserStateEnum.FreemiumExpiredNoCreditCard) {
			this.backOfficeService
				.confirmFreemiumStatus(this.userId)
				.pipe(
					takeUntil(this.unsubscriber$),
					catchError(error => {
						this.showSpinner = false;
						this.enableConfirmButton = false;
						this.noCardPopup();
						return EMPTY;
					})
				)
				.subscribe(value => {
					if (value.status === 200 || value.status === 204) {
						setTimeout(() => {
							this.showSpinner = false;
							this.authStore.dispatch(new LogIn());
						}, 12000);
					} else {
						this.showSpinner = false;
						this.enableConfirmButton = false;
						this.noCardPopup();
					}
				});
		} else {
			this.backOfficeService
				.confirmPaymentCard()
				.pipe(
					takeUntil(this.unsubscriber$),
					catchError(error => {
						this.showSpinner = false;
						this.enableConfirmButton = false;
						this.noCardPopup();
						return EMPTY;
					})
				)
				.subscribe(value => {
					if (value.status === 200 || value.status === 204) {
						setTimeout(() => {
							this.showSpinner = false;
							this.authStore.dispatch(new LogIn());
						}, 12000);
					} else {
						this.showSpinner = false;
						this.enableConfirmButton = false;
						this.noCardPopup();
					}
				});
		}
	}

	public logout(): void {
		this.authenticationService.logout();
	}

	public noCardPopup(): void {
		this.matDialog
			.open<GenericPopupComponent, PopUpInterface>(GenericPopupComponent, {
				data: {
					message: 'You have not successfully added a card payment detail, please try again or contact support',
					actionAnswer: 'Ok',
					noActionAnswer: '',
					header: 'No Card Payment Details',
					image: '/assets/icons/alert-logo.svg'
				},
				panelClass: ['generic-popup'],
				disableClose: true
			})
			.afterOpened()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.loadChargebeeLink();
			});
	}
}
