import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { ButtonClassEnum } from '../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../shared/button-component/button-type.enum';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { InputTextComponent } from '../../shared/form-input/input-text/input-text.component';
import { AuthenticationState, getResetPasswordDialogConstants } from '../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './reset-password-email-dialog.component.html',
	styleUrls: ['./reset-password-email-dialog.component.scss']
})
export class ResetPasswordEmailDialogComponent implements OnInit {
	@ViewChild('emailInput') emailInput: InputTextComponent;
	@ViewChild('confirmEmailInput') confirmEmailInput: InputTextComponent;

	public emailFormGroup: FormGroup;
	public mainBtn = ButtonClassEnum;
	public typeBtn = ButtonTypeEnum;
	public validationMessages: ValidatorMessages[];

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private toastNotificationService: ToastNotificationService,
		private userServiceApi: UserServiceApi,
		private translate: TranslateService,
		private formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<ResetPasswordEmailDialogComponent>,
		private authStore: Store<AuthenticationState>
	) {}

	ngOnInit() {
		this.authStore.pipe(select(getResetPasswordDialogConstants), take(1)).subscribe(messages => {
			this.validationMessages = messages;
		});
		this.emailFormGroup = this.formBuilder.group(
			{
				emailFormControl: new FormControl('', [Validators.required, Validators.email]),
				confirmFormControl: new FormControl('', [Validators.required, Validators.email])
			},
			{ validator: this.emailsMatchValidator }
		);
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public emailsMatchValidator(formGroup: FormGroup): void {
		const matchError = formGroup.get('emailFormControl').value === formGroup.get('confirmFormControl').value ? null : { notSame: true };
		if (matchError) {
			formGroup.get('confirmFormControl').setErrors(matchError);
		}
	}

	public onSubmit(): void {
		this.emailFormGroup.markAllAsTouched();

		if (this.emailFormGroup.valid) {
			this.userServiceApi
				.resetPassword(this.emailFormGroup.get('emailFormControl').value)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {
						this.toastNotificationService.sendSuccessToast(this.translate.instant('FORGOT_PASSWORD.SUCCESS'));
						this.close();
					},
					() => {
						this.toastNotificationService.sendErrorToast(this.translate.instant('FORGOT_PASSWORD.ERROR'));
					}
				);
		} else {
			this.emailInput.setErrorMessage();
			this.confirmEmailInput.setErrorMessage();
		}
	}

	public close(): void {
		this.dialogRef.close();
	}
}
