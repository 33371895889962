import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getStepFourDetails, getStepOneDetails, getStepTwoDetails } from '../../state/smart-create.reducer';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BudgetAllocationInterface } from '../../models/budget-allocation.interface';
import { ScrollService } from '../../../_services/scroll.service';

@Component({
	selector: 'app-campaign-summary-card',
	templateUrl: './campaign-summary-card.component.html',
	styleUrls: ['./campaign-summary-card.component.scss']
})
export class CampaignSummaryCardComponent implements OnInit, OnDestroy {
	@Input() public campaignName: string;
	@Input() public objectiveName: string;
	@Input() public numberOfLocations: number;
	@Input() public numberOfSplitsByAge: number;
	@Input() public currency: string;
	@Input() public campaignBudget: number;
	@Input() public adSetBudget: number;
	@Input() public budgetSplits: BudgetAllocationInterface;
	@Output() public currentBudget = new EventEmitter<number>();

	public totalBudgetPerCampaign = 0;
	public devicesMultiplier: number;
	public numberOfCampaigns: number;
	public numberOfAdSets: number;
	public genderMultiplier: number;
	public locationsMultiplier = 1;
	public isSticky = false;

	private unsubscriber$ = new Subject<void>();

	constructor(private campaignSmartCreateStore: Store<CampaignSmartCreateState>, private scrollService: ScrollService) {}

	public ngOnInit(): void {
		this.initData();
		this.initScrollServiceListener();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public initScrollServiceListener(): void {
		this.scrollService.currentScrollY.pipe(takeUntil(this.unsubscriber$)).subscribe(scrollY => {
			this.isSticky = scrollY >= 80;
		});
	}

	public initData(): void {
		this.campaignSmartCreateStore.pipe(select(getStepFourDetails), takeUntil(this.unsubscriber$), debounceTime(50)).subscribe(data => {
			if (!this.budgetSplits?.campaignsBudget?.length && !this.budgetSplits?.adSetsBudget?.length) {
				this.getCampaignDefaultBudget();
				if (data) {
					this.devicesMultiplier = data.isSplitByDevices ? 2 : 1;
					this.genderMultiplier = data.isSplitByGenderSelected ? 2 : 1;
					this.locationsMultiplier = this.numberOfLocations && data.isSplitByLocation ? this.numberOfLocations : 1;
					this.numberOfSplitsByAge = this.numberOfSplitsByAge && data.isSplitAgeRangeSelected ? this.numberOfSplitsByAge : 1;
				} else {
					this.devicesMultiplier = 1;
					this.locationsMultiplier = 1;
					this.genderMultiplier = 1;
					this.numberOfSplitsByAge = 1;
				}

				if (!this.campaignBudget && !data.isBudgetSplitEvenly) {
					this.campaignBudget = this.adSetBudget;
				}
				if (data.isBudgetSplitEvenly && this.adSetBudget) {
					this.campaignBudget = this.adSetBudget;
				}
				this.numberOfCampaigns = this.devicesMultiplier * this.locationsMultiplier;
				this.numberOfAdSets = this.genderMultiplier * this.numberOfSplitsByAge;
				this.currentBudget.emit(this.campaignBudget);
				this.totalBudgetPerCampaign = Math.round((this.campaignBudget / this.numberOfCampaigns + Number.EPSILON) * 100) / 100;
			} else if (this.budgetSplits?.campaignsBudget?.length) {
				this.numberOfCampaigns = this.budgetSplits.campaignsBudget.length;
				this.totalBudgetPerCampaign = Math.round((this.campaignBudget / this.numberOfCampaigns + Number.EPSILON) * 100) / 100;
				this.campaignBudget = 0;
				this.genderMultiplier = data.isSplitByGenderSelected ? 2 : 1;
				this.numberOfAdSets = this.genderMultiplier * this.numberOfSplitsByAge;
				this.budgetSplits.campaignsBudget.forEach(campaign => {
					this.campaignBudget += campaign.budget;
				});
				this.currentBudget.emit(this.campaignBudget);
			} else if (this.budgetSplits?.adSetsBudget?.length) {
				this.campaignBudget = 0;
				this.numberOfAdSets = 0;
				this.budgetSplits.adSetsBudget.forEach(campaign => {
					this.numberOfAdSets = campaign.adSets.length;
					campaign.adSets.forEach(adSet => {
						this.campaignBudget += adSet.budget;
					});
				});
				this.campaignBudget = Math.round(this.campaignBudget * 100) / 100;
				this.currentBudget.emit(this.campaignBudget);
			}
		});
		this.getCampaignDefaultBudget();
	}

	public getCampaignDefaultBudget(): void {
		this.campaignSmartCreateStore.pipe(select(getStepOneDetails), take(1), debounceTime(50)).subscribe(data => {
			if (data.campaignBudgetOptimization?.amount) {
				this.campaignBudget = data.campaignBudgetOptimization.amount;
			} else {
				this.campaignSmartCreateStore.pipe(select(getStepTwoDetails), take(1), debounceTime(50)).subscribe(budget => {
					this.campaignBudget = budget.budgetOptimization.amount;
				});
			}
		});
	}
}
