import { HttpClient } from '@angular/common/http';
import { InsightsCategoryTypeEnum } from '../../models/insights-category-type.enum';
import { GoogleInsightsMetadataService } from '../../google-insights-metadata.service';
import { InsightsMetadataBaseService } from 'src/app/shared/services/insights-metadata-base.service';
import { InsightsMetadataFactory } from './insights-metadata-factory.model';

export class GoogleInsightsMetadataFactory implements InsightsMetadataFactory {
	constructor(protected http: HttpClient) {}

	public getInstance(categoryType: InsightsCategoryTypeEnum): InsightsMetadataBaseService {
		switch (categoryType) {
			case InsightsCategoryTypeEnum.Campaign:
				return new GoogleInsightsMetadataService(this.http, 'CampaignPerformances');

			case InsightsCategoryTypeEnum.AdSet:
				return new GoogleInsightsMetadataService(this.http, 'AdGroupPerformances');

			case InsightsCategoryTypeEnum.Ad:
				return new GoogleInsightsMetadataService(this.http, 'AdPerformances');

			case InsightsCategoryTypeEnum.Keyword:
				return new GoogleInsightsMetadataService(this.http, 'KeywordsPerformances');
			default:
				return null;
		}
	}
}
