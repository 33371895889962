export class Legend {
	constructor(
		public orient: 'horizontal' | 'vertical',
		public bottom: string,
		public top: string,
		public icon: string,
		public type: any = 'scroll',
		data?: any[]
	) {}
}
