import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationActions, AuthenticationActionType } from './authentication.action';
import { CreditCardStaticVariables } from '../set-credit-card/CreditCardStaticVariables';
import { ResetPasswordDialogConstants } from '../reset-password-email/ResetPasswordDialogConstants';
import { DataInterface } from '../../shared/state/state-entities/data-interface.model';
import {
	updateCompanyName,
	updateRegDialcode,
	updateRegDuration,
	updateRegEmail,
	updateRegName,
	updateRegLastName,
	updateRegPassword,
	updateRegPhoneNumber,
	updateRegPlan,
	updateRegToken
} from '../new-signup/utils/signup-utils';
import { AccountConnection, RegisterModel, UserDetailModel } from '../_models/login.model';

export interface AuthenticationState {
	isAuthenticated: boolean;
	jwtToken: DataInterface<any>;
	setCreditCardConstants: CreditCardStaticVariables;
	resetPasswordConstants: ResetPasswordDialogConstants;
	isFirstTimeCustomer: boolean;
	redirectUrl: string;
	registrationDetail: RegisterModel;
	userRegistrationDetail: UserDetailModel;
	accountConnectionState: AccountConnection;
	showOtpPop: boolean;
}

export const initialAuthState: AuthenticationState = {
	isAuthenticated: false,
	jwtToken: null,
	setCreditCardConstants: new CreditCardStaticVariables(),
	resetPasswordConstants: new ResetPasswordDialogConstants(),
	isFirstTimeCustomer: false,
	redirectUrl: '/redirect',
	registrationDetail: { email: '', password: '', plan: 0, duration: '' },
	userRegistrationDetail: { name: '', lastname: '', phone: 0, dialCode: '', email: '', password: '', plan: 0, duration: '', token: '', companyName: '' },
	accountConnectionState: { connectionSource: false, refreshComplete: false },
	showOtpPop: false
};

export function authenticationReducer(state = initialAuthState, action: AuthenticationActions): AuthenticationState {
	switch (action.type) {
		case AuthenticationActionType.LogIn: {
			return {
				...state,
				isAuthenticated: true,
				jwtToken: {
					...state.jwtToken,
					isLoaded: true
				}
			};
		}
		case AuthenticationActionType.RestoreAuthentication:
		case AuthenticationActionType.LogInSuccess: {
			return {
				...state,
				isAuthenticated: true,
				jwtToken: {
					...state.jwtToken,
					data: action.payload
				}
			};
		}
		case AuthenticationActionType.LogInFailure: {
			return {
				...state,
				isAuthenticated: false,
				jwtToken: {
					...state.jwtToken,
					errorCode: action.payload
				}
			};
		}
		case AuthenticationActionType.EnableFirstTimeCustomer: {
			return {
				...state,
				isFirstTimeCustomer: true
			};
		}
		case AuthenticationActionType.DisableFirstTimeCustomer: {
			return {
				...state,
				isFirstTimeCustomer: false
			};
		}
		case AuthenticationActionType.LogOut: {
			return {
				...initialAuthState,
				isFirstTimeCustomer: state.isFirstTimeCustomer
			};
		}
		case AuthenticationActionType.RegisterUserEmail: {
			return {
				...state,
				userRegistrationDetail: updateRegEmail(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserPassword: {
			return {
				...state,
				userRegistrationDetail: updateRegPassword(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserAccountPlan: {
			return {
				...state,
				userRegistrationDetail: updateRegPlan(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserDuration: {
			return {
				...state,
				userRegistrationDetail: updateRegDuration(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserName: {
			return {
				...state,
				userRegistrationDetail: updateRegName(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserLastName: {
			return {
				...state,
				userRegistrationDetail: updateRegLastName(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserCompanyName: {
			return {
				...state,
				userRegistrationDetail: updateCompanyName(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserPhone: {
			return {
				...state,
				userRegistrationDetail: updateRegPhoneNumber(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserDialCode: {
			return {
				...state,
				userRegistrationDetail: updateRegDialcode(state, action.payload)
			};
		}

		case AuthenticationActionType.RegisterUserToken: {
			return {
				...state,
				userRegistrationDetail: updateRegToken(state, action.payload)
			};
		}

		case AuthenticationActionType.RegistrationDetail: {
			return {
				...state,
				userRegistrationDetail: action.payload
			};
		}
		case AuthenticationActionType.AccountConnectionState: {
			return {
				...state,
				accountConnectionState: action.payload
			};
		}
		case AuthenticationActionType.ShowOtpInput: {
			return {
				...state,
				showOtpPop: action.payload
			};
		}
		default:
			return state;
	}
}
const getAuthenticationState = createFeatureSelector<AuthenticationState>('authentication');

export const getSetCreditCardConstants = createSelector(getAuthenticationState, state => state.setCreditCardConstants);
export const getResetPasswordDialogConstants = createSelector(getAuthenticationState, state => state.resetPasswordConstants.validationMessages);
export const getIsFirstTimeCustomer = createSelector(getAuthenticationState, state => state.isFirstTimeCustomer);
export const getRedirectUrl = createSelector(getAuthenticationState, state => state.redirectUrl);

export const getUserRegistrationDetail = createSelector(getAuthenticationState, state => state.userRegistrationDetail);

export const getAccountConnectionState = createSelector(getAuthenticationState, state => state.accountConnectionState);
export const getOtpDialogState = createSelector(getAuthenticationState, state => state.showOtpPop);
