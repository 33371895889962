import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-spinner-filed',
	templateUrl: './spinner-filed.component.html',
	styleUrls: ['./spinner-filed.component.scss'],
	animations: [
		trigger('animateOnDestroy', [
			transition(':enter', [style({ opacity: '0' }), animate(300)]),
			transition(':leave', [style({ opacity: '0' }), animate(300)])
		])
	]
})
export class SpinnerFiledComponent implements OnDestroy, OnInit {
	@Input() public shouldCoverWholePage = false;
	@Input() public backgroundColor = 'rgba(247, 247, 247, 0)';
	@Input() public preloaderBackground = true;
	@Input() public isIndefinite = false;

	@Input()
	public set timeOut(value: number) {
		this.setTimeout(value);
	}

	public get timeOut(): number {
		return this._timeout;
	}

	public isSpinnerVisible = true;

	private _timeout = 800;
	private _subscription: Subscription = new Subscription();
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {}

	ngOnInit() {
		this._subscription = this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe(
			event => {
				if (event instanceof NavigationStart) {
					this.isSpinnerVisible = true;
				} else if ((event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) && !this.isIndefinite) {
					setTimeout(() => {
						this.isSpinnerVisible = false;
					}, this.timeOut);
				}
			},
			() => {
				this.isSpinnerVisible = false;
			}
		);
	}

	ngOnDestroy() {
		this.isSpinnerVisible = false;
		this._subscription.unsubscribe();

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private setTimeout(value: number) {
		if (!value) {
			return;
		}
		this._timeout = value;
	}
}
