<app-expansion-panel-sc
	*ngIf="selectedObjective === subObjectives.MessageText"
	class="scs-expansion-panel"
	[isSelected]="isPanelSelected"
	[expansionTitle]="'Message Destination'"
	[isExpandable]="false"
>
	<div expansion-content>
		<div class="k-mb-2">
			<font08-4>Choose where you want to send people to message your business.</font08-4>
		</div>
		<mat-radio-group class="tc-radio-group" name="targeting">
			<mat-radio-button class="tc-radio-button k-align-items-start" [value]="messageDestinationType.Messenger" name="message">
				<div>
					<span>Messenger</span>
					<font08>
						Send people from ads into Messenger conversations with your business. Your ad will be shown to people who are more likely to open
						Messenger.
					</font08>
				</div>
			</mat-radio-button>
			<div *ngIf="selectedMessageType == messageDestinationType.Messenger">
				<div class="k-mb-4-f">
					<drp08 (valueChanges)="dropDownValueChanges($event)" [data]="messageDropData" [width]="'100%'" [placeholder]="'Select an option'"></drp08>
				</div>
				<div class="k-mb-4-f">
					<font08-4 class="k-mb-3-f">From</font08-4>
					<drp08 (valueChanges)="dropDownValueChanges($event)" [data]="messageDropData" [width]="'100%'" [placeholder]="'Select an option'"></drp08>
				</div>
			</div>
			<mat-radio-button class="tc-radio-button" [value]="messageDestinationType.InstagramDirect" name="message">
				<span>Instagram Direct</span>
				<font08>
					Send people from ads into Messenger conversations with your business. Your ad will be shown to people who are more likely to open Messenger.
				</font08>
			</mat-radio-button>
		</mat-radio-group>
	</div>
</app-expansion-panel-sc>
