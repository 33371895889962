<section class="user-management-navigation">
	<nav mat-tab-nav-bar>
		<div>
			<a
				#rla="routerLinkActive"
				*ngFor="let nav of navLinks"
				[routerLinkActiveOptions]="{ exact: false }"
				[routerLink]="nav.link"
				mat-tab-link
				[routerLinkActive]="'active'"
			>
				{{ nav.label }}
			</a>
		</div>
		<ng-content></ng-content>
	</nav>
</section>
