import {
	Component,
	ElementRef,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { GridsterConfig, GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { Subject, Subscription } from 'rxjs';

import { ReportComponentService } from '../../services/chart-services/report-component.service';
import { ChartService } from '../../services/chart-services/chart.service';
import { IChartPositionProperty, ReportModel } from '../../models/report.model';
import { ChartTypeEnum } from '../charts/chart-type.enum';
import { SourceChannel } from '../../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { ReportingState } from '../../state/reporting.reducer';
import { Store } from '@ngrx/store';
import { DashboardService } from '../../services/api-services/dashboard.service';
import { take, takeUntil } from 'rxjs/operators';
import { themesModel } from '../../models/themes.model';
import { property } from 'lodash';
export interface themeColor {
	name: string;
	colors: string[];
}
@Component({
	selector: 'app-dashboard-grid',
	templateUrl: './dashboard-grid.component.html',
	styleUrls: ['./dashboard-grid.component.scss'],
	encapsulation: ViewEncapsulation.Emulated
})
export class DashboardGridComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public selectedReportIndex: number;
	@Input() public editWindowIsOpen: boolean;
	@Input() public dashboardInEditMode: boolean;
	@Input() public gridOptions: GridsterConfig;
	@Input() public dashboardReports: ReportModel[];
	@Output() deleteWidgetEvent = new EventEmitter();
	@Output() openEditWindow: EventEmitter<{
		index: number;
		widget: ReportModel;
		openEditWindow: boolean;
		property: IChartPositionProperty;
	}> = new EventEmitter();
	@Output() closeMenuOnOutsideClick: EventEmitter<number> = new EventEmitter();
	@Output() duplicateWidgetEvent: EventEmitter<ReportModel> = new EventEmitter();
	@Output() changeThemeEvent: EventEmitter<ReportModel> = new EventEmitter();
	@ViewChild('gridsterContainer') gridsterContainer: ElementRef;
	@ViewChild('gridster') gridster: GridsterConfig;

	public removeReportSubscription: Subscription;
	public hoverIndex: number;
	public infinity = Infinity;
	public chartTypes = ChartTypeEnum;
	public sourceChannel = SourceChannel;
	public expand: true;
	public expandSubscription: Subscription;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public WidgetIndex: number;
	public switchGridOptions: GridsterConfig;
	public themes = themesModel;
	public themesNames: themeColor[] = [];
	public arrayofProperty: IChartPositionProperty[] = [];

	constructor(
		private dashboardService: DashboardService,
		private store: Store<ReportingState>,
		private widgetComponentService: ReportComponentService,
		private chartService: ChartService
	) {}

	ngOnInit() {
		for (const key in this.themes) {
			if (Object.prototype.hasOwnProperty.call(this.themes, key)) {
				this.themesNames.push({
					name: key,
					colors: this.themes[key]['color'].slice(0, 6)
				});
			}
		}
		this.removeReportSubscription = this.widgetComponentService.removeWidgetSubject.subscribe((index: number) => {
			this.removeWidget(index);
		});
		this.dragStopEventHandle();
		this.resizeStopEventHandle();
		this.itemInitEventHandle();
		// this.setMinWidgetSize();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.dashboardInEditMode) {
			this.gridOptions.resizable.enabled = false;
			this.gridOptions.draggable.enabled = false;
		}
		this.PositionSet();
	}
	public do(par: any): boolean {
		return true;
	}
	public PositionSet(): void {
		this.gridOptions.itemInitCallback = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
			{
				const property: IChartPositionProperty = {
					graph_id: this.dashboardReports[item.index].id.toString(),
					positionProperty: {
						layerIndex: item.index,
						x: itemComponent.$item.x,
						y: itemComponent.$item.y,
						rows: itemComponent.$item.rows,
						cols: itemComponent.$item.cols,
						draggable: true,
						resizeEnabled: true
					}
				};
				this.arrayofProperty.push(property);
				this.editPropertyPosition(property);
			}
		};
	}
	public editedPosition(widget: ReportModel): IChartPositionProperty {
		let property: IChartPositionProperty;
		this.arrayofProperty.forEach(element => {
			if (element.graph_id == widget.id.toString()) {
				property = element;
			}
		});
		return property;
	}
	public itemInitEventHandle() {
		this.gridOptions.itemInitCallback = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
			if (this.dashboardReports[item.index].details.positionProperty) {
				const positioningValue = this.dashboardReports[item.index].details.positionProperty;
				this.dashboardReports[item.index].details.reportProperty.x = positioningValue.x;
				this.dashboardReports[item.index].details.reportProperty.y = positioningValue.y;
				this.dashboardReports[item.index].details.reportProperty.cols = positioningValue.cols;
				this.dashboardReports[item.index].details.reportProperty.rows = positioningValue.rows;
			} else {
				const property: IChartPositionProperty = {
					graph_id: this.dashboardReports[item.index].id.toString(),
					positionProperty: {
						layerIndex: item.index,
						x: itemComponent.$item.x,
						y: itemComponent.$item.y,
						rows: itemComponent.$item.rows,
						cols: itemComponent.$item.cols,
						draggable: true,
						resizeEnabled: true
					}
				};
				this.editPropertyPosition(property);
			}
		};
	}

	public dragStopEventHandle(): void {
		this.gridOptions.draggable.stop = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
			this.dashboardReports[item.index].details.reportProperty.x = itemComponent.$item.x;
			this.dashboardReports[item.index].details.reportProperty.y = itemComponent.$item.y;
			this.dashboardReports[item.index].details.reportProperty.cols = itemComponent.$item.cols;
			this.dashboardReports[item.index].details.reportProperty.rows = itemComponent.$item.rows;
			const property: IChartPositionProperty = {
				graph_id: this.dashboardReports[item.index].id.toString(),
				positionProperty: {
					layerIndex: item.index,
					x: itemComponent.$item.x,
					y: itemComponent.$item.y,
					rows: itemComponent.$item.rows,
					cols: itemComponent.$item.cols,
					draggable: true,
					resizeEnabled: true
				}
			};
			this.arrayofProperty.forEach(element => {
				if (element.graph_id == property.graph_id) {
					element.positionProperty.x = property.positionProperty.x;
					element.positionProperty.y = property.positionProperty.y;
					element.positionProperty.cols = property.positionProperty.cols;
					element.positionProperty.rows = property.positionProperty.rows;
				}
			});
			this.editPropertyPosition(property);
		};
	}

	public resizeStopEventHandle(): void {
		this.gridOptions.resizable.stop = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
			this.dashboardReports[item.index].details.reportProperty.x = itemComponent.$item.x;
			this.dashboardReports[item.index].details.reportProperty.y = itemComponent.$item.y;
			this.dashboardReports[item.index].details.reportProperty.cols = itemComponent.$item.cols;
			this.dashboardReports[item.index].details.reportProperty.rows = itemComponent.$item.rows;
			const property: IChartPositionProperty = {
				graph_id: this.dashboardReports[item.index].id.toString(),
				positionProperty: {
					layerIndex: item.index,
					x: itemComponent.$item.x,
					y: itemComponent.$item.y,
					rows: itemComponent.$item.rows,
					cols: itemComponent.$item.cols,
					draggable: true,
					resizeEnabled: true
				}
			};
			this.arrayofProperty.forEach(element => {
				if (element.graph_id == property.graph_id) {
					element.positionProperty.x = property.positionProperty.x;
					element.positionProperty.y = property.positionProperty.y;
					element.positionProperty.cols = property.positionProperty.cols;
					element.positionProperty.rows = property.positionProperty.rows;
				}
			});
			this.editPropertyPosition(property);
		};
	}

	public setMinWidgetSize(): void {
		this.gridOptions.resizable.start = (item: GridsterItem, itemComponent: GridsterItemComponentInterface) => {
			itemComponent.$item.minItemCols = 3;
			itemComponent.$item.minItemRows = 2;
			this.dashboardReports[item.index].details.reportProperty.minItemCol = itemComponent.$item.minItemCols;
			this.dashboardReports[item.index].details.reportProperty.minItemRows = itemComponent.$item.minItemRows;
		};
	}

	public openWidgetEdit(event: any, widget: ReportModel, index: number): void {
		this.editedPosition(widget);
		event.stopPropagation();
		this.WidgetIndex = index;
		this.selectedReportIndex = index;
		let property: IChartPositionProperty = this.editedPosition(widget);
		this.openEditWindow.emit({ index: index, widget: widget, openEditWindow: true, property: property });
	}

	public onHover(index?: number): void {
		this.hoverIndex = index;
	}

	public removeWidget(index: number): void {
		// This is required due to an known issue with gridster
		setTimeout(() => {
			this.dashboardReports.splice(index, 1);
			this.chartService.removeChartInstance(index);
			this.gridOptions.api.optionsChanged();
		}, 0);
		this.deleteWidgetEvent.emit(index);
	}

	public exportRawData(index: number): void {
		const report = this.dashboardReports[index];
		this.chartService.exportSingleChart(report);
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();

		if (this.removeReportSubscription) {
			this.removeReportSubscription.unsubscribe();
		}

		if (this.expandSubscription) {
			this.expandSubscription.unsubscribe();
		}
	}

	public duplicateWidget(item: ReportModel): void {
		this.duplicateWidgetEvent.emit(item);
	}

	public editPropertyPosition(property: IChartPositionProperty) {
		const propertyPayload: IChartPositionProperty[] = [property];
		this.dashboardService
			.editReportPositioning(propertyPayload, this.widgetComponentService.dashboardId$.value)
			.pipe(take(1))
			.subscribe(response => {});
	}
	public changeTheme(theme: string, item: ReportModel) {
		if (!item.details.style) {
			item.details.style = {
				title: '',
				subtitle: '',
				isBrandGuardian: false,
				theme: 'default',
				color: '#087ab1cc',
				hasGradient: false
			};
		}
		item.details.style.theme = theme;
		this.changeThemeEvent.emit(item);
	}
}
