import { AxisLabel } from './axis-label';
import { AxisLine } from './axis-line';
import { AxisTick } from './axis-tick';
import { AxisLabelTextStyle } from './axis-label-text-style';

export class YAxis {
	public axisLabel: AxisLabel;
	public axisLine: AxisLine;
	public axisTick: AxisTick;

	constructor(
		public type: string,
		public nameGap: number,
		public max: number,
		public name: string,
		public axisLabelColor: string,
		public nameLocation: string,
		public showAxisLine: boolean,
		public showAxisTick: boolean
	) {
		this.type = type;
		this.nameGap = nameGap;
		this.max = max;
		this.name = name;
		this.axisLabel = new AxisLabel(new AxisLabelTextStyle(axisLabelColor));
		this.nameLocation = nameLocation;
		this.axisLine = new AxisLine(showAxisLine);
		this.axisTick = new AxisTick(showAxisTick);
	}
}
