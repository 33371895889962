import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { ValidatorMessages } from '../../../../shared/form-input/validatorMessagesInputs';
import { NewUserModel } from '../../../models/new-user-model';

@Component({
	selector: 'app-user-details',
	templateUrl: './user-details.component.html',
	styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
	@Input() public userData: NewUserModel;
	@Input() public form: FormGroup;
	@Input() public activeStep: boolean;
	@Output() public formIsValid = new EventEmitter<boolean>();

	public user = new NewUserModel();
	public validationNameMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'This field is mandatory'
		},
		{
			validationType: 'maxlength',
			validationMessage: 'First name must not exceed 50 characters'
		}
	];
	public validationSurnameMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'This field is mandatory'
		},
		{
			validationType: 'maxlength',
			validationMessage: 'Last name must not exceed 50 characters'
		}
	];
	public validationEmailMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'This field is mandatory'
		},
		{
			validationType: 'pattern',
			validationMessage: 'Invalid email'
		},
		{
			validationType: 'maxlength',
			validationMessage: 'Email must not exceed 70 characters'
		}
	];
	public validationPhoneMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'This field is mandatory'
		},
		{
			validationType: 'pattern',
			validationMessage: 'Please enter a valid phone number'
		},
		{
			validationType: 'maxlength',
			validationMessage: 'Phone number must not exceed 128 characters'
		}
	];
	public validationRoleMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'This field is mandatory'
		}
	];

	public validationPositionMessages: ValidatorMessages[] = [
		{
			validationType: 'maxlength',
			validationMessage: 'Position must not exceed 50 characters'
		}
	];
	private unsubscriber$ = new Subject<void>();

	constructor() {}

	public ngOnInit(): void {
		this.FormOnChanges();
		this.patchEditEmployeeForm();
	}

	public FormOnChanges(): void {
		this.user.firstName = this.form.controls['nameFormControl'].value;
		this.user.lastName = this.form.controls['surnameFormControl'].value;
		this.user.email = this.form.controls['emailFormControl'].value;
		this.user.phoneNumber = this.form.controls['phoneFormControl'].value;
		this.user.position = this.form.controls['positionFormControl'].value;
		this.form.valueChanges.pipe(takeUntil(this.unsubscriber$), skip(1)).subscribe(val => {
			if (this.activeStep) {
				this.formIsValid.emit(this.form.valid);
			}
		});
	}

	private patchEditEmployeeForm(): void {
		this.form.patchValue({
			nameFormControl: this.userData.firstName,
			surnameFormControl: this.userData.lastName,
			phoneFormControl: this.userData.phoneNumber,
			emailFormControl: this.userData.email,
			positionFormControl: this.userData.position
		});
	}
}
