import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';
import { INoRowsOverlayParams } from 'ag-grid-community';

@Component({
	selector: 'app-add-quick-set-overlay',
	templateUrl: './add-quick-set-overlay.component.html',
	styleUrls: ['./add-quick-set-overlay.component.scss']
})
export class AddQuickSetOverlayComponent implements INoRowsOverlayAngularComp {
	public selectedValue: string;
	private params: any;

	constructor() {}

	public agInit(params: any): void {
		this.params = params;
	}
	public addNewSet(): void {
		this.params.noRowsActionFunc();
	}
}
