import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';

@Component({
	selector: 'app-retry-payment-confirmation',
	templateUrl: './retry-payment-confirmation.component.html',
	styleUrls: ['./retry-payment-confirmation.component.scss']
})
export class RetryPaymentConfirmationComponent implements OnInit {
	public submitting: boolean;
	public biliingId: string;
	public contactId: string;
	public planDetails: any;
	public title: string;
	public subtitle: string;
	constructor(private billingService: PaymentServiceApi, public activeRoute: ActivatedRoute, private router: Router) {}

	ngOnInit(): void {
		this.getRouteParam();
	}

	public getRouteParam(): void {
		this.activeRoute.queryParams.pipe(take(1)).subscribe(params => {
			if (params['bexid'] != undefined) {
				this.title = 'Thank you your payment is successful!';
				this.subtitle = 'You have been sent an email confirmation for the payment.';
			} else if (params['b'] != undefined) {
				this.title = 'Thank you your payment is successful!';
				this.subtitle = 'You have been sent an email confirmation for the payment.';
				this.biliingId = params['b'];
				this.contactId = params['c'];
				this.getBillingDetails();
			}
		});
	}

	public navigateToLunch(): void {
		this.router.navigate(['/authentication/launch']);
	}

	public getBillingDetails(): void {
		this.billingService.getRetryBillingDetails(this.biliingId, this.contactId).subscribe(res => {
			this.planDetails = res;
		});
	}
}
