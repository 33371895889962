import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, skipWhile, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getCurrency, getGenders, getStepFiveDetails, getStepFourDetails } from '../../state/smart-create.reducer';
import {
	ClearAds,
	ClearCurrentCampaign,
	ClearCurrentStep,
	CreateOptimizationDraft,
	UpdateBudgetAllocation,
	UpdateBudgetToggle,
	UpdateCampaignCurrentStep,
	UpdateCampaignTemplate,
	UpdateCurrency,
	UpdateSplitByAgeRange,
	UpdateSplitByDevice,
	UpdateSplitByGender,
	UpdateSplitByInterests,
	UpdateSplitByLocation,
	UpdateSplitByPlacements
} from '../../state/smart-create.actions';
import { IQuickNavigation } from '@filed-com/filed-lib/lib/cards/models/quick-navigation.model';
import { QuickNavigationService } from '@filed-com/filed-lib';
import { ExpansionPanelHelper } from 'src/app/shared/expansion-panel/expansion-panel-helper.service';
import { OptimizationPanelsEnum } from './optimization-panels.enum';
import { CampaignStepsEnum } from '../../models/campaign-steps.enum';
import { FacebookInterestsView } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests-view';
import { FacebookLocations } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations';
import { CampaignTemplate } from '../../models/campaign-template';
import { CatalogNodeInterface } from '../../catalogs/catalog-node.interface';
import { Gender } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/gender';
import { AllocationPopUpBudgetInterface } from '../../components-pieces/budget-allocation-popup/allocation-pop-up-budget.interface';
import { CampaignSmartCreateBudgetSplitsService } from '../../services/campaign-smart-create-budget-splits.service';
import { BudgetAllocationInterface } from '../../models/budget-allocation.interface';
import { AgeRange } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/age-range';
import { AgeSplitInterface } from '../../models/age-split.interface';
import { BudgetLevel } from '../../components-pieces/optimization-budget-card/budget-level.model';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { AdAccountApiService } from '../../../_services/facebook-accounts/ad-account-api.service';
import { getCurrencySymbol } from '@angular/common';
import { getPublishStatus, SharedState } from '../../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';
import { Timer } from '../../../shared/models/time-const.model';
import { ParentTargetingTypeEnum } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/targeting-type.enum';
import { SubObjectives } from '../../models/sub-objectives';
import { HierarchyDatum } from './tree-ui/tree-ui.interface';
import { FacebookInterests } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';

@Component({
	selector: 'app-optimization',
	templateUrl: './optimization.component.html',
	styleUrls: ['./optimization.component.scss'],
	providers: [ExpansionPanelHelper]
})
export class OptimizationComponent implements OnInit {
	public navigations: IQuickNavigation[] = [];
	public readonly step = CampaignStepsEnum.Four;
	public selectedIndex: number;
	public campaignName: string;
	public adSetName: string;
	public currency: string;
	public label: string;
	public toggleFormGroup: FormGroup | AbstractControl;
	public objectives: CatalogNodeInterface[];
	public optimizationPanelsEnum = OptimizationPanelsEnum;
	public objectiveName: string;
	public campaignLastStepId: CampaignStepsEnum;
	public selectedInterests: FacebookInterestsView[] = [];
	public selectedInterestsAtStepTwo: FacebookInterestsView[] = [];
	public selectedLocations: FacebookLocations[] = [];
	public maximumAgeRange: number;
	public budgeAllocationSplits: BudgetAllocationInterface;
	public campaignControl: FormArray;
	public adSetsControl: FormArray;
	public ageRange: AgeRange;
	public genders: number[];
	public splitsByAge: AgeSplitInterface[];
	public numberOfSplitsByAge: number;
	public popUpData: AllocationPopUpBudgetInterface;
	public minBudget: number;

	public isCampaignHasInterest: boolean;
	public isSplitByLocation: boolean;
	public isSplitByDevices: boolean;
	public isSplitByPlacements: boolean;
	public isSplitByGender: boolean;
	public splitByInterests: Array<Array<FacebookInterests>> | boolean;
	public isSplitByAgeRange: number | boolean;
	public isBudgetSplitEvenly: boolean;
	public isPublishing: boolean;
	public specialAdCategorySelected: boolean;
	public selectedMultipleGenders: boolean;
	public canSplitByPlacements: boolean;
	public selectedMultipleLocations: boolean;
	public isOnCampaignLevelBudget: number | boolean;
	public isOnAdSetLevelBudget: number | boolean;
	private campaignId: number;
	private unsubscriber$ = new Subject<void>();
	public isProductCatalogue: boolean;
	public isRetargetingType: boolean;
	public isAppInstall: boolean;
	public treeData: HierarchyDatum;
	public hideDeviceSplit = true;

	constructor(
		private injector: Injector,
		public expansionPanelHelper: ExpansionPanelHelper<OptimizationPanelsEnum>,
		public router: Router,
		public dialog: MatDialog,
		private activeRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private store: Store<CampaignSmartCreateState>,
		private sharedStore: Store<SharedState>,
		private splitsService: CampaignSmartCreateBudgetSplitsService,
		private toastService: ToastNotificationService,
		private accountService: AdAccountApiService,
		private smartCreateService: CampaignSmartCreateService,
		private quickNavigationService: QuickNavigationService
	) {
		this.dialog = this.injector.get<MatDialog>(MatDialog);
	}

	public ngOnInit(): void {
		this.getActiveRouteData();
		this.getCurrency();
		this.checkGenders();
		this.getSplits();
		this.initFormGroup();
		this.initFormGroupListener();
		this.checkPublish();
		this.getNavigation();
		this.registerSelection();
	}

	public registerSelection(): void {
		this.quickNavigationService
			.getSelectedNavigation()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((selectedIndex: number) => (this.selectedIndex = selectedIndex));
	}

	public onPanelClick(targetId: string): void {
		for (let i = 0; i < this.navigations.length; i++) {
			if (this.navigations[i].targetId === targetId) {
				this.quickNavigationService.setSelectedNavigation(i);
			}
		}
	}

	public getNavigation(): void {
		if (!this.hideDeviceSplit) {
			if (this.selectedMultipleLocations) {
				this.navigations.push({ name: 'Split by Location', targetId: 'split-by-location' });
			}
			this.navigations.push({ name: 'Split by Devices', targetId: 'split-by-device' });
		}
		if (this.canSplitByPlacements) {
			this.navigations.push({ name: 'Split by Placements', targetId: 'split-by-placements' });
		}
		if (!this.specialAdCategorySelected && this.selectedMultipleGenders) {
			this.navigations.push({ name: 'Split by Gender', targetId: 'split-by-gender' });
		}
		if (!this.specialAdCategorySelected && this.selectedInterestsAtStepTwo?.length > 1) {
			this.navigations.push({ name: 'Split by Interests', targetId: 'split-by-interest' });
		}
		if (!this.specialAdCategorySelected) {
			this.navigations.push({ name: 'Split by Age Range', targetId: 'split-by-age' });
		}
		if (this.isAppInstall && this.isRetargetingType) {
			this.navigations = [];
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public getActiveRouteData(): void {
		this.activeRoute.data.subscribe((data: Data) => {
			this.campaignControl = new FormArray([]);
			this.adSetsControl = new FormArray([]);
			this.store.dispatch(new UpdateCampaignCurrentStep(this.step));
			this.objectives = data.store.catalogs.Objective;
			const selectedObjective = data?.store?.campaign?.stepOneDetailsAsJson?.objective;
			this.isProductCatalogue = selectedObjective === SubObjectives.ProductCatalogSales;
			if (data.store.campaign.stepOneDetailsAsJson.campaignBudgetOptimization?.amount > 0) {
				this.isOnCampaignLevelBudget = data.store.campaign.stepOneDetailsAsJson.campaignBudgetOptimization.amount;
				this.minBudget = data.store.campaign.stepOneDetailsAsJson.campaignBudgetOptimization.minimumBudget;
				this.label = BudgetLevel.Campaigns;
				this.popUpData = {
					initial: this.isOnCampaignLevelBudget as number,
					current: this.isOnCampaignLevelBudget as number,
					currency: this.currency,
					level: 'Campaign',
					step: 'Step 1'
				};
			} else {
				this.isOnCampaignLevelBudget = false;
			}
			const targeting = data.store.campaign?.stepTwoDetailsAsJson?.targeting;
			if (targeting?.targeting_type === ParentTargetingTypeEnum.ReTargeting) {
				this.isRetargetingType = true;
			}
			if (targeting?.targeting_type === ParentTargetingTypeEnum.AutomatedTargeting) {
				this.isAppInstall = true;
			}
			this.store.dispatch(new UpdateBudgetToggle(true));
			this.store.dispatch(new UpdateBudgetAllocation(null));
			if (targeting?.targeting_type === ParentTargetingTypeEnum.ReTargeting && targeting?.targeting_type === ParentTargetingTypeEnum.AutomatedTargeting) {
				this.genders = null;
			} else {
				const gender = targeting?.gender;
				if (gender === Gender.All) {
					this.genders = [Gender.Women, Gender.Men];
				} else {
					this.genders = [gender];
				}
			}

			if (data.store.campaign.stepFourDetailsAsJson?.isBudgetSplitEvenly) {
				this.isBudgetSplitEvenly = data.store?.campaign?.stepFourDetailsAsJson?.isBudgetSplitEvenly;
			}
			this.currency = data.store?.campaign?.currency;
			this.ageRange = data.store.campaign?.stepTwoDetailsAsJson?.targeting?.ageRange;
			this.adSetName = data.store.campaign?.stepTwoDetailsAsJson?.adSetName;
			this.objectiveName = data.store.campaign.stepOneDetailsAsJson.objectiveGroupTree?.subObjectiveDisplayName;
			this.specialAdCategorySelected = !!data.store.campaign?.stepOneDetailsAsJson?.specialAdCategory;
			this.campaignName = data.store.campaign?.name;
			this.campaignId = data.store.campaign?.id;
			this.campaignLastStepId = data.store.campaign?.lastStepId;
			this.checkDynamicComponents(data.store.campaign);
			this.checkFields(data.store.campaign);
		});
	}

	public initFormGroup(): void {
		this.toggleFormGroup = this.formBuilder.group({
			location: new FormControl(this.isSplitByLocation),
			placements: new FormControl(this.isSplitByPlacements),
			devices: new FormControl(this.isSplitByDevices),
			gender: new FormControl(this.isSplitByGender),
			interest: new FormControl(this.splitByInterests),
			ageRange: new FormControl(this.isSplitByAgeRange),
			budgetSplit: new FormControl(this.isBudgetSplitEvenly),
			slider: new FormControl(this.isSplitByAgeRange as number | 5)
		});
	}

	public getSplits(): void {
		this.store.pipe(select(getStepFourDetails), takeUntil(this.unsubscriber$)).subscribe(stepFour => {
			this.isSplitByDevices = stepFour?.isSplitByDevices;
			this.isSplitByGender = stepFour?.isSplitByGenderSelected;
			this.isSplitByLocation = stepFour?.isSplitByLocation;
			this.splitByInterests = stepFour?.isSplitByInterests;
			this.isSplitByAgeRange = stepFour?.isSplitAgeRangeSelected;
			this.isBudgetSplitEvenly = stepFour?.isBudgetSplitEvenly;
			this.isSplitByPlacements = stepFour?.isSplitByPlacements;
		});
	}

	public checkPublish(): void {
		this.sharedStore.pipe(select(getPublishStatus), takeUntil(this.unsubscriber$)).subscribe(status => {
			this.isPublishing = status?.isActive;
		});
	}

	public initFormGroupListener(): void {
		this.toggleFormGroup.valueChanges.pipe(takeUntil(this.unsubscriber$), debounceTime(Timer.halfSecond)).subscribe(change => {
			if ((change.budgetSplit?.adSetsBudget || change.budgetSplit?.campaignsBudget) && change.isBudgetSplitEvenly) {
				this.store.dispatch(new UpdateBudgetAllocation(null));
			}

			if (!change.location && !change.devices && !change.ageRange && !change.gender) {
				this.store.dispatch(new UpdateBudgetAllocation(null));
			}
		});
		this.toggleFormGroup
			.get('placements')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(status => {
				this.store.dispatch(new UpdateSplitByPlacements(status));
			});

		this.toggleFormGroup
			.get('gender')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(status => {
				this.store.dispatch(new UpdateSplitByGender(status));
			});

		this.toggleFormGroup
			.get('ageRange')
			.valueChanges.pipe(takeUntil(this.unsubscriber$), debounceTime(Timer.halfSecond))
			.subscribe(status => {
				this.store.dispatch(new UpdateSplitByAgeRange(status));
			});
		this.toggleFormGroup
			.get('slider')
			.valueChanges.pipe(takeUntil(this.unsubscriber$), debounceTime(500))
			.subscribe(slider => {});
	}

	public onAgeRangeSlider(numberOfSplits: number): void {
		this.numberOfSplitsByAge = numberOfSplits;
		if ((this.isSplitByAgeRange as number) > 0) {
			this.splitsByAge = this.getAgeSplits();
		} else {
			this.splitsByAge = null;
		}
	}

	public getAgeSplits(): AgeSplitInterface[] {
		const temp: AgeSplitInterface[] = [];
		const range = this.isSplitByAgeRange as number;
		for (let i = this.ageRange.minAge; i < this.ageRange.maxAge; i += range) {
			if (i + range < this.ageRange.maxAge) {
				const ageRange: AgeSplitInterface = { min: i, max: i + range };
				temp.push(ageRange);
			} else {
				const lastAgeRange: AgeSplitInterface = { min: i, max: this.ageRange.maxAge };
				temp.push(lastAgeRange);
			}
		}
		return temp;
	}

	public getCurrency(): void {
		this.store.pipe(select(getCurrency), take(1)).subscribe(AccCurrency => {
			if (!AccCurrency) {
				this.accountService.adAccountChanged$.pipe(takeUntil(this.unsubscriber$)).subscribe(resp => {
					this.currency = getCurrencySymbol(resp.currency, 'narrow');
					this.store.dispatch(new UpdateCurrency(this.currency));
				});
			} else {
				this.currency = AccCurrency;
			}
		});
	}

	public checkGenders(): void {
		this.store.pipe(select(getGenders), take(1)).subscribe(genders => {
			this.selectedMultipleGenders = genders === Gender.All;
		});
	}

	public onCancelClick(): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate(['/campaign/select-campaign']);
	}

	public onBackClick(): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate([`campaign/build-campaign/3/${this.campaignId}`], {
			queryParams: {
				lastUserStep: this.step
			}
		});
	}

	public onNextClick(): void {
		this.onPublishConfirmed();
	}

	public onPublishConfirmed(): void {
		this.store.dispatch(new CreateOptimizationDraft());
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.store
			.select(getStepFiveDetails)
			.pipe(
				skipWhile(stepFiveDetails => !stepFiveDetails || !stepFiveDetails?.draftStructureId),
				take(1)
			)
			.subscribe(stepFiveDetails => {
				if (stepFiveDetails) {
					this.router.navigate([`campaign/build-campaign/5/${this.campaignId}`], {
						queryParams: {
							lastUserStep: this.step
						}
					});
				} else {
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			});
	}

	public onNavClick(step: CampaignStepsEnum): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate([`campaign/build-campaign/${step}/${this.campaignId}`], {
			queryParams: {
				lastUserStep: this.step
			}
		});
	}

	public checkDynamicComponents(campaign: CampaignTemplate): void {
		if (campaign.stepTwoDetailsAsJson?.targeting?.locations?.length > 1) {
			this.selectedLocations = campaign.stepTwoDetailsAsJson?.targeting?.locations;
			this.selectedMultipleLocations = true;
		} else {
			this.selectedMultipleLocations = false;
			this.store.dispatch(new UpdateSplitByLocation(false));
		}
		if (campaign.stepTwoDetailsAsJson?.placements.length > 1) {
			this.canSplitByPlacements = true;
		} else {
			this.canSplitByPlacements = false;
			this.store.dispatch(new UpdateSplitByPlacements(false));
		}

		if (campaign.stepFourDetailsAsJson?.isSplitByPlacements) {
			this.isSplitByPlacements = campaign.stepFourDetailsAsJson?.isSplitByPlacements;
		} else {
			this.isSplitByPlacements = false;
		}
		if (campaign.stepFourDetailsAsJson?.isSplitByDevices) {
			this.isSplitByDevices = campaign.stepFourDetailsAsJson?.isSplitByDevices;
		} else {
			this.isSplitByDevices = false;
		}
		if (campaign.stepTwoDetailsAsJson.targeting.interests.length) {
			this.selectedInterestsAtStepTwo = campaign.stepTwoDetailsAsJson.targeting.interests;
		} else {
			this.store.dispatch(new UpdateSplitByInterests(false));
		}
	}

	public checkFields(data: CampaignTemplate): void {
		if (data.stepFourDetailsAsJson?.isSplitByLocation) {
			this.isSplitByLocation = data.stepFourDetailsAsJson?.isSplitByLocation;
		}
		if (!this.isSplitByLocation) {
			this.store.dispatch(new UpdateSplitByLocation(false));
		} else {
			this.store.dispatch(new UpdateSplitByLocation(true));
		}

		this.isSplitByPlacements = !!data.stepFourDetailsAsJson?.isSplitByPlacements;
		if (this.isSplitByPlacements && this.canSplitByPlacements) {
			this.store.dispatch(new UpdateSplitByPlacements(true));
		} else {
			this.store.dispatch(new UpdateSplitByPlacements(false));
		}
		this.isSplitByDevices = !!data.stepFourDetailsAsJson?.isSplitByDevices;
		if (!this.isSplitByDevices) {
			this.store.dispatch(new UpdateSplitByDevice(false));
		} else {
			this.store.dispatch(new UpdateSplitByDevice(true));
		}
		this.splitByInterests = data?.stepFourDetailsAsJson?.isSplitByInterests
			? data?.stepFourDetailsAsJson?.isSplitByInterests
			: ([] as Array<Array<FacebookInterests>>);
		if (!this.splitByInterests) {
			this.store.dispatch(new UpdateSplitByInterests(false));
		} else {
			this.store.dispatch(new UpdateSplitByInterests(this.splitByInterests));
		}
		this.isSplitByGender = !!data.stepFourDetailsAsJson?.isSplitByGenderSelected;
		if (!this.isSplitByGender || this.specialAdCategorySelected) {
			this.store.dispatch(new UpdateSplitByGender(false));
		} else {
			this.store.dispatch(new UpdateSplitByGender(true));
		}

		this.isSplitByAgeRange = data.stepFourDetailsAsJson?.isSplitAgeRangeSelected !== undefined ? data.stepFourDetailsAsJson.isSplitAgeRangeSelected : false;
		if (!this.isSplitByAgeRange || this.specialAdCategorySelected) {
			this.store.dispatch(new UpdateSplitByAgeRange(false));
		} else {
			this.store.dispatch(new UpdateSplitByAgeRange(this.isSplitByAgeRange));
		}

		this.maximumAgeRange = data.stepTwoDetailsAsJson.targeting?.ageRange?.maxAge - data.stepTwoDetailsAsJson.targeting?.ageRange?.minAge;
	}

	protected clearStore(): void {
		this.store.dispatch(new ClearAds());
		this.store.dispatch(new ClearCurrentCampaign());
	}
}
