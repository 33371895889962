<div class="metrics-wrapper" *ngIf="selectedDetails && showMetrics">
	<div class="metric-item" *ngFor="let viewsOutside of selectedDetails.columns; let i = index">
		<div class="drop-metric-menu" [matMenuTriggerFor]="actionList" *ngIf="masterDetails">
			<mat-icon aria-hidden="false" aria-label="arrow_drop_down icon">arrow_drop_down</mat-icon>
			<mat-menu #actionList="matMenu" class="custom_mat_menu" xPosition="before">
				<button mat-menu-item class="selected">{{ viewsOutside.headerName }}</button>
				<button
					mat-menu-item
					*ngFor="let views of masterDetails.columns"
					[class]="{ 'selected-hidden': views.field === viewsOutside.field || findMetrics(views.field) === true }"
					(click)="changeColumn(i, views, views.field)"
				>
					{{ views.headerName }}
				</button>
			</mat-menu>
		</div>
		<div class="metric-interaction" [class.metric-disabled]="viewsOutside.disabled" (click)="toggleMetricAxis(viewsOutside.field, viewsOutside.disabled)">
			<h3>
				{{ viewsOutside.headerName }}
				<span *ngIf="viewsOutside.description">
					<mat-icon aria-hidden="false" aria-label="info_outline icon">info_outline</mat-icon>
					<div class="desc-metric">{{ viewsOutside.description }}</div>
				</span>
			</h3>
			<div class="amount-metric">
				{{
					viewsOutside.values &&
					viewsOutside.values[viewsOutside.field] &&
					viewsOutside.columnType &&
					viewsOutside.columnType === columnTypeEnum.Currency
						? currency
						: ''
				}}
				{{
					viewsOutside.values && viewsOutside.values[viewsOutside.field]
						? (viewsOutside.values[viewsOutside.field].toFixed(viewsOutside.numberOfDecimals) | number: '')
						: '--'
				}}
				{{
					viewsOutside.values &&
					viewsOutside.values[viewsOutside.field] &&
					viewsOutside.columnType &&
					viewsOutside.columnType === columnTypeEnum.Percentage
						? '%'
						: ''
				}}
			</div>
			<div class="metric-bottom-info">
				<span class="metric-down" *ngIf="viewsOutside.values && viewsOutside.values.trend === trend.Negative">
					<mat-icon aria-hidden="false" aria-label="arrow_drop_down icon">arrow_drop_down</mat-icon>
					{{ viewsOutside.values.percentage_difference.toFixed(viewsOutside.numberOfDecimals) }}
				</span>
				<span class="metric-up" *ngIf="viewsOutside.values && viewsOutside.values.trend === trend.Positive">
					<mat-icon aria-hidden="false" aria-label="arrow_drop_up icon">arrow_drop_up</mat-icon>
					{{ viewsOutside.values.percentage_difference.toFixed(viewsOutside.numberOfDecimals) }}
				</span>
				<span class="metric-undefined" *ngIf="(viewsOutside.values && viewsOutside.values.trend === trend.Undefined) || !viewsOutside.values">
					--
				</span>
				vs. previous period
			</div>
		</div>
		<div class="loading" *ngIf="viewsOutside.loading">
			<lolly-spinning-loader></lolly-spinning-loader>
		</div>
	</div>
</div>
