<app-expansion-panel-sc
	[expansionTitle]="'Split by Interests'"
	[isExpandable]="false"
	[isSelected]="isSelected"
	[contentMargin]="true"
	[infoHoverModel]="infoHoverModel"
	[subTitle]="'Would you like to split your ad sets by interests?'"
	(click)="onExpansionClick.emit()"
>
	<div expansion-content>
		<mat-slide-toggle (change)="onInterestsToggle()" [checked]="isInterestsToggled"></mat-slide-toggle>
		<div *ngIf="isInterestsToggled">
			<p>How many groups would you like to split your interests by?</p>
			<ng5-slider [(value)]="numberOfInterestGroups" [options]="options" (valueChange)="onSliderChange()"></ng5-slider>
			<div class="lists-container" cdkDropListGroup>
				<div class="list-item" *ngFor="let interests of interestsName; let i = index">
					<div class="item-title">
						<strong>Interests {{ i + 1 }}</strong>
					</div>
					<div cdkDropList class="example-list" [cdkDropListData]="interests" (cdkDropListDropped)="drop($event)">
						<div class="example-box" *ngFor="let interest of interests" cdkDrag>{{ interest }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
