/* tslint:disable */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlatNode, SelectionActions } from '../../../_models/TableModels/selection-actions';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

/**
 * SingleSelectionActionsComponent
 */
@Component({
	selector: 'app-single-selection-actions',
	templateUrl: './single-selection-actions.component.html',
	styleUrls: ['./single-selection-actions.component.scss']
})
export class SingleSelectionActionsComponent {
	@Input() singleSelectionActions: SelectionActions[];
	@Input() selectedRow: any;

	@Output() actionEvent: EventEmitter<{ functionName: string; parameter: any }> = new EventEmitter();

	public treeControl = new FlatTreeControl<FlatNode>(
		node => node.level,
		node => node.expandable
	);
	public treeFlattener = new MatTreeFlattener(
		this.transformer,
		node => node.level,
		node => node.expandable,
		node => node.children
	);
	public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	constructor() {}

	ngOnInit() {
		this.dataSource.data = this.singleSelectionActions.filter((singleSelectionAction: SelectionActions) => {
			// conditionally displaying some buttons in the ACTIONS column
			return singleSelectionAction.displayForRowProperty == null || this.selectedRow[singleSelectionAction.displayForRowProperty];
		});
	}

	private transformer(node: SelectionActions, level: number): FlatNode {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			value: node.value,
			functionName: node.functionName,
			level: level,
			isDelete: node.isDelete
		};
	}

	public hasChild(_: number, node: FlatNode): boolean {
		return node.expandable;
	}

	public doSomething(functionName: string, parameter: any): void {
		this.actionEvent.emit({ functionName, parameter });
	}

	public displayForRowProperty(value: string): boolean {
		const displayForRowProperty = this.singleSelectionActions.find(x => x.value == value).displayForRowProperty;
		return displayForRowProperty == null;
	}

	private isUpperCase(str: string) {
		return /^[^a-z]*$/.test(str);
	}

	public appendKey(key: string) {
		if (!this.isUpperCase(key)) {
			return key;
		}
		return 'TABLE.SINGLE_SELECTION_ACTION.' + key;
	}
}
