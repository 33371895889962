import { Component, OnInit } from '@angular/core';
import { WelcomePageModel } from '../../../shared/welcome-page/welcome-page.model';
import { Router } from '@angular/router';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';
import { select, Store } from '@ngrx/store';
import { getSelectedAdAccount, SharedState } from '../../../shared/state/shared.reducer';
import { AAAFlow } from '../../../AAA/models/AAA-steps.enum';
import { LoadFacebookPixels } from 'src/app/state/campaign-common-state/campaign-common-actions';
import { Observable, Subject } from 'rxjs';

@Component({
	selector: 'app-campaign-welcome-page',
	templateUrl: './campaign-welcome-page.component.html',
	styleUrls: ['./campaign-welcome-page.component.scss']
})
export class CampaignWelcomePageComponent implements OnInit {
	public welcomePageModel = new WelcomePageModel();
	public stepAd = false;
	public itemsTitle: string;
	public selectedCreateMethod: string;
	public activeStep: string;
	public unsubscriber$ = new Subject<void>();
	public useraccountSelectionListener$: Observable<any>;

	constructor(private router: Router, private smartCreateService: CampaignSmartCreateService, private store: Store<SharedState>) {}

	public ngOnInit(): void {
		this.welcomePageModel.img.left = '/assets/icons/welcome-pages/Reporting-LeftCard.svg';
		this.welcomePageModel.text.left = 'Build campaigns faster using Smart Creation';
		this.welcomePageModel.showRightCard = false;
		this.welcomePageModel.buttonText.left = 'SMART CREATE';
		this.welcomePageModel.title = 'Build your Campaigns';
		this.welcomePageModel.subtitle = 'See your campaigns performance.';
		this.useraccountSelectionListener$ = this.store.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$));
		this.defaultPage();
		this.updateAccountPixels();
	}

	public onSmartCreateClick(): void {
		this.store
			.pipe(
				select(getSelectedAdAccount),
				skipWhile(account => !account),
				take(1)
			)
			.subscribe(account => {
				// this.smartCreateService.getCampaignTemplatesList(account.adAccount.id).subscribe(templates => {
				// 	if (templates.length) {
				// 		this.router.navigate(['/campaign/select-campaign']);
				// 	} else {
				// 		this.router.navigate([`campaign/build-campaign/1`]);
				// 	}
				// });
			});
	}

	public nextStep(level: string): void {
		this.stepAd = true;
		this.activeStep = level;
		const levelText = level === 'ad' ? 'Ads' : 'Ad Sets';
		this.itemsTitle = `How would you like to create your ${levelText}?`;
	}

	public defaultPage(): void {
		this.stepAd = false;
		this.selectedCreateMethod = null;
		this.activeStep = null;
		this.itemsTitle = 'Hello, what would you like to do today?';
	}

	public activateCard(method: string): void {
		this.selectedCreateMethod = method;
	}
	public createNew(type: string): void {
		switch (type) {
			case 'campaign':
				this.router.navigate(['/campaign/select-campaign']);
				break;
			case 'ad':
				this.router.navigate(['/AAA/add-ad'], { queryParams: { flow: AAAFlow.createNewAd } });
				break;
			case 'adSet':
				this.router.navigate(['/AAA/add-ad'], { queryParams: { flow: AAAFlow.createNewAdset } });
		}
	}
	public createWithBestPerforming(type: string): void {
		switch (type) {
			case 'campaign':
				this.router.navigate(['/campaign/welcome']);
				break;
			case 'ad':
				this.router.navigate(['/AAA/add-ad'], { queryParams: { flow: AAAFlow.bestPerformingAd } });
				break;
			case 'adSet':
				this.router.navigate(['/AAA/add-ad'], { queryParams: { flow: AAAFlow.bestPerformingAdset } });
		}
	}

	public startCreating(type: string, method: string): void {
		if (method === 'performing') {
			this.createWithBestPerforming(type);
		} else {
			this.createNew(type);
		}
	}

	public updateAccountPixels(): void {
		this.useraccountSelectionListener$.pipe(takeUntil(this.unsubscriber$)).subscribe(account => {
			if (account) {
				this.store.dispatch(new LoadFacebookPixels());
			}
		});
	}
}
