import { CampaignBudgetOptimizationInterface } from './campaign-budget-optimization.interface';
import { ObjectiveTreeInterface } from './objective-tree.interface';
import { DateRangeX } from '../../shared/calendar/calendar.models';
import { CampaignDateChoices } from '../../shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { AppStoreTypeEnum } from './app-store-type.enum';

export class StepOneDetails {
	public name: string;
	public specialAdCategory: string;
	public objective?: string;
	public destinationType?: string;
	public objectiveGroupTree?: ObjectiveTreeInterface;
	public campaignBudgetOptimization?: CampaignBudgetOptimizationInterface;
	public date?: DateRangeX<string>;
	public dateChoice?: CampaignDateChoices;
	public productCatalogId?: string;
	public appStoreType?: AppStoreTypeEnum;
}
