<div *ngIf="inputDetailModel" class="welcome-page-wrapper">
	<div class="main-section" [ngClass]="{ 'single-card-width': !(inputDetailModel.showRightCard && inputDetailModel.showLeftCard) }">
		<!-- Title-->
		<div class="title-main-section">
			<span [innerHTML]="inputDetailModel.title" class="welcome-title"></span>
			<span [innerHTML]="inputDetailModel.subtitle" class="welcome-subtitle"></span>
		</div>

		<!--Welcome Cards-->
		<div class="box-cards-welcome">
			<div
				*ngIf="inputDetailModel.showLeftCard"
				class="left-welcome-section single-card-width"
				[ngClass]="!(inputDetailModel.showRightCard && inputDetailModel.showLeftCard) ? 'no-margin-left-card' : 'with-margin-left'"
			>
				<div class="center-wrapper">
					<div class="title-section">
						<img [src]="inputDetailModel.img.left" />
						<span>
							{{ inputDetailModel.text.left }}
							<span *ngIf="inputDetailModel.linkExtraInfo">
								<a *ngIf="!inputDetailModel.linkExtraInfo.href == ''" href="{{ inputDetailModel.linkExtraInfo.href }}" target="_blank">
									{{ inputDetailModel.linkExtraInfo.text }}
								</a>
								<p *ngIf="inputDetailModel.linkExtraInfo.href == ''">{{ inputDetailModel.linkExtraInfo.text }}</p>
							</span>
						</span>
					</div>
					<div class="buttons-section" *ngIf="inputDetailModel.showButtons">
						<btn01 [disabled]="inputDetailModel.buttonDisabled" (onClick)="leftButtonPressedHandler()">
							{{ inputDetailModel.buttonText.left | uppercase }}
						</btn01>
					</div>
				</div>
			</div>

			<div *ngIf="inputDetailModel.showRightCard" class="right-welcome-section single-card-width">
				<div class="center-wrapper">
					<div class="title-section">
						<img [src]="inputDetailModel.img.right" />
						<span>
							{{ inputDetailModel.text.right }}
							<span *ngIf="inputDetailModel.linkExtraInfo">
								<a *ngIf="!inputDetailModel.linkExtraInfo.href == ''" href="{{ inputDetailModel.linkExtraInfo.href }}" target="_blank">
									{{ inputDetailModel.linkExtraInfo.text }}
								</a>
								<p *ngIf="inputDetailModel.linkExtraInfo.href == ''">{{ inputDetailModel.linkExtraInfo.text }}</p>
							</span>
						</span>
					</div>
					<div class="buttons-section" *ngIf="inputDetailModel.showButtons">
						<btn01 (onClick)="rightButtonPressedHandler()" class="welcome-btn-save">{{ inputDetailModel.buttonText.right | uppercase }}</btn01>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
