import { TreeViewInterface } from '@filed-com/filed-lib/lib/tree-view/models/tw-interface';
import { Action } from '@ngrx/store';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { CampaignBudgetOptimizationInterface } from 'src/app/campaign-smart-create/models/campaign-budget-optimization.interface';
import { OptimizationAndDeliveryInterface } from 'src/app/campaign-smart-create/models/optimization-and-delivery.interface';
import { TargetingAudience } from 'src/app/campaign-smart-create/models/targeting-audience';
import { DateRangeX } from 'src/app/shared/calendar/calendar.models';
import { CampaignDateChoices } from 'src/app/shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { DeviceEnum, DeviceOSEnum, IDeviceOs } from '../../components/devices/devices.component';
import { AdSetModel } from '../../models/adset.model';

export enum AdsetActionTypes {
	UpdateAdSetName = '[Adset] Update Ad Set Name',
	ResetState = '[Adset] Reset State',
	UpdatePages = '[Adset] Update Pages',
	UpdateInstagramId = '[Adset] Update Instagram Id',
	UpdateTargeting = '[Adset] Update Targeting',
	UpdateCampaignActiveDate = '[Adset] Update Campaign Active Date',
	UpdateCampaignDate = '[Adset] Update Campaign Date',
	UpdatePlacements = '[Adset] Update Placements',
	UpdateAdSetBudgetOptimization = '[Adset] Update AdSet Budget Optimization',
	UpdateOptimizationAndDelivery = '[Adset] Update Optimization & Delivery',
	UpdateAdsetState = '[Adset] Update Adset State',
	UpdatePlacementsPerPlatform = '[Adset] Update Placement Per platform State',
	UpdateDevice = '[Adset] Update Device',
	UpdateDeviceOs = '[Adset] Update Device OS'
}

export class UpdateAdSetBudgetOptimization implements Action {
	public readonly type = AdsetActionTypes.UpdateAdSetBudgetOptimization;

	constructor(public payload: CampaignBudgetOptimizationInterface) {}
}

export class ResetState implements Action {
	public readonly type = AdsetActionTypes.ResetState;

	constructor() {}
}

export class UpdateAdsetState implements Action {
	public readonly type = AdsetActionTypes.UpdateAdsetState;

	constructor(public payload: AdSetModel) {}
}

export class UpdateOptimizationAndDelivery implements Action {
	public readonly type = AdsetActionTypes.UpdateOptimizationAndDelivery;

	constructor(public payload: OptimizationAndDeliveryInterface) {}
}

export class UpdateAdSetName implements Action {
	public readonly type = AdsetActionTypes.UpdateAdSetName;

	constructor(public payload: string) {}
}

export class UpdatePages implements Action {
	public readonly type = AdsetActionTypes.UpdatePages;

	constructor(public payload: { facebookPageId: string; instagramPageId: string }) {}
}

export class UpdateInstagramId implements Action {
	public readonly type = AdsetActionTypes.UpdateInstagramId;
	constructor(public payload: string) {}
}

export class UpdateTargeting implements Action {
	public readonly type = AdsetActionTypes.UpdateTargeting;

	constructor(public payload: TargetingAudience) {}
}

export class UpdateCampaignActiveDate implements Action {
	public readonly type = AdsetActionTypes.UpdateCampaignActiveDate;

	constructor(public payload: { date: DateRangeX<string>; dateChoiceType: CampaignDateChoices }) {}
}

export class UpdateCampaignDate implements Action {
	public readonly type = AdsetActionTypes.UpdateCampaignDate;

	constructor(public payload: { date: DateRangeX<string>; dateChoiceType: CampaignDateChoices }) {}
}

export class UpdatePlacements implements Action {
	public readonly type = AdsetActionTypes.UpdatePlacements;

	constructor(public payload: CatalogNodeInterface[]) {}
}

export class UpdatePlacementsPerPlatform implements Action {
	public readonly type = AdsetActionTypes.UpdatePlacementsPerPlatform;

	constructor(public payload: TreeViewInterface[]) {}
}

export class UpdateDevice implements Action {
	public readonly type = AdsetActionTypes.UpdateDevice;

	constructor(public payload: DeviceEnum[]) {}
}

export class UpdateDeviceOs implements Action {
	public readonly type = AdsetActionTypes.UpdateDeviceOs;

	constructor(public payload: DeviceOSEnum[]) {}
}

export type AdsetActions =
	| ResetState
	| UpdateAdsetState
	| UpdateAdSetName
	| UpdatePages
	| UpdateDevice
	| UpdateDeviceOs
	| UpdateInstagramId
	| UpdateTargeting
	| UpdateCampaignActiveDate
	| UpdateCampaignDate
	| UpdatePlacements
	| UpdatePlacementsPerPlatform
	| UpdateAdSetBudgetOptimization
	| UpdateOptimizationAndDelivery;
