import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getTemplates, CampaignSmartCreateState } from '../state/smart-create.reducer';
import { getSelectedAdAccount, SharedState } from '../../shared/state/shared.reducer';
import { map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { LoadCampaignList } from '../state/smart-create.actions';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { MapperService } from '../../shared/services/mapper.service';
import { DropdownData } from '../../shared/dropdown-search-select/dropdown-data.interface';
import { SelectedAdAccountModel } from '../../shared/state/state-entities/cached-ad-accounts.model';
import { CampaignTemplateListItem } from '../models/campaign-template-list-item';

@Injectable()
export class SelectCampaignResolver implements Resolve<Observable<{ templates: DropdownData<CampaignTemplateListItem>[]; account: SelectedAdAccountModel }>> {
	constructor(private mapperService: MapperService, private smartCreateStore: Store<CampaignSmartCreateState>, private sharedStore: Store<SharedState>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.sharedStore
			.pipe(
				select(getSelectedAdAccount),
				skipWhile(account => !account),
				take(1)
			)
			.subscribe(account => {
				this.smartCreateStore.dispatch(new LoadCampaignList(account.adAccount.id));
			});
		return this.sharedStore.pipe(
			select(getSelectedAdAccount),
			skipWhile(account => !account),
			take(1),
			switchMap(account => {
				return this.smartCreateStore.pipe(
					select(getTemplates, { accountId: account.adAccount.id }),
					skipWhile(templates => !templates),
					take(1),
					tap(templates => this.sharedStore.dispatch(new HideGlobalSpinner())),
					map(templates => {
						return {
							templates: this.mapperService.convertToDropdownData(templates, 'id', 'name'),
							account: account
						};
					})
				);
			})
		);
	}
}
