import _ from 'lodash';
import { AuthenticationActionType, RegistrationDetail } from '../../state/authentication.action';
import { AuthenticationState } from '../../state/authentication.reducer';
import { UserDetailModel } from '../../_models/login.model';
import { UserRoleAdminGuard } from '../../../shared/guards/user-role-admin-guard.service';

export const updateRegEmail = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.email = payload;
	return registrationDetail;
};

export const updateRegPassword = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.password = payload;
	return registrationDetail;
};

export const updateRegPlan = (state: AuthenticationState, payload: number): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.plan = payload;
	return registrationDetail;
};

export const updateRegDuration = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.duration = payload;
	return registrationDetail;
};

export const updateRegName = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.name = payload;
	return registrationDetail;
};

export const updateRegLastName = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.lastname = payload;
	return registrationDetail;
};

export const updateCompanyName = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.companyName = payload;
	return registrationDetail;
};

export const updateRegPhoneNumber = (state: AuthenticationState, payload: number): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.phone = payload;
	return registrationDetail;
};

export const updateRegDialcode = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.dialCode = payload;
	return registrationDetail;
};

export const updateRegToken = (state: AuthenticationState, payload: string): UserDetailModel => {
	const registrationDetail = _.cloneDeep(state.userRegistrationDetail);
	const currentDetail = registrationDetail;
	currentDetail.token = payload;
	return registrationDetail;
};
export const sleep = (ms: number): Promise<any> => {
	return new Promise(resolve => setTimeout(resolve, ms));
};
