import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { StorageKey } from '../../_models/local-storage-key';

/*
  created for matSnackBar
*/
@Component({
	selector: 'app-ad-blocker',
	template: `
		<div>
			<div>{{ message }}</div>
			<div style="display: flex; align-items: center;">
				<mat-checkbox [(ngModel)]="adBlockerFlag">Don't show this anymore</mat-checkbox>
				<button mat-button style="margin-left: auto;" (click)="closeSnackBar($event)">OK</button>
			</div>
		</div>
	`
})
export class AdBlockerComponent {
	public adBlockerFlag: boolean;
	public hideSnackBarMenu: boolean;

	constructor(@Inject(MAT_SNACK_BAR_DATA) public message: string, private _snackRef: MatSnackBarRef<AdBlockerComponent>) {
		this.hideSnackBarMenu = localStorage.getItem(StorageKey.hideAdBlockerNotification) === 'true';

		this.hideSnackBarMenu && setTimeout(() => this._snackRef.dismiss());
	}

	public closeSnackBar(event: any): void {
		Promise.resolve().then(() => {
			localStorage.setItem(StorageKey.hideAdBlockerNotification, JSON.stringify(this.adBlockerFlag));
		});

		this._snackRef.dismiss();

		event.stopPropagation();
	}
}
