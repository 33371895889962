export class StripeCreditCard {
	number: string;
	exp_month: string;
	exp_year: string;
	cvc: string;
	name: string;
	address_line1: string;
	address_line2: string;
	address_city: string;
	address_zip: boolean;
	address_country?: string;
	address_state?: string;
}
