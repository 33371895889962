import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { HttpStatusCode } from '../../models/http-response-codes.enum';
import { GeneralSettingsApi } from '../../services/general-settings/general-settings.api.service';
import { parseSettings } from '../../services/general-settings/helpers/settings-parser.util';
import { AdsManagerPreferences } from '../../services/general-settings/models/ads-manager-preferences.model';
import { GeneralSettingsResponse } from '../../services/general-settings/models/api/general-settings-response.model';
import { CampaignBuilderPreferences } from '../../services/general-settings/models/campaign-builder-preferences.model';
import { ServiceNamesEnum } from '../../services/general-settings/models/service-names.enum';
import { SharedPreferences } from '../../services/general-settings/models/shared-preferences/shared-preferences.model';
import { ToastNotificationService } from '../../toast-notification/toast-notification.service';
import {
	LoadAdsManagerSettingsFailure,
	LoadAdsManagerSettings,
	LoadAdsManagerSettingsSuccess,
	LoadCampaignBuilderSettings,
	LoadCampaignBuilderSettingsSuccess,
	LoadSharedSettingsFailure,
	LoadSharedSettings,
	LoadSharedSettingsSuccess,
	SharedActionTypes,
	UpdateAdsManagerSettings,
	UpdateAdsManagerSettingsSuccess,
	UpdateCampaignBuilderSettings,
	UpdateCampaignBuilderSettingsSuccess,
	UpdateSharedSettings,
	UpdateSharedSettingsSuccess,
	LoadCampaignBuilderSettingsFailure,
	UpdateSharedSettingsFailure,
	UpdateAdsManagerSettingsFailure,
	UpdateCampaignBuilderSettingsFailure
} from '../shared.actions';
import { isAdsManagerGeneralSettingsLoaded, isCampaignBuilderGeneralSettingsLoaded, isSharedGeneralSettingsLoaded, SharedState } from '../shared.reducer';
import { initialState } from '../state-entities/general-settings/general-settings-slice.model';
import { AppStateSlices } from '../../../state/app.state';
import { GeneralSettingsEffectsEnum } from './effects.enum';
import { ErrorsLoggingService } from '../../../_services/errors-logging/errors-logging.service';
import { EmitterVisitorContext } from '@angular/compiler';

@Injectable()
export class GeneralSettingsEffects {
	constructor(
		private actions$: Actions,
		private generalSettings: GeneralSettingsApi,
		private store: Store<SharedState>,
		private notifications: ToastNotificationService,
		private errorsLoggingService: ErrorsLoggingService
	) {}

	loadSharedSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadSharedSettings>(SharedActionTypes.LoadSharedSettings),
			withLatestFrom(this.store.pipe(select(isSharedGeneralSettingsLoaded))),
			filter(([_, isStateLoaded]: [LoadSharedSettings, boolean]) => !isStateLoaded),
			mergeMap(([action, _]: [LoadSharedSettings, boolean]) =>
				of([]).pipe(
					catchError(error => {
						this.errorsLoggingService.logEffectError(AppStateSlices.Shared, GeneralSettingsEffectsEnum.LoadSharedSettings, [action, _], error);
						if (error.status === HttpStatusCode.NOT_FOUND) {
							return of(
								new UpdateSharedSettings({
									userId: action.payload,
									details: JSON.stringify(initialState),
									module: ServiceNamesEnum.AdsManager
								})
							);
						} else {
							this.notifications.sendErrorToast('There was a problem retrieving your settings. Please try again later');
							new LoadSharedSettingsFailure(this.errorsLoggingService.getCodeFromHttpError(error));
							return EMPTY;
						}
					}),
					map((result: any) => {
						return new LoadSharedSettingsSuccess(parseSettings<SharedPreferences>(result));
					})
				)
			)
		)
	);

	loadAdsManagerSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadAdsManagerSettings>(SharedActionTypes.LoadAdsManagerSettings),
			withLatestFrom(this.store.pipe(select(isAdsManagerGeneralSettingsLoaded))),
			filter(([_, isStateLoaded]: [LoadAdsManagerSettings, boolean]) => !isStateLoaded),
			mergeMap(([action, _]: [LoadAdsManagerSettings, boolean]) =>
				of([]).pipe(
					catchError(error => {
						this.errorsLoggingService.logEffectError(AppStateSlices.Shared, GeneralSettingsEffectsEnum.LoadAdsManagerSettings, [action, _], error);
						new LoadAdsManagerSettingsFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						return of({ details: null });
					}),
					map((result: any) => new LoadAdsManagerSettingsSuccess(parseSettings<AdsManagerPreferences>(result)))
				)
			)
		)
	);

	loadCampaignBuilderSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadCampaignBuilderSettings>(SharedActionTypes.LoadCampaignBuilderSettings),
			withLatestFrom(this.store.pipe(select(isCampaignBuilderGeneralSettingsLoaded))),
			filter(([_, isStateLoaded]: [LoadCampaignBuilderSettings, boolean]) => !isStateLoaded),
			mergeMap(([action, _]: [LoadCampaignBuilderSettings, boolean]) =>
				of([]).pipe(
					catchError(error => {
						this.errorsLoggingService.logEffectError(
							AppStateSlices.Shared,
							GeneralSettingsEffectsEnum.LoadCampaignBuilderSettings,
							[action, _],
							error
						);
						new LoadCampaignBuilderSettingsFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						return of({ details: null });
					}),
					map((result: any) => new LoadCampaignBuilderSettingsSuccess(parseSettings<CampaignBuilderPreferences>(result)))
				)
			)
		)
	);

	UpdateSharedSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType<UpdateSharedSettings>(SharedActionTypes.UpdateSharedSettings),
			mergeMap((request: UpdateSharedSettings) =>
				of([]).pipe(
					catchError(error => {
						this.errorsLoggingService.logEffectError(AppStateSlices.Shared, GeneralSettingsEffectsEnum.UpdateSharedSettings, [request], error);
						new UpdateSharedSettingsFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						return EMPTY;
					}),
					map(() => new UpdateSharedSettingsSuccess(parseSettings<SharedPreferences>(request.payload)))
				)
			)
		)
	);

	UpdateAdsManagerSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType<UpdateAdsManagerSettings>(SharedActionTypes.UpdateAdsManagerSettings),
			mergeMap((request: UpdateAdsManagerSettings) =>
				of([]).pipe(
					catchError(error => {
						this.errorsLoggingService.logEffectError(AppStateSlices.Shared, GeneralSettingsEffectsEnum.UpdateAdsManagerSettings, [request], error);
						new UpdateAdsManagerSettingsFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						return EMPTY;
					}),
					map(() => new UpdateAdsManagerSettingsSuccess(parseSettings<AdsManagerPreferences>(request.payload)))
				)
			)
		)
	);

	UpdateCampaignBuilderSettings$ = createEffect(() =>
		this.actions$.pipe(
			ofType<UpdateCampaignBuilderSettings>(SharedActionTypes.UpdateCampaignBuilderSettings),
			mergeMap((request: UpdateCampaignBuilderSettings) =>
				of([]).pipe(
					catchError(error => {
						this.errorsLoggingService.logEffectError(
							AppStateSlices.Shared,
							GeneralSettingsEffectsEnum.UpdateCampaignBuilderSettings,
							[request],
							error
						);
						new UpdateCampaignBuilderSettingsFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						return EMPTY;
					}),
					map(() => new UpdateCampaignBuilderSettingsSuccess(parseSettings<CampaignBuilderPreferences>(request.payload)))
				)
			)
		)
	);
}
