export enum ChartTypeEnum {
	Custom = 'Other',
	SingleNumber = 'SingleNumber',
	Table = 'Table',
	Pie = 'Pie',
	Line = 'line',
	Area = 'Area',
	Bar = 'bar',
	HorizontalBar = 'HorizontalBar',
	GroupedHorizontalBar = 'GroupedHorizontalBar',
	GroupedVerticalBar = 'GroupedVerticalBar',
	HorizontalStackedBar = 'HorizontalStackedBar',
	VerticalStackedBar = 'VerticalStackedBar',
	Donut = 'Donut',
	Heat = 'Heat'
}
