import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getInvoiceHistory, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { BillingImagesEnum } from 'src/app/user-management/shared/billing-and-payment/billing-tab.enum';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import moment from 'moment';
import { getCurrencySymbol } from '@angular/common';
import { PopupDialogService } from '../../../_services/popup-dialog.service';
import { MatSelect } from '@angular/material/select';

@Component({
	selector: 'invoice-view-all-popup',
	templateUrl: './invoice-view-all-popup.component.html',
	styleUrls: ['./invoice-view-all-popup.component.scss']
})
export class InvoiceViewAllPopupComponent implements OnInit {
	@Input() public title: string;
	public displayedColumns = ['id', 'date', 'total', 'status', 'actions'];
	public sortByProperties = [
		{ key: 'all', value: 'Show All' },
		{ key: moment().format('YYYY'), value: 'This Year' },
		{ key: moment().subtract(1, 'years').format('YYYY'), value: 'Last Year' }
	];
	public imagesPathEnum = BillingImagesEnum;
	public sortPlaceholder = 'Sort by';
	public selectedPeriod: string;
	public dataSource: InvoiceHistory[];

	public filteredData: InvoiceHistory[];
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<InvoiceViewAllPopupComponent>,
		private store: Store<UserManagementState>,
		private popupDialog: PopupDialogService
	) {}

	public ngOnInit(): void {
		this.refresh();
		this.title = this.data.title;

		this.sortPlaceholder = 'Sort By';
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private refresh(): void {
		this.store.pipe(select(getInvoiceHistory), takeUntil(this.unsubscriber$)).subscribe(response => {
			this.dataSource = response;
			this.filteredData = this.dataSource;
		});
	}

	public onNoClick(): void {
		this.dialogRef.close();
	}

	public convertCurrency(currency: string): string {
		return getCurrencySymbol(currency, 'wide');
	}

	public selectOpen(): void {
		this.sortPlaceholder = '';
	}

	public selectClose(sel: MatSelect): void {
		this.sortPlaceholder = sel.value === undefined ? 'Sort By' : '';
	}

	public sortByPeriod = (selectedOption: string) => {
		// this.sortPlaceholder = selectedOption ? '' : 'Sort By';
		this.filteredData = this.dataSource;
		if (selectedOption !== 'all') {
			this.filteredData = this.dataSource.filter(item => {
				const [year] = item.date.split('-');
				return selectedOption === year;
			});
		}
	};

	public viewInvoiceDetails(id: string): void {
		this.popupDialog.showInvoice(id);
	}

	public payNow(element: InvoiceHistory): void {
		this.popupDialog.payNow(element);
		this.refresh();
	}
	public download(id: string): void {
		this.popupDialog.download(id);
	}
	public shareInvoice(element: InvoiceHistory): void {
		this.popupDialog.shareInvoice(element);
	}
}
