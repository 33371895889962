<app-user-management-header></app-user-management-header>

<div class="center-wrapper" *ngIf="!this.firstCard && appInit">
	<div class="header-wrapper">
		<div>
			<app-user-sub-header [pageTitle]="SettingsPageLabelsEnum.BillingAndPayment"></app-user-sub-header>
		</div>
	</div>

	<div class="card billing-cards">
		<div class="billing-section">
			<app-billing-and-payment [billingAddressDetails]="billingAddress"></app-billing-and-payment>
		</div>
		<div class="invoice-section">
			<app-billing-invoices [invoiceHistoryDetails]="invoiceHistory"></app-billing-invoices>
		</div>
		<mat-card class="u-h-100 payment-section">
			<mat-card-content>
				<app-card-table [billingAddressDetails]="billingAddress"></app-card-table>
			</mat-card-content>
		</mat-card>
	</div>
</div>
<div class="center-wrapper outside-no-card" *ngIf="this.firstCard && appInit">
	<div class="header-wrapper">
		<div>
			<app-user-sub-header [pageTitle]="'Billing & Payment'"></app-user-sub-header>
		</div>
	</div>
	<p class="outside-no-card-note">Add a Payment method to your account.</p>
	<div class="inside-no-card">
		<img src="assets/images/no-card.svg" />
		<p>You don’t have any payment method. Please update your payment and billing details.</p>
		<div class="add-payment-btn">
			<btn01 (onClick)="addFirstPaymentPopup()">Add Payment</btn01>
		</div>
	</div>
</div>
