export enum DatatableEnum {
	select = 'select',
	button = 'button',
	checkbox = 'checkbox',
	radio = 'radio',
	text = 'text',
	none = '',
	alert = 'status'
}

export interface DTUser {
	user: string;
	roles: string;
}

export interface Otp {
	secret: boolean;
	count: number;
}
export interface ModalInterface {
	title: string;
	subtitle?: string;
	text: string;
	closeBtn: boolean;
	actionBtn: boolean;
	actionBtnText?: string;
	useOtp?: boolean;
	otp?: Otp;
}
