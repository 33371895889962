import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { googleUnitAcount, googleManagerAcounts, googleUsersAccount } from '../../auth-signup';

@Component({
	selector: 'app-google-list',
	templateUrl: './google-list.component.html',
	styleUrls: ['./google-list.component.scss']
})
export class GoogleListComponent implements OnInit {
	public groupSelectId: any[] = [];
	@Input() googleAdsData: googleUsersAccount;
	@Output() connectAccount = new EventEmitter<any>();
	public adsAccountGoogle: googleManagerAcounts[] = [];
	public selectedGooglrAds: googleUnitAcount[] = [];
	constructor() {}

	ngOnInit(): void {
		this.adsAccountGoogle = this.googleAdsData.accounts;
	}
	public selectAll(googleAdsData: any[]): void {
		for (let i: number = 0; i < googleAdsData.length; i++) {
			let el = document.getElementById(googleAdsData[i].accountId) as HTMLInputElement;
			if (el.checked === false) {
				el.checked = true;
				this.selectGoogleAdsQue(googleAdsData[i]);
			}
		}
	}
	public selectGoogleAdsQue(selectedGoogleAdsQue: googleUnitAcount): void {
		let checkBox = document.getElementById(selectedGoogleAdsQue.accountId) as HTMLInputElement;
		if (checkBox?.checked === true) {
			selectedGoogleAdsQue.tracked = true;
			this.selectedGooglrAds.push(selectedGoogleAdsQue);
		} else {
			this.removeSelectedGoogleAdsQue(selectedGoogleAdsQue.accountId);
		}
	}
	public selectAndDEselectAllGroups(allDataAds: googleManagerAcounts): void {
		let checkBox = document.getElementById(allDataAds.managerId) as HTMLInputElement;
		if (checkBox?.checked === true) {
			this.selectAll(allDataAds.adaccounts);
		} else {
			this.deSelectAll(allDataAds.adaccounts);
		}
	}

	public removeSelectedGoogleAdsQue(id: string): void {
		for (let i: number = 0; i < this.selectedGooglrAds.length; i++) {
			if (this.selectedGooglrAds[i].accountId == id) {
				this.selectedGooglrAds[i].tracked = false;
				let checkBox = document.getElementById(this.selectedGooglrAds[i].accountId) as HTMLInputElement;
				checkBox.checked = false;
				this.selectedGooglrAds.splice(i, 1);
				return;
			}
		}
	}

	public deSelectAll(googleAdsData: googleUnitAcount[]): void {
		for (let i: number = 0; i < googleAdsData.length; i++) {
			let el = document.getElementById(googleAdsData[i].accountId) as HTMLInputElement;
			if (el.checked === true) {
				el.checked = false;
				this.removeSelectedGoogleAdsQue(googleAdsData[i].accountId);
			}
		}
	}

	public connectGoogleAccount(): void {
		let toPostTrackedData: googleManagerAcounts[] = this.adsAccountGoogle;
		toPostTrackedData.forEach(item => {
			let seletedAdsAcc: googleUnitAcount[] = [];
			item.adaccounts.forEach(item2 => {
				if (item2.tracked === true) {
					seletedAdsAcc.push(item2);
				}
			});
			item.adaccounts = [];
			item.adaccounts = seletedAdsAcc;
		});
		this.connectAccount.emit(toPostTrackedData);
	}
}
