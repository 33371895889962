import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { DynamicAds } from 'src/app/campaign-smart-create/models/dynamic-ads.enum';
import { CampaignSmartCreateMapperService } from 'src/app/campaign-smart-create/services/campaign-smart-create-mapper.service';
import { CampaignDateChoices } from 'src/app/shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { FacebookInterests } from 'src/app/shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';
import { FacebookLocations } from 'src/app/shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations';
import {
	ParentTargetingTypeEnum,
	TargetingTypeEnum
} from 'src/app/shared/smart-create-edit-components/step-two-components/targeting-card/models/targeting-type.enum';
import { DeviceEnum, DeviceOS, DeviceOSEnum, Devices } from '../components/devices/devices.component';
import { AAAFlow, FlowFourSteps, FlowOneSteps, FlowThreeSteps, FlowTwoSteps } from '../models/AAA-steps.enum';
import { AdModel } from '../models/ad.model';
import { AdSetModel } from '../models/adset.model';
import { RetargetingInterface } from '../../campaign-smart-create/models/retargeting.interface';
import { Store } from '@ngrx/store';

export function setStepBasedOnFlow(self: any, flow: AAAFlow, fn: (arg: number) => any, stepName: any): void {
	const newFn = fn.bind(self);
	switch (flow) {
		case AAAFlow.createNewAd:
			newFn(FlowOneSteps[stepName]);
			break;
		case AAAFlow.bestPerformingAd:
			newFn(FlowTwoSteps[stepName]);
			break;
		case AAAFlow.createNewAdset:
			newFn(FlowThreeSteps[stepName]);
			break;
		case AAAFlow.bestPerformingAdset:
			newFn(FlowFourSteps[stepName]);
			break;
	}
}

export function convertToAdModel(ad: IAPIAdModel): AdModel {
	const isCarousel = ad.adFormatType === AdFormatsEnum.Carousel;
	const newAd: AdModel = {
		adId: ad.id || null,
		adName: ad.name,
		dynamicAds: DynamicAds.Off,
		adFormatType: (ad.adFormatType ?? AdFormatsEnum.SingleImage) as AdFormatsEnum,
		adverts: {
			description: ad.advert?.description || ad.advert?.primaryText,
			display_link: ad.advert?.displayLink,
			headline: ad.advert?.headline,
			website_url: ad.advert?.websiteUrl,
			media_url: ad.advert?.mediaUrl || ad.advert?.picture,
			primary_text: ad.advert?.primaryText,
			picture: ad.advert?.mediaUrl || ad.advert?.picture,
			call_to_action: ad.advert?.callToAction,
			video_id: +ad.advert?.videoId,
			device_placement_position: 'MOBILE_FEED_STANDARD',
			post_id: ad.postId
		},
		toggle: false,
		pixelId: ad?.pixelId,
		eventRule: {},
		pixelAppEventId: ad?.pixelAppEventId,
		conversionId: '',
		customEventType: '',
		pixelRule: {},
		facebookPageId: ad.facebookPageId,
		instagramPageId: ad.instagramPageId
	};
	return newAd;
}

export function convertToAdsetModel(adset: IAPIAdsetModel): AdSetModel {
	const smartCreatMapperService = new CampaignSmartCreateMapperService(null, null, null);
	const locations = MapWithCallBack(adset.locations, smartCreatMapperService.mapLocationsToViewModel);
	const interests = MapWithCallBack(adset.interests, smartCreatMapperService.mapInterestToViewModel);
	const mobileOs = adset.mobileOs;
	return {
		adSetId: adset.id || null,
		adSetName: adset.name,
		facebookPageId: adset.pageId,
		instagramPageId: adset.pageId,
		placements: [],
		ads: adset.ads,
		targeting: {
			targeting_type: adset?.targeting_type,
			retargeting: adset?.retargeting,
			type: TargetingTypeEnum.SavedAudience,
			gender: adset.gender,
			ageRange: {
				minAge: adset.minAge,
				maxAge: adset.maxAge
			},
			locations,
			languages: adset.languages,
			interests,
			excludedInterests: adset.excludedInterests,
			narrowInterests: adset.narrowInterests,
			customAudiences: adset.customAudiences,
			excludeCustomAudiences: adset.excludedCustomAudiences
		},
		date: {
			startDate: adset.startTime,
			endDate: adset.endTime
		},
		dateChoice: CampaignDateChoices.Default,
		budgetOptimization: {
			budgetAllocatedTypeId: adset.adsetBudgetOptimization?.budgetAllocatedTypeId,
			amount: adset.adsetBudgetOptimization?.amount,
			minimumBudget: adset.adsetBudgetOptimization?.amount,
			bidStrategy: '',
			deliveryType: ''
		},
		optimizationAndDelivery: {
			optimizationGoal: adset.optimizationGoal,
			click: '',
			view: '',
			billingEvent: adset.billingEvent,
			bidControl: adset.bidControl
		},
		device: adset.devicePlatforms || Devices.all,
		deviceOs: adset.mobileOs || DeviceOS.all,
		placementPerPlatform: [],
		productSetId: adset.productSetId
	};
}

export function MapWithCallBack<T, U>(data: T[], callback: (item: T) => U): any[] {
	return data.map(item => {
		return callback(item);
	});
}

export interface IAPIAdModel {
	adFormatType: number;
	advert: IAPIAdvertModel;
	facebookPageId: string;
	id: string;
	instagramPageId: string;
	name: string;
	pixelAppEventId: string;
	pixelId: string;
	postId: string;
	status: string;
}

export interface IAPIAdvertModel {
	description: string;
	callToAction: string;
	displayLink: string;
	headline: string;
	websiteUrl: string;
	mediaType: number;
	mediaUrl: string;
	primaryText: string;
	picture?: string;
	videoId?: string;
}

export interface IAPIAdsetModel {
	targeting_type: ParentTargetingTypeEnum;
	retargeting: RetargetingInterface;
	ads: any[];
	adsetBudgetOptimization: { amount: number; budgetAllocatedTypeId: number };
	audienceNetworkPositions: any[];
	bidControl: any;
	billingEvent: string;
	customAudiences: any[];
	customEventType: any;
	destinationType: string;
	devicePlatforms: DeviceEnum[];
	endTime: any;
	excludedCustomAudiences: any[];
	excludedInterests: any[];
	facebookPositions: any[];
	gender: number;
	id: string;
	instagramPositions: [];
	interests: Iinterest[];
	languages: any[];
	locations: ILocation[];
	maxAge: number;
	minAge: number;
	mobileDevices: any[];
	mobileOs: DeviceOSEnum[];
	name: string;
	narrowInterests: any[];
	optimizationGoal: string;
	pageId: any;
	pixelId: any;
	publisherPlatforms: any;
	startTime: string;
	status: string;
	productSetId: string;
}

export interface Iinterest extends FacebookInterests {
	audienceSize: string;
	description: string;
	disambiguationCategory: string;
	id: string;
	name: string;
	path: string[];
	topic: string;
}

export interface ILocation extends FacebookLocations {
	country: string;
	countryName: string;
	distanceUnit: string;
	key: string;
	name: string;
	radius: number;
	region: string;
	regionId: string;
	type: string;
}
