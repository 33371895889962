import { Injectable } from '@angular/core';

@Injectable()
export class GenericTableComponentHelperService {
	public openFullScreen(element: HTMLElement) {
		if (element.requestFullscreen) {
			element.requestFullscreen();
		}
	}
}
