<div class="catalog-import">
	<div class="child-container">
		<section class="text-center">
			<div class="header"></div>
			<div class="sub-header"><font06>Your Connection was Successfull</font06></div>
			<img src="assets/images/catalog-product/welcome/welcome.png" />
			<br />
			<btn01 (onClick)="nextcontent()">Finish Connection</btn01>
		</section>
	</div>
</div>
