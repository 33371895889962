<app-campaign-smart-create-navigation
	#top
	[activeStep]="step"
	[campaignStep]="lastStep"
	[isCurrentStepValid]="isThisStepValid"
	(navigationClick)="onNavClick($event)"
></app-campaign-smart-create-navigation>

<div #mainContent class="ca-wrapper" id="ca-wrapper-id">
	<span class="title">Smart Create</span>
	<div class="container">
		<div class="navigate sticky">
			<quick-navigation [navigations]="navigations"></quick-navigation>
		</div>
		<div QuickNavigationScroll class="panels">
			<div (click)="onPanelClick(0)" class="ca-expansion-panel adSetName" id="adNavigation" qn-scroll-item *ngIf="!isAppInstalled">
				<create-ad-nav
					[isSelected]="expansionPanelHelper.isPanelSelected(panels.AdNavigation)"
					[expansionTitle]="'Ad Navigation'"
					[showContentMargin]="false"
					[ads]="allAds"
					(addNew)="addNewAd()"
					(removedAdIndex)="removeAd($event)"
					(selectedAdIndex)="selectAd($event)"
				></create-ad-nav>
			</div>
			<div (click)="onPanelClick(1)" id="name-your-ad" class="ca-expansion-panel name" qn-scroll-item>
				<app-name-expansion-panel
					[control]="adCreateFormGroup.get('name')"
					(click)="expansionPanelHelper.onPanelClick(panels.AdName)"
					[isSelected]="expansionPanelHelper.isPanelSelected(panels.AdName)"
					[expansionTitle]="'Ad Name'"
					[placeholder]="'Ad '"
					[label]="label"
					[showContentMargin]="false"
				></app-name-expansion-panel>
			</div>

			<div class="ca-expansion-panel" (click)="onPanelClick(2)" id="ad-format" qn-scroll-item>
				<app-ad-format-selection-card
					*ngIf="subObjective !== subObjectives.ProductCatalogSales"
					[objectiveTree]="objectiveTree"
					[selectedFormat]="selectedFormat"
					(onUpdateFormatType)="updateFormatType($event)"
					(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.AdFormat)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.AdFormat)"
				></app-ad-format-selection-card>
			</div>
			<div class="ca-expansion-panel ad-creation" (click)="onPanelClick(3)" id="ad-creation" qn-scroll-item>
				<app-ad-create-card
					(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.AdCreate)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.AdCreate)"
					(onUpdateInvalidStep)="updateInvalidStep($event)"
					(onLoadImages)="loadImages($event)"
					(onLoadVideos)="loadVideos($event)"
					(onUpdateAdverts)="updateAdverts($event)"
					[callForActions]="callToActions"
					[validatorTrigger]="validatorTrigger"
					[subObjective]="subObjective"
					[validations]="validations"
					[placements]="placements"
					[posts]="posts"
					[images]="images"
					[videos]="videos"
					[previews]="previews"
					[adFormatListener$]="adFormatListener$"
					[facebookPageId]="facebookPageId"
					[instagramPageId]="instagramPageId"
					[businessOwnerFbId]="businessOwnerFbId"
					(adCreationFormValidity)="checkAdCreationFormValidity($event)"
					[selectedAdAccount]="selectedAdAccount"
					(oniframeSrc)="oniframeSrc($event)"
					(ondropdownPreviewPlacement)="ondropdownPreviewPlacement($event)"
					(onadFormat)="onadFormat($event)"
					(onisSticky)="onisSticky($event)"
					(onadCreateFormGroup)="onadCreateFormGroup($event)"
					(onselectedAssets)="onselectedAssets($event)"
					[productSetID]="productSetId"
				></app-ad-create-card>
			</div>

			<div class="ca-expansion-panel pixel" (click)="onPanelClick(4)" id="tracking" qn-scroll-item>
				<app-pixel-card
					(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Pixel)"
					[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Pixel)"
					[selectedAdAccount]="selectedAdAccount"
					[subObjective]="subObjective"
					(sendEvents)="getEvents($event)"
					[isPixelMandatory]="pixelMandatory"
					[pixelId]="pixelId"
					[pixelEventId]="pixelEventId"
					[conversion]="conversion"
					[firstTime]="firstTime"
					[pixelControl]="pixelFormGroup.get('pixel')"
					[pixelEventControl]="pixelFormGroup.get('pixelEvent')"
					[conversionControl]="pixelFormGroup.get('conversion')"
					(updateConversion)="updateConversion($event)"
				></app-pixel-card>
				<br />
				<br />
			</div>
		</div>
		<div class="preview-box sticky">
			<app-preview-card
				[iframeSrc]="iframeSrc"
				[dropdownPreviewPlacement]="dropdownPreviewPlacement"
				[adFormat]="adFormat"
				[isSticky]="isSticky"
				[adCreateFormGroup]="adFormGroup"
				[devicePlacement]="placements"
				[selectedAssets]="selectedAssets"
			></app-preview-card>
		</div>
	</div>
	<app-campaign-smart-create-footer
		class="footer"
		[saveButtonText]="'Back'"
		(next)="onNextClick()"
		(save)="onBackClick()"
		(cancel)="onCancelClick()"
	></app-campaign-smart-create-footer>
</div>
