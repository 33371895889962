export class StripeCreditCard {
	number: string;
	exp_month: string;
	exp_year: string;
	cvc: string;
	name: string;
	address_line1: string;
	address_line2: string;
	address_city: string;
	address_zip: boolean;
	address_country?: string;
	address_state?: string;
}

export interface StripeFormErrorsDTO {
	code: string;
	type: string;
	param: string;
	message: string;
}

export interface SourceCardToken {
	SourceCardToken: string;
	FiledId?: number;
}

export class FiledCreditCard {
	cardId: string;
	brand: string;
	funding: string;
	last4Digits: string;
	expirationMonth: number;
	expirationYear: number;
	city: string;
	country: string;
	address1: string;
	address2: string;
	state: string;
	zip: string;
	holderName: string;
	isDefault: boolean;
}

export interface ChargeBeeFormErrorsDTO {
	code: string;
	description: string;
}
