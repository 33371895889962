import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { LoadSelectedKeysPermissions } from '../../../user-management/state/user-management.actions';
import { GetPermissionsResponse } from '../../../shared/models/permission';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { getKeysForAllPermissionsState, UserManagementState } from '../../../user-management/state/user-management.reducer';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';
import { SharedState } from '../../../shared/state/shared.reducer';

@Injectable()
export class PermissionsResolver implements Resolve<GetPermissionsResponse> {
	constructor(
		private router: Router,
		private store: Store<UserManagementState>,
		private sharedStore: Store<SharedState>,
		private toastNotification: ToastNotificationService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GetPermissionsResponse> {
		this.store.dispatch(new LoadSelectedKeysPermissions());
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		return this.store.pipe(
			select(getKeysForAllPermissionsState),
			filter(keys => keys != null),
			take(1),
			tap(keys => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				if (!keys) {
					this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
					this.router.navigate(['/my-account']);
					return EMPTY;
				}
			}),
			catchError(() => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
				this.router.navigate(['/my-account']);
				return EMPTY;
			})
		);
	}
}
