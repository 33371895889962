<div class="container">
	<div class="wrapper">
		<div class="vertical-icon" [style.margin-bottom]="expanded ? 'blue' : '0px'">
			<span class="k-icon k-i-select-all"></span>
		</div>
		<div class="panel">
			<div class="panel" *ngIf="!expanded">
				<div class="sub-sub-panel first" (click)="toggleExpansion()">
					<div>&nbsp;</div>
					<div>
						<button class="give-border">
							<i class="k-icon k-i-radiobutton-checked"></i>
							{{ placeholderText }}
						</button>
					</div>
				</div>
				<div *ngIf="expanded">
					<hr />
				</div>
			</div>

			<div class="panel" [formGroup]="formControl" *ngIf="expanded">
				<div class="form-container">
					<label class="form-label">Question</label>
					<tb06 [control]="formControl.get('question')" [placeHolderTextBox]="'Enter multiple choice question'"></tb06>
				</div>
				<div *ngFor="let item of possibleAnser; let i = index">
					<div class="container-form">
						<div class="vertical-icon">
							<i class="k-icon k-i-select-all"></i>
						</div>
						<div>
							{{ item.value }}
						</div>
						<div class="vertical-icon">
							<btn08 [icon]="'close'" (onClick)="removePossibleAnswer(i)"></btn08>
						</div>
					</div>
				</div>
				<div class="sub-sub-panel first">
					<div class="form-container">
						<label class="form-label">Answers</label>
						<tb06 [control]="formControl.get('qstnanswer')" [placeHolderTextBox]="'Enter a possible response'"></tb06>
					</div>
					<div [style.margin-top]="'9px'">
						<div class="panel" fxLayout [formGroup]="formControl">
							<div fxFlex="92">
								<div fxLayout *ngIf="expanded">
									<div fxFlex="5" class="icon-area">
										<span class="k-icon k-i-full-screen"></span>
									</div>
									<div fxFlex="95">
										<div class="form-container">
											<label class="form-label">Question</label>
											<tb06 [placeHolderTextBox]="'Enter multiple choice question'"></tb06>
										</div>
										<div fxLayout *ngFor="let item of possibleAnser; let i = index">
											<div fxFlex="82">
												<i class="k-icon k-i-full-screen"></i>
												&nbsp;&nbsp;{{ item.value }}
											</div>
											<div fxFlex="18">
												<btn08 [icon]="'close'" (onClick)="removePossibleAnswer(i)"></btn08>
											</div>
										</div>
										<div fxLayout>
											<div fxFlex="82">
												<div class="form-container">
													<label class="form-label">Answers</label>
													<tb06 [control]="formControl.get('qstnanswer')" [placeHolderTextBox]="'Enter a possible response'"></tb06>
												</div>
											</div>
											<div fxFlex="18" class="add-button">
												<btn06 [icon]="'plus'" (onClick)="addPossibleAnswer()">Add</btn06>
											</div>
										</div>
									</div>
									<div class="sub-sub-panel first" *ngIf="expanded">
										<div>&nbsp;</div>
										<btn03 [icon]="'trash'" (onClick)="removeItem()">Remove</btn03>
									</div>
								</div>
							</div>
							<div class="vertical-icon" *ngIf="!expanded"></div>
							<div fxLayout *ngIf="!expanded" class="collapsed-panel" (click)="expanded = true">
								<div fxFlex="70" [style.margin-top]="'5px'">
									<span class="k-icon k-i-full-screen"></span>
								</div>
								<div fxFlex="30" class="push-right">
									<button class="give-border">
										<i class="k-icon k-i-radiobutton-checked"></i>
										{{ placeholderText }}
									</button>
								</div>
							</div>
						</div>
						<div fxFlex="8" class="push-right trash-button" [style.margin-top]="expanded ? '10%' : '5px'">
							<btn08 [icon]="'trash'" (onClick)="removeItem()"></btn08>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
