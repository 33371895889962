import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { EMPTY, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AdAccount } from 'src/app/accounts/models/ad-account.model';
import { StorageKey } from '../../_models/local-storage-key';
import { AuthenticationService } from '../authentication.service';
import { TableColumn } from '../../_models/TableModels/table-column';
import { TableView } from '../../_models/TableModels/table-view';
import { GetPermissionsResponse } from 'src/app/shared/models/permission';
import { BaseApiUrl } from '../base-api-urls';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AccountTypeService } from '../../shared/account-type.service';
import { BusinessOwner } from '../../_models/business-model/business-owner';
import { SetAdAccountSelection } from 'src/app/shared/state/shared.actions';
import { select, Store } from '@ngrx/store';
import { getFacebookBusinessOwnerId, getGoogleBusinessOwnerId, getUserDetails, UserState } from '../../shared/state/user/user.reducer';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import {
	ClearFacebookPage,
	LoadAdvertisableApp,
	LoadCampaignCatalogs,
	LoadExistingImages,
	LoadExistingVideos,
	LoadFacebookPages,
	LoadUserImages,
	LoadUserVideos,
	LoadFacebookPixels
} from '../../state/campaign-common-state/campaign-common-actions';
import { BusinessOwnerService } from './business-owner.service';

@Injectable()
export class AdAccountApiService implements OnInit {
	public adAccountId: number;
	public selectFirstAdAccountSubject: Subject<void> = new Subject();
	public businessOwner: BusinessOwner;
	public adAccountChanged$: Observable<AdAccount>;
	public allAdAccounts: Map<SourceChannel, AdAccount[]> = new Map<SourceChannel, AdAccount[]>();

	private adAccountChangedSubject: ReplaySubject<AdAccount> = new ReplaySubject(1);
	private businessSubject$: ReplaySubject<BusinessOwner> = new ReplaySubject(1);

	private selectedAdAccount: Map<SourceChannel, AdAccount> = new Map<SourceChannel, AdAccount>();
	private logoutSubscription: Subscription;
	private selectedChannel: SourceChannel;

	private readonly baseURL = BaseApiUrl.FacebookAccount;
	private readonly pythonBaseURL = BaseApiUrl.GoogleTuring;

	constructor(
		protected http: HttpClient,
		private authenticationService: AuthenticationService,
		private accountTypeService: AccountTypeService,
		private userStore: Store<UserState>,
		private sharedStore: Store<SharedState>,
		private businessOwnerService: BusinessOwnerService
	) {
		this.adAccountChanged$ = this.adAccountChangedSubject.asObservable();

		this.accountTypeService.getAccountTypeObservable().subscribe(channel => {
			this.selectedChannel = channel;
		});

		this.addSubscriptions();
	}

	ngOnInit() {}

	public getAllGoogleAccounts(): Observable<AdAccount[]> {
		return this.userStore.pipe(
			select(getGoogleBusinessOwnerId),
			take(1),
			switchMap(googleId => {
				return this.http.get<AdAccount[]>(`${BaseApiUrl.GoogleAccounts}managed-customer`);
			})
		);
	}

	public getBusinessOwnerPermissions(): Observable<GetPermissionsResponse> {
		return this.http.get<GetPermissionsResponse>(`${this.baseURL}business-owners/permissions`);
	}

	public getBusinessOwner(): Observable<BusinessOwner> {
		return this.businessSubject$.asObservable();
	}

	public getAllBOBusinesses(facebookId: string) {
		return this.http.get<BusinessOwner>(`${this.baseURL}business-owners/${facebookId}`);
	}

	public getAllAdAccounts(): Observable<AdAccount[]> {
		return this.userStore.pipe(
			select(getUserDetails),
			take(1),
			map(userdetails => {
				const adAccounts: AdAccount[] = [];
				if (userdetails?.AccountsData) {
					userdetails?.AccountsData.forEach(account => {
						const businessOwnerId = account.businessOwnerId;
						const businessOwnerName = account.businessOwnerName;
						account.adaccounts.forEach(adAcc => {
							const adAccount: AdAccount = {
								id: adAcc.accountId,
								name: adAcc.accountName,
								businessId: parseInt(businessOwnerId),
								businessName: businessOwnerName,
								businessIdAsNumber: parseInt(businessOwnerId)
							};
							adAccounts.push(adAccount);
						});
					});
				}
				this.allAdAccounts.set(SourceChannel.Facebook, adAccounts);
				return this.allAdAccounts.get(SourceChannel.Facebook);
			})
		);
	}

	public getTableColumns(): Observable<TableColumn[]> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.get<TableColumn[]>(this.baseURL + 'metadatas?tablename=adaccounts', { headers: headers });
	}

	public createTableView(tableView: TableView): Observable<TableView> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.post<TableView>(this.baseURL + 'metadatas/views', tableView, { headers: headers });
	}

	public selectFirstAdAccount(): void {
		this.selectFirstAdAccountSubject.next();
	}

	private addSubscriptions = (): void => {
		this.logoutSubscription = this.authenticationService.logoutSubject.subscribe(() => {
			this.clearSelectedAdAccounts();
			this.clearAllCachedAdAccounts();
		});
	};

	public preSetSelectedChannel(): AdAccount {
		if (this.allAdAccounts.get(0)?.length > 0) {
			let firstAdAccount = this.allAdAccounts.get(0)[0];
			this.setSelectedAdAccounts(firstAdAccount, 0);
			this.getSelectedAdAccount();
		} else {
			return {
				id: 'none',
				name: 'No Facebook Account',
				status: 'ACTIVE',
				businessName: 'None',
				businessId: 977420299067,
				businessIdAsNumber: 9774202990674,
				currency: 'USD',
				createdAt: '2021-06-18T00:30:09.4508412',
				level: 1,
				expandable: false
			};
		}
	}
	public getSelectedAdAccount(): AdAccount {
		return this.selectedAdAccount.has(this.selectedChannel) ? this.selectedAdAccount.get(this.selectedChannel) : this.preSetSelectedChannel();
	}

	public setSelectedAdAccounts(adAccount: AdAccount, channel: SourceChannel = null): void {
		if (!adAccount || (!channel && this.selectedChannel === null)) {
			return;
		}
		this.selectedAdAccount.set(channel !== null ? channel : this.selectedChannel, adAccount);
		const formattedAccounts = this.selectedAdAccountsToJson();
		this.adAccountChangedSubject.next(adAccount);
		this.sharedStore.dispatch(
			new SetAdAccountSelection({
				sourceChannel: this.selectedChannel,
				adAccount: adAccount
			})
		);
		this.dispatchActions(adAccount, channel);
		localStorage.setItem(StorageKey.selectedAdAccounts, formattedAccounts);
	}

	public restoreAdAccountSelectionFromLocalStorage(): void {
		try {
			this.selectedAdAccount = new Map<SourceChannel, AdAccount>();
			const channel = localStorage.getItem('source_channel');
			const objFromJson = JSON.parse(localStorage.getItem(StorageKey.selectedAdAccounts));
			if (!objFromJson) {
				return;
			}

			for (const [key, value] of Object.entries(objFromJson)) {
				if (key) {
					this.selectedAdAccount.set(parseInt(key), value as AdAccount);
				}
			}

			this.sharedStore.dispatch(
				new SetAdAccountSelection({
					sourceChannel: parseInt(channel),
					adAccount: this.selectedAdAccount.get(parseInt(channel))
				})
			);
			this.dispatchActions(this.selectedAdAccount.get(parseInt(channel)), parseInt(channel));
			this.adAccountChangedSubject.next(this.selectedAdAccount.get(parseInt(channel)));
		} catch (e) {}
	}
	private dispatchActions(adAccount, channel): void {
		this.sharedStore.dispatch(new ClearFacebookPage());
		this.sharedStore.dispatch(new LoadFacebookPages());
		this.sharedStore.dispatch(new LoadAdvertisableApp());
		this.sharedStore.dispatch(new LoadCampaignCatalogs());
		this.sharedStore.dispatch(new LoadUserVideos());
		this.sharedStore.dispatch(new LoadExistingImages({ adAccountId: adAccount.id, businessOwnerId: adAccount.businessId.toString() }));
		this.sharedStore.dispatch(new LoadExistingVideos({ adAccountId: adAccount.id, businessOwnerId: adAccount.businessId.toString() }));
	}

	public getAllCachedAdAccounts(channel: SourceChannel): AdAccount[] {
		if (this.allAdAccounts && this.allAdAccounts.has(channel)) {
			return this.allAdAccounts.get(channel);
		}
		return [];
	}

	public setAllCachedAdAccounts(chanel: SourceChannel, accounts: AdAccount[]): void {
		this.allAdAccounts.set(chanel, accounts);
	}

	public clearAllCachedAdAccounts(): void {
		this.allAdAccounts = new Map<SourceChannel, AdAccount[]>();
	}

	public clearSelectedAdAccounts(): void {
		this.selectedAdAccount = new Map<SourceChannel, AdAccount>();
	}

	private selectedAdAccountsToJson(): string {
		const jsonObj = {};
		this.selectedAdAccount.forEach((value, key) => {
			(jsonObj as any)[key] = value;
		});

		return JSON.stringify(jsonObj);
	}
}
