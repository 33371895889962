import { Injectable } from '@angular/core';

@Injectable()
export class ExpansionPanelHelper<TEnum> {
	public selectedPanel: TEnum;
	constructor() {}

	public onPanelClick(panel: TEnum): void {
		this.selectedPanel = panel;
	}

	public isPanelSelected(panel: TEnum): boolean {
		return this.selectedPanel === panel;
	}
}
