import { createFeatureSelector, createSelector } from '@ngrx/store';

import _ from 'lodash';
import { InsightsCategoryTypeEnum } from 'src/app/shared/campaign-insights/models/insights-category-type.enum';
import { TableViewType } from 'src/app/shared/generic-table2/models/table-structure/table-view-type.enum';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { AdsManagerActions, AdsManagerActionTypes } from './ads-manager.actions';
import { getViewCategoryKey } from './utils/view-category-key.util';
import { DataInterface, NullDataInterface } from '../../shared/state/state-entities/data-interface.model';
import { TableView2 } from '../../shared/generic-table2/models/table-structure/table-view2.model';
import { CampaignResponseInterface } from '../ads-manager-insights/models/campaign-response.interface';
import { AdSetsResponseInterface } from '../ads-manager-insights/models/ad-sets-response.interface';
import { InsightsCategory } from '../../shared/campaign-insights/models/insights-category.model';
import { CampaignInterface } from '../ads-manager-insights/models/campaign.interface';
import { AdSetsInterface } from '../ads-manager-insights/models/ad-sets.interface';
import { AdsInterface } from '../ads-manager-insights/models/ads.interface';
import { UpdateCampaignInterface } from '../ads-manager-insights/models/update-campaign.interface';
import { AgGridViewInterface } from '../../shared/master-table/models/ag-grid-view.interface';

export interface AdsManagerState {
	facebookCampaignsData: DataInterface<TableView2[]>;
	facebookAdsetsData: DataInterface<TableView2[]>;
	facebookAdsData: DataInterface<TableView2[]>;
	googleCampaignsData: DataInterface<TableView2[]>;
	googleAdgroupsData: DataInterface<TableView2[]>;
	googleAdsData: DataInterface<TableView2[]>;
	googleKeywordsData: DataInterface<TableView2[]>;
	campaigns: {
		campaignsViews: DataInterface<AgGridViewInterface[]>;
		campaignsData: DataInterface<CampaignResponseInterface>;
		selectedCampaigns: CampaignInterface[];
		nextPageCursor: string;
	};
	adSets: {
		adSetsViews: DataInterface<AgGridViewInterface[]>;
		adSetsData: DataInterface<AdSetsResponseInterface>;
		selectedAdSets: AdSetsInterface[];
		nextPageCursor: string;
	};
	ads: {
		adsViews: DataInterface<AgGridViewInterface[]>;
		adsData: DataInterface<AdSetsResponseInterface>;
		selectedAds: AdsInterface[];
		nextPageCursor: string;
	};
	navigation: {
		oldSelectedTab: InsightsCategory;
	};
}

export const initialAdsManagerState: AdsManagerState = {
	facebookCampaignsData: _.cloneDeep(NullDataInterface),
	facebookAdsetsData: _.cloneDeep(NullDataInterface),
	facebookAdsData: _.cloneDeep(NullDataInterface),
	googleCampaignsData: _.cloneDeep(NullDataInterface),
	googleAdgroupsData: _.cloneDeep(NullDataInterface),
	googleAdsData: _.cloneDeep(NullDataInterface),
	googleKeywordsData: _.cloneDeep(NullDataInterface),
	campaigns: {
		campaignsViews: NullDataInterface,
		campaignsData: NullDataInterface,
		selectedCampaigns: null,
		nextPageCursor: null
	},
	adSets: {
		adSetsViews: NullDataInterface,
		adSetsData: NullDataInterface,
		selectedAdSets: null,
		nextPageCursor: null
	},
	ads: {
		adsViews: NullDataInterface,
		adsData: NullDataInterface,
		selectedAds: null,
		nextPageCursor: null
	},
	navigation: {
		oldSelectedTab: null
	}
};

export const getAdsManagerState = createFeatureSelector<AdsManagerState>('adsManager');

export const getBusinessViews = createSelector(
	getAdsManagerState,
	(state: AdsManagerState, { channel, category }: { channel: SourceChannel; category: InsightsCategoryTypeEnum }) =>
		_.cloneDeep((state[getViewCategoryKey(channel, category)].data || []).filter(view => view.type !== TableViewType.Master))
);

export const isBusinessViewsLoaded = createSelector(
	getAdsManagerState,
	(state: AdsManagerState, { channel, category }: { channel: SourceChannel; category: InsightsCategoryTypeEnum }) => {
		return (
			state &&
			state[getViewCategoryKey(channel, category)] &&
			state[getViewCategoryKey(channel, category)].data &&
			state[getViewCategoryKey(channel, category)].data.length >= 0
		);
	}
);

export const getCampaignsViews = createSelector(getAdsManagerState, state => state.campaigns.campaignsViews);
export const getSelectedCampaigns = createSelector(getAdsManagerState, state => state.campaigns.selectedCampaigns);

export const getAdSetsViews = createSelector(getAdsManagerState, state => state.adSets.adSetsViews);
export const getAdSetsData = createSelector(getAdsManagerState, state => state.adSets.adSetsData);
export const getSelectedAdSets = createSelector(getAdsManagerState, state => state.adSets.selectedAdSets);

export const getAdsViews = createSelector(getAdsManagerState, state => state.ads.adsViews);
export const getAdsData = createSelector(getAdsManagerState, state => state.ads.adsData);
export const getSelectedAds = createSelector(getAdsManagerState, state => state.ads.selectedAds);

export const getSelectedCampaignsIds = createSelector(getAdsManagerState, state =>
	state.campaigns.selectedCampaigns?.map((campaign: any) => {
		return campaign.campaign_id.toString();
	})
);
export const getSelectedAdSetsIds = createSelector(getAdsManagerState, state =>
	state.adSets.selectedAdSets?.map((adSet: any) => {
		return adSet.adset_id.toString();
	})
);
export const getSelectedAdsIds = createSelector(getAdsManagerState, state =>
	state.ads.selectedAds?.map((ad: any) => {
		return ad.ad_id.toString();
	})
);

export const getSelectedCampaignsAndAdSets = createSelector(
	getSelectedCampaigns,
	getSelectedAdSets,
	(selectedCampaigns: CampaignInterface[], selectedAdSets: AdSetsInterface[]) => {
		return {
			selectedCampaigns: selectedCampaigns,
			selectedAdSets: selectedAdSets
		};
	}
);

export const getSelectedAdSetsAndAds = createSelector(getSelectedAdSets, getSelectedAds, (selectedAdSets: AdSetsInterface[], selectedAds: AdsInterface[]) => {
	return {
		selectedAdSets: selectedAdSets,
		selectedAds: selectedAds
	};
});

export const getSelectedCampaignsAdSetsAndAds = createSelector(
	getSelectedCampaigns,
	getSelectedAdSets,
	getSelectedAds,
	(selectedCampaigns: CampaignInterface[], selectedAdSets: AdSetsInterface[], selectedAds: AdsInterface[]) => {
		return {
			selectedCampaigns: selectedCampaigns,
			selectedAdSets: selectedAdSets,
			selectedAds: selectedAds
		};
	}
);

export function adsManagerReducer(state = initialAdsManagerState, action: AdsManagerActions): AdsManagerState {
	switch (action.type) {
		case AdsManagerActionTypes.SetOldSelectedTab: {
			return {
				...state,
				navigation: {
					oldSelectedTab: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadFacebookCampaignsBusinessViews: {
			return {
				...state,
				facebookCampaignsData: {
					...state.facebookCampaignsData,
					isLoaded: true
				}
			};
		}
		case AdsManagerActionTypes.LoadFacebookCampaignsBusinessViewsSuccess: {
			return {
				...state,
				facebookCampaignsData: {
					...state.facebookCampaignsData,
					data: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadFacebookCampaignsBusinessViewsFailure: {
			return {
				...state,
				facebookCampaignsData: {
					...state.facebookCampaignsData,
					errorCode: action.payload
				}
			};
		}

		case AdsManagerActionTypes.LoadFacebookAdsetsBusinessViews: {
			return {
				...state,
				facebookAdsetsData: {
					...state.facebookAdsetsData,
					isLoaded: true
				}
			};
		}
		case AdsManagerActionTypes.LoadFacebookAdsetsBusinessViewsSuccess: {
			return {
				...state,
				facebookAdsetsData: {
					...state.facebookAdsetsData,
					data: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadFacebookAdsetsBusinessViewsFailure: {
			return {
				...state,
				facebookAdsetsData: {
					...state.facebookAdsetsData,
					errorCode: action.payload
				}
			};
		}

		case AdsManagerActionTypes.LoadFacebookAdsBusinessViews: {
			return {
				...state,
				facebookAdsData: {
					...state.facebookAdsData,
					isLoaded: true
				}
			};
		}
		case AdsManagerActionTypes.LoadFacebookAdsBusinessViewsSuccess: {
			return {
				...state,
				facebookAdsData: {
					...state.facebookAdsData,
					data: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadFacebookAdsBusinessViewsFailure: {
			return {
				...state,
				facebookAdsData: {
					...state.facebookAdsData,
					errorCode: action.payload
				}
			};
		}

		case AdsManagerActionTypes.LoadGoogleCampaignsBusinessViews: {
			return {
				...state,
				googleCampaignsData: {
					...state.googleCampaignsData,
					isLoaded: true
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleCampaignsBusinessViewsSuccess: {
			return {
				...state,
				googleCampaignsData: {
					...state.googleCampaignsData,
					data: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleCampaignsBusinessViewsFailure: {
			return {
				...state,
				googleCampaignsData: {
					...state.googleCampaignsData,
					errorCode: action.payload
				}
			};
		}

		case AdsManagerActionTypes.LoadGoogleKeywordsBusinessViews: {
			return {
				...state,
				googleKeywordsData: {
					...state.googleKeywordsData,
					isLoaded: true
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleKeywordsBusinessViewsSuccess: {
			return {
				...state,
				googleKeywordsData: {
					...state.googleKeywordsData,
					data: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleKeywordsBusinessViewsFailure:
			return {
				...state,
				googleKeywordsData: {
					...state.googleKeywordsData,
					errorCode: action.payload
				}
			};

		case AdsManagerActionTypes.LoadGoogleAdsBusinessViews: {
			return {
				...state,
				googleAdsData: {
					...state.googleAdsData,
					isLoaded: true
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleAdsBusinessViewsSuccess: {
			return {
				...state,
				googleAdsData: {
					...state.googleAdsData,
					data: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleAdsBusinessViewsFailure: {
			return {
				...state,
				googleAdsData: {
					...state.googleAdsData,
					errorCode: action.payload
				}
			};
		}

		case AdsManagerActionTypes.LoadGoogleAdgroupsBusinessViews: {
			return {
				...state,
				googleAdgroupsData: {
					...state.googleAdgroupsData,
					isLoaded: true
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleAdgroupsBusinessViewsSuccess: {
			return {
				...state,
				googleAdgroupsData: {
					...state.googleAdgroupsData,
					data: action.payload
				}
			};
		}
		case AdsManagerActionTypes.LoadGoogleAdgroupsBusinessViewsFailure: {
			return {
				...state,
				googleAdgroupsData: {
					...state.googleAdgroupsData,
					errorCode: action.payload
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedCampaignsSuccess: {
			let selectedCampaigns = null;
			if (action.payload !== null) {
				selectedCampaigns = _.cloneDeep(state.campaigns.selectedCampaigns) ?? ([] as CampaignInterface[]);
				const selectedCampaignsId = selectedCampaigns.map((selectedCampaign: CampaignInterface) => selectedCampaign.campaign_id);
				if (action.payload.isChosen && selectedCampaignsId.indexOf(action.payload.selectedRow.campaign_id) === -1) {
					selectedCampaigns.push(action.payload.selectedRow);
				} else if (!action.payload.isChosen && selectedCampaignsId.indexOf(action.payload.selectedRow.campaign_id) > -1) {
					const selectedIndex = selectedCampaignsId.indexOf(action.payload.selectedRow.campaign_id);
					selectedCampaigns.splice(selectedIndex, 1);
				}
				selectedCampaigns = selectedCampaigns.length !== 0 ? selectedCampaigns : null;
			}
			return {
				...state,
				campaigns: {
					...state.campaigns,
					selectedCampaigns: selectedCampaigns
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedCampaignsSuccessBundle: {
			const storeCampaigns = _.cloneDeep(state.campaigns.selectedCampaigns) ?? ([] as CampaignInterface[]);
			const toggledCampaigns = action.payload;
			if (toggledCampaigns[0]?.isChosen) {
				toggledCampaigns.forEach((row: UpdateCampaignInterface) => {
					if (storeCampaigns.findIndex(storedRow => storedRow.campaign_id === row.selectedRow.campaign_id) === -1) {
						storeCampaigns.push(row.selectedRow);
					}
				});
			} else if (!toggledCampaigns[0]?.isChosen) {
				toggledCampaigns.forEach(row => {
					const index = storeCampaigns.findIndex(storedRow => storedRow.campaign_id === row.selectedRow.campaign_id);
					if (index > -1) {
						storeCampaigns.splice(index, 1);
					}
				});
			}
			return {
				...state,
				campaigns: {
					...state.campaigns,
					selectedCampaigns: storeCampaigns
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedCampaignsStatus: {
			const changedCampaigns = action.payload;
			const status = action.status;
			const storeCampaigns = _.cloneDeep(state.campaigns.selectedCampaigns);
			changedCampaigns.forEach(campaignId => {
				storeCampaigns.find(storeCampaign => storeCampaign.campaign_id === campaignId).status = status;
			});
			return {
				...state,
				campaigns: {
					...state.campaigns,
					selectedCampaigns: storeCampaigns
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedAdSetsSuccess: {
			let selectedAdSets = null;
			if (action.payload !== null) {
				selectedAdSets = _.cloneDeep(state.adSets.selectedAdSets) ?? ([] as AdSetsInterface[]);
				const selectedAdSetsIds = selectedAdSets.map((selectedAdSet: AdSetsInterface) => selectedAdSet.adset_id);
				if (action.payload.isChosen && selectedAdSetsIds.indexOf(action.payload.selectedRow.adset_id) === -1) {
					selectedAdSets.push(action.payload.selectedRow);
				} else if (!action.payload.isChosen && selectedAdSetsIds.indexOf(action.payload.selectedRow.adset_id) > -1) {
					const selectedIndex = selectedAdSetsIds.indexOf(action.payload.selectedRow.adset_id);
					selectedAdSets.splice(selectedIndex, 1);
				}
			}
			return {
				...state,
				adSets: {
					...state.adSets,
					selectedAdSets: selectedAdSets
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedAdSetsBundleSuccess: {
			const storeAdSets = _.cloneDeep(state.adSets.selectedAdSets) ?? ([] as AdSetsInterface[]);
			const toggledAdSets = action.payload;
			if (toggledAdSets[0]?.isChosen) {
				toggledAdSets.forEach(row => {
					if (storeAdSets.findIndex(storedRow => storedRow.adset_id === row.selectedRow.adset_id) === -1) {
						storeAdSets.push(row.selectedRow);
					}
				});
			} else if (!toggledAdSets[0]?.isChosen) {
				toggledAdSets.forEach(row => {
					const index = storeAdSets.findIndex(storedRow => storedRow.adset_id === row.selectedRow.adset_id);
					if (index > -1) {
						storeAdSets.splice(index, 1);
					}
				});
			}
			return {
				...state,
				adSets: {
					...state.adSets,
					selectedAdSets: storeAdSets
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedAdSetsStatus: {
			const changedAdSetsIds = action.payload;
			const status = action.status;
			const storeAdSets = _.cloneDeep(state.adSets.selectedAdSets);
			changedAdSetsIds.forEach((adSetId: string) => {
				storeAdSets.find((storeAdSet: AdSetsInterface) => storeAdSet.adset_id === adSetId).status = status;
			});
			return {
				...state,
				adSets: {
					...state.adSets,
					selectedAdSets: storeAdSets
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedAds: {
			let selectedAds = null;
			if (action.payload != null) {
				selectedAds = _.cloneDeep(state.ads.selectedAds) ?? ([] as AdsInterface[]);
				const selectedAdsIds = selectedAds.map(selectedAd => selectedAd.ad_id);
				if (action.payload.isChosen && selectedAdsIds.indexOf(action.payload.selectedRow.ad_id) === -1) {
					selectedAds.push(action.payload.selectedRow);
				} else if (!action.payload.isChosen && selectedAdsIds.indexOf(action.payload.selectedRow.ad_id) > -1) {
					const selectedIndex = selectedAdsIds.indexOf(action.payload.selectedRow.ad_id);
					selectedAds.splice(selectedIndex, 1);
				}
				selectedAds = selectedAds.length !== 0 ? selectedAds : null;
			}
			return {
				...state,
				ads: {
					...state.ads,
					selectedAds: selectedAds
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedAdsBundleSuccess: {
			const storeAds = _.cloneDeep(state.ads.selectedAds) ?? ([] as AdsInterface[]);
			const toggledAds = action.payload;
			if (toggledAds[0]?.isChosen) {
				toggledAds.forEach(row => {
					if (storeAds.findIndex(storedRow => storedRow.ad_id === row.selectedRow.ad_id) === -1) {
						storeAds.push(row.selectedRow);
					}
				});
			} else if (!toggledAds[0]?.isChosen) {
				toggledAds.forEach(row => {
					const index = storeAds.findIndex(storedRow => storedRow.ad_id === row.selectedRow.ad_id);
					if (index > -1) {
						storeAds.splice(index, 1);
					}
				});
			}
			return {
				...state,
				ads: {
					...state.ads,
					selectedAds: storeAds
				}
			};
		}

		case AdsManagerActionTypes.UpdateSelectedAdsStatus: {
			const changedAdsIds = action.payload;
			const status = action.status;
			const storeAds = _.cloneDeep(state.ads.selectedAds);
			changedAdsIds.forEach((adId: string) => {
				storeAds.find((storeAd: AdsInterface) => storeAd.ad_id === adId).status = status;
			});
			return {
				...state,
				ads: {
					...state.ads,
					selectedAds: storeAds
				}
			};
		}

		case AdsManagerActionTypes.ClearSelected: {
			return {
				...state,
				ads: {
					...state.ads,
					selectedAds: null
				},
				adSets: {
					...state.adSets,
					selectedAdSets: null
				},
				campaigns: {
					...state.campaigns,
					selectedCampaigns: null
				}
			};
		}

		case AdsManagerActionTypes.SetnextPageCursorCampaigns: {
			return {
				...state,
				campaigns: {
					...state.campaigns,
					nextPageCursor: action.payload
				}
			};
		}
		default:
			return state;
	}
}
