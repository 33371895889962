<div mat-dialog-title class="dialog-header">
	<h6>
		Create Form
		<span class="dialog-close" (click)="close()"><i class="fa fa-times"></i></span>
	</h6>
</div>

<div class="dialog-container" [formGroup]="dialogForm">
	<div class="modal-box-content-tabs">
		<btn15 (onClick)="toggleShowGeneralContentTab(false)">Contents</btn15>
		<btn15 (onClick)="toggleShowGeneralContentTab(true)">Settings</btn15>
	</div>
	<div class="modal-box-content">
		<div fxLayout>
			<div fxFlex="67">
				<div [hidden]="isShowGeneralContentTab">
					<div class="form-container">
						<label class="label-control">Form Name</label>
						<tb06 [control]="dialogForm.get('formName')"></tb06>
					</div>
					<mat-accordion>
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Form Type</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div class="ca-expansion-panel">
								<font10>
									Customize your form depending on the goal of your lead generation campaign. The options you select may impact the volume of
									submissions and cost per lead.
									<a href="#">Learn More</a>
								</font10>
								<div>
									<mat-radio-group class="afc-radio-group">
										<mat-radio-button class="afc-radio-button" [value]="'More Volume'" (click)="formTypeValueChanges('More Volumn')">
											<div class="dc-radio-text">
												<p class="dc-first">More Volume</p>
												<font10><p>Use a form that's quick to fill out and submit on ambile device</p></font10>
											</div>
										</mat-radio-button>
										<mat-radio-button class="afc-radio-button" [value]="'Higher Intent'" (click)="formTypeValueChanges('Higher Intent')">
											<div class="dc-radio-text">
												<p class="dc-first">Higher Intent</p>
												<font10><p>Add a review step that gives people a chance to confirm their info</p></font10>
											</div>
										</mat-radio-button>
									</mat-radio-group>
								</div>
							</div>
						</mat-expansion-panel>

						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Intro</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div>
								<h5>Background Image</h5>
								<font10>The image you choose will show behind your form.</font10>
								<div class="panel">
									<mat-radio-group class="afc-radio-group">
										<mat-radio-button class="afc-radio-button" [value]="0" (click)="pageBackgroundImageSelector(0)">
											<div class="dc-radio-text">
												<font08><p class="dc-first">Use the image from you ad</p></font08>
												<font10>The image from your ad will appear with your context card.</font10>
											</div>
										</mat-radio-button>
										<mat-radio-button class="afc-radio-button" [value]="1" (click)="pageBackgroundImageSelector(1)">
											<div class="dc-radio-text">
												<font08><p class="dc-first">Use uploaded image</p></font08>
												<font10>Reccommended image size: 1200 X 628 pixels</font10>
											</div>
										</mat-radio-button>
									</mat-radio-group>

									<div *ngIf="selectImageFromComputer">
										<app-file-upload requiredFileType="image/png" (getUploadedFilename)="getUploadedFilename($event)"></app-file-upload>
									</div>
								</div>
							</div>
							<div class="divider">
								<hr />
							</div>

							<div>
								<h5>
									Greeting •
									<small>Recommended</small>
								</h5>
								<font10>The image you choose will show behind your form.</font10>
								<div class="panel">
									<div class="form-container">
										<label class="form-label">Headline</label>
										<tb07 [placeHolderTextBox]="'Enter short headline'" [maxLength]="'60'"></tb07>
									</div>
									<div class="form-container">
										<label class="form-label">Description</label>
										<br />
										<mat-radio-group aria-label="Select an option">
											<mat-radio-button value="Paragraph" (click)="updateintroGreetingDescriptionListState('Paragraph')" class="radio">
												Paragraph &nbsp;&nbsp;
											</mat-radio-button>
											<mat-radio-button value="List" (click)="updateintroGreetingDescriptionListState('List')">List</mat-radio-button>
										</mat-radio-group>

										<div *ngIf="dialogForm.value.introGreetingDescriptionType === 'Paragraph'">
											<br />
											<ta01 placeholder="Include additional details" [rows]="'5'" [cols]="'30'"></ta01>
										</div>
										<div *ngIf="dialogForm.value.introGreetingDescriptionType === 'List'">
											<br />
											<div *ngFor="let control of ListFieldObjects.controls; let i = index; trackBy: trackByFn">
												<app-input-with-counter
													formControlName="ListFieldObjects.controls[i]"
													[min]="0"
													[max]="80"
													[placeholderText]="i === 0 ? 'Include additional details' : ''"
												></app-input-with-counter>
											</div>
											<p class="push-right">
												<br />
												<btn09 class="btn primary" [icon]="'trash'">Remove Greeting</btn09>
											</p>
										</div>
									</div>
								</div>
							</div>
						</mat-expansion-panel>

						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Questions</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div>
								<h5>Custom Questions</h5>
								<font10>Ask for responses to questions that matter to your business.</font10>
								<div>
									<div *ngFor="let item of customQuestionControls.controls; let i = index">
										<app-multiple-answer-question
											[itemIndex]="i"
											(removeQuestion)="removeCustomQuestion($event)"
											[placeholderText]="'Multiple Choice'"
											[expanded]="i >= customQuestionControls.controls.length - 1"
										></app-multiple-answer-question>
									</div>
									<div *ngIf="conditionalQstnControls.controls.length > 0">
										<div>
											<app-conditional-qstn
												[itemIndex]="0"
												(removeQuestion)="removeConditionQstn($event)"
												[placeholderText]="'Conditional'"
												[expanded]="true"
											></app-conditional-qstn>
										</div>
										<div></div>
									</div>
								</div>

								<div *ngIf="shortAnswerControls.controls.length > 0">
									<div class="divider">
										<hr />
									</div>
									<font10>
										Short Answer, Appointment Scheduling and Native Dealer Lookup can only be placed after other types of custom questions.
									</font10>
									<div *ngFor="let item of shortAnswerControls.controls; let i = index">
										<app-short-answer
											[formControl]="dialogForm"
											[itemIndex]="i"
											(removeQuestion)="removeCustomQuestion($event)"
											[placeholderText]="'Short Answer'"
											[expanded]="i >= customQuestionControls.controls.length - 1"
										></app-short-answer>
									</div>
								</div>

								<div *ngIf="appointmentRequestControls.controls.length > 0">
									<div class="divider">
										<hr />
									</div>
									<div *ngFor="let item of appointmentRequestControls.controls; let i = index">
										<app-appointment-request
											[formControl]="dialogForm"
											[itemIndex]="i"
											(removeQuestion)="removeAppointmentRequest($event)"
											[placeholderText]="'Appointment Request'"
											[expanded]="true"
										></app-appointment-request>
									</div>
								</div>
								<br />
								<br />
								<btn18 [data]="dropdownWithIcon" [icon]="'plus'" label="Add Question"></btn18>
							</div>

							<div class="divider">
								<hr />
							</div>
							<div>
								<h5>Prefill Questions</h5>
								Ask for user information. This will be prefilled from their Facebook account.
								<div class="panel">
									<div class="form-container">
										<label class="label-control">Description</label>
										<font10>
											Let people know how the information they give you will be used or shared.
											<a>See Examples</a>
										</font10>
										<tb06 [control]="dialogForm.get('prefileQuestionObjects')" [placeHolderTextBox]="'Enter a message'"></tb06>
										<tb06 [placeHolderTextBox]="'Enter a message'"></tb06>
									</div>
									<div class="form-container">
										<label class="label-control">Choose the type of information you need</label>
									</div>
									<btn18 [data]="dropdownWithIcon" [icon]="'plus'">
										Add Question
									</btn18>
								</div>
							</div>
						</mat-expansion-panel>

						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Privacy</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div>
								<h5>Privacy Policy</h5>
								<font10>
									Because you will be collecting customer information, you need to include a link to your company's privacy policy. Your link
									will appear with Facebook's default privacy disclaimer.
								</font10>
								<div class="panel">
									<div class="form-container">
										<label class="form-label">Link</label>
										<tb06
											[control]="dialogForm.get('privacyLabelLink')"
											[placeHolderTextBox]="'Add a link'"
											[icon]="'fa fa-info-circle'"
											[onFocus]="addPrivacyLinkInfoEvent()"
											[onBlur]="removePrivacyLinkInfoEvent()"
										></tb06>
									</div>
									<div class="panel linkinfo" *ngIf="showPrivacyLinkInfo">
										<font10>
											Your privacy policy link can't go directly to a PDF file, JPEG file, image or download.
											<a href="#">Learn More</a>
										</font10>
									</div>
									<div class="form-container">
										<label class="form-label">Link text</label>
										<tb06 [control]="dialogForm.get('privacyLabelLinkText')" [placeHolderTextBox]="'Link text'"></tb06>
									</div>
								</div>
							</div>

							<div class="divider">
								<hr />
							</div>

							<div>
								<h5>Custom Disclaimer</h5>
								<font10>
									You can add additional notices like marketing opt-ins and legal disclaimers to the default Facebook privacy disclaimer.
								</font10>
								<div class="panel" *ngIf="showDisclaimer">
									<div class="form-container">
										<label class="form-label">Title</label>
										<tb06
											[control]="dialogForm.get('privacyCustomerDisclaimerTitle')"
											placeHolderTextBox="Terms and Conditions for ..."
										></tb06>
									</div>
									<div class="form-container">
										<label class="form-label">Text</label>
										<p>To add a link, highlight the words you want to link.</p>
										<tb06
											[control]="dialogForm.get('privacyCustomerDisclaimerTitleText')"
											placeHolderTextBox="Add disclaimer (Required)"
										></tb06>
									</div>
									<div>
										<app-custome-disclaimer
											*ngFor="let item of privacyCustomerDisclaimerConsents.controls; let i = index"
											[name]="'Consent_' + (i + 1)"
											[placeholderText]="'Required'"
											(removeConsent)="removeConsent($event)"
										></app-custome-disclaimer>
									</div>
									<div>
										<btn09 (onClick)="addConsent()" [icon]="'plus'">Add custom disclaimer</btn09>
									</div>
								</div>
								<div fxLayout>
									<div fxFlex="50" *ngIf="!showDisclaimer">
										<btn03 (onClick)="addDisclaimer()" [icon]="'plus'">Add custom disclaimer</btn03>
									</div>
									<div fxFlex="50" *ngIf="showDisclaimer">
										<btn03 (onClick)="removeDisclaimer()" [icon]="'fa fa-trash'">Remove custom disclaimer</btn03>
									</div>
								</div>
							</div>
						</mat-expansion-panel>

						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Completion</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div>
								<h5>Message for Leads</h5>
								<font10>Let people who submit their information know what to expect next.</font10>
								<div class="panel">
									<div class="form-container">
										<label class="form-label">Headline</label>
										<tb07 [maxLength]="60" placeHolderTextBox="Thanks, you're all set."></tb07>
									</div>
									<div class="form-container">
										<label class="form-label">Description</label>
										<ta01
											placeholder="sample text area"
											[rows]="5"
											[cols]="30"
											[disabled]="false"
											[readonly]="false"
											required="true"
										></ta01>

										<textarea class="textarea-control" placeholder="Required">
                                            You can visit our website or exit the form now.
                                        </textarea>
									</div>
									<div class="form-container">
										<label class="form-label">Call-To-Action Button</label>
										<drp08 [data]="[]"></drp08>
									</div>
									<div class="form-container">
										<label class="form-label">Call-To-Action Text</label>
										<tb06 [control]="dialogForm.get('completionCallToActionText')"></tb06>
									</div>
									<div class="form-container">
										<label class="form-label">Link</label>
										<tb06 [control]="dialogForm.get('completionLabelLink')"></tb06>
									</div>
								</div>
							</div>
							<div class="divider">
								<hr />
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<div [hidden]="!isShowGeneralContentTab">
					<mat-accordion>
						<mat-expansion-panel [expanded]="true">
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Form Configuration</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div>
								<div class="form-container">
									<label class="form-label">Language</label>
									<drp08 [data]="[]"></drp08>
								</div>
								<div class="form-container">
									<label class="form-label">Sharing</label>
									<mat-radio-group class="oc-sub-objectives-wrapper">
										<div class="oc-radio-btn-container oc-sub-objective-radio-btn">
											<mat-radio-button (click)="updateLanguageSharing('Restricted')" [value]="'Restricted'" name="sharing">
												<span class="oc-bold-text">Restricted</span>
											</mat-radio-button>
											<span class="oc-radio-btn-description">- Only people who are delivered your ad directly can submit this form.</span>
										</div>
										<div class="oc-radio-btn-container oc-sub-objective-radio-btn">
											<mat-radio-button (click)="updateLanguageSharing('Open')" [value]="'Open'" name="sharing">
												<span class="oc-bold-text">Open</span>
											</mat-radio-button>
											<span class="oc-radio-btn-description">- Your ad can be shared and anyone can submit this form.</span>
										</div>
									</mat-radio-group>
								</div>
							</div>
						</mat-expansion-panel>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Field Names</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div>
								<font10>Change how your field names appear when you export files.</font10>
								<div class="form-container">
									<label class="form-label">Email</label>
									<tb06 [control]="dialogForm.get('formConfigurartionFieldnameEmail')" [label]="" [placeHolderTextBox]="'email'"></tb06>
								</div>
								<div class="form-container">
									<label class="form-label">Full name</label>
									<tb06
										[control]="dialogForm.get('formConfigurartionFieldnameFullname')"
										[label]=""
										[placeHolderTextBox]="'full_name'"
									></tb06>
								</div>
							</div>
						</mat-expansion-panel>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									<h5>Tracking Parameters</h5>
								</mat-panel-title>
							</mat-expansion-panel-header>
							<div>
								<font10>
									You can include tracking parameters to help you identify where your leads come from. These fields won't appear in the form
									users see when they submit their info, but you'll be able to see it when you access your leads via API, Download, or
									configured CRM.
								</font10>
								<app-tracking-parameter
									*ngFor="let item of settingLanguageParameter.controls; let i = index"
									[showLabel]="true"
									[itemIndex]="i"
									(removeParameter)="removeLanguageParameter($event)"
								></app-tracking-parameter>
								<btn03 class="btn" (click)="addLanguageParameter()" [icon]="'plus'">Add Parameter</btn03>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</div>
			<div fxFlex="5"></div>
			<div fxFlex="28">
				<img alt="cameraOff" src="/assets/icons/cameraOff.svg" />
			</div>
		</div>
	</div>
</div>

<mat-dialog-actions fxLayout class="dialog-footer">
	<div fxFlex="85"></div>
	<div fxFlex="15">
		<btn06 (onClick)="onSubmit()" [icon]="'save'">Save & Continue</btn06>
	</div>
</mat-dialog-actions>
