import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CallToActionEnum } from '../../../../campaign-smart-create/models/call-to-action.enum';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';

import { dynamicInputDropDownInterface } from '@filed-com/filed-lib/lib/inputs/input.interface';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { MatDialog } from '@angular/material/dialog';
import { CampaignSmartCreateState } from 'src/app/campaign-smart-create/state/smart-create.reducer';
import { MapperService } from 'src/app/shared/services/mapper.service';
import { getCallToAction, getTemplateFieldsFromCatalog } from 'src/app/state/campaign-common-state/campaign-common-reducer';
import { SubObjectives } from 'src/app/campaign-smart-create/models/sub-objectives';
import { Placements } from '../ad-format/ad-creation-card/ad-creation.placement.model';

@Component({
	selector: 'app-app-install-card.component',
	templateUrl: './app-install-card.component.html',
	styleUrls: ['./app-install-card.component.scss']
})
export class AppInstallCardComponent implements OnInit {
	@Input() public formGroup: FormGroup;
	@Input() public callToActions: DropdownData<CatalogNodeInterface>[] = [];
	@Input() public isPageLikesSelected: boolean;
	@Input() public isPostLikesSelected: boolean;
	@Input() public adFormat: AdFormatsEnum;
	@Input() public adCreateFormGroup: FormGroup;
	@Input() public cardIndex: number;
	@Input() public subObjective: string;
	@Output() public onadCreateFormBuilder = new EventEmitter<FormGroup>();

	public isCtaSelected: boolean;
	public isAppInstalled: boolean = false;
	public adFormatEnum = AdFormatsEnum;
	private unsubscriber$ = new Subject<void>();
	public formBuilder: FormBuilder;
	public primaryTextForm: FormArray;

	constructor(private dialog: MatDialog, public mapperService: MapperService, private store: Store<CampaignSmartCreateState>, public injector: Injector) {
		this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
	}

	public ngOnInit(): void {
		alert('deji');
		this.getCallToAction();
		this.unwantedCallToActions();
		this.getTemplateFields();
		this.setIntroGreetingDescriptionTypeList();
		alert(JSON.stringify(this.getAdCardForms()));
	}

	public ngOnChanges(): void {
		this.isAppInstalled = this.subObjective === SubObjectives.AppInstallText;
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public initOnDefault(): void {
		//this.adCreateFormGroup = this.initFormGroup();
		//this.onadCreateFormBuilder.emit(this.adCreateFormGroup);
	}

	private initFormGroup(): FormGroup {
		const formGroup = this.formBuilder.group({
			primary_text: this.formBuilder.array([]),
			headline: this.formBuilder.array([]),
			displayLink: new FormControl(''),
			call_to_action: new FormControl('', [Validators.required]),
			media_url: new FormControl(''),
			picture: new FormControl(''),
			video_id: new FormControl(0),
			mediaType: new FormControl('')
		});
		return formGroup;
	}

	public unwantedCallToActions(): void {
		this.callToActions = this.callToActions.filter(item => item.value !== CallToActionEnum.WhatsappMessage);
	}

	public getAdCardForms(): AbstractControl[] {
		const advertItemsForm = this.formGroup?.get('adCardsItemData') as FormArray;
		return advertItemsForm ? advertItemsForm.controls : null;
	}
	public getPrimaryTextForms(): AbstractControl[] {
		const advertItemsForm = this.getAdCardForms()[0].get('primary_text') as FormArray;
		return advertItemsForm ? advertItemsForm.controls : null;
	}
	public addPrimarytextFieldObjects(): void {
		this.primaryTextForm.push(new FormControl(''));
	}
	private setIntroGreetingDescriptionTypeList(): void {
		const typeList: FormArray = new FormArray([]);
		for (let i = 0; i < 5; i++) {
			this.addPrimarytextFieldObjects();
		}
		// typeList.push(new FormControl(''));
		//this.dialogForm.patchValue({ introGreetingDescriptionTypeList: typeList });
	}

	public getCallToAction(): void {
		if (!this.callToActions) {
			this.store
				.pipe(
					select(getCallToAction),
					skipWhile(result => !result),
					take(1)
				)
				.subscribe(callToActions => {
					this.callToActions = this.mapperService.convertToDropdownData(callToActions, 'name', 'displayNameAutogen');
				});
		}
	}

	private getTemplateFields(): void {
		if (this.subObjective === SubObjectives.AppInstallText) {
			this.store.pipe(select(getTemplateFieldsFromCatalog), takeUntil(this.unsubscriber$)).subscribe((res: any) => {
				if (res) {
					res.pop();
					const convertData: dynamicInputDropDownInterface[] = [];
					// @ts-ignore
					res.forEach(item => {
						if (item) {
							const dd: dynamicInputDropDownInterface = {
								data: item,
								value: item?.name,
								name: item.display_name_autogen,
								text: item.display_name_autogen
							};
							convertData.push(dd);
						}
					});
					//this.catalogTemplateFields = convertData;
				}
			});
		}
	}
}
