<app-expansion-panel-sc
	[isSelected]="isPanelSelected"
	[expansionTitle]="'Devices'"
	[isExpandable]="false"
	[infoHoverModel]="{ message: 'Explanation text goes here' }"
>
	<div expansion-content>
		<div class="row">
			<div class="device-card" (click)="selectDevice(DeviceEnum.all)" [class.active]="selectedDevice?.length > 1">
				<div class="icon">
					<img src="assets/icons/aaa/Icon material-devices.svg" class="icon--default" alt="all device icon" />
					<img src="assets/icons/aaa/Icon material-devices-selected.svg" class="icon--selected" alt="all device icon" />
				</div>
				<div class="text">All Devices</div>
			</div>
			<div class="device-card" (click)="selectDevice(DeviceEnum.mobile)" [class.active]="isSelected(DeviceEnum.mobile)">
				<div class="icon">
					<img src="assets/icons/aaa/Icon awesome-mobile-alt.svg" class="icon--default" alt="all device icon" />
					<img src="assets/icons/aaa/Icon awesome-mobile-alt-selected.svg" class="icon--selected" alt="all device icon" />
				</div>
				<div class="text">Mobile</div>
			</div>
			<div class="device-card" (click)="selectDevice(DeviceEnum.desktop)" [class.active]="isSelected(DeviceEnum.desktop)">
				<div class="icon">
					<img src="assets/icons/aaa/Icon awesome-desktop.svg" class="icon--default" alt="all device icon" />
					<img src="assets/icons/aaa/Icon awesome-desktop-selected.svg" class="icon--selected" alt="all device icon" />
				</div>
				<div class="text">Desktop</div>
			</div>
		</div>
		<div class="vertical-spacing fadeIn" *ngIf="!isSelected(DeviceEnum.desktop)">
			<div>
				<font07>Mobile Devices and Operating System</font07>
			</div>
			<div class="vertical-spacing">
				<drp01 (valueChanges)="dropDownValueChanges($event)" [data]="dropDownListItems" [control]="dropDownControl" [valuePrimitive]="true"></drp01>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
