<div class="header_icons">
	<button (click)="openKnowledgeBase()" class="help-button" mat-button matTooltip="{{ 'HELP' | translate }}" matTooltipClass="button-custom-tooltip">
		<em class="fas fa-question-circle" style="color: white; font-size: 20px;"></em>
	</button>
	<button
		#menuTrigger="matMenuTrigger"
		[matMenuTriggerFor]="menu"
		[class.account-button-active]="showUserManagement"
		mat-button
		matTooltip="{{ 'ACCOUNT' | translate }}"
		matTooltipClass="button-custom-tooltip"
	>
		<em class="fas fa-user-circle" style="color: white; font-size: 20px;">
			<mat-menu #menu="matMenu" class="custom_mat_menu">
				<div class="wrapper-dropdown-buttons">
					<app-button
						(buttonClicked)="goToUserPage()"
						[buttonClass]="buttonClassEnum.Tertiary"
						[buttonType]="buttonTypeEnum.Simple"
						buttonLabel="SETTINGS"
						*ngIf="dropdownIsVisible"
					></app-button>
					<app-button
						(buttonClicked)="logout()"
						[buttonClass]="buttonClassEnum.Secondary"
						[buttonType]="buttonTypeEnum.Simple"
						buttonLabel="SIGN OUT"
					></app-button>
				</div>
			</mat-menu>
		</em>
	</button>
</div>
