import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ScrollService {
	private scrollY = new Subject<number>();
	public currentScrollY = this.scrollY.asObservable();

	public changeScrollY(scrollY: number): void {
		this.scrollY.next(scrollY);
	}
}
