import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonClassEnum } from '../button-component/button-class.enum';
import { ButtonTypeEnum } from '../button-component/button-type.enum';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
	@Input() public message: string;
	@Input() public btnCancelText: string;
	@Input() public btnCancelColor: string;
	@Input() public btnOkText: string;
	@Input() public btnOkColor: string;
	@Input() public title: string;

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}

	ngOnInit() {}

	public decline() {
		this.dialogRef.close(false);
	}

	public accept() {
		this.dialogRef.close(true);
	}
}
