<form [formGroup]="addBusinessManager">
	<h3 *ngIf="addBusinessManager.get('businessManager')">{{ getBusinessNameById(addBusinessManager.get('businessManager').value) }}</h3>
	<div *ngIf="index" class="removeB" (click)="removeBusiness()">x</div>
	<div class="grid">
		<app-dropdown-search-select
			[dropdownData]="businesses"
			[dropdownFormControl]="addBusinessManager.get('businessManager')"
			[dropdownPlaceholder]="'Search...'"
			[validatorMessages]="businessValidator"
		></app-dropdown-search-select>
		<app-dropdown-search-select
			[dropdownData]="businessesAccessLevels"
			[dropdownFormControl]="addBusinessManager.get('businessManagerAccessLevel')"
			[dropdownPlaceholder]="'Search...'"
			[validatorMessages]="accesLevelValidator"
		></app-dropdown-search-select>
	</div>
	<h6>Choose an access Level:</h6>
	<mat-radio-group *ngIf="optionsForm" [formGroup]="optionsForm">
		<div>
			<mat-radio-button (change)="radioChange($event)" value="1"></mat-radio-button>
			{{ 'ADD_TO_ALL_AD_ACCOUNTS' | translate }}
		</div>
		<div class="radio2">
			<mat-radio-button (change)="radioChange($event)" checked value="2"></mat-radio-button>
			{{ 'ADD_TO_INDIVIDUAL_ADD_ACCOUNTS' | translate }}
		</div>
	</mat-radio-group>
	<ng-container *ngIf="facebookAdAccountPermissions">
		<ng-container *ngFor="let adAccountPermission of facebookAdAccountPermissions; let i = index">
			<app-manage-ad-account-permissions
				[radioValue]="radioValue"
				[adAccountPermission]="adAccountPermission"
				[adAccountsLevels]="adAccountAccessLevels"
				[adAccounts]="fullAdAccounts"
				[fullAdAccounts]="adAccounts"
				[index]="i"
			></app-manage-ad-account-permissions>
			<div (click)="removeAdAccount(adAccountPermission.name)" [ngClass]="{ hide: checkPlus() }"><p class="x-button">x</p></div>
		</ng-container>
	</ng-container>
	<div (click)="selectNextAdAccount()" [ngClass]="{ hide: checkPlus() }" class="plus btn-add-adAccount">
		<p>+</p>
	</div>
</form>

<div *ngIf="!index" class="wrapper-buttons">
	<div appClickOutside (clickOutside)="onOutsideClick()" (click)="toggleMenuBO()" class="plus open-menu">
		<p>+</p>
		<div *ngIf="showMenuBO" (click)="addBusiness()" class="menu">
			<p>Add new Business Manager</p>
		</div>
	</div>
</div>
