import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { LogIn, RegisterUserEmail, RegisterUserPassword, RegisterUserToken, RegistrationDetail } from '../../state/authentication.action';
import { AuthenticationState, getUserRegistrationDetail } from '../../state/authentication.reducer';
import { SetPassword } from 'src/app/_models/identity-models/set-password';
import { LogInModel, UserDetailModel } from '../../_models/login.model';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { getUserDetails } from 'src/app/shared/state/user/user.reducer';
import { Ntf01Service } from '@filed-com/filed-lib';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { CustomValidators } from './custom-validators';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	public passwordControl: FormControl;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public submitting: boolean = false;
	public passwordIsValid: boolean = false;
	public stepToMark: number = 0;

	public minimumChar: boolean = false;
	public upperChar: boolean = false;
	public numberChar: boolean = false;
	public specialChar: boolean = false;
	public allValidated: boolean = false;

	public validColor: string;

	public registrationDetail: UserDetailModel;
	public token: string;
	public emailValue: string;

	public label: string = 'Enter your password';

	constructor(
		private router: Router,
		private store: Store<AuthenticationState>,
		private userServiceApi: UserServiceApi,
		private toastNotificationService: Ntf01Service,
		private sharedStore: Store<SharedState>,
		private authStore: Store<AuthenticationState>,
		private toastService: ToastNotificationService,
		private activatedRoute: ActivatedRoute
	) {}

	public ngOnInit(): void {
		this.passwordControl = new FormControl(
			null,
			Validators.compose([
				Validators.required,
				CustomValidators.patternValidator(/\d/, {
					hasNumber: true
				}),
				CustomValidators.patternValidator(/[A-Z]/, {
					hasCapitalCase: true
				}),
				CustomValidators.patternValidator(/[a-z]/, {
					hasSmallCase: true
				}),
				CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
					hasSpecialCharacters: true
				}),
				Validators.minLength(8)
			])
		);
		this.formChanges();
		this.store.dispatch(
			new RegistrationDetail({
				name: '',
				lastname: '',
				phone: 0,
				dialCode: '',
				email: '',
				password: '',
				plan: 0,
				duration: '',
				token: '',
				companyName: ''
			})
		);
		this.getActivatedRoute();
		this.getRegistrationDetails();
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.token = encodeURIComponent(params['token']);
			this.emailValue = decodeURIComponent(params['email']);
			this.store.dispatch(new RegisterUserEmail(this.emailValue));
			this.store.dispatch(new RegisterUserToken(this.token));
		});
	}

	public ngOnDestroy(): void {}

	public formChanges(): void {
		this.passwordControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.checkForInvalid();
		});
	}

	public checkForInvalid(): void {
		const password = this.passwordControl.value;
		if (password === undefined || password === '') {
			this.passwordControl.setErrors({ dialCode: true });
			this.minimumChar = false;
			this.upperChar = false;
			this.numberChar = false;
			this.specialChar = false;
		}
	}
	public passwordValid(event: boolean): void {
		this.passwordIsValid = event;
	}
	public passwordStepMarker(event: string[]): void {
		this.allValidated = false;
		this.minimumChar = false;
		this.upperChar = false;
		this.numberChar = false;
		this.specialChar = false;
		this.validColor = '';

		if (event.includes('minimum')) {
			this.minimumChar = true;
		}
		if (event.includes('uppers')) {
			this.upperChar = true;
		}

		if (event.includes('numbers')) {
			this.numberChar = true;
		}

		if (event.includes('symbols')) {
			this.specialChar = true;
		}

		if (this.minimumChar && this.upperChar && this.numberChar && this.specialChar) {
			this.allValidated = true;
			this.validColor = '#000000'; //'#34D24A';
		}
	}
	public getRegistrationDetails(): void {
		this.store.pipe(select(getUserRegistrationDetail), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.registrationDetail = detail;
				this.passwordControl.setValue(detail.password);
			}
		});
	}
	public resetLabel(): void {
		this.label = '';
	}
	public continueSignup(): void {
		this.store.dispatch(new RegisterUserPassword(this.passwordControl.value));
		this.onSubmit();
	}
	public onSubmit(): void {
		if (this.passwordControl.invalid) {
			return;
		}
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.submitting = true;
		const newPassword: SetPassword = {
			EmailAddress: this.registrationDetail.email,
			password: this.passwordControl.value.trim(),
			confirmPassword: this.passwordControl.value.trim(),
			TwoFactorToken: this.registrationDetail.token
		};

		this.userServiceApi
			.setPassword(newPassword)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					const loginPayload: LogInModel = {
						email: this.registrationDetail.email,
						password: this.passwordControl.value.trim()
					};
					localStorage.setItem('loginDetail', JSON.stringify(loginPayload));

					this.authStore.dispatch(new LogIn(loginPayload));
					this.authStore
						.select(getUserDetails)
						.pipe(
							takeUntil(this.unsubscriber$),

							catchError(error => {
								this.errorMessage = 'There was an error verifying your user credentials. Please contact support.';
								this.toastNotificationService.sendErrorToast('There was an error verifying your user credentials. Please contact support.');
								this.sharedStore.dispatch(new HideGlobalSpinner());
								this.submitting = false;
								return EMPTY;
							})
						)
						.subscribe(user => {
							if (user) {
								this.userServiceApi
									.checkIfSubUser()
									.pipe(takeUntil(this.unsubscriber$))
									.subscribe(isSubUser => {
										if (isSubUser) {
											this.submitting = false;
											this.router.navigate(['/accounts']);
										} else {
											this.submitting = false;
											this.sharedStore.dispatch(new HideGlobalSpinner());
											// routing from LogIn Effect by dispatcher
											// this.router.navigate(['/authentication/personal-welcome']);
										}
									});
							}
						});
				},
				({ error }) => {
					if (error[0].code == 'Filed__Domain__NA__User__IsNotFoundByCriteria') {
						this.errorMessage = 'User not found!';
					} else if ((error[0].code = 'Filed__Domain__NA__FirstPassword__Exists')) {
						this.errorMessage = 'You had already set your password. Kindly login or reset your password.';
					} else {
						this.errorMessage = 'There was an error creating your password. Please check and try again!';
					}
					this.toastService.sendCustomToast(this.errorMessage, ToastNotificationType.Error, 8000, 'Password Setup!');
					this.submitting = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
