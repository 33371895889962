<div class="page-body">
	<div class="body">
		<div class="back-btn">
			<p (click)="goBacks()">
				<i class="k-icon k-i-arrow-chevron-left"></i>
				Back
			</p>
		</div>
		<div class="title"></div>
		<div class="body-form">
			<div class="form-area">
				<div class="forms">
					<div>
						<label class="label">
							Select Account Type
						</label>
						<div class="selected-boxes">
							<div class="checkedbox" (click)="businessManagerSelect()" [class.active]="selectedType === usersSelectedTypeEnum.BusinessManager">
								Business
							</div>

							<div class="checkedbox" (click)="adAccountsSelect()" [class.active]="selectedType === usersSelectedTypeEnum.AdAccounts">
								Ad Account
							</div>
						</div>
					</div>

					<div>
						<label class="label">
							Selected Account
						</label>
						<div class="input-group">
							<input type="text" class="form-control" placeholder="Search" [formControl]="boSearchFormControl.controls['searchFormBoInfo']" />
							<div class="icon">
								<span class="k-icon k-i-search"></span>
							</div>
						</div>
						<div class="multi-selection">
							<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
							<div *ngIf="selectedType === usersSelectedTypeEnum.BusinessManager">
								<div *ngFor="let account of filteredBOInfo; let i = index">
									<p>
										<input type="checkbox" (change)="checkBoxBOChange(account)" [checked]="account.checked" />
										{{ account.name }}
									</p>
								</div>
							</div>
							<div *ngIf="selectedType === usersSelectedTypeEnum.AdAccounts">
								<div *ngFor="let account of filteredAdAccounts; let i = index">
									<p>
										<input type="checkbox" (change)="checkBoxChange(account)" [checked]="account.checked" />
										{{ account.name }}
									</p>
								</div>
							</div>
						</div>
						<div class="multi-buttons">
							<button class="btn-gcb-01" (click)="checkAll()">SELECT ALL</button>
						</div>

						<div class="selected-acct">
							<label class="label">
								Selected AdAccount
							</label>
							<div class="selected-ads">
								<div class="single-selected-button" *ngFor="let selectedAd of selectedAdAccounts; let i = index">
									{{ selectedAd.name }}
									<img src="assets/icons/removeicon.svg" (click)="removeSelected(selectedAd)" alt="" />
								</div>
							</div>
						</div>

						<div class="multi-buttons">
							<button class="btn-gcb-01" (click)="onContinueEdit()">CONTINUE</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
