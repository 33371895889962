export class BarChart {
	private readonly switchToLegendThreshhold: number = 10;
	private readonly enableOverflow: boolean = false;
	private mappingsNumber: number;
	public option: any;

	constructor(chartTitle: string, xAxisBenchmarks: any[], yAxisValues: number[], xAxisTitle: string = ' ', yAxisTitle: string = ' ') {
		this.mappingsNumber = xAxisBenchmarks.length;

		if (this.enableOverflow && this.mappingsNumber < this.switchToLegendThreshhold) {
			xAxisBenchmarks = this.applyOverflow(xAxisBenchmarks);
		}

		const colors = this.getRandomColors(this.mappingsNumber);
		const barData: ChartActor[] = [];

		for (let i = 0; i < this.mappingsNumber; ++i) {
			const spacedData: number[] = new Array(this.mappingsNumber).fill('');
			spacedData[i] = yAxisValues[i];
			barData.push({
				name: this.mappingsNumber > this.switchToLegendThreshhold ? '' : xAxisBenchmarks[i],
				type: 'bar',
				data: spacedData,
				stack: 'stack'
			});
		}

		this.option = {
			title: {
				text: chartTitle
			},
			toolbox: {
				feature: {
					saveAsImage: {
						name: chartTitle,
						title: ' '
					}
				}
			},
			legend:
				this.mappingsNumber > this.switchToLegendThreshhold
					? {
							data: xAxisBenchmarks,
							maxKeyLength: 100
					  }
					: '',
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow' // on hover behaviour :'line' | 'shadow'
				}
			},
			grid: {
				left: '7%',
				right: '6%',
				bottom: '10%',
				containLabel: true
			},
			xAxis: [
				{
					name: xAxisTitle,
					axisLabel: {
						formatter: (value: any) => {
							return value.length > 15 ? value.substr(0, 14) + '...' : value;
						}
					},
					type: 'category',
					data: this.mappingsNumber > this.switchToLegendThreshhold ? new Array(this.mappingsNumber).fill(' ') : xAxisBenchmarks,
					axisTick: {
						alignWithLabel: true,
						autoSkip: false
					}
				}
			],
			yAxis: [
				{
					name: yAxisTitle,
					type: 'value'
				}
			],
			series: barData
		};
	}

	private applyOverflow(xAxisBenchmarks: any[]): string[] {
		const labels = <string[]>xAxisBenchmarks;
		const shortenedLabels: string[] = [];
		for (const label of labels) {
			shortenedLabels.push(label.substr(0, 8) + '...');
		}
		return shortenedLabels;
	}

	private getRandomColors(count: number): string[] {
		const saturation = 100;
		const value = 100;
		const interval = 360 / (count - 1);
		const colors: string[] = [];

		for (let i = 0; i < count; ++i) {
			const hue = interval * i;
			const hexa = this.hsvToHexa(hue, saturation, value);
			colors.push(hexa);
		}

		return colors;
	}

	private hsvToHexa(hue: number, saturation: number, value: number): string {
		let r, g, b: number;
		let p, q, t: number;
		saturation /= 100;
		value /= 100;

		if (saturation === 0) {
			r = g = b = value;
			return this.rgbToHexa(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255));
		}

		hue /= 60;
		const intPart = Math.floor(hue);
		const fractionalPart = hue - intPart;
		p = value * (1 - saturation);
		q = value * (1 - saturation * fractionalPart);
		t = value * (1 - saturation * (1 - fractionalPart));

		switch (intPart) {
			case 0:
				r = value;
				g = t;
				b = p;
				break;

			case 1:
				r = q;
				g = value;
				b = p;
				break;

			case 2:
				r = p;
				g = value;
				b = t;
				break;

			case 3:
				r = p;
				g = q;
				b = value;
				break;

			case 4:
				r = t;
				g = p;
				b = value;
				break;

			default:
				// case 5:
				r = value;
				g = p;
				b = q;
		}

		return this.rgbToHexa(r, g, b);
	}

	private rgbToHexa(red: number, green: number, blue: number): string {
		const letters = '0123456789ABCDEF';
		let color = '#';
		color += letters[red / 16] + letters[red % 16];
		color += letters[green / 16] + letters[green % 16];
		color += letters[blue / 16] + letters[blue % 16];
		return color;
	}
}

interface ChartActor {
	name: string;
	data: number[];
	type: string;
	stack: string;
}
