import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { ExportCatalog } from 'src/app/export-catalog/service/export-catalog.service';
import { SharedService } from '../service/shared.service';

@Injectable()
export class ExportCatalogRedirectResolver implements Resolve<any> {
	constructor(private router: Router, private exportCatalog: ExportCatalog, private exportStore: Store<any>, private _sharedService: SharedService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
		window.localStorage.setItem('authwindow', 'true');
		window.close();
	}
}
