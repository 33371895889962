<div class="retargeting">
	<div class="option__list">
		<mat-radio-group [formControl]="reTargetingControl" class="mat-radio-group">
			<div class="option__item">
				<mat-radio-button [value]="retargetingTypeEnum.viewAddToCart" name="retarget">
					<font07>Viewed or Added to cart but not purchased</font07>
				</mat-radio-button>
				<div class="text">
					<span>in the last</span>
					<span class="days">
						<ntb01
							[control]="retargetingFormGroup.get('viewed_added_not_purchased_control')"
							[placeHolderTextBox]="placeholder"
							[validatorMessages]="validationMessages"
							[format]="'0'"
						></ntb01>
					</span>
					<span>days</span>
				</div>
			</div>
			<div class="option__item">
				<mat-radio-button [value]="retargetingTypeEnum.addToCart" name="retarget">
					<span class="oc-bold-text">Added to cart but not purchased</span>
				</mat-radio-button>
				<div class="text">
					<span>in the last</span>
					<span class="days">
						<ntb01
							[control]="retargetingFormGroup.get('added_not_purchased_control')"
							[placeHolderTextBox]="placeholder"
							[validatorMessages]="validationMessages"
							[format]="'0'"
						></ntb01>
					</span>
					<span>days</span>
				</div>
			</div>
			<div class="option__item">
				<mat-radio-button [value]="retargetingTypeEnum.upsale" name="retarget">
					<span class="oc-bold-text">Up-sell products</span>
				</mat-radio-button>
				<div class="text">
					<span>to people who viewed your product set in the last</span>
					<span class="days">
						<ntb01
							[control]="retargetingFormGroup.get('up_sell_products_control').get('daysValue')"
							[placeHolderTextBox]="placeholder"
							[validatorMessages]="validationMessages"
							[format]="'0'"
						></ntb01>
					</span>
					<span>days</span>
					<div class="py-2">
						<drp01
							[data]="productCatalogSets"
							[valuePrimitive]="true"
							placeholder="Choose Catalog Set"
							[control]="retargetingFormGroup.get('up_sell_products_control').get('productSetId')"
						></drp01>
					</div>
				</div>
			</div>
			<div class="option__item">
				<mat-radio-button [value]="retargetingTypeEnum.crossSales" name="retarget">
					<font07>Cross-sell products</font07>
				</mat-radio-button>
				<div class="text">
					<span>to people who purchased your product set in the last</span>
					<span class="days">
						<ntb01
							[control]="retargetingFormGroup.get('cross_sell_products_control').get('daysValue')"
							[placeHolderTextBox]="placeholder"
							[validatorMessages]="validationMessages"
							[format]="'0'"
						></ntb01>
					</span>
					<span>days</span>
					<div class="py-2">
						<drp01
							[data]="productCatalogSets"
							[valuePrimitive]="true"
							placeholder="Choose Catalog Set"
							[control]="retargetingFormGroup.get('cross_sell_products_control').get('productSetId')"
						></drp01>
					</div>
				</div>
			</div>
			<div class="option__item" [hidden]="true">
				<mat-radio-button value="custom" [disabled]="true" name="retarget">
					<font07>Custom combination</font07>
				</mat-radio-button>
				<div class="text">
					<span>Promote All Products to a custom audience based on how people interact with products</span>
				</div>
			</div>
		</mat-radio-group>
		<div class="customCominationForm" [formGroup]="customCombinationForm" *ngIf="retargetingControl.value == 'custom'">
			<div class="option__item">
				<font07>Interacted With Products From: {{ radioValue }}</font07>
				<tb01 [control]="productsControl" placeHolderTextBox="All Products" [validatorMessages]="validationMessages"></tb01>
			</div>

			<div class="inclusion" [hidden]="true">
				<p class="oc-bold-text">Included Audience Interactions</p>
				<div class="" formArrayName="inclusionForm" *ngFor="let inclusionForm of inclusionForms.controls; let i = index">
					<div class="form-group" [formGroupName]="i">
						<span class="oc-bold-text">product</span>
						<div class="form">
							<drp01 (valueChanges)="handleSelection()" [data]="products" [control]="product" [valuePrimitive]="true"></drp01>
							<div class="text-field">
								<span>in the last &nbsp;</span>
								<span class="days">
									<ntb01
										[control]="days"
										[placeHolderTextBox]="placeholder"
										[validatorMessages]="validationMessages"
										[min]="0"
										[format]="0"
									></ntb01>
								</span>
								<span>&nbsp;days</span>
								<span class="close-btn" (click)="removeInclusionForm(i)">&times;</span>
							</div>
						</div>
					</div>
				</div>
				<button class="btn--inline" (click)="addInclusionForm()">
					<img src="assets/icons/campaign-smart-create/add-icon.svg" alt="" />
					<span>Add exclusion</span>
				</button>
			</div>

			<div class="exclusion" [hidden]="true">
				<p class="oc-bold-text">But Not</p>
				<div class="" formArrayName="inclusionForm" *ngFor="let exclusionForm of exclusionForms.controls; let i = index">
					<div class="form-group" [formGroupName]="i">
						<span class="oc-bold-text">product</span>
						<div class="form">
							<drp01 (valueChanges)="handleSelection()" [data]="products" [control]="product" [valuePrimitive]="true"></drp01>
							<div class="text-field">
								<span>in the last &nbsp;</span>
								<span class="days">
									<ntb01
										[control]="days"
										[placeHolderTextBox]="placeholder"
										[validatorMessages]="validationMessages"
										[min]="0"
										[format]="0"
									></ntb01>
								</span>
								<span>&nbsp;days</span>
								<span class="close-btn" (click)="removeExclusionForm(i)">&times;</span>
							</div>
						</div>
					</div>
				</div>
				<button class="btn--inline" (click)="addExclusionForm()">
					<img src="assets/icons/campaign-smart-create/add-icon.svg" alt="" />
					<span>Add exclusion</span>
				</button>
			</div>
		</div>
	</div>
</div>
