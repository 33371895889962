import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
import { Options } from 'ng5-slider';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FacebookInterests } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';
import { CampaignSmartCreateState } from '../../state/smart-create.reducer';
import { Store } from '@ngrx/store';
import { UpdateSplitByInterests } from '../../state/smart-create.actions';

@Component({
	selector: 'app-interests-card',
	templateUrl: './interests-card.component.html',
	styleUrls: ['./interests-card.component.scss']
})
export class InterestsCardComponent implements OnInit {
	@Input() public splitData: Array<Array<FacebookInterests>>;
	@Input() public isInterestsToggled = false;
	@Input() public isSelected: boolean;
	@Input() public selectedInterestsAtStepTwo: Array<FacebookInterests>;
	@Output() public onExpansionClick = new EventEmitter();

	public interestsName: Array<Array<string>> = [];
	public infoHoverModel: InfoHoverDataModel;
	public numberOfInterestGroups = 1;
	public willDataBeReset = true;

	public options: Options = {
		floor: 1,
		ceil: 6,
		showTicksValues: true
	};

	constructor(private campaignSmartCreateStore: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {
		this.initInfoModel();
	}

	public initInfoModel(): void {
		this.infoHoverModel = {
			message: 'You  can move the interests around by clicking on each one and dragging and dropping between them.'
		};
		this.options.ceil = this.selectedInterestsAtStepTwo.length;
		// if there is only interest selected at step 2, prevent the user from changing the slider
		this.options.disabled = this.selectedInterestsAtStepTwo.length === 1;
		this.options.floor = this.selectedInterestsAtStepTwo.length === 1 ? 0 : 1;
		this.generateStepArray();
		if (!this.splitData?.length || !this.checkSplitDataAndSelectedInterestsAtStepTwo()) {
			this.isInterestsToggled = false;
			this.campaignSmartCreateStore.dispatch(new UpdateSplitByInterests(false));
			this.initializeSplitData();
		} else {
			this.initializeInterestsName();
			this.numberOfInterestGroups = this.splitData.length;
		}
	}
	private generateStepArray(): void {
		const allInterests = this.selectedInterestsAtStepTwo.length;
		const steps = [];
		if (!allInterests || allInterests === 1) {
			return;
		}
		for (let step = 1; step <= allInterests; step++) {
			if (allInterests % step === 0) {
				steps.push({ value: step });
			}
		}
		this.options.stepsArray = steps;
	}

	// function called when you toggle the slider
	public onInterestsToggle(): void {
		this.isInterestsToggled = !this.isInterestsToggled;
		if (this.isInterestsToggled) {
			this.campaignSmartCreateStore.dispatch(new UpdateSplitByInterests(this.splitData));
		} else {
			this.campaignSmartCreateStore.dispatch(new UpdateSplitByInterests(this.isInterestsToggled));
		}
	}

	// function called when we put the item from one container to another or when we drag it and put it in the same container
	public drop(event: CdkDragDrop<string[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
			this.interestsName = this.interestsName.filter((subArray: Array<string>) => {
				if (subArray.length === 0) {
					this.willDataBeReset = false;
					this.numberOfInterestGroups--;
				}
				return subArray.length !== 0;
			});
			this.syncSplitDataWithInterestsName();
			this.campaignSmartCreateStore.dispatch(new UpdateSplitByInterests(this.splitData));
		}
	}

	public initializeSplitData(): void {
		this.splitData = [];

		for (let i = 0; i < this.numberOfInterestGroups; i++) {
			this.splitData.push([] as Array<FacebookInterests>);
		}
		this.allocateInterests();
		this.initializeInterestsName();
	}

	// interests are evenly distributed on splitData
	public allocateInterests(): void {
		let numberOfInterests = this.selectedInterestsAtStepTwo.length;
		let numberOfInterestGroups = this.numberOfInterestGroups;
		const numberOfInterestsPerGroup = numberOfInterests / numberOfInterestGroups;
		if (Number.isInteger(numberOfInterestsPerGroup)) {
			// interests are evenly split
			let startIndex = 0;
			let endIndex = numberOfInterestsPerGroup;
			for (let index = 0; index < this.numberOfInterestGroups; index++) {
				this.splitData[index].push(...this.selectedInterestsAtStepTwo.slice(startIndex, endIndex));
				startIndex += numberOfInterestsPerGroup;
				endIndex += numberOfInterestsPerGroup;
			}
		} else {
			// some groups will have fewer interests than others
			let index = 0;
			let startIndex = 0;
			let endIndex = startIndex + Math.ceil(numberOfInterests / numberOfInterestGroups);
			while (index < this.numberOfInterestGroups) {
				this.splitData[index].push(...this.selectedInterestsAtStepTwo.slice(startIndex, endIndex));
				numberOfInterests = numberOfInterests - Math.ceil(numberOfInterests / numberOfInterestGroups);
				numberOfInterestGroups--;
				startIndex = endIndex;
				endIndex = endIndex + Math.ceil(numberOfInterests / numberOfInterestGroups);
				index++;
			}
		}
	}

	// function called when slider changes:
	// - on true when the user manually changes the slider;
	// - on false when a container becomes empty as a result of the user putting an interest in another container
	public onSliderChange(): void {
		if (this.willDataBeReset) {
			this.initializeSplitData();
			this.campaignSmartCreateStore.dispatch(new UpdateSplitByInterests(this.splitData));
		} else {
			this.willDataBeReset = true;
		}
	}

	// populate the interestsName with the corresponding splitData strings
	public initializeInterestsName(): void {
		this.interestsName = [];
		this.splitData.forEach(interestsArray => {
			this.interestsName.push(interestsArray.map(interest => interest.name));
		});
	}

	// sync the data that will be populated on store with data that is displayed inside the containers;
	// this function will be called when the user moves an interest from one container to another;
	public syncSplitDataWithInterestsName(): void {
		this.splitData = [];
		for (let i = 0; i < this.interestsName.length; i++) {
			this.splitData.push([] as FacebookInterests[]);
		}
		for (let index = 0; index < this.splitData.length; index++) {
			const currentInterestsName = this.interestsName[index];
			currentInterestsName.forEach(interestString => {
				const interest = this.selectedInterestsAtStepTwo.find(interestElement => interestElement.name === interestString);
				this.splitData[index].push(interest);
			});
		}
	}

	// check whether selectedInterests and splitData correspond
	public checkSplitDataAndSelectedInterestsAtStepTwo(): boolean {
		let areCorresponding = true;
		const splitData = [] as FacebookInterests[];
		this.splitData.forEach(subArray => {
			splitData.push(...subArray);
		});
		if (splitData.length !== this.selectedInterestsAtStepTwo.length) {
			return false;
		}
		splitData.forEach(element => {
			if (!this.selectedInterestsAtStepTwo.find(interest => interest.name === element.name)) {
				areCorresponding = false;
				return;
			}
		});
		return areCorresponding;
	}
}
