<app-expansion-panel-sc
	class="pc-panel"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Tracking'"
	[isExpandable]="false"
	[contentMargin]="false"
	[infoHoverModel]="info"
>
	<div expansion-content class="pc-container">
		<!--	Facebook Pixel	-->
		<div class="pc-content">
			<div class="flex--row">
				<mat-slide-toggle
					class="pc-slider"
					(change)="onPixelToggle()"
					[disabled]="(!isPixelMandatory || pixels?.length === 0) && !isConversionObjective"
					[checked]="pixelToggle"
				>
					<span>Facebook Pixel</span>
				</mat-slide-toggle>
				<span *ngIf="!pixels?.length">
					<info-tooltip title="No Data to track"></info-tooltip>
				</span>
			</div>
			<app-dropdown-search-select
				*ngIf="pixelToggle && pixels?.length > 0 && isPixelMandatory"
				[dropdownData]="pixels"
				[dropdownPlaceholder]="'Select Pixel'"
				[dropdownSelect]="selectType.Simple"
				[dropdownFormControl]="pixelControl"
				(selectionChange)="updateChanges()"
				[searchEnable]="true"
				[selectedValue]="this.pixelId"
			></app-dropdown-search-select>
		</div>

		<div *ngIf="subObjective === subObjectives.Conversion && pixelToggle">
			<span>Conversion Events</span>
			<div class="pc-content pc-extra">
				<app-live-search-dropdown
					*ngIf="conversionEvents?.length"
					[dropdownData]="conversionEvents"
					[inputControl]="conversionControl"
					[selectedData]="selectedConversion"
					[dropdownWidth]="21.3"
					[showSelected]="false"
					[inputLabel]="label"
					[columns]="conversionColumns"
					[inputPlaceholder]="'Select an Conversion Event'"
					[singleSelection]="true"
					(selectionChange)="showSelected($event)"
				></app-live-search-dropdown>
			</div>
		</div>

		<!--	App events	-->
		<div *ngIf="events?.length">
			<mat-slide-toggle
				class="pc-slider"
				(change)="onAppEventsToggle()"
				[checked]="eventsToggle"
				[disabled]="(!isPixelMandatory || pixels?.length === 0) && !isConversionObjective"
			>
				<span>App Events</span>
			</mat-slide-toggle>
			<app-dropdown-search-select
				*ngIf="eventsToggle"
				[dropdownData]="events"
				[dropdownFormControl]="pixelEventControl"
				[dropdownPlaceholder]="'Select Event'"
				[dropdownSelect]="selectType.Simple"
				[searchEnable]="true"
				[selectedValue]="pixelEventId"
			></app-dropdown-search-select>
		</div>
	</div>
</app-expansion-panel-sc>
