import { Input } from '@angular/core';
import { SourceChannel } from './sidenav-channel-buttons.enum';
import { SourcePlatform } from './sidenav-platform-menu';

export class ChannelButton {
	public sourceChannel: SourceChannel;
	public sourcePlatform?: SourcePlatform;
	public fileIcon: string;
	public fileIconDisabled: string;
	public hideChannel = false;

	constructor(sourceChannel: SourceChannel, fileIcon?: string, fileIconDisabled?: string, sourcePlatform?: SourcePlatform) {
		this.sourceChannel = sourceChannel;
		this.fileIcon = fileIcon;
		this.fileIconDisabled = fileIconDisabled;
		this.sourcePlatform = sourcePlatform;
	}
}
