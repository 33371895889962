import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OptimizationAndDeliveryCardComponent } from 'src/app/shared/smart-create-edit-components/step-two-components/optimization-delivery/optimization-and-delivery-card.component';
import { SubObjectives } from 'src/app/campaign-smart-create/models/sub-objectives';
@Component({
	selector: 'app-optimistation-delivery',
	templateUrl: './optimistation-delivery.component.html',
	styleUrls: ['./optimistation-delivery.component.scss']
})
export class OptimistationDeliveryComponent extends OptimizationAndDeliveryCardComponent implements OnInit {
	public isCatalogSales: boolean = false;

	public dropDownControl: FormControl = new FormControl(null, [Validators.required]);
	constructor(injector: Injector) {
		super(injector);
	}

	ngOnInit(): void {
		this.makeCartesianProduct();
		this.populateDropdowns();
	}
	ngOnChanges(): void {
		if (this.subObjective === SubObjectives.ProductCatalogSales) {
			this.optimizationDelivery.enable();
		}
	}
	public dropDownValueChanges($event: any): void {}
}
