<div class="container">
	<mat-card>
		<div class="body-section">
			<div class="left-chart">
				<div class="img-div" *ngIf="healthType === 'sentimentAnalysis'">
					<img [src]="imgSrc" class="img-analysis" id="img-analysis" />
				</div>
				<div class="img-div" *ngIf="healthType === 'landingPageAlert'">
					<img [src]="imgSrc" class="good-image" id="img-analysis" />
				</div>
				<div class="guage-area" *ngIf="healthType === 'landingPageComparison'">
					<graph09
						[startAngle]="90"
						[valueType]="'%'"
						[height]="'120px'"
						[width]="'120px'"
						[color]="optimizeService.getColorAndStatus(data?.alertSummary).colorCode"
						[value]="data?.alertSummary ? data?.alertSummary : 0"
					></graph09>
				</div>
				<div class="distribution-area" *ngIf="healthType === 'distributionComparison'">
					<img style="display: none;" src="assets/icons/optimize/smiley_neutral.svg" class="good-image" alt="" />
					<div>
						<google-chart
							[type]="chartData?.type"
							[data]="chartData?.data"
							[columns]="chartData?.chartColumns"
							[width]="chartData?.width"
							[height]="120"
							[options]="chartData?.options"
						></google-chart>
					</div>
				</div>
			</div>
			<div class="top-section">
				<span class="top-left">
					<label *ngIf="data?.alertTitle !== 'Distribution Comparisons'">{{ data?.alertTitle }}</label>
					<label *ngIf="data?.alertTitle === 'Distribution Comparisons'">Industry Comparisons</label>
					<div class="info-icon">
						<i-tooltip [title]="data?.toolTip">
							<img src="../../../../assets/icons/info-icon-hover.svg" />
						</i-tooltip>
					</div>
				</span>
			</div>
			<div [ngClass]="{ emoji: healthType === 'sentimentAnalysis' || healthType === 'landingPageAlert' }" class="right-text">
				<i-tooltip [title]="data?.alertMessage">
					<label *ngIf="healthType === 'distributionComparison' && data?.hyperlinkText" [innerHTML]="comparisonText"></label>
					<label
						*ngIf="healthType === 'distributionComparison' && !data?.hyperlinkText"
						[innerHTML]="optimizeService.shortenSomeText(comparisonText, 230)"
					></label>
					<label *ngIf="healthType !== 'distributionComparison'">{{ optimizeService.shortenSomeText(data?.alertMessage, 230) }}</label>
				</i-tooltip>
			</div>
		</div>
	</mat-card>
</div>
