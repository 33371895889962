import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-delete-dialog',
	templateUrl: './delete-confirmation.component.html',
	styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnDestroy {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public cardId: string;

	private unsubscriber$ = new Subject<void>();

	constructor(private dialogRef: MatDialogRef<DeleteConfirmationComponent>) {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public decline(): void {
		this.dialogRef.close(false);
	}

	public deleteCard(): void {
		this.dialogRef.close(true);
	}
}
