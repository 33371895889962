import { InsightsCategoryTypeEnum } from '../../shared/campaign-insights/models/insights-category-type.enum';
import { StyleModel } from './style.model';
import { GoalModel } from './goal.model';
import { WidgetPropertyModel } from './widget-property.model';
import { BasicStructureModel } from './basic-structure.model';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AdAccount } from '../../accounts/models/ad-account.model';
import { TableViewColumn2 } from 'src/app/shared/generic-table2/models/table-structure/table-view-column.model';
import { BreakdownModel } from '../../shared/models/breakdown.model';
import _ from 'lodash';
import { InsightsInterface } from './insights-interface';
import { IPositionProperty } from './report.model';

export class ReportDetailsModel {
	// ------ SEND TO DB ------

	// Chart type internal identifier
	public chartType: any;

	// Chart Metadata
	public adAccount: AdAccount;
	public reportLevel: InsightsCategoryTypeEnum = InsightsCategoryTypeEnum.Campaign;
	public reportType: string;
	public dimension: TableViewColumn2;
	public breakdown: BreakdownModel;
	public metric: TableViewColumn2[];
	public dateFrom: Date;
	public dateTo: Date;
	public rawData: any[]; // For exporting
	public goals: GoalModel[];
	public dataSource: SourceChannel;
	public insights: BasicStructureModel[]; // Do not send for templates
	public compareDate: { from: Date; to: Date };

	public allInsights: InsightsInterface<BasicStructureModel>;
	public selectedInsights: InsightsInterface<string>;

	// Chart specific properties
	public isTemplate: boolean;
	public hasGradient: boolean;
	public showLegend: boolean;
	public style: StyleModel;
	public compare: boolean;
	public reportProperty: WidgetPropertyModel;

	public positionProperty?: IPositionProperty;

	public simpleDataSource: boolean;
	public verticalAxisTitle?: string;
	public horizontalAxisTitle?: string;
	public resultPercentage: number;
	public resultAmount: string;
	public chartData: any;
	public compareData?: any[];

	// public hasDataLabels: boolean;

	constructor() {
		this.metric = [new TableViewColumn2()];
		this.adAccount = new AdAccount();
		this.reportProperty = new WidgetPropertyModel();
		this.goals = new Array<GoalModel>();
		this.insights = [];
	}
}
