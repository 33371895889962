<div>
	<ng-container *ngIf="formGroup">
		<form [formGroup]="formGroup">
			<div formArrayName="adCardsItemData">
				<div [hidden]="isAppInstalled">
					<span class="cif-call-title">Primary Text</span>
					<div class="cif-input-width" *ngFor="let control of primaryTextForm.controls; let i = index">
						<i-tooltip [title]="explanationPrimaryColor">
							<tb06
								[readonlyInput]="adFormat === adFormatEnum.PagePosts"
								[control]="primaryTextForm.controls[i]"
								[placeHolderTextBox]="'Primary text'"
								[icon]="'fas fa-info-circle'"
							></tb06>
						</i-tooltip>
					</div>

					<div>
						<span class="cif-call-title">Headline</span>
						<div class="cif-input-width-button">
							<div *ngIf="!isPageLikesSelected && !isPostLikesSelected && adFormat !== adFormatEnum.PagePosts" class="cif-input-width">
								<i-tooltip [title]="explanationHeadline">
									<tb06 [control]="card.get('headline')" [placeHolderTextBox]="'Headline'" [icon]="'fas fa-info-circle'"></tb06>
								</i-tooltip>
							</div>
						</div>
					</div>
				</div>

				<span class="cif-call-title" *ngIf="!isPageLikesSelected">Call to Action</span>
				<div *ngIf="!isPageLikesSelected" class="cif-dropdown-height">
					<app-dropdown-search-select
						class="cif-call-to-action"
						[dropdownData]="callToActions"
						[dropdownFormControl]="card.get('call_to_action')"
					></app-dropdown-search-select>
				</div>
			</div>
		</form>
	</ng-container>
</div>
