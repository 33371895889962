import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { getDisableUserState } from 'src/app/user-management/state/user-management.reducer';
import { DisableUserModel } from 'src/app/user-management/state/state.model';
import { UserState } from 'src/app/_models/user-models/user-state';
import { getFiledId } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { stat } from 'fs';

@Component({
	selector: 'app-appreciation',
	templateUrl: './appreciation.component.html',
	styleUrls: ['./appreciation.component.scss']
})
export class AppreciationComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading: boolean = false;
	public userFeed: DisableUserModel;
	public clickedNext: boolean = false;

	constructor(
		private _location: Location,
		private router: Router,
		private userServiceApi: UserServiceApi,
		private sharedStore: Store<SharedState>,
		private toastNotification: ToastNotificationService,
		private paymentService: PaymentServiceApi
	) {}

	ngOnInit(): void {}

	public getDataFromStore(): any {
		this.sharedStore.pipe(select(getDisableUserState), takeUntil(this.unsubscriber$)).subscribe(
			(detail: DisableUserModel) => {
				return detail;
			},
			error => {
				return null;
			}
		);
	}

	public backClicked() {
		this._location.back();
	}

	public onCancel() {
		this.router.navigate(['user-management/security']);
	}

	public onNext() {
		this.clickedNext = true;
		this.sharedStore.pipe(select(getDisableUserState), takeUntil(this.unsubscriber$)).subscribe((detail: DisableUserModel) => {
			if (detail && this.clickedNext) {
				this.loading = true;
				this.sharedStore.dispatch(new ShowGlobalSpinner());

				this.userServiceApi
					.deactivateUserNew(detail)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(
						activities => {
							if (activities) {
								this.router.navigate(['user-management/security/sad-final']);
							}
						},
						error => {
							if (error.error[0].code === 'Filed__Domain__NA__UserPassword__IsIncorrect') {
								this.toastNotification.sendErrorToast('Incorrect user password. Check and try again');
								this.router.navigate(['user-management/security/pausing-account']);
							} else {
								this.toastNotification.sendErrorToast('Something went wrong, please contact support');
								this.router.navigate(['user-management/security/pausing-account']);
							}
							this.clickedNext = false;
						},
						() => {
							this.loading = false;
							this.sharedStore.dispatch(new HideGlobalSpinner());
						}
					);
			}
		});
	}
	public onAcceptOffer(): void {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.paymentService
			.acceptOffers()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				status => {
					if (status) {
						this.router.navigate(['user-management/security/offer-accepted']);
					}
				},
				error => {
					if (error.code === 'Filed__Domain__NA__UserPassword__IsIncorrect') {
						this.toastNotification.sendErrorToast('Incorrect user password. Check and try again');
					} else {
						this.toastNotification.sendErrorToast('Something went wrong, please contact support');
					}
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
