import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getSelectedAdAccount, SharedState } from '../state/shared.reducer';
import { catchError, skipWhile, take } from 'rxjs/operators';
import { HideGlobalSpinner } from '../state/shared.actions';

@Injectable()
export class AdAccountResolver implements Resolve<Observable<any>> {
	constructor(private sharedStore: Store<SharedState>) {}
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		return this.sharedStore.pipe(
			select(getSelectedAdAccount),
			skipWhile(account => !account),
			take(1),
			catchError(error => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				return EMPTY;
			})
		);
	}
}
