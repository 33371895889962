export enum OptimizationLevelsEnum {
	Campaign = 'campaign',
	AdGroup = 'adGroup',
	AdSet = 'adSet',
	Ad = 'ad'
}

export enum OptimizationTypeEnum {
	Labs = 'labs',
	Recommendations = 'recommendations'
}
