<app-user-header></app-user-header>

<div class="container">
	<div class="header">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div>
			<h4 class="title">Payment & Billing</h4>
			<div class="subtitle">Changes to account setting will apply to all other signed in devices.</div>
		</div>
	</div>
	<div class="body">
		<div class="page">
			<div class="body-form">
				<app-billing-details></app-billing-details>
			</div>
			<!-- feature removed -->
			<!-- <div class="body-form">
				<app-failed-payment-notification></app-failed-payment-notification>
			</div> -->
			<div class="body-form">
				<app-payment-cards
					[source]="'payment'"
					[userDetail]="decodeJwtToken"
					(updateCurrentDefaultCard)="onUpdateCurrentDefaultCard($event)"
				></app-payment-cards>
				<div class="pay-form-btn">
					<btn04 (onClick)="addCard()">
						<span>+</span>
						Add New Card
					</btn04>
				</div>
			</div>
			<div class="body-form">
				<app-invoice-lists></app-invoice-lists>
			</div>
		</div>
	</div>
</div>
