import { Action } from '@ngrx/store';
import { TableView2 } from 'src/app/shared/generic-table2/models/table-structure/table-view2.model';
import { UpdateAdInterface } from '../ads-manager-insights/models/update-ad.interface';
import { UpdateAdsetInterface } from '../ads-manager-insights/models/update-adset.interface';
import { UpdateCampaignInterface } from '../ads-manager-insights/models/update-campaign.interface';
import { InsightsCategory } from '../../shared/campaign-insights/models/insights-category.model';
import { AdsManagerStatusEnum } from '../ads-manager-insights/models/ads-manager-status.enum';

export enum AdsManagerActionTypes {
	LoadFacebookCampaignsBusinessViews = '[Ads Manager] Load Facebook Campaigns Business Views',
	LoadFacebookCampaignsBusinessViewsSuccess = '[Ads Manager] Load Facebook Campaigns Business Views Success',
	LoadFacebookCampaignsBusinessViewsFailure = '[Ads Manager] Load Facebook Campaigns Business Views Failure',

	LoadFacebookAdsetsBusinessViews = '[Ads Manager] Load Facebook Adsets Business Views',
	LoadFacebookAdsetsBusinessViewsSuccess = '[Ads Manager] Load Facebook Adsets Business Views Success',
	LoadFacebookAdsetsBusinessViewsFailure = '[Ads Manager] Load Facebook Adsets Business Views Failure',

	LoadFacebookAdsBusinessViews = '[Ads Manager] Load Facebook Ads Business Views',
	LoadFacebookAdsBusinessViewsSuccess = '[Ads Manager] Load Facebook Ads Business Views Success',
	LoadFacebookAdsBusinessViewsFailure = '[Ads Manager] Load Facebook Ads Business Views Failure',

	LoadGoogleCampaignsBusinessViews = '[Ads Manager] Load Google Campaigns Business Views',
	LoadGoogleCampaignsBusinessViewsSuccess = '[Ads Manager] Load Google Campaigns Business Views Success',
	LoadGoogleCampaignsBusinessViewsFailure = '[Ads Manager] Load Google Campaigns Business Views Failure',

	LoadGoogleAdgroupsBusinessViews = '[Ads Manager] Load Google Adgroups Business Views',
	LoadGoogleAdgroupsBusinessViewsSuccess = '[Ads Manager] Load Google Adgroups Business Views Success',
	LoadGoogleAdgroupsBusinessViewsFailure = '[Ads Manager] Load Google Adgroups Business Views Failure',

	LoadGoogleAdsBusinessViews = '[Ads Manager] Load Google Ads Business Views',
	LoadGoogleAdsBusinessViewsSuccess = '[Ads Manager] Load Google Ads Business Views Success',
	LoadGoogleAdsBusinessViewsFailure = '[Ads Manager] Load Google Ads Business Views Failure',

	LoadGoogleKeywordsBusinessViews = '[Ads Manager] Load Google Keywords Business Views',
	LoadGoogleKeywordsBusinessViewsSuccess = '[Ads Manager] Load Google Keywords Business Views Success',
	LoadGoogleKeywordsBusinessViewsFailure = '[Ads Manager] Load Google Keywords Business Views Failure',

	LoadCampaignsViews = '[Ads Manager] Load Campaigns Views',
	LoadCampaignsViewsSuccess = '[Ads Manager] Load Campaigns Views Success',
	LoadCampaignsViewsFailure = '[Ads Manager] Load Campaigns Views Failure',

	LoadCampaignsData = '[Ads Manager] Load Campaigns Data',
	LoadCampaignsDataSuccess = '[Ads Manager] Load Campaigns Data Success',
	LoadCampaignsDataFailure = '[Ads Manager] Load Campaigns Data Failure',

	UpdateSelectedCampaigns = '[Ads Manager] Update Selected Campaigns',
	UpdateSelectedCampaignsSuccess = '[Ads Manager] Update Selected Campaigns Success ',
	LoadAdSetsViews = '[Ads Manager] Load AdSets Views',
	LoadAdSetsViewsSuccess = '[Ads Manager] Load AdSets Views Success',
	LoadAdSetsViewsFailure = '[Ads Manager] Load AdSets Views Failure',

	LoadAdSetsData = '[Ads Manager] Load AdSets Data',
	LoadAdSetsDataSuccess = '[Ads Manager] Load AdSets Data Success',
	LoadAdSetsDataFailure = '[Ads Manager] Load AdSets Data Failure',

	UpdateSelectedAdSets = '[Ads manager] Update Selected AdSets',
	UpdateSelectedAdSetsSuccess = '[Ads manager] Update Selected AdSets Success',

	ClearSelected = '[Ads manager] clear Selected AdSets, Campaign and Ads',
	LoadAdsViews = '[Ads Manager] Load Ads Views',
	LoadAdsViewsSuccess = '[Ads Manager] Load Ads Views Success',
	LoadAdsViewsFailure = '[Ads Manager] Load Ads Views Failure',

	LoadAdsData = '[Ads Manager] Load Ads Data',
	LoadAdsDataSuccess = '[Ads Manager] Load Ads Data Success',
	LoadAdsDataFailure = '[Ads Manager] Load Ads Data Failure',

	UpdateSelectedAds = '[Ads manager] Update Selected Ads',

	SetOldSelectedTab = '[Ads manager] Set Old Selected Tab',

	UpdateSelectedCampaignsBundle = '[Ads Manager] Update Selected Campaigns Bundle',
	UpdateSelectedCampaignsSuccessBundle = '[Ads Manager] Update Selected Campaigns Bundle Success',
	UpdateSelectedCampaignsStatus = '[Ads Manager] Update Selected Campaigns Status',

	UpdateSelectedAdSetsBundle = '[Ads Manager] Update Selected Ad Sets Bundle',
	UpdateSelectedAdSetsBundleSuccess = '[Ads Manager] Update Selected Ad Sets Bundle Succes',
	UpdateSelectedAdSetsStatus = '[Ads Manager] Update Selected AdSets Status',

	UpdateSelectedAdsBundleSuccess = '[Ads Manager] Update Selected Ads Bundle',
	UpdateSelectedAdsStatus = '[Ads Manager] Update Selected Ads Status',

	SetnextPageCursorCampaigns = '[Ads manager] Set Next Link Campaigns'
}

// ====================

export class LoadFacebookCampaignsBusinessViews implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookCampaignsBusinessViews;
	constructor() {}
}

export class SetOldSelectedTab implements Action {
	readonly type = AdsManagerActionTypes.SetOldSelectedTab;
	constructor(public payload: InsightsCategory) {}
}

export class LoadFacebookAdsetsBusinessViews implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookAdsetsBusinessViews;
	constructor() {}
}

export class LoadFacebookAdsBusinessViews implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookAdsBusinessViews;
	constructor() {}
}

export class LoadGoogleCampaignsBusinessViews implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleCampaignsBusinessViews;
	constructor() {}
}

export class LoadGoogleAdgroupsBusinessViews implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleAdgroupsBusinessViews;
	constructor() {}
}

export class LoadGoogleAdsBusinessViews implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleAdsBusinessViews;
	constructor() {}
}

export class LoadGoogleKeywordsBusinessViews implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleKeywordsBusinessViews;
	constructor() {}
}

export class LoadFacebookCampaignsBusinessViewsSuccess implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookCampaignsBusinessViewsSuccess;
	constructor(public payload: TableView2[]) {}
}

export class LoadFacebookAdsetsBusinessViewsSuccess implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookAdsetsBusinessViewsSuccess;
	constructor(public payload: TableView2[]) {}
}

export class LoadFacebookAdsBusinessViewsSuccess implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookAdsBusinessViewsSuccess;
	constructor(public payload: TableView2[]) {}
}

export class LoadGoogleCampaignsBusinessViewsSuccess implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleCampaignsBusinessViewsSuccess;
	constructor(public payload: TableView2[]) {}
}

export class LoadGoogleAdgroupsBusinessViewsSuccess implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleAdgroupsBusinessViewsSuccess;
	constructor(public payload: TableView2[]) {}
}

export class LoadGoogleAdsBusinessViewsSuccess implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleAdsBusinessViewsSuccess;
	constructor(public payload: TableView2[]) {}
}

export class LoadGoogleKeywordsBusinessViewsSuccess implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleKeywordsBusinessViewsSuccess;
	constructor(public payload: TableView2[]) {}
}

export class LoadFacebookCampaignsBusinessViewsFailure implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookCampaignsBusinessViewsFailure;
	constructor(public payload: string) {}
}

export class LoadFacebookAdsetsBusinessViewsFailure implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookAdsetsBusinessViewsFailure;
	constructor(public payload: string) {}
}

export class LoadFacebookAdsBusinessViewsFailure implements Action {
	readonly type = AdsManagerActionTypes.LoadFacebookAdsBusinessViewsFailure;
	constructor(public payload: string) {}
}

export class LoadGoogleCampaignsBusinessViewsFailure implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleCampaignsBusinessViewsFailure;
	constructor(public payload: string) {}
}

export class LoadGoogleAdgroupsBusinessViewsFailure implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleAdgroupsBusinessViewsFailure;
	constructor(public payload: string) {}
}

export class LoadGoogleAdsBusinessViewsFailure implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleAdsBusinessViewsFailure;
	constructor(public payload: string) {}
}

export class LoadGoogleKeywordsBusinessViewsFailure implements Action {
	readonly type = AdsManagerActionTypes.LoadGoogleKeywordsBusinessViewsFailure;
	constructor(public payload: string) {}
}

// store for campaigns

export class UpdateSelectedCampaigns implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedCampaigns;
	constructor(public payload: UpdateCampaignInterface) {}
}

export class UpdateSelectedCampaignsSuccess implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedCampaignsSuccess;
	constructor(public payload: UpdateCampaignInterface) {}
}

export class UpdateSelectedCampaignsBundle implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedCampaignsBundle;
	constructor(public payload: UpdateCampaignInterface[]) {}
}

export class UpdateSelectedCampaignsBundleSuccess implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedCampaignsSuccessBundle;
	constructor(public payload: UpdateCampaignInterface[]) {}
}

export class UpdateSelectedCampaignsStatus implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedCampaignsStatus;
	constructor(public payload: string[], public status: AdsManagerStatusEnum) {}
}

// store for ad sets

export class UpdateSelectedAdSets implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAdSets;
	constructor(public payload: UpdateAdsetInterface) {}
}

export class UpdateSelectedAdSetsSuccess implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAdSetsSuccess;
	constructor(public payload: UpdateAdsetInterface) {}
}

export class UpdateSelectedAdSetsBundle implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAdSetsBundle;
	constructor(public payload: UpdateAdsetInterface[]) {}
}

export class UpdateSelectedAdSetsBundleSuccess implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAdSetsBundleSuccess;
	constructor(public payload: UpdateAdsetInterface[]) {}
}

export class UpdateSelectedAdSetsStatus implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAdSetsStatus;
	constructor(public payload: string[], public status: AdsManagerStatusEnum) {}
}

// store for ads

export class UpdateSelectedAds implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAds;
	constructor(public payload: UpdateAdInterface) {}
}

export class UpdateSelectedAdsBundleSuccess implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAdsBundleSuccess;
	constructor(public payload: UpdateAdInterface[]) {}
}

export class UpdateSelectedAdsStatus implements Action {
	readonly type = AdsManagerActionTypes.UpdateSelectedAdsStatus;
	constructor(public payload: string[], public status: AdsManagerStatusEnum) {}
}

export class ClearSelected implements Action {
	public readonly type = AdsManagerActionTypes.ClearSelected;
}
// miscellaneous
export class SetnextPageCursorCampaigns implements Action {
	readonly type = AdsManagerActionTypes.SetnextPageCursorCampaigns;
	constructor(public payload: string) {}
}

export type AdsManagerActions =
	| SetOldSelectedTab
	| LoadFacebookAdsetsBusinessViewsFailure
	| LoadFacebookAdsBusinessViewsFailure
	| LoadGoogleKeywordsBusinessViews
	| LoadGoogleCampaignsBusinessViewsFailure
	| LoadGoogleAdgroupsBusinessViewsFailure
	| LoadGoogleAdsBusinessViewsFailure
	| LoadGoogleKeywordsBusinessViewsFailure
	| LoadFacebookCampaignsBusinessViewsFailure
	| LoadFacebookCampaignsBusinessViews
	| LoadFacebookAdsetsBusinessViews
	| LoadFacebookAdsBusinessViews
	| LoadGoogleCampaignsBusinessViews
	| LoadGoogleAdgroupsBusinessViews
	| LoadGoogleAdsBusinessViews
	| LoadFacebookCampaignsBusinessViewsSuccess
	| LoadFacebookAdsetsBusinessViewsSuccess
	| LoadFacebookAdsBusinessViewsSuccess
	| LoadGoogleCampaignsBusinessViewsSuccess
	| LoadGoogleAdgroupsBusinessViewsSuccess
	| LoadGoogleAdsBusinessViewsSuccess
	| LoadGoogleKeywordsBusinessViewsSuccess
	| UpdateSelectedCampaigns
	| UpdateSelectedCampaignsSuccess
	| UpdateSelectedAdSets
	| UpdateSelectedAdSetsSuccess
	| UpdateSelectedAds
	| SetnextPageCursorCampaigns
	| UpdateSelectedCampaignsBundle
	| UpdateSelectedCampaignsBundleSuccess
	| UpdateSelectedAdSetsBundle
	| UpdateSelectedAdSetsBundleSuccess
	| UpdateSelectedAdsBundleSuccess
	| UpdateSelectedCampaignsStatus
	| UpdateSelectedAdSetsStatus
	| ClearSelected
	| UpdateSelectedAdsStatus;
