import { Injectable } from '@angular/core';

import { DefaultChartValues } from '../../models/default-chart-values';
import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { Legend } from '../../models/legend';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { ChartLabel } from '../../models/chart-label';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { Tooltip } from '../../models/tooltip';

@Injectable({
	providedIn: 'root'
})
export class PieChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected store: Store<ReportingState>,
		protected toastNotificationService: ToastNotificationService
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel) {
		const initial = this.initializeChart(widget);
		const chartOptions = initial.options;
		const label = new ChartLabel('left', 'middle', 'insideBottom', 0, 10, 12, widget.details.reportProperty.showDataLabels);

		chartOptions.tooltip = new Tooltip('item', null);
		chartOptions.series = this.createSeriesForPieChart(widget, initial.data, '50%', ['50%', '35%'], label);

		if (initial.data.length) {
			chartOptions.legend = new Legend('horizontal', '0', '80%', 'circle');
			// chartOptions.legend.type = 'scroll';
			chartOptions.legend.data = initial.data.map((item: { name: string }) => item.name);
		} else {
			chartOptions.legend.data = ['First', 'Second', 'Third', 'Forth', 'Fifth'];
		}
		return chartOptions;
	}

	private createSeriesForPieChart(widget: ReportModel, chartData: any, radius: any, center: any, labelAlignment: any) {
		return [
			{
				type: 'pie',
				name: 'Pie',
				selectedMode: 'multiple',
				radius: radius,
				center: center,
				data: chartData.length > 0 ? chartData : (DefaultChartValues as any)['Pie'],
				itemStyle: {
					normal: {
						shadowBlur: widget.details.style.hasGradient ? 5 : 0,
						shadowColor: widget.details.style.hasGradient ? 'rgba(0, 0, 0, 0.9)' : null
					}
				},
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowOffsetX: 0,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				},
				tooltip: {
					trigger: 'item'
				},
				labelLine: {
					smooth: 0.2,
					length: 10,
					length2: 20
				},
				animationType: 'scale',
				animationEasing: 'elasticOut',
				animationDelay: () => {
					return Math.random() * 200;
				},
				label: {
					position: 'outer',
					alignTo: 'edge'
				}
			}
		];
	}
}
