import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActionButton } from '../../models/collapse';

@Component({
	selector: 'app-collapse-card',
	templateUrl: './collapse-card.component.html',
	styleUrls: ['./collapse-card.component.scss']
})
export class CollapseCardComponent implements OnInit {
	@Input() public collapsable: boolean;
	@Input() public title: string;
	@Input() public subTitle: string;
	@Input() public adSetCount: number;
	@Input() public hideAdsetCount: boolean;
	@Input() public thumbnailSrc: string;
	@Input() public actionButtons: ActionButton[];
	@Input() public width: string = '100%';
	@Input() public headerTemplate: TemplateRef<any>;
	@Input() public contentTemplate: TemplateRef<any>;
	@Input() public footerTemplate: TemplateRef<any>;
	@Input() public height: string;
	@Input() public description: string;
	@Input() public disableEditButton: boolean;
	@Input() public isCollapsed = false;
	@Output() public collapse = new EventEmitter<boolean>();
	@Output() public onEdit = new EventEmitter<void>();
	constructor() {
		this.hideAdsetCount = false;
		this.disableEditButton = false;
	}

	public ngOnInit(): void {}
	public toggleVisibility(): void {
		if (this.collapse) {
			this.collapse.emit(this.isCollapsed);
		}
		this.isCollapsed = !this.isCollapsed;
	}

	public onEditButtonClicked(event: void): void {
		this.onEdit.emit(event);
	}
}
