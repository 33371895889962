import { Component, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export interface possibleAnswerInterface {
	id: number;
	value: string;
	description?: string;
}

@Component({
	selector: 'app-conditional-qstn',
	templateUrl: './conditional-qstn.component.html',
	styleUrls: ['./conditional-qstn.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ConditionalQstnComponent),
			multi: true
		}
	]
})
export class ConditionalQstnComponent implements OnInit, ControlValueAccessor {
	@Input() formControl?: FormGroup;
	@Input() expanded?: boolean = true;
	@Input() placeholderText?: string;
	@Input() itemIndex: number;

	@Output() questionChanged: EventEmitter<FormControl> = new EventEmitter();
	@Output() removeQuestion: EventEmitter<number> = new EventEmitter();

	disabled = false;

	value = '';

	onChange: any = () => {};
	onTouched: any = () => {};
	public possibleQuestion: possibleAnswerInterface[];
	public ansControl: FormControl;
	public conditionalFormGroup: FormGroup;
	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.innitializeControl();
		this.possibleQuestion = [];
	}

	private innitializeControl(): void {
		this.conditionalFormGroup.addControl('csvFile', new FormControl(''));

		this.formControl.addControl('csvFile', this.conditionalFormGroup.controls.csvFile);
	}

	public toggleExpansion(): void {
		this.expanded = !this.expanded;
	}
	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	removeItem(): void {
		this.removeQuestion.emit(this.itemIndex);
	}
}
