import { Bits } from './bits';

export enum MiscellaneousPermissions {
	IsTechnicalAccount = Bits.Bit1,
	IsBusinessOwner = Bits.Bit2,
	IsClientEmployee = Bits.Bit3,
	IsFreemium = Bits.Bit4,
	// reserved  = Bits.Bit5,
	IsAccountManagerAdmin = Bits.Bit6,
	IsAccountManager = Bits.Bit7,
	IsSales = Bits.Bit8,
	IsSalesAdmin = Bits.Bit9,
	IsAdmin = Bits.Bit10,
	MiscellaneousFiledSmartTableAccess = Bits.Bit11,
	MiscellaneousFiledSmartTableExport = Bits.Bit12,
	MiscellaneousFacebookAccess = Bits.Bit13,
	MiscellaneousGoogleAccess = Bits.Bit14,
	MiscellaneousShopifyAccess = Bits.Bit15
}
