import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseApiUrl } from './base-api-urls';
import { Observable } from 'rxjs';
import { ElementViewsMetrics } from '../shared/elements/elements-metrics/models/elements-metrics.interface';
import { ViewsPostDetails } from '../shared/elements/elements-metrics/models/views-value.interface';
import { ViewsNumbers } from '../shared/elements/elements-metrics/models/views-values-response.interface';
import { GetRecommendationsInterface, RecommendationsResponseInterface } from '../shared/elements/elements-recommendations/get-recommendations.interface';
import { ChartDataInterface } from '../_models/elements-models/chart-data.interface';
import { ChartDataResult } from '../_models/elements-models/chart-data-result.interface';
import { UserPreferencesInterface } from '../_models/elements-models/user-preferences.interface';

@Injectable({
	providedIn: 'root'
})
export class ElementsService {
	constructor(protected http: HttpClient) {}

	public getElementViews(isAccounts: boolean): Observable<ElementViewsMetrics[]> {
		if (isAccounts) {
			return this.http.get<ElementViewsMetrics[]>(`${BaseApiUrl.FacebookDataPipeline}accounts/elements-cards-views`);
		} else {
			return this.http.get<ElementViewsMetrics[]>(`${BaseApiUrl.FacebookDataPipeline}ads-manager/elements-cards-views`);
		}
	}

	public getViewsValue(data: ViewsPostDetails, isAccounts: boolean): Observable<ViewsNumbers> {
		if (isAccounts) {
			return this.http.post<ViewsNumbers>(`${BaseApiUrl.FacebookDataPipeline}accounts/ag-grid-insights-trend/`, data);
		} else {
			return this.http.post<ViewsNumbers>(`${BaseApiUrl.FacebookDataPipeline}ads-manager/ag-grid-insights-trend/`, data);
		}
	}

	public getElementsRecommendations(settings: GetRecommendationsInterface): Observable<RecommendationsResponseInterface> {
		return this.http.post<RecommendationsResponseInterface>(`${BaseApiUrl.OptimizationApi}get-recommendations`, settings);
	}

	public getChartData(data: ChartDataInterface): Observable<ChartDataResult[]> {
		return this.http.post<ChartDataResult[]>(`${BaseApiUrl.FacebookDataPipeline}accounts/reports`, data);
	}

	// public getUserPreferences(): Observable<UserPreferencesInterface> {
	// 	return this.http.get<UserPreferencesInterface>(`${BaseApiUrl.GeneralSettings}userPreference`);
	// }

	// public updateUserPreferences(data: string): Observable<void> {
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Content-Type', 'application/json');
	// 	return this.http.put<void>(`${BaseApiUrl.GeneralSettings}userPreference`, data, { headers: headers });
	// }
}
