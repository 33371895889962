import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	constructor() {}

	public static getItem(key: string): string {
		return localStorage.getItem(key);
	}

	public static tryGetItem<T>(key: string): T {
		const value = localStorage.getItem(key);

		try {
			return JSON.parse(value) as T;
		} catch {
			console.error('Incorrect JSON object: ', value);
			return null;
		}
	}

	public static setItem(key: string, value: any): void {
		localStorage.setItem(key, value);
	}

	public static removeItem(key: string): void {
		localStorage.removeItem(key);
	}
}
