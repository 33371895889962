<app-campaign-smart-create-navigation
	#top
	[activeStep]="step"
	[campaignStep]="campaign.lastStepId"
	[isCurrentStepValid]="isStepValid"
	(navigationClick)="onNavClick($event)"
></app-campaign-smart-create-navigation>

<div #mainContent class="scs-wrapper" id="scs-wrapper">
	<font04 class="title">Smart Create</font04>
	<div class="container">
		<div class="sticky left">
			<quick-navigation [navigations]="navigations"></quick-navigation>
		</div>
		<div class="panels" QuickNavigationScroll>
			<div (click)="onPanelClick(0)" class="scs-expansion-panel adSetName" id="campaign-name" qn-scroll-item>
				<app-name-expansion-panel
					[control]="adSetSettingsFormGroup.get('adSetName')"
					(click)="expansionPanelHelper.onPanelClick(panels.AdSetName)"
					[isSelected]="expansionPanelHelper.isPanelSelected(panels.AdSetName)"
					[expansionTitle]="'Ad Set Name'"
					[validationMessages]="validationMessages"
					[placeholder]="label"
				></app-name-expansion-panel>
			</div>
			<div class="scs-expansion-panel lead" id="lead-generation" qn-scroll-item *ngIf="selectedObjective === subObjectives?.LeadGenerationNew">
				<app-lead-generation></app-lead-generation>
			</div>

			<div class="scs-expansion-panel lead" id="message-destination" qn-scroll-item>
				<div class="scs-expansion-panel messageDestination" id="MessageDestination">
					<app-message-destination [selectedObjective]="selectedObjective"></app-message-destination>
				</div>
				<div (click)="onPanelClick(1)" class="scs-expansion-panel pages" id="select-page" qn-scroll-item>
					<app-pages-card
						(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Pages)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Pages)"
						[pagesFormGroup]="adSetSettingsFormGroup.get('pages')"
						[facebookPages]="facebookPages"
						[instagramPages]="instagramPages"
						[selectedObjective]="selectedObjective"
					></app-pages-card>
				</div>
				<div
					(click)="onPanelClick(2)"
					class="scs-expansion-panel chooseCatalogSet"
					id="choose-catalog-set"
					qn-scroll-item
					*ngIf="productCatalogId && selectedObjective === subObjectives.ProductCatalogSales"
				>
					<app-catalog-set-selector
						[productCatalogSets]="productCatalogSets"
						[productCatalogSetFormControl]="this.adSetSettingsFormGroup.get('productCatalogSetFormControl')"
					></app-catalog-set-selector>
				</div>
				<div class="bct-expansion-panel appStoreType" *ngIf="selectedObjective === subObjectives.AppInstallText" qn-scroll-item>
					<app-campaign-app-selector
						[control]="adSetSettingsFormGroup?.get('appStoreType')"
						[appControl]="adSetSettingsFormGroup?.get('appLinks')"
						(selectedAppData)="updateSelectedApp($event)"
						[advertisableApps]="advertisableApps"
					></app-campaign-app-selector>
				</div>
				<div (click)="onPanelClick(4)" class="scs-expansion-panel placements" id="placements" qn-scroll-item>
					<app-placements-card
						*ngIf="placements"
						[selectedPlacements]="placements"
						[platformsPerDevice]="platformsPerDevice"
						[placementsControl]="adSetSettingsFormGroup.get('placements')"
						[objective]="subObjective"
						[platforms]="platforms"
						(onUpdatePlacements)="updatePlacements($event)"
						(onUpdatePlacementPerPlatform)="updatePlacementPerPlatform($event)"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Placements)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Placements)"
						[isInstagramSelected]="adSetSettingsFormGroup?.get('pages')?.get('instagramId')?.value"
					></app-placements-card>
				</div>

				<div (click)="onPanelClick(5)" class="scs-expansion-panel targeting locations" id="targetingid" qn-scroll-item>
					<!-- <app-targeting-card
											[selectedAdAccount]="selectedAdAccount"
											[adCategory]="adCategory"
											[targeting]="targeting"
											[savedAudiences]="savedAudiences"
											[targetingControl]="adSetSettingsFormGroup.get('locations')"
											[isSpecialAdCategoryOn]="isSpecialAdCategoryOn"
											[adAccountFacebookId]="adAccountFacebookId"
											[optimizationGoal]="optimizationGoal"
											(onUpdateTargeting)="updateTargeting($event)"
											(audienceSave)="saveAudience($event)"
											(audienceSize)="updateAudienceGraph($event)"
											(modifyAudience)="updateAudience($event)"
											(retargetingAudienceSpecs)="getRetargetingAudienceSpec($event)"
											[productSetId]="productSetId"
											[regulatedInterests]="regulatedInterests"
											[interests]="interests"
											[validationTrigger]="validationTrigger"
											(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Targeting)"
											[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Targeting)"
											[budgetAmount]="adSetSettingsFormGroup.get('adSetBudget').value"
											[selectedObjective]="campaign?.stepOneDetailsAsJson?.objective"
											[productCatalogSets]="productCatalogSets"
										></app-targeting-card>	 -->
					<app-targeting-card
						[selectedAdAccount]="selectedAdAccount"
						[adCategory]="adCategory"
						[targeting]="targeting"
						[savedAudiences]="savedAudiences"
						[targetingControl]="adSetSettingsFormGroup.get('locations')"
						[isSpecialAdCategoryOn]="isSpecialAdCategoryOn"
						[adAccountFacebookId]="adAccountFacebookId"
						[optimizationGoal]="optimizationGoal"
						(onUpdateTargeting)="updateTargeting($event)"
						(audienceSave)="saveAudience($event)"
						(audienceSize)="updateAudienceGraph($event)"
						(retargetingAudienceSpecs)="getRetargetingAudienceSpec($event)"
						[productSetId]="productSetId"
						[regulatedInterests]="regulatedInterests"
						[interests]="interests"
						[validationTrigger]="validationTrigger"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Targeting)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Targeting)"
						[budgetAmount]="adSetSettingsFormGroup.get('adSetBudget').value"
						[selectedObjective]="campaign?.stepOneDetailsAsJson?.objective"
						[productCatalogSets]="productCatalogSets"
					></app-targeting-card>
				</div>

				<div (click)="onPanelClick(6)" class="scs-expansion-panel adSetBudget" id="adset-budget-optimization" qn-scroll-item>
					<app-budget-card
						[currency]="currency"
						[validBudget]="validBudget"
						[adSetBudget]="adSetSettingsFormGroup.get('adSetBudget')"
						[campaignBudgetOptimization]="campaignBudgetOptimization"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Budget)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Budget)"
					></app-budget-card>
				</div>

				<div (click)="onPanelClick(7)" class="scs-expansion-panel" id="date" qn-scroll-item>
					<app-date-card
						*ngIf="!campaignBudgetOptimization"
						(campaignDateStatus)="updateCampaignDateStatus($event)"
						[preselectedDate]="preselectedDate"
						[isCampaignLifeTimeSelected]="isCampaignLifeTimeSelected"
						[isAdSetLifeTimeSelected]="isAdSetLifeTimeSelected"
						[campaignDate]="campaignDate"
						[dateChoiceType]="dateChoiceType"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Date)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Date)"
						(onReactivateView)="reactivateView($event)"
					></app-date-card>
				</div>

				<div (click)="onPanelClick(8)" class="scs-expansion-panel optimizationDelivery" id="optimization-delivery" qn-scroll-item *ngIf="false">
					<app-optimization-and-delivery-card
						[optimizationGoalList]="optimizationGoalList"
						[clicks]="clicks"
						[views]="views"
						[billingEvents]="billingEventsList"
						[validations]="validations"
						[objectiveWithDestination]="objectiveWithDestination"
						[subObjective]="subObjective"
						[optimizationDelivery]="adSetSettingsFormGroup.get('optimizationDelivery')"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Optimization)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Optimization)"
					></app-optimization-and-delivery-card>
				</div>
			</div>
		</div>
		<div class="sticky right">
			<div class="audience-size">
				<div class="graph-card">
					<font07>Audience Size</font07>
					<font08-4 *ngIf="targeting?.size !== undefined">{{ targeting.size }} people</font08-4>
					<div class="row bar-area">
						<div *ngFor="let bar of bars; let i = index">
							<div
								[style.height]="bar.height"
								[style.opacity]="((i + 1) / 10).toString()"
								class="bar"
								[ngClass]="{
									'bar-active': bar.isActive,
									'bar-non-active': !bar.isActive
								}"
							></div>
						</div>
					</div>
					<div class="bar-area-underline"></div>
					<div class="audience-result">
						<font08-4>{{ audienceResult }}</font08-4>
					</div>
				</div>
			</div>
		</div>
		<app-campaign-smart-create-footer
			[saveButtonText]="'Back'"
			(cancel)="onCancel()"
			(next)="onNext()"
			(save)="onBack()"
		></app-campaign-smart-create-footer>
	</div>
</div>
