import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DropdownInterface } from 'src/app-components/interfaces/dropdown-interface';
import { IRecommendationFeedbackReq, IRecommendationFeedbackResponse } from 'src/app/shared/recommendations-new-template/models/new-recommendation.interface';
import { getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { getUserDetails, UserState } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { listofCampaigns } from '../dexter-dashboard/component-pieces/dashboard-models/campaignList.model';
import { ChannelsEnum } from '../enums/channels.enum';
import {
	IContentCreatorFieldsConfig,
	ICreatorBanterReq,
	ICreatorBanterResponse,
	ICreatorBudgetAdset,
	ICreatorBudgetSummaryData,
	ICreatorBudgetSummaryReq,
	ICreatorContentReq,
	ICreatorContentResponse,
	ICreatorDemographics,
	ICreatorDemographicsData,
	ICreatorDuplicateReq,
	ICreatorDuplicateResponse,
	ICreatorFeedbackReq,
	ICreatorFeedbackResponse,
	ICreatorFormatInterestsReq,
	ICreatorFormats,
	ICreatorInterestDeleteReq,
	ICreatorInterests,
	ICreatorLoadTemplates,
	ICreatorLoadTemplatesReq,
	ICreatorPixelData,
	ICreatorPixelsFormatReq,
	ICreatprDemographicsFormatReq,
	IDropDownAdsetList,
	IGenericRequestPayload
} from '../models/creator.model';
import { IChannelAsset, IChannelRecommendation } from '../models/dexter-dashboard.model';
import { IDexterAd, IDexterHealthRequest, IDexterHealthResponse, IDexterHealthScoreResponse, IHealthColorStatus } from '../models/dexter-health.interface';
import {
	IApplyActionReq,
	IApplyActionResponse,
	IApplyHiddenInterestReq,
	IApplyHiddenInterestToCreateReq,
	IApplyHiddenInterestToCreateResponse,
	IHiddenInterestResponse,
	IOverlapResponse,
	ISourceInterestReq,
	ISourceInterestResponse,
	ISuggestedInterestResponse
} from '../models/hidden-interests.interface';
import {
	ILabCampaignGroup,
	ILabsRecommendationsNumberOfPagesRequest,
	ILabsRecommendationsNumberOfPagesResponse,
	ILabType
} from '../models/labs-recommendation.interface';
import { Data, GraphData, ICampaignList, IGraphResponse, INewRxdReq, INewRxdResponse } from '../models/new-recommendation.interface';
import { OptimizationNumberOfPagesRequestInterface } from '../models/optimization-number-of-pages-request.interface';
import { OptimizationNumberOfPagesResponseInterface } from '../models/optimization-number-of-pages-response.interface';
import { OptimizationRecommendationsRequestInterface } from '../models/optimization-recommendations-request.interface';
import { OptimizeRecommendationsResponseInterface, OptimizeRecommendationStatus } from '../models/optimize-recommendations-response.interface';
import {
	ISyncReportProgressRequest,
	ISyncReportProgressResponse,
	ISyncReportStartResponse,
	ISyncReportSummaryResponse,
	ISyncUpdateMetricsRequest,
	ISyncUpdateMetricsRequestData
} from '../models/welcome-sync.model';
import { GoogleService } from 'src/app/_services/google/google.service';

@Injectable()
export class OptimizeService {
	public recommendationStatus: BehaviorSubject<number> = new BehaviorSubject(null);
	public selectedLabsTypes: BehaviorSubject<ILabType[]> = new BehaviorSubject([]);
	private callLabsRefreshSubject = new Subject<ILabType[]>();
	private callLabsCampaignSelectRefreshSubject = new Subject<ILabCampaignGroup>();
	private callDexterRefreshSubject = new Subject<boolean>();
	public selectFirstCampaignSubject: Subject<void> = new Subject();
	public selectFirstAdSubject: Subject<void> = new Subject();
	public setSelectedCampaign: BehaviorSubject<ILabCampaignGroup> = new BehaviorSubject(null);
	public allLabsCampaignGrouped: BehaviorSubject<ILabCampaignGroup[]> = new BehaviorSubject([]);
	public setSelectedDexterHealthAd: BehaviorSubject<IDexterAd> = new BehaviorSubject(null);
	public setSelectedInterest: BehaviorSubject<any> = new BehaviorSubject(null);
	public setSelectedDexterHealthCanpaign: BehaviorSubject<string> = new BehaviorSubject(null);
	public setAdsetSelectedName: BehaviorSubject<string> = new BehaviorSubject(null);
	public setAdSelectedName: BehaviorSubject<string> = new BehaviorSubject(null);
	public setAdsNumber: BehaviorSubject<number> = new BehaviorSubject(null);
	public setAdIndex: BehaviorSubject<number> = new BehaviorSubject(null);
	public allDexterHealthAds: BehaviorSubject<IDexterAd[]> = new BehaviorSubject([]);
	public dexterHealthScore$: BehaviorSubject<IDexterHealthScoreResponse> = new BehaviorSubject(null);
	public healthTooltip$: BehaviorSubject<string> = new BehaviorSubject('');
	public selectedAd$: BehaviorSubject<string> = new BehaviorSubject('Select Ad');
	public dexterSmartCreate$: BehaviorSubject<number> = new BehaviorSubject(null);
	public resetPagination$: BehaviorSubject<number> = new BehaviorSubject(null);
	public labsInputTexts$: BehaviorSubject<OptimizeRecommendationStatus> = new BehaviorSubject(null);
	public setCampaignListDataIdeas$: BehaviorSubject<listofCampaigns[]> = new BehaviorSubject(null);
	public setSelectedCreatorAdset$: BehaviorSubject<DropdownInterface> = new BehaviorSubject(null);
	public setSelectedSummary$: BehaviorSubject<DropdownInterface> = new BehaviorSubject(null);

	public dashboardSelectedRecn: BehaviorSubject<IChannelRecommendation> = new BehaviorSubject(null);

	public selectedTab$: BehaviorSubject<string> = new BehaviorSubject('');
	public setCampaignListData$: BehaviorSubject<DropdownInterface[]> = new BehaviorSubject(null);
	public setCategorySelected$: BehaviorSubject<string> = new BehaviorSubject(null);
	public setCategorySelectedIdeas$: BehaviorSubject<string> = new BehaviorSubject(null);
	public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isLoadedIdeas$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public refreshOptimizationScore$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public dataLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public dexterRxd$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public setActiveContentCreatorChannel$: BehaviorSubject<IChannelAsset> = new BehaviorSubject(null);
	private unsubscriber$ = new Subject<void>();
	public googleSeletedAd: any;

	constructor(
		private http: HttpClient,
		private toastService: ToastNotificationService,
		public store: Store<SharedState>,
		private userStore: Store<UserState>,
		private googleService: GoogleService
	) {}

	public getRecommendations(request: OptimizationRecommendationsRequestInterface): Observable<OptimizeRecommendationsResponseInterface> {
		return this.http.post<OptimizeRecommendationsResponseInterface>(`${BaseApiUrl.OptimizationApi}get-recommendations`, request);
	}

	public getNumberOfPages(request: OptimizationNumberOfPagesRequestInterface): Observable<OptimizationNumberOfPagesResponseInterface> {
		return this.http.post<OptimizationNumberOfPagesResponseInterface>(`${BaseApiUrl.OptimizationApi}recommendations-number-of-pages`, request);
	}

	public dismissRecommendation(recommendationId: string): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.OptimizationApi}dismiss-recommendation/${recommendationId}`, null);
	}

	public applyRecommendation(recommendationId: string, request?: IApplyActionReq): Observable<HttpResponse<IApplyActionResponse>> {
		return this.http.put<IApplyActionResponse>(`${BaseApiUrl.OptimizationApi}apply-recommendation/${recommendationId}`, request, { observe: 'response' });
	}

	public applyRecommendationToCreate(recommendationId: string, request?: IApplyHiddenInterestToCreateReq): Observable<IApplyHiddenInterestToCreateResponse> {
		return this.http.put<IApplyHiddenInterestToCreateResponse>(`${BaseApiUrl.OptimizationApi}apply-recommendation/${recommendationId}`, request);
	}

	public getInterest(
		request: ISourceInterestReq
	): Observable<ISourceInterestResponse | ISuggestedInterestResponse[] | IHiddenInterestResponse[] | IOverlapResponse> {
		return this.http.post<ISourceInterestResponse | ISuggestedInterestResponse[] | IHiddenInterestResponse[] | IOverlapResponse>(
			`${BaseApiUrl.DexterAnalytics}labs/hidden-interests`,
			request
		);
	}

	public getDexterHealthData(request: IDexterHealthRequest): Observable<IDexterHealthResponse> {
		return this.http.post<IDexterHealthResponse>(`${BaseApiUrl.Dexter}dexter-health/alerts`, request);
	}

	public getDexterHealthScore(request: IDexterHealthRequest): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.Dexter}dexter/score`, request);
	}

	public applyRecommendationFeedback(request: IRecommendationFeedbackReq): Observable<HttpResponse<IRecommendationFeedbackResponse>> {
		return this.http.put<IRecommendationFeedbackResponse>(`${BaseApiUrl.Dexter}dexter/feedback`, request, { observe: 'response' });
	}

	public getLabsNumberOfPages(request: ILabsRecommendationsNumberOfPagesRequest): Observable<ILabsRecommendationsNumberOfPagesResponse> {
		return this.http.post<ILabsRecommendationsNumberOfPagesResponse>(`${BaseApiUrl.OptimizationApi}recommendations-number-of-pages`, request);
	}

	public applyHiddenRecommendation(recommendationId: string, request: IApplyHiddenInterestReq): Observable<void> {
		return this.http.put<void>(`${BaseApiUrl.OptimizationApi}apply-recommendation/${recommendationId}`, request);
	}

	public dexterSyncReportSummary(request: ISyncReportProgressRequest): Observable<ISyncReportSummaryResponse> {
		return this.http.post<ISyncReportSummaryResponse>(`${BaseApiUrl.DexterSync}dexter-sync/report/summary`, request);
	}

	public updateReportMetric(request: ISyncUpdateMetricsRequest): Observable<ISyncReportStartResponse> {
		return this.http.put<ISyncReportStartResponse>(`${BaseApiUrl.DexterSync}dexter-sync/report/summary/result-metric`, request);
	}

	public updateCampaignSyncStatus(request: ISyncUpdateMetricsRequest): Observable<ISyncReportStartResponse> {
		return this.http.put<ISyncReportStartResponse>(`${BaseApiUrl.DexterSync}dexter-sync/report/campaign/sync-status`, request);
	}

	public generateRecommendations(request: ISyncReportProgressRequest): Observable<ISyncReportStartResponse> {
		return this.http.post<ISyncReportStartResponse>(`${BaseApiUrl.DexterSync}dexter-sync/recommendation/start`, request);
	}

	// Creator API
	public getCreatorInterests(page: number, page_size: number, request: IGenericRequestPayload): Observable<HttpResponse<ICreatorInterests>> {
		return this.http.post<ICreatorInterests>(`${BaseApiUrl.OptimizationApi}dexter-creator/interests?page=${page}&page_size=${page_size}`, request, {
			observe: 'response'
		});
	}
	public formatCreatorInterests(request: ICreatorFormatInterestsReq): Observable<HttpResponse<ICreatorFormats>> {
		return this.http.post<ICreatorFormats>(`${BaseApiUrl.OptimizationApi}dexter-creator/format/interests`, request, { observe: 'response' });
	}
	public removeCreatorInterest(request: ICreatorInterestDeleteReq): Observable<any> {
		return this.http.request('delete', `${BaseApiUrl.OptimizationApi}dexter-creator/interests`, { body: request });
	}

	public getCreatorDemographics(request: IGenericRequestPayload): Observable<HttpResponse<ICreatorDemographics>> {
		return this.http.post<ICreatorDemographics>(`${BaseApiUrl.OptimizationApi}dexter-creator/demographics`, request, { observe: 'response' });
	}
	public formatCreatorDemographics(request: ICreatprDemographicsFormatReq): Observable<HttpResponse<ICreatorFormats>> {
		return this.http.post<ICreatorFormats>(`${BaseApiUrl.OptimizationApi}dexter-creator/format/demographics`, request, { observe: 'response' });
	}

	public getCreatorPixel(request: IGenericRequestPayload): Observable<HttpResponse<ICreatorPixelData>> {
		return this.http.post<ICreatorPixelData>(`${BaseApiUrl.OptimizationApi}dexter-creator/pixel-data`, request, { observe: 'response' });
	}
	public formatCreatorPixel(request: ICreatorPixelsFormatReq): Observable<HttpResponse<ICreatorFormats>> {
		return this.http.post<ICreatorFormats>(`${BaseApiUrl.OptimizationApi}dexter-creator/format/pixel-data`, request, { observe: 'response' });
	}

	public getCreatorAvailableAdsets(request: IGenericRequestPayload): Observable<HttpResponse<ICreatorBudgetAdset>> {
		return this.http.post<ICreatorBudgetAdset>(`${BaseApiUrl.OptimizationApi}dexter-creator/budget-allocation/adsets`, request, { observe: 'response' });
	}
	public getCreatorBudgetSummary(request: ICreatorBudgetSummaryReq): Observable<HttpResponse<ICreatorBudgetSummaryData>> {
		return this.http.post<ICreatorBudgetSummaryData>(`${BaseApiUrl.OptimizationApi}dexter-creator/budget-allocation/summary`, request, {
			observe: 'response'
		});
	}

	public creatorDuplicate(request: ICreatorDuplicateReq): Observable<HttpResponse<ICreatorDuplicateResponse>> {
		return this.http.post<ICreatorDuplicateResponse>(`${BaseApiUrl.OptimizationApi}dexter-creator/action/duplicate`, request, { observe: 'response' });
	}

	public loadTemplates(request: ICreatorLoadTemplatesReq): Observable<HttpResponse<ICreatorLoadTemplates>> {
		return this.http.post<ICreatorLoadTemplates>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/templates`, request, { observe: 'response' });
	}

	public loadContentCreatorConfigs(): Observable<HttpResponse<IContentCreatorFieldsConfig>> {
		return this.http.get<IContentCreatorFieldsConfig>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/user-input/fields`, { observe: 'response' });
	}

	public retrieveBanter(request: ICreatorBanterReq): Observable<HttpResponse<ICreatorBanterResponse>> {
		return this.http.post<ICreatorBanterResponse>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/user-output/banter`, request, {
			observe: 'response'
		});
	}
	public getWelcomeTextforCreator(): Observable<ICreatorLoadTemplates> {
		return this.http.get<ICreatorLoadTemplates>(`${BaseApiUrl.OptimizationApi}dexter-creator/templates/welcome-screen`);
	}
	public generateContentCreator(request: ICreatorContentReq): Observable<HttpResponse<ICreatorContentResponse>> {
		return this.http.post<ICreatorContentResponse>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/generate`, request, { observe: 'response' });
	}

	public feedbackContentCreator(request: any): Observable<HttpResponse<ICreatorFeedbackResponse>> {
		return this.http.post<ICreatorFeedbackResponse>(`${BaseApiUrl.DexterGenesis}dexter-content-creator/text/feedback`, request, { observe: 'response' });
	}
	// End of Creator API

	// Start new recommendations API
	public getRxds(request: INewRxdReq): Observable<INewRxdResponse> {
		return this.http.post<INewRxdResponse>(`${BaseApiUrl.OptimizationApi}dexter/recommendation`, request);
	}
	public getRxdsGoogle(request: INewRxdReq): Observable<INewRxdResponse> {
		return this.http.post<INewRxdResponse>(`${BaseApiUrl.OptimizationApiGoogle}dexter/recommendation`, request);
	}

	public getCampaignsList(request: INewRxdReq): Observable<ICampaignList> {
		return this.http.post<ICampaignList>(`${BaseApiUrl.OptimizationApi}dexter/recommendation/campaigns`, request);
	}
	public getGoogleCampaignsList(request: INewRxdReq): Observable<ICampaignList> {
		return this.http.post<ICampaignList>(`${BaseApiUrl.OptimizationApiGoogle}dexter/recommendation/campaigns`, request);
	}

	public genericRxdRequest(dataObj: Data = null, loadCampaignList = null, channelName?: string): Observable<DropdownInterface[][]> {
		return this.userStore.pipe(
			select(getUserDetails),
			take(1),
			concatMap(userDetails => {
				const businessOwnerId = userDetails.FacebookBusinessOwnerId;
				const filedUserId = userDetails.FiledId;
				return this.userStore.pipe(
					select(getSelectedAdAccount),
					take(1),
					concatMap(adAccount => {
						const userObject = {
							businessOwnerId: businessOwnerId,
							adAccountId: adAccount.adAccount.id.replace('act_', '')
						};
						const forkObject = [];
						if (dataObj) {
							if (channelName === 'google') {
								this.googleService.selectedGoogleAccount.pipe(takeUntil(this.unsubscriber$)).subscribe(selectedAcc => {
									if (selectedAcc) {
										this.googleSeletedAd = selectedAcc;
										let reqObj: INewRxdReq = {
											user: {
												adAccountId: selectedAcc.businessId.toString()
											}
										};
										reqObj.data = dataObj;
										forkObject.push(this.getRxdsGoogle(reqObj));
									}
								});
							} else {
								let reqObj: INewRxdReq = {
									user: null
								};
								reqObj.user = userObject;
								reqObj.data = dataObj;
								forkObject.push(this.getRxds(reqObj));
							}
						}
						if (loadCampaignList) {
							if (channelName === 'google') {
								this.googleService.selectedGoogleAccount.pipe(takeUntil(this.unsubscriber$)).subscribe(selectedAcc => {
									if (selectedAcc) {
										this.googleSeletedAd = selectedAcc;
										const userObject = {
											filedUserId: filedUserId.toString(),
											adAccountId: this.googleSeletedAd.businessId
										};

										const reqObj = {
											user: userObject
										};
										forkObject.push(this.getGoogleCampaignsList(reqObj));
									}
								});
							} else {
								let reqObj: INewRxdReq = {
									user: null
								};
								reqObj.user = userObject;
								if (reqObj.data) {
									delete reqObj.data;
								}
								forkObject.push(this.getCampaignsList(reqObj));
							}
						}
						return forkJoin(forkObject).pipe(
							concatMap(([...resObj]) => {
								let overviewData: INewRxdResponse = null;
								let campaignListData: ICampaignList = null;
								overviewData = resObj[0] as INewRxdResponse;
								if (loadCampaignList) {
									campaignListData = resObj[1] as ICampaignList;
								}

								const allRxdData = overviewData?.recommendations;
								const allTopRxdData = overviewData?.topRecommendations;
								const listAllRxdData = allRxdData?.map((rxdData, index) => {
									const data = rxdData;
									return {
										id: index,
										name: rxdData.structureName,
										data
									};
								});
								const listAllTopRxdData = allTopRxdData?.map((rxdData, index) => {
									const data = rxdData;
									return {
										id: index,
										name: rxdData.structureName,
										data
									};
								});
								const listAllCampaigns = campaignListData?.data.map((cData, index) => {
									const data = cData;
									return {
										id: index,
										name: cData.campaignName,
										data
									};
								});
								const listMetaData = [
									{
										id: 0,
										name: 'meta',
										data: [overviewData.defaultPage, overviewData.debugMessage, overviewData.tooltips],
										categoryData: {
											overviewCategoryData: overviewData.recommendationCategoryCount
										}
									}
								];

								return of([listAllRxdData, listAllTopRxdData, listMetaData, listAllCampaigns]);
							}),
							catchError(error => {
								const errorData = error.error;
								const listMetaData = [
									{
										id: 0,
										name: 'meta',
										data: [errorData?.defaultPage ? errorData?.defaultPage : '', errorData?.debugMessage ? errorData?.debugMessage : ''],
										categoryData: null
									}
								];
								this.isLoaded$.next(true);
								this.toastService.sendCustomToast('There was an error processing your request', ToastNotificationType.Error);
								return of([[], [], listMetaData, []]);
							})
						);
					})
				);
			}),
			catchError(error => {
				this.isLoaded$.next(true);
				this.toastService.sendCustomToast('There was an error processing your request', ToastNotificationType.Error);
				return EMPTY;
			})
		);
	}

	public getCampaignRxdRequest(dataAccount: Data = null, channelName?: string): Observable<DropdownInterface[][]> {
		return this.userStore.pipe(
			select(getUserDetails),
			take(1),
			concatMap(userDetails => {
				const businessOwnerId = userDetails.FacebookBusinessOwnerId;
				const filedUserId = userDetails.FiledId;
				return this.userStore.pipe(
					select(getSelectedAdAccount),
					take(1),
					concatMap(adAccount => {
						const userObject = {
							businessOwnerId: businessOwnerId,
							adAccountId: adAccount.adAccount.id.replace('act_', '')
						};
						const forkObject = [];
						if (dataAccount) {
							if (channelName === 'google') {
								let accId = this.googleSeletedAd.businessId;
								const userObject = {
									adAccountId: accId
								};

								const reqObj = {
									user: userObject,
									data: dataAccount
								};
								forkObject.push(this.getRxdsGoogle(reqObj));
							} else {
								let reqObj: INewRxdReq = {
									user: null
								};
								reqObj.user = userObject;
								reqObj.data = dataAccount;
								forkObject.push(this.getRxds(reqObj));
							}
						}
						return forkJoin(forkObject).pipe(
							concatMap(([...resObj]) => {
								let campaignData: INewRxdResponse = null;
								campaignData = resObj[0] as INewRxdResponse;
								const allRxdData = campaignData.recommendations;
								const allTopRxdData = campaignData.topRecommendations;
								const listAllRxdData = allRxdData?.map((rxdData, index) => {
									const data = rxdData;
									return {
										id: index,
										name: rxdData.structureName,
										data
									};
								});
								const listAllTopRxdData = allTopRxdData?.map((rxdData, index) => {
									const data = rxdData;
									return {
										id: index,
										name: rxdData.structureName,
										data
									};
								});

								const listMetaData = [
									{
										id: 0,
										name: 'meta',
										data: [campaignData.defaultPage, campaignData.debugMessage, campaignData.tooltips],
										categoryData: {
											campaignCategoryData: campaignData.recommendationCategoryCount
										}
									}
								];

								return of([listAllRxdData, listAllTopRxdData, listMetaData]);
							}),
							catchError(error => {
								this.isLoaded$.next(true);
								this.toastService.sendCustomToast('There was an error processing your request', ToastNotificationType.Error);
								return EMPTY;
							})
						);
					})
				);
			}),
			catchError(error => {
				this.isLoaded$.next(true);
				this.toastService.sendCustomToast('There was an error processing your request', ToastNotificationType.Error);
				return EMPTY;
			})
		);
	}

	public getGraphRxdRequest(data: any, channelName?: string): Observable<HttpResponse<IGraphResponse>> {
		return this.userStore.pipe(
			select(getUserDetails),
			take(1),
			concatMap(userDetails => {
				const businessOwnerId = userDetails.FacebookBusinessOwnerId;
				const filedUserId = userDetails.FiledId;
				return this.userStore.pipe(
					select(getSelectedAdAccount),
					take(1),
					catchError(error => {
						return EMPTY;
					}),
					concatMap(adAccount => {
						if (channelName === 'google') {
							let accId = this.googleSeletedAd.businessId;
							const userObject = {
								filedUserId: filedUserId.toString(),
								accountId: accId
							};

							const reqObj = {
								user: userObject,
								data
							};
							return this.http.post<any>(`${BaseApiUrl.DexterAnalyticsGoogle}dexter/recommendation/graph`, reqObj, { observe: 'response' });
						} else {
							const userObject = {
								businessOwnerId: businessOwnerId,
								adAccountId: adAccount.adAccount.id.replace('act_', '')
							};
							const reqObj = {
								user: userObject,
								data
							};
							return this.http.post<IGraphResponse>(`${BaseApiUrl.DexterAnalytics}dexter/recommendation/graph`, reqObj, { observe: 'response' });
						}
					})
				);
			}),
			catchError(error => {
				return of(error);
			})
		);
	}

	public getWelcomeMsgRequest(channelName?: string): Observable<IGraphResponse> {
		return this.userStore.pipe(
			select(getUserDetails),
			take(1),
			concatMap(userDetails => {
				const filedUserId = userDetails.FiledId;
				const businessOwnerId = userDetails.FacebookBusinessOwnerId;
				return this.userStore.pipe(
					select(getSelectedAdAccount),
					take(1),
					concatMap(adAccount => {
						if (channelName === 'google') {
							let accId = this.googleSeletedAd.businessId;
							const userObject = {
								filedUserId: filedUserId.toString(),
								accountId: accId
							};

							const reqObj = {
								user: userObject
							};
							return this.http.post<any>(`${BaseApiUrl.OptimizationApiGoogle}dexter/recommendation/welcome-message`, reqObj);
						} else {
							const userObject = {
								businessOwnerId: businessOwnerId,
								adAccountId: adAccount.adAccount.id.replace('act_', '')
							};
							const reqObj = {
								user: userObject
							};
							return this.http.post<any>(`${BaseApiUrl.OptimizationApi}dexter/recommendation/welcome-message`, reqObj);
						}
					})
				);
			}),
			catchError(error => {
				return EMPTY;
			})
		);
	}
	public getWelcomeMsgRequestIdeas(): Observable<IGraphResponse> {
		return this.userStore.pipe(
			select(getUserDetails),
			take(1),
			concatMap(userDetails => {
				const businessOwnerId = userDetails.FacebookBusinessOwnerId;
				return this.userStore.pipe(
					select(getSelectedAdAccount),
					take(1),
					concatMap(adAccount => {
						const userObject = {
							businessOwnerId: businessOwnerId,
							adAccountId: adAccount.adAccount.id.replace('act_', '')
						};
						const reqObj = {
							user: userObject
						};
						return this.http.post<any>(`${BaseApiUrl.OptimizationApi}dexter/new-ideas/welcome-message`, reqObj);
					})
				);
			}),
			catchError(error => {
				return EMPTY;
			})
		);
	}
	public getOptimizationScoreRequest(channelName?: string): Observable<IGraphResponse> {
		return this.userStore.pipe(
			select(getUserDetails),
			take(1),
			concatMap(userDetails => {
				const filedUserId = userDetails.FiledId;
				const businessOwnerId = userDetails.FacebookBusinessOwnerId;
				return this.userStore.pipe(
					select(getSelectedAdAccount),
					take(1),
					concatMap(adAccount => {
						if (channelName === 'google') {
							let accId = this.googleSeletedAd.businessId;
							const userObject = {
								filedUserId: filedUserId.toString(),
								adAccountId: accId
							};

							const reqObj = {
								user: userObject
							};
							return this.http.post<any>(`${BaseApiUrl.OptimizationApiGoogle}dexter/recommendation/optimization-score`, reqObj);
						} else {
							const userObject = {
								businessOwnerId: businessOwnerId,
								adAccountId: adAccount.adAccount.id.replace('act_', '')
							};
							const reqObj = {
								user: userObject
							};
							return this.http.post<any>(`${BaseApiUrl.OptimizationApi}dexter/recommendation/optimization-score`, reqObj);
						}
					})
				);
			}),
			catchError(error => {
				return EMPTY;
			})
		);
	}

	public rxdActions(type: string, rxdIds: string[], channelName?: string): Observable<HttpResponse<{ message: string }>> {
		const rxdActionObj = {
			data: {}
		};
		let endPointUrl;
		if (channelName === 'google') {
			endPointUrl = BaseApiUrl.OptimizationApiGoogle;
		} else {
			endPointUrl = BaseApiUrl.OptimizationApi;
		}
		type === 'apply' ? (rxdActionObj.data['apply'] = rxdIds) : (rxdActionObj.data['dismiss'] = rxdIds);
		return this.http.put<{ message: string }>(`${endPointUrl}dexter/recommendation/action/${type}`, rxdActionObj, { observe: 'response' });
	}
	// End new recommendation API

	public sendLabsRefreshEvent(typeLabsData: ILabType[]): void {
		this.callLabsRefreshSubject.next(typeLabsData);
	}

	public getLabsRefreshEvent(): Observable<ILabType[]> {
		return this.callLabsRefreshSubject.asObservable();
	}

	public sendLabsCampaignSelectRefreshEvent(campaign: ILabCampaignGroup): void {
		this.callLabsCampaignSelectRefreshSubject.next(campaign);
	}

	public getLabsCampaignSelectRefreshEvent(): Observable<ILabCampaignGroup> {
		return this.callLabsCampaignSelectRefreshSubject.asObservable();
	}

	public shortenSomeText(text: string, length: number): string {
		if (text) {
			const strTake = text.length <= length ? text : `${text.substring(0, length)}...`;
			return strTake;
		} else {
			return '';
		}
	}

	public getColorAndStatus(value: number): IHealthColorStatus {
		let overall: IHealthColorStatus = {
			colorCode: '#FFC12F',
			overallStatus: 'Neutral',
			similarityStatus: 'Neutral'
		};
		if (value > 60) {
			overall = {
				colorCode: '#09D8DE',
				overallStatus: 'Positive Health',
				similarityStatus: 'Similar'
			};
		} else if (value < 60) {
			overall = {
				colorCode: '#DC3445',
				overallStatus: 'Negative Health',
				similarityStatus: 'Low Similarity'
			};
		} else if (value === 60) {
			overall = {
				colorCode: '#FFC12F',
				overallStatus: 'Neutral Health',
				similarityStatus: 'Neutral'
			};
		}
		return overall;
	}

	public applyRecommendationAction(recommendationId: string): void {
		const applyActionReq: IApplyActionReq = {
			apply_button_type: 4
		};
		this.applyRecommendation(recommendationId, applyActionReq)
			.pipe(
				take(3),
				catchError(error => {
					this.toastService.sendWarningToast('There was an issue applying recommendations', 10000);
					return EMPTY;
				})
			)
			.subscribe(value => {});
	}

	public fetchReportSyncSummary(): Observable<any> {
		return this.store.select(getSelectedAdAccount).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([adAccount, userDetails]) => {
				const syncProgressReq: ISyncReportProgressRequest = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					]
				};
				return this.dexterSyncReportSummary(syncProgressReq);
			})
		);
	}

	public updatResultMetric(dataObj: ISyncUpdateMetricsRequestData, yesSteps: boolean): Observable<any> {
		return this.store.select(getSelectedAdAccount).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([adAccount, userDetails]) => {
				const syncProgressReq: ISyncUpdateMetricsRequest = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					],
					data: [dataObj]
				};
				return !yesSteps ? this.updateReportMetric(syncProgressReq) : this.updateCampaignSyncStatus(syncProgressReq);
			})
		);
	}

	public syncGenerateRecommendations(): Observable<any> {
		return this.store.select(getSelectedAdAccount).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([adAccount, userDetails]) => {
				const syncProgressReq: ISyncReportProgressRequest = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					]
				};
				return this.generateRecommendations(syncProgressReq);
			})
		);
	}
}
