import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUserDetails } from '../state/user/user.reducer';
import { Modules } from '../permisions/enums/modules';

@Injectable()
export class AuthenticationGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, public store: Store) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const isUserLoggedIn$ = of(this.authService.isLoggedIn());
		const runFBBusinessOwner$ = this.authService.initFBBusinessOwner();
		const runGoogleBusinessOwner$ = this.authService.initGoogleBusinessOwner();

		const hasBusinessOwner$ = this.authService.hasBusinessOwner();
		const hasCreditCard$ = this.authService.hasCreditCard();
		const isAdmin$ = this.authService.isAdmin();
		const isFreemiumExpired$ = this.authService.isFreemiumExpired();
		const isFreeTrialExpired$ = this.authService.isFreeTrialExpired();

		return forkJoin([
			isUserLoggedIn$,
			runFBBusinessOwner$,
			runGoogleBusinessOwner$,
			hasCreditCard$,
			isAdmin$,
			hasBusinessOwner$,
			isFreemiumExpired$,
			isFreeTrialExpired$
		]).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			switchMap(
				([
					[
						isUserLoggedIn,
						runFBBusinessOwner,
						runGoogleBusinessOwner,
						hasCreditCard,
						isAdmin,
						hasBusinessOwner,
						isFreemiumExpired,
						isFreeTrialExpired
					],
					userDetails
				]) => {
					const fbId = userDetails?.FacebookBusinessOwnerId;
					const googleBO = userDetails?.GoogleBusinessOwnerId;
					//  JSON.parse(localStorage.getItem('installedPlatorm'))?.find(value => {
					// 	return value === 'Google';
					// });
					const canskip = localStorage.getItem('skipConnect');
					const isInfluencer = userDetails?.Permissions.find(module => {
						return module.module === Modules.Influencer;
					});
					if (!isUserLoggedIn) {
						this.router.navigate(['/authentication']);
						return of(false);
					}
					if (!fbId && !googleBO && !isAdmin && !canskip && !isInfluencer) {
						this.router.navigate(['/authentication/connect-channel']);
						return of(false);
					} else if (!hasCreditCard && !isAdmin) {
						this.router.navigate(['/authentication/payment']);
						return of(false);
					} else if (isFreemiumExpired || isFreeTrialExpired) {
						this.router.navigate(['/authentication/trial-end']);
						return of(false);
					}

					return of(true);
				}
			)
		);
	}
}
