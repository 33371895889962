import { Injectable } from '@angular/core';

import { DefaultChartValues } from '../../models/default-chart-values';
import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { GridLayout } from '../../models/grid-layout';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { ChartLabel } from '../../models/chart-label';

@Injectable({
	providedIn: 'root'
})
export class HeatChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel) {
		const chartOptions = this.initChartOptions(widget);
		chartOptions.grid = new GridLayout('100', '20', '20', '120', false);
		chartOptions.nameTextStyle = {
			color: 'rgba(66,66,66, 0.8)'
		};
		const chart = this.createSeriesForHeatChart(widget);
		Object.assign(chartOptions, this.createHeatOrNumericChart(chart, widget));
		chartOptions.legend = null;

		return chartOptions;
	}

	public createSeriesForHeatChart(widget: ReportModel) {
		let dimensions: any[] = [];
		let chartData: any = [];
		let dataKeys: string[];
		const label = new ChartLabel('middle', 'middle', 'inside', 0, 20, 12, widget.details.reportProperty.showDataLabels);
		let ComplexchartData = this.createChartSourceForComplexData(widget, false, false, false, label);
		if (widget.details.chartData && widget.details.chartData.length > 0) {
			let mappedData: any[] = [];
			dataKeys = Object.keys(widget.details.chartData[0]);
			let dimensionKey;
			if (widget.details.breakdown) {
				dimensionKey = dataKeys.splice(dataKeys.indexOf(widget.details.breakdown.columnName), 1)[0];
			} else if (widget.details.dimension) {
				dimensionKey = dataKeys.splice(dataKeys.indexOf(widget.details.dimension.primaryValue.name), 1)[0];
			} else {
				let selectkey = widget.details.reportLevel.toLowerCase() + '_name';
				dimensionKey = dataKeys.splice(dataKeys.indexOf(selectkey), 1)[0];
			}
			for (let i = 0; i < widget.details.chartData.length; i++) {
				dimensions.push(widget.details.chartData[i][dimensionKey]);

				for (let j = 0; j < dataKeys.length; j++) {
					const value = widget.details.chartData[i][dataKeys[j]] ? widget.details.chartData[i][dataKeys[j]] : 0;
					mappedData.push({
						value: [i, j, parseFloat((Math.round(value * 100) / 100).toString()).toFixed(2)]
					});
				}
			}
			if (widget.details.breakdown && widget.details.dimension) {
				dimensions = [];
				dataKeys = [];
				mappedData = [];
				dimensions = ComplexchartData.dimensions;
				dataKeys = ComplexchartData.data.map(item => item.breakdown);
				for (let i = 0; i < ComplexchartData.data.length; i++) {
					for (let j = 0; j < ComplexchartData.data[i].data.length; j++) {
						const value = ComplexchartData.data[i].data[j];
						mappedData.push({
							value: [j, i, parseFloat((Math.round(value * 100) / 100).toString()).toFixed(2)]
						});
					}
				}
			}

			chartData = mappedData;
		} else {
			chartData = DefaultChartValues.Heat;
		}

		const dataResult = this.createGoalsForHeatChart(chartData, widget);
		const min = Math.min(...(chartData as any).map((item: any) => Math.min(...item.value)));
		const max = Math.max(...(chartData as any).map((item: any) => Math.max(...item.value))) * 1.4;

		return {
			data: dataResult,
			dimensions: dimensions,
			metrics: dataKeys,
			min: min,
			max: max
		};
	}

	public createGoalsForHeatChart(data: any, widget: ReportModel) {
		const chartData: any = [];

		data.forEach((item: any) => {
			chartData.push({
				name: 'Heat chart',
				type: 'heatmap',
				data: data,
				label: {
					normal: {
						show: true
					}
				},
				itemStyle: {
					normal:
						widget && widget.details.metric && widget.details.metric.length && widget.details.metric[0].primaryValue
							? this.createGoalColors(item[2], widget.details.metric[0].primaryValue.name, widget)
							: this.createGoalColors(item[2], '', widget)
				},
				axisLabel: {
					margin: 70,
					align: 'left',
					formatter: (value: any) => {
						return value.length > 15 ? value.substr(0, 10) + '...' : value;
					}
				},
				emphasis: {
					itemStyle: {
						shadowBlur: 10,
						shadowColor: 'rgba(0, 0, 0, 0.5)'
					}
				}
			});
		});

		return chartData;
	}

	private createHeatOrNumericChart(chart: any, widget: ReportModel) {
		return {
			tooltip: {
				position: 'top'
			},
			animation: true,
			xAxis: {
				type: 'category',
				data: chart.dimensions,
				splitArea: {
					show: true
				},
				nameGap: 40,
				nameLocation: 'middle',
				name: widget.details.reportProperty.horizontalAxisTitle,
				axisLabel: {
					formatter: (value: any) => {
						return value.length > 10 ? value.substr(0, 10) + '...' : value;
					}
				}
			},
			yAxis: {
				type: 'category',
				data: chart.metrics,
				splitArea: {
					show: true
				},
				nameLocation: 'middle',
				nameGap: 85,
				name: widget.details.reportProperty.verticalAxisTitle
			},
			axisLabel: {
				formatter: (value: any) => {
					return value.length > 10 ? value.substr(0, 10) + '...' : value;
				}
			},
			visualMap: {
				min: chart.min,
				max: chart.max,
				calculable: true,
				orient: 'horizontal',
				left: 'center',
				bottom: '5%'
			},
			series: chart.data
		};
	}
}
