import { Component, Injector, Input, OnInit } from '@angular/core';
import { TargetingCardComponent } from 'src/app/shared/smart-create-edit-components/step-two-components/targeting-card/targeting-card.component';
import { DropdownData } from '@filed-com/filed-lib';
import { FacebookLocationsView } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations-view';

@Component({
	selector: 'app-audience',
	templateUrl: './audience.component.html',
	styleUrls: ['./audience.component.scss']
})
export class AudienceComponent extends TargetingCardComponent implements OnInit {
	@Input() public selectedObjective: string;
	@Input() public selectedLocations: DropdownData<FacebookLocationsView>[];
	constructor(injector: Injector) {
		super(injector);
	}
	public ngOnInit(): void {
		super.ngOnInit();
	}
}
