<div [ngClass]="{ 'my-class': roleIsReadonly }">
	<div class="single-option-tables">
		<!--Accounts-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Accounts</h4>
				<div class="access-box">
					<input
						type="checkbox"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Accounts, this.accountPermissionsEnum.CanAccessAccounts)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Accounts).get(this.accountPermissionsEnum.CanAccessAccounts)"
					/>
					<span>Can access</span>
				</div>
			</div>
		</div>

		<!--Pixel-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Pixel</h4>
				<div class="access-box">
					<input
						type="checkbox"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Pixel, this.pixelsPermissionsEnum.CanAccessPixels)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Pixel).get(this.pixelsPermissionsEnum.CanAccessPixels)"
					/>
					<span>Can access</span>
				</div>
			</div>
		</div>
	</div>

	<div class="single-option-tables">
		<!--Ads Manager-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Ads Manager</h4>
				<div class="access-box">
					<input
						type="checkbox"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.AdsManager, this.adsManagerPermissionsEnum.CanAccessAdsManager)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.AdsManager).get(this.adsManagerPermissionsEnum.CanAccessAdsManager)"
					/>
					<span>Can access</span>
				</div>
			</div>
			<div class="table">
				<div class="table-row">
					<div class="table-head"></div>
					<div class="table-head">
						<h6>Edit</h6>
					</div>
					<div class="table-head">
						<h6>Delete</h6>
					</div>
				</div>
				<div class="table-row">
					<div class="table-cell">
						<h6>Ads Manager</h6>
					</div>
					<!--ALL checkbox-->
					<!--edit checkbox-->
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.AdsManager, this.adsManagerPermissionsEnum.AdsManagerEdit)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.AdsManager).get(this.adsManagerPermissionsEnum.AdsManagerEdit)"
						/>
					</div>
					<!--delete checkbox-->
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.AdsManager, this.adsManagerPermissionsEnum.AdsManagerDelete)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.AdsManager).get(this.adsManagerPermissionsEnum.AdsManagerDelete)"
						/>
					</div>
				</div>
			</div>
		</div>

		<!--Audience-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Audience</h4>
				<div class="access-box">
					<input
						type="checkbox"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Audience, this.audiencePermissionsEnum.CanAccessAudience)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Audience).get(this.audiencePermissionsEnum.CanAccessAudience)"
					/>
					<span>Can access</span>
				</div>
			</div>
			<div class="table">
				<div class="table-row">
					<div class="table-head"></div>

					<div class="table-head">
						<h6>Edit</h6>
					</div>
					<div class="table-head">
						<h6>Create</h6>
					</div>
					<div class="table-head">
						<h6>Delete</h6>
					</div>
				</div>
				<div class="table-row">
					<div class="table-cell">
						<h6>Audience</h6>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Audience, this.audiencePermissionsEnum.AudienceEdit)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Audience).get(this.audiencePermissionsEnum.AudienceEdit)"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Audience, this.audiencePermissionsEnum.AudienceCreate)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Audience).get(this.audiencePermissionsEnum.AudienceCreate)"
						/>
					</div>
					<div class="table-cell">
						<i class="fas fa-stop"></i>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--Miscellaneous-->
	<div class="table-wrapper">
		<div class="table-header">
			<h4>Miscellaneous</h4>
		</div>
		<div class="table">
			<div class="table-row">
				<div class="table-head"></div>
				<div class="table-head">
					<h6>Access</h6>
				</div>
				<div class="table-head">
					<h6>Export</h6>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Smart Table</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.MiscellaneousFiledSmartTableAccess)"
						[checked]="
							allPermissionsForAllModules
								.get(this.modulesEnum.Miscellaneous)
								.get(this.miscellaneousPermissionsEnum.MiscellaneousFiledSmartTableAccess)
						"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.MiscellaneousFiledSmartTableExport)"
						[checked]="
							allPermissionsForAllModules
								.get(this.modulesEnum.Miscellaneous)
								.get(this.miscellaneousPermissionsEnum.MiscellaneousFiledSmartTableExport)
						"
					/>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Facebook</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.MiscellaneousFacebookAccess)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.MiscellaneousFacebookAccess)
						"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Google</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.MiscellaneousGoogleAccess)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.MiscellaneousGoogleAccess)
						"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Shopify</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.MiscellaneousShopifyAccess)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.MiscellaneousShopifyAccess)
						"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
		</div>
	</div>

	<div class="single-option-tables">
		<!--Campaign Builder-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Campaign Builder</h4>
				<div class="access-box">
					<input
						type="checkbox"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CampaignBuilder, this.campaignBuilderPermissionsEnum.CanAccessCampaignBuilder)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.CampaignBuilder).get(this.campaignBuilderPermissionsEnum.CanAccessCampaignBuilder)
						"
					/>
					<span>Can access</span>
				</div>
			</div>
			<div class="table">
				<div class="table-row">
					<div class="table-head"></div>
					<div class="table-head">
						<h6>View</h6>
					</div>
				</div>
				<div class="table-row">
					<div class="table-cell">
						<h6>Smart Create</h6>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.CampaignBuilder, this.campaignBuilderPermissionsEnum.SmartCreateView)"
							[checked]="
								allPermissionsForAllModules.get(this.modulesEnum.CampaignBuilder).get(this.campaignBuilderPermissionsEnum.SmartCreateView)
							"
						/>
					</div>
				</div>
			</div>
		</div>

		<!--Optimize-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Optimize</h4>
				<div class="access-box">
					<input
						type="checkbox"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Optimize, this.optimizePermissionsEnum.CanAccessOptimize)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.CampaignBuilder).get(this.optimizePermissionsEnum.CanAccessOptimize)"
					/>
					<span>Can access</span>
				</div>
			</div>
			<div class="table">
				<div class="table-row">
					<div class="table-head"></div>

					<div class="table-head">
						<h6>Edit</h6>
					</div>
					<div class="table-head">
						<h6>Delete</h6>
					</div>
				</div>
				<div class="table-row">
					<div class="table-cell">
						<h6>Optimize</h6>
					</div>
					<div class="table-cell"></div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Optimize, this.optimizePermissionsEnum.OptimizeDelete)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.CampaignBuilder).get(this.optimizePermissionsEnum.OptimizeDelete)"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--Creative Builder -->
	<div class="table-wrapper">
		<div class="table-header">
			<h4>Creative Builder</h4>
			<div class="access-box">
				<input
					type="checkbox"
					[disabled]="roleIsReadonly"
					(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CanAccessCreativeBuilder)"
					[checked]="
						allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CanAccessCreativeBuilder)
					"
				/>
				<span>Can access</span>
			</div>
		</div>
		<div class="table">
			<div class="table-row">
				<div class="table-head"></div>

				<div class="table-head">
					<h6>View</h6>
				</div>
				<div class="table-head">
					<h6>Edit</h6>
				</div>
				<div class="table-head">
					<h6>Create</h6>
				</div>
				<div class="table-head">
					<h6>Delete</h6>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Creative Builder</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBView)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>My Library</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBMyLibraryView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBMyLibraryView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBMyLibraryEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBMyLibraryEdit)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBMyLibraryDelete)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBMyLibraryDelete)"
					/>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Create New</h6>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBCreateNewEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBCreateNewEdit)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBCreateNewCreate)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBCreateNewCreate)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Brand Guardian</h6>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBBrandGuardianEdit)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBBrandGuardianEdit)
						"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.CreativeBuilder, this.creativeBuilderPermissionsEnum.CBBrandGuardianCreate)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.CreativeBuilder).get(this.creativeBuilderPermissionsEnum.CBBrandGuardianCreate)
						"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
		</div>
	</div>

	<div class="single-option-tables">
		<!--Role assigned-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Role assigned</h4>
			</div>
			<div class="table">
				<div class="table-row">
					<div class="table-head"></div>
					<div class="table-head">
						<h6>Admin</h6>
					</div>
					<div class="table-head">
						<h6>Account Manager</h6>
					</div>
					<div class="table-head">
						<h6>Sales Admin</h6>
					</div>
					<div class="table-head">
						<h6>Sales</h6>
					</div>
					<div class="table-head">
						<h6>Business Owner</h6>
					</div>
					<div class="table-head">
						<h6>Client Employee</h6>
					</div>
				</div>
				<div class="table-row">
					<div class="table-cell">
						<h6>Role assigned</h6>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.IsAdmin)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.IsAdmin)"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.IsAccountManager)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.IsAccountManager)"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.IsSalesAdmin)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.IsSalesAdmin)"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.IsSales)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.IsSales)"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.IsBusinessOwner)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.IsBusinessOwner)"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.Miscellaneous, this.miscellaneousPermissionsEnum.IsClientEmployee)"
							[checked]="allPermissionsForAllModules.get(this.modulesEnum.Miscellaneous).get(this.miscellaneousPermissionsEnum.IsClientEmployee)"
						/>
					</div>
				</div>
			</div>
		</div>
		<!--Product Catalog-->
		<div class="table-wrapper">
			<div class="table-header">
				<h4>Product Catalog</h4>
				<div class="access-box">
					<input
						type="checkbox"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.ProductCatalog, this.productCatalogPermissionsEnum.CanAccessProductCatalog)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.ProductCatalog).get(this.productCatalogPermissionsEnum.CanAccessProductCatalog)
						"
					/>
					<span>Can access</span>
				</div>
			</div>
			<div class="table">
				<div class="table-row">
					<div class="table-head"></div>
					<div class="table-head">
						<h6>View</h6>
					</div>
					<div class="table-head">
						<h6>Edit</h6>
					</div>
					<div class="table-head">
						<h6>Create</h6>
					</div>
					<div class="table-head">
						<h6>Delete</h6>
					</div>
					<div class="table-head">
						<h6>Import</h6>
					</div>
					<div class="table-head">
						<h6>Export</h6>
					</div>
				</div>
				<div class="table-row">
					<div class="table-cell">
						<h6>Product Catalog</h6>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="
								checkBoxChange(this.modulesEnum.ProductCatalog, this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsView)
							"
							[checked]="
								allPermissionsForAllModules
									.get(this.modulesEnum.ProductCatalog)
									.get(this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsView)
							"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="
								checkBoxChange(this.modulesEnum.ProductCatalog, this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsEdit)
							"
							[checked]="
								allPermissionsForAllModules
									.get(this.modulesEnum.ProductCatalog)
									.get(this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsEdit)
							"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="
								checkBoxChange(this.modulesEnum.ProductCatalog, this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsCreate)
							"
							[checked]="
								allPermissionsForAllModules
									.get(this.modulesEnum.ProductCatalog)
									.get(this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsCreate)
							"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="
								checkBoxChange(this.modulesEnum.ProductCatalog, this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsDelete)
							"
							[checked]="
								allPermissionsForAllModules
									.get(this.modulesEnum.ProductCatalog)
									.get(this.productCatalogPermissionsEnum.PCCatalogsProductSetsProductsVariantsDelete)
							"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.ProductCatalog, this.productCatalogPermissionsEnum.PCFacebookAndShopifyImport)"
							[checked]="
								allPermissionsForAllModules
									.get(this.modulesEnum.ProductCatalog)
									.get(this.productCatalogPermissionsEnum.PCFacebookAndShopifyImport)
							"
						/>
					</div>
					<div class="table-cell">
						<input
							type="checkbox"
							class="options-input"
							[disabled]="roleIsReadonly"
							(change)="checkBoxChange(this.modulesEnum.ProductCatalog, this.productCatalogPermissionsEnum.PCFacebookAndShopifyExport)"
							[checked]="
								allPermissionsForAllModules
									.get(this.modulesEnum.ProductCatalog)
									.get(this.productCatalogPermissionsEnum.PCFacebookAndShopifyExport)
							"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--Reports-->
	<div class="table-wrapper">
		<div class="table-header">
			<h4>Reports</h4>
			<div class="access-box">
				<input
					type="checkbox"
					[disabled]="roleIsReadonly"
					(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.CanAccessReports)"
					[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.CanAccessReports)"
				/>
				<span>Can access</span>
			</div>
		</div>

		<div class="table">
			<div class="table-row">
				<div class="table-head"></div>

				<div class="table-head">
					<h6>View</h6>
				</div>
				<div class="table-head">
					<h6>Edit</h6>
				</div>
				<div class="table-head">
					<h6>Create</h6>
				</div>
				<div class="table-head">
					<h6>Delete</h6>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Overview</h6>
				</div>

				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportOverviewView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportOverviewView)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Dashboards</h6>
				</div>

				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportDashboardsView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportDashboardsView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportDashboardsEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportDashboardsEdit)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportDashboardsCreate)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportDashboardsCreate)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportDashboardsDelete)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportDashboardsDelete)"
					/>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>My Charts</h6>
				</div>

				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsEdit)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsCreate)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsCreate)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsDelete)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsDelete)"
					/>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Templates</h6>
				</div>

				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportTemplatesView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportTemplatesView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportTemplatesEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportTemplatesEdit)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Chart Templates</h6>
				</div>

				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsEdit)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsCreate)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsCreate)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Reports, this.reportsPermissionsEnum.ReportMyChartsDelete)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.reportsPermissionsEnum.ReportMyChartsDelete)"
					/>
				</div>
			</div>
		</div>
	</div>

	<!--Settings-->
	<div class="table-wrapper">
		<div class="table-header">
			<h4>Settings</h4>
		</div>
		<div class="table">
			<div class="table-row">
				<div class="table-head"></div>

				<div class="table-head">
					<h6>View</h6>
				</div>
				<div class="table-head">
					<h6>Edit</h6>
				</div>
				<div class="table-head">
					<h6>Full</h6>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>My Account</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsMyAccountView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsMyAccountView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsMyAccountEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsMyAccountEdit)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Change Password</h6>
				</div>

				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsChangePasswordView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsChangePasswordView)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Billing and Payment</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsBillingAndPaymentView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsBillingAndPaymentView)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>My Subscription</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsMyAccountView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsMyAccountView)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Manage Permissions</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsManagePermissionsView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsManagePermissionsView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsManagePermissionsEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsManagePermissionsEdit)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>User Management</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsUserManagementView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsUserManagementView)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.Settings, this.settingsPermissionsEnum.SettingsUserManagementFull)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.Reports).get(this.settingsPermissionsEnum.SettingsUserManagementFull)"
					/>
				</div>
			</div>
		</div>
	</div>

	<!--Back Office -->
	<div class="table-wrapper">
		<!-- header table-->
		<div class="table-header">
			<h4>Back Office</h4>
			<div class="access-box">
				<input
					type="checkbox"
					[disabled]="roleIsReadonly"
					(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.CanAccessBackOffice)"
					[checked]="allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.CanAccessBackOffice)"
				/>
				<span>Can access</span>
			</div>
		</div>
		<div class="table">
			<div class="table-row">
				<div class="table-head"></div>
				<div class="table-head">
					<h6>View</h6>
				</div>
				<div class="table-head">
					<h6>Edit</h6>
				</div>
				<div class="table-head">
					<h6>Create</h6>
				</div>
				<div class="table-head">
					<h6>Delete</h6>
				</div>
				<div class="table-head">
					<h6>Public/private</h6>
				</div>
				<div class="table-head">
					<h6>Download invoice</h6>
				</div>
				<div class="table-head">
					<h6>Decrease invoice 1 month</h6>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Offers</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.OffersView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.OffersView)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.OffersEdit)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.OffersEdit)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.OffersCreate)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.OffersCreate)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.OffersDelete)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.OffersDelete)"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.OffersMakePublicPrivate)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.OffersMakePublicPrivate)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Billing and Payment</h6>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.BillingAndPaymentView)"
						[checked]="allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.BillingAndPaymentView)"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.BillingAndPaymentDecreaseInvoiceOneMonth)"
						[checked]="
							allPermissionsForAllModules
								.get(this.modulesEnum.BackOffice)
								.get(this.backOfficePermissionsEnum.BillingAndPaymentDecreaseInvoiceOneMonth)
						"
					/>
				</div>
				<div class="table-cell">
					<input type="checkbox" class="options-input" />
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Clients</h6>
				</div>
				<div class="table-cell"></div>
				<div class="table-cell"></div>
				<div class="table-cell"></div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
			<div class="table-row">
				<div class="table-cell">
					<h6>Roles and Permissions</h6>
				</div>

				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.RolesAndPermissionsRolesView)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.RolesAndPermissionsRolesView)
						"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.RolesAndPermissionsRolesEdit)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.RolesAndPermissionsRolesEdit)
						"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.RolesAndPermissionsRolesCreate)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.RolesAndPermissionsRolesCreate)
						"
					/>
				</div>
				<div class="table-cell">
					<input
						type="checkbox"
						class="options-input"
						[disabled]="roleIsReadonly"
						(change)="checkBoxChange(this.modulesEnum.BackOffice, this.backOfficePermissionsEnum.RolesAndPermissionsRolesDelete)"
						[checked]="
							allPermissionsForAllModules.get(this.modulesEnum.BackOffice).get(this.backOfficePermissionsEnum.RolesAndPermissionsRolesDelete)
						"
					/>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
				<div class="table-cell">
					<i class="fas fa-stop"></i>
				</div>
			</div>
		</div>
	</div>
</div>
