import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SelectExportGrid } from '../model/select-export-grid.interface';
import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { ChannelOrganisation, ExportChannel, ExportChannelAuth } from '../model/manage-channel.model';
import { CatalogSmartSet } from '../model/catalog-smartset.interface';
import { CatalogSet, SetLinkRequest, SetLinkResponse } from '../model/catalog-set.interface';
import { CatalogQuickProduct, CatalogSmartVariant } from '../model/catalog-smart-variant.interface';
import { CatalogChannelSet } from '../model/catalog-channel-set.interface';
import { TemplateInterface } from '../model/template.interface';
import { ExportProductInterface } from '../model/products.interface';
import { ExportMappingRequest, MappingRequest, MappingResponse } from '../model/data-mapping-table.interface';
import { map } from 'rxjs/operators';
import { ExportCountry, ExportPlatform } from '../model/catalog-channel-selected.interface';

@Injectable()
export class ExportCatalog {
	constructor(private http: HttpClient) {}

	public getProductCatalog(): Observable<SelectExportGrid[]> {
		return this.http.get<SelectExportGrid[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}ProductCatalogs/export`);
	}
	public getChannel(channelId: number): Observable<ExportChannel[]> {
		return this.http.get<ExportChannel[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Exports/channels/${channelId}`);
	}

	public getChannelSets(channelId: number, catalogId: number): Observable<CatalogChannelSet[]> {
		return this.http.get<CatalogChannelSet[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Exports/sets/${channelId}/${catalogId}`);
	}

	public getExportMapping(channel: string, request: MappingRequest): Observable<MappingResponse> {
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/mapping/sets/${channel}`;
		return this.http.post<MappingResponse>(url, request).pipe(
			map((v: MappingResponse) => {
				v.mapping.variant.map(v => {
					v.filedKey = v.filedKey[0];
					return v;
				});
				return v;
			})
		);
	}

	public getExportCsvMapping(channel: string, request: MappingRequest): Observable<MappingResponse> {
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/mapping/csv/sets/${channel}`;
		return this.http.post<MappingResponse>(url, request).pipe(
			map((v: MappingResponse) => {
				v.mapping.variant.map(v => {
					v.filedKey = v.filedKey[0];
					return v;
				});
				return v;
			})
		);
	}

	public getExportCsvLink(request: SetLinkRequest): Observable<SetLinkResponse> {
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/csv/link/`;
		return this.http.post<SetLinkResponse>(url, request);
	}

	public getChannelAuthStatus(channel: string, redirectUrl: string): Observable<ExportChannelAuth> {
		const request = {
			redirect_url: redirectUrl
		};
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/oauth/${channel}/preinstall`;
		return this.http.post<ExportChannelAuth>(url, request);
	}

	public getChannelCsvAuthStatus(channel: string): Observable<ExportChannelAuth> {
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/csv/oauth/${channel}/preinstall`;

		return this.http.post<ExportChannelAuth>(url, {});
	}

	public exportChannelProducts(channel: string, request: ExportMappingRequest): Observable<{ message: string }> {
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/products/${channel}`;
		return this.http.post<{ message: string }>(url, request);
	}

	public exportCsvChannelProducts(channel: string, request: ExportMappingRequest): Observable<{ message: string }> {
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/csv/products/${channel}`;
		return this.http.post<{ message: string }>(url, request);
	}

	public getProductQuickSet(productCatalogId: string): Observable<CatalogSet[]> {
		return this.http.get<CatalogSet[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Sets/export/${productCatalogId}`);
	}
	public getCatalogProductForSmartSet(productCatalogId: string): Observable<CatalogSmartSet[]> {
		return this.http.get<CatalogSmartSet[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}SmartSets/${productCatalogId}`);
	}
	public getSmartSetVariants(smartSetId: string): Observable<CatalogSmartVariant[]> {
		return this.http.get<CatalogSmartVariant[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Products/smartSetProducts?smartSetId=${smartSetId}`);
	}
	public getQuickSetVariants(quickSetId: string): Observable<CatalogQuickProduct> {
		return this.http.get<CatalogQuickProduct>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Sets/${quickSetId}/products`);
	}
	public getOrganizations(channel: string): Observable<ChannelOrganisation[]> {
		const url = `${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/oauth/${channel}/getorganizations`;
		return this.http.post<{ data: ChannelOrganisation[] }>(url, {}).pipe(map(res => res.data));
	}

	public postMappingDetails(channel: string, mappings: any): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/mapping/${channel.toLowerCase()}`, mappings);
	}

	public exportChannel(data: any, endpin: string): Observable<any> {
		return this.http.post<any>(`${BaseApiUrl.PythonCatalogLambda.PythonCatalogLamda}export/products/${endpin}`, data);
	}
	public getSmartSetTemplate(productCatalogId: string, PlatformName: string, filter: string): Observable<TemplateInterface[]> {
		return this.http.get<TemplateInterface[]>(
			`${BaseApiUrl.ProductCatalog.ProductCatalog}Exports/smartSets/templates?Filter=${filter}&FiledProductCatalogId=${productCatalogId}&PlatformName=${PlatformName}`
		);
	}
	public getCatalogProduct(setId: string): Observable<ExportProductInterface> {
		return this.http.get<ExportProductInterface>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Sets/${setId}/filedProducts/withAmazonTypes`);
	}
	public getSmartVariantProduct(productCatalogId: string, filter: string): Observable<ExportProductInterface> {
		return this.http.get<ExportProductInterface>(`${BaseApiUrl.ProductCatalog.ProductCatalog}SmartSets/filedProducts/${productCatalogId}?filter=${filter}`);
	}
	public getQuickSetTemplate(setId: string, PlatformName: string): Observable<TemplateInterface[]> {
		return this.http.get<TemplateInterface[]>(
			`${BaseApiUrl.ProductCatalog.ProductCatalog}Exports/sets/templates?SetId=${setId}&PlatformName=${PlatformName}`
		);
	}

	public getExportCountries(): Observable<ExportCountry[]> {
		return this.http.get<ExportCountry[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Exports/countries`);
	}

	public getExportPlatformByCountry(code: string): Observable<ExportPlatform[]> {
		return this.http.get<ExportPlatform[]>(`${BaseApiUrl.ProductCatalog.ProductCatalog}Exports/platforms?countryCode=${code}`);
	}
}
