import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { EcomerceSource, EcomerceSourceImages } from '../model/eccomerce-source.enum';

@Injectable()
export class SharedService {
	private addUsuarioSource = new BehaviorSubject<string>('false');
	public addUsuario$ = this.addUsuarioSource.asObservable();

	constructor() {
		this.addUsuario$.subscribe(status => window.localStorage.setItem('addUsuario', status));
	}

	public getAddUsuario(): Observable<string> {
		let userStatus = window.localStorage.getItem('addUsuario');
		userStatus = userStatus === 'false' || userStatus == null ? 'true' : 'false';
		this.addUsuarioSource.next(userStatus);
		return this.addUsuario$;
	}

	public getPlatformImage(platform: string): string {
		return getPlatformImage(platform);
	}
}

function getPlatformImage(platform: string): string {
	switch (platform) {
		case EcomerceSource.Wish:
			return EcomerceSourceImages.wish;
		case EcomerceSource.Ebay:
			return EcomerceSourceImages.ebay;
		case EcomerceSource.Facebook:
			return EcomerceSourceImages.facebook;
		case EcomerceSource.Google:
			return EcomerceSourceImages.google;
		case EcomerceSource.Snapchat:
			return EcomerceSourceImages.snapchat;
		case EcomerceSource.Amazon:
			return EcomerceSourceImages.amazon;
		case EcomerceSource.Shopify:
			return EcomerceSourceImages.shopify;
		case EcomerceSource.BigCommerce:
			return EcomerceSourceImages.bigCommerce;
		case EcomerceSource.AdRoll:
			return EcomerceSourceImages.adRoll;
		case EcomerceSource.AliExpress:
			return EcomerceSourceImages.aliExpress;
		case EcomerceSource.Awin:
			return EcomerceSourceImages.awin;
		case EcomerceSource.Bueni:
			return EcomerceSourceImages.bueni;
		case EcomerceSource.ClickPoint:
			return EcomerceSourceImages.clickPoint;
		case EcomerceSource.Connexity:
			return EcomerceSourceImages.connexity;
		case EcomerceSource.Devatics:
			return EcomerceSourceImages.devatics;
		case EcomerceSource.Effiliation:
			return EcomerceSourceImages.effiliation;
		case EcomerceSource.Effinity:
			return EcomerceSourceImages.effinity;
		case EcomerceSource.Fruugo:
			return EcomerceSourceImages.fruugo;
		case EcomerceSource.Gamned:
			return EcomerceSourceImages.gamned;
		case EcomerceSource.HarveyNichols:
			return EcomerceSourceImages.harveyNichols;
		case EcomerceSource.Idealo:
			return EcomerceSourceImages.idealo;
		case EcomerceSource.Kelkoo:
			return EcomerceSourceImages.kelkoo;
		case EcomerceSource.Kips:
			return EcomerceSourceImages.kips;
		case EcomerceSource.Klaviyo:
			return EcomerceSourceImages.klaviyo;
		case EcomerceSource.LeGuide:
			return EcomerceSourceImages.leGuide;
		case EcomerceSource.Living24:
			return EcomerceSourceImages.living24;
		case EcomerceSource.LuxSeeker:
			return EcomerceSourceImages.luxSeeker;
		case EcomerceSource.NexTag:
			return EcomerceSourceImages.nexTag;
		case EcomerceSource.Octer:
			return EcomerceSourceImages.octer;
		case EcomerceSource.Peerius:
			return EcomerceSourceImages.peerius;
		case EcomerceSource.PriceRunner:
			return EcomerceSourceImages.priceRunner;
		case EcomerceSource.RtbHouse:
			return EcomerceSourceImages.rtbHouse;
		case EcomerceSource.SenseFuel:
			return EcomerceSourceImages.senseFuel;
		case EcomerceSource.Shareasale:
			return EcomerceSourceImages.shareasale;
		case EcomerceSource.Shopmania:
			return EcomerceSourceImages.shopmania;
		case EcomerceSource.Shopzilla:
			return EcomerceSourceImages.shopzilla;
		case EcomerceSource.Sociomantic:
			return EcomerceSourceImages.sociomantic;
		case EcomerceSource.Tracdelight:
			return EcomerceSourceImages.tracdelight;
		case EcomerceSource.Tradetracker:
			return EcomerceSourceImages.tradetracker;
		case EcomerceSource.Truefit:
			return EcomerceSourceImages.truefit;
		case EcomerceSource.Twenga:
			return EcomerceSourceImages.twenga;
		case EcomerceSource.Vidaxl:
			return EcomerceSourceImages.vidaxl;
		case EcomerceSource.Webgains:
			return EcomerceSourceImages.webgains;
		case EcomerceSource.Zalando:
			return EcomerceSourceImages.zalando;
		case EcomerceSource.Bingads:
			return EcomerceSourceImages.bingads;
		case EcomerceSource.Fashiola:
			return EcomerceSourceImages.fashiola;
		case EcomerceSource.Glami:
			return EcomerceSourceImages.glami;
		case EcomerceSource.Houzz:
			return EcomerceSourceImages.houzz;
		case EcomerceSource.Manomano:
			return EcomerceSourceImages.manomano;
		case EcomerceSource.Rakuten:
			return EcomerceSourceImages.rakuten;
		case EcomerceSource.Shopstyle:
			return EcomerceSourceImages.shopstyle;
		case EcomerceSource.Skinflint:
			return EcomerceSourceImages.skinflint;
		case EcomerceSource.Spartoo:
			return EcomerceSourceImages.spartoo;
		case EcomerceSource.Bouncex:
			return EcomerceSourceImages.bouncex;
		case EcomerceSource.Conversant:
			return EcomerceSourceImages.conversant;
		case EcomerceSource.Flixmedia:
			return EcomerceSourceImages.flixmedia;
		case EcomerceSource.Hometiger:
			return EcomerceSourceImages.hometiger;
		case EcomerceSource.Iceleads:
			return EcomerceSourceImages.iceleads;
		case EcomerceSource.Lyst:
			return EcomerceSourceImages.lyst;
		case EcomerceSource.Olapic:
			return EcomerceSourceImages.olapic;
		case EcomerceSource.Pepperjam:
			return EcomerceSourceImages.pepperjam;
		case EcomerceSource.Wanelo:
			return EcomerceSourceImages.wanelo;
		default:
			return EcomerceSourceImages.facebook;
	}
}
