import { Action } from '@ngrx/store';
import { Asset } from 'src/app/shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';

export enum AAAAssetsActionTypes {
	LoadPagePosts = '[AAA Assets] Load Posts',
	LoadPagePostsSuccess = '[AAA Assets] Load Posts Success',
	LoadPagePostsFailure = '[AAA Assets] Load Posts Failure'
}

export class LoadPagePosts implements Action {
	public readonly type = AAAAssetsActionTypes.LoadPagePosts;

	constructor(public payload: { businessOwnerId: string; adAccountId: string }) {}
}

export class LoadPagePostsSuccess implements Action {
	public readonly type = AAAAssetsActionTypes.LoadPagePostsSuccess;

	constructor(public payload: Asset[]) {}
}

export class LoadPagePostsFailure implements Action {
	public readonly type = AAAAssetsActionTypes.LoadPagePostsFailure;
}

export type AAAAssetsActions = LoadPagePosts | LoadPagePostsSuccess | LoadPagePostsFailure;
