<app-user-header></app-user-header>

<div class="container">
	<div class="header">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div>
			<h4 class="title">My Plan</h4>
			<div class="subtitle">
				Choose a plan that fits best for your account.
			</div>
		</div>
	</div>
	<div class="body">
		<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
		<div class="body-form" *ngIf="!loading">
			<div class="container-with-bottons">
				<!-- <div class="main-sub-title">
					Upgrade your account now.
					<br />
					Select one of the offer below and start exploring further!
				</div> -->
				<div class="action-button">
					<ul class="menus">
						<li>
							<a
								class="btn btn-duration-filled-orange-red"
								(click)="onSelectDuration('month')"
								[class.active-duration]="durationControl.value === 'month'"
							>
								MONTHLY
							</a>
						</li>
						<li>
							<a
								class="btn btn-duration-filled-orange-red"
								(click)="onSelectDuration('annual')"
								[class.active-duration]="durationControl.value === 'annual'"
							>
								ANNUAL
							</a>
						</li>
					</ul>
				</div>
			</div>
			<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
			<app-tarrif-plan
				[tarrifPlans]="tarrifPlans"
				[btnLabel]="'Upgrade Plan'"
				(selectedTarrifPlan)="onSelectTarrifPlan($event)"
				*ngIf="!loading"
			></app-tarrif-plan>
		</div>
	</div>
</div>
