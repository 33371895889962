import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AaaHeaderFooterStateService } from '../services/aaa-header-footer-state.service';
import { AAAModuleState } from '../state';

import * as fromAdsetActions from '../state/adset-state/adset-state.action';
import * as fromAdActions from '../state/ad-state/ad-state.actions';
import * as fromAAAActions from '../state/aaa.actions';
@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
	constructor(private aaaStateService: AaaHeaderFooterStateService, private store: Store<AAAModuleState>) {}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.store.dispatch(new fromAdsetActions.ResetState());
		this.store.dispatch(new fromAdActions.ResetState());
		this.store.dispatch(new fromAAAActions.ResetState());
		this.aaaStateService.resetState();
	}
}
