<div id="date-container"></div>
<app-expansion-panel-sc
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[isExpandable]="false"
	[expansionTitle]="'Date'"
	[infoHoverModel]="dateInfoModel"
>
	<div expansion-content>
		<mat-radio-group class="dc-radio-group" (ngModelChange)="changeDateChoice($event)" [(ngModel)]="dateChoiceType" [ngModelOptions]="{ standalone: true }">
			<mat-radio-button class="dc-radio-date-range" [value]="1"><span>Select a date range</span></mat-radio-button>
			<div class="dc-calendar">
				<app-calendar
					*ngIf="dateChoiceType === 1"
					(dateChanged)="onDateChange($event)"
					[preselectDateModel]="preselectedDate"
					[useSimplifiedInterface]="true"
					[useDefaultMaxDate]="false"
					[updateStore]="false"
					(onModalClose)="onModalClose($event)"
				></app-calendar>
			</div>
			<mat-radio-button class="dc-radio-no-end" [disabled]="isAdSetLifeTimeSelected || isCampaignLifeTimeSelected" [value]="0">
				<div class="dc-radio-text">
					<span class="dc-first">Start from today with</span>
					<span>no end date</span>
				</div>
			</mat-radio-button>
		</mat-radio-group>
	</div>
</app-expansion-panel-sc>
