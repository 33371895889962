<div class="set-layout">
	<div class="verticalSpacing"></div>
	<div *ngIf="flow === 0 || flow === 1">
		<font03>Create New Ad</font03>
	</div>
	<div *ngIf="flow === 2 || flow === 3">
		<font03>Create AdSet</font03>
	</div>
	<div class="verticalSpacing"></div>
	<div>
		<card-collapsible
			[title]="'Select Objective'"
			[subtitle]="'Please select the objective of the campaigns you wish to add your ad to'"
			[width]="'51.875rem'"
			[imgLink]="'../../../assets/icons/Group 16722.svg'"
			[description]="'For the moment we have support only for the objectives displayed. The rest of the objectives will be available in future updates.'"
		>
			<div class="oc-image-container oc-margin-bottom">
				<app-card-box
					*ngFor="let objective of customObjectives"
					class="oc-card-size"
					(click)="selectedCard(objective?.subObjectiveName)"
					[isSelected]="selectedObjectiveName === objective.subObjectiveName"
					[hidden]="objective?.disabled"
				>
					<div card-content appHoverSelect [statefulImage]="objective" [statefulImages]="customObjectives" class="oc-image-card oc-objective-card">
						<img
							src="/assets/icons/campaign-smart-create/new/{{ objective.name.toLowerCase() }}-{{
								selectedObjectiveName === objective.subObjectiveName ? 'pressed' : objective.imageState
							}}.svg"
							alt="image"
						/>
						<span class="oc-text-container">{{ objective?.name }}</span>
					</div>
				</app-card-box>
			</div>
		</card-collapsible>
	</div>
	<div class="verticalSpacing"></div>
	<div>
		<card-collapsible
			[title]="'Select Campaigns'"
			[subtitle]="'Please select the campaigns where you wish to add this ad to'"
			[width]="'51.875rem'"
			[imgLink]="'../../../assets/icons/Group 16724.svg'"
			[iscollapsed]="isCampaignCardCollapsed"
			[disabled]="isCampaignCardDisabled"
			[description]="'Only campaigns that are compatible with the selected objective will appear in the list.'"
		>
			<div class="w-100">
				<div class="searchBox">
					<tb06
						[icon]="'fa-search'"
						class="search-input"
						[placeHolderTextBox]="'Search'"
						[control]="searchFormControl.get('searchCampaignForm')"
					></tb06>
				</div>
				<div class="selectionCard">
					<app-selection-count>
						<font07-4>Selected: {{ selectedCampaignIds.length }}</font07-4>
					</app-selection-count>
				</div>
			</div>
			<div class="verticalSpacing"></div>
			<div>
				<app-master-table
					[areTabsAttached]="true"
					[masterService]="campaignsService"
					[masterTableImplementation]="adsManagerMasterTableImplementations.AdsManagerCampaignTable"
					[columnDefs]="campaignView"
					[frameworkComponents]="frameworkComponents"
					[rowBuffer]="200"
					[rowSelection]="evaluteRowSelection()"
					[rowModelType]="rowModelType.ServerSide"
					[defaultColDef]="defaultColDef"
					[paginationPageSize]="defaultPageSize"
					[numberOfRowsReturnedByServer]="defaultPageSize"
					[paginationOptions]="paginationOptions"
					[canPageSizeBeChanged]="true"
					[isRowSelectable]="adsManagerHelper.isRowSelectable"
					[getRowStyle]="adsManagerHelper.getRowStyle"
					[getRowClass]="adsManagerHelper.getRowClass"
					(gridReady)="onGridReady($event)"
				></app-master-table>
			</div>
			<div class="verticalSpacing"></div>
			<div [hidden]="!(flow === 0 || flow === 1)">
				<chkb01 (valueChanges)="adsetsCheckBox($event)" [value]="isAllAdsetsSelected"></chkb01>
				Apply to all Ad Sets
			</div>
		</card-collapsible>
	</div>
	<div class="verticalSpacing"></div>
	<div *ngIf="(flow === 0 || flow === 1) && !isAllAdsetsSelected">
		<card-collapsible
			[title]="'Select Adsets'"
			[subtitle]="'Please select the adsets where you wish to add this ad to'"
			[width]="'51.875rem'"
			[imgLink]="'../../../assets/icons/Group 16764.svg'"
			[iscollapsed]="isAdSetCardCollapsed"
			[disabled]="isAdSetCardDisabled"
			[description]="'Only the Ad Sets of the campaigns selected will appear in the list.'"
		>
			<div class="row">
				<div class="searchBox">
					<tb06 [icon]="'fa-search'" class="search-input" [placeHolderTextBox]="'Search'" [control]="searchFormControl.get('searchAdSetForm')"></tb06>
				</div>
				<div class="selectionCard">
					<app-selection-count>
						<font07-4>Selected: {{ selectedAdSetIds.length }}</font07-4>
					</app-selection-count>
				</div>
			</div>
			<div class="verticalSpacing"></div>
			<div>
				<app-master-table
					[areTabsAttached]="true"
					[masterService]="adSetsService"
					[masterTableImplementation]="adsManagerMasterTableImplementations.AdsManagerAdSetTable"
					[columnDefs]="adSetView"
					[frameworkComponents]="frameworkComponents"
					[rowBuffer]="200"
					[rowSelection]="rowSelection.Multiple"
					[rowModelType]="rowModelType.ServerSide"
					[defaultColDef]="defaultColDef"
					[paginationPageSize]="defaultPageSize"
					[numberOfRowsReturnedByServer]="defaultPageSize"
					[paginationOptions]="paginationOptions"
					[canPageSizeBeChanged]="true"
					[isRowSelectable]="adsManagerHelper.isRowSelectable"
					[getRowStyle]="adsManagerHelper.getRowStyle"
					[getRowClass]="adsManagerHelper.getRowClass"
					(gridReady)="onGridReady($event)"
				></app-master-table>
			</div>
		</card-collapsible>
	</div>
	<div class="bottomSpacing"></div>
</div>
