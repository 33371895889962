import { UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CaseInsensitiveUrlSerializer extends DefaultUrlSerializer {
	public parse(url: string): UrlTree {
		const transformedUrl = this.transform(url);
		return super.parse(transformedUrl);
	}

	private transform(url: string): string {
		if (/\/ads-manager\//.test(url)) {
			return url.toLowerCase();
		}

		return url;
	}
}
