export interface InstantFormContentQuestion {
	headline: string;
	greetings: string;
}
export enum CustomeQuestionType {
	MULTIPLE_CHOICE,
	SHORT_ANSWER,
	CONDITIONAL,
	APPOINTMENT_REQUEST
}
