export enum ChartStepsNumbers {
	step0 = 0,
	step1 = 1,
	step2 = 2,
	step3 = 3,
	step4 = 4,
	step5 = 5,
	step6 = 6,
	step7 = 7
}
