import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryBuilderColumn } from 'src/app/shared/query-builder/models/query-builder-column.model';
import { QueryBuilderConditionBlock } from 'src/app/shared/query-builder/models/query-builder-condition-block.model';
import { QueryBuilderCondition } from 'src/app/shared/query-builder/models/query-builder-condition.model';
import { QueryBuilderDimension } from 'src/app/shared/query-builder/models/query-builder-dimension.model';
import { QueryBuilderLogicalOperator } from 'src/app/shared/query-builder/models/query-builder-logical-operator.enum';
import { QueryBuilderWhereOperator } from 'src/app/shared/query-builder/models/query-builder-where-operator.enum';
import { QueryModel } from 'src/app/shared/query-builder/models/query.model';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { DateRangeX } from '../../shared/calendar/calendar.models';
import { ChartGridData } from '../../shared/charts2/chart-models/chart-grid-data';
import { ChartLegendData } from '../../shared/charts2/chart-models/chart-legend-data';
import { ChartSeriesModel } from '../../shared/charts2/chart-models/chart-series.model';
import { MarkPointDataItem } from '../../shared/charts2/chart-models/mark-point-data-item.model';
import { MarkPoint } from '../../shared/charts2/chart-models/mark-point.model';
import { DateHelper } from '../../shared/charts2/helper/date-helper';
import { QueryBuilderAggregatorEnum } from '../../shared/query-builder/models/query-builder.aggregator.enum';
import { QueryBuilderService } from '../../shared/query-builder/query-builder.service';
import { GoogleInsightsMetadataService } from '../../shared/services/google-insights-metadata.service';
import { ActionHistory } from '../models/actionHistory.model';
import { RecommendationMetric } from '../models/recommendation-metric.model';
import { RecommendationPlatform } from '../models/recommendation-platform.enum';
import { Recommendation } from '../models/recommendation.model';
import { OptimizeHelper } from './optimize-helper';
import { NewRecommendationInterface } from '../../shared/recommendations-new-template/models/new-recommendation.interface';
import { ChannelsEnum } from '../enums/channels.enum';
import { NewOptimizationLevelsEnum } from '../models/new-optimization-levels.enum';
import { GoogleTimeIncrement } from '../../shared/query-builder/models/google-time-increment.enum';
import { GraphDisplayParameters, GraphValue, IGraphResponse } from '../models/new-recommendation.interface';

@Injectable({
	providedIn: 'root'
})
export class OptimiseChartsHelperService {
	constructor(private http: HttpClient, private googleInsightsService: GoogleInsightsMetadataService) {}

	public getDataForChartWithQueryBuilder(
		recommendation: Recommendation | NewRecommendationInterface,
		selectedDateRange: DateRangeX<moment.Moment>,
		actionHistory: ActionHistory[] = null,
		selectedActionHistory: ActionHistory = null,
		isCyb: boolean = false
	): Observable<ChartSeriesModel[]> {
		const columns = this.getColumns(recommendation);
		const query: QueryModel = {
			TableName: OptimizeHelper.getInsightTable(recommendation),
			Dimensions: this.getDimensions(recommendation),
			Columns: columns,
			Where: this.getWhereClause(recommendation, selectedDateRange)
		};
		if (recommendation.channel === ChannelsEnum.Facebook) {
			return new QueryBuilderService(this.http, `${BaseApiUrl.DexterAnalytics}optimize/reports`, SourceChannel.Facebook).postQuery(query).pipe(
				map((chartData: any[]) => {
					if (isCyb) {
						return this.formatChartCData(chartData, selectedDateRange, actionHistory, selectedActionHistory, recommendation);
					}
					return this.formatChartData(chartData, selectedDateRange, actionHistory, selectedActionHistory, recommendation);
				})
			);
		} else if (recommendation.channel === ChannelsEnum.Google) {
			const tableName = OptimizeHelper.getInsightTable(recommendation);
			return this.googleInsightsService
				.postGoogleInsightsQuery(
					tableName,
					selectedDateRange.startDate,
					selectedDateRange.endDate,
					this.getColumns(recommendation).map(column => column.Name),
					this.getDimensions(recommendation).map(dimension => dimension.GroupColumnName),
					recommendation.level,
					recommendation.adAccountId,
					[recommendation.structureId],
					GoogleTimeIncrement.Daily
				)
				.pipe(
					map((chartData: any[]) => {
						const newChartDataLines = this.formatChartData(chartData, selectedDateRange, actionHistory, selectedActionHistory, recommendation);
						// If requesting data without breakdowns the Series name should be the structure name, not null (the breakdown value)
						if (newChartDataLines.length === 1 && !newChartDataLines[0].name) {
							newChartDataLines[0].name = recommendation.structureName;
						}
						this.addMarkPointsToChartData(newChartDataLines, selectedDateRange, actionHistory, selectedActionHistory);
						newChartDataLines[0].chartData = chartData;
						return newChartDataLines;
					})
				);
		}
	}

	public addMarkPointsToChartData(
		series: ChartSeriesModel[],
		selectedDateRange: DateRangeX<moment.Moment>,
		actionHistory: ActionHistory[] = null,
		selectedActionHistory: ActionHistory = null
	): void {
		series.forEach((value: ChartSeriesModel) => {
			value.markPoint = new MarkPoint();
		});

		if (actionHistory && series && series.length > 0) {
			const dates = DateHelper.getDaysInDateRange(selectedDateRange);
			const newMarkPoints: MarkPointDataItem[] = [];
			actionHistory.forEach((aHistory: ActionHistory) => {
				const actionTimeStamp = moment(aHistory.applicationDate, 'YYYY/MM/DD').format('DD/MM/YY');
				const dateIndex = dates.findIndex(value => value === actionTimeStamp);
				let lineIndex = -1;
				if (dateIndex) {
					let value = 0;
					for (let i = 0; i < series.length; i++) {
						if (series[i].data[dateIndex] > value) {
							value = series[i].data[dateIndex];
							lineIndex = i;
						}
					}
					if (lineIndex > -1) {
						if (selectedActionHistory && aHistory.id === selectedActionHistory.id) {
							newMarkPoints.push(this.getHighLightMarkPoint(dateIndex, value));
						}
						newMarkPoints.push(this.getBackGroundCircleMarkPoint(dateIndex, value));
						if (aHistory.source === RecommendationPlatform.Dexter) {
							newMarkPoints.push(this.getDexterGreyLineMarkPoint(dateIndex, value));
							newMarkPoints.push(this.getDexterOrangeDotMarkPoint(dateIndex, value));
							newMarkPoints.push(this.getDexterBlueDotMarkPoint(dateIndex, value));
						} else if (aHistory.source === RecommendationPlatform.Facebook) {
							newMarkPoints.push(this.getFacebookMarkPoint(dateIndex, value));
						}
						series[lineIndex].markPoint.data = series[lineIndex].markPoint.data.concat(newMarkPoints);
					}
				}
			});
		}
	}

	public createLegendData(): ChartLegendData {
		const legendData = new ChartLegendData();
		legendData.type = 'scroll';
		legendData.orient = 'horizontal';
		legendData.right = null;
		legendData.top = '0';
		legendData.left = 'center';
		legendData.bottom = null;
		return legendData;
	}

	public createGridData(): ChartGridData {
		const gridData = new ChartGridData();
		gridData.bottom = '10';
		gridData.top = '30';
		gridData.height = '80%';
		gridData.width = '100%';
		gridData.left = '0';
		gridData.right = '0';
		return gridData;
	}

	public getTitle(recommendation: Recommendation): string {
		return recommendation.structureName;
	}

	private getColumns(recommendation: NewRecommendationInterface | Recommendation): QueryBuilderColumn[] {
		const columns: QueryBuilderColumn[] = [];
		if (recommendation.metrics) {
			recommendation.metrics.forEach((metric: RecommendationMetric) => {
				columns.push({
					Name: metric.name,
					Aggregator: QueryBuilderAggregatorEnum.Avg
				});
			});
		} else {
			columns.push({ Name: 'cpc_all', Aggregator: QueryBuilderAggregatorEnum.Avg });
		}
		columns.push({ Name: `${recommendation.level.toLowerCase()}_id`, Aggregator: QueryBuilderAggregatorEnum.Max });
		columns.push({ Name: `${recommendation.level.toLowerCase()}_name`, Aggregator: QueryBuilderAggregatorEnum.Max });
		return columns;
	}

	private getDimensions(recommendation: Recommendation | NewRecommendationInterface): QueryBuilderDimension[] {
		const dimensions = [];
		const breakdownColumnName = recommendation.breakdown?.name;
		if (breakdownColumnName && breakdownColumnName !== 'none') {
			dimensions.push({ GroupColumnName: breakdownColumnName });
		}
		if (recommendation.channel === ChannelsEnum.Facebook) {
			dimensions.push({ GroupColumnName: 'date_start' });
		}
		return dimensions;
	}

	private getWhereClause(
		recommendation: NewRecommendationInterface | Recommendation,
		selectedDateRange: DateRangeX<moment.Moment>
	): QueryBuilderConditionBlock {
		const whereClause = new QueryBuilderConditionBlock(QueryBuilderLogicalOperator.And, null);

		const structureMatchCondition: QueryBuilderCondition = {
			ColumnName: '',
			Operator: QueryBuilderWhereOperator.Equals,
			Value: recommendation.structureId
		};

		if (recommendation.level === NewOptimizationLevelsEnum.Ad) {
			structureMatchCondition.ColumnName = 'ad.id';
		} else if (recommendation.level === NewOptimizationLevelsEnum.AdSet) {
			structureMatchCondition.ColumnName = 'adset.id';
		} else if (recommendation.level === NewOptimizationLevelsEnum.Campaign) {
			structureMatchCondition.ColumnName = 'campaign.id';
		} else if (recommendation.level === NewOptimizationLevelsEnum.AdGroup) {
			structureMatchCondition.ColumnName = 'adgroup.id';
		}

		const conditionBlock: QueryBuilderConditionBlock = new QueryBuilderConditionBlock(QueryBuilderLogicalOperator.And, null);

		conditionBlock.addConditions(structureMatchCondition);

		const chartDateRange = selectedDateRange;
		const dateStartCondition: QueryBuilderCondition = {
			ColumnName: 'date_start',
			Operator: QueryBuilderWhereOperator.Equals,
			Value: chartDateRange.startDate.format('YYYY-MM-DD').toString()
		};

		conditionBlock.addConditions(dateStartCondition);

		const dateEndCondition: QueryBuilderCondition = {
			ColumnName: 'date_stop',
			Operator: QueryBuilderWhereOperator.Equals,
			Value: chartDateRange.endDate.format('YYYY-MM-DD').toString()
		};

		conditionBlock.addConditions(dateEndCondition);

		const timeIncrementCondition: QueryBuilderCondition = {
			ColumnName: 'time_increment',
			Operator: QueryBuilderWhereOperator.Equals,
			Value: 1
		};

		conditionBlock.addConditions(timeIncrementCondition);

		const accountCondition: QueryBuilderCondition = {
			ColumnName: 'account_id',
			Operator: QueryBuilderWhereOperator.Equals,
			Value: recommendation.adAccountId
		};

		conditionBlock.addConditions(accountCondition);

		whereClause.addChildCondition(conditionBlock);

		return whereClause;
	}

	public formatNewRxdChartData(
		chartData: GraphValue[],
		selectedDateRange: DateRangeX<moment.Moment>,
		actionHistory: ActionHistory[] = null,
		selectedActionHistory: ActionHistory = null,
		recommendation: GraphDisplayParameters
	): ChartSeriesModel[] {
		const dates = DateHelper.getDaysInDateRange(selectedDateRange);
		const timeStampColumn = this.getNewTimeStampColumn();
		const breakdownValueColumn = '';
		const newChartDataLines: ChartSeriesModel[] = [];
		chartData.forEach((insight: any) => {
			// recommendation.metrics.forEach((metric: RecommendationMetric) => {
			const foundLineIndex = newChartDataLines.findIndex(value => value.name === recommendation.metricName);
			if (foundLineIndex > -1) {
				const insightTimeStamp = moment(insight[timeStampColumn], 'YYYY/MM/DD').format('DD/MM/YY');
				const dateIndex = dates.findIndex(d => d === insightTimeStamp);
				const value = insight['value'];
				if ((value || value === 0) && !isNaN(value)) {
					newChartDataLines[foundLineIndex].data[dateIndex] = value;
				}
			} else {
				const newLine = new ChartSeriesModel();
				newLine.name = recommendation.metricName;
				newLine.data = new Array(dates.length);
				const insightTimeStamp = moment(insight[timeStampColumn], 'YYYY/MM/DD').format('DD/MM/YY');
				const dateIndex = dates.findIndex(d => d === insightTimeStamp);
				// value can be 0. it actually is most of the time
				const value = insight['value'];
				if ((value || value === 0) && !isNaN(value)) {
					newLine.data[dateIndex] = value;
				}
				if (newLine.data.findIndex(x => x !== undefined) > -1) {
					newLine.connectNulls = true;
					newLine.markPoint = new MarkPoint();
					newChartDataLines.push(newLine);
				}
			}
			// });
		});

		this.addMarkPointsToChartData(newChartDataLines, selectedDateRange, actionHistory, selectedActionHistory);
		newChartDataLines[0].chartData = chartData;
		return newChartDataLines;
	}

	private formatChartData(
		chartData: any[],
		selectedDateRange: DateRangeX<moment.Moment>,
		actionHistory: ActionHistory[] = null,
		selectedActionHistory: ActionHistory = null,
		recommendation: NewRecommendationInterface | Recommendation
	): ChartSeriesModel[] {
		const dates = DateHelper.getDaysInDateRange(selectedDateRange);
		const timeStampColumn = this.getTimeStampColumn(recommendation);
		const breakdownValueColumn = recommendation.breakdown?.name;
		const newChartDataLines: ChartSeriesModel[] = [];
		chartData.forEach((insight: any) => {
			recommendation.metrics.forEach((metric: RecommendationMetric) => {
				const foundLineIndex = newChartDataLines.findIndex(
					value => value.name === metric.graphDisplayName + ' ' + (insight[breakdownValueColumn] ? insight[breakdownValueColumn] : '')
				);
				if (foundLineIndex > -1) {
					const insightTimeStamp = moment(insight[timeStampColumn], 'YYYY/MM/DD').format('DD/MM/YY');
					const dateIndex = dates.findIndex(d => d === insightTimeStamp);
					const value = insight[metric.name];
					if ((value || value === 0) && !isNaN(value)) {
						newChartDataLines[foundLineIndex].data[dateIndex] = value;
					}
				} else {
					const newLine = new ChartSeriesModel();
					newLine.name = metric.graphDisplayName + ' ' + (insight[breakdownValueColumn] ? insight[breakdownValueColumn] : '');
					newLine.data = new Array(dates.length);
					const insightTimeStamp = moment(insight[timeStampColumn], 'YYYY/MM/DD').format('DD/MM/YY');
					const dateIndex = dates.findIndex(d => d === insightTimeStamp);
					// value can be 0. it actually is most of the time
					const value = insight[metric.name];
					if ((value || value === 0) && !isNaN(value)) {
						newLine.data[dateIndex] = value;
					}
					if (newLine.data.findIndex(x => x !== undefined) > -1) {
						newLine.connectNulls = true;
						newLine.markPoint = new MarkPoint();
						newChartDataLines.push(newLine);
					}
				}
			});
		});

		this.addMarkPointsToChartData(newChartDataLines, selectedDateRange, actionHistory, selectedActionHistory);
		newChartDataLines[0].chartData = chartData;
		return newChartDataLines;
	}

	private formatChartCData(
		chartData: any[],
		selectedDateRange: DateRangeX<moment.Moment>,
		actionHistory: ActionHistory[] = null,
		selectedActionHistory: ActionHistory = null,
		recommendation: NewRecommendationInterface | Recommendation
	): ChartSeriesModel[] {
		const newChartDataLines: ChartSeriesModel[] = [];
		const newLine = new ChartSeriesModel();
		newLine.data = new Array(chartData.length);
		newLine.name = recommendation.metrics[0].graphDisplayName;
		newLine.labels = new Array(chartData.length);

		chartData.forEach((insight: any, index) => {
			const value = insight[recommendation.metrics[0].name];
			if ((value || value === 0) && !isNaN(value)) {
				newLine.data[index] = value;
				newLine.labels[index] = insight['age_gender'] ? insight['age_gender'] : insight['placement'];
			}
			if (newLine.data.findIndex(x => x !== undefined) > -1) {
				newLine.connectNulls = true;
				newLine.markPoint = new MarkPoint();
				newChartDataLines.push(newLine);
			}
		});
		this.addMarkPointsToChartData(newChartDataLines, selectedDateRange, actionHistory, selectedActionHistory);
		let chatLines = [];
		chatLines.push(newChartDataLines[0]);
		chatLines[0].chartData = chartData;
		return chatLines;
	}

	private getHighLightMarkPoint(yCoordinate: number, xCoordinate: number): MarkPointDataItem {
		return {
			symbol: 'circle',
			symbolSize: 50,
			coord: [yCoordinate, xCoordinate],
			itemStyle: {
				color: {
					type: 'radial',
					x: 0.5,
					y: 0.5,
					r: 0.5,
					colorStops: [
						{
							offset: 0,
							color: '#FF6E4E'
						},
						{
							offset: 1,
							color: '#FF6E4E00'
						}
					],
					global: false
				}
			}
		};
	}

	private getBackGroundCircleMarkPoint(yCoordinate: number, xCoordinate: number): MarkPointDataItem {
		return {
			symbol: 'circle',
			symbolSize: 25,
			symbolOffset: [0, 0],
			coord: [yCoordinate, xCoordinate],
			itemStyle: {
				color: 'white',
				borderColor: '#F7F7F7',
				borderWidth: 2
			}
		};
	}

	private getDexterGreyLineMarkPoint(yCoordinate: number, xCoordinate: number): MarkPointDataItem {
		return {
			symbol:
				'path://M57.1,88.1C38.2,59.5,0,4.9,0,4.9C7.2,0.3,15.8-1.2,23.8,2c5.2,2.1,8.9,5.4,12.1,9.4c1.2,1.5,2.4,3.1,3.5,4.7C54.3,37.4,68.8,59,83.5,80.4c4.5,6.6,8.9,13,13.3,19.5C96.8,99.9,76.3,117,57.1,88.1z',
			symbolSize: 10,
			symbolOffset: [0, 0],
			coord: [yCoordinate, xCoordinate],
			itemStyle: {
				color: '#424242'
			}
		};
	}

	private getDexterOrangeDotMarkPoint(yCoordinate: number, xCoordinate: number): MarkPointDataItem {
		return {
			symbol: 'path://M88.3,16.1c0,8.9-7.2,16.1-16.1,16.1S56.1,25,56.1,16.1S63.3,0,72.2,0C81.1-0.1,88.3,7.2,88.3,16.1z',
			symbolSize: 4,
			symbolOffset: [3, -3],
			coord: [yCoordinate, xCoordinate],
			itemStyle: {
				color: '#FF6E4E'
			}
		};
	}

	private getDexterBlueDotMarkPoint(yCoordinate: number, xCoordinate: number): MarkPointDataItem {
		return {
			symbol: 'path://M37.8,87.6c0,8.9-7.2,16.1-16.1,16.1S5.6,96.5,5.6,87.6s7.2-16.1,16.1-16.1C30.6,71.4,37.8,78.7,37.8,87.6z',
			symbolSize: 4,
			symbolOffset: [-3, 3],
			coord: [yCoordinate, xCoordinate],
			itemStyle: {
				color: '#2585FE'
			}
		};
	}

	private getFacebookMarkPoint(yCoordinate: number, xCoordinate: number): MarkPointDataItem {
		return {
			symbol: 'circle',
			symbolSize: 15,
			symbolOffset: [0, 0],
			value: 'f',
			coord: [yCoordinate, xCoordinate],
			itemStyle: {
				color: '#2585FE'
			}
		};
	}

	private getTimeStampColumn(recommendation: Recommendation | NewRecommendationInterface): string {
		switch (recommendation.channel) {
			case ChannelsEnum.Facebook:
				return 'date_start';
			case ChannelsEnum.Google:
				return 'date';
		}
	}

	private getNewTimeStampColumn(): string {
		return 'date';
	}
}
