import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PermissionsService } from '../permissions.service';
import { Modules } from '../enums/modules';
import { map, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getUserDetails, hasBackOfficePermission, isUserImpersonated, UserState } from '../../state/user/user.reducer';
import { AccountsPermissions } from '../enums/accounts-permissions';

@Injectable({
	providedIn: 'root'
})
export class AccountsGuard implements CanActivate {
	public moduleName = Modules.Accounts;

	constructor(private permissionServ: PermissionsService, private userStore: Store<UserState>, private router: Router) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// DEBUGGING TO BE REMOVED
		// let impersonated = false;
		// this.userStore.pipe(select(isUserImpersonated), take(1)).subscribe(res => {
		// 	impersonated = res;
		// });
		// return this.userStore.pipe(
		// 	select(hasBackOfficePermission),
		// 	take(1),
		// 	map(isAdmin => {
		// 		if (isAdmin && !impersonated) {
		// 			this.router.navigate(['/back-office']);
		// 			return false;
		// 		}
		// 		return true;
		// 	})
		// );
		let isAllowed = false;
		this.userStore.pipe(select(getUserDetails), take(1)).subscribe(data => {
			data.Permissions.filter(moduleData => moduleData?.module === Modules?.Accounts).forEach(module => {
				module.permissions.forEach(permission => {
					if (permission === AccountsPermissions.CanAccessAccounts) {
						isAllowed = true;
					}
				});
			});
		});
		return of(isAllowed);
	}
}
