import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonTypeEnum } from './button-type.enum';
import { ButtonClassEnum } from './button-class.enum';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
	@Input() buttonType: ButtonTypeEnum;
	@Input() buttonClass: ButtonClassEnum;
	@Input() buttonLabel: string;
	@Input() disabled: boolean;
	@Input() waiting: boolean;
	@Input() icon: string;
	@Input() selected: boolean;

	@Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() dropdownIconPressed: EventEmitter<void> = new EventEmitter<void>();

	public buttonTypeEnum = ButtonTypeEnum;
	public buttonClassEnum = ButtonClassEnum;

	constructor() {}

	public buttonClickedHandler(event: any): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (!this.disabled) {
			this.buttonClicked.emit();
		}
	}

	public dropdownIconPressedHandler(event: any): void {
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
		if (!this.disabled) {
			this.dropdownIconPressed.emit();
		}
	}
}
