<div>
	<ng-container *ngIf="formGroup">
		<form [formGroup]="formGroup">
			<div formArrayName="adCardsItemData">
				<div *ngFor="let card of getAdCardForms(); let i = index">
					<div [formGroupName]="i">
						<div *ngIf="cardIndex === i">
							<div *ngIf="isAppInstalled">
								<div formArrayName="primary_text">
									<span class="cif-call-title">
										Primary Text
										<span class="mark-red">*</span>
									</span>
									<div *ngFor="let c of getPrimaryTextForm(i).controls; let k = index">
										<div class="cif-input-width" [formGroupName]="k">
											<tb06
												[control]="c"
												[placeHolderTextBox]="k == 0 ? 'Primary Text*' : 'Primary Text'"
												[icon]="'fas fa-info-circle'"
											></tb06>
										</div>
									</div>
								</div>
								<br />
								<div formArrayName="headline">
									<span class="cif-call-title">
										Headline
										<span class="mark-red">*</span>
									</span>
									<div *ngFor="let c of getHeadlineForm(i).controls; let k = index">
										<div class="cif-input-width" [formGroupName]="k">
											<tb06 [control]="c" [placeHolderTextBox]="k == 0 ? 'Headline*' : 'Headline'" [icon]="'fas fa-info-circle'"></tb06>
										</div>
									</div>
								</div>
								<br />
							</div>

							<div *ngIf="!isCatalogSales && !isAppInstalled">
								<div class="cif-input-width">
									<i-tooltip [title]="explanationPrimaryColor">
										<tb06
											[readonlyInput]="adFormat === adFormatEnum.PagePosts"
											[control]="card.get('primary_text')"
											[placeHolderTextBox]="'Primary text'"
											[icon]="'fas fa-info-circle'"
										></tb06>
									</i-tooltip>
								</div>

								<div class="cif-input-width-button">
									<div *ngIf="!isPageLikesSelected && !isPostLikesSelected && adFormat !== adFormatEnum.PagePosts" class="cif-input-width">
										<i-tooltip [title]="explanationHeadline">
											<tb06 [control]="card.get('headline')" [placeHolderTextBox]="'Headline'" [icon]="'fas fa-info-circle'"></tb06>
										</i-tooltip>
									</div>
								</div>

								<div class="cif-input-width-button">
									<div *ngIf="!isPageLikesSelected && !isPostLikesSelected && adFormat !== adFormatEnum.PagePosts" class="cif-input-width">
										<i-tooltip [title]="explanationDescription">
											<tb06 [control]="card.get('description')" [placeHolderTextBox]="'Description'" [icon]="'fas fa-info-circle'"></tb06>
										</i-tooltip>
									</div>
								</div>
							</div>

							<div *ngIf="isCatalogSales">
								<div class="cif-input-width">
									<span class="cif-call-title">Primary Text</span>
									<chip-textbox
										[control]="card.get('primary_text')"
										[placeholder]="'Primary text'"
										[data]="catalogTemplateFields"
									></chip-textbox>
								</div>
								<div class="cif-input-width-button">
									<div class="cif-input-width">
										<span class="cif-call-title">Headline</span>
										<chip-textbox [control]="card.get('headline')" [placeholder]="'Headline'" [data]="catalogTemplateFields"></chip-textbox>
									</div>
								</div>
								<div class="cif-input-with-button">
									<div class="cif-input-width">
										<span class="cif-call-title">Description</span>
										<chip-textbox
											[control]="card.get('description')"
											[placeholder]="'Description'"
											[data]="catalogTemplateFields"
										></chip-textbox>
									</div>
								</div>
							</div>

							<div
								class="cif-input-width"
								*ngIf="
									((!isPageLikesSelected && !isPostLikesSelected) || (isPostLikesSelected && isCtaSelected)) &&
									(adFormat !== adFormatEnum.PagePosts || isWebsiteUrl) &&
									!isAppInstalled
								"
							>
								<tb01
									[readonlyInput]="adFormat === adFormatEnum.PagePosts"
									[control]="card.get('website_url')"
									[placeHolderTextBox]="'Website Url'"
								></tb01>
							</div>

							<span class="cif-call-title" *ngIf="!isPageLikesSelected">Call to Action</span>
							<div *ngIf="!isPageLikesSelected" class="cif-dropdown-height">
								<app-dropdown-search-select
									class="cif-call-to-action"
									[dropdownData]="callToActions"
									[dropdownFormControl]="card.get('call_to_action')"
									[selectedValue]="getCallToActionValue(card.value)"
								></app-dropdown-search-select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</ng-container>
</div>
