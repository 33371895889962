import { Action } from '@ngrx/store';
import { UpdateAAACampaignInterface } from '../models/update-aaa-campaign.interface';
import { CatalogInterface } from 'src/app/campaign-smart-create/catalogs/catalog.interface';
import { ObjectiveTreeInterface } from 'src/app/campaign-smart-create/models/objective-tree.interface';
import { AdModel } from '../models/ad.model';
import { UpdateAAAAdsetInterface } from '../models/update-aaa-adset.interface';
import { UpdateAAAAdInterface } from '../models/update-aaa-ads.interface';
import { AAAState } from './aaa.reducer';

export enum AaaActionsTypes {
	ResetState = '[AAA] Reset State',
	UpdateObjective = '[AAA] Update Objective',
	UpdateSelectedAAACamapignIds = '[AAA] Update Selected Camapaign Ids',
	UpdateSelectedAAAAdSetIds = '[AAA] Update Selected AdSet Ids',
	UpdateSelectedAAACampaigns = '[AAA] Update Selected Camapaigns',
	UpdateSelectedAAAAdSets = '[AAA] Update Selected AdSets',
	UpdateSelectedAAAAds = '[AAA] Update Selected Ads',
	UpdateSelectedAAAAdIds = '[AAA] Update Selected Ad Ids'
}

export class ResetState implements Action {
	public readonly type = AaaActionsTypes.ResetState;

	constructor() {}
}

export class UpdateObjective implements Action {
	public readonly type = AaaActionsTypes.UpdateObjective;

	constructor(public payload: ObjectiveTreeInterface) {}
}

export class UpdateSelectedAAACamapignIds implements Action {
	public readonly type = AaaActionsTypes.UpdateSelectedAAACamapignIds;

	constructor(public payload: string[]) {}
}

export class UpdateSelectedAAAAdSetIds implements Action {
	public readonly type = AaaActionsTypes.UpdateSelectedAAAAdSetIds;

	constructor(public payload: string[]) {}
}

export class UpdateSelectedAAAAdIds implements Action {
	public readonly type = AaaActionsTypes.UpdateSelectedAAAAdIds;

	constructor(public payload: string[]) {}
}

export class UpdateSelectedAAACampaigns implements Action {
	public readonly type = AaaActionsTypes.UpdateSelectedAAACampaigns;

	constructor(public payload: UpdateAAACampaignInterface) {}
}
export class UpdateSelectedAAAAdSets implements Action {
	public readonly type = AaaActionsTypes.UpdateSelectedAAAAdSets;

	constructor(public payload: UpdateAAAAdsetInterface) {}
}

export class UpdateSelectedAAAAds implements Action {
	public readonly type = AaaActionsTypes.UpdateSelectedAAAAds;

	constructor(public payload: UpdateAAAAdInterface) {}
}

export type AaaActions =
	| ResetState
	| UpdateObjective
	| UpdateSelectedAAACamapignIds
	| UpdateSelectedAAAAdSetIds
	| UpdateSelectedAAACampaigns
	| UpdateSelectedAAAAdSets
	| UpdateSelectedAAAAdIds
	| UpdateSelectedAAAAds;
