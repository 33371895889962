import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastNotificationService } from './toast-notification.service';
import { ToastNotificationModel } from './toast-notification.model';
import { ToastNotificationType } from './toast-notification-type.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-toast-notification',
	templateUrl: './toast-notification.component.html',
	styleUrls: ['./toast-notification.component.scss']
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
	public isToastVisible = false;
	public toastTypes = ToastNotificationType;
	public toastModel: ToastNotificationModel;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private toastNotificationService: ToastNotificationService) {}

	public ngOnInit(): void {
		this.toastNotificationService
			.getToasts()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(resp => {
				this.showToast(resp);
			});
		this.toastNotificationService
			.getDismissSubject()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => this.dismiss());
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private showToast(toastModel: ToastNotificationModel): void {
		this.toastModel = toastModel;

		this.isToastVisible = true;
		setTimeout(() => {
			this.isToastVisible = false;
		}, toastModel.duration);
	}

	public onClick(): void {
		this.isToastVisible = false;
	}

	public dismiss(): void {
		this.isToastVisible = false;
	}
}
