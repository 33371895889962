import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-drop-down-button',
	templateUrl: './drop-down-button.component.html',
	styleUrls: ['./drop-down-button.component.scss']
})
export class DropDownButtonComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit() {}

	createNewCampaign() {
		this.router.navigate(['/campaign-builder'], { queryParams: { isNew: true } });
	}
}
