import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { CampaignSmartCreateState } from '../../state/smart-create.reducer';
import { UpdateSplitByDevice } from '../../state/smart-create.actions';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'app-devices-card',
	templateUrl: './devices-card.component.html',
	styleUrls: ['./devices-card.component.scss']
})
export class DevicesCardComponent implements OnInit {
	@Input() public isDevicesToggle: boolean;
	@Input() public isSelected: boolean;
	@Input() public control: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter();

	constructor(public campaignStore: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {}

	public onDevicesToggle(): void {
		this.isDevicesToggle = !this.isDevicesToggle;
	}
}
