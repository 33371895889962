import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
	@Output() public clickOutside = new EventEmitter();
	private allowClose = true;

	constructor(private elementRef: ElementRef) {}

	@HostListener('click', ['$event'])
	public clickInside(event: MouseEvent) {
		this.allowClose = false;
	}

	@HostListener('document:click', ['$event'])
	public onClick(event: MouseEvent) {
		const insideClick = this.elementRef.nativeElement.contains(event.target);
		if (!insideClick && this.allowClose) {
			this.clickOutside.emit();
		}
		this.allowClose = true;
	}
}
