import { initialState as sharedInitialState } from './shared-slice.model';
import { initialState as adsManagerInitialState } from './ads-manager-slice.model';
import { initialState as campaignBuilderInitialState } from './campaign-builder-slice.model';
import { DataInterface } from '../data-interface.model';
import { SharedPreferences } from '../../../services/general-settings/models/shared-preferences/shared-preferences.model';
import { CampaignBuilderPreferences } from '../../../services/general-settings/models/campaign-builder-preferences.model';
import { AdsManagerPreferences } from '../../../services/general-settings/models/ads-manager-preferences.model';

export class GeneralSettingsSlice {
	shared: DataInterface<SharedPreferences>;
	adsManager: DataInterface<AdsManagerPreferences>;
	campaignBuilder: DataInterface<CampaignBuilderPreferences>;
}

export const initialState: GeneralSettingsSlice = {
	shared: sharedInitialState,
	adsManager: adsManagerInitialState,
	campaignBuilder: campaignBuilderInitialState
};
