import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownData, DropdownInterface } from '@filed-com/filed-lib';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { Country } from 'src/app/_services/back-office/back-office.models';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { CompleteModalComponent } from '../modals/complete-modal/complete-modal.component';

@Component({
	selector: 'app-payment-billing',
	templateUrl: './payment-billing.component.html',
	styleUrls: ['./payment-billing.component.scss']
})
export class PaymentBillingComponent implements OnInit {
	public userForm: FormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public userId: string;
	public decodeJwtToken: UserDetailsInterface;
	public countries: Country[];
	public dropdownCountries: DropdownData[];

	public paymentCard: any;
	constructor(public dialog: MatDialog, public backOfficeService: BackOfficeService, private formBuilder: FormBuilder, private router: Router) {}

	ngOnInit(): void {
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onUpdateCurrentDefaultCard(cardDetail: any): void {
		this.paymentCard = cardDetail;
	}

	public addCard(): void {
		this.router.navigate(['/user-management/payment-and-billing/add-card']);
	}
}
