<div class="tc-content">
	<span class="tc-subtitle-small">Exclude custom audience</span>
	<div class="tc-exclude-audience">
		<app-live-search-dropdown
			[dropdownWidth]="21"
			[dropdownData]="customAudiences"
			[selectedData]="selectedExcludeCustomAudiences"
			[columns]="customAudienceColumns"
			[inputLabel]="'Choose Custom audience'"
			[inputPlaceholder]="'My audience'"
			(selectionChange)="onDropdownDataChange($event, 'selectedExcludeCustomAudiences')"
		></app-live-search-dropdown>
	</div>

	<span class="tc-subtitle">Gender</span>
	<div class="tc-gender-group">
		<span
			class="tc-gender"
			(click)="onGenderClick(genders.All)"
			[ngClass]="{ 'tc-gender-selected': gender === genders.All, 'tc-gender-hidden-border': gender <= genders.Women }"
		>
			All
		</span>

		<span
			class="tc-gender"
			(click)="onGenderClick(genders.Women)"
			[ngClass]="{
				'tc-gender-selected': gender === genders.Women,
				'tc-gender-hidden-border': gender >= genders.Women,
				'tc-gender-not-allowed': isSpecialAddCategorySelected
			}"
		>
			Women
		</span>

		<span
			class="tc-gender tc-gender-hidden-border"
			(click)="onGenderClick(genders.Men)"
			[ngClass]="{ 'tc-gender-selected': gender === genders.Men, 'tc-gender-not-allowed': isSpecialAddCategorySelected }"
		>
			Men
		</span>
	</div>

	<span class="tc-age-subtitle">Age</span>
	<div class="tc-age">
		<ng5-slider class="tc-slider" (userChangeEnd)="onAgeChange()" [(highValue)]="maxAge" [(value)]="minAge" [options]="options"></ng5-slider>
	</div>

	<span class="tc-subtitle tc-extra">Locations</span>
	<div class="tc-locations">
		<app-live-search-dropdown
			(focusout)="onValidateLocation()"
			[inputControl]="locationFormControl"
			[dropdownWidth]="21"
			[dropdownData]="locations"
			[selectedData]="selectedLocations"
			[selfFilter]="false"
			[columns]="locationColumns"
			[inputLabel]="'Search location'"
			[inputPlaceholder]="'London'"
			(selectionChange)="onDropdownDataChange($event, 'selectedLocations')"
		></app-live-search-dropdown>
	</div>
	<span class="tc-subtitle">Language</span>
	<div class="tc-language">
		<app-live-search-dropdown
			[inputControl]="languagesFormControl"
			[dropdownWidth]="21"
			[dropdownData]="languages"
			[selectedData]="selectedLanguages"
			[columns]="languagesColumns"
			[inputLabel]="'Search languages'"
			[inputPlaceholder]="'English'"
			(selectionChange)="onDropdownDataChange($event, 'selectedLanguages')"
		></app-live-search-dropdown>
	</div>
	<div class="tc-subtitle"><span>Detailed Targeting</span></div>
	<span class="tc-subtitle-small">Include people that match</span>
	<div class="tc-interests">
		<app-live-search-dropdown
			[inputControl]="interestsFormControl"
			[dropdownWidth]="21"
			[dropdownData]="interests"
			[selectedData]="selectedInterests"
			[selfFilter]="false"
			[columns]="interestColumns"
			[inputLabel]="'Add demographics, interests or behaviours'"
			[inputPlaceholder]="'Fishing'"
			(selectionChange)="onDropdownDataChange($event, 'selectedInterests')"
		></app-live-search-dropdown>
	</div>
	<span class="tc-subtitle-small"></span>
	<div class="tc-narrow" *ngIf="!isSpecialAddCategorySelected">
		<div class="tc-narrow-btn-spacing">
			<btn04 *ngIf="!showNarrowInterests" (onClick)="onShowNarrowInterestsClick()">Narrow Audience</btn04>
		</div>

		<app-live-search-dropdown
			*ngIf="showNarrowInterests"
			[inputControl]="formGroup.get('narrowInterests')"
			[dropdownWidth]="21"
			[dropdownData]="narrowInterests"
			[selectedData]="selectedNarrowedInterest"
			[selfFilter]="false"
			[columns]="interestColumns"
			[inputLabel]="'Narrow demographics, interests or behaviours'"
			[inputPlaceholder]="'Fishing'"
			(selectionChange)="onDropdownDataChange($event, 'selectedNarrowedInterest')"
		></app-live-search-dropdown>
	</div>
	<span class="tc-exclude-extra"></span>

	<span class="tc-subtitle-small" *ngIf="showExcludeInterests">Exclude people that match</span>
	<div class="tc=exclude" *ngIf="!isSpecialAddCategorySelected">
		<btn04 *ngIf="!showExcludeInterests" (onClick)="onShowExcludedInterestsClick()">Exclude Audience</btn04>
		<app-live-search-dropdown
			*ngIf="showExcludeInterests"
			[inputControl]="formGroup.get('excludedInterests')"
			[dropdownWidth]="21"
			[dropdownData]="excludedInterests"
			[selectedData]="selectedExcludedInterest"
			[selfFilter]="false"
			[columns]="interestColumns"
			[inputLabel]="'Exclude demographics, interests or behaviours'"
			[inputPlaceholder]="'Fishing'"
			(selectionChange)="onDropdownDataChange($event, 'selectedExcludedInterest')"
		></app-live-search-dropdown>
	</div>

	<hr />
</div>
