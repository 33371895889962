import { BasicStructureModel } from '../../reports/models/basic-structure.model';
import { DataInterface, NullDataInterface } from '../../shared/state/state-entities/data-interface.model';
import _ from 'lodash';

export class AdAccount {
	public id: string;
	public name?: string;
	public status?: string;
	public businessName?: string;
	public businessId?: number;
	public businessIdAsNumber?: number;
	public currency?: string;
	public createdAt?: string;
	public campaigns?: StructureModel;
	public adSets?: StructureModel;
	public ads?: StructureModel;
	public level?: number;
	public expandable?: Boolean;
}

export interface StructureModel {
	active: DataInterface<BasicStructureModel[]>;
	paused: DataInterface<BasicStructureModel[]>;
}

export const NewAdAccountNulled: StructureModel = {
	active: _.cloneDeep(NullDataInterface),
	paused: _.cloneDeep(NullDataInterface)
};

export interface AdAccountDTO extends AdAccount {
	selectedByComponentId: number;
}

export class NavLinks {
	label: string;
	link: string;
	index: number;
}
