<div class="actions-dropdown" *ngIf="isDropDownVisible">
	<div
		class="actions-dropdown-option"
		[ngClass]="option.isDisabled ? 'action-dropdown-option-disable' : 'actions-dropdown-active-option'"
		*ngFor="let option of dropdownOptions"
		(click)="onOptionClick(option)"
	>
		{{ option.text }}
	</div>
</div>
