import { ChartSeriesModel } from './chart-series.model';
import { ChartLegendData } from './chart-legend-data';
import { ChartGridData } from './chart-grid-data';
import { ChartToolboxData } from './chart-toolbox-data';
import { ChartTypeEnum } from '../../../reports/shared/charts/chart-type.enum';

export class ChartDataModel {
	public constructor(
		public readonly title: string = '',
		public readonly subTitle: string = '',
		public readonly chartType: ChartTypeEnum = ChartTypeEnum.Line,
		public readonly categoricalData: any[] = [],
		public readonly isHorizontal: boolean = true,
		public readonly continuousData: ChartSeriesModel[] = [],
		public readonly legendData: ChartLegendData = new ChartLegendData(),
		public readonly gridData: ChartGridData = new ChartGridData(),
		public readonly toolboxData: ChartToolboxData = new ChartToolboxData()
	) {}
}
