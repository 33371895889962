<div class="filed_name">
  <!-- Generator: Adobe Illustrator 23.0.3, SVG Export Plug-In  -->
  <svg height="139.3px" style="enable-background:new 0 0 409.4 139.3;" version="1.1" viewBox="0 0 409.4 139.3"
       width="409.4px"
       x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
    <style type="text/css">
      .st0 {
        fill: #585857;
      }

      .st1 {
        fill: #2585FE;
      }

      .st2 {
        fill: #FF6E4E;
      }
    </style>
    <defs>
    </defs>
    <path class="st0" d="M148.9,37.3L148.9,37.3v99.8h-28.3V65.6C120.5,49.9,133.2,37.3,148.9,37.3z"/>
    <path class="st0" d="M192.4,1.1L192.4,1.1v136h-28.3V29.4C164.1,13.8,176.8,1.1,192.4,1.1z"/>
    <path class="st0" d="M202.1,87.4c0-28.5,20.3-52,49.4-52c33.3,0,48.6,25.9,48.6,54.2c0,2.2-0.2,4.8-0.4,7.4h-69.5
      c2.8,12.9,11.7,19.6,24.4,19.6c9.5,0,16.4-3,24.2-10.2l16.2,14.3c-9.3,11.5-22.7,18.6-40.8,18.6C224.2,139.3,202.1,118.3,202.1,87.4
      z M272.5,79.4c-1.7-12.7-9.1-21.2-21-21.2c-11.7,0-19.4,8.4-21.6,21.2H272.5z"/>
    <path class="st0" d="M304.5,87c0-33.3,21.8-51.6,45.5-51.6c15.1,0,24.4,6.9,31.1,14.9V29.4c0-15.6,12.7-28.3,28.3-28.3v136h-28.3
      v-14.3c-6.9,9.3-16.4,16.2-31.1,16.2C326.7,139,304.5,120.7,304.5,87z M381.4,87c0-16.6-11-27.6-24.2-27.6s-24.4,10.8-24.4,27.9
      c0,16.6,11.2,27.6,24.4,27.6C370.4,114.9,381.4,103.9,381.4,87z"/>
    <circle class="st1" cx="18.9" cy="118.2" r="18.9"/>
    <path class="st2"
          d="M151,16.1c0,8.9-7.2,16.1-16.1,16.1s-16.1-7.2-16.1-16.1S126,0,134.9,0C143.8-0.1,151,7.2,151,16.1z"/>
    <path class="st0" d="M76.4,31H0.2c0-16.6,13.4-30,30-30h76.2C106.4,17.6,93,31,76.4,31z"/>
    <path class="st0" d="M53.9,79.2H0.2l0,0c0-16.6,13.4-30,30-30h53.7C83.9,65.8,70.5,79.2,53.9,79.2z"/>
    </svg>
</div>
