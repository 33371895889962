<div class="m-auto">
  <div class="m-0 width-popup">{{"MODIFY_COLUMNS_FOR"| translate}}
  </div>
  <mat-divider></mat-divider>
  <div class="height-500" fxLayout="row wrap" fxLayout.sm="column" style="overflow: auto">
    <div class="width-400 column_names" fxLayout="column" fxLayoutAlign="start start">
      <div fxLayout="row">
        <mat-icon class="m-t-15">search</mat-icon>
        <mat-form-field class="search_columns">
          <input [formControl]="columnFilterCtrl" matInput placeholder="{{'SEARCH_COLUMNS'| translate}}" tabindex="-1">
        </mat-form-field>
      </div>
      <div (click)="addColumn(column)" *ngFor="let column of filteredColumns | async"
           [title]="column.columnDisplayName"
           class="pointer_div one_line_text m-l-10">
        {{column.columnDisplayName}}
      </div>
    </div>
    <div class="right_container p-t-24">
      <div fxLayout="row">
        <div class="right_container">{{"YOUR_COLUMNS"| translate}}
          <div class="small_title">{{"DRAG_DROP_RECORDER"| translate}}</div>
        </div>
      </div>
      <div>
        <div *ngFor="let column of data.displayedColumns">
          <div *ngIf="column.locked && column.name !== 'MASTER_CHECKBOX'">
            <mat-icon (click)="lockedColumn()" class="icon_size">lock</mat-icon>
            {{column.name | translate}}
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div (cdkDropListDropped)="drop($event)" cdkDropList class="p-t-24">
        <div *ngFor="let column of displayedColumns" cdkDrag>
          <div>
            <mat-icon class="icon_size">drag_handle</mat-icon>
            {{column.columnDisplayName}}
            <mat-icon (click)="hideColumn(column)" class="float-right icon_size">cancel</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
<div class="float-right" fxLayout="column" mat-dialog-actions>
  <div class="p-t-20 p-b-20">
    <mat-checkbox (change)="saveColumnsView($event.checked)"
                  class="small_title">{{"SAVE_COLUMN_SET"| translate}}</mat-checkbox>
  </div>
  <div>
    <button (click)="onNoClick()" [mat-dialog-close]="false" mat-button tabindex="-1">{{"CANCEL"| translate}}</button>
    <button (click)="displaySelectedColumns()" [mat-dialog-close]="data" mat-raised-button
            tabindex="-1">{{'SAVE'| translate}}</button>
  </div>
</div>
