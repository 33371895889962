import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { getFiledUser, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { LoadFiledUser } from 'src/app/user-management/state/user-management.actions';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';

@Component({
	selector: 'app-reactivate-account',
	templateUrl: './reactivate-account.component.html',
	styleUrls: ['./reactivate-account.component.scss']
})
export class ReactivateAccountComponent implements OnInit {
	private unsubscriber$: Subject<void> = new Subject<void>();
	public loading: boolean = false;
	public userFeed: any = [];
	public userProfile: any;
	public filedId: number;
	constructor(
		private _location: Location,
		private router: Router,
		private userServiceApi: UserServiceApi,
		private sharedStore: Store<SharedState>,
		private store: Store<UserManagementState>,
		private userStore: Store<UserState>
	) {}

	ngOnInit(): void {
		this.getUserFeedback();
	}

	public getUserFeedback(): void {
		this.userStore.pipe(select(getFiledId), take(1)).subscribe(id => {
			if (id) {
				this.filedId = id;
				this.store.dispatch(new LoadFiledUser({ id: id, setSelectedUser: false, isBackoffice: false }));
			}
		});

		this.store.pipe(select(getFiledUser), takeUntil(this.unsubscriber$)).subscribe(state => {
			this.userProfile = state;
		});
	}

	public backClicked() {
		this._location.back();
	}

	public onCancel() {
		this.router.navigate(['user-management/security']);
	}

	public onReactivateAcct() {
		this.loading = true;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.userServiceApi
			.activateUser(this.filedId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				activities => {
					if (activities) {
						this.router.navigate(['accounts']);
					}
				},
				error => {
					console.log(error);
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}
}
