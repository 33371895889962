import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CellClassParams, ColDef, IFilterComp } from 'ag-grid-community';
import { of, Subject } from 'rxjs';
import { debounceTime, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { AdsManagerHeaderCheckboxComponent } from 'src/app/ads-manager/ads-manager-insights/components-pieces/ads-manager-ag-grid-components/ads-manager-header-checkbox/ads-manager-header-checkbox.component';
import { TimeRangeInterface } from 'src/app/ads-manager/ads-manager-insights/models/time-range.interface';
import { AdsManagerHelperService } from 'src/app/ads-manager/ads-manager-insights/services/ads-manager-helper.service';
import { AdsService } from 'src/app/ads-manager/ads-manager-insights/services/ads.service';
import { AdsManagerResolverDataInterface } from 'src/app/ads-manager/models/ads-manager-resolver-data.interface';
import { CustomFieldCellRendererComponent } from 'src/app/shared/ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer.component';
import { MasterTableRowSelectionEnum } from 'src/app/shared/master-table/master-table-row-selection.enum';
import { AgGridViewInterface } from 'src/app/shared/master-table/models/ag-grid-view.interface';
import { MasterTableGridReadyInterface } from 'src/app/shared/master-table/models/master-table-grid-ready.interface';
import { MasterTableImplementationsEnum } from 'src/app/shared/master-table/models/master-table-implementations.enum';
import { RowModelTypeEnum } from 'src/app/shared/master-table/models/row-model-type.enum';
import { StandardColorsEnum } from 'src/app/shared/models/standard-colors.enum';
import { getGlobalDate, getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { AAAAdViewsEnum } from '../../models/AAA-ad-views.enum';
import { setStepBasedOnFlow } from '../../helpers/helper';
import { AAAFlow, stepName } from '../../models/AAA-steps.enum';
import { AaaHeaderFooterStateService } from '../../services/aaa-header-footer-state.service';
import { AAAState } from '../../state/aaa.reducer';
import { getSelectedAds, getSelectedAdsets, getSelectedCampaigns } from '../../state';
import { AdsAAAInterface } from '../../models/ads-aaa.interface';
import { UpdateSelectedAAAAdIds } from '../../state/aaa.actions';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AdsManagerImageCellComponent } from 'src/app/shared/ag-grid-custom-cell-renderers/ads-manager-image-cell/ads-manager-image-cell.component';
import { CampaignAAAInterface } from '../../models/campaign';
import { AdSetsAAAInterface } from '../../models/ad-sets-aaa.interface';
import { ResetState } from '../../state/ad-state/ad-state.actions';

@Component({
	selector: 'app-add-selection',
	templateUrl: './add-selection.component.html',
	styleUrls: ['./add-selection.component.scss']
})
export class AddSelectionComponent implements OnInit {
	public adsManagerMasterTableImplementations = MasterTableImplementationsEnum;
	public frameworkComponents = {
		checkboxHeader: AdsManagerHeaderCheckboxComponent,
		customFieldCellRenderer: CustomFieldCellRendererComponent,
		imageCellRenderer: AdsManagerImageCellComponent
	};
	public rowSelection = MasterTableRowSelectionEnum;
	public rowModelType = RowModelTypeEnum;
	public defaultPageSize = 25;
	public defaultFilterParams = {
		suppressAndOrCondition: true,
		buttons: ['reset', 'apply'],
		debounceMs: 200
	};
	public defaultColDef: ColDef = {
		minWidth: 100,
		flex: 1,
		sortable: true,
		resizable: true,
		lockPinned: true,
		menuTabs: [],
		filterParams: { ...this.defaultFilterParams },
		cellStyle: (params: CellClassParams) => {
			if (!params.colDef.pinned || params.colDef.editable) {
				return {
					'border-right': `1px solid ${StandardColorsEnum.FiledLighterGray}`
				};
			}
			return { border: '0' };
		}
	};
	public paginationOptions = [25, 50, 100, 200];
	public adView: ColDef[];
	public adViews: AgGridViewInterface[];
	public flow: AAAFlow;
	public selectedCampaignIds: string[] = [];
	public selectedAdSetIds: string[] = [];
	public selectedCampaigns: CampaignAAAInterface[];
	public selectedAdsets: AdSetsAAAInterface[];
	public searchFormControl: FormGroup;
	public selectedAds: AdsAAAInterface[];
	public numberFilterOptions = ['lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'];
	public selectedAdIds: string[];
	public initialAdFilters: (string | true | (new () => IFilterComp))[];
	private unsubscriber$ = new Subject<void>();

	constructor(
		public adsService: AdsService,
		public adsManagerHelper: AdsManagerHelperService,
		public activatedRoute: ActivatedRoute,
		public sharedStore: Store<SharedState>,
		private aaaStateService: AaaHeaderFooterStateService,
		private store: Store<AAAState>,
		private formBuilder: FormBuilder
	) {}

	ngOnInit(): void {
		this.getViews();
		this.initializeSubscriptions();
		this.listenToRoute();
		this.registeringActions();
		this.createForm();
		this.filteredData();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onGridReady(masterTableReady: MasterTableGridReadyInterface): void {
		this.adsService.gridApi = masterTableReady.gridApi;
		this.adsService.columnApi = masterTableReady.columnApi;
		this.adsService.hasDelivery = true;
		this.adsService.resetRequest();
		const sortModel = [{ colId: 'reach', sort: 'desc' }];
		this.adsService.gridApi.setSortModel(sortModel);
	}

	public filteredData(): void {
		this.searchFormControl
			.get('searchAdForm')
			.valueChanges.pipe(debounceTime(500))
			.subscribe(value => {
				this.adsService.filterName = value;
				this.adsService.resetRequest();
			});
	}

	private createForm(): void {
		this.searchFormControl = this.formBuilder.group({
			searchAdForm: new FormControl(null)
		});
	}

	public refactorNumberFilterOptions(column: ColDef): void {
		if (column.filter === 'agNumberColumnFilter') {
			column.filterParams = {
				...this.defaultFilterParams,
				filterOptions: this.numberFilterOptions
			};
		}
	}

	public getViews(): void {
		this.activatedRoute.data.pipe(take(1)).subscribe((obj: AdsManagerResolverDataInterface) => {
			this.adViews = obj.views.adViews;
			const adColIds = [
				'ad_id',
				'ad_image',
				'selected',
				'ad_name',
				'reach',
				'impressions',
				'results',
				'cost_per_result',
				'cost_per_unique_click_all',
				'unique_ctr_all'
			];
			this.adView = this.adViews.find(adView => adView.name === 'Create New Ad').columns;
			this.adView = this.adView.filter(adView => adColIds.includes(adView.colId));
			this.adsService.selectedAdSetsIds = [];
			this.adsService.selectedCampaignIds = [];
			this.adsService.innerStoreAction = true;
			this.initialAdFilters = this.extractDefaultFilters(this.adView);
			this.adsService.resetRequest();
			this.adView.forEach((ad, index) => {
				switch (ad.colId) {
					case AAAAdViewsEnum.ad_name:
						ad.pinned = null;
						ad.pinnedRowCellRenderer = null;
						ad.cellRenderer = null;
						break;
					case AAAAdViewsEnum.cost_per_result:
						ad.headerName = 'CPR';
						break;
					case AAAAdViewsEnum.cost_per_unique_click_all:
						ad.headerName = 'CPC';
						break;
					case AAAAdViewsEnum.unique_ctr_all:
						ad.headerName = 'CTR';
						break;
				}
				if (ad.sortable === true) {
					ad.headerTooltip = '';
					ad.tooltipField = ad.field;
				}
				ad.filter = this.initialAdFilters[index];
				this.refactorNumberFilterOptions(ad);
				ad.suppressMenu = false;
			});
			const toolPanelClassHidden = 'u-hidden';
			this.adView[0].toolPanelClass = toolPanelClassHidden;
		});
		this.adsService.resetRequest();
	}

	public extractDefaultFilters(arr: ColDef[]): (string | true | (new () => IFilterComp))[] {
		return arr.map(item => item.filter || '');
	}

	private initializeSubscriptions(): void {
		this.sharedStore.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(data => {
			this.adsService.selectedAccount = data.adAccount.id;
			this.adsService.resetRequest();
		});

		this.store
			.pipe(
				select(getSelectedAds),
				debounceTime(50),
				takeUntil(this.unsubscriber$),
				switchMap((selectedAds: AdsAAAInterface[]) => {
					return of(selectedAds);
				})
			)
			.subscribe((selectedAds: AdsAAAInterface[]) => {
				this.selectedAds = selectedAds;
				if (selectedAds && selectedAds.length !== 0) {
					this.selectedAdIds = selectedAds.map(ad => ad.ad_id);
					this.store.dispatch(new UpdateSelectedAAAAdIds(this.selectedAdIds));
					this.adsService.selectedAdsIds = this.selectedAdIds;
				} else {
					this.adsService.deselectAll();
					this.adsService.selectedAdsIds = [];
					this.selectedAdIds = [];
					this.store.dispatch(new UpdateSelectedAAAAdIds(null));
				}
			});

		this.sharedStore.pipe(select(getGlobalDate), takeUntil(this.unsubscriber$)).subscribe(date => {
			const newTimeRange: TimeRangeInterface = {
				since: moment().subtract(6, 'days').format('YYYY-MM-DD'),
				until: moment().format('YYYY-MM-DD')
			};
			this.adsService.timeRange = newTimeRange;
		});
	}

	public registeringActions() {
		this.aaaStateService
			.onNext()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.aaaStateService.setSkips([]);
				this.aaaStateService.editSelectedAds.next(true);
				this.store.dispatch(new ResetState());
				this.aaaStateService.sendNavigateForward();
			});
		this.aaaStateService
			.onSave()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {});
	}

	public setCurrentStepBasedOnFlow(): void {
		setStepBasedOnFlow(this, this.flow, this.setCurrentStep, stepName.SelectAd);
	}
	public listenToRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.flow = +params['flow'];
			this.setCurrentStepBasedOnFlow();
		});
	}
	public setCurrentStep(step: number): void {
		this.aaaStateService.sendActiveStep(step);
		this.aaaStateService.sendCampaignStep(step);
		this.aaaStateService.onPreviewAd(false);
	}
}
