import { Injectable } from '@angular/core';
import { DateRange } from '../../shared/calendar/calendar.models';
import { PixelEventInsightDto } from '../../pixel/_models/pixel-event-insight-dto';
import moment from 'moment';
import { ChartSeriesModel } from '../../shared/charts2/chart-models/chart-series.model';
import { PixelInsightsCommand } from '../../pixel/_models/pixel-query.model';
import { PixelInsightsLevel } from '../../pixel/_models/pixel-insights-level.enum';
import { PixelsDto } from '../../pixel/_models/pixels-dto.model';

@Injectable()
export class PixelService {
	public static mapPixels(pixels: PixelsDto[]): PixelsDto[] {
		const mappedPixels: PixelsDto[] = [];
		pixels.forEach(pixel => {
			mappedPixels.push({
				...pixel,
				status: pixel.statusId === 1
			});
		});
		return mappedPixels;
	}

	public getQueryForPixelDetails(
		facebookId: string,
		breakdown: string,
		selectedDateRange: DateRange,
		level: PixelInsightsLevel,
		facebookName = ''
	): PixelInsightsCommand {
		return {
			facebookId: facebookId,
			facebookName: facebookName,
			breakdown: breakdown,
			startTime: selectedDateRange.startDate.format('YYYY-MM-DD'),
			endTime: selectedDateRange.endDate.format('YYYY-MM-DD'),
			level: level
		};
	}

	public formatInsightsForChart(data: PixelEventInsightDto[], dates: string[]): Map<string, ChartSeriesModel[]> {
		if (!data || data.length === 0) {
			return;
		}
		const finalSeries: ChartSeriesModel[] = [];

		const mappedByEvent: Map<string, PixelEventInsightDto[]> = new Map<string, PixelEventInsightDto[]>();

		for (const insight of data) {
			const formattedInsight = {
				...insight,
				timestamp: moment(insight.timestamp, 'YYYY/MM/DD').format('DD/MM/YY')
			};
			if (!mappedByEvent.has(formattedInsight.value)) {
				mappedByEvent.set(formattedInsight.value, []);
			}
			mappedByEvent.get(formattedInsight.value).push(formattedInsight);
		}
		// format the data by Parameters_Until so that we can get the sum of values of events by date
		for (const event of Array.from(mappedByEvent.keys())) {
			const series = new ChartSeriesModel();
			series.name = event;
			series.data = new Array(dates.length);

			const mappedByTimestamp: Map<string, PixelEventInsightDto[]> = new Map<string, PixelEventInsightDto[]>();
			mappedByEvent.get(event).forEach(insight => {
				if (!mappedByTimestamp.has(insight.timestamp)) {
					mappedByTimestamp.set(insight.timestamp, []);
				}
				mappedByTimestamp.get(insight.timestamp).push(insight);
			});

			mappedByTimestamp.forEach(dto => {
				dto.forEach(x => {
					const timestamp = dto[0].timestamp;
					const index = dates.indexOf(timestamp);

					if (index !== -1) {
						series.data[index] = x.count;
					}
				});
			});
			finalSeries.push(series);
		}
		return new Map().set(data[0].breakdown, finalSeries);
	}
}
