export enum FrontEndModule {
	Accounts = 1,
	AdsManager = 2,
	CampaignBuilder = 3,
	Audience = 4,
	Reports = 5,
	Optimize = 6,
	Pixel = 7,
	UserManagement = 8,
	BillingAndPayment = 9
}
