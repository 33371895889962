<app-user-management-header></app-user-management-header>

<form [formGroup]="reason">
	<div class="page-container">
		<app-user-sub-header [pageTitle]="SettingsPageLabelsEnum.MySubscription"></app-user-sub-header>
		<div>
			<h5 class="text">{{ 'HELP_TODAY' | translate }}</h5>
			<app-dropdown-search-select
				[dropdownData]="reasons"
				[dropdownFormControl]="reason.get('option')"
				[dropdownPlaceholder]="'Search...'"
			></app-dropdown-search-select>
		</div>
	</div>
</form>

<ng-container *ngFor="let answer of answers">
	<div *ngIf="this.reason.value.option === answer.value" class="page-container2">
		<h5 class="subtitle">{{ reasons[answer.value].displayName }}</h5>

		<h6 class="text">{{ answer.displayName }}</h6>

		<app-input-textarea [control]="textareaComment" [validatorMessages]="textareaMessages" label="Comment"></app-input-textarea>
	</div>
</ng-container>

<div class="wrapper-buttons">
	<app-button (buttonClicked)="goBack()" [buttonClass]="buttonClassEnum.Secondary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="CANCEL"></app-button>
	<app-button
		(buttonClicked)="sendEmail()"
		[buttonClass]="buttonClassEnum.Tertiary"
		[buttonType]="buttonTypeEnum.Simple"
		[disabled]="formInvalid()"
		buttonLabel="SUBMIT"
	></app-button>
</div>
