import { AdModel } from '../../models/ad.model';
import { AdState } from './ad-state.reducer';
import _ from 'lodash';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { EventDtoInterface } from 'src/app/pixel/_models/event-dto.interface';
import { CarouselDto, SingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';

export const addAdToState = (state: AdState, payload: AdModel): AdModel[] => {
	const ads = _.cloneDeep(state.ads);
	ads.push(payload);
	return ads;
};

export const removeAdFromState = (state: AdState, payload: number): AdModel[] => {
	const ads = _.cloneDeep(state.ads);
	ads.splice(payload, 1);
	return ads;
};

export const updateAdFormatType = (state: AdState, payload: AdFormatsEnum): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.adFormatType = payload;
	return ads;
};

export const updateAdFacebookId = (state: AdState, payload: { facebookPageId: string; instagramPageId: string }): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.facebookPageId = payload.facebookPageId;
	currentAd.instagramPageId = payload.instagramPageId;
	return ads;
};

export const updateAdName = (state: AdState, payload: string): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.adName = payload;
	return ads;
};

export const updatePixelId = (state: AdState, payload: string): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.pixelId = payload;
	return ads;
};

export const updatePixelAppEventId = (state: AdState, payload: string): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.pixelAppEventId = payload;
	return ads;
};

export const updatePixelToggle = (state: AdState, payload: boolean): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.toggle = payload;
	return ads;
};

export const updateConversion = (state: AdState, payload: DropdownData<EventDtoInterface>): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	if (payload) {
		currentAd.customEventType = payload.data.customEventType;
		currentAd.pixelRule = payload.data.rule ?? null;
		currentAd.conversionId = payload.data.id;
	} else {
		currentAd.customEventType = null;
		currentAd.pixelRule = null;
	}
	return ads;
};

export const updateEventRule = (state: AdState, payload: Object): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.eventRule = payload;
	return ads;
};

export const updateAdverts = (state: AdState, payload: SingleAdvertDto | CarouselDto): AdModel[] => {
	const currentAdId = state.currentSelectedAdId;
	const ads = _.cloneDeep(state.ads);
	const currentAd = ads[currentAdId];
	currentAd.adverts = _.cloneDeep(payload);
	return ads;
};
