import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AgGridViewInterface } from '../../shared/master-table/models/ag-grid-view.interface';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { AccountsAgGridDataInterface } from '../models/accounts-ag-grid-data.interface';
import { AccountsAgGridRequestInterface } from '../models/accounts-ag-grid-request.interface';
import { AccountsAgGridComponentsEnum } from '../enums/accounts-ag-grid-components.enum';
import { AgGridColumnConfigurationEnum } from '../../shared/master-table/models/ag-grid-column-configuration.enum';
import { AccountsMasterTableService } from './accounts-master-table.service';
import { AccountsCellRenderParamsInterface } from '../models/accounts-cell-render-params.interface';
import { AccountsColumnFieldsEnum } from '../enums/accounts-column-fields.enum';
import { AgGridColDefInterface } from '../../shared/master-table/models/ag-grid-col-def.interface';
import { ColDef } from 'ag-grid-community';
import { CustomFieldCellRendererParamsInterface } from '../../shared/ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer-params.interface';
import { ColumnTypesEnum } from '../../shared/master-table/models/column-types.enum';
import { WelcomePageModel } from 'src/app/shared/welcome-page/welcome-page.model';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ISyncReportProgressRequest, ISyncReportProgressResponse, ISyncReportStartResponse } from 'src/app/optimise/models/welcome-sync.model';
import { getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { Store } from '@ngrx/store';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import { getUserDetails } from 'src/app/shared/state/user/user.reducer';

@Injectable()
export class AccountsService {
	constructor(
		private http: HttpClient,
		private masterTableService: AccountsMasterTableService,
		private authService: AuthenticationService,
		private store: Store<SharedState>
	) {}

	public getViews(): Observable<AgGridViewInterface> {
		return this.http.get<AgGridViewInterface>(`${BaseApiUrl.GoogleTuring}accounts-ag-grid-view`);
	}

	public getRows(request: AccountsAgGridRequestInterface): Observable<AccountsAgGridDataInterface[]> {
		return this.http.post<AccountsAgGridDataInterface[]>(`${BaseApiUrl.GoogleTuring}accounts-ag-grid-insights`, request);
	}

	public dexterSyncStart(request: ISyncReportProgressRequest): Observable<ISyncReportStartResponse> {
		return this.http.post<ISyncReportStartResponse>(`${BaseApiUrl.DexterSync}dexter-sync/report/start`, request);
	}

	public dexterSyncProgress(request: ISyncReportProgressRequest): Observable<ISyncReportProgressResponse> {
		return this.http.post<ISyncReportProgressResponse>(`${BaseApiUrl.DexterSync}dexter-sync/report/progress`, request);
	}
	public dexterSyncObjective(request: ISyncReportProgressRequest): Observable<ISyncReportProgressResponse> {
		return this.http.post<ISyncReportProgressResponse>(`${BaseApiUrl.DexterSync}dexter-sync/report/objectives`, request);
	}
	public dexterConfirmObjective(request: any): Observable<any> {
		return this.http.put<any>(`${BaseApiUrl.DexterSync}dexter-sync/report/primary-objective`, request);
	}

	public mapView(view: AgGridViewInterface): void {
		const actionsColDef: ColDef = {
			colId: 'actions',
			field: 'actions',
			headerName: 'Actions',
			maxWidth: 100,
			cellRenderer: AccountsAgGridComponentsEnum.ActionCellRender,
			cellRendererParams: {
				parentService: this.masterTableService
			},

			cellEditor: 'actionsCellEditor',
			headerComponent: AccountsAgGridComponentsEnum.ActionsHeaderComponent,
			cellEditorParams: {
				parentService: this.masterTableService
			},
			pinned: 'left',
			resizable: false,
			editable: true,
			lockPinned: true
		};
		view.columns.forEach(columnDef => {
			this.addColId(columnDef);
			this.addAccountNameFunctionality(columnDef);
			this.showFilterButtons(columnDef);
			this.addCustomFieldCellRenderer(columnDef);
			this.removeColDefPropertiesUnrelatedToAgGrid(columnDef);
		});
		view.columns.unshift(actionsColDef);
	}

	private addColId(columnDef: AgGridColDefInterface): void {
		columnDef.colId = columnDef.field;
	}

	private addAccountNameFunctionality(columnDef: AgGridColDefInterface): void {
		if (columnDef.colId === AccountsColumnFieldsEnum.AccountName) {
			columnDef.cellRenderer = AccountsAgGridComponentsEnum.AccountNameCellRenderer;
			(columnDef.cellRendererParams as AccountsCellRenderParamsInterface) = {
				parentService: this.masterTableService
			};
		}
	}

	public showFilterButtons(column: AgGridColDefInterface): void {
		if (column.filter) {
			column.menuTabs = [AgGridColumnConfigurationEnum.FilterMenuTab];
		}
	}

	private addCustomFieldCellRenderer(columnDef: AgGridColDefInterface): void {
		if (columnDef.numberOfDecimals || columnDef.columnType === ColumnTypesEnum.Percentage) {
			columnDef.cellRenderer = AccountsAgGridComponentsEnum.CustomFieldCellRenderer;
			(columnDef.cellRendererParams as CustomFieldCellRendererParamsInterface) = {
				numberOfDecimals: columnDef.numberOfDecimals,
				isPercentage: columnDef.columnType === ColumnTypesEnum.Percentage
			};
		}
	}

	private removeColDefPropertiesUnrelatedToAgGrid(columnDef: AgGridColDefInterface): void {
		if (columnDef.numberOfDecimals) {
			delete columnDef.numberOfDecimals;
		}
		if (columnDef.columnType) {
			delete columnDef.columnType;
		}
	}

	public getConnectFbAccountsModel(buttons: boolean): WelcomePageModel {
		const menuItemBlocked = this.authService.setRoute$.value;
		let title = 'No data to report';
		let subtitle = 'Connect to your accounts.<a href="https://www.filed.com" target="_blank"> Learn more</a>';
		if (menuItemBlocked && menuItemBlocked != '') {
			if (menuItemBlocked === 'campaign') {
				title = 'Campaign Builder module not available';
			} else if (menuItemBlocked === 'ads-manager') {
				title = 'Ads Manager module not available';
			} else if (menuItemBlocked === 'optimize') {
				title = 'Optimize module not available';
			} else if (menuItemBlocked === 'pixel') {
				title = 'Pixel module not available';
			} else if (menuItemBlocked === 'audience') {
				title = 'Audience module not available';
			} else if (menuItemBlocked === 'reports') {
				title = 'Reports module not available';
			} else if (menuItemBlocked === 'accounts') {
				title = 'Accounts module not available';
			} else if (menuItemBlocked === 'product-catalog') {
				title = 'You have access to Product Catalog, choose one of the sub menu items to continue..';
				subtitle = '';
			} else if (menuItemBlocked === 'influencer') {
				title = 'You have access to Influencer, choose one of the sub menu items to continue..';
				subtitle = '';
			} else if (menuItemBlocked === 'creative-builder') {
				title = 'You have access to Creative Builder, choose one of the sub menu items to continue..';
				subtitle = '';
			} else {
				title = 'No data to report';
			}
		}
		const model = new WelcomePageModel();
		model.img.left = 'assets/images/account-facebook-connect.svg';
		model.title = title;
		model.subtitle = subtitle;
		model.buttonText.left = 'Get Started';
		model.showRightCard = false;
		model.showButtons = buttons;
		return model;
	}

	public runDexterSyncStart(): Observable<ISyncReportStartResponse | ISyncReportProgressResponse> {
		return this.store.select(getSelectedAdAccount).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([adAccount, userDetails]) => {
				const syncProgressReq: ISyncReportProgressRequest = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					]
				};
				return this.dexterSyncProgress(syncProgressReq);
			}),
			withLatestFrom(this.store.select(getSelectedAdAccount)),
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([[sync, adAccount], userDetails]) => {
				const syncProgressReq: ISyncReportProgressRequest = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					]
				};
				return sync.data.progress === 0 ? this.dexterSyncStart(syncProgressReq) : of(sync);
			})
		);
	}

	public runDexterProgress(): Observable<ISyncReportProgressResponse> {
		return this.store.select(getSelectedAdAccount).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([adAccount, userDetails]) => {
				const syncProgressReq: ISyncReportProgressRequest = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					]
				};
				return this.dexterSyncProgress(syncProgressReq);
			})
		);
	}
	public runDexterObjecttive(): Observable<any> {
		return this.store.select(getSelectedAdAccount).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([adAccount, userDetails]) => {
				const syncProgressReq: ISyncReportProgressRequest = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					]
				};
				return this.dexterSyncObjective(syncProgressReq);
			})
		);
	}
	public runDexterConfirmObjecttive(objectKey: string): Observable<any> {
		return this.store.select(getSelectedAdAccount).pipe(
			withLatestFrom(this.store.select(getUserDetails)),
			concatMap(([adAccount, userDetails]) => {
				const syncProgressReq: any = {
					businessOwners: [
						{
							businessOwnerFacebookId: userDetails.FacebookBusinessOwnerId,
							adAccountIds: [adAccount.adAccount.id.replace('act_', '')]
						}
					],
					data: {
						objectiveKey: objectKey
					}
				};
				return this.dexterConfirmObjective(syncProgressReq);
			})
		);
	}
}
