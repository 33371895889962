import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash';
import { takeUntil } from 'rxjs/operators';

export interface GroupingFilterListItem {
	label: string;
	filterProperty: string;
}

@Component({
	selector: 'app-generic-search',
	templateUrl: './generic-search.component.html',
	styleUrls: ['./generic-search.component.scss']
})
export class GenericSearchComponent implements OnInit {
	@ViewChild('container') container: ElementRef;
	@Input() set dataSource(dataSource: any[]) {
		this.checkDataSource(dataSource);
	}

	@Input() set showSpinner(showSpinner: boolean) {
		this._showSpinner = showSpinner;
	}

	@Input() get getSpinner(): boolean {
		return this._showSpinner;
	}

	@Input() static?: boolean;
	@Input() uniqueKey?: string;
	@Input() confirmationKey?: string;
	@Input() url: string;
	@Input() filterBy: string;
	@Input() secondFilter?: string;
	@Input() thirdFilter?: string;
	@Input() fourthFilter?: string;
	@Input() placeholder: string;
	@Input() queryStringName?: string;
	@Input() groupingFilterList?: GroupingFilterListItem[] = [];
	@Input() multiselect?: boolean;
	@Input() excludeNumbers: boolean;

	@Input() set selectedValues(value: any[]) {
		this.processedSelectedValues = value;
		this.prepareComposedValues(this.processedSelectedValues);
	}

	@Output() optionSelection: EventEmitter<any> = new EventEmitter();

	public terms: any[] = [];
	public selectedTerm: any;
	public timeout: any;
	public pendingRequest: Subscription;
	public selectedList: any[] = [];
	public showTerms: Boolean = false;
	public filterSearch: string;
	public displayFn: ((value: any) => string) | null;
	public composedKey: string;
	public showSecondFilter = true;
	public showThirdFilter = true;
	public processedSelectedValues: any[];

	private readonly debounceDelay: number = 850;
	private _showSpinner: boolean;
	private readonly defaultUniqueKey: string = 'key';
	private callSubscription: Subscription;
	private unsubscriber$: Subject<void> = new Subject<void>();

	@HostListener('document:click', ['$event.target'])
	public onClickEvent(element: HTMLElement): void {
		if (!this.container || !this.container.nativeElement) {
			return;
		}

		const clickedInside = !!this.container.nativeElement.contains(element);

		if (!clickedInside) {
			this.hideList();
		}
	}

	constructor(private http: HttpClient) {
		// bind to the Angular component in order to have access to this context due to material autocomplete callback
		this.displayFn = this._displayFn.bind(this);
	}

	ngOnInit() {
		this.init();
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private checkDataSource = (dataSource: any[]): void => {
		if (dataSource && dataSource.length) {
			this.terms = _.cloneDeep(dataSource);
			this.sortTerms();
		} else {
			this.terms = [];
		}
	};

	private getPropertyListValue = (obj: any, properties: string[]): any => {
		let prop = obj;
		for (let i = 0; i < properties.length; i++) {
			prop = prop[properties[i]];
		}
		return prop;
	};

	private init = (): void => {
		if (this.multiselect) {
			this.selectedTerm = [];
		}
		if (!this.uniqueKey) {
			this.uniqueKey = this.defaultUniqueKey;
		}
		// if (!this.static) { this.static = true; }
		this.prepareComposedKey();
	};

	private prepareComposedKey() {
		this.composedKey = this.uniqueKey;
		if (this.confirmationKey) {
			this.composedKey += '_' + this.confirmationKey;
		}
	}

	private prepareComposedValues(items: any[]) {
		items.forEach(term => {
			term[this.composedKey] = term[this.uniqueKey];
			if (this.confirmationKey) {
				term[this.composedKey] += '_' + term[this.confirmationKey];
			}
		});
	}

	private getPropertyPathAsList = (propertyPath: string): string[] => {
		return propertyPath.split(/[\s,./]/);
	};

	private preselectExistingValues() {
		if (this.processedSelectedValues) {
			this.selectedTerm = this.processedSelectedValues;
		}
	}

	private _displayFn(item?: any): string | null {
		return item ? item[this.filterBy] : null;
	}

	public noNumbers(event: any): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		return !this.excludeNumbers || charCode === 46 || charCode === 8 || (charCode >= 35 && charCode <= 40) || (charCode >= 65 && charCode <= 90);
	}

	filterTerms(value: string) {
		if (this.pendingRequest) {
			this._showSpinner = false;
			this.pendingRequest.unsubscribe();
		}

		if (!value) {
			return;
		}

		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this._showSpinner = true;
			if (this.callSubscription) {
				this.callSubscription.unsubscribe();
			}
			this.callSubscription = this.http
				.get<any>(this.getSearchEndpoint(value))
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					(data: any) => {
						this._showSpinner = false;
						this.filterSearch = value;
						this.terms = data;
						this.sortTerms();
						this.showSecondFilter = this.terms.some(item => item[this.secondFilter]);
						this.showThirdFilter = this.terms.some(item => item[this.thirdFilter]);
						this.prepareComposedValues(this.terms);
						this.preselectExistingValues();
					},
					() => {
						this._showSpinner = false;
					}
				);
		}, this.debounceDelay);
	}

	getSearchEndpoint(value: string) {
		return this.queryStringName !== '' && this.queryStringName ? `${this.url}?${this.queryStringName}${value}` : `${this.url}${value}`;
	}

	getSelectedOption(option: any) {
		this.selectedTerm = option;
		this.emitSelection();
	}

	emitSelection = (): void => {
		// Current visible selection
		const selectedTerm = this.selectedTerm;

		// Include selected items not in view
		if (this.processedSelectedValues) {
			this.processedSelectedValues.forEach(selectedValue => {
				if (this.static) {
					if (
						!this.terms
							.filter(term => !this.filterSearch || new RegExp(this.filterSearch, 'i').test(term[this.filterBy]))
							.find(term => term[this.composedKey] === selectedValue[this.composedKey])
					) {
						selectedTerm.push(selectedValue);
					}
				} else {
					if (!this.terms.find(term => term[this.composedKey] === selectedValue[this.composedKey])) {
						selectedTerm.push(selectedValue);
					}
				}
			});
		}

		this.optionSelection.emit(selectedTerm);
	};

	public removeSelectedItem(index: number): void {
		this.selectedList.splice(index, 1);
	}

	public deselectAllSelectedItems(): void {
		this.selectedList = [];
		this.selectedTerm = [];
	}

	public checkSelected(term: any, selectedList: any[]): boolean {
		const condition = selectedList.some(
			(selectedTerm: any) =>
				selectedTerm[this.uniqueKey] === term[this.uniqueKey] &&
				// this is related to interests search response dto in CBS3 where KEY / ID are not PK, therefor PK = (KEY, TYPE) <=> PK = (this.uniqueKey, this,confirmationKey)
				(!this.confirmationKey || selectedTerm[this.confirmationKey] === term[this.confirmationKey])
		);
		return condition;
	}

	public setSelectedItemList(selectionModel: MatSelectionListChange) {
		const selectedIds = selectionModel.source.selectedOptions.selected.map(selection => selection.value);
		this.selectedTerm = this.terms.filter(item => selectedIds.some(selectedId => selectedId === item[this.composedKey]));

		this.emitSelection();
	}

	public hideList(): void {
		this.showTerms = false;
	}

	public showList(event?: Event): void {
		if (event) {
			event.stopPropagation();
		}
		this.preselectExistingValues();
		this.showTerms = true;
	}

	public filterStaticTerms(value: string) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.filterSearch = value;
		}, this.debounceDelay);
	}

	private sortTerms() {
		const orderBy = this.filterBy;
		this.terms.sort((a: any, b: any) => {
			if (!a[orderBy] || typeof a[orderBy] !== 'string' || !b[orderBy] || typeof b[orderBy] !== 'string') {
				return 0;
			}

			if (a[orderBy].toLowerCase() < b[orderBy].toLowerCase()) {
				return -1;
			} else if (a[orderBy].toLowerCase() > b[orderBy].toLowerCase()) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	processDisplayTerm(value: any) {
		return this.filterSearch ? value.replace(new RegExp('(' + this.filterSearch + ')', 'ig'), '<b>$1</b>') : value;
	}
}
