import { QueryBuilderLogicalOperator } from './query-builder-logical-operator.enum';
import { QueryBuilderCondition } from './query-builder-condition.model';

export class QueryBuilderConditionBlock {
	constructor(
		public LogicalOperator: QueryBuilderLogicalOperator,
		public Conditions?: QueryBuilderCondition[],
		public ChildConditions?: QueryBuilderConditionBlock[]
	) {}

	public addConditions(...conditions: QueryBuilderCondition[]): QueryBuilderConditionBlock {
		conditions = conditions.filter(item => !!item);
		this.Conditions = this.Conditions || [];
		this.Conditions.push(...conditions);
		return this;
	}

	public addChildCondition(...conditions: QueryBuilderConditionBlock[]): QueryBuilderConditionBlock {
		conditions = conditions.filter(item => !!item);
		this.ChildConditions = this.ChildConditions || [];
		this.ChildConditions.push(...conditions);
		return this;
	}
}
