import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-campaign-facebook-reg',
	templateUrl: './campaign-facebook-reg.component.html',
	styleUrls: ['./campaign-facebook-reg.component.scss']
})
export class CampaignFacebookRegComponent implements OnInit {
	public isFacebookAppRegistered = false;
	@Input() public isPanelSelected: boolean;
	@Output() public onExpansionClick = new EventEmitter();
	@Output() public onPanelClick = new EventEmitter();
	public appRegisterControl: FormControl = new FormControl('');
	constructor() {}

	public ngOnInit(): void {}

	public checkAppRegistered(): void {
		this.isFacebookAppRegistered = !this.isFacebookAppRegistered;
	}
}
