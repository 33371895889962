import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FiledPermissionCategory } from '../../shared/models/permission';
import { UserManagementActions, UserManagementTypes } from './user-management.actions';
import _ from 'lodash';
import { AdAccountFormData } from '../models/account';
import {
	AccountStateModel,
	BillingStateModel,
	MiscStateModel,
	PermissionStateModel,
	SubscriptionStateModel,
	UsersStateModel,
	UserStateModel,
	DisableUserModel
} from './state.model';
import {
	AccountInitialState,
	BillingInitialState,
	MiscInitialState,
	PermissionsInitialState,
	SubscriptionInitialState,
	UserInitialState,
	UserPlanInnitialState,
	UsersInitialState,
	PauseUserAccountInitState,
	setAdminManagementInitState
} from './state.initial';
import { User } from '../../_models/user-models/user';
import { AnyFn } from '@ngrx/store/src/selector';
import { AnySrvRecord } from 'dns';

export interface userPaymentPlan {
	tarrif: any;
	duration: string;
	paymentCard: any;
}
export interface UserManagementState {
	miscellaneous: MiscStateModel;
	permissions: PermissionStateModel;
	user: UserStateModel;
	billing: BillingStateModel;
	subscriptions: SubscriptionStateModel;
	users: UsersStateModel;
	account: AccountStateModel;
	userPlan: userPaymentPlan;
	PauseUserAccount: DisableUserModel;
	setAdminManagement: any;
}

export const initialUserManagementState: UserManagementState = {
	permissions: PermissionsInitialState,
	miscellaneous: MiscInitialState,
	user: UserInitialState,
	billing: BillingInitialState,
	subscriptions: SubscriptionInitialState,
	users: UsersInitialState,
	account: AccountInitialState,
	userPlan: UserPlanInnitialState,
	PauseUserAccount: PauseUserAccountInitState,
	setAdminManagement: setAdminManagementInitState
};

const getUserManagementState = createFeatureSelector<UserManagementState>('userManagement');

// Permissions

export const getKeysForAllPermissionsState = createSelector(getUserManagementState, state => state.permissions.keysPermissions.data);
export const getAllPermissionsState = createSelector(getUserManagementState, state => state.permissions.allPermissions);
export const getGeneralPermissionsState = createSelector(getUserManagementState, state => state.permissions.generalPermissions);
export const getAdsAndPagesPermissionsState = createSelector(getUserManagementState, state => state.permissions.adsAndPagesPermissions);
export const getInstagramPermissionsState = createSelector(getUserManagementState, state => state.permissions.instagramPermissions);
export const getTouchedPermission = createSelector(getUserManagementState, state => state.permissions.permissionsStatus.touched);

export const getDeletedPermissions = createSelector(getAllPermissionsState, permissionsState => {
	let deletedPermissions: string[] = [];

	permissionsState.map(permission => {
		if (!permission.isChecked) {
			deletedPermissions.push(permission.title);
		}
	});

	return deletedPermissions.join(',');
});

// Miscellaneous
export const getBusinesses = createSelector(getUserManagementState, state => state.miscellaneous.businesses);

// Miscellaneous -> Navigation
export const getNavigationUserManagementState = createSelector(getUserManagementState, state => state.miscellaneous.navigation.userManagement);
//Get the new nav sprint
export const getNewNavigationUserManagementState = createSelector(getUserManagementState, state => state.miscellaneous.navigation.newUserManagement);

export const getNavigationBillingState = createSelector(getUserManagementState, state => state.miscellaneous.navigation.billingAndPayment);
export const getActiveUserManagementPage = createSelector(getUserManagementState, state => state.miscellaneous.navigation.activeUserManagementPage);
export const getActiveUserPage = createSelector(getUserManagementState, state => state.miscellaneous.navigation.activeUserPage);

export const getActiveBillingPageUserManagement = createSelector(getUserManagementState, state => state.miscellaneous.navigation.currentBillingPageIndex);

// User
export const getUserProfileUserManagement = createSelector(getUserManagementState, state => state.user.profile.data);

// Billing
export const getCards = createSelector(getUserManagementState, state => state.billing.cards.data);
export const getEditingCardUserManagementPageStatus = createSelector(getUserManagementState, state => state.billing.editCardPageStatus);
export const getSelectedCardId = createSelector(getUserManagementState, state => state.billing.selectedCardId);
export const getBillingAccountInfo = createSelector(getUserManagementState, state => state.billing.billingAccountInfo.data);
export const getInvoiceHistory = createSelector(getUserManagementState, state => state.billing.invoiceHistory.data);

// Subscriptions

export const getSubscriptionsReasons = createSelector(getUserManagementState, state => state.subscriptions.contact.reasons);
export const getSubscriptionsAnswers = createSelector(getUserManagementState, state => state.subscriptions.contact.answers);
export const getInvoices = createSelector(getUserManagementState, state => state.subscriptions.invoices.data);

// Users

export const getUsersStatus = createSelector(getUserManagementState, state => state.users.status);
export const getUsersState = createSelector(getUserManagementState, state => state.users);
export const getUsers = createSelector(getUserManagementState, state => state.users.usersData.data);
export const getFacebookBusinessRoles = createSelector(getUserManagementState, state => state.users.facebookBusinessRoles.data);

// My account

export const getAccount = createSelector(getUserManagementState, state => state.account);
export const getFacebookPermissions = createSelector(getUserManagementState, state => state.account.addEditUser.secondStepData.adAccountPermissionArray);
export const getSelectedUser = createSelector(getUserManagementState, state => state.account.selectedUser.data);
export const getSelectedRoles = createSelector(getUserManagementState, state => state.account.addEditUser.secondStepData.selectedAccessLevelIds);
export const getSelectedBusiness = createSelector(getUserManagementState, state => state.account.addEditUser.secondStepData.selectedBusinessIds);
export const getFiledUser = createSelector(getUserManagementState, state => state.user.profile.data);

export const getUserPlanState = createSelector(getUserManagementState, state => state.userPlan);
export const getDisableUserState = createSelector(getUserManagementState, state => state.PauseUserAccount);
export const getUserAdminState = createSelector(getUserManagementState, state => state.setAdminManagement);

export function userManagementReducer(state: UserManagementState = initialUserManagementState, action: UserManagementActions): UserManagementState {
	switch (action.type) {
		// Permissions
		case UserManagementTypes.togglePermissionCheckByTitle:
			const updatedAllPermissions = _.cloneDeep(state.permissions.allPermissions);
			const objIndex = updatedAllPermissions.findIndex(permission => permission.title === action.payload);
			updatedAllPermissions[objIndex].isChecked = action.value || !updatedAllPermissions[objIndex].isChecked;
			return {
				...state,
				permissions: {
					...state.permissions,
					allPermissions: updatedAllPermissions
				}
			};

		case UserManagementTypes.setGeneralPermissions:
			const updatedGeneralPermissions = action.payload.filter(permission => permission.filedPermisionCategoryId === FiledPermissionCategory.General);
			return {
				...state,
				permissions: {
					...state.permissions,
					generalPermissions: updatedGeneralPermissions
				}
			};

		case UserManagementTypes.setAdsAndPagesPermissions:
			const updatedAdsAndPagesPermissions = action.payload.filter(
				permission => permission.filedPermisionCategoryId === FiledPermissionCategory.AdsAndPages
			);
			return {
				...state,
				permissions: {
					...state.permissions,
					adsAndPagesPermissions: updatedAdsAndPagesPermissions
				}
			};

		case UserManagementTypes.setInstagramPermissions:
			const updatedInstagramPermissions = action.payload.filter(permission => permission.filedPermisionCategoryId === FiledPermissionCategory.Instagram);
			return {
				...state,
				permissions: {
					...state.permissions,
					instagramPermissions: updatedInstagramPermissions
				}
			};
		case UserManagementTypes.setTouchedPermission:
			return {
				...state,
				permissions: {
					...state.permissions,
					permissionsStatus: {
						touched: action.payload
					}
				}
			};
		case UserManagementTypes.loadSelectedKeysPermissions:
			return {
				...state,
				permissions: {
					...state.permissions,
					keysPermissions: {
						...state.permissions.keysPermissions,
						isLoaded: true
					}
				}
			};
		case UserManagementTypes.loadSelectedKeysPermissionsSuccess:
			return {
				...state,
				permissions: {
					...state.permissions,
					keysPermissions: {
						...state.permissions.keysPermissions,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.loadSelectedKeysPermissionsFailure:
			return {
				...state,
				permissions: {
					...state.permissions,
					keysPermissions: {
						...state.permissions.keysPermissions,
						errorCode: action.payload
					}
				}
			};
		// Miscellaneous -> Navigation
		case UserManagementTypes.activeUserManagementPage:
			return {
				...state,
				miscellaneous: {
					navigation: {
						...state.miscellaneous.navigation,
						activeUserManagementPage: action.payload
					},
					businesses: state.miscellaneous.businesses || []
				}
			};
		case UserManagementTypes.setActiveBillingPageUserManagement:
			return {
				...state,
				miscellaneous: {
					navigation: {
						...state.miscellaneous.navigation,
						currentBillingPageIndex: action.payload
					},
					businesses: state.miscellaneous.businesses || []
				}
			};
		case UserManagementTypes.setActiveUsersPage:
			return {
				...state,
				miscellaneous: {
					navigation: {
						...state.miscellaneous.navigation,
						activeUserPage: action.payload
					},
					businesses: state.miscellaneous.businesses || []
				}
			};
		case UserManagementTypes.setBusinesses:
			return {
				...state,
				miscellaneous: {
					navigation: { ...state.miscellaneous.navigation },
					businesses: action.payload
				}
			};
		// User
		case UserManagementTypes.updateUserPicture:
			return {
				...state,
				user: {
					...state.user,
					profile: {
						...state.user.profile,
						isLoaded: true
					}
				}
			};
		case UserManagementTypes.updateUserPictureSuccess:
			return {
				...state,
				user: {
					...state.user,
					profile: {
						...state.user.profile,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.updateUserPictureFailure:
			return {
				...state,
				user: {
					...state.user,
					profile: {
						...state.user.profile,
						errorCode: action.payload
					}
				}
			};
		case UserManagementTypes.setUserProfileUserManagement:
			return {
				...state,
				user: {
					profile: {
						...state.user.profile,
						isLoaded: true,
						data: action.payload as User
					},
					editImage: state.user.editImage
				}
			};
		case UserManagementTypes.setUserProfileUserManagementFailure:
			return {
				...state,
				user: {
					profile: {
						...state.user.profile,
						errorCode: action.payload as string
					},
					editImage: state.user.editImage
				}
			};
		case UserManagementTypes.setUserEditImageUserManagement:
			return {
				...state,
				user: {
					profile: state.user.profile,
					editImage: action.payload
				}
			};

		// Billing
		case UserManagementTypes.loadInvoices:
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					invoices: {
						...state.subscriptions.invoices,
						isLoaded: true
					}
				}
			};
		case UserManagementTypes.loadInvoicesSuccess:
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					invoices: {
						...state.subscriptions.invoices,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.loadInvoicesFailure:
			return {
				...state,
				subscriptions: {
					...state.subscriptions,
					invoices: {
						...state.subscriptions.invoices,
						errorCode: action.payload
					}
				}
			};
		// Billing -> Edit page status
		case UserManagementTypes.setResetStatusForEditPage:
			return {
				...state,
				billing: {
					...state.billing,
					editCardPageStatus: {
						...state.billing.editCardPageStatus,
						reset: action.payload
					}
				}
			};
		case UserManagementTypes.setSubmitStatusForEditPage:
			return {
				...state,
				billing: {
					...state.billing,
					editCardPageStatus: {
						...state.billing.editCardPageStatus,
						submit: action.payload
					}
				}
			};
		case UserManagementTypes.setTouchedStatusForEditPage:
			return {
				...state,
				billing: {
					...state.billing,
					editCardPageStatus: {
						...state.billing.editCardPageStatus,
						touched: action.payload
					}
				}
			};
		case UserManagementTypes.setErrorsStatusForEditPage:
			return {
				...state,
				billing: {
					...state.billing,
					editCardPageStatus: {
						...state.billing.editCardPageStatus,
						errors: action.payload
					}
				}
			};
		case UserManagementTypes.makePrimaryCard: {
			return {
				...state,
				billing: {
					...state.billing,
					cards: {
						...state.billing.cards,
						isLoaded: true
					}
				}
			};
		}
		case UserManagementTypes.makePrimaryCardSuccess: {
			const newCardsArray = _.cloneDeep(state.billing.cards.data);
			newCardsArray.map(card => {
				card.isDefault = card.cardId === action.payload;
			});
			return {
				...state,
				billing: {
					...state.billing,
					cards: {
						...state.billing.cards,
						data: newCardsArray
					}
				}
			};
		}
		case UserManagementTypes.makePrimaryCardFailure: {
			return {
				...state,
				billing: {
					...state.billing,
					cards: {
						...state.billing.cards,
						errorCode: action.payload
					}
				}
			};
		}
		case UserManagementTypes.addCard: {
			const newCardsArray = _.cloneDeep(state.billing.cards.data);

			if (!newCardsArray || !newCardsArray.length) {
				return state;
			}

			if (action && action.payload) {
				newCardsArray.unshift(action.payload);
			}

			return {
				...state,
				billing: {
					...state.billing,
					cards: {
						...state.billing.cards,
						data: newCardsArray
					}
				}
			};
		}
		case UserManagementTypes.setValidStatusForEditPage:
			return {
				...state,
				billing: {
					...state.billing,
					editCardPageStatus: {
						...state.billing.editCardPageStatus,
						valided: action.payload
					}
				}
			};
		case UserManagementTypes.setNextStatusForEditPage:
			return {
				...state,
				billing: {
					...state.billing,
					editCardPageStatus: {
						...state.billing.editCardPageStatus,
						next: action.payload
					}
				}
			};
		case UserManagementTypes.setCheckStatusForEditPage:
			return {
				...state,
				billing: {
					...state.billing,
					editCardPageStatus: {
						...state.billing.editCardPageStatus,
						check: action.payload
					}
				}
			};
		case UserManagementTypes.setSelectedCardId:
			return {
				...state,
				billing: {
					...state.billing,
					selectedCardId: action.payload
				}
			};
		case UserManagementTypes.loadCards:
			return {
				...state,
				billing: {
					...state.billing,
					cards: {
						...state.billing.cards,
						isLoaded: true
					}
				}
			};
		case UserManagementTypes.loadCardsSuccess:
			return {
				...state,
				billing: {
					...state.billing,
					cards: {
						...state.billing.cards,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.loadCardsFailure:
			return {
				...state,
				billing: {
					...state.billing,
					cards: {
						...state.billing.cards,
						errorCode: action.payload
					}
				}
			};

		// Users

		case UserManagementTypes.deleteUserById:
			const usersArr = _.cloneDeep(state.users.usersData.data).filter(user => {
				return user.id !== action.payload;
			});

			return {
				...state,
				users: {
					...state.users,
					usersData: {
						...state.users.usersData,
						data: usersArr
					}
				}
			};
		case UserManagementTypes.addFiledUser:
			const users = state.users.usersData.data;
			users.push(action.payload);
			return {
				...state,
				users: {
					...state.users,
					usersData: {
						...state.users.usersData,
						data: users
					}
				}
			};
		case UserManagementTypes.setRefreshStatusUsers:
			return {
				...state,
				users: {
					...state.users,
					status: {
						refresh: action.payload
					}
				}
			};
		case UserManagementTypes.setFacebookBusinessRoles:
			return {
				...state,
				users: {
					...state.users,
					facebookBusinessRoles: {
						...state.users.facebookBusinessRoles,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.setFacebookAdAccountRoles:
			return {
				...state,
				users: {
					...state.users,
					facebookAdAccountRoles: {
						...state.users.facebookAdAccountRoles,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.LoadUsers:
			return {
				...state,
				users: {
					...state.users,
					usersData: { ...state.users.usersData, isLoaded: true }
				}
			};
		case UserManagementTypes.LoadUsersSuccess:
			return {
				...state,
				users: {
					...state.users,
					usersData: { ...state.users.usersData, data: action.payload }
				}
			};
		case UserManagementTypes.LoadUsersFailure:
			return {
				...state,
				users: {
					...state.users,
					usersData: { ...state.users.usersData, errorCode: action.payload }
				}
			};
		case UserManagementTypes.setGoogleAdAccountRoles:
			return {
				...state,
				users: {
					...state.users,
					googleAdAccountRoles: {
						...state.users.googleAdAccountRoles,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.setAccountEditState:
			return {
				...state,
				account: {
					...state.account,
					myAccount: {
						state: {
							...state.account.myAccount.state,
							edit: action.payload
						}
					}
				}
			};
		case UserManagementTypes.setAccountSubmitState:
			return {
				...state,
				account: {
					...state.account,
					myAccount: {
						state: {
							...state.account.myAccount.state,
							submit: action.payload
						}
					}
				}
			};
		case UserManagementTypes.setUpdatedUser:
			return {
				...state,
				account: {
					...state.account,
					selectedUser: {
						...state.account.selectedUser,
						isLoaded: true,
						data: action.payload
					}
				}
			};
		case UserManagementTypes.setUpdatedUserFailure:
			return {
				...state,
				account: {
					...state.account,
					selectedUser: {
						...state.account.selectedUser,
						errorCode: action.payload
					}
				}
			};
		case UserManagementTypes.setFirstStepData:
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						firstStepData: action.payload
					}
				}
			};
		case UserManagementTypes.setAddEditEditStatus:
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						status: {
							...state.account.addEditUser.status,
							next: action.payload
						}
					}
				}
			};
		case UserManagementTypes.setAddEditSubmitStatus:
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						status: {
							...state.account.addEditUser.status,
							submit: action.payload
						}
					}
				}
			};
		case UserManagementTypes.removeAllSelectedAdAccount:
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							adAccountPermissionArray: []
						}
					}
				}
			};
		case UserManagementTypes.addAdAccountData:
			const _adAccountPermissionArray = _.cloneDeep(state.account.addEditUser.secondStepData.adAccountPermissionArray) || [];
			const formData = {
				id: _adAccountPermissionArray.length,
				name: action.payload ? action.payload.name : '',
				level: action.payload ? action.payload.level : -1
			};
			_adAccountPermissionArray.push(formData);
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							adAccountPermissionArray: _adAccountPermissionArray
						}
					}
				}
			};
		case UserManagementTypes.updateAdAccountData:
			const updateAdAccountPermissionArray = _.cloneDeep(state.account.addEditUser.secondStepData.adAccountPermissionArray) || [];
			let found = false;
			updateAdAccountPermissionArray.map(adAccount => {
				if (action.payload.oldValue === adAccount.name && !found) {
					adAccount.level = action.payload.formData.level;
					adAccount.name = action.payload.formData.name;
					found = true;
				}
			});

			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							adAccountPermissionArray: updateAdAccountPermissionArray
						}
					}
				}
			};
		case UserManagementTypes.removeBusinessByIndex:
			const newBusinessArray = [...state.account.addEditUser.secondStepData.selectedBusinessIds];
			newBusinessArray.splice(action.payload, 1);
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedBusinessIds: newBusinessArray
						}
					}
				}
			};
		case UserManagementTypes.removeAllSelectedBusinesses:
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedBusinessIds: []
						}
					}
				}
			};
		case UserManagementTypes.addSelectedBusinessId:
			let selectedBusinessId = [...state.account.addEditUser.secondStepData.selectedBusinessIds];
			if (Number(action.payload)) {
				selectedBusinessId.push(Number(action.payload));
			}
			selectedBusinessId = [
				...new Set(
					selectedBusinessId.filter(businessId => {
						return businessId !== null;
					})
				)
			];
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedBusinessIds: selectedBusinessId
						}
					}
				}
			};
		case UserManagementTypes.updateSelectedBusinessId:
			let selectedBusinessIds = [...state.account.addEditUser.secondStepData.selectedBusinessIds];
			let index = -1;
			const newBusinessId = Number(action.data);
			selectedBusinessIds.find((businessId, i) => {
				if (businessId === action.payload) {
					index = i;
				}
			});
			if (index > -1) {
				selectedBusinessIds[index] = newBusinessId;
			}

			selectedBusinessIds = [
				...new Set(
					selectedBusinessIds.filter(businessId => {
						return businessId !== null;
					})
				)
			];
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedBusinessIds: selectedBusinessIds
						}
					}
				}
			};
		case UserManagementTypes.removeSelectedAdAccount:
			let newArray: AdAccountFormData[] = [];
			let removedAdAccountIndex = 0;

			if (action.payload) {
				newArray = _.cloneDeep(state.account.addEditUser.secondStepData.adAccountPermissionArray) || [];
				if (newArray && newArray.length > 0) {
					newArray = newArray.filter((adAccount, index) => {
						if (adAccount.name !== action.payload) {
							removedAdAccountIndex = index;
						}
						return adAccount.name !== action.payload;
					});
				}
			}
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							adAccountPermissionArray: newArray
						}
					}
				}
			};
		case UserManagementTypes.removeSelectedAdAccountByBusiness:
			let oldAdAccounts: AdAccountFormData[] = [];
			let newAdAccounts: AdAccountFormData[] = [];
			oldAdAccounts = _.cloneDeep(state.account.addEditUser.secondStepData.adAccountPermissionArray) || [];
			oldAdAccounts.forEach(oldAdAccount => {
				let found = false;
				action.payload.forEach(adAccount => {
					if (adAccount.value === oldAdAccount.name && !found) {
						found = true;
					}
				});
				if (!found) {
					newAdAccounts.push(oldAdAccount);
				}
			});
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							adAccountPermissionArray: newAdAccounts
						}
					}
				}
			};
		case UserManagementTypes.removeSelectedAccesByIndex:
			const selectedAccess = [...state.account.addEditUser.secondStepData.selectedAccessLevelIds];
			selectedAccess.splice(action.payload, 1);
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedAccessLevelIds: selectedAccess
						}
					}
				}
			};
		case UserManagementTypes.removeAllSelectedAccesLevelId:
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedAccessLevelIds: []
						}
					}
				}
			};
		case UserManagementTypes.addSelectedAccesLevelId:
			let newArrayselectedAccesLevelId = [...state.account.addEditUser.secondStepData.selectedAccessLevelIds];
			newArrayselectedAccesLevelId.push(action.payload);
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedAccessLevelIds: newArrayselectedAccesLevelId
						}
					}
				}
			};
		case UserManagementTypes.updateSelectedAccesLevelId:
			let newArrayselectedAccesLevelId2 = [...state.account.addEditUser.secondStepData.selectedAccessLevelIds];
			newArrayselectedAccesLevelId2[action.payload.index] = action.payload.value;
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							selectedAccessLevelIds: newArrayselectedAccesLevelId2
						}
					}
				}
			};
		case UserManagementTypes.removeAllRadioValues:
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							radioButtonValues: []
						}
					}
				}
			};
		case UserManagementTypes.removeRadioValueByIndex:
			const newRValue = [...state.account.addEditUser.secondStepData.radioButtonValues];
			newRValue.splice(action.payload, 1);
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							radioButtonValues: newRValue
						}
					}
				}
			};
		case UserManagementTypes.addRadioButtonValue:
			const rValues = [...state.account.addEditUser.secondStepData.radioButtonValues];
			rValues.push(action.payload);
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							radioButtonValues: rValues
						}
					}
				}
			};
		case UserManagementTypes.updateRadioButtonValue:
			const _rValues = [...state.account.addEditUser.secondStepData.radioButtonValues];
			_rValues[action.payload.index] = action.payload.value;
			return {
				...state,
				account: {
					...state.account,
					addEditUser: {
						...state.account.addEditUser,
						secondStepData: {
							...state.account.addEditUser.secondStepData,
							radioButtonValues: _rValues
						}
					}
				}
			};

		case UserManagementTypes.loadBillingAccountInfo:
			return {
				...state,
				billing: {
					...state.billing,
					billingAccountInfo: {
						...state.billing.billingAccountInfo,
						isLoaded: true
					}
				}
			};

		case UserManagementTypes.loadBillingAccountInfoSuccess:
			return {
				...state,
				billing: {
					...state.billing,
					billingAccountInfo: {
						...state.billing.billingAccountInfo,
						data: action.payload
					}
				}
			};

		case UserManagementTypes.loadBillingAccountInfoFailure:
			return {
				...state,
				billing: {
					...state.billing,
					billingAccountInfo: {
						...state.billing.billingAccountInfo,
						errorCode: action.payload
					}
				}
			};

		case UserManagementTypes.setInvoiceHistory:
			return {
				...state,
				billing: {
					...state.billing,
					invoiceHistory: {
						...state.billing.invoiceHistory,
						data: action.payload
					}
				}
			};

		case UserManagementTypes.setUserPlan:
			return {
				...state,
				userPlan: {
					...state.userPlan,
					...action.payload
				}
			};
		case UserManagementTypes.PauseUserAccount:
			return {
				...state,
				PauseUserAccount: {
					...state.PauseUserAccount,
					...action.payload
				}
			};
		case UserManagementTypes.SetAdminManagement:
			return {
				...state,
				setAdminManagement: {
					...state.setAdminManagement,
					...action.payload
				}
			};
		default:
			return state;
	}
}
