export const DefaultChartValues = {
	Heat: [
		{ value: [0, 0, 5] },
		{ value: [0, 1, 1] },
		{ value: [0, 2, 2] },
		{ value: [0, 3, 0] },
		{ value: [0, 4, 3] },
		{ value: [0, 5, 0] },
		{ value: [0, 6, 5] },
		{ value: [0, 7, 0] },
		{ value: [1, 0, 7] },
		{ value: [1, 1, 0] },
		{ value: [1, 2, 0] },
		{ value: [1, 3, 2] },
		{ value: [1, 4, 0] },
		{ value: [1, 5, 7] },
		{ value: [1, 6, 0] },
		{ value: [1, 7, 5] },
		{ value: [2, 0, 1] },
		{ value: [2, 1, 1] },
		{ value: [2, 2, 0] },
		{ value: [2, 3, 2] },
		{ value: [2, 4, 3] },
		{ value: [2, 5, 0] },
		{ value: [2, 6, 0] },
		{ value: [2, 7, 0] },
		{ value: [3, 0, 7] },
		{ value: [3, 1, 3] },
		{ value: [3, 2, 5] },
		{ value: [3, 3, 0] },
		{ value: [3, 4, 6] },
		{ value: [3, 5, 0] },
		{ value: [3, 6, 4] },
		{ value: [3, 7, 0] },
		{ value: [4, 0, 1] },
		{ value: [4, 1, 3] },
		{ value: [4, 2, 0] },
		{ value: [4, 3, 4] },
		{ value: [4, 4, 0] },
		{ value: [4, 5, 1] },
		{ value: [4, 6, 3] },
		{ value: [4, 7, 1] }
	],
	line: {
		xAxisLabels: ['First', 'Second', 'Third', 'Fourth'],
		series: [
			{
				type: 'line',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'line',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'line',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			}
		]
	},
	Area: {
		xAxisLabels: ['First', 'Second', 'Third', 'Fourth'],
		series: [
			{
				type: 'line',
				stack: 'stack',
				name: 'First',
				areaStyle: {},
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'line',
				stack: 'stack',
				name: 'Second',
				areaStyle: {},
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'line',
				stack: 'stack',
				name: 'Third',
				areaStyle: {},
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			}
		]
	},
	bar: {
		defaultAxisValues: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Label 6', 'Label 7'],
		series: [10, 52, 200, 334, 390, 330, 220]
	},
	HorizontalBar: {
		defaultAxisValues: ['Label 1', 'Label 2', 'Label 3', 'Label 4', 'Label 5', 'Label 6', 'Label 7'],
		series: [10, 52, 200, 334, 390, 330, 220]
	},
	VerticalStackedBar: {
		xAxisLabels: ['First', 'Second', 'Third', 'Fourth'],
		series: [
			{
				stack: 'stack',
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				stack: 'stack',
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				stack: 'stack',
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			}
		]
	},
	HorizontalStackedBar: {
		xAxisLabels: ['First', 'Second', 'Third', 'Forth'],
		series: [
			{
				stack: 'stack',
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				stack: 'stack',
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				stack: 'stack',
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			}
		]
	},
	GroupedVerticalBar: {
		xAxisLabels: ['First', 'Second', 'Third', 'Forth'],
		series: [
			{
				type: 'bar',
				name: 'First',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'bar',
				name: 'Second',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'bar',
				name: 'Third',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			}
		]
	},
	GroupedHorizontalBar: {
		xAxisLabels: ['First', 'Second', 'Third', 'Forth'],
		series: [
			{
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			},
			{
				type: 'bar',
				data: [
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200)),
					Math.floor(Math.random() * Math.floor(200))
				]
			}
		]
	},
	Pie: [
		{ value: 335, name: 'First' },
		{ value: 310, name: 'Second' },
		{ value: 234, name: 'Third' },
		{ value: 135, name: 'Forth' },
		{ value: 1548, name: 'Fifth' }
	],
	Scatter: {
		defaultAxisValues: ['label 1', 'Label 2', 'Label 3', 'label 4', 'Label 5'],
		series: [
			[10.0, 8.04],
			[8.0, 6.95],
			[13.0, 7.58],
			[9.0, 8.81],
			[11.0, 8.33]
		]
	},
	Table: [
		{ Name: 'Campaign name', Impressions: '2278', Clicks: '302', Reach: '3278' },
		{ Name: 'Campaign name', Impressions: '33782', Clicks: '135', Reach: '3242' },
		{ Name: 'Campaign name', Impressions: '5323', Clicks: '763', Reach: '3293' },
		{ Name: 'Campaign name', Impressions: '64223', Clicks: '763', Reach: '2333' },
		{ Name: 'Campaign name', Impressions: '4378', Clicks: '763', Reach: '2333' },
		{ Name: 'Campaign name', Impressions: '31833', Clicks: '763', Reach: '2333' },
		{ Name: 'Campaign name', Impressions: '292', Clicks: '190', Reach: '232' }
	]
};
