import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	getActiveBillingPageUserManagement,
	getActiveUserPage,
	getNavigationUserManagementState,
	UserManagementState
} from '../../state/user-management.reducer';
import * as userManagementActions from '../../state/user-management.actions';
import { GuardNavLinks } from '../../models/nav-link';
import { getFiledId, getPermissions, UserState } from '../../../shared/state/user/user.reducer';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SettingsPageLabelsEnum } from '../enums/navigation.enum';
import { Router } from '@angular/router';
import { FiledPermissions, ModulesPermissions } from '../../../shared/permisions/enums/filed-permissions';
import { Modules } from '../../../shared/permisions/enums/modules';
import { MiscellaneousPermissions } from '../../../shared/permisions/enums/miscellaneous-permissions';
import { BackOfficeService } from '../../../_services/back-office/back-office.service';
import { UserStateEnum } from '../../../_services/user/user-state.enum';
import { SettingsPermissions } from 'src/app/shared/permisions/enums/settings-permissions';

@Component({
	selector: 'app-user-management-header',
	templateUrl: './user-management-header.component.html',
	styleUrls: ['./user-management-header.component.scss']
})
export class UserManagementHeaderComponent {
	public navLinks: GuardNavLinks[];
	public selectedTabName: string;

	private billingPageIndex: number;
	private userPageStatus: boolean;
	private allPermissions: FiledPermissions<ModulesPermissions>[];

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private store: Store<UserManagementState>,
		private router: Router,
		private userStore: Store<UserState>,
		private backOfficeService: BackOfficeService
	) {
		this.store.dispatch(new userManagementActions.ToggleActiveUserManagementPage(true));
	}

	ngOnInit() {
		this.fetchStore();
	}

	ngOnDestroy() {
		this.store.dispatch(new userManagementActions.ToggleActiveUserManagementPage(false));

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public checkActive(selectedTabName: string, label: string): boolean {
		if (this.userPageStatus && label === SettingsPageLabelsEnum.UserManagement) {
			return true;
		}

		if (label === SettingsPageLabelsEnum.MySubscription && this.router.url.includes('subscription-question')) {
			return true;
		}

		if (this.billingPageIndex && this.billingPageIndex >= 0 && label === SettingsPageLabelsEnum.BillingAndPayment) {
			return true;
		}
		return selectedTabName === label;
	}

	private fetchStore(): void {
		this.store.pipe(select(getPermissions), take(1)).subscribe(roles => {
			this.allPermissions = roles;
		});

		this.store.pipe(select(getNavigationUserManagementState), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.userStore.pipe(select(getFiledId), take(1)).subscribe(filedId => {
				this.backOfficeService
					.getUserState(filedId)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(
						accountState => {
							if (accountState === UserStateEnum.BillPendingRestrictedAccess) {
								this.navLinks = resp.filter((link: GuardNavLinks) => {
									let displayRoute = false;
									if (link.label === SettingsPageLabelsEnum.BillingAndPayment) {
										displayRoute = true;
									}
									return displayRoute;
								});
							} else {
								// TEMP: hide user management tab
								const filterOutUserMgt = resp.filter(item => {
									// return item.link !== '/user-management/users';
									return item;
								});

								this.navLinks = filterOutUserMgt.filter((link: GuardNavLinks) => {
									let displayRoute = false;
									if (!link.guard) {
										displayRoute = true;
									} else {
										this.allPermissions
											.filter(module => module.module === Modules.Miscellaneous)
											.forEach(module => {
												module.permissions.forEach(singlePermission => {
													if (
														singlePermission === MiscellaneousPermissions.IsBusinessOwner ||
														singlePermission === MiscellaneousPermissions.IsFreemium
													) {
														displayRoute = true;
														if (
															singlePermission === MiscellaneousPermissions.IsFreemium &&
															link.label === SettingsPageLabelsEnum.UserManagement
														) {
															displayRoute = false;
														}
													}
												});
											});
									}

									return displayRoute;
								});
							}
						},
						() => {
							// TEMP: hide user management tab
							const filterOutUserMgt = resp.filter(item => {
								// return item.link !== '/user-management/users';
								return item;
							});

							this.navLinks = filterOutUserMgt.filter((link: GuardNavLinks) => {
								let displayRoute = false;
								if (!link.guard) {
									displayRoute = true;
								} else {
									this.allPermissions
										.filter(module => module.module === Modules.Settings)
										.forEach(module => {
											module.permissions.forEach(singlePermission => {
												if (
													singlePermission === SettingsPermissions.SettingsMyAccountView &&
													link.label === SettingsPageLabelsEnum.MyAccount
												) {
													displayRoute = true;
												}
												if (
													singlePermission === SettingsPermissions.SettingsChangePasswordView &&
													link.label === SettingsPageLabelsEnum.ChangePassword
												) {
													displayRoute = true;
												}
												if (
													singlePermission === SettingsPermissions.SettingsUserManagementView &&
													link.label === SettingsPageLabelsEnum.UserManagement
												) {
													displayRoute = true;
												}
											});
										});
								}

								return displayRoute;
							});
							console.log('navs ', this.navLinks);
						}
					);
			});
		});

		this.store.pipe(select(getActiveBillingPageUserManagement), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.billingPageIndex = resp;
		});

		this.store.pipe(select(getActiveUserPage), takeUntil(this.unsubscriber$)).subscribe(resp => {
			this.userPageStatus = resp;
		});
	}
}
