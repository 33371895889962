import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { StatefulImage } from './stateful-image';
import { ImageStateEnum } from './image-state.enum';

@Directive({
	selector: '[appHoverSelect]'
})
export class HoverSelectDirective {
	@Input() public statefulImage: StatefulImage;
	@Input() public statefulImages: StatefulImage[];

	constructor() {}

	@HostListener('mouseover') public onMouseOver(): void {
		if (this.statefulImage.imageState !== ImageStateEnum.Pressed && this.statefulImage.imageState !== ImageStateEnum.Disabled) {
			this.statefulImage.imageState = ImageStateEnum.Hover;
		}
	}

	@HostListener('mouseout') public onMouseOut(): void {
		if (this.statefulImage.imageState !== ImageStateEnum.Pressed && this.statefulImage.imageState !== ImageStateEnum.Disabled) {
			this.statefulImage.imageState = ImageStateEnum.Default;
		}
	}

	@HostListener('click') public onClick(): void {
		if (this.statefulImage.imageState !== ImageStateEnum.Disabled) {
			this.statefulImages.forEach(item => (item.imageState = ImageStateEnum.Default));
			this.statefulImage.imageState = ImageStateEnum.Pressed;
		}
	}
}
