<div class="form-input" id="input-form-component">
	<kendo-formfield>
		<kendo-textbox
			placeholder="'Enter text'"
			[disabled]="disabled"
			[readonly]="true"
			[formControl]="control"
			[showSuccessIcon]="control.valid"
			[showErrorIcon]="'initial'"
			[style.width]="'100%'"
		></kendo-textbox>
	</kendo-formfield>
</div>
