<div class="outside-trial-starts">
	<img src="assets/images/free-trial.svg" />
	<h4>Your {{ this.days }}-day Free Trial starts now</h4>
	<p *ngIf="!hasPaymentCard">
		The free trial allows you to have full access to all our services for {{ this.days }} days straight. When this expires, you will no longer be able to
		use our services. To continue using Lolly, you will need to add a primary payment card.
	</p>
	<p *ngIf="hasPaymentCard">
		The free trial allows you to have full access to all our services for {{ this.days }} days straight. When this expires, your card will be charged.
		Cancel anytime before the end date and you won’t be charged.
	</p>
	<div class="get-started-btn">
		<btn01 (onClick)="close()">Get Started</btn01>
	</div>
</div>
