import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-card-box',
	templateUrl: './card-box.component.html',
	styleUrls: ['./card-box.component.scss']
})
export class CardBoxComponent implements OnInit {
	@Input() public isSelected: boolean;
	@Input() public isDisabled: boolean;
	@Input() public isInvalid: boolean;

	constructor() {}

	public ngOnInit(): void {}
}
