<div class="grid-section">
	<div class="container">
		<div class="header">
			<div class="logo"><img src="assets/logo/lolly-white.svg" /></div>
			<div class="navigation"></div>
			<div class="buttons">
				<ul class="menus">
					<li (click)="goToSignin()">
						<a class="btn btn-filled-orange-red"><span>Sign In</span></a>
					</li>
					<li>
						<a class="btn btn-outline" href="https://www.filed.com/contact-us-filed/"><span>Talk to Sales</span></a>
					</li>
				</ul>
			</div>
		</div>

		<div class="content">
			<div class="main-title">
				Choose the product that's right for you.
			</div>
			<div class="container-with-bottons">
				<div class="main-sub-title">Pay by month or the year, and cancel at anytime.</div>
				<div class="action-button">
					<ul class="menus">
						<li>
							<a
								class="btn btn-duration-filled-orange-red"
								(click)="onSelectDuration('month')"
								[class.active-duration]="durationControl.value === 'month'"
							>
								MONTHLY
							</a>
						</li>
						<li>
							<a
								class="btn btn-duration-filled-orange-red"
								(click)="onSelectDuration('annual')"
								[class.active-duration]="durationControl.value === 'annual'"
							>
								ANNUAL
							</a>
						</li>
					</ul>
				</div>
			</div>

			<app-tarrif-plan [tarrifPlans]="tarrifPlans" [btnLabel]="'Select Plan'" (selectedTarrifPlan)="onSelectTarrifPlan($event)"></app-tarrif-plan>
		</div>
	</div>
</div>
