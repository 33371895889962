<div class="container">
	<div class="clear-btn">
		<mat-icon (click)="actionEvent()">clear</mat-icon>
	</div>

	<img src="/assets/icons/awesome-info-circle.svg" alt="image" />

	<h5>Budget Allocation</h5>

	<div class="content">
		<span class="content-message">
			The budget you have allocated for the split {{ level }} is different than the one you originally allocated in {{ step }}.
		</span>
		<div class="value">
			<span class="value-name">Original Budget:</span>
			<span class="value-money">{{ currency + ' ' + initial }}</span>
		</div>
		<div class="value">
			<span class="value-name">New Budget:</span>
			<span class="value-money">{{ currency + ' ' + current }}</span>
		</div>
		<span class="content-info">We will update the budget accordingly before publishing the campaigns.</span>
	</div>

	<div class="buttons">
		<btn15 (click)="actionEvent()">Cancel</btn15>
		<btn01 (onClick)="actionEvent(true)">Publish</btn01>
	</div>
</div>
