<div class="csc-align-center">
	<mat-horizontal-stepper
		#stepper
		[linear]="true"
		[selectedIndex]="activeStep - 1"
		(selectionChange)="onStepChange($event)"
		class="hidden-horizontal-scrollbar"
	>
		<mat-step [completed]="campaignStep >= 1" [editable]="true">
			<ng-template matStepLabel>
				<span>Objective Selection</span>
			</ng-template>
		</mat-step>
		<mat-step [completed]="toggleAspect(campaignStepEnum.Two)" [editable]="toggleAspect(campaignStepEnum.Two)">
			<ng-template matStepLabel>
				<span>Ad Set Settings</span>
			</ng-template>
		</mat-step>
		<mat-step [completed]="toggleAspect(campaignStepEnum.Three)" [editable]="toggleAspect(campaignStepEnum.Three)">
			<ng-template matStepLabel>
				<span>Create Ad</span>
			</ng-template>
		</mat-step>
		<mat-step [editable]="toggleAspect(campaignStepEnum.Four)" [completed]="toggleAspect(campaignStepEnum.Four)">
			<ng-template matStepLabel>
				<span>A/B Testing</span>
			</ng-template>
		</mat-step>
	</mat-horizontal-stepper>
</div>
<div class="spacing"></div>
