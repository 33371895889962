<div *ngIf="showInfo && infoData.question != 'What is a Dashboard?'" class="info-wrapper">
	<img alt="info-icon-box" src="../../../../assets/icons/info-icon-box.svg" />
	<div class="info-text">
		<span class="info-text-question">{{ infoData.question }}</span>
		<span class="info-text-message">
			{{ infoData.message }}
			<a *ngIf="infoData.link" href="{{ infoData.link.href }}" target="_blank">{{ infoData.link.text }}</a>
		</span>
	</div>
	<button (click)="onNoClick()" class="remove-button">
		<mat-icon>clear</mat-icon>
	</button>
</div>
