import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-user-admin',
	templateUrl: './user-admin.component.html',
	styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
