import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentRegistryService } from '../component-registry.service';
import { ReportModel } from '../../reports/models/report.model';
import { Helper } from '../../reports/helper';
import moment from 'moment';
import { ReportService } from '../../reports/services/api-services/report.service';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AccountTypeService } from '../account-type.service';
import { Subject, Subscription } from 'rxjs';
import { ReportingMetaInsightsService } from '../../reports/services/api-services/reporting-meta-insights.service';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-widget-loader',
	templateUrl: './widget-loader.component.html',
	styleUrls: ['./widget-loader.component.scss']
})
export class WidgetLoaderComponent implements OnInit {
	@Input() reportType: string;
	@Input() chartData: any[];
	@Input() report: ReportModel;
	@Input() index: number;

	@Output() removeWidget: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('parent', { read: ViewContainerRef, static: true }) parent: ViewContainerRef;

	private channelSubscription: Subscription;
	private selectedChannel: SourceChannel;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private reportingMetaInsightsService: ReportingMetaInsightsService,
		private reportService: ReportService,
		private accountTypeService: AccountTypeService,
		private componentRegistryService: ComponentRegistryService
	) {}

	ngOnInit() {
		// Refresh information on CHANNEL CHANGE (Facebook, Google)
		if (!this.channelSubscription) {
			this.channelSubscription = this.accountTypeService
				.getAccountTypeObservable()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(channel => {
					this.selectedChannel = channel;
				});
		}
		this.loadComponent(this.report.type);
	}

	// TODO: REFACTOR AND REMOVE DUPLICATE FUNCTION
	// PUT CHANNEL LISTENER IN THE META INSIGHTS SERVICE FOR AUTO SWITCHING AND GO FROM THERE
	public getChartDataAndCreateComponent(report: ReportModel, component: any): void {
		if (Helper.checkReport(report)) {
			const structureIds = report.details.insights.map(insight => {
				return insight.currentKey;
			});
			if (this.selectedChannel === SourceChannel.Facebook) {
				this.reportingMetaInsightsService
					.getFacebookChartDataWithQueryBuilder(
						`v${report.details.reportLevel}Insights`,
						report.details.metric,
						report.details.dimension.primaryValue.name,
						moment(report.details.dateFrom),
						moment(report.details.dateTo),
						structureIds,
						report.details.reportLevel,
						report.details.adAccount.id,
						report.details.breakdown,
						report.details.dimension.typeId
					)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe((resp: any) => {
						if (resp && this.report.details.breakdown) {
							this.report.details.chartData = this.reportingMetaInsightsService.convertChartDataWithBreakdowns(
								resp,
								report.details.breakdown.columnName,
								report.details.dimension.primaryValue.name,
								report.details.metric[0]
							);
						} else {
							this.report.details.chartData = resp;
						}
						this.createComponent(component);
					});
			} else {
				this.reportingMetaInsightsService
					.getGoogleChartDataWithQueryBuilder(
						this.report.details.reportType,
						this.report.details.reportLevel,
						this.report.details.metric,
						this.report.details.dimension.primaryValue.name,
						moment(this.report.details.dateFrom),
						moment(this.report.details.dateTo),
						structureIds,
						this.report.details.breakdown
					)
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(resp => {
						if (resp && this.report.details.breakdown) {
							this.report.details.chartData = this.reportingMetaInsightsService.convertChartDataWithBreakdowns(
								resp,
								report.details.breakdown.columnName,
								report.details.dimension.primaryValue.name,
								report.details.metric[0]
							);
						} else {
							this.report.details.chartData = resp;
						}
						this.createComponent(component);
					});
			}
		}
	}

	private loadComponent(reportType: string) {
		if (!reportType) {
			return;
		}

		const component = this.componentRegistryService.get(reportType);

		if (!component) {
			return;
		}

		if (this.reportIsValid() && !this.report.details.chartData?.length) {
			this.getChartDataAndCreateComponent(this.report, component);
		} else {
			this.createComponent(component);
		}
	}

	private createComponent(component: any) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component as any);
		const fixture = this.parent.createComponent(componentFactory);
		(fixture.instance as any).widget = this.report;
		(fixture.instance as any).index = this.index;
	}

	private reportIsValid() {
		return this.report.details.insights && this.report.details.insights.length && this.report.details.metric && this.report.details.dimension;
	}
}
