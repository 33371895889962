<div class="form">
	<div class="perrows">
		<div class="column">
			<label>
				Name as seen on Card
				<span>*</span>
			</label>
			<user-tb-2
				[ngClass]="{ 'no-edit': isEdit === true }"
				[control]="cardDetails.controls['cardName']"
				placeHolderTextBox="Enter Name as seen on Card"
				class="input-box"
			></user-tb-2>
		</div>
		<div class="column">
			<div>
				<label>
					Card Number
					<span>*</span>
				</label>
				<card-input
					class="input-box"
					[ngClass]="{ 'no-edit': isEdit === true }"
					[control]="cardDetails.controls['cardNumber']"
					[placeHolderTextBox]="'Enter 16 digit number'"
				></card-input>
			</div>
			<div class="perrows">
				<div class="columnp expiry">
					<label>
						Expiration Date
						<span>*</span>
					</label>
					<user-tb-2 [control]="expirationDate" placeHolderTextBox="MM/YYYY" class="input-box" (keyup)="formatExpiryDate($event)"></user-tb-2>
				</div>
				<div class="columnp cvv">
					<label>
						CVV
						<span>*</span>
					</label>
					<input type="password" class="password" placeholder="CVV" [formControl]="cardDetails.controls['securityCode']" />
				</div>
			</div>
		</div>
	</div>

	<div>
		<div class="sub-title billing-title">Billing details & address</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('firstName')">
				<label>
					First Name
					<span>*</span>
				</label>
				<user-tb-2 [control]="cardDetails.controls['firstName']" placeHolderTextBox="Enter your first name" class="input-box"></user-tb-2>
			</div>
			<div class="column" *ngIf="checkInRequest('lastName')">
				<label>
					Last Name
					<span>*</span>
				</label>
				<user-tb-2 [control]="cardDetails.controls['lastName']" placeHolderTextBox="Enter your second name" class="input-box"></user-tb-2>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('companyName')" [class.fullWidth]="!checkInRequest('phone')">
				<label>
					Company Name
					<span>*</span>
				</label>
				<user-tb-2 [control]="cardDetails.controls['companyName']" placeHolderTextBox="Enter your company name" class="input-box"></user-tb-2>
			</div>
			<div class="column" *ngIf="checkInRequest('phone')">
				<label>
					Phone Number
				</label>
				<user-tb-2 [control]="cardDetails.controls['phone']" placeHolderTextBox="+ _ _ (_ _ _) _ _ _ - _ _ - _ _" class="input-box"></user-tb-2>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('firstAddress')">
				<label>
					First line of address
					<span>*</span>
				</label>
				<user-tb-2 [control]="cardDetails.controls['firstAddress']" placeHolderTextBox="Enter first line of your address" class="input-box"></user-tb-2>
			</div>
			<div class="column" *ngIf="checkInRequest('secondAddress')">
				<label>
					Second line of address
				</label>
				<user-tb-2 [control]="cardDetails.controls['secondAddress']" placeHolderTextBox="Enter your address" class="input-box"></user-tb-2>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('city')">
				<label>
					City
					<span>*</span>
				</label>
				<user-tb-2 [control]="cardDetails.controls['city']" placeHolderTextBox="Enter in your City" class="input-box"></user-tb-2>
			</div>
			<div class="column" *ngIf="checkInRequest('zipCode')">
				<label>
					Zipcode/ Postcode
					<span>*</span>
				</label>
				<user-tb-2 [control]="cardDetails.controls['zipCode']" placeHolderTextBox="Enter your zipcode/postcode" class="input-box"></user-tb-2>
			</div>
		</div>
		<div class="perrows">
			<div class="column" *ngIf="checkInRequest('country')">
				<label>
					Country
					<span>*</span>
				</label>
				<app-select-item
					[dropDownControl]="cardDetails.controls['country']"
					[placeholderText]="'Select country'"
					[showCaretIcon]="true"
					[dropDownData]="countryData"
					[allowFilter]="true"
					(onItemSelected)="dropDownValueChanges($event)"
				></app-select-item>
			</div>
			<div class="column"></div>
		</div>
	</div>
</div>
