import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropdownInterface } from 'src/app-components/interfaces/dropdown-interface';

@Component({
	selector: 'app-choose-catalog',
	templateUrl: './choose-catalog.component.html',
	styleUrls: ['./choose-catalog.component.scss']
})
export class ChooseCatalogComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Output() public onExpansionClick = new EventEmitter();

	public catalogList: DropdownInterface[] = [
		{ name: 'Sample catalog 1', id: 1 },
		{ name: 'Sample catalog 2', id: 2 },
		{ name: 'Sample catalog 3', id: 3 }
	];
	public setList: DropdownInterface[] = [
		{ name: 'Sample set 1', id: 1 },
		{ name: 'Sample set 2', id: 2 },
		{ name: 'Sample set 3', id: 3 }
	];
	constructor() {}

	ngOnInit(): void {}
}
