import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
	selector: 'app-export-connection-page',
	templateUrl: './export-connection-page.component.html',
	styleUrls: ['./export-connection-page.component.scss']
})
export class ExportConnectionPageComponent implements OnInit {
	public access_token: string = '';
	public state: string = '';
	constructor(private route: ActivatedRoute) {}
	ngOnInit() {}
	public nextcontent(): void {
		window.close();
		window.top.location.href = 'https://ecommerce.filed.com/catalog/saved';
	}
}
