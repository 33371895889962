import { Component, Input, OnInit } from '@angular/core';
import { MessageDestinationEnum } from '../../models/message-destination-enum.enum';
import { DropdownInterface } from '@filed-com/filed-lib/lib/dropdowns/models/dropdown-interface';
import { SubObjectives } from '../../models/sub-objectives';

@Component({
	selector: 'app-message-destination',
	templateUrl: './message-destination.component.html',
	styleUrls: ['./message-destination.component.scss']
})
export class MessageDestinationComponent implements OnInit {
	@Input() public selectedObjective: string;
	@Input() public isPanelSelected: any;
	@Input() public onExpansionClick: any;
	@Input() public messageDestinationType = MessageDestinationEnum;
	@Input() public messageDropData = [
		{
			name: 'Click to Messenger',
			description:
				'Direct people from ads on Facebook, Instagram and/or Messenger into Messenger conversations. You will create both the ad and the message that people see on Messenger.',
			value: 'Click to Messenger'
		},
		{
			name: 'Sponsored message',
			description: 'Send a targeted message to people who have an existing Messenger conversation with your business.',
			value: 'Sponsored message'
		},
		{ name: 'Sponsored message', value: 'Sponsored message' }
	];
	@Input() public objectiveTypeIsMessage: boolean;
	@Input() public subObjectives = SubObjectives;
	@Input() public selectedMessageType: MessageDestinationEnum = this.messageDestinationType.Messenger;

	constructor() {}

	public ngOnInit(): void {}

	public dropDownValueChanges(event: DropdownInterface): void {}
}
