import { Component } from '@angular/core';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirm-password',
	templateUrl: './confirm-password.component.html',
	styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor(private dialogRef: MatDialogRef<ConfirmPasswordComponent>) {}

	public close(): void {
		this.dialogRef.close();
	}
}
