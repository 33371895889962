import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InstantFormSettings } from './instant-chat-settings.interface';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ButtonClassEnum } from 'src/app/shared/button-component/button-class.enum';
import { ButtonTypeEnum } from 'src/app/shared/button-component/button-type.enum';
import { IDropdwonWithIconData } from 'src/app-components/interfaces/button.interface';

const callToAction: Array<{ name: string; value: string; description: string }> = [
	{ name: 'No Button', value: 'View Website', description: '' },
	{ name: 'Download', value: 'Download', description: '' },
	{ name: 'View Website', value: 'View Website', description: '' }
];
export interface activeDialogButton {
	name: string;
	isActive: boolean;
}

@Component({
	selector: 'app-instant-chat-dialog',
	templateUrl: './instant-chat-dialog.component.html',
	styleUrls: ['./instant-chat-dialog.component.scss']
})
export class InstantChatDialogComponent implements OnInit, OnDestroy {
	@Input() public dialogGroup: FormGroup;

	private unsubscriber$ = new Subject<void>();
	public settings: InstantFormSettings;
	public file: File;
	public imageUrl: string;
	public searchControl = new FormControl();
	public buttonType = ButtonTypeEnum;
	public ListFieldObjects: FormArray;
	public introGreetingDescriptionListField: any;
	public callToActionButtons: Array<{ name: string; value: string; description: string }>;
	public showDisclaimer: boolean;
	public selectImageFromComputer: boolean;
	public buttonClass = ButtonClassEnum;
	public emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
	public showPrivacyLinkInfo: boolean;
	public consents: FormArray;
	public iframeSrc: string;

	public isShowGeneralContentTab: boolean = false;

	public contentCustomQuestion: FormArray = new FormArray([]);
	public contentCustomQuestionCity: FormArray = new FormArray([]);

	public customQuestions = [
		{ icon: '', text: 'Multiple Choice' },
		{ icon: '', text: 'Short Answer' },
		{ icon: '', text: 'Conditional' },
		{ icon: '', text: 'Appointment Request' }
	];
	public addCategory = [{ icon: '', text: 'Contact Fields', children: [{ icon: '', text: '' }] }];

	public dropdwonWithIcon: IDropdwonWithIconData[] = [
		{
			text: 'Phone Number',
			icon: 'phone',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('phone');
			}
		},
		{
			text: 'Email',
			icon: 'email',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('email');
			}
		},
		{
			text: 'Address',
			icon: 'location',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('address');
			}
		},
		{
			text: 'User Information',
			icon: 'profile',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('userInfo');
			}
		},
		{
			text: 'Job Title',
			icon: 'case',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('jobTitle');
			}
		},
		{
			text: 'Company Name',
			icon: 'home',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('companyName');
			}
		},
		{
			text: 'Gender',
			icon: 'user',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('gender');
			}
		},
		{
			text: 'Custom Question',
			icon: 'help',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('customQstn');
			}
		},
		{
			text: 'Carousel',
			icon: 'gallery',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('carousel');
			}
		}
	];
	constructor(private dialogRef: MatDialogRef<InstantChatDialogComponent>, private formBuilder: FormBuilder) {}

	public ngOnInit(): void {
		this.updatePosition();
		this.innitDialogForm();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public innitDialogForm(): void {
		this.dialogGroup = this.formBuilder.group({
			contentIntro: [null, [Validators.required]],
			contentCustomQuestion: this.formBuilder.array([this.createConsent()]),
			contentCompletionMessage: [null, [Validators.required]],
			contentCompletionActionButton: [null, [Validators.required]],
			contentCompletionActionButtonLink: [null, [Validators.required]],
			contentDisqualificationMessage: [null, [Validators.required]],
			contentPrivacyPolicy: [null, [Validators.required]],
			introGreetingDescriptionType: ['Paragraph', [Validators.required]],
			introGreetingDescriptionTypeParagraph: [null],
			email: [null, [Validators.required, Validators.pattern(this.emailRegx)]],
			introGreetingDescriptionTypeList: new FormArray([]),
			questionCustomeQuestion: [null],
			privacyLabelLink: [null],
			privacyLabelLinkText: [null],
			privacyCustomerDisclaimerTitle: [null],
			privacyCustomerDisclaimerTitleText: [null, [Validators.required]],
			privacyCustomerDisclaimerConsents: this.formBuilder.array([this.createConsent()]),
			completionCallToActionText: [null],
			completionLabelLink: [null],
			settingsFormConfigurationLanguageSharing: [null],
			settingsFieldnameEmail: [null],
			settingsFieldnameFullname: [null],
			settingsTrackingParameters: [null],
			settingsLanguageParameters: this.formBuilder.array([this.createParameters()])
		});
	}

	public oniframeSrc($event: string): void {
		this.iframeSrc = $event;
	}

	private createConsent(): FormGroup {
		return this.formBuilder.group({
			text: ''
		});
	}

	private createParameters(): FormGroup {
		return this.formBuilder.group({
			parameterName: '',
			parameterValue: ''
		});
	}
	public addCustomQuestion(type: string): void {
		this.contentCustomQuestion.controls.push(new FormControl(''));
	}
	public removeLanguageParameter(index: number): void {
		if (index > 0) {
			this.contentCustomQuestion.controls.splice(index);
		}
	}

	public addDisclaimer(): void {
		this.showDisclaimer = true;
	}
	public removeDisclaimer(): void {
		this.dialogGroup.get('privacyCustomerDisclaimerTitle').setValue = null;
		this.dialogGroup.get('privacyCustomerDisclaimerTitleText').setValue = null;
		this.dialogGroup.get('privacyCustomerDisclaimerConsents').setValue = null;
		this.showDisclaimer = false;
	}

	public toggleShowGeneralContentTab(value: boolean): void {
		this.isShowGeneralContentTab = value;
	}
	public updateLanguageSharing(value: string): void {
		this.dialogGroup.patchValue({ settingsFormConfigurationLanguageSharing: value });
	}
	public addPrivacyLinkInfoEvent(): void {
		this.showPrivacyLinkInfo = true;
	}

	public removePrivacyLinkInfoEvent(): void {
		this.showPrivacyLinkInfo = false;
	}
	public pageBackgroundImageSelector(value: number): void {
		this.selectImageFromComputer = value == 0 ? false : true;
	}
	addListFieldObjects() {
		this.ListFieldObjects.push(new FormControl(''));
	}
	private setIntroGreetingDescriptionTypeList(): void {
		let typeList: FormArray = new FormArray([]);
		for (var i = 0; i < 5; i++) this.addListFieldObjects();
		//typeList.push(new FormControl(''));
		this.dialogGroup.patchValue({ introGreetingDescriptionTypeList: typeList });
	}
	public updatePosition(): void {
		this.dialogRef.updatePosition({ top: '5vh' });
	}

	close(): void {
		this.dialogRef.close();
	}

	public onSubmit(): void {
		if (!this.dialogGroup.valid) {
			return;
		}
	}

	public getUploadedFilename(filename: string): void {
		this.dialogGroup.patchValue({ introBackgroundImageUrl: filename });
	}
}
