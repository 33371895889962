import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-input-with-counter',
	templateUrl: './input-with-counter.component.html',
	styleUrls: ['./input-with-counter.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputWithCounterComponent),
			multi: true
		}
	]
})
export class InputWithCounterComponent implements OnInit, ControlValueAccessor {
	@Input() max: number;
	@Input() placeholderText: number;
	disabled = false;
	textFormControl: FormControl;

	value = '';

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {}

	writeValue(value: string): void {
		this.textFormControl.setValue(value);
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
