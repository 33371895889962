import { Injectable } from '@angular/core';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { LoadCampaignTemplate } from '../state/smart-create.actions';
import { AdAccountResolver } from 'src/app/shared/resolvers/ad-account.resolver';
import { CampaignSmartCreateState, isCampaignUpdating, getCampaign, getObjectiveTree } from '../state/smart-create.reducer';
import { withLatestFrom, skipWhile, map, take, tap } from 'rxjs/operators';
import { forkJoin, EMPTY } from 'rxjs';
import { LoadCampaignCatalogs } from '../../state/campaign-common-state/campaign-common-actions';
import { getCatalogs } from '../../state/campaign-common-state/campaign-common-reducer';

@Injectable()
export class OptimizationResolver implements Resolve<any> {
	constructor(
		private sharedStore: Store<SharedState>,
		private addAccountResolver: AdAccountResolver,
		private store: Store<CampaignSmartCreateState>,
		private router: Router
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.sharedStore.dispatch(new LoadCampaignCatalogs());

		const adAccount$ = this.addAccountResolver.resolve(route, state);
		const campaignId = Number(route.params.campaignId);
		this.sharedStore.dispatch(new LoadCampaignTemplate(campaignId));

		const campaign$ = this.store.pipe(
			withLatestFrom(this.store.select(isCampaignUpdating)),
			skipWhile(([action, isUpdating]) => isUpdating),
			map(([action, isUpdating]) => action),
			select(getCampaign),
			skipWhile(campaign => !campaign || campaign.id !== campaignId),
			take(1)
		);

		const catalogs$ = this.store.pipe(
			select(getCatalogs),
			skipWhile(catalogs => !catalogs),
			take(1)
		);

		const objectives$ = this.store.pipe(
			select(getObjectiveTree),
			skipWhile(objectives => !objectives),
			take(1)
		);

		return forkJoin([campaign$, objectives$, catalogs$]).pipe(
			take(1),
			tap(test => this.store.dispatch(new HideGlobalSpinner())),
			map(([campaign, objectives, catalogs]) => {
				if (!campaign) {
					this.router.navigate(['/campaign/welcome']);
					return EMPTY;
				}
				return {
					campaign: campaign,
					objectives: objectives,
					catalogs: catalogs
				};
			})
		);
	}
}
