<a class="sap-close" (click)="confirm(false)"><mat-icon>close</mat-icon></a>
<img src="assets/icons/Group14489.svg" alt="info" class="sap-icon" />
<h5>Save your new Audience</h5>
<p>Write the name of your new Audience</p>
<div class="sap-content">
	<app-input-text [control]="audienceControl" [validatorMessages]="validationMessages" label="Audience Name" placeholder="Audience Name"></app-input-text>
</div>
<div mat-dialog-actions class="sap-actions">
	<app-button
		[buttonType]="buttonTypeEnum.Simple"
		[buttonClass]="buttonClassEnum.Secondary"
		[buttonLabel]="'Cancel'"
		(buttonClicked)="confirm(false)"
	></app-button>
	<app-button
		[buttonType]="buttonTypeEnum.Simple"
		[buttonClass]="buttonClassEnum.Primary"
		[buttonLabel]="'Save'"
		(buttonClicked)="confirm(true)"
	></app-button>
</div>
