import { Component, Input, OnInit } from '@angular/core';
import { ReportComponentService } from '../../../services/chart-services/report-component.service';
import { HeatChartService } from '../../../services/chart-services/heat-chart.service';
import { ReportModel } from '../../../models/report.model';
import { ChartInformation } from '../../../models/chart-information';
import { InitOptionsModel } from '../../../models/init-options.model';
import EChartOption = echarts.EChartOption;
import ECharts = echarts.ECharts;

@Component({
	selector: 'app-heat-map-chart',
	templateUrl: './heat-map-chart.component.html',
	styleUrls: ['./heat-map-chart.component.scss']
})
export class HeatMapChartComponent implements OnInit {
	@Input() widget: ReportModel;
	@Input() index: number;

	public chartOption: EChartOption;

	public initOptions: InitOptionsModel;

	constructor(private widgetComponentService: ReportComponentService, private chartService: HeatChartService) {}

	ngOnInit() {
		this.chartOption = this.chartService.createChart(this.widget);
	}

	public onChartInit(instance: ECharts): void {
		const chartInformation = new ChartInformation(instance, instance.getDom());
		this.chartService.setChartInstances(this.index, chartInformation);
	}
}
