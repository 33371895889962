import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

@Pipe({ name: 'snakeCaseToHuman' })
export class SnakeCaseToHuman implements PipeTransform {
	constructor(private capitalize: TitleCasePipe) {}
	transform(value: string) {
		let result = '';

		const wordArray = value.split('_');

		wordArray.forEach(word => {
			if (result) {
				result += ` ${word}`;
			} else {
				result = this.capitalize.transform(word);
			}
		});
		return result;
	}
}
