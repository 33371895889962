import { Component, Input, OnInit } from '@angular/core';
import { InfoHoverDataModel } from './info-hover-data.model';

@Component({
	selector: 'app-info-hover',
	templateUrl: './info-hover.component.html',
	styleUrls: ['./info-hover.component.scss']
})
export class InfoHoverComponent implements OnInit {
	@Input() public matTooltipPosition = 'after';
	@Input() public infoData: InfoHoverDataModel;

	constructor() {}

	ngOnInit() {}
}
