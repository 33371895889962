import { Action } from '@ngrx/store';
import { AgGridViewInterface } from 'src/app/shared/master-table/models/ag-grid-view.interface';
import { CatalogResolverDataInterface } from '../model/catalog-resolver-data.interface';
import { MappingDetailsInterface } from '../model/catalog-mapping.interface';
import { ProductCatalogInterface } from '../model/product-catalog.interface';
import { BackProductInterface, SelectedProductInterface } from '../model/products.interface';

export enum ActionTypes {
	FILE_UPLOAD = '[File Upload Form] Request',
	AG_GRID_HEADER = '[AG Grid Header] Request',
	PRODUCT_MAPPING = '[Product Mapping] Request',
	VARIANT_MAPPING = '[Variant Mapping] Request',
	PRODUCT_CATALOG_ID = '[Product Catalog Id] Request',
	PRODUCT_CATALOG = '[Product Catalog] Request',
	PRODUCT_CATALOG_SUCCESS = '[Product Catalog] Success',
	PRODUCT_SELECTION = '[Product Selection] Request',
	VARIABLE_DATA = '[Variants Data] Request'
}

export class FileUploadAction implements Action {
	readonly type = ActionTypes.FILE_UPLOAD;
	constructor(public payload: { Method: string; FileType: string; header: any[]; file: string }) {}
}
export class AgGridHeaderAction implements Action {
	readonly type = ActionTypes.AG_GRID_HEADER;
	constructor(public payload: { agGridHeaders: CatalogResolverDataInterface }) {}
}
export class AgGridVariantDataAction implements Action {
	readonly type = ActionTypes.VARIABLE_DATA;
	constructor(public payload: { variantsData: any }) {}
}
export class ProductMappingAction implements Action {
	readonly type = ActionTypes.PRODUCT_MAPPING;
	constructor(public payload: { product: MappingDetailsInterface }) {}
}
export class VariantMappingAction implements Action {
	readonly type = ActionTypes.VARIANT_MAPPING;
	constructor(public payload: { variant: MappingDetailsInterface }) {}
}
export class ProductCatalogId implements Action {
	readonly type = ActionTypes.PRODUCT_CATALOG_ID;
	constructor(public payload: { id: number }) {}
}
export class ProductSelection implements Action {
	readonly type = ActionTypes.PRODUCT_SELECTION;
	constructor(public payload: { selectedRow: BackProductInterface; isChosen: boolean }) {}
}

export class ProductCatalogAction implements Action {
	readonly type = ActionTypes.PRODUCT_CATALOG;
	constructor() {}
}

export class ProductCatalogSuccessAction implements Action {
	readonly type = ActionTypes.PRODUCT_CATALOG_SUCCESS;
	constructor(public payload: ProductCatalogInterface[]) {}
}
export type Actions =
	| FileUploadAction
	| AgGridHeaderAction
	| ProductMappingAction
	| VariantMappingAction
	| ProductCatalogId
	| ProductCatalogAction
	| ProductCatalogSuccessAction
	| ProductSelection
	| AgGridVariantDataAction;
