<div class="container">
	<div class="text-center wrapper">
		<div class="login-image">
			<img src="../../../../../../assets/logo/lolly-logo.svg" width="79" height="81" />
		</div>
		<div class="info-1">
			<font08>
				<span>
					<b>Lolly</b>
					would like access your
					{{
						(channel.source === 'facebook-normal' || channel.source === 'facebook' ? 'Facebook' : channel.title) ||
							(channel.source === 'google-normal' || channel.source === 'google' ? 'Google' : channel.title)
					}}
					API credentials connected to your account.
				</span>
			</font08>
		</div>

		<font08>
			<span class="info-2">
				By continuing, Lolly will receive ongoing access to the information and products that you add to
				{{ channel.title }}.
			</span>
		</font08>
	</div>
	<div class="footer-button">
		<btn15 class="btnflex" style="margin-left: 2rem;" (onClick)="closeDialog(false)">Cancel</btn15>
		<btn01 class="btnflex" style="margin-right: 2rem;" (onClick)="closeDialog(true)">Continue</btn01>
	</div>
</div>
