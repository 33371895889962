import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class QuickNavigationService {
	private selectedNavigation = new BehaviorSubject<number>(0);
	private selectedNavigationViaScroll = new BehaviorSubject<number>(0);
	constructor() {}

	public getSelectedNavigation(): Observable<number> {
		return this.selectedNavigation.asObservable();
	}

	public getSelectedNavigationViaScroll(): Observable<number> {
		return this.selectedNavigationViaScroll.asObservable();
	}

	public setSelectedNavigation(index: number): void {
		this.selectedNavigation.next(index);
	}

	public setSelectedNavigationViaScroll(index: number): void {
		this.selectedNavigationViaScroll.next(index);
	}
}
