<div
	*ngIf="dashboardReports && dashboardReports.length > 0"
	[class.expand-padding]="expand"
	[class.expand]="expand"
	[style.margin-left]="expand ? '0' : '2.08vw'"
	class="gridster-container"
	#gridsterContainer
>
	<gridster #gridster [options]="gridOptions" [ngClass]="{ 'gridster-container-margin': !dashboardInEditMode }">
		<ng-container *ngFor="let item of dashboardReports; let $index = index">
			<gridster-item
				(mouseenter)="onHover($index)"
				(mouseleave)="onHover(null)"
				[class.edit-widget]="$index === selectedReportIndex && editWindowIsOpen"
				[item]="{
					x: item.details.reportProperty.x,
					y: item.details.reportProperty.y,
					rows: item.details.reportProperty.rows,
					cols: item.details.reportProperty.cols,
					index: $index,
					minItemRows: 2,
					minItemCols: 3
				}"
				class="flex-gridster"
				#gridsterItem
			>
				<div class="heading-container">
					<div class="title">
						<img *ngIf="item.details?.dataSource === sourceChannel.Google" class="google-image" src="assets/icons/google-icon.svg" />
						<img *ngIf="item.details?.dataSource === sourceChannel.Facebook" class="google-image" src="assets/icons/Icon-facebook.svg" />
						<span class="text" matTooltip="{{ item.name }}" matTooltipPosition="after">{{ item.name }}</span>
					</div>
					<ng-container *ngIf="dashboardInEditMode">
						<div class="report-icons-section">
							<span class="report-icons" [ngClass]="{ 'hidden-icons': hoverIndex !== $index }">
								<i
									*ngIf="!(item.type === 'Table' || item.type === 'SingleNumber')"
									[matMenuTriggerFor]="menu"
									title="Colour"
									class="fas fa-paint-brush"
								>
									<mat-menu #menu="matMenu">
										<div *ngFor="let name of themesNames; let z = index" (click)="changeTheme(name.name, item)" mat-menu-item>
											<span *ngFor="let color of name.colors" class="padclr" [ngStyle]="{ 'background-color': color }"></span>
											<span class="padname">Palette {{ z + 1 }}</span>
										</div>
									</mat-menu>
								</i>
							</span>
							<span class="report-icons" [ngClass]="{ 'hidden-icons': hoverIndex !== $index }">
								<img (click)="duplicateWidget(item)" title="Duplicate" src="assets/icons/duplicate_icon.svg" />
							</span>
							<span class="report-icons export" [ngClass]="{ 'hidden-icons': hoverIndex !== $index }">
								<img (click)="exportRawData($index)" title="Export" src="assets/icons/export-gray-icon.svg" />
							</span>
							<span
								class="report-icons"
								*ngIf="item.type !== chartTypes.Image && item.type !== chartTypes.Text"
								[ngClass]="{ 'hidden-icons': hoverIndex !== $index }"
							>
								<i class="fas fa-pen" title="Edit" (click)="openWidgetEdit($event, item, $index)"></i>
							</span>
							<span class="report-icons" [ngClass]="{ 'hidden-icons': hoverIndex !== $index }">
								<i class="fas fa-times" title="Delete" (click)="removeWidget($index)"></i>
							</span>
						</div>
					</ng-container>
				</div>
				<div class="widget-container">
					<app-widget-loader (removeWidget)="removeWidget($event)" [index]="$index" [report]="item"></app-widget-loader>
				</div>
			</gridster-item>
		</ng-container>
	</gridster>
</div>
