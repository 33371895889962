import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { DropdownData } from '../../../../shared/dropdown-search-select/dropdown-data.interface';
import { CampaignTemplateListItem } from '../../../models/campaign-template-list-item';
import { SelectType } from '../../../../shared/dropdown-search-select/dropdown-type.enum';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-campaign-selector',
	templateUrl: './campaign-selector.component.html',
	styleUrls: ['./campaign-selector.component.scss']
})
export class CampaignSelectorComponent implements OnInit, OnChanges {
	@Input() public dropdownData: DropdownData<CampaignTemplateListItem>[];

	@Output() public cancelClick = new EventEmitter<void>();
	@Output() public continueClick = new EventEmitter<CampaignTemplateListItem>();
	@Output() public createNewClick = new EventEmitter<void>();

	public dropdownControl = new FormControl({ value: null });
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public dropdownSelect = SelectType;

	constructor() {}

	public ngOnInit(): void {
		if (this.dropdownData) {
			this.dropdownControl.setValue(null);
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.dropdownControl.setValue(null);
	}

	public onCreateNewClick(): void {
		this.createNewClick.emit();
	}

	public onContinueClick(): void {
		this.continueClick.emit(this.dropdownData.find(item => item.value === this.dropdownControl.value).data);
	}

	public onCancelClick(): void {
		this.cancelClick.emit();
	}
}
