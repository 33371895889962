export enum ChartImagePath {
	Bar = '/assets/icons/reporting/chart-types/bar.svg',
	HorizontalBar = '/assets/icons/reporting/chart-types/horizontalbar.svg',
	GroupedVerticalBar = '/assets/icons/reporting/chart-types/groupedverticalbar.svg',
	GroupedHorizontalBar = '/assets/icons/reporting/chart-types/groupedhorizontalbar.svg',
	VerticalStackedBar = '/assets/icons/reporting/chart-types/verticalstackedbar.svg',
	HorizontalStackedBar = '/assets/icons/reporting/chart-types/horizontalstackedbar.svg',
	Line = '/assets/icons/reporting/chart-types/line.svg',
	Area = '/assets/icons/reporting/chart-types/area.svg',
	Pie = '/assets/icons/reporting/chart-types/pie.svg',
	Donut = '/assets/icons/reporting/chart-types/donut.svg',
	Heat = '/assets/icons/reporting/chart-types/heat.svg',
	SingleNumber = '/assets/icons/reporting/chart-types/singlenumber.svg',
	Table = '/assets/icons/reporting/chart-types/table.svg',
	Image = '/assets/icons/reporting/chart-types/image-chart.svg',
	Text = '/assets/icons/reporting/chart-types/text-chart.svg'
}
