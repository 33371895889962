<div class="add-payment-outside modal-content">
	<div class="title-add-payment">
		<h4>Add New Payment</h4>
		<mat-icon (click)="cancel()">clear</mat-icon>
	</div>
	<div class="form-add-payment body">
		<div class="inside-form-add-payment">
			<div class="progress-bar" *ngIf="isFistPayment">
				<div class="progress-point" [class.active]="!billingActive">
					<img class="card-add-payment" src="/assets/icons/card-round.svg" />
					<span>Payment Details</span>
				</div>
				<span class="separator"></span>
				<div class="progress-point" [class.active]="billingActive">
					<img class="card-add-payment" src="/assets/icons/billing-round.svg" />
					<span>Billing Details</span>
				</div>
			</div>
			<div class="outside-form" [class.disabled-form]="disabledForm">
				<form [formGroup]="accountForm">
					<div class="first-card-part" [class.inactive]="billingActive">
						<div class="subtitle-add-payment">
							<img class="card-add-payment" src="/assets/icons/card-round.svg" />
							<h3>Add Payment Details</h3>
							<p>Please enter your payment details</p>
							<div class="cards-add-payment">
								<img src="/assets/icons/card-icons/visa.png" />
								<img src="/assets/icons/card-icons/american-express.png" />
								<img src="/assets/icons/card-icons/master-card.png" />
								<img src="/assets/icons/card-icons/maestro-card.png" />
							</div>
						</div>
						<div class="add-payment-fields">
							<div>
								<div class="margin-space">
									<app-input-text
										[control]="accountForm.get('cardHolder')"
										[validatorMessages]="cardNameValidatorMessages"
										label="Name seen on card"
										placeholder="{{ cardHolderPlaceholder }}"
									></app-input-text>
								</div>

								<app-input-card-number
									[control]="accountForm.get('number')"
									[validatorMessages]="cardNumberValidatorMessages"
									label="Card Number"
									placeholder="{{ cardNumberPlaceholder }}"
								></app-input-card-number>

								<div class="half-fields">
									<div class="date">
										<app-input-billing-date
											[control]="accountForm.get('expirationDate')"
											label="MM/YY"
											placeholder="{{ expirationDatePlaceholder }}"
										></app-input-billing-date>
									</div>

									<app-input-cvc
										[control]="accountForm.get('cvc')"
										[validatorMessages]="cardCvcValidatorMessages"
										label="CVC"
										placeholder="{{ cvcPlaceholder }}"
									></app-input-cvc>
								</div>

								<div class="default-statement" *ngIf="!isFistPayment">
									<mat-slide-toggle (change)="changeDefault()" [checked]="true"></mat-slide-toggle>
									<span>Make this my primary payment method</span>
								</div>
							</div>
						</div>
					</div>

					<div class="billing-part" *ngIf="isFistPayment" [class.active]="billingActive" [class.animate]="billingShown">
						<div class="subtitle-add-payment">
							<img class="card-add-payment" src="/assets/icons/billing-round.svg" />
							<h3>Add your Billing Details</h3>
							<p>Please enter your billing information for the new payment</p>
						</div>
						<div class="add-payment-fields">
							<div>
								<div>
									<app-input-text
										[tabIndex]="'-1'"
										[control]="accountForm.get('address1')"
										[validatorMessages]="cardAddressValidatorMessages"
										label="Billing Address*"
										placeholder="e.g: Ave. Omar Torrijos H., Panama"
									></app-input-text>
								</div>
								<div>
									<app-input-text
										tabindex="102"
										[control]="accountForm.get('city')"
										[validatorMessages]="cardCityValidatorMessages"
										label="City*"
										placeholder="e.g: London"
									></app-input-text>
								</div>
								<div class="margin-bottom-lg">
									<app-dropdown-search-select
										[tabIndex]="'-1'"
										[dropdownFormControl]="accountForm.get('country')"
										[validatorMessages]="cardCountryMessages"
										[dropdownPlaceholder]="'Country*'"
										[dropdownData]="dropdownCountries"
									></app-dropdown-search-select>
								</div>
								<div class="margin-bottom-spacing">
									<app-input-text
										[tabIndex]="'-1'"
										[control]="accountForm.get('zip')"
										[validatorMessages]="cardZipValidatorMessages"
										label="Zipcode/ Postcode*"
										placeholder="e.g: ER5 6GL"
									></app-input-text>
								</div>
							</div>
						</div>
					</div>
					<div class="formError" *ngIf="stripeError">{{ stripeError }}</div>
				</form>
			</div>
		</div>
	</div>
	<div class="add-payment-buttons">
		<app-button
			*ngIf="!billingActive"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			(buttonClicked)="cancel()"
			buttonLabel="Cancel"
		></app-button>

		<app-button
			*ngIf="billingActive"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
			(buttonClicked)="previousStep()"
			buttonLabel="Back"
		></app-button>

		<app-button
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			[disabled]="accountForm.invalid"
			(buttonClicked)="submitForm()"
			class="button-confirm"
			buttonLabel="Save"
			*ngIf="(billingActive && billingShown) || !isFistPayment"
		></app-button>

		<app-button
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
			(buttonClicked)="nextStep()"
			class="button-confirm"
			buttonLabel="Next"
			*ngIf="isFistPayment && !billingActive && !billingShown"
		></app-button>
	</div>
</div>
