import { GroupedHorizontalBarChartService } from '../services/chart-services/grouped-horizontal-bar-chart.service';
import { GroupedVerticalBarChartService } from '../services/chart-services/grouped-vertical-bar-chart.service';
import { StackedHorizontalChartService } from '../services/chart-services/stacked-horizontal-chart.service';
import { StackedVerticalChartService } from '../services/chart-services/stacked-vertical-chart.service';
import { HorizontalBarChartService } from '../services/chart-services/horizontal-bar-chart.service';
import { VerticalBarChartService } from '../services/chart-services/vertical-bar-chart.service';
import { DoughnutChartService } from '../services/chart-services/doughnut-chart.service';
import { TableChartService } from '../services/chart-services/table-chart.service';
import { LineChartService } from '../services/chart-services/line-chart.service';
import { AreaChartService } from '../services/chart-services/area-chart.service';
import { HeatChartService } from '../services/chart-services/heat-chart.service';
import { SingleNumberChartService } from '../services/chart-services/single-number.service';
import { PieChartService } from '../services/chart-services/pie-chart.service';

export const services = {
	line: LineChartService,
	bar: VerticalBarChartService,
	HorizontalBar: HorizontalBarChartService,
	GroupedHorizontalBar: GroupedHorizontalBarChartService,
	GroupedVerticalBar: GroupedVerticalBarChartService,
	HorizontalStackedBar: StackedHorizontalChartService,
	VerticalStackedBar: StackedVerticalChartService,
	Donut: DoughnutChartService,
	Area: AreaChartService,
	Heat: HeatChartService,
	Table: TableChartService,
	SingleNumber: SingleNumberChartService,
	Pie: PieChartService
};
