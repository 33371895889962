import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserState, getAccountState } from '../../shared/state/user/user.reducer';

@Component({
	selector: 'app-trial-end',
	templateUrl: './trial-end.component.html',
	styleUrls: ['./trial-end.component.scss']
})
export class TrialEndComponent implements OnInit, OnDestroy {
	private accountState: number;
	private unsubscriber$ = new Subject<void>();

	constructor(public userStore: Store<UserState>) {}

	public ngOnInit(): void {
		this.userStore.pipe(select(getAccountState), takeUntil(this.unsubscriber$)).subscribe(accountState => {
			this.accountState = accountState;
		});
	}

	public displayMessage(): string {
		if (this.accountState === 11) {
			return 'Your Freemium has expired';
		} else if (this.accountState === 12) {
			return 'Your Free Trial has expired';
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
