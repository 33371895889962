export interface DataInterface<T> {
	isLoaded: boolean;
	isLoading?: boolean;
	errorCode: string;
	data: T;
}

export const NullDataInterface: DataInterface<null> = {
	isLoaded: null,
	data: null,
	isLoading: null,
	errorCode: null
};
