import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { getSelectedAdAccount, SharedState } from '../../shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { CampaignTreeRequestInterface } from '../models/campaign-tree-request.interface';
import { StructureRequestInterface } from '../models/structure-request-interface';
import { DeleteStructureData } from '../models/smart-edit.interface';
import { NewRecommendationInterface } from 'src/app/optimise/models/new-recommendation.interface';

@Injectable({
	providedIn: 'root'
})
export class SmartEditService {
	private adAccountId: string;
	public dexterRecommendation$: BehaviorSubject<NewRecommendationInterface> = new BehaviorSubject(null);
	constructor(private httpClient: HttpClient, private sharedStore: Store<SharedState>) {
		this.sharedStore.pipe(select(getSelectedAdAccount)).subscribe(result => (this.adAccountId = result.adAccount.id));
	}

	// public getCampaignTreeStructure(data: CampaignTreeRequestInterface): Observable<any> {
	// 	return this.httpClient.get(
	// 		`${BaseApiUrl.FacebookPythonCampaignBuilder}campaign-trees-structure/${this.adAccountId}/${data.level}/${data.level_child_id}`
	// 	);
	// }

	// public getCampaignStructure(data: StructureRequestInterface): Observable<any> {
	// 	return this.httpClient.get(`${BaseApiUrl.FacebookPythonCampaignBuilder}get-structure/${this.adAccountId}/${data.level}/${data.id}`);
	// }

	// public smartEditPublish(body: any): Observable<any> {
	// 	return this.httpClient.post<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}smart-edit-publish`, body);
	// }

	public deleteStructure(data: DeleteStructureData): Observable<any> {
		return this.httpClient.delete(`${BaseApiUrl.FacebookDataPipeline}${data.level}/${data.id}`);
	}
	public duplicateStructure(level: string, structure_id: string): Observable<any> {
		return this.httpClient.get(`${BaseApiUrl.FacebookDataPipeline}/${level}/${structure_id}/duplicate`);
	}

	// public getCatalogs(id: string): Observable<any> {
	// 	return this.httpClient.get<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}catalogs/${id}`);
	// }

	// public getCatalogSets(catalogId: string): Observable<any> {
	// 	return this.httpClient.get<any>(`${BaseApiUrl.FacebookPythonCampaignBuilder}catalog-sets/${catalogId}`);
	// }
}
