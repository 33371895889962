import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, of, Subject } from 'rxjs';
import { FlatTreeControl } from '@angular/cdk/tree';
import { takeUntil } from 'rxjs/operators';

export class TodoItemNode {
	children?: TodoItemNode[];
	name: string;
	status?: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
	id?: number;
	name: string;
	level: number;
	expandable: boolean;
	disabled?: boolean;
	data?: any;
}

@Component({
	selector: 'app-tree-checklist',
	templateUrl: './tree-checklist.component.html',
	styleUrls: ['./tree-checklist.component.scss']
})
export class TreeChecklistComponent implements OnInit, OnDestroy {
	@Input() singleSelection?: boolean;
	@Input() primaryKeyName: string;
	@Input() parentKeyName?: string;
	@Input() nodeTitleName: string;
	@Input() expanded?: boolean;
	@Input() hasDisabledMode?: Boolean;
	@Input() data: any[];
	@Input() initialSelectionIds?: number[];
	@Input() onlySelectLeaves?: boolean;
	@Input() preventParentSelection?: boolean;
	@Input() listBulletColor?: boolean;
	@Output() treeSelection: EventEmitter<any> = new EventEmitter();
	@Output() model: EventEmitter<any> = new EventEmitter();
	@ViewChild('matTree') matTree: MatTree<TreeChecklistComponent>;

	dataChange: Subject<TodoItemNode[]> = new Subject();
	updateSelectionForDisabledSubject: Subject<void> = new Subject();
	selectAllSubject: Subject<void> = new Subject();
	expandAllSubject: Subject<void> = new Subject();
	collapseAllSubject: Subject<void> = new Subject();
	selectNodesSubject: Subject<any[]> = new Subject();
	deselectNodesSubject: Subject<void> = new Subject();
	disableNodesSubject: Subject<any[]> = new Subject();
	disableAllSubject: Subject<void> = new Subject();
	enableAllSubject: Subject<void> = new Subject();
	enableNodesSubject: Subject<any[]> = new Subject();

	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();

	/** A selected parent node to be inserted */
	selectedParent: TodoItemFlatNode | null = null;

	treeControl: FlatTreeControl<TodoItemFlatNode>;

	treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

	dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

	/** The selection for checklist */
	checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor() {
		if (this.nodeTitleName === undefined) {
			this.nodeTitleName = 'name';
		}
		this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
		this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

		this.addSubscriptions();
	}

	ngOnInit() {
		this.dataSource.data = this.data || [];
		this.initSelection();
		this.expanded && this.treeControl.expandAll();
		this.model.emit(this);
	}

	ngOnDestroy() {
		this.removeSubscriptions();

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private removeSubscriptions = (): void => {
		this.dataChange.unsubscribe();
		this.updateSelectionForDisabledSubject.unsubscribe();
		this.selectAllSubject.unsubscribe();
		this.expandAllSubject.unsubscribe();
		this.collapseAllSubject.unsubscribe();
	};

	private addSubscriptions = (): void => {
		this.dataChange.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			this.dataSource.data = data;
		});

		this.updateSelectionForDisabledSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			let descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes();

			// @ts-ignore
			descendantsToBeSelected = descendantsToBeSelected.filter((descendant: TodoItemFlatNode) => descendant.disabled);
			this.checklistSelection.deselect(...descendantsToBeSelected);
		});

		this.selectAllSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			const descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes();
			this.checklistSelection.select(...descendantsToBeSelected);
		});

		this.selectNodesSubject.pipe(takeUntil(this.unsubscriber$)).subscribe((nodeList: any[]) => {
			const nodeIds = nodeList.map(node => node[this.primaryKeyName]);

			const descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes().filter((item: any) => {
				return nodeIds.includes(item[this.primaryKeyName]);
			});
			[...descendantsToBeSelected].forEach(desc => this.todoItemSelectionToggle(desc, true));
		});

		this.deselectNodesSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			this.deselectAllNodes();
		});

		this.disableNodesSubject.pipe(takeUntil(this.unsubscriber$)).subscribe((nodeList: any[]) => {
			const nodeIds = nodeList.map(node => node[this.primaryKeyName]);

			let descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes();
			descendantsToBeSelected = descendantsToBeSelected.filter((node: TodoItemFlatNode | any) => {
				return nodeIds.includes(node[this.primaryKeyName]);
			});

			descendantsToBeSelected.forEach((node: TodoItemFlatNode) => {
				node.disabled = true;
			});
		});

		this.disableAllSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			const descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes();
			descendantsToBeSelected.forEach((descendant: TodoItemFlatNode) => {
				descendant.disabled = true;
			});
		});

		this.enableAllSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			const descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes();
			descendantsToBeSelected.forEach((descendant: TodoItemFlatNode) => {
				descendant.disabled = false;
			});
		});

		this.enableNodesSubject.pipe(takeUntil(this.unsubscriber$)).subscribe((nodeList: any[]) => {
			const nodeIds = nodeList.map(node => node[this.primaryKeyName]);

			let descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes();
			descendantsToBeSelected = descendantsToBeSelected.filter((node: TodoItemFlatNode | any) => {
				return nodeIds.includes(node[this.primaryKeyName]);
			});

			descendantsToBeSelected.forEach((node: TodoItemFlatNode) => {
				node.disabled = false;
			});
		});

		this.expandAllSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => this.treeControl.expandAll());

		this.collapseAllSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => this.treeControl.collapseAll());
	};

	private deselectAllNodes() {
		this.checklistSelection.deselect(...this.treeControl.dataNodes);
	}

	private getAllDescendantNodes = (): TodoItemFlatNode[] => this.treeControl.dataNodes;

	private initSelection = (): void => {
		if (!this.initialSelectionIds || !this.initialSelectionIds.length) {
			return;
		}

		let descendantsToBeSelected: TodoItemFlatNode[] = this.getAllDescendantNodes();

		descendantsToBeSelected = descendantsToBeSelected.filter(
			// @ts-ignore
			(descendant: TodoItemFlatNode) => this.initialSelectionIds.indexOf(descendant[this.primaryKeyName]) !== -1
		);
		descendantsToBeSelected.forEach(item => this.todoItemSelectionToggle(item, true));
	};

	private getLevel = (node: TodoItemFlatNode) => node.level;

	private isExpandable = (node: TodoItemFlatNode) => node.expandable;

	private getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => of(node.children);

	public hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

	private hasNoContent = (_: number, _nodeData: TodoItemFlatNode | any) => _nodeData[this.nodeTitleName] === '';

	/**
	 * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
	 */
	private transformer = (node: TodoItemNode | any, level: number) => {
		const existingNode: TodoItemNode | any = this.nestedNodeMap.get(node);
		const flatNode = existingNode && existingNode[this.nodeTitleName] === node[this.nodeTitleName] ? existingNode : new TodoItemFlatNode();

		// keep node data
		// !node.children && Object.assign(flatNode, node);
		Object.assign(flatNode, node);

		flatNode[this.nodeTitleName] = node[this.nodeTitleName];
		flatNode.level = level;
		flatNode.expandable = !!node.children;

		delete flatNode.children;

		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);

		return flatNode;
	};

	/** Whether all the descendants of the node are selected */
	descendantsAllSelected(node: TodoItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);

		const allDescendantsSelected = descendants.every((child: TodoItemFlatNode) => this.checklistSelection.isSelected(child));

		if (allDescendantsSelected || descendants.every((child: TodoItemFlatNode) => this.checklistSelection.isSelected(child) || child.disabled)) {
			this.checklistSelection.select(node);
		}
		return allDescendantsSelected;
	}

	/** Whether part of the descendants are selected */
	descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const result = descendants.some((child: TodoItemFlatNode) => this.checklistSelection.isSelected(child));
		return result && !this.descendantsAllSelected(node);
	}

	/** Whether all the descendants of the node are disabled */
	descendantsAllDisabled(node: TodoItemFlatNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const descAllDisabled = this.hasDisabledMode && descendants.every((child: TodoItemFlatNode) => child.disabled);
		return descAllDisabled;
	}

	/** Toggle the to-do item selection. Select/deselect all the descendants node */
	todoItemSelectionToggle(node: TodoItemFlatNode, noNotify?: Boolean): void {
		this.checklistSelection.toggle(node);

		if (this.singleSelection) {
			return;
		}

		const descendants = this.treeControl.getDescendants(node).filter((nodeItem: TodoItemFlatNode) => !nodeItem.disabled);

		// process needed for geo locations in targeting (based on parentKeyName input) in order to emit proper selection
		if (this.parentKeyName) {
			for (let i = descendants.length - 1; i >= 0; --i) {
				const descendant = descendants[i] as any;
				if (descendant[this.parentKeyName]) {
					const parent = descendants.find((item: any) => item[this.primaryKeyName] === descendant[this.parentKeyName]);
					if (parent || !descendant.expandable) {
						this.checklistSelection.isSelected(node) ? this.checklistSelection.select(descendant) : this.checklistSelection.deselect(descendant);
						descendants.splice(descendants.indexOf(descendant), 1);
					}
				}
			}
		}

		this.checklistSelection.isSelected(node) ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);

		// Force update for the parent
		descendants.every((child: TodoItemFlatNode) => this.checklistSelection.isSelected(child));
		this.checkAllParentsSelection(node);

		!noNotify && this.emitSelection();
	}

	/* Checks all the parents when a leaf node is selected/unselected */
	checkAllParentsSelection(node: TodoItemFlatNode): void {
		let parent: TodoItemFlatNode | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	/* Get the parent node of a node */
	getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
		const currentLevel = this.getLevel(node);

		if (currentLevel < 1) {
			return null;
		}

		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	/** Check root node checked state and change it accordingly */
	checkRootNodeSelection(node: TodoItemFlatNode): void {
		const nodeSelected = this.checklistSelection.isSelected(node);
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected = descendants.every((child: TodoItemFlatNode) => this.checklistSelection.isSelected(child));
		if (nodeSelected && !descAllSelected) {
			this.checklistSelection.deselect(node);
		} else if (!nodeSelected && descAllSelected) {
			this.checklistSelection.select(node);
		}
	}

	updateLeafSelection(node: TodoItemFlatNode): void {
		this.checklistSelection.toggle(node);
		this.checkAllParentsSelection(node);
		this.emitSelection();
	}

	selectSingleNode(singleNode: TodoItemFlatNode): void {
		if (this.onlySelectLeaves && singleNode.expandable) {
			return;
		}

		this.checklistSelection.deselect(...this.treeControl.dataNodes);
		this.checklistSelection.select(singleNode);
		this.emitSelection();
	}

	public getAllFlattenNodes(): TodoItemFlatNode[] {
		return this.treeControl.dataNodes;
	}

	private emitSelection = (): void => {
		const selection =
			this.onlySelectLeaves && !this.singleSelection
				? // leafs
				  this.checklistSelection.selected.filter((node: TodoItemFlatNode) => !node.expandable)
				: // all selection
				  this.checklistSelection.selected;
		this.treeSelection.emit(selection);
	};
}
