import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { ActionParamsInterface } from '../../../../back-office/models/action-params.interface';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-toggle-state-component',
	templateUrl: './toggle-state.component.html',
	styleUrls: ['./toggle-state.component.scss']
})
export class ToggleStateComponent {
	public isToggled = true;
	public params: ActionParamsInterface;
	private unsubscriber$ = new Subject<void>();

	constructor(private userServiceApi: UserServiceApi, private toastNotification: ToastNotificationService) {}
	public agInit(params: ActionParamsInterface): void {
		this.params = params;
		const toggle = !!Number(this.params.node.data.accountState);
		this.isToggled = toggle;
	}

	public refresh(params?: ActionParamsInterface): boolean {
		return true;
	}

	public toggleEvent(): void {
		if (this.isToggled) {
			if (this.params.backoffice) {
				this.userServiceApi.backOfficeDeactivateUser(this.params.node.data.id).subscribe(
					() => {},
					() => this.toastNotification.sendErrorToast('A problem occurred, please contact support!'),
					() => (this.isToggled = false)
				);
			} else {
				this.userServiceApi.deactivateUser(this.params.node.data.id).subscribe(
					() => {},
					() => this.toastNotification.sendErrorToast('A problem occurred, please contact support!'),
					() => (this.isToggled = false)
				);
			}
		} else {
			if (this.params.backoffice) {
				this.userServiceApi.backOfficeActivateUser(this.params.node.data.id).subscribe(
					() => {},
					() => this.toastNotification.sendErrorToast('A problem occurred, please contact support!'),
					() => (this.isToggled = true)
				);
			} else {
				this.userServiceApi.activateUser(this.params.node.data.id).subscribe(
					() => {},
					() => this.toastNotification.sendErrorToast('A problem occurred, please contact support!'),
					() => (this.isToggled = true)
				);
			}
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
