export const tableJson: any = {
	nameTemplate: 'Standard Table',
	tables: {
		OPTIMIZE: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: 'externalId',
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'PLATFORM',
					primaryValue: 'platformIconURL',
					secondaryValue: 'platformIconName',
					type: 'icon',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'RECOMMENDATIONS',
					primaryValue: 'recommendationText',
					secondaryValue: 'recommendationsCount',
					type: 'link',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'IMPRESSIONS',
					primaryValue: 'impressions',
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CLICKS',
					primaryValue: 'clicks',
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'REACH',
					primaryValue: 'reach',
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'AMOUNT_SPENT',
					primaryValue: 'spend',
					secondaryValue: null,
					type: 'currency',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'AUTOPILOT_STATUS',
					primaryValue: 'autoPilotStatus',
					secondaryValue: null,
					type: 'toggle',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteOptimizationSS',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteOptimizationMS',
					type: 'button',
					children: null
				},
				{
					name: 'DEACTIVATE',
					value: 'deactivate',
					functionName: 'deleteOptimizationMS',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		RECOMMENDATIONS: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'optimizationId',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '100',
					sticky: false
				},
				{
					name: 'RECOMMENDATION',
					primaryValue: 'recommendationName',
					secondaryValue: 'insightName',
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OPTIMIZATION_TYPE',
					primaryValue: 'optimizationType',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'RESULTS',
					primaryValue: 'results',
					secondaryValue: null,
					type: 'currency',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'COST_PER_RESULT',
					primaryValue: 'costPerResult',
					secondaryValue: null,
					type: 'currency',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'IMPRESSIONS',
					primaryValue: 'impressions',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'LINK_CLICKS',
					primaryValue: 'linkClicks',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'BUDGET',
					primaryValue: 'budget',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'APPLY',
					value: 'apply',
					functionName: 'applySingleSelection',
					type: 'button',
					children: null
				},
				{
					name: 'REJECT',
					value: 'reject',
					functionName: 'rejectSingleSelection',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'APPLY',
					value: 'apply',
					functionName: 'applyMultipleSelection',
					type: 'button',
					children: null
				},
				{
					name: 'REJECT',
					value: 'reject',
					functionName: 'rejectMultipleSelection',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: true,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		INVOICES: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'INVOICES',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'DESCRIPTION',
					primaryValue: 'description',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'CREATED_AT',
					primaryValue: 'createdAt',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'Edit',
					value: 'edit',
					type: 'button',
					functionName: 'edit',
					children: null
				},
				{
					name: 'Schedule report',
					value: 'scheduleReport',
					type: 'button',
					functionName: 'scheduleReport',
					children: null
				},
				{
					name: 'Duplicate',
					value: 'duplicate',
					functionName: 'duplicate',
					type: 'button',
					children: null
				},
				{
					name: 'Export raw data',
					value: 'exportRawData',
					type: 'button',
					functionName: 'exportRawData',
					children: null
				},
				{
					name: 'Delete',
					value: 'delete',
					functionName: 'delete',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'Duplicate',
					value: 'duplicateMultiple',
					functionName: 'duplicateMultiple',
					type: 'button',
					children: null
				},
				{
					name: 'Delete',
					value: 'delete',
					functionName: 'deleteMultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		DASHBOARD: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'DASHBOARD',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'PLATFORM_SOURCE',
					primaryValue: 'platformSource',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'DASHBOARD_TYPE',
					primaryValue: 'type',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'IS_DEFAULT',
					primaryValue: 'isDefault',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'CREATED_AT',
					primaryValue: 'createdAt',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'CREATED_BY',
					primaryValue: 'createdBy',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'Edit',
					value: 'edit',
					type: 'button',
					functionName: 'edit',
					children: null
				},
				{
					name: 'Duplicate',
					value: 'duplicate',
					functionName: 'duplicate',
					type: 'button',
					children: null
				},
				{
					name: 'Set as overview',
					value: 'setAsDefault',
					type: 'button',
					functionName: 'setAsDefault',
					children: null
				},
				{
					name: 'Share as link',
					value: 'shareAsLink',
					type: 'button',
					functionName: 'shareAsLink',
					children: null
				},
				{
					name: 'Share as email',
					value: 'shareAsEmail',
					type: 'button',
					functionName: 'shareAsEmail',
					children: null
				},
				{
					name: 'Schedule report',
					value: 'scheduleReport',
					type: 'button',
					functionName: 'scheduleReport',
					children: null
				},
				{
					name: 'Export raw data',
					value: 'exportRawData',
					type: 'button',
					functionName: 'exportRawData',
					children: null
				},
				{
					name: 'Delete',
					value: 'delete',
					functionName: 'delete',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'Duplicate',
					value: 'duplicateMultiple',
					functionName: 'duplicateMultiple',
					type: 'button',
					children: null
				},
				{
					name: 'Delete',
					value: 'delete',
					functionName: 'deleteMultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: false,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		REPORTS: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'REPORT_TYPE',
					primaryValue: 'reportType',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'DATE_RANGE',
					primaryValue: 'dateRange',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'CREATED_AT',
					primaryValue: 'createdAt',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'CREATED_BY',
					primaryValue: 'createdBy',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'SCHEDULED_REPORTS',
					primaryValue: 'scheduledReports',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'Edit',
					value: 'edit',
					type: 'button',
					functionName: 'edit',
					children: null
				},
				{
					name: 'Duplicate',
					value: 'duplicate',
					functionName: 'duplicate',
					type: 'button',
					children: null
				},
				{
					name: 'Export raw data',
					value: 'exportRawData',
					type: 'button',
					functionName: 'exportRawData',
					children: null
				},
				{
					name: 'Schedule report',
					value: 'scheduleReport',
					type: 'button',
					functionName: 'scheduleReport',
					children: null
				},
				{
					name: 'Delete',
					value: 'delete',
					functionName: 'delete',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'Duplicate',
					value: 'duplicateMultiple',
					functionName: 'duplicateMultiple',
					type: 'button',
					children: null
				},
				{
					name: 'Save as dashboard',
					value: 'saveAsDashboards',
					functionName: 'saveAsDashboardsMultiple',
					type: 'button',
					children: null
				},
				{
					name: 'Save as template',
					value: 'saveAsTemplates',
					functionName: 'saveAsTemplatesMultiple',
					type: 'button',
					children: null
				},
				{
					name: 'Delete',
					value: 'delete',
					functionName: 'deleteMultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		SCHEDULES: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'REPORT_NAME',
					primaryValue: 'reportName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'START_DATE',
					primaryValue: 'startDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'END_DATE',
					primaryValue: 'endDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'SUBJECT',
					primaryValue: 'subject',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'FREQUENCY',
					primaryValue: 'frequency',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'STATUS',
					primaryValue: 'status',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'RECIPIENTS',
					primaryValue: 'recipients',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'LAST_SEND_DATE',
					primaryValue: 'lastSendDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'NEXT_SEND_DATE',
					primaryValue: 'nextSendDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'Edit',
					value: 'edit',
					type: 'button',
					functionName: 'edit',
					children: null
				},
				{
					name: 'Delete',
					value: 'delete',
					type: 'button',
					functionName: 'delete',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'Delete',
					value: 'delete',
					type: 'button',
					functionName: 'deleteMultiple',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: false,
					type: 'button'
				}
			]
		},
		TEMPLATES: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'REPORT_TYPE',
					primaryValue: 'reportType',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'DATE_RANGE',
					primaryValue: 'dateRange',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'CREATED_AT',
					primaryValue: 'createdAt',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'CREATED_BY',
					primaryValue: 'createdBy',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'SCHEDULED_REPORTS',
					primaryValue: 'scheduledReports',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'EDIT',
					value: 'edit',
					type: 'button',
					functionName: 'edit',
					children: null
				},
				{
					name: 'DUPLICATE',
					value: 'duplicate',
					functionName: 'duplicate',
					type: 'button',
					children: null
				},
				{
					name: 'EXPORT_RAW_DATA',
					value: 'exportRawData',
					type: 'button',
					functionName: 'exportRawData',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'delete',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'DUPLICATE',
					value: 'duplicateMultiple',
					functionName: 'duplicateMultiple',
					type: 'button',
					children: null
				},
				{
					name: 'SAVE_AS_REPORTS',
					value: 'saveAsTemplates',
					functionName: 'saveAsReportsMultiple',
					type: 'button',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteMultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		PIXEL: {
			columns: [
				{
					name: 'STATUS',
					primaryValue: 'status',
					secondaryValue: null,
					type: 'toggle',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: false
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: 'facebookId',
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: true
				},
				{
					name: 'ACTIVITY',
					primaryValue: 'activity',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: false
				},
				{
					name: 'EVENTSRECEIVED',
					primaryValue: 'eventsReceived',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: false
				},
				{
					name: 'AD_ACCOUNT',
					primaryValue: 'adAccountId',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: true
				},
				{
					name: 'DOMAIN',
					primaryValue: 'domain',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: true
				},
				{
					name: 'CREATOR',
					primaryValue: 'creator',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: true
				},
				{
					name: 'OWNERBUSINESSNAME',
					primaryValue: 'ownerBusinessName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: true
				}
			],
			singleSelectionActions: [
				{
					name: 'Create audience',
					value: 'createAudience',
					functionName: 'createAudience',
					type: 'button',
					children: null
				},
				{
					name: 'Create new campaign',
					value: 'createNewCampaign',
					functionName: 'createNewCampaign',
					type: 'button',
					children: null
				},
				{
					name: 'Show events',
					value: 'showEvents',
					functionName: 'showEvents',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				}
			]
		},
		INVOICE: {
			columns: [
				{
					name: 'INVOICE_NUMBER',
					primaryValue: 'chargebeeInvoiceId',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '20',
					sticky: true
				},
				{
					name: 'INVOICE_DATE',
					primaryValue: 'invoiceDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '20',
					sticky: true
				},
				{
					name: 'INVOICE_AMOUNT',
					primaryValue: 'invoiceAmount',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '20',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '100',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'DOWNLOAD_INVOICE',
					value: 'downloadPdf',
					functionName: 'downloadPDF',
					type: 'button',
					children: null
				},
				{
					name: 'SHARE_INVOICE',
					value: 'shareInvoice',
					functionName: 'shareInvoice',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [],
			filters: [
				{
					name: 'expand',
					show: false,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: false,
					type: 'button'
				}
			]
		},
		CARDS: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'LAST_FOUR_DIGITS',
					primaryValue: 'last4Digits',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'EXPIRE_DATE',
					primaryValue: 'expireDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'BRAND',
					primaryValue: 'brand',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'FUNDING',
					primaryValue: 'funding',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'COUNTRY',
					primaryValue: 'country',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'SET_PRIMARY',
					value: 'setPrimary',
					functionName: 'setPrimary',
					type: 'button',
					children: null,
					displayForRowProperty: 'isNotPrimary'
				},
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteCard',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'DELETE',
					value: 'deleteMultiple',
					functionName: 'deleteCardMultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		BACK_OFFICE_TABLE: {
			columns: [
				{
					name: 'CLIENT_ID',
					primaryValue: 'clientId',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'COMPANY_BACK_OFFICE',
					primaryValue: 'company',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'DATE_CREATED_BACK_OFFICE',
					primaryValue: 'dateCreated',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'EMAIL_BACK_OFFICE',
					primaryValue: 'email',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'CONTACT_STATUS',
					primaryValue: 'contactStatus',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OFFER_ID',
					primaryValue: 'offerId',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '50',
					sticky: false
				},
				{
					name: 'BASE_OFFER_NAME',
					primaryValue: 'baseOfferName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '50',
					sticky: false
				},
				{
					name: 'ACTIVATION_DATE',
					primaryValue: 'hsCreationDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OWNER',
					primaryValue: 'owner',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'LAST_LOGIN',
					primaryValue: 'lastLogin',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true,
					displayForRowProperty: 'isChampion'
				}
			],
			singleSelectionActions: [
				{
					name: 'Edit offer',
					value: 'editOffer',
					functionName: 'editOffer',
					type: 'button',
					children: null,
					displayForRowProperty: 'offerId'
				},
				{
					name: 'Create offer',
					value: 'createOffer',
					functionName: 'createOffer',
					type: 'button',
					children: null,
					displayForRowProperty: 'createOfferCondition'
				},
				// {
				//   name: 'CHANGE_OFFER',
				//   value: 'changeOffer',
				//   functionName: 'changeOfferDialog',
				//   type: 'button',
				//   children: null,
				//   displayForRowProperty: 'showChangeOffer'
				// },
				// {
				//   name: 'ACTIVATE_OFFER',
				//   value: 'activateOffer',
				//   functionName: 'activateOffer',
				//   type: 'button',
				//   children: null,
				//   displayForRowProperty: 'showActivateOffer'
				// },
				{
					name: 'Add billing details',
					value: 'addBillingDetails',
					functionName: 'addBillingDetailsDialog',
					type: 'button',
					children: null
				},
				// {
				//   name: 'SHOW_PAYMENT_METHODS',
				//   value: 'showPaymentMethods',
				//   functionName: 'showPaymentMethodsDialog',
				//   type: 'button',
				//   children: null
				// },
				// {
				//   name: 'CHANGE_PAYMENT_METHOD',
				//   value: 'changePaymentMethod',
				//   functionName: 'changePaymentMethodDialog',
				//   type: 'button',
				//   children: null
				// },
				{
					name: 'Activate subscription',
					value: 'activate',
					type: 'button',
					functionName: 'activateDialog',
					children: null,
					displayForRowProperty: 'showActivateSubscription'
				}
				// {
				//   name: 'DELETE_SUBSCRIPTION',
				//   value: 'deactivate',
				//   functionName: 'unsubscribeDialog',
				//   type: 'button',
				//   children: null,
				//   displayForRowProperty: 'showDeactivateSubscription'
				// },
				// {
				//   hint: 'TODO: // ask on the group and check for Luke confirmation',
				//   name: 'REACTIVATE_SUBSCRIPTION',
				//   value: 'deactivate',
				//   functionName: 'unsubscribeDialog',
				//   type: 'button',
				//   children: null,
				//   displayForRowProperty: 'showDeactivateSubscription'
				// }
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		PAYMENT_METHODS_TABLE: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'LAST_FOUR_DIGITS',
					primaryValue: 'last4Digits',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'EXPIRE_DATE',
					primaryValue: 'expireDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'BRAND',
					primaryValue: 'brand',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'FUNDING',
					primaryValue: 'funding',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'COUNTRY',
					primaryValue: 'country',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteCard',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'DELETE',
					value: 'deleteMultiple',
					functionName: 'deleteCardMultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		BILLING_TABLE_BACK_OFFICE: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'CLIENT_NAME',
					primaryValue: 'clientName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'CLIENT_ID',
					primaryValue: 'clientId',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'OFFER',
					primaryValue: 'offer',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'TRIAL',
					primaryValue: 'trialStatus',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'TRIAL_END_DATE',
					primaryValue: 'trialEndDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'FACEBOOK_SPEND',
					primaryValue: 'totalSpendingOnFacebook',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: true
				},
				{
					name: 'BILLING_STATUS',
					primaryValue: 'billingStatus',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '80',
					sticky: true
				},
				{
					name: 'AMOUNT_PAID',
					primaryValue: 'totalWithoutVat',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100'
				},
				{
					name: 'SUBSCRIPTION_START_DATE',
					primaryValue: 'subscriptionStartDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'NEXT_BILLING_ON',
					primaryValue: 'nextBillingOn',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true,
					displayForRowProperty: 'downloadPdfCondition'
				}
			],
			singleSelectionActions: [
				{
					name: 'Download invoice',
					value: 'downloadPDF',
					functionName: 'downloadPDF',
					type: 'button',
					children: null,
					displayForRowProperty: 'downloadPdfCondition'
				},
				{
					name: 'Decrease invoice 1 month',
					value: 'decreaseInvoiceOneMonth',
					functionName: 'decreaseInvoiceOneMonth',
					type: 'button',
					children: null,
					displayForRowProperty: 'decreaseInvoiceMonthCondition'
				}
			],
			multipleSelectionActions: [
				{
					name: 'DOWNLOAD_INVOICES',
					value: 'downloadPDFmultiple',
					functionName: 'downloadPDFmultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		BILLING_TABLE_BILLING: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'INVOICE_ID',
					primaryValue: 'invoiceId',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'BILLING_STATUS',
					primaryValue: 'billingStatus',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'TOTAL_SPEND_ON_FACEBOOK',
					primaryValue: 'totalSpendingOnFacebook',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'INVOICE_SENT_DATE',
					primaryValue: 'invoiceSentDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'VAT_AMOUNT',
					primaryValue: 'vatAmount',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'TOTAL_WITHOUT_VAT',
					primaryValue: 'totalWithoutVat',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'TOTAL_PAID',
					primaryValue: 'totalPaid',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'DUE_DATE_BILLING',
					primaryValue: 'dueDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'NEXT_INVOICE_DATE_BILLING',
					primaryValue: 'nextInvoiceDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'DOWNLOAD_CSV',
					value: 'downloadCSV',
					type: 'button',
					functionName: 'downloadCSV',
					children: null
				},
				{
					name: 'DOWNLOAD_INVOICE',
					value: 'downloadPDF',
					functionName: 'downloadPDF',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'EXPORT_INVOICES',
					value: 'createReport',
					functionName: 'createReport',
					type: 'button',
					children: null
				},
				{
					name: 'DOWNLOAD_INVOICES',
					value: 'downloadPDFmultiple',
					functionName: 'downloadPDFmultiple',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		OFFERS_TABLE: {
			columns: [
				{
					name: 'BASE_OFFER_ID',
					primaryValue: 'offerId',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'OFFER_NAME',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'CURRENCY',
					primaryValue: 'currency',
					secondaryValue: 'currencyName',
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OFFER_MIN_AMOUNT_SPENT',
					primaryValue: 'amountSpentMin',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'OFFER_MAX_AMOUNT_SPENT',
					primaryValue: 'amountSpentMax',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'OFFER_MIN_PRICE',
					primaryValue: 'minPrice',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'OFFER_OVERAGE',
					primaryValue: 'overage',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'OFFER_IS_AGENCY',
					primaryValue: 'isAgency',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'OFFER_IS_DELETED',
					primaryValue: 'isDeleted',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				// {
				//   name: 'TYPE_OF_SERVICE',
				//   primaryValue: 'typeOfService',
				//   secondaryValue: null,
				//   type: 'text',
				//   displayedByDefault: true,
				//   width: '100',
				//   sticky: false
				// },
				{
					name: 'OFFER_FEE',
					primaryValue: 'feePercentage',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OFFER_CREATION_TIME',
					primaryValue: 'creationTime',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OFFER_MODIFICATION_TIME',
					primaryValue: 'updatedAt',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OFFER_USER_MODIFICATION',
					primaryValue: 'updatedById',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'OFFER_CREATED_BY',
					primaryValue: 'creatorUserId',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'EDIT',
					value: 'edit',
					type: 'button',
					functionName: 'editOffer',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'ACTIVATE',
					value: 'activateMultiple',
					type: 'button',
					functionName: 'activateOfferMultiple',
					children: null
				},
				{
					name: 'PAUSE',
					value: 'pauseMultiple',
					type: 'button',
					functionName: 'pauseOfferMultiple',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		BACK_OFFICE: {
			noPagination: true,
			columns: [
				{
					name: 'CLIENT_ID',
					primaryValue: 'clientId',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'DATE_CREATED_BACK_OFFICE',
					primaryValue: 'dateCreated',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'SOURCE',
					primaryValue: 'source',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'NAME_BACK_OFFICE',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'EMAIL_BACK_OFFICE',
					primaryValue: 'email',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'PHONE',
					primaryValue: 'phone',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'COMPANY_BACK_OFFICE',
					primaryValue: 'company',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'COUNTRY',
					primaryValue: 'country',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'LIFECYCLE_STAGE',
					primaryValue: 'lifecycleStage',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'CONTACT_STATUS',
					primaryValue: 'contactStatus',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'DEAL_STAGE',
					primaryValue: 'dealStage',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'ACTIVATION_DATE',
					primaryValue: 'activationDate',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'OWNER',
					primaryValue: 'owner',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'LAST_LOGIN',
					primaryValue: 'lastLogin',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'SET_UP',
					value: 'setup',
					type: 'button',
					functionName: 'setUpSingleSelection',
					children: null
				},
				{
					name: 'CREATE_EVENT',
					value: 'createEvent',
					functionName: 'createEventSingleSelection',
					type: 'button',
					children: null
				},
				{
					name: 'CREATE_AUDIENCE',
					value: 'createAudience',
					functionName: 'createAudienceSingleSelection',
					type: 'button',
					children: null
				},
				{
					name: 'CREATE_REPORT',
					value: 'createReport',
					functionName: 'createReportSingleSelection',
					type: 'button',
					children: null
				},
				{
					name: 'SETTINGS',
					value: 'settings',
					functionName: null,
					type: 'dropdown',
					children: [
						{
							name: 'PIXEL_USAGE',
							value: 'pixelUsage',
							type: 'button',
							functionName: 'pixelUsage',
							children: null
						},
						{
							name: 'COOKIE_USAGE',
							value: 'cookieUsage',
							type: 'button',
							functionName: 'cookieUsage',
							children: null
						}
					]
				}
			],
			multipleSelectionActions: [
				{
					name: 'EDIT',
					value: 'edit',
					functionName: 'editMultipleSelection',
					type: 'button',
					children: null
				},
				{
					name: 'CREATE_REPORT',
					value: 'createReport',
					functionName: 'createReportMultipleSelection',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: true,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		AUDIT: {
			columns: [
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					actions: null,
					width: '10',
					sticky: true
				},
				{
					name: 'USER',
					primaryValue: 'user',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'TIMESTAMP',
					primaryValue: 'timestamp',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'ACTION',
					primaryValue: 'action',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'STATE',
					primaryValue: 'status',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'MESSAGE TYPE',
					primaryValue: 'messageType',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'MESSAGE',
					primaryValue: 'message',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'SERVICE',
					primaryValue: 'service',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'SECTION',
					primaryValue: 'section',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				},
				{
					name: 'METHOD',
					primaryValue: 'method',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: true
				}
			],
			singleSelectionActions: [],
			multipleSelectionActions: [],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: true,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		EVENTS: {
			columns: [
				{
					name: 'STATUS',
					primaryValue: 'status',
					secondaryValue: null,
					type: 'toggle',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: false
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: 'facebookId',
					type: 'text',
					displayedByDefault: true,
					width: '400',
					sticky: true,
					locked: false
				},
				{
					name: 'EVENT_TYPE',
					primaryValue: 'type',
					secondaryValue: '',
					type: 'text',
					displayedByDefault: true,
					width: '150',
					sticky: true,
					locked: true
				},
				{
					name: 'EVENT_STATUS',
					primaryValue: 'statusDescription',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '150',
					sticky: false,
					locked: false
				},
				{
					name: 'EVENT_ACTIVITY',
					primaryValue: 'eventActivity',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '400',
					sticky: false,
					locked: false
				},
				{
					name: 'EVENT_SOURCE',
					primaryValue: 'pixelName',
					secondaryValue: 'pixelId',
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: false
				},
				{
					name: 'EVENT_COUNT',
					primaryValue: 'eventCount',
					secondaryValue: '',
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true,
					locked: false
				}
			],
			singleSelectionActions: [
				{
					name: 'Create audience',
					value: 'createAudience',
					functionName: 'createAudience',
					type: 'button',
					children: null
				},
				{
					name: 'Create new campaign',
					value: 'createNewCampaign',
					functionName: 'createNewCampaign',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		AUDIENCE: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: 'facebookId',
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'BUSINESS_NAME',
					primaryValue: 'businessName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'ACCOUNT_NAME',
					primaryValue: 'adAccountName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'AUDIENCE_STATUS',
					primaryValue: 'state',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'AUDIENCE_DATE_CREATED',
					primaryValue: 'dateCreated',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'AUDIENCE_LAST_UPDATED',
					primaryValue: 'lastUpdated',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'AUDIENCE_SIZE',
					primaryValue: 'size',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'AUDIENCE_SOURCE',
					primaryValue: 'source',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'AUDIENCE_TYPE',
					primaryValue: 'type',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'AUDIENCE_PIXEL_ID',
					primaryValue: 'pixelId',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'Edit',
					value: 'editAudience',
					type: 'button',
					functionName: 'editAudienceSingleSelection'
				},
				{
					name: 'Remove',
					value: 'deleteAudience',
					type: 'button',
					functionName: 'deleteAudienceSingleSelection',
					isDelete: true
				}
			],
			multipleSelectionActions: [
				{
					name: 'Remove',
					value: 'deleteAudience',
					functionName: 'deleteMultipleSelection',
					type: 'button',
					isDelete: true
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		EMAIL: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'STATE',
					primaryValue: 'status',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '10',
					sticky: true
				},
				{
					name: 'FROM',
					primaryValue: 'from',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '25',
					sticky: true
				},
				{
					name: 'RECIPIENTS',
					primaryValue: 'recipients',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '75',
					sticky: true
				},
				{
					name: 'SUBJECT',
					primaryValue: 'subject',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '25',
					sticky: false
				},
				{
					name: 'HAS_ATTACHMENTS',
					primaryValue: 'hasAttachments',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '25',
					sticky: false
				},
				{
					name: 'SENT_TIME',
					primaryValue: 'sentTime',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '25',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'DETAILS',
					value: 'details',
					type: 'button',
					functionName: 'getDetailsSingleSelection',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					type: 'button',
					functionName: 'deleteSingleSelection',
					children: null
				},
				{
					name: 'RESEND',
					value: 'resend',
					type: 'button',
					functionName: 'resendSingleSelection',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'DELETE',
					value: 'delete',
					type: 'button',
					functionName: 'deleteMultipleSelection',
					children: null
				},
				{
					name: 'RESEND',
					value: 'resend',
					type: 'button',
					functionName: 'resendMultipleSelection',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: true,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		CONFIGURATION_SETTINGS: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'NAME',
					primaryValue: 'name',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '25',
					sticky: false
				},
				{
					name: 'EMAIL',
					primaryValue: 'email',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '25',
					sticky: false
				},
				{
					name: 'USERNAME',
					primaryValue: 'username',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '25',
					sticky: false
				},
				{
					name: 'PASSWORD',
					primaryValue: 'password',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '25',
					sticky: false
				},
				{
					name: 'EXTRA_INFO',
					primaryValue: 'extraInfo',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '25',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'EDIT',
					value: 'edit',
					type: 'button',
					functionName: 'editSingleSelection',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					type: 'button',
					functionName: 'deleteSingleSelection',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'EDIT',
					value: 'edit',
					type: 'button',
					functionName: 'editMultipleSelection',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					type: 'button',
					functionName: 'editMultipleSelection',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: true,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		FACEBOOK_ADACCOUNTS: {
			noPagination: true,
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ACCOUNT_NAME',
					primaryValue: 'accountName',
					secondaryValue: 'FacebookId',
					type: 'link',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'BUSINESS_MANAGER',
					primaryValue: 'businessName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACCOUNT_STATUS',
					primaryValue: 'accountStatus',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'CURRENCY',
					primaryValue: 'currency',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'TOTAL_SPEND',
					primaryValue: 'amountSpent',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'CPC',
					primaryValue: 'cpc',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CPM',
					primaryValue: 'cpm',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CPP',
					primaryValue: 'cpp',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CTR',
					primaryValue: 'ctr',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'UNIQUE_CLICKS',
					primaryValue: 'uniqueClicks',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'UNIQUE_CTR',
					primaryValue: 'uniqueCtr',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'BUSINESS_ID',
					primaryValue: 'businessId',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'BUSINESS_FACEBOOK_ID',
					primaryValue: 'businessFacebookId',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'OWNER_FACEBOOK_ID',
					primaryValue: 'ownerFacebookId',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'CLIENT_NAME',
					primaryValue: 'clientName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'CLIENT_ID',
					primaryValue: 'clientId',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'SINCE',
					primaryValue: 'since',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'UNTIL',
					primaryValue: 'until',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'LAST_MODIFICATION_TIME',
					primaryValue: 'lastModificationTime',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'LAST_MODIFIER_USER_ID',
					primaryValue: 'lastModifierUserId',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'LAST_STATUS_CHANGE_ID',
					primaryValue: 'last_status_change_id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'DEFAULT_AMOUNT_SPENT',
					primaryValue: 'defaultAmountSpent',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'SPEND_CAP',
					primaryValue: 'spendCap',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'AGE',
					primaryValue: 'age',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'CLICKS',
					primaryValue: 'clicks',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'IMPRESSIONS',
					primaryValue: 'impressions',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'OPTIMISATION',
					value: 'showOptimization',
					functionName: 'showOptimization',
					type: 'button',
					children: null
				},
				{
					name: 'SHOW_CAMPAIGNS_',
					value: 'showCampaign',
					functionName: 'showCampaign',
					type: 'button',
					children: null
				},
				{
					name: 'EXPORT_DATA',
					value: 'exportSingleData',
					functionName: 'exportSingleData',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'EXPORT_DATA',
					value: 'exportMultipleData',
					functionName: 'exportMultipleData',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button',
					functionName: 'expand'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		GOOGLE_ADACCOUNTS: {
			noPagination: true,
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ACCOUNT_NAME',
					primaryValue: 'adAccountName',
					secondaryValue: null,
					type: 'link',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'AVERAGE_CPC',
					primaryValue: 'averageCpc',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CLICKS',
					primaryValue: 'clicks',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'IMPRESSIONS',
					primaryValue: 'impressions',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CTR',
					primaryValue: 'ctr',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'COST',
					primaryValue: 'cost',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CONVERSIONS',
					primaryValue: 'conversions',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: true,
					width: '80',
					sticky: false
				},
				{
					name: 'CONVERSION_RATE',
					primaryValue: 'conversionRate',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'BUSINESS_FACEBOOK_ID',
					primaryValue: 'businessFacebookId',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: false,
					width: '80',
					sticky: false
				},
				{
					name: 'COST_PER_CONVERSION',
					primaryValue: 'costPerConversion',
					secondaryValue: null,
					type: 'number',
					displayedByDefault: false,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'OPTIMISATION',
					value: 'showOptimization',
					functionName: 'showOptimization',
					type: 'button',
					children: null
				},
				{
					name: 'SHOW_CAMPAIGNS_',
					value: 'showCampaign',
					functionName: 'showCampaign',
					type: 'button',
					children: null
				},
				{
					name: 'EXPORT_DATA',
					value: 'exportSingleData',
					functionName: 'exportSingleData',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'EXPORT_DATA',
					value: 'exportMultipleData',
					functionName: 'exportMultipleData',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button',
					functionName: 'expand'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		RULES: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					actions: null,
					width: '50',
					sticky: true
				},
				{
					name: 'RULE_STATUS',
					primaryValue: 'isActive',
					secondaryValue: null,
					type: 'toggle',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					actions: null,
					width: '10',
					sticky: true
				},
				{
					name: 'RULE_NAME',
					primaryValue: 'name',
					secondaryValue: 'ruleFacebookId',
					type: 'text',
					displayedByDefault: true,
					width: '200',
					sticky: true
				},
				{
					name: 'APPLIED_TO',
					primaryValue: 'appliedTo',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTION_CONDITIONS',
					primaryValue: 'action',
					secondaryValue: 'conditions',
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: false
				},
				{
					name: 'WHEN_RULE_RUNS',
					primaryValue: 'whenRuleRuns',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: false
				},
				{
					name: 'CREATED_BY_USER_NAME',
					primaryValue: 'createdByUserName',
					secondaryValue: 'createdDateTime',
					type: 'text',
					displayedByDefault: true,
					actions: null,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '100',
					sticky: true
				}
			],
			singleSelectionActions: [
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteSingleSelection',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'CHANGE_STATUS',
					value: 'changeStatus',
					functionName: 'changeRuleStateMultipleSelection',
					type: 'button',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteMultipleSelection',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		FILED_USERS: {
			columns: [
				{
					name: 'MASTER_CHECKBOX',
					primaryValue: 'masterCheckbox',
					secondaryValue: null,
					type: 'masterCheckbox',
					displayedByDefault: true,
					width: '50',
					sticky: true
				},
				{
					name: 'ID',
					primaryValue: 'id',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '10',
					sticky: false
				},
				{
					name: 'USER_STATUS',
					primaryValue: 'userStatus',
					secondaryValue: null,
					type: 'toggle',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'FIRST_NAME',
					primaryValue: 'firstName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'LAST_NAME',
					primaryValue: 'lastName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: true
				},
				{
					name: 'COMPANY',
					primaryValue: 'company',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'EMAIL',
					primaryValue: 'email',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'PHONE_NUMBER',
					primaryValue: 'phoneNumber',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'BUSINESS_ACCESS_LEVEL',
					primaryValue: 'businessAccessLevel',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '100',
					sticky: false
				},
				{
					name: 'AD_ACCOUNT_NAME',
					primaryValue: 'adAccountName',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: false,
					width: '100',
					sticky: false
				},
				{
					name: 'AD_ACCOUNT_ACCESS_LEVEL',
					primaryValue: 'adAccountAccessLevel',
					secondaryValue: null,
					type: 'text',
					displayedByDefault: true,
					width: '100',
					sticky: false
				},
				{
					name: 'ACTIONS',
					primaryValue: 'actions',
					secondaryValue: null,
					type: 'actions',
					displayedByDefault: true,
					width: '80',
					sticky: false
				}
			],
			singleSelectionActions: [
				{
					name: 'EDIT',
					value: 'edit',
					type: 'button',
					functionName: 'editSS',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteSS',
					type: 'button',
					children: null
				}
			],
			multipleSelectionActions: [
				{
					name: 'ACTIVATE',
					value: 'activate',
					type: 'button',
					functionName: 'activateMS',
					children: null
				},
				{
					name: 'DEACTIVATE',
					value: 'deactivate',
					type: 'button',
					functionName: 'deactivateMS',
					children: null
				},
				{
					name: 'DELETE',
					value: 'delete',
					functionName: 'deleteMS',
					type: 'button',
					children: null
				}
			],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: false,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: false,
					type: 'button'
				},
				{
					name: 'group',
					show: false,
					type: 'button'
				},
				{
					name: 'columns',
					show: false,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		CUSTOMER_FILE_WIHOUT_LTV: {
			columns: [
				{
					name: 'EMAIL_ADDRESS',
					primaryValue: 'emailAddress',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'PHONE_NUMBER',
					primaryValue: 'phoneNumber',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'FIRST_NAME',
					primaryValue: 'firstName',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'SURNAME',
					primaryValue: 'surname',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'STATE_PROVINCE',
					primaryValue: 'stateProvince',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'COUNTRY',
					primaryValue: 'country',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'DATE_OF_BIRTH',
					primaryValue: 'dateOfBirth',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'YEAR_OF_BIRTH',
					primaryValue: 'yearOfBirth',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'AGE',
					primaryValue: 'age',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'ZIP_POSTCODE',
					primaryValue: 'postCode',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'GENDER',
					primaryValue: 'gender',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'MOBILE_ADVERTISER_ID',
					primaryValue: 'mobileAdvertiserId',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'FACEBOOK_APP_USER_ID',
					primaryValue: 'facebookAppUserId',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				}
			],
			singleSelectionActions: [],
			multipleSelectionActions: [],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: true,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		},
		CUSTOMER_FILE_WITH_LTV: {
			columns: [
				{
					name: 'EMAIL_ADDRESS',
					primaryValue: 'emailAddress',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'PHONE_NUMBER',
					primaryValue: 'phoneNumber',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'FIRST_NAME',
					primaryValue: 'firstName',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'SURNAME',
					primaryValue: 'surname',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'STATE_PROVINCE',
					primaryValue: 'stateProvince',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'COUNTRY',
					primaryValue: 'country',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'DATE_OF_BIRTH',
					primaryValue: 'dateOfBirth',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'YEAR_OF_BIRTH',
					primaryValue: 'yearOfBirth',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'AGE',
					primaryValue: 'age',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'ZIP_POSTCODE',
					primaryValue: 'postCode',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'GENDER',
					primaryValue: 'gender',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'MOBILE_ADVERTISER_ID',
					primaryValue: 'mobileAdvertiserId',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'FACEBOOK_APP_USER_ID',
					primaryValue: 'facebookAppUserId',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				},
				{
					name: 'CUSTOMER_LIFETIME_VALUE',
					primaryValue: 'customerLifetimeValue',
					type: 'text',
					displayedByDefault: true,
					width: '50'
				}
			],
			singleSelectionActions: [],
			multipleSelectionActions: [],
			filters: [
				{
					name: 'expand',
					show: true,
					type: 'button'
				},
				{
					name: 'filter',
					show: true,
					type: 'button'
				},
				{
					name: 'breakdown',
					show: true,
					type: 'button'
				},
				{
					name: 'group',
					show: true,
					type: 'button'
				},
				{
					name: 'columns',
					show: true,
					type: 'button'
				},
				{
					name: 'export',
					show: true,
					type: 'button'
				}
			]
		}
	}
};
