import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangePassword } from '../../../_models/identity-models/change-password';
import { UserServiceApi } from '../../../_services/user/user.api.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPasswordComponent } from '../../dialogs/confirm-password/confirm-password.component';
import { getApiEnums, SharedState } from '../../../shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { FiledApiModels, FiledUsersApiModel } from '../../../shared/state/state-entities/errors/errors.model';
import { PasswordValidator } from '../../models/validators';
import { FiledUsersApiCodesEnum } from '../../../shared/state/state-entities/errors/api-codes.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';
import { SettingsPageLabelsEnum } from '../../shared/enums/navigation.enum';
import { CustomValidator } from '../../../shared/form-input/input-password/custom-validator';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
	public changePasswordForm: FormGroup;

	public currentPasswordControl = new FormControl('', [Validators.required]);
	public newPasswordControl = new FormControl('', [
		Validators.required,
		CustomValidator.patternValidator(/\d/, {
			hasNumber: true
		}),
		CustomValidator.patternValidator(/[A-Z]/, {
			hasCapitalCase: true
		}),
		CustomValidator.patternValidator(/[a-z]/, {
			hasSmallCase: true
		}),
		CustomValidator.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
			hasSpecialCharacters: true
		}),
		Validators.minLength(8)
	]);
	public confirmPasswordControl = new FormControl();

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;

	public passwordValidator = PasswordValidator;

	private apiCodes: FiledApiModels;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private router: Router,
		private toastNotificationService: ToastNotificationService,
		private dialog: MatDialog,
		private userServiceApi: UserServiceApi,
		private formBuilder: FormBuilder,
		private store: Store<SharedState>
	) {}

	public ngOnInit(): void {
		this.initializeFormControls();
		this.fetchStore();
		this.changePasswordForm.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
			const newPassword = this.newPasswordControl.value;
			const currentPassword = this.currentPasswordControl.value;
			const confirmPassword = this.confirmPasswordControl.value;
			if (newPassword === currentPassword) {
				this.newPasswordControl.setErrors({ currentPasswordMatchNew: true });
			}
			if (newPassword !== confirmPassword) {
				this.confirmPasswordControl.setErrors({ NoPasswordMatch: true, validationMessage: 'validationMessage' });
			}
		});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onSubmit(): void {
		const changePassword = {
			CurrentPassword: this.changePasswordForm.get('currentPasswordControl').value,
			NewPassword: this.changePasswordForm.get('newPasswordControl').value,
			ConfirmNewPassword: this.changePasswordForm.get('newPasswordControl').value
		} as ChangePassword;
		this.store.dispatch(new ShowGlobalSpinner());
		this.userServiceApi
			.changePassword(changePassword)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					this.store.dispatch(new HideGlobalSpinner());
					this.dialog.open(ConfirmPasswordComponent);
				},
				err => {
					this.store.dispatch(new HideGlobalSpinner());
					if (err) {
						const errors = err.error;
						let messages = '';
						errors.forEach((error: FiledUsersApiModel) => {
							const code = error.code;
							let message = '';
							for (let value in FiledUsersApiCodesEnum) {
								if (Number(FiledUsersApiCodesEnum[code]) === +value) {
									message = this.apiCodes.filedApiUsersState.filedUsersApiCodesState[Number(value) - 1].message;
								}
								if (Number(value) === FiledUsersApiCodesEnum.Filed__Domain__NA__SetPassword__IsInvalidPasswordMismatch) {
									this.currentPasswordControl.setErrors({ incorect: true });
								}
							}

							messages += message + '\n';
						});

						this.toastNotificationService.sendErrorToast(messages);
					} else {
						this.toastNotificationService.sendErrorToast('An error occurred while trying to change your password!');
					}
				}
			);
	}

	public checkValidForm(): boolean {
		return !!(this.currentPasswordControl.errors || this.confirmPasswordControl.errors || this.newPasswordControl.errors);
	}

	public checkValidation(): void {
		if (this.checkValidForm()) {
			this.changePasswordForm.markAllAsTouched();
		} else {
			this.onSubmit();
		}
	}

	private initializeFormControls(): void {
		this.changePasswordForm = this.formBuilder.group({
			currentPasswordControl: this.currentPasswordControl,
			newPasswordControl: this.newPasswordControl,
			confirmPasswordControl: this.confirmPasswordControl
		});
	}

	private fetchStore(): void {
		this.store.pipe(select(getApiEnums), takeUntil(this.unsubscriber$)).subscribe(apiCodes => {
			this.apiCodes = apiCodes;
		});
	}
}
