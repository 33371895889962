import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import * as reportingActions from '../state/reporting.action';
import { select, Store } from '@ngrx/store';
import { getDashboards, getDashboardTemplates, ReportingState } from '../state/reporting.reducer';
import { filter, take, tap } from 'rxjs/operators';
import { DashboardModel } from '../models/dashboard.model';
import { SharedState } from '../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';

@Injectable()
export class DashboardTemplateResolver implements Resolve<DashboardModel[]> {
	constructor(private router: Router, private store: Store<ReportingState>, private sharedStore: Store<SharedState>) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DashboardModel[]> | Promise<DashboardModel[]> | DashboardModel[] {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.store.dispatch(new reportingActions.LoadDashboardTemplates());
		return this.store.pipe(
			select(getDashboardTemplates),
			filter(dashboards => dashboards != null),
			take(1),
			tap(() => {
				this.store
					.select(getDashboards)
					.pipe(take(1))
					.subscribe(dashboards => {
						if (dashboards) {
							this.sharedStore.dispatch(new HideGlobalSpinner());
						}
					});
			})
		);
	}
}
