import { UtilsService } from '../utils';
import _ from 'lodash';

export class FirstLevelDelta {
	public areEqual: boolean;
	public delta: ObjectDifference[];
	public particularities?: string; // "error" output for when provided objects are of different types and other corner cases

	public areDifferent = (): boolean => !this.areEqual;

	constructor(areEqual: boolean, delta: ObjectDifference[] = [], particularities?: string) {
		this.areEqual = areEqual;
		this.delta = delta;
		if (particularities) {
			this.particularities = particularities;
		}
	}
}

export interface ObjectDifference {
	x: PropertyDescription;
	y: PropertyDescription;
}

export interface PropertyDescription {
	propertyName: string;
	value: any;
}

export class DeepComparer {
	public static deepEquals(x: any, y: any, treatArraysAsSets = false, ignorePrototypeChain = true) {
		// If both x and y are null or undefined and exactly the same
		if (x === y) {
			return true;
		}

		// If they are not strictly equal, they both need to be Objects
		if (!(x instanceof Object) || !(y instanceof Object)) {
			return false;
		}

		// They must have the exact same prototype chain, the closest we can do is
		// test the constructor.
		if (x.constructor !== y.constructor) {
			if (!ignorePrototypeChain) {
				return false;
			}
		}

		// Handles cases where {a:[1,2,3]} should be equal to {a:[2,1,3]}
		if (treatArraysAsSets && x instanceof Array && y instanceof Array) {
			// Compares lengths. Wether this matters should also be specified in a parameter. Makes [1,2,3] not equal to [1,2,2,3]
			if (x.length !== y.length) {
				return false;
			}

			return DeepComparer.allXinY(x, y) && DeepComparer.allXinY(y, x);
		}

		for (const p in x) {
			// Inherited properties were tested using x.constructor === y.constructor
			if (x.hasOwnProperty(p)) {
				// Allows comparing x[ p ] and y[ p ] when set to undefined
				if (!y.hasOwnProperty(p)) {
					return false;
				}

				// If they have the same strict value or identity then they are equal
				if (x[p] === y[p]) {
					continue;
				}

				// Numbers, Strings, Functions, Booleans must be strictly equal
				if (typeof x[p] !== 'object') {
					return false;
				}

				// Objects and Arrays must be tested recursively
				if (!DeepComparer.deepEquals(x[p], y[p], treatArraysAsSets)) {
					return false;
				}
			}
		}

		for (const p in y) {
			// allows x[ p ] to be set to undefined
			if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
				return false;
			}
		}
		return true;
	}

	public static firstLevelDelta(x: any, y: any, treatArraysAsSets = true): FirstLevelDelta {
		x = DeepComparer.removeNullAndUndefined(x);
		y = DeepComparer.removeNullAndUndefined(y);

		// returns a description of the first level diff
		const delta: ObjectDifference[] = [];

		if (x === y) {
			return new FirstLevelDelta(true);
		}

		if (!(x instanceof Object) || !(y instanceof Object)) {
			return new FirstLevelDelta(false, [], 'parameters are not both objects');
		}

		if (x.constructor !== y.constructor) {
			return new FirstLevelDelta(false, [], 'parameters do not have the same prototype chain');
		}

		for (const p in x) {
			// Inherited properties were tested using x.constructor === y.constructor
			if (x.hasOwnProperty(p)) {
				// Allows comparing x[ p ] and y[ p ] when set to undefined
				if (!y.hasOwnProperty(p)) {
					delta.push({
						x: {
							propertyName: p,
							value: x[p]
						},
						y: {
							propertyName: p,
							value: null
						}
					});
				}

				// If they have the same strict value or identity then they are equal
				if (x[p] === y[p]) {
					continue;
				}

				// Numbers, Strings, Functions, Booleans must be strictly equal
				if (typeof x[p] !== 'object') {
					delta.push({
						x: {
							propertyName: p,
							value: x[p]
						},
						y: {
							propertyName: p,
							value: y[p]
						}
					});
					continue;
				}

				// Objects and Arrays must be tested recursively
				if (!DeepComparer.deepEquals(x[p], y[p], treatArraysAsSets)) {
					delta.push({
						x: {
							propertyName: p,
							value: x[p]
						},
						y: {
							propertyName: p,
							value: y[p]
						}
					});
				}
			}
		}

		for (const p in y) {
			// allows x[ p ] to be set to undefined
			if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
				delta.push({
					x: {
						propertyName: p,
						value: null
					},
					y: {
						propertyName: p,
						value: y[p]
					}
				});
			}
		}

		return new FirstLevelDelta(delta.length === 0, delta);
	}

	public static assembleProperties<T>(dataComperer: FirstLevelDelta): T {
		return dataComperer.delta.reduce((acc: any, item: any) => {
			acc[item.x.propertyName] = item.x.value;
			return acc;
		}, {}) as T;
	}

	private static allXinY(x: any[], y: any[]): boolean {
		let foundMatch = false;
		for (const xElement of x) {
			for (const yElement of y) {
				if (DeepComparer.deepEquals(xElement, yElement, true)) {
					foundMatch = true;
					break;
				}
			}
			if (!foundMatch) {
				return false;
			}
		}
		return true;
	}

	private static removeNullAndUndefined(x: any) {
		x = _.cloneDeep(x);
		for (const p in x) {
			if (
				x[p] === null ||
				x[p] === undefined ||
				(Array.isArray(x[p]) && x[p].length == 0) ||
				(typeof x[p] === 'object' && UtilsService.objectIsEmpty(x[p]))
			) {
				delete x[p];
			}
		}
		return x;
	}
}
