<app-expansion-panel-sc [expansionTitle]="'Budget'" [isExpandable]="false" [isSelected]="isSelected" [contentMargin]="true" (click)="onExpansionClick.emit()">
	<div expansion-content class="expansion">
		<div class="b-toggle">
			<span>Automatically allocate the budget evenly between {{ label }}</span>
			<mat-slide-toggle
				[checked]="isBudgetSplitEvenly"
				[formControl]="control"
				[disabled]="!campaignControl.valid || !adSetsControl.valid"
				(change)="onBudgetToggle()"
			></mat-slide-toggle>
		</div>
		<div *ngIf="!isBudgetSplitEvenly">
			<p>Click inside the budget you would like to edit.</p>
		</div>

		<!--- Campaign Level -->
		<div class="content-campaign" *ngIf="budgeAllocationSplits?.campaignsBudget?.length">
			<div class="content-campaign-level" *ngFor="let campaign of budgeAllocationSplits.campaignsBudget; index as i">
				<span class="campaign-title">{{ campaign.campaignName }}</span>
				<div class="campaign">
					<div class="campaign-budget">
						<app-input-number
							[control]="campaignControl.controls[i]"
							[validatorNumMessages]="validationMessage"
							(keydown.space)="$event.preventDefault()"
							[currencyStr]="currency"
							[decimals]="2"
						></app-input-number>
					</div>
				</div>
			</div>
		</div>

		<!--- Ad Sets Level -->
		<div class="content-ad-set" *ngIf="budgeAllocationSplits?.adSetsBudget?.length">
			<div class="content-ad-set-level" *ngFor="let campaign of budgeAllocationSplits.adSetsBudget; index as i">
				<span class="ad-set-campaign-title">{{ campaign.campaignName }}</span>
				<span class="space"></span>
				<div class="ad-set-content-border">
					<div class="ob-ad-set-campaign-content" *ngFor="let adSet of campaign.adSets; index as l">
						<span class="ad-set-title">{{ adSet.adSetName }}</span>
						<div class="ad-set">
							<div class="ad-set-budget">
								<app-input-number
									[control]="adSetsControl.controls[i * campaign.adSets.length + l]"
									[validatorNumMessages]="validationMessage"
									(keydown.space)="$event.preventDefault()"
									[currencyStr]="currency"
									[decimals]="2"
								></app-input-number>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
