import { DropdownInterface } from '@filed-com/filed-lib';
import { AdModel } from '../../models/ad.model';
import {
	addAdToState,
	removeAdFromState,
	updateAdFacebookId,
	updateAdFormatType,
	updateAdName,
	updateAdverts,
	updateConversion,
	updateEventRule,
	updatePixelAppEventId,
	updatePixelId,
	updatePixelToggle
} from './ad-state-utils';
import { AdActions, AdActionTypes } from './ad-state.actions';

export interface AdState {
	ads: AdModel[];
	currentSelectedAdId: number;
	currentAd: AdModel;
	pageIds: DropdownInterface[];
}

export const initialAdState: AdState = {
	ads: [],
	currentSelectedAdId: 0,
	currentAd: null,
	pageIds: []
};

export function adStateReducer(state = initialAdState, action: AdActions): AdState {
	switch (action.type) {
		case AdActionTypes.AddAd:
			return {
				...state,
				ads: addAdToState(state, action.payload)
			};
		case AdActionTypes.RemoveAd:
			return {
				...state,
				ads: removeAdFromState(state, action.payload)
			};
		case AdActionTypes.ClearAds:
			return {
				...state,
				ads: []
			};
		case AdActionTypes.UpdateCurrentAdId:
			return {
				...state,
				currentSelectedAdId: action.payload
			};
		case AdActionTypes.UpdatePages:
			return {
				...state,
				ads: updateAdFacebookId(state, action.payload)
			};
		case AdActionTypes.UpdateAdFormatType: {
			return {
				...state,
				ads: updateAdFormatType(state, action.payload)
			};
		}
		case AdActionTypes.UpdateAdName: {
			return {
				...state,
				ads: updateAdName(state, action.payload)
			};
		}
		case AdActionTypes.UpdatePixelId:
			return {
				...state,
				ads: updatePixelId(state, action.payload)
			};

		case AdActionTypes.UpdatePixelAppEventId:
			return {
				...state,
				ads: updatePixelAppEventId(state, action.payload)
			};

		case AdActionTypes.UpdatePixelToggle:
			return {
				...state,
				ads: updatePixelToggle(state, action.payload)
			};

		case AdActionTypes.UpdateConversion:
			return {
				...state,
				ads: updateConversion(state, action.payload)
			};

		case AdActionTypes.UpdateEventRule:
			return {
				...state,
				ads: updateEventRule(state, action.payload)
			};

		case AdActionTypes.UpdateAdverts:
			return {
				...state,
				ads: updateAdverts(state, action.payload)
			};
		case AdActionTypes.LoadFacebookPagesSuccess:
			return {
				...state,
				pageIds: action.payload
			};
		case AdActionTypes.ResetState:
			return Object.assign({}, initialAdState);
		default:
			return state;
	}
}
