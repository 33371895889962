import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, Optional, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { catchError, concatMap, map, take, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, interval, of, pipe, Subject } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { BusinessOwnerService } from 'src/app/_services/facebook-accounts/business-owner.service';
import { getFiledId, getPermissions, getUserDetails, isSubUserSignedIn, UserState } from 'src/app/shared/state/user/user.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { FacebookResponse } from 'src/app/authentication/_models/facebook-response.interface';
import { SocialUser } from 'src/app/authentication/_models/social-user';
import { GoogleService } from 'src/app/_services/google/google.service';
import { WindowService } from '@progress/kendo-angular-dialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { googleUnitAcount, googleManagerAcounts, googleUsersAccount } from './auth-signup';
import { AuthenticationState, getAccountConnectionState, getRedirectUrl } from '../../state/authentication.reducer';
import { AccountConnectionState, EnableFirstTimeCustomer, LogIn } from 'src/app/authentication/state/authentication.action';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BillingPlan } from 'src/app/user-management/models/billing-plan.interface';
import { TranslateService } from '@ngx-translate/core';
import { UserStateEnum } from 'src/app/_services/user/user-state.enum';
import { TrialDialogComponent } from '../../trial-dialog/trial-dialog.component';
import { PaymentServiceApi } from 'src/app/_services/payment/payment.api.service';
import { AnySrvRecord } from 'dns';
import { FiledPermissions, ModulesPermissions } from 'src/app/shared/permisions/enums/filed-permissions';
import { Modules } from 'src/app/shared/permisions/enums/modules';
import { NavigationExtras, Router } from '@angular/router';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { SetSubUserStatus, SetUserDetails } from 'src/app/shared/state/user/user.actions';

declare var window: any;
declare var FB: any;
export interface SignupChannelListInterface {
	name: string;
	icon: string;
	bullets: string[];
	status?: boolean;
}
export enum StepEnum {
	step1 = 'step1',
	step2 = 'step2',
	step3 = 'step3',
	step4 = 'step4',
	step4b = 'step4b',
	step5 = 'step5',
	step6 = 'step6'
}
@Component({
	selector: 'app-new-signup-welcome-popup',
	templateUrl: './new-signup-welcome-popup.component.html',
	styleUrls: ['./new-signup-welcome-popup.component.scss']
})
export class NewSignupWelcomePopupComponent implements OnInit {
	public selectedChannel: SignupChannelListInterface;
	public connectionResponse: boolean = false;
	public allStep: typeof StepEnum = StepEnum;
	public currentStep: StepEnum = StepEnum.step1;
	public disableGoogle: boolean = true;
	public Channels: SignupChannelListInterface[] = [
		{
			name: 'Facebook',
			icon: 'assets/icons/social-media-icons/facebook.svg',
			bullets: ['Have at least 1 page', 'Be a business account', 'Have at least 1 ad account'],
			status: true
		},
		{
			name: 'Google',
			icon: 'assets/icons/google-icon.svg',
			bullets: ['Have at least 1 account', 'Have editing permission'],
			status: true
		},
		{
			name: 'Tik Tok',
			icon: 'assets/icons/social-media-icons/tiktok-logo.png',
			bullets: ['Have at least 1 page', 'Be a business account', 'Have at least 1 ad account'],
			status: false
		},
		{
			name: 'Amazon Ads',
			icon: 'assets/icons/social-media-icons/amazon-ads-logo.png',
			bullets: ['Have at least 1 page', 'Be a business account', 'Have at least 1 ad account'],
			status: false
		}
	];
	private permissions: string[] = [
		'pages_show_list',
		'ads_management',
		'business_management',
		'pages_read_engagement',
		'public_profile',
		'catalog_management',
		'read_insights',
		'instagram_basic',
		'pages_read_user_content'
	];
	private fields = 'name,email,picture,first_name,last_name';
	private readonly defaultUnknownEmail = 'unknownFacebook@email.com';

	private unsubscriber$ = new Subject<void>();
	private filedId: number;
	private errorTriggered: boolean;
	private trigerredError: any;
	private successTriggered: boolean;
	private windowHandle: Window;
	private intervalId: any = null;
	private loopCount = 4000;
	private intervalLength = 100;
	private jwtToken: string;
	authorizationCode: string;
	oAuthToken: string;
	oAuthVerifier: string;

	public decodeJwtToken: UserDetailsInterface;
	public pageSelected: any;
	public isPageSelected: boolean = false;
	public googleSelected: boolean = false;
	public facebookSelected: boolean = false;
	public googleAdsData: googleUsersAccount;
	public facebookAdsData: any;
	public billingPlan: BillingPlan;
	public userState: number;
	public hasPaymentCard: boolean;
	public planDetails: any[] = [];
	public allPermissions: FiledPermissions<ModulesPermissions>[];
	public skipIsFired: boolean = false;
	public isFacebookConnected = false;
	public isGoogleConnected = false;
	public googleImgSrc = 'assets/icons/onboarding/google/btn_google_signin_dark_normal_web.png';

	constructor(
		private businessOwnerService: BusinessOwnerService,
		private userStore: Store<UserState>,
		private toastNotification: ToastNotificationService,
		private sharedStore: Store<SharedState>,
		private authStore: Store<AuthenticationState>,
		private changeDetectorRef: ChangeDetectorRef,
		private ngZone: NgZone,
		private googleService: GoogleService,
		private userService: UserServiceApi,
		private dialogRef: MatDialogRef<NewSignupWelcomePopupComponent>,
		private dialog: MatDialog,
		private backOfficeService: BackOfficeService,
		private billingService: PaymentServiceApi,
		private router: Router,
		private translate: TranslateService,
		private infoDialog: MatDialog,
		@Optional() @Inject(MAT_DIALOG_DATA) private data: any
	) {
		this.verifyUser();
		this.checkConnectionStatus();
	}

	public ngOnInit(): void {
		this.userStore.pipe(select(getFiledId), takeUntil(this.unsubscriber$)).subscribe(filedId => {
			this.filedId = filedId;
		});
		this.jwtToken = localStorage.getItem('token');
		this.decodeJwtToken = JSON.parse(localStorage.getItem('decodedJwtIo')) as UserDetailsInterface;
		this.getUserPsermissions();
		this.loadFacebook();

		interval(500)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				if (this.errorTriggered) {
					if (this.trigerredError instanceof HttpErrorResponse) {
						if (this.trigerredError.status === 400 && this.trigerredError.error) {
							this.toastNotification.sendErrorToast('Facebook account not supported, no bussinesses associated to it');
						} else if (this.trigerredError.error.message) {
							this.toastNotification.sendErrorToast(this.trigerredError.error.message);
						} else {
							this.toastNotification.sendErrorToast(
								'Seems like we have had trouble connecting your Facebook account, please try again. If this error persists contact help@filed.com'
							);
						}
					} else if (this.trigerredError.error?.message) {
						this.toastNotification.sendErrorToast(this.trigerredError.error.message);
					} else {
						this.toastNotification.sendErrorToast(
							'Seems like we have had trouble connecting your Facebook account, please try again. If this error persists contact help@filed.com'
						);
					}
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.errorTriggered = false;
				} else if (this.successTriggered) {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.successTriggered = false;
				}
			});
		let skipConnect = localStorage.getItem('skipConnect');
		if (skipConnect == 'skip') {
			this.getStarted(this.allStep.step2);
		}

		this.authStore.pipe(select(isSubUserSignedIn), takeUntil(this.unsubscriber$)).subscribe(isSubUser => {
			if (isSubUser) this.skipConnection();
		});
		//this.getStarted(this.allStep.step5);
	}

	public loadFacebook(): void {
		window.fbAsyncInit = () => {
			FB.init({
				appId: '174014546372191',
				// appId: isDevMode() ? '1030669141081257' : '174014546372191', //TO BE UPDATED
				autoLogAppEvents: true,
				cookie: true,
				xfbml: true,
				version: 'v10.0'
			});
			FB.AppEvents.logPageView();
		};

		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			(js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	}

	public getUserPsermissions(): void {
		this.sharedStore.pipe(select(getPermissions), take(1)).subscribe(roles => {
			for (var ln in roles) {
				let obj = this.getLink(roles[ln].module);
				if (obj !== undefined) {
					this.planDetails.push(obj);
				}
			}
		});
	}

	public getTarrifDetails(): void {
		this.billingService
			.getCurrentTariffModules()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((details: any) => {
				this.planDetails.push(
					details.map(x => {
						return {
							...[this.getLink(x)]
						};
					})
				);
			});
	}

	private getLink(module: Modules): any {
		switch (module) {
			case Modules.Accounts:
				return {
					text: 'Accounts',
					url: '/accounts',
					img: 'assets/icons/creative-builder/Single_Ad-Icon.svg',
					description: "Track & monitor all of your ad account's performance from a single dashboard",
					skip: false
				};
			case Modules.AdsManager:
				return {
					text: 'Ads Manager',
					url: '/ads-manager/insights',
					description: 'Manage and edit all your campaign at one place',
					skip: false
				};
			case Modules.Audience:
				return {
					text: 'Audience',
					url: '/audience',
					description:
						'Experiment with high performing pre built audience suggestions. Or create high performing audiences based on your existing data',
					skip: false
				};
			case Modules.CampaignBuilder:
				return {
					text: 'Campaign Builder',
					url: '/campaign/welcome',
					description: 'Create dexter assist high performing campaigns',
					skip: true
				};
			case Modules.Influencer:
				return {
					text: 'Influencer',
					url: '/influencer',
					description: "Make quality influencer marketing campaigns that expands your brand's awareness instantly",
					skip: true
				};
			case Modules.Optimize:
				return {
					text: 'Optimize',
					url: '/optimize/dashboard',
					description: "Optimize your advertising campaign's performance based on AI based recommendations and new ideas.",
					skip: false
				};
			case Modules.Pixel:
				return {
					text: 'Pixel',
					url: '/pixel/pixelTab',
					description: 'Manage your tracking codes across different platforms. All from a single place',
					skip: false
				};
			case Modules.ProductCatalog:
				return {
					text: 'Feed manager',
					url: '/catalog/catalog-get-started',
					description: 'Import and export product data from one channel to another',
					skip: true
				};
			case Modules.CreativeBuilder:
				return {
					text: 'Creative builder',
					url: '/creative-builder/home',
					description: 'Design high performing creative that converts',
					skip: true
				};

			case Modules.Reports:
				return {
					text: 'Report',
					url: '/reports/home',
					description: "Create personalized dashboards to analyze your ad account's performances",
					skip: false
				};
		}
	}
	public login($step: StepEnum): void {
		this.facebookSelected = true;
		FB.login(
			(response: FacebookResponse) => {
				if (response.authResponse) {
					this.sharedStore.dispatch(new ShowGlobalSpinner());
					FB.api(
						'/me',
						{
							fields: this.fields
						},
						(userInfo: any) => {
							const user: SocialUser = new SocialUser();
							user.id = userInfo.id;
							user.name = userInfo.name;
							user.email = userInfo.email || this.defaultUnknownEmail;
							user.photoUrl = 'https://graph.facebook.com/' + userInfo.id + '/picture?type=normal';
							user.firstName = userInfo.first_name;
							user.lastName = userInfo.last_name;
							user.authToken = response.authResponse.accessToken;
							user.facebook = userInfo;

							this.saveFacebookAccount(user, $step);
						}
					);
				} else {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotification.sendErrorToast(
						'Seems like we have had trouble connecting your Facebook account, please try again. If this error persists contact help@filed.com'
					);
					this.getStarted(this.allStep.step2);
				}
			},
			{
				scope:
					'email,pages_show_list,ads_management,business_management,pages_read_engagement,public_profile,catalog_management,read_insights,instagram_basic,pages_read_user_content'
			}
		);
	}

	public saveFacebookAccount(facebookUser: SocialUser, $step: StepEnum): void {
		const account = {
			facebookId: facebookUser.id,
			name: facebookUser.name,
			email: facebookUser.email,
			temporaryToken: facebookUser.authToken,
			requestedPermissions: this.permissions,
			filedUserId: this.decodeJwtToken.user_filed_id.toString()
		};

		this.businessOwnerService
			.connectToFacebookAccount(account)
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.errorTriggered = true;
					this.trigerredError = error;
					this.fbConnectionResponse(false);
					this.getStarted(this.allStep.step2);
					FB.logout();
					return EMPTY;
				})
			)
			.subscribe(() => {
				this.currentStep = $step;
				this.fbConnectionResponse(true);
				this.successTriggered = true;
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.backOfficeService.signUpGetStartedActive$.next('idle');
	}
	public fbConnectionResponse(response: boolean): void {
		if (response) {
			this.getAdsListofFacebook(this.allStep.step4);
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			this.ngZone.run(() => {
				this.authStore.dispatch(new EnableFirstTimeCustomer());
				this.backOfficeService
					.getBillingPlan()
					.pipe(takeUntil(this.unsubscriber$))
					.subscribe(
						billingPlan => {
							this.userStore.pipe(select(getUserDetails), take(1)).subscribe(userDetails => {
								this.billingPlan = billingPlan;
								this.backOfficeService
									.getUserState(userDetails.FiledId)
									.pipe(takeUntil(this.unsubscriber$))
									.subscribe(state => {
										this.userState = state;
										this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
											this.checkPaymentWallAndOpenTrialPopup(redirectUrl);
										});
									});
							});
						},
						() => {
							this.sharedStore.dispatch(new HideGlobalSpinner());
							this.toastNotification.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
						}
					);
			});
		}
	}

	public checkPaymentWallAndOpenTrialPopup(redirectUrl: string): void {
		this.backOfficeService
			.getAllCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				cards => {
					this.hasPaymentCard = cards && cards.length > 0 ? true : false;
					if (this.billingPlan.isFreeTrial && this.userState === UserStateEnum.Active) {
						this.dialog
							.open<TrialDialogComponent>(TrialDialogComponent, {
								width: '40%',
								data: {
									days: this.billingPlan.freeTrialDuration,
									hasPaymentCard: this.hasPaymentCard
								}
							})
							.afterClosed()
							.subscribe(() => {
								this.backOfficeService.signUpGetStartedActive$.next('active');
								// this.authStore.dispatch(new LogIn());
								this.continueProcesses();
							});
					} else {
						this.backOfficeService.signUpGetStartedActive$.next('active');
						// this.authStore.dispatch(new LogIn());
						this.continueProcesses();
					}
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotification.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
	}

	public continueProcesses(): void {
		this.backOfficeService.signUpGetStartedActive$.pipe(takeUntil(this.unsubscriber$)).subscribe(value => {
			if (value === 'running') {
				// this.getStarted(this.allStep.step4);
				// this.getAdsListofFacebook(this.allStep.step4);

				this.backOfficeService.signUpGetStartedActive$.next('idle');
			}
		});
	}
	public getStarted($step: StepEnum): void {
		this.currentStep = $step;
		if (this.currentStep === this.allStep.step3) {
			switch (this.selectedChannel.name) {
				case 'Facebook':
					this.login($step);
					break;
				case 'Google':
					this.connectToGoogle($step);
					this.googleImg(0);
					break;
				default:
					break;
			}
		}
	}
	public selectChannel(channel: SignupChannelListInterface): void {
		this.selectedChannel = channel;
	}
	public lastSteponGoogle($event: any): void {
		let data = {
			accounts: $event
		};
		this.currentStep = this.allStep.step3;
		this.googleService
			.trackGoogleAds(data)
			.pipe(
				take(1),
				catchError(error => {
					this.currentStep = this.allStep.step4b;
					return EMPTY;
				})
			)
			.subscribe((res: HttpResponse<any>) => {
				this.googleSelected = false;
				this.currentStep = this.allStep.step4;
			});
	}
	public connectToGoogle($step: StepEnum): void {
		this.googleSelected = true;
		this.googleService
			.connectToGoogleUserAccount(`${window.location.origin}/finish-connection`)
			.pipe(take(1))
			.subscribe(
				res => {
					this.doAuthorization(res, '', false, $step);
				},
				() => {
					this.getStarted(this.allStep.step2);
				},
				() => {}
			);
	}
	public getAdsListofGoogle($step: StepEnum): void {
		this.googleService
			.listGoogleAds('True')
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.currentStep = this.allStep.step4b;
					return EMPTY;
				})
			)
			.subscribe((res: HttpResponse<any>) => {
				this.googleAdsData = res.body;
				this.currentStep = $step;
				this.googleAdsData.accounts.forEach(item => {
					item.adaccounts.forEach(item2 => {
						item2.tracked = false;
					});
				});
			});
	}
	public doAuthorization(url: string, socialMediaProvider: string, isRegisterAction: boolean, $step: StepEnum): void {
		let loopCount = this.loopCount;

		this.windowHandle = this.createOauthWindow(url, 'OAuth login');

		this.intervalId = window.setInterval(() => {
			if (loopCount-- < 0) {
				window.clearInterval(this.intervalId);
				this.closeWindow();
			} else if (this.windowHandle?.location?.href === undefined) {
				window.clearInterval(this.intervalId);
				this.toastNotification.sendErrorToast('Google account not supported, no bussinesses associated to it');
				this.getStarted(this.allStep.step2);
			} else if (this.windowHandle.location.href.indexOf('catalog/saved') > -1) {
				window.clearInterval(this.intervalId);
				this.closeWindow();
			} else if (this.windowHandle.location.href.indexOf('finish-connection') > -1) {
				window.clearInterval(this.intervalId);
				this.checkInstalledGoogle();

				this.closeWindow();
			} else {
				let href: string;
				try {
					href = this.windowHandle.location.href;
				} catch (e) {}

				if (href != null) {
					const getQueryString = function (field: any, url: string) {
						const windowLocationUrl = url ? url : href;
						const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
						const string = reg.exec(windowLocationUrl);
						return string ? string[1] : null;
					};

					if (href.match('code')) {
						window.clearInterval(this.intervalId);
						this.authorizationCode = getQueryString('code', href);
						this.closeWindow();

						if (isRegisterAction) {
						} else {
						}
					} else if (href.match('oauth_token')) {
						window.clearInterval(this.intervalId);
						this.oAuthToken = getQueryString('oauth_token', href);
						this.oAuthVerifier = getQueryString('oauth_verifier', href);
						this.closeWindow();

						if (isRegisterAction) {
						} else {
						}
					}
					this.currentStep = $step;
				}
			}
		}, this.intervalLength);
	}
	public closeWindow(): void {
		this.windowHandle.close();
	}
	public createOauthWindow(url: string, name = 'Authorization', width = 500, height = 600, left = 0, top = 0): Window {
		if (url == null) {
			return null;
		}
		const options = `width=${width},height=${height},left=${left},top=${top}`;
		return window.open(url, name, options);
	}
	public checkInstalledGoogle(): void {
		this.googleService
			.checkGoogleStatus()
			.pipe(take(1))
			.subscribe(res => {
				if (res.filed_user_id) {
					this.getAdsListofGoogle(this.allStep.step4);
				} else {
					this.getStarted(this.allStep.step2);
				}
			});
	}

	public selectPage(option: string): void {
		this.pageSelected = option;
		this.isPageSelected = true;
	}
	public routeRequest(): void {
		// this.dialogRef.close({ event: 'close', data: this.pageSelected.url });
		// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		// this.router.onSameUrlNavigation = 'reload';
		// this.router.navigate([this.pageSelected.url]);
		// this.ngZone.run(() => this.router.navigate([this.pageSelected.url]));
		// this.router.navigateByUrl(this.pageSelected.url);
		// this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
		// 	this.router.navigate([this.pageSelected.url]);
		// });
		this.router.navigateByUrl(this.pageSelected.url);
	}

	public skipConnection(): void {
		localStorage.setItem('skipConnect', 'skip');
		this.skipIsFired = true;
		this.getStarted(this.allStep.step5);
	}

	public skipModule(): void {
		localStorage.setItem('skipConnect', 'skip');
		this.skipIsFired = true;
		this.getStarted(this.allStep.step6);
	}

	public checkConnectionStatus(): void {
		this.userStore.select(pipe(getUserDetails), takeUntil(this.unsubscriber$)).subscribe(userDetails => {
			if (userDetails?.FacebookBusinessOwnerId) {
				this.isFacebookConnected = true;
			}
			if (userDetails?.GoogleBusinessOwnerId) {
				this.isGoogleConnected = true;
			}
		});
	}

	public verifyUser(): void {
		this.userService
			.checkIfSubUser()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(isSubuser => {
				this.authStore.dispatch(new SetSubUserStatus(isSubuser));
			});
	}

	public getAdsListofFacebook($step: StepEnum): void {
		this.businessOwnerService
			.listFacebookAds('True')
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.currentStep = this.allStep.step4b;
					this.toastNotification.sendErrorToast('Failed to load facebook ad accounts');
					return EMPTY;
				})
			)
			.subscribe((res: HttpResponse<any>) => {
				this.runFBOp(res, $step);
			});
	}

	public runFBOp(res: HttpResponse<any>, $step: any): void {
		if (res.body?.accounts) {
			this.facebookAdsData = res.body;
			this.facebookAdsData.accounts.forEach(item => {
				item.adaccounts.forEach(item2 => {
					item2.tracked = false;
				});
			});
			this.currentStep = $step;
		} else {
			this.currentStep = this.allStep.step4b;
			this.toastNotification.sendErrorToast('Failed to load facebook ad accounts');
		}
	}

	public lastSteponFacebook($event: any): void {
		// let data = {
		// 	accounts: $event
		// };
		this.currentStep = this.allStep.step3;
		this.businessOwnerService
			.trackFacebookAds($event)
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.currentStep = this.allStep.step4b;
					this.toastNotification.sendErrorToast('Failed to update facebook ad accounts');
					return EMPTY;
				})
			)
			.subscribe((res: HttpResponse<any>) => {
				this.facebookSelected = false;
				this.currentStep = this.allStep.step4;
				this.userStore.pipe(select(getUserDetails), take(1)).subscribe(value => {
					value.AccountsData = $event;
					this.userStore.dispatch(new SetUserDetails(value));
					this.authStore.dispatch(new LogIn());
				});
			});
		// .subscribe((res: HttpResponse<any>) => {
		// 	this.us
		// 	this.facebookSelected = false;
		// 	this.currentStep = this.allStep.step4;
		// });
	}

	public googleImg(type: number): void {
		switch (type) {
			case 0:
				this.googleImgSrc = 'assets/icons/onboarding/google/btn_google_signin_dark_normal_web.png';
				break;
			case 1:
				this.googleImgSrc = 'assets/icons/onboarding/google/btn_google_signin_dark_focus_web.png';
				break;
			case 2:
				this.googleImgSrc = 'assets/icons/onboarding/google/btn_google_signin_dark_pressed_web.png';
				break;
		}
	}
}
