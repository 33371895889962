import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownInterface } from '@filed-com/filed-lib/lib/dropdowns/models/dropdown-interface';
import { select, Store } from '@ngrx/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { getUserProfileUserManagement, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { User } from 'src/app/_models/user-models/user';

@Component({
	selector: 'app-submit-ticket',
	templateUrl: './submit-ticket.component.html',
	styleUrls: ['./submit-ticket.component.scss']
})
export class SubmitTicketComponent implements OnInit {
	public searchControl: FormControl = new FormControl('');
	public isSearchPage = false;

	private unsubscriber$ = new Subject<void>();

	public emailForm: FormGroup;
	public user: User;

	public loading = false;
	public submitSuccess = false;

	constructor(
		private store: Store<UserManagementState>,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public kbService: KnowledgebaseService,
		private toastNotificationService: ToastNotificationService,
		public fb: FormBuilder
	) {
		this.emailForm = this.fb.group({
			subject: ['', Validators.required],
			description: ['', Validators.required],
			emailId: ['', Validators.required],
			files: [null]
		});
	}

	ngOnInit(): void {
		this.store.pipe(select(getUserProfileUserManagement), take(1)).subscribe(user => {
			this.user = user;
			this.emailForm.patchValue({
				emailId: this.user?.email
			});
		});
	}

	public searchKnowledgeBase(): void {
		return;
	}

	public dropDownValueChanges($event: any): void {}

	public submit(): void {
		this.loading = true;
		var formData: any = new FormData();
		formData.append('subject', this.emailForm.get('subject').value);
		formData.append('description', this.emailForm.get('description').value);
		formData.append('emailId', this.emailForm.get('emailId').value);
		formData.append('files', this.emailForm.get('files').value);

		this.kbService
			.submitTicket(formData)
			.pipe(
				take(1),
				catchError(error => {
					this.toastNotificationService.sendErrorToast('There was an error submitting this ticket, please try again.');
					this.loading = false;
					return EMPTY;
				})
			)
			.subscribe(data => {
				this.loading = false;
				this.submitSuccess = true;
			});
	}

	uploadFile(event: any) {
		let file = (event.target as HTMLInputElement).files[0];
		if (file.size > 10000000) {
			this.toastNotificationService.sendErrorToast('File size is greater that 10MB, please attach a file with size not greater than 10MB.');
			return;
		} else if (file.size <= 10000000) {
			this.emailForm.patchValue({
				files: file
			});
			this.emailForm.get('files').updateValueAndValidity();
		}
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
