import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-awesome',
	templateUrl: './awesome.component.html',
	styleUrls: ['./awesome.component.scss']
})
export class AwesomeComponent implements OnInit {
	constructor(private router: Router) {}

	ngOnInit(): void {
		setTimeout(() => {
			this.router.navigate(['/authentication/mobile-welcome']);
		}, 3000);
	}
}
