import { Bits } from './bits';

export enum BackofficePermissions {
	CanAccessBackOffice = Bits.Bit1,
	BillingAndPaymentDecreaseInvoiceOneMonth = Bits.Bit2,
	BillingAndPaymentDownloadInvoice = Bits.Bit3,
	BillingAndPaymentView = Bits.Bit4,
	OffersCreate = Bits.Bit5,
	OffersEdit = Bits.Bit6,
	OffersDelete = Bits.Bit7,
	OffersMakePublicPrivate = Bits.Bit8,
	OffersView = Bits.Bit9,
	RolesAndPermissionsRolesCreate = Bits.Bit10,
	RolesAndPermissionsRolesDelete = Bits.Bit11,
	RolesAndPermissionsRolesEdit = Bits.Bit12,
	RolesAndPermissionsRolesView = Bits.Bit13
}
