<div class="grid-section">
	<div class="grid-section-one">
		<img class="grid-section-one-img" src="assets/images/trial-expired.svg" />
	</div>
	<div class="grid-section-two">
		<div class="grid-gap-left"></div>
		<div class="grid-section-two-inner container">
			<div class="grid-inner-context">
				<div class="filed-logo">
					<img src="assets/logo/lolly-logo.svg" />
				</div>
				<div class="text-description">
					<h2>{{ displayMessage() }}</h2>
					<p>In order to keep using our services, you need to add a payment method to your account.</p>
				</div>
				<div>
					<btn21 [routerLink]="['/authentication/add-billing-address']">Add Payment Now</btn21>
				</div>
			</div>
		</div>
		<div class="grid-gap-right"></div>
	</div>
</div>

<app-toast-notification></app-toast-notification>
