import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Timer } from 'src/app/shared/models/time-const.model';

@Component({
	selector: 'app-edit-image',
	templateUrl: './edit-image.component.html',
	styleUrls: ['./edit-image.component.scss']
})
export class EditImageComponent {
	public ButtonClassEnum = ButtonClassEnum;
	public ButtonTypeEnum = ButtonTypeEnum;
	public src: SafeUrl;
	private fileToUpload: File;
	public fileIsUploading: boolean;

	constructor(
		private toastNotificationService: ToastNotificationService,
		private dialogRef: MatDialogRef<EditImageComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public domSanitizer: DomSanitizer
	) {}

	public onNoClick(): void {
		this.dialogRef.close();
	}

	public send(): void {
		if (!this.src) {
			this.toastNotificationService.sendErrorToast('Please select an image');
			return;
		}
		const data = {
			src: this.src,
			file: this.fileToUpload
		};
		this.dialogRef.close({ data: data });
	}

	public reset(): void {
		this.src = '';
	}

	public handleFileInput(files: FileList): void {
		if (files === null || files.length === 0) {
			return;
		}

		this.fileToUpload = files.item(0);
		const reader = new FileReader();

		reader.onload = (event: any) => {
			this.src = this.domSanitizer.bypassSecurityTrustUrl(event.target.result);
		};
		reader.readAsDataURL(this.fileToUpload);

		this.fileIsUploading = true;
		setTimeout(() => {
			this.send();
		}, Timer.halfSecond);
	}
}
