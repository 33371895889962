import { Observable } from 'rxjs';
import { UpdateAdvertisingStructureRequestInterface } from '../models/update-advertising-structure-request.interface';
import { BaseApiUrl } from '../../../_services/base-api-urls';
import { HttpClient } from '@angular/common/http';
import { AdsManagerLevelsEnum } from '../models/ads-manager-levels.enum';
import { UpdateDetailsInterface } from '../models/update-details.interface';

export class AdsManagerBaseService {
	constructor(public http: HttpClient) {}
	public changeStructureName(id: string, structureName: string, level: AdsManagerLevelsEnum): Observable<void> {
		const details: UpdateDetailsInterface = {
			name: structureName
		};
		const request: UpdateAdvertisingStructureRequestInterface = {
			details: details
		};
		return this.http.put<void>(`${BaseApiUrl.FacebookDataPipeline}${level}/${id}`, request);
	}
}
