import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-catalog-set-selector',
	templateUrl: './catalog-set-selector.component.html',
	styleUrls: ['./catalog-set-selector.component.scss']
})
export class CatalogSetSelectorComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public productCatalogSets: any;
	@Input() public productCatalogSetFormControl: FormControl | AbstractControl;
	@Output() public onCatalogSetSelection = new EventEmitter();
	@Output() public onExpansionClick = new EventEmitter();
	public subscription = new Subscription();
	constructor() {}
	public ngOnInit(): void {}
	public ngOnDestroy(): void {}
}
