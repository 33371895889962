<div class="main">
	<div class="image-and-title">
		<div class="account-access-img">
			<img src="../../../../../assets/icons/permissions-icon.svg" />
		</div>
		<div class="account-access-title">
			<h5><strong>Permissions</strong></h5>
			<p>Grant View, Edit or Full access to your new user for different sections.</p>
		</div>
	</div>

	<div class="table">
		<div class="table-row">
			<div class="table-head"></div>
			<div class="table-head">
				<p><strong>View</strong></p>
			</div>
			<div class="table-head">
				<p><strong>Edit</strong></p>
			</div>
			<div class="table-head">
				<p><strong>Full</strong></p>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Accounts</strong></p>
				<p class="explanation">Displays the list of the Advertisement Accounts in the platform</p>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Accounts, this.permissionsUserEnum.View)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Accounts).get(permissionsUserEnum.View)"
				/>
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Ads Manager</strong></p>
				<p class="explanation">Display and manage advertising campaigns</p>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.AdsManager, this.permissionsUserEnum.View)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.AdsManager).get(permissionsUserEnum.View)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.AdsManager, this.permissionsUserEnum.Edit)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.AdsManager).get(permissionsUserEnum.Edit)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.AdsManager, this.permissionsUserEnum.Full)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.AdsManager).get(permissionsUserEnum.Full)"
				/>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Campaign Builder</strong></p>
				<p class="explanation">Create campaigns with ease</p>
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.CampaignBuilder, this.permissionsUserEnum.Full)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.CampaignBuilder).get(permissionsUserEnum.Full)"
				/>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Audience</strong></p>
				<p class="explanation">Display saved Facebook audience details</p>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Audience, this.permissionsUserEnum.View)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Audience).get(permissionsUserEnum.View)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Audience, this.permissionsUserEnum.Edit)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Audience).get(permissionsUserEnum.Edit)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Audience, this.permissionsUserEnum.Full)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Audience).get(permissionsUserEnum.Full)"
				/>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Reports</strong></p>
				<p class="explanation">Create powerful dashboards to better understand advertisement data</p>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Reports, this.permissionsUserEnum.View)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Reports).get(permissionsUserEnum.View)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Reports, this.permissionsUserEnum.Edit)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Reports).get(permissionsUserEnum.Edit)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Reports, this.permissionsUserEnum.Full)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Reports).get(permissionsUserEnum.Full)"
				/>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Optimize</strong></p>
				<p class="explanation">View and apply insightful campaign’s recommendations</p>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Optimize, this.permissionsUserEnum.View)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Optimize).get(permissionsUserEnum.View)"
				/>
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Optimize, this.permissionsUserEnum.Full)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Optimize).get(permissionsUserEnum.Full)"
				/>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Pixel</strong></p>
				<p class="explanation">Manage your advertising tracking Pixel</p>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.Pixel, this.permissionsUserEnum.View)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.Pixel).get(permissionsUserEnum.View)"
				/>
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>User Management</strong></p>
				<p class="explanation">Manage your users that have access to the system</p>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.UserManagement, this.permissionsUserEnum.View)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.UserManagement).get(permissionsUserEnum.View)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.UserManagement, this.permissionsUserEnum.Edit)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.UserManagement).get(permissionsUserEnum.Edit)"
				/>
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.UserManagement, this.permissionsUserEnum.Full)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.UserManagement).get(permissionsUserEnum.Full)"
				/>
			</div>
		</div>
		<div class="table-row">
			<div class="table-cell">
				<p><strong>Billing & Payments</strong></p>
				<p class="explanation">Manage your payment method and see your invoices</p>
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
			<div class="table-cell">
				<input [disabled]="roleIsReadonly" type="checkbox" class="options-input" />
			</div>
			<div class="table-cell">
				<input
					(click)="checkBoxChange(frontEndModule.BillingAndPayment, this.permissionsUserEnum.Full)"
					type="checkbox"
					class="options-input"
					[checked]="allPermissionsForAllModules.get(frontEndModule.BillingAndPayment).get(permissionsUserEnum.Full)"
				/>
			</div>
		</div>
	</div>
</div>
