<div class="popup-section">
	<div class="close-box">
		<div>
			<span (click)="onNoClick()" class="remove-button">
				<mat-icon>clear</mat-icon>
			</span>
		</div>
	</div>

	<!--main section-->
	<div class="main-section">
		<!--text section-->

		<div class="popup-title">
			<div class="title-section">
				<h2>{{ title }}</h2>
			</div>
			<div class="sort-section">
				<mat-form-field>
					<mat-select
						#select
						(selectionChange)="sortByPeriod(selectedPeriod)"
						(closed)="selectClose(select)"
						(opened)="selectOpen()"
						placeholder="{{ sortPlaceholder }}"
						[(value)]="selectedPeriod"
					>
						<mat-option *ngFor="let sortItem of sortByProperties" [value]="sortItem.key">
							{{ sortItem.value }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="popup-text">
			<div class="table-wrapper">
				<div class="table-scroll">
					<!-- table-section -->
					<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-view">
						<!-- invoice id Column -->
						<ng-container matColumnDef="id">
							<div *matHeaderCellDef class="table-head id-head">Invoice ID</div>
							<div *matCellDef="let element" class="table-col-new id-col">
								<span (click)="viewInvoiceDetails(element.id)" class="blue-link">ID {{ element.id }}</span>
							</div>
						</ng-container>

						<!-- Date Column -->
						<ng-container matColumnDef="date">
							<div *matHeaderCellDef class="table-head">Date</div>
							<div *matCellDef="let element" class="table-col-new">{{ element.date | date: 'dd-MM-yyyy' }}</div>
						</ng-container>

						<!-- Weight Column -->
						<ng-container matColumnDef="total">
							<div *matHeaderCellDef class="table-head">Amount</div>
							<div *matCellDef="let element" class="table-col-new">{{ convertCurrency(element.currencyCode) }} {{ element.total }}</div>
						</ng-container>

						<!-- Status Column -->
						<ng-container matColumnDef="status">
							<div *matHeaderCellDef class="table-head">Status</div>
							<div *matCellDef="let element" class="status-view table-col-new">
								<span class="status-dot" [ngClass]="element.status === 'paid' ? 'status-paid' : 'status-unpaid'"></span>
								<span>{{ element.status === 'paid' ? 'Paid' : 'Unpaid' }}</span>
							</div>
						</ng-container>

						<!-- Actions Column -->
						<ng-container matColumnDef="actions">
							<div *matHeaderCellDef class="table-head actions-head">Actions</div>
							<div *matCellDef="let element" class="table-col-new actions-col">
								<div [matMenuTriggerFor]="actionList">
									<img class="dots" src="{{ imagesPathEnum.ActionDots }}" />
									<mat-menu #actionList="matMenu" class="custom_mat_menu" xPosition="before">
										<button *ngIf="element.status !== 'paid'" (click)="payNow(element)" mat-menu-item>
											<img class="paynow" src="{{ imagesPathEnum.PaymentMethod }}" />
											Pay Now
										</button>
										<button (click)="download(element.id)" mat-menu-item>
											<img class="download" src="{{ imagesPathEnum.Download }}" />
											Download
										</button>
										<button (click)="shareInvoice(element)" mat-menu-item>
											<img class="share" src="{{ imagesPathEnum.Shared }}" />
											Share
										</button>
									</mat-menu>
								</div>
							</div>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumns" class="invoices-header-row"></mat-header-row>
						<mat-row *matRowDef="let row; columns: displayedColumns" class="invoices-table-row"></mat-row>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
