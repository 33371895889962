import { GridsterItem } from 'angular-gridster2';

export class WidgetPropertyModel implements GridsterItem {
	constructor(
		public verticalAxisTitle: string = '',
		public horizontalAxisTitle: string = '',
		public secondaryAxis: boolean = false,
		public showDataLabels: boolean = false,
		public fontColor: string = '',
		public fontSize: number = 0,
		public fontBold: string = '',
		public fontItalic: string = '',
		public fontUnderline: string = '',
		public fontFamily: string = '',
		public x: number = 0,
		public y: number = 0,
		public rows: number = 3,
		public cols: number = 5,
		public minItemCol: number = 5,
		public minItemRows: number = 3
	) {}
}
