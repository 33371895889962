import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PermissionsApiOptionsService } from '../../../../shared/permisions/permissions-api-options.service';
import { PermissionsUserEnum, userPermission } from '../../../models/permissions-user.enum';
import { FrontEndModule } from '../../../models/user-permissions-table.model';

@Component({
	selector: 'app-user-permissions',
	templateUrl: './user-permissions.component.html',
	styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent {
	@Output() public formIsValid: EventEmitter<boolean> = new EventEmitter();
	@Output() public userData: EventEmitter<userPermission[]> = new EventEmitter();
	@Input() public permission: userPermission[];
	@Input() public activeStep: boolean;

	public roleIsReadonly: boolean = true;
	public frontEndModule = FrontEndModule;
	public permissionsUserEnum = PermissionsUserEnum;
	public finishedObject: userPermission[] = [];
	public allPermissionsForAllModules: Map<FrontEndModule, Map<PermissionsUserEnum, boolean>>;

	constructor(public permissionsApiOptionsService: PermissionsApiOptionsService) {
		this.allPermissionsForAllModules = new Map<FrontEndModule, Map<PermissionsUserEnum, boolean>>();
		this.resetAllPermissionForModule();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.resetAllPermissionForModule();
		if (this.permission) {
			this.permission.forEach(permission => {
				this.checkBoxChange(permission.frontEndModule, permission.accessType);
			});
		}
	}

	public checkBoxChange(FrontEndModule: FrontEndModule, permission: any): void {
		const allPermissionsForGivenModule = this.allPermissionsForAllModules.get(FrontEndModule);
		const permissionStateToBeInverted = allPermissionsForGivenModule.get(permission);
		if (permission === PermissionsUserEnum.Full) {
			this.setPermissionFull(FrontEndModule, allPermissionsForGivenModule, permissionStateToBeInverted);
		}
		if (permission === PermissionsUserEnum.Edit) {
			this.setPermissionEdit(allPermissionsForGivenModule, permissionStateToBeInverted);
		}
		if (permission === PermissionsUserEnum.View) {
			this.setPermissionView(allPermissionsForGivenModule, permissionStateToBeInverted);
		}
		if (this.finishedObject) {
			this.finishedObject = [];
		}
		this.convertToObject();
	}

	public setPermissionFull(FrontEndModule: FrontEndModule, module: any, permission: any): void {
		module.set(PermissionsUserEnum.Full, !permission);
		if (FrontEndModule !== this.frontEndModule.BillingAndPayment && FrontEndModule !== this.frontEndModule.CampaignBuilder) {
			if (FrontEndModule === this.frontEndModule.Optimize) {
				module.set(PermissionsUserEnum.Edit, false);
			} else {
				module.set(PermissionsUserEnum.Edit, !permission ? true : true);
			}
			module.set(PermissionsUserEnum.View, !permission ? true : true);
		} else {
			module.set(PermissionsUserEnum.Edit, false);
			module.set(PermissionsUserEnum.View, false);
		}
	}

	public setPermissionEdit(module: any, permission: any): void {
		module.set(PermissionsUserEnum.Full, false);
		module.set(PermissionsUserEnum.Edit, !permission);
		module.set(PermissionsUserEnum.View, !permission ? true : true);
	}

	public setPermissionView(module: any, permission: any): void {
		if (!permission) {
			module.set(PermissionsUserEnum.View, true);
		} else {
			module.set(PermissionsUserEnum.View, false);
		}
		module.set(PermissionsUserEnum.Edit, false);
		module.set(PermissionsUserEnum.Full, false);
	}

	public convertToObject(): void {
		this.allPermissionsForAllModules.forEach((value, key, map) => {
			let object: userPermission = new userPermission();
			object.frontEndModule = key;
			const allPermissionsForGivenModule = this.allPermissionsForAllModules.get(key);
			allPermissionsForGivenModule.forEach((value1, key1) => {
				if (value1) {
					object.accessType = key1;
				}
			});
			if (object.accessType) {
				this.finishedObject.push(object);
			}
		});
		if (this.finishedObject.length > 0) {
			this.userData.emit(this.finishedObject);
			if (this.activeStep) {
				this.formIsValid.emit(true);
			}
		} else {
			this.formIsValid.emit(false);
		}
	}

	private resetAllPermissionForModule(): void {
		this.allPermissionsForAllModules.set(FrontEndModule.Pixel, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.Accounts, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.Reports, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.AdsManager, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.Audience, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.CampaignBuilder, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.Optimize, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.UserManagement, this.getPermissionsForModule(PermissionsUserEnum));
		this.allPermissionsForAllModules.set(FrontEndModule.BillingAndPayment, this.getPermissionsForModule(PermissionsUserEnum));
	}

	private getPermissionsForModule(enumerationType: any): Map<PermissionsUserEnum, boolean> {
		const result: Map<PermissionsUserEnum, boolean> = new Map<PermissionsUserEnum, boolean>();
		const enumerationKeys = Object.values(enumerationType).filter(this.stringIsNumber);
		enumerationKeys.forEach(key => result.set(key as PermissionsUserEnum, false));
		return result;
	}

	private stringIsNumber = (value: any) => isNaN(Number(value)) === false;
}
