import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserSetting } from '../../back-office/models/user-setting.model';
import { select, Store } from '@ngrx/store';
import { getFiledId, UserState } from '../state/user/user.reducer';
import { take } from 'rxjs/operators';

@Component({
	selector: 'app-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
	getUserSettingsSubscription: Subscription;

	editUserSettingsSubscription: Subscription;

	userSetting: UserSetting;

	constructor(private backOfficeService: BackOfficeService, private userStore: Store<UserState>) {}

	ngOnInit() {
		this.userStore.pipe(select(getFiledId), take(1)).subscribe(filedId => {
			this.getUserSettingsSubscription = this.backOfficeService.getSettings(filedId).subscribe((response: any) => {
				this.userSetting = {
					id: filedId,
					language: response.userSettingsDto.languageName,
					dataSharing: response.userSettingsDto.dataSharing,
					notificationDelivery: response.userSettingsDto.notificationDelivery.toString(),
					notificationFrequency: response.userSettingsDto.notificationFrequency.toString()
				};
			});
		});
	}

	saveOnClose() {
		this.editUserSettingsSubscription = this.backOfficeService.editSettings(this.userSetting).subscribe();
	}

	ngOnDestroy() {
		if (this.getUserSettingsSubscription) {
			this.getUserSettingsSubscription.unsubscribe();
		}
	}
}
