import { Component, Input, OnInit } from '@angular/core';
import { WarningButton } from './_models/models';
import { ButtonClassEnum } from '../button-component/button-class.enum';
import { ButtonTypeEnum } from '../button-component/button-type.enum';

@Component({
	selector: 'app-warning-message-no-data',
	templateUrl: './warning-message-no-data.component.html',
	styleUrls: ['./warning-message-no-data.component.scss']
})
export class WarningMessageNoDataComponent implements OnInit {
	@Input() public iconClass: string;
	@Input() public name: string;
	@Input() public description: string;
	@Input() public buttons: WarningButton[];
	@Input() public isSmall: boolean;

	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor() {}

	ngOnInit() {}
}
