<!--dropdown select -->
<ng-select
	#ngSelect
	(window:resize)="onResize($event, ngSelect)"
	[closeOnSelect]="dropdownSelect !== dropdownSelectType.Multiple"
	[formControl]="dropdownFormControl"
	[readonly]="readOnly"
	[items]="dropdownData"
	(scroll)="onScroll($event)"
	(scrollToEnd)="onScrollToEnd()"
	[multiple]="dropdownSelect === dropdownSelectType.Multiple"
	[searchable]="searchEnable"
	[bindLabel]="labelProperty"
	[bindValue]="bindProperty"
	[clearSearchOnAdd]="true"
	(search)="onSearch($event)"
	(focus)="autocompleteFocus()"
	(focusout)="setErrorMessage()"
	(change)="onChange()"
	(close)="onDropdownClose()"
	[ngClass]="{
		'input-error-message': dropdownFormControl.invalid && dropdownFormControl.touched,
		'input-success-message': dropdownFormControl.valid && dropdownFormControl.touched
	}"
	notFoundText="{{ noDataFoundLabel }}"
	placeholder="{{ dropdownPlaceholder }}"
>
	<ng-template ng-multi-label-tmp>
		<div class="custom-value" (mouseover)="changeText()">
			<span>VIEW SELECTED</span>
		</div>
	</ng-template>

	<!--Select/Deselect All only for multiple dropdown-->
	<div *ngIf="dropdownSelect === dropdownSelectType.Multiple">
		<ng-template *ngIf="enableAllActions" ng-header-tmp>
			<div class="buttons-wrapper">
				<div (click)="onSelectAll()" [class.active]="selectAll" class="btn select-btn">SELECT ALL</div>
				<div (click)="onClearAll()" [class.active]="clearAll" class="btn clear-btn">CLEAR ALL</div>

				<div
					*ngFor="let filterRule of filterRules"
					(click)="filterStatus(filterRule.key, filterRule.independentFilter)"
					[class.active]="filterRule.selected"
					class="btn clear-btn"
				>
					{{ filterRule.displayName }}
				</div>
			</div>
		</ng-template>

		<!--Input checkbox-->
		<ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
			<span class="right-check">
				<span>{{ item.displayName }}</span>
				<input [ngModel]="item$.selected" id="item-{{ index }}" name="item-{{ index }}" type="checkbox" />
			</span>
		</ng-template>
	</div>
</ng-select>
<!--Error message -->
<span class="error-message" *ngIf="dropdownFormControl.invalid && dropdownFormControl.touched">{{ errorMessage }}</span>
<!--Error message -->
