import { AxisLabelTextStyle } from './axis-label-text-style';
import { Helper } from '../helper';

export class AxisLabel {
	formatter: any;

	constructor(public textStyle?: AxisLabelTextStyle, public align?: string) {
		this.attachFormatter();
	}

	attachFormatter() {
		this.formatter = (value: any) => {
			return Helper.convertAxisValues(value);
		};
	}
}
