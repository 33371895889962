import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AdAccountApiService } from '../../_services/facebook-accounts/ad-account-api.service';
import {
	LoadCatalogs,
	LoadFiledUser,
	LoadUsers,
	LoadSelectedKeysPermissionsSuccess,
	LoadCardsSuccess,
	SetFacebookAdAccountRoles,
	SetFacebookBusinessRoles,
	SetGoogleAdAccountRoles,
	LoadInvoicesSuccess,
	SetNextStatusForEditPage,
	SetUpdatedUser,
	SetUserProfileUserManagement,
	LoadUsersSuccess,
	UserManagementTypes,
	LoadUsersFailure,
	SetUpdatedUserFailure,
	SetUserProfileUserManagementFailure,
	SetFacebookBusinessRolesFailure,
	SetFacebookAdAccountRolesFailure,
	SetGoogleAdAccountRolesFailure,
	LoadCardsFailure,
	UpdateUserPicture,
	UpdateUserPictureSuccess,
	UpdateUserPictureFailure,
	MakePrimaryCard,
	MakePrimaryCardSuccess,
	MakePrimaryCardFailure,
	LoadBillingAccountInfoSuccess,
	LoadBillingAccountInfoFailure
} from './user-management.actions';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { InvoiceDetails } from '../../_models/invoice-details.interface';
import { UserServiceSubscription } from '../../_services/user/user-subscription.service';
import { GetPermissionsResponse } from '../../shared/models/permission';
import { BackOfficeService } from '../../_services/back-office/back-office.service';
import { UserCatalogKey } from '../models/user-catalog-keys';
import { EnumerationEx } from '../../_models/enumeration';
import { DropdownData } from '../../shared/dropdown-search-select/dropdown-data.interface';
import { EMPTY, Observable } from 'rxjs';
import {
	getBillingAccountInfo,
	getCards,
	getFacebookBusinessRoles,
	getFiledUser,
	getInvoices,
	getKeysForAllPermissionsState,
	getUsers,
	UserManagementState
} from './user-management.reducer';
import { select, Store } from '@ngrx/store';
import { FiledCreditCard } from '../components/filed-cards/set-creditcard.models';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { User } from '../../_models/user-models/user';
import { AppStateSlices } from '../../state/app.state';
import { ErrorsLoggingService } from '../../_services/errors-logging/errors-logging.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserManagementEffectsEnum } from './effects.enum';
import { BillingAddress } from '../shared/billing-and-payment/billing-and-payment.models';

@Injectable()
export class UserManagementEffects {
	constructor(
		private actions: Actions,
		private adAccountService: AdAccountApiService,
		private userServiceApi: UserServiceApi,
		private userServiceSubscription: UserServiceSubscription,
		private backOfficeService: BackOfficeService,
		private store: Store<UserManagementState>,
		private toastNotification: ToastNotificationService,
		private router: Router,
		private translate: TranslateService,
		private errorsLoggingService: ErrorsLoggingService
	) {}

	LoadUsers$ = createEffect(() => {
		return this.actions.pipe(
			ofType<LoadUsers>(UserManagementTypes.LoadUsers),
			withLatestFrom(this.store.pipe(select(getUsers))),
			exhaustMap(([action, users]) => {
				if (users || !action || !action['payload']) {
					return EMPTY;
				}

				return this.userServiceApi.getAllUsersByOwnerFacebookId(action['payload']).pipe(
					map((_users: User[]) => {
						return new LoadUsersSuccess(_users);
					}),
					catchError(error => {
						this.errorsLoggingService.logEffectError(
							AppStateSlices.UserManagement,
							UserManagementEffectsEnum.LoadUsers,
							[action.payload, users],
							error
						);
						this.failure();
						new LoadUsersFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						return EMPTY;
					})
				);
			})
		);
	});

	LoadFiledUser$ = createEffect(() => {
		return this.actions.pipe(
			ofType<LoadFiledUser>(UserManagementTypes.LoadFiledUser),
			withLatestFrom(this.store.pipe(select(getFiledUser))),
			exhaustMap(([action, userProfile]) => {
				if (userProfile) {
					return EMPTY;
				}
				return this.userServiceApi.getUserById(action.payload.id, action.payload.isBackoffice).pipe(
					map((user: User) => {
						if (action.payload.setSelectedUser) {
							return new SetUpdatedUser(user);
						} else {
							return new SetUserProfileUserManagement(user);
						}
					}),
					catchError(error => {
						if (action.payload.setSelectedUser) {
							new SetUpdatedUserFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						} else {
							new SetUserProfileUserManagementFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						}
						this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.LoadFiledUser, [action], error);
						this.failure();
						return EMPTY;
					})
				);
			})
		);
	});

	LoadCatalogs$ = createEffect(() =>
		this.actions.pipe(
			ofType<LoadCatalogs>(UserManagementTypes.loadCatalogs),
			withLatestFrom(this.store.pipe(select(getFacebookBusinessRoles))),
			exhaustMap(([action, data]) => {
				if (data) {
					return EMPTY;
				}
				return this.userServiceApi.getCatalogs().pipe(
					switchMap(catalogs => [
						new SetFacebookBusinessRoles(this.enumerationExToDropdown(catalogs[UserCatalogKey.FacebookBusinessRoles])),
						new SetFacebookAdAccountRoles(this.enumerationExToDropdown(catalogs[UserCatalogKey.FacebookAdAccountRoles])),
						new SetGoogleAdAccountRoles(this.enumerationExToDropdown(catalogs[UserCatalogKey.GoogleAdAccountRoles]))
					]),
					catchError(error => {
						new SetFacebookBusinessRolesFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						new SetFacebookAdAccountRolesFailure(this.errorsLoggingService.getCodeFromHttpError(error));
						new SetGoogleAdAccountRolesFailure(this.errorsLoggingService.getCodeFromHttpError(error));

						this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.LoadCatalogs, [action], error);
						this.failure();
						return EMPTY;
					})
				);
			})
		)
	);

	@Effect()
	LoadSubscriptions$: Observable<LoadInvoicesSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadInvoices),
		withLatestFrom(this.store.pipe(select(getInvoices))),
		exhaustMap(([action, invoices]) => {
			if (invoices) {
				return EMPTY;
			}
			return this.userServiceSubscription.getInvoiceDetails().pipe(
				map((invoices: InvoiceDetails) => new LoadInvoicesSuccess(invoices)),
				catchError(error => {
					this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.LoadSubscriptions, [action], error);
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	@Effect()
	LoadPermissions$: Observable<LoadSelectedKeysPermissionsSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadSelectedKeysPermissions),
		withLatestFrom(this.store.pipe(select(getKeysForAllPermissionsState))),
		switchMap(([action, keys]) => {
			if (keys && !action['payload']) {
				return EMPTY;
			}
			return this.adAccountService.getBusinessOwnerPermissions().pipe(
				map((permissions: GetPermissionsResponse) => new LoadSelectedKeysPermissionsSuccess(permissions)),
				catchError(error => {
					this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.LoadPermissions$, [action], error);
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	@Effect()
	LoadCards$: Observable<LoadCardsSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadCards),
		withLatestFrom(this.store.pipe(select(getCards))),
		switchMap(([action, cards]) => {
			if (cards) {
				return EMPTY;
			}
			return this.backOfficeService.getBusinessOwnerCards().pipe(
				map((cards: FiledCreditCard[]) => new LoadCardsSuccess(cards)),
				catchError(error => {
					new LoadCardsFailure(this.errorsLoggingService.getCodeFromHttpError(error));
					this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.LoadCards, [action], error);
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	@Effect()
	UpdateUserPicture$: Observable<UpdateUserPictureSuccess> = this.actions.pipe(
		ofType<UpdateUserPicture>(UserManagementTypes.updateUserPicture),
		exhaustMap(action => {
			this.store.dispatch(new ShowGlobalSpinner());
			return this.userServiceApi.updateUserPicture(action.payload.pictureForm).pipe(
				map((updatedUser: User) => {
					this.toastNotification.sendSuccessToast('Image saved');
					this.store.dispatch(new HideGlobalSpinner());
					return new UpdateUserPictureSuccess(updatedUser);
				}),
				catchError(error => {
					new UpdateUserPictureFailure(this.errorsLoggingService.getCodeFromHttpError(error));
					this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.UpdateProfilePicture, [action], error);
					this.store.dispatch(new UpdateUserPictureFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	@Effect()
	makePrimaryCard$: Observable<MakePrimaryCardSuccess> = this.actions.pipe(
		ofType<MakePrimaryCard>(UserManagementTypes.makePrimaryCard),
		exhaustMap(action => {
			this.store.dispatch(new ShowGlobalSpinner());
			return this.backOfficeService.makeCardPrimary(action.payload).pipe(
				map(() => {
					this.toastNotification.sendSuccessToast('This card will now be your default payment method');
					this.store.dispatch(new HideGlobalSpinner());
					return new MakePrimaryCardSuccess(action.payload);
				}),
				catchError(error => {
					new UpdateUserPictureFailure(this.errorsLoggingService.getCodeFromHttpError(error));
					this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.MakePrimaryCard, [action], error);
					this.store.dispatch(new MakePrimaryCardFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
					this.failure();
					return EMPTY;
				})
			);
		})
	);

	private enumerationExToDropdown(array: EnumerationEx[]): DropdownData[] {
		return array.map((EnumerationEx: EnumerationEx) => {
			return {
				displayName: EnumerationEx.name,
				value: Number(EnumerationEx.id),
				selected: false,
				data: Number(EnumerationEx.id)
			};
		});
	}

	private failure() {
		this.store.dispatch(new HideGlobalSpinner());
		this.toastNotification.sendErrorToast('Something went wrong, please contact support');
	}

	@Effect()
	LoadBillingAccountInfo$: Observable<LoadBillingAccountInfoSuccess> = this.actions.pipe(
		ofType(UserManagementTypes.loadBillingAccountInfo),
		withLatestFrom(this.store.pipe(select(getBillingAccountInfo))),
		switchMap(([action]) => {
			return this.backOfficeService.getBillingAccountDetails().pipe(
				map((billingInfo: BillingAddress) => new LoadBillingAccountInfoSuccess(billingInfo)),
				catchError(error => {
					new LoadBillingAccountInfoFailure(this.errorsLoggingService.getCodeFromHttpError(error));
					this.errorsLoggingService.logEffectError(AppStateSlices.UserManagement, UserManagementEffectsEnum.LoadBillingAccountInfo, [action], error);
					this.failure();
					return EMPTY;
				})
			);
		})
	);
}
