<div class="popup-section">
	<!--header-->
	<!--delete btn-->
	<div class="clear-btn">
		<mat-icon (click)="actionEvent()">clear</mat-icon>
		<div></div>
	</div>

	<!--main section-->
	<div class="main-section">
		<img src="{{ image }}" />
		<p class="title">{{ header }}</p>
		<p *ngIf="messageIsHtml" [innerHTML]="message"></p>
		<p *ngIf="!messageIsHtml">{{ message }}</p>
	</div>

	<!--footer-->
	<div class="bottom-buttons">
		<btn15 (click)="actionEvent(false)">
			{{ noActionAnswer }}
		</btn15>

		<btn01 (onClick)="actionEvent(true)">
			{{ actionAnswer }}
		</btn01>
	</div>
</div>
