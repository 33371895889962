import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-ads-manager-action-cell',
	templateUrl: './ads-manager-action-cell.component.html',
	styleUrls: ['./ads-manager-action-cell.component.scss']
})
export class AdsManagerActionCellComponent implements ICellRendererAngularComp {
	public params: any;

	public agInit(params: any): void {
		this.params = params;
	}

	public invokeParentMethod() {
		this.params.context.componentParent.methodFromParent(`Row: ${this.params.node.rowIndex}, Col: ${this.params.colDef.headerName}`);
	}

	public refresh(): boolean {
		return false;
	}

	public executeAction(): void {}
}
