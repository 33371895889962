export class GoalModel {
	public name: string;
	public min: number;
	public max: number;
	public operator: string;
	public metric: string;
	public isTemporary: boolean;

	constructor(name: string = '', min: number = 0, max: number = 0, accountId: number = 0, metric: string = '', operator: string = '') {
		this.name = name;
		this.min = min;
		this.max = max;
		this.metric = metric;
		this.operator = operator;
		this.isTemporary = true;
	}
}
