import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { RetargetingInterface } from '../../../../../campaign-smart-create/models/retargeting.interface';
import { debounceTime } from 'rxjs/operators';
import { RetargetingOptionEnum } from '../../../../../campaign-smart-create/models/retargeting-option.enum';
import { RetargetingOptionsEnum } from '../../../../../campaign-smart-create/models/retargeting-options.enum';
import { IProductSet } from '../../../../../creative-builder/cb-m-template/model/product-set';

@Component({
	selector: 'app-re-targeting',
	templateUrl: './re-targeting.component.html',
	styleUrls: ['./re-targeting.component.scss']
})
export class ReTargetingComponent implements OnInit, OnChanges {
	@Input() public productSetId: string;
	@Input() public retargetingControl: FormControl | AbstractControl;
	@Input() public retargetingDaysControl: FormControl | AbstractControl;
	@Input() public productCatalogSets: any;
	@Output() public productAudienceSpec: EventEmitter<RetargetingInterface> = new EventEmitter<RetargetingInterface>();
	public control: FormControl | AbstractControl = new FormControl();
	public validationMessages: ValidatorMessages[];
	public placeholder = '';
	public products: any = [];
	public productsControl = new FormControl();
	public customCombinationForm: FormGroup;
	public radioValue: string;
	public retargetingOptions = RetargetingOptionsEnum;
	public retargetingFormGroup: FormGroup;
	public reTargetingControl: FormControl | AbstractControl = new FormControl('');
	public retargetingTypeEnum = RetargetingOptionEnum;

	constructor(private fb: FormBuilder) {
		this.customCombinationForm = this.createCustomCombination();
	}

	public ngOnInit(): void {
		this.retargetingFormGroup = this.fb.group({
			cross_sell_products_control: this.fb.group({
				daysValue: [{ value: null, disabled: true }],
				productSetId: [{ value: null, disabled: true }]
			}),
			up_sell_products_control: this.fb.group({
				daysValue: [{ value: null, disabled: true }],
				productSetId: [{ value: null, disabled: true }]
			}),
			added_not_purchased_control: [{ value: null, disabled: true }],
			viewed_added_not_purchased_control: [{ value: null, disabled: true }]
		});
		this.reTargetingControl.valueChanges.subscribe(() => {
			this.onOptionsClick();
		});
		this.onOptionsClick(this.retargetingControl);
		this.retargetingFormGroup.valueChanges.pipe(debounceTime(2000)).subscribe(res => {
			let productAudience: RetargetingInterface;
			let formStateError: boolean;
			if (res.hasOwnProperty('up_sell_products_control')) {
				const controlValue = this.retargetingFormGroup.get('up_sell_products_control').get('daysValue').value;
				if (controlValue === null) {
					formStateError = true;
				}
				productAudience = {
					productSetId: this.retargetingFormGroup.get('up_sell_products_control').get('productSetId').value ?? this.productSetId,
					timeInDays: controlValue,
					retargetingOption: this.retargetingTypeEnum.upsale
				};
			} else if (res.hasOwnProperty('cross_sell_products_control')) {
				const controlValue = this.retargetingFormGroup.get('cross_sell_products_control').get('daysValue').value;
				if (controlValue === null) {
					formStateError = true;
				}
				productAudience = {
					productSetId: this.retargetingFormGroup.get('cross_sell_products_control').get('productSetId').value ?? this.productSetId,
					timeInDays: controlValue,
					retargetingOption: this.retargetingTypeEnum.crossSales
				};
			} else if (res.hasOwnProperty('added_not_purchased_control')) {
				const controlValue = this.retargetingFormGroup.get('added_not_purchased_control').value;
				if (controlValue === null) {
					formStateError = true;
				}
				productAudience = {
					productSetId: this.productSetId,
					retargetingOption: this.retargetingTypeEnum.addToCart,
					timeInDays: controlValue
				};
			} else if (res.hasOwnProperty('viewed_added_not_purchased_control')) {
				const controlValue = this.retargetingFormGroup.get('viewed_added_not_purchased_control').value;
				if (controlValue === null) {
					formStateError = true;
				}
				productAudience = {
					productSetId: this.productSetId,
					timeInDays: controlValue,
					retargetingOption: this.retargetingTypeEnum.viewAddToCart
				};
			}
			if (formStateError) {
				this.productAudienceSpec.emit(null);
			} else if (productAudience && this.retargetingFormGroup.valid) {
				this.productAudienceSpec.emit(productAudience);
			}
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {}

	get inclusionForms(): FormArray {
		return this.customCombinationForm.get('inclusionForms') as FormArray;
	}

	get exclusionForms(): FormArray {
		return this.customCombinationForm.get('exclusionForms') as FormArray;
	}

	public onOptionsClick(defaultControl?: AbstractControl): void {
		this.retargetingFormGroup.reset();
		this.retargetingFormGroup.disable();
		let selectedControl: AbstractControl;
		const loadedRetargetingValue = defaultControl?.value;
		if (loadedRetargetingValue) {
			this.reTargetingControl.patchValue(loadedRetargetingValue?.retargetingOption);
		}
		switch (this.reTargetingControl?.value) {
			case RetargetingOptionEnum.viewAddToCart: {
				selectedControl = this.retargetingFormGroup.get('viewed_added_not_purchased_control');
				break;
			}
			case RetargetingOptionEnum.addToCart: {
				selectedControl = this.retargetingFormGroup.get('added_not_purchased_control');
				break;
			}
			case RetargetingOptionEnum.upsale: {
				selectedControl = this.retargetingFormGroup.get('up_sell_products_control');
				break;
			}
			case RetargetingOptionEnum.crossSales: {
				selectedControl = this.retargetingFormGroup.get('cross_sell_products_control');
				break;
			}
			default: {
			}
		}
		if (selectedControl) {
			selectedControl.enable();
			if (this.reTargetingControl?.value === RetargetingOptionEnum.crossSales || this.reTargetingControl?.value === RetargetingOptionEnum.upsale) {
				selectedControl.get('daysValue').patchValue(loadedRetargetingValue?.timeInDays ?? null);
				selectedControl.get('productSetId').setValue(loadedRetargetingValue?.productSetId ?? this.productSetId ?? null);
				selectedControl.get('daysValue').setValidators([Validators.required, Validators.min(0)]);
			} else {
				selectedControl.patchValue(loadedRetargetingValue?.timeInDays ?? null);
				selectedControl.setValidators([Validators.required, Validators.min(0)]);
			}
		}
	}

	public handleSelection(): void {}

	public createCustomCombination(): FormGroup {
		return this.fb.group({
			inclusionForms: this.fb.array([this.buildInclusionOrExclusionForm()]),
			exclusionForms: this.fb.array([this.buildInclusionOrExclusionForm()])
		});
	}

	public buildInclusionOrExclusionForm(): FormGroup {
		return this.fb.group({
			product: [''],
			days: [14]
		});
	}

	public addInclusionForm(): void {
		this.inclusionForms.push(this.buildInclusionOrExclusionForm());
	}

	public addExclusionForm(): void {
		this.exclusionForms.push(this.buildInclusionOrExclusionForm());
	}

	public removeInclusionForm(index: number): void {
		this.inclusionForms.removeAt(index);
	}

	public removeExclusionForm(index: number): void {
		this.exclusionForms.removeAt(index);
	}
}
