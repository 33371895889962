<app-campaign-smart-create-navigation
	(navigationClick)="onNavClick($event)"
	[activeStep]="step"
	[campaignStep]="campaign.lastStepId"
	[isCurrentStepValid]="isStepValid"
></app-campaign-smart-create-navigation>

<div class="bct-wrapper">
	<font04 class="title">Smart Create</font04>
	<div class="container">
		<div class="sticky">
			<quick-navigation [navigations]="navigations"></quick-navigation>
		</div>
		<div>
			<div (click)="onPanelClick(findNavigationIndex('campaign-name'))" id="campaign-name" class="bct-expansion-panel campaignName">
				<app-name-expansion-panel
					(click)="expansionPanelHelper.onPanelClick(campaignPanels.Name)"
					[isSelected]="expansionPanelHelper.isPanelSelected(campaignPanels.Name)"
					[control]="campaignFormGroup.get('campaignName')"
					[expansionTitle]="'Campaign Name'"
					[placeholder]="label"
				></app-name-expansion-panel>
			</div>
			<div (click)="onPanelClick(findNavigationIndex('campaign-objective'))" id="campaign-objective" class="bct-expansion-panel objectiveGroup">
				<app-new-objective-card
					[objectives]="objectives"
					[objectivesGroup]="campaignFormGroup.get('objectiveGroup')"
					[isObjectivesPanelSelected]="expansionPanelHelper.isPanelSelected(campaignPanels.Objectives)"
					[isSubObjectivesPanelSelected]="expansionPanelHelper.isPanelSelected(campaignPanels.SubObjectives)"
					[isSubObjectivesOptionsPanelSelected]="expansionPanelHelper.isPanelSelected(campaignPanels.SubObjectivesOptions)"
					(onExpansionClick)="expansionPanelHelper.onPanelClick($event)"
					[selectedSubObjectiveText]="campaignFormGroup?.get('objectiveGroup')?.get('subObjectiveDisplayName')?.value"
					[showErrorTextMsg]="showErrorTextMsg"
					[selectedAccount]="selectedAccount"
				></app-new-objective-card>
			</div>
			<div
				(click)="onPanelClick(findNavigationIndex('product-catalog'))"
				class="bct-expansion-panel productCatalog"
				id="product-catalog"
				*ngIf="selectedObjective === subObjectives.ProductCatalogSales"
				qn-scroll-item
			>
				<app-catalog-selector
					[catalogList]="productCatalogs"
					[catalogFormControl]="campaignFormGroup.get('productCatalog')"
					[accountId]="selectedAccount?.adAccount?.id"
				></app-catalog-selector>
			</div>
			<div class="bct-expansion-panel appStoreType" *ngIf="selectedObjective === subObjectives.AppInstallText" qn-scroll-item>
				<app-campaign-facebook-reg></app-campaign-facebook-reg>
			</div>
			<div class="bct-expansion-panel" qn-scroll-item>
				<div (click)="onPanelClick(findNavigationIndex('special-ad-category'))" id="special-ad-category" class="bct-expansion-panel specialAdCategory">
					<app-special-ad-category-card
						[control]="campaignFormGroup.get('specialAdCategory')"
						(onSpecialAdSelectPopUp)="specialAdCategoryPopUp()"
						(onToggleOff)="updateSelectedAdCategory($event)"
						[isSpecialAdCategoryToggled]="isSpecialAdCategoryToggled"
						[selectedSpecialAdCategory]="selectedSpecialAdCategory"
						[specialAdCategories]="specialAdCategories"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(campaignPanels.SpecialAdCategory)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(campaignPanels.SpecialAdCategory)"
					></app-special-ad-category-card>
				</div>

				<div
					(click)="onPanelClick(findNavigationIndex('campaign-budget'))"
					id="campaign-budget"
					class="bct-expansion-panel budgetOptimization"
					*ngIf="!isAppInstall"
				>
					<app-bid-strategy-card
						[cboGroup]="campaignFormGroup.get('budgetOptimization')"
						[budgetOptimization]="campaignBudgetOptimization"
						[selectedObjective]="selectedObjective"
						[minBudget]="validationBudget"
						[currency]="currency"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(campaignPanels.CampaignBudgetOptimization)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(campaignPanels.CampaignBudgetOptimization)"
						(toggleStatus)="updateDateCardVisibility($event)"
					></app-bid-strategy-card>
				</div>

				<div class="bct-expansion-panel">
					<app-date-card
						*ngIf="isDateCardVisible"
						(campaignDateStatus)="updateCampaignDateStatus($event)"
						[preselectedDate]="preselectedDate"
						[isCampaignLifeTimeSelected]="isCampaignLifeTimeSelected"
						[isAdSetLifeTimeSelected]="isAdSetLifeTimeSelected"
						[campaignDate]="campaignDate"
						[dateChoiceType]="dateChoiceType"
						(onExpansionClick)="expansionPanelHelper.onPanelClick(panels.Date)"
						[isPanelSelected]="expansionPanelHelper.isPanelSelected(panels.Date)"
					></app-date-card>
				</div>
			</div>
		</div>
	</div>
	<app-campaign-smart-create-footer (cancel)="onCancelClick()" (save)="onSaveAsDraftClick()" (next)="onNextClick()"></app-campaign-smart-create-footer>
</div>
