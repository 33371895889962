import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { GridsterCreateEditReportConfigurator } from '../../config-files/gridster-create-edit-report.config';
import { GridsterConfig } from 'angular-gridster2';
import { DashboardModel } from '../../models/dashboard.model';
import { DashboardService } from '../../services/api-services/dashboard.service';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { services } from '../../models/services-list';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { ReportingMetaInsightsService } from '../../services/api-services/reporting-meta-insights.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetDashboardModel } from '../../models/get-dashboard.model';
import { ReportModel } from '../../models/report.model';
import { GetReportModel } from '../../models/get-report.model';
import { ToastNotificationType } from 'src/app/shared/toast-notification/toast-notification-type.enum';

@Component({
	selector: 'app-share-dashboard-as-link',
	templateUrl: './share-dashboard-as-link.component.html',
	styleUrls: ['./share-dashboard-as-link.component.scss']
})
export class ShareDashboardAsLinkComponent implements OnInit, OnDestroy {
	public options: GridsterConfig;
	public openEdit = false;
	public gridsterEnabled = false;
	public dashboard: GetReportModel;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private dashboardService: DashboardService,
		private route: ActivatedRoute,
		private injector: Injector,
		private toastNotificationService: ToastNotificationService,
		private reportingMetaInsightsService: ReportingMetaInsightsService
	) {}

	ngOnInit() {
		this.options = new GridsterCreateEditReportConfigurator().getConfiguration();
		const params = this.route.snapshot.params;
		if (params.hash) {
			this.dashboardService
				.getReportByHashForSharing(params.hash)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					dashboard => {
						if (dashboard) {
							this.dashboard = dashboard;
							if (dashboard.graphs.length > 0) {
								dashboard.graphs.forEach(element => {
									if (element.details.positionProperty && element.details.reportProperty) {
										element.details.reportProperty.x = element.details.positionProperty.x;
										element.details.reportProperty.y = element.details.positionProperty.y;
										element.details.reportProperty.rows = element.details.positionProperty.rows;
										element.details.reportProperty.cols = element.details.positionProperty.cols;
									}
									element.details.reportProperty.verticalAxisTitle = element.details.verticalAxisTitle;
									element.details.reportProperty.horizontalAxisTitle = element.details.horizontalAxisTitle;
								});
							} else {
								this.toastNotificationService.sendToast('No dashboard data found', ToastNotificationType.Warning);
							}
						}
					},
					() => {
						this.toastNotificationService.sendErrorToast('An error occurred. Please refresh the page or contact support.');
					}
				);
		}
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private populateDashboard(dashboard: ReportModel[]) {
		dashboard.forEach((report, index) => {
			setTimeout(() => {
				const chartInstanceService = this.injector.get((services as any)[report.type]);
				if (new Date(report.details.dateTo) > new Date()) {
					this.reportingMetaInsightsService.populateChart(report, chartInstanceService, index);
				} else {
					this.reportingMetaInsightsService.updateChartInstance(index, report, chartInstanceService);
				}
			}, 2000);
		});
	}
}
