<div class="addedCards" *ngIf="!loading">
	<div class="title" [class.makebold]="source.payment">Card Details</div>
	<div *ngFor="let card of cardDetails">
		<div
			class="selectableDiv"
			[class.active-class]="paymentCard?.cardLastFourDigits == card.cardLastFourDigits && paymentCard?.cardType == card.cardType"
			[class.processing]="isprocessing"
			(click)="changeDefaultPaymentCard(card)"
		>
			<span class="check">
				<img
					src="assets/icons/Listing-Icon.svg"
					alt=""
					width="20px"
					*ngIf="paymentCard?.cardLastFourDigits !== card.cardLastFourDigits || paymentCard?.cardType !== card.cardType"
				/>
				<img
					src="assets/icons/fullfilled.png"
					alt=""
					width="20px"
					*ngIf="paymentCard?.cardLastFourDigits == card.cardLastFourDigits && paymentCard?.cardType == card.cardType"
				/>
			</span>
			<span class="card">
				<img class="visa" src="assets/icons/Visa.svg" alt="" *ngIf="card.cardType.toLowerCase() == 'visa'" />
				<img class="mastercard" src="assets/icons/Master.svg" alt="" *ngIf="card.cardType.toLowerCase() == 'mastercard'" />
				<img alt="Amex Card" class="amex" src="assets/icons/amex-card.svg" *ngIf="card.cardType.toLowerCase() == 'amex'" />
			</span>
			<span class="number">
				<strong>{{ card.cardType }} {{ card.funding }} Card</strong>
				/ {{ card.cardLastFourDigits }}
			</span>
			<span class="name">{{ (card?.billingInfo.holderName.split(' '))[0] }}</span>
			<span class="expiry">{{ card.expirationMonth }}/{{ card.expirationYear }}</span>
			<span class="status" [ngClass]="card.isDefault ? 'default-card' : 'default-none'">Default</span>
			<span class="action" (click)="openConfirmModal(card)" [hidden]="hideRemove">Remove</span>
			<span class="action" (click)="editCard(card)">Edit</span>
		</div>
	</div>
</div>
<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
