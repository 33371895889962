import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, skipWhile, take, takeUntil } from 'rxjs/operators';
import { AccountsService } from 'src/app/accounts/services/accounts.service';
import { TreeSelection } from 'src/app/shared/models/tree-selection.const';
import { UserSelection } from 'src/app/shared/models/user-selection.const';
import { GenericPopupComponent } from 'src/app/shared/pop-ups/generic-popup/generic-popup.component';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { GoogleService } from 'src/app/_services/google/google.service';
import { AdAccount } from '../../accounts/models/ad-account.model';
import { CampaignSmartCreateService } from '../../campaign-smart-create/services/campaign-smart-create.service';
import { LoadCampaignList, SaveCampaignTemplates } from '../../campaign-smart-create/state/smart-create.actions';
import { CampaignSmartCreateState } from '../../campaign-smart-create/state/smart-create.reducer';
import { LinkAccountDialogComponent } from '../../sidenav/link-account-dialog/link-account-dialog.component';
import { ChannelButton } from '../../sidenav/sidenav/sidenav-button-channel.model';
import { SourceChannel } from '../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AuthenticationService } from '../../_services/authentication.service';
import { AdAccountApiService } from '../../_services/facebook-accounts/ad-account-api.service';
import { AccountTypeService } from '../account-type.service';
import { MapperService } from '../services/mapper.service';
import { LoadFacebookAdAccounts, LoadGoogleAdAccounts, SetAdAccountSelection } from '../state/shared.actions';
import { getSelectedAdAccount, SharedState } from '../state/shared.reducer';
import { SelectedAdAccountModel } from '../state/state-entities/cached-ad-accounts.model';
import { getUserDetails, isUserFacebookOwner, isUserGoogleOwner, UserState } from '../state/user/user.reducer';
import { ToastNotificationService } from '../toast-notification/toast-notification.service';
import { TreeChecklistComponent } from '../tree-checklist/tree-checklist.component';
import { TreeChecklistService } from '../tree-checklist/tree-checklist.service';

@Component({
	selector: 'app-account-dropdown',
	templateUrl: './account-dropdown.component.html',
	styleUrls: ['./account-dropdown.component.scss']
})
export class AccountDropdownComponent implements OnInit, OnDestroy {
	@HostListener('document: click', ['$event'])
	public clickOutsideElement(event: MouseEvent): void {
		const isOutsideClick = !this.eRef.nativeElement.contains(event.target);
		if (isOutsideClick) {
			this.hideTab = true;
		}
	}
	@ViewChild('searchInputRef') private _searchInputRef: ElementRef;

	public adAccountSearchFormControl = new FormControl();
	public userSelection = UserSelection.selectAccounts;
	public hideTab = true;
	public dataForTree: any[];
	public selectedDataFroTree: any[];
	public adAccountsGroup: any[] = [];
	public channelButtons: ChannelButton[];
	public selectedButton: ChannelButton;
	public sourceChannel = SourceChannel;
	public filterSearch: string;
	public allAdAccounts: AdAccount[];
	public hideChannel: boolean;

	private readonly debounceDelay: number = 500;
	private unsubscriber$ = new Subject<void>();
	private routesToHide = ['/audience', '/pixel', '/campaign', '/campaign-builder'];
	private timeout: any;
	private treeChkListService: TreeChecklistService;
	private selectedAdAccount: AdAccount;
	private facebookBusinessOwnerId: string;
	private googleBusinessOwnerId: string;

	constructor(
		private eRef: ElementRef,
		private googleService: GoogleService,
		private router: Router,
		private adAccountService: AdAccountApiService,
		private authenticationService: AuthenticationService,
		private smartCreateService: CampaignSmartCreateService,
		private mapperService: MapperService,
		private matDialog: MatDialog,
		private accountTypeService: AccountTypeService,
		private store: Store<SharedState>,
		private userStore: Store<UserState>,
		private smartCreateStore: Store<CampaignSmartCreateState>,
		private accountsService: AccountsService,
		public toastNotificationService: ToastNotificationService
	) {}

	public ngOnInit(): void {
		this.userSelection = UserSelection.selectAccounts;
		this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe((event: RouterEvent) => {
			if (event instanceof NavigationStart || (event instanceof NavigationEnd && this.channelButtons.length)) {
				this.checkButtons(event.url);
			}
		});

		this.userStore
			.pipe(
				select(getUserDetails),
				skipWhile(details => !details),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(details => {
				if (this.getGoogleStatus()) {
					this.googleBusinessOwnerId = details?.FiledId.toLocaleString(); //
				} else {
					this.googleBusinessOwnerId = details?.GoogleBusinessOwnerId;
				}
				this.facebookBusinessOwnerId = details?.FacebookBusinessOwnerId;

				this.initChannelButtons();
				this.initializeAdAccounts();
				this.fetchStore();

				this.adAccountService.selectFirstAdAccountSubject.pipe(takeUntil(this.unsubscriber$)).subscribe(() => {
					this.treeChkListService.deselectAll();
					this.treeChkListService.selectNodes([this.allAdAccounts[0]]);
					this.adAccountService.setSelectedAdAccounts(this.allAdAccounts[0]);
					this.updateCurrentSelectionLabel();
				});
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.unsubscribe();
	}
	public getGoogleStatus(): boolean {
		if (!!localStorage.getItem(StorageKey.installedPlatorm)) {
			let allPatform: string[] = JSON.parse(localStorage.getItem(StorageKey.installedPlatorm));
			return allPatform.includes('Google');
		}
		return false;
	}
	public toggleDropdown(): void {
		this.hideTab = !this.hideTab;
		setTimeout(() => {
			this._searchInputRef.nativeElement.focus();
		}, 0);
	}

	public treeSelectionCallback(selection: AdAccount[]): void {
		const adAccounts = this.adAccountService.allAdAccounts.get(this.selectedButton.sourceChannel);
		const selectedAdAccount = adAccounts.find(adAccount => adAccount.name === selection[0].name);
		selection[0].id = selectedAdAccount.id;
		this.matDialog
			.open(GenericPopupComponent, {
				data: {
					image: TreeSelection.image,
					actionAnswer: TreeSelection.actionAnswer,
					noActionAnswer: TreeSelection.noActionAnswer,
					message: TreeSelection.message,
					header: TreeSelection.header
				},
				panelClass: ['generic-popup']
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.changeActiveAdAccount(selection);

					this.accountTypeService.selectedLabsCampaign$.next('Select adset or campaign');
					this.accountTypeService.reloadDexterHealth$.next(true);
					this.accountTypeService.optimizationScore$.next(true);
					this.accountsService
						.runDexterSyncStart()
						.pipe(
							take(1),
							catchError(error => {
								return EMPTY;
							})
						)
						.subscribe((msg: any) => {
							this.accountTypeService.reloadSync$.next(true);
							if (msg.data && typeof msg.data?.progress === 'number') {
							}
						});
				}
			});
	}

	public updateSmartCreateTemplates(): void {
		this.store
			.pipe(
				select(getSelectedAdAccount),
				skipWhile(account => !account),
				take(1)
			)
			.subscribe(account => {
				this.smartCreateStore.dispatch(new LoadCampaignList(account.adAccount.id));
				// this.smartCreateService.getCampaignTemplatesList(account.adAccount.id).subscribe(templates => {
				// 	const mappedTemplates = this.mapperService.convertToDropdownData(templates.reverse(), 'id', 'name').slice(0, 20);
				// 	this.smartCreateStore.dispatch(new SaveCampaignTemplates(mappedTemplates));
				// 	if (!mappedTemplates.length) {
				// 		this.router.navigate(['campaign/welcome']);
				// 	}
				// });
			});
	}

	public getTreeModel(model: TreeChecklistComponent): void {
		this.treeChkListService = new TreeChecklistService(model);
	}

	public onSelectedChannelChange(channelButton: ChannelButton): void {
		const channel = this.getNewChannel(channelButton.sourceChannel, this.selectedButton.sourceChannel);
		if (!(channel === this.selectedButton.sourceChannel)) {
			this.accountTypeService.setAccountType(channel);
			this.selectedButton = channelButton;
			this.selectedAdAccount = this.adAccountService.getSelectedAdAccount();
			this.adAccountService.setSelectedAdAccounts(this.selectedAdAccount, channel);
			this.setAdAccountChannelSelection();
			this.updateData();
		}
	}

	public searchForAccount(event: KeyboardEvent): void {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.filterSearch = (event.target as HTMLTextAreaElement).value;
			this.createDataForTree(this.filterSearch);
		}, this.debounceDelay);
	}

	private initializeAdAccounts(): void {
		if (this.googleBusinessOwnerId) {
			this.store.dispatch(new LoadGoogleAdAccounts());
			this.initializeSourceChannel();
			this.updateData();
			this.adAccountService
				.getAllGoogleAccounts()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(result => {
					this.adAccountService.setAllCachedAdAccounts(SourceChannel.Google, result);
					this.initializeSourceChannel();
					this.updateData();
				});
		}

		if (this.facebookBusinessOwnerId) {
			this.store.dispatch(new LoadFacebookAdAccounts());
			this.adAccountService
				.getAllAdAccounts()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					this.adAccountService.setAllCachedAdAccounts(SourceChannel.Facebook, res);
					this.initializeSourceChannel();
					this.updateData();
				});
		}
	}

	private reloadCurrentRoute(): void {
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};

		this.router.events.subscribe(evt => {
			if (evt instanceof NavigationEnd) {
				this.router.navigated = false;
				window.scrollTo(0, 0);
			}
		});
	}
	private changeActiveAdAccount(selection: AdAccount[]): void {
		this.selectedAdAccount = selection[0];
		this.adAccountService.setSelectedAdAccounts(selection[0]);
		this.updateCurrentSelectionLabel();
		this.hideTab = true;

		if (this.router.url.includes('/campaign/build-campaign/')) {
			this.router.navigate(['/campaign/welcome']);
		} else if (this.router.url.includes('/campaign/select-campaign')) {
			this.updateSmartCreateTemplates();
		} else {
		}
		if (this.router.url.includes('/optimize/dashboard')) {
			this.router.routeReuseStrategy.shouldReuseRoute = () => false;
			this.router.onSameUrlNavigation = 'reload';
			this.router.navigate(['/optimize/dashboard']);
			this.authenticationService.setRoute$.next('optimize');
		}
	}

	private createSelectionObject(): SelectedAdAccountModel {
		const selectedAdAccountModel = {
			adAccount: this.selectedAdAccount,
			sourceChannel: this.selectedButton.sourceChannel
		};

		return selectedAdAccountModel;
	}

	private initChannelButtons(): void {
		this.channelButtons = [];
		this.userStore.pipe(select(isUserFacebookOwner), take(1)).subscribe(isFacebookBusinessOwner => {
			if (isFacebookBusinessOwner) {
				this.channelButtons.push(
					new ChannelButton(SourceChannel.Facebook, 'assets/icons/Icon-facebook.svg', 'assets/icons/Icon-facebook-disabled.svg')
				);
			}
		});
		this.userStore.pipe(select(isUserGoogleOwner), take(1)).subscribe(isGoogleBusinessOwner => {
			if (isGoogleBusinessOwner) {
				this.channelButtons.push(
					new ChannelButton(SourceChannel.Google, 'assets/icons/google-icon-channel.svg', 'assets/icons/google-disabled-icon.svg')
				);
				this.checkButtons(this.router.url);
			}
		});
	}

	private getNewChannel(newChannel: SourceChannel, oldChannel: SourceChannel): SourceChannel {
		switch (newChannel) {
			case SourceChannel.Facebook:
				return this.tryConnectAccount(this.facebookBusinessOwnerId, newChannel, oldChannel);
			case SourceChannel.Google:
				//return this.tryConnectAccount(this.facebookBusinessOwnerId, newChannel, oldChannel);
				return this.tryConnectAccount(this.googleBusinessOwnerId, newChannel, oldChannel);
		}
	}

	private tryConnectAccount(adAccountId: string, newChannel: SourceChannel, oldChannel: SourceChannel): SourceChannel {
		if (adAccountId) {
			return newChannel;
		}
		//return newChannel;
		this.openLinkAccountDialog(newChannel);
		return oldChannel;
	}

	private openLinkAccountDialog(channel: SourceChannel): void {
		this.matDialog
			.open(LinkAccountDialogComponent, {
				data: channel,
				panelClass: 'link-account-section-popup'
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((hasLinkedAnAccount: boolean) => {
				if (hasLinkedAnAccount) {
					this.authenticationService.logout();
				}
			});
	}

	private initializeSourceChannel(): void {
		const sourceChannel = parseInt(localStorage.getItem(this.accountTypeService.localStorageChannelKey));

		if (!isNaN(sourceChannel) && sourceChannel >= 0 && sourceChannel < this.channelButtons.length) {
			this.selectedButton = this.channelButtons.find(btn => btn.sourceChannel === sourceChannel);
		} else {
			if (this.facebookBusinessOwnerId) {
				this.selectedButton = this.channelButtons.find(btn => btn.sourceChannel === SourceChannel.Facebook);
			} else if (this.googleBusinessOwnerId) {
				this.selectedButton = this.channelButtons.find(btn => btn.sourceChannel === SourceChannel.Google);
			} else {
				this.authenticationService.logout();
				return;
			}
		}

		this.adAccountService.setSelectedAdAccounts(this.adAccountService.getSelectedAdAccount(), this.selectedButton.sourceChannel);
		this.setAdAccountChannelSelection();
		this.accountTypeService.setAccountType(this.selectedButton.sourceChannel);
	}

	private setAdAccountChannelSelection(): void {
		if (this.selectedAdAccount && this.selectedButton) {
			this.store.dispatch(new SetAdAccountSelection(this.createSelectionObject()));
		}
	}

	private updateData(): void {
		if (this.treeChkListService) {
			const data = this.adAccountService.getAllCachedAdAccounts(this.selectedButton.sourceChannel);

			if (data && data.length > 0) {
				if (!this.adAccountService.getSelectedAdAccount()) {
					this.adAccountService.setSelectedAdAccounts(data[0]);
				}

				this.adAccountsGroup = this.groupBy(this.adAccountService.getAllCachedAdAccounts(this.selectedButton.sourceChannel));

				this.createDataForTree();
			} else {
				this.treeChkListService.deselectAll();
				this.treeChkListService.updateDataSource([]);
			}

			this.updateCurrentSelectionLabel();
		}
	}

	private groupBy(list: AdAccount[]): AdAccount[] {
		return list.reduce(function (total, adAccount) {
			if (!total.length) {
				total.push({
					accounts: [adAccount],
					businessName: adAccount.businessName,
					checked: false
				});
			} else {
				const occ = total.find(i => i.businessName === adAccount.businessName);
				occ
					? occ.accounts.push(adAccount)
					: total.push({
							accounts: [adAccount],
							businessName: adAccount.businessName,
							checked: false
					  });
			}
			return total;
		}, []);
	}

	private createDataForTree(filter: string = ''): void | AdAccount {
		const regFilter = new RegExp(filter, 'i');
		const filterFunction = (e: AdAccount) => {
			if (regFilter.test(e.name)) {
				return e;
			}
		};

		this.dataForTree = [];
		this.adAccountsGroup.forEach(adAccountsGroup => {
			let adAccountChildren = adAccountsGroup.accounts.filter(filterFunction);
			if (adAccountChildren.length > 0) {
				this.dataForTree.push({
					children: adAccountChildren,
					name: adAccountsGroup.businessName
				});
			}
		});

		if (this.treeChkListService) {
			this.treeChkListService.deselectAll();
			this.treeChkListService.updateDataSource(this.dataForTree);
			this.treeChkListService.selectNodes([this.adAccountService.getSelectedAdAccount()].filter(filterFunction));
			this.treeChkListService.expandAll();
		}
	}

	private updateCurrentSelectionLabel(adAccount: AdAccount = null): void {
		let currentSelection: AdAccount;

		if (adAccount && adAccount.name) {
			currentSelection = adAccount;
			this.selectedAdAccount = adAccount;
		} else {
			currentSelection = this.adAccountService.getSelectedAdAccount();
		}

		if (currentSelection) {
			this.userSelection = currentSelection.name;
		} else {
			this.userSelection = UserSelection.selectAccounts;
		}
	}

	private fetchStore(): void {
		this.store.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(selectedAdAccount => {
			if (selectedAdAccount) {
				this.updateCurrentSelectionLabel(selectedAdAccount.adAccount);
			}
		});
	}

	private checkButtons(url: string): void {
		const btn = this.channelButtons.find(button => button.sourceChannel === SourceChannel.Google);
		if (btn) {
			for (const route of this.routesToHide) {
				btn.hideChannel = url.startsWith(route);
				if (btn.hideChannel) {
					this.onSelectedChannelChange(this.channelButtons.find(button => button.sourceChannel === SourceChannel.Facebook));
					break;
				}
			}
		}
	}
}
