<div class="inner-collapsible-card" [style.width]="width" [style.height]="height">
	<div class="header-title" (click)="toggleVisibility()">
		<span>{{ title }}</span>
		<div class="header-action">
			<span style="margin-right: 6px;">
				<btn07 label="Edit" (onClick)="onEditButtonClicked($event)"></btn07>
			</span>
			<span *ngIf="adSetCount">
				<span style="margin-right: 8px;">|</span>
				( {{ adSetCount }} Ad Set )
			</span>
			<ng-template [ngIf]="!hideAdsetCount && !adSetCount">
				<span style="margin-right: 8px;">|</span>
				<span>(All Ad Sets)</span>
			</ng-template>
			<div *ngIf="adSetCount" class="icon" [class.collapsed]="isCollapsed" (click)="toggleVisibility()">
				<svg xmlns="http://www.w3.org/2000/svg" width="12.544" height="7.288" viewBox="0 0 12.544 7.288">
					<defs>
						<style>
							.a {
								fill: #a9a9a9;
							}
						</style>
					</defs>
					<path
						class="a"
						d="M1.922.324,6.278,4.618,10.633.324a1.129,1.129,0,0,1,1.583,0,1.091,1.091,0,0,1,0,1.561L7.063,6.964a1.129,1.129,0,0,1-1.583,0L.328,1.884a1.091,1.091,0,0,1,0-1.561A1.154,1.154,0,0,1,1.922.324Z"
						transform="translate(12.544 7.288) rotate(180)"
					/>
				</svg>
			</div>
		</div>
	</div>
	<div class="inner-collapsible-card-body" *ngIf="!isCollapsed && adSetCount">
		<ng-content></ng-content>
		<ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
	</div>
</div>
