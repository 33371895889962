import { AxisPointer } from './axis-pointer';

/**
 * Tooltip class for chart
 */
export class Tooltip {
	/**
	 * Trigger
	 */
	trigger: string;

	/**
	 * Axis pointer
	 */
	axisPointer: AxisPointer;

	/**
	 * Tooltip position
	 */
	position: (point: any, params: any, dom: any, rect: any, size: any) => any;

	/**
	 * Constructor
	 *
	 * @param trigger
	 * @param axisPointer
	 */
	constructor(trigger: string, axisPointer: AxisPointer) {
		this.trigger = trigger;
		this.axisPointer = axisPointer;
		this.position = (point: any, params: any, dom: any, rect: any, size: any) => {
			return this.setTooltipPosition(point, params, dom, rect, size);
		};
	}

	/**
	 * Sets the tooltip position so it doesn't go out of bounds
	 *
	 * @param point
	 * @param params
	 * @param dom
	 * @param rect
	 * @param size
	 */
	setTooltipPosition(point: any, params: any, dom: any, rect: any, size: any) {
		const pointX = point[0];
		const pointY = point[1];
		const boxWidth = size.contentSize[0];
		const boxHeight = size.contentSize[1];
		const x = boxWidth > pointX ? 5 : pointX - boxWidth;
		const y = boxHeight > pointY ? 5 : pointY - boxHeight;

		return [x, y];
	}
}
