import { Component, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export interface PossibleAnswerInterface {
	id: number;
	value: string;
	description?: string;
}

@Component({
	selector: 'app-short-answer',
	templateUrl: './short-answer.component.html',
	styleUrls: ['./short-answer.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ShortAnswerComponent),
			multi: true
		}
	]
})
export class ShortAnswerComponent implements OnInit, ControlValueAccessor {
	constructor(private formBuilder: FormBuilder) {}
	@Input() public formControl?: FormGroup;
	@Input() public expanded = true;
	@Input() public placeholderText?: string;
	@Input() public itemIndex: number;

	@Output() public questionChanged: EventEmitter<FormControl> = new EventEmitter();
	@Output() public removeQuestion: EventEmitter<number> = new EventEmitter();
	public disabled = false;

	public value = '';
	public possibleQuestion: PossibleAnswerInterface[];
	public ansControl: FormControl;
	public shortAnsFormGroup: FormGroup;

	public onChange: any = () => {};
	public onTouched: any = () => {};

	public ngOnInit(): void {
		this.innitializeControl();
		this.possibleQuestion = [];
	}

	private innitializeControl(): void {
		this.shortAnsFormGroup.addControl('question', new FormControl(''));

		this.formControl.addControl('question', this.shortAnsFormGroup.controls.question);
	}

	public toggleExpansion(): void {
		this.expanded = !this.expanded;
		this.formControl = this.formBuilder.group({
			question: [null, [Validators.required]],
			possibleAnswers: this.formBuilder.array([this.createAnswers()])
		});
	}
	private createAnswers(): FormGroup {
		return this.formBuilder.group({
			text: ''
		});
	}

	public addPossibleAnswer(): void {
		this.possibleQuestion.push({ id: this.possibleQuestion.length, value: this.formControl.value.question });
		this.formControl.patchValue({ question: '' });
	}
	public removePossibleAnswer(index: number): void {
		this.possibleQuestion.splice(index);
	}

	public writeValue(value: string): void {
		this.value = value;
	}

	public registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	public removeItem(): void {
		this.removeQuestion.emit(this.itemIndex);
	}
}
