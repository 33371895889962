<div [ngClass]="{ 'selected-panel': isSelected }" class="expansion-panel-card">
	<div class="expansion-panel-title">
		<div class="title-info-expansion">
			<h5>{{ expansionTitle }}</h5>
			<app-info-hover *ngIf="infoHoverModel" [infoData]="infoHoverModel"></app-info-hover>
		</div>
		<p>{{ secondaryTitle }}</p>
	</div>
	<div #scrollMe class="expansion-panel-content" [ngClass]="{ 'expansion-panel-content-margin': contentMargin }">
		<h6>{{ subTitle }}</h6>
		<ng-content *ngIf="isExpanded" select="[expansion-content]"></ng-content>
	</div>
	<div *ngIf="isExpandable" class="expansion-panel-btn">
		<img (click)="onExpandClick(scrollMe)" alt="expansion-icon" src="{{ imagePath }}" />
	</div>
</div>
