<!--	Budget-->
<app-expansion-panel-sc
	(click)="onExpansionClick.emit()"
	[isSelected]="isPanelSelected"
	[isExpandable]="false"
	[expansionTitle]="'Campaign Budget Optimization'"
	[infoHoverModel]="cboInfoModel"
>
	<div expansion-content class="bsc-budget-optimization-wrapper">
		<div class="bsc-subtitle">
			<mat-slide-toggle (change)="onToggle()" [checked]="cboToggle"></mat-slide-toggle>
		</div>
		<div *ngIf="cboToggle" class="bsc-content">
			<div class="bsc-margin">
				<mat-radio-group class="bsc-radio-group" [formControl]="cboGroup.get('budgetType')" name="budgetType">
					<mat-radio-button class="bsc-radio-button" [value]="budgetType.LifeTime" (click)="onBudgetTypeClick(budgetType.LifeTime)" name="budgetType">
						<span>Lifetime</span>
					</mat-radio-button>
					<mat-radio-button class="bsc-radio-button" [value]="budgetType.Daily" (click)="onBudgetTypeClick(budgetType.Daily)" name="budgetType">
						<span>Daily</span>
					</mat-radio-button>
				</mat-radio-group>
				<div class="bsc-budget">
					<span>Budget</span>
					<div class="bsc-input">
						<app-input-number
							[validatorNumMessages]="validationMessage"
							[control]="cboGroup.get('amount')"
							placeholder="200"
							(keydown.space)="$event.preventDefault()"
							[currencyStr]="currency"
						></app-input-number>
					</div>
				</div>
				<div>
					<span class="bsc-radio-button">Campaign bid strategy</span>
					<mat-radio-button class="bsc-radio-button" [disabled]="true" [checked]="true" name="bid-strategy">
						<span>Lowest Cost</span>
					</mat-radio-button>
					<span class="bsc-radio-button">Delivery type</span>
					<mat-radio-button class="bsc-radio-button" [disabled]="true" [checked]="true" name="delivery-type"><span>Standard</span></mat-radio-button>
				</div>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
