import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectType } from '../../../dropdown-search-select/dropdown-type.enum';
import { AbstractControl } from '@angular/forms';
import { InstagramAccount } from '../../../../campaign-builder/models/instagram-account.model';
import { DropdownData } from '../../../dropdown-search-select/dropdown-data.interface';
import { SubObjectives } from '../../../../campaign-smart-create/models/sub-objectives';
import { InstagramService } from '../../../../_services/facebook-accounts/instagram.service';
import { InstagramPagesInterface } from './instagram-pages.interface';
import { DropdownInterface } from '@filed-com/filed-lib';
import { FiledLibMapperService } from 'src/app/shared/services/filed-lib-Mapper.service';
import { InfoHoverDataModel } from '../../../info/info-hover/info-hover-data.model';
@Component({
	selector: 'app-pages-card',
	templateUrl: './pages-card.component.html',
	styleUrls: ['./pages-card.component.scss']
})
export class PagesCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public facebookPages: DropdownInterface[];
	@Input() public instagramPages: DropdownData<InstagramAccount>[];
	@Input() public pagesFormGroup: AbstractControl;
	@Input() private selectedObjective: string;

	@Output() public onExpansionClick = new EventEmitter();

	public selectType = SelectType;
	public isPageLikesSelected: boolean;
	public instaAccountPage: boolean;
	public sortedInstagram: DropdownInterface[];
	public pageInfoModel: InfoHoverDataModel;

	constructor(private instagramService: InstagramService, private mapperService: FiledLibMapperService) {}

	public ngOnInit(): void {
		this.hideInstagramPages();
		this.getPages(false);
		this.autoSelect();
		this.pageInfoModel = {
			message:
				'Your Facebook Page or Instagram account represents your business in ads. Select the Facebook Page or an Instagram account you want to promote from the drop-down options.'
		};
	}

	public hideInstagramPages(): void {
		if (this.selectedObjective === SubObjectives.PageLikes) {
			this.isPageLikesSelected = true;
		}
	}

	public autoSelect(): void {
		if (!this.pagesFormGroup.get('facebookId').value && this.facebookPages.length >= 1) {
			this.pagesFormGroup.get('facebookId').patchValue(this.facebookPages[0].id);
		}
	}

	public getPages(change: boolean): void {
		if (change) {
			this.pagesFormGroup.get('instagramId').patchValue(null);
		}
		const selectedFacebookPage = this.pagesFormGroup.get('facebookId').value;
		if (selectedFacebookPage) {
			this.instagramService.getInstagramPages(selectedFacebookPage).subscribe(instagramPages => {
				if (instagramPages.length) {
					this.sortedInstagram = this.mapperService.convertToDropdownData<InstagramPagesInterface>(instagramPages, 'id', 'username');
					this.preselectPage();
					this.instaAccountPage = true;
				} else {
					this.pagesFormGroup.get('instagramId').patchValue(null);
					this.sortedInstagram = [];
					this.instaAccountPage = false;
				}
			});
		}
	}

	public preselectPage(): void {
		if (!this.pagesFormGroup.get('instagramId').value) {
			this.pagesFormGroup.get('instagramId').patchValue(this.sortedInstagram[0].id);
			this.pagesFormGroup.get('instagramId').markAsTouched();
		}
	}
}
