import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { AdsManagerState } from 'src/app/ads-manager/state/ads-manager.reducer';
import { getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { select, Store } from '@ngrx/store';
import { HideGlobalSpinner, LoadAdsManagerSettings, LoadSharedSettings, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { AdsManagerLoadBusinessViewsFactory } from 'src/app/ads-manager/state/utils/ads-manager-load-business-views-factory.service';
import { concatMap, map, skipWhile, take, tap } from 'rxjs/operators';
import { MetadataLoadActionFactory } from 'src/app/shared/state/utils/metadata-load-action-factory.service';
import { ServiceNamesEnum } from 'src/app/shared/services/general-settings/models/service-names.enum';
import { CampaignsService } from 'src/app/ads-manager//ads-manager-insights/services/campaigns.service';
import { AdSetsService } from 'src/app/ads-manager//ads-manager-insights/services/ad-sets.service';
import { AdsService } from 'src/app/ads-manager//ads-manager-insights/services/ads.service';
import { InsightsCategoryTypeEnum } from '../../shared/campaign-insights/models/insights-category-type.enum';
import { ViewsMapperService } from 'src/app/ads-manager//ads-manager-insights/services/views-mapper.service';
import { AgGridViewInterface } from '../../shared/master-table/models/ag-grid-view.interface';
import { AdsManagerResolverViewsInterface } from 'src/app/ads-manager//models/ads-manager-resolver-views.interface';
import { SelectedAdAccountModel } from '../../shared/state/state-entities/cached-ad-accounts.model';
import { getCurrencySymbol } from '@angular/common';
import { LoadFacebookPages } from '../../state/campaign-common-state/campaign-common-actions';

@Injectable()
export class AAAResolver implements Resolve<AdsManagerResolverViewsInterface> {
	constructor(
		private sharedStore: Store<SharedState>,
		private adsManagerStore: Store<AdsManagerState>,
		private userStore: Store<UserState>,
		private metadataLoadFactory: MetadataLoadActionFactory,
		private adsManagerBusinessViewsFactory: AdsManagerLoadBusinessViewsFactory,
		private campaignService: CampaignsService,
		private adSetsService: AdSetsService,
		private adsService: AdsService,
		private viewsMapper: ViewsMapperService
	) {}
	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AdsManagerResolverViewsInterface> {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.sharedStore.dispatch(new LoadFacebookPages());
		return this.userStore.pipe(
			select(getFiledId),
			skipWhile(value => !value),
			take(1),
			concatMap(filedId => {
				this.sharedStore.dispatch(new LoadSharedSettings(filedId));
				this.sharedStore.dispatch(this.metadataLoadFactory.getInstance(route.data.channel, route.data.category));
				this.adsManagerStore.dispatch(this.adsManagerBusinessViewsFactory.getInstance(route.data.channel, route.data.category));
				this.sharedStore.dispatch(
					new LoadAdsManagerSettings({
						userId: filedId,
						module: ServiceNamesEnum.AdsManager
					})
				);
				this.sharedStore.dispatch(new HideGlobalSpinner());

				return forkJoin([
					this.campaignService.getViews(),
					this.adSetsService.getViews(),
					this.adsService.getViews(),
					this.sharedStore.pipe(
						select(getSelectedAdAccount),
						skipWhile(value => !value || !value.adAccount),
						take(1)
					)
				]);
			}),
			map(
				([campaignViews, adSetViews, adViews, adAccount]: [
					AgGridViewInterface[],
					AgGridViewInterface[],
					AgGridViewInterface[],
					SelectedAdAccountModel
				]) => {
					this.viewsMapper.currency = getCurrencySymbol(adAccount.adAccount.currency, 'wide');
					this.viewsMapper.mapViews(campaignViews, this.campaignService, InsightsCategoryTypeEnum.Campaign);
					this.viewsMapper.mapViews(adSetViews, this.adSetsService, InsightsCategoryTypeEnum.AdSet);
					this.viewsMapper.mapViews(adViews, this.adsService, InsightsCategoryTypeEnum.Ad);
					const views: AdsManagerResolverViewsInterface = {
						campaignViews: campaignViews,
						adSetViews: adSetViews,
						adViews: adViews
					};
					return views;
				}
			),
			tap(() => this.sharedStore.dispatch(new HideGlobalSpinner()))
		);
	}
}
