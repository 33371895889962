import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AddAdComponent } from './add-ad/add-ad.component';
import { AddAdSetComponent } from './add-adset/add-adset.component';
import { InsightsCategoryTypeEnum } from '../shared/campaign-insights/models/insights-category-type.enum';
import { SourceChannel } from '../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AAACreateAdComponent } from './create-ad/create-ad.component';
import { LayoutComponent } from './layout/layout.component';
import { CreateAdResolver } from './resolvers/create-ad.resolver';
import { AAAResolver } from './resolver/aaa.resolver';
import { PreviewAdComponent } from './preview-ad/preview-ad.component';
import { CampaignGuard } from './guards/campaign.guard';
import { AddSelectionComponent } from './components/add-selection/add-selection.component';
import { AdSetResolver } from './resolvers/adset.resolver';
import { BestPerformingAdsetsComponent } from './components/best-performing-adsets/best-performing-adsets.component';

const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: 'add-ad',
				component: AddAdComponent,
				data: {
					category: InsightsCategoryTypeEnum.Campaign,
					channel: SourceChannel.Facebook
				},
				resolve: { views: AAAResolver }
			},
			{
				path: 'select-ad',
				component: AddSelectionComponent,
				data: {
					category: InsightsCategoryTypeEnum.Campaign,
					channel: SourceChannel.Facebook
				},
				resolve: { views: AAAResolver },
				canActivate: [CampaignGuard]
			},
			{ path: 'create', component: AAACreateAdComponent, resolve: { store: CreateAdResolver }, canActivate: [CampaignGuard] },
			{ path: 'ad-preview', component: PreviewAdComponent },
			{ path: 'add-adset', component: AddAdSetComponent, resolve: { store: AdSetResolver }, canActivate: [CampaignGuard] },
			{
				path: '',
				redirectTo: 'add-ad',
				pathMatch: 'full'
			},
			{
				path: 'select-best-performing-adsets',
				component: BestPerformingAdsetsComponent,
				data: {
					category: InsightsCategoryTypeEnum.Campaign,
					channel: SourceChannel.Facebook
				},
				resolve: { views: AAAResolver },
				canActivate: [CampaignGuard]
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AAARoutingModule {}
