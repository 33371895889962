import { MarkPointDataItem } from './mark-point-data-item.model';

export class MarkPoint {
	symbol: string;
	symbolSize: number;
	clickable: boolean;
	silent: boolean;
	label: {
		show: boolean;
		position?: string | number[]; // this can be a set of coordinates like [0, 70] or a string like 'bottom' or 'inside'
		color?: string;
		fontSize?: number;
		verticalAlign?: string;
	};
	data: MarkPointDataItem[];

	constructor() {
		this.symbol = 'pin';
		this.symbolSize = 20;
		this.clickable = false;
		this.silent = true;
		this.data = [];
		this.label = {
			show: true
		};
	}
}
