import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToastNotificationModel } from './toast-notification.model';
import { ToastNotificationType } from './toast-notification-type.enum';

@Injectable({
	providedIn: 'root'
})
export class ToastNotificationService {
	private toastSubject = new Subject<ToastNotificationModel>();
	private dismissSubject = new Subject<void>();

	constructor() {}

	public sendToast(message: string, type: ToastNotificationType, duration?: number): void {
		const toast = new ToastNotificationModel(message, type, duration);
		this.toastSubject.next(toast);
	}

	public sendCustomToast(message: string, type: ToastNotificationType, duration?: number, title?: string): void {
		const toast = new ToastNotificationModel(message, type, duration, title);
		this.toastSubject.next(toast);
	}

	public getToasts(): Observable<ToastNotificationModel> {
		return this.toastSubject.asObservable();
	}

	public getDismissSubject(): Observable<void> {
		return this.dismissSubject.asObservable();
	}

	public sendErrorToast(message: string, duration?: number): void {
		const toast = new ToastNotificationModel(message, ToastNotificationType.Error, duration);
		this.toastSubject.next(toast);
	}

	public sendSuccessToast(message: string, duration?: number): void {
		const toast = new ToastNotificationModel(message, ToastNotificationType.Success, duration);
		this.toastSubject.next(toast);
	}

	public sendWarningToast(message: string, duration?: number): void {
		const toast = new ToastNotificationModel(message, ToastNotificationType.Warning, duration);
		this.toastSubject.next(toast);
	}

	public sendInfoToast(message: string, duration?: number): void {
		const toast = new ToastNotificationModel(message, ToastNotificationType.Info, duration);
		this.toastSubject.next(toast);
	}

	public dismiss(): void {
		this.dismissSubject.next();
	}
}
