import { Asset } from 'src/app/shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';
import { AAAAssetsActions, AAAAssetsActionTypes } from './aaa-assets.actions';

export interface AAAAssetsState {
	userImages: Asset[];
	userVideos: Asset[];
	existingImages: Asset[];
	existingVideos: Asset[];
	pagePosts: Asset[];
}

export const initialAAAAssetsState: AAAAssetsState = {
	userImages: null,
	userVideos: null,
	existingImages: null,
	existingVideos: null,
	pagePosts: null
};

export function assetsReducer(state = initialAAAAssetsState, action: AAAAssetsActions): AAAAssetsState {
	switch (action.type) {
		case AAAAssetsActionTypes.LoadPagePostsSuccess: {
			return {
				...state,
				pagePosts: action.payload
			};
		}
		default:
			return state;
	}
}
