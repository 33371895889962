import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getCampaign, getPagePosts, getStepTwoDetails, getTemplates } from './smart-create.reducer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
	CampaignSmartCreateActionTypes,
	CreateCampaignTemplate,
	CreateCampaignTemplateFailure,
	CreateCampaignTemplateSuccess,
	CreateOptimizationDraftSuccess,
	LoadCampaignList,
	LoadCampaignListFailure,
	LoadCampaignListSuccess,
	LoadCampaignTemplate,
	LoadCampaignTemplateFailure,
	LoadCampaignTemplateSuccess,
	LoadPagePosts,
	LoadPagePostsFailure,
	LoadPagePostsSuccess,
	PublishCampaignFailure,
	PublishCampaignSuccess,
	UpdateCampaignTemplate,
	UpdateCampaignTemplateFailure,
	UpdateCampaignTemplateSuccess,
	UpdateOptimizationDraft,
	UpdateTemplateEnd,
	UpdateTemplateStart
} from './smart-create.actions';
import { catchError, exhaustMap, filter, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CampaignSmartCreateService } from '../services/campaign-smart-create.service';
import { EMPTY, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CampaignSmartCreatePythonService } from '../services/campaign-smart-create-python.service';
import { getSelectedAdAccount, SharedState } from '../../shared/state/shared.reducer';
import { HideGlobalSpinner } from '../../shared/state/shared.actions';
import { CampaignSmartCreateMapperService } from '../services/campaign-smart-create-mapper.service';
import { AssetService } from '../../shared/services/assets/asset.service';
import { getFacebookBusinessOwnerId, UserState } from '../../shared/state/user/user.reducer';
import { Placements } from '../../shared/smart-create-edit-components/step-two-components/placements-card/placements.model';
import { Router } from '@angular/router';
import { ToastNotificationType } from '../../shared/toast-notification/toast-notification-type.enum';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import {
	LoadCampaignCatalogsFailure,
	LoadExistingImages,
	LoadExistingVideos,
	LoadUserImages,
	LoadUserVideos,
	LoadFacebookPixels
} from '../../state/campaign-common-state/campaign-common-actions';
import { StepFiveDetails } from '../models/step-five-details';

@Injectable()
export class SmartCreateEffects {
	constructor(
		private actions: Actions,
		private assetService: AssetService,
		private smartCreateService: CampaignSmartCreateService,
		private smartCreatePythonService: CampaignSmartCreatePythonService,
		private campaignMappingService: CampaignSmartCreateMapperService,
		private store: Store<CampaignSmartCreateState>,
		private sharedStore: Store<SharedState>,
		private userStore: Store<UserState>,
		private router: Router,
		private toastService: ToastNotificationService
	) {}

	// public loadTemplateList$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadCampaignList>(CampaignSmartCreateActionTypes.LoadCampaignList),
	// 		mergeMap(payload => of(payload).pipe(withLatestFrom(this.store.pipe(select(getTemplates, { accountId: payload.payload }))))),
	// 		filter(([action, templates]) => !templates),
	// 		map(([action, templates]) => action.payload),
	// 		exhaustMap(accountId => {
	// 			return this.smartCreateService.getCampaignTemplatesList(accountId).pipe(
	// 				map(templates => new LoadCampaignListSuccess({ accountId: accountId, templates: templates })),
	// 				catchError(() => of(new LoadCampaignListFailure()))
	// 			);
	// 		})
	// 	)
	// );

	public loadTemplatesFailure$ = createEffect(() =>
		this.actions.pipe(
			ofType<LoadCampaignListFailure>(CampaignSmartCreateActionTypes.LoadCampaignListFailure),
			tap(() => this.sharedStore.dispatch(new HideGlobalSpinner()))
		)
	);

	// public loadCampaign$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadCampaignTemplate>(CampaignSmartCreateActionTypes.LoadCampaign),
	// 		withLatestFrom(this.store.pipe(select(getCampaign))),
	// 		filter(([action, campaign]) => !campaign || campaign.id !== action.payload),
	// 		map(([action, campaign]) => action.payload),
	// 		exhaustMap(id => {
	// 			return this.smartCreateService.getCampaignTemplate(id).pipe(
	// 				map(campaign => {
	// 					this.store.dispatch(new LoadUserVideos());
	// 					this.store.dispatch(new LoadFacebookPixels());
	// 					let selectedAddAccount: string;
	// 					this.userStore.pipe(select(getSelectedAdAccount), take(1)).subscribe(data => {
	// 						selectedAddAccount = data.adAccount.id;
	// 					});
	// 					let filterInstagram = false;
	// 					this.store.pipe(select(getStepTwoDetails), take(1)).subscribe(stepTwo => {
	// 						const instagram = stepTwo?.placements?.filter(item => item.name === Placements.Instagram);
	// 						if (instagram?.length) {
	// 							filterInstagram = true;
	// 						}
	// 					});
	// 					this.userStore.pipe(select(getFacebookBusinessOwnerId), take(1)).subscribe(businessId => {
	// 						this.store.dispatch(new LoadExistingImages({ adAccountId: selectedAddAccount, businessOwnerId: businessId }));
	// 						this.store.dispatch(new LoadExistingVideos({ adAccountId: selectedAddAccount, businessOwnerId: businessId, filterInstagram }));
	// 						if (campaign?.stepTwoDetailsAsJson?.facebookPageId) {
	// 							this.store.dispatch(
	// 								new LoadPagePosts({ adAccountId: campaign?.stepTwoDetailsAsJson?.facebookPageId, businessOwnerId: businessId })
	// 							);
	// 						}
	// 					});
	// 					return new LoadCampaignTemplateSuccess(campaign);
	// 				}),
	// 				catchError(() => of(new LoadCampaignTemplateFailure()))
	// 			);
	// 		})
	// 	)
	// );

	public loadCampaignsFailure$ = createEffect(
		() =>
			this.actions.pipe(
				ofType<LoadCampaignTemplateFailure>(CampaignSmartCreateActionTypes.LoadCampaignFailure),
				tap(() => this.sharedStore.dispatch(new HideGlobalSpinner()))
			),
		{ dispatch: false }
	);

	public loadCatalogsFailure$ = createEffect(() =>
		this.actions.pipe(
			ofType<LoadCampaignCatalogsFailure>(CampaignSmartCreateActionTypes.LoadCatalogsFailure),
			tap(() => this.sharedStore.dispatch(new HideGlobalSpinner()))
		)
	);

	// public createCampaign$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<CreateCampaignTemplate>(CampaignSmartCreateActionTypes.CreateCampaignTemplate),
	// 		map(action => action.payload),
	// 		exhaustMap(request => {
	// 			return this.smartCreateService.createCampaign(request).pipe(
	// 				map(response => {
	// 					return new CreateCampaignTemplateSuccess(response);
	// 				}),
	// 				catchError(() => of(new CreateCampaignTemplateFailure()))
	// 			);
	// 		})
	// 	)
	// );

	// public updateCampaign$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<UpdateCampaignTemplate>(CampaignSmartCreateActionTypes.UpdateCampaignTemplate),
	// 		tap(_ => this.store.dispatch(new UpdateTemplateStart())),
	// 		withLatestFrom(this.store.pipe(select(getCampaign))),
	// 		map(([action, campaign]) => action.payload || campaign),
	// 		exhaustMap(campaign => {
	// 			const updateRequest = this.campaignMappingService.mapCampaignToUpdateRequest(campaign);
	// 			return this.smartCreateService.updateCampaign(campaign.id, updateRequest).pipe(
	// 				map(() => new UpdateCampaignTemplateSuccess(campaign)),
	// 				catchError(err => {
	// 					if (err.error[0].code === 'Filed__Domain__NA__TemplateUpdate__IsNotAllowed') {
	// 						this.router.navigate(['campaign/welcome']);
	// 						this.toastService.sendCustomToast(
	// 							'Publishing for this campaign has already started and is still in progress. ',
	// 							ToastNotificationType.Warning,
	// 							8000
	// 						);
	// 					}
	// 					this.store.dispatch(new UpdateTemplateEnd());
	// 					return of(new UpdateCampaignTemplateFailure());
	// 				})
	// 			);
	// 		})
	// 	)
	// );

	// public publishCampaign$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<UpdateCampaignTemplate>(CampaignSmartCreateActionTypes.PublishCampaign),
	// 		withLatestFrom(this.store.pipe(select(getCampaign))),
	// 		map(([action, campaign]) => action.payload || campaign),
	// 		exhaustMap(campaign => {
	// 			if (campaign.stepFourDetailsAsJson.budgetAllocation) {
	// 				const budget = campaign.stepFourDetailsAsJson.budgetAllocation;
	// 				if (budget.campaignsBudget.length) {
	// 					budget.campaignsBudget.forEach(camp => {
	// 						delete camp.campaignName;
	// 					});
	// 				} else if (budget.adSetsBudget.length) {
	// 					budget.adSetsBudget.forEach(camp => {
	// 						delete camp.campaignName;
	// 						camp.adSets.forEach(adSet => {
	// 							delete adSet.adSetName;
	// 						});
	// 					});
	// 				}
	// 				campaign.stepFourDetailsAsJson.budgetAllocation = budget;
	// 			}
	// 			if (campaign.stepThreeDetailsAsJson.length > 0) {
	// 				const ads = campaign.stepThreeDetailsAsJson;
	// 				ads.forEach(ad => {
	// 					if (ad.hasOwnProperty('iframeUrl')) {
	// 						delete ad.iframeUrl;
	// 					}
	// 				});
	// 				campaign.stepThreeDetailsAsJson = ads;
	// 			}
	// 			const updateRequest = this.campaignMappingService.mapCampaignToUpdateRequest(campaign);
	// 			return this.smartCreateService.publishCampaign(campaign.id, updateRequest).pipe(
	// 				map(() => new PublishCampaignSuccess(campaign.stepFourDetailsAsJson)),
	// 				catchError(() => {
	// 					return of(new PublishCampaignFailure());
	// 				})
	// 			);
	// 		})
	// 	)
	// );
	// public createDraft$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<UpdateCampaignTemplate>(CampaignSmartCreateActionTypes.CreateOptimizationDraft),
	// 		withLatestFrom(this.store.pipe(select(getCampaign))),
	// 		map(([action, campaign]) => action.payload || campaign),
	// 		exhaustMap(campaign => {
	// 			if (campaign.stepFourDetailsAsJson.budgetAllocation) {
	// 				const budget = campaign.stepFourDetailsAsJson.budgetAllocation;
	// 				if (budget.campaignsBudget.length) {
	// 					budget.campaignsBudget.forEach(camp => {
	// 						delete camp.campaignName;
	// 					});
	// 				} else if (budget.adSetsBudget.length) {
	// 					budget.adSetsBudget.forEach(camp => {
	// 						delete camp.campaignName;
	// 						camp.adSets.forEach(adSet => {
	// 							delete adSet.adSetName;
	// 						});
	// 					});
	// 				}
	// 				campaign.stepFourDetailsAsJson.budgetAllocation = budget;
	// 			}
	// 			if (campaign.stepThreeDetailsAsJson.length > 0) {
	// 				const ads = campaign.stepThreeDetailsAsJson;
	// 				ads.forEach(ad => {
	// 					if (ad.hasOwnProperty('iframeUrl')) {
	// 						delete ad.iframeUrl;
	// 					}
	// 				});
	// 				campaign.stepThreeDetailsAsJson = ads;
	// 			}
	// 			const updateRequest = this.campaignMappingService.mapCampaignToUpdateRequest(campaign);
	// 			if (campaign?.stepFiveDetailsAsJson && campaign?.stepFiveDetailsAsJson?.draftStructureId) {
	// 				return this.smartCreateService.updateOptimizationDraft(campaign?.stepFiveDetailsAsJson?.draftStructureId, updateRequest).pipe(
	// 					map(res => {
	// 						return new UpdateOptimizationDraft();
	// 					}),
	// 					catchError(() => {
	// 						return of(new PublishCampaignFailure());
	// 					})
	// 				);
	// 			}
	// 			return this.smartCreateService.createOptimizationDraft(campaign.id, updateRequest).pipe(
	// 				map(res => {
	// 					const step5Details = new StepFiveDetails();
	// 					step5Details.draftStructureId = res?.draftStructureId;
	// 					campaign.draftStructureId = res?.draftStructureId;
	// 					campaign.stepFiveDetailsAsJson = step5Details;
	// 					return new CreateOptimizationDraftSuccess(campaign.stepFiveDetailsAsJson);
	// 				}),
	// 				catchError(() => {
	// 					return of(new PublishCampaignFailure());
	// 				})
	// 			);
	// 		})
	// 	)
	// );
	public createOptimizationDraftSuccess$ = createEffect(() => {
		return this.actions.pipe(
			ofType<CreateOptimizationDraftSuccess>(CampaignSmartCreateActionTypes.CreateOptimizationDraftSuccess),
			withLatestFrom(this.store.pipe(select(getCampaign))),
			mergeMap(([action, campaign]) => {
				campaign.draftStructureId = action.payload?.draftStructureId;
				campaign.stepFiveDetailsAsJson = action.payload;
				return of(new UpdateCampaignTemplate(campaign));
			})
		);
	});

	// public loadPagePosts$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadPagePosts>(CampaignSmartCreateActionTypes.LoadPagePosts),
	// 		withLatestFrom(this.store.pipe(select(getPagePosts))),
	// 		map(([action, images]) => action.payload),
	// 		exhaustMap(payload => {
	// 			return this.assetService.getAssetPagePosts(payload.adAccountId, payload.businessOwnerId).pipe(
	// 				map(assets => {
	// 					const mappedAssets = this.campaignMappingService.mapFacebookAssetToAssetPickerModel(assets);
	// 					return new LoadPagePostsSuccess(mappedAssets);
	// 				}),
	// 				catchError(() => of(new LoadPagePostsFailure()))
	// 			);
	// 		})
	// 	)
	// );
}
