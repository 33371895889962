export enum TargetingTypeEnum {
	NewAudience,
	SavedAudience,
	CustomAudience
}
export enum ParentTargetingTypeEnum {
	NewTarget,
	ReTargeting,
	AutomatedTargeting
}
