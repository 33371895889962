import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InlineSelection } from './inline-selection.model';

@Component({
	selector: 'app-inline-selector',
	templateUrl: './inline-selector.component.html',
	styleUrls: ['./inline-selector.component.scss']
})
export class InlineSelectorComponent implements OnInit {
	@Input() selectionList: InlineSelection[];
	@Output() itemSelected: EventEmitter<string | number> = new EventEmitter<string | number>();

	constructor() {}

	ngOnInit() {}

	public itemClicked(item: InlineSelection): void {
		this.selectionList.forEach(selection => {
			selection.selected = item.value === selection.value;
		});
		this.itemSelected.emit(item.value);
	}
}
