<div class="dropdown-select">
	<div class="button-container">
		<button
			(click)="toggleDropdown()"
			class="select"
			[class.activateControl]="!dropDownControl.disabled"
			[disabled]="dropDownControl.disabled"
			[class.greyout]="textToShow === placeholderText"
		>
			{{ textToShow }}
		</button>
		<ng-container *ngIf="showCaretIcon">
			<span class="k-icon k-i-kpi-trend-decrease" [style.color]="caretColor" *ngIf="!showDropdown"></span>
			<span class="k-icon k-i-kpi-trend-increase" [style.color]="caretColor" *ngIf="showDropdown"></span>
		</ng-container>
	</div>
	<div class="dropdown-content" [class.showHide]="showDropdown">
		<div class="input-group" *ngIf="allowFilter">
			<input type="text" class="form-control" placeholder="Search" [formControl]="searchControl" />
			<div class="icon">
				<span class="k-icon k-i-search"></span>
			</div>
		</div>
		<div class="content">
			<div *ngFor="let item of filterList; let i = index">
				<a (click)="onSelectItem(item.value)" [key]="i">{{ item.label }}</a>
			</div>
		</div>
	</div>
</div>
