import { AdsManagerPreferences } from 'src/app/shared/services/general-settings/models/ads-manager-preferences.model';
import { DataInterface } from '../data-interface.model';

export const initialState = {
	isLoaded: false,
	data: {
		selectedCampaigns: null,
		selectedAdsets: null,
		selectedAds: null,

		filteredCampaigns: null,
		filteredAdsets: null,
		filteredAds: null
	}
} as DataInterface<AdsManagerPreferences>;
