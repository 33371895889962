import { DynamicAds } from './dynamic-ads.enum';
import { AdFormatsEnum } from './ad-formats.enum';
import { CarouselDto, SingleAdvertDto } from '../../campaign-builder/models/advert.interface';
import { ConversionInterface } from './conversion-interface';

export class StepThreeDetails {
	public adName: string;
	public dynamicAds: DynamicAds = DynamicAds.Off;
	public adFormatType: AdFormatsEnum;
	public adverts: SingleAdvertDto | CarouselDto;
	public toggle: boolean;
	public pixelId: string;
	public eventRule: Object;
	public pixelAppEventId: string;
	public conversionId: string;
	public customEventType?: string;
	public pixelRule?: Object;
	public iframeUrl?: string;
	public facebookPageId?: string;
	public instagramPageId?: string;
}
