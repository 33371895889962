import { Injectable, OnInit } from '@angular/core';
import { DefaultChartValues } from '../../models/default-chart-values';
import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { DashboardModel } from '../../models/dashboard.model';
import { GoalModel } from '../../models/goal.model';
import { GoalService } from './goal.service';
import { Helper } from '../../helper';
import { GridLayout } from '../../models/grid-layout';
import { YAxis } from '../../models/y-axis';
import { ChartXAxis } from '../../models/chart-primary-axis';
import { ChartInformation } from '../../models/chart-information';
import { EChartOption } from 'echarts';
import { Legend } from '../../models/legend';
import { GoalOperator } from '../../models/goal-operator.enum';
import { getCurrencySymbol } from '@angular/common';
import { TableViewColumnTemplateEnum } from 'src/app/shared/generic-table2/models/table-structure/table-view-column-template.enum';
import { ChartTypeEnum } from '../../shared/charts/chart-type.enum';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { GetDashboardById } from '../../state/reporting.action';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { TooltipFormatterInterface } from './models/tooltip-formatter.interface';
import { ChartLabel } from '../../models/chart-label';
import { element } from 'protractor';

@Injectable({
	providedIn: 'root'
})
export class ChartService implements OnInit {
	public charts: Map<number, ChartInformation> = new Map<number, ChartInformation>();

	public chartTypes: Map<number, string> = new Map<number, string>();
	public dimensions: string[] = [];
	public chartData: any[] = [];

	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		this.chartTypes.set(1, 'line');
		this.chartTypes.set(3, 'bar');
		this.chartTypes.set(4, 'table');
		this.chartTypes.set(5, 'pie');
		this.chartTypes.set(8, 'numeric');
		this.chartTypes.set(6, 'donut');
		this.chartTypes.set(12, 'heat');
		this.chartTypes.set(14, 'bubble');
		this.chartTypes.set(15, 'singlenumber');
		this.chartTypes.set(16, 'scatter');
		this.chartTypes.set(17, 'image');
		this.chartTypes.set(18, 'text');
		this.chartTypes.set(10002, 'barmultipleline');
	}

	ngOnInit() {}

	public setChartInstances(chartIndex: number, chartInstance: any): Map<number, ChartInformation> {
		return this.charts.set(chartIndex, chartInstance);
	}

	protected getFormattedAxisData(widget: ReportModel, initialData: any, label: ChartLabel) {
		let formattedData;
		const currency = widget.details.adAccount?.currency;
		if (initialData.length) {
			formattedData = initialData.map((item: any) => {
				item['label'] = {
					show: label.show,
					position: 'inside',
					formatter: function (params: TooltipFormatterInterface) {
						return (
							(currency && widget.details.metric[0].typeId === TableViewColumnTemplateEnum.Currency
								? getCurrencySymbol(currency, 'narrow')
								: '') + +params.value
						);
					}
				};
				return item;
			});
		} else {
			formattedData = initialData;
		}
		return formattedData;
	}

	public removeChartInstance(chartIndex: number): boolean {
		return this.charts.delete(chartIndex);
	}

	public initializeChart(widget: ReportModel): any {
		const currencySymbol = widget.details.adAccount?.currency;
		const currency = currencySymbol ? currencySymbol : '';
		let metricName = '';
		let goal: GoalModel = new GoalModel();
		if (widget.details.metric && widget.details.metric.length && widget.details.metric[0].primaryValue && widget.details.metric[0].primaryValue.name) {
			metricName = widget.details.metric
				.map(metric => {
					return metric.primaryValue.name;
				})
				.join(',');
		}
		if (widget.details.goals) {
			goal = widget.details.goals.find(t => t.metric.toLowerCase() === metricName.toLowerCase());
		}
		const data: any = [];

		if (widget.details.chartData && Helper.checkReport(widget)) {
			widget.details.chartData.forEach((item: any) => {
				let itemName;
				if (widget.details.breakdown && widget.details.breakdown.columnName) {
					itemName = item[widget.details.breakdown.columnName];
				}
				if (widget.details.dimension && widget.details.dimension.primaryValue.name) {
					itemName = item[widget.details.dimension.primaryValue.name];
				}
				if (!widget.details.dimension && !widget.details.breakdown) {
					itemName = item[widget.details.reportLevel.toLowerCase() + '_name'];
				}
				const itemValue = item[widget.details.metric[0].primaryValue.name];
				if (itemValue !== null && itemValue !== undefined) {
					const itemStyle = this.createItemStyle(goal, widget, itemValue);
					const typeId = widget.details.metric[0]?.typeId;
					const tooltipValue = (value: TooltipFormatterInterface['value']) => {
						return currency && typeId === TableViewColumnTemplateEnum.Currency ? getCurrencySymbol(currency, 'narrow') + value : '' + value;
					};
					let toolTip = {
						formatter: (params: TooltipFormatterInterface) => {
							return tooltipValue(params.value);
						}
					};

					if (widget.type === ChartTypeEnum.Pie) {
						const metrics = widget.details?.metric;
						const firstMetricName = metrics?.length ? metrics[0]?.displayName : '';
						toolTip = {
							formatter: (params: TooltipFormatterInterface) => {
								return `${params.name} </br> ${params.marker} ${firstMetricName}: ${tooltipValue(params.value)} (${params.percent}%`;
							}
						};
					}

					data.push({
						value: Helper.round(itemValue) === 0 ? itemValue : +parseFloat((Math.round(itemValue * 100) / 100).toString()).toFixed(2),
						name: itemName,
						itemStyle: itemStyle,
						tooltip: toolTip
					});
				}
			});
		}

		const chartOptions: { [key: string]: any } = this.initChartOptions(widget);

		return {
			options: chartOptions,
			data: data
		};
	}

	public initChartOptions(widget?: ReportModel): any {
		const chartOptions: EChartOption = {};
		chartOptions.grid = new GridLayout('40px', '20px', '20px', '30%', false);
		chartOptions.animation = true;
		chartOptions.tooltip = {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow'
			},
			position: function (point: any, params: any, dom: any, rect: any, size: any) {
				if (!(params instanceof Array)) {
					params = [params];
				}
				const tooltipWidthPosition = point[0] < size.viewSize[0] / 2 ? point[0] : point[0] - size.contentSize[0];
				const tooltipHeightPosition = point[1] < size.viewSize[1] / 2 ? point[1] : point[1] - size.contentSize[1];

				if (size.contentSize[1] >= size.viewSize[1] / 2) {
					return [tooltipWidthPosition, (size.viewSize[1] - size.contentSize[1]) / 2];
				}
				return [tooltipWidthPosition, tooltipHeightPosition];
			}
		};
		chartOptions.legend = new Legend('horizontal', '0', '80%', 'circle');
		chartOptions.legend.data = [];
		if (widget && widget.details) {
			chartOptions.legend.data.push(...widget.details.metric.map(item => item.displayName));
		}

		if (widget) {
			const currency = widget.details.adAccount ? widget.details.adAccount.currency : '';
			const widgetMetrics = widget.details.metric;
			const widgetBreakdown = widget.details.breakdown;
			chartOptions.tooltip.formatter = (value: any) => {
				const title = value[0].name;
				const nonNullValues = value.filter((item: any) => parseInt(item.value, 10) !== 0);

				const values = nonNullValues
					.map((val: any) => {
						let isCurrency = widgetMetrics.find(metric => metric.displayName === val.seriesName)?.typeId === TableViewColumnTemplateEnum.Currency;
						if (widgetBreakdown) {
							if (widgetMetrics?.length) {
								isCurrency = widgetMetrics[0].typeId === TableViewColumnTemplateEnum.Currency;
							}
						}

						const value = val.value ? +Number(val.value).toFixed(2) : 0;
						const currencyStr = isCurrency ? getCurrencySymbol(currency, 'narrow') : '';
						const metricName = val.seriesName;

						return `<span class="tooltip-item-container">${val.marker}${metricName}: ${currencyStr} ${value}</span><br>`;
					})
					.join('')
					.substring(-5);
				return title + '<br>' + values;
			};
		}

		return chartOptions;
	}

	public createDataForMultipleExport(report: ReportModel[]): void {
		const excelData: any = [];
		report.forEach(report => {
			if (this.checkReportNameLengthValidity(report.name)) {
				if (report.details.simpleDataSource) {
					excelData.push({
						reportName: report.name,
						data: this.createSimpleChartExcelData(report)
					});
				} else {
					if (report.details.chartData && report.details.chartData.length > 0) {
						excelData.push({
							reportName: report.name,
							data: this.mapComplexDataForExcel(report)
						});
					} else {
						const setChartData = [report.details.chartData];
						report.details.chartData = setChartData;
						const setRawData = [report.details.rawData];
						report.details.rawData = setRawData;
						excelData.push({
							reportName: report.name,
							data: this.createSimpleChartExcelData(report)
						});
					}
				}
			} else {
				this.toastNotificationService.sendErrorToast(
					'We cannot export if the name of a report is longer than 31 characters. Please change the name and try again.'
				);
			}
		});
		if (this.checkExcelExportForDuplicateNames(excelData)) {
			this.toastNotificationService.sendErrorToast(
				'You cannot export a dashboard with multiple reports with the same name. Please change the name and try again'
			);
		} else {
			this.excelService.exportAsExcelFileWithMultipleSheets(excelData, 'export');
		}
	}

	private checkReportNameLengthValidity(name: string) {
		return name.length < 31;
	}

	private checkExcelExportForDuplicateNames(excelData: any[]) {
		if (excelData === []) {
			return false;
		} else {
			const seen = new Set();
			return excelData.some((currentObject: any) => {
				return seen.size === seen.add(currentObject.reportName).size;
			});
		}
	}

	public exportSingleChart(report: ReportModel): void {
		let excelData = null;
		if (report.name && this.checkReportNameLengthValidity(report.name)) {
			if (report.details.simpleDataSource) {
				excelData = this.createSimpleChartExcelData(report);
			} else {
				if (report.details.chartData && report.details.chartData.length && report.details.chartData.length > 0) {
					excelData = this.mapComplexDataForExcel(report);
				} else {
					const setChartData = [report.details.chartData];
					report.details.chartData = setChartData;
					const setRawData = [report.details.rawData];
					report.details.rawData = setRawData;
					excelData = this.mapComplexDataForExcel(report);
				}
			}
		} else {
			if (!report.name) {
				this.toastNotificationService.sendErrorToast('We cannot export if the name of a report is invalid');
			} else {
				this.toastNotificationService.sendErrorToast(
					'We cannot export if the name of a report is longer than 31 characters. Please change the name and try again.'
				);
			}
			return;
		}
		this.excelService.exportAsExcelFile(excelData, 'chart', report.name);
	}

	public mapComplexDataForExcel(report: ReportModel): any[] {
		const chartData: any[] = [];
		const dimensionName = report.details.dimension?.primaryValue.name;
		const breakdownName = report.details.breakdown ? report.details.breakdown.columnName : null;
		const campaignName = report.details.reportLevel ? report.details.reportLevel.toLowerCase() + '_name' : null;
		report.details.rawData.forEach((data: any, index: number) => {
			const result: any = {};
			const name = report.details.adAccount.name;

			if (report.type !== ChartTypeEnum.Table) {
				if (name) {
					result['Account name'] = report.details.adAccount.name;
				}
			}

			if (result.selectedCampaignIds?.length === 1) {
				result[result.details.reportLevel + ' Name'] = result.details.insights.find((insight: any) => {
					const chartData = result.details.chartData[index];

					return (
						insight.displayName.includes(chartData[Object.keys(chartData)[0]]) || insight.displayName.includes(chartData[Object.keys(chartData)[1]])
					);
				})?.displayName;
			}

			if (dimensionName) {
				result[report.details.dimension.displayName] = data[dimensionName];
			}
			if (breakdownName) {
				result[report.details.breakdown.displayName] = data[breakdownName];
			}
			if (campaignName && data[campaignName]) {
				result[report.details.reportLevel] = data[campaignName];
			}
			report.details.metric.forEach((metric, index) => {
				result[metric.displayName] = data[metric.primaryValue.name] ? data[metric.primaryValue.name] : report.details.chartData[index]?.required_metric;
			});

			chartData.push(result);
		});

		return chartData;
	}

	public calculateGoalColor(currentMetricGoal: GoalModel, dataValue: string): string {
		switch (currentMetricGoal.operator) {
			case GoalOperator.GreaterThan:
				return parseFloat(dataValue) > currentMetricGoal.max ? 'rgba(50,197,133,1)' : 'rgba(255,63,37,1)';
			case GoalOperator.LessThan:
				return parseFloat(dataValue) > currentMetricGoal.max ? 'rgba(255,63,37,1)' : 'rgba(50,197,133,1)';
			case GoalOperator.Between:
				return currentMetricGoal.max > parseFloat(dataValue) && currentMetricGoal.min < parseFloat(dataValue)
					? 'rgba(50,197,133,1)'
					: 'rgba(255,63,37,1)';
			case GoalOperator.NotBetween:
				return currentMetricGoal.max > parseFloat(dataValue) && currentMetricGoal.min < parseFloat(dataValue)
					? 'rgba(255,63,37,1)'
					: 'rgba(50,197,133,1)';
		}
	}

	public filterEmptyData(dataSource: any, hasBreakdowns: boolean) {
		const filteredDataSource: any[] = [];
		let dataKeysSet = new Set<string>();
		dataSource.forEach((data: any) => {
			const keys = Object.keys(data);
			if (hasBreakdowns) {
				if (keys.indexOf('null') === -1) {
					dataKeysSet = new Set([...dataKeysSet, ...keys]);
					filteredDataSource.push(data);
				}
			} else {
				dataKeysSet = new Set([...dataKeysSet, ...keys]);
				const values = Object.values(data);
				const filtered = values.filter(value => value == null);
				if (filtered.length !== keys.length - 1) {
					filteredDataSource.push(data);
				}
			}
		});
		return { filteredDataSource: filteredDataSource, dataKeysSet: dataKeysSet };
	}

	public createChartSourceForComplexData(
		widget: ReportModel,
		isStacked: boolean,
		normalize: boolean,
		isArea: boolean,
		label: any,
		compareMode: boolean = false
	): { data: any; dimensions: any[] } {
		let dimensions: any[] = [];
		let chartData: any = [];
		let dataSource: any;

		if (compareMode) {
			dataSource = widget.details.compareData;
		} else {
			dataSource = widget.details.chartData;
		}

		if (dataSource && dataSource.length > 0) {
			const mappedData: any[] = [];
			const filteredEmptyDataObject = this.filterEmptyData(dataSource, !!widget.details.breakdown);
			const dataKeys = [...filteredEmptyDataObject.dataKeysSet];
			let dimensionName = widget.details.dimension?.primaryValue?.name;
			if (!dimensionName) {
				dimensionName = widget.details.breakdown?.columnName;
			}
			const dimensionKey = dataKeys.splice(dataKeys.indexOf(dimensionName), 1)[0];
			if (widget.details.breakdown) {
				for (let i = 0; i < dataKeys.length; i++) {
					mappedData.push({
						title: dataKeys[i],
						name: dataKeys[i],
						data: []
					});
				}
			} else {
				for (const metric of widget.details.metric) {
					mappedData.push({
						title: metric.primaryValue.name,
						name: metric.displayName,
						columnType: metric.typeId,
						data: []
					});
				}
			}
			for (const data of filteredEmptyDataObject.filteredDataSource) {
				dimensions.push(data[dimensionKey]);

				for (const dataKey of dataKeys) {
					const mappedDataItem = mappedData.find(dataItem => dataItem.title === dataKey);
					if (mappedDataItem && mappedDataItem.data) {
						const dataValue = (Math.round(data[dataKey] * 100) / 100).toFixed(2);
						let dataObject;
						if (widget.details.goals.length) {
							if (!widget.details.breakdown) {
								const currentMetricGoal = widget.details.goals.find(goal => goal.metric === mappedDataItem.title);
								if (currentMetricGoal) {
									dataObject = {
										value: dataValue,
										itemStyle: {
											color: this.calculateGoalColor(currentMetricGoal, dataValue)
										}
									};
								}
							} else {
								dataObject = {
									value: dataValue,
									itemStyle: {
										color: this.calculateGoalColor(widget.details.goals[0], dataValue)
									}
								};
							}
						}
						mappedDataItem.data.push(dataObject ? dataObject : dataValue);
					}
				}
			}
			let typeId;
			if (widget.details.dimension) {
				typeId = widget.details.dimension.typeId;
			} else {
				typeId = 0;
			}
			for (const data of mappedData) {
				chartData.push({
					label: {
						normal: {
							show: widget.details.reportProperty.showDataLabels,
							align: label.align,
							verticalAlign: label.verticalAlign,
							position: label.position,
							rotate: label.rotate,
							distance: label.distance,
							fontSize: label.fontSize,
							formatter: label.formatter,
							rich: {
								name: {
									textBorderColor: 'rgba(0,0,0,1)'
								}
							}
						}
					},
					stack: isStacked ? 'stack' : '',
					areaStyle: isArea
						? {
								opacity: 0.5
						  }
						: null,
					name: data.name,
					breakdown: data.title,
					metric: data.title,
					connectNulls: (widget.type === ChartTypeEnum.Line || widget.type === ChartTypeEnum.Area) && typeId === TableViewColumnTemplateEnum.Date,
					type: this.chartTypes.get(widget.details.chartType.id),
					data: data.data
				});
			}

			const goalsSeries = this.createGoalsSeriesData(widget, chartData[0].data.length);
			chartData.push(...goalsSeries);
		} else {
			chartData = (DefaultChartValues as any)[widget.type].series;
			dimensions = (DefaultChartValues as any)[widget.type].xAxisLabels;
		}

		if (widget.details.breakdown && widget.details.dimension) {
			let dimension = widget.details.dimension.primaryValue.name;
			let breakdwn = widget.details.breakdown.columnName;
			let measure = widget.details.metric[0].primaryValue.name;
			let dimensionvalues: Array<string> = [];
			let breakdownvalues: Array<string> = [];
			let newmappedData: any[] = [];
			let newtypeId = 0;
			let newchartData: any = [];
			widget.details.chartData.forEach(element => {
				breakdownvalues.push(element[breakdwn]);
				dimensionvalues.push(element[dimension]);
			});

			for (const name of dimensionvalues.filter(Helper.onlyUnique)) {
				newmappedData.push({
					title: name,
					name: name,
					data: widget.details.chartData.filter(item => item[dimension] == name).map(item => item[measure])
				});
			}
			for (const data of newmappedData) {
				newchartData.push({
					label: {
						normal: {
							show: widget.details.reportProperty.showDataLabels,
							align: label.align,
							verticalAlign: label.verticalAlign,
							position: label.position,
							rotate: label.rotate,
							distance: label.distance,
							fontSize: label.fontSize,
							formatter: label.formatter,
							rich: {
								name: {
									textBorderColor: 'rgba(0,0,0,1)'
								}
							}
						}
					},
					stack: isStacked ? 'stack' : '',
					areaStyle: isArea
						? {
								opacity: 0.5
						  }
						: null,
					name: data.name,
					breakdown: data.title,
					metric: data.title,
					connectNulls: (widget.type === ChartTypeEnum.Line || widget.type === ChartTypeEnum.Area) && newtypeId === TableViewColumnTemplateEnum.Date,
					type: this.chartTypes.get(widget.details.chartType.id),
					data: data.data
				});
			}

			chartData = [];
			chartData = newchartData;
			dimensions = [];
			dimensions = breakdownvalues.filter(Helper.onlyUnique);
		}
		if (!widget.details.breakdown && !widget.details.dimension) {
			dimensions = [];
			chartData = [];
			const mappedData: any[] = [];
			let selectkey = widget.details.reportLevel.toLowerCase() + '_name';
			widget.details.chartData.forEach(element => {
				for (const key in element) {
					if (key == selectkey) {
						if (Object.prototype.hasOwnProperty.call(element, key)) {
							dimensions.push(element[key]);
						}
					}
				}
			});
			for (const metric of widget.details.metric) {
				mappedData.push({
					title: metric.primaryValue.name,
					name: metric.displayName,
					columnType: metric.typeId,
					data: widget.details.chartData.map(item => item[metric.primaryValue.name])
				});
			}
			let typeId;
			if (widget.details.dimension) {
				typeId = widget.details.dimension.typeId;
			} else {
				typeId = 0;
			}
			for (const data of mappedData) {
				chartData.push({
					label: {
						normal: {
							show: widget.details.reportProperty.showDataLabels,
							align: label.align,
							verticalAlign: label.verticalAlign,
							position: label.position,
							rotate: label.rotate,
							distance: label.distance,
							fontSize: label.fontSize,
							formatter: label.formatter,
							rich: {
								name: {
									textBorderColor: 'rgba(0,0,0,1)'
								}
							}
						}
					},
					stack: isStacked ? 'stack' : '',
					areaStyle: isArea
						? {
								opacity: 0.5
						  }
						: null,
					name: data.name,
					breakdown: data.title,
					metric: data.title,
					connectNulls: (widget.type === ChartTypeEnum.Line || widget.type === ChartTypeEnum.Area) && typeId === TableViewColumnTemplateEnum.Date,
					type: this.chartTypes.get(widget.details.chartType.id),
					data: data.data
				});
			}
		}
		return {
			data: chartData,
			dimensions: dimensions
		};
	}

	public createPrimaryAxis(chart: any, widget: ReportModel, nameGap: number, axisName: string): ChartXAxis {
		return new ChartXAxis('category', 'middle', 'rgba(153,153,153,1)', true, true, nameGap, axisName, chart.dimensions.filter(Helper.onlyUnique), false);
	}

	public createSecondaryAxis(chart: any, widget: ReportModel, axisKey: string, axisName: string, nameGap: number, secondaryAxis: number) {
		let axis: any = [];
		let metrics = [''];
		if (widget.details.metric && widget.details.metric.length && widget.details.metric[0].displayName) {
			metrics = widget.details.metric.map(metric => {
				return metric.primaryValue.name;
			});
		}
		const secondaryAxisMetric = metrics[metrics.length - 1];
		let secondaryAxisValues: any[] = [];
		let primaryAxisValues: any[] = [];

		if (widget.details.reportProperty.secondaryAxis) {
			for (let i = 0; i < chart.data.length; i++) {
				if (chart.data[i].metric === secondaryAxisMetric) {
					chart.data[i][axisKey] = 1;
					secondaryAxisValues = secondaryAxisValues.concat(chart.data[i].data.filter((t: any) => t !== undefined));
				} else {
					primaryAxisValues = primaryAxisValues.concat(chart.data[i].data.filter((t: any) => t !== undefined));
				}
			}

			axis = [
				new YAxis('value', nameGap, this.calculateSecondaryAxisValues(primaryAxisValues), axisName, 'rgba(153,153,153,1)', 'middle', false, false),
				new YAxis(
					'value',
					secondaryAxis,
					this.calculateSecondaryAxisValues(secondaryAxisValues),
					secondaryAxisMetric,
					'rgba(153,153,153,0.9)',
					'middle',
					false,
					false
				)
			];
		} else {
			if (chart && chart.data.length) {
				for (let i = 0; i < chart.data.length; i++) {
					if (chart.data[i].metric === secondaryAxisMetric) {
						delete chart.data[i][axisKey];
					}
				}
			}
			axis = new YAxis('value', nameGap, undefined, axisName, 'rgba(153,153,153,1)', 'middle', false, false);
		}
		return axis;
	}

	public createGoalColors(value: number, metric: string, widget: ReportModel): any {
		const goal = widget.details.goals.find(t => t.metric.toLowerCase() === metric.toLowerCase());

		if (goal && goal.operator) {
			return this.goalService.operatorMap.get(widget.type + goal.operator)(value, goal);
		}
	}

	public shadeColor(color: string, percent: number): string {
		let R = parseInt(color.substring(1, 3), 16);
		let G = parseInt(color.substring(3, 5), 16);
		let B = parseInt(color.substring(5, 7), 16);

		R = parseInt(String((R * (100 + percent)) / 100));
		G = parseInt(String((G * (100 + percent)) / 100));
		B = parseInt(String((B * (100 + percent)) / 100));

		R = R < 255 ? R : 255;
		G = G < 255 ? G : 255;
		B = B < 255 ? B : 255;

		const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
		const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
		const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

		return '#' + RR + GG + BB;
	}

	private createItemStyle(goal: GoalModel, widget: ReportModel, chartItem: any): void {
		if (goal && goal.operator) {
			return this.goalService.operatorMap.get(widget.type + goal.operator)(chartItem, goal);
		}
	}

	public createSimpleChartExcelData(widget: ReportModel): any[] {
		const excelData: any[] = [];
		if (widget.details.rawData) {
			let excelObject: any = {};

			if (widget.type === ChartTypeEnum.SingleNumber) {
				let rawData = widget.details.rawData[0];
				excelObject[Object.keys(rawData)[0]] = rawData[Object.keys(rawData)[0]];
				excelObject[widget.details.metric[0].primaryValue.name] = rawData[Object.keys(rawData)[1]] ? rawData[Object.keys(rawData)[1]] : 0;
				excelObject[Object.keys(rawData)[2]] = rawData[Object.keys(rawData)[2]] ? rawData[Object.keys(rawData)[2]] : 0;
				excelData.push(excelObject);
			} else {
				widget.details.rawData.forEach((item: any, index: number) => {
					const name = widget.details.adAccount.name;
					const excelObject: any = {};
					const dimension = widget.details.dimension.displayName;
					excelObject['Account Name'] = name ? name : '';
					if (widget.selectedCampaignIds?.length === 1) {
						excelObject[widget.details.reportLevel + ' Id'] = widget.details.insights.find(insight => {
							const chartData = widget.details.chartData[index];

							return (
								insight.displayName.includes(chartData[Object.keys(chartData)[0]]) ||
								insight.displayName.includes(chartData[Object.keys(chartData)[1]])
							);
						})?.displayName;
					}
					excelObject[dimension] = item[widget.details.dimension.primaryValue.name];
					excelObject[widget.details.metric[0].displayName] = item[widget.details.metric[0].primaryValue.name];
					excelData.push(excelObject);
				});
			}
		}
		return excelData;
	}

	private calculateSecondaryAxisValues(secondaryAxisValues: any[]): number {
		const values = secondaryAxisValues.filter(t => !!t.value).map(t => t.value);
		const max = Math.max(...values);

		if (Math.round(max) < max) {
			return Math.round(max) + 1;
		} else {
			return Math.round(max);
		}
	}

	public createGoalsSeriesData(widget: ReportModel, length: number): any {
		const series = [];
		for (const goal of widget.details.goals.filter(item => item.name !== '' && item.max !== 0)) {
			if (goal.min !== 0) {
				const minSeriesData = new Array(length).fill(goal.min);
				series.push({
					roam: false,
					data: minSeriesData,
					name: 'Min ' + goal.name,
					type: 'line'
				});
			}
			const maxSeriesData = new Array(length).fill(goal.max);
			series.push({
				roam: false,
				data: maxSeriesData,
				name: goal.min === 0 ? goal.name : 'Max ' + goal.name,
				type: 'line'
			});
		}

		return series;
	}
}
