import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownInterface } from '@filed-com/filed-lib';
import { select, Store } from '@ngrx/store';
import { AnyAaaaRecord } from 'dns';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { getUserRegistrationDetail } from 'src/app/authentication/state/authentication.reducer';
import { PermissionsApiOptionsService } from 'src/app/shared/permisions/permissions-api-options.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { UpdateProfile } from 'src/app/user-management/models/update-profile';
import { LoadFiledUser, SetAccountEditState, SetAccountSubmitState, SetUserProfileUserManagement } from 'src/app/user-management/state/user-management.actions';
import { getAccount, getFiledUser, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { UserDetailsInterface } from 'src/app/_models/identity-models/user-details.interface';
import { ActionParamsInterface } from '../../../../back-office/models/action-params.interface';
import { GenericPopUpComponent } from '../../../../back-office/popUps/generic-pop-up/generic-pop-up.component';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../../shared/state/shared.actions';
import { getFacebookBusinessOwnerId, getFiledId, UserState } from '../../../../shared/state/user/user.reducer';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { User } from '../../../../_models/user-models/user';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { UserServiceApi } from '../../../../_services/user/user.api.service';
import { NewUserModel } from '../../../models/new-user-model';
import { CompleteModalComponent } from '../modals/complete-modal/complete-modal.component';
import { VerifyAddUserComponent } from '../modals/verify-add-user/verify-add-user.component';
@Component({
	selector: 'app-my-profile',
	templateUrl: './my-profile.component.html',
	styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
	public userForm: FormGroup;
	public contactForm: FormGroup;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public isEdit: boolean = false;
	public isEdit2: boolean = false;
	public userId: string;
	public userDetail = new NewUserModel();
	public userRoles: any;
	public userProfile: any;

	public accessLevel = [
		{ label: 'Standard', value: 'Standard' },
		{ label: 'View only', value: 'View only' }
	];

	public decodeJwtToken: UserDetailsInterface;

	public loading: boolean = false;

	private filedId: number;

	constructor(
		public dialog: MatDialog,
		public backOfficeService: BackOfficeService,
		private formBuilder: FormBuilder,
		private toastNotification: ToastNotificationService,
		private activatedRoute: ActivatedRoute,
		private roleServices: PermissionsApiOptionsService,
		private sharedStore: Store<SharedState>,
		private store: Store<UserManagementState>,
		private userStore: Store<UserState>,
		private userServiceApi: UserServiceApi
	) {}

	public ngOnInit(): void {
		this.createForm();
		this.fetchStore();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	private fetchStore(): void {
		this.userStore.pipe(select(getFiledId), take(1)).subscribe(id => {
			if (id) {
				this.filedId = id;
				this.store.dispatch(new LoadFiledUser({ id: this.filedId, setSelectedUser: false, isBackoffice: false }));
			}
		});

		this.store.pipe(select(getFiledUser), takeUntil(this.unsubscriber$)).subscribe(state => {
			this.userProfile = state;
			let firstname = this.userProfile?.firstName !== '' && this.userProfile?.firstName !== undefined ? this.userProfile?.firstName : '';
			let lastname = firstname ? ` ${this.userProfile?.lastName}` : '';
			this.userForm.patchValue({
				company: this.userProfile?.companyName !== '' && this.userProfile?.companyName !== undefined ? this.userProfile.companyName : '',
				firstname: firstname,
				lastname: lastname,
				accessLevel: this.userProfile?.access ? this.userProfile?.access : 'Standard'
			});
			this.userForm.controls['firstname'].disable();
			this.userForm.controls['lastname'].disable();
			this.userForm.get('accessLevel').disable();
			this.userForm.get('company').disable();
			this.contactForm.patchValue({
				phone: this.userProfile?.phoneNumber,
				email: this.userProfile?.email
			});
			this.contactForm.controls['email'].disable();
			this.contactForm.get('phone').disable();
		});
	}

	private createForm(): void {
		this.userForm = this.formBuilder.group({
			firstname: new FormControl('', Validators.required),
			lastname: new FormControl('', Validators.required),
			company: new FormControl(''),
			accessLevel: new FormControl('', Validators.required)
		});

		this.contactForm = this.formBuilder.group({
			email: new FormControl(null, [Validators.required, Validators.email]),
			phone: new FormControl(null, [Validators.required, Validators.maxLength(70)])
		});
	}

	public getActivatedRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params) {
				this.userId = encodeURIComponent(params['userId']);
				this.isEdit = true;
			}
		});
	}

	public onFormChange(value: string): void {}

	public dropDownValueChanges(selected: any): void {
		if (selected) {
			this.userForm.get('accessLevel').setValue(selected);
		}
	}

	public openConfirmModal(): void {
		const dialogRef = this.dialog.open(CompleteModalComponent, {
			width: '480px',
			height: '360px',
			disableClose: true
		});
		dialogRef.componentInstance.modelDetail = {
			title: 'Account Detail',
			text: 'You have successfully changed your account details.',
			closeBtn: false,
			actionBtn: false
		};
		dialogRef.afterClosed().subscribe(() => {});
	}

	public saveProfile(): void {
		const firstname = this.userForm.controls['firstname'].value;
		const lastname = this.userForm.controls['lastname'].value;

		const payload: UpdateProfile = {
			companyName: this.userForm.get('company').value,
			phoneNumber: this.contactForm.get('phone').value,
			firstName: firstname,
			lastName: lastname,
			access: this.userForm.get('accessLevel').value,
			email: this.contactForm.get('email').value
		};
		this.loading = false;
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.userServiceApi
			.updateProfile(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					for (const control in this.userForm.controls) {
						this.userForm.controls[control].disable();
					}
					for (const control in this.contactForm.controls) {
						this.contactForm.controls[control].disable();
					}
					this.isEdit = false;
					this.isEdit2 = false;
					this.store.dispatch(new SetUserProfileUserManagement(this.userProfile));
					this.store.dispatch(new SetAccountEditState(false));
					this.store.dispatch(new SetAccountSubmitState(false));
					this.openConfirmModal();
				},
				() => {
					this.store.dispatch(new SetAccountEditState(false));
					this.store.dispatch(new SetAccountSubmitState(false));
					this.toastNotification.sendErrorToast('Something went wrong, please contact support');
				},
				() => {
					this.loading = false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
				}
			);
	}

	public editProfile(): void {
		this.isEdit = true;
		this.userForm.controls['firstname'].enable();
		this.userForm.controls['lastname'].enable();
		this.userForm.get('accessLevel').enable();
		this.userForm.get('company').enable();
	}

	public editProfile2(): void {
		this.isEdit2 = true;
		this.contactForm.controls['email'].enable();
		this.contactForm.get('phone').enable();
	}
}
