import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelCaseToText' })
export class CamelCaseToTextPipe implements PipeTransform {
	transform(value: string) {
		if (value === '') {
			return value;
		}

		let humanizedString = value.replace(/([^A-Z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][^A-Z])/g, '$1 $2');
		humanizedString = humanizedString.toLowerCase();
		humanizedString = humanizedString[0].toUpperCase() + humanizedString.slice(1);
		return humanizedString;
	}
}
