import { ReportModel } from './models/report.model';
import _ from 'lodash';
import { AdAccount } from '../accounts/models/ad-account.model';
import { ChartTypeEnum } from './shared/charts/chart-type.enum';

export class Helper {
	static onlyUnique(value: any, index: any, self: any): boolean {
		return self.indexOf(value) === index;
	}

	static pascalCaseToRegular(text: string) {
		return text.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
			return str.toUpperCase();
		});
	}

	public static deepEqual(object1: any, object2: any): boolean {
		if (!object1 || !object2) {
			return false;
		}

		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			const val1 = object1[key];
			const val2 = object2[key];
			const areObjects = Helper.isObject(val1) && Helper.isObject(val2);
			if ((areObjects && !Helper.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
				return false;
			}
		}

		return true;
	}

	public static objectDiffs(obj1: any, obj2: any): any {
		return _.reduce(
			obj1,
			(result: any, value, key) => {
				if (_.isPlainObject(value)) {
					result[key] = Helper.objectDiffs(value, obj2[key]);
				} else if (!_.isEqual(value, obj2[key])) {
					result[key] = value;
				}
				return result;
			},
			{}
		);
	}

	static isObject(object: any): boolean {
		return object !== null && typeof object === 'object';
	}

	static sanitizeWidget(report: ReportModel): ReportModel {
		const sanitizedReport = _.cloneDeep(report);
		sanitizedReport.details.adAccount = new AdAccount();
		sanitizedReport.details.resultPercentage = null;
		sanitizedReport.details.resultAmount = null;
		sanitizedReport.details.chartData = null;
		sanitizedReport.details.rawData = null;
		sanitizedReport.selectedCampaignIds = null;
		sanitizedReport.details.compareData = null;
		sanitizedReport.details.insights = null;
		sanitizedReport.details.dateFrom = null;
		sanitizedReport.details.dateTo = null;
		sanitizedReport.details.allInsights = {
			campaigns: [],
			adSets: [],
			ads: []
		};
		sanitizedReport.details.selectedInsights = {
			campaigns: [],
			adSets: [],
			ads: []
		};
		return sanitizedReport;
	}

	static round(number: number): number {
		return number && !isNaN(number) ? parseInt(number.toString().split('.')[0], 10) : 0;
	}

	static adjustCapitalWords(word: string): string {
		return word.replace(/([A-Z])/g, ' $1').trim();
	}

	static sum(values: any, property: string): number {
		let sum = 0;

		for (let i = 0; i < values.length; i++) {
			sum += !isNaN(values[i][property]) ? values[i][property] : 0;
		}

		return sum;
	}

	static encode(value: any): string {
		return encodeURIComponent(btoa(value));
	}

	static decode(value: any): string {
		return atob(decodeURIComponent(value));
	}

	static convertAxisValues(value: any): string {
		if (isNaN(value)) {
			return value.length > 10 ? value.substr(0, 10) + '...' : value;
		} else {
			if (value < 1000) {
				return value;
			} else if (value >= 1000 && value <= 999999) {
				return (value / 1000).toFixed(1) + 'K';
			} else if (value >= 1000000 && value <= 99999999) {
				return (value / 1000000).toFixed(1) + 'M';
			} else if (value >= 1000000000) {
				return (value / 1000000000).toFixed(1) + 'B';
			}
		}
	}

	static convertLabelValues(val: any): string {
		if (isNaN(val)) {
			return val.length > 10 ? val.substr(0, 10) + '...' : val;
		} else {
			const value = Number(val);
			if (value >= 1 && value < 1000) {
				return value.toFixed(2);
			} else if (value >= 1000 && value <= 999999) {
				return (value / 1000).toFixed(2) + 'K';
			} else if (value >= 1000000 && value <= 99999999) {
				return (value / 1000000).toFixed(2) + 'M';
			} else if (value >= 1000000000) {
				return (value / 1000000000).toFixed(2) + 'B';
			}
		}
	}

	static checkReport(widget: ReportModel): boolean {
		if (widget.type === ChartTypeEnum.SingleNumber) {
			return !!widget.details.insights?.length && !!widget.details.metric && !!widget.details.metric[0] && !!widget.details.metric[0].displayName;
		} else {
			return !!widget.details.insights?.length && !!widget.details.metric && !!widget.details.metric[0] && !!widget.details.metric[0].displayName;
		}
	}

	static uniqueValuesFromArray<T>(array: T[]): T[] {
		const a = array.concat();
		for (let i = 0; i < a.length; ++i) {
			for (let j = i + 1; j < a.length; ++j) {
				if (a[i] === a[j]) {
					a.splice(j--, 1);
				}
			}
		}
		return a;
	}

	static snakeToRegular(value: string): string {
		const tileCase = value[0].toUpperCase() + value.substring(1).toLowerCase();
		return tileCase.replace('_', ' ');
	}
}
