import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { AAAFlow, AAAStepNameEnum, AAAStepsEnum } from '../../models/AAA-steps.enum';
import { AaaHeaderFooterStateService } from '../../services/aaa-header-footer-state.service';
import { AAAModuleState } from '../../state';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatHorizontalStepper } from '@angular/material/stepper';

@Component({
	selector: 'app-aaanavigation-components',
	templateUrl: './aaanavigation-components.component.html',
	styleUrls: ['./aaanavigation-components.component.scss']
})
export class AAANavigationComponentsComponent implements OnInit {
	public activeStep: number = 0;
	public campaignStep: number = 0;
	public isCurrentStepValid = true;
	public processing = false;
	@Output() public navigationClick = new EventEmitter<AAAStepsEnum>();
	@ViewChild('stepper') public stepper: MatHorizontalStepper;
	@ViewChild('container') container: ElementRef<HTMLElement>;
	public campaignStepEnum = AAAStepsEnum;
	public htmlSelectors: Element[] = [];
	public navigations: INavigation[] = [];
	private unsubscriber$ = new Subject<void>();
	public campaignStepControl: any;
	public previewStepControl: any;
	public createStepControl: any;
	public skips: number[];
	public flow: AAAFlow;
	constructor(
		private store: Store<AAAModuleState>,
		private aaaHeaderFooterStateService: AaaHeaderFooterStateService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.registerQueryParamsSubscription();
	}
	public ngOnInit(): void {
		this.registerSteps();
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public registerQueryParamsSubscription(): void {
		this.route.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.flow = +params['flow'];
			this.navigations = this.setNavType(this.flow);
		});
	}

	public registerSteps(): void {
		const activeStep$ = this.aaaHeaderFooterStateService.getActiveStep();
		const skips$ = this.aaaHeaderFooterStateService.getSkips();

		combineLatest([activeStep$, skips$])
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(data => {
				const [activeStep, skips] = data;
				this.processing = true;
				this.activeStep = activeStep;
				this.skips = skips;
				setTimeout(() => (this.processing = false));
			});

		this.aaaHeaderFooterStateService
			.getCampaignStep()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(data => {
				if (data > this.campaignStep) {
					this.campaignStep = data;
				}
			});

		this.aaaHeaderFooterStateService
			.onNavigateForward()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((stepId: number) => {
				const stepIdExists = stepId > -1;
				const event = (stepIdExists ? { selectedIndex: stepId } : { selectedIndex: this.activeStep + 1 }) as StepperSelectionEvent;
				this.handleStepChange(event);
			});
	}

	public setNavType(type: AAAFlow): INavigation[] {
		switch (type) {
			case AAAFlow.createNewAd:
				return [
					{ name: 'Select Campaigns', link: AAAStepLinks.selectCampaigns },
					{ name: 'Create Ad', link: AAAStepLinks.createAd },
					{ name: 'Review and Publish', link: AAAStepLinks.reviewAndPublish }
				];
			case AAAFlow.bestPerformingAd:
				return [
					{ name: 'Select Campaigns', link: AAAStepLinks.selectCampaigns },
					{ name: 'Select ad', link: AAAStepLinks.selectAd },
					{ name: 'Create Ad', link: AAAStepLinks.createAd, skipMessage: 'This step was automatically skipped because you chose to continue' },
					{ name: 'Review and Publish', link: AAAStepLinks.reviewAndPublish }
				];
			case AAAFlow.createNewAdset:
				return [
					{ name: 'Select Campaigns', link: AAAStepLinks.selectCampaigns },
					{ name: 'Create adset', link: AAAStepLinks.createAdset },
					{
						name: 'Select ad',
						link: AAAStepLinks.selectAd,
						skipMessage: 'This step was automatically skipped as it is only relevant for when creating ads using best performing ones.'
					},
					{ name: 'Create Ad', link: AAAStepLinks.createAd, skipMessage: 'This step was automatically skipped because you chose to continue' },
					{ name: 'Review and Publish', link: AAAStepLinks.reviewAndPublish }
				];
			case AAAFlow.bestPerformingAdset:
				return [
					{ name: 'Select Campaigns', link: AAAStepLinks.selectCampaigns },
					{ name: 'Select Adset', link: AAAStepLinks.selectAdset },
					{
						name: 'Edit Adset',
						link: AAAStepLinks.createAdset,
						skipMessage: 'This step was automatically skipped because you chose to continue without editing the ad set.'
					},
					{
						name: 'Edit Ads',
						link: AAAStepLinks.createAd,
						skipMessage: 'This step was automatically skipped because you chose to continue without editing the ad set.'
					},
					{ name: 'Review and Publish', link: AAAStepLinks.reviewAndPublish }
				];
		}
	}

	public handleStepChange({ selectedIndex }: StepperSelectionEvent): void {
		const link = this.navigations[selectedIndex].link;
		const queryParams = { flow: this.flow };
		this.router.navigate([`/AAA/${link}`], { queryParams });
	}

	public stepSkipped(index: number): boolean {
		return this.skips.includes(index);
	}

	public isStepCompleted(index: number): boolean {
		return this.campaignStep > index && !this.stepSkipped(index);
	}

	public handleMouseOver(event: Event): void {
		const parent = this.container.nativeElement;
		const selector = (event.target as HTMLElement).closest('mat-step-header');

		if (selector?.hasAttribute('aria-labelledby')) {
			selector.classList.add('no-click');
			parent.classList.add('onHover');
			this.htmlSelectors.push(selector);
		}
	}

	public handleMouseLeave(): void {
		const parent = this.container.nativeElement;
		if (parent.classList.contains('onHover')) {
			this.htmlSelectors.forEach(el => el.classList.remove('no-click'));
			parent.classList.remove('onHover');
		}
	}
}

export interface INavigation {
	name: string;
	link: string;
	skipMessage?: string;
}

export enum AAAStepLinks {
	selectCampaigns = 'add-ad',
	createAd = 'create',
	createAdset = 'add-adset',
	selectAd = 'select-ad',
	selectAdset = 'select-best-performing-adsets',
	reviewAndPublish = 'ad-preview'
}
