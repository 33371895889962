import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ResetPasswordEmailDialogComponent } from './reset-password-email/reset-password-email-dialog.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SigninComponent } from './signin/signin.component';
import { TokenService } from '../_services/token.service';
import { FirstLoginDialogComponent } from './first-login-dialog/first-login-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GoogleService } from '../_services/google/google.service';
import { ConnectToSourceComponent } from './connect-to-source/connect-to-source.component';
import { ConnectToGoogleComponent } from './connect-to-google/connect-to-google.component';
import { ConnectToFacebookComponent } from './connect-to-facebook/connect-to-facebook.component';
import { authenticationReducer } from './state/authentication.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationEffects } from './state/authentication.effects';
import { GoogleLandingPageComponent } from './landing-pages/google-landing-page/google-landing-page.component';
import { FacebookLandingPageComponent } from './landing-pages/facebook-landing-page/facebook-landing-page.component';
import { ButtonsModule, FontsModule, ToastNotificationModule } from '@filed-com/filed-lib';
import { ResetPasswordWithEmailComponent } from './reset-password-with-email/reset-password-with-email.component';
import { SetCreditCardComponent } from './set-credit-card/set-credit-card.component';
import { SetFirstPasswordComponent } from './set-first-password/set-first-password.component';
import { TrialDialogComponent } from './trial-dialog/trial-dialog.component';
import { TrialEndComponent } from './trial-end/trial-end.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { TextBoxesModule } from '@filed-com/filed-lib';
import { CheckboxesModule } from '@filed-com/filed-lib';
import { ProgressBarComponentsModule } from '@filed-com/filed-lib';
import { MaskedTextBoxesModule } from '@filed-com/filed-lib';
import { DropdownsModule } from '@filed-com/filed-lib';
import { FloatingTextBoxesModule } from '@filed-com/filed-lib';
import { InterceptorsProvider } from '../_services/interceptors/intercepors.provider';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { AddBillingAddressComponent } from './add-billing-address/add-billing-address.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { PaymentComponent } from '../user-management/new-sprint/components/payment/payment.component';
import { UserSprintModule } from '../user-management/new-sprint/user-sprint.module';

@NgModule({
	imports: [
		AuthenticationRoutingModule,
		TranslateModule,
		FlexLayoutModule,
		SharedModule,
		RouterModule,
		SlickCarouselModule,
		StoreModule.forFeature('authentication', authenticationReducer),
		EffectsModule.forFeature([AuthenticationEffects]),
		ButtonsModule,
		TextBoxesModule,
		DropdownsModule,
		FloatingTextBoxesModule,
		FontsModule,
		ToastNotificationModule,
		MaskedTextBoxesModule,
		ProgressBarComponentsModule,
		DropdownsModule,
		FloatingTextBoxesModule
	],
	declarations: [
		SigninComponent,
		SetPasswordComponent,
		ResetPasswordEmailDialogComponent,
		FirstLoginDialogComponent,
		ConnectToSourceComponent,
		ConnectToGoogleComponent,
		ConnectToFacebookComponent,
		FacebookLandingPageComponent,
		GoogleLandingPageComponent,
		ResetPasswordWithEmailComponent,
		SetCreditCardComponent,
		SetFirstPasswordComponent,
		TrialDialogComponent,
		TrialEndComponent,
		SignUpComponent,
		AddPaymentComponent,
		AddBillingAddressComponent,
		GetStartedComponent
	],
	exports: [
		SigninComponent,
		SetPasswordComponent,
		ResetPasswordEmailDialogComponent,
		FacebookLandingPageComponent,
		GoogleLandingPageComponent,
		SetFirstPasswordComponent,
		ConnectToFacebookComponent
	],
	providers: [TokenService, GoogleService, InterceptorsProvider],
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthenticationModule {}
