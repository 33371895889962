import { Component, Input, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

export interface possibleAnswerInterface {
	id: number;
	value: string;
	description?: string;
}

@Component({
	selector: 'app-appointment-request',
	templateUrl: './appointment-request.component.html',
	styleUrls: ['./appointment-request.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AppointmentRequestComponent),
			multi: true
		}
	]
})
export class AppointmentRequestComponent implements OnInit, ControlValueAccessor {
	@Input() formControl?: FormGroup;
	@Input() expanded?: boolean = true;
	@Input() placeholderText?: string;
	@Input() itemIndex: number;

	@Output() questionChanged: EventEmitter<FormControl> = new EventEmitter();
	@Output() removeQuestion: EventEmitter<number> = new EventEmitter();

	disabled = false;
	checked = true;
	value = '';

	onChange: any = () => {};
	onTouched: any = () => {};
	public possibleAnser: possibleAnswerInterface[];
	public ansControl: FormControl;
	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.innitializeControl();
		this.possibleAnser = [];
	}

	private innitializeControl(): void {
		this.formControl = this.formBuilder.group({
			question: [null, [Validators.required]],
			confirmationMessage: [null, [Validators.required]]
		});
	}

	public addPossibleAnswer(): void {
		this.possibleAnser.push({ id: this.possibleAnser.length, value: this.formControl.value.qstnanswer });
		this.formControl.patchValue({ qstnanswer: '' });
	}
	public removePossibleAnswer(index: number): void {
		this.possibleAnser.splice(index);
	}

	public toggleExpansion(): void {
		this.expanded = !this.expanded;
	}
	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	removeItem(): void {
		this.removeQuestion.emit(this.itemIndex);
	}
}
