export class GridLayout {
	/**
	 * Left margin
	 */
	left: string;

	/**
	 * Right margin
	 */
	right: string;

	/**
	 * Top margin
	 */
	top: string;

	/**
	 * Bottom margin
	 */
	bottom: string;

	/**
	 * Contain label
	 */
	containLabel: boolean;

	/**
	 * Constructor
	 *
	 * @param left
	 * @param right
	 * @param top
	 * @param bottom
	 * @param containLabel
	 */
	constructor(left: string, right: string, top: string, bottom: string, containLabel: boolean) {
		this.left = left;
		this.right = right;
		this.top = top;
		this.bottom = bottom;
		this.containLabel = containLabel;
	}
}
