import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-selection-count',
	templateUrl: './selection-count.component.html',
	styleUrls: ['./selection-count.component.scss']
})
export class SelectionCountComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
