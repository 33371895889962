import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '../../_services/authentication.service';

@Injectable()
export class AuthenticationRedirectGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const isBigCommerce = route.queryParams.platform && route.queryParams.platform === 'bigcommerce';
		if (this.authService.isLoggedIn() && !isBigCommerce) {
			this.router.navigate(['/accounts']);
			return false;
		}
		return true;
	}
}
