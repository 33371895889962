<div class="wrapper">
	<div class="d-flex justify-content-between align-items-center mb-1" mat-dialog-title>
		<h3 class="title">Upload an image</h3>
		<div (click)="onNoClick()" class="x">
			<img alt="close" src="assets/icons/user-management/account/x.png" />
		</div>
	</div>

	<div class="uploadfilecontainer" (click)="uploadFileInput.click()" (onFileDropped)="handleFileInput($event)" appDragAndDrop>
		<div class="central" *ngIf="!fileIsUploading">
			<ng-template [ngIf]="!src">
				<img alt="upload" src="assets/icons/user-management/account/upload2.png" />
				<p>Drag an image here</p>
				<p>or click to upload</p>
				<app-button
					(buttonClicked)="uploadFileInput.click()"
					[buttonClass]="ButtonClassEnum.Primary"
					[buttonType]="ButtonTypeEnum.Simple"
					buttonLabel="Upload"
					class="button"
				></app-button>
			</ng-template>

			<ng-template [ngIf]="src">
				<div class="image">
					<app-rounded-image-container *ngIf="src" [src]="src"></app-rounded-image-container>
				</div>
			</ng-template>
		</div>
		<div class="bottom" *ngIf="!fileIsUploading">
			<p>
				Upload JPG, PNG or GIF with a maximum file size of 20mb
			</p>
		</div>
		<div class="central" *ngIf="fileIsUploading">
			<lolly-spinning-loader></lolly-spinning-loader>
			<p>Uploading Image</p>
		</div>
	</div>

	<input #dragFileInput #dropimage (change)="handleFileInput(dropimage.files)" disabled hidden type="file" />
	<input #uploadFileInput #uploadimage (change)="handleFileInput(uploadimage.files)" hidden type="file" />
</div>
