export class StorageKey {
	public static token = 'token';
	public static oldToken = 'oldToken';
	public static oldDecodedJwtIo = 'oldDecodedJwtIo';
	public static decodedJwtIo = 'decodedJwtIo';
	public static selectedAdAccounts = 'selectedAdAccounts';
	public static hideAdBlockerNotification = 'hideAdBlockerNotification';
	public static date = 'selectedDateRange';
	public static installedPlatorm = 'installedPlatorm';
}
