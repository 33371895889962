import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdAccountModel, BusinessModel } from '../../../../accounts/models/business.model';
import { getFacebookBusinessOwnerId } from '../../../../shared/state/user/user.reducer';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { AdAccountApiService } from '../../../../_services/facebook-accounts/ad-account-api.service';
import { UserServiceApi } from '../../../../_services/user/user.api.service';
import { FacebookBusiness } from '../../../models/facebook-business.model';
import { FacebookAdAccount } from '../../../models/new-user-model';
import { UserAccessLevelEnum } from '../../../models/user-access-level-enum';
import { UsersSelectedTypeEnum } from '../../../models/users-selected-type-enum';
import { UserManagementState } from '../../../state/user-management.reducer';

@Component({
	selector: 'app-user-account-access',
	templateUrl: './user-account-access.component.html',
	styleUrls: ['./user-account-access.component.scss']
})
export class UserAccountAccessComponent implements OnInit {
	@Input() public changes: Subject<boolean>;
	@Input() public activeStep: boolean;
	@Input() public isEdit: boolean;
	@Input() public userDataId: number;
	@Output() public isDoneLoading: EventEmitter<boolean> = new EventEmitter();
	@Output() public formIsValid: EventEmitter<boolean> = new EventEmitter();
	@Output() public userData: EventEmitter<BusinessModel[]> = new EventEmitter();

	public finalObject = new FacebookBusiness();
	public businessLength: number = 0;
	public BOInfo: BusinessModel[] = [];
	public businessWithAdAccounts: BusinessModel[] = [];
	public filteredBOInfo: BusinessModel[] = [];
	public selectedBusiness: BusinessModel[] = [];
	public userAccessLevelEnum = UserAccessLevelEnum;
	public usersSelectedTypeEnum = UsersSelectedTypeEnum;
	public selectedType: string;
	public accessLevel: boolean;
	public boSearchFormControl: FormGroup;
	public adSearchFormControl: FormGroup;
	public checkBO: boolean;
	public checkAd: boolean;
	public businessOwnerFacebookId: string;
	public allAdAccounts: FacebookAdAccount[];
	public filteredAdAccounts: FacebookAdAccount[];
	private unsubscriber$ = new Subject<void>();

	constructor(
		private formBuilder: FormBuilder,
		private adAccountApiService: AdAccountApiService,
		private store: Store<UserManagementState>,
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService
	) {}

	public ngOnInit(): void {
		this.getAllAdAccounts();
		this.getClientEmployee();
		this.fetchStore();
		this.getBOBusinesses();
		this.businessManagerSelect();
		this.defaultAccessLevel();
		this.createForm();
		this.listenForNextStep();
		this.filteredDataBoInfo();
		this.filterDataAdAccounts();
	}

	public ngOnChanges(): void {
		if (this.activeStep) {
			this.formIsValid.emit(true);
		}
	}

	public listenForNextStep(): void {
		this.changes.subscribe(v => {
			this.sendDataBack();
		});
	}

	public accessLevelEvent(accessLevel: UserAccessLevelEnum): void {
		if (accessLevel === UserAccessLevelEnum.AllAdAccounts) {
			this.accessLevel = false;
			this.sendDataBack();
		} else if (UsersSelectedTypeEnum.AdAccounts) {
			this.formIsValid.emit(false);
			this.accessLevel = true;
		}
	}

	public defaultAccessLevel(): void {
		const accessLevel = this.isEdit ? UserAccessLevelEnum.IndividualAdAccounts : UserAccessLevelEnum.AllAdAccounts;
		this.accessLevelEvent(accessLevel);
	}

	public getClientEmployee(): void {
		if (this.userDataId) {
			this.getAllAdAccounts();
			this.userServiceApi
				.getUserById(this.userDataId, false)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					res => {
						res.facebookBusinessPermissions.ids.forEach(clientBusiness => {
							this.BOInfo.map(business => {
								if (clientBusiness === business.FacebookBusinessId) {
									business.checked = true;
								}
							});
						});
						res.facebookAdAccountPermissions.ids.forEach(receivedAdAccount => {
							this.BOInfo.map(business => {
								business.FacebookAdAccountPermissions.filter(adAccount => adAccount.FacebookAdAccountId === receivedAdAccount).map(
									adAccount => {
										adAccount.checked = true;
									}
								);
							});
							this.filteredAdAccounts = this.filteredAdAccounts.map(clientAdAccount => {
								if (receivedAdAccount === clientAdAccount.id) {
									return {
										...clientAdAccount,
										checked: true
									};
								}
								return clientAdAccount;
							});
						});
					},
					() => {
						this.toastNotification.sendErrorToast('An error occurred, please try again later!');
						this.formIsValid.emit(false);
					},
					() => {
						this.checkIfEverythingIsCheckedForAd();
						this.checkIfEverythingIsCheckedForBO();
						this.sendDataBack();
						this.formIsValid.emit(true);
						this.isDoneLoading.emit(true);
					}
				);
		} else {
			this.filteredBOInfo = this.BOInfo;
		}
	}

	public businessManagerSelect(): void {
		this.selectedType = this.usersSelectedTypeEnum.BusinessManager;
	}

	public adAccountsSelect(): void {
		this.selectedType = this.usersSelectedTypeEnum.AdAccounts;
	}

	public getBOBusinesses(): void {
		this.adAccountApiService
			.getAllBOBusinesses(this.businessOwnerFacebookId)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res =>
					(this.BOInfo = res.businesses
						.filter(business => business.adAccounts.length > 0)
						.map(businesses => {
							return {
								name: businesses.name,
								FacebookBusinessId: +businesses.id,
								FacebookAdAccountPermissions: businesses.adAccounts.map(adAccount => {
									return {
										FacebookAdAccountId: adAccount.id,
										checked: false,
										name: adAccount.name,
										businessId: adAccount.businessId,
										id: adAccount.id
									};
								}),
								checked: false
							};
						})),
				() => this.toastNotification.sendErrorToast('An error occurred, please try again later!'),
				() => (this.filteredBOInfo = this.BOInfo)
			);
	}

	public checkBoxBOChange(event: BusinessModel): void {
		console.log(this.filteredBOInfo);
		event.checked = !event.checked;
		this.filteredBOInfo.map(business => {
			this.filteredAdAccounts.map(adAccounts => {
				if (event.checked) {
					if (adAccounts.businessId === event.FacebookBusinessId) {
						adAccounts.checked = true;
						business.FacebookAdAccountPermissions.map(adAccount => {
							if (adAccount.businessId === event.FacebookBusinessId) {
								adAccount.checked = true;
							}
						});
					}
				} else if (this.activeStep) {
					this.formIsValid.emit(true);
					if (!event.checked) {
						if (adAccounts.businessId === event.FacebookBusinessId) {
							adAccounts.checked = false;
						}
						this.formIsValid.emit(false);
					}
				}
			});
		});

		this.checkIfEverythingIsCheckedForBO();
		this.sendDataBack();
	}

	public checkBoxChange(event: AdAccountModel): void {
		event.checked = !event.checked;
		this.filteredBOInfo
			.filter(business => business.FacebookBusinessId === event.businessId)
			.map(business => {
				if (event.checked) {
					business.checked = true;
				}
				business.FacebookAdAccountPermissions.filter(adAccount => adAccount.FacebookAdAccountId === event.id).map(adAccounts => {
					adAccounts.checked = event.checked;
				});
				if (!event.checked && business.FacebookAdAccountPermissions.filter(adAccount => adAccount.checked).length === 0) {
					business.checked = false;
				}
			});
		this.filteredAdAccounts
			.filter(adAccount => adAccount.id === event.id)
			.forEach(adAccount => {
				if (event.checked) {
					adAccount.checked = true;
					this.formIsValid.emit(true);
				} else {
					adAccount.checked = false;
					this.formIsValid.emit(false);
				}
			});
		this.checkIfEverythingIsCheckedForAd();
		this.sendDataBack();
	}

	public sendDataBack(): void {
		if (!this.accessLevel) {
			if (this.activeStep) {
				this.formIsValid.emit(true);
			}
			this.userData.emit(this.filteredBOInfo);
		} else {
			this.selectedBusiness = this.filteredBOInfo
				.filter(business => business.checked && business.FacebookAdAccountPermissions.length > 0)
				.map(business => {
					return {
						...business,
						FacebookAdAccountPermissions: this.filteredAdAccounts
							.filter(adAccount => adAccount.businessId?.toString() === business.FacebookBusinessId?.toString())
							.map(adAccounts => {
								if (adAccounts.checked) {
									return {
										FacebookAdAccountId: adAccounts.id,
										name: adAccounts.name,
										checked: adAccounts.checked,
										businessId: adAccounts.businessId ? adAccounts.businessId : '',
										id: adAccounts.id
									};
								}
							})
					};
				});
			if (this.selectedBusiness.length > 0) {
				if (this.activeStep) {
					this.formIsValid.emit(true);
				}
				this.userData.emit(this.selectedBusiness);
			}
		}
	}

	public checkIfEverythingIsCheckedForBO(): void {
		const filteredBoInfoChecked = this.filteredBOInfo.filter(business => business.checked).length;
		const filteredBoInfoAll = this.filteredBOInfo.length;
		if (filteredBoInfoChecked < filteredBoInfoAll) {
			this.checkBO = false;
		} else if (filteredBoInfoChecked === filteredBoInfoAll) {
			this.checkBO = true;
		}
	}

	public checkIfEverythingIsCheckedForAd(): void {
		const filteredAdAccountsChecked = this.filteredAdAccounts.filter(business => business.checked).length;
		const filteredAdAccountsAll = this.filteredAdAccounts.length;
		if (filteredAdAccountsChecked < filteredAdAccountsAll) {
			this.checkAd = false;
		} else if (filteredAdAccountsChecked === filteredAdAccountsAll) {
			this.checkAd = true;
		}
	}

	public checkAllBO(): void {
		this.checkBO = !this.checkBO;
		if (this.checkBO) {
			this.filteredBOInfo
				.filter(business => business.checked)
				.map(business => {
					business.checked = false;
				});
			this.filteredBOInfo.forEach(business => {
				business.checked = true;
			});

			this.checkAd = true;
			this.filteredAdAccounts.forEach(adAccount => {
				adAccount.checked = true;
			});
			this.formIsValid.emit(true);
		} else {
			this.filteredBOInfo
				.filter(business => business.checked)
				.map(business => {
					business.checked = false;
				});

			this.checkAd = false;
			this.filteredAdAccounts.forEach(adAccount => {
				adAccount.checked = false;
			});
			this.formIsValid.emit(false);
		}
	}

	public checkAllAd(): void {
		this.checkAd = !this.checkAd;
		if (this.checkAd) {
			this.filteredAdAccounts
				.filter(adAccount => adAccount.checked)
				.map(adAccount => {
					adAccount.checked = false;
				});
			this.filteredAdAccounts.forEach(adAccount => {
				adAccount.checked = true;
			});

			this.checkBO = true;
			this.filteredBOInfo.forEach(business => {
				business.checked = true;
			});
			this.formIsValid.emit(true);
		} else {
			this.filteredAdAccounts
				.filter(adAccount => adAccount.checked)
				.map(adAccount => {
					adAccount.checked = false;
				});

			this.checkBO = false;
			this.filteredBOInfo.forEach(business => {
				business.checked = false;
			});
			this.formIsValid.emit(false);
		}
	}

	public getAllAdAccounts(): void {
		this.adAccountApiService
			.getAllAdAccounts()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				res =>
					(this.allAdAccounts = res.map(adAccounts => {
						return {
							id: adAccounts.id,
							name: adAccounts.name,
							checked: false,
							businessId: adAccounts.businessId
						};
					})),
				() => this.toastNotification.sendErrorToast('An error occurred, please try again later!'),
				() => (this.filteredAdAccounts = this.allAdAccounts)
			);
	}

	public filteredDataBoInfo(): void {
		this.boSearchFormControl
			.get('searchFormBoInfo')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(value => {
				this.filteredBOInfo = this.BOInfo.filter(data => {
					return data.name.toLowerCase().includes(value);
				});
				this.filteredBOInfo = [...this.filteredBOInfo];
			});
	}

	public filterDataAdAccounts(): void {
		this.adSearchFormControl
			.get('searchFormAdAccounts')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(value => {
				this.filteredAdAccounts = this.allAdAccounts.filter(data => {
					return data.name.toLowerCase().includes(value);
				});
				this.filteredAdAccounts = [...this.filteredAdAccounts];
			});
	}

	private createForm(): void {
		this.filteredAdAccounts = this.allAdAccounts;
		this.filteredBOInfo = this.BOInfo;

		this.boSearchFormControl = this.formBuilder.group({
			searchFormBoInfo: new FormControl(null)
		});
		this.adSearchFormControl = this.formBuilder.group({
			searchFormAdAccounts: new FormControl(null)
		});
	}

	private fetchStore(): void {
		this.store.pipe(select(getFacebookBusinessOwnerId), takeUntil(this.unsubscriber$)).subscribe(businessOwnerFacebookId => {
			this.businessOwnerFacebookId = businessOwnerFacebookId;
		});
	}
}
