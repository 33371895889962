import { DateRange } from '../../calendar/calendar.models';
import moment from 'moment';

export class DateHelper {
	public static getDaysInDateRange(range: DateRange): string[] {
		// safe creating new references
		const _range = new DateRange();
		_range.startDate = moment(range.startDate);
		_range.endDate = moment(range.endDate);
		const days: string[] = [];

		for (const day = _range.startDate; day <= _range.endDate; day.add(1, 'days')) {
			days.push(day.format('DD/MM/YY'));
		}
		return days;
	}
}
