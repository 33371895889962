import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { Store } from '@ngrx/store';
import { CampaignSmartCreateState } from '../../state/smart-create.reducer';
import { UpdateSplitByAgeRange } from '../../state/smart-create.actions';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
	selector: 'app-split-age-range-card',
	templateUrl: './split-age-range-card.component.html',
	styleUrls: ['./split-age-range-card.component.scss']
})
export class SplitAgeRangeCardComponent implements OnInit {
	@Input() public isSplitAgeRangeToggled: boolean;
	@Input() public yearsPerAdSet = 5;
	@Input() public maximumAgeRange: number;
	@Input() public isSelected: boolean;
	@Input() public control: FormControl | AbstractControl;
	@Input() public sliderControl: FormControl | AbstractControl;
	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public onAgeRangeSliderChange = new EventEmitter<number>();

	public options: Options = {
		floor: 1,
		ceil: 50,
		translate: (value: number, label: LabelType): string => {
			switch (label) {
				case LabelType.Low:
					return `${value} years/ad set`;
				case LabelType.High:
					return `${value} years/ad set`;
				default:
					return `${value}`;
			}
		}
	};

	constructor(public campaignSmartCreateStore: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {
		this.onComponentInit();
	}

	public onComponentInit(): void {
		if (this.maximumAgeRange) {
			this.options.ceil = this.maximumAgeRange;
		}
		if (this.isSplitAgeRangeToggled) {
			this.onAgeRangeSliderChange.emit();
		}
	}

	public onSplitAgeRangeToggle(): void {
		if (!this.yearsPerAdSet) {
			this.yearsPerAdSet = 5;
		}
		this.isSplitAgeRangeToggled = !this.isSplitAgeRangeToggled;
		this.campaignSmartCreateStore.dispatch(new UpdateSplitByAgeRange(this.isSplitAgeRangeToggled ? this.yearsPerAdSet : false));
		this.onAgeRangeSliderChange.emit();
		this.setNumberOfSplitsByAgeRange();
	}

	public onSliderChange(): void {
		this.campaignSmartCreateStore.dispatch(new UpdateSplitByAgeRange(this.yearsPerAdSet));
		this.onAgeRangeSliderChange.emit();
		this.setNumberOfSplitsByAgeRange();
	}

	public setNumberOfSplitsByAgeRange(): void {
		if (this.isSplitAgeRangeToggled) {
			const value = this.maximumAgeRange / this.yearsPerAdSet;
			const numberOfAdSets = Math.ceil(value);
			this.onAgeRangeSliderChange.emit(numberOfAdSets);
		} else {
			this.onAgeRangeSliderChange.emit(1);
		}
	}
}
