import { Component, Injector, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { PlatformEnum } from 'src/app/campaign-smart-create/models/platform.enum';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { AdCreationCardComponent } from 'src/app/shared/smart-create-edit-components/step-three-components/ad-format/ad-creation-card/ad-creation-card.component';
import {
	UpdatePreviewPlacement,
	UpdatePreviewIFrameSrc,
	UpdatePreviewWidth,
	UpdatePreviewHeight,
	SetFetchingIsIframe
} from '../../state/ad-preview/ad-preview.action';
import { FormGroup } from '@angular/forms';
import { AAAModuleState } from '../../state';
import { getCurrentAdDetails, getCurrentAdIndex, getFacebookId } from '../../state/ad-state';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { ButtonStatusModel } from 'src/app/shared/smart-create-edit-components/step-three-components/ad-format/ad-creation-card/button-status.model';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { Observable } from 'rxjs';
import { AdModel } from '../../models/ad.model';
import { CarouselDto, SingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
@Component({
	selector: 'app-ad-creation',
	templateUrl: './ad-creation.component.html',
	styleUrls: ['./ad-creation.component.scss']
})
export class AdCreationComponent extends AdCreationCardComponent implements OnInit {
	@Input('adCreateFormGroup') public adCreateFormGroup: FormGroup;
	public info: InfoHoverDataModel = { message: `Ad creation explanation goes here. Lorem ipsum tale hestos negras tremor doi. See more here` };

	constructor(injector: Injector, protected AAAstore: Store<AAAModuleState>) {
		super(injector);
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.registerAdChange();
		this.AAAstore.select(getFacebookId).subscribe(page => {
			this.facebookPageId = page;
		});
	}

	protected checkPlacement(placements: CatalogNodeInterface[], allPreviews: DropdownData<CatalogNodeInterface>[]): void {
		super.checkPlacement(placements, allPreviews);
		this.AAAstore.dispatch(new UpdatePreviewPlacement(this.dropdownPreviewPlacement));
	}

	protected checkPreview(): void {
		this.AAAstore.pipe(select(getCurrentAdDetails), takeUntil(this.unsubscriber$)).subscribe(data => {
			if (data.adFormatType === AdFormatsEnum.Carousel) {
				this.preview = data.adverts as CarouselDto;
				const advert = this.preview as CarouselDto;
				if (advert) {
					for (let i = 0; i < advert.cards.length; i++) {
						const formBuildControl = this.getCarouselCards().controls[i];
						if (formBuildControl) {
							formBuildControl
								.get('primary_text')
								.patchValue(advert.cards[i].primary_text ? advert.cards[i].primary_text : '', { emitEvent: false });
							formBuildControl.get('headline').patchValue(advert.cards[i].headline ? advert.cards[i].headline : '', { emitEvent: false });
							formBuildControl
								.get('website_url')
								.patchValue(advert.cards[i].website_url ? advert.cards[i].website_url : '', { emitEvent: false });
							formBuildControl
								.get('description')
								.patchValue(advert.cards[i].description ? advert.cards[i].description : '', { emitEvent: false });
							formBuildControl.get('media_url').patchValue(advert.cards[i].media_url ? advert.cards[i].media_url : '', { emitEvent: false });
							formBuildControl.get('picture').patchValue(advert.cards[i].picture ? advert.cards[i].picture : '', { emitEvent: false });
							formBuildControl.get('video_id').patchValue(advert.cards[i].video_id ? advert.cards[i].video_id : 0, { emitEvent: false });
							formBuildControl.get('mediaType').patchValue(advert.cards[i].mediaType ? advert.cards[i].mediaType : '', { emitEvent: false });
						}
					}
					if (!advert.cards[0]?.primary_text) {
						this.buttonStatusName = ButtonStatusModel.Add;
					} else {
						this.buttonStatusName = ButtonStatusModel.Change;
					}
				}
			} else {
				this.preview = data.adverts as SingleAdvertDto;
				const advert = this.preview as SingleAdvertDto;
				if (advert) {
					const formBuildControl = this.getCarouselCards().controls[0];
					if (formBuildControl) {
						formBuildControl.get('primary_text').patchValue(advert.primary_text ? advert.primary_text : '', { emitEvent: false });
						formBuildControl.get('headline').patchValue(advert.headline ? advert.headline : '', { emitEvent: false });
						formBuildControl.get('website_url').patchValue(advert.website_url ? advert.website_url : '', { emitEvent: false });
						formBuildControl.get('description').patchValue(advert.description ? advert.description : '', { emitEvent: false });
						formBuildControl.get('media_url').patchValue(advert.media_url ? advert.media_url : '', { emitEvent: false });
						formBuildControl.get('picture').patchValue(advert.picture ? advert.picture : '', { emitEvent: false });
						formBuildControl.get('video_id').patchValue(advert.video_id ? advert.video_id : 0, { emitEvent: false });
						formBuildControl.get('mediaType').patchValue(advert.mediaType ? advert.mediaType : '', { emitEvent: false });
						formBuildControl.get('call_to_action').patchValue(advert?.call_to_action ?? '', { emitEvent: false });
					}
					if (!advert?.primary_text) {
						this.buttonStatusName = ButtonStatusModel.Add;
					} else {
						this.buttonStatusName = ButtonStatusModel.Change;
					}
				}
			}
		});
	}

	protected generatePreview(): void {
		const formBuildControl = this.getCarouselCards().controls[this.selectedCardIndex];
		if (this.adFormat === AdFormatsEnum.Carousel && (this.getCarouselCards().value.length < 3 || this.getCarouselCards().invalid)) {
			return;
		}
		if ((!this.triggerGetAdverts && !this.selectedAssets.length) || (formBuildControl.invalid && this.adFormat !== AdFormatsEnum.PagePosts)) {
			return;
		}
		this.cancelPreview$.next();
		const previewDto = this.buildAdvertDto();
		this.onUpdateAdverts.emit(previewDto);
		this.iframeSrc = '';

		const placement = this.adCreateFormGroup.get('preview').value.toString();
		let request$: Observable<any>;
		// if (placement.includes(PlatformEnum.INSTAGRAM)) {
		// 	request$ = this.advertPreviewService.generateAdvertPreview(
		// 		this.facebookPageId,
		// 		this.selectedAdAccount.adAccount.id,
		// 		previewDto,
		// 		this.subObjective,
		// 		this.instagramPageId,
		// 		this.productSetID
		// 	);
		// } else {
		// 	request$ = this.advertPreviewService.generateAdvertPreview(
		// 		this.facebookPageId,
		// 		this.selectedAdAccount.adAccount.id,
		// 		previewDto,
		// 		this.subObjective,
		// 		'',
		// 		this.productSetID
		// 	);
		// }
		this.AAAstore.dispatch(new SetFetchingIsIframe(true));
		request$.pipe(takeUntil(this.cancelPreview$)).subscribe(
			result => {
				this.iframeSrc = result;
				const firstIndex = result.indexOf('src=');
				const secondIndex = result.indexOf('width=');
				const url = result.substring(firstIndex + 5, secondIndex - 2);
				this.setPreviewWidthAndHeight();
				this.AAAstore.dispatch(new UpdatePreviewWidth(this.previewWidth));
				this.AAAstore.dispatch(new UpdatePreviewHeight(this.previewWidth));
				const urlS = `<iframe src=${url} width=${this.previewWidth} height=${this.previewHeight} style="border: none" scrolling="no"></iframe>`;
				this.iframeSrc = urlS;
				this.AAAstore.dispatch(new UpdatePreviewIFrameSrc(this.iframeSrc));
				this.AAAstore.dispatch(new SetFetchingIsIframe(false));
			},
			error => {
				this.iframeSrc = error.error.description;
				this.AAAstore.dispatch(new SetFetchingIsIframe(false));
				this.AAAstore.dispatch(new UpdatePreviewIFrameSrc(this.iframeSrc));
			}
		);
	}

	public registerAdChange(): void {
		this.AAAstore.pipe(select(getCurrentAdIndex))
			.pipe(takeUntil(this.unsubscriber$))
			.pipe(switchMap(() => this.AAAstore.pipe(select(getCurrentAdDetails), take(1))))
			.subscribe((data: AdModel) => {
				this.checkPreview();
				this.selectedAssets = [];
				this.adCreateFormGroup.reset({}, { emitEvent: false });
				if (this.canUpdate(data)) {
					this.preview = data.adverts;
					this.getAdPreview();
				}
				this.adCreateFormGroup.get('pages').patchValue({ facebookId: data.facebookPageId, instagramId: data.instagramPageId });
			});
	}

	public canUpdate(data: AdModel): boolean {
		if (!data.adverts) return false;
		switch (data.adFormatType) {
			case AdFormatsEnum.Carousel: {
				const advert = data.adverts as CarouselDto;
				return !!(advert.cards[0].media_url || advert.cards[0].video_id);
			}
			case AdFormatsEnum.Video: {
				const advert = data.adverts as SingleAdvertDto;
				return !!(advert.video_id || advert.picture);
			}
			case AdFormatsEnum.PagePosts:
			default: {
				const advert = data.adverts as SingleAdvertDto;
				return !!(advert.media_url || advert.video_id);
			}
		}
	}

	public initOnDefault(): void {
		for (let i = 0; i < 3; i++) {
			if (this.getCarouselCards().controls.length === 3) {
				break;
			}
			this.getCarouselCards().push(this.cardAdItem());
		}

		this.onadCreateFormGroup.emit(this.adCreateFormGroup);
		if (this.selectedAssets.length || this.preview) {
			this.buttonStatusName = ButtonStatusModel.Change;
		} else {
			this.buttonStatusName = ButtonStatusModel.Add;
		}
	}
}
