import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export let FirstNameValidator: ValidatorMessages[];
export let LastNameValidator: ValidatorMessages[];
export let EmailValidator: ValidatorMessages[];
export let PositionValidator: ValidatorMessages[];
export let CompanyValidator: ValidatorMessages[];
export let PhoneValidator: ValidatorMessages[];
export let CommentValidator: ValidatorMessages[];
export let CardNumberValidator: ValidatorMessages[];
export let CardCvcValidator: ValidatorMessages[];
export let CardAdressValidator: ValidatorMessages[];
export let CardCountryValidator: ValidatorMessages[];
export let CardCityValidator: ValidatorMessages[];
export let CardZipValidator: ValidatorMessages[];
export let CardHolderNameValidator: ValidatorMessages[];
export let BusinessValidator: ValidatorMessages[];
export let AccesLevelValidator: ValidatorMessages[];
export let AdAccountValidator: ValidatorMessages[];
export let PasswordValidator: ValidatorMessages[];

export function positiveNumberValidator(): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const isNotOk = Number(control.value) < 0;
		return isNotOk ? { nonPositive: { value: control.value } } : null;
	};
}

export enum FieldValidators {
	Phone = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
	Email = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
}

PasswordValidator = [
	{
		validationType: 'incorect',
		validationMessage: 'Incorect password.'
	}
];

AdAccountValidator = [
	{
		validationType: 'required',
		validationMessage: 'Please select an ad account.'
	}
];

AccesLevelValidator = [
	{
		validationType: 'required',
		validationMessage: 'Please select an acess level.'
	}
];

BusinessValidator = [
	{
		validationType: 'required',
		validationMessage: 'Please select a business.'
	}
];

FirstNameValidator = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Name must not exceed 50 characters'
	}
];

LastNameValidator = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Surname must not exceed 50 characters'
	}
];

EmailValidator = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory'
	},
	{
		validationType: 'email',
		validationMessage: 'Email is not valid.'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Email must not exceed 70 characters'
	}
];

CompanyValidator = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Company must not exceed 100 characters'
	}
];

PhoneValidator = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory'
	},
	{
		validationType: 'pattern',
		validationMessage: 'Phone format is not valid.'
	},
	{
		validationType: 'minlength',
		validationMessage: 'Phone format is not valid.'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Phone must not exceed 15 characters'
	}
];

PositionValidator = [
	{
		validationType: 'required',
		validationMessage: 'This field is mandatory'
	},
	{
		validationType: 'maxlength',
		validationMessage: 'Position must not exceed 50 characters'
	}
];

CommentValidator = [
	{
		validationType: 'required',
		validationMessage: 'Comment is required'
	}
];

CardNumberValidator = [
	{
		validationType: 'required',
		validationMessage: 'Card number is required.'
	},
	{
		validationType: 'pattern',
		validationMessage: 'Invalid card number.'
	},
	{
		validationType: 'minLength',
		validationMessage: 'Invalid card number length.'
	}
];

CardHolderNameValidator = [
	{
		validationType: 'required',
		validationMessage: 'Card holder name is required.'
	}
];

CardCvcValidator = [
	{
		validationType: 'required',
		validationMessage: 'Card security number is required.'
	},
	{
		validationType: 'minLength',
		validationMessage: 'Invalid security number length.'
	}
];

CardAdressValidator = [
	{
		validationType: 'required',
		validationMessage: 'Address is required.'
	}
];

CardCityValidator = [
	{
		validationType: 'required',
		validationMessage: 'City is required.'
	}
];

CardZipValidator = [
	{
		validationType: 'required',
		validationMessage: 'Zip is required.'
	}
];

CardCountryValidator = [
	{
		validationType: 'required',
		validationMessage: 'Country is required.'
	}
];
