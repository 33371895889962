import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AAAStepsEnum } from '../models/AAA-steps.enum';
import { AAAFooterButtonInterface } from '../models/aaafooter-button.interface';

@Injectable({
	providedIn: 'root'
})
export class AaaHeaderFooterStateService {
	private complete = new Subject<void>();
	private preview = new Subject<boolean>();
	private cancel = new Subject<void>();
	private save = new Subject<void>();
	private edit = new Subject<void>();
	public hasEdit = new BehaviorSubject<AAAFooterButtonInterface>({ disabled: false, show: false });
	public stepperSkips = new BehaviorSubject<number[]>([]);
	private next = new Subject<void>();
	private navigateToStep = new Subject<number>();
	private back = new Subject<void>();
	public activeStep = new BehaviorSubject<number>(0);
	private campaignStep = new BehaviorSubject<number>(0);
	public getEditButtonStatus = this.hasEdit.asObservable();
	public editSelectedAds = new BehaviorSubject<boolean>(false);

	constructor() {}
	public onCancel(): Observable<void> {
		return this.cancel.asObservable();
	}
	public onSave(): Observable<void> {
		return this.save.asObservable();
	}
	public onNext(): Observable<void> {
		return this.next.asObservable();
	}
	public onNavigateForward(): Observable<number> {
		return this.navigateToStep.asObservable();
	}
	public onBack(): Observable<void> {
		return this.back.asObservable();
	}

	public getActiveStep(): Observable<number> {
		return this.activeStep.asObservable();
	}

	public getCampaignStep(): Observable<number> {
		return this.campaignStep.asObservable();
	}

	public sendOnCancel(): void {
		this.cancel.next();
	}

	public sendOnSave(): void {
		this.save.next();
	}

	public sendOnNext(): void {
		this.next.next();
	}

	public sendOnEdit(): void {
		this.edit.next();
	}

	public sendNavigateForward(number = -1): void {
		this.navigateToStep.next(number);
	}

	public goBack(): void {
		this.back.next();
	}

	public onPublish(): void {
		this.activeStep.complete();
	}

	public setSkips(value: number[]): void {
		this.stepperSkips.next(value);
	}

	public getSkips(): Observable<number[]> {
		return this.stepperSkips.asObservable();
	}

	public sendActiveStep(value: AAAStepsEnum): void {
		this.activeStep.next(value);
	}
	public sendCampaignStep(value: AAAStepsEnum): void {
		this.campaignStep.next(value);
	}

	public onPreviewAd(value: boolean): void {
		this.preview.next(value);
	}
	public setHasEdit(value: AAAFooterButtonInterface): void {
		return this.hasEdit.next(value);
	}
	public onEdit(): Observable<void> {
		return this.edit.asObservable();
	}
	public getEditButtonState(): Observable<AAAFooterButtonInterface> {
		return this.hasEdit.asObservable();
	}
	public getPreviewState(): Observable<boolean> {
		return this.preview.asObservable();
	}
	public onNavClick(): void {}

	public resetState() {
		this.setSkips([]);
		this.editSelectedAds.next(false);
		this.sendActiveStep(0);
		this.sendCampaignStep(0);
	}
}
