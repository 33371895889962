import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import $ from 'jquery';

require('./dayScheduleSelector.js');

@Component({
	selector: 'app-hour-selector',
	template: `
		<div></div>
	`,
	styleUrls: ['./hour-selector.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HourSelectorComponent implements OnInit {
	model: any;
	@Input() public options: any;
	@Output() public selection: EventEmitter<any> = new EventEmitter();

	element: any;

	constructor(element: ElementRef) {
		this.element = element;
	}

	ngOnInit() {
		// @ts-ignore
		this.model = $(this.element.nativeElement).dayScheduleSelector(this.options);
		//todo: append close - clear button
		//this.model.append(this.closeButtonTemplate);
		this.model.on('selected.filed.dayScheduleSelector', () => {
			this.clearSelection();
			const selection = this.showConversion();
			this.selection.emit(selection);
		});

		$(this.element.nativeElement).trigger('initial-selection', { initialSelection: this.options.initialSelection });
	}

	private showConversion(finalResults?: any): any {
		let initialResults = this.getResults();
		let _finalResults = finalResults;
		if (!initialResults.length) return;

		//begin process
		// find first unflagged item
		let $cell = $(initialResults.find((item: any) => !$(item).data('wasProccessed')));

		if (!$cell.length) {
			return _finalResults;
		}

		$cell.data('wasProccessed', true);

		return this.checkForSiblings($cell, _finalResults);
	}

	private checkForSiblings($cell: any, finalResults: any): any {
		finalResults = finalResults || [];

		// find next sibling
		const $nextSibling = this.findSibling($cell);
		const $hasPrevSibling = this.findPrevSibling($cell).length;

		if (!$nextSibling.length) {
			//create dto interval

			if (!$hasPrevSibling) {
				const obj: any = {};
				obj.weekDay = parseInt($cell.data('day'));
				obj.startTime = parseInt($cell.data('time').split(':')[0]) * this.options.interval;
				obj.endTime = obj.startTime + this.options.interval;

				finalResults.push(obj);
				$cell.data('wasProccessed', true);

				return this.showConversion(finalResults);
			}

			const entry = finalResults[finalResults.length - 1];
			entry.endTime = entry.endTime + this.options.interval;
			$cell.data('wasProccessed', true);
			// exit
			return this.showConversion(finalResults);
		}

		// has sibling
		// push it to the list
		if (finalResults.length === 0 || !$hasPrevSibling) {
			const obj: any = {};
			obj.weekDay = parseInt($cell.data('day'));
			obj.startTime = parseInt($cell.data('time').split(':')[0]) * this.options.interval;
			obj.endTime = obj.startTime + this.options.interval;

			finalResults.push(obj);

			$cell.data('wasProccessed', true);

			return this.checkForSiblings($nextSibling, finalResults);
		}

		const entry = finalResults[finalResults.length - 1];

		entry.endTime = entry.endTime + this.options.interval;

		$cell.data('wasProccessed', true);
		return this.checkForSiblings($nextSibling, finalResults);
	}

	private findPrevSibling($cell: any): any {
		const day = $cell.data('day');
		const cellTimeHour = parseInt($cell.data('time').split(':')[0]);
		const posibleSiblingList = this.getResults().filter((item: any) => {
			const $item = $(item);
			return $item.data('day') === day && $item.data('wasProccessed') === true;
		});

		return $(
			posibleSiblingList.find((item: any) => {
				const siblingTimeHour = parseInt($(item).data('time').split(':')[0]);
				return siblingTimeHour === cellTimeHour - 1;
			})
		);
	}

	private findSibling($cell: any): any {
		const day = $cell.data('day');
		const cellTimeHour = parseInt($cell.data('time').split(':')[0]);
		const posibleSiblingList = this.getResults().filter((item: any) => {
			const $item = $(item);
			return $item.data('day') === day && $item.data('wasProccessed') !== true;
		});

		return $(
			posibleSiblingList.find((item: any) => {
				const siblingTimeHour = parseInt($(item).data('time').split(':')[0]);
				return siblingTimeHour === cellTimeHour + 1;
			})
		);
	}

	private getResults(): any {
		const results = $(this.model).find('[data-selected]');
		return results.get();
	}

	private clearSelection() {
		const selectedValues = this.getResults();
		selectedValues.forEach((value: any) => $(value).data('wasProccessed', false));
		$('[hour-selector]')
			.find('[data-selected]')
			.get()
			.forEach(item => $(item).removeAttr('parameters-selected'));
	}
}
