export enum AgGridColumnDefEnum {
	CellRenderer = 'buttonRenderer',
	CellRenderer1 = 'buttonRenderer1',
	CellRenderer2 = 'buttonRenderer2',
	CellRenderer3 = 'buttonRenderer3',
	CellRenderer4 = 'buttonRenderer4',
	CellRenderer5 = 'buttonRenderer5',
	CellRenderer6 = 'buttonRenderer6',
	CellRenderer7 = 'buttonRenderer7',
	OfferIdRedirect = 'offerIdRedirect',
	HeaderName = 'Actions',
	Name = 'Name',
	UserHeaderName = 'State',
	Status = 'Status'
}
