import { MarkLine } from './mark-line.model';
import { MarkPoint } from './mark-point.model';

export class ChartSeriesModel {
	public name: string;
	public data: any[];
	public stack: string;
	public connectNulls = false;
	public markPoint?: MarkPoint;
	public markLine?: MarkLine;
	public smooth?: boolean;
	public labels?: any[];
	public chartData?: any[];
	public colorCode?: number[];
	public type?: string;
}
