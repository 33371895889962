import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CampaignSmartCreateMapperService } from './campaign-smart-create-mapper.service';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { CatalogInterface } from '../catalogs/catalog.interface';
import { map } from 'rxjs/operators';
import { CatalogNodeInterface } from '../catalogs/catalog-node.interface';

@Injectable()
export class CampaignSmartCreatePythonService {
	constructor(private http: HttpClient, private mapper: CampaignSmartCreateMapperService) {}

	// public getCatalogs(): Observable<CatalogInterface> {
	// 	return this.http.get<CatalogInterface>(`${BaseApiUrl.FacebookPythonCampaignBuilder}smart-create/cats`).pipe(
	// 		map(catalogs => {
	// 			const catalog = new CatalogInterface(catalogs);
	// 			catalog.ActionAttributionWindowClick = this.mapper.mapCatalogNodeToViewModel(catalog.ActionAttributionWindowClick as CatalogNodeInterface[]);
	// 			catalog.ActionAttributionWindowView = this.mapper.mapCatalogNodeToViewModel(catalog.ActionAttributionWindowView as CatalogNodeInterface[]);
	// 			catalog.AdFormat = this.mapper.mapCatalogNodeToViewModel(catalog.AdFormat as CatalogNodeInterface[]);
	// 			catalog.BidStrategy = this.mapper.mapCatalogNodeToViewModel(catalog.BidStrategy as CatalogNodeInterface[]);
	// 			catalog.BillingEvent = this.mapper.mapCatalogNodeToViewModel(catalog.BillingEvent as CatalogNodeInterface[]);
	// 			catalog.CallToActionType = this.mapper.mapCatalogNodeToViewModel(catalog.CallToActionType as CatalogNodeInterface[]);
	// 			catalog.Placement = this.mapper.mapCatalogNodeToViewModel(catalog.Placement as CatalogNodeInterface[]);
	// 			catalog.Platform = this.mapper.mapCatalogNodeToViewModel(catalog.Platform as CatalogNodeInterface[]);
	// 			catalog.Objective = this.mapper.mapCatalogNodeToViewModel(catalog.Objective as CatalogNodeInterface[]);
	// 			catalog.ObjectiveWithDestinationGroup = this.mapper.mapCatalogNodeToViewModel(catalog.ObjectiveWithDestinationGroup as CatalogNodeInterface[]);
	// 			catalog.OptimizationGoal = this.mapper.mapCatalogNodeToViewModel(catalog.OptimizationGoal as CatalogNodeInterface[]);
	// 			catalog.SpecialAdCategories = this.mapper.mapCatalogNodeToViewModel(catalog.SpecialAdCategories as CatalogNodeInterface[]);
	// 			return catalog;
	// 		})
	// 	);
	// }
}
