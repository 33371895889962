<app-user-header></app-user-header>
<div class="page-container">
	<div class="form-area">
		<div class="page-content">
			<div class="left-pane">
				<div class="back-btn">
					<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
				</div>
				<div class="title">Thank you for joining us!</div>
				<div class="sub-title">You have been sent an email confirmation for the payment.</div>
				<div class="sub-title2">Your next bill will be on the {{ planDetails?.nextInvoiceDate }}</div>
				<div class="footer-link">
					Got a question?
					<a href="https://www.filed.com/contact-us-filed">Don't worry. We here to help you</a>
				</div>
			</div>
			<lolly-spinning-loader class="spinner" *ngIf="submitting"></lolly-spinning-loader>
			<div class="container" *ngIf="!submitting">
				<div class="title">Order Summary</div>
				<div class="plan">Filed Plan - {{ planDetails?.subscriptionName }}</div>
				<div class="logo"><img src="assets/images/signup/flash-icon.svg" /></div>

				<div class="price">
					<div class="title">Total due today</div>
					<div class="amount">{{ getPlanAmount }}</div>
				</div>
				<div class="duration">
					<div class="remark">Billed {{ planDetails?.billingInterval }}ly.</div>
					<div class="sub-title">We will bill you every {{ planDetails?.billingInterval }} on the 1st, unless you cancel.</div>
					<div class="sub-title2">Your next bill will be on the {{ planDetails?.nextInvoiceDate }}</div>
				</div>

				<div class="form-btn">
					<btn01 class="btn-subscription" (onClick)="thankYouBackHome()">Back to Accounts Home Page</btn01>
				</div>
			</div>
		</div>
	</div>
</div>
