import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonClassEnum } from '../../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../../shared/button-component/button-type.enum';
import { getAccount, UserManagementState } from '../../../state/user-management.reducer';
import { select, Store } from '@ngrx/store';
import { SetAccountEditState, SetAccountSubmitState } from '../../../state/user-management.actions';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { SettingsPageLabelsEnum } from '../../../shared/enums/navigation.enum';
import { SettingsPermissions } from 'src/app/shared/permisions/enums/settings-permissions';
import { Modules } from 'src/app/shared/permisions/enums/modules';
import { MiscellaneousPermissions } from '../../../../shared/permisions/enums/miscellaneous-permissions';
import { PermissionsDirectiveInterface } from '../../../../shared/permisions/models/permissions-directive.interface';
import { AccountsPermissions } from '../../../../shared/permisions/enums/accounts-permissions';
import { ReportsPermissions } from '../../../../shared/permisions/enums/reports-permissions';

@Component({
	selector: 'app-my-account',
	templateUrl: './my-account.component.html',
	styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;

	public ModulesEnum = Modules;
	public SettingsPermissions = SettingsPermissions;
	public editState: boolean;
	public accountPermissions = SettingsPermissions;
	public permissionModelAccountEdit: PermissionsDirectiveInterface = {
		moduleName: Modules.Settings,
		permissions: [this.accountPermissions.SettingsMyAccountEdit]
	};

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private store: Store<UserManagementState>, private translate: TranslateService, private activatedRoute: ActivatedRoute) {}

	ngOnInit() {
		this.fetchResolver();
		this.fetchStore();
	}

	ngOnDestroy() {
		this.store.dispatch(new SetAccountEditState(false));

		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public setEdit(): void {
		this.store.dispatch(new SetAccountEditState());
	}
	public cancelEdit(): void {
		this.store.dispatch(new SetAccountEditState(false));
	}

	public setSubmit(): void {
		this.store.dispatch(new SetAccountSubmitState());
	}

	private fetchResolver(): void {
		this.activatedRoute.data.pipe(take(1));
	}

	private fetchStore(): void {
		this.store.pipe(select(getAccount), takeUntil(this.unsubscriber$)).subscribe(state => {
			this.editState = state.myAccount.state.edit;
		});
	}
}
