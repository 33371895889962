<div class="card chart-card" *ngIf="showChart" [class]="{ 'hidden-rec': !showRecommendations }">
	<div class="element-chart">
		<div [matMenuTriggerFor]="menu2" class="chart-type-menu">
			<mat-icon class="more-button">more_horiz</mat-icon>

			<mat-menu #menu2="matMenu" class="custom_mat_menu" xPosition="before">
				<button mat-menu-item (click)="changeChartType('line', false)">Line Chart</button>
				<button mat-menu-item (click)="changeChartType('bar', false)">Bar Chart</button>
				<button mat-menu-item (click)="changeChartType('line', true)">Area Chart</button>
				<button mat-menu-item (click)="changeGraphDisplay()">Hide Chart</button>
			</mat-menu>
		</div>
		<div class="button-show-rec" (click)="changeRecommendationsToggle()" *ngIf="false">
			<img src="assets/icons/filed-icon.svg" />
			Show Recommendations
		</div>
		<div
			(chartInit)="initChart($event)"
			[merge]="false"
			[autoResize]="true"
			[options]="chartOption"
			[theme]="'default'"
			id="chart-data"
			class="demo-chart"
			echarts
		></div>

		<div class="loading" *ngIf="loading">
			<lolly-spinning-loader></lolly-spinning-loader>
		</div>
	</div>
	<div *ngIf="false" class="element-recommendations">
		<app-elements-recommendations [accounts]="accounts"></app-elements-recommendations>
	</div>
</div>
<div (click)="changeGraphDisplay()" *ngIf="!showChart" class="no-graph">
	<p>Show Chart</p>
</div>
