import { Action } from '@ngrx/store';
import { AccountConnection, LogInModel, RegisterModel, UserDetailModel } from '../_models/login.model';

export enum AuthenticationActionType {
	LogIn = '[Auth] Log In',
	LogInSuccess = '[Auth] Log In Success',
	LogInFailure = '[Auth] Log In Failure',
	LogOut = '[Auth] Log Out',

	RefreshToken = '[Auth] Refresh user token from server',

	RestoreAuthentication = '[Auth] Reload token from storage',

	EnableFirstTimeCustomer = '[Auth Welcome Page] Enable First Time Customer',
	DisableFirstTimeCustomer = '[Auth Welcome Page] Disable First Time Customer',

	RegistrationDetail = '[Register] Register User Details',

	RegisterUserEmail = '[Register] Register User Email',

	RegisterUserPassword = '[Register] Register User Password',

	RegisterUserAccountPlan = '[Register] Register User Account Plan',

	RegisterUserDuration = '[Register] Register User Tarrif Duration',

	RegisterUserName = '[Register] Register User Name',

	RegisterUserLastName = '[Register] Register User Last Name',

	RegisterUserCompanyName = '[Register] Register User Company Name',

	RegisterUserPhone = '[Register] Register User Phone Number',

	RegisterUserDialCode = '[Register] Register User Mobile Dial Code',

	RegisterUserToken = '[Register Register User Access Token',

	RegisterUserContinueDetail = '[Register] Register User Name and Phone Number',

	AddRegToState = 'AddRegToState',

	AccountConnectionState = '[Register] Connect Account to Facebook or Google',

	ShowOtpInput = '[Signin] Request for OTP'
}

export class LogIn implements Action {
	readonly type = AuthenticationActionType.LogIn;

	constructor(public payload?: LogInModel) {}
}

export class LogInSuccess implements Action {
	readonly type = AuthenticationActionType.LogInSuccess;

	constructor(public payload: string) {}
}

export class LogInFailure implements Action {
	readonly type = AuthenticationActionType.LogInFailure;

	constructor(public payload: string) {}
}

export class ShowOtpInput implements Action {
	readonly type = AuthenticationActionType.ShowOtpInput;

	constructor(public payload: boolean) {}
}
export class LogOut implements Action {
	readonly type = AuthenticationActionType.LogOut;
}

export class RestoreAuthentication implements Action {
	readonly type = AuthenticationActionType.RestoreAuthentication;

	constructor(public payload: string) {}
}

export class EnableFirstTimeCustomer implements Action {
	readonly type = AuthenticationActionType.EnableFirstTimeCustomer;
}

export class DisableFirstTimeCustomer implements Action {
	readonly type = AuthenticationActionType.DisableFirstTimeCustomer;
}

export class RegistrationDetail implements Action {
	readonly type = AuthenticationActionType.RegistrationDetail;

	constructor(public payload?: UserDetailModel) {}
}
export class RegisterUserEmail implements Action {
	readonly type = AuthenticationActionType.RegisterUserEmail;

	constructor(public payload?: string) {}
}

export class RegisterUserPassword implements Action {
	readonly type = AuthenticationActionType.RegisterUserPassword;

	constructor(public payload?: string) {}
}

export class RegisterUserAccountPlan implements Action {
	readonly type = AuthenticationActionType.RegisterUserAccountPlan;

	constructor(public payload?: number) {}
}

export class RegisterUserDuration implements Action {
	readonly type = AuthenticationActionType.RegisterUserDuration;

	constructor(public payload?: string) {}
}
export class RegisterUserName implements Action {
	readonly type = AuthenticationActionType.RegisterUserName;

	constructor(public payload?: string) {}
}

export class RegisterUserLastName implements Action {
	readonly type = AuthenticationActionType.RegisterUserLastName;

	constructor(public payload?: string) {}
}

export class RegisterUserCompanyName implements Action {
	readonly type = AuthenticationActionType.RegisterUserCompanyName;

	constructor(public payload?: string) {}
}
export class RegisterUserPhone implements Action {
	readonly type = AuthenticationActionType.RegisterUserPhone;

	constructor(public payload?: number) {}
}
export class RegisterUserDialCode implements Action {
	readonly type = AuthenticationActionType.RegisterUserDialCode;

	constructor(public payload?: string) {}
}
export class RegisterUserToken implements Action {
	readonly type = AuthenticationActionType.RegisterUserToken;

	constructor(public payload?: string) {}
}

export class AddRegToState implements Action {
	readonly type = AuthenticationActionType.AddRegToState;
	constructor(public payload?: RegisterModel) {}
}

export class AccountConnectionState implements Action {
	readonly type = AuthenticationActionType.AccountConnectionState;
	constructor(public payload?: AccountConnection) {}
}

export type AuthenticationActions =
	| LogIn
	| LogInSuccess
	| LogInFailure
	| LogOut
	| RestoreAuthentication
	| EnableFirstTimeCustomer
	| DisableFirstTimeCustomer
	| RegistrationDetail
	| RegisterUserEmail
	| RegisterUserPassword
	| RegisterUserAccountPlan
	| RegisterUserDuration
	| RegisterUserName
	| RegisterUserLastName
	| RegisterUserCompanyName
	| RegisterUserPhone
	| RegisterUserDialCode
	| RegisterUserToken
	| AddRegToState
	| AccountConnectionState
	| ShowOtpInput;
