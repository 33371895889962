import { FormControl, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Router } from '@angular/router';

export interface ScrollOptions {
	behavior?: string;
	block?: string;
	inline?: string;
}

export class UtilsService {
	public static listsAreEquivalent(list1: any[], list2: any[], uniqueKeyMatcher?: string): boolean {
		if (!list1 || !list2 || list1.length !== list2.length) {
			return false;
		}

		// for lists that contains objects [{...}, {...}] <=> [{...}, [{...}]]
		if (uniqueKeyMatcher) {
			const list1HasDuplicates = this.listHasDuplicates(list1.map(item => item[uniqueKeyMatcher]));
			const list2HasDuplicates = this.listHasDuplicates(list2.map(item => item[uniqueKeyMatcher]));

			if (list1HasDuplicates || list2HasDuplicates) {
				console.error('The provided lists contains duplicates');
				return false;
			}

			const status = !list1.some(item1 => {
				const match = list2.some(item2 => item2[uniqueKeyMatcher] === item1[uniqueKeyMatcher]);
				return !match;
			});

			return status;
		} else {
			// for lists that contains primitives ['a', 'b'] <=> ['b', 'a']
			return !list1.some(entry1 => !(list2.indexOf(entry1) !== -1));
		}
	}

	public static listHasDuplicates(list: any[]): boolean {
		return new Set(list).size !== list.length;
	}

	/**
	 * scrollToElement()
	 * @param selectorOrElement: string | HTMLElement
	 * @param options: ScrollOptions | boolean
	 * @param scrollDelay: number ( delay in milliseconds )
	 */
	public static scrollToElement(selectorOrElement: string | HTMLElement, options?: ScrollOptions | boolean, scrollDelay?: number): void {
		let element: any = selectorOrElement;
		if (typeof selectorOrElement === 'string') {
			element = document.querySelector(selectorOrElement);
		}
		scrollDelay ? setTimeout(() => element.scrollIntoView(options), scrollDelay) : element.scrollIntoView(options);
	}

	public static findFirstInvalidFormControl(formGroup: FormGroup): string {
		let firstInvalidFormControlSelector: string; // ex: campaignNameControl
		const formControls = Object.keys(formGroup.controls);
		for (let i = 0; i < formControls.length; i++) {
			const propertyName = formControls[i];
			const formControlValue = formGroup.controls[propertyName];
			if (formControlValue.invalid) {
				firstInvalidFormControlSelector = propertyName;
				break;
			}
		}
		return firstInvalidFormControlSelector;
	}

	public static ngbDateToMoment(ngbDate: NgbDate): moment.Moment {
		return moment(new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day).getTime());
	}

	public static ngbDateToTime(ngbDate: NgbDate): number {
		return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day).getTime();
	}

	public static momentToNgb(momentDate: moment.Moment): NgbDate {
		return new NgbDate(momentDate.year(), momentDate.month() + 1, momentDate.date()); // "+1" = month index 0 = Jan
	}

	public static objectIsEmpty(obj: any): boolean {
		for (const x in obj) {
			if (obj.hasOwnProperty(x)) {
				return false;
			}
		}
		return true;
	}

	public static removeEmptyProperties(obj: any, leaveNullAndEmptyLists: boolean = false): void {
		Object.keys(obj).forEach(item => {
			const currentObj = obj[item];
			if (
				!currentObj ||
				(typeof currentObj === 'object' && this.objectIsEmpty(currentObj)) ||
				(Array.isArray(currentObj) && (leaveNullAndEmptyLists || currentObj.length === 0))
			) {
				if (!leaveNullAndEmptyLists || currentObj !== null) {
					delete obj[item];
				}
			}
		});
	}

	public static noWhitespaceValidator(control: FormControl) {
		const isWhitespace = (control.value || '').split(' ').length > 1;
		const isValid = !isWhitespace;
		return isValid ? null : { whitespace: true };
	}

	/*
	 *
	 *  INPUT
	 *
	 *  someObject = {
	 *     something: {
	 *       else: {
	 *         name: 'some string',
	 *         value: 100
	 *       }
	 *     }
	 *  }
	 *
	 *  OUTPUT
	 *
	 *  {
	 *    something.else.name: 'some string',
	 *    something.else.value: 100
	 *  }
	 *
	 * */
	public static flattenObject(obj: any): any {
		const result: any = {};

		function recurse(cur: any, prop: any) {
			if (Object(cur) !== cur) {
				result[prop] = cur;
			} else if (Array.isArray(cur)) {
				for (let i = 0; i < cur.length; i++) {
					recurse(cur[i], prop + '[' + i + ']');
				}
				if (cur.length == 0) {
					result[prop] = [];
				}
			} else {
				let isEmpty = true;
				for (const p in cur) {
					isEmpty = false;
					recurse(cur[p], prop ? prop + '.' + p : p);
				}
				if (isEmpty && prop) {
					result[prop] = {};
				}
			}
		}

		recurse(obj, '');
		return result;
	}

	public static unflattenObject(object: any): any {
		if (Object(object) !== object || Array.isArray(object)) {
			return object;
		}

		const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
		const resultHolder: any = {};

		for (const p in object) {
			let cur: any = resultHolder;
			let prop = '';
			let m;

			while ((m = regex.exec(p))) {
				cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
				prop = m[2] || m[1];
			}
			cur[prop] = object[p];
		}
		return resultHolder[''] || resultHolder;
	}

	public static validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}

	public static routeIs(routeUrl: string, routerInstance: Router): boolean {
		if (!routeUrl || !routerInstance) {
			console.warn('Provide a router url and routerInstance');
			return false;
		}

		return routerInstance.url.startsWith(`/${routeUrl}`);
	}

	public static enumToArray(enumme: any) {
		const map = [];

		for (const n in enumme) {
			isNaN(Number(n)) && map.push({ id: enumme[n], name: n });
		}

		return map;
	}

	public static downloadUrlFile(fileUrl: string): void {
		window.open(fileUrl, '_self');
	}

	public static abbreviateNumber(value: number): string {
		if (!value) {
			return '0';
		}
		var newValue: string | number = value;
		if (value >= 1000) {
			var suffixes = ['', 'k', 'm', 'b', 't'];
			var suffixNum = Math.floor(('' + value).length / 3);
			var shortValue;
			for (var precision = 2; precision >= 1; precision--) {
				shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
				var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
				if (dotLessShortValue.length <= 2) {
					break;
				}
			}
			if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
			newValue = shortValue + suffixes[suffixNum];
		}
		return newValue.toString();
	}
}
