import { Bits } from './bits';

export enum ReportsPermissions {
	CanAccessReports = Bits.Bit1,
	ReportChartTemplatesCreate = Bits.Bit2,
	ReportChartTemplatesDelete = Bits.Bit3,
	ReportChartTemplatesEdit = Bits.Bit4,
	ReportChartTemplatesView = Bits.Bit5,
	ReportDashboardsDelete = Bits.Bit6,
	ReportDashboardsCreate = Bits.Bit7,
	ReportDashboardsEdit = Bits.Bit8,
	ReportDashboardsView = Bits.Bit9,
	ReportMyChartsCreate = Bits.Bit10,
	ReportMyChartsDelete = Bits.Bit11,
	ReportMyChartsEdit = Bits.Bit12,
	ReportMyChartsView = Bits.Bit13,
	ReportOverviewView = Bits.Bit14,
	ReportSchedulesCreate = Bits.Bit15,
	ReportSchedulesDelete = Bits.Bit16,
	ReportSchedulesEdit = Bits.Bit17,
	ReportSchedulesView = Bits.Bit18,
	ReportTemplatesCreate = Bits.Bit19,
	ReportTemplatesDelete = Bits.Bit20,
	ReportTemplatesEdit = Bits.Bit21,
	ReportTemplatesView = Bits.Bit22,
	ReportsCanAccessReportsData = Bits.Bit23
}
