import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, map, skipWhile, startWith, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AdFormatsEnum } from '../../../../../campaign-smart-create/models/ad-formats.enum';
import { SelectedAdAccountModel } from '../../../../state/state-entities/cached-ad-accounts.model';
import { CampaignStepsEnum } from '../../../../../campaign-smart-create/models/campaign-steps.enum';
import { AssetPickerDialogComponent } from '../asset-picker-dialog/asset-picker-dialog.component';
import { AssetService } from '../../../../services/assets/asset.service';
import { ButtonTypeEnum } from '../../../../button-component/button-type.enum';
import { ButtonClassEnum } from '../../../../button-component/button-class.enum';
import { AssetPickerContract } from '../asset-picker-dialog/asset-picker-contract';
import { Asset } from '../asset-picker-dialog/asset-model';
import { MapperService } from '../../../../services/mapper.service';
import { CarouselCardDto, CarouselDto, MultipleSingleAdvertDto, SingleAdvertDto } from '../../../../../campaign-builder/models/advert.interface';
import { AdvertService } from '../../../../../_services/facebook-marketing/advert.service';
import { CampaignSmartCreateMapperService } from '../../../../../campaign-smart-create/services/campaign-smart-create-mapper.service';
import { AdFormats } from '../../../../models/server-statics-enum-constants';
import { CatalogNodeInterface } from '../../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { DropdownData } from '../../../../dropdown-search-select/dropdown-data.interface';
import { ValidationNodeInterface } from '../../../../../campaign-smart-create/catalogs/validation-node.interface';
import { CampaignSmartCreateValidationService } from '../../../../../campaign-smart-create/services/campaign-smart-create-validation.service';
import { PlatformEnum } from '../../../../../campaign-smart-create/models/platform.enum';
import { UserVideosInterface } from '../../../../../campaign-smart-create/models/user-videos.interface';
import { UserImagesInterface } from '../../../../../campaign-smart-create/models/user-images.interface';
import { SubObjectives } from '../../../../../campaign-smart-create/models/sub-objectives';
import { AssetContractNameModel } from '../asset-picker-dialog/asset-contract-name.model';
import { ButtonStatusModel } from './button-status.model';
import { PlacementType } from './placement-type.model';
import { Placements } from './ad-creation.placement.model';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getCurrentAd, getStepTwoDetails } from '../../../../../campaign-smart-create/state/smart-create.reducer';
import { ScrollService } from '../../../../../_services/scroll.service';
import { StepThreeDetails } from 'src/app/campaign-smart-create/models/step-three-details';
import { UpdateIframeSrc } from 'src/app/campaign-smart-create/state/smart-create.actions';
import { CampaignSmartCreateService } from '../../../../../campaign-smart-create/services/campaign-smart-create.service';
import { getCreativeSingleImages } from '../../../../../state/campaign-common-state/campaign-common-reducer';
import { VideoSizeInterface } from '../../../../../campaign-smart-create/models/video-size.interface';
import { BaseApiUrl } from '../../../../../_services/base-api-urls';
import { AdModel } from 'src/app/AAA/models/ad.model';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-ad-create-card',
	templateUrl: './ad-creation-card.component.html',
	styleUrls: ['./ad-creation-card.component.scss']
})
export class AdCreationCardComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public posts: Asset[];
	@Input() public images: UserImagesInterface;
	@Input() public videos: UserVideosInterface;
	@Input() public adFormatListener$: Observable<AdFormatsEnum>;
	@Input() public isPanelSelected: boolean;
	@Input() public selectedAdAccount: SelectedAdAccountModel;
	@Input() public businessOwnerFbId: string;
	@Input() public facebookPageId: string;
	@Input() public instagramPageId: string;
	@Input() public subObjective: string;
	public subObjectives = SubObjectives;
	@Input() public productSetID: string;
	@Input() public previews: CatalogNodeInterface[];
	@Input() public callForActions: CatalogNodeInterface[];
	@Input() public validations: ValidationNodeInterface[];
	@Input() public placements: CatalogNodeInterface[];
	@Input() public validatorTrigger: boolean;
	@Input() public cardIndex: number;
	@Input() public productSetId!: string;

	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public adCreationFormValidity = new EventEmitter<boolean>();
	@Output() public onUpdateInvalidStep = new EventEmitter<CampaignStepsEnum>();
	@Output() public onLoadImages = new EventEmitter<boolean>();
	@Output() public onLoadVideos = new EventEmitter<boolean>();
	@Output() public onUpdateAdverts = new EventEmitter<SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto>();

	@Output() public oniframeSrc = new EventEmitter<string>();
	@Output() public ondropdownPreviewPlacement = new EventEmitter<DropdownData<CatalogNodeInterface>[]>();
	@Output() public onadFormat = new EventEmitter<AdFormatsEnum>();
	@Output() public onisSticky = new EventEmitter<boolean>();
	@Output() public onadCreateFormGroup = new EventEmitter<FormGroup>();
	@Output() public onselectedAssets = new EventEmitter<Asset[]>();

	public adFormatEnum = AdFormatsEnum;
	public buttonType = ButtonTypeEnum;
	public buttonClass = ButtonClassEnum;
	public campaignStep = CampaignStepsEnum;
	public devicePlacement = Placements;
	public dialogContracts = new Map<AdFormatsEnum, AssetPickerContract[]>();
	public dropdownPreviewPlacement: DropdownData<CatalogNodeInterface>[] = [];
	public carouselCards: CarouselCardDto[] = [];
	public selectedAssets: Asset[] = [];
	public selectedCardIndex = 0;
	public selectedAd: StepThreeDetails;
	public adFormat: AdFormatsEnum;
	public adCreateFormGroup: FormGroup;
	public buttonLabel: string;
	public iframeSrc: string;
	public callToActions: DropdownData<CatalogNodeInterface>[];
	public isPageLikesSelected: boolean;
	public isPostLikesSelected: boolean;
	public isWebsiteUrl: boolean;
	public buttonStatusName: string;
	public previewHeight: number;
	public previewWidth: number;
	public isSticky: boolean;

	public contractName = AssetContractNameModel;
	public unsubscriber$ = new Subject<void>();
	protected cancelPreview$ = new Subject<void>();
	protected triggerGetAdverts = true;
	protected preview: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto;

	// INJECTED SERVICES
	protected dialog: MatDialog;
	public formBuilder: FormBuilder;
	protected assetService: AssetService;
	protected smartCreateService: CampaignSmartCreateService;
	protected advertPreviewService: AdvertService;
	protected mapperService: MapperService;
	protected campaignMapperService: CampaignSmartCreateMapperService;
	protected validationService: CampaignSmartCreateValidationService;
	protected scrollService: ScrollService;
	protected store: Store<CampaignSmartCreateState>;
	public existingCreativeImages: Asset[];
	public assetUrls: string[] = [];
	public seAdvert: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto;
	public seAdvertEvent: boolean = false;

	public info: InfoHoverDataModel = { message: `Select the media, text, and destination for your ad.` };

	constructor(public injector: Injector) {
		this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
		this.dialog = this.injector.get<MatDialog>(MatDialog);
		this.assetService = this.injector.get<AssetService>(AssetService);
		this.smartCreateService = this.injector.get<CampaignSmartCreateService>(CampaignSmartCreateService);
		this.advertPreviewService = this.injector.get<AdvertService>(AdvertService);
		this.mapperService = this.injector.get<MapperService>(MapperService);
		this.campaignMapperService = this.injector.get<CampaignSmartCreateMapperService>(CampaignSmartCreateMapperService);
		this.validationService = this.injector.get<CampaignSmartCreateValidationService>(CampaignSmartCreateValidationService);
		this.scrollService = this.injector.get<ScrollService>(ScrollService);
		this.store = this.injector.get<Store<CampaignSmartCreateState>>(Store);
	}

	public ngOnInit(): void {
		this.getCreativeImages();
		this.initOnDefault();
		this.checkPreview();
		this.initListeners();
		this.initScrollServiceListener();
		this.initAdFormGroupListener();

		this.getAppInstallAssetsFromStore();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.cancelPreview$.next();
		this.cancelPreview$.complete();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.validatorTrigger) {
			this.adCreateFormGroup.markAllAsTouched();
		}
	}

	public initOnDefault(): void {
		this.adCreateFormGroup = this.initFormGroup();

		for (let i = 0; i < 3; i++) {
			if (this.getCarouselCards().controls.length === 3) {
				break;
			}

			if (this.subObjective == SubObjectives.AppInstallText) {
				this.getCarouselCards().push(this.appInstallCardAdItem());
			} else {
				this.getCarouselCards().push(this.cardAdItem());
			}
		}

		this.onadCreateFormGroup.emit(this.adCreateFormGroup);
		if (this.selectedAssets.length || this.preview) {
			this.buttonStatusName = ButtonStatusModel.Change;
		} else {
			this.buttonStatusName = ButtonStatusModel.Add;
		}
		this.innitializeAppInstall();
	}
	public innitializeAppInstall(): void {
		if (this.subObjective === SubObjectives.AppInstallText) {
			for (let i = 0; i < 5; i++) {
				this.addAddFieldObjects(i, '', '', '');
			}
		}
	}

	public getAppInstallAssetsFromStore(): void {
		if (this.subObjective === SubObjectives.AppInstallText) {
			this.store.pipe(select(getCurrentAd)).subscribe(data => {
				if (data.adverts.primary_text && data.adverts.headline) {
					this.adCreateFormGroup.get('preview').patchValue(data.adverts?.device_placement_position ?? Placements.FacebookMobileFeed, {
						emitEvent: false
					});

					const formBuildControl = this.getCarouselCards().controls[0];
					const primary_text = formBuildControl.get('primary_text') as FormArray;
					const headline = formBuildControl.get('headline') as FormArray;
					const media_url = formBuildControl.get('media_url') as FormArray;

					formBuildControl.get('website_url').patchValue(data.adverts?.website_url);

					formBuildControl.get('call_to_action').patchValue(data.adverts?.call_to_action ? data.adverts?.call_to_action : '', { emitEvent: false });

					let primaryText = data.adverts?.primary_text;
					let headlines = data.adverts?.headline;
					let mediaUrl = data.adverts?.media_url;

					this.selectedAssets = [];
					this.assetUrls = [];
					if (mediaUrl.length > 0) {
						for (let i = 0; i < mediaUrl.length; i++) {
							this.assetUrls.push(mediaUrl[i]);
							media_url.controls[i].setValue(mediaUrl[i]);

							const asset: Asset = {
								id: data?.adverts?.post_id,
								url: mediaUrl[i],
								primary_text: primaryText[i] ? primaryText[i] : '',
								call_to_action: data?.adverts?.call_to_action,
								title: headlines[i] ? headlines[i] : '',
								website_url: data?.adverts?.website_url,
								type: data?.adverts?.ad_format,
								picture: data?.adverts?.picture,
								height: data?.adverts?.pictureHeight,
								width: data?.adverts?.pictureWidth
							};

							this.selectedAssets.push(asset);
						}
					}

					if (primaryText.length > 0) {
						for (let i = 0; i < primaryText.length; i++) {
							primary_text.controls[i].setValue(primaryText[i]);
						}
					}

					if (headlines.length > 0) {
						for (let i = 0; i < headlines.length; i++) {
							headline.controls[i].setValue(headlines[i]);
						}
					}

					if (!data.adverts?.primary_text) {
						this.buttonStatusName = ButtonStatusModel.Add;
					} else {
						this.buttonStatusName = ButtonStatusModel.Change;
					}

					return;
				}
			});
		}
	}

	get media_url(): FormArray {
		return this.getAdCardForms()[0].get('media_url') as FormArray;
	}

	public initScrollServiceListener(): void {
		this.scrollService.currentScrollY.pipe(takeUntil(this.unsubscriber$)).subscribe(scrollY => {
			this.isSticky = scrollY >= 80;
			this.onisSticky.emit(this.isSticky);
		});
	}

	public initAdFormGroupListener(): void {
		this.getCarouselCards().controls.forEach((adCardGroup: FormGroup, index: number) => {
			adCardGroup?.valueChanges
				.pipe(
					takeUntil(this.unsubscriber$),
					skipWhile(() => !this.triggerGetAdverts),
					debounceTime(500)
				)
				.subscribe(values => {
					if (this.adFormat === AdFormatsEnum.Carousel) {
						this.updateCarouselCardValues(values, index);
					}
					this.generatePreview();
					let formStatus = this.adCreateFormGroup.valid;
					if (this.adFormat === AdFormatsEnum.PagePosts) {
						formStatus = true;
					}
					let previewDto;
					if (index === 0) {
						previewDto = this.buildAdvertDto(values);
					} else {
						previewDto = this.buildAdvertDto();
					}

					this.adCreationFormValidity.emit(formStatus);
					if (!formStatus) {
						this.onUpdateInvalidStep.emit(this.campaignStep.Three);
					} else {
						this.onUpdateInvalidStep.emit(null);
					}

					this.onUpdateAdverts.emit(previewDto);
				});
		});
		this.adCreateFormGroup
			.get('preview')
			.valueChanges.pipe(
				startWith(() => this.adCreateFormGroup.get('preview').value),
				takeUntil(this.unsubscriber$),
				skipWhile(() => !this.triggerGetAdverts)
			)
			.subscribe(value => {
				this.generatePreview();
			});

		if (this.seAdvertEvent) {
			let advert = localStorage.getItem('SEadvert');
			const previewDto = this.buildAdvertDto(this.seAdvert ? JSON.parse(advert) : null);
			this.onUpdateAdverts.emit(previewDto);
		}
	}

	public openDialog(format: AdFormatsEnum = this.adFormat): void {
		const ref = this.dialog.open(AssetPickerDialogComponent, {
			panelClass: 'adp-asset-picker-dialog',
			data: { contract: this.dialogContracts.get(format), callToActions: this.callToActions }
		});
		ref.afterClosed()
			.pipe(
				skipWhile(output => !output),
				take(1)
			)
			.subscribe((output: { assets: Asset[]; uploadFiles: boolean }) => {
				let waitImageResponse = false;
				if (this.adFormat === AdFormatsEnum.multipleSingleImage && this.assetUrls.length >= 5) {
					return;
				}
				if (this.adFormat === AdFormatsEnum.Carousel) {
					this.selectedAssets[this.selectedCardIndex] = output.assets[0];

					output.assets.forEach((asset, index) => {
						if (this.selectedAssets.length > this.carouselCards.length) {
							this.selectedAssets[this.selectedCardIndex] = output.assets[0];
						}
						const formBuildControl = this.getCarouselCards().controls[this.selectedCardIndex];
						formBuildControl.get('media_url').patchValue(asset.url, { emitEvent: false });
						formBuildControl.get('picture').patchValue(asset.picture, { emitEvent: false });
						formBuildControl.get('video_id').patchValue(asset.video_id, { emitEvent: false });
						formBuildControl.get('mediaType').patchValue(format, { emitEvent: false });
					});
				} else {
					if (output.assets[0]?.thumbnail) {
						waitImageResponse = true;
						this.assetService
							.getAssetImageById(output.assets[0].id as number)
							.pipe(take(1))
							.subscribe(response => {
								output.assets[0].url = 'data:image/jpg;base64,' + response.image;
								this.generatePreview();
							});
					}

					if (this.adFormat === AdFormatsEnum.multipleSingleImage) {
						this.selectedAssets.push(output.assets[0]);
						this.assetUrls.push(output.assets[0]?.url);
						this.updateMediaUrl();
					} else {
						this.selectedAssets = output.assets;
					}

					this.onselectedAssets.emit(this.selectedAssets);
				}

				if (this.selectedAssets.length) {
					this.buttonStatusName = ButtonStatusModel.Change;
				} else {
					this.buttonStatusName = ButtonStatusModel.Add;
				}

				this.setButtonLabel(this.adFormat);

				if (this.adFormat === AdFormatsEnum.PagePosts && this.selectedAssets.length) {
					const primary_text = this.selectedAssets[0].primary_text;
					const callToAction = this.selectedAssets[0].call_to_action ?? '';

					const advertItemsForm = this.adCreateFormGroup.get('adCardsItemData') as FormArray;
					const formBuildControl = advertItemsForm.controls[this.selectedCardIndex];
					formBuildControl.get('primary_text').patchValue(primary_text);
					formBuildControl.get('call_to_action').patchValue(callToAction);
					formBuildControl.get('call_to_action').markAsUntouched();

					if (this.selectedAssets[0].website_url) {
						const website_url = this.selectedAssets[0].website_url;
						this.adCreateFormGroup.get('website_url').patchValue(website_url);
						this.isWebsiteUrl = true;
					} else {
						this.isWebsiteUrl = false;
					}
				}

				if (output.uploadFiles) {
					this.uploadFiles(output.assets);
				}
				if (!waitImageResponse) {
					this.generatePreview();
				}
				const previewDto = this.buildAdvertDto();
				this.onUpdateAdverts.emit(previewDto);
			});
	}

	public removeMediaUrl(index: number): void {
		this.removeFieldObjects(index);
		this.removeAsset(this.selectedAssets[index]);
		this.generatePreview();
	}

	public removeAsset(asset: Asset): void {
		this.buttonStatusName = ButtonStatusModel.Add;
		this.setButtonLabel(this.adFormat);
		this.selectedAssets = this.selectedAssets.filter(item => item.id !== asset.id);
		this.onselectedAssets.emit(this.selectedAssets);
		this.iframeSrc = '';
		this.oniframeSrc.emit(this.iframeSrc);
		if (!this.selectedAssets.length) {
			this.adCreateFormGroup.reset();
			this.patchOtherFormDefaults();
			this.onUpdateAdverts.emit({ eventTrigger: true });
		}
	}

	public removeCard(cardIndex: number): void {
		this.selectedAssets = this.selectedAssets.filter(item => item.id !== cardIndex);
		this.onselectedAssets.emit(this.selectedAssets);
		this.getCarouselCards().removeAt(cardIndex);
		this.carouselCardChange(cardIndex - 1);
	}

	public carouselCardChange(index: number): void {
		this.selectedCardIndex = index;
		if (!this.selectedAssets[index]) {
			this.buttonStatusName = ButtonStatusModel.Add;
		} else {
			this.buttonStatusName = ButtonStatusModel.Change;
		}
	}

	public onAddCarouselCard(): void {
		if (this.subObjective === SubObjectives.AppInstallText) {
			this.getCarouselCards().push(this.appInstallCardAdItem());
		} else {
			this.getCarouselCards().push(this.cardAdItem());
		}
		this.carouselCardChange(this.getCarouselCards().controls.length - 1);
	}

	private uploadFiles(assets: Asset[]): void {
		if (!assets) {
			return;
		}
		// assets.forEach((asset, index) => {
		// 	this.assetService.uploadFile(asset.file, this.selectedAdAccount.adAccount.id).subscribe(() => {
		// 		if (index !== assets.length - 1) {
		// 			return;
		// 		}
		// 		this.smartCreateService.uploadSingleImage({ SingleAdImage: asset.file, SingleAdName: asset.title }).pipe(take(1));
		// 		// on last upload get new user data
		// 		switch (this.adFormat) {
		// 			case AdFormatsEnum.SingleImage:
		// 				this.onLoadImages.emit(true);
		// 				break;
		// 			case AdFormatsEnum.Video:
		// 				this.onLoadVideos.emit(true);
		// 				break;
		// 			default:
		// 				this.onLoadImages.emit(true);
		// 				this.onLoadVideos.emit(true);
		// 				break;
		// 		}
		// 	});
		// });
	}

	private createDialogContracts(adFormat: AdFormatsEnum): void {
		this.getCreativeImages();
		const singleSelection = adFormat !== AdFormatsEnum.Carousel;
		this.dialogContracts.set(
			AdFormatsEnum.SingleImage,
			this.campaignMapperService.createAssetDialogContracts(
				this.images?.existingImages,
				null,
				this.posts,
				singleSelection,
				'image/*',
				AdFormatsEnum.SingleImage,
				this.existingCreativeImages
			)
		);

		this.dialogContracts.set(
			AdFormatsEnum.multipleSingleImage,
			this.campaignMapperService.createAssetDialogContracts(
				this.images?.existingImages,
				[],
				this.posts,
				singleSelection,
				'image/*',
				AdFormatsEnum.multipleSingleImage,
				this.existingCreativeImages
			)
		);

		this.dialogContracts.set(
			AdFormatsEnum.PagePosts,
			this.campaignMapperService.createAssetDialogContracts([], [], this.posts, singleSelection, 'image/*', AdFormatsEnum.PagePosts, [])
		);

		this.dialogContracts.set(
			AdFormatsEnum.Video,
			this.campaignMapperService.createAssetDialogContracts(
				this.videos?.existingVideos,
				this.videos?.userVideos,
				this.posts,
				singleSelection,
				'video/*',
				AdFormatsEnum.Video,
				[]
			)
		);
	}

	private initFormGroup(): FormGroup {
		const formGroup = this.formBuilder.group({
			adCardsItemData: this.formBuilder.array([]),
			preview: new FormControl(Placements.FacebookMobileFeed, [Validators.required])
		});
		const advertItemsForm = formGroup.get('adCardsItemData') as FormArray;
		if (this.subObjective === SubObjectives.AppInstallText) {
			advertItemsForm.push(this.appInstallCardAdItem());
		} else {
			advertItemsForm.push(this.cardAdItem());
		}
		return formGroup;
	}

	protected cardAdItem(): FormGroup {
		const urlValidator = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
		return this.formBuilder.group({
			primary_text: new FormControl('', [Validators.required]),
			headline: new FormControl('', [Validators.required]),
			website_url: new FormControl('', { validators: [Validators.required, Validators.pattern(urlValidator)], updateOn: 'blur' }),
			description: new FormControl('', [Validators.required]),
			displayLink: new FormControl(''),
			call_to_action: new FormControl('', [Validators.required]),
			media_url: new FormControl(''),
			picture: new FormControl(''),
			video_id: new FormControl(0),
			mediaType: new FormControl('')
		});
	}

	protected appInstallCardAdItem(): FormGroup {
		return this.formBuilder.group({
			primary_text: new FormArray([]),
			headline: new FormArray([]),
			displayLink: new FormControl(''),
			call_to_action: new FormControl('', [Validators.required]),
			media_url: new FormArray([]),
			picture: new FormControl(''),
			video_id: new FormControl(0),
			mediaType: new FormControl(''),
			website_url: new FormControl(''),
			description: new FormControl('')
		});
	}

	public imageExists(ad: AdModel): boolean {
		return !!(ad?.adverts as SingleAdvertDto)?.media_url;
	}

	public getImgSrc(ad: AdModel): string {
		return (ad.adverts as SingleAdvertDto).media_url || ad.adverts.picture;
	}
	public getAdCardForms(): FormArray {
		return this.adCreateFormGroup.get('adCardsItemData') as FormArray;
	}
	public getPrimaryTextForms(): FormArray {
		return this.getAdCardForms().at(this.selectedCardIndex).get('primary_text') as FormArray;
	}
	public getHeadlineForms(): FormArray {
		return this.getAdCardForms().at(this.selectedCardIndex).get('headline') as FormArray;
	}
	public getMediaUrlForms(): FormArray {
		return this.getAdCardForms().at(this.selectedCardIndex).get('media_url') as FormArray;
	}
	public addAddFieldObjects(index: number, primary_text: string, headline: string, url: string): void {
		if (index > 0) {
			this.getPrimaryTextForms().push(new FormControl(primary_text));
			this.getHeadlineForms().push(new FormControl(headline));
			this.getMediaUrlForms().push(new FormControl(url));
		} else {
			this.getPrimaryTextForms().push(new FormControl(primary_text, [Validators.required]));
			this.getHeadlineForms().push(new FormControl(headline, [Validators.required]));
			this.getMediaUrlForms().push(new FormControl(url, [Validators.required]));
		}
	}
	public removeFieldObjects(index: number): void {
		this.assetUrls.splice(index);
		this.updateMediaUrl();
	}

	public updateMediaUrl(): void {
		const media_url = this.getAdCardForms().at(this.selectedCardIndex).get('media_url') as FormArray;
		for (let i = 0; i < media_url.controls.length; i++) {
			if (this.assetUrls[i]) {
				media_url.controls[i].setValue(this.assetUrls[i]);
			} else {
				media_url.controls[i].patchValue('');
			}
		}
	}
	public emitadFormat(data: AdFormatsEnum): void {
		this.onadFormat.emit(data);
	}

	public checkObjective(): void {
		if (this.subObjective === SubObjectives.PageLikes) {
			const formBuildControl = this.getCarouselCards().controls[this.selectedCardIndex];
			this.adFormat = this.adFormatEnum.SingleImage;
			this.emitadFormat(this.adFormat);
			this.setButtonLabel(this.adFormat);
			this.isPageLikesSelected = true;
			this.clearFormValidators();
			formBuildControl.get('call_to_action').clearValidators();
			formBuildControl.get('call_to_action').updateValueAndValidity();
		} else if (this.subObjective === SubObjectives.PostLikes) {
			this.adFormat = this.adFormatEnum.SingleImage;
			this.emitadFormat(this.adFormat);
			this.setButtonLabel(this.adFormat);
			this.isPostLikesSelected = true;
			this.clearFormValidators();
		} else if (this.subObjective === SubObjectives.VideoViews) {
			this.adFormat = AdFormatsEnum.Video;
			this.emitadFormat(this.adFormat);
			this.setButtonLabel(AdFormatsEnum.Video);
		} else if (this.subObjective === SubObjectives.AppInstallText) {
			this.adFormat = AdFormatsEnum.multipleSingleImage;
			this.emitadFormat(this.adFormat);
			this.setButtonLabel(this.adFormat);
		}
	}

	public clearFormValidators(): void {
		const formBuildControl = this.getCarouselCards().controls[this.selectedCardIndex];
		formBuildControl.get('headline').clearValidators();
		formBuildControl.get('headline').updateValueAndValidity();
		formBuildControl.get('description').clearValidators();
		formBuildControl.get('description').updateValueAndValidity();
		formBuildControl.get('website_url').clearValidators();
		formBuildControl.get('website_url').updateValueAndValidity();
	}

	protected checkPreview(): void {
		this.store.pipe(select(getCurrentAd), takeUntil(this.unsubscriber$)).subscribe(data => {
			if (data.adFormatType === AdFormatsEnum.Carousel) {
				this.preview = data.adverts as CarouselDto;
				const advert = this.preview as CarouselDto;
				if (advert) {
					this.adCreateFormGroup.get('preview').patchValue(advert?.device_placement_position ?? Placements.FacebookDesktopFeed, {
						emitEvent: false
					});
					for (let i = 0; i < advert.cards.length; i++) {
						const formBuildControl = this.getCarouselCards().controls[i];
						if (formBuildControl) {
							formBuildControl
								.get('primary_text')
								.patchValue(advert.cards[i].primary_text ? advert.cards[i].primary_text : '', { emitEvent: false });
							formBuildControl.get('headline').patchValue(advert.cards[i].headline ? advert.cards[i].headline : '', { emitEvent: false });
							formBuildControl
								.get('website_url')
								.patchValue(advert.cards[i].website_url ? advert.cards[i].website_url : '', { emitEvent: false });
							formBuildControl
								.get('description')
								.patchValue(advert.cards[i].description ? advert.cards[i].description : '', { emitEvent: false });
							formBuildControl.get('media_url').patchValue(advert.cards[i].media_url ? advert.cards[i].media_url : '', { emitEvent: false });
							formBuildControl.get('picture').patchValue(advert.cards[i].picture ? advert.cards[i].picture : '', { emitEvent: false });
							formBuildControl.get('video_id').patchValue(advert.cards[i].video_id ? advert.cards[i].video_id : 0, { emitEvent: false });
							formBuildControl.get('mediaType').patchValue(advert.cards[i].mediaType ? advert.cards[i].mediaType : '', { emitEvent: false });
						}
					}
					if (!advert.cards[0]?.primary_text) {
						this.buttonStatusName = ButtonStatusModel.Add;
					} else {
						this.buttonStatusName = ButtonStatusModel.Change;
					}
				}
			} else if (data.adFormatType === AdFormatsEnum.multipleSingleImage) {
				this.preview = data.adverts as SingleAdvertDto;
				const advert = this.preview as SingleAdvertDto;

				if (advert) {
					this.adCreateFormGroup.get('preview').patchValue(advert?.device_placement_position ?? Placements.FacebookMobileFeed, {
						emitEvent: false
					});

					const asset: Asset = {
						id: data?.adverts?.post_id,
						url: data.adverts.media_url,
						primary_text: data?.adverts?.primary_text,
						call_to_action: data?.adverts?.call_to_action,
						title: data?.adverts?.headline,
						website_url: data?.adverts?.website_url,
						type: data?.adverts?.ad_format,
						picture: data?.adverts?.picture,
						height: data?.adverts?.pictureHeight,
						width: data?.adverts?.pictureWidth
					};

					this.selectedAssets.push(asset);

					if (!advert?.primary_text) {
						this.buttonStatusName = ButtonStatusModel.Add;
					} else {
						this.buttonStatusName = ButtonStatusModel.Change;
					}
				}
			} else {
				this.preview = data.adverts as SingleAdvertDto;
				const advert = this.preview as SingleAdvertDto;
				if (advert) {
					this.adCreateFormGroup.get('preview').patchValue(advert?.device_placement_position ?? Placements.FacebookMobileFeed, {
						emitEvent: false
					});
					const formBuildControl = this.getCarouselCards().controls[0];
					if (formBuildControl) {
						formBuildControl.get('primary_text').patchValue(advert.primary_text ? advert.primary_text : '', { emitEvent: false });
						formBuildControl.get('headline').patchValue(advert.headline ? advert.headline : '', { emitEvent: false });
						formBuildControl.get('website_url').patchValue(advert.website_url ? advert.website_url : '', { emitEvent: false });
						formBuildControl.get('description').patchValue(advert.description ? advert.description : '', { emitEvent: false });
						formBuildControl.get('media_url').patchValue(advert.media_url ? advert.media_url : '', { emitEvent: false });
						formBuildControl.get('picture').patchValue(advert.picture ? advert.picture : '', { emitEvent: false });
						formBuildControl.get('video_id').patchValue(advert.video_id ? advert.video_id : 0, { emitEvent: false });
						formBuildControl.get('mediaType').patchValue(advert.mediaType ? advert.mediaType : '', { emitEvent: false });

						formBuildControl.get('call_to_action').patchValue(advert?.call_to_action ?? '', { emitEvent: false });
					}

					this.selectedAssets = [];
					const asset: Asset = {
						id: data?.adverts?.post_id,
						url: data.adverts.media_url,
						primary_text: data?.adverts?.primary_text,
						call_to_action: data?.adverts?.call_to_action,
						title: data?.adverts?.headline,
						website_url: data?.adverts?.website_url,
						type: data?.adverts?.ad_format,
						picture: data?.adverts?.picture,
						height: data?.adverts?.pictureHeight,
						width: data?.adverts?.pictureWidth
					};
					this.selectedAssets.push(asset);

					if (!advert?.primary_text) {
						this.buttonStatusName = ButtonStatusModel.Add;
					} else {
						this.buttonStatusName = ButtonStatusModel.Change;
					}
				}
			}
		});
	}

	protected initListeners(): void {
		if (!this.adFormat) {
			this.adFormat = AdFormatsEnum.SingleImage;
			this.emitadFormat(this.adFormat);
		}
		this.adFormatListener$.pipe(takeUntil(this.unsubscriber$)).subscribe(format => {
			this.checkPreview();
			this.adFormat = format;
			this.emitadFormat(this.adFormat);
			this.buttonLabel = '';
			this.iframeSrc = '';
			this.oniframeSrc.emit(this.iframeSrc);
			this.selectedCardIndex = 0;
			this.selectedAssets = [];
			this.onselectedAssets.emit(this.selectedAssets);

			if (this.adFormat && !this.getCarouselCards().controls[this.selectedCardIndex].get('primary_text').value) {
				this.getCallToActions();
				this.getPreviewPlacements();
			}
			this.patchOtherFormDefaults();
			this.createDialogContracts(this.adFormat);
			this.setButtonLabel(this.adFormat);
			this.cancelPreview$.next();
		});
		this.checkObjective();
		this.getCallToActions();
		this.getPreviewPlacements();
		this.getAdPreview();
	}

	protected setCarouselCardsNumber(cards: number): void {
		for (let i = 0; i < cards; i++) {
			this.carouselCards.push(new CarouselCardDto());
		}
	}

	private getPreviewPlacements(): void {
		this.previews.forEach(item => {
			item.displayName = item.displayName ?? item.displayNameAutogen;
		});
		const allPreviews = this.mapperService.convertToDropdownData(this.previews, 'name', 'displayName');
		this.checkPlacement(this.placements, allPreviews);
	}

	protected checkPlacement(placements: CatalogNodeInterface[], allPreviews: DropdownData<CatalogNodeInterface>[]): void {
		if (this.dropdownPreviewPlacement.length) {
			return;
		}
		placements.forEach(item => {
			if (item?.name === PlatformEnum.Facebook) {
				const sortedByFirstPlacement = this.validationService.getValidFields(
					this.validations,
					'Placement',
					'AdFormat',
					allPreviews,
					PlacementType.facebookFeed
				);

				if (this.subObjective !== SubObjectives.PostLikes) {
					const sortedBySecondPlacement = this.validationService.getValidFields(
						this.validations,
						'Placement',
						'AdFormat',
						allPreviews,
						PlacementType.facebookStories
					);
					const allMatched = sortedByFirstPlacement.concat(sortedBySecondPlacement);
					this.dropdownPreviewPlacement = this.dropdownPreviewPlacement.concat(allMatched);
					this.emitdropdownPreviewPlacement(this.dropdownPreviewPlacement);
				} else {
					this.dropdownPreviewPlacement = this.dropdownPreviewPlacement.concat(sortedByFirstPlacement);
					this.emitdropdownPreviewPlacement(this.dropdownPreviewPlacement);
				}
			} else if (item?.name === PlatformEnum.INSTAGRAM) {
				const sortedFirst = this.validationService.getValidFields(this.validations, 'Placement', 'AdFormat', allPreviews, PlacementType.instagramFeed);

				if (this.subObjective !== SubObjectives.PostLikes) {
					const sortedSecond = this.validationService.getValidFields(
						this.validations,
						'Placement',
						'AdFormat',
						allPreviews,
						PlacementType.instagramStories
					);
					const matched = sortedFirst.concat(sortedSecond);
					this.dropdownPreviewPlacement = this.dropdownPreviewPlacement.concat(matched);
					this.emitdropdownPreviewPlacement(this.dropdownPreviewPlacement);
				} else {
					this.dropdownPreviewPlacement = this.dropdownPreviewPlacement.concat(sortedFirst);
					this.emitdropdownPreviewPlacement(this.dropdownPreviewPlacement);
				}
			} else if (item?.name === PlatformEnum.APPS) {
				const matched = this.validationService.getValidFields(this.validations, 'Placement', 'AdFormat', allPreviews, PlacementType.mobile);
				this.dropdownPreviewPlacement = this.dropdownPreviewPlacement.concat(matched);
				this.emitdropdownPreviewPlacement(this.dropdownPreviewPlacement);
			}
		});
	}
	public emitdropdownPreviewPlacement(data: DropdownData<CatalogNodeInterface>[]): void {
		this.ondropdownPreviewPlacement.emit(data);
	}
	protected getAdPreview(): void {
		if (this.preview) {
			if (this.preview.ad_format === AdFormats.Carousel) {
				const adverts = this.getCarouselCards().value;
				for (let i = 0; i < adverts.length; i++) {
					this.selectedAssets.push({
						url: adverts[i].media_url || adverts[i].video_id.toString(),
						title: '',
						id: this.selectedAssets.length,
						picture: adverts[i].picture
					});
				}
			} else {
				const advert = this.preview as SingleAdvertDto;
				if (this.adFormat === AdFormatsEnum.PagePosts) {
					this.selectedAssets.push({
						url: advert.media_url || advert.video_id.toString(),
						title: '',
						id: this.preview.post_id
					});
					advert.post_id = this.preview.post_id;
				} else if (this.adFormat === AdFormatsEnum.Video) {
					advert.video_id = this.preview.video_id;
					advert.picture = this.preview.picture;
					this.selectedAssets.push({
						url: this.preview.picture,
						title: '',
						id: this.preview.video_id,
						picture: this.preview.picture,
						height: this.preview.pictureHeight,
						width: this.preview.pictureWidth
					});
				} else {
					this.selectedAssets.push({
						url: advert.media_url || advert.video_id?.toString(),
						title: '',
						id: this.selectedAssets.length,
						height: this.preview.pictureHeight,
						width: this.preview.pictureWidth
					});
					const advertItemsForm = this.adCreateFormGroup.get('adCardsItemData') as FormArray;
					const formBuildControl = advertItemsForm.controls[0];

					formBuildControl?.get('headline').patchValue(advert.headline);
					formBuildControl?.get('description').patchValue(advert.description);
				}
			}
			this.generatePreview();
		}
	}

	private getCallToActions(): void {
		if (this.callForActions) {
			const callToActionList = this.mapperService.convertToDropdownData(this.callForActions, 'name', 'displayNameAutogen');
			this.callToActions = this.validationService.getValidFields(this.validations, 'Objective', 'CallToActionType', callToActionList, this.subObjective);
		}
	}

	private patchFormWithExistingData(advert: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto, adIndex: number): void {
		const advertItemsForm = this.adCreateFormGroup.get('adCardsItemData') as FormArray;
		const formBuildControl = advertItemsForm.controls[adIndex];
		if (this.adFormat === AdFormatsEnum.Carousel) {
			const carouselAdvert = advert as CarouselDto;
			if (formBuildControl) {
				formBuildControl.get('primary_text').patchValue(carouselAdvert.cards[adIndex].primary_text ? carouselAdvert.cards[adIndex].primary_text : '');
				formBuildControl.get('call_to_action').patchValue(this.callToActions[adIndex].value);
				formBuildControl.get('headline').patchValue(carouselAdvert.cards[adIndex].headline);
				formBuildControl.get('description').patchValue(carouselAdvert.cards[adIndex].description);
				formBuildControl.get('website_url').patchValue(carouselAdvert.cards[adIndex].website_url);
				formBuildControl.get('media_url').patchValue(carouselAdvert.cards[adIndex].media_url);
				formBuildControl.get('picture').patchValue(carouselAdvert.cards[adIndex].picture);
				formBuildControl.get('video_id').patchValue(carouselAdvert.cards[adIndex].video_id);
				formBuildControl.get('mediaType').patchValue(carouselAdvert.cards[adIndex].mediaType);
			}
		}
		const carouselAdvert = advert as SingleAdvertDto;
		if (this.adFormat !== AdFormatsEnum.PagePosts) {
			if (formBuildControl) {
				formBuildControl.get('website_url').patchValue(carouselAdvert.website_url);
			}
		}
		if (this.adFormat !== AdFormatsEnum.Carousel && this.adFormat !== AdFormatsEnum.PagePosts) {
			if (formBuildControl) {
				formBuildControl.get('headline').patchValue(carouselAdvert.headline);
				formBuildControl.get('description').patchValue(carouselAdvert.description);
			}
		}
		this.adCreateFormGroup.markAllAsTouched();
		if (this.adFormat === AdFormatsEnum.PagePosts) {
			this.adCreationFormValidity.emit(true);
		} else {
			this.adCreationFormValidity.emit(this.adCreateFormGroup.valid);
		}
	}

	private patchOtherFormDefaults(): void {
		if (this.callToActions && this.callToActions.length > 0) {
			for (let i = 0; i < this.callToActions.length; i++) {
				const formBuildControl = this.getCarouselCards().controls[i];
				if (formBuildControl) {
					formBuildControl.get('call_to_action').patchValue(this.callToActions[i].value, { emitEvent: false });
					formBuildControl.get('call_to_action').markAsTouched();
				}
			}
		}
	}

	protected generatePreview(): void {
		const formBuildControl = this.getCarouselCards()?.controls[this.selectedCardIndex];
		if (this.adFormat === AdFormatsEnum.Carousel && (this.getCarouselCards().value.length < 3 || this.getCarouselCards().invalid)) {
			return;
		}

		if ((!this.triggerGetAdverts && !this.selectedAssets.length) || (formBuildControl.invalid && this.adFormat !== AdFormatsEnum.PagePosts)) {
			return;
		}

		this.cancelPreview$.next();
		const previewDto = this.buildAdvertDto(formBuildControl.value);
		if (this.seAdvert && previewDto.media_url.length > 0) {
			this.onUpdateAdverts.emit(previewDto);
		} else {
			this.onUpdateAdverts.emit(previewDto);
		}

		this.iframeSrc = '';
		this.oniframeSrc.emit(this.iframeSrc);

		const placement = this.adCreateFormGroup.get('preview').value.toString();
		let request$: Observable<any>;
		// if (placement.includes(PlatformEnum.INSTAGRAM)) {
		// 	request$ = this.advertPreviewService.generateAdvertPreview(
		// 		this.facebookPageId,
		// 		this.selectedAdAccount.adAccount.id,
		// 		previewDto,
		// 		this.subObjective,
		// 		this.instagramPageId,
		// 		this.productSetID
		// 	);
		// } else {
		// 	request$ = this.advertPreviewService.generateAdvertPreview(
		// 		this.facebookPageId,
		// 		this.selectedAdAccount.adAccount.id,
		// 		previewDto,
		// 		this.subObjective,
		// 		'',
		// 		this.productSetID
		// 	);
		// }

		request$.pipe(takeUntil(this.cancelPreview$)).subscribe(
			result => {
				this.iframeSrc = result;
				this.oniframeSrc.emit(this.iframeSrc);
				const firstIndex = result.indexOf('src=');
				const secondIndex = result.indexOf('width=');
				const url = result.substring(firstIndex + 5, secondIndex - 2);
				this.setPreviewWidthAndHeight();
				const urlS = `<iframe src=${url} width=${this.previewWidth} height=${this.previewHeight} style="border: none" scrolling="no"></iframe>`;
				this.iframeSrc = urlS;
				this.store.dispatch(new UpdateIframeSrc(this.iframeSrc));
				this.oniframeSrc.emit(this.iframeSrc);
			},
			error => {
				this.iframeSrc = error.error.description;
				this.oniframeSrc.emit(this.iframeSrc);
			}
		);
	}

	public getCorrectHeight(): number {
		const oneRow = 58;
		let height = 690;
		const formBuildControl = this.getCarouselCards().controls[this.selectedCardIndex];
		const numberOfLetters = formBuildControl.get('primary_text').value.length;
		let numberOfRows = Math.ceil(numberOfLetters / oneRow);
		numberOfRows = numberOfRows < 9 ? numberOfRows : 9;

		if (
			this.selectedAssets[0].width > this.selectedAssets[0].height ||
			this.adFormat === AdFormatsEnum.Carousel ||
			(this.adFormat === AdFormatsEnum.Video && this.selectedAssets[0]?.videoSize?.width > this.selectedAssets[0]?.videoSize?.height)
		) {
			height = 530;
		} else if (!this.selectedAssets[0].width && this.adFormat === AdFormatsEnum.SingleImage) {
			const image = new Image();
			image.src = this.selectedAssets[0].url;

			if (image.width > image.height) {
				height = 530;
			}
		}
		height += numberOfRows * 20;
		return height;
	}

	public setPreviewWidthAndHeight(): void {
		const formBuildControl = this.getCarouselCards().controls[this.selectedCardIndex];
		const selected = this.adCreateFormGroup.get('preview').value;
		switch (selected) {
			case Placements.FacebookDesktopFeed: {
				this.previewHeight = this.getCorrectHeight();
				this.previewWidth = 512;
				break;
			}
			case Placements.FacebookMobileFeed: {
				this.previewHeight = 540;
				this.previewWidth = 320;
				break;
			}
			case Placements.InstagramStory:
			case Placements.FacebookStory: {
				this.previewHeight = 560;
				this.previewWidth = 320;
				break;
			}
			case Placements.InstagramStandard: {
				this.previewHeight = 557;
				this.previewWidth = 320;
				break;
			}
			case Placements.MobileNative: {
				this.previewHeight = 400;
				this.previewWidth = 325;
				break;
			}
			case Placements.MobileBanner: {
				this.previewHeight = 355;
				this.previewWidth = 325;
				break;
			}
			case Placements.MobileInterstitial: {
				this.previewHeight = 570;
				this.previewWidth = 325;
				break;
			}
			case Placements.MobileRectangle: {
				this.previewHeight = 400;
				this.previewWidth = 350;
				break;
			}
		}
	}

	protected buildAdvertDto(advert?: any): SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto {
		let previewDto: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto;

		switch (this.adFormat) {
			case AdFormatsEnum.Carousel: {
				previewDto = this.buildCarouselDto();
				break;
			}
			case AdFormatsEnum.multipleSingleImage: {
				previewDto = this.buildMultipleSingleAdvertDto(advert);
				break;
			}
			case AdFormatsEnum.Video:
			case AdFormatsEnum.PagePosts:
			case AdFormatsEnum.catalogueSale:
			case AdFormatsEnum.SingleImage: {
				previewDto = this.buildSingleAdvertDto(advert);
				break;
			}
		}

		previewDto.ad_format = parseInt(`${this.adFormat}`);
		return previewDto;
	}
	public buildMultipleSingleAdvertDto(adv?: any): MultipleSingleAdvertDto {
		const formBuildControl = this.getCarouselCards().controls[0];
		const primary_text = formBuildControl.get('primary_text') as FormArray;
		const headline = formBuildControl.get('headline') as FormArray;
		const media_url = formBuildControl.get('media_url') as FormArray;

		let previewDto = new MultipleSingleAdvertDto();
		previewDto = formBuildControl.value;
		previewDto.description = previewDto.primary_text[0];
		if (this.assetUrls.length > 0) {
			let last = [];
			for (let i = 0; i < this.assetUrls.length; i++) {
				if (!last.includes(this.assetUrls[i])) {
					previewDto.media_url[i] = this.assetUrls[i];
					last.push(this.assetUrls[i]);
				}
			}
		}
		for (let i = 0; i < previewDto.primary_text.length; i++) {
			if (previewDto.primary_text[i] === '' || previewDto.primary_text[i] === null) {
				previewDto.primary_text.splice(i);
			}
		}
		for (let i = 0; i < previewDto.media_url.length; i++) {
			if (previewDto.media_url[i] === '' || previewDto.media_url[i] === null) {
				previewDto.media_url.splice(i);
			}
		}
		for (let i = 0; i < previewDto.headline.length; i++) {
			if (previewDto.headline[i] === '' || previewDto.headline[i] === null) {
				previewDto.headline.splice(i);
			}
		}

		this.store.pipe(select(getStepTwoDetails), take(1)).subscribe(details => {
			if (details) {
				previewDto.website_url = details?.appLink;
			}
		});

		previewDto.call_to_action = formBuildControl.get('call_to_action').value;

		previewDto.device_placement_position = adv?.preview ? adv?.preview : this.adCreateFormGroup.get('preview').value;
		previewDto.eventTrigger = previewDto.primary_text[0] && previewDto.primary_text[0] !== '' && previewDto.primary_text[0] !== null ? true : false;
		this.getCarouselCards().controls.forEach((value, index) => {
			if (index !== 0) {
				value.get('headline').patchValue(formBuildControl.get('headline').value, { emitEvent: false });

				value.get('headline').patchValue(formBuildControl.get('headline').value, { emitEvent: false });

				value.get('media_url').patchValue(formBuildControl.get('media_url').value, { emitEvent: false });

				value.get('website_url').patchValue(formBuildControl.get('website_url').value, { emitEvent: false });
				value.get('description').patchValue(formBuildControl.get('description').value, { emitEvent: false });

				value.get('picture').patchValue(formBuildControl.get('picture').value, { emitEvent: false });
				value.get('video_id').patchValue(formBuildControl.get('video_id').value, { emitEvent: false });
				value.get('mediaType').patchValue(formBuildControl.get('mediaType').value, { emitEvent: false });

				value.get('call_to_action').patchValue(adv?.call_to_action ? adv.adv?.call_to_action.value : '', { emitEvent: false });
			}
		});
		return previewDto;
	}
	private buildSingleAdvertDto(adv?: any): SingleAdvertDto {
		const previewDto = new SingleAdvertDto();
		previewDto.media_url = this.selectedAssets[0]?.url;
		previewDto.media = {
			hash: this.selectedAssets[0]?.hash ?? null,
			url: this.selectedAssets[0]?.url ?? null
		};
		const formBuildControl = this.getCarouselCards().controls[0];
		previewDto.primary_text = formBuildControl.get('primary_text').value;
		previewDto.headline = formBuildControl.get('headline').value;
		previewDto.description = formBuildControl.get('description').value;
		previewDto.website_url = formBuildControl.get('website_url').value;
		previewDto.eventTrigger = true;
		if (this.selectedAssets[0]?.height) {
			previewDto.pictureHeight = this.selectedAssets[0].height;
			previewDto.pictureWidth = this.selectedAssets[0].width;
		} else if (this.selectedAssets[0]?.videoSize?.height) {
			previewDto.pictureHeight = this.selectedAssets[0].videoSize?.height;
			previewDto.pictureWidth = this.selectedAssets[0].videoSize?.width;
		}
		if (this.adFormat === AdFormatsEnum.PagePosts) {
			previewDto.post_id = this.selectedAssets[0]?.id.toString();
		} else if (this.adFormat === AdFormatsEnum.Video && this.selectedAssets[0]?.video_id) {
			previewDto.video_id = this.selectedAssets[0].video_id;
			previewDto.picture = this.selectedAssets[0].picture;
			previewDto.media = {
				video_id: this.selectedAssets[0].video_id,
				id: this.selectedAssets[0].video_id,
				picture: this.selectedAssets[0].picture
			};
		} else if (this.adFormat === AdFormatsEnum.Video && this.preview?.video_id) {
			previewDto.video_id = this.preview.video_id;
			previewDto.picture = this.preview.picture;
			previewDto.media = {
				id: this.preview.video_id,
				video_id: this.preview.video_id,
				picture: this.preview.picture
			};
		}
		const callToAction = this.getCarouselCards().controls[0].value.call_to_action;
		previewDto.call_to_action = callToAction;
		previewDto.device_placement_position = adv?.preview ? adv?.preview : this.adCreateFormGroup.get('preview').value;

		this.getCarouselCards().controls.forEach((value, index) => {
			if (index !== 0) {
				value.get('primary_text').patchValue(formBuildControl.get('primary_text').value, { emitEvent: false });
				value.get('headline').patchValue(formBuildControl.get('headline').value, { emitEvent: false });
				value.get('website_url').patchValue(formBuildControl.get('website_url').value, { emitEvent: false });
				value.get('description').patchValue(formBuildControl.get('description').value, { emitEvent: false });
				value.get('media_url').patchValue(formBuildControl.get('media_url').value, { emitEvent: false });
				value.get('picture').patchValue(formBuildControl.get('picture').value, { emitEvent: false });
				value.get('video_id').patchValue(formBuildControl.get('video_id').value, { emitEvent: false });
				value.get('mediaType').patchValue(formBuildControl.get('mediaType').value, { emitEvent: false });
			}
		});

		return previewDto;
	}

	private buildCarouselDto(): CarouselDto {
		const previewDto = new CarouselDto();
		const cards: CarouselCardDto[] = this.getCarouselCards().value;
		const newCards: CarouselCardDto[] = [];
		if (cards && cards.length > 0) {
			cards.forEach((card, index) => {
				if (card.mediaType === '5') {
					newCards.push({
						...card,
						media: {
							id: card.video_id,
							picture: this.selectedAssets[index].picture,
							video_id: card.video_id
						}
					});
				} else {
					newCards.push({
						...card,
						media: {
							url: card.media_url,
							hash: this.selectedAssets[index].hash
						}
					});
				}
			});
		}
		previewDto.cards = newCards;
		const formBuildControl0 = this.getCarouselCards().controls[0];
		previewDto.call_to_action = formBuildControl0.get('call_to_action').value;
		previewDto.device_placement_position = this.adCreateFormGroup.get('preview').value;
		previewDto.add_card_with_page_profile = false;
		previewDto.eventTrigger = true;
		return previewDto;
	}

	protected getValidCarouselCards(): CarouselCardDto[] {
		return this.carouselCards.filter(card => card.media_url || card.website_url || card.description || card.headline);
	}
	private updateCarouselCardValues(formValue: CarouselDto, index: number): void {
		const formBuildControl = this.getCarouselCards().controls[index];

		if (formBuildControl) {
			formBuildControl.get('primary_text').patchValue(formValue.primary_text, { emitEvent: false });
			formBuildControl.get('headline').patchValue(formValue.headline, { emitEvent: false });
			formBuildControl.get('website_url').patchValue(formValue.website_url, { emitEvent: false });
			formBuildControl.get('description').patchValue(formValue.description, { emitEvent: false });
			formBuildControl.get('media_url').patchValue(formValue.media_url, { emitEvent: false });
			formBuildControl.get('picture').patchValue(formValue.picture, { emitEvent: false });
			formBuildControl.get('video_id').patchValue(formValue.video_id, { emitEvent: false });
			formBuildControl.get('mediaType').patchValue(formValue.mediaType, { emitEvent: false });
		}
	}

	private setButtonLabel(format: AdFormatsEnum): void {
		switch (format) {
			case AdFormatsEnum.SingleImage:
				this.buttonLabel = `${this.buttonStatusName + ' ' + 'Image'}`;
				break;
			case AdFormatsEnum.Video:
				this.buttonLabel = `${this.buttonStatusName + ' ' + 'Video'}`;
				break;
			case AdFormatsEnum.PagePosts:
				this.buttonLabel = `${this.buttonStatusName + ' ' + 'Post'}`;
				break;
			default:
				this.buttonLabel = '';
				break;
		}
	}

	public setPreviewClass(): any {
		const isFaceBookDesktopFeed = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.FacebookDesktopFeed;
		const isFacebookMobileFeed = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.FacebookMobileFeed;
		const isFacebookStory = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.FacebookStory;
		const isInstagramStory = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.InstagramStory;
		const isInstagramStandard = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.InstagramStandard;
		const isMobileNative = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.MobileNative;
		const isMobileBanner = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.MobileBanner;
		const isMobileInterstitial = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.MobileInterstitial;
		const isMobileRectangle = this.adCreateFormGroup.get('previewPlacement').value === this.devicePlacement.MobileRectangle;
		return {
			'ac-iframe-box-facebook-desktop-feed': isFaceBookDesktopFeed,
			'small-image': isFaceBookDesktopFeed && this.previewHeight < 570,
			'small-image-two-rows': isFaceBookDesktopFeed && this.previewHeight === 570,
			'small-image-three-rows': isFaceBookDesktopFeed && this.previewHeight === 590,
			'small-image-four-rows': isFaceBookDesktopFeed && this.previewHeight === 610,
			'small-image-five-rows': isFaceBookDesktopFeed && this.previewHeight === 630,
			'small-image-six-rows': isFaceBookDesktopFeed && this.previewHeight === 650,
			'small-image-seven-rows': isFaceBookDesktopFeed && this.previewHeight === 670,
			'small-image-eight-rows': isFaceBookDesktopFeed && this.previewHeight === 690,
			'small-image-nine-rows': isFaceBookDesktopFeed && this.previewHeight === 710,
			'one-row': isFaceBookDesktopFeed && this.previewHeight < 730 && this.previewHeight > 710,
			'two-rows': isFaceBookDesktopFeed && this.previewHeight === 730,
			'three-rows': isFaceBookDesktopFeed && this.previewHeight === 750,
			'four-rows': isFaceBookDesktopFeed && this.previewHeight === 770,
			'five-rows': isFaceBookDesktopFeed && this.previewHeight === 790,
			'six-rows': isFaceBookDesktopFeed && this.previewHeight === 810,
			'seven-rows': isFaceBookDesktopFeed && this.previewHeight === 830,
			'eight-rows': isFaceBookDesktopFeed && this.previewHeight === 850,
			'nine-rows': isFaceBookDesktopFeed && this.previewHeight === 870,
			'ac-iframe-box-facebook-mobile-news-feed': isFacebookMobileFeed,
			'ac-iframe-box-facebook-story': isFacebookStory,
			'ac-iframe-box-instagram-story': isInstagramStory,
			'ac-iframe-box-instagram-standard': isInstagramStandard,
			'ac-iframe-box-mobile-native': isMobileNative,
			'ac-iframe-box-mobile-banner': isMobileBanner,
			'ac-iframe-box-mobile-interstitial': isMobileInterstitial,
			'ac-iframe-box-mobile-rectangle': isMobileRectangle
		};
	}
	public getCarouselCards(): FormArray {
		return this.adCreateFormGroup.get('adCardsItemData') as FormArray;
	}

	private getCreativeImages(): void {
		this.existingCreativeImages = [];
		this.store.pipe(select(getCreativeSingleImages)).subscribe(res => {
			if (res && res.length > 0) {
				res.forEach(xd => {
					const imageData = xd?.singleAdViewKey;
					if (imageData) {
						const url = `${BaseApiUrl.CreativeBuilderSingleAdImage}${imageData}`;
						this.existingCreativeImages.push({
							id: xd.id,
							title: xd?.singleAdName,
							url: url,
							thumbnail: url
						});
					}
				});
			}
		});
	}
}
