import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { AudienceData } from '../../audience/models/audience-data-table.model';
import { BaseApiUrl } from '../base-api-urls';
import { AdAccount } from '../../accounts/models/ad-account.model';
import { SavedAudience } from '../../_models/saved-audience/saved-audience';
import { SavedAudienceInterface } from '../../campaign-smart-create/models/saved-audience.interface';
import { SavedAudienceSizeReqInterface } from '../../campaign-smart-create/models/saved-audience-size-req.interface';
import { AudienceSizeInterface } from '../../campaign-smart-create/models/audience-size.interface';
import { IReachEstimate } from 'src/app/campaign-smart-create/models/reach-estimate.interface';
import { IProgressCards } from '@filed-com/filed-lib/lib/cards/progress-card/progress-card.interface';
@Injectable({
	providedIn: 'root'
})
export class AudienceService {
	// private readonly baseURL = BaseApiUrl.Audience + 'audiences';
	public reachEstimate$: Subject<IProgressCards> = new Subject();

	constructor(private http: HttpClient) {}

	// public getAll(selectedAccounts: AdAccount, types?: number[]): Observable<AudienceData[]> {
	// 	let endpoint = this.baseURL + '?adAccountFacebookIds=' + selectedAccounts.id;
	// 	if (types) {
	// 		endpoint += '&types=' + types.join(',');
	// 	}
	// 	return this.http.get<AudienceData[]>(endpoint);
	// }

	// public createAudience(saveAudienceInterface: SavedAudience): Observable<any> {
	// 	return this.http.post<any>(`${BaseApiUrl.Audience}audiences`, saveAudienceInterface);
	// }

	// public deleteAudience(audienceId: number): Observable<void> {
	// 	return this.http.delete<void>(`${this.baseURL}/${audienceId}`);
	// }

	// public getSavedAudiencesByAdAccounts(adAccounts: AdAccount): Observable<SavedAudience[]> {
	// 	if (!adAccounts) {
	// 		return;
	// 	}
	// 	return this.http.get<SavedAudience[]>(`${this.baseURL}?adAccountFacebookIds=${adAccounts.id}&types=2`);
	// }

	// public getAudienceSize(audience: SavedAudienceSizeReqInterface, id: string): Observable<AudienceSizeInterface> {
	// 	return this.http.post<AudienceSizeInterface>(`${BaseApiUrl.FacebookPythonCampaignBuilder}audience-size/${id}`, audience);
	// }

	// public getReachEstimate(reach: any): Observable<IReachEstimate> {
	// 	// return this.http.get<IReachEstimate>(`${BaseApiUrl.FacebookAquaPythonCampaignBuilder}audience/adset/estimate`, { params: reach});
	// 	return this.http.post<IReachEstimate>(`${BaseApiUrl.FacebookPythonCampaignBuilder}delivery-estimate`, reach);
	// }

	// public saveAudience(body: SavedAudienceInterface): Observable<void> {
	// 	return this.http.post<void>(`${BaseApiUrl.Audience}audiences/`, body);
	// } // TODO: when endpoint will return the id eliminate update function of Saved Audiences

	// public editAudience(body: SavedAudienceInterface): Observable<void> {
	// 	const id = body.details.savedAudienceId;
	// 	return this.http.put<void>(`${BaseApiUrl.Audience}audiences/${id}`, body);
	// }
}
