import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdAccountApiService } from '../../_services/facebook-accounts/ad-account-api.service';
import { AuthenticationState, getOtpDialogState, getRedirectUrl } from '../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { LogIn, LogInSuccess, ShowOtpInput } from '../state/authentication.action';
import { IBigCommerceAuth, LogInModel } from '../_models/login.model';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { TemporaryCredentials } from '../../_models/user-models/user';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { FieldValidators } from '../../user-management/models/validators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AppState, AppStateSlices } from 'src/app/state/app.state';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';
import { EMPTY, of, Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { isDevMode } from '@angular/core';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BillingAndSubscriptionService } from 'src/app/shared/services/billing-and-subscriptions.service';
import { VerifyAddUserComponent } from 'src/app/user-management/new-sprint/components/modals/verify-add-user/verify-add-user.component';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { GoogleService } from 'src/app/_services/google/google.service';
import { GoogleOauthService } from 'src/app/shared/google-oauth/google-oauth.service';

@Component({
	selector: 'app-signin',
	templateUrl: './signin-new.component.html',
	styleUrls: ['./signin-new.component.scss']
})
export class SigninComponent implements OnInit, OnDestroy {
	public signInForm: FormGroup;
	public emFormControl: FormControl;
	public passFormControl: FormControl;
	public isAccountSignedIn = false;
	public loading = false;
	public hasRedirectParam: boolean;
	public validationMessages: ValidatorMessages[];
	public isFromBigCommerce = false;
	private bigCommerceObj: IBigCommerceAuth;
	private unsubscriber$ = new Subject<void>();
	public subscribeToBillingId: any;
	private intervalId: any = null;
	private loopCount = 4000;
	private intervalLength = 100;
	private windowHandle: Window;
	isLoggedin: boolean;

	constructor(
		private router: Router,
		private adAccountService: AdAccountApiService,
		private activatedRoute: ActivatedRoute,
		private authStore: Store<AuthenticationState>,
		private store: Store<AppState>,
		private authenticationService: AuthenticationService,
		private userService: UserServiceApi,
		private toastNotificationService: ToastNotificationService,
		private bilingService: BillingAndSubscriptionService,
		public dialog: MatDialog,
		private _location: Location,
		private googleService: GoogleService,
		private googleoauthService: GoogleOauthService,
		private ngZone: NgZone
	) {
		this.authStore.pipe(select(getRedirectUrl), take(1)).subscribe(redirectUrl => {
			this.activatedRoute.queryParams.subscribe(params => {
				this.hasRedirectParam = params[redirectUrl] === 'true';

				// controls for when route is hit from bigcommerce
				this.isFromBigCommerce = params.platform && params.platform === 'bigcommerce';
				if (this.isFromBigCommerce) {
					this.bigCommerceObj = {
						platform: params.platform,
						storeEmail: params.store_email,
						idInPlatform: params.id_in_platform,
						scope: params.scope,
						storeHash: params.store_hash,
						accessToken: params.access_token,
						storeFrontToken: params.storefront_token
					};
				}
			});
		});
	}

	public ngOnInit(): void {
		const emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		this.emFormControl = new FormControl('', [Validators.required, Validators.pattern(emailValidator), Validators.maxLength(70)]);
		this.passFormControl = new FormControl('', [Validators.required]);

		this.signInForm = new FormGroup({
			email: this.emFormControl,
			password: this.passFormControl
		});

		if (this.hasRedirectParam && !this.isFromBigCommerce) {
			this.authStore.dispatch(new LogIn());
		}

		this.toggleLoader();
		this.listenToOTPRequest();

		this.bilingService
			.getSubcribeToId()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.subscribeToBillingId = isDevMode() ? '1' : '35';
					return EMPTY;
				})
			)
			.subscribe(value => {
				if (value) {
					this.subscribeToBillingId = value;
				} else {
					this.subscribeToBillingId = isDevMode() ? '1' : '35';
				}
			});
	}

	public listenToOTPRequest(): void {
		this.authStore.pipe(select(getOtpDialogState), takeUntil(this.unsubscriber$)).subscribe((status: boolean) => {
			if (status) {
				const dialogRef = this.dialog.open(VerifyAddUserComponent, {
					width: '520px',
					height: '480px',
					disableClose: true
				});
				dialogRef.componentInstance.modelDetail = {
					title: 'Two-Factor Authentication!',
					text: 'A message has been sent to the provided phone number. Please enter 6 digit code to authorize this signin.',
					closeBtn: true,
					actionBtn: true,
					actionBtnText: 'CONFIRM',
					useOtp: true,
					otp: {
						secret: false,
						count: 6
					}
				};
				dialogRef.afterClosed().subscribe(details => {
					if (details && details?.status) {
						const temporaryLoginEmail = this.emFormControl.value.trim();
						const temporaryLoginPassword = this.passFormControl.value.trim();
						const loginPayload: LogInModel = {
							email: temporaryLoginEmail,
							password: temporaryLoginPassword,
							code: details.code
						};
						this.authStore.dispatch(new LogIn(loginPayload));
						this.loading = true;
					} else {
						this.authenticationService.logout();
					}
				});
			}
		});
	}

	public onForgotPasswordClick(): void {
		this.router.navigate(['/authentication/reset-password-with-email']);
	}

	public onSubmit(): void {
		if (this.signInForm.invalid) {
			return;
		}
		this.loading = true;

		const temporaryLoginEmail = this.emFormControl.value.trim();
		const temporaryLoginPassword = this.passFormControl.value.trim();

		if (!this.hasRedirectParam) {
			const temporaryCredentials = {
				emailAddress: this.emFormControl.value.trim(),
				password: this.passFormControl.value.trim()
			};
		}

		const loginPayload: LogInModel = {
			email: temporaryLoginEmail,
			password: temporaryLoginPassword,
			code: ''
		};

		if (!this.isFromBigCommerce) {
			this.ngZone.run(() => {
				this.authStore.dispatch(new LogIn(loginPayload));
			});
			this.toggleLoader();
		} else if (this.isFromBigCommerce) {
			this.bigCommerceObj.emailAddress = temporaryLoginEmail;
			this.bigCommerceObj.password = temporaryLoginPassword;
			this.authBigCommerceUser(this.bigCommerceObj);
		}
	}

	private toggleLoader(): void {
		this.store.select('authentication').subscribe(res => {
			if (!res.isAuthenticated) {
				this.loading = false;
			}
		});
	}

	public onSubscribeToAnOffer(): void {
		this.router.navigateByUrl(`/authentication/sign-up`);
		// this.router.navigateByUrl(`/authentication/verify-email`);
	}

	private authBigCommerceUser(payload: IBigCommerceAuth) {
		this.userService
			.bigCommerceAuth(payload)
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(error => {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('There was an error authenticating user for big commerce ' + error.toString());
					return EMPTY;
				})
			)
			.subscribe(value => {
				if (value.status === 200) {
					const loginPayload: LogInModel = {
						email: payload.emailAddress,
						password: payload.password,
						code: ''
					};
					this.authenticationService.bigCommerceAction$.next(true);
					this.authStore.dispatch(new LogIn(loginPayload));
					this.toggleLoader();
				} else {
					this.loading = false;
					this.toastNotificationService.sendErrorToast('There was an error authenticating user for big commerce ');
				}
			});
	}

	public signinWithGoogle(): void {
		this.googleoauthService
			.loginForUser()
			.then(value => {
				if (value.getAuthResponse().id_token) {
					this.userService
						.googleSignin(value.getAuthResponse().id_token)
						.pipe(
							takeUntil(this.unsubscriber$),
							catchError(error => {
								this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
								return of(null);
							})
						)
						.subscribe(value => {
							this.ngZone.run(() => {
								this.store.dispatch(new LogInSuccess(value));
							});
						});
				} else {
					this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
				}
			})
			.catch(error => {
				this.toastNotificationService.sendErrorToast('Error while signing you in with google, please contact support team');
			});
	}

	public doGoogleAuthorization(): void {
		let loopCount = this.loopCount;

		this.windowHandle = this.createOauthWindow(
			'https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=17118844476-ff344s34figbe2sfjmqa5qg7msrjta7m.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fpy-google-accounts-api.dev3.filed.com%2Fapi%2Fv1%2Fuser%2Fauth&scope=openid+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&state=d39f5f35d85637a59e2623d32cf75d8256ddf9250e0ead5c42dbc579d22b3d96&prompt=consent&access_type=offline&include_granted_scopes=true',
			'OAuth login'
		);

		this.intervalId = window.setInterval(() => {
			if (loopCount-- < 0) {
				window.clearInterval(this.intervalId);
				this.closeWindow();
			} else if (this.windowHandle?.location?.href === undefined) {
				window.clearInterval(this.intervalId);
				this.toastNotificationService.sendErrorToast('Google account not supported');
			} else if (this.windowHandle.location.href.indexOf('authentication') > -1) {
				window.clearInterval(this.intervalId);
				// this.checkInstalledGoogle();

				this.closeWindow();
			} else {
				let href: string;
				try {
					href = this.windowHandle.location.href;
				} catch (e) {}

				// if (href != null) {
				// 	const getQueryString = function (field: any, url: string) {
				// 		const windowLocationUrl = url ? url : href;
				// 		const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
				// 		const string = reg.exec(windowLocationUrl);
				// 		return string ? string[1] : null;
				// 	};

				// 	if (href.match('code')) {
				// 		window.clearInterval(this.intervalId);
				// 		this.authorizationCode = getQueryString('code', href);
				// 		this.closeWindow();

				// 		if (isRegisterAction) {
				// 		} else {
				// 		}
				// 	} else if (href.match('oauth_token')) {
				// 		window.clearInterval(this.intervalId);
				// 		this.oAuthToken = getQueryString('oauth_token', href);
				// 		this.oAuthVerifier = getQueryString('oauth_verifier', href);
				// 		this.closeWindow();

				// 		if (isRegisterAction) {
				// 		} else {
				// 		}
				// 	}
				// 	// this.currentStep = $step;
				// }
			}
		}, this.intervalLength);
	}
	public closeWindow(): void {
		this.windowHandle.close();
	}
	public createOauthWindow(url: string, name = 'authorization', width = 500, height = 600, left = 0, top = 0): Window {
		if (url == null) {
			return null;
		}
		const options = `width=${width},height=${height},left=${left},top=${top}`;
		return window.open(url, '_self');
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
