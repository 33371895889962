import { Injectable } from '@angular/core';
import { ValidationNodeInterface } from '../catalogs/validation-node.interface';
import { DropdownData } from '../../shared/dropdown-search-select/dropdown-data.interface';
import { CatalogNodeInterface } from '../catalogs/catalog-node.interface';

@Injectable()
export class CampaignSmartCreateValidationService {
	public temp: string[];
	constructor() {}

	public getValidFields(
		validator: ValidationNodeInterface[],
		filterBy: string,
		select: string,
		all: DropdownData<CatalogNodeInterface>[],
		objective: string
	): DropdownData<CatalogNodeInterface>[] {
		const step1 = this.firstFilter(validator, filterBy, select);
		const step2 = this.secondFilter(step1, objective);
		return this.lastFilter(step2, all);
	}

	public getValidFourLayer(
		validator: ValidationNodeInterface[],
		filterBy: string,
		select: string,
		all: DropdownData<CatalogNodeInterface>[],
		objective: string,
		goal: string
	): DropdownData<CatalogNodeInterface>[] {
		const step1 = this.firstFilter(validator, filterBy, select);
		const step2 = this.secondFilterFourLayers(step1, objective, goal);

		return this.lastFilter(step2, all);
	}

	public firstFilter(validation: ValidationNodeInterface[], filterBy: string, select: string): string[] {
		validation.forEach(item => {
			if (item.fields.includes(filterBy) && item.fields.includes(select)) {
				this.temp = item.items;
			}
		});
		return this.temp;
	}

	public secondFilter(data: string[], objective: string): string[] {
		const allowed: string[] = [];
		data.forEach(item => {
			if (item[0].includes(objective)) {
				const index = item[1].indexOf('.') + 1;
				allowed.push(item[1].slice(index, item[1].length));
			}
		});
		return allowed;
	}

	public secondFilterFourLayers(data: string[], objective: string, goal: string): string[] {
		const temp: string[] = [];
		data.forEach(item => {
			if (item[0].includes(objective) && item[1].includes(goal)) {
				const index1 = item[2].indexOf('.') + 1;
				const index2 = item[3].indexOf('.') + 1;
				const value1 = item[2].slice(index1, item[2].length);
				const value2 = item[3].slice(index2, item[3].length);
				temp.push(`${value1 + '/' + value2}`);
			}
		});
		return temp;
	}

	public lastFilter(allowed: string[], all: DropdownData<CatalogNodeInterface>[]): DropdownData<CatalogNodeInterface>[] {
		const result: DropdownData<CatalogNodeInterface>[] = [];
		allowed.forEach(allow => {
			const match = all.filter(item => item.value === allow);
			result.push(match[0]);
		});
		return result;
	}
}
