import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, skipWhile, switchMap, withLatestFrom } from 'rxjs/operators';
import { FiledLibMapperService } from 'src/app/shared/services/filed-lib-Mapper.service';
import { HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { PageService } from 'src/app/_services/facebook-accounts/page.service';
import { AAAModuleState } from '..';
import { AdActionTypes, LoadFacebookPages, LoadFacebookPagesFailure, LoadFacebookPagesSuccess } from './ad-state.actions';
import { getFacebookBusinessOwnerId, getUserDetails } from '../../../shared/state/user/user.reducer';

@Injectable()
export class AdsEffects {
	constructor(
		private actions: Actions,
		private store: Store<AAAModuleState>,
		private sharedStore: Store<SharedState>,
		private pagesService: PageService,
		private mapperService: FiledLibMapperService,
		private toastNotificationService: ToastNotificationService
	) {}

	// public loadPageIds$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadFacebookPages>(AdActionTypes.LoadFacebookPages),
	// 		switchMap(action =>
	// 			of(action).pipe(
	// 				withLatestFrom(this.store.pipe(select(getUserDetails))),
	// 				exhaustMap(([action, facebookBusinessId]) => {
	// 					if (facebookBusinessId) {
	// 						return this.pagesService.getPages(facebookBusinessId?.FacebookBusinessOwnerId).pipe(
	// 							map(pages => {
	// 								const mappedPages = this.mapperService.convertToDropdownData(pages, 'id', 'name');
	// 								this.sharedStore.dispatch(new HideGlobalSpinner());
	// 								return new LoadFacebookPagesSuccess(mappedPages);
	// 							}),
	// 							catchError(() => {
	// 								this.sharedStore.dispatch(new HideGlobalSpinner());
	// 								return of(new LoadFacebookPagesFailure());
	// 							})
	// 						);
	// 					}
	// 				})
	// 			)
	// 		)
	// 	)
	// );
}
