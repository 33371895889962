import { ChartDataModel } from './chart-data.model';
import { ChartSeriesModel } from './chart-series.model';
import { ChartLegendData } from './chart-legend-data';
import { ChartGridData } from './chart-grid-data';
import { ChartToolboxData } from './chart-toolbox-data';
import { ChartTypeEnum } from '../../../reports/shared/charts/chart-type.enum';

export class ChartDataBuilder {
	private _title = '';
	private _subTitle = '';
	private _chartType: ChartTypeEnum = ChartTypeEnum.Line;
	private _categoricalData: any[] = [];
	private _isHorizontal = true;
	private _continuousData: ChartSeriesModel[] = [];
	private _legendData: ChartLegendData;
	private _gridData: ChartGridData;
	private _toolBoxData: ChartToolboxData;

	public constructor(chartData?: ChartDataModel) {
		if (chartData) {
			this._title = chartData.title;
			this._subTitle = chartData.subTitle;
			this._chartType = chartData.chartType;
			this._categoricalData = chartData.categoricalData;
			this._isHorizontal = chartData.isHorizontal;
			this._continuousData = chartData.continuousData;
			this._legendData = chartData.legendData;
			this._gridData = chartData.gridData;
			this._toolBoxData = chartData.toolboxData;
		}
	}

	public title(title: string): ChartDataBuilder {
		this._title = title;
		return this;
	}

	public subTitle(subTitle: string): ChartDataBuilder {
		this._subTitle = subTitle;
		return this;
	}

	public chartType(type: ChartTypeEnum): ChartDataBuilder {
		this._chartType = type;
		return this;
	}

	public categoricalData(data: any[]): ChartDataBuilder {
		this._categoricalData = data;
		return this;
	}

	public isHorizontal(isHorizontal: boolean): ChartDataBuilder {
		this._isHorizontal = isHorizontal;
		return this;
	}

	public continuousData(data: ChartSeriesModel[]): ChartDataBuilder {
		this._continuousData = data;
		return this;
	}

	public legendData(legendData: ChartLegendData): ChartDataBuilder {
		this._legendData = legendData;
		return this;
	}

	public gridData(gridData: ChartGridData): ChartDataBuilder {
		this._gridData = gridData;
		return this;
	}

	public toolboxData(toolboxData: ChartToolboxData) {
		this._toolBoxData = toolboxData;
		return this;
	}

	public build(): ChartDataModel {
		return new ChartDataModel(
			this._title,
			this._subTitle,
			this._chartType,
			this._categoricalData,
			this._isHorizontal,
			this._continuousData,
			this._legendData,
			this._gridData,
			this._toolBoxData
		);
	}
}
