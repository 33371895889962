<app-user-header></app-user-header>
<div class="page-container">
	<div class="form-area">
		<div class="page-header">
			<div class="back-btn">
				<a (click)="backClicked()">
					<i class="k-icon k-i-arrow-chevron-left"></i>
					Back
				</a>
			</div>
			<div class="title">{{ isNewCard ? 'Add' : 'Edit' }} Card</div>

			<div class="card-image">
				<img class="visa" alt="" src="assets/icons/Visa.svg" />
				<img class="mastercard" alt="" src="assets/icons/Master.svg" />
				<img alt="Amex Card" class="amex" src="assets/icons/amex-card.svg" />
			</div>
		</div>
		<!--lolly-spinning-loader class="spinner" *ngIf="loading"></!--lolly-spinning-loader-->
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<app-payment [cardDetails]="cardDetails" [isEdit]="!isNewCard" [formControlsRequests]="formControlsRequests"></app-payment>

					<div class="checboxes">
						<label>
							<input type="checkbox" [checked]="isDefault ? 'checked' : ''" (change)="onMarkDefault($event.target.checked)" />
							<span>Make this card as default payment</span>
						</label>
						<br />
						<label>
							<input type="checkbox" (change)="onAcceptTerms($event.target.checked)" />
							<span>
								By adding your card to our platform, you agree to our
								<a href="https://www.lolly.com/privacy/" target="_blank">terms of services</a>
								and
								<a href="https://www.lolly.com/privacy/" target="_blank">privacy policy</a>
								.
							</span>
						</label>
					</div>
				</div>
				<div class="form-btn">
					<div></div>
					<div>
						<ngTemplate *ngIf="cardDetails.invalid || !noTermsOption">
							<btn04 class="button" *ngIf="isNewCard">
								ADD CARD
							</btn04>
							<btn04 class="button" *ngIf="!isNewCard">
								UPDATE CARD
							</btn04>
						</ngTemplate>
						<ngTemplate *ngIf="cardDetails.valid && noTermsOption">
							<btn01 class="btn-signup" (click)="addPaymentCard()" *ngIf="isNewCard">
								ADD CARD
							</btn01>
							<btn01 class="btn-signup" (click)="updatePaymentCard()" *ngIf="!isNewCard">
								UPDATE CARD
							</btn01>
						</ngTemplate>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
