export enum FiledUsersApiCodesEnum {
	NA = 0,

	InitialPasswordWasAlreadySetup,

	Filed__Domain__NA__SetPassword__IsInvalidPasswordMismatch,
	SetPassword_PasswordRequiresDigit,
	SetPassword_PasswordRequiresLower,
	SetPassword_PasswordRequiresNonAlphanumeric,
	SetPassword_PasswordRequiresUniqueChars,
	SetPassword_PasswordRequiresUpper,
	SetPassword_PasswordTooShort,

	SignIn_IdentityIsLockedOut,
	SignIn_IdentityIsNotAllowed,
	SignIn_IdentityRequiresTwoFactor,

	UserId_HasUserWithNoRolesDefined,
	UserId_IsDifferentInRouteAndJwt,
	UserId_IsNotClientEmployeeForCurrentUser,

	UserWasNotFound_ByBusinessOwnerFacebookId,
	UserWasNotFound_ByEmail,
	UserWasNotFound_ById,

	UserWithSameEmail_AlreadyExists
}
