import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Modules } from '../enums/modules';
import { PermissionsService } from '../permissions.service';
import { take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getUserDetails, UserState } from '../../state/user/user.reducer';
import { CampaignBuilderPermissions } from '../enums/campaign-builder-permissions';

@Injectable({
	providedIn: 'root'
})
export class CampaignBuilderGuard implements CanActivate {
	constructor(private permissionServiceBuilder: PermissionsService, private userStore: Store<UserState>) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		let isAllowed = false;
		this.userStore.pipe(select(getUserDetails), take(1)).subscribe(data => {
			data.Permissions.filter(moduleData => moduleData.module === Modules.CampaignBuilder).forEach(module => {
				module.permissions.forEach(permission => {
					if (permission === CampaignBuilderPermissions.CanAccessCampaignBuilder) {
						isAllowed = true;
					}
				});
			});
		});
		return of(isAllowed);
	}
}
