export enum QueryBuilderWhereOperator {
	Equals = 0,
	GreaterThan = 1,
	GreaterThanOrEqualWith = 2,
	LessThan = 3,
	LessThanOrEqualWith = 4,
	NotEquals = 5,
	In = 6
}

export enum QueryBuilderWhereOperatorExtension {
	Between = 100
}

export type ExtendedQueryBuilderWhereOperator = QueryBuilderWhereOperator | QueryBuilderWhereOperatorExtension;
