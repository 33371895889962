import { CallToAction } from 'src/app/campaign-builder/models/get-call-to-actions-request.model';
import { DevicePlacementPosition } from './device-placement-position.model';
import { UtmParameter } from '../../_models/utm/utm-parameter';
import { AdFormats } from '../../shared/models/server-statics-enum-constants';

export class AdvertTemplateGenericDetail {
	public id: number;
	public texts: string[];
	public websiteUrls: string[];
	public devicePlacementPositionId: number;
	public callToActionItemIds: number[];
	public callToActionItems: CallToAction[];
}

export class CarouselAdvertTemplateDetail extends AdvertTemplateGenericDetail {
	constructor() {
		super();
	}

	public carouselAdvertTemplateFrames: CarouselAdvertTemplateFrame[];
}

export class CarouselAdvertTemplateFrame {
	public carouselAdvertTemplateFrameDetails: CarouselAdvertTemplateFrameDetail[];
}

export class CarouselAdvertTemplateFrameDetail {
	public headLine: string;
	public websiteUrl: string;
	public displayLink: string;
	public displayLinkDescription: string;
	public mediaUrl: string;
	public videoId?: string;
}

export class SingleAdvertTemplateDetail extends AdvertTemplateGenericDetail {
	constructor() {
		super();
	}

	public headLines: string[];
	public displayLinks: string[];
	public displayLinkDescriptions: string[];
	public mediaUrls: string[];
	public videoIds: string[];
}

export class SlideshowAdvertTemplateDetail extends AdvertTemplateGenericDetail {
	constructor() {
		super();
	}

	public headLines: string[];
	public displayLinks: string[];
	public displayLinkDescriptions: string[];
	public slideshowTemplates: SlideshowTemplate[];
}

export interface SlideshowTemplate {
	mediaUrls: string[];
	videoIds: string[];
	duration: number;
	transition: number;
	facebookVideoId: string;
}

export interface AdvertPreviewDetail {
	isSelected: boolean;
	headLine: string;
	text: string;
	websiteUrl: string;
	displayLink: string;
	displayLinkDescription: string;
	devicePlacementPosition: DevicePlacementPosition;
	devicePlacementPositionId: number;
	callToActionItemId: number;
	callToActionItemKey: string;
	mediaURL: string;
	preview: string;
}

export class AdvertTemplate {
	public id: number;
	public campaignId: number;
	public adSetTemplateId: number;
	public variationId: number;
	public adFormatId: number;
	public singleImageAdvertTemplates: SingleAdvertTemplateDetail[];
	public singleVideoAdvertTemplates: SingleAdvertTemplateDetail[];
	public carouselAdvertTemplates: CarouselAdvertTemplateDetail[];
	public slideshowAdvertTemplates: SlideshowAdvertTemplateDetail[];
	public adAccountId?: string;
	public adSetId?: number;
	public utm: UtmParameter;
	public name?: string;
	public usePosts?: boolean;
	public details: AdvertDetails;
	public trackingSpec: ConversionActionQuery[];
}

export interface AdvertDetails {
	name: string;
	generated_adverts: (SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto)[];
	isValid: boolean;
	pageFacebookId: string;
	instagramAccountFacebookId: string;
	postFacebookId: string;
}

export class AdvertPreview {
	public facebookPageId?: string;
	public ad_account_id: string;
	public business_owner_facebook_id: string;
	public instagram_id?: string;
	public adverts: SingleAdvertDto | CarouselDto | MultipleSingleAdvertDto;
	public ad_format?: AdFormats;
	public ad_format_type?: AdFormats;
	public objective?: string;
	public devicePlacementPosition?: string;
	public productSetID?: string;
	public productSetId?: string;
}

export interface ConversionActionQuery {
	'action.type': string;
	page?: string;
	post?: string;
	fb_pixel?: string;
	application?: string;
	object?: string;
}

// Single Image and Video
export class SingleAdvertDisplayModel {
	public isValid: boolean;
	public media_urls: string[];
	public video_ids: string[];
	public primary_texts: string[];
	public headlines: string[];
	public descriptions: string[];
	public website_urls: string[];
	public display_links: string[];
	public deferred_deep_links: string[];
	public deep_links: string[];
	public call_to_actions: CallToAction[];
	// TODO following will be implemented later
	// edit_top_colors: string[];
	// edit_bottom_colors: string[];

	constructor() {
		this.media_urls = [];
		this.video_ids = [];
		this.primary_texts = [];
		this.headlines = [];
		this.descriptions = [];
		this.website_urls = [];
		this.display_links = [];
		this.deferred_deep_links = [];
		this.deep_links = [];
		this.call_to_actions = [];
		// TODO following will be implemented later
		// this.edit_top_colors = [];
		// this.edit_bottom_colors = [];
	}
}

export class SingleAdvertDto {
	public media_url?: string;
	public media?: any;
	public objectieve?: string;
	public video_id?: number;
	public picture?: string;
	public primary_text?: string;
	public headline?: string;
	public guid?: string;
	public description?: string;
	public website_url: string;
	public display_link?: string;
	public deferred_deep_link?: string;
	public deep_link?: string;
	public call_to_action?: string;
	public callToAction?: string;
	// TODO following will be implemented later
	// edit_top_color: string;
	// edit_bottom_color: string;
	public is_selected?: boolean;
	public ad_format?: AdFormats;
	public device_placement_position?: string;
	public instagram_id?: string;
	public preview?: string;
	public custom_id?: number;
	public tracking_spec?: ConversionActionQuery[];
	public instagramAccountFacebookId?: string;
	public post_id?: string;
	public pictureWidth?: number;
	public pictureHeight?: number;
	public mediaType?: string;
	public productSetID?: string;
	public eventTrigger?: boolean;
}

export class MultipleSingleAdvertDto {
	public media_url?: string[];
	public objectieve?: string;
	public video_id?: number;
	public picture?: string;
	public primary_text?: string[];
	public headline?: string[];
	public guid?: string;
	public description?: string;
	public website_url?: string;
	public display_link?: string;
	public deferred_deep_link?: string;
	public deep_link?: string;
	public call_to_action?: string;
	// TODO following will be implemented later
	// edit_top_color: string;
	// edit_bottom_color: string;
	public is_selected?: boolean;
	public ad_format?: AdFormats;
	public device_placement_position?: string;
	public instagram_id?: string;
	public preview?: string;
	public custom_id?: number;
	public tracking_spec?: ConversionActionQuery[];
	public instagramAccountFacebookId?: string;
	public post_id?: string;
	public pictureWidth?: number;
	public pictureHeight?: number;
	public mediaType?: string;
	public productSetID?: string;
	public eventTrigger?: boolean;
}

export class CarouselDisplayModel {
	public isValid: boolean;
	public cards: CarouselCardDisplayModel[];
	public autoShowBestPerformingFirst: boolean;
	public addCardWithPageProfile: boolean;
	public primary_texts: string[];
	public website_urls: string[];
	public see_more_urls: string[];
	public see_more_display_links: string[];
	public deferred_deep_links: string[];
	public deep_links: string[];
	public call_to_actions: CallToAction[];

	constructor() {
		this.cards = [];
		for (let i = 0; i < 2; i++) {
			this.cards.push(new CarouselCardDisplayModel());
		}
		this.primary_texts = [];
		this.website_urls = [];
		this.see_more_urls = [];
		this.see_more_display_links = [];
		this.deferred_deep_links = [];
		this.deep_links = [];
		this.call_to_actions = [];
	}
}

export class CarouselCardDisplayModel {
	public media_urls: string[];
	public video_ids: string[];
	public headlines: string[];
	public descriptions: string[];
	public website_urls: string[];
	public deferred_deep_links: string[];
	public deep_links: string[];

	constructor() {
		this.media_urls = [];
		this.video_ids = [];
		this.headlines = [];
		this.descriptions = [];
		this.website_urls = [];
		this.deferred_deep_links = [];
		this.deep_links = [];
	}
}

export class CarouselDto {
	public objectieve?: string;
	public auto_show_best_performing_first: boolean;
	public add_card_with_page_profile: boolean;
	public primary_text: string;
	public website_url: string;
	public see_more_url: string;
	public see_more_display_link: string;
	public deferred_deep_link: string;
	public deep_link: string;
	public call_to_action: string;
	public is_selected?: boolean;
	public ad_format?: AdFormats;
	public device_placement_position?: string;
	public instagram_actor_id?: string;
	public preview?: string;
	public custom_id?: number;
	public tracking_spec: ConversionActionQuery[];
	public instagramAccountFacebookId: string;
	public post_id?: string;
	public video_id?: number;
	public picture?: string;
	public headline?: string;
	public description?: string;
	public pictureWidth?: number;
	public pictureHeight?: number;
	public media_url: string;
	public mediaType: string;
	public cards: CarouselCardDto[];
	public productSetID: string;
	public eventTrigger?: boolean;
}

export class CarouselCardDto {
	public primary_text: string;
	public media_url: string;
	public video_id: number;
	public media?: any;
	public headline: string;
	public description: string;
	public website_url: string;
	public deferred_deep_link: string;
	public deep_link: string;
	public mediaType: string;
	public picture: string;
	public call_to_action: CallToAction;
	public preview?: string;
}
