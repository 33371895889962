<app-expansion-panel-sc [isSelected]="isPanelSelected" (click)="onExpansionClick.emit()" [isExpandable]="false" expansionTitle="Select App">
	<div expansion-content class="app__wrapper">
		<div class="app__dropdown">
			<span>Choose the app that you want to promote</span>
			<div class="oad-dropdown-wrapper">
				<drp01 (valueChanges)="handleAppSelection()" [data]="apps" [control]="appName" [valuePrimitive]="true"></drp01>
			</div>
			<p class="description">
				Search for your app by typing in the app name or enter its exact app store URL, e.g. https://play.google.com/store/apps/details?
				id=com.facebook.katana
			</p>
		</div>
		<div class="expansion-input">
			<tb01 [control]="appLink" [placeHolderTextBox]="placeholder" [validatorMessages]="validationMessages"></tb01>
		</div>
		<div class="check">
			<span>
				<chkb01 [value]="isChecked" (valueChanges)="toggleIsChecked($event)"></chkb01>
			</span>
			<p class="description">If you are unable to find your app above, refine your search by selecting a country in which your app is available</p>
		</div>
	</div>
</app-expansion-panel-sc>
