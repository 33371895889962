import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CampaignStepsEnum } from '../../models/campaign-steps.enum';
import { ExpansionPanelHelper } from '../../../shared/expansion-panel/expansion-panel-helper.service';
import { CreateAdPanels } from './create-ad.panels';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { SelectedAdAccountModel } from '../../../shared/state/state-entities/cached-ad-accounts.model';
import { StepThreeDetails } from '../../models/step-three-details';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IQuickNavigation } from '@filed-com/filed-lib/lib/cards/models/quick-navigation.model';
import {
	CampaignSmartCreateState,
	getAdFormat,
	getCurrentAd,
	getCurrentAdIndex,
	getFacebookPageId,
	getInstagramPageId,
	getPixelAppEventId,
	getPixelId,
	getPixelToggle,
	getStepThreeDetails,
	getStepTwoDetails
} from '../../state/smart-create.reducer';
import {
	AddNewAd,
	ClearCurrentStep,
	RemoveAd,
	UpdateAdFormatType,
	UpdateAdName,
	UpdateAdverts,
	UpdateCampaignCurrentStep,
	UpdateCampaignInvalidStep,
	UpdateCampaignTemplate,
	UpdateConversion,
	UpdateCurrentAd,
	UpdatePixelAppEventId,
	UpdatePixelId,
	UpdatePixelToggle,
	UpdateSelectedAdIndex
} from '../../state/smart-create.actions';
import { getSelectedAdAccount, SharedState } from '../../../shared/state/shared.reducer';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { AdFormatsEnum } from '../../models/ad-formats.enum';
import { Asset } from '../../../shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';
import { CarouselDto, SingleAdvertDto } from '../../../campaign-builder/models/advert.interface';
import { CatalogNodeInterface } from '../../catalogs/catalog-node.interface';
import { EventDtoInterface } from '../../../pixel/_models/event-dto.interface';
import { ObjectivesEnum } from '../../models/objectives.enum';
import { ValidationNodeInterface } from '../../catalogs/validation-node.interface';
import { UserVideosInterface } from '../../models/user-videos.interface';
import { UserImagesInterface } from '../../models/user-images.interface';
import { ObjectiveTreeInterface } from '../../models/objective-tree.interface';
import { DropdownData } from '../../../shared/dropdown-search-select/dropdown-data.interface';
import { ConversionInterface } from '../../models/conversion-interface';
import { QuickNavigationService } from 'src/app/AAA/services/quick-navigation.service';
import { LoadExistingImages, LoadUserVideos } from '../../../state/campaign-common-state/campaign-common-actions';
import { getAdFormatFromCatalog } from '../../../state/campaign-common-state/campaign-common-reducer';
import { SubObjectives } from '../../models/sub-objectives';
import { AdFormats } from '../../../shared/models/server-statics-enum-constants';

@Component({
	selector: 'app-create-ad',
	templateUrl: './create-ad.component.html',
	styleUrls: ['./create-ad.component.scss'],
	providers: [ExpansionPanelHelper]
})
export class CreateAdComponent implements OnInit, OnDestroy, AfterViewInit {
	public navigations: IQuickNavigation[] = [
		{ name: 'Ad Navigation', targetId: 'ad-navigation' },
		{ name: 'Ad Name', targetId: 'name-your-ad' },
		{ name: 'Ad Format', targetId: 'ad-format' },
		{ name: 'Ad Creation', targetId: 'ad-creation' },
		{ name: 'Tracking', targetId: 'tracking' }
	];
	public adNameControl = new FormControl('', Validators.required);
	public panels = CreateAdPanels;
	public selectedAdAccount: SelectedAdAccountModel;
	public adCreateFormGroup: FormGroup;
	public adFormGroup: FormGroup;
	public pixelFormGroup: FormGroup;
	public lastStep: CampaignStepsEnum;
	public isThisStepValid: boolean;
	public isAdCreationFormValid = false;
	public readonly step = CampaignStepsEnum.Three;
	public selectedFormat: number;
	public adFormats: CatalogNodeInterface[];
	public pixelId: string;
	public pixelEventId: string;
	public previews: CatalogNodeInterface[] = [];
	public facebookPageId: string;
	public instagramPageId: string;
	public businessOwnerFbId: string;
	public posts: Asset[];
	public images: UserImagesInterface;
	public videos: UserVideosInterface;
	public adFormatListener$: Observable<AdFormatsEnum>;
	public callToActions: CatalogNodeInterface[];
	public events: EventDtoInterface[];
	public pixelMandatory: boolean;
	public objective: string;
	public subObjective: string;
	public subObjectives = SubObjectives;
	public objectiveType = ObjectivesEnum;
	public conversion: ConversionInterface;
	public firstTime: boolean;
	public validations: ValidationNodeInterface[];
	public placements: CatalogNodeInterface[];
	public objectiveTree: ObjectiveTreeInterface;
	public validatorTrigger: boolean;
	public selectedIndex: number;
	public label = 'Ad';

	public iframeSrc: string;
	public dropdownPreviewPlacement: DropdownData<CatalogNodeInterface>[] = [];
	public adFormatEnum = AdFormatsEnum;
	public adFormat: AdFormatsEnum;
	public isSticky: boolean;
	public selectedAssets: Asset[] = [];

	public allAds: StepThreeDetails[] = [];
	public selectedAd: StepThreeDetails;
	public selectedAdIndex = 0;

	protected campaignId: string;
	protected currentStep: StepThreeDetails[] = [];
	protected unsubscriber$ = new Subject<void>();
	protected formUnsubscriber$ = new Subject<void>();

	protected formBuilder: FormBuilder;
	protected store: Store<CampaignSmartCreateState>;
	protected sharedStore: Store<SharedState>;
	protected toastService: ToastNotificationService;
	protected router: Router;
	protected activatedRoute: ActivatedRoute;
	public expansionPanelHelper: ExpansionPanelHelper<CreateAdPanels>;
	protected quickNavigationService: QuickNavigationService;
	public leadGenMethod: string;
	public productSetId: string;
	public isAppInstalled: boolean = false;

	constructor(protected injector: Injector) {
		this.router = this.injector.get<Router>(Router);
		this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
		this.store = this.injector.get<Store<CampaignSmartCreateState>>(Store);
		this.activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
		this.toastService = this.injector.get<ToastNotificationService>(ToastNotificationService);
		this.expansionPanelHelper = this.injector.get<ExpansionPanelHelper<CreateAdPanels>>(ExpansionPanelHelper);
		this.quickNavigationService = this.injector.get<QuickNavigationService>(QuickNavigationService);
	}

	public ngOnInit(): void {
		this.store.dispatch(new UpdateSelectedAdIndex(this.selectedAdIndex));
		this.storeListener();
		this.registerSelection();
		this.getActivatedRoute();
		this.getPagesIds();
		this.getAdNameControl();
		this.getSelectedAccount();
		this.getAdFormats();
		this.getAdFormat();
		this.checkSelectedPixelId();
		this.checkSelectedEventId();
		this.getConversion();
		this.renderLeadMethodForms();
		this.isAppInstalled = this.subObjective == SubObjectives.AppInstallText;
		this.recreateNavigationLink();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.formUnsubscriber$.next();

		this.formUnsubscriber$.complete();
	}
	public recreateNavigationLink(): void {
		if (this.isAppInstalled) {
			this.navigations.shift();
		}
	}
	public renderLeadMethodForms(): void {
		this.leadGenMethod = localStorage.getItem('method');
	}
	public addNewAd(): void {
		const newAd = this.allAds[this.selectedAdIndex];
		newAd.adName = 'Filed Ad ' + (this.allAds?.length + 1);
		this.store.dispatch(new AddNewAd(newAd));
		this.adCreateFormGroup.markAllAsTouched();
	}
	public removeAd(index: number): void {
		this.store.dispatch(new RemoveAd(index));
		if (this.selectedAdIndex === index) {
			this.selectedAdIndex--;
			this.store.dispatch(new UpdateSelectedAdIndex(this.selectedAdIndex));
			this.store.dispatch(new UpdateCurrentAd());
		}
	}
	public selectAd(index: number): void {
		this.store.dispatch(new UpdateSelectedAdIndex(index));
		this.store.dispatch(new UpdateCurrentAd());
		this.toggleFormGroupValue(this.selectedAd);
		if (this.selectedAd.adverts) {
			this.store.dispatch(new UpdateAdverts(this.selectedAd.adverts));
		}
		if (this.selectedAd) {
			this.store.dispatch(new UpdateAdFormatType(this.selectedAd?.adFormatType ?? AdFormatsEnum.SingleImage));
		}
		this.adFormGroup?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
		this.adCreateFormGroup?.markAllAsTouched();
	}
	public registerSelection(): void {
		this.quickNavigationService
			.getSelectedNavigation()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((selectedIndex: number) => (this.selectedIndex = selectedIndex));
	}

	public onPanelClick(index: number): void {
		this.quickNavigationService.setSelectedNavigation(index);
	}

	public oniframeSrc($event: string): void {
		this.iframeSrc = $event;
	}
	public ondropdownPreviewPlacement($event: DropdownData<CatalogNodeInterface>[]): void {
		this.dropdownPreviewPlacement = $event;
	}
	public onadFormat($event: AdFormatsEnum): void {
		this.adFormat = $event;
	}
	public onisSticky($event: boolean): void {
		this.isSticky = $event;
	}
	public onadCreateFormGroup($event: FormGroup): void {
		this.adFormGroup = $event;
	}
	public onselectedAssets($event: Asset[]): void {
		this.selectedAssets = $event;
	}
	public ngAfterViewInit(): void {}
	public onResize(): void {}

	public getActivatedRoute(): void {
		this.activatedRoute.data.pipe(take(1)).subscribe(data => {
			this.objectiveTree = data.store.objectiveTree;
			this.validations = data.store.validations;
			this.placements = data.store.placements;
			this.selectedFormat = data.store?.step?.adFormatType;
			this.posts = data.store.posts;
			this.images = data.store.images;
			this.videos = data.store.videos;
			this.store.dispatch(new UpdateCampaignCurrentStep(data.store?.step));
			this.campaignId = data.store.campaignId;
			this.currentStep = data.store?.step ?? [];
			this.initFormGroup();
			this.initFormGroupListeners();
			this.saveStepsToStore();
			this.lastStep = data.store.lastStep;
			this.selectedAdAccount = data.store.account;
			this.adNameControl.setValue(this.allAds[0]?.adName);
			if (this.allAds[0]?.adName) {
				this.label = '';
			} else {
				this.adCreateFormGroup.get('name').patchValue('Filed Ads 1');
			}
			if (data.store?.step && data.store?.step.length > 0) {
				this.pixelId = data.store?.step[0].pixelId;
				this.pixelEventId = data.store?.step[0].pixelAppEventId;
				this.pixelFormGroup.get('pixel').patchValue(this.pixelId);
			}
			this.previews = data.store.platforms;
			this.callToActions = data.store.callToActions;
			this.checkIfPixelIsMandatory();
		});
	}

	public checkIfPixelIsMandatory(): void {
		this.objective = this.objectiveTree?.objective;
		this.subObjective = this.objectiveTree?.subObjective;
		if (this.objective === this.subObjective) {
			this.pixelFormGroup.get('pixel').setValidators(Validators.required);
			this.pixelMandatory = true;
		} else {
			this.pixelMandatory = false;
		}
	}

	public getEvents(events: EventDtoInterface[]): void {
		this.events = events;
	}

	public getAdNameControl(): void {
		this.adNameControl.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(adName => {
			if (this.adNameControl.valid) {
				this.allAds[0].adName = adName;
				this.store.dispatch(new UpdateAdName(adName));
			}
		});
	}

	public getSelectedAccount(): void {
		this.sharedStore
			.pipe(
				select(getSelectedAdAccount),
				skipWhile(account => !account || account.adAccount.id === this.selectedAdAccount.adAccount.id),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(account => {
				this.store.dispatch(new UpdateCampaignTemplate());
				this.store.dispatch(new ClearCurrentStep());
				this.router.navigate(['/ads-manager/campaign']);
			});
	}
	public getAdFormat(): void {
		this.adFormatListener$ = this.store.pipe(select(getAdFormat), takeUntil(this.unsubscriber$));
	}

	public getAdFormats(): void {
		this.store.pipe(select(getAdFormatFromCatalog), take(1)).subscribe(adFormats => {
			this.adFormats = adFormats;
		});
	}

	public updateFormatType(format?: AdFormatsEnum): void {
		if (this.subObjective === this.subObjectives.CatalogSalesText) {
			this.selectedAd.adFormatType = AdFormatsEnum.catalogueSale;
		} else if (this.subObjective === SubObjectives.AppInstallText) {
			this.selectedAd.adFormatType = AdFormatsEnum.multipleSingleImage;
		} else {
			this.selectedAd.adFormatType = format;
		}
		this.store.dispatch(new UpdateAdFormatType(this.selectedAd.adFormatType ?? AdFormatsEnum.SingleImage));
	}

	public updateAdverts(advert: SingleAdvertDto | CarouselDto): void {
		delete advert['eventTrigger'];
		this.store.dispatch(new UpdateAdverts(advert));
		if (advert.ad_format !== AdFormats.Video && advert.ad_format !== AdFormats.SingleImage && advert.ad_format !== AdFormats.Carousel) {
			this.updateFormatType();
		}
		this.store.dispatch(new UpdateCampaignTemplate());
	}

	public updateInvalidStep(status: CampaignStepsEnum): void {
		this.store.dispatch(new UpdateCampaignInvalidStep(status));
	}

	public loadImages(status: boolean): void {
		this.store.dispatch(
			new LoadExistingImages({
				adAccountId: this.selectedAdAccount.adAccount.id,
				businessOwnerId: this.selectedAdAccount.adAccount.businessId.toString()
			})
		);
	}

	public loadVideos(status: boolean): void {
		this.store.dispatch(new LoadUserVideos(status));
	}

	public onNavClick(step: CampaignStepsEnum): void {
		if (step < CampaignStepsEnum.Three) {
			this.store.dispatch(new UpdateCampaignTemplate());
			this.store.dispatch(new ClearCurrentStep());
			this.router.navigate([`campaign/build-campaign/${step}/${this.campaignId}`]);
		} else {
			this.onNextClick();
		}
	}

	public checkSelectedPixelId(): void {
		this.store.pipe(select(getPixelId), take(1)).subscribe(data => {
			if (data) {
				this.pixelId = data;
			}
		});
		this.getToggleStatus();
	}

	public getToggleStatus(): void {
		this.store.pipe(select(getPixelToggle), take(1)).subscribe(resp => {
			this.firstTime = !resp;
		});
		this.store.dispatch(new UpdatePixelToggle(true));
	}

	public checkSelectedEventId(): void {
		this.store.pipe(select(getPixelAppEventId), take(1)).subscribe(data => {
			if (data) {
				this.pixelEventId = data;
			}
		});
	}

	public getConversion(): void {
		this.store.pipe(select(getCurrentAd), take(1)).subscribe(data => {
			if (data?.customEventType) {
				this.conversion = { customEventType: data.customEventType, pixelRule: data.pixelRule, id: data.conversionId };
			}
		});
	}

	public onNextClick(): void {
		this.updateCampaign();
		if (this.isThisStepValid) {
			this.store.dispatch(new UpdateCampaignTemplate());
			this.store.dispatch(new ClearCurrentStep());
			this.router.navigate([`campaign/build-campaign/4/${this.campaignId}`]);
		}
	}

	public onBackClick(): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate([`campaign/build-campaign/2/${this.campaignId}`]);
	}

	public onCancelClick(): void {
		this.store.dispatch(new UpdateCampaignTemplate());
		this.store.dispatch(new ClearCurrentStep());
		this.router.navigate(['/campaign/select-campaign']);
	}

	public checkAdCreationFormValidity(status: boolean): void {
		this.isAdCreationFormValid = status;
	}

	public updateConversion(conversion: DropdownData<EventDtoInterface>): void {
		this.store.dispatch(new UpdateConversion(conversion));
	}

	public updateCampaign(): void {
		this.store.pipe(select(getStepThreeDetails), take(1)).subscribe(data => {
			this.validatorTrigger = false;
			this.isThisStepValid = true;

			data.forEach((form, index) => {
				if (!form.adName) {
					this.store.dispatch(new UpdateCampaignInvalidStep(CampaignStepsEnum.Three));
					this.toastService.sendErrorToast(`You must select a name for ad ${index}.`);
					this.selectAd(index);
					this.adCreateFormGroup.markAllAsTouched();
					const target = document.querySelector('.name');
					target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
					this.isThisStepValid = false;
					return;
				} else if (!form.adverts) {
					if (!form.adFormatType) {
						this.store.dispatch(new UpdateCampaignInvalidStep(CampaignStepsEnum.Three));
						this.toastService.sendErrorToast(`You must select an Ad Format for ad ${index + 1}.`);
						this.selectAd(index);
						this.isThisStepValid = false;
						return;
					} else {
						this.store.dispatch(new UpdateCampaignInvalidStep(CampaignStepsEnum.Three));
						this.toastService.sendErrorToast(`You must complete Ad Creation Form for ad ${index + 1}.`);
						this.selectAd(index);
						const target = document.querySelector('.ad-creation');
						target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
						this.validatorTrigger = true;
						this.isThisStepValid = false;
						return;
					}
				} else if (this.pixelMandatory) {
					if (!form.pixelId) {
						this.store.dispatch(new UpdateCampaignInvalidStep(CampaignStepsEnum.Three));
						this.toastService.sendErrorToast('You must select a valid Pixel');
						const target = document.querySelector('.pixel');
						target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
						this.isThisStepValid = false;
						return;
					}
				} else {
					this.store.dispatch(new UpdateCampaignInvalidStep(null));
					this.store.dispatch(new UpdateCampaignTemplate());
					this.store.dispatch(new ClearCurrentStep());
				}
			});

			if (this.subObjective === this.subObjectives.CatalogSalesText && this.isThisStepValid === true) {
				this.store.dispatch(new UpdateCampaignInvalidStep(null));
				this.store.dispatch(new UpdateCampaignTemplate());
				this.store.dispatch(new ClearCurrentStep());
			}
		});
	}

	protected getPagesIds(): void {
		this.store.pipe(select(getFacebookPageId), take(1)).subscribe(pageId => {
			this.facebookPageId = pageId;
		});

		this.store.pipe(select(getInstagramPageId), take(1)).subscribe(pageId => {
			this.instagramPageId = pageId;
		});
	}

	protected toggleFormGroupValue(ad: StepThreeDetails): void {
		this.adCreateFormGroup.get('name').patchValue(ad.adName);
		this.pixelFormGroup.get('pixel').patchValue(ad.pixelId);
		this.pixelFormGroup.get('pixelEvent').patchValue(ad.pixelAppEventId);
		this.pixelFormGroup.get('conversion').patchValue('');
	}
	protected listenToSelectedAdForm(): void {
		this.adCreateFormGroup
			.get('name')
			.valueChanges.pipe(takeUntil(this.formUnsubscriber$))
			.subscribe(adName => {
				this.allAds[this.selectedAdIndex].adName = adName;
			});
		this.pixelFormGroup
			.get('pixel')
			.valueChanges.pipe(takeUntil(this.formUnsubscriber$))
			.subscribe(pixelId => {
				this.allAds[this.selectedAdIndex].pixelId = pixelId;
			});
		this.pixelFormGroup
			.get('pixelEvent')
			.valueChanges.pipe(takeUntil(this.formUnsubscriber$))
			.subscribe(pixelEventId => {
				this.allAds[this.selectedAdIndex].pixelAppEventId = pixelEventId;
			});
	}

	protected initFormGroup(): void {
		this.adCreateFormGroup = this.formBuilder.group({
			name: new FormControl(this.allAds[0]?.adName, Validators.required)
		});
		this.pixelFormGroup = this.formBuilder.group({
			pixel: new FormControl(this.currentStep[0]?.pixelId),
			pixelEvent: new FormControl(this.currentStep[0]?.pixelAppEventId),
			conversion: new FormControl('')
		});
		const newAd = new StepThreeDetails();
		newAd.adName = this.allAds[0]?.adName;
		newAd.pixelId = this.currentStep[0]?.pixelId ?? null;
		newAd.pixelAppEventId = this.currentStep[0]?.pixelAppEventId ?? null;
		newAd.adFormatType = this.subObjective === this.subObjectives.ProductCatalogSales ? this.adFormatEnum.catalogueSale : this.adFormatEnum.SingleImage;

		this.selectedAd = newAd;
		this.store.dispatch(new UpdateCurrentAd());
		this.listenToSelectedAdForm();
	}

	protected initFormGroupListeners(): void {
		this.adCreateFormGroup
			.get('name')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(adName => {
				this.label = '';
				if (this.adCreateFormGroup.get('name').valid) {
					this.allAds[0].adName = adName;
					this.store.dispatch(new UpdateAdName(adName));
				}
			});

		this.pixelFormGroup
			.get('pixel')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pixelId => {
				this.pixelId = pixelId;
				this.store.dispatch(new UpdatePixelId(pixelId));
			});

		this.pixelFormGroup
			.get('pixelEvent')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pixelEventId => {
				this.store.dispatch(new UpdatePixelAppEventId(pixelEventId));
			});

		this.adCreateFormGroup.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			setTimeout(() => {
				this.isThisStepValid = this.adCreateFormGroup.valid && this.isAdCreationFormValid && this.pixelFormGroup.valid;
			});
		});
	}

	protected storeListener(): void {
		this.store.pipe(select(getStepTwoDetails), takeUntil(this.unsubscriber$)).subscribe(res => {
			this.productSetId = res?.productSetId;
		});
		this.store.pipe(select(getStepThreeDetails), takeUntil(this.unsubscriber$)).subscribe((ads: StepThreeDetails[]) => {
			this.allAds = ads;
		});

		this.store.pipe(select(getCurrentAdIndex), takeUntil(this.unsubscriber$)).subscribe(ind => {
			this.selectedAdIndex = ind;
		});
		this.store.pipe(select(getCurrentAd), takeUntil(this.unsubscriber$)).subscribe(ad => {
			if (ad) {
				this.selectedAd = ad;
			}
			this.iframeSrc = ad?.iframeUrl;
			this.selectedFormat = this.selectedAd?.adFormatType ?? AdFormatsEnum.SingleImage;
		});
	}

	private convertToFormGroup(data: StepThreeDetails[]): FormGroup[] {
		const formGroup: FormGroup[] = [];
		data.forEach((res: StepThreeDetails) => {
			this.adCreateFormGroup.get('name').patchValue(res.adName);
			formGroup.push(this.adCreateFormGroup);
		});
		return formGroup;
	}

	protected saveStepsToStore(): void {
		if (this.currentStep?.length > 0) {
			this.currentStep.forEach(ad => {
				let x_ad = ad;
				if (this.subObjective === SubObjectives.ProductCatalogSales) {
					x_ad = { ...ad, adFormatType: AdFormatsEnum.catalogueSale };
				}
				this.store.dispatch(new AddNewAd(x_ad));
			});
			this.store.dispatch(new UpdateSelectedAdIndex(0));
		} else {
			const newAd = new StepThreeDetails();
			newAd.adName = '';
			newAd.pixelId = '';
			newAd.pixelAppEventId = '';
			newAd.adFormatType = this.subObjective === this.subObjectives.ProductCatalogSales ? this.adFormatEnum.catalogueSale : null;
			this.store.dispatch(new AddNewAd(newAd));
			this.store.dispatch(new UpdateCurrentAd());
		}
		this.selectAd(0);
	}
}
