<div>
	<div class="container">
		<div class="d-flex align-items-center">
			<ng-container *ngIf="userProfile || userPageStatus">
				<div class="card_image">
					<app-rounded-image-container [src]="getImage()"></app-rounded-image-container>
					<button (click)="openDialog()" *ngIf="editState && !userPageStatus" class="edit-button">
						<i class="fas fa-pen edit-icon"></i>
					</button>
				</div>
			</ng-container>

			<div *ngIf="!!userProfile" class="box-title">
				<h5>
					<b>
						{{ accountForm.get('firstNameControl').value | titlecase | slice: 0:50 }}
						{{ accountForm.get('lastNameControl').value | titlecase | slice: 0:50 }}
					</b>
				</h5>
				<h5 *ngIf="accountForm.get('positionControl').value && accountForm.get('companyNameControl').value">
					{{ accountForm.get('positionControl').value | titlecase | slice: 0:50 }} @
					{{ accountForm.get('companyNameControl').value | titlecase | slice: 0:50 }}
				</h5>
			</div>
		</div>
	</div>
	<hr />
	<form [formGroup]="accountForm" class="form-container">
		<div>
			<div class="filed-input-field">
				<tb02
					[control]="accountForm.get('firstNameControl')"
					[validatorMessages]="accountFirstNameValidatorMessages"
					label="{{ 'ACCOUNT_EDIT_PAGE.FIRST_NAME' | translate }}"
					placeHolderTextBox="{{ 'ACCOUNT_EDIT_PAGE.FIRST_NAME_PLACEHOLDER' | translate }}"
				></tb02>
			</div>
			<div class="filed-input-field">
				<tb02
					[control]="accountForm.get('lastNameControl')"
					[validatorMessages]="accountLastNameValidatorMessages"
					label="{{ 'ACCOUNT_EDIT_PAGE.LAST_NAME' | translate }}"
					placeHolderTextBox="{{ 'ACCOUNT_EDIT_PAGE.LAST_NAME_PLACEHOLDER' | translate }}"
				></tb02>
			</div>
		</div>
		<div>
			<div class="filed-input-field">
				<tb02
					[control]="accountForm.get('emailAddressControl')"
					[validatorMessages]="accountEmailValidatorMessages"
					label="{{ 'ACCOUNT_EDIT_PAGE.EMAIL_ADDRESS' | translate }}"
					placeHolderTextBox="{{ 'ACCOUNT_EDIT_PAGE.EMAIL_ADDRESS_PLACEHOLDER' | translate }}"
				></tb02>
			</div>
			<div class="filed-input-field">
				<tb02
					[control]="accountForm.get('phoneNumberControl')"
					[validatorMessages]="accountPhoneValidatorMessages"
					label="{{ 'ACCOUNT_EDIT_PAGE.PHONE_NUMBER' | translate }}"
					placeHolderTextBox="{{ 'ACCOUNT_EDIT_PAGE.PHONE_NUMBER_PLACEHOLDER' | translate }}"
				></tb02>
			</div>
		</div>
		<div>
			<div class="filed-input-field">
				<tb02
					*appCheckPermissions="businessOwnerPermission"
					[control]="accountForm.get('companyNameControl')"
					[validatorMessages]="accountCompanyValidatorMessages"
					label="{{ 'ACCOUNT_EDIT_PAGE.COMPANY' | translate }}"
					placeHolderTextBox="{{ 'ACCOUNT_EDIT_PAGE.COMPANY_PLACEHOLDER' | translate }}"
				></tb02>
			</div>
			<div class="filed-input-field">
				<tb02
					*appCheckPermissions="businessOwnerPermission"
					[control]="accountForm.get('positionControl')"
					[validatorMessages]="accountPositionValidatorMessages"
					label="{{ 'ACCOUNT_EDIT_PAGE.POSITION' | translate }}"
					placeHolderTextBox="{{ 'ACCOUNT_EDIT_PAGE.POSITION_PLACEHOLDER' | translate }}"
				></tb02>
			</div>
		</div>
	</form>
</div>
