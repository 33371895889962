<form [formGroup]="form" action="#" class="gallery-form">
  <div class="image-header-section">
      <!--      <div fxLayout="col">-->
      <!--        <button mat-icon-button (click)="showFilter = !showFilter">-->
      <!--          <mat-icon>filter_list</mat-icon>-->
      <!--        </button>-->
      <!--        <app-tree-checklist-->
      <!--          *ngIf="showFilter"-->
      <!--          class="dropdown_filters"-->
      <!--          [data]="filters"-->
      <!--        ></app-tree-checklist>-->
      <!--      </div>-->

      <mat-form-field class="search-container">
        <span matPrefix><mat-icon>search</mat-icon>&nbsp;</span>
        <input
            formControlName="search"
            matInput
            placeholder="{{ 'SEARCH' | translate }}"
            type="text"/>
      </mat-form-field>

      <app-button [buttonClass]="buttonClassEnum.Primary"
                  [buttonType]="buttonTypeEnum.Simple"
                  (buttonClicked)="selectItems();"
                  [disabled]="!anySelected"
                  buttonLabel="SELECT">
      </app-button>
      <!--      <button mat-raised-button disabled>{{ "CUSTOMIZE" | translate }}</button>-->

  </div>

  <div class="content">
    <div class="gallery">
      <a (click)="item.isSelected = !item.isSelected" *ngFor="let item of filteredData">
        <svg
            *ngIf="item.isSelected"
            class="checkmark"
            viewBox="0 0 52 52"
            xmlns="http://www.w3.org/2000/svg">
          <circle class="checkmark__circle" cx="26" cy="26" fill="none" r="25"/>
          <path
              class="checkmark__check"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
              fill="none"
          />
        </svg>
        <div *ngIf="item.asset.type==0">
          <img
              [alt]="item.asset.title"
              [ngClass]="{ selected: item.isSelected }"
              [src]="item.asset.url"/>
        </div>
        <div *ngIf="item.asset.type==1">
          <video [ngClass]="{ selected: item.isSelected }" controls preload="metadata">
            <source [src]="item.asset.url">
          </video>
        </div>
      </a>
    </div>
  </div>
</form>
