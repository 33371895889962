<div class="edit-container">
  <div class="wrapper-edit-simple-text" mat-dialog-content>
    <label name="edit-title"> Campaign name </label>
    <input for="edit-title" [formControl]="nameFormControl"/>
  </div>

  <div class="wrapper-edit-simple-buttons" mat-dialog-actions>
    <app-button (buttonClicked)="onNoClick()"[buttonClass]="buttonClassEnum.Secondary"
                [buttonType]="buttonTypeEnum.Simple" buttonLabel="CANCEL">
    </app-button>

    <app-button (buttonClicked)="updateProperty()" [buttonClass]="buttonClassEnum.Primary"
                [buttonType]="buttonTypeEnum.Simple" [disabled]="nameFormControl.invalid" buttonLabel="APPLY">
    </app-button>
  </div>
</div>
