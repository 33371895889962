import { createSelector } from '@ngrx/store';
import { AAAModuleState, selectAAAModuleState } from '..';
import _ from 'lodash';

export const getAdsetState = createSelector(selectAAAModuleState, (state: AAAModuleState) => state.AdsetState);
export const getTargeting = createSelector(getAdsetState, details => _.cloneDeep(details?.targeting));
export const getLocations = createSelector(getAdsetState, details => details?.targeting.locations);
export const getLanguages = createSelector(getAdsetState, details => details?.targeting.languages);
export const getGenders = createSelector(getAdsetState, details => details?.targeting.gender);
export const getFacebookPageId = createSelector(getAdsetState, details => details.facebookPageId);
export const getInstagramPageId = createSelector(getAdsetState, details => details.instagramPageId);
export const getAdsetPlacements = createSelector(getAdsetState, details => details?.placements);
export const getCampaignActiveDate = createSelector(getAdsetState, details => {
	const clone = _.cloneDeep(details);
	return clone?.date ? { date: clone?.date, dateChoiceType: clone?.dateChoice } : null;
});
export const getAdSetOptimization = createSelector(getAdsetState, details => details?.budgetOptimization);
export const getAdSetDevices = createSelector(getAdsetState, details => details?.device);
export const getAdSetDeviceOS = createSelector(getAdsetState, details => details?.deviceOs);
