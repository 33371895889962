import { AxisLabel } from './axis-label';
import { AxisTick } from './axis-tick';
import { AxisLine } from './axis-line';
import { AxisLabelTextStyle } from './axis-label-text-style';
import { SplitLine } from './split-line';

class GraphAxis {
	public axisLabel: AxisLabel;
	public axisLine: AxisLine;
	public axisTick: AxisTick;
	public splitLine: SplitLine;

	constructor(
		public type: string,
		public nameLocation: string,
		color: string,
		showAxisLine: boolean,
		showAxisTick: boolean,
		public nameGap: number,
		public name: string,
		public data: any,
		showSplitLine: boolean
	) {
		this.axisLabel = new AxisLabel(new AxisLabelTextStyle(color));
		this.axisLine = new AxisLine(showAxisLine);
		this.axisTick = new AxisTick(showAxisTick);
		this.splitLine = new SplitLine(showSplitLine);
	}
}

export class ChartXAxis extends GraphAxis {}

export class ChartYAxis extends GraphAxis {}
