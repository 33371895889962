import { AfterViewInit, Component, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { select, Store } from '@ngrx/store';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { EMPTY, Subject } from 'rxjs';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { BillingPlan } from '../../models/billing-plan.interface';
import { UserManagementState } from '../../state/user-management.reducer';
import { TranslateService } from '@ngx-translate/core';
import { SettingsPageLabelsEnum } from '../../shared/enums/navigation.enum';
import { IntegrationInterface } from './models/integration.models';
import { IntegrationHelper } from './services/integration-helper';
import { DropdownListItem } from '@filed-com/filed-lib';
import { ConnectToGoogleComponent } from 'src/app/authentication/connect-to-google/connect-to-google.component';

import { environment } from 'src/environments/environment';
import { GoogleService } from 'src/app/_services/google/google.service';
import { getFiledId, UserState } from 'src/app/shared/state/user/user.reducer';
import { ConnectToGoogleRequest } from 'src/app/authentication/_models/ConnectToGoogleRequest';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
declare var gapi: any;
@Component({
	selector: 'app-integrations',
	templateUrl: './integrations.component.html',
	styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, AfterViewInit, OnChanges {
	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;
	public allIntegrations: IntegrationInterface[] = [];
	public marketingIntegrations: IntegrationInterface[] = [];
	public feedsIntegrations: IntegrationInterface[] = [];
	private unsubscriber$ = new Subject<void>();

	public categoryOptionData: Array<DropdownListItem> = [{ index: 0, text: 'All Integrations' }];
	public drpDownSelectedValue: DropdownListItem = this.categoryOptionData[0];
	private googleButton: Element;
	public searchFormControl: FormGroup;
	private auth2: any;
	public allIntegrationsConnected: IntegrationInterface[];
	constructor(
		private router: Router,
		private toastNotificationService: ToastNotificationService,
		private translate: TranslateService,
		private store: Store<UserManagementState>,
		private storeUser: Store<UserState>,
		private integrationHelper: IntegrationHelper,
		private formBuilder: FormBuilder,
		private googleService?: GoogleService
	) {}

	public ngOnInit(): void {
		this.getIntegrations();
		this.createForm();
	}
	/* private googleInit(): void {
		console.log('this.googleButton');
		gapi.load('auth2', () => {
			this.auth2 = gapi.auth2.init({
				client_id: environment.Google.ClientId,
				cookiepolicy: 'single_host_origin',
				scope: 'https://www.googleapis.com/auth/adwords'
			});

			//this.renderGoogleButton();
			this.googleButton = document.getElementById('google-normal');
			console.log(this.googleButton);
			this.attachSignin(this.googleButton);
		});
	} */
	private async createGoogleConnectionRequest(code: string): Promise<ConnectToGoogleRequest> {
		const currentUserProfile = this.auth2.currentUser.get();
		return {
			code: code,
			email: currentUserProfile.getBasicProfile().getEmail(),
			userId: await this.storeUser.pipe(select(getFiledId), take(1)).toPromise()
		};
	}
	/* public attachSignin(element: Element): void {
		console.log(element);
		this.auth2.attachClickHandler(
			element,
			{},
			googleUser => {
				if (googleUser?.isSignedIn()) {
					this.auth2.grantOfflineAccess().then((resp: any) => {
						console.log(resp['code']);
						this.createGoogleConnectionRequest(resp['code']).then(request => {
							this.googleService
								.connectToGoogleAccount(request)
								.pipe(take(1))
								.subscribe(
									() => {
										console.log('complete');
										this.auth2.signOut();
									},
									() => {
										this.auth2.signOut();
									}
								);
						});
					});
				}
			},
			error => {
				console.log(error);
				// alert(JSON.stringify(error, undefined, 2));
			}
		);
	} */
	ngOnChanges(): void {}
	private createForm(): void {
		this.searchFormControl = this.formBuilder.group({
			searchIntegrationForm: new FormControl(null)
		});
	}
	public ngAfterViewInit(): void {
		//
		//this.googleInit();
	}
	public getIntegrations(): void {
		this.store.dispatch(new ShowGlobalSpinner());
		this.integrationHelper
			.getAllIntegrationStatuses()
			.pipe(
				takeUntil(this.unsubscriber$),
				catchError(err => {
					this.store.dispatch(new HideGlobalSpinner());
					return EMPTY;
				})
			)
			.subscribe(value => {
				if (value) {
					value.forEach((item, i) => {
						if (item.source === 'facebook-normal' || item.source === 'google-normal') {
							this.marketingIntegrations.push(item);
							value.splice(i, 1);
						}
					});
					this.feedsIntegrations = value;
					this.allIntegrations = value.concat(this.marketingIntegrations);
					this.allIntegrationsConnected = this.allIntegrations.filter(item => {
						return item.status === true;
					});
				}
				this.store.dispatch(new HideGlobalSpinner());
			});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
