import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, RouterEvent, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CampaignSmartCreateState, getCampaign, isCampaignUpdating } from '../state/smart-create.reducer';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { StepThreeDetails } from '../models/step-three-details';
import { CampaignTemplate } from '../models/campaign-template';

@Injectable({
	providedIn: 'root'
})
export class StepFourGuard implements CanActivate {
	constructor(public store: Store<CampaignSmartCreateState>, public activateRouter: ActivatedRoute, public toasterService: ToastNotificationService) {}
	public canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.store.pipe(
			select(getCampaign),
			take(1),
			map(campaign => {
				if (!campaign) {
					return true;
				} else if (this.checkStepThree(campaign?.stepThreeDetailsAsJson)) {
					this.toasterService.sendErrorToast('Step Three is invalid/incomplete!');
					return false;
				} else if (!campaign?.stepOneDetailsAsJson?.campaignBudgetOptimization?.amount && !campaign?.stepTwoDetailsAsJson?.budgetOptimization?.amount) {
					this.toasterService.sendErrorToast('You need to select a budget!');
					return false;
				}
				return true;
			})
		);
	}

	public checkStepThree(campaign: StepThreeDetails[]): boolean {
		let stepIsInvalid = false;
		campaign.forEach(data => {
			if (!data?.adverts) {
				stepIsInvalid = true;
			}
		});
		return stepIsInvalid;
	}
}
