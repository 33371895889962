import { Bits } from './bits';

export enum CreativeBuilderPermissions {
	CanAccessCreativeBuilder = Bits.Bit1,
	CBBrandGuardianCreate = Bits.Bit2,
	CBBrandGuardianEdit = Bits.Bit3,
	CBCreateNewCreate = Bits.Bit4,
	CBCreateNewEdit = Bits.Bit5,
	CBMyLibraryDelete = Bits.Bit6,
	CBMyLibraryEdit = Bits.Bit7,
	CBMyLibraryView = Bits.Bit8,
	CBView = Bits.Bit9
}
