import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { BudgetAllocationInterface } from '../../models/budget-allocation.interface';
import { CampaignSmartCreateState } from '../../state/smart-create.reducer';
import { Store } from '@ngrx/store';
import { UpdateBudgetAllocation, UpdateBudgetToggle } from '../../state/smart-create.actions';
import { ValidatorMessages } from '../../../shared/form-input/validatorMessagesInputs';

@Component({
	selector: 'app-optimization-budget-card',
	templateUrl: './optimization-budget-card.component.html',
	styleUrls: ['./optimization-budget-card.component.scss']
})
export class OptimizationBudgetCardComponent implements OnInit {
	@Input() public isSelected: boolean;
	@Input() public isBudgetSplitEvenly: boolean;
	@Input() public currency: string;
	@Input() public label: string;
	@Input() public budgeAllocationSplits: BudgetAllocationInterface;
	@Input() public control: FormControl | AbstractControl;
	@Input() public adSetsControl: FormArray | AbstractControl;
	@Input() public campaignControl: FormArray | AbstractControl;
	@Input() public minBudget: number;
	@Output() public onExpansionClick = new EventEmitter();

	public validationMessage: ValidatorMessages[];
	private unsubscriber$ = new Subject<void>();

	constructor(private store: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {}

	public ngOnChanges(changes: SimpleChanges): void {
		this.validationMessage = [
			{
				validationType: 'required',
				validationMessage: 'This field is mandatory'
			},
			{
				validationType: 'min',
				validationMessage: `${'Minimum budget is ' + this.minBudget + ' ' + this.currency}`
			}
		];
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onBudgetToggle(): void {
		this.isBudgetSplitEvenly = !this.isBudgetSplitEvenly;
		this.store.dispatch(new UpdateBudgetToggle(this.isBudgetSplitEvenly));
		if (this.isBudgetSplitEvenly) {
			this.store.dispatch(new UpdateBudgetAllocation(null));
		}
	}
}
