<div mat-dialog-title>
	<div (click)="close()" class="x">
		<img alt="close" src="assets/icons/user-management/account/x.png">
	</div>
	<div class="upload">
		<img alt="dialog" src="assets/icons/user-management/account/warning.png">
	</div>
	<h4>{{ 'CONFIRM_PERMISSIONS' | translate}}</h4>
</div>
<div mat-dialog-content>
	<p class="popup-text">{{ 'QUESTION_PERMISSIONS' | translate}}</p>
</div>


<div class="buttons">
	<app-button (buttonClicked)="close()"
				[buttonClass]="buttonClassEnum.Secondary"
				[buttonType]="buttonTypeEnum.Simple"
				buttonLabel="CANCEL">
	</app-button>

	<app-button (buttonClicked)="close(true)"
				[buttonClass]="buttonClassEnum.Tertiary"
				[buttonType]="buttonTypeEnum.Simple"
				buttonLabel="YES">
	</app-button>
</div>
