import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-save-table-column-view',
	templateUrl: './save-table-column-view.component.html',
	styleUrls: ['./save-table-column-view.component.scss']
})
export class SaveTableColumnViewComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<SaveTableColumnViewComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

	ngOnInit() {}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
