import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageKey } from '../../_models/local-storage-key';

@Injectable({
	providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
	private readonly AUTH_TYPE = 'Bearer';

	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = localStorage.getItem(StorageKey.token);

		if (token) {
			request = request.clone({ headers: request.headers.set('Authorization', `${this.AUTH_TYPE} ${token}`) });
		}
		return next.handle(request);
	}
}
