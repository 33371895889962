<div mat-dialog-title>
	<div class="x" matDialogClose>
		<img alt="close" src="assets/icons/user-management/account/x.png">
	</div>
	<div class="upload">
		<img alt="dialog" src="assets/icons/user-management/account/circle-x.png">
	</div>
	<div class="text">
		<h5>In order to cancel your account you need to verify you identity, provide us with the phone number that
			corresponds to the owner of the account and we will get in contact.</h5>
		<h6>Enter your phone number:</h6>
		<div class="phone">
			<app-input-text
				[control]="phoneNumber"
				[validatorMessages]="subscriptionPhoneValidatorMessages"
				label="{{'ACCOUNT_EDIT_PAGE.PHONE_NUMBER' | translate}}"
				placeholder="{{'ACCOUNT_EDIT_PAGE.PHONE_NUMBER_PLACEHOLDER' | translate}}">
			</app-input-text>
		</div>
	</div>
</div>

<div class="buttons">
	<app-button (buttonClicked)="cancel()"
				[buttonClass]="buttonClassEnum.Secondary"
				[buttonType]="buttonTypeEnum.Simple"
				buttonLabel="CANCEL">
	</app-button>
	<app-button (buttonClicked)="sendEmail()"
				[buttonClass]="buttonClassEnum.Primary"
				[buttonType]="buttonTypeEnum.Simple"
				[disabled]="phoneNumber.invalid"
				buttonLabel="YES">
	</app-button>
</div>

