<div class="preview-container" [style.height]="returnHeight()" [style.width]="returnWidth()">
	<div
		class="ac-preview-container"
		id="ac-preview-container-id"
		[ngClass]="{
			stickyForPost: isSticky && adFormat === adFormatEnum.PagePosts,
			stickyForNonPost: isSticky && adFormat != adFormatEnum.PagePosts,
			postPreview: adFormat === adFormatEnum.PagePosts,
			nonPostPreview: adFormat != adFormatEnum.PagePosts
		}"
	>
		<div class="ac-align-dropdown">
			<div class="ac-dropdown-width">
				<app-dropdown-search-select
					[dropdownData]="dropdownPreviewPlacement"
					[dropdownFormControl]="adCreateFormGroup.get('preview')"
					[selectedValue]="adCreateFormGroup.get('preview').value"
					[searchEnable]="true"
					(selectionChange)="setPreviewWidthAndHeight()"
				></app-dropdown-search-select>
			</div>
		</div>
		<div class="ac-ad-preview-box">
			<div *ngIf="!iframeSrc" class="camera-off">
				<img alt="cameraOff" src="/assets/icons/cameraOff.svg" />
			</div>
			<div
				*ngIf="iframeSrc"
				class="iFrame-wrapper"
				[ngClass]="{
					'ac-iframe-box-facebook-desktop-feed': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed,
					'small-image': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight < 570,
					'small-image-two-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 570,
					'small-image-three-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 590,
					'small-image-four-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 610,
					'small-image-five-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 630,
					'small-image-six-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 650,
					'small-image-seven-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 670,
					'small-image-eight-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 690,
					'small-image-nine-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 710,
					'one-row':
						adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight < 730 && this.previewHeight > 710,
					'two-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 730,
					'three-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 750,
					'four-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 770,
					'five-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 790,
					'six-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 810,
					'seven-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 830,
					'eight-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 850,
					'nine-rows': adCreateFormGroup.get('preview').value === devicePlacement.FacebookDesktopFeed && this.previewHeight === 870,
					'ac-iframe-box-facebook-mobile-news-feed': adCreateFormGroup.get('preview').value === devicePlacement.FacebookMobileFeed,
					'ac-iframe-box-facebook-story': adCreateFormGroup.get('preview').value === devicePlacement.FacebookStory,
					'ac-iframe-box-instagram-story': adCreateFormGroup.get('preview').value === devicePlacement.InstagramStory,
					'ac-iframe-box-instagram-standard': adCreateFormGroup.get('preview').value === devicePlacement.InstagramStandard,
					'ac-iframe-box-mobile-native': adCreateFormGroup.get('preview').value === devicePlacement.MobileNative,
					'ac-iframe-box-mobile-banner': adCreateFormGroup.get('preview').value === devicePlacement.MobileBanner,
					'ac-iframe-box-mobile-interstitial': adCreateFormGroup.get('preview').value === devicePlacement.MobileInterstitial,
					'ac-iframe-box-mobile-rectangle': adCreateFormGroup.get('preview').value === devicePlacement.MobileRectangle
				}"
				[innerHTML]="iframeSrc | sanitizeHtml"
			></div>
		</div>
	</div>
</div>
