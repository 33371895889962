import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {
	@Output() onFileDropped = new EventEmitter<FileList>();

	@HostBinding('style.background-color')
	private background = '#fffff';
	@HostBinding('style.opacity')
	private opacity = '1';

	//Dragover listener
	@HostListener('dragover', ['$event'])
	private onDragOver(evt: DragEvent): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#9ecbec';
		this.opacity = '0.8';
	}

	//Dragleave listener
	@HostListener('dragleave', ['$event'])
	public onDragLeave(evt: DragEvent): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#ffffff';
		this.opacity = '1';
	}

	//Drop listener
	@HostListener('drop', ['$event'])
	public ondrop(evt: DragEvent): void {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#ffffff';
		this.opacity = '1';
		let files = evt.dataTransfer.files;
		if (files.length > 0) {
			this.onFileDropped.emit(files);
		}
	}
}
