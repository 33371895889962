<div class="body">
	<div class="form-search">
		<div class="blockTitle">
			<div class="title">Account Activity</div>
		</div>
		<div class="form"></div>
	</div>
	<div class="datatable">
		<table>
			<thead>
				<tr>
					<th *ngFor="let column of columnDef">{{ column.label }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of filteredRowData">
					<td>{{ timeSince(row?.date) }}</td>
					<td class="name">{{ row?.user }}</td>
					<td>{{ row?.role ? row?.role : '--' }}</td>
					<td>{{ row?.event }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="paginate">
		<app-datatable-pagination [totalPage]="userActivities.length" (eventClick)="onPageChanged($event)"></app-datatable-pagination>
	</div>
</div>
<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
