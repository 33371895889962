import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { act } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HideGlobalSpinner, ShowGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { NewUserModel } from 'src/app/user-management/models/new-user-model';
import { SetAdminManagement } from 'src/app/user-management/state/user-management.actions';
import { getUserAdminState, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { BackOfficeService } from 'src/app/_services/back-office/back-office.service';
import { UserServiceApi } from 'src/app/_services/user/user.api.service';

@Component({
	selector: 'app-user-permission',
	templateUrl: './user-permission.component.html',
	styleUrls: ['./user-permission.component.scss']
})
export class UserPermissionComponent implements OnInit {
	@Input() public permissions: any[];
	@Output() public onCcreateUser: EventEmitter<boolean> = new EventEmitter();
	@Output() public onGoBack: EventEmitter<boolean> = new EventEmitter();

	private unsubscriber$ = new Subject<void>();

	public user: NewUserModel;
	public isEdit: boolean;
	public isViewOnly: boolean;
	public isFull: boolean;

	public userDetail: any;
	public userPermissions: any[];

	constructor(
		private userServiceApi: UserServiceApi,
		private toastNotification: ToastNotificationService,
		private store: Store<UserManagementState>,
		private toaster: ToastNotificationService,
		public backOfficeService: BackOfficeService
	) {}

	ngOnInit(): void {
		this.fetchStore();
	}
	ngOnChanges(): void {
		this.userPermissions = this.permissions;
	}

	public onCheckToggleEdit(checked: boolean) {
		this.userPermissions.forEach(t => (t.isEdit.status = checked));
	}
	public onCheckToggleView(checked: boolean) {
		this.userPermissions.forEach(t => (t.isViewOnly.status = checked));
	}
	public onCheckToggleAccess(checked: boolean) {
		this.userPermissions.forEach(t => (t.isAccessBoth.status = checked));
	}

	public checkItem(event: boolean, row: number, action: string) {
		if (action === 'isAccessBoth') {
			this.userPermissions[row]['isEdit']['status'] = event;
			this.userPermissions[row]['isViewOnly']['status'] = event;
		} else if (action === 'isEdit') {
			this.userPermissions[row]['isAccessBoth']['status'] = event;
		} else {
			if (!event) {
				this.userPermissions[row]['isEdit']['status'] = event;
			}
			this.userPermissions[row]['isAccessBoth']['status'] = event;
		}
		this.userPermissions[row][action]['status'] = event;
	}

	private fetchStore(): void {
		this.store.pipe(select(getUserAdminState), takeUntil(this.unsubscriber$)).subscribe(detail => {
			if (detail) {
				this.userDetail = detail;
				this.isEdit = this.userDetail?.user.roles === 'Standard' ? true : false;
				this.isViewOnly = true;
				this.isFull = this.userDetail?.user.roles === 'Standard' ? true : false;
				if (this.userDetail.permissions.length > 0) {
					this.userPermissions = this.userDetail.permissions;
				}
			}
		});
	}

	public onContinueNext() {
		const payload: any = {
			user: this.userDetail.user,
			accounts: this.userDetail.accounts,
			permissions: this.userPermissions
		};
		this.store.dispatch(new SetAdminManagement(payload));
		this.onCcreateUser.emit();
	}
	public goBacks(): void {
		this.onGoBack.emit();
	}
}
