import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { interval, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';

@Component({
	selector: 'app-knowledge-frame',
	templateUrl: './knowledge-frame.component.html',
	styleUrls: ['./knowledge-frame.component.scss']
})
export class KnowledgeFrameComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
	@ViewChild('frameparent') public iFrame: ElementRef;

	public unsubscriber$ = new Subject<void | boolean>();

	public kBaseIFrame = '';
	public articleName = '';
	public heightSet = false;
	constructor(public router: Router, public kbService: KnowledgebaseService) {}

	ngOnInit(): void {
		const articleIndex = this.kbService.frameIndexValue$.value;
		let kBaseUrl = '';
		if (this.kbService.searchDataValue$.value.length > 0) {
			kBaseUrl = this.kbService.searchDataValue$.value[articleIndex].url;
			this.articleName = `${this.kbService.searchDataValue$.value[articleIndex].category} > ${this.kbService.searchDataValue$.value[articleIndex].subcategory}`;
		} else {
			kBaseUrl = this.kbService.articleDataValue$.value[articleIndex].url;
			const subCategory = this.kbService.articleDataValue$.value[articleIndex].subcategory
				? this.kbService.articleDataValue$.value[articleIndex].subcategory
				: this.kbService.searchValue$.value.charAt(0).toUpperCase() + this.kbService.searchValue$.value.slice(1).toLowerCase() + ' articles';
			this.articleName = `${this.kbService.articleDataValue$.value[articleIndex].category} > ${subCategory}`;
		}
		this.kBaseIFrame = `<iframe #iframe class="i-frame" width="480px" height="100%" frameborder="0" src="${kBaseUrl}"></iframe>`;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
