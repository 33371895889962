import { Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from './permissions.service';
import { select, Store } from '@ngrx/store';
import { getPermissions } from '../state/user/user.reducer';
import { first } from 'rxjs/operators';
import { User } from '../../_models/user-models/user';
import { PermissionsDirectiveInterface } from './models/permissions-directive.interface';
import { Modules } from './enums/modules';
import { ModulesPermissions } from './enums/filed-permissions';

@Directive({
	selector: '[appCheckPermissions]'
})
export class CheckPermissionsDirective implements OnChanges, OnInit {
	private moduleName: Modules;
	private permissions: ModulesPermissions[];

	@Input()
	set appCheckPermissions(permissionsDirectiveInterface: PermissionsDirectiveInterface) {
		this.moduleName = permissionsDirectiveInterface.moduleName;
		this.permissions = permissionsDirectiveInterface.permissions;
	}

	constructor(
		private store: Store<User>,
		private permissionsService: PermissionsService,
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}

	ngOnInit() {}

	ngOnChanges() {
		this.viewContainer.clear();

		if (this.moduleName && this.permissions) {
			this.store
				.pipe(
					select(getPermissions),
					first(data => !!data?.length)
				)
				.subscribe(data => {
					const allow = this.permissionsService.checkPermission(data, this.moduleName, this.permissions);
					if (allow) {
						this.viewContainer.createEmbeddedView(this.templateRef);
					} else {
						this.viewContainer.clear();
					}
				});
		}
	}
}
