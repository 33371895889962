export const themesModel = {
	default: {
		color: ['#38CCF6', '#008CC9', '#707070', '#BBA1FF', '#32C585', '#F6DB38', '#F9A75B', '#FFA1CA', '#FB6767', '#4FFF69'],
		backgroundColor: 'rgba(252,252,252,0)',
		textStyle: {},
		title: {
			textStyle: {
				color: '#666666'
			},
			subtextStyle: {
				color: '#999999'
			}
		},
		line: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '3'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false
		},
		radar: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '3'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false
		},
		bar: {
			itemStyle: {
				normal: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				},
				emphasis: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				}
			}
		},
		pie: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		scatter: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		boxplot: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		parallel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		sankey: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		funnel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		gauge: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		candlestick: {
			itemStyle: {
				normal: {
					color: '#e6a0d2',
					color0: 'transparent',
					borderColor: '#e6a0d2',
					borderColor0: '#3fb1e3',
					borderWidth: '2'
				}
			}
		},
		graph: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			},
			lineStyle: {
				normal: {
					width: '1',
					color: '#cccccc'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false,
			color: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		},
		map: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#aaaaaa',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(63,177,227,0.25)',
					borderColor: '#3fb1e3',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(63,177,227)'
					}
				}
			}
		},
		geo: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#aaaaaa',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(63,177,227,0.25)',
					borderColor: '#3fb1e3',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(63,177,227)'
					}
				}
			}
		},
		categoryAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		valueAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		logAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		timeAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		toolbox: {
			iconStyle: {
				normal: {
					borderColor: '#999999'
				},
				emphasis: {
					borderColor: '#666666'
				}
			}
		},
		legend: {
			textStyle: {
				color: '#999999',
				padding: [4, 0, 0, 0]
			}
		},
		tooltip: {
			axisPointer: {
				lineStyle: {
					color: '#cccccc',
					width: 1
				},
				crossStyle: {
					color: '#cccccc',
					width: 1
				}
			}
		},
		timeline: {
			lineStyle: {
				color: '#626c91',
				width: 1
			},
			itemStyle: {
				normal: {
					color: '#626c91',
					borderWidth: 1
				},
				emphasis: {
					color: '#626c91'
				}
			},
			controlStyle: {
				normal: {
					color: '#626c91',
					borderColor: '#626c91',
					borderWidth: 0.5
				},
				emphasis: {
					color: '#626c91',
					borderColor: '#626c91',
					borderWidth: 0.5
				}
			},
			checkpointStyle: {
				color: '#3fb1e3',
				borderColor: 'rgba(63,177,227,0.15)'
			},
			label: {
				normal: {
					textStyle: {
						color: '#626c91'
					}
				},
				emphasis: {
					textStyle: {
						color: '#626c91'
					}
				}
			}
		},
		visualMap: {
			color: ['#2a99c9', '#afe8ff']
		},
		dataZoom: {
			backgroundColor: 'rgba(255,255,255,0)',
			dataBackgroundColor: 'rgba(222,222,222,1)',
			fillerColor: 'rgba(114,230,212,0.25)',
			handleColor: '#cccccc',
			handleSize: '100%',
			textStyle: {
				color: '#999999'
			}
		},
		markPoint: {
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		}
	},
	coconut: {
		color: [
			'#FFA600',
			'#A05195',
			'#00FF6F',
			'#00C358',
			'#665191',
			'#00ABFF',
			'#2F4B7C',
			'#2F4B7C',
			'#8BFCFF',
			'#00C4FF',
			'#00D9FF',
			'#A331E5',
			'#ACACAC',
			'#008EFF',
			'#696AFF',
			'#F95D6A',
			'#D45087',
			'#FF7C43',
			'#37EBFF',
			'#008940',
			'#043B1C'
		],
		backgroundColor: 'rgba(252,252,252,0)',
		textStyle: {},
		title: {
			textStyle: {
				color: '#666666'
			},
			subtextStyle: {
				color: '#999999'
			}
		},
		line: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '3'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false
		},
		radar: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '3'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false
		},
		bar: {
			itemStyle: {
				normal: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				},
				emphasis: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				}
			}
		},
		pie: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		scatter: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		boxplot: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		parallel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		sankey: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		funnel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		gauge: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		candlestick: {
			itemStyle: {
				normal: {
					color: '#e6a0d2',
					color0: 'transparent',
					borderColor: '#e6a0d2',
					borderColor0: '#3fb1e3',
					borderWidth: '2'
				}
			}
		},
		graph: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			},
			lineStyle: {
				normal: {
					width: '1',
					color: '#cccccc'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false,
			color: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		},
		map: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#aaaaaa',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(63,177,227,0.25)',
					borderColor: '#3fb1e3',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(63,177,227)'
					}
				}
			}
		},
		geo: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#aaaaaa',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(63,177,227,0.25)',
					borderColor: '#3fb1e3',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(63,177,227)'
					}
				}
			}
		},
		categoryAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		valueAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		logAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		timeAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		toolbox: {
			iconStyle: {
				normal: {
					borderColor: '#999999'
				},
				emphasis: {
					borderColor: '#666666'
				}
			}
		},
		legend: {
			textStyle: {
				color: '#999999'
			}
		},
		tooltip: {
			axisPointer: {
				lineStyle: {
					color: '#cccccc',
					width: 1
				},
				crossStyle: {
					color: '#cccccc',
					width: 1
				}
			}
		},
		timeline: {
			lineStyle: {
				color: '#626c91',
				width: 1
			},
			itemStyle: {
				normal: {
					color: '#626c91',
					borderWidth: 1
				},
				emphasis: {
					color: '#626c91'
				}
			},
			controlStyle: {
				normal: {
					color: '#626c91',
					borderColor: '#626c91',
					borderWidth: 0.5
				},
				emphasis: {
					color: '#626c91',
					borderColor: '#626c91',
					borderWidth: 0.5
				}
			},
			checkpointStyle: {
				color: '#3fb1e3',
				borderColor: 'rgba(63,177,227,0.15)'
			},
			label: {
				normal: {
					textStyle: {
						color: '#626c91'
					}
				},
				emphasis: {
					textStyle: {
						color: '#626c91'
					}
				}
			}
		},
		visualMap: {
			color: ['#2a99c9', '#afe8ff']
		},
		dataZoom: {
			backgroundColor: 'rgba(255,255,255,0)',
			dataBackgroundColor: 'rgba(222,222,222,1)',
			fillerColor: 'rgba(114,230,212,0.25)',
			handleColor: '#cccccc',
			handleSize: '100%',
			textStyle: {
				color: '#999999'
			}
		},
		markPoint: {
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		}
	},
	lollipop: {
		color: [
			'#c23531',
			'#2f4554',
			'#61a0a8',
			'#d48265',
			'#91c7ae',
			'#749f83',
			'#8BFCFF',
			'#6AEFF2',
			'#09D8DE',
			'#00AEB3',
			'#0D898D',
			'#0B6365',
			'#C2F2FF',
			'#40D5FF',
			'#1FC8F8',
			'#26B0EC',
			'#0F91C9',
			'#066087',
			'#F2E5F9',
			'#E8C6FC',
			'#DEA5FF',
			'#BE63F2',
			'#A331E5',
			'#7727A5',
			'#7727A5',
			'#3E0360',
			'#ACACAC',
			'#FED692',
			'#FDA91A',
			'#C9891B',
			'#5A3E0D',
			'#ACACFF',
			'#0000FF',
			'#070792',
			'#00003A',
			''
		],
		backgroundColor: 'rgba(252,252,252,0)',
		textStyle: {},
		title: {
			textStyle: {
				color: '#333333'
			},
			subtextStyle: {
				color: '#aaaaaa'
			}
		},
		line: {
			itemStyle: {
				normal: {
					borderWidth: 1
				}
			},
			lineStyle: {
				normal: {
					width: 2
				}
			},
			symbolSize: 4,
			symbol: 'emptyCircle',
			smooth: false
		},
		radar: {
			itemStyle: {
				normal: {
					borderWidth: 1
				}
			},
			lineStyle: {
				normal: {
					width: 2
				}
			},
			symbolSize: 4,
			symbol: 'emptyCircle',
			smooth: false
		},
		bar: {
			itemStyle: {
				normal: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				},
				emphasis: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				}
			}
		},
		pie: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		scatter: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		boxplot: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		parallel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		sankey: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		funnel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		gauge: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		candlestick: {
			itemStyle: {
				normal: {
					color: '#c23531',
					color0: '#314656',
					borderColor: '#c23531',
					borderColor0: '#314656',
					borderWidth: 1
				}
			}
		},
		graph: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			},
			lineStyle: {
				normal: {
					width: 1,
					color: '#aaaaaa'
				}
			},
			symbolSize: 4,
			symbol: 'emptyCircle',
			smooth: false,
			color: ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'],
			label: {
				normal: {
					textStyle: {
						color: '#eeeeee'
					}
				}
			}
		},
		map: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#444444',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(255,215,0,0.8)',
					borderColor: '#444444',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#000000'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(100,0,0)'
					}
				}
			}
		},
		geo: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#444444',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(255,215,0,0.8)',
					borderColor: '#444444',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#000000'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(100,0,0)'
					}
				}
			}
		},
		categoryAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: false,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		valueAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		logAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		timeAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		toolbox: {
			iconStyle: {
				normal: {
					borderColor: '#999999'
				},
				emphasis: {
					borderColor: '#666666'
				}
			}
		},
		legend: {
			textStyle: {
				color: '#333333'
			}
		},
		tooltip: {
			axisPointer: {
				lineStyle: {
					color: '#cccccc',
					width: 1
				},
				crossStyle: {
					color: '#cccccc',
					width: 1
				}
			}
		},
		timeline: {
			lineStyle: {
				color: '#293c55',
				width: 1
			},
			itemStyle: {
				normal: {
					color: '#293c55',
					borderWidth: 1
				},
				emphasis: {
					color: '#a9334c'
				}
			},
			controlStyle: {
				normal: {
					color: '#293c55',
					borderColor: '#293c55',
					borderWidth: 0.5
				},
				emphasis: {
					color: '#293c55',
					borderColor: '#293c55',
					borderWidth: 0.5
				}
			},
			checkpointStyle: {
				color: '#e43c59',
				borderColor: 'rgba(194,53,49,0.5)'
			},
			label: {
				normal: {
					textStyle: {
						color: '#293c55'
					}
				},
				emphasis: {
					textStyle: {
						color: '#293c55'
					}
				}
			}
		},
		visualMap: {
			color: ['#bf444c', '#d88273', '#f6efa6']
		},
		dataZoom: {
			backgroundColor: 'rgba(47,69,84,0)',
			dataBackgroundColor: 'rgba(47,69,84,0.3)',
			fillerColor: 'rgba(167,183,204,0.4)',
			handleColor: '#a7b7cc',
			handleSize: '100%',
			textStyle: {
				color: '#333333'
			}
		},
		markPoint: {
			label: {
				normal: {
					textStyle: {
						color: '#eeeeee'
					}
				},
				emphasis: {
					textStyle: {
						color: '#eeeeee'
					}
				}
			}
		}
	},
	walden: {
		color: ['#008CC9', '#00AEB3', '#38CCF6', '#81D2D4', '#09D8DE', '#52F5F9'],
		backgroundColor: 'rgba(252,252,252,0)',
		textStyle: {},
		title: {
			textStyle: {
				color: '#666666'
			},
			subtextStyle: {
				color: '#999999'
			}
		},
		line: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '3'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false
		},
		radar: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '3'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false
		},
		bar: {
			itemStyle: {
				normal: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				},
				emphasis: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				}
			}
		},
		pie: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		scatter: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		boxplot: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		parallel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		sankey: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		funnel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		gauge: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		candlestick: {
			itemStyle: {
				normal: {
					color: '#e6a0d2',
					color0: 'transparent',
					borderColor: '#e6a0d2',
					borderColor0: '#3fb1e3',
					borderWidth: '2'
				}
			}
		},
		graph: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			},
			lineStyle: {
				normal: {
					width: '1',
					color: '#cccccc'
				}
			},
			symbolSize: '8',
			symbol: 'emptyCircle',
			smooth: false,
			color: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		},
		map: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#aaaaaa',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(63,177,227,0.25)',
					borderColor: '#3fb1e3',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(63,177,227)'
					}
				}
			}
		},
		geo: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#aaaaaa',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(63,177,227,0.25)',
					borderColor: '#3fb1e3',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(63,177,227)'
					}
				}
			}
		},
		categoryAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		valueAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		logAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		timeAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#cccccc'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eeeeee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		toolbox: {
			iconStyle: {
				normal: {
					borderColor: '#999999'
				},
				emphasis: {
					borderColor: '#666666'
				}
			}
		},
		legend: {
			textStyle: {
				color: '#999999'
			}
		},
		tooltip: {
			axisPointer: {
				lineStyle: {
					color: '#cccccc',
					width: 1
				},
				crossStyle: {
					color: '#cccccc',
					width: 1
				}
			}
		},
		timeline: {
			lineStyle: {
				color: '#626c91',
				width: 1
			},
			itemStyle: {
				normal: {
					color: '#626c91',
					borderWidth: 1
				},
				emphasis: {
					color: '#626c91'
				}
			},
			controlStyle: {
				normal: {
					color: '#626c91',
					borderColor: '#626c91',
					borderWidth: 0.5
				},
				emphasis: {
					color: '#626c91',
					borderColor: '#626c91',
					borderWidth: 0.5
				}
			},
			checkpointStyle: {
				color: '#3fb1e3',
				borderColor: 'rgba(63,177,227,0.15)'
			},
			label: {
				normal: {
					textStyle: {
						color: '#626c91'
					}
				},
				emphasis: {
					textStyle: {
						color: '#626c91'
					}
				}
			}
		},
		visualMap: {
			color: ['#2a99c9', '#afe8ff']
		},
		dataZoom: {
			backgroundColor: 'rgba(255,255,255,0)',
			dataBackgroundColor: 'rgba(222,222,222,1)',
			fillerColor: 'rgba(114,230,212,0.25)',
			handleColor: '#cccccc',
			handleSize: '100%',
			textStyle: {
				color: '#999999'
			}
		},
		markPoint: {
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		}
	},
	macarons: {
		color: [
			'#2ec7c9',
			'#b6a2de',
			'#5ab1ef',
			'#ffb980',
			'#d87a80',
			'#8d98b3',
			'#e5cf0d',
			'#97b552',
			'#95706d',
			'#dc69aa',
			'#07a2a4',
			'#9a7fd1',
			'#588dd5',
			'#f5994e',
			'#c05050',
			'#59678c',
			'#c9ab00',
			'#7eb00a',
			'#6f5553',
			'#c14089'
		],
		backgroundColor: 'rgba(252,252,252,0)',
		textStyle: {},
		title: {
			textStyle: {
				color: '#008acd'
			},
			subtextStyle: {
				color: '#aaaaaa'
			}
		},
		line: {
			itemStyle: {
				normal: {
					borderWidth: 1
				}
			},
			lineStyle: {
				normal: {
					width: 2
				}
			},
			symbolSize: 3,
			symbol: 'emptyCircle',
			smooth: true
		},
		radar: {
			itemStyle: {
				normal: {
					borderWidth: 1
				}
			},
			lineStyle: {
				normal: {
					width: 2
				}
			},
			symbolSize: 3,
			symbol: 'emptyCircle',
			smooth: true
		},
		bar: {
			itemStyle: {
				normal: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				},
				emphasis: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				}
			}
		},
		pie: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		scatter: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		boxplot: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		parallel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		sankey: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		funnel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		gauge: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		candlestick: {
			itemStyle: {
				normal: {
					color: '#d87a80',
					color0: '#2ec7c9',
					borderColor: '#d87a80',
					borderColor0: '#2ec7c9',
					borderWidth: 1
				}
			}
		},
		graph: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			},
			lineStyle: {
				normal: {
					width: 1,
					color: '#aaa'
				}
			},
			symbolSize: 3,
			symbol: 'emptyCircle',
			smooth: true,
			color: [
				'#2ec7c9',
				'#b6a2de',
				'#5ab1ef',
				'#ffb980',
				'#d87a80',
				'#8d98b3',
				'#e5cf0d',
				'#97b552',
				'#95706d',
				'#dc69aa',
				'#07a2a4',
				'#9a7fd1',
				'#588dd5',
				'#f5994e',
				'#c05050',
				'#59678c',
				'#c9ab00',
				'#7eb00a',
				'#6f5553',
				'#c14089'
			],
			label: {
				normal: {
					textStyle: {
						color: '#eee'
					}
				}
			}
		},
		map: {
			itemStyle: {
				normal: {
					areaColor: '#dddddd',
					borderColor: '#eeeeee',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(254,153,78,1)',
					borderColor: '#444444',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#d87a80'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(100,0,0)'
					}
				}
			}
		},
		geo: {
			itemStyle: {
				normal: {
					areaColor: '#dddddd',
					borderColor: '#eeeeee',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(254,153,78,1)',
					borderColor: '#444444',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#d87a80'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(100,0,0)'
					}
				}
			}
		},
		categoryAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#008acd'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: false,
				lineStyle: {
					color: ['#eee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		valueAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#008acd'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eee']
				}
			},
			splitArea: {
				show: true,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		logAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#008acd'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eee']
				}
			},
			splitArea: {
				show: true,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		timeAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#008acd'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#eee']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		toolbox: {
			iconStyle: {
				normal: {
					borderColor: '#2ec7c9'
				},
				emphasis: {
					borderColor: '#18a4a6'
				}
			}
		},
		legend: {
			textStyle: {
				color: '#333333'
			}
		},
		tooltip: {
			axisPointer: {
				lineStyle: {
					color: '#008acd',
					width: '1'
				},
				crossStyle: {
					color: '#008acd',
					width: '1'
				}
			}
		},
		timeline: {
			lineStyle: {
				color: '#008acd',
				width: 1
			},
			itemStyle: {
				normal: {
					color: '#008acd',
					borderWidth: 1
				},
				emphasis: {
					color: '#a9334c'
				}
			},
			controlStyle: {
				normal: {
					color: '#008acd',
					borderColor: '#008acd',
					borderWidth: 0.5
				},
				emphasis: {
					color: '#008acd',
					borderColor: '#008acd',
					borderWidth: 0.5
				}
			},
			checkpointStyle: {
				color: '#2ec7c9',
				borderColor: 'rgba(46,199,201,0.4)'
			},
			label: {
				normal: {
					textStyle: {
						color: '#008acd'
					}
				},
				emphasis: {
					textStyle: {
						color: '#008acd'
					}
				}
			}
		},
		visualMap: {
			color: ['#5ab1ef', '#e0ffff']
		},
		dataZoom: {
			backgroundColor: 'rgba(47,69,84,0)',
			dataBackgroundColor: 'rgba(239,239,255,1)',
			fillerColor: 'rgba(182,162,222,0.2)',
			handleColor: '#008acd',
			handleSize: '100%',
			textStyle: {
				color: '#333333'
			}
		},
		markPoint: {
			label: {
				normal: {
					textStyle: {
						color: '#eee'
					}
				},
				emphasis: {
					textStyle: {
						color: '#eee'
					}
				}
			}
		}
	},
	essos: {
		color: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', '#ebdba4'],
		backgroundColor: 'rgba(252,252,252,0)',
		textStyle: {},
		title: {
			textStyle: {
				color: '#893448'
			},
			subtextStyle: {
				color: '#d95850'
			}
		},
		line: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '2'
				}
			},
			symbolSize: '6',
			symbol: 'emptyCircle',
			smooth: true
		},
		radar: {
			itemStyle: {
				normal: {
					borderWidth: '2'
				}
			},
			lineStyle: {
				normal: {
					width: '2'
				}
			},
			symbolSize: '6',
			symbol: 'emptyCircle',
			smooth: true
		},
		bar: {
			itemStyle: {
				normal: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				},
				emphasis: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				}
			}
		},
		pie: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		scatter: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		boxplot: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		parallel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		sankey: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		funnel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		gauge: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		candlestick: {
			itemStyle: {
				normal: {
					color: '#eb8146',
					color0: 'transparent',
					borderColor: '#d95850',
					borderColor0: '#58c470',
					borderWidth: '2'
				}
			}
		},
		graph: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			},
			lineStyle: {
				normal: {
					width: 1,
					color: '#aaa'
				}
			},
			symbolSize: '6',
			symbol: 'emptyCircle',
			smooth: true,
			color: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', '#ebdba4'],
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		},
		map: {
			itemStyle: {
				normal: {
					areaColor: '#f3f3f3',
					borderColor: '#999999',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(255,178,72,1)',
					borderColor: '#eb8146',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#893448'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(137,52,72)'
					}
				}
			}
		},
		geo: {
			itemStyle: {
				normal: {
					areaColor: '#f3f3f3',
					borderColor: '#999999',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(255,178,72,1)',
					borderColor: '#eb8146',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#893448'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(137,52,72)'
					}
				}
			}
		},
		categoryAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#aaaaaa'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e6e6e6']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		valueAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#aaaaaa'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e6e6e6']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		logAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#aaaaaa'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e6e6e6']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		timeAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#aaaaaa'
				}
			},
			axisTick: {
				show: false,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#999999'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#e6e6e6']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)']
				}
			}
		},
		toolbox: {
			iconStyle: {
				normal: {
					borderColor: '#999'
				},
				emphasis: {
					borderColor: '#666'
				}
			}
		},
		legend: {
			textStyle: {
				color: '#999999'
			}
		},
		tooltip: {
			axisPointer: {
				lineStyle: {
					color: '#ccc',
					width: 1
				},
				crossStyle: {
					color: '#ccc',
					width: 1
				}
			}
		},
		timeline: {
			lineStyle: {
				color: '#893448',
				width: 1
			},
			itemStyle: {
				normal: {
					color: '#893448',
					borderWidth: 1
				},
				emphasis: {
					color: '#ffb248'
				}
			},
			controlStyle: {
				normal: {
					color: '#893448',
					borderColor: '#893448',
					borderWidth: 0.5
				},
				emphasis: {
					color: '#893448',
					borderColor: '#893448',
					borderWidth: 0.5
				}
			},
			checkpointStyle: {
				color: '#eb8146',
				borderColor: 'rgba(255,178,72,0.41)'
			},
			label: {
				normal: {
					textStyle: {
						color: '#893448'
					}
				},
				emphasis: {
					textStyle: {
						color: '#893448'
					}
				}
			}
		},
		visualMap: {
			color: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', 'rgb(247,238,173)']
		},
		dataZoom: {
			backgroundColor: 'rgba(255,255,255,0)',
			dataBackgroundColor: 'rgba(255,178,72,0.5)',
			fillerColor: 'rgba(255,178,72,0.15)',
			handleColor: '#ffb248',
			handleSize: '100%',
			textStyle: {
				color: '#333'
			}
		},
		markPoint: {
			label: {
				normal: {
					textStyle: {
						color: '#ffffff'
					}
				},
				emphasis: {
					textStyle: {
						color: '#ffffff'
					}
				}
			}
		}
	},
	vintage: {
		color: ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'],
		backgroundColor: 'rgba(252,252,252,0)',
		textStyle: {},
		title: {
			textStyle: {
				color: '#333333'
			},
			subtextStyle: {
				color: '#aaaaaa'
			}
		},
		line: {
			itemStyle: {
				normal: {
					borderWidth: 1
				}
			},
			lineStyle: {
				normal: {
					width: 2
				}
			},
			symbolSize: 4,
			symbol: 'emptyCircle',
			smooth: false
		},
		radar: {
			itemStyle: {
				normal: {
					borderWidth: 1
				}
			},
			lineStyle: {
				normal: {
					width: 2
				}
			},
			symbolSize: 4,
			symbol: 'emptyCircle',
			smooth: false
		},
		bar: {
			itemStyle: {
				normal: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				},
				emphasis: {
					barBorderWidth: 0,
					barBorderColor: '#ccc'
				}
			}
		},
		pie: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		scatter: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		boxplot: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		parallel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		sankey: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		funnel: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		gauge: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				},
				emphasis: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			}
		},
		candlestick: {
			itemStyle: {
				normal: {
					color: '#c23531',
					color0: '#314656',
					borderColor: '#c23531',
					borderColor0: '#314656',
					borderWidth: 1
				}
			}
		},
		graph: {
			itemStyle: {
				normal: {
					borderWidth: 0,
					borderColor: '#ccc'
				}
			},
			lineStyle: {
				normal: {
					width: 1,
					color: '#aaaaaa'
				}
			},
			symbolSize: 4,
			symbol: 'emptyCircle',
			smooth: false,
			color: ['#d87c7c', '#919e8b', '#d7ab82', '#6e7074', '#61a0a8', '#efa18d', '#787464', '#cc7e63', '#724e58', '#4b565b'],
			label: {
				normal: {
					textStyle: {
						color: '#eeeeee'
					}
				}
			}
		},
		map: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#444444',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(255,215,0,0.8)',
					borderColor: '#444444',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#000000'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(100,0,0)'
					}
				}
			}
		},
		geo: {
			itemStyle: {
				normal: {
					areaColor: '#eeeeee',
					borderColor: '#444444',
					borderWidth: 0.5
				},
				emphasis: {
					areaColor: 'rgba(255,215,0,0.8)',
					borderColor: '#444444',
					borderWidth: 1
				}
			},
			label: {
				normal: {
					textStyle: {
						color: '#000000'
					}
				},
				emphasis: {
					textStyle: {
						color: 'rgb(100,0,0)'
					}
				}
			}
		},
		categoryAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: false,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		valueAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		logAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		timeAxis: {
			axisLine: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisTick: {
				show: true,
				lineStyle: {
					color: '#333'
				}
			},
			axisLabel: {
				show: true,
				textStyle: {
					color: '#333'
				}
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['#ccc']
				}
			},
			splitArea: {
				show: false,
				areaStyle: {
					color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
				}
			}
		},
		toolbox: {
			iconStyle: {
				normal: {
					borderColor: '#999999'
				},
				emphasis: {
					borderColor: '#666666'
				}
			}
		},
		legend: {
			textStyle: {
				color: '#333333'
			}
		},
		tooltip: {
			axisPointer: {
				lineStyle: {
					color: '#cccccc',
					width: 1
				},
				crossStyle: {
					color: '#cccccc',
					width: 1
				}
			}
		},
		timeline: {
			lineStyle: {
				color: '#293c55',
				width: 1
			},
			itemStyle: {
				normal: {
					color: '#293c55',
					borderWidth: 1
				},
				emphasis: {
					color: '#a9334c'
				}
			},
			controlStyle: {
				normal: {
					color: '#293c55',
					borderColor: '#293c55',
					borderWidth: 0.5
				},
				emphasis: {
					color: '#293c55',
					borderColor: '#293c55',
					borderWidth: 0.5
				}
			},
			checkpointStyle: {
				color: '#e43c59',
				borderColor: 'rgba(194,53,49,0.5)'
			},
			label: {
				normal: {
					textStyle: {
						color: '#293c55'
					}
				},
				emphasis: {
					textStyle: {
						color: '#293c55'
					}
				}
			}
		},
		visualMap: {
			color: ['#bf444c', '#d88273', '#f6efa6']
		},
		dataZoom: {
			backgroundColor: 'rgba(47,69,84,0)',
			dataBackgroundColor: 'rgba(47,69,84,0.3)',
			fillerColor: 'rgba(167,183,204,0.4)',
			handleColor: '#a7b7cc',
			handleSize: '100%',
			textStyle: {
				color: '#333333'
			}
		},
		markPoint: {
			label: {
				normal: {
					textStyle: {
						color: '#eeeeee'
					}
				},
				emphasis: {
					textStyle: {
						color: '#eeeeee'
					}
				}
			}
		}
	}
};
