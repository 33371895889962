import { Injectable } from '@angular/core';
import { graphic } from 'echarts';

import { GoalModel } from '../../models/goal.model';

@Injectable({
	providedIn: 'root'
})
export class GoalService {
	operatorMap: Map<any, any> = new Map<any, any>();

	constructor() {
		this.operatorMap.set('barGreaterThan', (value: number, goal: GoalModel) => this.barChartGreaterThan(value, goal));
		this.operatorMap.set('barLesserThan', (value: number, goal: GoalModel) => this.barChartLessThan(value, goal));
		this.operatorMap.set('barBetween', (value: number, goal: GoalModel) => this.barChartBetween(value, goal));
		this.operatorMap.set('barNotBetween', (value: number, goal: GoalModel) => this.barChartNotBetween(value, goal));
		this.operatorMap.set('HorizontalBarGreaterThan', (value: number, goal: GoalModel) => this.barHorizontalChartGreaterThan(value, goal));
		this.operatorMap.set('HorizontalBarLesserThan', (value: number, goal: GoalModel) => this.barHorizontalChartLessThan(value, goal));
		this.operatorMap.set('HorizontalBarBetween', (value: number, goal: GoalModel) => this.barHorizontalChartBetween(value, goal));
		this.operatorMap.set('HorizontalBarNotBetween', (value: number, goal: GoalModel) => this.barHorizontalChartNotBetween(value, goal));
		this.operatorMap.set('PieGreaterThan', (value: number, goal: GoalModel) => this.pieChartGreaterThan(value, goal));
		this.operatorMap.set('PieLesserThan', (value: number, goal: GoalModel) => this.pieChartLessThan(value, goal));
		this.operatorMap.set('PieBetween', (value: number, goal: GoalModel) => this.pieChartBetween(value, goal));
		this.operatorMap.set('PieNotBetween', (value: number, goal: GoalModel) => this.pieChartNotBetween(value, goal));
		this.operatorMap.set('DonutGreaterThan', (value: number, goal: GoalModel) => this.pieChartGreaterThan(value, goal));
		this.operatorMap.set('DonutLesserThan', (value: number, goal: GoalModel) => this.pieChartLessThan(value, goal));
		this.operatorMap.set('DonutBetween', (value: number, goal: GoalModel) => this.pieChartBetween(value, goal));
		this.operatorMap.set('DonutNotBetween', (value: number, goal: GoalModel) => this.pieChartNotBetween(value, goal));
		this.operatorMap.set('HeatGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('HeatLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('HeatBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('HeatNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
		this.operatorMap.set('NumericGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('NumericLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('NumericBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('NumericNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
		this.operatorMap.set('GroupedVerticalBarGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('GroupedVerticalBarLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('GroupedVerticalBarBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('GroupedVerticalBarNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
		this.operatorMap.set('GroupedHorizontalBarGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('GroupedHorizontalBarLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('GroupedHorizontalBarBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('GroupedHorizontalBarNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
		this.operatorMap.set('HorizontalStackedBarGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('HorizontalStackedBarLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('HorizontalStackedBarBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('HorizontalStackedBarNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
		this.operatorMap.set('VerticalStackedBarGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('VerticalStackedBarLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('VerticalStackedBarBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('VerticalStackedBarNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
		this.operatorMap.set('lineGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('lineLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('lineBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('lineNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
		this.operatorMap.set('AreaGreaterThan', (value: number, goal: GoalModel) => this.heatChartGreaterThan(value, goal));
		this.operatorMap.set('AreaLesserThan', (value: number, goal: GoalModel) => this.heatChartLessThan(value, goal));
		this.operatorMap.set('AreaBetween', (value: number, goal: GoalModel) => this.heatChartBetween(value, goal));
		this.operatorMap.set('AreaNotBetween', (value: number, goal: GoalModel) => this.heatChartNotBetween(value, goal));
	}

	private getGradientObject(x1: number, y1: number, x2: number, y2: number, ...colors: string[]): any {
		return new graphic.LinearGradient(
			x1,
			y1,
			x2,
			y2,
			colors.map((value, index, arr) => {
				return { offset: index / (arr.length || 1 - 1 || 1), color: value };
			})
		);
	}

	private getVerticalGradient(...colors: string[]): any {
		return this.getGradientObject(0, 0, 0, 1, ...colors);
	}

	private getHorizontalGradient(...colors: string[]): any {
		return this.getGradientObject(0, 0, 1, 0, ...colors);
	}

	private barChartGreaterThan(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value > goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private barChartLessThan(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value < goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private barChartBetween(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value > goal.min && value < goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private barChartNotBetween(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value < goal.min || value > goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private barHorizontalChartGreaterThan(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value > goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private barHorizontalChartLessThan(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value < goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private barHorizontalChartBetween(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value > goal.min && value < goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private barHorizontalChartNotBetween(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value < goal.min && value > goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private pieChartGreaterThan(value: number, goal: GoalModel) {
		return {
			normal: {
				color: goal && value > goal.max ? '#32C585' : '#FF3F25'
			}
		};
	}

	private pieChartLessThan(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value < goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private pieChartBetween(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value > goal.min && value < goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private pieChartNotBetween(value: number, goal: GoalModel) {
		if (goal) {
			return {
				normal: {
					color: value < goal.min || value > goal.max ? '#32C585' : '#FF3F25'
				}
			};
		}
	}

	private heatChartGreaterThan(value: number, goal: GoalModel) {
		return {
			color: value > goal.max ? '#32C585' : '#FF3F25'
		};
	}

	private heatChartLessThan(value: number, goal: GoalModel) {
		return {
			color: value < goal.max ? '#32C585' : '#FF3F25'
		};
	}

	private heatChartBetween(value: number, goal: GoalModel) {
		return {
			color: value > goal.min && value < goal.max ? '#32C585' : '#FF3F25'
		};
	}

	private heatChartNotBetween(value: number, goal: GoalModel) {
		return {
			color: value < goal.min && value > goal.max ? '#32C585' : '#FF3F25'
		};
	}
}
