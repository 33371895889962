import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { SharedState } from 'src/app/shared/state/shared.reducer';
import { getFiledId, getUserDetails, hasBackOfficePermission, UserState } from 'src/app/shared/state/user/user.reducer';
import { KnowledgebaseComponent } from 'src/app/sidenav/component-pieces/knowledgebase/knowledgebase.component';
import { LoadFiledUser } from 'src/app/user-management/state/user-management.actions';
import { getBillingAccountInfo, getUserProfileUserManagement, UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { StorageKey } from 'src/app/_models/local-storage-key';
import { User } from 'src/app/_models/user-models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
	selector: 'app-knowledge-header',
	templateUrl: './knowledge-header.component.html',
	styleUrls: ['./knowledge-header.component.scss']
})
export class KnowledgeHeaderComponent implements OnInit {
	@Input() public leftBackButton = false;
	@Input() public rightActionButton = false;
	@Input() public showAvatar = false;

	public user: User;

	public unsubscriber$ = new Subject<void>();
	private filedId: number;
	activeAllRoute: string;

	constructor(
		private authenticationService: AuthenticationService,
		private store: Store<UserManagementState>,
		public kbService: KnowledgebaseService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public dialogRef: MatDialogRef<KnowledgebaseComponent>,
		private sharedStore: Store<SharedState>
	) {}

	ngOnInit(): void {
		this.store.pipe(select(getFiledId)).subscribe(id => {
			if (id) {
				this.filedId = id;
				this.store.pipe(select(hasBackOfficePermission), take(1)).subscribe(isAdmin => {
					this.store.dispatch(new LoadFiledUser({ id: this.filedId, setSelectedUser: false, isBackoffice: isAdmin }));
				});
			}
		});
		this.store.pipe(select(getUserProfileUserManagement), takeUntil(this.unsubscriber$)).subscribe(user => {
			this.user = user;
		});
		if (this.activatedRoute.outlet === 'primary') {
			this.kbService.knowledgebaseHomeVisited$.next(false);
			this.kbService.knowledgebaseHome$.next(true);
			this.kbService.knowledgebaseShowView$.next(false);
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseShowTickets$.next(false);
			this.kbService.knowledgebaseHomeVisited$.next(true);
			this.router.navigate([{ outlets: { 'knowledge-base-modal': 'home' } }]);
		} else {
			this.router.navigate([{ outlets: { 'knowledge-base-modal': 'home' } }]);
		}
		this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe((event: RouterEvent) => {
			this.activeAllRoute = event.url;
		});
	}

	public backToHome(): void {
		if (this.kbService.searchQuery$.value.length > 0 && this.kbService.knowledgebaseShowFrame$.value) {
			this.kbService.knowledgebaseShowFrame$.next(false);
		} else if (this.kbService.knowledgebaseShowFrame$.value) {
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseShowSub$.next(true);
		} else if (this.kbService.knowledgebaseShowSub$.value) {
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowView$.next(true);
		} else {
			this.router.navigate([{ outlets: { 'knowledge-base-modal': 'home' } }]);
			this.kbService.knowledgebaseHome$.next(true);
			this.kbService.knowledgebaseShowView$.next(false);
			this.kbService.knowledgebaseShowSub$.next(false);
			this.kbService.knowledgebaseShowFrame$.next(false);
			this.kbService.knowledgebaseShowTickets$.next(false);
			this.kbService.knowledgebaseHomeVisited$.next(true);
			this.kbService.articleDataValue$.next([]);
			this.kbService.searchDataValue$.next([]);
			this.kbService.searchValue$.next('');
			this.kbService.frameIndexValue$.next(0);
			this.kbService.searchQuery$.next('');
		}
	}

	public closeModal(): void {
		const fullStringToArr = this.activeAllRoute.split('');
		const findIndex = fullStringToArr.findIndex(item => {
			return item === '(';
		});
		if (findIndex) {
			const sliceOther = fullStringToArr.slice(0, findIndex);
			const getActiveRoute = sliceOther.join('');
			this.router.navigate([{ outlets: { 'knowledge-base-modal': null } }]).then(() => {
				this.router.navigate([getActiveRoute]);
				this.dialogRef.close();
			});
		} else {
			this.dialogRef.close();
		}
	}
}
