import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ValidatorMessages } from '@filed-com/filed-lib/lib/shared/validatorMessagesInputs';

@Component({
	selector: 'app-select-app-card',
	templateUrl: './select-app-card.component.html',
	styleUrls: ['./select-app-card.component.scss']
})
export class SelectAppCardComponent implements OnInit {
	@Input() public isPanelSelected: boolean;
	@Input() public appFormGroup: AbstractControl | FormControl;
	@Output() public onExpansionClick = new EventEmitter();
	@Input() public validationMessages: ValidatorMessages[];
	public apps: any = [];
	public placeholder = 'Enter app name or exact store URL';
	public isChecked = false;
	constructor() {}

	public ngOnInit(): void {}

	get appName() {
		return this.appFormGroup.get('appName');
	}

	get appLink() {
		return this.appFormGroup.get('appLink');
	}
	public handleAppSelection(): void {}

	public toggleIsChecked(value: boolean): void {
		this.isChecked = value;
	}
}
