import { CampaignTemplateListItem } from '../models/campaign-template-list-item';
import { CampaignSmartCreateActionTypes, SmartCreateActions, UpdateAppStoreType } from './smart-create.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import _ from 'lodash';
import { CampaignTemplate } from '../models/campaign-template';
import { CampaignSmartCreateMapperService } from '../services/campaign-smart-create-mapper.service';
import { StepOneDetails } from '../models/step-one-details';
import { StepTwoDetails } from '../models/step-two-details';
import { CampaignStepsEnum } from '../models/campaign-steps.enum';
import { StepThreeDetails } from '../models/step-three-details';
import { Asset } from '../../shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';
import { StepFourDetails } from '../models/step-four-details';
import { CatalogInterface } from '../catalogs/catalog.interface';
import { DropdownData } from '../../shared/dropdown-search-select/dropdown-data.interface';
import { AdFormatsEnum } from '../models/ad-formats.enum';
import { StepFiveDetails } from '../models/step-five-details';

export interface CampaignSmartCreateState {
	campaignsList: Map<string, CampaignTemplateListItem[]>;
	currentCampaign: CampaignTemplate;
	currentSelectedAdIndex: number;
	currentAd: StepThreeDetails;
	stepThreeAds: StepThreeDetails[];
	currentStepDetails: StepOneDetails | StepTwoDetails | StepThreeDetails[] | StepFourDetails | StepFiveDetails;
	catalogs: CatalogInterface;
	templates: DropdownData<CampaignTemplateListItem>[];
	userImages: Asset[];
	userVideos: Asset[];
	existingImages: Asset[];
	existingVideos: Asset[];
	pagePosts: Asset[];
	updatingStep: boolean;
	validateFormsTrigger: boolean;
	areFormsValid: boolean;
}

export const initialCampaignSmartCreateState: CampaignSmartCreateState = {
	campaignsList: new Map<string, CampaignTemplateListItem[]>(),
	currentCampaign: null,
	currentStepDetails: null,
	currentSelectedAdIndex: 0,
	currentAd: null,
	stepThreeAds: [],
	catalogs: null,
	templates: null,
	userImages: null,
	userVideos: null,
	existingImages: null,
	existingVideos: null,
	pagePosts: null,
	updatingStep: false,
	validateFormsTrigger: false,
	areFormsValid: true
};

const getSmartCreateSelector = createFeatureSelector<CampaignSmartCreateState>('campaignSmartCreate');
export const getTemplates = createSelector(getSmartCreateSelector, (state: CampaignSmartCreateState, { accountId }: { accountId: string }) =>
	state.campaignsList.get(accountId)
);
export const getCampaign = createSelector(getSmartCreateSelector, state => _.cloneDeep(state.currentCampaign));
export const getCurrentStepDetails = createSelector(getSmartCreateSelector, state => _.cloneDeep(state.currentStepDetails));
export const getStepOneDetails = createSelector(getCampaign, campaign => _.cloneDeep(campaign?.stepOneDetailsAsJson));
export const getCampaignObjective = createSelector(getStepOneDetails, details => details.objective);
export const getStepTwoDetails = createSelector(getCampaign, campaign => _.cloneDeep(campaign?.stepTwoDetailsAsJson));
export const getStepThreeDetails = createSelector(getSmartCreateSelector, campaign => _.cloneDeep(campaign?.stepThreeAds));
export const getStepFourDetails = createSelector(getCampaign, campaign => _.cloneDeep(campaign?.stepFourDetailsAsJson));
export const getStepFiveDetails = createSelector(getCampaign, campaign => _.cloneDeep(campaign?.stepFiveDetailsAsJson));
export const getLastStepId = createSelector(getCampaign, campaign => _.cloneDeep(campaign?.lastStepId));
export const getInvalidStep = createSelector(getCampaign, campaign => _.cloneDeep(campaign?.invalidStep));
export const getCurrency = createSelector(getCampaign, campaign => _.cloneDeep(campaign?.currency));

// Step 1
export const getObjective = createSelector(getStepOneDetails, details => details?.objective);
export const getObjectiveTree = createSelector(getStepOneDetails, details => details?.objectiveGroupTree);
export const getSpecialAdCategory = createSelector(getStepOneDetails, details => details?.specialAdCategory);
export const getCampaignBudgetOptimization = createSelector(getStepOneDetails, details => details?.campaignBudgetOptimization);
export const getCampaignDate = createSelector(getStepOneDetails, details => {
	const clone = _.cloneDeep(details);
	return clone?.date ? { date: clone?.date, dateChoiceType: clone?.dateChoice } : null;
});
export const getCatalogId = createSelector(getStepOneDetails, details => details?.productCatalogId);

// Step 2
export const getTargeting = createSelector(getStepTwoDetails, details => details?.targeting);
export const getLocations = createSelector(getStepTwoDetails, details => details?.targeting?.locations);
export const getLanguages = createSelector(getStepTwoDetails, details => details?.targeting.languages);
export const getGenders = createSelector(getStepTwoDetails, details => details?.targeting?.gender);
export const getFacebookPageId = createSelector(getStepTwoDetails, details => details?.facebookPageId);
export const getInstagramPageId = createSelector(getStepTwoDetails, details => details?.instagramPageId);
export const getPlacements = createSelector(getStepTwoDetails, details => details?.placements);
export const getCampaignActiveDate = createSelector(getStepTwoDetails, details => {
	const clone = _.cloneDeep(details);
	return clone?.date ? { date: clone?.date, dateChoiceType: clone?.dateChoice } : null;
});
export const getAdSetOptimization = createSelector(getStepTwoDetails, details => details?.budgetOptimization);
export const getProductSetId = createSelector(getStepTwoDetails, detail => detail?.productSetId);

// Step 3
export const getCurrentAdIndex = createSelector(getSmartCreateSelector, details => details?.currentSelectedAdIndex);
export const getCurrentAd = createSelector(getSmartCreateSelector, getCurrentAdIndex, (details, currentInd) => {
	if (!details.currentAd) {
		const allAds = _.cloneDeep(details?.stepThreeAds);
		if (!allAds) {
			allAds.push(new StepThreeDetails());
		}
		details.currentAd = allAds[currentInd];
	}
	return details?.currentAd;
});
export const getAllAds = createSelector(getStepThreeDetails, details => details);
export const getAdName = createSelector(getCurrentAd, details => details?.adName);
export const getAdFormat = createSelector(getCurrentAd, details => details?.adFormatType ?? AdFormatsEnum?.SingleImage);
export const getAdPreview = createSelector(getCurrentAd, details => details?.adverts);
export const getPixelToggle = createSelector(getCurrentAd, details => details?.toggle);
export const getPixelId = createSelector(getCurrentAd, details => details?.pixelId);
export const getPixelAppEventId = createSelector(getCurrentAd, details => details?.pixelAppEventId);

// Step 4
export const getSplitByLocation = createSelector(getStepFourDetails, details => details?.isSplitByLocation);
export const getSplitByDevices = createSelector(getStepFourDetails, details => details?.isSplitByDevices);
export const getBudgetToggle = createSelector(getStepFourDetails, details => details?.isBudgetSplitEvenly);
export const getBudgetAllocation = createSelector(getStepFourDetails, details => details?.budgetAllocation);

// Templates
export const getPagePosts = createSelector(getSmartCreateSelector, state => _.cloneDeep(state.pagePosts));
export const getTemplateList = createSelector(getSmartCreateSelector, state => _.cloneDeep(state.templates));
export const isCampaignUpdating = createSelector(getSmartCreateSelector, state => state.updatingStep);
export const getValidateFormsTrigger = createSelector(getSmartCreateSelector, state => _.cloneDeep(state.validateFormsTrigger));
export const getFormsState = createSelector(getSmartCreateSelector, state => _.cloneDeep(state.areFormsValid));

export function campaignSmartCreateReducer(
	state: CampaignSmartCreateState = initialCampaignSmartCreateState,
	action: SmartCreateActions
): CampaignSmartCreateState {
	switch (action.type) {
		case CampaignSmartCreateActionTypes.SaveCampaignTemplates: {
			return {
				...state,
				templates: action.payload
			};
		}
		case CampaignSmartCreateActionTypes.LoadCampaignListSuccess: {
			const templates = _.cloneDeep(state.campaignsList);
			if (templates.has(action.payload.accountId)) {
				templates.get(action.payload.accountId).push(...action.payload.templates);
			} else {
				templates.set(action.payload.accountId, action.payload.templates);
			}
			return {
				...state,
				campaignsList: templates
			};
		}
		case CampaignSmartCreateActionTypes.LoadCampaignSuccess: {
			const newPayload = action.payload;
			if ((newPayload?.stepThreeDetailsAsJson as any)?.ads) {
				newPayload.stepThreeDetailsAsJson = [...(action.payload.stepThreeDetailsAsJson as any).ads];
			}
			return {
				...state,
				currentCampaign: newPayload
			};
		}
		case CampaignSmartCreateActionTypes.CreateCampaignTemplateSuccess: {
			const campaign = _.cloneDeep(state.currentCampaign);
			campaign.id = action.payload;
			campaign.lastStepId = CampaignStepsEnum.Two;
			return {
				...state,
				currentCampaign: campaign
			};
		}
		case CampaignSmartCreateActionTypes.UpdateCampaignTemplateSuccess: {
			let templates = _.cloneDeep(state.campaignsList);
			const template = templates.get(action.payload.adAccountFacebookId)?.find(item => item.id === action.payload.id);
			if (template) {
				template.name = action.payload.name;
			} else {
				templates = CampaignSmartCreateMapperService.addTemplateToCampaignsList(state.campaignsList, action.payload);
			}

			const campaignTemplate = action.payload;
			let step = 0;
			if (campaignTemplate.stepFourDetailsAsJson && !_.isEmpty(campaignTemplate.stepFourDetailsAsJson)) {
				step = 4;
			} else if (campaignTemplate.stepThreeDetailsAsJson && !_.isEmpty(campaignTemplate.stepThreeDetailsAsJson)) {
				step = 3;
			} else if (campaignTemplate.stepTwoDetailsAsJson && !_.isEmpty(campaignTemplate.stepTwoDetailsAsJson)) {
				step = 2;
			} else if (campaignTemplate.stepOneDetailsAsJson && !_.isEmpty(campaignTemplate.stepOneDetailsAsJson)) {
				step = 1;
			}

			campaignTemplate.lastStepId = step;

			return {
				...state,
				currentCampaign: campaignTemplate,
				campaignsList: templates,
				updatingStep: false
			};
		}

		case CampaignSmartCreateActionTypes.ClearCurrentCampaign: {
			return {
				...state,
				currentCampaign: initialCampaignSmartCreateState.currentCampaign
			};
		}

		case CampaignSmartCreateActionTypes.ClearCurrentAssets: {
			return {
				...state,
				userImages: null,
				userVideos: null,
				existingVideos: null,
				existingImages: null,
				pagePosts: null
			};
		}

		case CampaignSmartCreateActionTypes.ClearUserImages: {
			return {
				...state,
				userImages: null
			};
		}

		case CampaignSmartCreateActionTypes.ClearCurrentStep: {
			return {
				...state,
				currentStepDetails: initialCampaignSmartCreateState.currentStepDetails
			};
		}
		case CampaignSmartCreateActionTypes.UpdateCurrentStep: {
			const campaign = _.cloneDeep(state.currentCampaign);
			if (action.payload.stepLevel === CampaignStepsEnum.One) {
				campaign.stepOneDetailsAsJson = action.payload.step as StepOneDetails;
			} else if (action.payload.stepLevel === CampaignStepsEnum.Two) {
				campaign.stepTwoDetailsAsJson = action.payload.step as StepTwoDetails;
			} else if (action.payload.stepLevel === CampaignStepsEnum.Three) {
				campaign.stepThreeDetailsAsJson = action.payload.step as StepThreeDetails[];
			}

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: action.payload.step
			};
		}

		case CampaignSmartCreateActionTypes.UpdateCampaignCurrentStep: {
			let campaign = _.cloneDeep(state.currentCampaign);
			if (!campaign) {
				campaign = {} as CampaignTemplate;
			}
			campaign.currentStep = action.payload;
			if (campaign.currentStep > campaign.lastStepId) {
				campaign.lastStepId = campaign.currentStep;
			}
			return {
				...state,
				currentCampaign: campaign
			};
		}

		case CampaignSmartCreateActionTypes.UpdateCampaignInvalidStep: {
			let campaign = _.cloneDeep(state.currentCampaign);
			if (!campaign) {
				campaign = {} as CampaignTemplate;
			}
			campaign.invalidStep = action.payload;
			return {
				...state,
				currentCampaign: campaign
			};
		}

		case CampaignSmartCreateActionTypes.UpdateCampaignBudgetOptimization: {
			let campaign = _.cloneDeep(state.currentCampaign);
			const stepTwoDetails = _.cloneDeep(state.currentCampaign?.stepTwoDetailsAsJson);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepOneDetails) ?? new StepOneDetails();
			if (!campaign) {
				campaign = {} as CampaignTemplate;
			}
			if (campaign.stepTwoDetailsAsJson?.budgetOptimization) {
				stepTwoDetails.budgetOptimization = null;
			}
			currentStep.campaignBudgetOptimization = action.payload;
			campaign.stepOneDetailsAsJson = currentStep;
			campaign.stepTwoDetailsAsJson = stepTwoDetails;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateCatalogId: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepOneDetails) ?? new StepOneDetails();
			currentStep.productCatalogId = action.payload;
			campaign.stepOneDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateAppStoreType: {
			const campaign = _.cloneDeep(state.currentCampaign);
			campaign.stepOneDetailsAsJson.appStoreType = action.payload;
			return {
				...state,
				currentCampaign: campaign
			};
		}

		case CampaignSmartCreateActionTypes.UpdateAppSelection: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepTwoDetails) ?? new StepTwoDetails();
			const payload = action.payload;
			if (payload) {
				currentStep.appId = payload.appId;
				currentStep.appLink = payload.appLink;
			} else {
				currentStep.appId = null;
				currentStep.appLink = null;
			}
			campaign.stepTwoDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateCatalogSetId: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepTwoDetails) ?? new StepTwoDetails();
			currentStep.productSetId = action.payload;
			campaign.stepTwoDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateOptimizationAndDelivery: {
			let campaign = _.cloneDeep(state.currentCampaign);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepTwoDetails) ?? new StepTwoDetails();
			if (!campaign) {
				campaign = {} as CampaignTemplate;
			}

			currentStep.optimizationAndDelivery = action.payload;
			campaign.stepTwoDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateAdSetBudgetOptimization: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepTwoDetails) ?? new StepTwoDetails();

			currentStep.budgetOptimization = action.payload;
			campaign.stepTwoDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateCurrency: {
			let campaign = _.cloneDeep(state.currentCampaign);
			if (!campaign) {
				campaign = {} as CampaignTemplate;
			}
			campaign.currency = action.payload;
			return {
				...state,
				currentCampaign: campaign
			};
		}

		case CampaignSmartCreateActionTypes.UpdateCampaignName: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStep = (_.cloneDeep(campaign.stepOneDetailsAsJson) as StepOneDetails) ?? new StepOneDetails();
			campaign.name = action.payload;
			currentStep.name = action.payload;
			campaign.stepOneDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdateCampaignDate: {
			const campaign = _.cloneDeep(state.currentCampaign);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepOneDetails;
			if (!currentStep) {
				currentStep = campaign.stepOneDetailsAsJson ?? new StepOneDetails();
			}
			currentStep.date = action.payload.date;
			currentStep.dateChoice = action.payload.dateChoiceType;
			campaign.stepOneDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdateObjective: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepOneDetails) ?? new StepOneDetails();
			const objectiveTree = action.payload;
			currentStep.objectiveGroupTree = objectiveTree;
			currentStep.objective = objectiveTree.subObjective ?? objectiveTree.objective;
			currentStep.destinationType = objectiveTree.destinationType;
			campaign.stepOneDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdateSpecialAdCategory: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepOneDetails) ?? new StepOneDetails();

			currentStep.specialAdCategory = action.payload;
			campaign.stepOneDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdateAdSetName: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentStep = (_.cloneDeep(state.currentStepDetails) as StepTwoDetails) ?? new StepTwoDetails();

			currentStep.adSetName = action.payload;
			campaign.stepTwoDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdatePages: {
			const campaign = _.cloneDeep(state.currentCampaign);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepTwoDetails;
			if (!currentStep) {
				currentStep = campaign.stepTwoDetailsAsJson ?? new StepTwoDetails();
			}
			currentStep.facebookPageId = action.payload.facebookPageId;
			currentStep.instagramPageId = action.payload?.instagramPageId;
			campaign.stepTwoDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdateInstagramId: {
			const campaign = _.cloneDeep(state.currentCampaign);
			campaign.stepTwoDetailsAsJson.instagramPageId = action.payload;
			return {
				...state,
				currentCampaign: campaign
			};
		}

		case CampaignSmartCreateActionTypes.UpdateTargeting: {
			const campaign = _.cloneDeep(state.currentCampaign);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepTwoDetails;
			if (!currentStep) {
				currentStep = campaign.stepTwoDetailsAsJson ?? new StepTwoDetails();
			}
			if (campaign.currentStep === CampaignStepsEnum.Two) {
				currentStep.targeting = action.payload;
				campaign.stepTwoDetailsAsJson = currentStep;
			} else if (campaign.stepTwoDetailsAsJson) {
				campaign.stepTwoDetailsAsJson.targeting = action.payload;
			}
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdateCampaignActiveDate: {
			const campaign = _.cloneDeep(state.currentCampaign);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepTwoDetails;
			if (!currentStep) {
				currentStep = campaign.stepTwoDetailsAsJson ?? new StepTwoDetails();
			}
			currentStep.date = action.payload.date;
			currentStep.dateChoice = action.payload.dateChoiceType;
			campaign.stepTwoDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdatePlacements: {
			const campaign = _.cloneDeep(state.currentCampaign);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepTwoDetails;
			if (!currentStep) {
				currentStep = campaign.stepTwoDetailsAsJson ?? new StepTwoDetails();
			}
			currentStep.placements = action.payload;
			campaign.stepTwoDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.AddNewAd: {
			const allAds = _.cloneDeep(state.stepThreeAds);
			const campaign = _.cloneDeep(state.currentCampaign);
			allAds.push(action.payload);

			campaign.stepThreeDetailsAsJson = allAds;

			return {
				...state,
				stepThreeAds: allAds,
				currentCampaign: campaign
			};
		}
		case CampaignSmartCreateActionTypes.RemoveAd: {
			const allAds = _.cloneDeep(state.stepThreeAds);
			const campaign = _.cloneDeep(state.currentCampaign);
			allAds.splice(action.payload, 1);

			campaign.stepThreeDetailsAsJson = allAds;
			return {
				...state,
				stepThreeAds: allAds,
				currentCampaign: campaign
			};
		}
		case CampaignSmartCreateActionTypes.UpdateSelectedAdIndex: {
			return {
				...state,
				currentSelectedAdIndex: action.payload
			};
		}
		case CampaignSmartCreateActionTypes.UpdateCurrentAd: {
			const allAds = _.cloneDeep(state.stepThreeAds);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			return {
				...state,
				currentAd: allAds[currentIndex]
			};
		}
		case CampaignSmartCreateActionTypes.UpdateAdFormatType: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}

			currentStep[currentIndex].adFormatType = action.payload;
			currentAd.adFormatType = action.payload;
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				stepThreeAds: currentStep,
				currentAd: currentAd
			};
		}
		case CampaignSmartCreateActionTypes.UpdateAdName: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}

			currentStep[currentIndex].adName = action.payload;
			currentAd.adName = action.payload;
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				stepThreeAds: currentStep,
				currentAd: currentAd
			};
		}
		case CampaignSmartCreateActionTypes.UpdatePixelId: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}

			currentStep[currentIndex].pixelId = action.payload;
			currentAd.pixelId = action.payload;
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				stepThreeAds: currentStep,
				currentAd: currentAd
			};
		}

		case CampaignSmartCreateActionTypes.UpdatePixelAppEventId: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}

			currentStep[currentIndex].pixelAppEventId = action.payload;
			currentAd.pixelAppEventId = action.payload;
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				stepThreeAds: currentStep,
				currentAd: currentAd
			};
		}

		case CampaignSmartCreateActionTypes.UpdatePixelToggle: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}

			currentStep[currentIndex].toggle = action.payload;
			currentAd.toggle = action.payload;
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				stepThreeAds: currentStep,
				currentAd: currentAd
			};
		}

		case CampaignSmartCreateActionTypes.UpdateConversion: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}
			if (action.payload) {
				currentStep[currentIndex].customEventType = action.payload.data.customEventType;
				currentStep[currentIndex].pixelRule = action.payload.data.rule ?? null;
				currentStep[currentIndex].conversionId = action.payload.data.id;

				currentAd.customEventType = action.payload.data.customEventType;
				currentAd.pixelRule = action.payload.data.rule ?? null;
				currentAd.conversionId = action.payload.data.id;

				campaign.stepThreeDetailsAsJson = currentStep;
			} else {
				currentStep[currentIndex].customEventType = null;
				currentStep[currentIndex].pixelRule = null;

				currentAd.customEventType = null;
				currentAd.pixelRule = null;
			}
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				currentAd: currentAd,
				stepThreeAds: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateEventRule: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}

			currentStep[currentIndex].eventRule = action.payload;
			currentAd.eventRule = action.payload;
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				currentAd: currentAd,
				stepThreeAds: currentStep
			};
		}
		case CampaignSmartCreateActionTypes.UpdateIframeSrc: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentStep.length === 0) {
				currentStep.push(new StepThreeDetails());
			}

			currentStep[currentIndex].iframeUrl = action.payload;
			currentAd.iframeUrl = action.payload;
			campaign.stepThreeDetailsAsJson = currentStep;

			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep,
				currentAd: currentAd,
				stepThreeAds: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateAdverts: {
			const campaign = _.cloneDeep(state.currentCampaign);
			const currentIndex = _.cloneDeep(state.currentSelectedAdIndex);
			const currentAd = _.cloneDeep(state.currentAd);
			const currentStep = _.cloneDeep(state.stepThreeAds);
			if (currentAd) {
				currentStep[currentIndex].adverts = action.payload;
				currentAd.adverts = action.payload;
				campaign.stepThreeDetailsAsJson[currentIndex] = currentAd;
				return {
					...state,
					currentCampaign: campaign,
					currentStepDetails: currentStep,
					stepThreeAds: currentStep,
					currentAd: currentAd
				};
			}
			return {
				...state,
				currentCampaign: campaign,
				stepThreeAds: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.ClearAds: {
			const currentCampaign = _.cloneDeep(state.currentCampaign);
			if (currentCampaign) currentCampaign.stepThreeDetailsAsJson = [];
			return {
				...state,
				currentCampaign,
				currentAd: initialCampaignSmartCreateState.currentAd,
				stepThreeAds: initialCampaignSmartCreateState.stepThreeAds,
				currentStepDetails: initialCampaignSmartCreateState.currentStepDetails,
				currentSelectedAdIndex: initialCampaignSmartCreateState.currentSelectedAdIndex
			};
		}

		case CampaignSmartCreateActionTypes.UpdateTemplateStart: {
			return {
				...state,
				updatingStep: true
			};
		}

		case CampaignSmartCreateActionTypes.UpdateTemplateEnd: {
			return {
				...state,
				updatingStep: false
			};
		}
		case CampaignSmartCreateActionTypes.TriggerFormsValidation: {
			return {
				...state,
				validateFormsTrigger: action.payload
			};
		}
		case CampaignSmartCreateActionTypes.AreFormsValid: {
			return {
				...state,
				areFormsValid: action.payload
			};
		}

		case CampaignSmartCreateActionTypes.LoadPagePostsSuccess: {
			return {
				...state,
				pagePosts: action.payload
			};
		}

		// step 4

		case CampaignSmartCreateActionTypes.UpdateBudgetToggle: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepFourDetails;
			if (!currentStep) {
				currentStep = campaign.stepFourDetailsAsJson ?? new StepFourDetails();
			}

			currentStep.isBudgetSplitEvenly = action.payload;
			campaign.stepFourDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		case CampaignSmartCreateActionTypes.UpdateBudgetAllocation: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepFourDetails;
			if (!currentStep) {
				currentStep = campaign.stepFourDetailsAsJson ?? new StepFourDetails();
			}

			currentStep.budgetAllocation = action.payload;
			campaign.stepFourDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		// Split by location
		case CampaignSmartCreateActionTypes.UpdateSplitByLocation: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			let currentStep = _.cloneDeep(state.currentStepDetails) as StepFourDetails;
			if (!currentStep) {
				currentStep = campaign.stepFourDetailsAsJson ?? new StepFourDetails();
			}
			currentStep.isSplitByLocation = action.payload;
			campaign.stepFourDetailsAsJson = currentStep;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStep
			};
		}

		// Split by devices
		case CampaignSmartCreateActionTypes.UpdateSplitByDevice: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStepDetails = _.cloneDeep(state.currentStepDetails as StepFourDetails) ?? new StepFourDetails();
			currentStepDetails.isSplitByDevices = action.payload;
			campaign.stepFourDetailsAsJson = currentStepDetails;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStepDetails
			};
		}

		// Split by interests
		case CampaignSmartCreateActionTypes.UpdateSplitByInterests: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStepDetails = _.cloneDeep(state.currentStepDetails as StepFourDetails) ?? new StepFourDetails();
			currentStepDetails.isSplitByInterests = action.payload;
			campaign.stepFourDetailsAsJson = currentStepDetails;
			return {
				...state,
				currentStepDetails: currentStepDetails,
				currentCampaign: campaign
			};
		}

		// Split by placements
		case CampaignSmartCreateActionTypes.UpdateSplitByPlacements: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStepDetails = _.cloneDeep(state.currentStepDetails as StepFourDetails) ?? new StepFourDetails();
			currentStepDetails.isSplitByPlacements = action.payload;
			campaign.stepFourDetailsAsJson = currentStepDetails;
			return {
				...state,
				currentStepDetails: currentStepDetails,
				currentCampaign: campaign
			};
		}
		// Split by gender
		case CampaignSmartCreateActionTypes.UpdateSplitByGender: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStepDetails = _.cloneDeep(state.currentStepDetails as StepFourDetails) ?? new StepFourDetails();
			// has to be checked otherwise it will update on step one
			if (campaign.currentStep === CampaignStepsEnum.Four) {
				currentStepDetails.isSplitByGenderSelected = action.payload;
				campaign.stepFourDetailsAsJson = currentStepDetails;
			} else if (campaign.stepFourDetailsAsJson) {
				campaign.stepFourDetailsAsJson.isSplitByGenderSelected = action.payload;
			}
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStepDetails
			};
		}
		// Split by age range
		case CampaignSmartCreateActionTypes.UpdateSplitByAgeRange: {
			const campaign = _.cloneDeep(state.currentCampaign) ?? ({} as CampaignTemplate);
			const currentStepDetails = _.cloneDeep(state.currentStepDetails as StepFourDetails) ?? new StepFourDetails();
			// has to be checked otherwise it will update on step one
			if (campaign.currentStep === CampaignStepsEnum.Four) {
				currentStepDetails.isSplitAgeRangeSelected = action.payload;
				campaign.stepFourDetailsAsJson = currentStepDetails;
			} else if (campaign.stepFourDetailsAsJson) {
				campaign.stepFourDetailsAsJson.isSplitAgeRangeSelected = action.payload;
			}
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: currentStepDetails
			};
		}
		default: {
			return state;
		}

		case CampaignSmartCreateActionTypes.PublishCampaignSuccess: {
			const campaign = _.cloneDeep(state.currentCampaign);
			campaign.stepFourDetailsAsJson = action.payload;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: action.payload
			};
		}
		case CampaignSmartCreateActionTypes.UpdateOptimizationDraft: {
			const campaign = _.cloneDeep(state.currentCampaign);
			campaign.stepFiveDetailsAsJson = action.payload;
			return {
				...state,
				currentCampaign: campaign,
				currentStepDetails: action.payload
			};
		}
	}
}
