<mat-tree #matTree [dataSource]="dataSource" [treeControl]="treeControl">
	<mat-tree-node
		*matTreeNodeDef="let node"
		[class.active-node]="checklistSelection.isSelected(node)"
		[matTreeNodePaddingIndent]="25"
		matTreeNodePadding
		matTreeNodeToggle
	>
		<mat-checkbox
			(change)="updateLeafSelection(node)"
			*ngIf="!singleSelection"
			[checked]="checklistSelection.isSelected(node)"
			[disabled]="hasDisabledMode && node.disabled"
			class="checklist-leaf-node"
		>
			{{ node[nodeTitleName] }}
		</mat-checkbox>

		<div (click)="selectSingleNode(node)" *ngIf="singleSelection" [ngClass]="{ active: checklistSelection.isSelected(node) }" class="button">
			{{ node[nodeTitleName] }}
		</div>
	</mat-tree-node>

	<mat-tree-node *matTreeNodeDef="let node; when: hasChild" [class.active-node]="checklistSelection.isSelected(node)" matTreeNodePadding>
		<mat-checkbox
			(change)="!preventParentSelection && todoItemSelectionToggle(node)"
			*ngIf="!singleSelection"
			[checked]="checklistSelection.isSelected(node)"
			[disabled]="descendantsAllDisabled(node)"
			[indeterminate]="descendantsPartiallySelected(node)"
		>
			{{ node[nodeTitleName] }}
		</mat-checkbox>

		<div (click)="selectSingleNode(node)" *ngIf="singleSelection" [ngClass]="{ active: checklistSelection.isSelected(node) }" class="button">
			{{ node[nodeTitleName] }}
		</div>

		<button mat-icon-button matTreeNodeToggle>
			<mat-icon class="mat-icon-rtl-mirror">
				{{ treeControl.isExpanded(node) ? 'arrow_drop_up' : 'arrow_drop_down' }}
			</mat-icon>
		</button>
	</mat-tree-node>
</mat-tree>
