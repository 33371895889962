<div class="bct-buttons-wrapper">
	<btn07 (onClick)="onCancelClick()">Cancel</btn07>
	<div class="bc-navigation">
		<div *ngIf="isPublishing && continueButtonText === 'Publish'" class="publish-hover">
			<span>Please wait until the current publishing action has finished.</span>
		</div>
		<btn06 class="test" *ngIf="saveButtonText == 'Back'" (onClick)="onBackClick()" [disabled]="isPublishing && saveButtonText === 'Save as Draft'">
			{{ saveButtonText }}
		</btn06>
		<btn04 class="" *ngIf="hasEditButton" [disabled]="disableEdit" (onClick)="onEditClick()">Edit</btn04>
		<btn01 class="test" (onClick)="onNextClick()" [disabled]="isPublishing && continueButtonText === 'Publish'">{{ continueButtonText }}</btn01>
	</div>
</div>
