import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { QueryModel, ReportsQueryModel } from './models/query.model';
import { QueryBuilderColumn } from './models/query-builder-column.model';
import { QueryBuilderDimension } from './models/query-builder-dimension.model';
import { QueryBuilderConditionBlock } from './models/query-builder-condition-block.model';
import { QueryBuilderAggregatorEnum } from './models/query-builder.aggregator.enum';
import { TableMetaColumn } from '../generic-table2/models/table-structure/table-meta-column.model';
import { catchError } from 'rxjs/operators';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';

export class QueryBuilderService {
	protected queryModel: QueryModel = {
		TableName: null,
		Dimensions: [],
		Columns: [],
		Where: null
	};

	constructor(protected http: HttpClient, protected baseUrl: string, protected channel: SourceChannel) {}

	public postQuery(query: QueryModel | ReportsQueryModel): Observable<any> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

		return this.http.post(this.baseUrl, query, { headers: headers });
	}

	public setBaseUrl(url: string): QueryBuilderService {
		this.baseUrl = url;
		return this;
	}

	public generateQuery(): QueryModel {
		return this.queryModel;
	}

	public addMetrics(...metrics: QueryBuilderColumn[]): QueryBuilderService {
		if (metrics) {
			this.queryModel.Columns.push(...metrics);
		}

		return this;
	}

	public addDimensions(...dimensions: QueryBuilderDimension[]): QueryBuilderService {
		if (dimensions) {
			this.queryModel.Dimensions.push(...dimensions);
		}

		return this;
	}

	public setTableName(tableName: string): QueryBuilderService {
		this.queryModel.TableName = tableName;
		return this;
	}

	public addConditionBlock(condition: QueryBuilderConditionBlock): QueryBuilderService {
		this.queryModel.Where = condition;
		return this;
	}

	public addColumnsList(columns: TableMetaColumn[]): QueryBuilderService {
		columns.forEach(column => {
			if (column.aggregationId === QueryBuilderAggregatorEnum.None) {
				this.queryModel.Dimensions.push({
					GroupColumnName: column.columnName || column.name
				});
			} else {
				this.queryModel.Columns.push({
					Name: column.columnName || column.name,
					Aggregator: this.normalizeAggregator(column.aggregationId ? column.aggregationId : (column as any)['aggregationType'])
				});
			}
		});

		return this;
	}

	public asType<T extends QueryBuilderService>(): T {
		return (this as unknown) as T;
	}

	public post(): { instance: QueryBuilderService; result: Observable<any[]> } {
		return {
			instance: this,
			result: this.postQuery(this.generateQuery()).pipe(catchError(e => of(null)))
		};
	}

	// TODO: Aggregator type should be removed as soon as its dependencies are changed to QueryBuilderAggregatorEnum
	private normalizeAggregator(aggregator: string | QueryBuilderAggregatorEnum): QueryBuilderAggregatorEnum {
		const aggregatorEnum = QueryBuilderAggregatorEnum;

		// Rather quick fix, should see the reason of needing this
		if (typeof aggregator === 'string') {
			if (aggregator === 'Average' || aggregator === 'Avg') {
				return QueryBuilderAggregatorEnum.Avg;
			}

			const key = Object.keys(aggregatorEnum).find(item => aggregatorEnum[item as any] === aggregator);

			if (key) {
				return (parseInt(key) as any) as QueryBuilderAggregatorEnum;
			}
		}

		return aggregator as QueryBuilderAggregatorEnum;
	}
}
