<div class="main">
	<div class="image-and-title">
		<div class="account-access-img">
			<img src="assets/icons/account-access.svg" />
		</div>
		<div class="account-access-title">
			<h5><strong>Accounts Access</strong></h5>
			<p>Assign which Facebook Ad Accounts can be accessed by the user.</p>
		</div>
	</div>
	<div class="account-access-main-content">
		<div class="account-access-content-header">
			<h6><strong>Choose an Access Level</strong></h6>
			<div class="accounts-access-content-radio">
				<mat-radio-button
					(click)="accessLevelEvent(userAccessLevelEnum.AllAdAccounts)"
					[checked]="!accessLevel ? true : accessLevel"
					class="radio-button"
				>
					Add to all ad accounts
				</mat-radio-button>
				<mat-radio-button (click)="accessLevelEvent(userAccessLevelEnum.IndividualAdAccounts)" [checked]="accessLevel" class="radio-button">
					Add to individual ad accounts
				</mat-radio-button>
			</div>
		</div>
		<div *ngIf="accessLevel">
			<h6 class="account-access-head"><strong>Assign Access</strong></h6>

			<div class="account-access-content border-top">
				<div class="account-access-content-sidebar">
					<div class="sidebar-title">
						<p><strong>Type</strong></p>
					</div>
					<div
						[ngClass]="{
							selected: selectedType === usersSelectedTypeEnum.BusinessManager,
							unselected: selectedType !== usersSelectedTypeEnum.BusinessManager
						}"
						(click)="businessManagerSelect()"
						class="account-access-sidebar-item"
					>
						<b>Businesses</b>
					</div>
					<div
						[ngClass]="{
							selected: selectedType === usersSelectedTypeEnum.AdAccounts,
							unselected: selectedType !== usersSelectedTypeEnum.AdAccounts
						}"
						(click)="adAccountsSelect()"
						class="account-access-sidebar-item"
					>
						<b>Ad Accounts</b>
					</div>
				</div>
				<div class="">
					<div class="rightside">
						<div class="rightside-title">
							<p><strong>Assets</strong></p>
						</div>
						<app-input-text
							*ngIf="selectedType === usersSelectedTypeEnum.BusinessManager"
							[icon]="'fa-search'"
							class="search-input"
							[control]="boSearchFormControl.get('searchFormBoInfo')"
							[label]="'Search'"
						></app-input-text>
						<app-input-text
							*ngIf="selectedType === usersSelectedTypeEnum.AdAccounts"
							[icon]="'fa-search'"
							class="search-input"
							[control]="adSearchFormControl.get('searchFormAdAccounts')"
							[label]="'Search'"
						></app-input-text>
						<div class="table-item" style="border-bottom: 1px solid #e3e3e3;">
							<div *ngIf="selectedType === usersSelectedTypeEnum.BusinessManager" class="table-item-checkbox">
								<input (change)="checkAllBO()" [checked]="checkBO" type="checkbox" class="options-input" />
							</div>
							<div *ngIf="selectedType === usersSelectedTypeEnum.AdAccounts" class="table-item-checkbox">
								<input (change)="checkAllAd()" [checked]="checkAd" type="checkbox" class="options-input" />
							</div>
							<div class="table-item-title">
								<p><strong>Name</strong></p>
							</div>
						</div>
						<div *ngIf="selectedType === usersSelectedTypeEnum.BusinessManager" class="table">
							<div *ngFor="let business of filteredBOInfo" class="table-item">
								<div class="table-item-checkbox">
									<input (change)="checkBoxBOChange(business)" [checked]="business.checked" type="checkbox" class="options-input" />
								</div>
								<div class="table-item-title">
									<p>{{ business.name }}</p>
								</div>
							</div>
						</div>
						<div *ngIf="selectedType === usersSelectedTypeEnum.AdAccounts" class="table">
							<div *ngFor="let adAccount of filteredAdAccounts" class="table-item">
								<div class="table-item-checkbox">
									<input (change)="checkBoxChange(adAccount)" [checked]="adAccount.checked" type="checkbox" class="options-input" />
								</div>
								<div class="table-item-title">
									<p>{{ adAccount.name }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
