<div
	*ngIf="isSpinnerVisible && preloaderBackground"
	@animateOnDestroy
	[class.cover-page]="shouldCoverWholePage"
	class="preloader"
	[style.background]="backgroundColor"
>
	<div class="filed_logo">
		<img alt="Filed" src="/assets/logo/lolly-logo.svg" />
	</div>
	<div class="lds-ellipsis">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>
