import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState } from '../../../../../campaign-smart-create/state/smart-create.reducer';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { InstantChatDialogComponent } from './dialog-box/instant-chat-dialog.component';

@Component({
	selector: 'app-automated-chat',
	templateUrl: './automated-chat.component.html',
	styleUrls: ['./automated-chat.component.scss']
})
export class AutomatedChatComponent implements OnInit {
	@Input() public formGroup: FormGroup;

	private unsubscriber$ = new Subject<void>();

	// INJECTED SERVICES
	protected dialog: MatDialog;
	public dialogGroup: FormGroup;
	public instantChatMethod: string;
	protected formBuilder: FormBuilder;
	protected store: Store<CampaignSmartCreateState>;

	public contentCustomQuestion: FormArray = new FormArray([]);
	public contentCustomQuestionCity: FormArray = new FormArray([]);

	constructor(private injector: Injector) {
		this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
		this.dialog = this.injector.get<MatDialog>(MatDialog);
	}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public updateInstantChat(value: string): void {
		this.instantChatMethod = value;
	}

	public openDialog(): void {
		const dialogRef = this.dialog.open(InstantChatDialogComponent, {
			height: '550px',
			width: '1024px'
		});
		dialogRef.afterClosed().subscribe(result => {
			console.log(`Dialog result: ${result}`); // Pizza!
		});

		// dialogRef.close('Pizza!');
	}
}
