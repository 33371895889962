import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ValidatorMessages } from 'src/app/shared/form-input/validatorMessagesInputs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationState } from '../../state/authentication.reducer';
import { Store } from '@ngrx/store';
import { RegisterUserAccountPlan, RegisterUserDuration, RegistrationDetail } from '../../state/authentication.action';
import { TarrifPlansInterface } from '../../sign-up/subscribe.interface';
import { sleep } from '../utils/signup-utils';

import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy {
	public errorMessage: string;
	private unsubscriber$: Subject<void> = new Subject<void>();
	public validationMessages: ValidatorMessages[];
	public tarrifPlans: TarrifPlansInterface[];
	public selectedTarrifPlan: TarrifPlansInterface;
	public submitting: boolean = false;
	public loadingTarrifPlan: boolean = false;
	public durationControl: FormControl = new FormControl('', [Validators.required]);
	public isPageScroll: boolean = true;
	constructor(private router: Router, private dialog: MatDialog, private store: Store<AuthenticationState>, private activatedRoute: ActivatedRoute) {}

	public ngOnInit(): void {
		this.store.dispatch(
			new RegistrationDetail({
				name: '',
				lastname: '',
				phone: 0,
				dialCode: '',
				email: '',
				password: '',
				plan: 0,
				duration: '',
				token: '',
				companyName: ''
			})
		);
		this.loadTarrifPlan();
		this.getActivatedRoute();
		this.onSelectDuration('month');
	}

	public getActivatedRoute() {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			if (params && params?.id) {
				let currentPlan = this.tarrifPlans.filter(plan => plan.id == params.id);
				this.onSelectDuration('month');
				localStorage.setItem('tarrifPlan', JSON.stringify(currentPlan[0] || null));
				this.store.dispatch(new RegisterUserAccountPlan(+params?.id));
				this.router.navigate(['/authentication/register-email']);
			}
		});
	}

	public ngOnDestroy(): void {}

	public loadTarrifPlan(): void {
		this.tarrifPlans = [
			{
				id: 146,
				tag: 'Free trial',
				price: 'Free Trial',
				amount: 0.0,
				tagLine: '',
				currency: 'USD',
				header: '',
				features: ['All features', 'Dedicated Service', 'Up to 40 million impressions', '700 SignNow contracts'],
				buttonText: 'Select Plan'
			},
			{
				id: 142,
				tag: 'Andrew Offer',
				price: 250.0,
				amount: 250.0,
				tagLine: 'Per month',
				currency: 'USD',
				header: 'Recommended',
				features: ['All features', 'Expert Service', 'Up to 20 million impressions', '300 SignNow contracts'],
				buttonText: 'Select Plan'
			},
			{
				id: 147,
				tag: 'Offer 2',
				price: 500.0,
				amount: 500.0,
				tagLine: 'Per month',
				currency: 'USD',
				header: '',
				features: ['All features', 'Advanced Service', 'Up to 10 million impressions', '150 SignNow contracts'],
				buttonText: 'Select Plan'
			}
		];
	}
	public onSelectDuration(duration: string): void {
		for (let ln in this.tarrifPlans) {
			let index = ln;
			if (this.tarrifPlans[ln].price !== 'Free Trial') {
				this.tarrifPlans[ln].amount = duration == 'month' ? this.tarrifPlans[ln].price * 1 : this.tarrifPlans[ln].price * 12;
				this.tarrifPlans[ln].tagLine = duration == 'month' ? 'Per month' : 'Per year';
				//this.tarrifPlans[ln].buttonText = +index === 0 && duration == 'month' ? 'Current Plan' : 'Upgrade Plan';
				this.tarrifPlans[ln].disable = +index === 0 && duration == 'month' ? true : false;
			}
		}
		this.durationControl.setValue(duration);
		this.store.dispatch(new RegisterUserDuration(duration));
	}
	public onSelectTarrifPlan(event: TarrifPlansInterface): void {
		this.selectedTarrifPlan = event;
		localStorage.setItem('tarrifPlan', JSON.stringify(this.selectedTarrifPlan));
		this.store.dispatch(new RegisterUserAccountPlan(+this.selectedTarrifPlan.id));
		this.submitting = true;
		sleep(3000);
		this.router.navigate(['/authentication/register-email']);
	}
	public goToSignin(): void {
		this.router.navigate(['/authentication']);
	}
}
