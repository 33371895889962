<div class="recommendation-container" [ngStyle]="setBorder()" *ngIf="!isDismissed">
	<div *ngIf="isOnSmartEdit" class="dexter-wrapper">
		<div class="dexter-image-container">
			<img src="assets/icons/icon-dexter-x-dark.svg" />
		</div>
		<label>Dexter recommendations</label>
	</div>
	<div class="top-info">
		<div class="details-wrapper">
			<div class="detail">
				<div class="inside-detail">
					<img [src]="iconUrl" />
					<label [matTooltip]="newRecommendation.priority" [matTooltipPosition]="'above'">
						{{ newRecommendation.priority | uppercase }}
					</label>
				</div>
			</div>
			<div class="detail">
				<div class="inside-detail">
					<img src="../../../assets/icons/optimize/level-icon.svg" />
					<label [matTooltip]="newRecommendation.level" [matTooltipPosition]="'above'">
						{{ newRecommendation.level | uppercase }}
					</label>
				</div>
			</div>
			<div class="detail">
				<div class="inside-detail">
					<img src="../../../assets/icons/optimize/metric-icon.svg" />
					<label [matTooltip]="newRecommendation.metrics[0].displayName" [matTooltipPosition]="'above'">
						{{ newRecommendation.metrics[0].displayName | uppercase }}
					</label>
				</div>
			</div>
			<div class="detail">
				<div class="inside-detail">
					<img src="../../../assets/icons/optimize/structure-name-icon.svg" />
					<label [matTooltip]="newRecommendation.structureName" [matTooltipPosition]="'above'">
						{{ newRecommendation.structureName | limitLength: 17:'...' }}
					</label>
				</div>
			</div>
		</div>
		<div
			*ngIf="!isOnSmartEdit"
			class="three-dots-container"
			[ngClass]="{ 'active-state': isDismissVisible }"
			appClickOutside
			(clickOutside)="onClickOutsideOption()"
			(mouseenter)="onMouseEnterOptionsContainer()"
			(mouseleave)="onMouseLeaveOptionsContainer()"
			(click)="onOptionsButtonClick()"
		>
			<img [src]="threeDotsImageSource" class="three-dots" />
			<div class="dismiss-option" *ngIf="isDismissVisible" (click)="onDismissButtonClick()">
				<label>Dismiss</label>
			</div>
		</div>
	</div>
	<div class="middle-info">
		<div class="left-side">
			<h6>{{ newRecommendation.title }}</h6>
			<p>
				{{ newRecommendation.subText }}
				<label class="toggle-recommendation" (click)="onToggleSeeMore()">{{ isExpanded ? 'Show less.' : 'Show more.' }}</label>
			</p>
		</div>
		<div class="right-side">
			<div *ngIf="!isOnSmartEdit" class="button-apply">
				<btn04 [icon]="'plus'" (onClick)="onEditButtonClick()">Edit</btn04>
			</div>
			<div *ngIf="!isOnSmartEdit" class="button-apply">
				<i-tooltip [title]="applyTooltip">
					<btn03 (onClick)="onApplyButtonClick()" [disabled]="!newRecommendation.isApplicable">Apply</btn03>
				</i-tooltip>
			</div>
		</div>
	</div>
	<div class="lower-info" [hidden]="!isExpanded" *ngIf="newRecommendation.algorithmType !== 'breakdown_strategy'">
		<label>{{ newRecommendation.analysis }}</label>
		<div class="chart-container">
			<lolly-spinning-loader *ngIf="!isLoaded"></lolly-spinning-loader>
			<app-generic-charts [chartColor]="chartColorArray" [chartData]="chartDataModel" [zoom]="true"></app-generic-charts>
			<div class="chart-option" *ngIf="isLoaded">
				<div class="button-range">
					<btn07 (onClick)="changeDataRange('1d')" [ngClass]="{ active: dataRange === '1d' }" [disabled]="!aboveOneDay">1d</btn07>
					<btn07 (onClick)="changeDataRange('3d')" [ngClass]="{ active: dataRange === '3d' }" [disabled]="!aboveThreeDays">3d</btn07>
					<btn07 (onClick)="changeDataRange('7d')" [ngClass]="{ active: dataRange === '7d' }" [disabled]="!aboveOneWeek">7d</btn07>
					<btn07 (onClick)="changeDataRange('2w')" [ngClass]="{ active: dataRange === '2w' }" [disabled]="!aboveTwoWeeks">2w</btn07>
					<btn07 (onClick)="changeDataRange('1m')" [ngClass]="{ active: dataRange === '1m' }" [disabled]="!aboveOneMonth">1m</btn07>
					<btn07 (onClick)="changeDataRange('2m')" [ngClass]="{ active: dataRange === '2m' }" [disabled]="!aboveTwoMonths">2m</btn07>
					<btn07 (onClick)="changeDataRange('max')" [ngClass]="{ active: showAll }">Max</btn07>
				</div>
				<div class="chart-type">
					<drp07
						[data]="chartTypes"
						[control]="activeChartTypeControl"
						(valueChanges)="changeChartType($event)"
						[placeholder]="'Select chart type'"
					></drp07>
				</div>
			</div>
		</div>
		<div class="recommendation-wrapper">
			<div class="image-container">
				<img src="assets/icons/icon-dexter-x-dark.svg" />
			</div>
			<div class="speech-bubble-triangle-space"></div>
			<div class="recommendation-text">
				<label>
					{{ this.newRecommendation.quote }}
				</label>
			</div>
		</div>
	</div>

	<div class="lower-info" [hidden]="!isExpanded" *ngIf="newRecommendation.algorithmType === 'breakdown_strategy'">
		<label>{{ newRecommendation.analysis }}</label>
		<div class="chart-container">
			<!--  *ngIf="!isLoaded"><> -->
			<lolly-spinning-loader *ngIf="!isLoaded"></lolly-spinning-loader>
			<app-generic-charts [chartColor]="chartColorArray" [chartData]="chartDataModel" [zoom]="true"></app-generic-charts>
		</div>
		<div class="recommendation-wrapper">
			<div class="image-container">
				<img src="assets/icons/icon-dexter-x-dark.svg" />
			</div>
			<div class="speech-bubble-triangle-space"></div>
			<div class="recommendation-text">
				<label>
					{{ this.newRecommendation.quote }}
				</label>
			</div>
		</div>
	</div>

	<label class="toggle-recommendation bottom-text" [ngStyle]="{ 'margin-bottom': '10px' }" (click)="onToggleSeeMore()" *ngIf="isExpanded">
		{{ isExpanded ? 'Show less.' : 'Show more.' }}
	</label>
</div>
