import { Injectable } from '@angular/core';
import { BaseApiUrl } from '../base-api-urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TableView } from '../../_models/TableModels/table-view';
import { TableColumn } from '../../_models/TableModels/table-column';

@Injectable({
	providedIn: 'root'
})
export class AudienceMetadataService {
	// private readonly baseUrl = `${BaseApiUrl.Audience}metadatas`;

	constructor(private http: HttpClient) {}

	// public getTableViews(): Observable<TableView[]> {
	// 	const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	// 	return this.http.get<TableView[]>(`${this.baseUrl}/views`, { headers: headers });
	// }

	// public getTableColumns(): Observable<TableColumn[]> {
	// 	const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	// 	return this.http.get<TableColumn[]>(`${this.baseUrl}?tablename=FacebookAudience`, {
	// 		headers: headers
	// 	});
	// }
}
