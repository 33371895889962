export enum StripeFormTypeEnum {
	incorrectAddress = 'incorrect_address',
	incorrectCvc = 'incorrect_cvc',
	incorrectNumber = 'incorrect_number',
	invalidNumber = 'invalid_number',
	accountNumberInvalid = 'account_number_invalid',
	invalidCardType = 'invalid_card_type',
	invalidCvc = 'invalid_cvc',
	invalidExpiryMonth = 'invalid_expiry_month',
	invalidExpiryYear = 'invalid_expiry_year',
	cardDeclined = 'card_declined',
	billingZip = 'card[billing_zip]'
}
