<app-user-header></app-user-header>
<div class="page-container">
	<div class="form-area">
		<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<div class="title">We are sad to see you go.</div>
					<div class="sub-title">Your account is now confirmed and it is currently in the passed stage.</div>

					<div class="box">
						<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
						<div class="detail">
							<div class="title">Your account is now on paused</div>
							<div class="tag">We’ll miss you, Andrew</div>
							<div class="subtitle">Thank you for been our valuable customer.</div>
							<div class="tagline">An email confirmation has been sent to you in regards to your latest account status.</div>
						</div>
					</div>
				</div>
				<div class="form-btn">
					<button class="btn-signup left" (click)="onNext()">
						BACK TO ACCOUNT HOME PAGE
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
