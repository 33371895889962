export enum ViewName {
	MasterView = 'MasterView',
	Performance = 'Performance',
	Purchases = 'Purchases',
	Leads = 'Leads',
	WebsiteTraffic = 'Website Traffic',
	Engagement = 'Engagement',
	Awareness = 'Awareness'
}

export enum ColumnType {
	Percentage = 'PERCENTAGE',
	Currency = 'CURRENCY',
	Number = 'NUMBER'
}

export enum ViewsTrendEnum {
	Negative = 'NEGATIVE',
	Positive = 'POSITIVE',
	Undefined = 'UNDEFINED'
}
