export enum GeoLocationEnum {
	Country = 'country',
	Region = 'region',
	City = 'city',
	SubCity = 'subcity',
	GeoMarket = 'geo_market',
	ElectoralDistrict = 'electoral_district',
	Neighborhoods = 'neighborhood',
	MidArea = 'medium_geo_area',
	CountryGroup = 'country_group',
	Zip = 'zip'
}
