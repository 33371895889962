import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState } from '../../../../../campaign-smart-create/state/smart-create.reducer';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { MapperService } from '../../../../services/mapper.service';
import { ButtonTypeEnum } from '../../../../button-component/button-type.enum';
import { ButtonClassEnum } from '../../../../button-component/button-class.enum';
import { MatDialog } from '@angular/material/dialog';
import { BuildUrlParameterDialogComponent } from '../build-url-parameter-dialog/build-url-parameter-dialog.component';
import { CatalogNodeInterface } from '../../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { DropdownData } from '../../../../dropdown-search-select/dropdown-data.interface';
import { Subject } from 'rxjs';
import { CallToActionEnum } from '../../../../../campaign-smart-create/models/call-to-action.enum';
import { AdFormatsEnum } from '../../../../../campaign-smart-create/models/ad-formats.enum';
import { getCallToAction, getTemplateFieldsFromCatalog } from '../../../../../state/campaign-common-state/campaign-common-reducer';
import { SubObjectives } from '../../../../../campaign-smart-create/models/sub-objectives';
import { dynamicInputDropDownInterface } from '@filed-com/filed-lib/lib/inputs/input.interface';

@Component({
	selector: 'app-ad-creation-input-form',
	templateUrl: './ad-creation-input-form.component.html',
	styleUrls: ['./ad-creation-input-form.component.scss']
})
export class AdCreationInputFormComponent implements OnInit {
	@Input() public formGroup: FormGroup;
	@Input() public callToActions: DropdownData<CatalogNodeInterface>[] = [];
	@Input() public isPageLikesSelected: boolean;
	@Input() public isPostLikesSelected: boolean;
	@Input() public isWebsiteUrl: boolean;
	@Input() public adFormat: AdFormatsEnum;
	@Input() public adCreateFormGroup: FormGroup;
	@Input() public cardIndex: number;
	@Input() public subObjective: string;
	@Input() public itemCount: number;
	public subObjectives = SubObjectives;
	public catalogTemplateFields: dynamicInputDropDownInterface[];
	public explanationDescription =
		"The description will be shown in your ad if it's likely to " +
		'resonate with the person seeing it. It will only appear in some placements and its position will vary.';
	public explanationHeadline =
		'The headline will appear in most placements, ' + 'but its position will vary by placement. Headlines over 40 characters may be cut off.';
	public explanationPrimaryColor =
		'The primary text for your ad appears in most placements, ' +
		'though the position varies. You can use @ to tag Facebook profiles or Pages. We recommend 125 characters or fewer.';

	public isCtaSelected: boolean;
	public adFormatEnum = AdFormatsEnum;
	public buttonType = ButtonTypeEnum;
	public buttonClass = ButtonClassEnum;
	private unsubscriber$ = new Subject<void>();
	public isCatalogSales: boolean;
	public isAppInstalled: boolean;
	public isFormInnitializedForAppInstall: boolean = false;

	constructor(private dialog: MatDialog, public mapperService: MapperService, private store: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {
		this.getCallToAction();
		this.unwantedCallToActions();
		this.isCatalogSales = this.subObjective === SubObjectives.CatalogSalesText;
		this.isAppInstalled = this.subObjective === SubObjectives.AppInstallText;
		this.getTemplateFields();
	}

	public ngOnChanges(): void {
		this.isFormInnitializedForAppInstall = this.getPrimaryTextForm(this.cardIndex).length > 0 ? true : false;
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public unwantedCallToActions(): void {
		this.callToActions = this.callToActions.filter(item => item.value !== CallToActionEnum.WhatsappMessage);
	}

	public checkInputForm(): void {
		const urlValidator = "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$";
		if (this.isPostLikesSelected && this.formGroup.get('callToAction').value === CallToActionEnum.NoButton) {
			this.isCtaSelected = false;
			this.adCreateFormGroup.get('websiteUrl').patchValue(null);
			this.adCreateFormGroup.get('websiteUrl').clearValidators();
			this.adCreateFormGroup.get('websiteUrl').updateValueAndValidity();
		}
		if (this.isPostLikesSelected && this.adCreateFormGroup.get('callToAction').value !== CallToActionEnum.NoButton) {
			this.isCtaSelected = true;
			this.adCreateFormGroup.get('websiteUrl').setValidators([Validators.required, Validators.pattern(urlValidator)]);
			this.adCreateFormGroup.get('websiteUrl').updateValueAndValidity();
		}
	}

	public getCallToAction(): void {
		if (!this.callToActions) {
			this.store
				.pipe(
					select(getCallToAction),
					skipWhile(result => !result),
					take(1)
				)
				.subscribe(callToActions => {
					this.callToActions = this.mapperService.convertToDropdownData(callToActions, 'name', 'displayNameAutogen');
				});
		}
	}
	public onBuildParameterClick(): void {
		this.dialog
			.open(BuildUrlParameterDialogComponent, {
				panelClass: 'cif-build-url-parameter',
				data: this.formGroup.get('websiteUrl').value
			})
			.afterClosed()
			.subscribe(param => {
				if (param) {
					this.formGroup.get('websiteUrl').patchValue(param);
				}
			});
	}

	public getAdCardForms(): AbstractControl[] {
		const advertItemsForm = this.formGroup?.get('adCardsItemData') as FormArray;
		return advertItemsForm ? advertItemsForm.controls : null;
	}

	public adCardsItemDataObject(): FormArray {
		return this.formGroup.get('adCardsItemData') as FormArray;
	}
	public getPrimaryTextForm(Index: number): FormArray {
		return this.adCardsItemDataObject().at(Index).get('primary_text') as FormArray;
	}

	public getHeadlineForm(Index: number): FormArray {
		return this.adCardsItemDataObject().at(Index).get('headline') as FormArray;
	}

	public getMediaUrlForm(Index: number): FormArray {
		return this.adCardsItemDataObject().at(Index).get('media_url') as FormArray;
	}
	private getTemplateFields(): void {
		if (this.subObjective === this.subObjectives.CatalogSalesText) {
			this.store.pipe(select(getTemplateFieldsFromCatalog), takeUntil(this.unsubscriber$)).subscribe((res: any) => {
				if (res) {
					res.pop();
					const convertData: dynamicInputDropDownInterface[] = [];
					// @ts-ignore
					res.forEach(item => {
						if (item) {
							const dd: dynamicInputDropDownInterface = {
								data: item,
								value: item?.name,
								name: item.display_name_autogen,
								text: item.display_name_autogen
							};
							convertData.push(dd);
						}
					});
					this.catalogTemplateFields = convertData;
				}
			});
		}
	}

	public getCallToActionValue(value: any): string {
		return value ?? this.callToActions[0]?.value ?? null;
	}
}
