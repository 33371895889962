import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[appScrollOutside]'
})
export class ScrollOutsideDirective {
	@Output() public scrollOutside = new EventEmitter();

	constructor() {}

	@HostListener('document:wheel', ['$event'])
	public onScroll(event: WheelEvent) {
		const scrollTarget = (event.target as Element).className as string;
		const insideScroll = scrollTarget.indexOf('ng-option ng-star-inserted') > -1;
		if (!insideScroll) {
			this.scrollOutside.emit();
		}
	}
}
