import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionTypeEnum } from '../../../../back-office/users/action-type.enum';
import { ActionParamsInterface } from '../../../../back-office/models/action-params.interface';
import { PermissionsDirectiveInterface } from '../../../../shared/permisions/models/permissions-directive.interface';
import { Modules } from '../../../../shared/permisions/enums/modules';
import { MiscellaneousPermissions } from '../../../../shared/permisions/enums/miscellaneous-permissions';
import { SettingsPermissions } from 'src/app/shared/permisions/enums/settings-permissions';

@Component({
	selector: 'app-actions-button-user-management',
	templateUrl: './actions-button-user-management.component.html',
	styleUrls: ['./actions-button-user-management.component.scss']
})
export class ActionsButtonUserManagementComponent {
	public params: ActionParamsInterface;
	private unsubscriber$: Subject<void> = new Subject<void>();

	public actionTypeButton = ActionTypeEnum;

	public agInit(params: ActionParamsInterface): void {
		this.params = params;
	}

	public refresh(params?: ActionParamsInterface): boolean {
		return true;
	}
	public miscellaneousPermissions = MiscellaneousPermissions;
	public settingPermission = SettingsPermissions;

	public permissionModelFull: PermissionsDirectiveInterface = {
		moduleName: Modules.Miscellaneous,
		permissions: [
			this.miscellaneousPermissions.IsBusinessOwner,
			this.miscellaneousPermissions.IsClientEmployee,
			this.settingPermission.SettingsUserManagementFull
		]
	};
	public permissionModelEditOnly: PermissionsDirectiveInterface = {
		moduleName: Modules.Miscellaneous,
		permissions: [
			this.miscellaneousPermissions.IsBusinessOwner,
			this.miscellaneousPermissions.IsClientEmployee,
			this.settingPermission.SettingsUserManagementEdit
		]
	};

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public edit(): void {
		const userId = this.params.node.data.id;
		if (this.params.onClick instanceof Function) {
			const params = {
				actionType: this.actionTypeButton.Edit,
				rowData: this.params.node.data,
				userId: userId
			};
			this.params.onClick(params);
		}
	}

	public delete(): void {
		const userId = this.params.node.data.id;
		if (this.params.onClick instanceof Function) {
			const params = {
				actionType: this.actionTypeButton.Delete,
				rowData: this.params.node.data,
				userId: userId
			};
			this.params.onClick(params);
		}
	}
}
