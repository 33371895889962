export const SubObjectives = {
	CatalogSales: 'PRODUCT_CATALOG_SALES_X_UNDEFINED',
	CatalogSalesText: 'PRODUCT_CATALOG_SALES',
	CatalogSalesName: 'Catalog Sales',
	LocalAwareness: 'LOCAL_AWARENESS_X_UNDEFINED',
	LeadGeneration: 'LEAD_GENERATION_X_UNDEFINED',
	LeadGenerationNew: 'LEAD_GENERATION',
	LinkCLicks: 'LINK_CLICKS',
	PageLikes: 'PAGE_LIKES',
	PostLikes: 'POST_ENGAGEMENT',
	Conversion: 'CONVERSIONS',
	BrandAwareness: 'BRAND_AWARENESS_X_UNDEFINED',
	BrandAwarenessName: 'Brand Awareness',
	VideoViews: 'VIDEO_VIEWS',
	Message: 'MESSAGES_X_UNDEFINED',
	MessageText: 'MESSAGES',
	AppInstallText: 'APP_INSTALLS',
	AppInstall: 'APP_INSTALLS_X_UNDEFINED',
	AppInstallName: 'App Installs',
	WebsiteConversion: 'CONVERSIONS_X_WEBSITE',
	WebsiteConversionName: 'Website Conversions',
	ProductCatalogSales: 'PRODUCT_CATALOG_SALES',
	StoreVisits: 'STORE_VISITS_X_UNDEFINED'
};
