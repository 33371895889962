import { getCurrencySymbol } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, mergeMap, retry, skipWhile, take, takeUntil } from 'rxjs/operators';
import { Helper } from '../../../reports/helper';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { ExpansionPanelHelper } from '../../../shared/expansion-panel/expansion-panel-helper.service';
import { ImageStateEnum } from '../../../shared/hover-select-directive/image-state.enum';
import { PopUpInterface } from '../../../shared/models/generic-popup-interface';
import { GenericPopupComponent } from '../../../shared/pop-ups/generic-popup/generic-popup.component';
import { Gender } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/gender';
import {
	ParentTargetingTypeEnum,
	TargetingTypeEnum
} from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/targeting-type.enum';
import { HideGlobalSpinner, ShowGlobalSpinner, UpdatePublishStatus } from '../../../shared/state/shared.actions';
import { getSelectedAdAccount, SharedState } from '../../../shared/state/shared.reducer';
import { SelectedAdAccountModel } from '../../../shared/state/state-entities/cached-ad-accounts.model';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { AdAccountApiService } from '../../../_services/facebook-accounts/ad-account-api.service';
import { CatalogNodeInterface } from '../../catalogs/catalog-node.interface';
import { CampaignBudgetOptimizationInterface } from '../../models/campaign-budget-optimization.interface';
import { CampaignStepsEnum } from '../../models/campaign-steps.enum';
import { CampaignTemplate } from '../../models/campaign-template';
import { CatalogNode } from '../../models/catalog-node';
import { CreateCampaignRequest } from '../../models/create-campaign-request';
import { SubObjectives } from '../../models/sub-objectives';
import { TargetingAudience } from '../../models/targeting-audience';
import { CampaignSmartCreateMapperService } from '../../services/campaign-smart-create-mapper.service';
import { CampaignSmartCreatePythonService } from '../../services/campaign-smart-create-python.service';
import { IQuickNavigation } from '@filed-com/filed-lib/lib/cards/models/quick-navigation.model';
import { QuickNavigationService } from '@filed-com/filed-lib';
import {
	ClearCurrentStep,
	CreateCampaignTemplate,
	UpdateAdverts,
	UpdateCampaignBudgetOptimization,
	UpdateCampaignCurrentStep,
	UpdateCampaignDate,
	UpdateCampaignName,
	UpdateCampaignTemplate,
	UpdateCatalogId,
	UpdateCurrency,
	UpdateInstagramId,
	UpdateObjective,
	UpdateSpecialAdCategory,
	UpdateSplitByAgeRange,
	UpdateSplitByGender,
	UpdateTargeting
} from '../../state/smart-create.actions';
import {
	CampaignSmartCreateState,
	getAdSetOptimization,
	getCampaign,
	getCampaignBudgetOptimization,
	getCampaignDate,
	getCurrency,
	getLastStepId,
	getObjective,
	getSpecialAdCategory
} from '../../state/smart-create.reducer';
import { BuildCampaignPanelsEnum } from './build-campaign-panels.enum';
import { PublishStatusInterface } from '../../../shared/state/state-entities/publish/publish-status.interface';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';
import { DateRangeX, PublicDateModel } from '../../../shared/calendar/calendar.models';
import moment from 'moment';
import { CampaignDateChoices } from '../../../shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { DateCardInterface } from '../../../shared/smart-create-edit-components/step-two-components/date-card/campaign-date-card.interface';
import { BudgetAllocatedEnum } from '../../models/budget-allocated.enum';
import { Timer } from '../../../shared/models/time-const.model';
import {
	getBidStrategiesFromCatalog,
	getSpecialAdCategoriesFromCatalog,
	selectProductCatalog
} from '../../../state/campaign-common-state/campaign-common-reducer';
import { dynamicInputDropDownInterface } from '@filed-com/filed-lib/lib/inputs/input.interface';
import { LoadAdvertisableApp, LoadFacebookPixels, LoadProductCatalogSuccess } from '../../../state/campaign-common-state/campaign-common-actions';

@Component({
	selector: 'app-build-campaign-template',
	templateUrl: './build-campaign-template.component.html',
	styleUrls: ['./build-campaign-template.component.scss'],
	providers: [ExpansionPanelHelper]
})
export class BuildCampaignTemplateComponent implements OnInit, OnDestroy {
	public readonly step = CampaignStepsEnum.One;
	public campaignPanels = BuildCampaignPanelsEnum;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	public label = 'Enter Campaign Name';
	public isStepValid = false;
	public selectedObjective: string;
	public bidStrategy: CatalogNodeInterface[];
	public deliveryName: CatalogNode;
	public deliveryType: CatalogNode;
	public campaign: CampaignTemplate;
	public objectives: CatalogNodeInterface[];
	public campaignFormGroup: FormGroup;
	public showBidStrategy: boolean;
	public campaignBudgetOptimization: CampaignBudgetOptimizationInterface;
	public currency: string;
	public isSpecialAdCategoryToggled: boolean;
	public specialAdCategories: CatalogNodeInterface[];
	public selectedSpecialAdCategory: boolean;
	public minimumBudget: number;
	public validationBudget: number;
	public preselectedDate: PublicDateModel = {
		date: {
			startDate: moment(),
			endDate: moment().add(1, 'month')
		}
	};
	public isCampaignLifeTimeSelected = false;
	public isAdSetLifeTimeSelected = false;
	public navigations: IQuickNavigation[] = [
		{ name: 'Campaign Name', targetId: 'campaign-name' },
		{ name: 'Campaign Objective', targetId: 'campaign-objective' },
		{ name: 'Special Ad Category', targetId: 'special-ad-category' },
		{ name: 'Campaign Budget Optimization', targetId: 'campaign-budget' }
	];
	public campaignDate = new DateRangeX<string>();
	public dateChoiceType: CampaignDateChoices;
	public panels = BuildCampaignPanelsEnum;
	public isDateCardVisible: boolean;
	public selectedIndex: number;
	private unsubscriber$ = new Subject<void>();
	private lastStepId: CampaignStepsEnum;
	private lastChosenSpecialAdCategory: string;
	private isNewCampaign: boolean;
	public selectedAccount: SelectedAdAccountModel;
	private previousSubObjective: string;
	public subObjectives = SubObjectives;
	public catalogueTemplateFields: dynamicInputDropDownInterface[];
	public isCatalogSales: boolean;
	public productCatalogs: any;
	public isAppInstall: boolean;
	public showErrorTextMsg: boolean;
	public accountHasPixel: boolean = true;

	constructor(
		public expansionPanelHelper: ExpansionPanelHelper<BuildCampaignPanelsEnum>,
		public dialog: MatDialog,
		public python: CampaignSmartCreatePythonService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private toastService: ToastNotificationService,
		private store: Store<CampaignSmartCreateState>,
		private sharedStore: Store<SharedState>,
		private accountService: AdAccountApiService,
		private mapperService: CampaignSmartCreateMapperService,
		private smartCreateService: CampaignSmartCreateService,
		private quickNavigationService: QuickNavigationService
	) {}

	public ngOnInit(): void {
		this.store
			.select(selectProductCatalog)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(res => (this.productCatalogs = res));
		this.registerSelection();
		// this.getActivatedRouteData();
		this.checkBudget();
		this.getObjectives();
		this.checkAccountCurrency();
		this.getSpecialCards();
		// this.checkPublish();
		this.getSubObjective();
		this.getCampaignDateInfo();
		this.selectedIndex = 0;
		this.isCatalogSales = this.selectedObjective === this.subObjectives.ProductCatalogSales;
		this.store.dispatch(new LoadAdvertisableApp());

		this.checkQuickNavigation();
	}

	public ngOnChanges(): void {
		let selected = localStorage.getItem('selectedCampaignDate');
		this.isCampaignLifeTimeSelected = selected === '1' ? true : false;
	}

	public getSubObjective(): void {
		this.store.pipe(select(getObjective), take(1)).subscribe(objective => {
			this.previousSubObjective = objective;
		});
	}

	public registerSelection(): void {
		this.quickNavigationService
			.getSelectedNavigation()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((selectedIndex: number) => (this.selectedIndex = selectedIndex));
	}

	public onPanelClick(index: number): void {
		this.quickNavigationService.setSelectedNavigation(index);
	}

	public updateDateCardVisibility(isVisible: boolean): void {
		this.isDateCardVisible = isVisible;
		this.setDefaultDate();
	}

	public checkAdSetBudget(): void {
		this.store.pipe(select(getAdSetOptimization), takeUntil(this.unsubscriber$)).subscribe(isActive => {
			if (isActive && isActive.budgetAllocatedTypeId === BudgetAllocatedEnum.LifeTime) {
				this.isAdSetLifeTimeSelected = true;
			}
		});
	}

	public updateCampaignDateStatus(status: DateCardInterface): void {
		this.store.dispatch(new UpdateCampaignDate({ date: status.campaignDate, dateChoiceType: status.dateChoiceType }));
		this.store.pipe(select(getCampaignBudgetOptimization), take(1)).subscribe(budget => {
			if (budget) {
				if (budget.budgetAllocatedTypeId === BudgetAllocatedEnum.LifeTime) {
					this.validationBudget = Math.round(this.dateLength() * this.minimumBudget);
				} else if (budget.budgetAllocatedTypeId === BudgetAllocatedEnum.Daily) {
					this.validationBudget = this.minimumBudget;
				} else {
					return;
				}
				this.campaignFormGroup
					.get('budgetOptimization')
					.get('amount')
					.setValidators([Validators.min(this.validationBudget)]);
				this.campaignFormGroup.get('budgetOptimization').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });
				const budgetOptimization: CampaignBudgetOptimizationInterface = {
					amount: budget.amount,
					budgetAllocatedTypeId: budget.budgetAllocatedTypeId,
					minimumBudget: this.validationBudget,
					bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
					deliveryType: 'standard'
				};
				this.store.dispatch(new UpdateCampaignBudgetOptimization(budgetOptimization));
			}
		});
	}

	public defaultCampaignDate(): DateRangeX<string> {
		const result = new DateRangeX<string>();
		result.startDate = moment().toISOString(true);
		result.endDate = moment().add(1, 'month').toISOString(true);
		return result;
	}

	public getCampaignDateInfo(): void {
		this.store.pipe(select(getCampaignDate), takeUntil(this.unsubscriber$), debounceTime(500)).subscribe(date => {
			if ((date && date.dateChoiceType === CampaignDateChoices.Custom) || (date && (!this.isCampaignLifeTimeSelected || !this.isAdSetLifeTimeSelected))) {
				this.campaignDate = date.date;
				this.dateChoiceType = date.dateChoiceType;
				this.preselectedDate.date.startDate = moment(this.campaignDate?.startDate);
				this.preselectedDate.date.endDate = moment(this.campaignDate?.endDate);
			} else {
				this.setDefaultDate();
			}
		});
	}

	public setDefaultDate(): void {
		const campaignDate = this.defaultCampaignDate();
		this.dateChoiceType = CampaignDateChoices.Custom;
		this.campaignDate = campaignDate;
		if (this.isDateCardVisible) {
			this.store.dispatch(new UpdateCampaignDate({ date: campaignDate, dateChoiceType: this.dateChoiceType }));
		} else {
			this.store.dispatch(new UpdateCampaignDate({ date: null, dateChoiceType: null }));
		}
	}

	public getSpecialCards(): void {
		this.store
			.pipe(
				select(getSpecialAdCategoriesFromCatalog),
				skipWhile(categories => !categories),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(categories => {
				this.specialAdCategories = categories.filter(item => item.name !== 'NONE');
				this.specialAdCategories.pop();
				this.store.pipe(select(getSpecialAdCategory), take(1)).subscribe(category => {
					this.isSpecialAdCategoryToggled = !!category;
					if (this.isSpecialAdCategoryToggled) {
						this.specialAdCategories.find(item => item.name === category).imageState = ImageStateEnum.Pressed;
					}
				});
			});
	}

	// public checkPublish(): void {
	// 	this.smartCreateService
	// 		.checkPublishStatus()
	// 		.pipe(take(3))
	// 		.subscribe(response => {
	// 			if (response) {
	// 				const status: PublishStatusInterface = {
	// 					isActive: true,
	// 					totalStructures: response.totalStructures,
	// 					progressStatus: response.publishedStructures
	// 				};
	// 				this.sharedStore.dispatch(new UpdatePublishStatus(status));
	// 			} else {
	// 				const notActive: PublishStatusInterface = {
	// 					isActive: false,
	// 					totalStructures: null,
	// 					progressStatus: null
	// 				};
	// 				this.sharedStore.dispatch(new UpdatePublishStatus(notActive));
	// 			}
	// 		});
	// }

	// public getMinBudget(): void {
	// 	this.smartCreateService
	// 		.getBudgetValidation(this.selectedAccount.adAccount.id)
	// 		.pipe(take(1))
	// 		.subscribe(response => {
	// 			this.minimumBudget = response.minimumAdAccountDailyBudget;
	// 		});
	// }

	public dateLength(): number {
		let numberOfDays = 0;
		this.store.pipe(select(getCampaignDate), take(1)).subscribe(response => {
			if (response?.date) {
				const startDate = moment(response.date.startDate);
				const endDate = moment(response.date.endDate);
				numberOfDays = Math.round(moment.duration(endDate.diff(startDate)).asDays());
			}
		});
		return numberOfDays;
	}

	public specialAdCategoryPopUp(): void {
		if (!this.selectedSpecialAdCategory) {
			this.dialog
				.open<GenericPopupComponent, PopUpInterface>(GenericPopupComponent, {
					data: {
						image: '/assets/icons/info-filed-blue.svg',
						message:
							'If you choose to continue with the Special ad category, some information will default, and your campaign will change settings to match the Special Ad Category format.',
						header: 'Settings will be changed',
						actionAnswer: 'Continue',
						noActionAnswer: 'Cancel'
					},
					width: '503px',
					height: 'auto',
					panelClass: ['generic-popup']
				})
				.afterClosed()
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe((confirmed: boolean) => {
					if (confirmed) {
						const defaultTarget = this.updateTargetingOnSpecialAdCategory();
						this.store.dispatch(new UpdateTargeting(defaultTarget));
						this.selectedSpecialAdCategory = true;
					} else {
						this.campaignFormGroup.get('specialAdCategory').patchValue(null);
						this.campaignFormGroup.get('specialAdCategory').clearValidators();
						this.campaignFormGroup.get('specialAdCategory').updateValueAndValidity();
						this.isSpecialAdCategoryToggled = false;
						this.selectedSpecialAdCategory = false;
					}
				});
		}
	}

	public updateSelectedAdCategory(status: boolean): void {
		this.selectedSpecialAdCategory = status;
		this.store.dispatch(new UpdateSpecialAdCategory(null));
	}

	public getObjectives(): void {
		this.store
			.pipe(
				select(getObjective),
				skipWhile(objective => !objective),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(objective => {
				this.selectedObjective = objective;
				this.store.pipe(select(getBidStrategiesFromCatalog), take(1)).subscribe(bidStrategy => {
					this.bidStrategy = bidStrategy;
					// the user at the moment can't select bid strategy & delivery type
				});
			});
	}

	public checkAccountCurrency(): void {
		this.store.pipe(select(getCurrency), take(1)).subscribe(currency => {
			if (!currency) {
				this.accountService.adAccountChanged$.pipe(takeUntil(this.unsubscriber$)).subscribe(resp => {
					this.currency = getCurrencySymbol(resp.currency, 'narrow');
					this.store.dispatch(new UpdateCurrency(this.currency));
				});
			} else {
				this.currency = currency;
			}
		});
	}

	// public getActivatedRouteData(): void {
	// 	this.activatedRoute.data
	// 		.pipe(
	// 			mergeMap(data => {
	// 				this.selectedAccount = data.store.account;
	// 				if (!this.productCatalogs) {
	// 					this.getProductCatalogs();
	// 				}
	// 				this.campaign = data.store.campaign || {};
	// 				this.objectives = data.store.objectives;
	// 				this.objectives = this.mapperService.sortContexts(this.objectives);
	// 				this.isNewCampaign = data.store.isNewCampaign;
	// 				this.lastChosenSpecialAdCategory = data.store?.campaign?.stepOneDetailsAsJson?.specialAdCategory ?? null;
	// 				if (this.campaign.stepOneDetailsAsJson?.name) {
	// 					this.label = '';
	// 				}
	// 				return this.store.pipe(select(getLastStepId), take(1));
	// 			})
	// 		)
	// 		.subscribe(lastStepId => {
	// 			this.dispatchActions();
	// 			this.lastStepId = lastStepId;
	// 			this.initFormGroup();
	// 			this.initFormSubscriptions();
	// 			this.expansionPanelHelper.selectedPanel = this.campaign.name ? null : BuildCampaignPanelsEnum.Name;
	// 			if (this.campaign?.stepOneDetailsAsJson) {
	// 				this.campaignFormGroup.get('objectiveGroup').patchValue({
	// 					objective: this.campaign.stepOneDetailsAsJson?.objectiveGroupTree.objective,
	// 					subObjective: this.campaign.stepOneDetailsAsJson?.objectiveGroupTree.subObjective,
	// 					destinationType: this.campaign.stepOneDetailsAsJson?.destinationType,
	// 					subObjectiveDisplayName: this.campaign.stepOneDetailsAsJson?.objectiveGroupTree.subObjectiveDisplayName
	// 				});
	// 			}
	// 		});
	// 	this.getMinBudget();
	// 	this.initStoreListeners();
	// }

	public onNavClick(step: CampaignStepsEnum): void {
		if (step <= this.campaign.lastStepId) {
			const isValidityChecked = this.campaign.currentStep <= step;
			this.saveOrUpdateCampaign(isValidityChecked);
			if (this.isStepValid) {
				this.router.navigate([`campaign/build-campaign/${step}/${this.campaign.id}`]);
			}
		}
	}

	public onCancelClick(): void {
		this.saveOrUpdateCampaign(false);
		this.router.navigate(['/campaign/select-campaign']);
	}

	public onSaveAsDraftClick(): void {
		this.saveOrUpdateCampaign(true);
		if (this.campaignFormGroup.valid) {
			this.toastService.sendSuccessToast(`Campaign ${this.campaign.name} successfully saved.`);
		}
	}

	public onNextClick(): void {
		if (this.campaignFormGroup?.get('objectiveGroup')?.get('subObjectiveDisplayName')?.value) {
			this.saveOrUpdateCampaign(true, true);
		} else {
			this.showErrorTextMsg = true;
		}
	}

	public saveOrUpdateCampaign(showErrorMessage = true, fromNextClick = false): boolean {
		this.isStepValid = true;
		this.campaignFormGroup.markAllAsTouched();
		if (this.campaignFormGroup.invalid) {
			if (showErrorMessage) {
				this.isStepValid = false;
				this.showErrorToast();
			}
			if (this.isNewCampaign) {
				this.campaign.lastStepId = CampaignStepsEnum.One;
			}
			return;
		}
		if (this.isNewCampaign) {
			this.campaign.lastStepId = CampaignStepsEnum.Two;
			this.store.pipe(select(getCampaign), take(1)).subscribe(campaign => {
				const request: CreateCampaignRequest = {
					adAccountId: this.selectedAccount.adAccount.id,
					name: campaign.name,
					stepOneDetails: campaign.stepOneDetailsAsJson
				};
				this.store.dispatch(new CreateCampaignTemplate(request));
			});
			// take the campaign to get the campaign id
			this.sharedStore.dispatch(new ShowGlobalSpinner());
			this.store
				.pipe(
					select(getCampaign),
					skipWhile(campaign => !campaign || isNaN(campaign.id)),
					take(1)
				)
				.subscribe(campaign => {
					this.campaign = campaign;
					if (fromNextClick) {
						this.router.navigate([`campaign/build-campaign/2/${this.campaign.id}`]);
						this.store.dispatch(new ClearCurrentStep());
					} else {
						this.sharedStore.dispatch(new HideGlobalSpinner());
					}
				});
			this.isNewCampaign = false;
		} else {
			this.store.dispatch(new UpdateCampaignTemplate());
			this.store.dispatch(new ClearCurrentStep());
			if (fromNextClick) {
				this.router.navigate([`campaign/build-campaign/2/${this.campaign.id}`]);
			}
		}
	}

	public checkBudget(): void {
		this.store.pipe(select(getCampaignBudgetOptimization), take(1), debounceTime(500)).subscribe(budget => {
			if (budget && budget.amount != null) {
				this.campaignBudgetOptimization = budget;
				this.validationBudget = budget.minimumBudget;
				this.isDateCardVisible = true;
			}
		});
	}

	private showErrorToast(): void {
		for (const controlKey in this.campaignFormGroup.controls) {
			if (this.campaignFormGroup.get(controlKey).valid) {
				continue;
			}
			const titleCaseName = Helper.pascalCaseToRegular(controlKey);
			const name = titleCaseName[0] + titleCaseName.substring(1).toLowerCase();
			this.toastService.sendErrorToast(`${name} is required.`);
			const target = document.querySelector(`${'.' + controlKey}`);
			target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			break;
		}
	}

	private initFormGroup(): void {
		this.campaignFormGroup = this.formBuilder.group({
			campaignName: new FormControl(this.campaign.stepOneDetailsAsJson?.name, [Validators.required, Validators.maxLength(150)]),
			specialAdCategory: new FormControl(this.campaign.stepOneDetailsAsJson?.specialAdCategory),
			objectiveGroup: this.formBuilder.group({
				objective: new FormControl(this.campaign.stepOneDetailsAsJson?.objectiveGroupTree.objective, Validators.required),
				subObjective: new FormControl(this.campaign.stepOneDetailsAsJson?.objectiveGroupTree.subObjective),
				destinationType: new FormControl(this.campaign.stepOneDetailsAsJson?.destinationType),
				subObjectiveDisplayName: new FormControl(this.campaign.stepOneDetailsAsJson?.objectiveGroupTree.subObjectiveDisplayName)
			}),
			budgetOptimization: this.formBuilder.group({
				budgetType: new FormControl(this.campaign.stepOneDetailsAsJson?.campaignBudgetOptimization?.budgetAllocatedTypeId),
				amount: new FormControl(this.campaign.stepOneDetailsAsJson?.campaignBudgetOptimization?.amount)
			})
		});
	}

	private updateTargetingOnSpecialAdCategory(): TargetingAudience {
		return {
			retargeting: null,
			targeting_type: ParentTargetingTypeEnum.NewTarget,
			ageRange: {
				minAge: 18,
				maxAge: 65
			},
			gender: Gender.All,
			type: TargetingTypeEnum.NewAudience,
			interests: [],
			customAudiences: [],
			excludedInterests: [],
			narrowInterests: [],
			locations: [],
			languages: [],
			savedAudienceId: null
		};
	}

	private initFormSubscriptions(): void {
		// check if all required fields are valid so that  upper navigation is blocked if otherwise
		this.campaignFormGroup.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			// setTimeout put in order to get rid of "Initial value has changed:previously ... now ...
			setTimeout(() => {
				this.isStepValid = this.campaignFormGroup.valid;
			});
		});

		this.campaignFormGroup
			.get('campaignName')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(name => {
				this.store.dispatch(new UpdateCampaignName(name));
				if (name.length) {
					this.label = '';
				} else {
					this.label = 'Enter Campaign Name';
				}
			});

		this.campaignFormGroup
			.get('budgetOptimization')
			.valueChanges.pipe(takeUntil(this.unsubscriber$), debounceTime(Timer.halfSecond), retry(3))
			.subscribe(budget => {
				if (!this.minimumBudget) {
					return;
				}
				if (budget.budgetType === BudgetAllocatedEnum.Daily || budget.budgetType === BudgetAllocatedEnum.LifeTime) {
					if (budget.budgetType === CampaignDateChoices.Default && !this.campaignDate.endDate) {
						this.setDefaultDate();
					}
					this.isAdSetLifeTimeSelected = budget?.budgetType === BudgetAllocatedEnum.LifeTime;
					if (budget.budgetType === BudgetAllocatedEnum.LifeTime) {
						this.validationBudget = Math.ceil(this.dateLength() * this.minimumBudget);
					} else if (budget.budgetType === BudgetAllocatedEnum.LifeTime) {
						this.validationBudget = this.minimumBudget;
					}

					this.campaignFormGroup
						.get('budgetOptimization')
						.get('amount')
						.setValidators([Validators.required, Validators.min(this.validationBudget)]);
					this.campaignFormGroup.get('budgetOptimization').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });
					const budgetOptimization: CampaignBudgetOptimizationInterface = {
						amount: budget.amount,
						budgetAllocatedTypeId: budget.budgetType,
						minimumBudget: this.validationBudget,
						bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
						deliveryType: 'standard'
					};
					this.store.dispatch(new UpdateCampaignBudgetOptimization(budgetOptimization));
				} else {
					this.store.dispatch(new UpdateCampaignBudgetOptimization(null));
					this.campaignFormGroup.get('budgetOptimization').get('amount').clearValidators();
					this.campaignFormGroup.get('budgetOptimization').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });
				}
			});

		this.campaignFormGroup
			.get('specialAdCategory')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(category => {
				this.store.dispatch(new UpdateSpecialAdCategory(category));
				if (category) {
					if (this.lastStepId === CampaignStepsEnum.Four) {
						this.store.dispatch(new UpdateSplitByGender(false));
						this.store.dispatch(new UpdateSplitByAgeRange(false));
					}
					this.lastChosenSpecialAdCategory = category;
				}
			});

		// debounce time since on some clicks we set values in multiple form controls and they all emit the same action
		this.campaignFormGroup
			.get('objectiveGroup')
			.valueChanges.pipe(debounceTime(50), takeUntil(this.unsubscriber$))
			.subscribe(values => {
				this.showBidStrategy = values.subObjective;
				if (values.subObjectiveOption === SubObjectives.PageLikes && this.campaign.stepTwoDetailsAsJson) {
					this.store.dispatch(new UpdateInstagramId(null));
				}
				this.store.dispatch(
					new UpdateObjective({
						objective: values.objective,
						subObjective: values.subObjective,
						destinationType: values.destinationType,
						subObjectiveDisplayName: values.subObjectiveDisplayName
					})
				);
				if (values.subObjective !== this.previousSubObjective) {
					if (
						(values.subObjective === SubObjectives.PageLikes ||
							values.subObjective === SubObjectives.PostLikes ||
							values.subObjective === SubObjectives.VideoViews ||
							this.previousSubObjective === SubObjectives.VideoViews ||
							this.previousSubObjective === SubObjectives.PageLikes ||
							this.previousSubObjective === SubObjectives.PostLikes) &&
						this.campaign.stepTwoDetailsAsJson
					) {
						this.store.dispatch(new UpdateAdverts(null));
					}
				}
				this.isCatalogSales = values.subObjective === this.subObjectives.ProductCatalogSales;
				this.isAppInstall = values.subObjective === this.subObjectives.AppInstallText;
				if (this.isCatalogSales && !this.campaignFormGroup.controls.hasOwnProperty('productCatalog')) {
					this.campaignFormGroup.addControl(
						'productCatalog',
						new FormControl(this.campaign?.stepOneDetailsAsJson?.productCatalogId, Validators.required)
					);
					this.updateCatalogId();
				} else if (!this.isCatalogSales && this.campaignFormGroup.controls.hasOwnProperty('productCatalog')) {
					this.campaignFormGroup.removeControl('productCatalog');
					this.store.dispatch(new UpdateCatalogId(null));
				}
				if (this.isCatalogSales && this.campaignFormGroup.controls.hasOwnProperty('productCatalog')) {
					this.campaignFormGroup
						?.get('productCatalog')
						?.valueChanges.pipe(takeUntil(this.unsubscriber$))
						.subscribe(res => {
							this.updateCatalogId();
						});
				}
				this.checkQuickNavigation();
			});
	}

	private initStoreListeners(): void {
		this.sharedStore
			.pipe(
				select(getSelectedAdAccount),
				skipWhile(account => !account || account.adAccount.id === this.selectedAccount.adAccount.id),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(account => {
				this.saveOrUpdateCampaign(false);
				this.router.navigate(['/ads-manager/campaign']);
			});
	}

	private dispatchActions(): void {
		this.store.dispatch(new UpdateCampaignCurrentStep(this.step));
		this.campaign.currentStep = this.step;
		if (this.campaign?.stepOneDetailsAsJson?.name) {
			this.store.dispatch(new UpdateCampaignName(this.campaign.stepOneDetailsAsJson.name));
		}
		if (this.campaign?.stepOneDetailsAsJson?.specialAdCategory) {
			this.store.dispatch(new UpdateSpecialAdCategory(this.campaign.stepOneDetailsAsJson.specialAdCategory));
		}
		if (this.campaign?.stepOneDetailsAsJson?.objectiveGroupTree) {
			this.store.dispatch(
				new UpdateObjective({
					objective: this.campaign.stepOneDetailsAsJson.objectiveGroupTree.objective,
					subObjective: this.campaign.stepOneDetailsAsJson.objectiveGroupTree.subObjective,
					destinationType: this.campaign.stepOneDetailsAsJson.objectiveGroupTree.destinationType,
					subObjectiveDisplayName: this.campaign.stepOneDetailsAsJson.objectiveGroupTree.subObjectiveDisplayName
				})
			);
		}
		if (this.campaign?.stepOneDetailsAsJson?.date) {
			this.store.dispatch(
				new UpdateCampaignDate({ date: this.campaign.stepOneDetailsAsJson.date, dateChoiceType: this.campaign.stepOneDetailsAsJson.dateChoice })
			);
		}

		if (this.campaign?.stepOneDetailsAsJson?.campaignBudgetOptimization) {
			this.validationBudget = this.campaign?.stepOneDetailsAsJson?.campaignBudgetOptimization.minimumBudget;
		}
	}

	public updateCatalogId(): void {
		this.store.dispatch(new UpdateCatalogId(this.campaignFormGroup?.get('productCatalog')?.value ?? null));
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	// private getProductCatalogs(): void {
	// 	if (this.selectedAccount) {
	// 		this.store.dispatch(new ShowGlobalSpinner());
	// 		this.smartCreateService
	// 			.getCatalogs(this.selectedAccount?.adAccount?.id)
	// 			.pipe(takeUntil(this.unsubscriber$))
	// 			.subscribe(
	// 				res => {
	// 					this.productCatalogs = res;
	// 					this.store.dispatch(new HideGlobalSpinner());
	// 					this.store.dispatch(new LoadProductCatalogSuccess(res));
	// 				},
	// 				err => {
	// 					this.store.dispatch(new HideGlobalSpinner());
	// 				}
	// 			);
	// 	}
	// }

	private checkQuickNavigation(): void {
		this.navigations = [
			{ name: 'Campaign Name', targetId: 'campaign-name' },
			{ name: 'Campaign Objective', targetId: 'campaign-objective' }
		];
		if (this.isCatalogSales) {
			this.navigations = [...this.navigations, { name: 'Product Catalogue', targetId: 'product-catalog' }];
		}
		if (this.isAppInstall) {
			this.navigations = [...this.navigations, { name: 'Facebook Registration', targetId: 'facebook-registration' }];
		}
		this.navigations = [
			...this.navigations,
			{ name: 'Special Ad Category', targetId: 'special-ad-category' },
			{ name: 'Campaign Budget Optimization', targetId: 'campaign-budget' }
		];
	}
	public findNavigationIndex(targetId: string): number {
		return this.navigations.findIndex(navigation => navigation.targetId === targetId);
	}
}
