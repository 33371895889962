import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownInterface } from '@filed-com/filed-lib/lib/dropdowns/models/dropdown-interface';
import { debounce } from 'lodash';
import { EMPTY, Subject } from 'rxjs';
import { catchError, debounceTime, take, takeUntil } from 'rxjs/operators';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { IKBSearchResult, IKBSearchResultData } from 'src/app/shared/models/knowledgebase.interface';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';

@Component({
	selector: 'app-knowledge-search',
	templateUrl: './knowledge-search.component.html',
	styleUrls: ['./knowledge-search.component.scss']
})
export class KnowledgeSearchComponent implements OnInit {
	public searchControl: FormControl = new FormControl('');
	public isSearchPage = false;
	public loading = false;

	private unsubscriber$ = new Subject<void>();

	public dropDownControl: FormControl = new FormControl(0);

	public kbArticles: IKBSearchResult[] | IKBSearchResultData[];

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public kbService: KnowledgebaseService,
		private toastNotificationService: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.searchControl.setValue(this.kbService.searchQuery$.value);
		this.loading = true;
		this.kbService.knowledgebaseShowFrame$.next(false);
		this.kbService
			.searchKnowledgebase(this.kbService.searchQuery$.value, 'search')
			.pipe(
				take(1),
				catchError(error => {
					this.toastNotificationService.sendErrorToast('There was an error loading the knowledge base articles, please contact support.');
					this.loading = false;
					return EMPTY;
				})
			)
			.subscribe(articles => {
				this.kbArticles = articles;
				this.loading = false;
				this.kbService.searchDataValue$.next(articles);
			});
		this.searchControl.valueChanges.pipe(takeUntil(this.unsubscriber$), debounceTime(500)).subscribe(search => {
			if (search.length > 0) {
				this.loading = true;
				this.kbService.knowledgebaseShowFrame$.next(false);
				this.kbService
					.searchKnowledgebase(search, 'search')
					.pipe(
						takeUntil(this.unsubscriber$),
						catchError(error => {
							this.toastNotificationService.sendErrorToast('There was an error loading the knowledge base articles, please contact support.');
							this.loading = false;
							return EMPTY;
						})
					)
					.subscribe(articles => {
						this.kbArticles = articles;
						this.loading = false;
						this.kbService.searchDataValue$.next(articles);
					});
			}
		});
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
