<app-expansion-panel-sc
	class="scs-expansion-panel"
	[isSelected]="isPanelSelected"
	(click)="onExpansionClick.emit()"
	[expansionTitle]="'Select Page'"
	[isExpandable]="false"
	[infoHoverModel]="pageInfoModel"
>
	<div expansion-content>
		<div class="pc-dropdown-wrapper pc-margin-bottom">
			<drp10
				(valueChanges)="getPages(true)"
				[data]="facebookPages"
				[control]="pagesFormGroup.get('facebookId')"
				[valuePrimitive]="true"
				placeholder="Choose Facebook Page"
			></drp10>
		</div>

		<div *ngIf="!isPageLikesSelected && instaAccountPage">
			<span>Choose the Instagram Page you want to promote</span>
			<div class="pc-dropdown-wrapper">
				<drp10
					(valueChanges)="pagesFormGroup.get('instagramId')"
					[data]="sortedInstagram"
					[control]="pagesFormGroup.get('instagramId')"
					[valuePrimitive]="true"
					placeholder="Choose Instagram Page"
				></drp10>
			</div>
		</div>
	</div>
</app-expansion-panel-sc>
