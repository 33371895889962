import { Action } from '@ngrx/store';
import { LeadGenerationEnum } from 'src/app/shared/smart-create-edit-components/step-two-components/lead-generation/models/lead-generation.enum';
import { CarouselDto, SingleAdvertDto } from '../../campaign-builder/models/advert.interface';
import { EventDtoInterface } from '../../pixel/_models/event-dto.interface';
import { DateRangeX } from '../../shared/calendar/calendar.models';
import { DropdownData } from '../../shared/dropdown-search-select/dropdown-data.interface';
import { Asset } from '../../shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';
import { CampaignDateChoices } from '../../shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { FacebookInterests } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';
import { CatalogNodeInterface } from '../catalogs/catalog-node.interface';
import { AdFormatsEnum } from '../models/ad-formats.enum';
import { BudgetAllocationInterface } from '../models/budget-allocation.interface';
import { CampaignBudgetOptimizationInterface } from '../models/campaign-budget-optimization.interface';
import { CampaignStepsEnum } from '../models/campaign-steps.enum';
import { CampaignTemplate } from '../models/campaign-template';
import { CampaignTemplateListItem } from '../models/campaign-template-list-item';
import { CreateCampaignRequest } from '../models/create-campaign-request';
import { OptimizationAndDeliveryInterface } from '../models/optimization-and-delivery.interface';
import { StepFourDetails } from '../models/step-four-details';
import { StepOneDetails } from '../models/step-one-details';
import { StepThreeDetails } from '../models/step-three-details';
import { StepTwoDetails } from '../models/step-two-details';
import { TargetingAudience } from '../models/targeting-audience';
import { AppStoreTypeEnum } from '../models/app-store-type.enum';
import { AdvertiseAppStoreInterface } from '../models/advertise-app.interface';
import { UpdateCampaignRequest } from '../models/update-campaign-request';
import { StepFiveDetails } from '../models/step-five-details';

export enum CampaignSmartCreateActionTypes {
	// Load Actions
	LoadCampaignList = '[Campaign Smart Create] Load Templates',
	LoadCampaignListSuccess = '[Campaign Smart Create] Load Templates Success',
	LoadCampaignListFailure = '[Campaign Smart Create] Load Templates Failure',

	LoadCampaign = '[Campaign Smart Create] Load Campaign',
	LoadCampaignSuccess = '[Campaign Smart Create] Load Campaign Success',
	LoadCampaignFailure = '[Campaign Smart Create] Load Campaign Failure',

	LoadCatalogs = '[Campaign Smart Create] Load Catalogs',
	LoadCatalogsSuccess = '[Campaign Smart Create] Load Catalogs Success',
	LoadCatalogsFailure = '[Campaign Smart Create] Load Catalogs Failure',

	LoadUserImages = '[Campaign Smart Create] Load User Images',
	LoadUserImagesSuccess = '[Campaign Smart Create] Load User Images Success',
	LoadUserImagesFailure = '[Campaign Smart Create] Load User Images Failure',

	LoadUserVideos = '[Campaign Smart Create] Load User Videos',
	LoadUserVideosSuccess = '[Campaign Smart Create] Load User Videos Success',
	LoadUserVideosFailure = '[Campaign Smart Create] Load User Videos Failure',

	LoadExistingImages = '[Campaign Smart Create] Load Existing Images',
	LoadExistingImagesSuccess = '[Campaign Smart Create] Load Existing Images Success',
	LoadExistingImagesFailure = '[Campaign Smart Create] Load Existing Images Failure',

	LoadExistingVideos = '[Campaign Smart Create] Load Existing Videos',
	LoadExistingVideosSuccess = '[Campaign Smart Create] Load Existing Videos Success',
	LoadExistingVideosFailure = '[Campaign Smart Create] Load Existing Videos Failure',

	LoadPagePosts = '[Campaign Smart Create] Load Posts',
	LoadPagePostsSuccess = '[Campaign Smart Create] Load Posts Success',
	LoadPagePostsFailure = '[Campaign Smart Create] Load Posts Failure',

	// Update & Create
	CreateCampaignTemplate = '[Campaign Smart Create] Create Campaign Template',
	CreateCampaignTemplateSuccess = '[Campaign Smart Create] Create Campaign Template Success',
	CreateCampaignTemplateFailure = '[Campaign Smart Create] Create Campaign Template Failure',

	UpdateCampaignTemplate = '[Campaign Smart Create] Update Campaign Template',
	UpdateCampaignTemplateSuccess = '[Campaign Smart Create] Update Campaign Template Success',
	UpdateCampaignTemplateFailure = '[Campaign Smart Create] Update Campaign Template Failure',

	// Publish
	PublishCampaign = '[Campaign Smart Create] Publish Campaign',
	PublishCampaignSuccess = '[Campaign Smart Create] Publish Campaign Success',
	PublishCampaignFailure = '[Campaign Smart Create] Publish Campaign Failure',

	// ab testing Publish
	CreateOptimizationDraft = '[Campaign Smart Create] Create optimization drafts',
	CreateOptimizationDraftSuccess = '[Campaign Smart Create] Publish Optimization Success',
	UpdateOptimizationDraft = '[Campaign Smart Create] update Optimization',
	CreateOptimizationDraftFailure = '[Campaign Smart Create] Publish Optimization Failure',

	// Step 1
	UpdateCampaignName = '[Campaign Smart Create] Update Campaign Name',
	UpdateObjective = '[Campaign Smart Create] Update Objective',
	UpdateSpecialAdCategory = '[Campaign Smart Create] Update Special Ad Category',
	UpdateCampaignBudgetOptimization = '[Campaign Smart Create] Update Campaign Budget Optimization',
	UpdateCurrency = '[Campaign Smart Create] Update Currency',
	UpdateCampaignDate = '[Campaign Smart Create] Update Campaign Date',
	UpdateCatalogId = '[Campaign Smart Create] Update Catalog Id',
	UpdateAppStoreType = '[Campaign Smart Create] Update app store Type',
	UpdateAppSelection = '[campaign smart create] Update app selection',

	// Step 2
	UpdateAdSetName = '[Campaign Smart Create] Update Ad Set Name',
	UpdateObjectiveLeadGeneration = '[Campaign Smart Create] Update Objective Lead Generation',
	UpdatePages = '[Campaign Smart Create] Update Pages',
	UpdateInstagramId = '[Campaign Smart Create] Update Instagram Id',
	UpdateTargeting = '[Campaign Smart Create] Update Targeting',
	UpdateCampaignActiveDate = '[Campaign Smart Create] Update Campaign Active Date',
	UpdatePlacements = '[Campaign Smart Create] Update Placements',
	UpdateAdSetBudgetOptimization = '[Campaign Smart Create] Update AdSet Budget Optimization',
	UpdateOptimizationAndDelivery = '[Campaign Smart Create] Update Optimization & Delivery',
	UpdateCatalogSetId = '[Campaign Smart Create] Update Catalog Set Id',

	// Step 3
	AddNewAd = '[Campaign Smart Create] Add New Ad',
	RemoveAd = '[Campaign Smart Create] Remove Ad',
	ClearAds = '[Campaign Smart Create] Clear Ads',
	UpdateSelectedAdIndex = '[Campaign Smart Create] Update Selected Ad Index',
	UpdateCurrentAd = '[Campaign Smart Create] Update Current Ad',
	UpdateAdName = '[Campaign Smart Create] Update Ad Name',
	UpdateAdFormatType = '[Campaign Smart Create] Update Ad Format Type',
	UpdateAdverts = '[Campaign Smart Create] Update Adverts',
	UpdatePixelToggle = '[Campaign Smart Create] Update Pixel toggle',
	UpdatePixelId = '[Campaign Smart Create] Update PixelId',
	UpdatePixelAppEventId = '[Campaign Smart Create] Update PixelAppEvent ',
	UpdateConversion = '[Campaign Smart Create] Update Conversion ',
	UpdateEventRule = '[Campaign Smart Create] Update EventRule ',
	UpdateIframeSrc = '[Campaign Smart Create] Update Iframe',

	// Step 4
	UpdateSplitByLocation = '[Campaign Smart Create] Update Split By Location',
	UpdateSplitByDevice = '[Campaign Smart Create] Update Split By Devices',
	UpdateSplitByInterests = '[Campaign Smart Create] Update Split By Interests',
	UpdateSplitByPlacements = '[Campaign Smart Create] Update Split By placement',
	UpdateSplitByGender = '[Campaign Smart Create] Update Split By Gender',
	UpdateSplitByAgeRange = '[Campaign Smart Create] Update Split By Age Range',
	UpdateBudgetToggle = '[Campaign Smart Create] Update Budget Toggle',
	UpdateBudgetAllocation = '[Campaign Smart Create] Update BudgetAllocation',

	// Misc
	SaveCampaignTemplates = '[Campaign Smart Create] Save Campaign Templates',
	UpdateCampaignCurrentStep = '[Campaign Smart Create] Update Campaign Current Step',
	UpdateCampaignInvalidStep = '[Campaign Smart Create] Update Campaign Invalid Step',
	UpdateCurrentStep = '[Campaign Smart Create] Update Current Step',
	ClearCurrentCampaign = '[Campaign Smart Create] Clear Current Campaign',
	ClearCurrentStep = '[Campaign Smart Create] Clear Current Step',
	ClearCurrentAssets = '[Campaign Smart Create] Clear Current Assets',
	ClearUserImages = '[Campaign Smart Create] Clear User Images',
	UpdateTemplateStart = '[Campaign Smart Create] Update Template Start',
	UpdateTemplateEnd = '[Campaign Smart Create] Update Template End',
	TriggerFormsValidation = '[Campaign Smart Create] Trigger Forms Validation',
	AreFormsValid = '[Campaign Smart Create] Get Forms State'

	// product catalogue
}

export class LoadCampaignList implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadCampaignList;

	constructor(public payload: string) {}
}

export class LoadCampaignListSuccess implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadCampaignListSuccess;

	constructor(public payload: { accountId: string; templates: CampaignTemplateListItem[] }) {}
}

export class LoadCampaignListFailure implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadCampaignListFailure;
}

export class LoadCampaignTemplate implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadCampaign;

	constructor(public payload: number) {}
}

export class LoadCampaignTemplateSuccess implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadCampaignSuccess;

	constructor(public payload: CampaignTemplate) {}
}

export class LoadCampaignTemplateFailure implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadCampaignFailure;
}

export class LoadPagePosts implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadPagePosts;

	constructor(public payload: { businessOwnerId: string; adAccountId: string }) {}
}

export class LoadPagePostsSuccess implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadPagePostsSuccess;

	constructor(public payload: Asset[]) {}
}

export class LoadPagePostsFailure implements Action {
	public readonly type = CampaignSmartCreateActionTypes.LoadPagePostsFailure;
}

export class CreateCampaignTemplate implements Action {
	public readonly type = CampaignSmartCreateActionTypes.CreateCampaignTemplate;

	constructor(public payload: CreateCampaignRequest) {}
}

export class CreateCampaignTemplateSuccess implements Action {
	public readonly type = CampaignSmartCreateActionTypes.CreateCampaignTemplateSuccess;

	constructor(public payload: number) {}
}

export class CreateCampaignTemplateFailure implements Action {
	public readonly type = CampaignSmartCreateActionTypes.CreateCampaignTemplateFailure;
}

export class UpdateCampaignTemplate implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignTemplate;

	constructor(public payload?: CampaignTemplate) {}
}

export class UpdateCampaignTemplateSuccess implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignTemplateSuccess;

	constructor(public payload: CampaignTemplate) {}
}

export class UpdateCampaignTemplateFailure implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignTemplateFailure;
}

export class PublishCampaign implements Action {
	public readonly type = CampaignSmartCreateActionTypes.PublishCampaign;

	constructor(public payload?: StepFourDetails) {}
}

export class PublishCampaignSuccess implements Action {
	public readonly type = CampaignSmartCreateActionTypes.PublishCampaignSuccess;

	constructor(public payload: StepFourDetails) {}
}

export class PublishCampaignFailure implements Action {
	public readonly type = CampaignSmartCreateActionTypes.PublishCampaignFailure;
}

export class CreateOptimizationDraft implements Action {
	public readonly type = CampaignSmartCreateActionTypes.CreateOptimizationDraft;
	constructor(public payload?: UpdateCampaignRequest) {}
}

export class CreateOptimizationDraftSuccess implements Action {
	public readonly type = CampaignSmartCreateActionTypes.CreateOptimizationDraftSuccess;
	constructor(public payload?: StepFiveDetails) {}
}
export class UpdateOptimizationDraft implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateOptimizationDraft;
	constructor(public payload?: StepFiveDetails) {}
}

export class ClearCurrentCampaign implements Action {
	public readonly type = CampaignSmartCreateActionTypes.ClearCurrentCampaign;
}

export class ClearCurrentStep implements Action {
	public readonly type = CampaignSmartCreateActionTypes.ClearCurrentStep;
}

export class ClearCurrentAssets implements Action {
	public readonly type = CampaignSmartCreateActionTypes.ClearCurrentAssets;
}

export class ClearUserImages implements Action {
	public readonly type = CampaignSmartCreateActionTypes.ClearUserImages;
}

export class UpdateCurrentStep implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCurrentStep;

	constructor(public payload: { step: StepOneDetails | StepTwoDetails | StepThreeDetails[]; stepLevel: CampaignStepsEnum }) {}
}

export class UpdateCampaignCurrentStep implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignCurrentStep;

	constructor(public payload: CampaignStepsEnum) {}
}

export class UpdateCampaignInvalidStep implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignInvalidStep;
	constructor(public payload: CampaignStepsEnum) {}
}

export class UpdateTemplateStart implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateTemplateStart;
}

export class UpdateTemplateEnd implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateTemplateEnd;
}

export class TriggerFormsValidation implements Action {
	public readonly type = CampaignSmartCreateActionTypes.TriggerFormsValidation;

	constructor(public payload: boolean) {}
}

export class AreFormsValid implements Action {
	public readonly type = CampaignSmartCreateActionTypes.AreFormsValid;

	constructor(public payload: boolean) {}
}

export class SaveCampaignTemplates implements Action {
	public readonly type = CampaignSmartCreateActionTypes.SaveCampaignTemplates;
	constructor(public payload: DropdownData<CampaignTemplateListItem>[]) {}
}
export class UpdateAppStoreType implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateAppStoreType;
	constructor(public payload: AppStoreTypeEnum | null) {}
}

// Step 1

export class UpdateCampaignName implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignName;
	constructor(public payload: string) {}
}

export class UpdateObjective implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateObjective;

	constructor(public payload: { objective: string; subObjective: string; destinationType: string; subObjectiveDisplayName: string }) {}
}

export class UpdateSpecialAdCategory implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSpecialAdCategory;

	constructor(public payload: string) {}
}

export class UpdateCampaignBudgetOptimization implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignBudgetOptimization;

	constructor(public payload: CampaignBudgetOptimizationInterface) {}
}

export class UpdateCurrency implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCurrency;
	constructor(public payload: string) {}
}
export class UpdateCatalogId implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCatalogId;

	constructor(public payload: string) {}
}

// Step 2

export class UpdateAdSetBudgetOptimization implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateAdSetBudgetOptimization;

	constructor(public payload: CampaignBudgetOptimizationInterface) {}
}

export class UpdateOptimizationAndDelivery implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateOptimizationAndDelivery;

	constructor(public payload: OptimizationAndDeliveryInterface) {}
}

export class UpdateAdSetName implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateAdSetName;

	constructor(public payload: string) {}
}

export class UpdateObjectiveLeadGeneration implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateObjectiveLeadGeneration;

	constructor(public payload: LeadGenerationEnum) {}
}

export class UpdatePages implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdatePages;

	constructor(public payload: { facebookPageId: string; instagramPageId: string }) {}
}

export class UpdateInstagramId implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateInstagramId;
	constructor(public payload: string) {}
}

export class UpdateTargeting implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateTargeting;

	constructor(public payload: TargetingAudience) {}
}

export class UpdateCampaignActiveDate implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignActiveDate;

	constructor(public payload: { date: DateRangeX<string>; dateChoiceType: CampaignDateChoices }) {}
}

export class UpdateCampaignDate implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCampaignDate;

	constructor(public payload: { date: DateRangeX<string>; dateChoiceType: CampaignDateChoices }) {}
}

export class UpdatePlacements implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdatePlacements;

	constructor(public payload: CatalogNodeInterface[]) {}
}
export class UpdateCatalogSetId implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCatalogSetId;

	constructor(public payload: string) {}
}
// Step 3
export class AddNewAd implements Action {
	public readonly type = CampaignSmartCreateActionTypes.AddNewAd;
	constructor(public payload: StepThreeDetails) {}
}
export class RemoveAd implements Action {
	public readonly type = CampaignSmartCreateActionTypes.RemoveAd;

	constructor(public payload: number) {}
}

export class ClearAds implements Action {
	public readonly type = CampaignSmartCreateActionTypes.ClearAds;

	constructor() {}
}
export class UpdateSelectedAdIndex implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSelectedAdIndex;

	constructor(public payload: number) {}
}
export class UpdateCurrentAd implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateCurrentAd;
	constructor() {}
}
export class UpdateAdName implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateAdName;

	constructor(public payload: string) {}
}

export class UpdateAdFormatType implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateAdFormatType;

	constructor(public payload: AdFormatsEnum) {}
}

export class UpdateAdverts implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateAdverts;
	constructor(public payload: SingleAdvertDto | CarouselDto) {}
}

export class UpdatePixelToggle implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdatePixelToggle;
	constructor(public payload: boolean) {}
}

export class UpdatePixelId implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdatePixelId;
	constructor(public payload: string) {}
}

export class UpdatePixelAppEventId implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdatePixelAppEventId;
	constructor(public payload: string) {}
}

export class UpdateConversion implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateConversion;
	constructor(public payload: DropdownData<EventDtoInterface>) {}
}

export class UpdateEventRule implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateEventRule;
	constructor(public payload: Object) {}
}

export class UpdateIframeSrc implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateIframeSrc;
	constructor(public payload: string) {}
}

// Step 4

export class UpdateSplitByLocation implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSplitByLocation;
	constructor(public payload: boolean) {}
}

export class UpdateSplitByDevice implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSplitByDevice;
	constructor(public payload: boolean) {}
}

export class UpdateSplitByInterests implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSplitByInterests;
	constructor(public payload: Array<Array<FacebookInterests>> | boolean) {}
}
export class UpdateSplitByPlacements implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSplitByPlacements;
	constructor(public payload: boolean) {}
}

export class UpdateSplitByGender implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSplitByGender;
	constructor(public payload: boolean) {}
}

export class UpdateSplitByAgeRange implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateSplitByAgeRange;
	constructor(public payload: number | boolean) {}
}

export class UpdateBudgetToggle implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateBudgetToggle;
	constructor(public payload: boolean) {}
}

export class UpdateBudgetAllocation implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateBudgetAllocation;
	constructor(public payload: BudgetAllocationInterface) {}
}

export class UpdateAppSelection implements Action {
	public readonly type = CampaignSmartCreateActionTypes.UpdateAppSelection;
	constructor(public payload: AdvertiseAppStoreInterface) {}
}

export type SmartCreateActions =
	| LoadCampaignList
	| LoadCampaignListSuccess
	| LoadCampaignListFailure
	| LoadCampaignTemplate
	| LoadCampaignTemplateSuccess
	| LoadCampaignTemplateFailure
	| LoadPagePosts
	| LoadPagePostsSuccess
	| LoadPagePostsFailure
	| CreateCampaignTemplate
	| CreateCampaignTemplateSuccess
	| CreateCampaignTemplateFailure
	| UpdateCampaignTemplate
	| UpdateCampaignTemplateSuccess
	| UpdateCampaignTemplateFailure
	| PublishCampaign
	| PublishCampaignSuccess
	| PublishCampaignFailure
	| CreateOptimizationDraft
	| CreateOptimizationDraftSuccess
	| UpdateOptimizationDraft
	| ClearCurrentCampaign
	| ClearCurrentStep
	| ClearCurrentAssets
	| ClearUserImages
	| UpdateCurrentStep
	| UpdateCampaignName
	| UpdateObjective
	| UpdateAdSetName
	| UpdateObjectiveLeadGeneration
	| UpdatePages
	| UpdateTargeting
	| UpdateCampaignDate
	| TriggerFormsValidation
	| UpdateCampaignActiveDate
	| UpdatePlacements
	| AddNewAd
	| RemoveAd
	| ClearAds
	| UpdateCurrentAd
	| UpdateSelectedAdIndex
	| UpdateAdName
	| UpdateTemplateStart
	| UpdateTemplateEnd
	| UpdateAdFormatType
	| UpdateSpecialAdCategory
	| UpdateAdverts
	| UpdatePixelToggle
	| UpdatePixelId
	| UpdatePixelAppEventId
	| UpdateConversion
	| UpdateSplitByDevice
	| UpdateSplitByInterests
	| UpdateSplitByPlacements
	| UpdateSplitByGender
	| UpdateSplitByAgeRange
	| UpdateSplitByLocation
	| AreFormsValid
	| UpdateCampaignCurrentStep
	| UpdateInstagramId
	| UpdateCampaignInvalidStep
	| UpdateCurrency
	| UpdateCampaignBudgetOptimization
	| UpdateAdSetBudgetOptimization
	| UpdateEventRule
	| UpdateIframeSrc
	| UpdateBudgetToggle
	| SaveCampaignTemplates
	| UpdateBudgetAllocation
	| UpdateOptimizationAndDelivery
	| UpdateCatalogId
	| UpdateCatalogSetId
	| UpdateAppStoreType
	| UpdateAppSelection;
