import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiUrl } from '../base-api-urls';
import { SubscriptionReason } from '../../_models/subscription-reason';
import { Phone } from '../../user-management/models/phone';

@Injectable({
	providedIn: 'root'
})
export class ContactService {
	private readonly baseURL = BaseApiUrl.Subscription + 'subscriptions/';

	constructor(private http: HttpClient) {}

	public sendContactEmail(subscriptionReason: SubscriptionReason): Observable<any> {
		return this.http.put(`${this.baseURL}contact-us`, subscriptionReason);
	}

	public cancelSubscription(phone: Phone): Observable<void> {
		return this.http.put<void>(`${this.baseURL}cancel-subscription`, phone);
	}

	public uncancelSubscription(): Observable<void> {
		return this.http.put<void>(`${this.baseURL}uncancel-subscription`, {});
	}
}
