<div [ngClass]="submitSuccess ? 'main-container-success' : 'main-container'">
	<div *ngIf="!loading && !submitSuccess" class="header-avatar">
		<div class="avatar-img">
			<img src="../../../../../assets/icons/account-icon.svg" />
		</div>
		<div class="avatar-text">
			<h4>
				We'll email you within one working day.
			</h4>
		</div>
	</div>
	<form *ngIf="!loading && !submitSuccess" [formGroup]="emailForm" (ngSubmit)="submit()">
		<div class="form-area">
			<div class="topic">
				<mat-form-field appearance="outline">
					<mat-label>Topic</mat-label>
					<input formControlName="subject" matInput placeholder="Problem overview" />
					<mat-error>Please enter subject</mat-error>
				</mat-form-field>
			</div>
			<div class="description">
				<mat-form-field appearance="outline">
					<mat-label>Description</mat-label>
					<textarea formControlName="description" matInput></textarea>
					<mat-error>Please enter description</mat-error>
				</mat-form-field>
			</div>
			<div class="file">
				<input type="file" accept=".pdf,.jpg,.jpeg,.png,.gif,.txt,.csv,.doc,.docx,.mp4,.mov,.webm" (change)="uploadFile($event)" />
			</div>
			<div class="email">
				<mat-form-field appearance="outline">
					<mat-label>Email</mat-label>
					<input formControlName="emailId" matInput placeholder="Email" readonly />
				</mat-form-field>
			</div>
		</div>
		<div class="bottom-container">
			<mat-divider></mat-divider>
			<div class="action-button">
				<btn01>Submit</btn01>
			</div>
		</div>
	</form>
	<div class="loader" *ngIf="loading">
		<lolly-spinning-loader></lolly-spinning-loader>
	</div>
	<div *ngIf="!loading && submitSuccess" class="message-success">
		<img src="../../../../../assets/icons/mail-icon.svg" />
		<h3>Lolly Support is on the case.</h3>
		<h5>We strive to respond within one working day</h5>
	</div>
</div>
