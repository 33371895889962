import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FacebookInsightsMetadataFactory } from './facebook-insights-metadata-factory.service';
import { InsightsMetadataFactory } from './insights-metadata-factory.model';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';
import { GoogleInsightsMetadataFactory } from './google-insights-metadata-factory.service';

@Injectable({
	providedIn: 'root'
})
export class InsightsMetadataAbstractFactory {
	constructor(public http: HttpClient) {}

	public getInstance(channel: SourceChannel): InsightsMetadataFactory {
		switch (channel) {
			case SourceChannel.Facebook:
				return new FacebookInsightsMetadataFactory(this.http);

			case SourceChannel.Google:
				return new GoogleInsightsMetadataFactory(this.http);

			default:
				break;
		}
	}
}
