import { ReportDetailsModel } from './report-details.model';
import { ReportingPermissionLevel } from './dashboard.model';
import { ChartTypeEnum } from '../shared/charts/chart-type.enum';
import { FilterKeysEnum } from '../shared/models/filter-keys.enum';

export class ReportModel {
	public id: number;
	public name: string;
	public type: ChartTypeEnum;
	public createdAt: string;
	public isEditable: boolean;
	public levelId: ReportingPermissionLevel;
	public details: ReportDetailsModel;
	public filtersKeys: FilterKeysEnum[];
	public selectedCampaignIds?: string[];

	constructor() {
		this.details = new ReportDetailsModel();
	}

	public copy(widget: ReportModel): void {
		Object.assign(this, widget);
	}

	public copyRequired(widget: ReportModel): void {
		this.details.reportLevel = widget.details.reportLevel;
		this.details.reportType = widget.details.reportType;
		this.details.dataSource = widget.details.dataSource;

		if (!this.details.simpleDataSource && widget.details.breakdown) {
			this.details.breakdown = widget.details.breakdown;
		}

		this.details.dateFrom = widget.details.dateFrom;
		this.details.dateTo = widget.details.dateTo;
		this.details.chartData = widget.details.chartData;
		this.details.insights = widget.details.insights;
		this.details.compareDate.from = widget.details.compareDate.from;
		this.details.compareDate.to = widget.details.compareDate.to;
		this.details.compare = widget.details.compare;
	}
}

export interface IChartPositionProperty {
	graph_id: string;
	positionProperty: IPositionProperty;
}

export interface IPositionProperty {
	layerIndex: number;
	x: number;
	y: number;
	rows: number;
	cols: number;
	draggable: boolean;
	resizeEnabled: boolean;
}

export interface IPositioningResponse {
	message?: string;
}
