<app-user-header></app-user-header>
<div class="page-container">
	<div class="form-area">
		<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
		<div class="page-content" *ngIf="!loading">
			<div class="container">
				<div class="form">
					<div class="title">Offer accepted.</div>
					<div class="sub-title">We are extremely pleased to see you are staying with us.</div>

					<div class="box">
						<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
						<div class="detail">
							<div class="title">Our Exclusive Offer - 30% off next month</div>
							<div class="tag">Thank you for accepting the offer.</div>
							<div class="subtitle">We will automatically deduct your next month payment by 30%.</div>
							<div class="tagline">An email confirmation will be sent to you confirming you have accepted our exclusive offer.</div>
						</div>
					</div>
				</div>
				<div class="form-btn">
					<button class="btn-signup left" (click)="onNext()">
						BACK TO ACCOUNT HOME PAGE
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
