<div class="main">
	<div class="search-div">
		<tb06 [control]="agGridFormBuilder.get('searchControl')" [icon]="'fa fa-search'" [placeHolderTextBox]="'Search'"></tb06>
	</div>
	<div class="accordion">
		<mat-accordion>
			<mat-expansion-panel hideToggle>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<div class="expansion-header" (click)="$event.stopPropagation()">
							<input type="checkbox" id="master-id" (click)="selectAllMaster('master-id', 'check-input')" />
							<span class="title">Master</span>
							<span class="material-icons">
								edit
							</span>
						</div>
					</mat-panel-title>
					<span class="material-icons drop-down">
						arrow_drop_down
					</span>
				</mat-expansion-panel-header>
				<mat-panel-title *ngFor="let masterViews of filterMasterColumnViews; let i = index">
					<input class="check-input" type="checkbox" />
					<div class="expansion-field">
						<span class="title">{{masterViews.displayName}}</span>
					</div>
				</mat-panel-title>
			</mat-expansion-panel>
			<mat-expansion-panel hideToggle>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<div class="expansion-header" (click)="$event.stopPropagation()">
							<input type="checkbox" id="master-id-two" (click)="selectAllMaster('master-id-two', 'check-input-two')" />
							<span class="title">Custom</span>
							<span class="material-icons">
								edit
							</span>
						</div>
					</mat-panel-title>
					<span class="material-icons drop-down">
						arrow_drop_down
					</span>
				</mat-expansion-panel-header>
				<mat-panel-title *ngFor="let customColumn of filterCustomColumnViews; let w = index">
					<input class="check-input-two" type="checkbox" />
					<div class="expansion-field">
						<span class="title">{{customColumn.displayName}}</span>
					</div>
				</mat-panel-title>
			</mat-expansion-panel>
			<mat-expansion-panel hideToggle>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<div class="expansion-header" (click)="$event.stopPropagation()">
							<input type="checkbox" id="master-id-three" (click)="selectAllMaster('master-id-three', 'check-input-three')" />
							<span class="title">CustomView</span>
							<span class="material-icons">
								edit
							</span>
						</div>
					</mat-panel-title>
					<span class="material-icons drop-down">
						arrow_drop_down
					</span>
				</mat-expansion-panel-header>
				<mat-panel-title *ngFor="let customViews of filterCustomViews; let q = index">
					<input class="check-input-three" type="checkbox" />
					<div class="expansion-field">
						<span class="title">{{customViews.displayName}}</span>
					</div>
				</mat-panel-title>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
