import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdAccount } from 'src/app/accounts/models/ad-account.model';
import { IMainImage, ISingleAd } from '../cb-m-single-ad/model/single-ad-model';
import { IFontAsset } from '../cb-m-template/model/font-asset-model';
import { IimageAsset } from '../cb-m-template/model/image-asset-model';
import { IProductCatalog } from '../cb-m-template/model/product-catalog';
import { IProductSet } from '../cb-m-template/model/product-set';
import { ISelectAsset, ITemplate, ITemplateList } from '../cb-m-template/model/template-model';
import { ITextElements } from '../model/creative-builder-elements-model';
import { creativeBuilderAction, CreativeBuilderActionType } from './creative-builder.actions';

export interface CreativeBuilderState {
	showImageLoading: boolean;
	showEditorSidebar: boolean;
	activeTemplateSize: number;
	activeSingleAdPage: number;
	activeTextSidebar: string;
	activeShapeSidebar: string;
	suggestedTexts: ITextElements[];
	selectedAsset: ISelectAsset;
	selectedShapeColor: { name: string; color: string };
	selectedTextElement: ITextElements;
	activeToolBar: string;
	activeSideBar: string;
	templates: ITemplateList;
	selectedSet: IProductSet;
	currentTemplate: ITemplate;
	currentSingleAdDesign: ISingleAd;
	undoTray: ITemplate[];
	redoTray: ITemplate[];
	undoSingleAdTray: ISingleAd[];
	redoSingleAdTray: ISingleAd[];
	isPredefinedTemplate?: boolean;
	isPredefinedSingleAd?: boolean;
	deselectAssest?: string;
	defaultDynamicText?: string[];
	customFont: IFontAsset[];
	defaultFont: IFontAsset[];
	previewTemplateCropImage: boolean;
	previewSingleAdCropImage: boolean;
	previewBGAdDesignCropImage: boolean;
	templateImageAssets: IimageAsset[];
	singleAdImageAssets: IimageAsset[];
	IsTemplateImageAssetSelected: boolean;
	IsSingleAdImageAssetSelected: boolean;
	IsAdDesignBackgroundImageSelected: boolean;
	IsTemplateBackgroundImageSelected: boolean;
	IsTemplateCropping: boolean;
	IsSingleAdCropping: boolean;
	IsBGAdDesignCropping: boolean;
	defaultTemplateId: number;
	defaultSingleAdId: number;
	editorSelectedDefaultTemplate: boolean;
	editorSelectedDefaultSingleAd: boolean;
	productCatalogues: IProductCatalog[];
	setList: IProductSet[];
	IsSingleAd: boolean;
	IsFreshAccount: boolean;
	alertAlreadyCalled: boolean;
	facebookPageId: string;
	adAccount: AdAccount;
}
const currentTemplateInit: ITemplate = {
	templateName: '',
	templateData: '',
	attachedFonts: [],
	attachedImageAssets: [],
	templateDataAsJson: {
		TemplateData: [
			{
				TemplateTypeId: 1,
				MainImage: null,
				ImageElements: [],
				TextElements: [],
				LineElements: [],
				ShapeElements: [],
				SvgElements: [],
				Height: 1080,
				Width: 1080,
				BackgroundColor: [245, 245, 245]
			}
		]
	}
};
const currentSingleAdDesignInit: ISingleAd = {
	singleAdName: '',
	singleAdData: '',
	attachedFonts: [],
	attachedImageAssets: [],
	singleAdDataAsJson: {
		singleAdData: [
			{
				ImageElements: [],
				LineElements: [],
				Height: 1080,
				Width: 1080,
				MainImage: null,
				SvgElements: [],
				TextElements: [],
				BackgroundColor: [245, 245, 245],
				Page: 0,
				SingleAdTypeId: 1
			}
		]
	}
};
export const initialCreativeBuilderState: CreativeBuilderState = {
	showImageLoading: false,
	showEditorSidebar: false,
	activeTemplateSize: 1,
	activeSingleAdPage: 1,
	suggestedTexts: null,
	selectedShapeColor: null,
	selectedAsset: {
		type: '',
		id: null
	},
	selectedTextElement: null,
	activeToolBar: 'image',
	activeSideBar: 'image',
	activeTextSidebar: 'text',
	activeShapeSidebar: 'shape',
	templates: null,
	selectedSet: null,
	customFont: [],
	defaultFont: [],
	currentTemplate: currentTemplateInit,
	currentSingleAdDesign: currentSingleAdDesignInit,
	undoTray: [],
	redoTray: [],
	undoSingleAdTray: [],
	redoSingleAdTray: [],
	isPredefinedTemplate: false,
	isPredefinedSingleAd: false,
	defaultDynamicText: [],
	previewTemplateCropImage: false,
	previewSingleAdCropImage: false,
	previewBGAdDesignCropImage: false,
	templateImageAssets: [],
	singleAdImageAssets: [],
	IsTemplateImageAssetSelected: false,
	IsSingleAdImageAssetSelected: false,
	IsAdDesignBackgroundImageSelected: false,
	IsTemplateBackgroundImageSelected: false,
	IsTemplateCropping: false,
	IsSingleAdCropping: false,
	IsBGAdDesignCropping: false,
	defaultTemplateId: 0,
	defaultSingleAdId: 0,
	editorSelectedDefaultTemplate: false,
	editorSelectedDefaultSingleAd: false,
	productCatalogues: [],
	setList: null,
	IsSingleAd: false,
	IsFreshAccount: false,
	alertAlreadyCalled: false,
	facebookPageId: null,
	adAccount: null
};

const getCreativeBuilderFeatureState = createFeatureSelector<CreativeBuilderState>('creativeBuilder');
export const getActiveTemplateSize = createSelector(getCreativeBuilderFeatureState, state => state.activeTemplateSize);
export const getActiveSingleAdPage = createSelector(getCreativeBuilderFeatureState, state => state.activeSingleAdPage);
export const getDeselectAssestState = createSelector(getCreativeBuilderFeatureState, state => state.deselectAssest);
export const getUndoTray = createSelector(getCreativeBuilderFeatureState, state => state.undoTray);
export const getRedoTray = createSelector(getCreativeBuilderFeatureState, state => state.redoTray);
export const getUndoSingleAdTray = createSelector(getCreativeBuilderFeatureState, state => state.undoSingleAdTray);
export const getRedoSingleAdTray = createSelector(getCreativeBuilderFeatureState, state => state.redoSingleAdTray);
export const getPreviewTemplateCropImage = createSelector(getCreativeBuilderFeatureState, state => state.previewTemplateCropImage);
export const getPreviewSingleAdCropImage = createSelector(getCreativeBuilderFeatureState, state => state.previewSingleAdCropImage);
export const getPreviewBGAdDesignCropImage = createSelector(getCreativeBuilderFeatureState, state => state.previewBGAdDesignCropImage);
export const getTemplateImageAssets = createSelector(getCreativeBuilderFeatureState, state => state.templateImageAssets);
export const getSingleAdImageAssets = createSelector(getCreativeBuilderFeatureState, state => state.singleAdImageAssets);
export const getIsSingleAd = createSelector(getCreativeBuilderFeatureState, state => state.IsSingleAd);
export const getIsSFreshAccount = createSelector(getCreativeBuilderFeatureState, state => state.IsFreshAccount);
export const getAlertAlreadyCalled = createSelector(getCreativeBuilderFeatureState, state => state.alertAlreadyCalled);
export const getFacebookPageId = createSelector(getCreativeBuilderFeatureState, state => state.facebookPageId);
export const getAdAccount = createSelector(getCreativeBuilderFeatureState, state => state.adAccount);
export const getProductCatalogues = createSelector(getCreativeBuilderFeatureState, state => state.productCatalogues);
export const getSetList = createSelector(getCreativeBuilderFeatureState, state => state.setList);
export const getIsTemplateImageAssetSelected = createSelector(getCreativeBuilderFeatureState, state => state.IsTemplateImageAssetSelected);
export const getIsSingleAdImageAssetSelected = createSelector(getCreativeBuilderFeatureState, state => state.IsSingleAdImageAssetSelected);
export const getIsAdDesignBackgroundImageSelected = createSelector(getCreativeBuilderFeatureState, state => state.IsAdDesignBackgroundImageSelected);
export const getIsTemplateBackgroundImageSelected = createSelector(getCreativeBuilderFeatureState, state => state.IsTemplateBackgroundImageSelected);
export const getIsTemplateCropping = createSelector(getCreativeBuilderFeatureState, state => state.IsTemplateCropping);
export const getIsSingleAdCropping = createSelector(getCreativeBuilderFeatureState, state => state.IsSingleAdCropping);
export const getIsBGAdDesignCropping = createSelector(getCreativeBuilderFeatureState, state => state.IsBGAdDesignCropping);
export const getEditorSelectedDefaultTemplate = createSelector(getCreativeBuilderFeatureState, state => state.editorSelectedDefaultTemplate);
export const getEditorSelectedDefaultTSingleAd = createSelector(getCreativeBuilderFeatureState, state => state.editorSelectedDefaultSingleAd);
export const getDefaultTemplateId = createSelector(getCreativeBuilderFeatureState, state => state.defaultTemplateId);
export const getDefaultSingleAdId = createSelector(getCreativeBuilderFeatureState, state => state.defaultSingleAdId);
export const showEditorSidebar = createSelector(getCreativeBuilderFeatureState, state => state.showEditorSidebar);
export const getIsPredefinedTemplate = createSelector(getCreativeBuilderFeatureState, state => state.isPredefinedTemplate);
export const getIsPredefinedSingleAd = createSelector(getCreativeBuilderFeatureState, state => state.isPredefinedSingleAd);
export const getActiveToolBar = createSelector(getCreativeBuilderFeatureState, state => state.activeToolBar);
export const getActiveSideBar = createSelector(getCreativeBuilderFeatureState, state => state.activeSideBar);
export const getActiveTextSidebar = createSelector(getCreativeBuilderFeatureState, state => state.activeTextSidebar);
export const getActiveShapeSidebar = createSelector(getCreativeBuilderFeatureState, state => state.activeShapeSidebar);
export const getSelectedShapeColor = createSelector(getCreativeBuilderFeatureState, state => state.selectedShapeColor);
export const getSelectedSet = createSelector(getCreativeBuilderFeatureState, state => state.selectedSet);
export const getSelectedAsset = createSelector(getCreativeBuilderFeatureState, state => state.selectedAsset);
export const getTemplateName = createSelector(getCreativeBuilderFeatureState, state => state.currentTemplate.templateName);
export const getSuggestedText = createSelector(getCreativeBuilderFeatureState, state => state.suggestedTexts);
export const getCustomFont = createSelector(getCreativeBuilderFeatureState, state => state.customFont);
export const getDefaultFont = createSelector(getCreativeBuilderFeatureState, state => state.defaultFont);
export const getDefaultDynamicText = createSelector(getCreativeBuilderFeatureState, state => state.defaultDynamicText);
export const getCurrentTemplate = createSelector(getCreativeBuilderFeatureState, state => state.currentTemplate);
export const getCurrentSingleAdDesign = createSelector(getCreativeBuilderFeatureState, state => state.currentSingleAdDesign);
export const getCurrentTemplateJson = createSelector(getCurrentTemplate, state => state.templateDataAsJson.TemplateData);

export function creativeBuilderReducer(state = initialCreativeBuilderState, action: creativeBuilderAction): CreativeBuilderState {
	switch (action.type) {
		case CreativeBuilderActionType.toggleEditorSidebar:
			return {
				...state,
				showEditorSidebar: action.payload
			};

		case CreativeBuilderActionType.deselectAssest:
			return {
				...state,
				deselectAssest: (Math.random() + 1).toString(36).substring(7)
			};

		case CreativeBuilderActionType.setSelectedSet:
			return {
				...state,
				selectedSet: action.payload
			};

		case CreativeBuilderActionType.setSelectedTextElement:
			return {
				...state,
				selectedTextElement: action.payload
			};

		case CreativeBuilderActionType.setActiveToolBar:
			return {
				...state,
				activeToolBar: action.payload
			};

		case CreativeBuilderActionType.setProductCatalogues:
			return {
				...state,
				productCatalogues: action.payload
			};

		case CreativeBuilderActionType.setSetList:
			return {
				...state,
				setList: action.payload
			};

		case CreativeBuilderActionType.setActiveSideBar:
			return {
				...state,
				activeSideBar: action.payload
			};

		case CreativeBuilderActionType.setEditorSelectedTemplate:
			return {
				...state,
				editorSelectedDefaultTemplate: action.payload
			};

		case CreativeBuilderActionType.setEditorSelectedSingleAd:
			return {
				...state,
				editorSelectedDefaultSingleAd: action.payload
			};

		case CreativeBuilderActionType.storeTemplateAdMainImage:
			const tempJsonSAMainImage = [...state.currentTemplate.templateDataAsJson.TemplateData];
			tempJsonSAMainImage.forEach(TemplatePage => {
				if (TemplatePage.TemplateTypeId === state.activeSingleAdPage) {
					TemplatePage.MainImage = action.payload;
				}
			});

			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: { TemplateData: tempJsonSAMainImage } }
			};

		case CreativeBuilderActionType.storeSingleAdMainImage:
			const adDesignJsonSAMainImage = [...state.currentSingleAdDesign.singleAdDataAsJson.singleAdData];
			adDesignJsonSAMainImage.forEach(SingleAdPage => {
				if (SingleAdPage.SingleAdTypeId === state.activeSingleAdPage) {
					SingleAdPage.MainImage = action.payload;
				}
			});

			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdDataAsJson: { singleAdData: adDesignJsonSAMainImage } }
			};

		case CreativeBuilderActionType.storeSingleAdBackgroundColour:
			const adDesignJsonSABackgroundColour = [...state.currentSingleAdDesign.singleAdDataAsJson.singleAdData];
			adDesignJsonSABackgroundColour.forEach(SingleAdPage => {
				if (SingleAdPage.SingleAdTypeId === state.activeSingleAdPage) {
					SingleAdPage.BackgroundColor = action.payload;
				}
			});

			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdDataAsJson: { singleAdData: adDesignJsonSABackgroundColour } }
			};

		case CreativeBuilderActionType.storeTemplateBackgroundColour:
			const tempJsonSABackgroundColour = [...state.currentTemplate.templateDataAsJson.TemplateData];
			tempJsonSABackgroundColour.forEach(TemplatePage => {
				if (TemplatePage.TemplateTypeId === state.activeTemplateSize) {
					TemplatePage.BackgroundColor = action.payload;
				}
			});

			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: { TemplateData: tempJsonSABackgroundColour } }
			};

		case CreativeBuilderActionType.setIsSingleAd:
			return {
				...state,
				IsSingleAd: action.payload
			};

		case CreativeBuilderActionType.setFacebookPageId:
			return {
				...state,
				facebookPageId: action.payload
			};

		case CreativeBuilderActionType.storeAdAccount:
			return {
				...state,
				adAccount: action.payload
			};

		case CreativeBuilderActionType.setIsFreshAccount:
			return {
				...state,
				IsFreshAccount: action.payload
			};

		case CreativeBuilderActionType.setAlertAlreadyCalled:
			return {
				...state,
				alertAlreadyCalled: action.payload
			};

		case CreativeBuilderActionType.setDefaultTemplateId:
			return {
				...state,
				defaultTemplateId: action.payload
			};

		case CreativeBuilderActionType.setDefaultSingleAdId:
			return {
				...state,
				defaultSingleAdId: action.payload
			};

		case CreativeBuilderActionType.setActiveTextSidebar:
			return {
				...state,
				activeTextSidebar: action.payload
			};

		case CreativeBuilderActionType.setSelectedShapeColor:
			return {
				...state,
				selectedShapeColor: action.payload
			};

		case CreativeBuilderActionType.setActiveShapeSidebar:
			return {
				...state,
				activeShapeSidebar: action.payload
			};

		case CreativeBuilderActionType.setTemplateCroppingInProgress:
			return {
				...state,
				IsTemplateCropping: action.payload
			};

		case CreativeBuilderActionType.setSingleAdCroppingInProgress:
			return {
				...state,
				IsSingleAdCropping: action.payload
			};

		case CreativeBuilderActionType.setBGAdDesignCroppingInProgress:
			return {
				...state,
				IsBGAdDesignCropping: action.payload
			};

		case CreativeBuilderActionType.setSelectedAsset:
			return {
				...state,
				selectedAsset: action.payload
			};

		case CreativeBuilderActionType.updateRedoTray:
			if (state.redoTray.length >= 10) {
				state.redoTray.shift();
			}

			return {
				...state,
				redoTray: [...state.redoTray, action.payload]
			};

		case CreativeBuilderActionType.updateRedoSingleAdTray:
			if (state.redoSingleAdTray.length >= 10) {
				state.redoSingleAdTray.shift();
			}

			return {
				...state,
				redoSingleAdTray: [...state.redoSingleAdTray, action.payload]
			};

		case CreativeBuilderActionType.updateUndoTray:
			if (state.undoTray.length >= 10) {
				state.undoTray.shift();
			}

			return {
				...state,
				undoTray: [...state.undoTray, action.payload]
			};

		case CreativeBuilderActionType.updateUndoSingleAdTray:
			if (state.undoSingleAdTray.length >= 10) {
				state.undoSingleAdTray.shift();
			}

			return {
				...state,
				undoSingleAdTray: [...state.undoSingleAdTray, action.payload]
			};

		case CreativeBuilderActionType.redoAction:
			const redoTray: ITemplate[] = JSON.parse(JSON.stringify(state.redoTray));
			const redoState = redoTray.pop();

			return {
				...state,
				currentTemplate: redoState,
				undoTray: [...state.undoTray, JSON.parse(JSON.stringify(state.currentTemplate))],
				redoTray: [...redoTray]
			};

		case CreativeBuilderActionType.redoSingleAdAction:
			const redoSingleAdTray: ISingleAd[] = JSON.parse(JSON.stringify(state.redoSingleAdTray));
			const redoSingleAdState = redoSingleAdTray.pop();

			return {
				...state,
				currentSingleAdDesign: redoSingleAdState,
				undoSingleAdTray: [...state.undoSingleAdTray, JSON.parse(JSON.stringify(state.currentSingleAdDesign))],
				redoSingleAdTray: [...redoSingleAdTray]
			};

		case CreativeBuilderActionType.undoAction:
			const undoTray: ITemplate[] = JSON.parse(JSON.stringify(state.undoTray));
			const undoState = undoTray.pop();

			return {
				...state,
				currentTemplate: undoState,
				undoTray: [...undoTray],
				redoTray: [...state.redoTray, JSON.parse(JSON.stringify(state.currentTemplate))]
			};

		case CreativeBuilderActionType.undoSingleAdAction:
			const undoSingleAdTray: ISingleAd[] = JSON.parse(JSON.stringify(state.undoSingleAdTray));
			const undoSingleAdState = undoSingleAdTray.pop();

			return {
				...state,
				currentSingleAdDesign: undoSingleAdState,
				undoSingleAdTray: [...undoSingleAdTray],
				redoSingleAdTray: [...state.redoSingleAdTray, JSON.parse(JSON.stringify(state.currentSingleAdDesign))]
			};

		case CreativeBuilderActionType.resetRedoTray:
			return {
				...state,
				redoTray: []
			};

		case CreativeBuilderActionType.resetUndoTray:
			return {
				...state,
				undoTray: []
			};

		case CreativeBuilderActionType.resetRedoSingleAdTray:
			return {
				...state,
				redoSingleAdTray: []
			};

		case CreativeBuilderActionType.resetUndoSingleAdTray:
			return {
				...state,
				undoSingleAdTray: []
			};

		case CreativeBuilderActionType.storeTemplateImageAssets:
			return {
				...state,
				templateImageAssets: action.payload
			};

		case CreativeBuilderActionType.storeSingleAdImageAssets:
			return {
				...state,
				singleAdImageAssets: action.payload
			};

		case CreativeBuilderActionType.setSuggestedText:
			return {
				...state,
				suggestedTexts: action.payload
			};

		case CreativeBuilderActionType.setLoadImageSpiner:
			return {
				...state,
				showImageLoading: action.payload
			};

		case CreativeBuilderActionType.setIsPredefinedTemplate:
			return {
				...state,
				isPredefinedTemplate: action.payload
			};

		case CreativeBuilderActionType.setIsPredefinedSingleAd:
			return {
				...state,
				isPredefinedSingleAd: action.payload
			};

		case CreativeBuilderActionType.storeTemplate:
			return {
				...state,
				currentTemplate: action.payload
			};

		case CreativeBuilderActionType.storeSingleAdDesign:
			return {
				...state,
				currentSingleAdDesign: action.payload
			};

		case CreativeBuilderActionType.storeCustomFont:
			return {
				...state,
				customFont: action.payload
			};

		case CreativeBuilderActionType.storeDefaultFont:
			return {
				...state,
				defaultFont: action.payload
			};

		case CreativeBuilderActionType.storeDefaultDynamicText:
			return {
				...state,
				defaultDynamicText: action.payload
			};

		case CreativeBuilderActionType.setActiveTemplateSize:
			return {
				...state,
				activeTemplateSize: action.payload
			};
		case CreativeBuilderActionType.setActiveSingleAdPage:
			return {
				...state,
				activeSingleAdPage: action.payload
			};

		case CreativeBuilderActionType.storeSingleAdName:
			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdName: action.payload }
			};

		case CreativeBuilderActionType.storeTemplateName:
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateName: action.payload }
			};

		case CreativeBuilderActionType.storeTemplateData:
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateData: action.payload }
			};

		case CreativeBuilderActionType.storeTemplateDataAsJson:
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: action.payload }
			};

		case CreativeBuilderActionType.storeSingleAdData:
			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdDataAsJson: { singleAdData: action.payload } }
			};

		case CreativeBuilderActionType.storeTextElement:
			const tempJson = { ...state.currentTemplate.templateDataAsJson };
			tempJson.TemplateData.forEach(templateX => {
				if (templateX.TemplateTypeId === state.activeTemplateSize) {
					templateX.TextElements = action.payload;
				}
			});
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: tempJson }
			};

		case CreativeBuilderActionType.storeLineElement:
			const tempJsonLine = { ...state.currentTemplate.templateDataAsJson };
			tempJsonLine.TemplateData.forEach(templateX => {
				if (templateX.TemplateTypeId === state.activeTemplateSize) {
					templateX.LineElements = action.payload;
				}
			});
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: tempJsonLine }
			};

		case CreativeBuilderActionType.storeShapeElement:
			const tempJsonShape = { ...state.currentTemplate.templateDataAsJson };
			tempJsonShape.TemplateData.forEach(templateX => {
				if (templateX.TemplateTypeId === state.activeTemplateSize) {
					templateX.ShapeElements = action.payload;
				}
			});
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: tempJsonShape }
			};

		case CreativeBuilderActionType.storeSingleAdImageElement:
			const tempJsonSAImage = [...state.currentSingleAdDesign.singleAdDataAsJson.singleAdData];
			tempJsonSAImage.forEach(SingleAdPages => {
				if (SingleAdPages.Page === state.activeSingleAdPage) {
					SingleAdPages.ImageElements = action.payload;
				}
			});

			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdDataAsJson: { singleAdData: tempJsonSAImage } }
			};

		case CreativeBuilderActionType.storeSingleAdTextElement:
			const tempJsonSAText = [...state.currentSingleAdDesign.singleAdDataAsJson.singleAdData];
			tempJsonSAText.forEach(SingleAdPage => {
				if (SingleAdPage.SingleAdTypeId === state.activeSingleAdPage) {
					SingleAdPage.TextElements = action.payload;
				}
			});

			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdDataAsJson: { singleAdData: tempJsonSAText } }
			};

		case CreativeBuilderActionType.storeSingleAdLineElement:
			const tempJsonAsLine = [...state.currentSingleAdDesign.singleAdDataAsJson.singleAdData];
			tempJsonAsLine.forEach(SingleAdPage => {
				if (SingleAdPage.SingleAdTypeId === state.activeSingleAdPage) {
					SingleAdPage.LineElements = action.payload;
				}
			});

			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdDataAsJson: { singleAdData: tempJsonAsLine } }
			};

		case CreativeBuilderActionType.storeSingleAdSvgElement:
			const tempJsonAsShape = [...state.currentSingleAdDesign.singleAdDataAsJson.singleAdData];
			tempJsonAsShape.forEach(SingleAdPage => {
				if (SingleAdPage.SingleAdTypeId === state.activeSingleAdPage) {
					SingleAdPage.SvgElements = action.payload;
				}
			});

			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, singleAdDataAsJson: { singleAdData: tempJsonAsShape } }
			};

		case CreativeBuilderActionType.storeTemplateSvgElement:
			const tempJsonAsSvg = [...state.currentTemplate.templateDataAsJson.TemplateData];
			tempJsonAsSvg.forEach(templateX => {
				if (templateX.TemplateTypeId === state.activeTemplateSize) {
					templateX.SvgElements = action.payload;
				}
			});

			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: { TemplateData: tempJsonAsSvg } }
			};

		case CreativeBuilderActionType.storeImageElement:
			const tempJsonImage = { ...state.currentTemplate.templateDataAsJson };
			tempJsonImage.TemplateData.forEach(templateX => {
				if (templateX.TemplateTypeId === state.activeTemplateSize) {
					templateX.ImageElements = action.payload;
				}
			});

			return {
				...state,
				currentTemplate: { ...state.currentTemplate, templateDataAsJson: tempJsonImage }
			};

		case CreativeBuilderActionType.storeUsedImageElement:
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, attachedImageAssets: action.payload }
			};

		case CreativeBuilderActionType.storeSingleAdUsedImageElement:
			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, attachedImageAssets: action.payload }
			};

		case CreativeBuilderActionType.storeUsedFontElement:
			return {
				...state,
				currentTemplate: { ...state.currentTemplate, attachedFonts: action.payload }
			};

		case CreativeBuilderActionType.storeSingleAdUsedFontElement:
			return {
				...state,
				currentSingleAdDesign: { ...state.currentSingleAdDesign, attachedFonts: action.payload }
			};

		case CreativeBuilderActionType.previewTemplateCropImage:
			return {
				...state,
				previewTemplateCropImage: action.payload
			};

		case CreativeBuilderActionType.previewSingleAdCropImage:
			return {
				...state,
				previewSingleAdCropImage: action.payload
			};

		case CreativeBuilderActionType.previewBGAdDesignCropImage:
			return {
				...state,
				previewBGAdDesignCropImage: action.payload
			};

		case CreativeBuilderActionType.setTemplateImageAssetSelected:
			return {
				...state,
				IsTemplateImageAssetSelected: action.payload
			};

		case CreativeBuilderActionType.setSingleAdImageAssetSelected:
			return {
				...state,
				IsSingleAdImageAssetSelected: action.payload
			};

		case CreativeBuilderActionType.setAdDesignBackgroundImageSelected:
			return {
				...state,
				IsAdDesignBackgroundImageSelected: action.payload
			};

		case CreativeBuilderActionType.setTemplateBackgroundImageSelected:
			return {
				...state,
				IsTemplateBackgroundImageSelected: action.payload
			};

		case CreativeBuilderActionType.resetTemplateAndSetAndSingleAd:
			return {
				...state,
				currentTemplate: currentTemplateInit,
				currentSingleAdDesign: currentSingleAdDesignInit,
				selectedSet: null,
				selectedAsset: {
					type: '',
					id: null
				}
			};

		default:
			return state;
	}
}
