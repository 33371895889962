import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { navLinksList } from 'src/app/back-office/models/back-office-header-routes-model';
import { Modules } from '../permisions/enums/modules';
import { MiscellaneousPermissions } from '../permisions/enums/miscellaneous-permissions';
import { NavLinksInterface } from './nav-links.interface';
import { select, Store } from '@ngrx/store';
import { getPermissions, UserState } from '../state/user/user.reducer';
import _ from 'lodash';

@Component({
	selector: 'app-back-office-navigation',
	templateUrl: './back-office-navigation.component.html',
	styleUrls: ['./back-office-navigation.component.scss']
})
export class BackOfficeNavigationComponent implements OnInit {
	public navLinks: NavLinksInterface[];
	public Module = Modules;
	public miscPermissions = MiscellaneousPermissions;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private router: Router, private userStore: Store<UserState>) {
		this.navLinks = navLinksList;
	}

	public ngOnInit(): void {
		this.router.events.pipe(takeUntil(this.unsubscriber$)).subscribe(res => {
			this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
		});
		this.navBarPermissions();
	}

	public navBarPermissions(): void {
		let miscellaneous: number;
		const linksList = _.cloneDeep(navLinksList);

		this.userStore.pipe(select(getPermissions), take(1)).subscribe(module => {
			module
				.filter(element => element.module === Modules.Miscellaneous)
				.forEach(secondModule => {
					miscellaneous = secondModule.permissions[0];
				});
		});
		linksList.forEach((module, lmao, das) => {
			const miscellaneousPermissions: boolean = module.permissions.includes(miscellaneous);
			if (!miscellaneousPermissions) {
				const hideLinks: number = this.navLinks.findIndex(element => element.index === module.index);
				this.navLinks.splice(hideLinks, 1);
			}
		});
	}
}
