import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-input-field-password',
	templateUrl: './input-field-password.component.html',
	styleUrls: ['./input-field-password.component.scss']
})
export class InputFieldPasswordComponent implements OnInit {
	@ViewChild('password') public textBox: TextBoxComponent;
	@Input('placeHolderTextBox') public placeHolderTextBox: string = 'Enter text here';
	@Input('inputControl') public inputControl: FormControl;
	@Input() public disabled: boolean = false;
	@Input('readOnly') public readOnly: boolean = false;
	@Input('errorMessage') public errorMessage: string = 'Enter text';
	@Input('maxLength') public maxLength: number = 180;
	@Input('hideError') public hideError: boolean = false;
	@Input('showValidIcon') public showValidIcon: boolean = false;
	@Input('showCustomIcon') public showCustomIcon: string = null;
	@Input() iconWidth: string = null;
	@Input() marginRight: string = null;
	@Output() public kendoInputEvent = new EventEmitter<any>();
	private unsubscriber$ = new Subject<void>();

	constructor() {}

	public ngOnInit(): void {}

	public ngAfterViewInit(): void {
		this.textBox.input.nativeElement.type = 'password';
	}

	public showIconStyle(): Object {
		let styles = {};
		if (this.iconWidth && this.marginRight) {
			styles = {
				width: this.iconWidth,
				'margin-right': this.marginRight
			};
		}
		return styles;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
