<div class="ac-preview-container">
	<div class="ac-align-dropdown">
		<div class="ac-dropdown-width">
			<app-dropdown-search-select
				[dropdownData]="dropdownPreviewPlacement"
				[dropdownFormControl]="adCreateFormGroup.get('preview')"
				[searchEnable]="true"
			></app-dropdown-search-select>
		</div>
	</div>
	<div class="ac-ad-preview-box">
		<div class="no-preview" *ngIf="!(iframeSrc$ | async) && !(isFetchingIframe$ | async)">
			<img src="assets/icons/aaa/no-preview-icon.svg" alt="" />
		</div>
		<lolly-spinning-loader class="spinner" *ngIf="!(iframeSrc$ | async) && (isFetchingIframe$ | async)"></lolly-spinning-loader>
		<div class="ad-preview" *ngIf="iframeSrc$ | async" [ngClass]="setPreviewClass()" [innerHTML]="iframeSrc$ | async | sanitizeHtml"></div>
	</div>
</div>
