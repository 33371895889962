<div mat-dialog-title>
	<div (click)="close()" class="x">
		<img alt="close" src="assets/icons/user-management/account/x.png">
	</div>
	<div class="upload">
		<img alt="dialog" src="assets/icons/user-management/account/check.png">
	</div>
	<h4>Your password has been updated correctly</h4>
</div>

<div class="buttons">
	<app-button (buttonClicked)="close()"
				[buttonClass]="buttonClassEnum.Primary"
				[buttonType]="buttonTypeEnum.Simple"
				buttonLabel="OK">
	</app-button>
</div>
