import { Injectable } from '@angular/core';
import { ExcelService } from '../../../_services/excel.service';
import { ReportModel } from '../../models/report.model';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { ChartLabel } from '../../models/chart-label';
import { TableViewColumnTemplateEnum } from '../../../shared/generic-table2/models/table-structure/table-view-column-template.enum';
import { getCurrencySymbol } from '@angular/common';
import { GridLayout } from '../../models/grid-layout';
import { DefaultChartValues } from '../../models/default-chart-values';
import { AxisLine } from '../../models/axis-line';
import { graphic } from 'echarts';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { chartTooltipModel } from '../../shared/models/chart-tooltip.model';

@Injectable({
	providedIn: 'root'
})
export class VerticalBarChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected store: Store<ReportingState>,
		protected toastNotificationService: ToastNotificationService
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	createChart(widget: ReportModel) {
		const label = new ChartLabel('left', 'middle', 'inside', 0, 15, 12, widget.details.reportProperty.showDataLabels);
		const chartOptions = this.initChartOptions(widget);
		chartOptions.grid = new GridLayout('120', '20', '20', '115', false);
		const chartData = this.createChartSourceForComplexData(widget, false, false, false, label);

		chartOptions.yAxis = this.createSecondaryAxis(chartData, widget, 'xAxisIndex', widget.details.reportProperty.verticalAxisTitle, 100, 20);
		chartOptions.tooltip = chartTooltipModel;
		chartOptions.xAxis = this.createPrimaryAxis(chartData, widget, 100, widget.details.reportProperty.horizontalAxisTitle);
		(chartOptions.yAxis.axisLabel = {
			margin: 70,
			align: 'left',
			formatter: (value: any) => {
				return value.length > 10 ? value.substr(0, 10) + '...' : value;
			}
		}),
			(chartOptions.nameTextStyle = {
				color: '#424242'
			}),
			(chartOptions.series = chartData.data);
		chartOptions.legend.data = [];
		chartOptions.legend.data = chartData.data.map(item => item.name);

		return chartOptions;
	}

	public createYAxis(widget: ReportModel, nameGap: number, alignWithLabel: boolean, axisTitle: string, initialData: any, label: ChartLabel) {
		const formattedData = this.getFormattedAxisData(widget, initialData, label);
		return {
			type: 'category',
			nameGap: nameGap,
			nameLocation: 'middle',
			data:
				formattedData && formattedData.length > 0
					? initialData.map((data: any) => data.name)
					: (DefaultChartValues as any)[widget.type].defaultAxisValues,
			name: '',
			nameTextStyle: {
				color: '#424242'
			},
			axisLabel: {
				formatter: (value: any) => {
					return value.length > 10 ? value.substr(0, 10) + '...' : value;
				},
				rotate: 45
			},
			axisTick: {
				show: false,
				alignWithLabel: alignWithLabel
			},
			axisLine: new AxisLine(true)
		};
	}

	public createXAxis(widget: ReportModel, nameGap: number, axisTitle: string): any {
		return {
			type: 'value',
			nameGap: nameGap,
			nameLocation: 'middle',
			name: axisTitle,
			nameTextStyle: {
				color: '#424242'
			},
			axisLine: {
				show: true
			},
			axisLabel: {
				formatter: (value: any) => {
					return value.length > 10 ? value.substr(0, 10) + '...' : value;
				},
				textStyle: {
					color: '#999'
				}
			},
			axisTick: {
				show: false
			}
		};
	}

	private createSeriesForBarChart(widget: ReportModel, chartData: any, labelAlignment: any, legendName: string) {
		const series: any = [
			{
				label: {
					normal: labelAlignment
				},
				roam: false,
				name: legendName,
				data: chartData.length > 0 ? chartData : (DefaultChartValues as any)[widget.type].series,
				type: this.chartTypes.get(widget.details.chartType.id),
				itemStyle: {
					normal: {
						color: this.getGradient(widget)
					}
				}
			}
		];
		if (chartData.length > 0) {
			const goalsSeries = this.createGoalsSeriesData(widget, chartData.length);
			series.push(...goalsSeries);
		}

		return series;
	}

	private getGradient(widget: ReportModel) {
		if (widget.details.style.hasGradient) {
			return new graphic.LinearGradient(0, 0, 1, 0, [
				{ offset: 0, color: this.shadeColor(widget.details.style.color, 40) },
				{ offset: 0.5, color: this.shadeColor(widget.details.style.color, 0) },
				{ offset: 1, color: this.shadeColor(widget.details.style.color, -40) }
			]);
		} else {
			return widget.details.style.color;
		}
	}
}
