import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { BudgetAllocatedEnum } from 'src/app/campaign-smart-create/models/budget-allocated.enum';
import { CampaignBudgetOptimizationInterface } from 'src/app/campaign-smart-create/models/campaign-budget-optimization.interface';
import { OptimizationAndDeliveryInterface } from 'src/app/campaign-smart-create/models/optimization-and-delivery.interface';
import { TargetingAudience } from 'src/app/campaign-smart-create/models/targeting-audience';
import { DateRangeX } from 'src/app/shared/calendar/calendar.models';
import { CampaignDateChoices } from 'src/app/shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { AdsetActions, AdsetActionTypes } from './adset-state.action';
import _ from 'lodash';
import { TreeViewInterface } from '@filed-com/filed-lib/lib/tree-view/models/tw-interface';
import { DeviceEnum, DeviceOS, DeviceOSEnum, Devices, IDeviceOs } from '../../components/devices/devices.component';

export interface AdsetState {
	adSetName: string;
	facebookPageId: string;
	instagramPageId: string;
	placements: CatalogNodeInterface[];
	targeting: TargetingAudience;
	date: DateRangeX<string>;
	dateChoice: CampaignDateChoices;
	device: DeviceEnum[];
	deviceOs: DeviceOSEnum[];
	budgetOptimization: CampaignBudgetOptimizationInterface;
	optimizationAndDelivery: OptimizationAndDeliveryInterface;
	placementPerPlatform: TreeViewInterface[];
}

export const initialAdsetState: AdsetState = {
	adSetName: '',
	facebookPageId: '',
	instagramPageId: '',
	placements: [],
	targeting: new TargetingAudience(),
	date: {
		startDate: '',
		endDate: ''
	},
	dateChoice: CampaignDateChoices.Default,
	budgetOptimization: {
		budgetAllocatedTypeId: BudgetAllocatedEnum.LifeTime,
		amount: null,
		minimumBudget: null,
		bidStrategy: '',
		deliveryType: ''
	},
	optimizationAndDelivery: {
		optimizationGoal: '',
		click: '',
		view: '',
		billingEvent: '',
		bidControl: ''
	},
	device: Devices.all,
	deviceOs: DeviceOS.all,
	placementPerPlatform: []
};

export function adsetStateReducer(state = initialAdsetState, action: AdsetActions): AdsetState {
	switch (action.type) {
		case AdsetActionTypes.UpdateAdSetName:
			return {
				...state,
				adSetName: action.payload
			};
		case AdsetActionTypes.UpdateOptimizationAndDelivery:
			return {
				...state,
				optimizationAndDelivery: action.payload
			};
		case AdsetActionTypes.UpdateAdSetBudgetOptimization:
			return {
				...state,
				budgetOptimization: action.payload
			};
		case AdsetActionTypes.UpdatePages:
			return {
				...state,
				facebookPageId: action.payload.facebookPageId,
				instagramPageId: action.payload?.instagramPageId
			};
		case AdsetActionTypes.UpdateInstagramId: {
			return {
				...state,
				instagramPageId: action.payload
			};
		}
		case AdsetActionTypes.UpdateTargeting: {
			return {
				...state,
				targeting: action.payload
			};
		}
		case AdsetActionTypes.UpdateCampaignActiveDate:
			return {
				...state,
				date: action.payload.date,
				dateChoice: action.payload.dateChoiceType
			};

		case AdsetActionTypes.UpdatePlacements:
			return {
				...state,
				placements: action.payload
			};
		case AdsetActionTypes.UpdateAdsetState: {
			const data = _.cloneDeep(action.payload);
			return {
				...state,
				...data
			};
		}
		case AdsetActionTypes.UpdatePlacementsPerPlatform: {
			const data = _.cloneDeep(action.payload);
			return {
				...state,
				placementPerPlatform: data
			};
		}
		case AdsetActionTypes.UpdateDevice:
			return {
				...state,
				device: action.payload
			};
		case AdsetActionTypes.UpdateDeviceOs:
			return {
				...state,
				deviceOs: action.payload
			};
		case AdsetActionTypes.ResetState:
			return Object.assign({}, initialAdsetState);
		default:
			return state;
	}
}
