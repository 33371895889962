import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BuildCampaignPanelsEnum } from '../../../../campaign-smart-create/components/build-campaign-template/build-campaign-panels.enum';
import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ImageStateEnum } from '../../../hover-select-directive/image-state.enum';
import { CatalogNodeInterface } from '../../../../campaign-smart-create/catalogs/catalog-node.interface';
import { CatalogItemsInterface } from '../../../../campaign-smart-create/catalogs/catalog.items.interface';
import { ObjectivesEnum } from '../../../../campaign-smart-create/models/objectives.enum';
import { SubObjectives } from '../../../../campaign-smart-create/models/sub-objectives';

@Component({
	selector: 'app-objectives-card',
	templateUrl: './objective-card.component.html',
	styleUrls: ['./objective-card.component.scss']
})
export class ObjectiveCardComponent implements OnInit, OnDestroy {
	@Input() public objectivesGroup: AbstractControl;
	@Input() public objectives: CatalogNodeInterface[];
	@Input() public isObjectivesPanelSelected: boolean;
	@Input() public isSubObjectivesPanelSelected: boolean;
	@Input() public isSubObjectivesOptionsPanelSelected: boolean;

	@Output() public onExpansionClick = new EventEmitter();

	public state = ImageStateEnum;
	public selectedObjective: CatalogNodeInterface;
	public selectedSubObjective: CatalogItemsInterface | CatalogItemsInterface[];
	public campaignPanels = BuildCampaignPanelsEnum;
	public contexts: string[];
	public objective = ObjectivesEnum;
	public appInstalls = appInstalls;
	private unsubscriber$ = new Subject();
	public selectedSubObjectiveForCheck: CatalogItemsInterface;

	constructor() {}

	public ngOnInit(): void {
		this.hideObjectivesAndSUbObjectives();
		this.onActivatedRoute();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onActivatedRoute(): void {
		if (this.objectivesGroup.get('objective').value && this.objectivesGroup.get('subObjective').value) {
			this.selectedObjective = this.objectives.find(objective => objective.name === this.objectivesGroup.get('objective').value);
			this.selectedObjective.imageState = ImageStateEnum.Pressed;
			this.selectedSubObjective = this.selectedObjective?.items?.find(subObjective =>
				subObjective.name.includes(this.objectivesGroup.get('subObjective').value)
			);
			this.updateSubObjectiveState(this.selectedSubObjective);
		}
	}

	public checkSelected(value: CatalogItemsInterface): string {
		const objective = Object.values(value.items);
		const selectedObjective = objective.find(item => item.kind === 'Objective');
		return selectedObjective.name;
	}

	public onObjectiveClick(objective: CatalogNodeInterface): void {
		this.selectedObjective = objective;
		this.objectivesGroup.get('objective').setValue(objective.name);
		const newSubObjective: CatalogItemsInterface = objective.items ? objective.items[0] : null;
		this.updateSubObjectiveState(newSubObjective);
	}

	public updateSubObjectiveState(subObjective: CatalogItemsInterface): void {
		this.selectedSubObjective = Object.values(subObjective.items);
		this.objectivesGroup.get('subObjectiveDisplayName').setValue(subObjective.display_name);
		for (let i = 0; i < this.selectedSubObjective.length; ++i) {
			if (this.selectedSubObjective[i].kind === 'Objective') {
				this.objectivesGroup.get('subObjective').setValue(this.selectedSubObjective[i].name);
				this.selectedSubObjectiveForCheck = this.selectedSubObjective[i];
			} else if (this.selectedSubObjective[i].kind === 'DestinationType') {
				this.objectivesGroup.get('destinationType').setValue(this.selectedSubObjective[i].name);
			}
		}
	}

	public isCardSelected(value: string, control: string): boolean {
		return this.objectivesGroup.get(control).value === value;
	}

	public hideObjectivesAndSUbObjectives(): void {
		this.objectives = this.objectives.filter(item => item.name !== this.objective.AppActivity);
		const unwanted = [
			SubObjectives.LocalAwareness,
			SubObjectives.LeadGeneration,
			SubObjectives.LeadGenerationNew,
			SubObjectives.Message,
			SubObjectives.StoreVisits
		];
		this.objectives.forEach(item => {
			item.items = item.items.filter(child => child.name !== unwanted.filter(x => x === child.name)[0]);
		});
	}
}

const newSubObjective = {
	display_name: 'App install campaign type',
	items: [
		{
			AUTOMATED_APP_ADS: {
				display_name: 'Automated app ads',
				name: 'AUTOMATED_APP_ADS',
				description: 'Drive campaign performance and continue to sustain it over time.'
			},
			APP_ADS: {
				display_name: 'App ads',
				name: 'APP_ADS',
				description: 'Manually Setup and run your app campaigns.'
			},
			APP_INSTALLS: {
				display_name: 'App Installs',
				description: "Send people to the store where they can download your business's app.",
				kind: 'Objective',
				name: 'APP_INSTALLS'
			}
		}
	]
};

export const appInstalls = {
	name: 'APP_INSTALLS',
	kind: 'Objective',
	items: {
		APP_INSTALL_CAMPAIGN_TYPE: {
			display_name: 'App install campaign type',
			displayNameAutogen: 'App install campaign type',
			items: [
				{
					display_name: 'Automated app ads',
					name: 'AUTOMATED_APP_ADS',
					description: 'Drive campaign performance and continue to sustain it over time.',
					items: {
						AUTOMATED_APP_ADS: {
							display_name: 'Automated app ads',
							name: 'AUTOMATED_APP_ADS',
							description: 'Drive campaign performance and continue to sustain it over time.',
							kind: 'Objective'
						}
					}
				},
				{
					display_name: 'App ads',
					name: 'APP_ADS',
					description: 'Manually Setup and run your app campaigns.',
					items: {
						APP_ADS: {
							display_name: 'App ads',
							name: 'APP_ADS',
							description: 'Manually Setup and run your app campaigns.',
							kind: 'Objective'
						}
					}
				}
			],
			kind: 'Objective',
			name: 'APP_INSTALL_CAMPAIGN_TYPE'
		}
	},
	description: "Send people to the store where they can download your business's app.",
	display_name_autogen: 'App Installs',
	description_autogen: 'App installs.',
	image_name_autogen: 'objective--app-installs',
	display_name: 'App Installs'
};
