import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReportModel } from '../../models/report.model';
import { MetricModel } from '../../models/metric.model';
import { BaseApiUrl } from '../../../_services/base-api-urls';

@Injectable({
	providedIn: 'root'
})
export class ReportService {
	private readonly baseURL = BaseApiUrl.Reporting + 'widget/';

	constructor(private http: HttpClient) {}

	public createReport(widget: ReportModel): Observable<number> {
		return this.http.post<number>(`${this.baseURL}`, widget);
	}

	public deleteReport(id: number): Observable<void> {
		return this.http.delete<void>(`${this.baseURL}${id}`);
	}

	public updateReport(report: ReportModel): Observable<number> {
		return this.http.put<number>(`${this.baseURL}${report.id}`, report);
	}

	public createMetric(metric: MetricModel): Observable<void> {
		return this.http.post<void>(`${this.baseURL}create-metric`, metric);
	}

	public getAllMetrics(): Observable<MetricModel[]> {
		return this.http.get<MetricModel[]>(`${this.baseURL}get-metrics`);
	}

	public getUserReports(): Observable<ReportModel[]> {
		return this.http.get<ReportModel[]>(`${this.baseURL}`);
	}

	public getReportById(id: number): Observable<ReportModel> {
		return this.http.get<ReportModel>(`${this.baseURL}${id}`);
	}
}
