import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CatalogNode } from '../../../../campaign-smart-create/models/catalog-node';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BudgetAllocatedEnum } from '../../../../campaign-smart-create/models/budget-allocated.enum';
import { CampaignBudgetOptimizationInterface } from '../../../../campaign-smart-create/models/campaign-budget-optimization.interface';
import { ValidatorMessages } from '../../../form-input/validatorMessagesInputs';
import { InfoHoverDataModel } from '../../../info/info-hover/info-hover-data.model';

@Component({
	selector: 'app-bid-strategy-card',
	templateUrl: './bid-strategy-card.component.html',
	styleUrls: ['./bid-strategy-card.component.scss']
})
export class BidStrategyCardComponent implements OnInit, OnDestroy, OnChanges {
	@Input() public selectedObjective: string;
	@Input() public cboGroup: FormControl | AbstractControl;
	@Input() public bidStrategy: string;
	@Input() public bidStrategyName: string;
	@Input() public deliveryName: CatalogNode;
	@Input() public currency: string;
	@Input() public isPanelSelected: boolean;
	@Input() public minBudget: number;
	@Input() public budgetOptimization: CampaignBudgetOptimizationInterface;
	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public toggleStatus = new EventEmitter();

	public cboToggle: boolean;
	public budgetType = BudgetAllocatedEnum;
	public validationMessage: ValidatorMessages[];
	private unSubscriber$ = new Subject<void>();
	public cboInfoModel: InfoHoverDataModel;

	constructor() {}

	public ngOnInit(): void {
		this.initToggleListener();

		const budgetControl = this.cboGroup.get('amount');
		budgetControl.valueChanges.pipe(takeUntil(this.unSubscriber$)).subscribe(value => {
			budgetControl.markAsTouched();
		});
		this.cboInfoModel = {
			message:
				'Campaign budget optimization (CBO) automatically manages your campaign budget across all ad sets to get you the overall best results. You set one central campaign budget and this budget continuously distributes in real-time to ad sets with the highest success rate, for the lowest cost per result.'
		};
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.validationMessage = [
			{
				validationType: 'required',
				validationMessage: 'This field is mandatory'
			},
			{
				validationType: 'min',
				validationMessage: `${'Minimum budget is ' + this.minBudget + ' ' + this.currency}`
			}
		];
	}

	public ngOnDestroy(): void {
		this.unSubscriber$.next();
		this.unSubscriber$.complete();
	}

	public initToggleListener(): void {
		if (this.budgetOptimization) {
			this.cboToggle = true;
		}
		if (this.cboToggle && !this.cboGroup.get('budgetType').value) {
			this.cboGroup.get('budgetType').patchValue(this.budgetType.LifeTime);
		}
	}

	public onBudgetTypeClick(type: BudgetAllocatedEnum): void {
		this.cboGroup.get('budgetType').patchValue(type);
	}

	public onToggle(): void {
		this.cboToggle = !this.cboToggle;
		this.toggleStatus.emit(this.cboToggle);
		if (this.cboToggle) {
			this.cboGroup.get('amount').setValidators([Validators.required, Validators.max(1000000000), Validators.pattern('^[0-9]+$')]);
			this.initToggleListener();
		} else {
			this.cboGroup.reset();
			this.cboGroup.clearValidators();
			this.budgetOptimization = null;
		}
		this.cboGroup.updateValueAndValidity();
	}
}
