import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { GridReadyEvent } from 'ag-grid-community';

@Directive({
	selector: '[agGridDirective]'
})
export class AgGridDirective {
	constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

	@HostListener('gridReady')
	public onLoad(): void {
		const sideBar = this.elementRef.nativeElement.querySelector('.ag-side-buttons');
		this.renderer.setStyle(sideBar, 'display', 'none');
	}
}
