<div class="cards-container">
	<div [ngClass]="{ cardConnected: channel.status }" *ngFor="let channel of integrations" id="{{ channel.source }}" class="card">
		<div class="status-icon">
			<img *ngIf="channel.status" src="assets/icons/integrations/check-good.svg" />
		</div>
		<div class="integration-icon">
			<img [src]="channel.icon" />
		</div>
		<div class="title">
			{{ channel.title }}
		</div>
		<div *ngIf="channel.status" class="status-text-action">
			{{ 'Connected' }}
		</div>
		<div (click)="connectChannel(channel)" *ngIf="!channel.status && channel.source !== 'facebook-normal'" class="status-text-action">
			{{ 'Connect' }}
		</div>
		<app-connect-to-facebook
			*ngIf="!channel.status && channel.source === 'facebook-normal'"
			[integrationsFB]="true"
			(connectionResponse)="fbConnectionResponse($event)"
		></app-connect-to-facebook>
	</div>
</div>
