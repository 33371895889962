import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptimizationLevelsEnum } from '../../optimise/models/optimization-level.enum';
import { RecommendationType } from '../../optimise/models/recomendation-type.enum';
import { RecommendationClickedAction, RecommendationClickedEvent } from '../../optimise/models/recommendation-clicked-action';
import { RecommendationPlatform } from '../../optimise/models/recommendation-platform.enum';
import { Recommendation } from '../../optimise/models/recommendation.model';
import { ButtonClassEnum } from '../button-component/button-class.enum';
import { ButtonTypeEnum } from '../button-component/button-type.enum';

@Component({
	selector: 'app-recommendation-template',
	templateUrl: './recommendation-template.component.html',
	styleUrls: ['./recommendation-template.component.scss']
})
export class RecommendationTemplateComponent implements OnInit {
	@Input() public recommendation: Recommendation;
	@Input() public showButtons = true;
	@Output() recommendationActionEmitter: EventEmitter<RecommendationClickedEvent> = new EventEmitter();

	public levels = OptimizationLevelsEnum;
	public imagePath: string;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	constructor() {}

	ngOnInit() {
		if (this.recommendation.source === RecommendationPlatform.Facebook) {
			this.imagePath = 'assets/icons/Icon-facebook.svg';
		} else if (this.recommendation.source === RecommendationPlatform.Dexter) {
			this.imagePath = 'assets/icons/icon-dexter-x-dark.svg';
		} else if (this.recommendation.source === RecommendationPlatform.Google) {
			this.imagePath = 'assets/icons/google-icon.svg';
		}
	}

	public viewMoreRecommendation(): void {
		const action: RecommendationClickedEvent = {
			recommendation: this.recommendation,
			action: RecommendationClickedAction.ViewMore
		};
		this.recommendationActionEmitter.emit(action);
	}

	public getLabelClass(label: string) {
		switch (label) {
			case RecommendationType.BudgetAndBid:
				return 'budget';
			case RecommendationType.Audience:
				return 'audience';
			case RecommendationType.Creative:
				return 'creative';
			case RecommendationType.Performance:
				return 'performance';
			case RecommendationType.PlacementAndDevice:
				return 'placement';
		}
	}

	public rejectRecommendation(): void {
		const action: RecommendationClickedEvent = {
			recommendation: this.recommendation,
			action: RecommendationClickedAction.Reject
		};
		this.recommendationActionEmitter.emit(action);
	}

	public editStructure(): void {
		const action: RecommendationClickedEvent = {
			recommendation: this.recommendation,
			action: RecommendationClickedAction.Edit
		};
		this.recommendationActionEmitter.emit(action);
	}

	// Unused method will be used when we auto-apply recommendations
	public applyRecommendation(): void {
		const action: RecommendationClickedEvent = {
			recommendation: this.recommendation,
			action: RecommendationClickedAction.Apply
		};
		this.recommendationActionEmitter.emit(action);
	}
}
