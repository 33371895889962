<div #mainContent class="container" id="ca-wrapper-id">
	<div class="row">
		<div class="col-12">
			<div class="tree-information">
				<div class="row">
					<span class="title">Your Ad Sets are publishing</span>
					<div class="processing-loader">
						<div class="dot"></div>
						<div class="dot"></div>
						<div class="dot"></div>
					</div>
				</div>
				<div class="description">
					Don’t want to wait? We can publish in the background so you can get on!
				</div>
				<div>
					<btn03 (onClick)="runBackground()">Run in the background</btn03>
				</div>
			</div>

			<div id="treeContainer"></div>
			<div class="fadingEffect"></div>
		</div>
	</div>
</div>
