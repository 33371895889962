export enum UserStateEnum {
	Inactive = 0,
	Active = 1,
	Removed = 2,
	InTransition = 3,
	NoBusinessOwner = 4,
	NoCreditCard = 5,
	BillPendingUnrestrictedAccess = 7,
	BillPendingRestrictedAccess = 8,
	FreemiumExpiredNoCreditCard = 11,
	FreeTrialExpiredNoCreditCard = 12
}
