import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { AdModel } from 'src/app/AAA/models/ad.model';
import { SingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
import { StepThreeDetails } from '../../models/step-three-details';

@Component({
	selector: 'create-ad-nav',
	templateUrl: './create-ad-nav.component.html',
	styleUrls: ['./create-ad-nav.component.scss']
})
export class CreateAdNavComponent implements OnInit, OnDestroy {
	@Input() public isSelected: boolean;
	@Input() public expansionTitle: string;
	@Input() public label: string;
	@Input() public placeholder: string;
	@Input() public showContentMargin = true;
	@Input() public ads: AdModel[] | StepThreeDetails[] = [];

	@Output() public removedAdIndex: EventEmitter<number>;
	@Output() public selectedAdIndex: EventEmitter<number>;
	@Output() public addNew: EventEmitter<void>;

	public selectedIndex = 0;
	public info: InfoHoverDataModel = { message: `Ad navigation allows you to move between your ads and edit in quantity.` };

	protected unsubscriber$ = new Subject<void>();
	constructor() {
		this.removedAdIndex = new EventEmitter<number>();
		this.selectedAdIndex = new EventEmitter<number>();
		this.addNew = new EventEmitter<void>();
	}

	ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public addNewAd(): void {
		this.addNew.emit();
	}

	public removeAd(index: number): void {
		this.selectAd(index - 1);
		this.removedAdIndex.emit(index);
	}

	public selectAd(index: number): void {
		this.selectedIndex = index;
		this.selectedAdIndex.emit(index);
	}

	public imageExists(ad: AdModel): boolean {
		return !!(ad?.adverts as SingleAdvertDto)?.media_url;
	}

	public getImgSrc(ad: AdModel): string {
		return (ad.adverts as SingleAdvertDto).media_url || ad.adverts.picture;
	}
}
