import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GenericPopUpComponent } from 'src/app/back-office/popUps/generic-pop-up/generic-pop-up.component';
import { ActionTypeEnum } from 'src/app/back-office/users/action-type.enum';
import { User } from 'src/app/optimise/models/new-recommendation.interface';
import { ButtonClassEnum } from 'src/app/shared/button-component/button-class.enum';
import { ButtonTypeEnum } from 'src/app/shared/button-component/button-type.enum';
import { ShowGlobalSpinner, HideGlobalSpinner } from 'src/app/shared/state/shared.actions';
import { getApiEnums } from 'src/app/shared/state/shared.reducer';
import { FiledApiModels, FiledSubscriptionApiModel } from 'src/app/shared/state/state-entities/errors/errors.model';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { Phone } from 'src/app/user-management/models/phone';
import { SubscriptionCancelEnum } from 'src/app/user-management/models/subscription-cancel.enum';
import { UserManagementPopupInterface } from 'src/app/user-management/models/user-management-popup.interface';
import { UserManagementState } from 'src/app/user-management/state/user-management.reducer';
import { ContactService } from 'src/app/_services/contact/contact.service';

@Component({
	selector: 'app-user-sub-header',
	templateUrl: './user-sub-header.component.html',
	styleUrls: ['./user-sub-header.component.scss']
})
export class UserSubHeaderComponent implements OnInit, OnDestroy {
	@Input() public pageTitle: string;
	@Input() public permissions: boolean;
	@Input() public userProfile: User;
	@Input() public displayMenu = false;

	@Output() public cancelledSubscriptionAction: EventEmitter<boolean> = new EventEmitter();

	public showMenu: boolean;
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;

	private reason: FormGroup;
	private reasonOption: FormControl;
	private textareaComment: FormControl;

	private apiCodes: FiledApiModels;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(
		private translate: TranslateService,
		private toastNotificationService: ToastNotificationService,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private contactService: ContactService,
		private store: Store<UserManagementState>
	) {}

	ngOnInit() {
		this.fetchStore();
		this.showMenu = false;
		this.createForm();
	}

	ngOnDestroy() {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public toggleMenu(): void {
		this.showMenu = !this.showMenu;
	}

	public onOutsideClick(): void {
		this.showMenu = false;
	}

	public openCancelSubscriptionDialog(): void {
		this.dialog
			.open<GenericPopUpComponent, UserManagementPopupInterface>(GenericPopUpComponent, {
				data: {
					image: SubscriptionCancelEnum.Image,
					header: SubscriptionCancelEnum.Header,
					message: SubscriptionCancelEnum.Message,
					actionAnswer: ActionTypeEnum.Continue,
					noActionAnswer: ActionTypeEnum.Cancel
				},
				panelClass: ['generic-popup']
			})
			.afterClosed()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(response => {
				if (response) {
					this.sendEmail(response.phone);
				}
			});
	}

	private sendEmail(phone: string): void {
		const phoneData = { PhoneNumber: phone } as Phone;

		this.store.dispatch(new ShowGlobalSpinner());
		this.contactService
			.cancelSubscription(phoneData)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				() => {
					this.toastNotificationService.sendSuccessToast('Your subscription has been cancelled.');
					this.cancelledSubscriptionAction.emit(true);
					this.store.dispatch(new HideGlobalSpinner());
				},
				err => {
					if (err && err.error) {
						err.error.forEach((error: FiledSubscriptionApiModel) => {
							const code = error.code;
							const subscriptionApiModel = this.apiCodes.filedSubscriptionApi.filedSubscriptionApiModel;

							const message = subscriptionApiModel.find(filedEnumType => {
								return code === filedEnumType.code;
							}).message;
							this.toastNotificationService.sendErrorToast(message);
						});
					} else {
						this.showError();
					}
					this.store.dispatch(new HideGlobalSpinner());
				}
			);
	}

	private showError(): void {
		this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
	}

	private fetchStore(): void {
		this.store.pipe(select(getApiEnums), takeUntil(this.unsubscriber$)).subscribe(apiCodes => {
			this.apiCodes = apiCodes;
		});
	}

	private createForm(): void {
		this.reasonOption = new FormControl('');
		this.textareaComment = new FormControl('');

		this.reason = this.formBuilder.group({
			option: this.reasonOption,
			textarea: this.textareaComment
		});
	}
}
