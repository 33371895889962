import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { InstantFormSettings } from './instant-form-settings.interface';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ButtonClassEnum } from 'src/app/shared/button-component/button-class.enum';
import { ButtonTypeEnum } from 'src/app/shared/button-component/button-type.enum';
import { IDropdwonWithIconData } from 'src/app-components/interfaces/button.interface';
import { CustomeQuestionType } from './instant-form-content.interface';

const callToAction: Array<{ name: string; value: string; description: string }> = [
	{ name: 'View Website', value: 'View Website', description: '' },
	{ name: 'Download', value: 'Download', description: '' },
	{ name: 'Call Business', value: 'Call Business', description: '' }
];
export interface ActiveDialogButton {
	name: string;
	isActive: boolean;
}

@Component({
	selector: 'app-instant-form-dialog',
	templateUrl: './instant-form-dialog.component.html',
	styleUrls: ['./instant-form-dialog.component.scss']
})
export class InstantFormDialogComponent implements OnInit, OnDestroy {
	private unsubscriber$ = new Subject<void>();
	public settings: InstantFormSettings;
	public dialogForm: FormGroup;
	public file: File;
	public imageUrl: string;
	public searchControl = new FormControl();
	public buttonType = ButtonTypeEnum;
	public ListFieldObjects: FormArray = new FormArray([]);
	public introGreetingDescriptionListField: any;
	public callToActionButtons: Array<{ name: string; value: string; description: string }>;
	public showDisclaimer: boolean;
	public selectImageFromComputer: boolean;
	public buttonClass = ButtonClassEnum;
	public emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
	public showPrivacyLinkInfo: boolean;
	public consents: FormArray;
	public iframeSrc: string;

	public isShowGeneralContentTab: boolean = false;

	public privacyCustomerDisclaimerConsents: FormArray = new FormArray([]);
	public settingLanguageParameter: FormArray = new FormArray([]);
	public customQuestionControls: FormArray = new FormArray([]);
	public shortAnswerControls: FormArray = new FormArray([]);
	public conditionalQstnControls: FormArray = new FormArray([]);
	public appointmentRequestControls: FormArray = new FormArray([]);

	public customQuestions = [
		{ icon: '', text: 'Multiple Choice' },
		{ icon: '', text: 'Short Answer' },
		{ icon: '', text: 'Conditional' },
		{ icon: '', text: 'Appointment Request' }
	];
	public addCategory = [{ icon: '', text: 'Contact Fields', children: [{ icon: '', text: '' }] }];

	public dropdownWithIcon: IDropdwonWithIconData[] = [
		{
			text: 'Multiple Choice',
			icon: 'radiobutton-checked',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('Multiple Choice');
			}
		},
		{
			text: 'Short Answer',
			icon: 'convert-uppercase',
			click: () => {
				console.log('Short Answer click handler', CustomeQuestionType.SHORT_ANSWER);
				this.addShortAnswer('Short Answer');
			}
		},
		{
			text: 'Conditional',
			icon: 'connector',
			click: () => {
				console.log('Conditional Question click handler');
				this.addConditionalQstn('Conditional');
			}
		},
		{
			text: 'Appointment Request',
			icon: 'clock',
			click: () => {
				console.log('Appointment Request click handler');
				this.addAppointmentRequest('Appointment Request');
			}
		}
	];

	public prefillDropdownWithIcon: IDropdwonWithIconData[] = [
		{
			text: 'Contact Fields',
			icon: 'paste-plain-text',
			click: () => {
				console.log('Keep Text Only click handler');
				this.addCustomQuestion('Multiple Choice');
			}
		},
		{
			text: 'User Information',
			icon: 'paste-plain-text',
			click: () => {
				console.log('Short Answer click handler', CustomeQuestionType.SHORT_ANSWER);
				this.addShortAnswer('Short Answer');
			}
		},
		{
			text: 'Demographic Questions',
			icon: 'paste-plain-text',
			click: () => {
				console.log('Conditional Question click handler');
				this.addConditionalQstn('Conditional');
			}
		},
		{
			text: 'Work Information',
			icon: 'paste-plain-text',
			click: () => {
				console.log('Appointment Request click handler');
				this.addAppointmentRequest('Appointment Request');
			}
		},
		{
			text: 'National ID Number',
			icon: 'paste-plain-text',
			click: () => {
				console.log('Appointment Request click handler');
				this.addAppointmentRequest('Appointment Request');
			}
		}
	];

	constructor(private dialogRef: MatDialogRef<InstantFormDialogComponent>, private formBuilder: FormBuilder) {}

	public ngOnInit(): void {
		this.updatePosition();
		this.innitDialogForm();
		this.addLanguageParameter();
		this.updateintroGreetingDescriptionListState('Paragraph');
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public innitDialogForm(): void {
		const today = new Date();
		// this.ListFieldObjects = new FormArray([]) ;
		const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

		const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

		this.dialogForm = this.formBuilder.group({
			formName: [`Untitled form ${date} ${time}`, [Validators.required]],
			formType: [null, [Validators.required]],
			introBackgroundImageSource: [null, [Validators.required]],
			introBackgroundImageUrl: [null, [Validators.required]],
			introGreetingHeadline: [null, [Validators.required]],
			introGreetingDescriptionType: [null, [Validators.required]],
			introGreetingDescriptionTypeParagraph: [null],
			email: [null, [Validators.required, Validators.pattern(this.emailRegx)]],
			introGreetingDescriptionTypeList: new FormArray([]),
			questionCustomeQuestion: [null],
			privacyLabelLink: [null],
			privacyLabelLinkText: [null],
			privacyCustomerDisclaimerTitle: [null],
			privacyCustomerDisclaimerTitleText: [null, [Validators.required]],
			privacyCustomerDisclaimerConsents: this.formBuilder.array([this.createConsent()]),
			customeQuestionObjects: this.formBuilder.array([this.createcustomQuestionControls()]),
			shortAnswerObjects: this.formBuilder.array([this.createShortAnswerControls()]),
			conditionalQstnObjects: this.formBuilder.array([this.createConditionalQstnControls()]),
			appointmentRequestObjects: this.formBuilder.array([this.createAppointmentRequestControls()]),
			completionCallToActionText: [null],
			completionLabelLink: [null],
			settingsFormConfigurationLanguageSharing: [null],
			settingsFieldnameEmail: [null],
			settingsFieldnameFullname: [null],
			settingsTrackingParameters: [null],
			settingsLanguageParameters: this.formBuilder.array([this.createParameters()])
		});
		this.callToActionButtons = callToAction;
		this.showDisclaimer = false;
	}

	public oniframeSrc($event: string): void {
		this.iframeSrc = $event;
	}

	private createConsent(): FormGroup {
		return this.formBuilder.group({
			text: ''
		});
	}

	private createParameters(): FormGroup {
		return this.formBuilder.group({
			parameterName: '',
			parameterValue: ''
		});
	}

	private createcustomQuestionControls(): FormGroup {
		return this.formBuilder.group({
			questionTitle: ''
		});
	}
	private createShortAnswerControls(): FormGroup {
		return this.formBuilder.group({
			questionTitle: ''
		});
	}
	private createConditionalQstnControls(): FormGroup {
		return this.formBuilder.group({
			questionTitle: ''
		});
	}
	private createAppointmentRequestControls(): FormGroup {
		return this.formBuilder.group({
			questionTitle: ''
		});
	}

	public addLanguageParameter(): void {
		this.settingLanguageParameter.controls.push(new FormControl(''));
	}
	public removeLanguageParameter(index: number): void {
		if (index > 0) {
			this.settingLanguageParameter.controls.splice(index);
		}
	}

	public addCustomQuestion(title: string): void {
		this.customQuestionControls.controls.push(
			new FormControl({
				questionTitle: title
			})
		);
	}
	public removeCustomQuestion(index: number): void {
		this.customQuestionControls.controls.splice(index);
	}

	public addShortAnswer(title: string): void {
		this.shortAnswerControls.controls.forEach(control => {
			control.patchValue({ expanded: false });
		});
		this.shortAnswerControls.controls.push(
			new FormControl({
				questionTitle: title
			})
		);
		console.log(this.shortAnswerControls);
	}
	public removeShortAnswer(index: number): void {
		this.shortAnswerControls.controls.splice(index);
	}

	public addConditionalQstn(title: string): void {
		if (this.conditionalQstnControls.controls.length < 1) {
			this.conditionalQstnControls.controls.push(
				new FormControl({
					questionTitle: title
				})
			);
		}
	}
	public removeConditionQstn(index: number): void {
		this.conditionalQstnControls.controls.splice(index);
	}

	public addAppointmentRequest(title: string): void {
		if (this.appointmentRequestControls.controls.length < 1) {
			this.appointmentRequestControls.controls.push(
				new FormControl({
					questionTitle: title
				})
			);
		}
		this.conditionalQstnControls.controls.push(
			new FormControl({
				questionTitle: title
			})
		);
	}
	public removeAppointmentRequest(index: number): void {
		this.appointmentRequestControls.controls.splice(index);
	}

	public addConsent(): void {
		this.privacyCustomerDisclaimerConsents.push(new FormControl(''));
	}
	public removeConsent(index: string): void {
		const indices: any[] = index.split('_');
		this.privacyCustomerDisclaimerConsents.controls.splice(indices[1]);
	}

	public addDisclaimer(): void {
		this.showDisclaimer = true;
	}
	public removeDisclaimer(): void {
		this.dialogForm.get('privacyCustomerDisclaimerTitle').setValue = null;
		this.dialogForm.get('privacyCustomerDisclaimerTitleText').setValue = null;
		this.dialogForm.get('privacyCustomerDisclaimerConsents').setValue = null;
		this.showDisclaimer = false;
	}

	public toggleShowGeneralContentTab(value: boolean): void {
		this.isShowGeneralContentTab = value;
	}
	public updateLanguageSharing(value: string): void {
		this.dialogForm.patchValue({ settingsFormConfigurationLanguageSharing: value });
	}
	public addPrivacyLinkInfoEvent(): void {
		this.showPrivacyLinkInfo = true;
	}

	public removePrivacyLinkInfoEvent(): void {
		this.showPrivacyLinkInfo = false;
	}
	public pageBackgroundImageSelector(value: number): void {
		this.selectImageFromComputer = value !== 0;
	}
	public addListFieldObjects(): void {
		this.ListFieldObjects.push(new FormControl(''));
	}
	private setIntroGreetingDescriptionTypeList(): void {
		const typeList: FormArray = new FormArray([]);
		for (let i = 0; i < 5; i++) {
			this.addListFieldObjects();
		}
		// typeList.push(new FormControl(''));
		this.dialogForm.patchValue({ introGreetingDescriptionTypeList: typeList });
	}
	public updatePosition(): void {
		this.dialogRef.updatePosition({ top: '5vh' });
	}
	public trackByFn(index: any): any {
		return index;
	}

	public close(): void {
		this.dialogRef.close();
	}

	public onSubmit(): void {
		if (!this.dialogForm.valid) {
			return;
		}

		console.log(this.dialogForm.value);
		this.close();
	}

	public getUploadedFilename(filename: string): void {
		this.dialogForm.patchValue({ introBackgroundImageUrl: filename });
	}

	public formTypeValueChanges(value: string): void {}

	public updateintroGreetingDescriptionListState(value: string): void {
		this.dialogForm.patchValue({ introGreetingDescriptionType: value });
		if (value === 'List') {
			for (let i = 0; i < 5; i++) {
				this.addListFieldObjects();
			}
		}
	}
}
