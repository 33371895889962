import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState } from '../../../../../campaign-smart-create/state/smart-create.reducer';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { InstantFormDialogComponent } from './dialog-box/instant-form-dialog.component';

@Component({
	selector: 'app-instant-form',
	templateUrl: './instant-form.component.html',
	styleUrls: ['./instant-form.component.scss']
})
export class InstantFormComponent implements OnInit {
	private unsubscriber$ = new Subject<void>();

	// INJECTED SERVICES
	protected dialog: MatDialog;
	protected formBuilder: FormBuilder;
	protected store: Store<CampaignSmartCreateState>;

	constructor(private injector: Injector) {
		this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
		this.dialog = this.injector.get<MatDialog>(MatDialog);
	}

	public ngOnInit(): void {}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
	// InstantFormDialogComponent
	public openDialog(): void {
		const dialogRef = this.dialog.open(InstantFormDialogComponent, {
			height: '550px',
			width: '1024px'
		});
		dialogRef.afterClosed().subscribe(result => {});
	}
}
