import { Component, OnInit } from '@angular/core';
import { ButtonClassEnum } from '../../../shared/button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../shared/button-component/button-type.enum';
import { AuthenticationState } from '../../state/authentication.reducer';
import { Store } from '@ngrx/store';
import { DisableFirstTimeCustomer } from '../../state/authentication.action';
import { Router } from '@angular/router';

@Component({
	selector: 'app-landing-page',
	templateUrl: './facebook-landing-page.component.html',
	styleUrls: ['./facebook-landing-page.component.scss']
})
export class FacebookLandingPageComponent implements OnInit {
	public mainBtn = ButtonClassEnum;
	public typeBtn = ButtonTypeEnum;

	constructor(private router: Router, private authStore: Store<AuthenticationState>) {}

	ngOnInit() {
		this.authStore.dispatch(new DisableFirstTimeCustomer());
	}

	public onOptimizeClick(): void {
		this.router.navigate(['optimize']);
	}

	public onCampaignBuilderCreateClick(): void {
		this.router.navigate(['campaign-builder']);
	}

	public onReportsCreateClick(): void {
		this.router.navigate(['reports/smart-create']);
	}
}
