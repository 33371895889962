<div class="body" *ngIf="!loading">
	<div class="title">My Billing Information</div>
	<div class="body-form">
		<div class="forms">
			<div>
				<label class="label">
					First Name
					<span class="required">*</span>
				</label>
				<user-tb-2
					[control]="userForm.controls['firstName']"
					placeHolderTextBox="Enter first name"
					class="input-box"
					(change)="onFormChange($event)"
				></user-tb-2>
			</div>

			<div>
				<label class="label">
					Last Name
					<span class="required">*</span>
				</label>
				<user-tb-2
					[control]="userForm.controls['lastName']"
					placeHolderTextBox="Enter last name"
					class="input-box"
					(change)="onFormChange($event)"
				></user-tb-2>
			</div>
		</div>
		<div class="forms">
			<div>
				<label class="label">
					Billing Address
					<span class="required">*</span>
				</label>
				<user-tb-2
					[control]="userForm.controls['firstAddress']"
					placeHolderTextBox="Billing address"
					class="input-box"
					(change)="onFormChange($event)"
				></user-tb-2>
			</div>

			<div>
				<label class="label">
					City
					<span class="required">*</span>
				</label>
				<user-tb-2 [control]="userForm.controls['city']" placeHolderTextBox="Enter city" class="input-box" (change)="onFormChange($event)"></user-tb-2>
			</div>
		</div>
		<div class="forms">
			<div>
				<label class="label">
					Zipcode/Postcode
					<span class="required">*</span>
				</label>
				<user-tb-2
					[control]="userForm.controls['zipcode']"
					placeHolderTextBox="Enter zipcode"
					class="input-box"
					(change)="onFormChange($event)"
				></user-tb-2>
			</div>

			<div>
				<label class="label">
					Country
					<span class="required">*</span>
				</label>
				<app-select-item
					[dropDownControl]="userForm.controls['country']"
					[placeholderText]="'Select country'"
					[showCaretIcon]="true"
					[dropDownData]="dropdownCountries"
					[allowFilter]="true"
					(onItemSelected)="dropDownValueChanges($event)"
				></app-select-item>
			</div>
		</div>
		<div class="buttons">
			<button (click)="editForm()" class="btn-gcb-04 action-button" *ngIf="!isEditMode">EDIT</button>
			<button (click)="onSaveBillings()" [disabled]="userForm.invalid" class="btn-gcb-01 action-button" *ngIf="isEditMode">SAVE CHANGES</button>
		</div>
	</div>
</div>
<!--lolly-spinning-loader class="spinner" *ngIf="loading"></!--lolly-spinning-loader-->
