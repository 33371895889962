import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonRowButton } from './button-row-button.model';

@Component({
	selector: 'app-button-row',
	templateUrl: './button-row.component.html',
	styleUrls: ['./button-row.component.scss']
})
export class ButtonRowComponent {
	@Input() buttons: ButtonRowButton[];
	@Input() showCount: boolean;
	@Output() buttonClicked: EventEmitter<ButtonRowButton> = new EventEmitter<ButtonRowButton>();

	public onButtonClick(button: ButtonRowButton) {
		if (button.active) {
			const foundIndex = this.buttons.findIndex((b: ButtonRowButton) => b.label === button.label);
			this.buttons[foundIndex].selected = !this.buttons[foundIndex].selected;
			this.buttonClicked.emit(button);
		}
	}
}
