<app-expansion-panel-sc
	[expansionTitle]="'Split by Age Range'"
	[isExpandable]="false"
	[isSelected]="isSelected"
	[contentMargin]="true"
	(click)="onExpansionClick.emit()"
>
	<div expansion-content class="expansion-input">
		<mat-slide-toggle class="age-range-toggle" [formControl]="control" (change)="onSplitAgeRangeToggle()" [checked]="isSplitAgeRangeToggled">
			<span>Do you want to split your Ad Sets by age range?</span>
		</mat-slide-toggle>
		<div *ngIf="isSplitAgeRangeToggled">
			<ng5-slider
				class="slider-padding"
				[(value)]="yearsPerAdSet"
				[formControl]="sliderControl"
				[options]="options"
				(valueChange)="onSliderChange()"
			></ng5-slider>
		</div>
	</div>
</app-expansion-panel-sc>
