<div class="create-ad-container">
	<div class="layout">
		<h4 class="header">Create New Ad</h4>
		<div class="quick-navigation">
			<app-quick-navigation [navigations]="navigations"></app-quick-navigation>
		</div>
		<div class="ad-components" QuickNavigationScroll>
			<app-ad-navigation
				(click)="handlePanelClick(aaaPanels.AdNavigation, 0)"
				[isSelected]="aaaExpansionPanelHelper.isPanelSelected(aaaPanels.AdNavigation)"
				[expansionTitle]="'Ad Navigation'"
				[showContentMargin]="false"
				id="adNavigation"
				qn-scroll-item
			></app-ad-navigation>

			<app-name-expansion-panel
				[control]="adCreateFormGroup.get('name')"
				(click)="handlePanelClick(aaaPanels.AdName, 1)"
				[isSelected]="aaaExpansionPanelHelper.isPanelSelected(aaaPanels.AdName)"
				[expansionTitle]="'Ad Name'"
				[label]="label"
				[showContentMargin]="false"
				id="adName"
				qn-scroll-item
			></app-name-expansion-panel>

			<div class="scs-expansion-panel pages" *ngIf="showPageComponent">
				<app-pages-card
					(onExpansionClick)="handlePanelClick(aaaPanels.Pages, 2)"
					[isPanelSelected]="aaaExpansionPanelHelper.isPanelSelected(aaaPanels.Pages)"
					[pagesFormGroup]="adCreationFormGroup.get('pages')"
					[facebookPages]="facebookPages"
					[instagramPages]="[]"
					[selectedObjective]="objective"
					id="selectPage"
					qn-scroll-item
				></app-pages-card>
			</div>

			<app-ad-format
				id="adFormat"
				[objectiveTree]="objectiveTree"
				[selectedFormat]="selectedFormat"
				(onUpdateFormatType)="updateFormatType($event)"
				(onExpansionClick)="handlePanelClick(aaaPanels.AdFormat, 3)"
				[isPanelSelected]="aaaExpansionPanelHelper.isPanelSelected(aaaPanels.AdFormat)"
				qn-scroll-item
			></app-ad-format>

			<app-ad-creation
				id="adCreate"
				(onExpansionClick)="handlePanelClick(aaaPanels.AdCreate, 4)"
				[isPanelSelected]="aaaExpansionPanelHelper.isPanelSelected(aaaPanels.AdCreate)"
				(onUpdateInvalidStep)="updateInvalidStep($event)"
				(onLoadImages)="loadImages($event)"
				(onLoadVideos)="loadVideos($event)"
				(onUpdateAdverts)="updateAdverts($event)"
				[adCreateFormGroup]="adCreationFormGroup"
				[callForActions]="callToActions"
				[validatorTrigger]="validatorTrigger"
				[subObjective]="subObjective"
				[validations]="validations"
				[placements]="placements"
				[posts]="posts"
				[images]="images"
				[videos]="videos"
				[previews]="previews"
				[adFormatListener$]="adFormatListener$"
				[facebookPageId]="facebookPageId"
				[businessOwnerFbId]="businessOwnerFbId"
				(adCreationFormValidity)="checkAdCreationFormValidity($event)"
				[selectedAdAccount]="selectedAdAccount"
				qn-scroll-item
			></app-ad-creation>

			<app-pixel-card
				#adTracking
				id="adTracking"
				(onExpansionClick)="handlePanelClick(aaaPanels.Pixel, 5)"
				[isPanelSelected]="aaaExpansionPanelHelper.isPanelSelected(aaaPanels.Pixel)"
				[selectedAdAccount]="selectedAdAccount"
				[subObjective]="objectiveTree.subObjective"
				(sendEvents)="getEvents($event)"
				[isPixelMandatory]="pixelMandatory"
				[pixelId]="pixelId"
				[pixelEventId]="pixelEventId"
				[conversion]="conversion"
				[firstTime]="firstTime"
				[pixelControl]="pixelFormGroup.get('pixel')"
				[pixelEventControl]="pixelFormGroup.get('pixelEvent')"
				[conversionControl]="pixelFormGroup.get('conversion')"
				(updateConversion)="updateConversion($event)"
				qn-scroll-item
			></app-pixel-card>
		</div>
		<div class="ad-previews">
			<app-ad-preview [adCreateFormGroup]="adCreationFormGroup" [adFormatListener$]="adFormatListener$"></app-ad-preview>
		</div>
	</div>
</div>
