import { Action } from '@ngrx/store';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { AdPreviewState } from './ad-preview.reducer';

export enum AdPreviewActionTypes {
	UpdatePreviewPlacement = '[Ad Preview] Update Preview Placement',
	ResetState = '[Ad Preview] Reset State',
	UpdatePreviewIFrameSrc = '[Ad Preview] Update Preview IFrame Source',
	UpdatePreviewWidth = '[Ad Preview] Update Preview Width',
	UpdatePreviewHeight = '[Ad Preview] Update Preview Height',
	SetFetchingIsIframe = '[Ad preview] Set Is Fetching Iframe'
}

export class ResetState implements Action {
	public readonly type = AdPreviewActionTypes.ResetState;
	constructor(public payload: AdPreviewState) {}
}

export class UpdatePreviewPlacement implements Action {
	public readonly type = AdPreviewActionTypes.UpdatePreviewPlacement;
	constructor(public payload: DropdownData<CatalogNodeInterface>[]) {}
}

export class UpdatePreviewWidth implements Action {
	public readonly type = AdPreviewActionTypes.UpdatePreviewWidth;
	constructor(public payload: number) {}
}

export class UpdatePreviewHeight implements Action {
	public readonly type = AdPreviewActionTypes.UpdatePreviewHeight;
	constructor(public payload: number) {}
}

export class UpdatePreviewIFrameSrc implements Action {
	public readonly type = AdPreviewActionTypes.UpdatePreviewIFrameSrc;
	constructor(public payload: string) {}
}

export class SetFetchingIsIframe implements Action {
	public readonly type = AdPreviewActionTypes.SetFetchingIsIframe;
	constructor(public payload: boolean) {}
}

export type AdPreviewActions = UpdatePreviewPlacement | UpdatePreviewIFrameSrc | UpdatePreviewWidth | UpdatePreviewHeight | SetFetchingIsIframe;
