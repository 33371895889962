import { Injectable } from '@angular/core';
import { ChargeBeeFormErrorsDTO, StripeCreditCard, StripeFormErrorsDTO } from './set-creditcard.models';
import { AbstractControl } from '@angular/forms';

@Injectable({
	providedIn: 'root'
})
export class SetCreditCardService {
	public async validateStripeCard(finalFormControls: { [key: string]: AbstractControl }, fistPayment: boolean): Promise<{ status: number; response: any }> {
		await this.loadStripe();
		return new Promise(resolve => {
			const creditCard = new StripeCreditCard();

			creditCard.name = finalFormControls.cardHolder && finalFormControls.cardHolder.value ? finalFormControls.cardHolder.value.toString() : '';

			creditCard.number = finalFormControls.number && finalFormControls.number.value ? finalFormControls.number.value.toString().replace(/\s/g, '') : '';

			if (finalFormControls.expirationDate && finalFormControls.expirationDate.value) {
				const dates = finalFormControls.expirationDate.value.split('/');
				creditCard.exp_month = dates[0] && dates[0].length ? String(Number(dates[0])) : null;
				creditCard.exp_year = dates[1];
			}

			creditCard.cvc = finalFormControls.cvc && finalFormControls.cvc.value ? finalFormControls.cvc.value.toString() : '';

			if (fistPayment) {
				creditCard.address_line1 =
					finalFormControls.company && finalFormControls.company.value
						? `${finalFormControls.address1.value} / ${finalFormControls.company.value}`
						: `${finalFormControls.address1.value}`;

				creditCard.address_line2 = finalFormControls.address2 && finalFormControls.address2.value ? finalFormControls.address2.value.toString() : '';

				creditCard.address_city = finalFormControls.city && finalFormControls.city.value ? finalFormControls.city.value.toString() : '';
				creditCard.address_zip = finalFormControls.zip && finalFormControls.zip.value ? finalFormControls.zip.value.toString() : '';
				creditCard.address_state = finalFormControls.county && finalFormControls.county.value ? finalFormControls.county.value.toString() : '';
				creditCard.address_country = finalFormControls.country && finalFormControls.country.value ? finalFormControls.country.value.toString() : '';
			} else {
				creditCard.address_line1 =
					finalFormControls.company && finalFormControls.company.value.value
						? `${finalFormControls.address1.value.value} / ${finalFormControls.company.value.value}`
						: `${finalFormControls.address1.value.value}`;

				creditCard.address_line2 =
					finalFormControls.address2 && finalFormControls.address2.value.value ? finalFormControls.address2.value.value.toString() : '';

				creditCard.address_city = finalFormControls.city && finalFormControls.city.value.value ? finalFormControls.city.value.value.toString() : '';
				creditCard.address_zip = finalFormControls.zip && finalFormControls.zip.value.value ? finalFormControls.zip.value.value.toString() : '';
				creditCard.address_state =
					finalFormControls.county && finalFormControls.county.value.value ? finalFormControls.county.value.value.toString() : '';
				creditCard.address_country =
					finalFormControls.country && finalFormControls.country.value.value ? finalFormControls.country.value.value.toString() : '';
			}

			return (window as any).Stripe.card.createToken(creditCard, (status: number, response: any) => {
				return resolve({
					status,
					response
				});
			});
		});
	}

	public getFormControlWithErrorFromStripe(stripeFormErrorsDTO: StripeFormErrorsDTO): string {
		// todo: those should be presented somewhere...in their API and switch to dynamic mapping
		let formControlWithError: string;

		switch (stripeFormErrorsDTO.code) {
			case 'incorrect_address':
				formControlWithError = 'address1';
				break;
			case 'incorrect_cvc':
				formControlWithError = 'cvc';
				break;
			case 'incorrect_number':
				formControlWithError = 'number';
				break;
			case 'invalid_number':
				formControlWithError = 'number';
				break;
			case 'account_number_invalid':
				formControlWithError = 'number';
				break;
			case 'invalid_card_type':
				formControlWithError = 'number';
				break;
			case 'invalid_cvc':
				formControlWithError = 'cvc';
				break;
			case 'invalid_expiry_month':
				formControlWithError = 'expirationDate';
				break;
			case 'invalid_expiry_year':
				formControlWithError = 'expirationDate';
				break;
			case 'card_declined':
				formControlWithError = 'number';
				break;
		}

		return formControlWithError;
	}

	public getFormControlWithErrorFromChargebeeAddNewCard(chargeBeeFormErrorsDTO: ChargeBeeFormErrorsDTO): string {
		let formControlWithError: string;
		// 'credit_card.billing_zip : invalid zip/postal code'

		switch (chargeBeeFormErrorsDTO.code) {
			case 'credit_card.billing_zip':
			case 'card[billing_zip]':
				formControlWithError = 'zip';
		}

		return formControlWithError;
	}

	public async loadStripe() {
		return new Promise(resolve => {
			if (!window.document.getElementById('stripe-custom-form-script')) {
				const s = window.document.createElement('script');
				s.id = 'stripe-custom-form-script';
				s.type = 'text/javascript';
				s.src = 'https://js.stripe.com/v2/';
				s.onload = () => {
					(window as any).Stripe.setPublishableKey('pk_test_uHs1I1NLROiEyuYkaZfmDeDb');
					resolve();
				};

				window.document.body.appendChild(s);
			} else {
				return resolve();
			}
		});
	}
}
