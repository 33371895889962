<div class="search-main-wrapper">
  <ng-container *ngIf="multiselect">
    <mat-form-field (click)="showList()">
      <input #searchTerms
			 (keydown)="noNumbers($event)"
             (click)="showList($event)" (input)="filterTerms(searchTerms.value)" *ngIf="!static"
             [placeholder]="placeholder"
             autocomplete="off"
             matInput>

      <input #searchTerms
             (click)="showList($event)" (input)="filterStaticTerms(searchTerms.value)" *ngIf="static"
             [placeholder]="placeholder"
             autocomplete="off"
             matInput>

      <div [hidden]="!getSpinner" class="spinner">
        <mat-spinner diameter="15"></mat-spinner>
      </div>
    </mat-form-field>

    <div *ngIf="showTerms" #container class="search-content mat-tab-campaign">
      <mat-selection-list (selectionChange)="setSelectedItemList($event)" *ngIf="terms && terms.length">
        <ng-container *ngIf="static">
          <mat-list-option *ngFor="let term of terms | searchText:filterSearch:filterBy" [selected]="checkSelected(term, selectedTerm)"
                           [value]="term[composedKey]">
            <div class="first-col" title="{{filterBy | formatText}}: {{term[filterBy]}}">
              <span class="one_line_text width-250"><div [innerHtml]="processDisplayTerm(term[filterBy])"></div></span>
            </div>
            <div *ngIf="secondFilter && showSecondFilter" class="one_line_text second-col"
                 title="{{secondFilter | formatText}}: {{term[secondFilter]}}">
              <span class="one_line_text width-200">{{term[secondFilter]}}</span>
            </div>
            <div *ngIf="thirdFilter && showThirdFilter" class="one_line_text second-col"
                 title="{{thirdFilter | formatText}}: {{term[thirdFilter]}}">
              <span class="one_line_text width-200">{{term[thirdFilter]}}</span>
            </div>
            <div *ngIf="fourthFilter" class="one_line_text second-col"
                 title="{{fourthFilter | formatText}}: {{term[fourthFilter]}}">
              <span class="one_line_text width-200">{{term[fourthFilter]}}</span>
            </div>
          </mat-list-option>
        </ng-container>
        <ng-container *ngIf="!static">
          <mat-list-option *ngFor="let term of terms | searchText:filterSearch:filterBy" [selected]="checkSelected(term, selectedTerm)"
                           [value]="term[composedKey]">
            <div class="first-col" title="{{filterBy | formatText}}: {{term[filterBy]}}">
              <span class="one_line_text width-250"><div [innerHtml]="processDisplayTerm(term[filterBy])"></div></span>
            </div>
            <div *ngIf="secondFilter && showSecondFilter"
                 class="one_line_text second-col"
                 title="{{secondFilter | formatText}}: {{term[secondFilter] | formatText}}">
              <span class="one_line_text width-200">{{term[secondFilter] | formatText}}</span>
            </div>
            <div *ngIf="thirdFilter && showThirdFilter"
                 class="one_line_text second-col"
                 title="{{thirdFilter | formatText}}: {{term[thirdFilter] | formatText}}">
              <span class="one_line_text width-200">{{term[thirdFilter] | formatText}}</span>
            </div>
            <div *ngIf="fourthFilter" class="one_line_text second-col"
                 title="{{fourthFilter | formatText}}: {{term[fourthFilter] | formatText}}">
              <span class="one_line_text width-200">{{term[fourthFilter] | formatText}}</span>
            </div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </div>

  </ng-container>

  <ng-container *ngIf="!multiselect">
    <ng-container *ngIf="static">
      <mat-form-field>
        <input #searchTerms
               (input)="filterStaticTerms(searchTerms.value)"
               [disabled]="getSpinner || !terms || !terms.length"
               [matAutocomplete]="autoSearch"
               [placeholder]="(!terms || !terms.length) ? 'No entries available' : placeholder"
               autocomplete="off"
               matInput value="">

        <div [hidden]="!getSpinner" class="spinner">
          <mat-spinner diameter="15"></mat-spinner>
        </div>

        <mat-autocomplete #autoSearch="matAutocomplete"
                          (optionSelected)="getSelectedOption($event.option.value)"
                          [displayWith]="displayFn">
          <mat-option *ngFor="let term of terms | searchText:filterSearch:filterBy" [id]="term.id" [value]="term"
                      matTooltip="{{term[filterBy]}}" matTooltipClass="wrapper-custom-tooltip">
            {{term[filterBy]}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!static">
      <mat-form-field>
        <input #searchTerms (input)="filterTerms(searchTerms.value)" [matAutocomplete]="autoSearch" [placeholder]="placeholder"
               autocomplete="off"
               matInput value="">

        <div [hidden]="!getSpinner" class="spinner">
          <mat-spinner diameter="15"></mat-spinner>
        </div>

        <mat-autocomplete #autoSearch="matAutocomplete"
                          (optionSelected)="getSelectedOption($event.option.value)"
                          [displayWith]="displayFn">
          <mat-option *ngFor="let term of terms" [value]="term" matTooltip="{{term[filterBy]}}"
                      matTooltipClass="custom-tooltip">
            {{term[filterBy]}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
  </ng-container>
</div>
