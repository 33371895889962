import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'echarts/theme/macarons.js';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
	'CompanyName=ABSTRACT HOLDINGS LIMITED,LicensedApplication=Filed.com,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-017120,ExpiryDate=20_August_2022_[v2]_MTY2MDk1MDAwMDAwMA==e4cc84f0a38826f6ad8925417539bf41'
);

namespace global {
	interface JQuery {
		dayScheduleSelector(): any;
	}
}

if (environment.Flags.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
