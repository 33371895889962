import { InsightsReportModel } from 'src/app/shared/campaign-insights/models/insights-report.model';
import { DataInterface, NullDataInterface } from '../data-interface.model';
import _ from 'lodash';

export interface SharedInsightsStructure {
	facebookCampaignsMetadata: DataInterface<InsightsReportModel[]>;
	facebookAdsetsMetadata: DataInterface<InsightsReportModel[]>;
	facebookAdsMetadata: DataInterface<InsightsReportModel[]>;

	googleCampaignsMetadata: DataInterface<InsightsReportModel[]>;
	googleAdgroupsMetadata: DataInterface<InsightsReportModel[]>;
	googleAdsMetadata: DataInterface<InsightsReportModel[]>;
	googleKeywordsMetadata: DataInterface<InsightsReportModel[]>;
}

export const initialState: SharedInsightsStructure = {
	facebookCampaignsMetadata: _.cloneDeep(NullDataInterface),
	facebookAdsetsMetadata: _.cloneDeep(NullDataInterface),
	facebookAdsMetadata: _.cloneDeep(NullDataInterface),
	googleCampaignsMetadata: _.cloneDeep(NullDataInterface),
	googleAdgroupsMetadata: _.cloneDeep(NullDataInterface),
	googleAdsMetadata: _.cloneDeep(NullDataInterface),
	googleKeywordsMetadata: _.cloneDeep(NullDataInterface)
};
