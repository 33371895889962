import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-ads-manager-image-cell',
	templateUrl: './ads-manager-image-cell.component.html',
	styleUrls: ['./ads-manager-image-cell.component.scss']
})
export class AdsManagerImageCellComponent implements ICellRendererAngularComp {
	public params: any;

	public agInit(params: any): void {
		this.params = params;
	}

	public refresh(): boolean {
		return false;
	}
}
