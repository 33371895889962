import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RoleNameEnum } from './role-name.enum';

@Injectable()
export class TablePermissionService {
	private subject = new Subject<RoleNameEnum>();
	constructor() {}

	public getMessage(): Observable<any> {
		return this.subject.asObservable();
	}

	public sendMessage(message: RoleNameEnum): void {
		this.subject.next(message);
	}
}
