<div class="edit-container">
	<div class="wrapper-edit-simple-text" mat-dialog-content>
		<input [placeholder]="placeholder" [formControl]="editFormControl" />
	</div>

	<div class="wrapper-edit-simple-buttons" mat-dialog-actions>
		<app-button
			buttonLabel="Cancel"
			(buttonClicked)="onNoClick()"
			[buttonClass]="buttonClassEnum.Secondary"
			[buttonType]="buttonTypeEnum.Simple"
		></app-button>
		<app-button
			buttonLabel="Save"
			(buttonClicked)="updateProperty()"
			[buttonClass]="buttonClassEnum.Primary"
			[buttonType]="buttonTypeEnum.Simple"
		></app-button>
	</div>
</div>
