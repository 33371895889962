import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CampaignStepsEnum } from '../../models/campaign-steps.enum';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { select, Store } from '@ngrx/store';
import { CampaignSmartCreateState, getInvalidStep } from '../../state/smart-create.reducer';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-campaign-smart-create-navigation',
	templateUrl: './campaign-smart-create-navigation.component.html',
	styleUrls: ['./campaign-smart-create-navigation.component.scss']
})
export class CampaignSmartCreateNavigationComponent implements OnInit, OnDestroy {
	@Input() public activeStep: CampaignStepsEnum;
	@Input() public campaignStep: CampaignStepsEnum;
	@Input() public isCurrentStepValid = true;
	@Output() public navigationClick = new EventEmitter<CampaignStepsEnum>();
	@ViewChild('stepper') public stepper: MatStepper;

	public invalidStep: CampaignStepsEnum;
	public campaignStepEnum = CampaignStepsEnum;

	private unsubscriber$ = new Subject<void>();
	constructor(public store: Store<CampaignSmartCreateState>) {}

	public ngOnInit(): void {
		this.checkInvalidStep();
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public checkInvalidStep(): void {
		this.store.pipe(select(getInvalidStep), takeUntil(this.unsubscriber$)).subscribe(invalidStep => {
			this.invalidStep = invalidStep;
		});
	}

	public onStepChange($event: StepperSelectionEvent): void {
		const clickIndex = $event.selectedIndex + 1;
		this.checkInvalidStep();

		if (clickIndex >= this.campaignStep) {
			setTimeout(() => (this.stepper.selectedIndex = this.campaignStep - 1));
		}
		if ((!this.isCurrentStepValid && this.activeStep < clickIndex) || (this.invalidStep && clickIndex > this.invalidStep)) {
			setTimeout(() => (this.stepper.selectedIndex = this.activeStep - 1));
		} else {
			this.navigationClick.emit(clickIndex);
		}
	}

	public toggleAspect(step: CampaignStepsEnum): boolean {
		return this.campaignStep >= step && (this.activeStep >= step || (this.isCurrentStepValid && (this.invalidStep >= step || !this.invalidStep)));
	}
}
