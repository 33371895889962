import { Component, OnDestroy, OnInit } from '@angular/core';
import { getBillingAccountInfo, UserManagementState } from '../../../state/user-management.reducer';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SettingsPageLabelsEnum } from '../../../shared/enums/navigation.enum';
import { BillingAddress } from 'src/app/user-management/shared/billing-and-payment/billing-and-payment.models';
import { InvoiceHistory } from 'src/app/_models/invoice-history-interface';
import * as UserManagementActions from '../../../state/user-management.actions';
import { CardInputComponent } from '../card-input/card-input.component';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../../shared/state/shared.actions';
import { MatDialog } from '@angular/material/dialog';
import { SharedState } from '../../../../shared/state/shared.reducer';
import { BackOfficeService } from '../../../../_services/back-office/back-office.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastNotificationService } from '../../../../shared/toast-notification/toast-notification.service';
import { AuthenticationService } from '../../../../_services/authentication.service';
import { AuthenticationState, getRedirectUrl } from '../../../../authentication/state/authentication.reducer';

@Component({
	selector: 'app-payment-method',
	templateUrl: './payment-method.component.html',
	styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
	public SettingsPageLabelsEnum = SettingsPageLabelsEnum;
	public firstCard = false;
	public billingAddress: BillingAddress;
	public invoiceHistory: InvoiceHistory;
	public appInit: boolean = false;

	private unsubscriber$ = new Subject<void>();

	constructor(
		private store: Store<UserManagementState>,
		private dialog: MatDialog,
		private sharedStore: Store<SharedState>,
		private backOfficeService: BackOfficeService,
		private translate: TranslateService,
		private toastNotificationService: ToastNotificationService,
		private authenticationService: AuthenticationService,
		private authStore: Store<AuthenticationState>
	) {}

	public ngOnInit(): void {
		this.store.dispatch(new UserManagementActions.SetActiveBillingPageUserManagement(null));
		this.fetchStore();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	private fetchStore(): void {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.backOfficeService
			.getAllCards()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(
				cards => {
					const cardsDontExist = !cards || cards.length < 0;
					this.firstCard = cardsDontExist ? true : false;
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.appInit = true;
				},
				() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.appInit = true;
					this.toastNotificationService.sendErrorToast(this.translate.instant('AN_ERROR_OCCURRED_PLEASE_REFRESH_THE_PAGE_OR_CONTACT_SUPPORT'));
				}
			);
		this.store.pipe(select(getBillingAccountInfo), takeUntil(this.unsubscriber$)).subscribe(billingAddress => {
			this.billingAddress = billingAddress;
		});
	}

	public addFirstPaymentPopup(): void {
		this.dialog
			.open<CardInputComponent>(CardInputComponent, {
				width: '33.375rem',
				height: '100%',
				position: {
					right: '0'
				},
				panelClass: ['matdialog-no-padding', 'slide', 'slideInRight'],
				data: {
					firstCard: this.firstCard
				}
			})
			.afterClosed()
			.subscribe(result => {
				if (result) {
					this.sharedStore.dispatch(new ShowGlobalSpinner());
					let err = false;

					this.backOfficeService
						.addCardNoPw({
							sourceCardToken: result.addedCard.cardId,
							billingAddress: result.addedCard.address1,
							city: result.addedCard.city,
							country: result.addedCard.country,
							zipCode: result.addedCard.zip
						})
						.toPromise()
						.catch(error => {
							this.store.dispatch(new HideGlobalSpinner());
							err = true;
							if (error && error.error && error.error[0] && error.error[0].description && error.error[0].description.length) {
								this.toastNotificationService.sendErrorToast(error.error[0].description.split(':')[1].split('.')[0]);
							} else {
								this.toastNotificationService.sendErrorToast(
									this.translate.instant('Error while trying to save your card changes, please contact support team')
								);
							}

							return;
						})
						.then(id => {
							this.store.dispatch(new HideGlobalSpinner());
							if (!err && id) {
								this.fetchStore();
								this.toastNotificationService.sendSuccessToast(this.translate.instant('YOUR_CREDIT_CARD_WAS_ADDED'));
							}
						});
				}
			});
	}
}
