import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { AAAModuleState, campaignCommonSelector, selectAAAModuleState } from '..';

const getAssetsState = createSelector(selectAAAModuleState, (state: AAAModuleState) => state.assetsState);
export const getUserImages = createSelector(campaignCommonSelector, state => state.userImages);
export const getUserVideos = createSelector(campaignCommonSelector, state => state.userVideos);
export const getExistingImages = createSelector(campaignCommonSelector, state => state.existingImages);
export const getExistingVideos = createSelector(campaignCommonSelector, state => state.existingVideos);
export const getPagePosts = createSelector(getAssetsState, state => _.cloneDeep(state.pagePosts));
export const getImageAssets = createSelector(getUserImages, getExistingImages, (userImages, existingImages) => {
	if (!userImages && !existingImages) {
		return null;
	}
	return { userImages: userImages, existingImages: existingImages };
});

export const getVideoAssets = createSelector(getUserVideos, getExistingVideos, (userVideos, existingVideos) => {
	if (!userVideos && !existingVideos) {
		return null;
	}
	return { userVideos: userVideos, existingVideos: existingVideos };
});
