<div class="table-con">
	<div class="datatable">
		<div class="header">
			<div class="title">Standard - Permissions</div>
			<div class="sub-title">Grant View, Edit or Full access to your new user for different sections.</div>
		</div>
		<table>
			<thead>
				<tr>
					<th>Services</th>
					<th>
						<span>Edit</span>
						<tgl01
							[disabled]="userDetail && userDetail?.user.roles === 'View only'"
							class="toggleBtn"
							(checked)="onCheckToggleEdit($event)"
							[value]="userDetail && userDetail?.user.roles === 'Standard'"
						></tgl01>
					</th>
					<th>
						<span>View Only</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of userPermissions; let i = index">
					<td>
						<div class="description">
							<div class="title">{{ row.title }}</div>
							<div class="sub-title">{{ row.desc }}</div>
						</div>
					</td>
					<td class="center">
						<input
							type="checkbox"
							[disabled]="!row.isEdit.active"
							(change)="checkItem($event.checked, i, 'isEdit')"
							[checked]="row.isEdit.status ? 'checked' : ''"
						/>
					</td>
					<td class="center">
						<input
							type="checkbox"
							[disabled]="true"
							(change)="checkItem($event.checked, i, 'isViewOnly')"
							[checked]="row.isViewOnly.status ? 'checked' : ''"
						/>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="buttons">
			<button class="btn-gcb-01" (click)="goBacks()">BACK</button>
			<button class="btn-gcb-01" (click)="onContinueNext()">CONFIRM</button>
		</div>
	</div>
</div>
