<div>
	<div class="verticalSpacing"></div>
	<div>
		<font03>Create AdSet</font03>
	</div>
	<div class="verticalSpacing"></div>
	<div>
		<card-collapsible
			[title]="'Use a Best Performing Adset'"
			[subtitle]="'Choose the Ad Set you would like to use. Edit this, or continue using it as it is.'"
			[width]="'51.875rem'"
			[imgLink]="'../../../assets/icons/Group 16764.svg'"
			[iscollapsed]="false"
			[description]="'Only the Ad Sets of the campaigns selected will appear in the list.'"
		>
			<div class="row">
				<div class="searchBox">
					<tb06 [icon]="'fa-search'" class="search-input" [placeHolderTextBox]="'Search'" [control]="searchFormControl.get('searchAdSetForm')"></tb06>
				</div>
				<div class="selectionCard">
					<app-selection-count>
						<font07-4>Selected: {{ selectedAdSetIds.length }}</font07-4>
					</app-selection-count>
				</div>
			</div>
			<div class="verticalSpacing"></div>
			<div>
				<app-master-table
					[areTabsAttached]="true"
					[masterService]="adSetsService"
					[masterTableImplementation]="adsManagerMasterTableImplementations.AdsManagerAdSetTable"
					[columnDefs]="adSetView"
					[frameworkComponents]="frameworkComponents"
					[rowBuffer]="200"
					[rowSelection]="rowSelection.Single"
					[rowModelType]="rowModelType.ServerSide"
					[defaultColDef]="defaultColDef"
					[paginationPageSize]="defaultPageSize"
					[numberOfRowsReturnedByServer]="defaultPageSize"
					[paginationOptions]="paginationOptions"
					[canPageSizeBeChanged]="true"
					[isRowSelectable]="adsManagerHelper.isRowSelectable"
					[getRowStyle]="adsManagerHelper.getRowStyle"
					[getRowClass]="adsManagerHelper.getRowClass"
					(gridReady)="onGridReady($event)"
				></app-master-table>
			</div>
		</card-collapsible>
	</div>
	<div class="bottomSpacing"></div>
</div>
