<div class="main-container">
	<div class="main-cards">
		<mat-card (click)="showKnowledgeView('general')">
			<div class="section">
				<img src="../../../../../assets/icons/general.png" />
				<div class="section-description">
					<h5>General</h5>
					Manage your Lolly account preferences
				</div>
			</div>
		</mat-card>
		<mat-card (click)="showKnowledgeView('accounts')">
			<div class="section">
				<img src="../../../../../assets/icons/accounts.png" />
				<div class="section-description">
					<h5>Accounts</h5>
					View and compare data across all your accounts
				</div>
			</div>
		</mat-card>
		<mat-card (click)="showKnowledgeView('advertize')">
			<div class="section">
				<img src="../../../../../assets/icons/advertize.png" />
				<div class="section-description">
					<h5>Advertize</h5>
					Manage and opimize your campaigns, ad sets and ads
				</div>
			</div>
		</mat-card>
		<mat-card (click)="showKnowledgeView('optimize')">
			<div class="section">
				<img src="../../../../../assets/icons/optimize.png" />
				<div class="section-description">
					<h5>Optimize</h5>
					Get Recommendations from Dexter on how to optimize your campaigns
				</div>
			</div>
		</mat-card>
		<mat-card (click)="showKnowledgeView('reports')">
			<div class="section">
				<img src="../../../../../assets/icons/report.png" />
				<div class="section-description">
					<h5>Reports</h5>
					Build fully customized reports to view and compare performance
				</div>
			</div>
		</mat-card>
		<mat-card (click)="showKnowledgeView('tracking')">
			<div class="section">
				<img src="../../../../../assets/icons/tracking.jpg" />
				<div class="section-description">
					<h5>Tracking</h5>
					Track events and view website's activity
				</div>
			</div>
		</mat-card>
	</div>
</div>
