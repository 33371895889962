<app-user-management-header></app-user-management-header>

<div class="page-container">
	<div class="d-flex justify-content-between align-items-center header">
		<div>
			<app-user-sub-header [pageTitle]="'Integrations'"></app-user-sub-header>
		</div>
	</div>
	<div class="integrate-content">
		<h5>Total {{ allIntegrationsConnected?.length }} accounts connected</h5>
		<div class="integration-section">
			<h4>Marketing</h4>
			<app-integration-list [integrations]="marketingIntegrations"></app-integration-list>
		</div>

		<div class="integration-section">
			<h4>Feeds Management</h4>
			<app-integration-list [integrations]="feedsIntegrations"></app-integration-list>
		</div>
	</div>
</div>
