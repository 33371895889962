import { InputDetailModel } from './input-detail.model';
import { LinkExtraInfo } from './link-extra-info.model';

export class WelcomePageModel {
	public img: InputDetailModel;
	public text: InputDetailModel;
	public buttonText: InputDetailModel;
	public buttonDisabled? = false;
	public title: string;
	public subtitle: string;
	public showLeftCard = true;
	public showRightCard = true;
	public linkExtraInfo: LinkExtraInfo;
	public showButtons = true;

	constructor() {
		this.img = new InputDetailModel();
		this.text = new InputDetailModel();
		this.buttonText = new InputDetailModel();
		this.linkExtraInfo = new LinkExtraInfo();
	}
}
