<div class="popup-section">
	<div class="main-section">
		<p style="font-size: 42px; color: #5b5fc7;" class="title">Thank you for your payment</p>
		<p style="font-size: 16px; margin-top: 25px; color: #4e4e4e;">A Receipt for this transaction has been sent via email for your records</p>
		<p style="color: #121212; margin-top: 25px; font-weight: 500;">Total Payment Amount</p>
		<p style="font-size: 36px;">{{ dialogData.price }}</p>
		<p style="color: #3a3a3a;">Payment Message</p>
		<p style="color: #1d1d1d;">Payment Processed {{ ' ' + dialogData.reference }}</p>
		<p style="color: #5b5fc7; font-weight: 14px;">You will be redirected in 5 seconds, please wait.</p>
	</div>
</div>
