import { FormControl } from '@angular/forms';
import { DateRange } from '../../calendar/calendar.models';
import moment from 'moment';
import { QueryBuilderColumn } from '../../query-builder/models/query-builder-column.model';
import { ChartTypeEnum } from '../../../reports/shared/charts/chart-type.enum';

export interface Actor {
	name: string;
	metrics: number[];
}

class XAndYAxis {
	xAxisData: any[] = [];
	yAxisData: number[] = [];

	constructor() {}
}

export class ChartWrapper {
	constructor(chartType: ChartTypeEnum) {
		this.chartType = chartType;
	}

	private static dateFormat = 'DD/MM/YY';
	private static dbDateFormat = 'YYYY-MM-DD';
	updateOptions: any;
	autoResize = true;
	show = false;
	chartType: ChartTypeEnum;
	chartOption: any;
	chartSingleMetricCtrl: FormControl = new FormControl();
	chartMultipleMetricCtrl: FormControl = new FormControl();
	selectedDateRange: DateRange;
	isOptimize = false;

	public static getLegendsMap(labelData: string[], actionHistory: any): string[] {
		const newData: string[] = new Array(labelData.length);
		for (let i = 0; i < newData.length; ++i) {
			newData[i] = '';
		}

		const rootDate = moment(labelData[0], this.dateFormat);
		for (const action of actionHistory) {
			const actionDate = moment(action.executedAt, this.dbDateFormat);
			const dayDifference = this.getDifferenceInDays(rootDate, actionDate);
			if (dayDifference >= 0 && dayDifference < labelData.length) {
				newData[dayDifference] = action.message;
			}
		}
		return newData;
	}

	public static getActorDisplayName(actor: any, mainDataName: string, nameDataMapping: any) {
		let actorName: string;

		if (nameDataMapping) {
			const found_mapping = nameDataMapping.find(function (mapping: any) {
				return mapping.id === actor[mainDataName];
			});

			if (found_mapping) {
				actorName = found_mapping.displayName;
			} else {
				actorName = actor[mainDataName];
			}
			return actorName;
		} else {
			return actor[mainDataName];
		}
	}

	public static reformatMoreFacebookObjectsForLineChart(
		response: any[],
		metric: QueryBuilderColumn,
		mainDataName: string,
		timeRange: DateRange,
		dateFieldName: string = 'Parameters_Until',
		nameDataMapping?: any
	): { labels: any[]; lines: Actor[] } {
		// nameDataMapping is for mapping Id's to actorNames for display puroposes
		// this is pure technical debt.

		const days = this.getDaysFromDateRange(timeRange);
		const actors: { [id: string]: Actor } = {};
		const actorNames: string[] = [];
		for (const insight of response) {
			const actorName = this.getActorDisplayName(insight, mainDataName, nameDataMapping);

			const dateIndex = this.getDifferenceInDays(
				moment(days[0], this.dateFormat),
				moment(insight[dateFieldName] as string, this.dbDateFormat)
			);
			if (!actors[actorName]) {
				actorNames.push(actorName);
				actors[actorName] = {
					name: actorName,
					metrics: new Array(days.length)
				};
			}
			actors[actorName].metrics[dateIndex] = insight[metric.accessName ? metric.accessName : metric.Name];
		}

		const actorsList: Actor[] = [];
		for (const actorName of actorNames) {
			actorsList.push(actors[actorName]);
		}

		return {
			labels: days,
			lines: actorsList
		};
	}

	public static reformatMoreFacebookObjectsForBarChart(
		response: any[],
		metric: QueryBuilderColumn,
		mainDataName: string,
		nameDataMapping: any
	): XAndYAxis {
		const axises = new XAndYAxis();
		for (const object of response) {
			const identifier = object[mainDataName];
			const displayName = this.getActorDisplayName(object, mainDataName, nameDataMapping);
			axises.xAxisData.push(displayName);

			axises.yAxisData.push(object[metric.accessName ? metric.accessName : metric.Name]);
		}
		return axises;
	}

	public static reformatMoreMetricsForLineChart(
		response: any[],
		metrics: QueryBuilderColumn[],
		timeRange: DateRange,
		dateFieldName: string = 'date_start'
	): { labels: any[]; lines: Actor[] } {
		const days = this.getDaysFromDateRange(timeRange);
		const actors: { [id: string]: Actor } = {};
		const actorNames: string[] = [];
		const metric_names = this.getMetricNames(metrics);

		for (const insight of response) {
			const dateIndex = this.getDifferenceInDays(
				moment(days[0], this.dateFormat),
				moment(insight[dateFieldName] as string, this.dbDateFormat)
			);
			for (const key in insight) {
				if (metric_names.find(metricName => metricName === key)) {
					const actorName = key;
					if (!actors[actorName]) {
						actorNames.push(actorName);
						actors[actorName] = {
							name: actorName,
							metrics: new Array(days.length)
						};
					}
					actors[actorName].metrics[dateIndex] = insight[key];
				}
			}
		}

		const actorsList: Actor[] = [];
		for (const actorName of actorNames) {
			actorsList.push(actors[actorName]);
		}

		//// TODO: Refactor
		// // Temporary fix as left and right side bar chart columns were going over the chart edge (axis lines)

		for (const actor of actorsList) {
			actor.metrics = [null, ...actor.metrics, null];
		}

		return {
			labels: days,
			lines: actorsList
		};
	}

	public static reformatMoreMetricsForOneFacebookObject(response: any, metrics: QueryBuilderColumn[]): XAndYAxis {
		if (response.length) {
			response = response[0];
		}
		const axises = new XAndYAxis();

		const metric_names = this.getMetricNames(metrics);

		for (const key in response) {
			if (metric_names.find(metricName => metricName === key)) {
				axises.xAxisData.push(key);
				axises.yAxisData.push(response[key]);
			}
		}

		return axises;
	}

	private static getDifferenceInDays(rootDate: moment.Moment, laterDate: moment.Moment): number {
		return laterDate.diff(rootDate, 'days');
	}

	private static getDaysFromDateRange(dateRange: DateRange): string[] {
		const dates: moment.Moment[] = [];
		for (const day = dateRange.startDate.clone(); day <= dateRange.endDate; day.add(1, 'days')) {
			dates.push(day.clone());
		}
		return dates.map(day => day.format(this.dateFormat));
	}

	private static getMetricNames(metrics: QueryBuilderColumn[]): string[] {
		const metric_names = [];
		for (const metric of metrics) {
			metric_names.push(metric.accessName ? metric.accessName : metric.Name);
		}
		return metric_names;
	}
}
