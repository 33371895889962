import { Component, OnDestroy } from '@angular/core';
import { GridApi, RowNode } from 'ag-grid-community';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { AdsManagerEffectiveStatus } from 'src/app/ads-manager/ads-manager-insights/models/ads-manager-effective-status.enum';
import { AdsManagerStatusEnum } from 'src/app/ads-manager/ads-manager-insights/models/ads-manager-status.enum';
import { AdsManagerAgGridServiceInterface } from '../../../models/ads-manager-ag-grid-service.interface';
import { AdsManagerHeaderComponentParamsInterface } from '../../../models/ads-manager-header-component-params.interface';

@Component({
	selector: 'app-ads-manager-header-checkbox',
	templateUrl: './ads-manager-header-checkbox.component.html',
	styleUrls: ['./ads-manager-header-checkbox.component.scss']
})
export class AdsManagerHeaderCheckboxComponent implements OnDestroy {
	public isCheckboxToggled = false;
	public isIndeterminate = false;
	public parentService: AdsManagerAgGridServiceInterface;
	public gridApi: GridApi;

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor() {}

	public agInit(params: AdsManagerHeaderComponentParamsInterface): void {
		this.parentService = params.parentService;
		this.gridApi = params.api;
		this.checkToggleState();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onCheckboxToggle(): void {
		if (!this.parentService.isCheckboxDeactivated) {
			if (!this.isCheckboxToggled && !this.isIndeterminate) {
				this.gridApi.getRenderedNodes().forEach((row: RowNode) => {
					if (row.data.effective_status === AdsManagerEffectiveStatus.Removed && row.data.status === AdsManagerStatusEnum.Removed) {
						return;
					}
					this.parentService.isSelectionToggledProgramatically = true;
					row.setSelected(true);
				});
			} else {
				this.gridApi.getRenderedNodes().forEach((row: RowNode) => {
					this.parentService.isSelectionToggledProgramatically = true;
					row.setSelected(false);
				});
			}

			// used timeout to mark this code as asynchronous which will run after synchronous code
			setTimeout(() => {
				this.parentService.isSelectionToggledProgramatically = false;
				this.parentService.triggerBundleUpdateSubject.next();
			}, 0);
			this.isCheckboxToggled = !this.isCheckboxToggled;
		}
	}

	private checkToggleState(): void {
		this.parentService
			.checkBoxStateObservable()
			.pipe(
				takeUntil(this.unsubscriber$),
				debounceTime(50),
				switchMap(data => {
					return of(data);
				})
			)
			.subscribe(() => {
				let isToggledCheckboxFound = false;
				let isUntoggledCheckboxFound = false;
				for (const row of this.gridApi.getRenderedNodes()) {
					if (row.isSelected()) {
						isToggledCheckboxFound = true;
					} else {
						isUntoggledCheckboxFound = true;
					}
					if (isToggledCheckboxFound && isUntoggledCheckboxFound) {
						break;
					}
				}
				this.isCheckboxToggled = isToggledCheckboxFound && !isUntoggledCheckboxFound;
				this.isIndeterminate = isToggledCheckboxFound && isUntoggledCheckboxFound;
			});
	}
}
