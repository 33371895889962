import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { FiledCreditCard } from '../../../user-management/components/filed-cards/set-creditcard.models';
import { catchError, filter, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { LoadCards } from '../../../user-management/state/user-management.actions';
import { getCards, UserManagementState } from '../../../user-management/state/user-management.reducer';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { SharedState } from '../../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../../shared/state/shared.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FiledCardsResolver implements Resolve<Observable<FiledCreditCard[]>> {
	constructor(
		private router: Router,
		private store: Store<UserManagementState>,
		private sharedStore: Store<SharedState>,
		private toastNotification: ToastNotificationService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FiledCreditCard[]> {
		this.store.dispatch(new LoadCards());
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		return this.store.pipe(
			select(getCards),
			filter(cards => cards !== null),
			take(1),
			tap(cards => {
				this.sharedStore.dispatch(new HideGlobalSpinner());
				if (!cards) {
					this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
					this.router.navigate(['/my-account']);
					return EMPTY;
				}
				catchError(() => {
					this.sharedStore.dispatch(new HideGlobalSpinner());
					this.toastNotification.sendErrorToast('Could not fetch data, please contact suport');
					this.router.navigate(['/my-account']);
					return EMPTY;
				});
			})
		);
	}
}
