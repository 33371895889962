import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { QuickNavigationService } from 'src/app/AAA/services/quick-navigation.service';

@Directive({
	selector: '[QuickNavigationScroll]'
})
export class QuickNavigationScrollDirective implements AfterViewInit, OnDestroy {
	constructor(private el: ElementRef, private quickNavigationService: QuickNavigationService) {}

	protected unsubscriber$ = new Subject<void>();

	public ngAfterViewInit(): void {
		const mainEl = this.el.nativeElement;
		mainEl.style.position = 'relative';
		let scrollItems = Array.from(mainEl.querySelectorAll('[qn-scroll-item]'));
		fromEvent(mainEl, 'scroll')
			.pipe(takeUntil(this.unsubscriber$), debounceTime(300))
			.subscribe((e: Event) => {
				if ((scrollItems[scrollItems.length - 1] as HTMLElement).offsetTop == 0) {
					scrollItems = Array.from(mainEl.querySelectorAll('[qn-scroll-item]'));
				}
				for (let i = 0; i < scrollItems.length; i++) {
					const itemOffsetTop = (scrollItems[i] as HTMLElement).offsetTop;
					const containerScrollTop = (e.target as Element).scrollTop;
					if (containerScrollTop < itemOffsetTop) {
						this.quickNavigationService.setSelectedNavigationViaScroll(i);
						return;
					}
				}
			});
	}

	public getOffsetTop(element: HTMLElement) {
		const rect = element.getBoundingClientRect();
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return rect.top + scrollTop;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}
}
