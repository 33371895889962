<div class="oc-container">
	<!--	 Lead Generation items-->
	<app-expansion-panel-sc [isSelected]="false" [expansionTitle]="'Lead Method'" [isExpandable]="false">
		<mat-radio-group expansion-content class="oc-sub-objectives-wrapper" name="Lead">
			<div *ngFor="let data of datas" class="oc-radio-btn-container oc-sub-objective-radio-btn">
				<mat-radio-button (click)="updateLeadMethodState(data)" [value]="data.value" name="leadMethods">
					<span class="oc-bold-text">{{ data.name }}</span>
				</mat-radio-button>
				<span class="oc-radio-btn-description">{{ data.description }}</span>
			</div>
		</mat-radio-group>
	</app-expansion-panel-sc>
</div>
