<div class="fxLayout" (mouseenter)="showTrashButton = true" (mouseout)="showTrashButton = false">
	<div fxFlex="44" class="form-container">
		<label class="form-label">{{ itemIndex < 1 ? 'Parameter Name' : '' }}</label>
		<tb06 [control]="parameterName" [placeHolderTextBox]="'e.g. Language'"></tb06>
	</div>
	<div fxFlex="1" class="form-container"></div>
	<div fxFlex="44" class="form-container">
		<label class="form-label">{{ itemIndex < 1 ? 'Parameter Value' : '' }}</label>
		<tb06 [control]="parameterName" [placeHolderTextBox]="'e.g. English'"></tb06>
	</div>
	<div fxFlex="1" class="form-container"></div>
	<div fxFlex="10" class="form-button" [style.margin-top]="itemIndex == 0 ? '28px' : ''" *ngIf="showTrashButton">
		<btn02 (onClick)="removeItem()" [icon]="'trash'"></btn02>
	</div>
</div>
