<div class="body">
	<div class="form-search">
		<div class="blockTitle">
			<div class="title">Invoices</div>
		</div>
		<div class="form">
			<div class="input-group">
				<input type="text" class="form-control" placeholder="Search Invoices" [formControl]="searchFormControl.controls['searchForm']" />
				<div class="icon">
					<span class="k-icon k-i-search"></span>
				</div>
			</div>
		</div>
	</div>
	<div class="datatable">
		<table>
			<thead>
				<tr>
					<th *ngFor="let column of columnDef">{{ column.label }}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of filteredRowData">
					<td>{{ row?.externalTransactionId ? row?.externalTransactionId : row?.billingId }}</td>
					<td>{{ fixInvDate(row?.billingPeriodTo) }}</td>
					<td>{{ fixAmount(row) }}</td>
					<td>
						<span *ngIf="row.status == 'Succeeded'" class="alert success">Paid</span>
						<span *ngIf="row.status == 'Pending' || row.status == 'Created' || row.status == 'Sent'" class="alert pending">Pending</span>
						<span *ngIf="row.status == 'Failed'" class="alert failed">Failed</span>
					</td>
					<td *ngIf="row.status == 'Succeeded'">
						<btn01 (onClick)="download(row)">Download</btn01>
					</td>
					<td *ngIf="row.status == 'Pending' || row.status == 'Created' || row.status == 'Sent'">
						<btn01 (onClick)="payForBilling(row)">Pay</btn01>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<td colspan="5">
					<div class="paginate">
						<app-datatable-pagination [totalPage]="rowDataLength" (eventClick)="onPageChanged($event)"></app-datatable-pagination>
					</div>
				</td>
			</tfoot>
		</table>
	</div>
</div>
<lolly-spinning-loader class="spinner" *ngIf="loading"></lolly-spinning-loader>
