export enum TableViewType {
	Master = 'Master',
	Fallback = 'Fallback',
	Business = 'Business',
	User = 'User'
}

export enum GoogleTableViewType {
	Master = 0,
	Fallback,
	Business,
	User
}
