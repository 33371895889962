<div mat-dialog-title>
	<div (click)="close()" class="x">
		<img alt="close" src="assets/icons/user-management/account/x.png" />
	</div>
	<div class="question">
		<img class="paynow" src="assets/icons/user-management/account/invoice-share.svg" />
	</div>
	<h5 class="text-display">Invoice number: {{ billId }}</h5>

	<h4 class="text-display">Share Invoice With</h4>

	<span>Enter the email address you want to send this invoice to</span>
	<br />
	<div class="input">
		<app-input-text [control]="emailFormControl" [validatorMessages]="validationLineMessages" label="Email address"></app-input-text>
	</div>
</div>

<div class="buttons">
	<app-button (buttonClicked)="close()" [buttonClass]="buttonClassEnum.Secondary" [buttonType]="buttonTypeEnum.Simple" buttonLabel="CANCEL"></app-button>
	<app-button
		(buttonClicked)="send()"
		[buttonClass]="buttonClassEnum.Primary"
		[buttonType]="buttonTypeEnum.Simple"
		[disabled]="emailFormControl.invalid"
		buttonLabel="SEND"
	></app-button>
</div>
