import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationRouteGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const hasCreditCard$ = this.authService.hasCreditCard();
		const hasBusinessOwner$ = this.authService.hasBusinessOwner();
		const hasClientEmployee$ = this.authService.hasClientEmployee();
		const isUserLoggedIn = this.authService.isLoggedIn();

		return forkJoin([hasCreditCard$, hasBusinessOwner$, hasClientEmployee$]).pipe(
			switchMap(([hasCreditCard, hasBusinessOwner, hasClientEmployee]) => {
				if (hasCreditCard && hasBusinessOwner && isUserLoggedIn) {
					this.router.navigate(['/accounts']);
					return of(false);
				}

				if (hasClientEmployee && isUserLoggedIn) {
					this.router.navigate(['/accounts']);
					return of(false);
				}

				return of(true);
			})
		);
	}
}
