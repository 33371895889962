import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserServiceApi } from '../../_services/user/user.api.service';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';
import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';
import { AuthenticationState, getResetPasswordDialogConstants } from '../state/authentication.reducer';
import { select, Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FieldValidators } from '../../user-management/models/validators';

@Component({
	selector: 'app-reset-password-with-email',
	templateUrl: './reset-password-with-email.component.html',
	styleUrls: ['./reset-password-with-email.component.scss']
})
export class ResetPasswordWithEmailComponent implements OnInit {
	public resetEmailFormGroup: FormGroup;
	public validationMessages: ValidatorMessages[];
	public switchDivSection: string;
	public resetEmailInput: string;
	public loader: boolean;

	private unsubscriber$: Subject<void> = new Subject<void>();
	constructor(
		private toastNotificationService: ToastNotificationService,
		private userServiceApi: UserServiceApi,
		private formBuilder: FormBuilder,
		private authStore: Store<AuthenticationState>
	) {}
	public ngOnInit(): void {
		this.authStore.pipe(select(getResetPasswordDialogConstants), take(1)).subscribe(messages => {
			this.validationMessages = messages;
		});
		this.resetEmailFormGroup = this.formBuilder.group({
			resetEmailFormControl: new FormControl('', [Validators.required, Validators.pattern(FieldValidators.Email)])
		});
	}
	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onSubmit(): void {
		this.resetEmailFormGroup.markAllAsTouched();
		if (this.resetEmailFormGroup.valid) {
			this.userServiceApi
				.resetPassword(this.resetEmailFormGroup.get('resetEmailFormControl').value)
				.pipe(takeUntil(this.unsubscriber$))
				.subscribe(
					() => {
						this.toastNotificationService.sendSuccessToast('Check your email ');
						this.resetEmailInput = this.resetEmailFormGroup.value.resetEmailFormControl;
						this.goTo('resendEmailVerification');
						this.resetEmailFormGroup.reset();
					},
					() => {
						this.toastNotificationService.sendErrorToast('No email address found. Please contact admin for more details');
					}
				);
		}
	}
	public goTo(switchDivSection: string): void {
		this.switchDivSection = switchDivSection;
	}
	private resendOnsubmit(): void {
		this.userServiceApi
			.resetPassword(this.resetEmailInput)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.toastNotificationService.sendSuccessToast('Your email was Successfully sent again');
				this.goTo('resendEmailVerification');
			});
	}
}
