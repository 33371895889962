import { DropdownInterface } from '@filed-com/filed-lib';
import { Action } from '@ngrx/store';
import { CarouselDto, SingleAdvertDto } from 'src/app/campaign-builder/models/advert.interface';
import { Page } from 'src/app/campaign-builder/models/page.model';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { EventDtoInterface } from 'src/app/pixel/_models/event-dto.interface';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { AdModel } from '../../models/ad.model';
import { AdState } from './ad-state.reducer';

export enum AdActionTypes {
	AddAd = '[Ad] Add Ad',
	ResetState = '[Ad] Reset State',
	RemoveAd = '[Ad] Remove Ad',
	ClearAds = '[Ad] Clear All Ads',
	UpdateCurrentAdId = '[Ad] Update Current Ad Id',
	UpdateAdName = '[Ad] Update Ad Name',
	UpdateAdverts = '[Ad] Update Ad Adverts',
	UpdateAdFormatType = '[Ad] Update Ad Format Type',
	UpdatePixelId = '[Ad] Update Ad Pixel Id',
	UpdatePixelToggle = '[Ad] Update Ad Pixel Toggle',
	UpdatePixelAppEventId = '[Ad] Update Ad Pixel App Event Id',
	UpdateConversion = '[Ad] Update Ad Conversion',
	UpdateEventRule = '[Ad] Update Ad Event Rule',

	UpdatePages = '[Ad] Update Pages Id',
	LoadFacebookPages = '[AAA Assets] Load Facebook Pages',
	LoadFacebookPagesSuccess = '[AAA Assets] Load Facebook Pages Success',
	LoadFacebookPagesFailure = '[AAA Assets] Load Facebook Pages Failure'
}

export class AddAd implements Action {
	public readonly type = AdActionTypes.AddAd;

	constructor(public payload: AdModel) {}
}

export class ResetState implements Action {
	public readonly type = AdActionTypes.ResetState;

	constructor() {}
}
export class RemoveAd implements Action {
	public readonly type = AdActionTypes.RemoveAd;

	constructor(public payload: number) {}
}

export class ClearAds implements Action {
	public readonly type = AdActionTypes.ClearAds;

	constructor() {}
}

export class UpdatePages implements Action {
	public readonly type = AdActionTypes.UpdatePages;

	constructor(public payload: { facebookPageId: string; instagramPageId: string }) {}
}

export class UpdateCurrentAdId implements Action {
	public readonly type = AdActionTypes.UpdateCurrentAdId;

	constructor(public payload: number) {}
}

export class UpdateAdName implements Action {
	public readonly type = AdActionTypes.UpdateAdName;

	constructor(public payload: string) {}
}

export class UpdateAdFormatType implements Action {
	public readonly type = AdActionTypes.UpdateAdFormatType;

	constructor(public payload: AdFormatsEnum) {}
}

export class UpdateAdverts implements Action {
	public readonly type = AdActionTypes.UpdateAdverts;
	constructor(public payload: SingleAdvertDto | CarouselDto) {}
}

export class UpdatePixelToggle implements Action {
	public readonly type = AdActionTypes.UpdatePixelToggle;
	constructor(public payload: boolean) {}
}

export class UpdatePixelId implements Action {
	public readonly type = AdActionTypes.UpdatePixelId;
	constructor(public payload: string) {}
}

export class UpdatePixelAppEventId implements Action {
	public readonly type = AdActionTypes.UpdatePixelAppEventId;
	constructor(public payload: string) {}
}

export class UpdateConversion implements Action {
	public readonly type = AdActionTypes.UpdateConversion;
	constructor(public payload: DropdownData<EventDtoInterface>) {}
}

export class UpdateEventRule implements Action {
	public readonly type = AdActionTypes.UpdateEventRule;
	constructor(public payload: Object) {}
}

export class LoadFacebookPages implements Action {
	public readonly type = AdActionTypes.LoadFacebookPages;
	constructor() {}
}

export class LoadFacebookPagesSuccess implements Action {
	public readonly type = AdActionTypes.LoadFacebookPagesSuccess;
	constructor(public payload: DropdownInterface[]) {}
}

export class LoadFacebookPagesFailure implements Action {
	public readonly type = AdActionTypes.LoadFacebookPagesFailure;
	constructor() {}
}

export type AdActions =
	| AddAd
	| ResetState
	| RemoveAd
	| ClearAds
	| UpdateCurrentAdId
	| UpdatePages
	| UpdateAdName
	| UpdateAdFormatType
	| UpdateAdverts
	| UpdatePixelToggle
	| UpdatePixelId
	| UpdatePixelAppEventId
	| UpdateConversion
	| UpdateEventRule
	| LoadFacebookPages
	| LoadFacebookPagesSuccess
	| LoadFacebookPagesFailure;
