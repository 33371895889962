import { AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CampaignStepsEnum } from '../../models/campaign-steps.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, exhaustMap, skipWhile, take, takeUntil } from 'rxjs/operators';
import { SelectedAdAccountModel } from '../../../shared/state/state-entities/cached-ad-accounts.model';
import { Page } from '../../../campaign-builder/models/page.model';
import { CampaignTemplate } from '../../models/campaign-template';
import { InstagramAccount } from '../../../campaign-builder/models/instagram-account.model';
import { ExpansionPanelHelper } from '../../../shared/expansion-panel/expansion-panel-helper.service';
import { AdSetSettingsPanels } from './ad-set-settings-panels';
import { IQuickNavigation } from '@filed-com/filed-lib/lib/cards/models/quick-navigation.model';
import { DropdownInterface, QuickNavigationService } from '@filed-com/filed-lib';
import { SelectType } from '../../../shared/dropdown-search-select/dropdown-type.enum';
import { select, Store } from '@ngrx/store';
import {
	CampaignSmartCreateState,
	getAdSetOptimization,
	getCampaignActiveDate,
	getCampaignBudgetOptimization,
	getCatalogId,
	getCurrency,
	getFacebookPageId,
	getObjectiveTree,
	getPlacements,
	getSpecialAdCategory,
	getStepTwoDetails,
	getTargeting
} from '../../state/smart-create.reducer';
import {
	ClearCurrentAssets,
	ClearCurrentStep,
	LoadPagePosts,
	UpdateAdSetBudgetOptimization,
	UpdateAdSetName,
	UpdateAppSelection,
	UpdateAppStoreType,
	UpdateCampaignActiveDate,
	UpdateCampaignCurrentStep,
	UpdateCampaignInvalidStep,
	UpdateCampaignTemplate,
	UpdateCatalogSetId,
	UpdateCurrency,
	UpdateOptimizationAndDelivery,
	UpdatePages,
	UpdatePlacements,
	UpdateTargeting
} from '../../state/smart-create.actions';
import { Helper } from '../../../reports/helper';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { StepTwoDetails } from '../../models/step-two-details';
import { getSelectedAdAccount, SharedState } from '../../../shared/state/shared.reducer';
import { of, Subject } from 'rxjs';
import moment from 'moment';
import { CampaignDateChoices } from '../../../shared/smart-create-edit-components/step-two-components/date-card/campaign-date-choices.enum';
import { DateCardInterface } from '../../../shared/smart-create-edit-components/step-two-components/date-card/campaign-date-card.interface';
import { DateRangeX, PublicDateModel } from '../../../shared/calendar/calendar.models';
import { CampaignSmartCreateMapperService } from '../../services/campaign-smart-create-mapper.service';
import { FacebookInterestsView } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests-view';
import { TargetingAudience } from '../../models/targeting-audience';
import { SavedAudienceInterface } from '../../models/saved-audience.interface';
import { AudienceTypeEnum } from '../../models/audience-type.enum';
import { AudienceSubtypeEnum } from '../../models/audience-subtype.enum';
import { AudienceService } from '../../../_services/audience/audience.service';
import { AudienceData } from '../../../audience/models/audience-data-table.model';
import { MapperService } from '../../../shared/services/mapper.service';
import { CatalogNodeInterface } from '../../catalogs/catalog-node.interface';
import { DropdownData } from '../../../shared/dropdown-search-select/dropdown-data.interface';
import { CampaignBudgetOptimizationInterface } from '../../models/campaign-budget-optimization.interface';
import { ValidationNodeInterface } from '../../catalogs/validation-node.interface';
import { OptimizationAndDeliveryInterface } from '../../models/optimization-and-delivery.interface';
import { getFacebookBusinessOwnerId, UserState } from '../../../shared/state/user/user.reducer';
import { SubObjectives } from '../../models/sub-objectives';
import { getCurrencySymbol } from '@angular/common';
import { AdAccountApiService } from '../../../_services/facebook-accounts/ad-account-api.service';
import { CampaignSmartCreateService } from '../../services/campaign-smart-create.service';
import { BudgetAllocatedEnum } from '../../models/budget-allocated.enum';
import { AudienceSizeBarInterface } from 'src/app/shared/models/audience-size-bar.interface';
import { TreeViewInterface } from '@filed-com/filed-lib/lib/tree-view/models/tw-interface';
import { LoadExistingImages, LoadExistingVideos, LoadUserImages, LoadUserVideos } from '../../../state/campaign-common-state/campaign-common-actions';
import {
	getAdvertisableApps,
	getBillingEvents,
	getClicks,
	getOptimizationGoal,
	getPlacementsFromCatalog,
	getPlatforms,
	getValidations,
	getViews
} from '../../../state/campaign-common-state/campaign-common-reducer';
import { AdvertiseAppInterface } from '../../models/advertise-app.interface';
import { AppStoreTypeEnum } from '../../models/app-store-type.enum';
import { ParentTargetingTypeEnum } from '../../../shared/smart-create-edit-components/step-two-components/targeting-card/models/targeting-type.enum';
import { ValidatorMessages } from '../../../shared/form-input/validatorMessagesInputs';

@Component({
	selector: 'app-ad-set-settings',
	templateUrl: './ad-set-settings.component.html',
	styleUrls: ['./ad-set-settings.component.scss'],
	providers: [ExpansionPanelHelper]
})
export class AdSetSettingsComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('top', { read: ElementRef }) public navigation: ElementRef;
	public minBudget: number = 0;
	public validBudget: number = 0;
	public facebookPages: DropdownData<Page>[];
	public instagramPages: DropdownData<InstagramAccount>[];
	public campaign: CampaignTemplate;
	public adSetSettingsFormGroup: FormGroup;
	public panels = AdSetSettingsPanels;
	public selectType = SelectType;
	public selectedAdAccount: SelectedAdAccountModel;
	public currentStep: StepTwoDetails;
	public readonly step = CampaignStepsEnum.Two;
	public isStepValid = true;
	public placements: CatalogNodeInterface[];
	public targeting: TargetingAudience;
	public campaignBudgetOptimization: boolean;
	public isSpecialAdCategoryOn: boolean;
	public interests: DropdownData<FacebookInterestsView>[] = [];
	public savedAudiences: DropdownData<AudienceData>[];
	public adAccountFacebookId: string;
	public optimizationGoal: string;
	public optimizationGoalList: DropdownData<CatalogNodeInterface>[];
	public billingEventsList: DropdownData<CatalogNodeInterface>[];
	public clicks: DropdownData<CatalogNodeInterface>[];
	public views: DropdownData<CatalogNodeInterface>[];
	public platforms: CatalogNodeInterface[];
	public currency: string;
	public validations: ValidationNodeInterface[];
	public objectiveWithDestination: string;
	public subObjective: string;
	public facebookPageId: string;
	public validationTrigger: boolean;
	public subObjectives = SubObjectives;
	public productCatalogId: string;
	public productSetId: string;
	public selectedObjective: string;

	public preselectedDate: PublicDateModel = {
		date: {
			startDate: moment(),
			endDate: moment().add(1, 'month')
		}
	};
	public navigations: IQuickNavigation[] = [
		{ name: 'Ad Set Name', targetId: 'campaign-name' },
		{ name: 'Select Page', targetId: 'select-page' },
		{ name: 'Choose Catalog Set', targetId: 'choose-catalog-set' },
		{ name: 'Placements', targetId: 'placements' },
		{ name: 'Targeting', targetId: 'targetingid' },
		{ name: 'Ad Set Budget Optimization', targetId: 'adset-budget-optimization' },
		{ name: 'Date', targetId: 'date' }
	];

	public bars: AudienceSizeBarInterface[] = [
		{
			height: '1.4rem',
			isActive: false
		},
		{
			height: '1.69rem',
			isActive: false
		},
		{
			height: '2.188rem',
			isActive: false
		},
		{
			height: '3.375rem',
			isActive: false
		},
		{
			height: '4.375rem',
			isActive: false
		},
		{
			height: '5.375rem',
			isActive: false
		},
		{
			height: '6.688rem',
			isActive: false
		},
		{
			height: '8.313rem',
			isActive: false
		},
		{
			height: '9.75rem',
			isActive: false
		},
		{
			height: '10.938rem',
			isActive: false
		}
	];
	public audienceResult: string;

	public campaignDate = new DateRangeX<string>();
	public label = 'Name of Ad';
	public dateChoiceType: CampaignDateChoices;
	public platformsPerDevice: CatalogNodeInterface[];
	public regulatedInterests: DropdownData<FacebookInterestsView>[];
	public adCategory: string;
	public isCampaignLifeTimeSelected = false;
	public isAdSetLifeTimeSelected = false;
	public selectedIndex: number;

	protected unsubscriber$ = new Subject<void>();
	protected invalidStep: CampaignStepsEnum;
	protected businessId: number;

	public expansionPanelHelper: ExpansionPanelHelper<AdSetSettingsPanels>;
	protected router: Router;
	protected formBuilder: FormBuilder;
	protected activatedRoute: ActivatedRoute;
	protected toastService: ToastNotificationService;
	protected store: Store<CampaignSmartCreateState>;
	protected sharedStore: Store<SharedState>;
	protected smartCreateMapper: CampaignSmartCreateMapperService;
	protected audienceService: AudienceService;
	protected mapperService: MapperService;
	protected mapperSmartService: CampaignSmartCreateMapperService;
	protected accountService: AdAccountApiService;
	protected userStore: Store<UserState>;
	protected toastNotificationService: ToastNotificationService;
	protected smartCreateService: CampaignSmartCreateService;
	protected quickNavigationService: QuickNavigationService;
	public appForm: FormGroup;
	public productCatalogSets: any;
	public isCatalogSales: boolean;
	public isAppInstall: boolean;
	public advertisableApps: DropdownInterface[] | null;
	public validationMessages: ValidatorMessages[] = [];

	constructor(protected injector: Injector) {
		this.router = this.injector.get<Router>(Router);
		this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
		this.activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
		this.toastService = this.injector.get<ToastNotificationService>(ToastNotificationService);
		this.sharedStore = this.injector.get<Store<SharedState>>(Store);
		this.store = this.injector.get<Store<CampaignSmartCreateState>>(Store);
		this.smartCreateMapper = this.injector.get<CampaignSmartCreateMapperService>(CampaignSmartCreateMapperService);
		this.mapperSmartService = this.injector.get<CampaignSmartCreateMapperService>(CampaignSmartCreateMapperService);
		this.audienceService = this.injector.get<AudienceService>(AudienceService);
		this.mapperService = this.injector.get<MapperService>(MapperService);
		this.accountService = this.injector.get<AdAccountApiService>(AdAccountApiService);
		this.userStore = this.injector.get<Store<UserState>>(Store);
		this.toastNotificationService = this.injector.get<ToastNotificationService>(ToastNotificationService);
		this.smartCreateService = this.injector.get<CampaignSmartCreateService>(CampaignSmartCreateService);
		this.expansionPanelHelper = this.injector.get<ExpansionPanelHelper<AdSetSettingsPanels>>(ExpansionPanelHelper);
		this.quickNavigationService = this.injector.get<QuickNavigationService>(QuickNavigationService);
	}

	public ngOnInit(): void {
		this.getadvertisableApps();
		this.getActivatedRouteData();
		this.getPlacements();
		this.getPlacementsFromCatalog();
		this.checkAdSetBudget();
		// this.getMinBudget();
		this.checkCampaignBudgetOptimisationIsOn();
		this.getCampaignDateInfo();
		// this.getSpecialAdCategory();
		this.getTargeting();
		this.getPlatforms();
		this.getCurrency();
		this.getOptimizationGoals();
		this.getBillingEvents();
		this.getClickAndViews();
		this.getValidations();
		this.getObjectiveTree();
		this.getFacebookId();
		this.initAppForm();
		this.getCatalogId();
	}

	public initAppForm(): void {
		this.appForm = this.formBuilder.group({
			appName: [null, Validators.required],
			appLink: [null, Validators.required]
		});
	}
	public getadvertisableApps(): void {
		this.store.pipe(select(getAdvertisableApps), takeUntil(this.unsubscriber$)).subscribe(res => {
			this.advertisableApps = res;
		});
	}
	public registerSelection(): void {
		this.quickNavigationService
			.getSelectedNavigation()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((selectedIndex: number) => (this.selectedIndex = selectedIndex));
	}

	public onPanelClick(index: number): void {
		// this.quickNavigationService.setSelectedNavigation(index);
	}

	public ngAfterViewInit(): void {
		this.navigation.nativeElement.scrollIntoView();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public getActivatedRouteData(): void {
		this.activatedRoute.data.pipe(take(1)).subscribe(data => {
			this.store.dispatch(new UpdateCampaignCurrentStep(this.step));
			this.facebookPages = data.store.facebookPages;
			this.campaign = data.store.campaign;
			this.selectedObjective = this.campaign?.stepOneDetailsAsJson?.objective;
			if (this.campaign.stepTwoDetailsAsJson?.adSetName) {
				this.label = '';
			}
			this.campaign.currentStep = this.step;
			this.invalidStep = this.campaign.invalidStep;
			this.currentStep = this.campaign.stepTwoDetailsAsJson ?? new StepTwoDetails();
			this.selectedAdAccount = data.store.adAccount;
			this.adAccountFacebookId = this.selectedAdAccount.adAccount.id;
			this.optimizationGoal = data.store.campaign.stepOneDetailsAsJson.objective;
			this.businessId = this.selectedAdAccount.adAccount.businessId;
			this.isAppInstall = this.selectedObjective === this.subObjectives.AppInstallText;
			this.initFormGroup();
			this.isCatalogSales = this.subObjectives.ProductCatalogSales === this.selectedObjective;
			this.initStoreListeners();
			this.dispatchActions();
			this.isStepValid = this.adSetSettingsFormGroup.valid;
		});
	}

	public getValidations(): void {
		this.store.pipe(select(getValidations), take(1)).subscribe(validation => {
			this.validations = validation;
		});
	}

	public getFacebookId(): void {
		this.store.pipe(select(getFacebookPageId), takeUntil(this.unsubscriber$)).subscribe(response => {
			if (response) {
				this.facebookPageId = response;
			}
		});
	}

	public getObjectiveTree(): void {
		this.store.pipe(select(getObjectiveTree), take(1)).subscribe(objectiveTree => {
			this.subObjective = objectiveTree.subObjective;
			if (objectiveTree.subObjective !== this.subObjectives.LinkCLicks) {
				this.objectiveWithDestination = `${objectiveTree.subObjective + '_X_' + 'UNDEFINED'}`;
			} else {
				this.objectiveWithDestination = `${objectiveTree.subObjective + '_X_' + objectiveTree.destinationType}`;
			}
		});
	}

	public getOptimizationGoals(): void {
		this.store.pipe(select(getOptimizationGoal), take(1)).subscribe(goals => {
			if (goals && goals.length > 0) {
				this.optimizationGoalList = this.mapperService.convertToDropdownData(goals, 'name', 'displayNameAutogen');
				this.optimizationGoalList.pop();
			}
		});
	}

	public getClickAndViews(): void {
		this.store.pipe(select(getClicks), take(1)).subscribe(clicksList => {
			if (clicksList && clicksList.length) {
				this.clicks = this.mapperService.convertToDropdownData(clicksList, 'name', 'displayName');
				this.clicks.pop();
			}
		});

		this.store.pipe(select(getViews), take(1)).subscribe(viewsList => {
			if (viewsList && viewsList.length) {
				this.views = this.mapperService.convertToDropdownData(viewsList, 'name', 'displayName');
				this.views.pop();
			}
		});
	}

	public getBillingEvents(): void {
		this.store.pipe(select(getBillingEvents), take(1)).subscribe(billingEvents => {
			if (billingEvents && billingEvents.length) {
				this.billingEventsList = this.mapperService.convertToDropdownData(billingEvents, 'name', 'displayNameAutogen');
				this.billingEventsList.pop();
			}
		});
	}

	public checkCampaignBudgetOptimisationIsOn(): void {
		this.store.pipe(select(getCampaignBudgetOptimization), take(1)).subscribe(isActive => {
			this.campaignBudgetOptimization = isActive && isActive.budgetAllocatedTypeId != null;
			if (this.campaignBudgetOptimization) {
				this.store.dispatch(new UpdateCampaignActiveDate({ date: null, dateChoiceType: null }));
			}
			if (isActive && isActive.budgetAllocatedTypeId === 0) {
				this.isCampaignLifeTimeSelected = true;
			}
		});
	}

	public checkAdSetBudget(): void {
		this.store.pipe(select(getAdSetOptimization), takeUntil(this.unsubscriber$)).subscribe(isActive => {
			if (isActive && isActive.budgetAllocatedTypeId === 0) {
				this.isAdSetLifeTimeSelected = true;
			}
		});
	}

	public getPlacementsFromCatalog(): void {
		this.store.pipe(select(getPlacementsFromCatalog), takeUntil(this.unsubscriber$)).subscribe(placements => {
			this.platformsPerDevice = placements;
		});
	}

	public getPlacements(): void {
		this.store.pipe(select(getPlacements), takeUntil(this.unsubscriber$)).subscribe(placements => {
			this.placements = placements;
		});
	}

	public getPlatforms(): void {
		this.store.pipe(select(getPlatforms), takeUntil(this.unsubscriber$)).subscribe(platforms => {
			this.platforms = platforms;
			if (platforms) {
				this.platforms = this.mapperSmartService.sortContexts(this.platforms);
			}
		});
	}

	public getCampaignDateInfo(): void {
		this.store.pipe(select(getCampaignActiveDate), takeUntil(this.unsubscriber$)).subscribe(date => {
			if ((date && date.dateChoiceType === CampaignDateChoices.Custom) || (date && (!this.isCampaignLifeTimeSelected || !this.isAdSetLifeTimeSelected))) {
				this.campaignDate = date.date;
				this.dateChoiceType = date.dateChoiceType;
				this.preselectedDate.date.startDate = moment(this.campaignDate?.startDate);
				this.preselectedDate.date.endDate = moment(this.campaignDate?.endDate);
			} else {
				this.setDefaultDate();
			}
		});
	}

	// public getMinBudget(): void {
	// 	this.smartCreateService
	// 		.getBudgetValidation(this.selectedAdAccount.adAccount.id)
	// 		.pipe(take(1))
	// 		.subscribe(response => {
	// 			this.minBudget = response.minimumAdAccountDailyBudget;
	// 			this.adSetSettingsFormGroup.get('adSetBudget').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: true });
	// 		});
	// }

	public setDefaultDate(): void {
		const campaignDate = this.defaultCampaignDate();
		this.dateChoiceType = CampaignDateChoices.Custom;
		this.campaignDate = campaignDate;
		if (this.campaignBudgetOptimization) {
			this.store.dispatch(new UpdateCampaignActiveDate({ date: null, dateChoiceType: null }));
		} else {
			this.store.dispatch(new UpdateCampaignActiveDate({ date: campaignDate, dateChoiceType: this.dateChoiceType }));
		}
	}

	public getTargeting(): void {
		this.store.pipe(select(getTargeting), takeUntil(this.unsubscriber$)).subscribe(details => {
			this.targeting = details ?? new TargetingAudience();
		});
	}

	// public getSpecialAdCategory(): void {
	// 	this.store
	// 		.pipe(
	// 			select(getSpecialAdCategory),
	// 			take(1),
	// 			exhaustMap(adCategory => {
	// 				if (adCategory) {
	// 					this.isSpecialAdCategoryOn = true;
	// 					this.adCategory = adCategory;
	// 					return this.smartCreateMapper.getAndMapRegulatedInterests(adCategory);
	// 				} else {
	// 					return of([]);
	// 				}
	// 			})
	// 		)
	// 		.subscribe(regulatedInterests => {
	// 			if (regulatedInterests.length) {
	// 				this.regulatedInterests = regulatedInterests;
	// 				this.interests = regulatedInterests;
	// 			}
	// 		});
	// }

	public defaultCampaignDate(): DateRangeX<string> {
		const result = new DateRangeX<string>();
		result.startDate = moment().toISOString(true);
		result.endDate = moment().add(1, 'month').toISOString(true);
		return result;
	}

	public saveAudience(audience: TargetingAudience): void {
		audience.businessId = this.businessId;
		const savedAudience: SavedAudienceInterface = {
			adAccountFacebookId: this.adAccountFacebookId,
			details: audience,
			audienceType: AudienceTypeEnum.Saved,
			audienceSubtype: AudienceSubtypeEnum.LOOKALIKE
		};
		// this.audienceService
		// 	.saveAudience(savedAudience)
		// 	.pipe(take(1))
		// 	.subscribe(
		// 		response => {
		// 			this.updateSavedAudiences();
		// 		},
		// 		() => {
		// 			this.toastNotificationService.sendErrorToast('Audience could not be saved due to an internal error. Please try again');
		// 		}
		// 	);
	}
	public dateLength(): number {
		let numberOfDays = 0;
		this.store.pipe(select(getCampaignActiveDate), take(1)).subscribe(response => {
			if (response?.date) {
				const startDate = moment(response.date.startDate);
				const endDate = moment(response.date.endDate);
				numberOfDays = Math.round(moment.duration(endDate.diff(startDate)).asDays());
			}
		});
		return numberOfDays;
	}

	// public updateAudience(audience: TargetingAudience): void {
	// 	const modifyAudience: SavedAudienceInterface = {
	// 		adAccountId: this.adAccountFacebookId,
	// 		details: audience,
	// 		audienceType: AudienceTypeEnum.Saved,
	// 		audienceSubtype: AudienceSubtypeEnum.LOOKALIKE,
	// 		name: audience.name
	// 	};
	// 	this.audienceService
	// 		.editAudience(modifyAudience)
	// 		.pipe(take(1))
	// 		.subscribe(
	// 			response => {
	// 				this.updateSavedAudiences();
	// 			},
	// 			() => {
	// 				this.toastNotificationService.sendErrorToast('Audience could not be updated due to an internal error. Please try again');
	// 			}
	// 		);
	// }

	// protected updateSavedAudiences(): void {
	// 	this.audienceService.getAll(this.selectedAdAccount.adAccount).subscribe(audiences => {
	// 		const allAudiences = this.mapperService.convertToDropdownDataOfType(audiences, 'id', 'name', this.smartCreateMapper.formatAudienceSize);
	// 		this.savedAudiences = allAudiences.filter(audience => audience.data.type === 'Saved');
	// 	});
	// }

	public updateTargeting(target: TargetingAudience): void {
		this.store.dispatch(new UpdateTargeting(target));
	}

	public updatePlacements(data: CatalogNodeInterface[]): void {
		this.store.dispatch(new UpdatePlacements(data));
	}

	public updateCampaignDateStatus(status: DateCardInterface): void {
		this.store.dispatch(new UpdateCampaignActiveDate({ date: status.campaignDate, dateChoiceType: status.dateChoiceType }));
		this.store.pipe(select(getAdSetOptimization), take(1)).subscribe(budget => {
			if (budget) {
				if (budget.budgetAllocatedTypeId === BudgetAllocatedEnum.LifeTime) {
					this.validBudget = Math.round(this.dateLength() * this.minBudget);
				} else {
					this.validBudget = this.minBudget;
				}
				this.adSetSettingsFormGroup
					.get('adSetBudget')
					.get('amount')
					.setValidators([Validators.min(this.validBudget)]);
				this.adSetSettingsFormGroup.get('adSetBudget').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });

				const budgetOptimization: CampaignBudgetOptimizationInterface = {
					amount: budget.amount,
					budgetAllocatedTypeId: budget.budgetAllocatedTypeId,
					minimumBudget: this.validBudget,
					bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
					deliveryType: 'standard'
				};
				this.store.dispatch(new UpdateAdSetBudgetOptimization(budgetOptimization));
			}
		});
	}

	public onNavClick(step: CampaignStepsEnum): void {
		if (step <= this.campaign.lastStepId) {
			const isValidityChecked = this.campaign.currentStep <= step;
			this.updateCampaign(isValidityChecked);
			if (this.isStepValid) {
				this.router.navigate([`campaign/build-campaign/${step}/${this.campaign.id}`]);
			}
		}
	}

	public onCancel(): void {
		this.updateCampaign(false);
		this.router.navigate(['/campaign/select-campaign']);
	}

	public onBack(): void {
		const backStep = CampaignStepsEnum.One;
		this.updateCampaign(false);
		this.router.navigate([`campaign/build-campaign/${backStep}/${this.campaign.id}`]);
	}

	public onNext(): void {
		const nextStep = CampaignStepsEnum.Three;
		this.updateCampaign(this.targeting?.retargeting === null);
		if (this.isStepValid || this.targeting?.retargeting !== null) {
			this.router.navigate([`campaign/build-campaign/${nextStep}/${this.campaign.id}`]);
		}
	}

	public getCurrency(): void {
		this.store.pipe(select(getCurrency), take(1)).subscribe(AccCurrency => {
			if (!AccCurrency) {
				this.accountService.adAccountChanged$.pipe(takeUntil(this.unsubscriber$)).subscribe(resp => {
					this.currency = getCurrencySymbol(resp.currency, 'narrow');
					this.store.dispatch(new UpdateCurrency(this.currency));
				});
			} else {
				this.currency = AccCurrency;
			}
		});
	}

	public updatePlacementPerPlatform(placementPerPlatform: TreeViewInterface[]): void {}

	public updateAudienceGraph(size: number): void {
		if (size < 100000) {
			this.audienceResult = 'Targeted';
			for (var i = 0; i < 9; i++) {
				if (i < 1) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 1000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 2) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 10000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 3) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 30000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 4) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 40000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 5) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 50000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 6) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 60000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 7) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 70000000) {
			this.audienceResult = 'Just Perfect';
			for (var i = 0; i < 9; i++) {
				if (i < 8) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else if (size < 75000000) {
			this.audienceResult = 'Broad';
			for (var i = 0; i < 9; i++) {
				if (i < 9) {
					this.bars[i].isActive = true;
				} else {
					this.bars[i].isActive = false;
				}
			}
		} else {
			this.audienceResult = 'Broad';
			for (var i = 0; i < 9; i++) {
				this.bars[i].isActive = true;
			}
		}
	}

	private loadAssets(facebookPage: string): void {
		this.store.dispatch(new LoadUserVideos());
		this.userStore.pipe(select(getFacebookBusinessOwnerId), take(1)).subscribe(id => {
			this.store.dispatch(new LoadExistingImages({ adAccountId: this.selectedAdAccount.adAccount.id, businessOwnerId: id }));
			this.store.dispatch(new LoadExistingVideos({ adAccountId: this.selectedAdAccount.adAccount.id, businessOwnerId: id }));
			this.store.dispatch(new LoadPagePosts({ adAccountId: facebookPage, businessOwnerId: id }));
		});
	}

	protected initFormGroup(): void {
		this.adSetSettingsFormGroup = this.formBuilder.group({
			adSetName: new FormControl(this.currentStep?.adSetName, Validators.required),
			pages: this.formBuilder.group({
				facebookId: new FormControl(this.currentStep?.facebookPageId, Validators.required),
				instagramId: new FormControl(this.currentStep?.instagramPageId)
			}),
			placements: new FormControl(this.currentStep?.placements, Validators.required),
			locations: new FormControl(''),
			adSetBudget: this.formBuilder.group({
				budgetType: new FormControl(this.currentStep?.budgetOptimization?.budgetAllocatedTypeId),
				amount: new FormControl(this.currentStep?.budgetOptimization?.amount)
			}),
			optimizationDelivery: this.formBuilder.group({
				optimizationGoal: new FormControl(),
				clicksAndViews: new FormControl(),
				billingEvents: new FormControl()
			})
		});
		this.isCatalogSales = this.campaign?.stepOneDetailsAsJson.objectiveGroupTree.subObjective === this.subObjectives.ProductCatalogSales;
		if (this.isCatalogSales && !this.adSetSettingsFormGroup.controls.hasOwnProperty('productCatalogSetFormControl')) {
			this.adSetSettingsFormGroup.addControl(
				'productCatalogSetFormControl',
				new FormControl(this.campaign?.stepTwoDetailsAsJson?.productSetId, Validators.required)
			);
			this.updateCatalogSetId();
		} else if (!this.isCatalogSales && this.adSetSettingsFormGroup.controls.hasOwnProperty('productCatalogSetFormControl')) {
			this.adSetSettingsFormGroup.removeControl('productCatalogSetFormControl');
			this.updateCatalogSetId();
		}
		if (this.isCatalogSales && this.adSetSettingsFormGroup.controls.hasOwnProperty('productCatalogSetFormControl')) {
			this.adSetSettingsFormGroup
				?.get('productCatalogSetFormControl')
				?.valueChanges.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					this.productSetId = res;
					this.updateCatalogSetId();
				});
		}
		if (this.isAppInstall && !this.adSetSettingsFormGroup.controls.hasOwnProperty('appStoreType')) {
			this.adSetSettingsFormGroup.addControl('appStoreType', new FormControl('', Validators.required));
			this.adSetSettingsFormGroup.addControl('appLinks', new FormControl('', Validators.required));
		} else if (!this.isAppInstall && this.adSetSettingsFormGroup.controls.hasOwnProperty('appStoreType')) {
			this.adSetSettingsFormGroup.removeControl('appStoreType');
			this.adSetSettingsFormGroup.removeControl('appLinks');
			this.store.dispatch(new UpdateAppStoreType(null));
		}
		if (this.isAppInstall && this.adSetSettingsFormGroup.controls.hasOwnProperty('appStoreType')) {
			this.adSetSettingsFormGroup
				?.get('appStoreType')
				?.valueChanges.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {
					this.store.dispatch(new UpdateAppStoreType(res));
				});
			this.adSetSettingsFormGroup.get('appStoreType').patchValue(this.campaign?.stepOneDetailsAsJson?.appStoreType ?? AppStoreTypeEnum.GooglePlayStore);
		}
		if (this.isAppInstall && this.adSetSettingsFormGroup.controls.hasOwnProperty('appLinks')) {
			this.adSetSettingsFormGroup
				?.get('appLinks')
				?.valueChanges.pipe(takeUntil(this.unsubscriber$))
				.subscribe(res => {});
			this.adSetSettingsFormGroup.get('appLinks').patchValue(this.campaign?.stepTwoDetailsAsJson?.appId ?? null);
		}
		this.checkQuickNavigation();
		this.adSetSettingsFormGroup
			.get('adSetName')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(name => {
				if (this.adSetSettingsFormGroup.get('adSetName').hasError('required')) {
					this.validationMessages.push({ validationType: 'required', validationMessage: 'Adset name required' });
				}
				if (name.length) {
					this.label = '';
				} else {
					this.label = 'Ad Set name';
				}
				this.store.dispatch(new UpdateAdSetName(name));
			});

		this.adSetSettingsFormGroup
			.get('pages')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(pages => {
				this.facebookPageId = pages.facebookId;
				this.store.dispatch(new UpdatePages({ facebookPageId: pages.facebookId, instagramPageId: pages.instagramId }));
			});

		this.adSetSettingsFormGroup
			.get('adSetBudget')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.subscribe(budget => {
				if (
					(budget.budgetType === BudgetAllocatedEnum.Daily || budget.budgetType === BudgetAllocatedEnum.LifeTime) &&
					!this.campaignBudgetOptimization
				) {
					this.isAdSetLifeTimeSelected = budget.budgetType === CampaignDateChoices.Default && !this.campaignBudgetOptimization;
					if (budget.budgetType === CampaignDateChoices.Default && !this.campaignDate.endDate) {
						this.setDefaultDate();
					}

					if (budget.budgetType === BudgetAllocatedEnum.LifeTime) {
						this.validBudget = Math.round(this.dateLength() * this.minBudget);
					} else {
						this.validBudget = this.minBudget;
					}

					this.adSetSettingsFormGroup
						.get('adSetBudget')
						.get('amount')
						.setValidators([Validators.required, Validators.min(this.validBudget)]);
					this.adSetSettingsFormGroup.get('adSetBudget').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });

					const budgetOptimization: CampaignBudgetOptimizationInterface = {
						amount: budget.amount,
						budgetAllocatedTypeId: budget.budgetType,
						minimumBudget: this.validBudget,
						bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
						deliveryType: 'standard'
					};
					this.store.dispatch(new UpdateAdSetBudgetOptimization(budgetOptimization));
				} else {
					this.store.dispatch(new UpdateAdSetBudgetOptimization(null));
					this.adSetSettingsFormGroup.get('adSetBudget').get('amount').clearValidators();
					this.adSetSettingsFormGroup.get('adSetBudget').get('amount').updateValueAndValidity({ onlySelf: false, emitEvent: false });
				}
			});

		this.adSetSettingsFormGroup
			.get('optimizationDelivery')
			.valueChanges.pipe(takeUntil(this.unsubscriber$))
			.pipe(debounceTime(500))
			.subscribe(data => {
				const index = data.clicksAndViews ? data.clicksAndViews.indexOf('/') : null;
				const click = data.clicksAndViews ? data.clicksAndViews.slice(0, index) : null;
				const view = data.clicksAndViews ? data.clicksAndViews.slice(index + 1, data.clicksAndViews.length) : null;
				const update: OptimizationAndDeliveryInterface = {
					optimizationGoal: data.optimizationGoal,
					click: click,
					view: view,
					billingEvent: data.billingEvents
				};
				this.store.dispatch(new UpdateOptimizationAndDelivery(update));
			});

		// used for validity check
		this.adSetSettingsFormGroup.valueChanges.pipe(takeUntil(this.unsubscriber$)).subscribe(data => {
			setTimeout(() => {
				this.isStepValid = this.adSetSettingsFormGroup.valid;
				if (this.invalidStep === CampaignStepsEnum.Two && this.isStepValid) {
					this.store.dispatch(new UpdateCampaignInvalidStep(null));
				}
			});
		});
	}

	protected initStoreListeners(): void {
		this.sharedStore
			.pipe(
				select(getSelectedAdAccount),
				skipWhile(account => !account || account.adAccount.id === this.selectedAdAccount.adAccount.id),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(account => {
				this.updateCampaign(false);
				this.router.navigate(['/ads-manager/campaign']);
			});
	}

	private updateCampaign(showErrorMessage = true): void {
		this.adSetSettingsFormGroup.markAllAsTouched();
		this.isStepValid = true;
		let shouldAnInvalidStepBeDispatched = false;
		if (this.adSetSettingsFormGroup.invalid) {
			if (showErrorMessage) {
				this.isStepValid = false;
				this.showErrorToast();
			} else {
				shouldAnInvalidStepBeDispatched = true;
				this.store.dispatch(new UpdateCampaignInvalidStep(CampaignStepsEnum.Two));
			}
		}

		this.store.pipe(select(getStepTwoDetails), take(1)).subscribe(details => {
			let numberOfLocationsChosen: number;
			if (details?.targeting?.retargeting) {
				numberOfLocationsChosen = 1;
			} else if (details?.targeting?.locations) {
				numberOfLocationsChosen = details.targeting?.locations?.length > 0 ? details.targeting?.locations?.length : 0;
			}

			if (showErrorMessage) {
				if (details?.placements?.length === 0) {
					if (showErrorMessage) {
						this.isStepValid = false;
						this.adSetSettingsFormGroup.markAllAsTouched();
					} else {
						shouldAnInvalidStepBeDispatched = true;
						this.store.dispatch(new UpdateCampaignInvalidStep(CampaignStepsEnum.Two));
					}
				} // Create new audience
				if (numberOfLocationsChosen === 0 && !this.targeting?.retargeting) {
					if (showErrorMessage) {
						this.adSetSettingsFormGroup.markAllAsTouched();
						this.showErrorToast();
						this.validationTrigger = true;
						this.isStepValid = false;
					} else {
						this.validationTrigger = false;
						shouldAnInvalidStepBeDispatched = true;
						this.store.dispatch(new UpdateCampaignInvalidStep(CampaignStepsEnum.Two));
					}
				}
				if (!this.isStepValid) {
					return;
				}
			}
			if (!shouldAnInvalidStepBeDispatched) {
				this.store.dispatch(new UpdateCampaignInvalidStep(null));
			}
			this.store.dispatch(new ClearCurrentAssets());
			if (this.facebookPageId) {
				this.loadAssets(this.facebookPageId);
			}
			this.store.dispatch(new UpdateCampaignTemplate());
			this.store.dispatch(new ClearCurrentStep());
		});
	}

	private showErrorToast(): void {
		for (const controlKey in this.adSetSettingsFormGroup.controls) {
			if (this.adSetSettingsFormGroup.get(controlKey).invalid) {
				const titleCaseName = Helper.pascalCaseToRegular(controlKey);
				const name = titleCaseName[0] + titleCaseName.substring(1).toLowerCase();
				this.toastService.sendErrorToast(`${name} is required.`);
				const target = document.querySelector(`${'.' + controlKey}`);
				target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
				break;
			}
		}
	}

	protected dispatchActions(): void {
		if (this.campaign?.stepTwoDetailsAsJson?.adSetName) {
			this.store.dispatch(new UpdateAdSetName(this.campaign.stepTwoDetailsAsJson.adSetName));
		}

		if (this.campaign?.stepTwoDetailsAsJson?.facebookPageId || this.campaign?.stepTwoDetailsAsJson?.instagramPageId) {
			this.store.dispatch(
				new UpdatePages({
					facebookPageId: this.campaign.stepTwoDetailsAsJson.facebookPageId,
					instagramPageId: this.campaign.stepTwoDetailsAsJson.instagramPageId
				})
			);
		}

		if (this.campaign?.stepTwoDetailsAsJson?.placements) {
			this.store.dispatch(new UpdatePlacements(this.campaign.stepTwoDetailsAsJson?.placements));
		}

		if (this.campaign?.stepTwoDetailsAsJson?.targeting) {
			let targeting = this.campaign?.stepTwoDetailsAsJson?.targeting ?? this.currentStep.targeting;
			const targetingValue = this.isAppInstall ? ParentTargetingTypeEnum.AutomatedTargeting : ParentTargetingTypeEnum.NewTarget;
			if (targeting && !targeting.hasOwnProperty('targeting_type')) {
				targeting = { ...targeting, targeting_type: targetingValue };
			}
			if (this.isAppInstall) {
				targeting = { ...targeting, targeting_type: targetingValue, ageRange: null };
			}
			this.store.dispatch(new UpdateTargeting(targeting));
		}

		if (this.campaign?.stepTwoDetailsAsJson?.date) {
			this.store.dispatch(
				new UpdateCampaignActiveDate({ date: this.campaign.stepTwoDetailsAsJson.date, dateChoiceType: this.campaign.stepTwoDetailsAsJson.dateChoice })
			);
		}
		if (this.campaign?.stepTwoDetailsAsJson?.budgetOptimization) {
			this.store.dispatch(new UpdateAdSetBudgetOptimization(this.campaign.stepTwoDetailsAsJson.budgetOptimization));
		}
		if (this.isAppInstall) {
			let targeting = this.currentStep.targeting;
			targeting = { ...targeting, targeting_type: ParentTargetingTypeEnum.AutomatedTargeting, ageRange: null };
			this.store.dispatch(new UpdateTargeting(targeting));
		}
	}

	public getCatalogId(): void {
		this.store.pipe(select(getCatalogId), takeUntil(this.unsubscriber$)).subscribe(response => {
			if (response) {
				this.productCatalogId = response;
				// this.getProductCatalogSets();
			}
		});
	}

	// private getProductCatalogSets(): void {
	// 	this.smartCreateService
	// 		.getCatalogSets(this.productCatalogId)
	// 		.pipe(takeUntil(this.unsubscriber$))
	// 		.subscribe(
	// 			res => {
	// 				if (res) {
	// 					this.productCatalogSets = res;
	// 					if (this.campaign?.stepTwoDetailsAsJson?.productSetId) {
	// 						this.adSetSettingsFormGroup.get('productCatalogSetFormControl').setValue(this.campaign?.stepTwoDetailsAsJson?.productSetId);
	// 					}
	// 				}
	// 			},
	// 			error => {}
	// 		);
	// }

	public updateCatalogSetId(): void {
		this.store.dispatch(new UpdateCatalogSetId(this.adSetSettingsFormGroup?.get('productCatalogSetFormControl')?.value ?? null));
	}

	public getRetargetingAudienceSpec(event: any): void {
		if (event) {
			const target: TargetingAudience = {
				targeting_type: 1,
				retargeting: event
			};
			this.updateTargeting(target);
		}
	}

	public updateSelectedApp(event: AdvertiseAppInterface): void {
		if (event) {
			this.store.dispatch(new UpdateAppSelection({ appId: event?.id, appLink: event?.link }));
		} else {
			this.store.dispatch(new UpdateAppSelection(null));
		}
	}
	private checkQuickNavigation(): void {
		this.navigations = [
			{ name: 'Ad Set Name', targetId: 'campaign-name' },
			{ name: 'Select Page', targetId: 'select-page' }
		];
		if (this.isCatalogSales) {
			this.navigations = [...this.navigations, { name: 'Choose Catalog Set', targetId: 'choose-catalog-set' }];
		}
		if (this.isAppInstall) {
			this.navigations = [...this.navigations, { name: 'Platform & App Selection', targetId: 'platform-selection' }];
		}
		this.navigations = [
			...this.navigations,
			{ name: 'Placements', targetId: 'placements' },
			{ name: 'Targeting', targetId: 'targetingid' },
			{ name: 'Ad Set Budget Optimization', targetId: 'adset-budget-optimization' },
			{ name: 'Date', targetId: 'date' }
		];
	}
	public findNavigationIndex(targetId: string): number {
		return this.navigations.findIndex(navigation => navigation.targetId === targetId);
	}
	private reactivateView(): void {
		let counter = 0;
		let interval = setInterval(fireClick, 1000);
		function fireClick() {
			if (counter == 100) {
				clearInterval(interval);
			} else {
				counter++;
				document.getElementById('scs-wrapper').click();
				const body = document.body;
				const scrollY = body.style.top;
				body.style.position = '';
				body.style.top = '';
				window.scrollTo(0, document.body.scrollHeight - 10);
				//window.scrollTo(0, parseInt(scrollY || '0') * -1);
				window.addEventListener('scroll', () => {
					document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
				});
			}
		}
	}
}
