<div class="welcome-section">
	<!--	<app-welcome-page [inputDetailModel]="welcomePageModel" (leftButtonPressed)="onSmartCreateClick()"></app-welcome-page>-->
	<div>
		<div class="welcome-title-header">
			<img src="assets/icons/campaign-smart-create/welcome/dexter-icon.svg" />
			<div>
				<font07>
					Let’s get creating!
					<div class="circles">
						<img src="assets/icons/campaign-smart-create/welcome/circle.svg" />
						<img src="assets/icons/campaign-smart-create/welcome/circle.svg" />
						<img src="assets/icons/campaign-smart-create/welcome/circle.svg" />
					</div>
				</font07>
				<p>It’s time to get building. Select one of the asset below that you would like to build today.</p>
			</div>
		</div>
		<div class="welcome-body-wrapper">
			<div class="welcome-body-header">
				<font07>{{ itemsTitle }}</font07>
			</div>
			<div class="items-wrapper" *ngIf="!stepAd">
				<div class="items-card">
					<div class="items-card-header">
						<p>Campaign</p>
					</div>
					<img src="assets/icons/campaign-smart-create/welcome/campaign-group-icon.svg" />
					<div class="items-card-footer">
						<btn07 (onClick)="onSmartCreateClick()">START CREATING</btn07>
					</div>
				</div>
				<div class="items-card">
					<div class="items-card-header">
						<p>Ad Set</p>
					</div>
					<img src="assets/icons/campaign-smart-create/welcome/adset-group-icon.svg" />
					<div class="items-card-footer">
						<btn07 (onClick)="nextStep('adSet')" class="padding-right">START CREATING</btn07>
					</div>
				</div>
				<div class="items-card">
					<div class="items-card-header">
						<p>Ads</p>
					</div>
					<img src="assets/icons/campaign-smart-create/welcome/ad-group-icon.svg" />
					<div class="items-card-footer">
						<btn07 (onClick)="nextStep('ad')">START CREATING</btn07>
					</div>
				</div>
			</div>
			<div class="step-ad items-wrapper" *ngIf="stepAd">
				<div class="item-mini-card {{ selectedCreateMethod == 'new' ? 'active' : '' }}" (click)="activateCard('new')">
					<div class="mini-card-text">
						<span>Create New {{ activeStep === 'ad' ? 'Ad' : 'Ad Sets' }}</span>
					</div>
					<div class="item-image">
						<img
							src="assets/icons/campaign-smart-create/welcome/{{ activeStep | lowercase }}-{{
								selectedCreateMethod == 'new' ? 'active' : 'default'
							}}.svg"
						/>
					</div>
				</div>
				<div class="item-mini-card {{ selectedCreateMethod == 'performing' ? 'active' : '' }}" (click)="activateCard('performing')">
					<div class="mini-card-text">
						<span>Use Best Performing</span>
					</div>
					<div class="item-image">
						<img
							src="assets/icons/campaign-smart-create/welcome/performing-{{ selectedCreateMethod == 'performing' ? 'active' : 'default' }}.svg"
						/>
					</div>
				</div>
			</div>
			<div class="welcome-body-footer" *ngIf="stepAd">
				<btn07 (onClick)="defaultPage()">BACK TO SELECTION</btn07>
				<btn07 (onClick)="startCreating(activeStep, selectedCreateMethod)" [disabled]="!selectedCreateMethod">NEXT</btn07>
			</div>
		</div>
	</div>
</div>
