import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CellClassParams, ColDef, IFilterComp } from 'ag-grid-community';
import { of, Subject } from 'rxjs';
import { debounceTime, skip, switchMap, take, takeUntil } from 'rxjs/operators';
import { AdsManagerHeaderCheckboxComponent } from 'src/app/ads-manager/ads-manager-insights/components-pieces/ads-manager-ag-grid-components/ads-manager-header-checkbox/ads-manager-header-checkbox.component';
import { TimeRangeInterface } from 'src/app/ads-manager/ads-manager-insights/models/time-range.interface';
import { AdSetsService } from 'src/app/ads-manager/ads-manager-insights/services/ad-sets.service';
import { AdsManagerHelperService } from 'src/app/ads-manager/ads-manager-insights/services/ads-manager-helper.service';
import { AdsManagerResolverDataInterface } from 'src/app/ads-manager/models/ads-manager-resolver-data.interface';
import { CustomFieldCellRendererComponent } from 'src/app/shared/ag-grid-custom-cell-renderers/custom-field-cell-renderer/custom-field-cell-renderer.component';
import { MasterTableRowSelectionEnum } from 'src/app/shared/master-table/master-table-row-selection.enum';
import { AgGridViewInterface } from 'src/app/shared/master-table/models/ag-grid-view.interface';
import { MasterTableGridReadyInterface } from 'src/app/shared/master-table/models/master-table-grid-ready.interface';
import { MasterTableImplementationsEnum } from 'src/app/shared/master-table/models/master-table-implementations.enum';
import { RowModelTypeEnum } from 'src/app/shared/master-table/models/row-model-type.enum';
import { StandardColorsEnum } from 'src/app/shared/models/standard-colors.enum';
import { getGlobalDate, getSelectedAdAccount, SharedState } from 'src/app/shared/state/shared.reducer';
import { AAAAdsetViewsEnum } from '../../models/AAA-adset-views.enum';
import { AAAFlow, stepName } from '../../models/AAA-steps.enum';
import { AdSetsAAAInterface } from '../../models/ad-sets-aaa.interface';
import { CampaignAAAInterface } from '../../models/campaign';
import { AaaHeaderFooterStateService } from '../../services/aaa-header-footer-state.service';
import { AAAModuleState, getObjectiveTree, getSelectedAdsets, getSelectedCampaigns } from '../../state';
import { UpdateSelectedAAAAdSetIds } from '../../state/aaa.actions';
import moment from 'moment';
import { setStepBasedOnFlow } from '../../helpers/helper';

@Component({
	selector: 'app-best-performing-adsets',
	templateUrl: './best-performing-adsets.component.html',
	styleUrls: ['./best-performing-adsets.component.scss']
})
export class BestPerformingAdsetsComponent implements OnInit {
	public adsManagerMasterTableImplementations = MasterTableImplementationsEnum;
	public adSetView: ColDef[];
	public adSetViews: AgGridViewInterface[];
	public frameworkComponents = {
		checkboxHeader: AdsManagerHeaderCheckboxComponent,
		customFieldCellRenderer: CustomFieldCellRendererComponent
	};
	public rowSelection = MasterTableRowSelectionEnum;
	public rowModelType = RowModelTypeEnum;
	public defaultPageSize = 25;
	public defaultFilterParams = {
		suppressAndOrCondition: true,
		buttons: ['reset', 'apply'],
		debounceMs: 200
	};
	public numberFilterOptions = ['lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'inRange'];
	public defaultColDef: ColDef = {
		minWidth: 100,
		flex: 1,
		sortable: true,
		resizable: true,
		lockPinned: true,
		menuTabs: [],
		filterParams: { ...this.defaultFilterParams },
		cellStyle: (params: CellClassParams) => {
			if (!params.colDef.pinned || params.colDef.editable) {
				return {
					'border-right': `1px solid ${StandardColorsEnum.FiledLighterGray}`
				};
			}
			return { border: '0' };
		}
	};
	public paginationOptions = [25, 50, 100, 200];
	public selectedAdSetIds: string[] = [];
	public selectedAdsets: any[];
	public searchFormControl: FormGroup;
	public selectedCampaignIds: string[] = [];
	public selectedCampaigns: any[];
	public initialAdSetFilters: (string | true | (new () => IFilterComp))[];
	private unsubscriber$ = new Subject<void>();
	public flow: AAAFlow;

	constructor(
		private store: Store<AAAModuleState>,
		public activatedRoute: ActivatedRoute,
		public router: Router,
		public adsManagerHelper: AdsManagerHelperService,
		private sharedStore: Store<SharedState>,
		public adSetsService: AdSetsService,
		private formBuilder: FormBuilder,
		private aaaStateService: AaaHeaderFooterStateService
	) {
		this.listenToRoute();
	}

	public ngOnInit(): void {
		this.initializeSubscriptions();
		this.getViews();
		this.aaaStateService.setHasEdit({ disabled: false, show: true });
		this.createForm();
		this.filteredData();
		this.registeringActions();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
		this.aaaStateService.setHasEdit({ disabled: false, show: false });
	}

	public refactorNumberFilterOptions(column: ColDef): void {
		if (column.filter === 'agNumberColumnFilter') {
			column.filterParams = {
				...this.defaultFilterParams,
				filterOptions: this.numberFilterOptions
			};
		}
	}

	public extractDefaultFilters(arr: ColDef[]): (string | true | (new () => IFilterComp))[] {
		return arr.map(item => item.filter || '');
	}

	public filteredData(): void {
		this.searchFormControl
			.get('searchAdSetForm')
			.valueChanges.pipe(debounceTime(500))
			.subscribe(value => {
				this.adSetsService.filterName = value;
				this.adSetsService.resetRequest();
			});
	}

	private createForm(): void {
		this.searchFormControl = this.formBuilder.group({
			searchAdSetForm: new FormControl(null)
		});
	}

	public getViews(): void {
		this.activatedRoute.data.pipe(take(1)).subscribe((obj: AdsManagerResolverDataInterface) => {
			const adsetsColIds = ['selected', 'adset_name', 'impressions', 'results', 'cost_per_result', 'cost_per_unique_click_all', 'unique_ctr_all'];
			this.adSetViews = obj.views.adSetViews;
			this.adSetView = this.adSetViews.find(adSetView => adSetView.isDefault).columns;
			this.adSetView = this.adSetView.filter(adSetView => adsetsColIds.includes(adSetView.colId));
			this.initialAdSetFilters = this.extractDefaultFilters(this.adSetView);
			this.adSetView.forEach(adSet => {
				switch (adSet.colId) {
					case AAAAdsetViewsEnum.campaign_name:
						adSet.pinned = null;
						adSet.pinnedRowCellRenderer = null;
						adSet.cellRenderer = null;
						break;
					case AAAAdsetViewsEnum.adset_name:
						adSet.pinned = null;
						adSet.pinnedRowCellRenderer = null;
						adSet.cellRenderer = null;
						break;
					case AAAAdsetViewsEnum.cost_per_result:
						adSet.headerName = 'CPR';
						break;
					case AAAAdsetViewsEnum.cost_per_unique_click_all:
						adSet.headerName = 'CPC';
						break;
					case AAAAdsetViewsEnum.unique_ctr_all:
						adSet.headerName = 'CTR';
						break;
				}
			});
			this.adSetView.forEach((adSet, index) => {
				if (adSet.sortable === true) {
					adSet.headerTooltip = '';
					adSet.tooltipField = adSet.field;
				}

				adSet.filter = this.initialAdSetFilters[index] || false;
				this.refactorNumberFilterOptions(adSet);
				adSet.suppressMenu = false;
			});
			const toolPanelClassHidden = 'u-hidden';
			this.adSetView[0].toolPanelClass = toolPanelClassHidden;
		});
	}

	public onGridReady(masterTableReady: MasterTableGridReadyInterface): void {
		if (masterTableReady.masterTableImplementation === this.adsManagerMasterTableImplementations.AdsManagerAdSetTable) {
			this.adSetsService.hasDelivery = true;
			this.adSetsService.gridApi = masterTableReady.gridApi;
			this.adSetsService.columnApi = masterTableReady.columnApi;
			const sortModel = [{ colId: 'impressions', sort: 'desc' }];
			this.adSetsService.gridApi.setSortModel(sortModel);
		}
	}

	private initializeSubscriptions(): void {
		this.sharedStore.pipe(select(getSelectedAdAccount), takeUntil(this.unsubscriber$)).subscribe(data => {
			this.adSetsService.selectedAccount = data.adAccount.id;
			this.adSetsService.selectedCampaignIds = [];
			this.adSetsService.innerStoreAction = true;
		});

		this.sharedStore.pipe(select(getSelectedAdAccount), skip(1), takeUntil(this.unsubscriber$)).subscribe(data => {
			this.adSetsService.resetRequest();
		});

		this.store
			.pipe(
				select(getSelectedAdsets),
				debounceTime(50),
				takeUntil(this.unsubscriber$),
				switchMap((selectedAdSets: AdSetsAAAInterface[]) => {
					return of(selectedAdSets);
				})
			)
			.subscribe((selectedAdSets: AdSetsAAAInterface[]) => {
				this.selectedAdsets = selectedAdSets;
				if (selectedAdSets && selectedAdSets.length !== 0) {
					this.selectedAdSetIds = selectedAdSets.map(adSetRow => adSetRow.adset_id);
					this.store.dispatch(new UpdateSelectedAAAAdSetIds(this.selectedAdSetIds));
					this.adSetsService.selectedAdSetsIds = this.selectedAdSetIds;
				} else {
					this.adSetsService.deselectAll();
					this.adSetsService.selectedAdSetsIds = [];
					this.selectedAdSetIds = [];
					this.store.dispatch(new UpdateSelectedAAAAdSetIds(null));
				}
			});

		this.store.pipe(select(getObjectiveTree), takeUntil(this.unsubscriber$)).subscribe(objective => {
			this.adSetsService.filterObjective = objective.subObjective;
		});

		this.sharedStore.pipe(select(getGlobalDate), takeUntil(this.unsubscriber$)).subscribe(date => {
			const newTimeRange: TimeRangeInterface = {
				since: moment().subtract(6, 'days').format('YYYY-MM-DD'),
				until: moment().format('YYYY-MM-DD')
			};
			this.adSetsService.timeRange = newTimeRange;
		});
	}

	public registeringActions() {
		this.aaaStateService
			.onNext()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.aaaStateService.setSkips([this.aaaStateService.activeStep.value + 1, this.aaaStateService.activeStep.value + 2]);
				this.aaaStateService.sendNavigateForward(this.aaaStateService.activeStep.value + 3);
			});
		this.aaaStateService
			.onSave()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {});
		this.aaaStateService
			.onEdit()
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.aaaStateService.setSkips([]);
				this.aaaStateService.editSelectedAds.next(true);
				this.aaaStateService.sendNavigateForward();
			});
	}

	public setCurrentStepBasedOnFlow(): void {
		setStepBasedOnFlow(this, this.flow, this.setCurrentStep, stepName.SelectAdset);
	}

	public listenToRoute(): void {
		this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscriber$)).subscribe(params => {
			this.flow = +params['flow'];
			this.setCurrentStepBasedOnFlow();
		});
	}
	public setCurrentStep(step: number): void {
		this.aaaStateService.sendActiveStep(step);
		this.aaaStateService.sendCampaignStep(step);
		this.aaaStateService.onPreviewAd(false);
	}
}
