import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-input-with-emoji',
	templateUrl: './input-with-emoji.component.html',
	styleUrls: ['./input-with-emoji.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputWithEmojiComponent),
			multi: true
		}
	]
})
export class InputWithEmojiComponent implements OnInit, ControlValueAccessor {
	@Input() emoji: boolean;
	@Input() personalize: boolean;
	@Input() addImage: boolean;
	@Input() addVideo: boolean;
	@Input() placeholderText: number;
	disabled = false;

	value = '';

	onChange: any = () => {};
	onTouched: any = () => {};

	constructor() {}

	ngOnInit(): void {}

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
