<div [matMenuTriggerFor]="menuDelete" class="menu-center">
	<img class="dots" src="assets/icons/user-management/account/dots2.svg" />
	<mat-menu #menuDelete="matMenu" class="custom_mat_menu">
		<button (click)="edit()" mat-menu-item *appCheckPermissions="permissionModelEditOnly">
			<img class="edit" src="../../../../../assets/icons/user-management/account/table-action-edit.svg" />
			Edit
		</button>
		<button (click)="delete()" mat-menu-item *appCheckPermissions="permissionModelFull">
			<img class="delete" src="assets/icons/user-management/account/table-action-delete.svg" />
			Delete
		</button>
	</mat-menu>
</div>
