import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';

export class CreditCardStaticVariables {
	public cardHolderMinChars = 5;
	public addressMinLength = 5;
	public cvcMinLength = 3;
	public cityMinLength = 3;
	public zipMinLength = 3;
	public cardNumberMinLength = 13;
	public companyMinLength = 3;
	public validationMessages = new Map<string, ValidatorMessages[]>([
		[
			'cardHolder',
			[
				{
					validationType: 'required',
					validationMessage: 'Card holder name required'
				},
				{
					validationType: 'pattern',
					validationMessage: 'Card holder name must contain only letters'
				},
				{
					validationType: 'minlength',
					validationMessage: `Card holder name should have at least ${this.cardHolderMinChars} characters`
				}
			]
		],
		[
			'cardNumber',
			[
				{
					validationType: 'required',
					validationMessage: 'Card number is required'
				},
				{
					validationType: 'minlength',
					validationMessage: `Card number should be at least ${this.cardNumberMinLength} digits long`
				}
			]
		],
		[
			'address1',
			[
				{
					validationType: 'required',
					validationMessage: 'Address is required'
				},
				{
					validationType: 'minlength',
					validationMessage: `Address should be at least ${this.addressMinLength} characters long`
				}
			]
		],
		[
			'city',
			[
				{
					validationType: 'required',
					validationMessage: 'City is required'
				},
				{
					validationType: 'minlength',
					validationMessage: `City should be at least ${this.cityMinLength} characters long`
				}
			]
		],
		[
			'zip',
			[
				{
					validationType: 'required',
					validationMessage: 'Zip is required'
				},
				{
					validationType: 'minlength',
					validationMessage: `Zip should be at least ${this.zipMinLength} characters long`
				}
			]
		],
		[
			'expirationDate',
			[
				{
					validationType: 'required',
					validationMessage: 'This field is required'
				},
				{
					validationType: 'month',
					validationMessage: `Month should be between 01 to 12`
				},
				{
					validationType: 'year',
					validationMessage: `Year cannot be less than current year`
				},
				{
					validationType: 'expired',
					validationMessage: `Your card has expired.`
				}
			]
		]
	]);
}
