import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
import { Options } from 'ng5-slider';

@Component({
	selector: 'app-optimization-placements-card',
	templateUrl: './optimization-placements-card.component.html',
	styleUrls: ['./optimization-placements-card.component.scss']
})
export class OptimizationPlacementsCardComponent implements OnInit {
	@Input() isSelected: boolean;
	@Output() onExpansionClick = new EventEmitter();

	public infoHoverModel: InfoHoverDataModel;
	public numberOfPlacements = 1;
	// needs to be synced with store
	public isOptimizationPlacementsToggle = false;
	public options: Options = {
		floor: 1,
		ceil: 3
	};
	constructor() {}

	ngOnInit(): void {
		this.infoHoverModel = {
			message: 'You can move the placements around by clicking on each one and dragging and dropping between them.'
		};
	}

	public onOptimizationPlacementsToggle(): void {
		this.isOptimizationPlacementsToggle = !this.isOptimizationPlacementsToggle;
	}
}
