import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CampaignSmartCreateMapperService } from 'src/app/campaign-smart-create/services/campaign-smart-create-mapper.service';
import { AssetService } from 'src/app/shared/services/assets/asset.service';
import { getPagePosts } from '.';
import { AAAAssetsActionTypes, LoadPagePosts, LoadPagePostsFailure, LoadPagePostsSuccess } from './aaa-assets.actions';
import { AAAAssetsState } from './aaa-assets.reducer';

@Injectable()
export class AAAAssetsEffects {
	constructor(
		private actions: Actions,
		private assetService: AssetService,
		private campaignMappingService: CampaignSmartCreateMapperService,
		private store: Store<AAAAssetsState>
	) {}

	// public loadPagePosts$ = createEffect(() =>
	// 	this.actions.pipe(
	// 		ofType<LoadPagePosts>(AAAAssetsActionTypes.LoadPagePosts),
	// 		switchMap(actions =>
	// 			of(actions).pipe(
	// 				withLatestFrom(this.store.pipe(select(getPagePosts))),
	// 				map(([action, images]) => action.payload),
	// 				exhaustMap(payload => {
	// 					return this.assetService.getAssetPagePosts(payload.adAccountId, payload.businessOwnerId).pipe(
	// 						map(assets => {
	// 							const mappedAssets = this.campaignMappingService.mapFacebookAssetToAssetPickerModel(assets);
	// 							return new LoadPagePostsSuccess(mappedAssets);
	// 						}),
	// 						catchError(() => of(new LoadPagePostsFailure()))
	// 					);
	// 				})
	// 			)
	// 		)
	// 	)
	// );
}
