/**
 * Split line
 */
export class SplitLine {
	/**
	 * Show
	 */
	show: boolean;

	/**
	 * Constructor
	 *
	 * @param show
	 */
	constructor(show: boolean) {
		this.show = show;
	}
}
