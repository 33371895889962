import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PixelServiceApi } from '../../../../_services/pixel/pixel.api.service';
import { SelectedAdAccountModel } from '../../../state/state-entities/cached-ad-accounts.model';
import { PixelsDto } from '../../../../pixel/_models/pixels-dto.model';
import { MapperService } from '../../../services/mapper.service';
import { SelectType } from '../../../dropdown-search-select/dropdown-type.enum';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { EventDtoInterface } from '../../../../pixel/_models/event-dto.interface';
import { Subject } from 'rxjs';
import { DropdownData } from '../../../dropdown-search-select/dropdown-data.interface';
import { DropdownColumnInterface } from '../../live-search-dropdown/dropdown-columns.interface';
import { ConversionDropdownConfig } from './conversion-config';
import { AppEventsInterface } from '../../../../campaign-smart-create/models/app-events-interface';
import { SubObjectives } from '../../../../campaign-smart-create/models/sub-objectives';
import { ConversionStatus } from './conversion-status';
import { ConversionInterface } from '../../../../campaign-smart-create/models/conversion-interface';
import { InfoHoverDataModel } from 'src/app/shared/info/info-hover/info-hover-data.model';
import { ObjectivesEnum } from 'src/app/campaign-smart-create/models/objectives.enum';
import { Store } from '@ngrx/store';
import { getFacebookPixels } from '../../../../state/campaign-common-state/campaign-common-reducer';

@Component({
	selector: 'app-pixel-card',
	templateUrl: './pixel-card.component.html',
	styleUrls: ['./pixel-card.component.scss']
})
export class PixelCardComponent implements OnInit, OnDestroy {
	@Input() public isPanelSelected: boolean;
	@Input() public selectedAdAccount: SelectedAdAccountModel;
	@Input() public pixelControl: FormControl | AbstractControl;
	@Input() public pixelEventControl: FormControl | AbstractControl;
	@Input() public conversionControl: FormControl | AbstractControl;
	@Input() public subObjective: string;
	@Input() public pixelId: string;
	@Input() public pixelEventId: string;
	@Input() public isPixelMandatory: boolean;
	@Input() public conversion: ConversionInterface;
	@Input() public firstTime: boolean;

	@Output() public onExpansionClick = new EventEmitter<void>();
	@Output() public sendEvents = new EventEmitter<EventDtoInterface[]>();
	@Output() public updateConversion = new EventEmitter<DropdownData<EventDtoInterface>>();

	public subObjectives = SubObjectives;
	public pixelToggle = false;
	public pixel_id: string = '';
	public selectType = SelectType;
	public conversionColumns: DropdownColumnInterface[] = ConversionDropdownConfig.conversionColumns;
	public eventsToggle: boolean;
	public conversionToggle: boolean;
	public pixels: DropdownData<PixelsDto>[] = [];
	public events: DropdownData<AppEventsInterface>[];
	public selectedEvents: DropdownData<EventDtoInterface>[];
	public conversionEvents: DropdownData<EventDtoInterface>[];
	public selectedConversion: DropdownData<EventDtoInterface>[];
	public isConversionObjective: boolean = false;
	public label: string;

	public info: InfoHoverDataModel = { message: `Add events to your app to view analytics, measure ad performance and build audiences for ad targeting.` };

	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private pixelServiceApi: PixelServiceApi, private mapperService: MapperService, private store: Store) {}

	public ngOnInit(): void {
		this.isConversionObjective = this.subObjective === ObjectivesEnum?.Conversions;
		this.takeAllPixels();
		this.validationSet();
		// this.takeAppEvents();
	}

	public ngOnChanges(): void {
		this.isPixelMandatory = this.subObjective === ObjectivesEnum?.Conversions;
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public takeAllPixels(): void {
		this.store.select(getFacebookPixels).subscribe(res => {
			this.pixels = res;
			this.checkLastUsedPixel();
		});
		if (!this.isConversionObjective) {
			this.updateConversion.emit(null);
			this.pixelToggle = false;
		} else {
			if (this.pixelEventId) {
				this.eventsToggle = true;
			}
			if (this.pixelId) {
				this.pixelToggle = true;
			}
			this.getConversion();
			this.isConversionObjective = true;
		}

		if (this.subObjective !== SubObjectives.Conversion) {
			this.updateConversion.emit(null);
		}
		const selectedAdAccountId = parseInt(this.selectedAdAccount.adAccount.id.split('_')[1]);
		// this.pixelServiceApi
		// 	.getPixelEventsForAdAccount(selectedAdAccountId)
		// 	.pipe(take(1))
		// 	.subscribe(pixels => {
		// 		if (pixels) {
		// 			this.pixels = this.mapperService.convertToDropdownData(pixels, 'id', 'name');
		// 			this.checkLastUsedPixel();
		// 		}
		// 	});
	}

	// public takeAppEvents(): void {
	// 	this.pixelServiceApi.getAppEvents(this.selectedAdAccount.adAccount.id).subscribe(events => {
	// 		this.events = this.mapperService.convertToDropdownData(events, 'id', 'name');
	// 	});
	// }

	public onPixelToggle(): void {
		this.pixelToggle = !this.pixelToggle;
		if (this.pixelToggle) {
			this.pixelControl.setValidators(Validators.required);
			this.checkLastUsedPixel();
		} else {
			this.pixelEventControl.reset();
			this.pixelControl.reset();
			this.pixelControl.clearValidators();
			this.pixelControl.updateValueAndValidity();
			if (this.conversionToggle) {
				this.unselect();
				this.conversionToggle = false;
			}
		}
	}

	public onAppEventsToggle(): void {
		this.eventsToggle = !this.eventsToggle;
		if (this.eventsToggle) {
			this.pixelEventControl.setValidators(Validators.required);
			this.pixelEventControl.patchValue(this.events[0].value);
		} else {
			this.pixelEventControl.reset();
			this.pixelEventControl.clearValidators();
		}
	}

	public getConversion(): void {
		if (!this.pixelId) {
			return;
		}
		// this.pixelServiceApi.getConversionEvents(this.pixelId).subscribe(response => {
		// 	this.conversionEvents = this.mapperService.convertToDropdownData(response, 'id', 'name');
		// 	this.conversionEvents.forEach(item => {
		// 		if (item.data.statusId === 1) {
		// 			item.data.statusDescription = ConversionStatus.Active;
		// 		} else if (item.data.statusId === 0) {
		// 			item.data.statusDescription = ConversionStatus.Inactive;
		// 		} else if (item.data.statusId === 2) {
		// 			item.data.statusDescription = ConversionStatus.Deleted;
		// 		}
		// 	});
		// 	if (this.conversion) {
		// 		this.conversionToggle = true;
		// 		this.selectedConversion = this.conversionEvents.filter(item => item.data.id === this.conversion.id);
		// 		this.label = this.selectedConversion[0]?.displayName;
		// 		this.updateConversion.emit(this.selectedConversion[0]);
		// 	} else {
		// 		this.updateConversion.emit(this.conversionEvents[0]);
		// 		this.label = this.conversionEvents[0]?.displayName;
		// 		this.conversionControl.clearValidators();
		// 		this.conversionControl.updateValueAndValidity();
		// 	}
		// 	this.conversionEvents = this.conversionEvents.filter(item => item.data.statusDescription !== ConversionStatus.Deleted);
		// 	this.conversionEvents.sort((a, b) => {
		// 		return b.data.statusId - a.data.statusId;
		// 	});
		// });
	}

	public showSelected(selected: DropdownData[]): void {
		this.updateConversion.emit(selected[0]);
		this.label = selected[0].displayName;
	}

	public unselect(): void {
		this.updateConversion.emit(null);
		this.label = '';
	}

	public checkLastUsedPixel(): void {
		const sortedPixelsByDate = this.pixels?.sort((a, b) => b.data.activity.localeCompare(a.data.activity));
		const lastPixelUsed = sortedPixelsByDate[0]?.data.id;
		if (this.pixelId) {
			this.pixelToggle = true;
			this.pixelControl.patchValue(this.pixelId);
		}
		if (!this.pixelId && this.firstTime && lastPixelUsed) {
			this.pixelToggle = true;
			this.pixelControl.patchValue(lastPixelUsed);
			this.pixelId = lastPixelUsed;
		}
		if (this.subObjective === SubObjectives?.Conversion) {
			this.getConversion();
		}
	}

	public updateChanges(): void {
		this.unselect();
		this.conversion = null;
		if (this.pixelControl.value) {
			this.pixelId = this.pixelControl.value;
			if (this.subObjective === SubObjectives?.Conversion) {
				this.getConversion();
			}
		} else {
			this.pixelId = null;
		}
		this.updateConversion.emit(null);
		this.validationSet();
	}

	public validationSet(): void {
		if (this.pixelToggle) {
			this.pixelControl.setValidators(Validators.required);
		} else {
			this.pixelControl.clearValidators();
			this.pixelControl.updateValueAndValidity();
		}
		if (this.eventsToggle) {
			this.pixelEventControl.setValidators(Validators.required);
		} else {
			this.pixelEventControl.clearValidators();
			this.pixelEventControl.updateValueAndValidity();
		}
		if (this.subObjective === this.subObjectives?.Conversion && !this.pixelId) {
			this.conversionControl.setValidators(Validators.required);
		} else {
			this.conversionControl.clearValidators();
			this.conversionControl.updateValueAndValidity();
		}
	}
}
