import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownInterface } from '@filed-com/filed-lib/lib/dropdowns/models/dropdown-interface';
import { select, Store } from '@ngrx/store';
import { EMPTY, Subject } from 'rxjs';
import { catchError, skipWhile, take, takeUntil } from 'rxjs/operators';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { IKBSearchResult, ITicketResponse } from 'src/app/shared/models/knowledgebase.interface';
import { KnowledgebaseService } from 'src/app/shared/services/knowledgebase.service';
import { ToastNotificationService } from 'src/app/shared/toast-notification/toast-notification.service';
import { getUserProfileUserManagement, UserManagementState } from 'src/app/user-management/state/user-management.reducer';

@Component({
	selector: 'app-view-tickets',
	templateUrl: './view-ticket.component.html',
	styleUrls: ['./view-ticket.component.scss']
})
export class ViewTicketComponent implements OnInit {
	private unsubscriber$ = new Subject<void>();
	public loading = false;
	tickets: ITicketResponse[] = [];

	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private store: Store<UserManagementState>,
		public kbService: KnowledgebaseService,
		private toastNotificationService: ToastNotificationService
	) {}

	ngOnInit(): void {
		this.loading = true;
		this.store
			.pipe(
				select(getUserProfileUserManagement),
				skipWhile(user => !user),
				takeUntil(this.unsubscriber$)
			)
			.subscribe(
				user => {
					this.kbService
						.getUserTickets(user?.email)
						.pipe(
							takeUntil(this.unsubscriber$),
							catchError(error => {
								this.toastNotificationService.sendErrorToast('There was an error loading your support tickets, please try again.');
								this.loading = false;
								return EMPTY;
							})
						)
						.subscribe(tickets => {
							if (tickets && tickets.length > 0) {
								this.tickets = tickets.sort((a, b) => {
									return (
										parseInt((new Date(b.created_date).getTime() / 1000).toFixed(0)) -
										parseInt((new Date(a.created_date).getTime() / 1000).toFixed(0))
									);
								});
							}
							this.loading = false;
						});
				},
				error => {
					this.loading = false;
				}
			);
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public shortenDescription(description: string): string {
		const strTake = description.length <= 200 ? description : `${description.substring(0, 200)}...`;
		return strTake;
	}
}
