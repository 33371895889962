import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Modules } from '../enums/modules';
import { take } from 'rxjs/operators';
import { PermissionsService } from '../permissions.service';
import { select, Store } from '@ngrx/store';
import { getUserDetails, UserState } from '../../state/user/user.reducer';
import { AudiencePermissions } from '../enums/audience-permissions';

@Injectable({
	providedIn: 'root'
})
export class AudienceGuard implements CanActivate {
	public moduleName = Modules.Audience;

	constructor(private permissionsService: PermissionsService, private userStore: Store<UserState>) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		let isAllowed = false;
		this.userStore.pipe(select(getUserDetails), take(1)).subscribe(data => {
			data.Permissions.filter(moduleData => moduleData.module === Modules.Audience).forEach(module => {
				module.permissions.forEach(permission => {
					if (permission === AudiencePermissions.CanAccessAudience) {
						isAllowed = true;
					}
				});
			});
		});
		return of(isAllowed);
	}
}
