/**
 * Component registry
 *
 * Service used to register and get dynamic components
 */
import { Component, Injectable } from '@angular/core';

@Injectable()
export class ComponentRegistryService {
	private components: any = {};

	register(type: string, component: any) {
		this.components[type] = component;
	}
	get(type: string): Component {
		if (!this.components[type]) {
			console.log(`Filed-Debug: component "${type}" not known`);
		}

		return this.components[type];
	}
}
