<app-expansion-panel-sc
	[expansionTitle]="'Split by Location'"
	[isExpandable]="false"
	[isSelected]="isSelected"
	[contentMargin]="true"
	[hasToggle]="true"
	(click)="onExpansionClick.emit()"
>
	<div expansion-content class="expansion-input">
		<mat-slide-toggle (change)="onLocationToggle()" [formControl]="control" [checked]="isSplitLocationToggled">
			<span>Would you like to split your Campaign by location?</span>
		</mat-slide-toggle>
	</div>
</app-expansion-panel-sc>
