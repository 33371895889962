import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdAccountApiService } from '../../../_services/facebook-accounts/ad-account-api.service';
import { catchError, exhaustMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators/filter';
import { map } from 'rxjs/operators/map';
import {
	LoadCachedAdAccountsFailure,
	LoadCachedAdAccountsSuccess,
	LoadCachedAds,
	LoadCachedAdSets,
	LoadCachedAdSetsSuccess,
	LoadCachedCampaigns,
	LoadCachedCampaignsSuccess,
	LoadFacebookAdAccounts,
	LoadGoogleAdAccounts,
	SharedActionTypes
} from '../shared.actions';
import { facebookAdAccountsLoaded, googleAdAccountsLoaded, isInsightDataLoaded, SharedState } from '../shared.reducer';
import { CachedAdAccountsModel } from '../state-entities/cached-ad-accounts.model';
import { SourceChannel } from '../../../sidenav/sidenav/sidenav-channel-buttons.enum';
import { AppStateSlices } from '../../../state/app.state';
import { of, EMPTY } from 'rxjs';
import { ErrorsLoggingService } from '../../../_services/errors-logging/errors-logging.service';
import { AdAccountEffectsEnum } from './effects.enum';
import { ReportMetadataService } from '../../services/report-metadata.service';
import { ToastNotificationService } from '../../toast-notification/toast-notification.service';
import { GoogleMetadataService } from '../../services/google-metadata.service';
import { FacebookMetadataService } from '../../services/facebook-metadata.service';
import { InsightsLevelEnum } from '../../services/general-settings/models/insights-level.enum';
import { AdSetsSelectorInterface } from '../../../reports/shared/models/ad-sets-selector.interface';
import { AdsSelectorInterface } from '../../../reports/shared/models/ads-selector.interface';

@Injectable()
export class AdAccountEffects {
	private channelMetadataService: FacebookMetadataService | GoogleMetadataService;

	constructor(
		private errorsLoggingService: ErrorsLoggingService,
		private actions$: Actions,
		private adAccountService: AdAccountApiService,
		private store: Store<SharedState>,
		private toastNotificationService: ToastNotificationService,
		private reportMetadataService: ReportMetadataService
	) {}

	loadFacebookCachedAdAccounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadFacebookAdAccounts>(SharedActionTypes.LoadFacebookAdAccounts),
			withLatestFrom(this.store.pipe(select(facebookAdAccountsLoaded))),
			filter(([action, adAccountsLoaded]) => {
				return !adAccountsLoaded || action.payload;
			}),
			exhaustMap(([action, adAccountsLoaded]) =>
				this.adAccountService.getAllAdAccounts().pipe(
					map(adAccounts => {
						const result: CachedAdAccountsModel = {
							sourceChannel: SourceChannel.Facebook,
							adAccounts: adAccounts
						};
						return new LoadCachedAdAccountsSuccess(result);
					}),
					catchError(error => {
						this.errorsLoggingService.logEffectError(
							AppStateSlices.Shared,
							AdAccountEffectsEnum.LoadFacebookCachedAdAccounts,
							[action, adAccountsLoaded],
							error
						);
						return of(new LoadCachedAdAccountsFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
					})
				)
			)
		)
	);

	loadGoogleAdAccounts$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadGoogleAdAccounts>(SharedActionTypes.LoadGoogleAdAccounts),
			withLatestFrom(this.store.pipe(select(googleAdAccountsLoaded))),
			filter(([, adAccountsLoaded]) => !adAccountsLoaded),
			exhaustMap(([action, adAccountsLoaded]) =>
				this.adAccountService.getAllGoogleAccounts().pipe(
					map(adAccounts => {
						const result: CachedAdAccountsModel = {
							sourceChannel: SourceChannel.Google,
							adAccounts: adAccounts
						};
						return new LoadCachedAdAccountsSuccess(result);
					}),
					catchError(error => {
						this.errorsLoggingService.logEffectError(
							AppStateSlices.Shared,
							AdAccountEffectsEnum.LoadGoogleAdAccounts,
							[action, adAccountsLoaded],
							error
						);
						return of(new LoadCachedAdAccountsFailure(this.errorsLoggingService.getCodeFromHttpError(error)));
					})
				)
			)
		)
	);

	loadCampaigns$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadCachedCampaigns>(SharedActionTypes.LoadCachedCampaigns),
			map(action => action.payload),
			mergeMap(
				payload =>
					of(payload).pipe(
						withLatestFrom(
							this.store.pipe(
								select(isInsightDataLoaded(payload.sourceChannel, payload.filterKeys, payload.adAccountId, InsightsLevelEnum.Campaigns))
							)
						)
					),
				(payload, latestStoreData) => [payload, latestStoreData]
			),
			exhaustMap(([payload, latestStoreData]: [any, any]) => {
				if (latestStoreData[1]) {
					return EMPTY;
				}
				this.channelMetadataService = this.reportMetadataService.getSelectedMetadataService(latestStoreData[0].sourceChannel);
				return this.channelMetadataService.getStructures(latestStoreData[0].reportLevel, latestStoreData[0].adAccountId).pipe(
					map(campaigns => {
						return new LoadCachedCampaignsSuccess({ selector: payload, insights: campaigns });
					}),
					catchError(error => {
						this.toastNotificationService.sendErrorToast(this.errorsLoggingService.getCodeFromHttpError(error));
						this.errorsLoggingService.logEffectError(AppStateSlices.Shared, AdAccountEffectsEnum.LoadCampaigns, [payload, latestStoreData], error);
						return EMPTY;
					})
				);
			})
		)
	);

	loadAdSets$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadCachedAdSets>(SharedActionTypes.LoadCachedAdSets),
			map(action => action.payload),
			mergeMap(
				payload =>
					of(payload).pipe(
						withLatestFrom(
							this.store.pipe(
								select(isInsightDataLoaded(payload.sourceChannel, payload.filterKeys, payload.adAccountId, InsightsLevelEnum.AdSets))
							)
						)
					),
				(payload, latestStoreData) => [payload, latestStoreData]
			),
			exhaustMap(([payload, latestStoreData]: [AdSetsSelectorInterface, any]) => {
				if (latestStoreData[1]) {
					return EMPTY;
				}
				this.channelMetadataService = this.reportMetadataService.getSelectedMetadataService(latestStoreData[0].sourceChannel);
				return this.channelMetadataService
					.getFilteredStructuresByCampaignIds(latestStoreData[0].reportLevel, payload.adSetIds, latestStoreData[0].adAccountId)
					.pipe(
						map(adSets => {
							return new LoadCachedAdSetsSuccess({ selector: payload, insights: adSets });
						}),
						catchError(error => {
							this.toastNotificationService.sendErrorToast(this.errorsLoggingService.getCodeFromHttpError(error));
							this.errorsLoggingService.logEffectError(AppStateSlices.Shared, AdAccountEffectsEnum.LoadAdSets, [payload, latestStoreData], error);
							return EMPTY;
						})
					);
			})
		)
	);

	loadAds$ = createEffect(() =>
		this.actions$.pipe(
			ofType<LoadCachedAds>(SharedActionTypes.LoadCachedAds),
			map(action => action.payload),
			mergeMap(
				payload =>
					of(payload).pipe(
						withLatestFrom(
							this.store.pipe(select(isInsightDataLoaded(payload.sourceChannel, payload.filterKeys, payload.adAccountId, InsightsLevelEnum.Ads)))
						)
					),
				(payload, latestStoreData) => [payload, latestStoreData]
			),
			exhaustMap(([payload, latestStoreData]: [AdsSelectorInterface, any]) => {
				if (latestStoreData[1]) {
					return EMPTY;
				}
				this.channelMetadataService = this.reportMetadataService.getSelectedMetadataService(latestStoreData[0].sourceChannel);
				return this.channelMetadataService
					.getFilteredStructuresByCampaignIds(latestStoreData[0].reportLevel, payload.adIds, latestStoreData[0].adAccountId)
					.pipe(
						map(ads => {
							return new LoadCachedAdSetsSuccess({ selector: payload, insights: ads });
						}),
						catchError(error => {
							this.toastNotificationService.sendErrorToast(this.errorsLoggingService.getCodeFromHttpError(error));
							this.errorsLoggingService.logEffectError(AppStateSlices.Shared, AdAccountEffectsEnum.LoadAds, [payload, latestStoreData], error);
							return EMPTY;
						})
					);
			})
		)
	);
}
