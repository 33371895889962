<form *ngIf="adAccountPermissionForms" [formGroup]="adAccountPermissionForms">
	<app-dropdown-search-select
		*ngIf="radioValue !== 1"
		[dropdownData]="adAccounts"
		[dropdownFormControl]="adAccountPermissionForms.get('addAccount')"
		[dropdownPlaceholder]="'Search...'"
		[validatorMessages]="adAccountValidator"
	></app-dropdown-search-select>
	<app-dropdown-search-select
		[dropdownData]="adAccountsLevels"
		[dropdownFormControl]="adAccountPermissionForms.get('addAccountAccessLevel')"
		[dropdownPlaceholder]="'Search...'"
		[validatorMessages]="accesLevelValidator"
	></app-dropdown-search-select>
</form>
