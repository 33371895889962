import { Injectable, OnInit } from '@angular/core';
import { ImageStateEnum } from '../../shared/hover-select-directive/image-state.enum';
import { CampaignTemplate } from '../models/campaign-template';
import { CampaignTemplateListItem } from '../models/campaign-template-list-item';
import _ from 'lodash';
import { UpdateCampaignRequest } from '../models/update-campaign-request';
import { FacebookLocations } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations';
import { FacebookLocationsView } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-locations-view';
import { FacebookInterests } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests';
import { FacebookInterestsView } from '../../shared/smart-create-edit-components/step-two-components/targeting-card/models/facebook-interests-view';
import { Observable } from 'rxjs';
import { DropdownData } from '../../shared/dropdown-search-select/dropdown-data.interface';
import { map } from 'rxjs/operators';
import { AudienceTargetingService } from '../../_services/audience/audience-targeting.service';
import { MapperService } from '../../shared/services/mapper.service';
import { AudienceData } from '../../audience/models/audience-data-table.model';
import { FacebookAsset } from '../../shared/services/assets/models/facebook-asset.model';
import { AssetCollection } from '../../shared/services/assets/asset.service';
import { Asset } from '../../shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-model';
import { AdFormatsEnum } from '../models/ad-formats.enum';
import { AssetPickerContract } from '../../shared/smart-create-edit-components/step-three-components/ad-format/asset-picker-dialog/asset-picker-contract';
import { CatalogNodeInterface } from '../catalogs/catalog-node.interface';
import { CatalogNodeReqInterface } from '../catalogs/catalog-node-req.interface';
import { VideoSizeInterface } from '../models/video-size.interface';
import { select, Store } from '@ngrx/store';
import { getSelectedAdAccount } from '../../shared/state/shared.reducer';

@Injectable()
export class CampaignSmartCreateMapperService {
	private accountId: string;
	constructor(private targetingService: AudienceTargetingService, private mapperService: MapperService, private store: Store) {
		this.store?.pipe(select(getSelectedAdAccount)).subscribe(res => {
			this.accountId = res?.adAccount?.id;
		});
	}
	public static mapCampaignTemplateToCampaignListItem(template: CampaignTemplate): CampaignTemplateListItem {
		return {
			id: template.id,
			name: template.name,
			lastStepId: template.lastStepId
		};
	}

	public static addTemplateToCampaignsList(
		campaignList: Map<string, CampaignTemplateListItem[]>,
		template: CampaignTemplate
	): Map<string, CampaignTemplateListItem[]> {
		const newList = _.cloneDeep(campaignList);
		const listItem = CampaignSmartCreateMapperService.mapCampaignTemplateToCampaignListItem(template);
		if (newList.has(template.adAccountFacebookId)) {
			newList.get(template.adAccountFacebookId).push(listItem);
		} else {
			newList.set(template.adAccountFacebookId, [listItem]);
		}
		return newList;
	}

	public static convertToCommaSeparated(input: number | string): string {
		if (input < 1000) {
			return '<1000';
		}
		const asString = input + '';
		const strLen = asString.length;
		let decimalSeparated = '';
		for (let i = strLen - 3; i >= -3; i -= 3) {
			let subStr = asString.substring(_.clamp(i, 0, strLen), i + 3);
			subStr = (i > 0 ? ',' : '') + subStr;
			decimalSeparated = subStr + decimalSeparated;
		}
		return decimalSeparated;
	}
	public sortContexts(data: CatalogNodeInterface[]): CatalogNodeInterface[] {
		const getContexts = Object.values(data.pop());
		const context = getContexts[0];
		const sorted = [];
		for (let i = 0; i < context.length; i++) {
			const found = data.filter(item => item.name === context[i]);
			sorted.push(found[0]);
		}
		return sorted;
	}

	public mapCatalogNodeToViewModel(nodes: CatalogNodeReqInterface[]): CatalogNodeInterface[] {
		return nodes.map(node => {
			if (nodes.indexOf(node) < nodes.length - 1) {
				return {
					name: node.name,
					kind: node.kind,
					items: Object.values(node.items),
					imageNameAutogen: node.image_name_autogen,
					displayName: node.display_name,
					displayNameAutogen: node.display_name_autogen,
					descriptionAutogen: node.description_autogen,
					imageState: ImageStateEnum.Default
				};
			} else {
				return { contexts: node.SMART_CREATE.items };
			}
		});
	}

	public mapCampaignToUpdateRequest(campaign: CampaignTemplate, publishing = false): UpdateCampaignRequest {
		const stepThreeDetailsAsJson = campaign.stepThreeDetailsAsJson;
		let stepTwoDetailsAsJson = campaign.stepTwoDetailsAsJson;
		const ads = [];
		if (stepThreeDetailsAsJson && stepThreeDetailsAsJson.length > 0) {
			stepThreeDetailsAsJson.forEach(ad => {
				if (ad?.iframeUrl) {
					delete ad.iframeUrl;
				}
				ads.push(ad);
			});
		}
		const stepThree = {
			ads: ads
		};
		if (stepTwoDetailsAsJson && stepTwoDetailsAsJson.adSetName) {
			stepTwoDetailsAsJson = { ...stepTwoDetailsAsJson, name: stepTwoDetailsAsJson.adSetName };
		}
		const campaignTemplate = {
			name: campaign.name,
			adAccountId: this.accountId,
			templateId: campaign.id,
			draftStructureId: campaign.draftStructureId ?? null,
			stepOneDetails: campaign.stepOneDetailsAsJson,
			stepTwoDetails: stepTwoDetailsAsJson,
			stepThreeDetails: stepThree,
			stepFourDetails: campaign.stepFourDetailsAsJson,
			stepFiveDetails: campaign.stepFiveDetailsAsJson
		};
		if (publishing) {
			campaignTemplate.stepFiveDetails = null;
		}
		return campaignTemplate;
	}

	public mapLocationsToViewModel(location: FacebookLocations): FacebookLocationsView {
		let locString = location.name;
		if (location.region) {
			locString += ', ' + location.region;
		}
		return {
			...location,
			countryName: location.countryName ?? location.name,
			cityOrCountryName: location.primaryCity ?? location.countryName ?? location.name,
			selectedLocationString: locString
		};
	}

	public mapInterestToViewModel(interest: FacebookInterests): FacebookInterestsView {
		return {
			...interest,
			viewFormattedPath: interest.path.join(' > ')
		};
	}

	public mapInterestsToDropdownData(interests: FacebookInterests[]): DropdownData<FacebookInterestsView>[] {
		return this.mapperService.convertToDropdownDataOfType(interests, 'id', 'name', this.mapInterestToViewModel);
	}

	// public getAndMapInterests(value: string, adAccountId: string): Observable<DropdownData<FacebookInterestsView>[]> {
	// 	return this.targetingService.getInterests(value, adAccountId).pipe(map(interests => this.mapInterestsToDropdownData(interests)));
	// }

	// public getAndMapRegulatedInterests(adCategory: string): Observable<DropdownData<FacebookInterestsView>[]> {
	// 	return this.targetingService.getRegulatedInterests(adCategory).pipe(map(interests => this.mapInterestsToDropdownData(interests)));
	// }
	public formatAudienceSize(input: AudienceData): AudienceData {
		const audience = _.cloneDeep(input);
		audience.size = CampaignSmartCreateMapperService.convertToCommaSeparated(audience.size);
		return audience;
	}
	public mapFacebookAssetToAssetPickerModel(facebookAssets: FacebookAsset[]): Asset[] {
		return facebookAssets.map(asset => {
			return {
				id: asset?.id,
				url: asset?.permalinkUrl ?? asset?.picture ?? asset?.thumbnail,
				title: asset?.name ?? asset?.title,
				type: asset?.type,
				picture: asset?.picture,
				hash: asset?.hash,
				video_id: asset?.id,
				primary_text: asset?.primary_text,
				call_to_action: asset?.call_to_action ?? '',
				website_url: asset?.website_url,
				height: asset?.height ?? null,
				width: asset?.width ?? null,
				videoSize: asset?.thumbnail as VideoSizeInterface
			};
		});
	}

	public mapAssetCollectionToAssetPickerModel(assetCollection: AssetCollection): Asset[] {
		return assetCollection.assets.map(asset => {
			const converted = 'data:image/jpg;base64,' + asset.thumbnail;
			return {
				id: asset.id,
				url: asset.url ?? converted,
				title: asset.name ?? asset.title,
				thumbnail: asset.thumbnail as string
			};
		});
	}

	public createAssetDialogContracts(
		existingAssets: Asset[],
		userAssets: Asset[],
		posts: Asset[],
		singleSelection: boolean,
		mime: 'image/*' | 'video/*',
		adFormat: AdFormatsEnum,
		existingCreativeImages: Asset[]
	): AssetPickerContract[] {
		const contracts: AssetPickerContract[] = [];
		let formatName: string;
		if (adFormat === AdFormatsEnum.SingleImage || adFormat === AdFormatsEnum.multipleSingleImage) {
			formatName = 'Images';
		} else if (adFormat === AdFormatsEnum.Video) {
			formatName = 'Videos';
		} else {
			formatName = 'Posts';
		}
		if (adFormat === AdFormatsEnum.SingleImage || adFormat === AdFormatsEnum.multipleSingleImage) {
			contracts.push({
				data: [],
				contractName: 'Upload',
				contractFor: adFormat,
				isViewOnly: false,
				isSingleSelection: singleSelection,
				acceptedMime: mime,
				showSearchInput: false,
				uploadFiles: true
			});
			contracts.push({
				data: existingAssets,
				contractName: `Existing Ad ${formatName}`,
				contractFor: adFormat,
				isViewOnly: true,
				isSingleSelection: singleSelection,
				acceptedMime: mime,
				showSearchInput: true
			});
			contracts.push({
				data: existingCreativeImages,
				contractName: `Ad Designs`,
				contractFor: adFormat,
				isViewOnly: true,
				isSingleSelection: singleSelection,
				acceptedMime: mime,
				showSearchInput: true
			});
		} else if (adFormat === AdFormatsEnum.Video) {
			contracts.push({
				data: existingAssets,
				contractName: `Existing Ad ${formatName}`,
				contractFor: adFormat,
				isViewOnly: true,
				isSingleSelection: singleSelection,
				acceptedMime: mime,
				showSearchInput: true
			});
		} else {
			contracts.push({
				data: posts,
				contractName: 'Page Posts',
				contractFor: adFormat,
				isViewOnly: true,
				isSingleSelection: singleSelection,
				acceptedMime: mime,
				showSearchInput: true
			});
		}
		return contracts;
	}
}
