<app-user-header></app-user-header>
<div class="container">
	<div class="header">
		<img class="header-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" />
		<div>
			<h4 class="title">Account Security</h4>
			<div class="subtitle">Changes to account setting will apply to all other signed in devices.</div>
		</div>
	</div>
	<div class="body">
		<div class="body-form">
			<div class="title">Password</div>

			<form class="password-form" [formGroup]="changePasswordForm">
				<div class="input-wrapper">
					<font07>Current password</font07>
					<password-tb
						[placeHolderTextBox]="'***********'"
						name="password"
						[control]="currentPasswordControl"
						class="input-box"
						class="input-box"
					></password-tb>
				</div>
				<div class="input-wrapper">
					<font07>New password</font07>

					<password-tb
						[placeHolderTextBox]="'Enter your new password here'"
						[validatorMessages]="passwordValidator"
						name="password"
						[control]="newPasswordControl"
						class="input-box"
						pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$"
						class="input-box"
					></password-tb>
				</div>
			</form>
			<div class="form__operators" *ngIf="!this.changePasswordForm.disabled">
				<div class="form__validators">
					<div class="form__group">
						<div class="form__radio-group">
							<div
								class="form__radio-outer"
								[style.border]="
									!this.changePasswordForm.disabled && !newPasswordControl.hasError('required') && !newPasswordControl.hasError('minlength')
										? '1px solid #37d65c'
										: '1px solid #2585fa'
								"
							>
								<div
									[style.backgroundColor]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('minlength')
											? '#37d65c'
											: 'none'
									"
									[style.border]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('minlength')
											? '1px solid #37d65c'
											: ''
									"
									class="form__radio-inner"
								></div>
							</div>
							<font08>Minimum 8 characters</font08>
						</div>

						<div class="form__radio-group">
							<div
								class="form__radio-outer"
								[style.border]="
									!this.changePasswordForm.disabled && !newPasswordControl.hasError('required') && !newPasswordControl.hasError('minlength')
										? '1px solid #37d65c'
										: '1px solid #2585fa'
								"
							>
								<div
									[style.backgroundColor]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('hasNumber')
											? '#37d65c'
											: 'none'
									"
									[style.border]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('minlength')
											? '1px solid #37d65c'
											: ''
									"
									class="form__radio-inner"
								></div>
							</div>
							<font08>Contain 1 number</font08>
						</div>
					</div>

					<div class="form__group">
						<div class="form__radio-group">
							<div
								class="form__radio-outer"
								[style.border]="
									!this.changePasswordForm.disabled && !newPasswordControl.hasError('required') && !newPasswordControl.hasError('minlength')
										? '1px solid #37d65c'
										: '1px solid #2585fa'
								"
							>
								<div
									[style.backgroundColor]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('hasCapitalCase')
											? '#37d65c'
											: 'none'
									"
									[style.border]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('minlength')
											? '1px solid #37d65c'
											: ''
									"
									class="form__radio-inner"
								></div>
							</div>
							<font08>Contain 1 Capital Letter</font08>
						</div>

						<div class="form__radio-group">
							<div
								class="form__radio-outer"
								[style.border]="
									!this.changePasswordForm.disabled && !newPasswordControl.hasError('required') && !newPasswordControl.hasError('minlength')
										? '1px solid #37d65c'
										: '1px solid #2585fa'
								"
							>
								<div
									[style.backgroundColor]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('hasSpecialCharacters')
											? '#37d65c'
											: 'none'
									"
									[style.border]="
										!this.changePasswordForm.disabled &&
										!newPasswordControl.hasError('required') &&
										!newPasswordControl.hasError('minlength')
											? '1px solid #37d65c'
											: ''
									"
									class="form__radio-inner"
								></div>
							</div>
							<font08>Contain 1 Special Letter</font08>
						</div>
					</div>
				</div>
			</div>
			<div class="form__btn">
				<button class="btn-gcb-04 action-button" *ngIf="this.changePasswordForm.disabled" (click)="this.changePasswordForm.enable()">EDIT</button>
				<button class="btn-gcb-01" *ngIf="!this.changePasswordForm.disabled" (click)="changePassword()">CHANGE PASSWORD</button>
			</div>

			<div [hidden]="!passwordChangePopup" class="password-popup-wrapper">
				<div class="password-popup">
					<div class="popup-div">
						<img (click)="closePasswordChangePopup()" src="assets/icons/campaign-smart-create/welcome/cross-dismiss-icon.svg" alt="" />
					</div>

					<div class="popup-body">
						<img class="popup-icon" src="assets/icons/dexter-icons/dexter-action-icon.svg" alt="" />
						<font05>Password updated!</font05>
						<font08>You have successfully changed your password.</font08>
					</div>
				</div>
			</div>
		</div>

		<div class="body-form">
			<app-two-factor-authentication></app-two-factor-authentication>
		</div>

		<div class="body-form">
			<app-user-activity></app-user-activity>
		</div>

		<div class="body-form">
			<div class="faq-div">
				<div class="title">FAQ & Help</div>
			</div>

			<div class="support-wrapper">
				<div class="support-card">
					<img class="support-card-icon" src="assets/icons/dexter-icons/AdsDescriptionTextIcon.svg" alt="" />
					<font07>Talk to Sales</font07>
					<font08>Interested in LOLLY's software? Just pick up the phone to chart with a member of our sales team</font08>
				</div>

				<div class="support-card">
					<img class="support-card-icon" src="assets/icons/dexter-icons/AdsHeadlineTextIcon.svg" alt="" />
					<font07>Customer Support</font07>
					<font08>Interested in LOLLY's software? Just pick up the phone to chart with a member of our sales team</font08>
				</div>
			</div>
		</div>

		<div class="body-form">
			<div class="title">Danger Zone</div>

			<button class="danger-btn" (click)="onPauseAccount()">
				Pause Account
			</button>
		</div>
	</div>
</div>
