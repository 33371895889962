<div class="csc-align-center" #container *ngIf="!processing" (mouseover)="handleMouseOver($event)" (mouseleave)="handleMouseLeave($event)">
	<mat-horizontal-stepper
		#stepper
		[linear]="true"
		[selectedIndex]="activeStep"
		class="hidden-horizontal-scrollbar"
		(selectionChange)="handleStepChange($event)"
	>
		<mat-step
			*ngFor="let navigation of navigations; let i = index"
			[completed]="isStepCompleted(i)"
			[editable]="true"
			[aria-labelledby]="stepSkipped(i) ? navigation.skipMessage : null"
		>
			<ng-template matStepLabel>
				<span>{{ navigation.name }}</span>
			</ng-template>
		</mat-step>
	</mat-horizontal-stepper>
</div>
<div class="spacing"></div>
