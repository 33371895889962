import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { GalleryItem } from '../../../../_models/Gallery/GalleryItem';
import { ButtonClassEnum } from '../../../button-component/button-class.enum';
import { ButtonTypeEnum } from '../../../button-component/button-type.enum';
import { AssetService } from 'src/app/shared/services/assets/asset.service';
import { Asset } from 'src/app/shared/services/assets/models/asset.model';
import { UserSetting } from 'src/app/back-office/models/user-setting.model';
import { select, Store } from '@ngrx/store';
import { getBusinessOwnerIdByChannel } from 'src/app/shared/state/user/user.reducer';
import { AccountTypeService } from 'src/app/shared/account-type.service';
import { SourceChannel } from 'src/app/sidenav/sidenav/sidenav-channel-buttons.enum';

@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnDestroy {
	@Output() public submit = new EventEmitter();

	@ViewChild('input') public input: ElementRef;

	public form: FormGroup;
	public assets: Asset[] = [];
	public assetsSubscription: Subscription[];
	public buttonClassEnum = ButtonClassEnum;
	public buttonTypeEnum = ButtonTypeEnum;
	private unsubscriber$: Subject<void> = new Subject<void>();

	constructor(private service: AssetService, private accountTypeService: AccountTypeService, private store: Store<UserSetting>) {}

	ngOnDestroy() {
		if (this.assetsSubscription) {
			this.assetsSubscription.forEach(s => s.unsubscribe());
		}
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public onFileSelected(event: File | any): void {
		const files: File[] = [];
		if (event.length === 0) {
			return;
		}

		files.push(...event);

		this.accountTypeService
			.getAccountTypeObservable()
			.pipe(switchMap((channel: SourceChannel) => this.store.pipe(select(getBusinessOwnerIdByChannel, { channel: channel }), take(1))))
			.subscribe((businessOwnerId: string) => {
				// this.uploadFiles(files, businessOwnerId);
			});
	}

	public openFileSelection(): void {
		const event = new MouseEvent('click', { bubbles: true });
		this.input.nativeElement.dispatchEvent(event);
	}

	public updateAssets(): void {
		const obs = this.assets.map(a => this.service.updateTitle(a.id, a.title));
		forkJoin(obs)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.service.getTemplates();
				this.service.getImages();
				this.service.getVideos();
			})
			.add(() => {
				this.submit.emit(this.assets.map(a => ({ isSelected: true, asset: a } as GalleryItem)));
			});
	}

	// private uploadFiles(files: File[], businessOwnerId: string): void {
	// 	this.assetsSubscription = files.map(file =>
	// 		this.service
	// 			.uploadFile(file, businessOwnerId)
	// 			.pipe(
	// 				takeUntil(this.unsubscriber$),
	// 				mergeMap(responseId => this.service.getAssetById(responseId))
	// 			) // Get element processed by BE
	// 			.subscribe(
	// 				(asset: Asset) => this.assets.push(asset),
	// 				(error: any) => {}
	// 			)
	// 	);
	// }
}
