export enum FiledUsersApiValidationCodesEnum {
	NA = 0,

	BusinessOwnerFacebookId_IsNull,
	BusinessOwnerGoogleId_IsNull,

	ClientEmployeeFirstName_IsNullOrEmpty,
	ClientEmployeeLastName_IsNullOrEmpty,
	ClientEmployeePermissions_IsNullOrEmpty,
	ClientEmployeePhoneNumber_IsNullOrEmpty,

	InputFile_HasNoContent,
	InputFile_IsNull,

	FileContentType_IsNullOrEmpty,
	FileContentType_IsNotImageOrVideo,

	FileName_IsNullOrEmpty,

	SetPassword_CurrentPassword_IsNull,
	SetPassword_NewPassword_IsNull,
	SetPassword_ConfirmNewPassword_IsNull,
	SetPassword_NewPassword_DiffersFomConfirmNewPassword
}

export enum FiledSubscriptionApiValidationCodesEnum {
	NA = 0,

	Filed__Domain__NA__PhoneNumber_ContactPhoneNumber__AreDifferent = 'Filed__Domain__NA__PhoneNumber_ContactPhoneNumber__AreDifferent',
	Filed__Domain__NA__Contact_RemoveAt__IsNotNull = 'Filed__Domain__NA__Contact_RemoveAt__IsNotNull'
}
