export interface IDexterHealthRequest {
	adAccountId: string;
}

export interface IDexterHealthResponse {
	alerts_structure_tree: IDexterHealthAlert[];
	tooltip: string;
	health_status: IDexterHealthStatus;
}

export interface IDexterHealthStatus {
	hyperTextLink: string;
	links: string;
	mainText: string;
	titleText: string;
}

export interface IDexterHealthAlert {
	campaignId: string;
	campaignName: string;
	adsets: IDexterAdset[];
}

export interface IDexterAdset {
	adsetId: string;
	adsetName: string;
	ads: IDexterAd[];
}

export interface IDexterAd {
	adId: string;
	adName: string;
	displayName?: string;
	alerts?: IDexterAdAlert[];
	topLevelData?: IDexterHealthAlert;
}

export interface IDexterAdAlert {
	alertId?: string;
	alertMessage?: string;
	alertSummary?: number | string;
	alertTitle?: string;
	toolTip?: string;
	links?: string;
	hyperlinkText?: string;
	ranking?: IDexterAdAlertRanking;
	overallHealthScore?: number;
}

export interface IDexterAdAlertRanking {
	qualityRanking: string;
	engagementRateRanking: string;
	conversionRateRanking: string;
}

export interface IDexterHealthScoreResponse {
	dexter_score: number;
	tooltip: string;
}

export enum AlertTitleEnum {
	PageAlerts = 'Landing Page Alerts',
	PageComparisons = 'Landing Page Comparisons',
	SentimentAnalysis = 'Sentiment Analysis on Ads',
	DistributionComparisons = 'Industry Comparisons'
}

export enum AlertSentimentAnalysis {
	Neutral = 'Neutral',
	Positive = 'Positive',
	Negative = 'Negative'
}

export enum DistributionComparisons {
	BELOW_AVERAGE_10 = 'BELOW_AVERAGE_10',
	BELOW_AVERAGE_20 = 'BELOW_AVERAGE_20',
	BELOW_AVERAGE_35 = 'BELOW_AVERAGE_35',
	AVERAGE = 'AVERAGE',
	ABOVE_AVERAGE = 'ABOVE_AVERAGE'
}

export enum DistributionComparisonValues {
	'BELOW_AVERAGE_10' = 10,
	'BELOW_AVERAGE_20' = 20,
	'BELOW_AVERAGE_35' = 35,
	'AVERAGE' = 50,
	'ABOVE_AVERAGE' = 75
}

export enum AlertRankingText {
	qualityRanking = 'Quality Ranking',
	engagementRateRanking = 'Engagement Rate Ranking',
	conversionRateRanking = 'Conversion Rate Ranking'
}

export interface IDistributionComparisonChartData {
	type: string;
	data: any[][];
	chartColumns: any[];
	width: number;
	height: number;
	options: any;
}

export interface IHealthColorStatus {
	colorCode: string;
	overallStatus: string;
	similarityStatus: string;
}
