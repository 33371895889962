import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CatalogNodeInterface } from 'src/app/campaign-smart-create/catalogs/catalog-node.interface';
import { AdFormatsEnum } from 'src/app/campaign-smart-create/models/ad-formats.enum';
import { DropdownData } from 'src/app/shared/dropdown-search-select/dropdown-data.interface';
import { Placements } from 'src/app/shared/smart-create-edit-components/step-three-components/ad-format/ad-creation-card/ad-creation.placement.model';
import * as fromAdPreview from '../../state/ad-preview';
import * as fromAdState from '../../state/ad-state';
import { AdPreviewState } from '../../state/ad-preview/ad-preview.reducer';
import { UpdatePreviewIFrameSrc } from '../../state/ad-preview/ad-preview.action';

@Component({
	selector: 'app-ad-preview',
	templateUrl: './ad-preview.component.html',
	styleUrls: ['./ad-preview.component.scss']
})
export class AdPreviewComponent implements OnInit, OnDestroy {
	@Input() public adCreateFormGroup: FormGroup;
	@Input() public adFormatListener$: Observable<AdFormatsEnum>;
	public iframeSrc$: Observable<string>;
	public previewWidth: number;
	public previewHeight: number;
	private unsubscriber$ = new Subject<void>();
	public dropdownPreviewPlacement: DropdownData<CatalogNodeInterface>[];
	public isFetchingIframe$: Observable<boolean>;
	constructor(private store: Store) {}

	public ngOnInit(): void {
		this.initListeners();
	}

	public ngOnDestroy(): void {
		this.unsubscriber$.next();
		this.unsubscriber$.complete();
	}

	public initListeners(): void {
		this.iframeSrc$ = this.store.pipe(select(fromAdPreview.getAdPreviewIframeSrc));
		this.isFetchingIframe$ = this.store.pipe(select(fromAdPreview.getIsFetchingIframe));
		this.store.pipe(select(fromAdPreview.getAdPreviewPlacements), takeUntil(this.unsubscriber$)).subscribe(data => {
			if (data.length) {
				this.adCreateFormGroup.get('preview').patchValue(data[1]?.value);
				this.dropdownPreviewPlacement = data;
			}
		});
		this.store
			.pipe(select(fromAdState.getCurrentAdIndex))
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(() => {
				this.store.dispatch(new UpdatePreviewIFrameSrc(''));
				const placements = this.dropdownPreviewPlacement;
				if (placements.length) {
					this.adCreateFormGroup.get('preview').patchValue(placements[1].value);
				}
			});
		const previewWidth$ = this.store.pipe(select(fromAdPreview.getAdPreviewWidth));
		const previewHeight$ = this.store.pipe(select(fromAdPreview.getAdPreviewHeight));

		combineLatest([previewWidth$, previewHeight$])
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe(([width, height]) => {
				this.previewWidth = width;
				this.previewHeight = height;
			});
	}
	public setPreviewClass(): any {
		const isFaceBookDesktopFeed = this.adCreateFormGroup.get('preview').value === Placements.FacebookDesktopFeed;
		const isFacebookMobileFeed = this.adCreateFormGroup.get('preview').value === Placements.FacebookMobileFeed;
		const isFacebookStory = this.adCreateFormGroup.get('preview').value === Placements.FacebookStory;
		const isInstagramStory = this.adCreateFormGroup.get('preview').value === Placements.InstagramStory;
		const isInstagramStandard = this.adCreateFormGroup.get('preview').value === Placements.InstagramStandard;
		const isMobileNative = this.adCreateFormGroup.get('preview').value === Placements.MobileNative;
		const isMobileBanner = this.adCreateFormGroup.get('preview').value === Placements.MobileBanner;
		const isMobileInterstitial = this.adCreateFormGroup.get('preview').value === Placements.MobileInterstitial;
		const isMobileRectangle = this.adCreateFormGroup.get('preview').value === Placements.MobileRectangle;
		return {
			'ac-iframe-box-facebook-desktop-feed': isFaceBookDesktopFeed,
			'small-image': isFaceBookDesktopFeed && this.previewHeight < 570,
			'small-image-two-rows': isFaceBookDesktopFeed && this.previewHeight === 570,
			'small-image-three-rows': isFaceBookDesktopFeed && this.previewHeight === 590,
			'small-image-four-rows': isFaceBookDesktopFeed && this.previewHeight === 610,
			'small-image-five-rows': isFaceBookDesktopFeed && this.previewHeight === 630,
			'small-image-six-rows': isFaceBookDesktopFeed && this.previewHeight === 650,
			'small-image-seven-rows': isFaceBookDesktopFeed && this.previewHeight === 670,
			'small-image-eight-rows': isFaceBookDesktopFeed && this.previewHeight === 690,
			'small-image-nine-rows': isFaceBookDesktopFeed && this.previewHeight === 710,
			'one-row': isFaceBookDesktopFeed && this.previewHeight < 730 && this.previewHeight > 710,
			'two-rows': isFaceBookDesktopFeed && this.previewHeight === 730,
			'three-rows': isFaceBookDesktopFeed && this.previewHeight === 750,
			'four-rows': isFaceBookDesktopFeed && this.previewHeight === 770,
			'five-rows': isFaceBookDesktopFeed && this.previewHeight === 790,
			'six-rows': isFaceBookDesktopFeed && this.previewHeight === 810,
			'seven-rows': isFaceBookDesktopFeed && this.previewHeight === 830,
			'eight-rows': isFaceBookDesktopFeed && this.previewHeight === 850,
			'nine-rows': isFaceBookDesktopFeed && this.previewHeight === 870,
			'ac-iframe-box-facebook-mobile-news-feed': isFacebookMobileFeed,
			'ac-iframe-box-facebook-story': isFacebookStory,
			'ac-iframe-box-instagram-story': isInstagramStory,
			'ac-iframe-box-instagram-standard': isInstagramStandard,
			'ac-iframe-box-mobile-native': isMobileNative,
			'ac-iframe-box-mobile-banner': isMobileBanner,
			'ac-iframe-box-mobile-interstitial': isMobileInterstitial,
			'ac-iframe-box-mobile-rectangle': isMobileRectangle
		};
	}
	public getCarouselCards(index: number = 0): AbstractControl {
		const advertItemsForm = this.adCreateFormGroup.get('adCardsItemData') as FormArray;
		return advertItemsForm.controls[index];
	}
}
