export enum TableViewColumnTemplateEnum {
	MasterCheckbox = 0,
	Text,
	Number,
	Currency,
	Toggle,
	Link,
	Percentage,
	Button,
	Date,
	Actions
}
