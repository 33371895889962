import { Injectable } from '@angular/core';

import { ExcelService } from '../../../_services/excel.service';
import { ChartService } from './chart.service';
import { GoalService } from './goal.service';
import { ChartLabel } from '../../models/chart-label';
import { ReportModel } from '../../models/report.model';
import { ToastNotificationService } from '../../../shared/toast-notification/toast-notification.service';
import { Store } from '@ngrx/store';
import { ReportingState } from '../../state/reporting.reducer';
import { chartTooltipModel } from '../../shared/models/chart-tooltip.model';
import { GridLayout } from '../../models/grid-layout';
@Injectable({
	providedIn: 'root'
})
export class StackedVerticalChartService extends ChartService {
	constructor(
		protected excelService: ExcelService,
		protected goalService: GoalService,
		protected toastNotificationService: ToastNotificationService,
		protected store: Store<ReportingState>
	) {
		super(excelService, goalService, toastNotificationService, store);
	}

	public createChart(widget: ReportModel) {
		const label = new ChartLabel('center', 'middle', 'inside', 0, 15, 12, widget.details.reportProperty.showDataLabels);
		const chartOptions = this.initChartOptions(widget);
		const chartData = this.createChartSourceForComplexData(widget, true, false, false, label);
		chartOptions.xAxis = this.createPrimaryAxis(chartData, widget, 35, widget.details.reportProperty.horizontalAxisTitle);
		chartOptions.yAxis = this.createSecondaryAxis(chartData, widget, 'yAxisIndex', widget.details.reportProperty.verticalAxisTitle, 50, 0);
		chartOptions.series = chartData.data;
		chartOptions.tooltip = chartTooltipModel;
		chartOptions.dataZoom = [
			{
				show: true,
				type: 'inside'
			}
		];
		chartOptions.nameTextStyle = {
			color: '#424242'
		};
		chartOptions.grid = new GridLayout('120', '20', '20', '115', false);
		chartOptions.legend.data = [];
		chartOptions.legend.data = chartData.data.map(item => item.name);
		return chartOptions;
	}
}
