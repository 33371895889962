import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
	CampaignSmartCreateState,
	getAdFormat,
	getAdPreview,
	getCampaign,
	getObjectiveTree,
	getPagePosts,
	getPlacements,
	isCampaignUpdating
} from '../state/smart-create.reducer';
import { SharedState } from '../../shared/state/shared.reducer';
import { HideGlobalSpinner, ShowGlobalSpinner } from '../../shared/state/shared.actions';
import { ClearAds, LoadCampaignTemplate } from '../state/smart-create.actions';
import { map, skipWhile, take, tap, withLatestFrom } from 'rxjs/operators';
import { AdAccountResolver } from '../../shared/resolvers/ad-account.resolver';
import {
	LoadCampaignCatalogs,
	LoadCreativeBuilderSingleImage,
	LoadExistingImages,
	LoadFacebookPages
} from '../../state/campaign-common-state/campaign-common-actions';
import {
	getAdPreviewFormat,
	getValidations,
	getVideoAssets,
	getCatalogs,
	getImageAssets,
	getCallToAction,
	getFacebookPages
} from '../../state/campaign-common-state/campaign-common-reducer';
import { ToastNotificationService } from '../../shared/toast-notification/toast-notification.service';

@Injectable()
export class CreateAdResolver implements Resolve<Observable<any>> {
	constructor(
		private router: Router,
		private accountResolver: AdAccountResolver,
		private store: Store<CampaignSmartCreateState>,
		private sharedStore: Store<SharedState>,
		public toastNotificationService: ToastNotificationService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		this.sharedStore.dispatch(new ShowGlobalSpinner());
		this.store.dispatch(new LoadCampaignCatalogs());
		this.store.dispatch(new LoadFacebookPages());
		this.store.dispatch(new LoadCreativeBuilderSingleImage());

		const adAccount$ = this.accountResolver.resolve(route, state);
		const campaignId = Number(route.params.campaignId);
		this.store.dispatch(new ClearAds());
		this.store.dispatch(new LoadCampaignTemplate(campaignId));
		const campaign$ = this.store.pipe(
			withLatestFrom(this.store.pipe(select(isCampaignUpdating))),
			skipWhile(([action, isUpdating]) => isUpdating),
			map(([action, isUpdating]) => action),
			select(getCampaign),
			skipWhile(campaign => !campaign || campaign.id !== campaignId),
			take(1)
		);

		const pages$ = this.store.pipe(
			select(getFacebookPages),
			skipWhile(pages => !pages),
			take(1)
		);
		const catalogs$ = this.store.pipe(
			select(getCatalogs),
			skipWhile(catalogs => !catalogs),
			take(1)
		);

		const platforms$ = this.store.pipe(
			select(getAdPreviewFormat),
			skipWhile(platform => !platform),
			take(1)
		);

		const preview$ = this.store.pipe(select(getAdPreview), take(1));

		const posts$ = this.store.pipe(select(getPagePosts), take(1));

		const images$ = this.store.pipe(
			select(getImageAssets),
			skipWhile(assets => !assets?.existingImages),
			take(1)
		);
		const videos$ = this.store.pipe(
			select(getVideoAssets),
			skipWhile(assets => !assets?.userVideos || !assets?.existingVideos),
			take(1)
		);

		const callToActions$ = this.store.pipe(
			select(getCallToAction),
			skipWhile(result => !result),
			take(1)
		);

		const objectives$ = this.store.pipe(
			select(getObjectiveTree),
			skipWhile(objectives => !objectives),
			take(1)
		);

		const validations$ = this.store.pipe(
			select(getValidations),
			skipWhile(validations => !validations),
			take(1)
		);

		const placements$ = this.store.pipe(
			select(getPlacements),
			skipWhile(placements => !placements),
			take(1)
		);

		const selectedFormat$ = this.store.pipe(select(getAdFormat), take(1));

		return forkJoin([
			adAccount$,
			campaign$,
			catalogs$,
			platforms$,
			preview$,
			callToActions$,
			videos$,
			images$,
			objectives$,
			validations$,
			placements$,
			selectedFormat$,
			pages$,
			posts$
		]).pipe(
			take(1),
			tap(_ => this.sharedStore.dispatch(new HideGlobalSpinner())),
			map(
				([
					adAccount,
					campaign,
					catalog$,
					platforms,
					preview,
					callToActions,
					videos,
					images,
					objectiveTree,
					validations,
					placements,
					selectedFormat,
					pages,
					posts
				]) => {
					if (!campaign) {
						this.sharedStore.dispatch(new HideGlobalSpinner());
						this.router.navigate(['/campaign/welcome']);
						return EMPTY;
					}
					return {
						account: adAccount,
						step: campaign.stepThreeDetailsAsJson,
						campaignId: campaign.id,
						lastStep: campaign.lastStepId,
						platforms: platforms,
						preview: preview,
						callToActions: callToActions,
						videos: videos,
						images: images,
						objectiveTree: objectiveTree,
						validations: validations,
						placements: placements,
						selectedFormat: selectedFormat,
						facebookPages: pages,
						posts: posts
					};
				}
			)
		);
	}

	protected getCurrentCampaign(): number {
		let campaignId = 0;
		this.store.pipe(select(getCampaign), take(1)).subscribe(res => {
			campaignId = res?.id;
		});

		return campaignId;
	}
}
