/* tslint:disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlatNode, SelectionActions } from '../../../_models/TableModels/selection-actions';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

/**
 * Multiple Selection Actions Component
 */
@Component({
	selector: 'app-multiple-selection-actions',
	templateUrl: './multiple-selection-actions.component.html',
	styleUrls: ['./multiple-selection-actions.component.scss']
})
export class MultipleSelectionActionsComponent implements OnInit {
	/**
	 * Constructor
	 */
	constructor() {}

	/**
	 * MultipleSelectionActions
	 */
	@Input() multipleSelectionActions: SelectionActions[];

	/**
	 * selectedRows
	 */
	@Input() selectedRows: any[];

	/**
	 * actionEvent
	 */
	@Output() actionEvent: EventEmitter<{ functionName: string; parameter: any[] }> = new EventEmitter();

	private transformer = (node: SelectionActions, level: number) => {
		return {
			expandable: !!node.children && node.children.length > 0,
			name: node.name,
			value: node.value,
			functionName: node.functionName,
			level: level,
			isDelete: node.isDelete
		};
	};

	treeControl = new FlatTreeControl<FlatNode>(
		node => node.level,
		node => node.expandable
	);

	treeFlattener = new MatTreeFlattener(
		this.transformer,
		node => node.level,
		node => node.expandable,
		node => node.children
	);

	dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

	ngOnInit() {
		this.dataSource.data = this.multipleSelectionActions;
	}

	hasChild = (_: number, node: FlatNode) => node.expandable;

	/**
	 * Do a specific action when you selected multiples rows
	 * @param functionName
	 * @param parameter
	 */
	doSomething(functionName: string, parameter: any[]) {
		this.actionEvent.emit({ functionName, parameter });
	}

	private isUpperCase(str: string) {
		return /^[^a-z]*$/.test(str);
	}

	public appendKey(key: string) {
		if (!this.isUpperCase(key)) {
			return key;
		}
		return 'TABLE.MULTIPLE_SELECTION_ACTION.' + key;
	}
}
