import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiUrl } from '../../_services/base-api-urls';
import { IAPIAdModel, IAPIAdsetModel } from '../helpers/helper';

@Injectable({
	providedIn: 'root'
})
export class AAAAdService {
	// private readonly baseURL = `${BaseApiUrl.FacebookPythonCampaignBuilder}`;

	constructor(private http: HttpClient) {}
	// public fetchAds(adId: string, accountId: string): Observable<IAPIAdModel[]> {
	// 	return this.http.get<IAPIAdModel[]>(`${this.baseURL}get-structure/${accountId}/ad/${adId}`);
	// }

	// public fetchAdset(adsetId: string, accountId: string): Observable<IAPIAdsetModel[]> {
	// 	return this.http.get<IAPIAdsetModel[]>(`${this.baseURL}get-structure/${accountId}/adset/${adsetId}`);
	// }

	// public getPost(adsetId: string): Observable<any> {
	// 	return this.http.get<any>(`${this.baseURL}get-post/${adsetId}`);
	// }

	// public getAdImages(adIds: string): Observable<any> {
	// 	return this.http.get<any>(`${this.baseURL}get-ad-image/${adIds}`);
	// }

	// public fetchCampaign(adsetId: string, accountId: string): Observable<any> {
	// 	return this.http.get<any>(`${this.baseURL}get-structure/${accountId}/campaign/${adsetId}`);
	// }
}
