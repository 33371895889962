export enum EcomerceSource {
	Facebook = 'facebook',
	Google = 'google',
	Instagram = 'instagram',
	Snapchat = 'snapchat',
	Ebay = 'ebay',
	Amazon = 'amazon',
	Shopify = 'shopify',
	BigCommerce = 'bigcommerce',
	Wish = 'wish',
	AdRoll = 'adroll',
	Affiliatewindow = 'affiliatewindow',
	AliExpress = 'aliexpress',
	Awin = 'awin',
	Barnebys = 'barnebys',
	Become = 'become',
	Bueni = 'bueni',
	Bingads = 'bingads',
	Bouncex = 'bouncex',
	Commissionjunction = 'commissionjunction',
	ClickPoint = 'clickpoint',
	Connexity = 'connexity',
	Conversant = 'conversant',
	Devatics = 'devatics',
	Effiliation = 'effiliation',
	Effinity = 'effinity',
	Fashiola = 'fashiola',
	Flixmedia = 'flixmedia',
	Fruugo = 'fruugo',
	Gamned = 'gamned',
	Glami = 'glami',
	HarveyNichols = 'harveynichols',
	Hometiger = 'hometiger',
	Houzz = 'houzz',
	Iceleads = 'iceleads',
	Idealo = 'idealo',
	Jetlore = 'jetlore',
	Kelkoo = 'kelkoo',
	Kips = 'kips',
	Klaviyo = 'klaviyo',
	LeGuide = 'leguide',
	Livestory = 'livestory',
	Living24 = 'living24',
	LuxSeeker = 'luxseeker',
	Lyst = 'lyst',
	Manomano = 'manomano',
	NexTag = 'nextag',
	Octer = 'octer',
	Olapic = 'olapic',
	Peerius = 'peerius',
	Pepperjam = 'pepperjam',
	PriceRunner = 'pricerunner',
	Rakuten = 'rakuten',
	Redlift = 'redlift',
	RtbHouse = 'rtbhouse',
	SenseFuel = 'sensefuel',
	Shareasale = 'shareasale',
	Shopmania = 'shopmania',
	Shopstyle = 'shopstyle',
	Shopzilla = 'shopzilla',
	Skinflint = 'skinflint',
	Sociomantic = 'sociomantic',
	Spartoo = 'spartoo',
	Tracdelight = 'tracdelight',
	Tradetracker = 'tradetracker',
	Truefit = 'truefit',
	Twenga = 'twenga',
	Vidaxl = 'vidaxl',
	Wanelo = 'wanelo',
	Webgains = 'webgains',
	Zalando = 'zalando',
	Autodiff = 'autodiff',
	Beslist = 'beslist',
	Billiger = 'billiger',
	Bizrate = 'bizrate',
	Bol = 'bol',
	Comparer = 'comparer',
	Criteo = 'criteo',
	Hardwareinfo = 'hardwareinfo',
	Impact = 'impact',
	Kieskeurig = 'kieskeurig',
	Koopkeus = 'koopkeus',
	Kwanko = 'kwanko',
	Marktplaats = 'marktplaats',
	Promobutler = 'promobutler',
	Shopbot = 'shopbot',
	Sizebay = 'sizebay',
	Squadata = 'squadata',
	Stylight = 'stylight',
	Tirecomp = 'tirecomp',
	Tradedoubler = 'tradedoubler',
	Vergelijk = 'vergelijk',
	Vizury = 'vizury',
	Adcell = 'adcell',
	Adforstyle = 'adforstyle',
	Affilinet = 'affilinet',
	Berake = 'berake',
	Billionhands = 'billionhands',
	Blackfriday = 'blackfriday',
	Catchys = 'catchys',
	Check24 = 'check24',
	Chip = 'chip',
	Converto = 'converto',
	Dba = 'dba',
	Evendi = 'evendi',
	Findologic = 'findologic',
	Geizhals = 'geizhals',
	Guenstiger = 'guenstiger',
	Guloggratis = 'guloggratis',
	Hatch = 'hatch',
	Hinta = 'hinta',
	Hintaopas = 'hintaopas',
	Hintaseuranta = 'hintaseuranta',
	Jaknjil = 'jaknjil',
	Joom = 'joom',
	Kaufland = 'kaufland',
	Kupona = 'kupona',
	Ladenzeile = 'ladenzeile',
	Lionshome = 'lionshome',
	Liveshoppingaktuell = 'liveshoppingaktuell',
	Locafox = 'locafox',
	Manor = 'manor',
	Minderest = 'minderest',
	Moebel = 'moebel',
	Mybestbrands = 'mybestbrands',
	Myliveshopping = 'myliveshopping',
	Netbooster = 'netbooster',
	Nexeps = 'nexeps',
	Nokaut = 'nokaut',
	Okazjeinfo = 'okazjeinfo',
	Parsionate = 'parsionate',
	Photoslurp = 'photoslurp',
	Preis = 'preis',
	Preisvergleich = 'preisvergleich',
	Prisjakt = 'prisjakt',
	Proskilab = 'proskilab',
	Publicidees = 'publicidees',
	Ricardo = 'ricardo',
	Scarab = 'scarab',
	Shopalike = 'shopalike',
	Shopello = 'shopello',
	Shopping24 = 'shopping24',
	Shoesize = 'shoesize',
	Shopwahl = 'shopwahl',
	Sovendus = 'sovendus',
	Squarelovin = 'squarelovin',
	Toppreise = 'toppreise',
	Trbo = 'trbo',
	Venca = 'venca',
	Vertaa = 'vertaa',
	Vivalu = 'vivalu',
	Webarcondicionado = 'webarcondicionado',
	Webepartners = 'webepartners',
	Webkupiec = 'webkupiec',
	Welchereifen = 'welchereifen',
	Worten = 'worten',
	Xxldeals = 'xxldeals',
	Yahoogemini = 'yahoogemini',
	Yatego = 'yatego',
	Yopi = 'yopi',
	Achetermoinscher = 'achetermoinscher',
	Adventori = 'adventori',
	Affilae = 'affilae',
	Antvoice = 'antvoice',
	Bhv = 'bhv',
	Brandalley = 'brandalley',
	But = 'but',
	Cezigue = 'cezigue',
	Cherchons = 'cherchons',
	Ematch = 'ematch',
	Fashionchick = 'fashionchick',
	Fitizzy = 'fitizzy',
	Greenweez = 'greenweez',
	Hellopro = 'hellopro',
	Homify = 'homify',
	Hotsale = 'hotsale',
	Iprice = 'iprice',
	Keyade = 'keyade',
	Kissmyshoe = 'kissmyshoe',
	Lcdcompare = 'lcdcompare',
	Ledenicheur = 'ledenicheur',
	Leroymerlin = 'leroymerlin',
	Lesbonnesbouilles = 'lesbonnesbouilles',
	Liganz = 'liganz',
	Mafringue = 'mafringue',
	Mercis1000 = 'mercis1000',
	Mythings = 'mythings',
	Priceobservatory = 'priceobservatory',
	Prisguide = 'prisguide',
	Pureshopping = 'pureshopping',
	Qwant = 'qwant',
	Remailme = 'remailme',
	Retailmenot = 'retailmenot',
	Showroomprive = 'showroomprive',
	Skaze = 'skaze',
	Skitour = 'skitour',
	Spotashop = 'spotashop',
	Tek = 'tek',
	Thunderstone = 'thunderstone',
	Timeone = 'timeone',
	Tourdumondiste = 'tourdumondiste',
	Touslesprix = 'touslesprix',
	Trouversoncadeau = 'trouversoncadeau',
	Uzerly = 'uzerly',
	Veepee = 'veepee',
	Wordpress = 'wordpress',
	Zebestof = 'zebestof',
	Adup = 'adup',
	Galerieslafayette = 'galerieslafayette',
	Kreocen = 'kreocen',
	Kuantokusta = 'kuantokusta',
	Laposte = 'laposte',
	Socloz = 'socloz'
}

export enum EcomerceSourceImages {
	filed = 'assets/images/catalog-product/filed-f-icon.svg',
	facebook = 'assets/images/export-catalog/facebook-icon.svg',
	google = 'assets/images/export-catalog/google-logo.png',
	instagram = 'assets/images/export-catalog/instagram.jpg',
	snapchat = 'assets/images/export-catalog/snapchat.svg',
	ebay = 'assets/images/export-catalog/ebay.png',
	amazon = 'assets/images/export-catalog/amazon.png',
	shopify = 'assets/images/catalog-product/shopify-1.svg',
	bigCommerce = 'assets/images/catalog-product/BigCommerce-logomark-whitebg.png',
	wish = 'assets/images/export-catalog/wish.jpg',
	adRoll = 'assets/images/export-catalog/adroll.png',
	affiliatewindow = 'assets/images/export-catalog/affiliatewindow.png',
	aliExpress = 'assets/images/export-catalog/aliexpress.png',
	awin = 'assets/images/export-catalog/awin.png',
	barnebys = 'assets/images/export-catalog/barnebys.png',
	become = 'assets/images/export-catalog/become.png',
	bingads = 'assets/images/export-catalog/bingads.png',
	bouncex = 'assets/images/export-catalog/bouncex.jpeg',
	bueni = 'assets/images/export-catalog/bueni.png',
	commissionjunction = 'assets/images/export-catalog/cj.svg',
	clickPoint = 'assets/images/export-catalog/clickpoint.jpeg',
	connexity = 'assets/images/export-catalog/connexity.png',
	conversant = 'assets/images/export-catalog/conversant.png',
	devatics = 'assets/images/export-catalog/devatics.jpeg',
	effiliation = 'assets/images/export-catalog/effiliation.png',
	effinity = 'assets/images/export-catalog/effinity.png',
	fashiola = 'assets/images/export-catalog/fashiola.png',
	flixmedia = 'assets/images/export-catalog/flixmedia.jpeg',
	fruugo = 'assets/images/export-catalog/fruugo.png',
	gamned = 'assets/images/export-catalog/gamned.png',
	glami = 'assets/images/export-catalog/glami.png',
	harveyNichols = 'assets/images/export-catalog/harveynichols.png',
	hometiger = 'assets/images/export-catalog/hometiger.jpeg',
	houzz = 'assets/images/export-catalog/houzz.png',
	iceleads = 'assets/images/export-catalog/iceleads.png',
	idealo = 'assets/images/export-catalog/idealo.png',
	jetlore = 'assets/images/export-catalog/jetlore.png',
	kelkoo = 'assets/images/export-catalog/kelkoo.jpeg',
	kips = 'assets/images/export-catalog/kips.jpeg',
	klaviyo = 'assets/images/export-catalog/klaviyo.jpeg',
	leGuide = 'assets/images/export-catalog/leguide.png',
	livestory = 'assets/images/export-catalog/livestory.jpeg',
	living24 = 'assets/images/export-catalog/living24.jpeg',
	luxSeeker = 'assets/images/export-catalog/luxseeker.jpeg',
	lyst = 'assets/images/export-catalog/lyst.png',
	manomano = 'assets/images/export-catalog/manomano.jpeg',
	nexTag = 'assets/images/export-catalog/nextag.png',
	octer = 'assets/images/export-catalog/octer.jpeg',
	olapic = 'assets/images/export-catalog/olapic.png',
	peerius = 'assets/images/export-catalog/peerius.jpeg',
	pepperjam = 'assets/images/export-catalog/pepperjam.jpeg',
	priceRunner = 'assets/images/export-catalog/pricerunner.png',
	rakuten = 'assets/images/export-catalog/rakuten.jpeg',
	redlift = 'assets/images/export-catalog/redlift.jpeg',
	rtbHouse = 'assets/images/export-catalog/rtbhouse.jpeg',
	senseFuel = 'assets/images/export-catalog/sensefuel.jpeg',
	shareasale = 'assets/images/export-catalog/shareasale.png',
	shopmania = 'assets/images/export-catalog/shopmania.png',
	shopstyle = 'assets/images/export-catalog/shopstyle.png',
	shopzilla = 'assets/images/export-catalog/shopzilla.png',
	skinflint = 'assets/images/export-catalog/skinflint.png',
	sociomantic = 'assets/images/export-catalog/sociomantic.png',
	spartoo = 'assets/images/export-catalog/spartoo.jpeg',
	tracdelight = 'assets/images/export-catalog/tracdelight.png',
	tradetracker = 'assets/images/export-catalog/tradetracker.png',
	truefit = 'assets/images/export-catalog/truefit.jpeg',
	twenga = 'assets/images/export-catalog/twenga.png',
	vidaxl = 'assets/images/export-catalog/vidaxl.png',
	wanelo = 'assets/images/export-catalog/wanelo.png',
	webgains = 'assets/images/export-catalog/webgains.jpeg',
	zalando = 'assets/images/export-catalog/zalando.jpeg',
	autodiff = 'assets/images/export-catalog/autodiff.jpeg',
	beslist = 'assets/images/export-catalog/beslist.png',
	billiger = 'assets/images/export-catalog/billiger.png',
	bizrate = 'assets/images/export-catalog/bizrate.png',
	bol = 'assets/images/export-catalog/bol.jpeg',
	comparer = 'assets/images/export-catalog/comparer.jpeg',
	criteo = 'assets/images/export-catalog/criteo.png',
	hardwareinfo = 'assets/images/export-catalog/hardwareinfo.jpeg',
	impact = 'assets/images/export-catalog/impact.jpeg',
	kieskeurig = 'assets/images/export-catalog/kieskeurig.jpg',
	koopkeus = 'assets/images/export-catalog/koopkeus.jpeg',
	kwanko = 'assets/images/export-catalog/kwanko.png',
	marktplaats = 'assets/images/export-catalog/marktplaats.jpeg',
	promobutler = 'assets/images/export-catalog/promobutler.jpeg',
	shopbot = 'assets/images/export-catalog/shopbot.png',
	sizebay = 'assets/images/export-catalog/sizebay.jpeg',
	squadata = 'assets/images/export-catalog/squadata.jpeg',
	stylight = 'assets/images/export-catalog/stylight.png',
	tirecomp = 'assets/images/export-catalog/tirecomp.png',
	tradedoubler = 'assets/images/export-catalog/tradedoubler.png',
	vergelijk = 'assets/images/export-catalog/vergelijk.jpeg',
	vizury = 'assets/images/export-catalog/vizury.png',
	adcell = 'assets/images/export-catalog/platforms/adcell.png',
	adforstyle = 'assets/images/export-catalog/platforms/adforstyle.png',
	affilinet = 'assets/images/export-catalog/platforms/affilinet.png',
	berake = 'assets/images/export-catalog/platforms/berake.jpeg',
	billionhands = 'assets/images/export-catalog/platforms/billionhands.jpeg',
	blackfriday = 'assets/images/export-catalog/platforms/blackfriday.jpeg',
	catchys = 'assets/images/export-catalog/platforms/catchys.png',
	check24 = 'assets/images/export-catalog/platforms/check24.jpeg',
	chip = 'assets/images/export-catalog/platforms/chip.png',
	converto = 'assets/images/export-catalog/platforms/converto.jpeg',
	dba = 'assets/images/export-catalog/platforms/dba.jpeg',
	evendi = 'assets/images/export-catalog/platforms/evendi.png',
	findologic = 'assets/images/export-catalog/platforms/findologic.jpeg',
	geizhals = 'assets/images/export-catalog/platforms/geizhals.png',
	guenstiger = 'assets/images/export-catalog/platforms/guenstiger.png',
	guloggratis = 'assets/images/export-catalog/platforms/guloggratis.jpeg',
	hatch = 'assets/images/export-catalog/platforms/hatch.png',
	hinta = 'assets/images/export-catalog/platforms/hinta.jpeg',
	hintaopas = 'assets/images/export-catalog/platforms/hintaopas.png',
	hintaseuranta = 'assets/images/export-catalog/platforms/hintaseuranta.png',
	jaknjil = 'assets/images/export-catalog/platforms/jaknjil.jpeg',
	joom = 'assets/images/export-catalog/platforms/joom.jpeg',
	kaufland = 'assets/images/export-catalog/platforms/kaufland.jpeg',
	kupona = 'assets/images/export-catalog/platforms/kupona.jpeg',
	ladenzeile = 'assets/images/export-catalog/platforms/ladenzeile.png',
	lionshome = 'assets/images/export-catalog/platforms/lionshome.jpeg',
	liveshoppingaktuell = 'assets/images/export-catalog/platforms/liveshoppingaktuell.jpeg',
	locafox = 'assets/images/export-catalog/platforms/locafox.png',
	manor = 'assets/images/export-catalog/platforms/manor.jpeg',
	minderest = 'assets/images/export-catalog/platforms/minderest.jpeg',
	moebel = 'assets/images/export-catalog/platforms/moebel.png',
	mybestbrands = 'assets/images/export-catalog/platforms/mybestbrands.png',
	myliveshopping = 'assets/images/export-catalog/platforms/myliveshopping.jpeg',
	netbooster = 'assets/images/export-catalog/platforms/netbooster.png',
	nexeps = 'assets/images/export-catalog/platforms/nexeps.png',
	nokaut = 'assets/images/export-catalog/platforms/nokaut.png',
	okazjeinfo = 'assets/images/export-catalog/platforms/okazjeinfo.png',
	parsionate = 'assets/images/export-catalog/platforms/parsionate.png',
	photoslurp = 'assets/images/export-catalog/platforms/photoslurp.jpeg',
	preis = 'assets/images/export-catalog/platforms/preis.png',
	preisvergleich = 'assets/images/export-catalog/platforms/preisvergleich.png',
	prisjakt = 'assets/images/export-catalog/platforms/prisjakt.png',
	proskilab = 'assets/images/export-catalog/platforms/proskilab.jpeg',
	publicidees = 'assets/images/export-catalog/platforms/publicidees.png',
	ricardo = 'assets/images/export-catalog/platforms/ricardo.jpeg',
	scarab = 'assets/images/export-catalog/platforms/scarab.png',
	shopalike = 'assets/images/export-catalog/platforms/shopalike.png',
	shopello = 'assets/images/export-catalog/platforms/shopello.png',
	shopping24 = 'assets/images/export-catalog/platforms/shopping24.jpeg',
	shoesize = 'assets/images/export-catalog/platforms/shoesize.png',
	shopwahl = 'assets/images/export-catalog/platforms/shopwahl.png',
	sovendus = 'assets/images/export-catalog/platforms/sovendus.png',
	squarelovin = 'assets/images/export-catalog/platforms/squarelovin.jpeg',
	toppreise = 'assets/images/export-catalog/platforms/toppreise.png',
	trbo = 'assets/images/export-catalog/platforms/trbo.jpeg',
	venca = 'assets/images/export-catalog/platforms/venca.jpeg',
	vertaa = 'assets/images/export-catalog/platforms/vertaa.jpeg',
	vivalu = 'assets/images/export-catalog/platforms/vivalu.jpeg',
	webarcondicionado = 'assets/images/export-catalog/platforms/webarcondicionado.png',
	webepartners = 'assets/images/export-catalog/platforms/webepartners.png',
	webkupiec = 'assets/images/export-catalog/platforms/webkupiec.png',
	welchereifen = 'assets/images/export-catalog/platforms/welchereifen.png',
	worten = 'assets/images/export-catalog/platforms/worten.jpeg',
	xxldeals = 'assets/images/export-catalog/platforms/xxldeals.jpeg',
	yahoogemini = 'assets/images/export-catalog/platforms/yahoogemini.png',
	yatego = 'assets/images/export-catalog/platforms/yatego.png',
	yopi = 'assets/images/export-catalog/platforms/yopi.jpeg',
	achetermoinscher = 'assets/images/export-catalog/platforms/achetermoinscher.png',
	adventori = 'assets/images/export-catalog/platforms/adventori.png',
	affilae = 'assets/images/export-catalog/platforms/affilae.png',
	antvoice = 'assets/images/export-catalog/platforms/antvoice.jpeg',
	bhv = 'assets/images/export-catalog/platforms/bhv.jpeg',
	brandalley = 'assets/images/export-catalog/platforms/brandalley.jpeg',
	but = 'assets/images/export-catalog/platforms/but.jpeg',
	cezigue = 'assets/images/export-catalog/platforms/cezigue.png',
	cherchons = 'assets/images/export-catalog/platforms/cherchons.png',
	ematch = 'assets/images/export-catalog/platforms/ematch.png',
	fashionchick = 'assets/images/export-catalog/platforms/fashionchick.png',
	fitizzy = 'assets/images/export-catalog/platforms/fitizzy.png',
	greenweez = 'assets/images/export-catalog/platforms/greenweez.jpeg',
	hellopro = 'assets/images/export-catalog/platforms/hellopro.png',
	homify = 'assets/images/export-catalog/platforms/homify.jpeg',
	hotsale = 'assets/images/export-catalog/platforms/hotsale.jpeg',
	iprice = 'assets/images/export-catalog/platforms/iprice.png',
	keyade = 'assets/images/export-catalog/platforms/keyade.png',
	kissmyshoe = 'assets/images/export-catalog/platforms/kissmyshoe.jpeg',
	lcdcompare = 'assets/images/export-catalog/platforms/lcdcompare.png',
	ledenicheur = 'assets/images/export-catalog/platforms/ledenicheur.png',
	leroymerlin = 'assets/images/export-catalog/platforms/leroymerlin.jpeg',
	lesbonnesbouilles = 'assets/images/export-catalog/platforms/lesbonnesbouilles.jpeg',
	liganz = 'assets/images/export-catalog/platforms/liganz.png',
	mafringue = 'assets/images/export-catalog/platforms/mafringue.png',
	mercis1000 = 'assets/images/export-catalog/platforms/mercis1000.png',
	mythings = 'assets/images/export-catalog/platforms/mythings.png',
	priceobservatory = 'assets/images/export-catalog/platforms/priceobservatory.png',
	prisguide = 'assets/images/export-catalog/platforms/prisguide.png',
	pureshopping = 'assets/images/export-catalog/platforms/pureshopping.png',
	qwant = 'assets/images/export-catalog/platforms/qwant.jpeg',
	remailme = 'assets/images/export-catalog/platforms/remailme.jpeg',
	retailmenot = 'assets/images/export-catalog/platforms/retailmenot.jpeg',
	showroomprive = 'assets/images/export-catalog/platforms/showroomprive.jpeg',
	skaze = 'assets/images/export-catalog/platforms/skaze.jpeg',
	skitour = 'assets/images/export-catalog/platforms/skitour.jpeg',
	spotashop = 'assets/images/export-catalog/platforms/spotashop.jpeg',
	tek = 'assets/images/export-catalog/platforms/tek.png',
	thunderstone = 'assets/images/export-catalog/platforms/thunderstone.jpeg',
	timeone = 'assets/images/export-catalog/platforms/timeone.jpeg',
	tourdumondiste = 'assets/images/export-catalog/platforms/tourdumondiste.jpeg',
	touslesprix = 'assets/images/export-catalog/platforms/touslesprix.png',
	trouversoncadeau = 'assets/images/export-catalog/platforms/trouversoncadeau.png',
	uzerly = 'assets/images/export-catalog/platforms/uzerly.jpeg',
	veepee = 'assets/images/export-catalog/platforms/veepee.jpeg',
	wordpress = 'assets/images/export-catalog/platforms/wordpress.png',
	zebestof = 'assets/images/export-catalog/platforms/zebestof.png',
	adup = 'assets/images/export-catalog/platforms/adup.jpeg',
	galerieslafayette = 'assets/images/export-catalog/platforms/galerieslafayette.png',
	kreocen = 'assets/images/export-catalog/platforms/kreocen.png',
	kuantokusta = 'assets/images/export-catalog/platforms/kuantokusta.png',
	laposte = 'assets/images/export-catalog/platforms/laposte.jpeg',
	socloz = 'assets/images/export-catalog/platforms/socloz.png'
}
