import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../_services/authentication.service';
import { Store } from '@ngrx/store';
import { UserState } from '../state/user/user.reducer';

@Injectable({
	providedIn: 'root'
})
export class UserBlockGuard implements CanActivate {
	constructor(public authService: AuthenticationService, public router: Router, private userStore: Store<UserState>) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.authService.isLoggedIn()) {
			return of(true);
		}
		this.authService.logout();
		return of(false);
	}
}
