import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalInterface } from 'src/app/user-management/models/DatatableEnum';

@Component({
	selector: 'app-confirm-another-card',
	templateUrl: './confirm-another-card.component.html',
	styleUrls: ['./confirm-another-card.component.scss']
})
export class ConfirmAnotherCardComponent implements OnInit {
	@Input() public modelDetail: ModalInterface;

	constructor(private dialogRef: MatDialogRef<ConfirmAnotherCardComponent>) {}

	ngOnInit(): void {}

	public closeDialog(): void {
		this.dialogRef.close({ addCard: false });
	}

	public actionButtonClick(): void {
		this.dialogRef.close({ addCard: true });
	}
}
