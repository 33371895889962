import { ValidatorMessages } from '../../shared/form-input/validatorMessagesInputs';

export class ReportBasicDetailsValidationMessages {
	public validationAccountDropdownMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'Account is required.'
		}
	];
	public validationInsightsDropdownMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'At least one insight must be selected.'
		}
	];
	public validationBreakdownDropdownMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'Breakdown is required.'
		}
	];
	public validationDimensionDropdownMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'Dimension is required.'
		}
	];
	public validationMetricsDropdownMessages: ValidatorMessages[] = [
		{
			validationType: 'required',
			validationMessage: 'Metric is required.'
		}
	];
}
