import { BaseApiUrl } from 'src/app/_services/base-api-urls';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeneralSettingsRequest } from './models/api/general-settings-request.model';
import { GeneralSettingsResponse } from './models/api/general-settings-response.model';
import { UserState } from 'src/app/_models/user-models/user-state';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root'
})
export class GeneralSettingsApi {
	private readonly baseURL = `${BaseApiUrl.GeneralSettings}userPreference`;

	constructor(protected http: HttpClient, protected store: Store<UserState>) {}

	public getSharedSettings(userId: number): Observable<GeneralSettingsResponse> {
		return this.http.get<GeneralSettingsResponse>(`${this.baseURL}/`);
	}

	public getFeatureSettings(request: GeneralSettingsRequest): Observable<GeneralSettingsResponse> {
		return this.http.get<GeneralSettingsResponse>(`${this.baseURL}/${request.userId}/${request.module}`);
	}

	public updateSharedSettings(request: GeneralSettingsRequest): Observable<void> {
		return this.http.put<void>(`${this.baseURL}/${request.userId}`, request);
	}

	public updateFeatureSettings(request: GeneralSettingsRequest): Observable<void> {
		return this.http.put<void>(`${this.baseURL}/${request.userId}/${request.module}`, request);
	}
}
