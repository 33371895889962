import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-rounded-image-container',
	templateUrl: 'rounded-image-container.html',
	styleUrls: ['rounded-image-container.scss']
})
export class RoundedImageContainerComponent {
	@Input() src: string;
	@Input() svg: boolean;
}
