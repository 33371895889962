import { RowNode } from 'ag-grid-community';
import { AdsManagerEffectiveStatus } from 'src/app/ads-manager/ads-manager-insights/models/ads-manager-effective-status.enum';
import { RowClassInterface } from 'src/app/shared/master-table/models/row-class.interface';
import { InsightsCategoryTypeEnum } from '../../../shared/campaign-insights/models/insights-category-type.enum';
import { RowStyleInterface } from '../../../shared/master-table/models/row-style.interface';
import { StandardColorsEnum } from '../../../shared/models/standard-colors.enum';
import { AdSetsInterface } from '../models/ad-sets.interface';
import { AdsManagerStatusEnum } from '../models/ads-manager-status.enum';
import { AdsInterface } from '../models/ads.interface';
import { CampaignInterface } from '../models/campaign.interface';

export class AdsManagerHelperService {
	constructor() {}

	public getRowStyle(params: RowStyleInterface): Partial<CSSStyleDeclaration> {
		const rowStyle: Partial<CSSStyleDeclaration> = {};
		if (params.node.isRowPinned()) {
			rowStyle.backgroundColor = StandardColorsEnum.FiledLightestGray;
			rowStyle.color = StandardColorsEnum.DarkestGray;
		}
		rowStyle.borderBottom = `1px solid ${StandardColorsEnum.FiledLighterGray}`;
		return rowStyle;
	}

	public getRowClass(params: RowClassInterface): String {
		if (params.node.data?.effective_status === AdsManagerEffectiveStatus.Removed && params.node.data?.status === AdsManagerStatusEnum.Removed) {
			return 'row-deleted';
		}
	}

	public returnAsStructureId(structure: CampaignInterface | AdSetsInterface | AdsInterface, level: InsightsCategoryTypeEnum): string {
		if (level === InsightsCategoryTypeEnum.Campaign) {
			return (structure as CampaignInterface).campaign_id;
		} else if (level === InsightsCategoryTypeEnum.AdSet) {
			return (structure as AdSetsInterface).adset_id;
		}
		return (structure as AdsInterface).ad_id;
	}

	public isRowSelectable(rowNode: RowNode): boolean {
		return (rowNode.data as CampaignInterface | AdSetsInterface | AdsInterface)?.status !== AdsManagerStatusEnum.Removed;
	}
}
